import { put, select, takeLatest } from 'redux-saga/effects';
import {
    actionChangeRows,
    actionClearFilter,
    actionClearSort,
    actionGotoFirstPage,
    actionGotoNextPage,
    actionGotoPreviousPage,
    actionSetFilter,
    actionSetSort,
    actionSetSorts,
    actionToggleSelectAllItems,
    actionToggleSelectItem,
    selectorGetRawState,
    selectorUrlFunctionsAreDisabled,
} from '../redux/index_ns';
import { buildSearchString } from 'pitch4_layout/components/uri';
import { replace } from 'react-router-redux';
// Actions
const FIRST_PAGE = `saga.reset_page`;
export const sagaActionGotoFirstPage = (namespace, pathname = '') => () => ({
    type: `${FIRST_PAGE}`,
    namespace,
    pathname,
});

const NEXT_PAGE = `saga.next_page`;
export const sagaActionGotoNextPage = (namespace, pathname = '') => ({ startCursor, endCursor }) => ({
    type: `${NEXT_PAGE}`,
    namespace,
    pathname,
    startCursor: startCursor,
    endCursor: endCursor,
});

const PREVIOUS_PAGE = `saga.prev_page`;
export const sagaActionGotoPreviousPage = (namespace, pathname = '') => () => ({
    type: `${PREVIOUS_PAGE}`,
    namespace,
    pathname,
});

const TOGGLE_SELECT_ALL_ITEMS = `saga.toggle_select_all_items`;
export const sagaActionToggleSelectAllItems = (namespace, pathname = '') => (visibleItemIds) => ({
    type: `${TOGGLE_SELECT_ALL_ITEMS}`,
    namespace,
    pathname,
    visibleItemIds,
});

const TOGGLE_SELECT_ITEM = `saga.toggle_select_item`;
export const sagaActionToggleSelectItem = (namespace, pathname = '') => (itemId) => ({
    type: `${TOGGLE_SELECT_ITEM}`,
    namespace,
    pathname,
    itemId,
});

const SET_IGNORE = `saga.set_ignore`;
export const sagaActionSetIgnore = (namespace, pathname = '') => (enquiries) => ({
    type: `${SET_IGNORE}`,
    namespace,
    pathname,
    ignoreEnquires: enquiries,
});

const CHANGE_ROWS = `saga.rows`;
export const sagaActionChangeRows = (namespace, pathname = '') => (first = 10) => ({
    type: `${CHANGE_ROWS}`,
    namespace,
    pathname,
    first: first,
});

const SET_FILTERS = `saga.set_filters`;
export const sagaActionSetFilters = (namespace, pathname = '') => (filters) => {
    return {
        type: `${SET_FILTERS}`,
        namespace,
        pathname,
        newFilters: [...filters],
    };
};

const SET_FILTER = `saga.set_filter`;
export const sagaActionSetFilter = (namespace, pathname = '') => (filterIn) => {
    return {
        type: `${SET_FILTER}`,
        namespace,
        pathname,
        filter: { ...filterIn },
    };
};

const CLEAR_FILTER = `saga.clear_filter`;
export const sagaActionClearFilter = (namespace, pathname = '') => (column) => {
    return {
        type: `${CLEAR_FILTER}`,
        namespace,
        pathname,
        column,
    };
};

const SET_SORT = `saga.set_sort`;
export const sagaActionSetSort = (namespace, pathname = '') => (sort) => ({
    type: `${SET_SORT}`,
    pathname,
    namespace,
    sort: sort,
});

const SET_SORTS = `saga.set_sorts`;
export const sagaActionSetSorts = (namespace, pathname = '') => (sorts) => ({
    type: `${SET_SORTS}`,
    namespace,
    pathname,
    sorts,
});

const CLEAR_SORT = `saga.clear_sort`;
export const sagaActionClearSort = (namespace, pathname = '') => (column) => {
    return {
        type: `${CLEAR_SORT}`,
        namespace,
        pathname,
        column,
    };
};

//Worker Sagas
function* onNextPage(action) {
    yield put(actionGotoNextPage(action.namespace)({ startCursor: action.startCursor, endCursor: action.endCursor }));
    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

function* onPreviousPage(action) {
    yield put(actionGotoPreviousPage(action.namespace)());
    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

function* onFirstPage(action) {
    yield put(actionGotoFirstPage(action.namespace)());
    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

function* onChangeRows(action) {
    yield put(actionGotoFirstPage(action.namespace)());
    yield put(actionChangeRows(action.namespace)(action.first));
    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

function* onSetFilter(action) {
    yield put(actionSetFilter(action.namespace)(action.filter));
    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

function* onClearFilter(action) {
    yield put(actionClearFilter(action.namespace)(action.column));
    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

function* onSetSorts(action) {
    yield put(actionSetSorts(action.namespace)(action.sorts));
    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

function* onSetSort(action) {
    yield put(actionSetSort(action.namespace)(action.sort));
    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

function* onClearSort(action) {
    yield put(actionClearSort(action.namespace)(action.column));
    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

function* onSelectItem(action) {
    yield put(actionToggleSelectItem(action.namespace)(action.itemId));
    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

function* onSelectAllItems(action) {
    yield put(actionToggleSelectAllItems(action.namespace)(action.visibleItemIds));

    let urlDisabled = yield select(selectorUrlFunctionsAreDisabled(action.namespace));
    if (!urlDisabled && action.pathname && action.pathname !== '') {
        let state = yield select(selectorGetRawState(action.namespace));
        yield put(replace(`${action.pathname}?${buildSearchString(state)}`));
    }
}

//Main Saga
function* main(action) {
    yield takeLatest(NEXT_PAGE, onNextPage);
    yield takeLatest(PREVIOUS_PAGE, onPreviousPage);
    yield takeLatest(FIRST_PAGE, onFirstPage);
    yield takeLatest(SET_FILTER, onSetFilter);
    yield takeLatest(SET_SORT, onSetSort);
    yield takeLatest(SET_SORTS, onSetSorts);
    yield takeLatest(CHANGE_ROWS, onChangeRows);
    yield takeLatest(TOGGLE_SELECT_ITEM, onSelectItem);
    yield takeLatest(TOGGLE_SELECT_ALL_ITEMS, onSelectAllItems);
    yield takeLatest(CLEAR_FILTER, onClearFilter);
    yield takeLatest(CLEAR_SORT, onClearSort);
}

export default main;
