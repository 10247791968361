import {
    BUSINESS_TYPE,
    PROPERTY_TYPE,
    ASSET_TYPE,
    BRIDGE_TYPE,
    DEVELOPMENT_TYPE,
    INVOICE_TYPE,
    MERCHANT_CASH_ADVANCE_TYPE,
    MEZZANINE_TYPE,
    PORTFOLIO_TYPE,
    R_C_F_TYPE,
    REFURBISHMENT_TYPE,
    SECOND_CHARGE_TYPE,
    SUPPLY_CHAIN_TYPE,
    TERM_LONG_TYPE,
    TERM_SHORT_TYPE,
    TERM_TYPE,
} from '../Borrower/BorrowerLoanType';
import {
    QUESTION_ADDITIONAL_INFO,
    QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
    QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
    QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
    QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
    QUESTION_APPLICANT_MAIN_NATIONALITY,
    QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
    QUESTION_APPLICANT_MAIN_OCCUPATION,
    QUESTION_BUILD_COSTS_BORROWING,
    QUESTION_BUSINESS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
    QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
    QUESTION_BUSINESS_FACILITY_NEEDED_BY,
    QUESTION_BUSINESS_INVOICE_FINANCE_TYPE,
    QUESTION_BUSINESS_IS_SECURE_FINANCE,
    QUESTION_BUSINESS_NAME,
    QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY,
    QUESTION_BUSINESS_OUTSTANDING_DEBT,
    QUESTION_BUSINESS_OUTSTANDING_INVOICES,
    QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
    QUESTION_BUSINESS_PDQ_INCOME,
    QUESTION_BUSINESS_PROFITABLE,
    QUESTION_BUSINESS_REFINANCE,
    QUESTION_BUSINESS_SEASONAL,
    QUESTION_BUSINESS_SECTOR,
    QUESTION_BUSINESS_TRADING_LENGTH,
    QUESTION_BUSINESS_TYPE,
    QUESTION_BUSINESS_WEBSITE,
    QUESTION_BUY_TO_LET_EXPERIENCE,
    QUESTION_COMMERCIAL_OWNER_OCCUPIED,
    QUESTION_COMMERCIAL_TENANT_AMOUNT,
    QUESTION_COMMERCIAL_TYPE_OTHER,
    QUESTION_COMMERCIAL_TYPES,
    QUESTION_COMMERCIAL_TYPES_REFURBISHMENT,
    QUESTION_COMMERCIAL_UNIT_COUNT,
    QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT,
    QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN,
    QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
    QUESTION_CURRENT_VALUE,
    QUESTION_DAY_ONE_LOAN_AMOUNT,
    QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE,
    QUESTION_DEVELOPMENT_LENGTH,
    QUESTION_EPC_RATING,
    QUESTION_EXIT_STRATEGY,
    QUESTION_EXPECTED_END_VALUE,
    QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE,
    QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
    QUESTION_FIRST_CHARGE_AMOUNT,
    QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE,
    QUESTION_FUNDING_TYPE,
    QUESTION_HMO_BEDROOMS,
    QUESTION_HMO_HAS_LICENSE,
    QUESTION_HMO_LICENSE_IS_REQUIRED,
    QUESTION_HMO_ROOMS_SELF_CONTAINED,
    QUESTION_LENDER,
    QUESTION_LOAN_AMOUNT,
    QUESTION_LOAN_AMOUNT_REFURBISHMENT,
    QUESTION_LOAN_NEEDED_BY,
    QUESTION_LOAN_PURPOSE,
    QUESTION_LOAN_PURPOSE_MEZZANINE,
    QUESTION_LOAN_TERM,
    QUESTION_LOAN_TERM_BUSINESS_TERM,
    QUESTION_LOAN_TYPE,
    QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
    QUESTION_OCCUPY_PROPERTY,
    QUESTION_OUTSTANDING_LOAN,
    QUESTION_OUTSTANDING_LOAN_AMOUNT,
    QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY,
    QUESTION_OWN_ANY_OTHER_PROPERTY,
    QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD,
    QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
    QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT,
    QUESTION_PERCENTAGE_RESIDENTIAL_VALUE,
    QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT,
    QUESTION_PERCENTAGE_COMMERCIAL_VALUE,
    QUESTION_PERCENTAGE_COMMERCIAL_VALUE_DEVELOPMENT,
    QUESTION_PLANNING_IN_PLACE,
    QUESTION_PORTFOLIO_PROPERTIES,
    QUESTION_PORTFOLIO_REFINANCE,
    QUESTION_PORTFOLIO_VALUE,
    QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
    QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE,
    QUESTION_PROPERTY_ADDRESS,
    QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS,
    QUESTION_PROPERTY_LOCATION,
    QUESTION_PROPERTY_LOCATION_BUSINESS,
    QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
    QUESTION_PROPERTY_LOCATION_OTHER,
    QUESTION_PROPERTY_OWNERSHIP,
    QUESTION_PROPERTY_POSTCODE,
    QUESTION_PROPERTY_TYPE,
    QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
    QUESTION_REFURBISHMENT_LENGTH,
    QUESTION_RENTAL_INCOME,
    QUESTION_RENTAL_INCOME_PORTFOLIO,
    QUESTION_RENTAL_INCOME_RESIDENTIAL,
    QUESTION_RESIDENTIAL_REFINANCE,
    QUESTION_RESIDENTIAL_UNIT_COUNT,
    QUESTION_RESIDENTIAL_UNIT_VALUE,
    QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
    QUESTION_SECOND_CHARGE_REFINANCE,
    QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
    QUESTION_SITE_DESCRIPTION,
    QUESTION_SITE_INTENTIONS,
    QUESTION_SITE_INTENTIONS_MEZZANINE,
    QUESTION_SITE_INTENTIONS_REFURBISHMENT,
    QUESTION_TEAM_DEVELOPMENT_EXPERIENCE,
    QUESTION_TOTAL_DEVELOPMENT_COSTS,
    QUESTION_TURN_OVER,
    QUESTION_UNITS_PRE_SOLD,
    QUESTION_WORKS_EXPLANATION,
    QUESTION_LOAN_TO_VALUE,
    QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
    QUESTION_PROPERTY_TYPE_MEZZANINE,
    QUESTION_PROPERTY_TYPE_PORTFOLIO,
    QUESTION_PROPERTY_TYPE_REFURBISHMENT,
    QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
    QUESTION_LOAN_PURPOSE_DESCRIPTION,
} from '../Borrower/BorrowerQuestions';
import {
    COMMERCIAL_FUNDING_TYPE,
    COMMERCIAL_PORTFOLIO_FUNDING_TYPE,
    HMO_FUNDING_TYPE,
    LAND_WITHOUT_PLANNING_FUNDING_TYPE,
    MIXED_PORTFOLIO_FUNDING_TYPE,
    RESIDENTIAL_FUNDING_TYPE,
    RESIDENTIAL_PORTFOLIO_FUNDING_TYPE,
    SEMI_COMMERCIAL_FUNDING_TYPE,
} from './CommercialFundingType';

class LoanEnquiryQuestionsPerType {
    questions = {
        BUSINESS_UNIVERSAL: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_IS_SECURE_FINANCE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_BUSINESS_FACILITY_NEEDED_BY,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_PURPOSE_DESCRIPTION,
            QUESTION_LOAN_TERM_BUSINESS_TERM,
            QUESTION_LOAN_TERM,
            QUESTION_TURN_OVER,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
            QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES,
            QUESTION_BUSINESS_OUTSTANDING_INVOICES,
            QUESTION_BUSINESS_INVOICE_FINANCE_TYPE,
            QUESTION_BUSINESS_PDQ_INCOME,
        },
        [TERM_LONG_TYPE + '_SECURE']: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_IS_SECURE_FINANCE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_BUSINESS_FACILITY_NEEDED_BY,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_PURPOSE_DESCRIPTION,
            QUESTION_LOAN_TERM_BUSINESS_TERM,
            QUESTION_TURN_OVER,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [TERM_LONG_TYPE + '_UNSECURE']: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_IS_SECURE_FINANCE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_BUSINESS_FACILITY_NEEDED_BY,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_PURPOSE_DESCRIPTION,
            QUESTION_LOAN_TERM_BUSINESS_TERM,
            QUESTION_TURN_OVER,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [TERM_SHORT_TYPE + '_SECURE']: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_IS_SECURE_FINANCE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_BUSINESS_FACILITY_NEEDED_BY,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_TERM_BUSINESS_TERM,
            QUESTION_TURN_OVER,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [TERM_SHORT_TYPE + '_UNSECURE']: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_IS_SECURE_FINANCE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_BUSINESS_FACILITY_NEEDED_BY,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_PURPOSE_DESCRIPTION,
            QUESTION_LOAN_TERM_BUSINESS_TERM,
            QUESTION_TURN_OVER,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [R_C_F_TYPE + '_SECURE']: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_IS_SECURE_FINANCE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_PURPOSE_DESCRIPTION,
            QUESTION_TURN_OVER,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [R_C_F_TYPE + '_UNSECURE']: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_IS_SECURE_FINANCE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_PURPOSE_DESCRIPTION,
            QUESTION_TURN_OVER,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [INVOICE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES,
            QUESTION_BUSINESS_OUTSTANDING_INVOICES,
            QUESTION_BUSINESS_INVOICE_FINANCE_TYPE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_PURPOSE_DESCRIPTION,
            QUESTION_LOAN_TERM,
            QUESTION_TURN_OVER,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [ASSET_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_PURPOSE_DESCRIPTION,
            QUESTION_LOAN_TERM,
            QUESTION_TURN_OVER,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [MERCHANT_CASH_ADVANCE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_BUSINESS_PDQ_INCOME,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_PURPOSE_DESCRIPTION,
            QUESTION_TURN_OVER,
            QUESTION_LOAN_TERM,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [SUPPLY_CHAIN_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_BUSINESS_REFINANCE,
            QUESTION_BUSINESS_SEASONAL,
            QUESTION_BUSINESS_TYPE,
            QUESTION_BUSINESS_NAME,
            QUESTION_BUSINESS_WEBSITE,
            QUESTION_BUSINESS_TRADING_LENGTH,
            QUESTION_BUSINESS_SECTOR,
            QUESTION_PROPERTY_LOCATION_BUSINESS,
            QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_PURPOSE,
            QUESTION_LOAN_TERM,
            QUESTION_TURN_OVER,
            QUESTION_BUSINESS_PROFITABLE,
            QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
            QUESTION_BUSINESS_OUTSTANDING_DEBT,
            QUESTION_BUSINESS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
            QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
            QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [COMMERCIAL_FUNDING_TYPE + '_' + TERM_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY,
            QUESTION_COMMERCIAL_UNIT_COUNT,
            QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN,
            QUESTION_COMMERCIAL_TENANT_AMOUNT,
            QUESTION_COMMERCIAL_TYPES,
            QUESTION_COMMERCIAL_TYPE_OTHER,
            QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [SEMI_COMMERCIAL_FUNDING_TYPE + '_' + TERM_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_RENTAL_INCOME_RESIDENTIAL,
            QUESTION_RESIDENTIAL_UNIT_COUNT,
            QUESTION_COMMERCIAL_UNIT_COUNT,
            QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN,
            QUESTION_COMMERCIAL_TENANT_AMOUNT,
            QUESTION_COMMERCIAL_TYPES,
            QUESTION_COMMERCIAL_TYPE_OTHER,
            QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY,
            QUESTION_RESIDENTIAL_UNIT_VALUE,
            QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
            QUESTION_PERCENTAGE_RESIDENTIAL_VALUE,
            QUESTION_PERCENTAGE_COMMERCIAL_VALUE,
            QUESTION_COMMERCIAL_OWNER_OCCUPIED,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [HMO_FUNDING_TYPE + '_' + TERM_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_RENTAL_INCOME_RESIDENTIAL,
            QUESTION_HMO_HAS_LICENSE,
            QUESTION_HMO_LICENSE_IS_REQUIRED,
            QUESTION_HMO_BEDROOMS,
            QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [RESIDENTIAL_FUNDING_TYPE + '_' + TERM_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_RESIDENTIAL_UNIT_COUNT,
            QUESTION_RENTAL_INCOME_RESIDENTIAL,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [COMMERCIAL_FUNDING_TYPE + '_' + BRIDGE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
            QUESTION_COMMERCIAL_OWNER_OCCUPIED,
            QUESTION_BUY_TO_LET_EXPERIENCE,
            QUESTION_COMMERCIAL_TYPES,
            QUESTION_COMMERCIAL_TYPE_OTHER,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [SEMI_COMMERCIAL_FUNDING_TYPE + '_' + BRIDGE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
            QUESTION_COMMERCIAL_OWNER_OCCUPIED,
            QUESTION_BUY_TO_LET_EXPERIENCE,
            QUESTION_RESIDENTIAL_UNIT_COUNT,
            QUESTION_COMMERCIAL_TYPES,
            QUESTION_COMMERCIAL_TYPE_OTHER,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [HMO_FUNDING_TYPE + '_' + BRIDGE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_HMO_HAS_LICENSE,
            QUESTION_HMO_LICENSE_IS_REQUIRED,
            QUESTION_HMO_BEDROOMS,
            QUESTION_HMO_ROOMS_SELF_CONTAINED,
            QUESTION_BUY_TO_LET_EXPERIENCE,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [RESIDENTIAL_FUNDING_TYPE + '_' + BRIDGE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [COMMERCIAL_FUNDING_TYPE + '_' + REFURBISHMENT_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_REFURBISHMENT,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_DAY_ONE_LOAN_AMOUNT,
            QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
            QUESTION_LOAN_AMOUNT_REFURBISHMENT,
            QUESTION_REFURBISHMENT_LENGTH,
            QUESTION_SITE_DESCRIPTION,
            QUESTION_SITE_INTENTIONS_REFURBISHMENT,
            QUESTION_EXPECTED_END_VALUE,
            QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE,
            QUESTION_COMMERCIAL_TYPES_REFURBISHMENT,
            QUESTION_COMMERCIAL_TYPE_OTHER,
            QUESTION_COMMERCIAL_OWNER_OCCUPIED,
            QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [SEMI_COMMERCIAL_FUNDING_TYPE + '_' + REFURBISHMENT_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_REFURBISHMENT,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_DAY_ONE_LOAN_AMOUNT,
            QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
            QUESTION_LOAN_AMOUNT_REFURBISHMENT,
            QUESTION_REFURBISHMENT_LENGTH,
            QUESTION_SITE_DESCRIPTION,
            QUESTION_SITE_INTENTIONS_REFURBISHMENT,
            QUESTION_EXPECTED_END_VALUE,
            QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE,
            QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
            QUESTION_PERCENTAGE_RESIDENTIAL_VALUE,
            QUESTION_PERCENTAGE_COMMERCIAL_VALUE,
            QUESTION_RENTAL_INCOME_RESIDENTIAL,
            QUESTION_COMMERCIAL_TYPES_REFURBISHMENT,
            QUESTION_COMMERCIAL_TYPE_OTHER,
            QUESTION_COMMERCIAL_OWNER_OCCUPIED,
            QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [RESIDENTIAL_FUNDING_TYPE + '_' + REFURBISHMENT_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_REFURBISHMENT,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_DAY_ONE_LOAN_AMOUNT,
            QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
            QUESTION_LOAN_AMOUNT_REFURBISHMENT,
            QUESTION_REFURBISHMENT_LENGTH,
            QUESTION_SITE_DESCRIPTION,
            QUESTION_SITE_INTENTIONS_REFURBISHMENT,
            QUESTION_EXPECTED_END_VALUE,
            QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [HMO_FUNDING_TYPE + '_' + REFURBISHMENT_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_REFURBISHMENT,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_DAY_ONE_LOAN_AMOUNT,
            QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
            QUESTION_LOAN_AMOUNT_REFURBISHMENT,
            QUESTION_REFURBISHMENT_LENGTH,
            QUESTION_SITE_DESCRIPTION,
            QUESTION_SITE_INTENTIONS_REFURBISHMENT,
            QUESTION_EXPECTED_END_VALUE,
            QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE,
            QUESTION_HMO_BEDROOMS,
            QUESTION_HMO_HAS_LICENSE,
            QUESTION_HMO_LICENSE_IS_REQUIRED,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [COMMERCIAL_FUNDING_TYPE + '_' + SECOND_CHARGE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_SECOND_CHARGE_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_LENDER,
            QUESTION_RENTAL_INCOME,
            QUESTION_COMMERCIAL_UNIT_COUNT,
            QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [SEMI_COMMERCIAL_FUNDING_TYPE + '_' + SECOND_CHARGE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_SECOND_CHARGE_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_LENDER,
            QUESTION_RENTAL_INCOME,
            QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE,
            QUESTION_PERCENTAGE_RESIDENTIAL_VALUE,
            QUESTION_PERCENTAGE_COMMERCIAL_VALUE,
            QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
            QUESTION_COMMERCIAL_UNIT_COUNT,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [RESIDENTIAL_FUNDING_TYPE + '_' + SECOND_CHARGE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_SECOND_CHARGE_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_LENDER,
            QUESTION_RENTAL_INCOME,
            QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [COMMERCIAL_FUNDING_TYPE + '_' + DEVELOPMENT_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_REFURBISHMENT,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_DEVELOPMENT_LENGTH,
            QUESTION_OUTSTANDING_LOAN,
            QUESTION_UNITS_PRE_SOLD,
            QUESTION_COMMERCIAL_TYPES_REFURBISHMENT,
            QUESTION_COMMERCIAL_TYPE_OTHER,
            QUESTION_DAY_ONE_LOAN_AMOUNT,
            QUESTION_WORKS_EXPLANATION,
            QUESTION_TOTAL_DEVELOPMENT_COSTS,
            QUESTION_BUILD_COSTS_BORROWING,
            QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
            QUESTION_TEAM_DEVELOPMENT_EXPERIENCE,
            QUESTION_EPC_RATING,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [SEMI_COMMERCIAL_FUNDING_TYPE + '_' + DEVELOPMENT_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_REFURBISHMENT,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_DEVELOPMENT_LENGTH,
            QUESTION_OUTSTANDING_LOAN,
            QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT,
            QUESTION_PERCENTAGE_COMMERCIAL_VALUE_DEVELOPMENT,
            QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT,
            QUESTION_UNITS_PRE_SOLD,
            QUESTION_COMMERCIAL_TYPES_REFURBISHMENT,
            QUESTION_COMMERCIAL_TYPE_OTHER,
            QUESTION_DAY_ONE_LOAN_AMOUNT,
            QUESTION_WORKS_EXPLANATION,
            QUESTION_TOTAL_DEVELOPMENT_COSTS,
            QUESTION_BUILD_COSTS_BORROWING,
            QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
            QUESTION_TEAM_DEVELOPMENT_EXPERIENCE,
            QUESTION_EPC_RATING,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [RESIDENTIAL_FUNDING_TYPE + '_' + DEVELOPMENT_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_REFURBISHMENT,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_DEVELOPMENT_LENGTH,
            QUESTION_OUTSTANDING_LOAN,
            QUESTION_DAY_ONE_LOAN_AMOUNT,
            QUESTION_WORKS_EXPLANATION,
            QUESTION_TOTAL_DEVELOPMENT_COSTS,
            QUESTION_BUILD_COSTS_BORROWING,
            QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
            QUESTION_TEAM_DEVELOPMENT_EXPERIENCE,
            QUESTION_EPC_RATING,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [COMMERCIAL_FUNDING_TYPE + '_' + MEZZANINE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_MEZZANINE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_FIRST_CHARGE_AMOUNT,
            QUESTION_LENDER,
            QUESTION_SITE_INTENTIONS_MEZZANINE,
            QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE,
            QUESTION_TOTAL_DEVELOPMENT_COSTS,
            QUESTION_EXPECTED_END_VALUE,
            QUESTION_DEVELOPMENT_LENGTH,
            QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
            QUESTION_COMMERCIAL_TYPES_REFURBISHMENT,
            QUESTION_COMMERCIAL_TYPE_OTHER,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [SEMI_COMMERCIAL_FUNDING_TYPE + '_' + MEZZANINE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_MEZZANINE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_FIRST_CHARGE_AMOUNT,
            QUESTION_LENDER,
            QUESTION_SITE_INTENTIONS_MEZZANINE,
            QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE,
            QUESTION_TOTAL_DEVELOPMENT_COSTS,
            QUESTION_EXPECTED_END_VALUE,
            QUESTION_DEVELOPMENT_LENGTH,
            QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
            QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD,
            QUESTION_COMMERCIAL_TYPES_REFURBISHMENT,
            QUESTION_COMMERCIAL_TYPE_OTHER,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [RESIDENTIAL_FUNDING_TYPE + '_' + MEZZANINE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_MEZZANINE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_FIRST_CHARGE_AMOUNT,
            QUESTION_LENDER,
            QUESTION_SITE_INTENTIONS_MEZZANINE,
            QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE,
            QUESTION_TOTAL_DEVELOPMENT_COSTS,
            QUESTION_EXPECTED_END_VALUE,
            QUESTION_DEVELOPMENT_LENGTH,
            QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [COMMERCIAL_PORTFOLIO_FUNDING_TYPE + '_' + PORTFOLIO_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_PORTFOLIO,
            QUESTION_PORTFOLIO_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_PORTFOLIO_PROPERTIES,
            QUESTION_PORTFOLIO_VALUE,
            QUESTION_RENTAL_INCOME_PORTFOLIO,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [RESIDENTIAL_PORTFOLIO_FUNDING_TYPE + '_' + PORTFOLIO_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_PORTFOLIO,
            QUESTION_PORTFOLIO_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_PORTFOLIO_PROPERTIES,
            QUESTION_PORTFOLIO_VALUE,
            QUESTION_RENTAL_INCOME_PORTFOLIO,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [MIXED_PORTFOLIO_FUNDING_TYPE + '_' + PORTFOLIO_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE_PORTFOLIO,
            QUESTION_PORTFOLIO_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_PORTFOLIO_PROPERTIES,
            QUESTION_PORTFOLIO_VALUE,
            QUESTION_RENTAL_INCOME_PORTFOLIO,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [LAND_WITHOUT_PLANNING_FUNDING_TYPE + '_' + BRIDGE_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_LOAN_TO_VALUE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_SITE_DESCRIPTION,
            QUESTION_SITE_INTENTIONS,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
        [LAND_WITHOUT_PLANNING_FUNDING_TYPE + '_' + DEVELOPMENT_TYPE]: {
            QUESTION_LOAN_TYPE,
            QUESTION_FUNDING_TYPE,
            QUESTION_PROPERTY_TYPE,
            QUESTION_RESIDENTIAL_REFINANCE,
            QUESTION_CURRENT_VALUE,
            QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PROPERTY_LOCATION,
            QUESTION_PROPERTY_LOCATION_OTHER,
            QUESTION_PROPERTY_ADDRESS,
            QUESTION_PROPERTY_POSTCODE,
            QUESTION_OWN_ANY_OTHER_PROPERTY,
            QUESTION_PROPERTY_OWNERSHIP,
            QUESTION_OCCUPY_PROPERTY,
            QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
            QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
            QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
            QUESTION_OUTSTANDING_LOAN_AMOUNT,
            QUESTION_LOAN_PURPOSE_MEZZANINE,
            QUESTION_LOAN_AMOUNT,
            QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE,
            QUESTION_LOAN_TERM,
            QUESTION_LOAN_NEEDED_BY,
            QUESTION_PLANNING_IN_PLACE,
            QUESTION_DEVELOPMENT_LENGTH,
            QUESTION_OUTSTANDING_LOAN,
            QUESTION_DAY_ONE_LOAN_AMOUNT,
            QUESTION_WORKS_EXPLANATION,
            QUESTION_TOTAL_DEVELOPMENT_COSTS,
            QUESTION_BUILD_COSTS_BORROWING,
            QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE,
            QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
            QUESTION_TEAM_DEVELOPMENT_EXPERIENCE,
            QUESTION_EPC_RATING,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
            QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
            QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
            QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
            QUESTION_APPLICANT_MAIN_NATIONALITY,
            QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
            QUESTION_APPLICANT_MAIN_OCCUPATION,
            QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
            QUESTION_EXIT_STRATEGY,
            QUESTION_ADDITIONAL_INFO,
        },
    };
    getQuestions = (
        loanEnquiryType,
        loanEnquiryFundingType,
        loanEnquiryCommercialType = null,
        isSecureFinance = false
    ) => {
        switch (loanEnquiryType) {
            case BUSINESS_TYPE:
                return this.getQuestionsForBusinessType(loanEnquiryFundingType, isSecureFinance);
            case PROPERTY_TYPE:
                return this.getQuestionsForPropertyType(loanEnquiryFundingType, loanEnquiryCommercialType);
            default:
                return {};
        }
    };

    getQuestionsForBusinessType(loanEnquiryFundingType, isSecureFinance = false) {
        if (loanEnquiryFundingType === null) {
            return this.questions['BUSINESS_UNIVERSAL'];
        }
        let typeKey = loanEnquiryFundingType.toUpperCase();
        if (
            loanEnquiryFundingType === TERM_LONG_TYPE ||
            loanEnquiryFundingType === TERM_SHORT_TYPE ||
            loanEnquiryFundingType === R_C_F_TYPE
        ) {
            typeKey += '_' + (true === isSecureFinance ? 'SECURE' : 'UNSECURE');
        }
        return this.questions[typeKey];
    }

    getQuestionsForPropertyType(loanEnquiryFundingType, loanEnquiryCommercialType) {
        if (!loanEnquiryFundingType || !loanEnquiryCommercialType) {
            return {};
        }
        return this.questions[loanEnquiryCommercialType.toUpperCase() + '_' + loanEnquiryFundingType.toUpperCase()];
    }
}

export default LoanEnquiryQuestionsPerType;
