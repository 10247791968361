import moment from 'moment';

class DateTime {
    constructor(dateStr = {}) {
        this.moment = moment(dateStr);
    }

    toDate = () => {
        return this.moment.toDate();
    };

    toLocaleString = () => {
        return `${this.toDate().toLocaleString()}`;
    };

    getDate = () => {
        return this.toDate().getDate();
    };

    getMonth = () => {
        return this.toDate().getMonth();
    };

    getFullYear = () => {
        return this.toDate().getFullYear();
    };

    getUnix = () => {
        return this.toDate().valueOf();
    };

    hasElapsed = () => {
        const now = moment().toDate();

        return now < this.toDate();
    };

    isBefore = (comparisonDateStr, granularity = null) => {
        return this.moment.isBefore(comparisonDateStr, granularity ? granularity : undefined);
    };

    isAfter = (comparisonDateStr, granularity = null) => {
        return this.moment.isAfter(comparisonDateStr, granularity ? granularity : undefined);
    };

    isSame = (comparisonDateStr, granularity = null) => {
        return this.moment.isSame(comparisonDateStr, granularity ? granularity : undefined);
    };
}

export default DateTime;
