import { BRAND_MAIN_CONTACT, BRAND_NAME } from '../../bootstrap/constants/constants';

export const PROFILE = 'profile';
export const BORROWER_LOAN_ENQUIRY = 'borrower-loan-enquiry';
export const BORROWER_LENDER_LOAN_ENQUIRY = 'borrower-lender-loan-enquiry';
export const INTERMEDIARY_LOAN_ENQUIRY = 'intermediary-loan-enquiry';
export const INTERMEDIARY_LOAN_ENQUIRY_LIST = 'intermediary-loan-enquiry-list';
export const INTERMEDIARY_LENDER_LOAN_ENQUIRY = 'intermediary-lender-loan-enquiry';
export const SLAM_LENDER_LOAN_ENQUIRY = 'slam-lender-loan-enquiry';
export const SLAM_BUSINESS_STREAM_CREATION = 'slam-business-stream-creation';
export const MISSING_REQUIRED_FIELDS = 'missing-required-fields';

export const JOYRIDE_CONFIGS = {
    [MISSING_REQUIRED_FIELDS]: [
        {
            target: '#account-menu',
            title: 'Please Complete Your Account',
            content: 'Your account is almost setup, please head to the Account Settings to add the remaining info.',
            placement: 'bottom',
            disableBeacon: true,
        },
    ],
    [PROFILE]: [
        {
            target: '#root',
            title: 'Welcome To Your Account Settings',
            content: `Here you are able to edit details on your personal information relating to your account, and control certain preferences of how your ${BRAND_NAME} account operates.`,
            placement: 'center',
        },
        {
            target: '#primaryPhoneNumber',
            title: 'Add Required Fields',
            content: 'To complete your account some fields, such as phone number, need to be added to your details.',
        },
    ],
    [BORROWER_LOAN_ENQUIRY]: [
        {
            target: '#root',
            title: 'Welcome To Your Enquiry Screen',
            content:
                'Thank you for submitting an enquiry! You are now looking at the page which will hold all the information on your enquiry, and let you view all the matched Lenders who are suitable and ready to discuss your loan requirements.',
            placement: 'center',
        },
        {
            target: '#matched-lenders',
            title: 'Here Is Where You View Your Matched Lenders',
            content:
                'This panel contains a list of all lenders who have matched to your enquiry. To view each lender simply click on their name here.',
        },
        {
            target: '#lead-status',
            title: 'Easily View The Status Of A Loan',
            content:
                'As you talk to lenders and move along the process towards completion you will see the status against the lenders here.',
        },
    ],
    [BORROWER_LENDER_LOAN_ENQUIRY]: [
        {
            target: '#root',
            title: 'Welcome To The Lender Loan Match Screen',
            content:
                'On this screen you are able to start communication with this lender about the details of your loan, exchange documents and complete the loan all in one place.',
            placement: 'center',
        },
        {
            target: '#lender-chat',
            title: 'Chat Box To Communicate With The Lender',
            content:
                'Here you will be able to open talks with the lenders, send across new information, and respond to them when they get in touch.',
        },
        {
            target: '#status-widget',
            title: 'Match Status & Progression',
            content:
                'As you progress through the process with the lender, they will be able to change the status here, so you can easily see exactly what stage you are in and how far away from completion.',
        },
        {
            target: '#lead-documents',
            title: 'Upload & Exchange Documents',
            content:
                'Here you can upload and exchange documents with the lender. This ensures all information for the loan is kept in one place and document transfer is as secure and safe as possible.',
        },
    ],
    [INTERMEDIARY_LENDER_LOAN_ENQUIRY]: [
        {
            target: '#root',
            title: 'Welcome To The Lender Match Screen',
            content:
                'On this screen you are able to start communication with this lender about the details of your loan, exchange documents and complete the loan all in one place.',
            placement: 'center',
        },
        {
            target: '#lender-chat',
            title: 'Chat Box To Communicate With The Lender',
            content:
                'Here you will be able to open talks with the lenders, send across new information, and respond to them when they get in touch.',
        },
        {
            target: '#status-widget',
            title: 'Match Status & Progression',
            content:
                'As you progress through the process with the lender, they will be able to change the status here, so you can easily see exactly what stage you are in and how far away from completion.',
        },
        {
            target: '#lender-docs',
            title: 'Upload & Exchange Documents',
            content:
                'Here you can upload and exchange documents with the lender. This ensures all information for the loan is kept in one place and document transfer is as secure and safe as possible.',
        },
    ],
    [INTERMEDIARY_LOAN_ENQUIRY]: [
        {
            target: '#root',
            title: 'Welcome To Your Enquiry Screen',
            content:
                'Thank you for submitting an enquiry! You are now looking at the page which will hold all the information on your enquiry, and let you view all the matched Lenders who are suitable and ready to discuss your loan requirements.',
            placement: 'center',
        },
        {
            target: '#enquiry-summary',
            title: 'Here Are Your Enquiry Details',
            content: 'Here you can find all the details of this enquiry.',
        },
        {
            target: '#lender-communications',
            title: 'Here Is Where You View Your Matched Lenders',
            content:
                'This panel contains a list of all lenders who have matched to your enquiry. To view each lender simply click on their name here.',
        },
        {
            target: '#lender-status',
            title: 'View The Status Of Each Match',
            content:
                'As you talk to lenders you will progress independently with the ones you choose to engage with, this can be viewed against the lenders list.',
        },
        {
            target: '#status-widget',
            title: 'Easily View The Status Of The Enquiry',
            content:
                'As you progress through a loan with the lenders of an enquiry, the overall status of the enquiry will reflect this here.',
        },
    ],
    [INTERMEDIARY_LOAN_ENQUIRY_LIST]: [
        {
            target: '#root',
            title: 'Welcome To Your Enquiry List Screen',
            content:
                'This screen holds the overview for all of your current customer enquiries, and allows you to create new enquiries and post them into the platform to match with the best lenders for the requirements.',
            placement: 'center',
        },
        {
            target: '#le-filters',
            title: 'Sort and Search',
            content:
                'Here are the controls to sort the data by different fields such as Date Created or Applicant name as well as a dedicated Applicant Search.',
        },
        {
            target: '#le-list-pagination',
            title: 'List View Page Navigation',
            content:
                'Here you can click through to the next page of data, quickly jump back to the first page, and also control the number of visible rows per page.',
        },
        {
            target: '#create-button',
            title: 'Create A New Enquiry',
            content:
                'Here you are able to create a new enquiry to be matched with our fantastic lenders. The enquiry creation process is a simple form wizard, guiding you along each step with tooltips to make things as easy as possible.',
        },
    ],
    [SLAM_LENDER_LOAN_ENQUIRY]: [
        {
            target: '#root',
            title: 'Welcome To a Lead Screen',
            content:
                'On this screen you are able to view all details supplied by the customer seeking a loan, start communications with the customer, exchange documents and progress the status to reflect the progression of the loan through your companies process.',
            placement: 'center',
        },
        {
            target: '#lead-summary',
            title: 'Here are the enquiry details',
            content:
                'Here you can find all the details of this enquiry, see if they are an Intermediary or end-customer and see details of the applicants.',
        },
        {
            target: '#lender-comms',
            title: 'Chat box to communicate with Lender',
            content:
                'Here you will be able to open talks with the lenders, send across new information, and respond to them when they get in touch.',
        },
        {
            target: '#status-widget',
            title: 'Manage the Status of the Lead',
            content:
                'As you progress the customer through the process leading to completing a loan you can advance the status here. This will help keep momentum with the customer, and allow you to see the status of all your current leads.',
        },
        {
            target: '#document-list',
            title: 'Exchange Documents with the Customer',
            content:
                'Here you can upload and exchange documents with the customer. This ensures all information for the loan is kept in one place and document transfer is as secure and safe as possible.',
        },
    ],
    [SLAM_BUSINESS_STREAM_CREATION]: [
        {
            target: '#root',
            title: 'Welcome To The Business Stream Creation Form',
            content:
                'Here you can create new Business Streams with different combinations of criteria, to make sure you perfectly match with the incoming loan enquiries from our borrowers and intermediaries. ',
            placement: 'center',
        },
        {
            target: '#LoanTypeSection',
            title: 'Criteria Organised Into Sections',
            content:
                'You’re prompted to enter the criteria relating to each section of the loan enquiry. Some of these choices will change the remaining questions based on your selection.',
        },
        {
            target: '#input-text-field-wrapper',
            title: 'Choose the Relevant Options',
            content:
                'You will be building a detailed Business Stream using the form interface, choosing the criteria that belong together in one enquiry. This gives you the flexibility to make sure you are not seeing loans on which you cannot offer.',
        },
        {
            target: '#business-stream-telephone-box',
            title: "We're Here to Help",
            content: `If you have any questions at all our Customer Success team are here to help you setup your streams. Just give us a call on ${BRAND_MAIN_CONTACT} and we can screen-share and walk you through the process.`,
        },
    ],
};

export default class JoyrideConfig {
    getConfigs = () => JOYRIDE_CONFIGS;
    getSteps = (type) => this.getConfigs()[type];
}
