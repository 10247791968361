import React from 'react';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import { STATUS_ACTIVE, STATUS_INACTIVE } from '../../../enum/BusinessStreams/StatusEnum';
import Switch from 'pitch4_forms/components/inputs/Switch';

class SetStatusToggle extends React.Component {
    render() {
        let { status, ...rest } = this.props;

        return <Switch checked={status === STATUS_ACTIVE} onChange={this.onChange} {...rest} />;
    }

    onChange = (event) => {
        let { outerRefetch, mutate, itemId, notificationSuccess, notificationError } = this.props;

        const newStatus = event.target.checked ? STATUS_ACTIVE : STATUS_INACTIVE;

        return mutate({
            variables: {
                input: {
                    uuids: [itemId],
                    newStatus: newStatus,
                },
            },
        })
            .then(({ data }) => {
                const msg = newStatus === STATUS_ACTIVE ? 'Business stream enabled' : 'Business stream disabled';
                notificationSuccess(msg);
                outerRefetch();
            })
            .catch((error) => {
                notificationError('An error occurred. Please contact support.');
                outerRefetch();
            });
    };
}

SetStatusToggle.propTypes = {
    itemId: PropTypes.string.isRequired,
    mutate: PropTypes.func.isRequired,
    outerRefetch: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

const SetStatusesGql = gql`
    mutation setStatuses($input: slam_setBusinessStreamStatusesInput!) {
        setBusinessStreamStatuses(input: $input) {
            businessStreams {
                id
                status
            }
        }
    }
`;

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(SetStatusesGql, {
        options: {
            context: {
                uri: SLAM_URL,
            },
        },
    })
)(SetStatusToggle);
