import { getNetworkLoginCookie } from '../../../pitch4_utils/LoginCookie';

export const REDUCER_NAMESPACE = 'authentication';
export const AUTHORISATION_TOKEN_FAILED = `${REDUCER_NAMESPACE}.auth_token_failed`;
export const LOGIN_SUCCESS = `${REDUCER_NAMESPACE}.login_success`;
export const LOGIN_SET_GOTO = `${REDUCER_NAMESPACE}.login_set_goto`;
export const LOGIN_REDIRECT_SUCCESS = `${REDUCER_NAMESPACE}.login_redirect_success`;
export const LOGOUT = `${REDUCER_NAMESPACE}.logout`;
export const LOGIN_HIDE_VALIDATION_ERRORS = `${REDUCER_NAMESPACE}.hide_validation_errors`;
export const LOGIN_SHOW_VALIDATION_ERRORS = `${REDUCER_NAMESPACE}.show_validation_errors`;
export const LOGIN_SET_VALIDATION_ERRORS = `${REDUCER_NAMESPACE}.set_validation_errors`;
export const LOGIN_SET_2FA_VALIDATION_ERRORS = `${REDUCER_NAMESPACE}.set_two_factor_auth_validation_errors`;
export const LOGIN_SET_SESSION = `${REDUCER_NAMESPACE}.set_session`;
export const SHOW_2FA_FORM = `${REDUCER_NAMESPACE}.two_factor_auth_form`;
export const SUBMIT_2FA_FORM = `${REDUCER_NAMESPACE}.submit_two_factor_auth_form`;

//Action creators
export const actionSetGotoPath = (gotoPath) => {
    return {
        type: LOGIN_SET_GOTO,
        gotoPath,
    };
};

export const actionSubmitTwoFactorAuthForm = (user, code) => {
    return {
        type: SUBMIT_2FA_FORM,
        user,
        code,
    };
};

export const actionShowTwoFactorAuthForm = (username, password) => {
    return {
        type: SHOW_2FA_FORM,
        username,
        password,
    };
};

export const actionSetSession = (session) => {
    return {
        type: LOGIN_SET_SESSION,
        session: session,
    };
};

export const actionSetTwoFactorAuthValidationErrors = (errors) => {
    return {
        type: LOGIN_SET_2FA_VALIDATION_ERRORS,
        two_factor_submission_errors: errors,
    };
};

export const actionSetValidationErrors = (errors) => {
    return {
        type: LOGIN_SET_VALIDATION_ERRORS,
        validationErrors: errors,
    };
};

export const actionHideValidationErrors = (props) => {
    return {
        type: LOGIN_HIDE_VALIDATION_ERRORS,
    };
};

export function actionLoginSuccess() {
    return {
        type: LOGIN_SUCCESS,
    };
}

export function actionRedirectSuccess() {
    return {
        type: LOGIN_REDIRECT_SUCCESS,
    };
}

export function actionLogout() {
    return {
        type: LOGOUT,
        targetUrl: '/' + getNetworkLoginCookie(),
    };
}

export function actionAuthorisationTokenFailed(networkError) {
    return {
        type: AUTHORISATION_TOKEN_FAILED,
        networkError,
    };
}

//Reducer
const initialState = {
    authenticated: false,
    validationErrors: [],
    visible: false,
    gotoPath: null,
    user: {},
    code: '',
    two_factor_submission_errors: {},
};

const reducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case LOGIN_SET_2FA_VALIDATION_ERRORS:
            return {
                ...state,
                two_factor_submission_errors: action.two_factor_submission_errors,
            };

        case SHOW_2FA_FORM:
            return {
                ...state,
                user: {
                    username: action.username,
                    password: action.password,
                },
            };

        case SUBMIT_2FA_FORM:
            return {
                ...state,
                two_factor_submission_errors: {},
            };

        case LOGIN_SET_GOTO:
            return {
                ...state,
                gotoPath: action.gotoPath,
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                authenticated: true,
                user: {},
                code: '',
            };

        case LOGIN_REDIRECT_SUCCESS:
            return {
                ...state,
                gotoPath: null,
            };

        case LOGOUT:
            return {
                ...state,
                authenticated: false,
            };

        case LOGIN_SHOW_VALIDATION_ERRORS:
            return {
                ...state,
                visible: true,
            };

        case LOGIN_SET_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: action.validationErrors,
                visible: true,
            };

        case LOGIN_HIDE_VALIDATION_ERRORS:
            return {
                ...state,
                visible: false,
            };

        default:
            return { ...state };
    }
};

export default reducer;

//Selectors
export const selectorGetTwoFactorAuthValidationErrors = (state) => {
    return state[REDUCER_NAMESPACE].two_factor_submission_errors;
};
export const selectorGetGotoPath = (state) => {
    return state[REDUCER_NAMESPACE].gotoPath;
};
export const selectorGetTwoFactorAuthUser = (state) => {
    return state[REDUCER_NAMESPACE].user;
};
export const selectorGetValidationErrors = (state) => {
    return state[REDUCER_NAMESPACE].validationErrors;
};

export const selectorAreErrorsVisible = (state) => {
    return state[REDUCER_NAMESPACE].visible;
};
