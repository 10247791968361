import { BORROWER_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const setLLETerms = (uri = BORROWER_URL, lenderLoanEnquiry, lenderTerms) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation updateLenderTerms($lenderLoanEnquiry: ID!, $lenderTerms: String) {
          updateLenderTerms(lenderLoanEnquiry: $lenderLoanEnquiry, lenderTerms: $lenderTerms) {
            success
          }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            lenderLoanEnquiry: lenderLoanEnquiry,
            lenderTerms: JSON.stringify(lenderTerms),
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
