import React from 'react';
import { Button } from '@material-ui/core';

export default function NoApplicationModal(props) {
    const handleOnClick = () => {
        const {
            lender,
            toggleLenderDrawer,
            setModalClose,
            handleFavouriteMatchChange,
            handleRemoveAlertMatchAlert,
            updateLLEStatus,
        } = props;
        setModalClose();
        toggleLenderDrawer(true, lender, handleFavouriteMatchChange, handleRemoveAlertMatchAlert, updateLLEStatus)();
    };

    return (
        <div className={'no-application-modal'}>
            <div className={'header'}>You have not yet applied to any lenders...</div>
            <div className={'text'}>
                <span className={'bold'}>
                    Look at your matches and start applying to lenders as they send you details on terms they can offer.
                </span>
                You are free to talk to and apply to as many of your Matched Lenders as you want.
            </div>
            <div className={'actions'}>
                <Button color={'primary'} variant={'contained'} size={'large'} onClick={handleOnClick}>
                    See First Lender
                </Button>
            </div>
        </div>
    );
}
