import React from 'react';
import { TableBody as MTableBody } from '@material-ui/core';

class TableBody extends React.Component {
    render() {
        let { children, ...other } = this.props;

        return <MTableBody {...other}>{children}</MTableBody>;
    }
}

export default TableBody;
