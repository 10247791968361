import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { getBorrowerApplicants } from '../../../api/get_applicants_borrower';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import NewApplicantDialog from './NewApplicantDialog';
import NewMainApplicantDialog from './NewMainApplicantDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_INTERMEDIARY, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { getBrokerApplicants } from '../../../api/get_applicants_broker';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Paper from '@material-ui/core/Paper';

export default function ApplicantContainer(props) {
    const { deleteFormSubApplicant } = props;
    const [applicantsData, setApplicantsData] = useState([]);
    const [applicantsLoaded, setApplicantsLoaded] = useState(false);
    const [formData, setFormData] = useState(props.formData);
    const [newApplicantModalOpen, setNewApplicantModalOpen] = useState(false);
    const [newMainApplicantModalOpen, setNewMainApplicantModalOpen] = useState(false);
    const [subApplicants, setSubApplicants] = useState(props.formData.applicants.sub);
    const getUserApplicants = () => {
        let sdm = new SessionManager();
        let data = [];
        let sessionData;
        let isAssumed = false;
        if (sdm.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sdm.hasAssumedToken() === true) {
            isAssumed = true;
            sdm = sdm.getAssumedSession();
            sessionData = sdm;
        } else {
            sessionData = sdm.getSession();
        }
        if (sdm.getMostSeniorRole().name === ROLE_INTERMEDIARY) {
            data = getBrokerApplicants();
        }
        if (sdm.getMostSeniorRole().name === ROLE_BORROWER) {
            data = getBorrowerApplicants();
        }
        if (isAssumed === false) {
            sessionData = sdm.getSession();
        }

        data.then((resp) => {
            const applicantsData = resp.applicants.edges;
            let applicants = [];

            applicantsData.map(function (applicant, index) {
                let node = applicant.node;
                let labelText =
                    node.given + ' ' + node.family + ' (' + moment(node.dateOfBirth).format('MMM D, YYYY') + ')';
                applicants = [
                    ...applicants,
                    {
                        index: index,
                        id: node.id,
                        family: node.family,
                        given: node.given,
                        date_of_birth: node.dateOfBirth,
                        label: labelText,
                    },
                ];
                return applicants;
            });

            if (
                sdm.getMostSeniorRole().name === ROLE_BORROWER &&
                applicantsData.length === 0 &&
                null === formData.applicants.main
            ) {
                let labelText =
                    sessionData.getGiven() +
                    ' ' +
                    sessionData.getFamily() +
                    ' (' +
                    moment(sessionData.getDateOfBirth()).format('MMM D, YYYY') +
                    ')';
                applicants = [
                    {
                        index: 0,
                        id: null,
                        family: sessionData.getFamily(),
                        given: sessionData.getGiven(),
                        date_of_birth: sessionData.getDateOfBirth(),
                        label: labelText,
                    },
                ];
                addMainApplicant(applicants[0]);
            }

            setApplicantsData(applicants);
            setFormData(formData);
            setApplicantsLoaded(true);

            return applicants;
        });

        return [];
    };

    const addApplicantDropdown = () => {
        setNewApplicantModalOpen(true);
    };

    const addMainApplicantDropdown = () => {
        setNewMainApplicantModalOpen(true);
    };

    const addSubApplicant = (newSubApplicant) => {
        const sas = subApplicants;
        let applicants = applicantsData;
        sas.push(newSubApplicant);
        applicants = [
            ...applicants,
            {
                given: newSubApplicant.given,
                family: newSubApplicant.family,
                date_of_birth: newSubApplicant.date_of_birth,
                label: newSubApplicant.label,
            },
        ];
        setApplicantsData(applicants);
        setSubApplicants(sas);
    };

    const addMainApplicant = (mainApplicant) => {
        let applicants = applicantsData;
        formData.applicants.main = {
            given: mainApplicant.given,
            family: mainApplicant.family,
            date_of_birth: mainApplicant.date_of_birth,
            label: mainApplicant.label,
        };
        applicants = [
            ...applicants,
            {
                given: mainApplicant.given,
                family: mainApplicant.family,
                date_of_birth: mainApplicant.date_of_birth,
                label: mainApplicant.label,
            },
        ];
        setApplicantsData(applicants);
        setFormData(formData);
    };

    const addNewMainApplicantComponent = (options) => {
        const { containerProps, children } = options;

        return (
            <Paper {...containerProps}>
                {children}
                <Button
                    fullWidth
                    color="primary"
                    startIcon={<AddCircleOutlineIcon />}
                    onMouseDown={(event) => {
                        event.preventDefault();
                        addMainApplicantDropdown();
                    }}
                >
                    <Typography>Add new applicant</Typography>
                </Button>
            </Paper>
        );
    };

    const deleteSubApplicant = (index) => {
        const su = subApplicants.filter((item, i) => i !== index);
        setSubApplicants(su);
        deleteFormSubApplicant(index);
    };

    useEffect(() => {
        getUserApplicants();
    }, []);

    let sessionManager = new SessionManager();
    if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
        sessionManager = sessionManager.getAssumedSession();
    }
    const userRole = sessionManager.getMostSeniorRole().name;
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography variant="h3" component="h3">
                        Who's applying?
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <p>
                        {userRole === ROLE_BORROWER
                            ? 'To find the best match for your finance requirements we will need a few details about yourself and what you’re looking for.'
                            : 'To find the best match for your clients finance requirements we will need a few details about them and what they’re looking for.'}
                    </p>
                </Grid>
                <Grid item xs={12} md={12} className={'spacer'}></Grid>
                <Grid item xs={12} md={12}>
                    <p>Main Applicant</p>
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6} className={'mainApplicantInput'}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: applicantsLoaded && applicantsData.length === 0 ? 'unset' : 'space-between',
                        }}
                    >
                        <Autocomplete
                            id="main-applicant"
                            options={applicantsData}
                            openOnFocus={true}
                            getOptionLabel={(option) => option.label}
                            style={{ width: '100%' }}
                            PaperComponent={addNewMainApplicantComponent}
                            renderInput={(params) => (
                                <TextField {...params} label={'Select the main applicant'} variant="outlined" />
                            )}
                            value={props.formData.applicants.main}
                            onChange={(event, data) => props.setMainApplicant(data)}
                        />
                    </div>
                    {props.validation.applicants_error.main !== '' ? (
                        <p className={'typography-field-error'}>{props.validation.applicants_error.main}</p>
                    ) : (
                        ''
                    )}
                </Grid>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                    <div className={'applicantButtons'}>
                        <Button
                            variant="contained"
                            onClick={() => {
                                addApplicantDropdown();
                            }}
                            color="primary"
                        >
                            Add sub applicant
                        </Button>
                    </div>
                </Grid>
                {subApplicants.length > 0 && (
                    <>
                        <Grid item xs={12} md={12}>
                            Sub Applicants
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {subApplicants.map((subApplicant, index) => (
                                <Grid
                                    container
                                    key={index}
                                    style={{
                                        justifyContent: 'space-between',
                                        border: '1px solid',
                                        padding: 12,
                                        borderRadius: 20,
                                        borderColor: 'rgb(197 197 197)',
                                        marginBottom: 5,
                                    }}
                                >
                                    <Grid item xs={10} md={5}>
                                        {subApplicant.label}
                                    </Grid>
                                    {/*<Grid item xs={2} md={6}>*/}
                                    <DeleteIcon cursor={'pointer'} onClick={() => deleteSubApplicant(index)} />
                                    {/*</Grid>*/}
                                </Grid>
                            ))}
                        </Grid>
                    </>
                )}
            </Grid>
            <NewApplicantDialog
                newApplicantModalOpen={newApplicantModalOpen}
                setNewApplicantModalOpen={setNewApplicantModalOpen}
                applicantsData={applicantsData}
                addSubApplicant={addSubApplicant}
                addMainApplicant={addMainApplicant}
            />
            <NewMainApplicantDialog
                newApplicantModalOpen={newMainApplicantModalOpen}
                setNewApplicantModalOpen={setNewMainApplicantModalOpen}
                applicantsData={applicantsData}
                addMainApplicant={addMainApplicant}
            />
        </>
    );
}
