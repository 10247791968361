import { NO, YES } from './YesNo';

class SecuredUnsecuredFinance {
    getTypes = () => {
        return [
            { value: YES, label: 'Secured' },
            { value: NO, label: 'Unsecured' },
        ];
    };
    getOptions = () => {
        return {
            [YES]: { value: YES, label: 'Secured' },
            [NO]: { value: NO, label: 'Unsecured' },
        };
    };
}

export default SecuredUnsecuredFinance;
