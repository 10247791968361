import React from 'react';
import Notice from '../../../pitch4_layout/components/content/Notice';
import { BRAND_MAIN_CONTACT } from '../../../bootstrap/constants/constants';
import ErrorIcon from '@material-ui/icons/Error';

export default function AdditionalInformationHelperBox(props) {
    return (
        <div className={'business-stream-telephone-box-wrapper'}>
            <div className={'business-stream-telephone-box'} id={'business-stream-telephone-box'}>
                <Notice
                    customIcon={<ErrorIcon />}
                    messageHeader={
                        'Completing this extra information step will help your lender find you the best rates.'
                    }
                    message={
                        <span>{`Please call us on ${BRAND_MAIN_CONTACT} to talk to a member of our admin team.`}</span>
                    }
                />
            </div>
        </div>
    );
}
