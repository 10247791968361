import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ASSETS_LOGO_URL, API_URL } from '../../../../../bootstrap/constants/constants';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChatContainer from '../../chat/containers/ChatContainer';
import StatusEnum, { STATUS_ENDED } from '../../../enum/LenderLoanEnquiries/StatusEnum';
import {
    STATUS_ON_HOLD as LE_STATUS_ON_HOLD,
    STATUS_WITHDRAWN as LE_STATUS_WITHDRAWN,
} from '../../enum/LoanEnquiries/StatusEnum';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import DocumentList from '../../administer_lenders_loanenquiries_view/components/DocumentList';
import { Chip, FormControlLabel, Switch } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getLenderLoanEnquiry } from '../api/get_ll_enquiry';
import { removeLenderLoanEnquiryAlert } from '../api/remove_lender_loan_enquiry_alert';
import StarIcon from '../../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../../assets/images/star-gray.svg';
import ChangeLLEStatus from './change-lle-status/ChangeLLEStatus';
import { sagaActionModalHide, sagaActionModalShow } from '../../../../modal/sagas/container';
import { sagaActionNotificationSetError } from '../../../../notifications/sagas/container';
import DrawerTerms from './terms/DrawerTerms';
import moment from 'moment/moment';
import { clearLenderTermsUpdatedAlerts } from '../../../../../pitch4_api/lender_terms_alerts';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div hidden={value !== index} id={`tab-panel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tab-panel-${index}`,
    };
}

const statusEnum = new StatusEnum();

class LenderDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            matches: props.matches,
            matchSelected: null,
            chatChannel: null,
            lleAlertActiveUpdated: false,
            lenderTermsUpdatedAlertActive: false,
        };
    }

    componentDidMount() {
        const { lenderLoanEnquiry } = this.props;
        const matchSelected = this.props.matches.find((match) => match.id === lenderLoanEnquiry.id);

        this.setState({ matchSelected: matchSelected }, () => {
            this.getLLEData();
        });
    }

    updateMatchSelectedTerms = (newTerms) => {
        this.setState({ matchSelected: { ...this.state.matchSelected, lenderTerms: newTerms } });
    };

    buildChat = () => {
        const { matchSelected, chatChannel, lleAlertActiveUpdated } = this.state;
        const isGloballyDisabled = matchSelected.loanEnquiry ? matchSelected.loanEnquiry.status === STATUS_ENDED : true;
        const enquiryWithdrawn = matchSelected.loanEnquiry
            ? matchSelected.loanEnquiry.status === LE_STATUS_WITHDRAWN
            : true;
        const enquiryOnHold = matchSelected.loanEnquiry ? matchSelected.loanEnquiry.status === LE_STATUS_ON_HOLD : true;

        if (chatChannel) {
            if (false === lleAlertActiveUpdated && true === matchSelected.alertActive) {
                removeLenderLoanEnquiryAlert(this.state.matchSelected.id).then(() =>
                    this.setState({
                        matchSelected: { ...this.state.matchSelected, alertActive: false },
                        lleAlertActiveUpdated: true,
                    })
                );
            }

            return (
                <ChatContainer
                    chatLocation={'sidetray'}
                    channelId={chatChannel}
                    disableForm={enquiryWithdrawn || isGloballyDisabled || enquiryOnHold}
                />
            );
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 100 }}>
                <CircularProgress />
            </div>
        );
    };

    buildDocumentList = (lenderLoanEnquiry) => {
        const { session } = this.props;
        const { matchSelected } = this.state;

        const isGloballyDisabled = matchSelected.loanEnquiry ? matchSelected.loanEnquiry.status === STATUS_ENDED : true;
        const enquiryWithdrawn = matchSelected.loanEnquiry
            ? matchSelected.loanEnquiry.status === LE_STATUS_WITHDRAWN
            : true;
        const enquiryOnHold = matchSelected.loanEnquiry ? matchSelected.loanEnquiry.status === LE_STATUS_ON_HOLD : true;

        if (lenderLoanEnquiry.loanEnquiry) {
            return (
                <div className={'document-list'}>
                    <DocumentList
                        containerStyles={{ boxShadow: 'none', width: '100%', padding: '20px 0 0 0' }}
                        docActionsStyles={{ display: 'flex', justifyContent: 'flex-end' }}
                        docTypeStyles={{ fontWeight: 600 }}
                        showIcon={true}
                        disabled={isGloballyDisabled || session.hasAssumedToken() || enquiryOnHold}
                        lenderLoanEnquiry={matchSelected}
                        disableUploadButton={
                            enquiryWithdrawn || isGloballyDisabled || session.hasAssumedToken() || enquiryOnHold
                        }
                    />
                </div>
            );
        }

        return <></>;
    };

    handlePrevious = () => {
        const { matchSelected, matches, chatChannel } = this.state;
        const matchIndex = matches.findIndex((match) => (matchSelected ? match.id === matchSelected.id : null));
        const nextMatchIndex = matchIndex === 0 ? matches.length - 1 : matchIndex - 1;

        if (chatChannel) {
            this.setState({ matchSelected: null, chatChannel: null }, () => {
                this.setState({ matchSelected: matches[nextMatchIndex] }, () => {
                    this.getLLEData();
                });
            });
        }
    };

    handleNext = () => {
        const { matchSelected, matches, chatChannel } = this.state;
        const matchIndex = matches.findIndex((match) => (matchSelected ? match.id === matchSelected.id : null));
        const nextMatchIndex = matchIndex + 1 === matches.length ? 0 : matchIndex + 1;

        if (chatChannel) {
            this.setState({ matchSelected: null, chatChannel: null }, () => {
                this.setState({ matchSelected: matches[nextMatchIndex] }, () => {
                    this.getLLEData();
                });
            });
        }
    };

    getLLEData = () => {
        const { matchSelected } = this.state;
        getLenderLoanEnquiry(matchSelected.id).then((resp) => {
            Object.keys(resp.lenderLoanEnquiry).forEach((k, i) => {
                if (k === 'lenderTerms') {
                    resp.lenderLoanEnquiry[k] = JSON.parse(resp.lenderLoanEnquiry[k]);
                }
            });

            this.setState({
                matchSelected: { ...matchSelected, ...resp.lenderLoanEnquiry },
                chatChannel: resp.lenderLoanEnquiry.channel.id,
                lenderTermsUpdatedAlertActive: resp.lenderLoanEnquiry.lenderTermsUpdatedAlertActive === true,
            });
        });
    };

    buildChatLabel = () => {
        const { matchSelected } = this.state;

        if (matchSelected.alertActive) {
            return (
                <span>
                    Lender Chat <Badge label={'update'} style={{ marginLeft: 5 }} />
                </span>
            );
        }

        return 'Lender Chat';
    };

    buildTermsLabel = () => {
        const { lenderTermsUpdatedAlertActive } = this.state;

        if (lenderTermsUpdatedAlertActive) {
            return (
                <span>
                    Indicative Terms <Badge label={'update'} style={{ marginLeft: 5 }} />
                </span>
            );
        }

        return 'Indicative Terms ';
    };

    handleClearLenderTermsUpdatedAlerts = (leId = null, lleId = null) => {
        if (leId && lleId) {
            clearLenderTermsUpdatedAlerts(leId, lleId, API_URL).then((resp) => {
                this.setState({
                    lenderTermsUpdatedAlertActive: false,
                });
            });
        }
    };

    handleFavChange = () => {
        const { matchSelected, chatChannel } = this.state;
        const { handleFavouriteMatchChange } = this.props;

        if (chatChannel) {
            this.setState(
                {
                    matchSelected: {
                        ...this.state.matchSelected,
                        favourite: !matchSelected.favourite,
                    },
                },
                () => handleFavouriteMatchChange(matchSelected.id, null, matchSelected.favourite)()
            );
        }
    };

    handleMatchSelectedStatusChange = (status) => {
        this.setState(
            {
                matchSelected: {
                    ...this.state.matchSelected,
                    status,
                },
            },
            () => {
                window.location.reload(false);
            }
        );
    };

    handleLenderWaiting = (event, match) => {
        this.props.handleLenderWaiting(event, match); // Update on the parent list

        match.lenderWaiting = event.target.checked ? moment() : null;
        this.setState({ matchSelected: match });
    };

    handleBrokerWaiting = (event, match) => {
        this.props.handleBrokerWaiting(event, match); // Update on the parent list

        match.brokerWaiting = event.target.checked ? moment() : null;
        this.setState({ matchSelected: match });
    };

    handleBorrowerWaiting = (event, match) => {
        this.props.handleBorrowerWaiting(event, match); // Update on the parent list

        match.borrowerWaiting = event.target.checked ? moment() : null;
        this.setState({ matchSelected: match });
    };

    getLenderWaitingLabel = (match) => {
        this.props.getLenderWaitingLabel(match); // Update on the parent list

        if (null !== match.lenderWaiting) {
            return 'Lender Waiting: ' + moment(match.lenderWaiting).format('MMM D, YYYY');
        }

        return '';
    };

    getBrokerWaitingLabel = (match) => {
        this.props.getBrokerWaitingLabel(match); // Update on the parent list

        if (null !== match.brokerWaiting) {
            return 'Broker Waiting: ' + moment(match.brokerWaiting).format('MMM D, YYYY');
        }

        return '';
    };

    getBorrowerWaitingLabel = (match) => {
        this.props.getBorrowerWaitingLabel(match); // Update on the parent list

        if (null !== match.borrowerWaiting) {
            return 'Borrower Waiting: ' + moment(match.borrowerWaiting).format('MMM D, YYYY');
        }

        return '';
    };

    render() {
        const {
            anchor,
            open,
            toggleLenderDrawer,
            setModalComponent,
            setModalClose,
            notificationError,
            updateLenderTermsInGrid,
        } = this.props;
        const { matchSelected, matches, chatChannel } = this.state;
        const matchIndex = matches.findIndex((match) => (matchSelected ? match.id === matchSelected.id : null));

        const handleTabChange = (event, newValue) => {
            this.setState({ tabValue: newValue });
            // Clear alert badges
            if (newValue === 1) {
                this.handleClearLenderTermsUpdatedAlerts(matchSelected.loanEnquiry.id, matchSelected.id);
            }
        };

        const arrowsStyles = chatChannel
            ? {
                  cursor: 'pointer',
              }
            : {
                  color: '#C7C7C7',
              };

        let learnMoreStyle = {};

        if (matchSelected && matchSelected.lenderProfile.profilePrimaryColor) {
            learnMoreStyle = { color: matchSelected.lenderProfile.profilePrimaryColor };
        }

        return (
            <Drawer variant={'temporary'} anchor={anchor} open={open} className={'lender-profile-modal'}>
                {matchSelected && (
                    <>
                        <div className={'lender-drawer-profile-icons'}>
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
                                {matchIndex + 1} of {matches.length}
                                <ArrowLeftIcon
                                    style={arrowsStyles}
                                    fontSize={'large'}
                                    onClick={() => this.handlePrevious()}
                                />
                                <ArrowRightIcon
                                    style={arrowsStyles}
                                    fontSize={'large'}
                                    onClick={() => this.handleNext()}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    src={true === matchSelected.favourite ? StarIcon : StarGrayIcon}
                                    alt="Favourite"
                                    className={'favourite'}
                                    onClick={() => this.handleFavChange()}
                                />
                                <Chip
                                    style={{ marginRight: 55 }}
                                    className={`match-status ${matchSelected.status}`}
                                    label={statusEnum.getType(matchSelected.status, matchSelected.leType)}
                                />
                                <IconButton className={`close-button`} onClick={toggleLenderDrawer(false)}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className={'lender-profile-image'}>
                            {matchSelected.lenderProfile.profileLogo ? (
                                <div>
                                    <img
                                        alt={matchSelected.lenderProfile.name + ' Logo'}
                                        src={ASSETS_LOGO_URL.replace('{{lenderGuid}}', matchSelected.lenderProfile.id)}
                                        className={'lender-profile-logo'}
                                    />
                                </div>
                            ) : (
                                <div className={'lender-profile-title'}>
                                    <h5 className={'typography-page-title'}>
                                        {matchSelected.lenderProfile.name ?? 'N/A'}
                                    </h5>
                                </div>
                            )}
                            <div className={`lender-draw-details`}>
                                <span className={'body-1'}>Lender Email:</span>
                                <span className={`body-1`} style={{ marginLeft: 5 }}>
                                    {matchSelected.lenderProfile.primaryEmail ?? 'N/A'}
                                </span>
                            </div>
                            <div className={`lender-draw-details`}>
                                <span className={'body-1'}>Lender Phone:</span>
                                <span className={`body-1`} style={{ marginLeft: 5 }}>
                                    {matchSelected.lenderProfile.primaryPhone.number.length === 0
                                        ? 'N/A'
                                        : matchSelected.lenderProfile.primaryPhone.number}
                                </span>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                paddingRight: 30,
                                border: '1px solid',
                                margin: '20px 30px 0 30px',
                                padding: '0 30px 30px 0',
                                borderRadius: '20px',
                            }}
                        >
                            <ChangeLLEStatus
                                matchId={matchSelected.id}
                                setModalComponent={setModalComponent}
                                setModalClose={setModalClose}
                                currentStatus={matchSelected.status}
                                handleMatchSelectedStatusChange={this.handleMatchSelectedStatusChange}
                                notificationError={notificationError}
                                leType={matchSelected.leType}
                            />
                            {matchSelected.lenderProfile.offPanel && (
                                <div style={{ margin: '30px 0 0 0', display: 'flex', alignItems: 'center' }}>
                                    <span
                                        style={{
                                            backgroundColor: '#d9d9d9',
                                            padding: 15,
                                            borderRadius: 10,
                                            color: '#ff0090',
                                        }}
                                    >
                                        Off-panel lender
                                    </span>
                                </div>
                            )}
                            {matchSelected.lenderWaiting !== null && (
                                <div
                                    style={{
                                        padding: '0px 0px 0px 20px',
                                        backgroundColor: '#d9d9d9',
                                        borderRadius: 10,
                                        margin: '30px 0px 0px 0px',
                                        display: 'flex',
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={true}
                                                onChange={(event) => this.handleLenderWaiting(event, matchSelected)}
                                                color="primary"
                                                name="lenderWaiting"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                disabled={false}
                                                id={matchSelected.id}
                                            />
                                        }
                                        label={this.getLenderWaitingLabel(matchSelected)}
                                    />
                                </div>
                            )}
                            {matchSelected.brokerWaiting !== null && (
                                <div
                                    style={{
                                        padding: '0px 0px 0px 20px',
                                        backgroundColor: '#d9d9d9',
                                        borderRadius: 10,
                                        margin: '30px 0px 0px 0px',
                                        display: 'flex',
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={true}
                                                onChange={(event) => this.handleBrokerWaiting(event, matchSelected)}
                                                color="primary"
                                                name="brokerWaiting"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                disabled={false}
                                                id={matchSelected.id}
                                            />
                                        }
                                        label={this.getBrokerWaitingLabel(matchSelected)}
                                    />
                                </div>
                            )}
                            {matchSelected.borrowerWaiting !== null && (
                                <div
                                    style={{
                                        padding: '0px 0px 0px 20px',
                                        backgroundColor: '#d9d9d9',
                                        borderRadius: 10,
                                        margin: '30px 0px 0px 0px',
                                        display: 'flex',
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={true}
                                                onChange={(event) => this.handleBorrowerWaiting(event, matchSelected)}
                                                color="primary"
                                                name="brokerWaiting"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                disabled={false}
                                                id={matchSelected.id}
                                            />
                                        }
                                        label={this.getBorrowerWaitingLabel(matchSelected)}
                                    />
                                </div>
                            )}
                        </div>
                        <div style={{ paddingTop: 20, backgroundColor: 'white' }}>
                            <hr className={'tabs-indicator-top-line'} />
                            <Tabs
                                value={this.state.tabValue}
                                onChange={handleTabChange}
                                TabIndicatorProps={{
                                    children: (
                                        <div className={'tab-indicator-container'}>
                                            <div className={'tab-active-indicator'} />
                                        </div>
                                    ),
                                }}
                            >
                                {!matchSelected.lenderProfile.offPanel && (
                                    <Tab label={this.buildChatLabel()} {...a11yProps(0)} className={'tab'} />
                                )}
                                <Tab
                                    label={this.buildTermsLabel()}
                                    {...a11yProps(matchSelected.lenderProfile.offPanel ? 0 : 1)}
                                    className={'tab'}
                                />
                                <Tab
                                    label={'About'}
                                    {...a11yProps(matchSelected.lenderProfile.offPanel ? 1 : 2)}
                                    className={'tab'}
                                />
                                <Tab
                                    label={'Documents'}
                                    {...a11yProps(matchSelected.lenderProfile.offPanel ? 2 : 3)}
                                    className={'tab'}
                                />
                            </Tabs>
                            {!matchSelected.lenderProfile.offPanel && (
                                <TabPanel value={this.state.tabValue} index={0}>
                                    {this.buildChat()}
                                </TabPanel>
                            )}
                            <TabPanel value={this.state.tabValue} index={matchSelected.lenderProfile.offPanel ? 0 : 1}>
                                <DrawerTerms
                                    lle={matchSelected}
                                    updateLenderTermsInGrid={updateLenderTermsInGrid}
                                    updateMatchSelectedTerms={this.updateMatchSelectedTerms}
                                />
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={matchSelected.lenderProfile.offPanel ? 1 : 2}>
                                <p className={'lender-profile-description'}>
                                    {!matchSelected.lenderProfile.profileDescription ||
                                    matchSelected.lenderProfile.profileDescription.length === 0
                                        ? 'Details on this lender are coming soon.'
                                        : matchSelected.lenderProfile.profileDescription}
                                </p>
                                {matchSelected.lenderProfile.profileUrl && (
                                    <p className={'lender-profile-link'}>
                                        <a
                                            href={matchSelected.lenderProfile.profileUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            style={learnMoreStyle}
                                        >
                                            Learn more
                                        </a>{' '}
                                        about {matchSelected.lenderProfile.name}.
                                    </p>
                                )}
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={matchSelected.lenderProfile.offPanel ? 2 : 3}>
                                {this.buildDocumentList(matchSelected)}
                            </TabPanel>
                        </div>
                    </>
                )}
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => dispatch(sagaActionModalShow(component, interactive)),
        setModalClose: () => dispatch(sagaActionModalHide()),
        notificationError: (message) => dispatch(sagaActionNotificationSetError(message)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(LenderDrawer);
