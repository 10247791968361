import { SLAM_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: SLAM_URL });

const GetLeadsQuery = `
    query getLenderLoanEnquiries(
                $cursor: String
                $first: Int!
                $sorts: [SortInput]
                $filters: [FilterInput]
                $storedDataFilters: [String]
                $page: Int
            ) {
                lenderLoanEnquiries(
                    first: $first
                    after: $cursor
                    sorts: $sorts
                    filters: $filters
                    storedDataFilters: $storedDataFilters
                    page: $page
                ) {
                    edges {
                        node {
                            id
                            lenderReference
                            status
                            createdAt
                            updatedAt
                            matchedAt
                            alertActive    
                            lenderTermsUpdatedAlertActive
                            favourite
                            businessStreams {
                                id
                                name
                                businessStreamType
                            }
                            loanEnquiry {
                                status
                                isNewForm
                                loanEnquiryData {
                                    loanToValue
                                    loanToCost
                                    loanToGrossDevelopmentValue
                                    dayOneLoanAmountPercentage
                                    additionalInformation
                                    rentalIncomeResidential
                                    hmoBedroom
                                    hmoRoomsSelfContained
                                    hmoHasLicense
                                    hmoNeedsLicense
                                    buyToLetExperience
                                    rentalIncomeCommercial
                                    rentalIncomePortfolio
                                    rentalIncome
                                    commercialUnitsRentedOut
                                    commercialUnitOwnerOccupied
                                    commercialUnitAmount
                                    commercialType
                                    commercialTypeOther
                                    siteDescription
                                    siteIntention
                                    refinance
                                    businessSeasonal
                                    businessType
                                    businessName
                                    businessWebsite
                                    businessTradingLength
                                    businessSector
                                    businessFacilityNeededBy
                                    businessFacilityTerm
                                    businessCurrentPaymentTerms
                                    businessOutstandingInvoices
                                    businessPdqIncome
                                    propertyType
                                    loanAmount
                                    loanPurpose
                                    turnOver
                                    businessProfitable
                                    businessOverdraftFacility
                                    businessOutstandingDebt
                                    businessAdverseCredit
                                    businessAdverseCreditExplanation
                                    businessDirectorsAdverseCredit
                                    businessDirectorsAdverseCreditExplanation
                                    shareholdersAreHomeOwners
                                    propertyAvailableForAdditionalSecurity
                                    planningFor
                                    developmentLength
                                    refurbishmentLength
                                    outstandingLoan
                                    dayOneLoanAmount
                                    unitsPreSold
                                    worksExplanation
                                    totalDevelopmentCosts
                                    buildCostsBorrowing
                                    expectedGrossDevelopmentValue
                                    previousDevelopmentExperience
                                    previousRefurbishmentExperience
                                    teamDevelopmentExperience
                                    epcRating
                                    loanType
                                    fundingType
                                    businessSecuredFinance
                                    loanUsedInUkEu
                                    planningInPlace
                                    endValue
                                    percentageResidential
                                    percentageCommercial
                                    lender
                                    firstChargeAmount
                                    currentValue
                                    properties
                                    portfolioValue
                                    portfolioAlreadyOwned
                                    loanTerm
                                    loanNeededBy
                                    preApproveMatches
                                    loanAmountRefurbishment
                                    percentageResidentialSquareFeet
                                    percentageResidentialValue
                                    percentageCommercialValue
                                    residentialUnitCount
                                    residentialUnitValue
                                    businessOccupyEntireProperty
                                    commercialUnitRentBreakdown
                                    commercialTenantAmount
                                    ownsOtherCommercialProperty
                                    ownOtherProperties
                                    occupyProperty
                                    ownsOtherInvestmentProperties
                                    familyHasOccupied
                                    secondCharge
                                    occupyingGreaterThan40Percent
                                    location
                                    locationOther
                                    address
                                    postcode
                                    propertyOwnership
                                    refurbishmentCost
                                    invoiceFinanceType
                                    leaseHirePurchase
                                    mainApplicantCreditDifficulties
                                    mainApplicantCreditDifficultiesExplanation
                                    mainApplicantCitizenshipStatus
                                    mainApplicantCountryOfResidence
                                    mainApplicantCountryOfResidenceOther
                                    mainApplicantNationality
                                    mainApplicantNationalityOther
                                    mainApplicantOccupation
                                    mainApplicantGrossIncome
                                    mainApplicantExitStrategy
                                    agreeToTerms
                                }
                            }
                            loanEnquiryApplicants {
                                isMainApplicant
                                applicant {
                                    given
                                    family
                                    dateOfBirth
                                }
                            }                            
                            storedData {
                                meta {
                                    key
                                }
                                value
                            }
                            submissionData {
                                rawSubmission
                            }
                            assignees {
                              id
                              userProfile {
                                given
                                family
                              }
                            }
                        }
                        cursor
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                        startCursor
                        hasPreviousPage
                    }
                    totalCount
                }
            }
`;

export const getLeads = (cursor, filters, first, sorts, storedDataFilters, page) => {
    return apolloFetch({
        query: GetLeadsQuery,
        variables: {
            cursor: cursor,
            filters: filters,
            first: first,
            sorts: sorts,
            storedDataFilters: storedDataFilters,
            page: page,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
