import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { QUESTION_PRE_APPROVE } from '../../../enums/Borrower/BorrowerQuestions';
import Question from '../../Question/containers/Question';
import YesNo from '../../../enums/Shared/YesNo';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BorrowerQuestions from '../../../enums/Borrower/BorrowerQuestions';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';

class PreApprovalContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const yesNoOptions = new YesNo().getTypes();
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        Do you want to pre-approve Lenders?
                    </Typography>
                </Grid>
                <Question
                    type={questions[QUESTION_PRE_APPROVE].type}
                    label={questions[QUESTION_PRE_APPROVE].label}
                    questionEnum={'QUESTION_PRE_APPROVE'}
                    fieldName={questions[QUESTION_PRE_APPROVE].fieldName}
                    placeholder={questions[QUESTION_PRE_APPROVE].placeholder}
                    validation={questions[QUESTION_PRE_APPROVE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
            </Grid>
        );
    }
}

export default PreApprovalContainer;
