import React from 'react';
import { getTersByType } from '../../../../pitch4_elements/terms/Utils';
import CardViewItem from './CardViewItem';

export default function CardView(props) {
    const {
        handleLenderSelection,
        selectedLenders,
        lendersMessageAllowed,
        toggleLenderDrawer,
        handleFavouriteMatchChange,
        handleRemoveAlertMatchAlert,
        data,
        handleSelectAll,
        updateLLEStatus,
    } = props;

    return (
        <div className={'card-view-container'}>
            {data.map((lender, index) => {
                const interestRateTerm = lender.terms ? getTersByType(lender.terms, 'mainInterestRate')[0] : null;
                return (
                    <CardViewItem
                        index={index}
                        lender={lender}
                        selectedLenders={selectedLenders}
                        toggleLenderDrawer={toggleLenderDrawer}
                        handleFavouriteMatchChange={handleFavouriteMatchChange}
                        handleRemoveAlertMatchAlert={handleRemoveAlertMatchAlert}
                        interestRateTerm={interestRateTerm}
                        lendersMessageAllowed={lendersMessageAllowed}
                        handleLenderSelection={handleLenderSelection}
                        dataLength={data.length}
                        handleSelectAll={handleSelectAll}
                        updateLLEStatus={updateLLEStatus}
                    />
                );
            })}
        </div>
    );
}
