import React from 'react';
import PropTypes from 'prop-types';
import RowItem from 'pitch4_layout/components/div/RowItem';
import { FieldRowInputOnly, TextField } from 'pitch4_forms/components/inputs';
import { hasMaxLength255, hasMinLength2, isRequired } from 'pitchblack_react_utils/form/validation';
import BusinessStreamSummaryTable from './BusinessStreamSummaryTable';
import Notice from '../../../../../pitch4_layout/components/content/Notice';

export class SubmitSummary extends React.Component {
    render() {
        const { submissionData, headings, textMainHeading, textSubtitle, textRoundup, submitting } = this.props;

        return (
            <div className={`business-stream-submit-summary`}>
                <h4 className={`submit-summary-heading`}>{textMainHeading}</h4>
                <div className={`submit-summary-subtitle`}>
                    <Notice message={textSubtitle} customIconColor={'#2ec4b6'} />
                </div>
                <RowItem />
                <BusinessStreamSummaryTable formSections={submissionData} headings={headings} />
                <RowItem />
                <div className={`submit-summary-section-name`}>
                    <h2>Stream Name</h2>
                    <span className={`submit-summary-roundup`}>{textRoundup}</span>
                    <FieldRowInputOnly
                        input={
                            <TextField
                                placeholder={`Stream name`}
                                name={`businessStream`}
                                fullWidth={true}
                                disabled={submitting}
                                validate={[isRequired, hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                </div>
            </div>
        );
    }
}

SubmitSummary.propTypes = {
    submissionData: PropTypes.object.isRequired,
    headings: PropTypes.object.isRequired,
};
