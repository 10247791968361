export const NAMESPACE = 'user.two_factor_auth';

export const GET_2FA_DETAILS = `${NAMESPACE}.get_two_factor_auth_details`;
export const SET_2FA_DETAILS = `${NAMESPACE}.set_two_factor_auth_details`;
export const ENABLE_2FA = `${NAMESPACE}.enable_two_factor_auth`;
export const SET_ENABLE_2FA_ERRORS = `${NAMESPACE}.set_enable_two_factor_auth_errors`;
export const SET_2FA_ACCESS_DENIED = `${NAMESPACE}.set_access_denied`;

export const actionGetTwoFactorAuthDetails = (redirectOnFailure) => ({
    type: GET_2FA_DETAILS,
    redirectOnFailure,
});

export const actionSetTwoFactorAuthAccessDenied = () => ({
    type: SET_2FA_ACCESS_DENIED,
});

export const actionSetTwoFactorAuthDetails = (twoFactorAuthDetails) => ({
    type: SET_2FA_DETAILS,
    twoFactorAuthDetails,
});

export const actionEnableTwoFactorAuth = (code) => ({
    type: ENABLE_2FA,
    code,
});

export const actionSetEnableTwoFactorAuthErrors = (errors) => {
    return {
        type: SET_ENABLE_2FA_ERRORS,
        errors,
    };
};

const initialState = {
    twoFactorAuthDetails: {},
    errors: {},
    accessDenied: false,
    redirectOnFailure: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_2FA_DETAILS:
            return {
                ...state,
                redirectOnFailure: action.redirectOnFailure,
            };

        case SET_2FA_DETAILS:
            const twoFactorAuthDetails = action.twoFactorAuthDetails;

            return {
                ...state,
                twoFactorAuthDetails,
            };

        case SET_ENABLE_2FA_ERRORS:
            return {
                ...state,
                errors: action.errors,
            };

        default:
            return {
                ...state,
            };
    }
};

export const selectorGetRedirectOnFailure = (state) => {
    return state[NAMESPACE].redirectOnFailure;
};

export const selectorGetTwoFactorAuthDetails = (state) => {
    return state[NAMESPACE].twoFactorAuthDetails;
};

export const selectorGetTwoFactorAuthFormErrors = (state) => {
    return state[NAMESPACE].errors;
};

//Default export reducer
export default {
    [NAMESPACE]: reducer,
};
