import CreateDocumentManagerFactory from './src/CreateDocumentManagerFactory';
import ViewDocumentManagerFactory from './src/ViewDocumentManagerFactory';
import InputFile from './src/components/InputFile';
import AddForm from './src/components/AddForm/AddForm';
import AddMapper from './src/form/AddMapper';
import AddButton from './src/components/AddButton';
import Document from './src/components/Document';
import DownloadButton from './src/components/DownloadButton';
import EditFileNameInput from './src/components/EditFileNameInput';
import ConfirmDeletion from './src/components/ConfirmDeletion';
import DocumentInfo from './src/components/DocumentInfo';
import Explorer from './src/components/Explorer';
import EditForm from './src/components/EditForm';
import EditMapper from './src/form/EditMapper';

export {
    CreateDocumentManagerFactory,
    ViewDocumentManagerFactory,
    InputFile,
    AddForm,
    AddMapper,
    AddButton,
    Document,
    DownloadButton,
    EditFileNameInput,
    ConfirmDeletion,
    DocumentInfo,
    Explorer,
    EditForm,
    EditMapper,
};
