import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IntermediaryRegistrationForm from '../components/IntermediaryRegistrationForm';
import {
    selectorAreErrorsVisible,
    selectorIntermediaryRegisterSubmissionErrors,
    selectorIntermediaryRegisterSubmitted,
    selectorDuplicateEmailError,
    actionIntermediaryRegisterSubmitted,
} from '../redux/intermediary';
import { sagaActionRedirectToDashboard } from '../../authentication/sagas/dashboard';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { selectorGetAcceptedTermsVersion } from '../../terms_and_conditions/redux';
import { DEFAULT_TERMS_VERSION } from '../../../pitch4_enum/enum/DefaultEnum';
import Cookies from 'js-cookie';
export const INTERMEDIARY_REGISTRATION_RECAPTCHA_ACTION = 'intermediary_registration';

class RegistrationContainer extends React.Component {
    async componentDidMount() {
        const { redirectToDashboard } = this.props;

        const sessionManager = new SessionManager();

        if (sessionManager.isAuthenticated()) {
            redirectToDashboard();
        }
    }

    onSubmit = async (values) => {
        if (!this.props.googleReCaptchaProps.executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
        }

        const recaptchaToken = this.props.googleReCaptchaProps.executeRecaptcha
            ? await this.props.googleReCaptchaProps.executeRecaptcha(INTERMEDIARY_REGISTRATION_RECAPTCHA_ACTION)
            : null;

        if (recaptchaToken === null) {
            console.warn('Recaptcha token is null!');
        }

        const { submit, acceptedTermsVersion } = this.props;
        values.hubspotCookie = Cookies.get('hubspotutk');
        values.pageUri = window.location.href;
        values.pageName = document.title;
        values.ppcPage = document.referrer;
        if ('prod' === window._env_.APP_ENV) {
            window.dataLayer.push({
                event: 'user_registration',
                userDetails: {
                    email: values.email,
                    userType: 'Broker',
                    timestamp: new Date().toISOString(),
                },
                ...values,
                password: '',
            });
        }
        submit(values, acceptedTermsVersion || DEFAULT_TERMS_VERSION, recaptchaToken);
    };

    render() {
        return <React.Fragment>{this.renderForm()}</React.Fragment>;
    }

    renderForm = () => {
        const { submitErrors, errorsVisible, submitted } = this.props;
        return (
            <>
                <IntermediaryRegistrationForm
                    onSubmit={this.onSubmit}
                    errors={submitErrors}
                    errorsVisible={errorsVisible}
                    submitted={submitted}
                />
            </>
        );
    };
}

RegistrationContainer.propTypes = {
    redirectToDashboard: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    submitErrors: PropTypes.array.isRequired,
    submitted: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        submitted: selectorIntermediaryRegisterSubmitted(state),
        duplicateEmailError: selectorDuplicateEmailError(state),
        submitErrors: selectorIntermediaryRegisterSubmissionErrors(state),
        errorsVisible: selectorAreErrorsVisible(state),
        acceptedTermsVersion: selectorGetAcceptedTermsVersion(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submit: (values, acceptedTermsVersion, recaptchaToken) => {
            dispatch(actionIntermediaryRegisterSubmitted(values, acceptedTermsVersion, recaptchaToken));
        },
        redirectToDashboard: () => dispatch(sagaActionRedirectToDashboard()),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withGoogleReCaptcha
)(RegistrationContainer);
