import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BorrowerDefaultRegistrationForm from '../components/BorrowerDefaultRegistrationForm';
import BorrowerProductRegistrationForm from '../components/BorrowerProductRegistrationForm';
import {
    actionBorrowerRegisterSubmitted,
    selectorAreErrorsVisible,
    selectorBorrowerRegisterSubmissionErrors,
    selectorBorrowerRegisterSubmitted,
    selectorDuplicateEmailError,
} from '../redux/step';
import { sagaActionRedirectToDashboard } from '../../authentication/sagas/dashboard';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { selectorGetAcceptedTermsVersion } from '../../terms_and_conditions/redux';
import { DEFAULT_TERMS_VERSION } from '../../../pitch4_enum/enum/DefaultEnum';
import PrePopulatedBorrowerProductRegistrationForm from '../components/PrePopulatedBorrowerProductRegistrationForm';
import PrePopulatedIntroducerBorrowerRegistrationForm from '../components/PrePopulatedIntroducerBorrowerRegistrationForm';
import Cookies from 'js-cookie';

export const BORROWER_REGISTRATION_RECAPTCHA_ACTION = 'borrower_registration';

class BorrowerRegistrationContainer extends React.Component {
    async componentDidMount() {
        const { redirectToDashboard } = this.props;

        const sessionManager = new SessionManager();

        if (sessionManager.isAuthenticated()) {
            redirectToDashboard();
        }
    }

    onSubmit = async (values) => {
        if (!this.props.googleReCaptchaProps.executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
        }

        const recaptchaToken = this.props.googleReCaptchaProps.executeRecaptcha
            ? await this.props.googleReCaptchaProps.executeRecaptcha(BORROWER_REGISTRATION_RECAPTCHA_ACTION)
            : null;

        if (recaptchaToken === null) {
            console.warn('Recaptcha token is null!');
        }

        const { submit, acceptedTermsVersion } = this.props;
        values.hubspotCookie = Cookies.get('hubspotutk');
        values.pageUri = window.location.href;
        values.pageName = document.title;
        values.ppcPage = document.referrer;
        values.customerSource = this.props.customerSource;
        if ('prod' === window._env_.APP_ENV) {
            window.dataLayer.push({
                event: 'user_registration',
                userDetails: {
                    email: values.email,
                    userType: 'Borrower',
                    timestamp: new Date().toISOString(),
                },
                ...values,
                password: '',
            });
        }
        submit(values, acceptedTermsVersion || DEFAULT_TERMS_VERSION, recaptchaToken);
    };

    sleep = (time) => {
        return new Promise((resolve) => setTimeout(resolve, time));
    };

    render() {
        return <React.Fragment>{this.renderForm()}</React.Fragment>;
    }

    renderForm = () => {
        const { submitErrors, errorsVisible, submitted, formType, productConfig, formValuesType } = this.props;

        switch (formType) {
            case 'product':
                switch (formValuesType) {
                    case 'pp': // PrePopulated form values
                        return (
                            <>
                                <PrePopulatedBorrowerProductRegistrationForm
                                    onSubmit={this.onSubmit}
                                    errors={submitErrors}
                                    errorsVisible={errorsVisible}
                                    submitted={submitted}
                                    stepHeading={'Your details:'}
                                    productConfig={productConfig}
                                />
                            </>
                        );
                    default:
                        return (
                            <>
                                <BorrowerProductRegistrationForm
                                    onSubmit={this.onSubmit}
                                    errors={submitErrors}
                                    errorsVisible={errorsVisible}
                                    submitted={submitted}
                                    stepHeading={'Your details:'}
                                    productConfig={productConfig}
                                />
                            </>
                        );
                }

            case 'default':
                return (
                    <>
                        <BorrowerDefaultRegistrationForm
                            onSubmit={this.onSubmit}
                            errors={submitErrors}
                            errorsVisible={errorsVisible}
                            submitted={submitted}
                            stepHeading={'Your details:'}
                        />
                    </>
                );
            case 'introducer':
                return (
                    <PrePopulatedIntroducerBorrowerRegistrationForm
                        onSubmit={this.onSubmit}
                        errors={submitErrors}
                        errorsVisible={errorsVisible}
                        submitted={submitted}
                        stepHeading={'Your details:'}
                        introducerConfig={productConfig}
                    />
                );
            default:
                return (
                    <>
                        <BorrowerDefaultRegistrationForm
                            onSubmit={this.onSubmit}
                            errors={submitErrors}
                            errorsVisible={errorsVisible}
                            submitted={submitted}
                            stepHeading={'Your details:'}
                        />
                    </>
                );
        }
    };
}

BorrowerRegistrationContainer.propTypes = {
    redirectToDashboard: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    submitErrors: PropTypes.array.isRequired,
    submitted: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    const href = window.location.href;
    const arr = new URL(href);
    let referrer = document.referrer;
    referrer = true === referrer.toLowerCase().includes('giftware') ? 'giftware' : false;

    return {
        submitted: selectorBorrowerRegisterSubmitted(state),
        duplicateEmailError: selectorDuplicateEmailError(state),
        submitErrors: selectorBorrowerRegisterSubmissionErrors(state),
        errorsVisible: selectorAreErrorsVisible(state),
        acceptedTermsVersion: selectorGetAcceptedTermsVersion(state),
        page: !arr.searchParams.get('pg') ? referrer : arr.searchParams.get('pg'),
        customerSource: !arr.searchParams.get('cs') ? false : arr.searchParams.get('cs').toLowerCase(),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        submit: (values, acceptedTermsVersion, recaptchaToken) =>
            dispatch(actionBorrowerRegisterSubmitted(values, acceptedTermsVersion, recaptchaToken)),
        redirectToDashboard: () => dispatch(sagaActionRedirectToDashboard()),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withGoogleReCaptcha
)(BorrowerRegistrationContainer);
