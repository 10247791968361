import { spawn } from 'redux-saga/effects';

import assumedIdentitySession from '../administer_users/assumed_identity/sagas/assumed_identity_session';
import viewLoanEnquirySaga from '../administer_loanenquiries_viewloanenquiry/sagas';
import createOrEditUserSaga from '../administer_users/shared/sagas/form';
import viewLenderLoanEnquirySaga from '../administer_lenders_loanenquiries_view/sagas';

function* main() {
    yield spawn(assumedIdentitySession);
    yield spawn(viewLoanEnquirySaga);
    yield spawn(createOrEditUserSaga);
    yield spawn(viewLenderLoanEnquirySaga);
}

export default main;
