import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { Table, TableBody, TableCell, TableHead, TableRow } from '../../../../pitch4_layout/components/tables';
import { TableDateTimeLayout } from '../../../../pitch4_layout/components/content';
import { ROLE_BORROWER } from '../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import Container from '../../../../pitchblack_react_utils/layout/Container';
import Item from '../../../../pitchblack_react_utils/layout/Item';
import RowItem from '../../../../pitchblack_react_utils/layout/RowItem';
import { PrimaryButton } from '../../../../pitch4_layout/components/buttons';
import { RightAligned } from '../../../../pitch4_layout/components/div';
import { Checkbox } from '../../../../pitch4_forms/components/inputs';
import { STATUS_MATCH_REJECTED, STATUS_MATCHED } from '../../../admin/enum/LenderLoanEnquiries/StatusEnum';

const FormTableRow = ({ match }) => {
    const [checked, setChecked] = useState(true);

    const style = {};

    if (checked === false) {
        style['opacity'] = '0.37';
    }

    return (
        <TableRow className={'no-hover'} key={'review-match-' + match.id} style={{ color: 'blue' }}>
            <TableCell style={style}>{match.lender.name}</TableCell>
            <TableCell style={style}>
                <TableDateTimeLayout value={match.updatedAt} />
            </TableCell>
            <TableCell>
                <Checkbox
                    name={['match', match.id].join('.')}
                    label={''}
                    onChangeFunc={(event) => setChecked(event.target.checked)}
                    defaultChecked={true}
                />
            </TableCell>
        </TableRow>
    );
};

class ReviewMatchedLendersForm extends React.Component {
    render() {
        let { matches, submitting, handleSubmit } = this.props;

        return (
            <Container>
                <Item xs={12}>
                    <form onSubmit={handleSubmit(this.onSubmit)}>
                        <Table className={'table-wrapper'}>
                            <TableHead className={`table-head`}>
                                <TableCell>Name</TableCell>
                                <TableCell>Matched</TableCell>
                                <TableCell>Approve?</TableCell>
                            </TableHead>
                            <TableBody className={`table-body`}>
                                {matches.map((match) => (
                                    <FormTableRow match={match} />
                                ))}
                            </TableBody>
                        </Table>
                        <RowItem>
                            <RightAligned className={'button-panel'}>
                                <PrimaryButton type="submit" disabled={submitting}>
                                    Submit
                                </PrimaryButton>
                            </RightAligned>
                        </RowItem>
                    </form>
                </Item>
            </Container>
        );
    }

    onSubmit = (values) => {
        const { setMatchStatuses } = this.props;

        return new Promise((resolve, reject) => {
            const statuses = [];
            for (const id in values.match) {
                statuses.push({
                    id: id,
                    newStatus: values.match[id] === true ? STATUS_MATCHED : STATUS_MATCH_REJECTED,
                });
            }

            const res = setMatchStatuses(statuses);
            resolve(res);
        });
    };
}

ReviewMatchedLendersForm.propTypes = {
    matches: PropTypes.array.isRequired,
    initialValues: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'borrower_review_matched_lenders_form',
    }),
    renderErrorIfAuthorisationFail([ROLE_BORROWER])
)(ReviewMatchedLendersForm);
