import React from 'react';
import { Button, Grid, LinearProgress } from '@material-ui/core';

export default function TermsProgress(props) {
    const { termsProgress, toggleTermsEditDrawer, disableActions } = props;

    return (
        <Grid container className={'progress-container'}>
            <Grid
                item
                lg={4}
                md={4}
                sm={12}
                xs={12}
                className={'progress-rate'}
            >{`${termsProgress.doneItemsCount} / ${termsProgress.totalItemsCount} Terms offered`}</Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} className={'progress-bar-container'}>
                <LinearProgress className={'progress-bar'} variant="determinate" value={termsProgress.percentDone} />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12} className={'progress-actions'}>
                <Button
                    className={'btn-edit-terms'}
                    color="primary"
                    variant="contained"
                    onClick={toggleTermsEditDrawer}
                    disabled={disableActions}
                >
                    Edit terms
                </Button>
            </Grid>
        </Grid>
    );
}
