import _ from 'lodash';

/**
 * Test for the presence of a nested property.
 *
 * @example
 *
 * // The source object to test against...
 * const myObj = { one: { two: { three: ['one'] } } };
 *
 * // Example path queries...
 * getNested(['one', 'two', 'three', 0], myObj); // Returns: "one"
 *
 * getNested(['one', 'two', 'three', 1], myObj); // Returns: null
 *
 * getNested(['one', 'two'], myObj); // Returns: { three: ['one'] }
 *
 * getNested(['one', 'nope'], myObj); // Returns: null
 *
 * getNested([], myObj); // Returns: { one: { two: { three: ['one'] } } }
 *
 * @param Array        pathDefinition
 * @param Array|Object testObj
 *
 * @return mixed
 */
export const getNested = (pathDefinition, testObj) => {
    return pathDefinition.reduce((obj, pathElement) => (obj && obj[pathElement] ? obj[pathElement] : null), testObj);
};

export const isset = (val) => {
    return !_.isUndefined(val) && !_.isNull(val);
};

export const isEmpty = (val) => {
    if (!isset(val)) {
        return true;
    }

    if (_.isFunction(val)) {
        return false;
    }

    if (_.isArray(val) || _.isString(val)) {
        return val.length === 0;
    }

    if (_.isObject(val)) {
        return _.isEmpty(val);
    }

    return false;
};
