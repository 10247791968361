import React, { useState } from 'react';
import { Container } from 'pitch4_layout/components/div';
import { Table } from 'pitch4_tabular/components/table';
import BorrowerQuestions, {
    QUESTION_ADDITIONAL_INFO,
    QUESTION_AGREE_TO_TERMS,
    QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
    QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
    QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
    QUESTION_APPLICANT_MAIN_NATIONALITY,
    QUESTION_APPLICANT_MAIN_OCCUPATION,
    QUESTION_BUSINESS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_ASSET_HIRE_PURCHASE_OR_LEASE,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_FACILITY_NEEDED_BY,
    QUESTION_BUSINESS_INVOICE_FINANCE_TYPE,
    QUESTION_BUSINESS_IS_SECURE_FINANCE,
    QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY,
    QUESTION_BUSINESS_OUTSTANDING_DEBT,
    QUESTION_BUSINESS_PROFITABLE,
    QUESTION_BUSINESS_REFINANCE,
    QUESTION_BUSINESS_SEASONAL,
    QUESTION_BUSINESS_SECTOR,
    QUESTION_BUSINESS_TYPE,
    QUESTION_BUY_TO_LET_EXPERIENCE,
    QUESTION_COMMERCIAL_OWNER_OCCUPIED,
    QUESTION_COMMERCIAL_TYPES,
    QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
    QUESTION_EPC_RATING,
    QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
    QUESTION_FUNDING_TYPE,
    QUESTION_HMO_HAS_LICENSE,
    QUESTION_HMO_LICENSE_IS_REQUIRED,
    QUESTION_HMO_ROOMS_SELF_CONTAINED,
    QUESTION_LOAN_NEEDED_BY,
    QUESTION_LOAN_PURPOSE,
    QUESTION_LOAN_PURPOSE_MEZZANINE,
    QUESTION_LOAN_TERM,
    QUESTION_LOAN_TERM_BUSINESS_TERM,
    QUESTION_LOAN_TYPE,
    QUESTION_LOAN_USAGE_LOCATION,
    QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
    QUESTION_OCCUPY_PROPERTY,
    QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY,
    QUESTION_OWN_ANY_OTHER_PROPERTY,
    QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES,
    QUESTION_PLANNING_FOR,
    QUESTION_PLANNING_IN_PLACE,
    QUESTION_PORTFOLIO_REFINANCE,
    QUESTION_PRE_APPROVE,
    QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
    QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE,
    QUESTION_PROPERTY_LOCATION,
    QUESTION_PROPERTY_LOCATION_BUSINESS,
    QUESTION_PROPERTY_OWNERSHIP,
    QUESTION_PROPERTY_TYPE,
    QUESTION_PROPERTY_TYPE_MEZZANINE,
    QUESTION_PROPERTY_TYPE_PORTFOLIO,
    QUESTION_PROPERTY_TYPE_REFURBISHMENT,
    QUESTION_RESIDENTIAL_REFINANCE,
    QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
    QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
    QUESTION_TEAM_DEVELOPMENT_EXPERIENCE,
    QUESTION_UNITS_PRE_SOLD,
} from '../enums/Borrower/BorrowerQuestions';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../authentication/enum/Roles/NameEnum';
import BrokerQuestions from '../enums/Broker/BrokerQuestions';
import BorrowerLoanType, {
    BRIDGE_TYPE,
    BUSINESS_TYPE,
    DEVELOPMENT_TYPE,
    MEZZANINE_TYPE,
    PORTFOLIO_TYPE,
    REFURBISHMENT_TYPE,
    TERM_LONG_TYPE,
    TERM_SHORT_TYPE,
} from '../enums/Borrower/BorrowerLoanType';
import BrokerLoanType, { ASSET_TYPE } from '../enums/Broker/BrokerLoanType';
import SecuredUnsecuredFinance from '../enums/Shared/SecuredUnsecuredFinance';
import YesNo, { NO, YES } from '../enums/Shared/YesNo';
import FacilityNeededBy from '../enums/Shared/FacilityNeededBy';
import PropertyLocation from '../enums/Shared/PropertyLocation';
import PortfolioRefinance from '../enums/Shared/PortfolioRefinance';
import InvoiceFinancingTypes from '../enums/Shared/InvoiceFinancingTypes';
import LeaseHirePurchaseTypes from '../enums/Shared/LeaseHirePurchaseTypes';
import BusinessTypes from '../enums/Shared/BusinessTypes';
import CommercialStructure from '../enums/Shared/CommercialStructure';
import BusinessLoanPurposes from '../enums/Shared/BusinessLoanPurposes';
import LoanPurposes from '../enums/Shared/LoanPurposes';
import LoanNeededBy from '../enums/Shared/LoanNeededBy';
import OwnAnyOtherProperty from '../enums/Shared/OwnAnyOtherProperty';
import Ownership from '../enums/Shared/Ownership';
import CommercialFundingType, { LAND_WITHOUT_PLANNING_FUNDING_TYPE } from '../enums/Shared/CommercialFundingType';
import CommericalTypeEnums from '../../admin/enum/matching/enum/CommercialTypeEnum';
import CommercialTypes from '../enums/Shared/CommercialTypes';
import CountryNameEnum from '../../../pitch4_enum/enum/CountryNameEnum';
import NationalityEnum from '../../../pitch4_enum/enum/NationalityEnum';
import CitizenshipTypes from '../enums/Shared/CitizenshipTypes';
import EPCRatings from '../enums/Shared/EPCRatings';
import { TableRow } from '../../../pitch4_tabular/components/table';
import { Item } from '../../../pitch4_layout/components/div';
import { Body1, Subtitle1 } from '../../../pitch4_layout/components/text';
import { filterTokens } from '../../utils/filter-tokens';
import { formatCurrency } from '../../../pitch4_layout/components/data/formatter/CurrencyFormatter';
import { toCamelCase } from '../../view_loan_enquiry/HelperFunctions';
import LoanEnquiryQuestionsPerType from '../enums/Shared/LoanEnquiryQuestionsPerType';
import AssetRefinance from '../enums/Shared/AssetRefinance';

export function NewLoanEnquirySummaryTable(props) {
    const [loanEnquiry] = useState(props.loanEnquiry);
    const [loanEnquiryData] = useState(props.loanEnquiryData);
    const [lenderLoanEnquiry] = useState(props.lenderLoanEnquiry);
    const fundingType = loanEnquiryData.fundingType;
    const loanType = loanEnquiryData.loanType;
    const propertyType = loanEnquiryData.propertyType !== null ? loanEnquiryData.propertyType : null;
    const isSecureFinance = loanEnquiryData.businessSecuredFinance === true;
    const loanEnquiryQuestionsPerType = new LoanEnquiryQuestionsPerType();
    const questions = loanEnquiryQuestionsPerType.getQuestions(loanType, fundingType, propertyType, isSecureFinance);

    const renderSummaryForSection = (field, sectionType = null) => {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questionsEnum =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const loanTypes =
            userRole === ROLE_BORROWER ? new BorrowerLoanType().getTypes() : new BrokerLoanType().getTypes();
        const securedUnsecuredOptions = new SecuredUnsecuredFinance().getOptions();
        const yesNoOptions = new YesNo().getOptions();
        const facilityNeededByOptions = new FacilityNeededBy().getOptions();
        const propertyLocations = new PropertyLocation().getOptions();
        const businessRefinanceOptions = new PortfolioRefinance().getBusinessOptionTypes();
        const assetRefinanceOptions = new AssetRefinance().getBusinessOptionTypes();
        const invoiceFinanceOptions = new InvoiceFinancingTypes().getOptions();
        const leaseHirePurchaseOptions = new LeaseHirePurchaseTypes().getOptions();
        const businessTypes = new BusinessTypes().getOptions();
        const commercialStructure = new CommercialStructure().getOptions();
        const businessFinanceLoanPurpose = new BusinessLoanPurposes().getOptions();
        const propertyFinanceLoanPurpose = new LoanPurposes().getOptions();
        const loanNeededByOptions = new LoanNeededBy().getOptions();
        const ownAnyOtherPropertyOptions = new OwnAnyOtherProperty().getOptions();
        const portfolioRefinanceOptions = new PortfolioRefinance().getPortfolioOptions();
        const ownershipOptions = new Ownership().getOptions();
        const commercialFundingTypes = new CommercialFundingType().getTypes();
        const commercialUnitTypes = new CommericalTypeEnums().getTypes();
        const commercialPlanningTypeOptions = new CommercialTypes().getSummaryPlanningOptions();
        const countryOptions = new CountryNameEnum().getTypes();
        const nationalityOptions = new NationalityEnum().getTypes();
        const citizenshipTypes = new CitizenshipTypes().getOptions();
        const epcRatingTypes = new EPCRatings().getOptions();
        let applicants = {};
        if ('loanEnquiryApplicants' in loanEnquiry) {
            applicants = loanEnquiry.loanEnquiryApplicants;
        }
        if (lenderLoanEnquiry !== null && 'loanEnquiryApplicants' in lenderLoanEnquiry) {
            applicants = lenderLoanEnquiry.loanEnquiryApplicants;
        }

        const mainApplicant = findMainApplicant(applicants);
        let questionField = null;
        if (field in questionsEnum) {
            questionField = questionsEnum[field];
        }
        let value = '';
        let label = '';

        if (typeof questionField == 'undefined') {
            return <></>;
        }
        if (questionField === null) {
            return <></>;
        }

        const loanEnquiryDataField = toCamelCase(questionField.fieldName);
        let fieldValue = loanEnquiryData[loanEnquiryDataField];
        const fundingType = loanEnquiryData[toCamelCase(questionsEnum[QUESTION_FUNDING_TYPE].fieldName)];

        if (fieldValue === false || fieldValue === 'false') {
            fieldValue = NO;
        }
        if (fieldValue === true || fieldValue === 'true') {
            fieldValue = YES;
        }
        if (fieldValue === '0' || fieldValue === 0) {
            return <></>;
        }

        if (QUESTION_ADDITIONAL_INFO !== field && (fieldValue === null || fieldValue === '')) {
            return <></>;
        }
        if (QUESTION_ADDITIONAL_INFO === field && (fieldValue === null || fieldValue === '')) {
            fieldValue = 'No additional information provided.';
        }
        if (
            QUESTION_PROPERTY_TYPE === field &&
            [MEZZANINE_TYPE, PORTFOLIO_TYPE, REFURBISHMENT_TYPE, DEVELOPMENT_TYPE].includes(fundingType)
        ) {
            return <></>;
        }
        if (QUESTION_PROPERTY_TYPE_MEZZANINE === field && fundingType !== MEZZANINE_TYPE) {
            return <></>;
        }
        if (QUESTION_PROPERTY_TYPE_PORTFOLIO === field && fundingType !== PORTFOLIO_TYPE) {
            return <></>;
        }
        if (
            QUESTION_PROPERTY_TYPE_REFURBISHMENT === field &&
            fundingType !== REFURBISHMENT_TYPE &&
            fundingType !== DEVELOPMENT_TYPE
        ) {
            return <></>;
        }
        if (QUESTION_PORTFOLIO_REFINANCE === field && fundingType !== PORTFOLIO_TYPE) {
            return <></>;
        }
        if (QUESTION_RESIDENTIAL_REFINANCE === field && fundingType === PORTFOLIO_TYPE) {
            return <></>;
        }
        if (
            QUESTION_LOAN_PURPOSE_MEZZANINE === field &&
            sectionType === MEZZANINE_TYPE &&
            loanEnquiryData[loanEnquiryDataField] === YES
        ) {
            return <></>;
        }
        if (
            QUESTION_LOAN_PURPOSE_MEZZANINE === field &&
            sectionType !== MEZZANINE_TYPE &&
            loanEnquiryData[loanEnquiryDataField] !== YES
        ) {
            return <></>;
        }
        if (QUESTION_LOAN_TERM === field && (fundingType === TERM_LONG_TYPE || fundingType === TERM_SHORT_TYPE)) {
            return <></>;
        }
        if (
            QUESTION_LOAN_TERM_BUSINESS_TERM === field &&
            fundingType !== TERM_LONG_TYPE &&
            fundingType !== TERM_SHORT_TYPE
        ) {
            return <></>;
        }
        if (
            (QUESTION_LOAN_PURPOSE === field || QUESTION_LOAN_PURPOSE_MEZZANINE === field) &&
            loanEnquiryData[toCamelCase(questionsEnum[QUESTION_RESIDENTIAL_REFINANCE].fieldName)] !== YES
        ) {
            return <></>;
        }
        switch (field) {
            case QUESTION_LOAN_TYPE:
                label = questionField.label;
                value = loanTypes[fieldValue].label;
                break;
            case QUESTION_FUNDING_TYPE:
                label =
                    'What is the main purpose for this [bold|' +
                    loanTypes[
                        loanEnquiryData[toCamelCase(questionsEnum[QUESTION_LOAN_TYPE].fieldName)]
                    ].label.toLowerCase() +
                    '] finance?';
                value = loanTypes[fieldValue].label;
                break;
            case QUESTION_OCCUPY_PROPERTY:
                let property = 'property';
                let on_wording = 'in';
                if (
                    (fundingType === BRIDGE_TYPE &&
                        loanEnquiryData[toCamelCase(questionsEnum[QUESTION_PROPERTY_TYPE].fieldName)] ===
                            LAND_WITHOUT_PLANNING_FUNDING_TYPE) ||
                    (fundingType === DEVELOPMENT_TYPE &&
                        loanEnquiryData[toCamelCase(questionsEnum[QUESTION_PROPERTY_TYPE].fieldName)] ===
                            LAND_WITHOUT_PLANNING_FUNDING_TYPE)
                ) {
                    property = 'site';
                    on_wording = 'on';
                }
                label = 'Do you intend to live ' + on_wording + ' the ' + property + '?';
                value = yesNoOptions[fieldValue].label;
                break;
            case QUESTION_BUSINESS_IS_SECURE_FINANCE:
                label = questionField.label;
                value = securedUnsecuredOptions[fieldValue].label;
                break;
            case QUESTION_BUSINESS_REFINANCE:
                label = questionField.label;
                if (loanEnquiryData[questions[QUESTION_FUNDING_TYPE].fieldName] === ASSET_TYPE) {
                    value = assetRefinanceOptions[fieldValue].label;
                } else {
                    value = businessRefinanceOptions[fieldValue].label;
                }
                break;
            case QUESTION_COMMERCIAL_TYPES:
                const multiselectValues = fieldValue;
                label = questionField.label;
                let mappedValues = [];
                for (const multiselectValue of multiselectValues) {
                    mappedValues.push(commercialUnitTypes[multiselectValue]);
                }
                value = mappedValues.join(', ');
                break;
            case QUESTION_PLANNING_FOR:
                label = questionField.label;
                value = commercialPlanningTypeOptions[fieldValue].label;
                break;
            case QUESTION_PRE_APPROVE:
            case QUESTION_BUSINESS_SEASONAL:
            case QUESTION_BUSINESS_PROFITABLE:
            case QUESTION_BUSINESS_OUTSTANDING_DEBT:
            case QUESTION_BUSINESS_ADVERSE_CREDIT:
            case QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT:
            case QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS:
            case QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES:
            case QUESTION_OCCUPY_GREATER_THAN_40_PERCENT:
            case QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY:
            case QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES:
            case QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY:
            case QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY:
            case QUESTION_COMMERCIAL_OWNER_OCCUPIED:
            case QUESTION_HMO_HAS_LICENSE:
            case QUESTION_HMO_LICENSE_IS_REQUIRED:
            case QUESTION_PLANNING_IN_PLACE:
            case QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE:
            case QUESTION_PORTFOLIO_REFINANCE:
            case QUESTION_LOAN_USAGE_LOCATION:
            case QUESTION_TEAM_DEVELOPMENT_EXPERIENCE:
            case QUESTION_UNITS_PRE_SOLD:
            case QUESTION_BUY_TO_LET_EXPERIENCE:
            case QUESTION_COMMERCIAL_UNITS_RENTED_OUT:
            case QUESTION_HMO_ROOMS_SELF_CONTAINED:
            case QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE:
            case QUESTION_AGREE_TO_TERMS:
                label = questionField.label;
                value = yesNoOptions[fieldValue].label;
                break;
            case QUESTION_BUSINESS_TYPE:
                label = questionField.label;
                value = commercialStructure[fieldValue].label;
                break;
            case QUESTION_BUSINESS_SECTOR:
                label = questionField.label;
                value = businessTypes[fieldValue].label;
                break;
            case QUESTION_BUSINESS_FACILITY_NEEDED_BY:
                label = questionField.label;
                value = facilityNeededByOptions[fieldValue].label;
                break;
            case QUESTION_PROPERTY_LOCATION_BUSINESS:
            case QUESTION_PROPERTY_LOCATION:
                label = questionField.label;
                value = propertyLocations[fieldValue].label;
                break;
            case QUESTION_BUSINESS_INVOICE_FINANCE_TYPE:
                label = questionField.label;
                value = invoiceFinanceOptions[fieldValue].label;
                break;
            case QUESTION_OWN_ANY_OTHER_PROPERTY:
                label = questionField.label;
                value = ownAnyOtherPropertyOptions[fieldValue].label;
                break;
            case QUESTION_PROPERTY_OWNERSHIP:
                label = questionField.label;
                value = ownershipOptions[fieldValue].label;
                break;
            case QUESTION_BUSINESS_ASSET_HIRE_PURCHASE_OR_LEASE:
                label = questionField.label;
                value = leaseHirePurchaseOptions[fieldValue].label;
                break;
            case QUESTION_LOAN_NEEDED_BY:
                label = questionField.label;
                value = loanNeededByOptions[fieldValue].label;
                break;
            case QUESTION_RESIDENTIAL_REFINANCE:
                label = questionField.label;
                value = portfolioRefinanceOptions[fieldValue].label;
                break;
            case QUESTION_EPC_RATING:
                label = questionField.label;
                value = epcRatingTypes[fieldValue].label;
                break;
            case QUESTION_PROPERTY_TYPE:
                label = questionField.label;
                value = commercialFundingTypes[fieldValue].label;
                break;
            case QUESTION_PROPERTY_TYPE_REFURBISHMENT:
                label = questionField.label;
                value = commercialFundingTypes[fieldValue].label;
                break;
            case QUESTION_PROPERTY_TYPE_MEZZANINE:
                label = questionField.label;
                value = commercialFundingTypes[fieldValue].label;
                break;
            case QUESTION_PROPERTY_TYPE_PORTFOLIO:
                label = questionField.label;
                value = commercialFundingTypes[fieldValue].label;
                break;
            case QUESTION_LOAN_PURPOSE:
            case QUESTION_LOAN_PURPOSE_MEZZANINE:
                if (loanEnquiryData[toCamelCase(questionsEnum[QUESTION_LOAN_TYPE].fieldName)] === BUSINESS_TYPE) {
                    label = questionField.label;
                    value = businessFinanceLoanPurpose[fieldValue].label;
                } else {
                    label = questionField.label;
                    value = propertyFinanceLoanPurpose[fieldValue].label;
                }
                break;
            case QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS:
                label =
                    'What is ' +
                    mainApplicant.applicant.given +
                    ' ' +
                    mainApplicant.applicant.family +
                    "'s citizenship status?";
                value = citizenshipTypes[fieldValue].label;
                break;
            case QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE:
                label =
                    'What country does ' +
                    mainApplicant.applicant.given +
                    ' ' +
                    mainApplicant.applicant.family +
                    ' reside in?';
                value = countryOptions[fieldValue];
                break;
            case QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES:
                label =
                    'Has ' +
                    mainApplicant.applicant.given +
                    ' ' +
                    mainApplicant.applicant.family +
                    ' had any credit difficulties?';
                value = yesNoOptions[fieldValue].label;
                break;
            case QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME:
                label =
                    'What is ' +
                    mainApplicant.applicant.given +
                    ' ' +
                    mainApplicant.applicant.family +
                    ' annual gross income?';
                value = fieldValue;
                break;
            case QUESTION_APPLICANT_MAIN_NATIONALITY:
                label =
                    'What is ' +
                    mainApplicant.applicant.given +
                    ' ' +
                    mainApplicant.applicant.family +
                    "'s nationality?";
                value = nationalityOptions[fieldValue];
                break;
            case QUESTION_APPLICANT_MAIN_OCCUPATION:
                label =
                    'What is ' + mainApplicant.applicant.given + ' ' + mainApplicant.applicant.family + ' occupation?';
                value = fieldValue;
                break;
            default:
                label = questionField.label;
                value = fieldValue;
                break;
        }

        return (
            <>
                <Item xs={5}>
                    <Subtitle1>{filterTokens(label)}</Subtitle1>
                </Item>
                <Item xs={6}>
                    <Body1>{valueTypeConverter(questionField.type, filterTokens(value))}</Body1>
                </Item>
            </>
        );
    };

    const findMainApplicant = (loanEnquiryApplicants) => {
        return loanEnquiryApplicants.find((applicant) => applicant.isMainApplicant);
    };

    const valueTypeConverter = (fieldType, value) => {
        switch (fieldType) {
            case 'currency':
                return formatCurrency({
                    value: value,
                });
            case 'percent':
                return `${Math.round(value * 100) / 100}%`;
            default:
                return value;
        }
    };

    return (
        <Container>
            <Table style={{ border: 'none' }}>
                {Object.keys(questions).map((field, index) => {
                    return (
                        <TableRow key={index}>{renderSummaryForSection(field, loanEnquiryData.fundingType)}</TableRow>
                    );
                })}
            </Table>
        </Container>
    );
}
