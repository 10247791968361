import BaseEnum from 'pitchblack_react_utils/enums/BaseEnum';

export const SHORT_TERM = 'short_term';
export const LONG_TERM = 'long_term';
export const REVOLVING_CREDIT = 'revolving_credit';

class ProductTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [SHORT_TERM]: 'Short Term Loans (6-36 months)',
            [LONG_TERM]: 'Long Term Loans (36+ months)',
            [REVOLVING_CREDIT]: 'Revolving Credit Facility',
        };
    };
}

export default ProductTypeEnum;
