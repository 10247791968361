class ValidationResults {
    constructor() {
        this.results = [];
    }

    getResults = () => {
        return this.results;
    };
    setResults = (results) => {
        this.results = results;
        return this;
    };

    addResult = (validationResult) => {
        this.results.push(validationResult);
        return this;
    };
}

export default ValidationResults;
