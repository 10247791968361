import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_INTERMEDIARY } from '../../../../authentication/enum/Roles/NameEnum';
import { push } from 'react-router-redux';
import { actionUpdateMatchFavourite } from '../redux/main';
import { connect } from 'react-redux';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ListView from '../components/ListView';
import CardView from '../components/CardView';
import { Hidden, Paper } from '@material-ui/core';
import _ from 'lodash';
import { actionRemoveLenderLoanEnquiryAlert } from '../../view_lender_loan_enquiry/redux/main';
import ContractSigned from '../../../../legal/ContractSigned';

class LeadsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lenderProfileDrawOpen: false,
            lenderProfile: null,
            loanEnquiryMatches: [],
            matchSelected: null,
            refreshedData: false,
            refreshedFavData: false,
            showOnlyFavEnabled: false,
            data: this.getData(),
            matches: props.matches,
            listView: false,
            cardView: true,
            selectAll: false,
            sortBy: {
                field: 'status',
                sort: 'asc',
            },
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            false === this.state.refreshedFavData &&
            (!this.state.refreshedData || this.props.matches.length !== this.state.data.length)
        ) {
            const { field, sort } = this.state.sortBy;
            // Refresh data after a user accepts matches in a popup form
            const data = this.props.matches.map((match, index) => {
                return {
                    id: match.id,
                    lenderId: match.lender.id,
                    lenderName: match.lender.name,
                    lenderAnonName: match.lender.anonName ?? null,
                    lenderProfileEnabled: match.lender.profileEnabled,
                    lenderProfile: match.lender,
                    updatedAt: match.updatedAt,
                    status: match.status,
                    alertActive: match.alertActive,
                    favourite: match.favourite,
                    terms: JSON.parse(match.lenderTerms) || null,
                };
            });

            this.setState({ data: _.orderBy(data, [field], [sort]), refreshedData: true });
        }
    }

    getData = () => {
        const { matches } = this.props;

        const data = matches.map((match) => {
            return {
                id: match.id,
                lenderId: match.lender.id,
                lenderName: match.lender.name,
                lenderAnonName: match.lender.anonName ?? null,
                lenderProfileEnabled: match.lender.profileEnabled,
                lenderProfile: match.lender,
                updatedAt: match.updatedAt,
                status: match.status,
                alertActive: match.alertActive,
                favourite: match.favourite,
                terms: JSON.parse(match.lenderTerms) || null,
            };
        });

        return _.orderBy(data, ['status'], ['asc']);
    };

    handleShowOnlyFav = (selectFav) => {
        const { matches } = this.state;
        const data = matches
            .filter((match) => (true === selectFav ? selectFav === match.favourite : match))
            .map((match) => {
                return {
                    id: match.id,
                    lenderId: match.lender.id,
                    lenderName: match.lender.name,
                    lenderAnonName: match.lender.anonName ?? null,
                    lenderProfileEnabled: match.lender.profileEnabled,
                    lenderProfile: match.lender,
                    updatedAt: match.updatedAt,
                    status: match.status,
                    alertActive: match.alertActive,
                    favourite: match.favourite,
                    terms: JSON.parse(match.lenderTerms) || null,
                };
            });

        this.setState({
            data: data,
            refreshedFavData: true,
            showOnlyFavEnabled: selectFav,
        });
    };

    handleFavouriteMatchChange = (lenderLoanEnquiryId = null, loanEnquiryId = null, isFavourite = null) => () => {
        if (null !== lenderLoanEnquiryId && null !== isFavourite) {
            const { handleFavouriteMatchChange } = this.props;
            const data = this.state.data;
            const dMatchIndex = this.state.data.findIndex((m) => m.id === lenderLoanEnquiryId);
            data[dMatchIndex].favourite = !isFavourite;

            // We need to do the same as above to update props matches objects in state.
            // Props matches kept in state  - we need them to filter out Fav matches and then show all matches again
            const matches = this.state.matches;
            const mMatchIndex = this.state.matches.findIndex((m) => m.id === lenderLoanEnquiryId);
            matches[mMatchIndex].favourite = !isFavourite;

            this.setState({ data: [], matches: [] }, () =>
                this.setState({
                    // Filter out not Fav LLEs
                    data:
                        true === this.state.showOnlyFavEnabled
                            ? data.filter((match) => match.id !== lenderLoanEnquiryId)
                            : data,
                    matches: matches,
                })
            );

            handleFavouriteMatchChange(lenderLoanEnquiryId, loanEnquiryId, !isFavourite);
        }
    };

    handleRemoveAlertMatchAlert = (lenderLoanEnquiry) => {
        const { removeLenderLoanEnquiryAlert } = this.props;
        const { data } = this.state;
        let lle = data.find((lle) => lle.id === lenderLoanEnquiry.id);
        lle.alertActive = false;

        this.setState({ data: [] }, () => {
            this.setState({ data: data }, () => removeLenderLoanEnquiryAlert(lenderLoanEnquiry.id));
        });
    };

    handleSelectAll = (selectAll = false) => this.setState({ selectAll: selectAll });

    handleSortBy = (
        sortBy = {
            field: 'status',
            sort: 'asc',
        }
    ) => {
        this.setState({ sortBy: sortBy }, () => {
            const { data } = this.state;
            this.setState({ data: _.orderBy(data, [sortBy.field], [sortBy.sort]) });
        });
    };

    setStatusName = () => {
        const { sortBy } = this.state;
        switch (sortBy.field) {
            case 'lenderName':
                return sortBy.sort === 'asc' ? 'lenderNameAsc' : 'lenderNameDesc';
            case 'updatedAt':
                return sortBy.sort === 'asc' ? 'updatedAtAsc' : 'updatedAtDesc';
            case 'status':
                return sortBy.sort === 'asc' ? 'statusAsc' : 'statusDesc';
            default:
                return 'statusAsc';
        }
    };

    render() {
        let {
            handleLenderSelection,
            selectedLenders,
            lendersMessageAllowed,
            toggleLenderDrawer,
            matchCount,
            contractSigned = false,
            contractInProgress = true,
            loanType,
        } = this.props;

        return (
            <Paper elevation={1} className={'leads-container-main'} style={{ border: 'none', boxShadow: 'none' }}>
                <div className={'lenders-badge'}>
                    <div className={'count'}>{matchCount}</div>
                    <div>LENDER{matchCount !== 1 && 'S'}</div>
                </div>
                <div className={`leads-container-header`}>
                    <div className={'label'}>
                        Lender Matches: <span className={'count'}>({matchCount})</span>
                    </div>
                    <div className={'actions'}>
                        <div className={'select-fav'}>
                            <span className={'label'}>Show Only Starred:</span>{' '}
                            {this.state.selectFav ? (
                                <CheckBoxIcon
                                    className={'icon'}
                                    onClick={() => {
                                        this.setState({ selectFav: false }, () => this.handleShowOnlyFav(false));
                                    }}
                                />
                            ) : (
                                <CheckBoxOutlineBlankIcon
                                    className={'icon'}
                                    onClick={() => {
                                        this.setState({ selectFav: true }, () => this.handleShowOnlyFav(true));
                                    }}
                                />
                            )}
                            <div className={'divider'} />
                        </div>
                        <div className={'select-all'}>
                            <span className={'label'}>Select all:</span>{' '}
                            {this.state.selectAll ? (
                                <CheckBoxIcon
                                    className={'icon'}
                                    onClick={() => {
                                        this.setState({ selectAll: false }, () => {
                                            handleLenderSelection(null);
                                        });
                                    }}
                                />
                            ) : (
                                <CheckBoxOutlineBlankIcon
                                    className={'icon'}
                                    onClick={() => {
                                        this.setState({ selectAll: true }, () => {
                                            handleLenderSelection(this.state.data.map((item) => item.id));
                                        });
                                    }}
                                />
                            )}
                        </div>
                        <div className={'sort-by'}>
                            <div className={'divider'} />
                            <span className={'label'}>Sort by:</span>
                            <FormControl variant="outlined" style={{ minWidth: 130 }}>
                                <Select
                                    native
                                    value={this.setStatusName()}
                                    onChange={(event) => {
                                        let field, sort;
                                        switch (event.target.value) {
                                            case 'lenderNameAsc':
                                                field = 'lenderName';
                                                sort = 'asc';
                                                break;
                                            case 'lenderNameDesc':
                                                field = 'lenderName';
                                                sort = 'desc';
                                                break;
                                            case 'updatedAtAsc':
                                                field = 'updatedAt';
                                                sort = 'asc';
                                                break;
                                            case 'updatedAtDesc':
                                                field = 'updatedAt';
                                                sort = 'desc';
                                                break;
                                            case 'statusAsc':
                                                field = 'status';
                                                sort = 'asc';
                                                break;
                                            case 'statusDesc':
                                                field = 'status';
                                                sort = 'desc';
                                                break;
                                            default:
                                                field = 'status';
                                                sort = 'asc';
                                        }

                                        this.handleSortBy({ field: field, sort: sort });
                                    }}
                                    className={'list'}
                                >
                                    <option value={'lenderNameAsc'}>Name (asc)</option>
                                    <option value={'lenderNameDesc'}>Name (desc)</option>
                                    <option value={'updatedAtAsc'}>Updated (asc)</option>
                                    <option value={'updatedAtDesc'}>Updated (desc)</option>
                                    <option value={'statusAsc'}>Status (asc)</option>
                                    <option value={'statusDesc'}>Status (desc)</option>
                                </Select>
                            </FormControl>
                            <div className={'divider'} />
                        </div>

                        <Hidden smDown>
                            <div className={'view-list'}>
                                <ViewListIcon
                                    onClick={() => {
                                        this.setState({
                                            listView: true,
                                            cardView: false,
                                        });
                                    }}
                                    className={`icon ${this.state.listView && 'active'}`}
                                />
                            </div>
                            <div className={'view-cards'}>
                                <ViewModuleIcon
                                    onClick={() => {
                                        this.setState({
                                            listView: false,
                                            cardView: true,
                                        });
                                    }}
                                    className={`icon ${this.state.cardView && 'active'}`}
                                />
                            </div>
                        </Hidden>
                        <Hidden mdUp>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <div className={'view-list'}>
                                    <ViewListIcon
                                        onClick={() => {
                                            this.setState({
                                                listView: true,
                                                cardView: false,
                                            });
                                        }}
                                        className={`icon ${this.state.listView && 'active'}`}
                                    />
                                </div>
                                <div className={'view-cards'}>
                                    <ViewModuleIcon
                                        onClick={() => {
                                            this.setState({
                                                listView: false,
                                                cardView: true,
                                            });
                                        }}
                                        className={`icon ${this.state.cardView && 'active'}`}
                                    />
                                </div>
                            </div>
                        </Hidden>
                    </div>
                </div>
                {true === contractSigned && false === contractInProgress ? (
                    this.state.listView ? (
                        <ListView
                            handleLenderSelection={handleLenderSelection}
                            selectedLenders={selectedLenders}
                            lendersMessageAllowed={lendersMessageAllowed}
                            toggleLenderDrawer={toggleLenderDrawer}
                            handleFavouriteMatchChange={this.handleFavouriteMatchChange}
                            handleRemoveAlertMatchAlert={this.handleRemoveAlertMatchAlert}
                            data={this.state.data}
                            sortBy={this.state.sortBy}
                            handleSelectAll={this.handleSelectAll}
                            loanType={loanType}
                        />
                    ) : (
                        <CardView
                            handleLenderSelection={handleLenderSelection}
                            selectedLenders={selectedLenders}
                            lendersMessageAllowed={lendersMessageAllowed}
                            toggleLenderDrawer={toggleLenderDrawer}
                            handleFavouriteMatchChange={this.handleFavouriteMatchChange}
                            handleRemoveAlertMatchAlert={this.handleRemoveAlertMatchAlert}
                            data={this.state.data}
                            sortBy={this.state.sortBy}
                            handleSelectAll={this.handleSelectAll}
                            loanType={loanType}
                        />
                    )
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}>
                        <ContractSigned contractSigned={contractSigned} contractInProgress={contractInProgress} />
                    </div>
                )}
            </Paper>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        redirect: (url) => dispatch(push(url)),
        handleFavouriteMatchChange: (lenderLoanEnquiryId, loanEnquiryId, isFavourite) =>
            dispatch(actionUpdateMatchFavourite(lenderLoanEnquiryId, loanEnquiryId, isFavourite)),
        removeLenderLoanEnquiryAlert: (status) => {
            dispatch(actionRemoveLenderLoanEnquiryAlert(status));
        },
    };
};

LeadsContainer.propTypes = {
    matches: PropTypes.array.isRequired,
    loanEnquiryStatus: PropTypes.string,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_INTERMEDIARY])
)(LeadsContainer);
