import React from 'react';
import { Button, FormControl, Link, InputLabel, Select, MenuItem, CircularProgress } from '@material-ui/core';
import URLEnum from '../../pitch4_enum/enum/URLEnum';

export default function AssignToModal(props) {
    const { setModalClose, userRole, assignedTo, handleUpdateLEAssignee, assignees } = props;
    const [selectedAssignee, setSelectedAssignee] = React.useState(assignedTo);
    const [submitting, setSubmitting] = React.useState(false);
    const urlEnum = new URLEnum();
    const getHref = () => urlEnum.getURL(userRole);
    const noOwner = {
        id: 'no_owner',
        name: 'No owner',
    };

    const handleAssigneeChange = (event) => {
        const id = event.target.value;
        setSelectedAssignee(noOwner.id === id ? noOwner : assignees.find((assignee) => id === assignee.id));
    };

    const handleSubmit = () => {
        const newAssignee = assignees.find((assignee) => selectedAssignee.id === assignee.id) || noOwner;

        setSubmitting(true);
        handleUpdateLEAssignee(newAssignee);
    };

    return (
        <div className={'assign-le-container-modal'}>
            <div className={'header'}>Assign Enquiry</div>
            <div className={'content'}>
                <FormControl variant="outlined">
                    <InputLabel>Assignee</InputLabel>
                    <Select
                        className={'assignee-list'}
                        value={selectedAssignee.id}
                        onChange={handleAssigneeChange}
                        label={null === selectedAssignee.id ? 'Loading...' : 'Assignee'}
                        defaultValue={'no_owner'}
                    >
                        <MenuItem value={noOwner.id}>
                            <em>{noOwner.name}</em>
                        </MenuItem>
                        {assignees.map((assignee) => (
                            <MenuItem value={assignee.id} key={assignee.id}>
                                {assignee.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className={'link'}>
                <Link href={getHref()} target={'_blank'}>
                    Need to Add Team Members?
                </Link>
            </div>
            <div className={'actions'}>
                <Button className={'btn-back'} onClick={() => setModalClose()}>
                    BACK
                </Button>
                <Button
                    className={'btn-submit'}
                    variant={'contained'}
                    disabled={submitting || assignedTo.id === selectedAssignee.id}
                    color="primary"
                    onClick={() => handleSubmit()}
                >
                    {true === submitting ? <CircularProgress size={18} /> : 'SUBMIT'}
                </Button>
            </div>
        </div>
    );
}
