// see https://github.com/erikras/ducks-modular-redux

export const REDUCER_NAMESPACE = 'anon.borrower_register_step';

// Actions
export const BORROWER_REGISTER_STEP_SUBMISSION_ERRORS = `${REDUCER_NAMESPACE}.step.submission_errors`;
export const BORROWER_REGISTER_SUBMITTED = `${REDUCER_NAMESPACE}.submitted`;
export const BORROWER_REGISTER_RESET = `${REDUCER_NAMESPACE}.reset`;
export const BORROWER_REGISTER_NEXT_STEP = `${REDUCER_NAMESPACE}.next_step`;
export const BORROWER_REGISTER_NEXT_STEP_SUCCESS = `${REDUCER_NAMESPACE}.set_next_step`;
export const BORROWER_REGISTER_PREVIOUS_STEP = `${REDUCER_NAMESPACE}.previous_step`;
export const BORROWER_REGISTER_PREVIOUS_STEP_SUCCESS = `${REDUCER_NAMESPACE}.set_previous_step`;

const initialState = {
    step: 1,
    submitted: false,
    duplicateEmailError: false,
    errors: [],
    errorsVisible: false,
    useCompositeForm: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case BORROWER_REGISTER_NEXT_STEP:
            return {
                ...state,
                submitted: true,
                errors: [],
                errorsVisible: false,
                duplicateEmailError: false,
            };
        case BORROWER_REGISTER_NEXT_STEP_SUCCESS:
            return {
                ...state,
                submitted: false,
                errors: [],
                errorsVisible: false,
                duplicateEmailError: false,
                step: state.step + 1,
            };
        case BORROWER_REGISTER_PREVIOUS_STEP:
            return {
                ...state,
                submitted: false,
                errors: [],
                errorsVisible: false,
                duplicateEmailError: false,
                step: state.step - 1,
            };
        case BORROWER_REGISTER_PREVIOUS_STEP_SUCCESS:
            return {
                ...state,
                submitted: false,
                errors: [],
                errorsVisible: false,
                duplicateEmailError: false,
                step: state.step - 1,
            };
        case BORROWER_REGISTER_SUBMITTED:
            return {
                ...state,
                submitted: true,
            };
        case BORROWER_REGISTER_STEP_SUBMISSION_ERRORS:
            return {
                ...state,
                submitted: false,
                errors: action.errors,
                errorsVisible: true,
                duplicateEmailError: action.duplicateEmailError,
                useCompositeForm: state.step === 4,
                step: state.step === 4 ? state.step - 1 : state.step,
            };
        case BORROWER_REGISTER_RESET:
            return {
                ...initialState,
            };
        default:
            return { ...state };
    }
};

// Action creators

export const actionBorrowerRegisterNextStep = (input) => {
    return {
        type: BORROWER_REGISTER_NEXT_STEP,
        input,
    };
};

export const actionBorrowerRegisterPreviousStep = (input) => {
    return {
        type: BORROWER_REGISTER_PREVIOUS_STEP,
        input,
    };
};

export const actionBorrowerRegisterSubmitted = (input, acceptedTermsVersion, recaptchaToken) => {
    return {
        type: BORROWER_REGISTER_SUBMITTED,
        input,
        acceptedTermsVersion,
        recaptchaToken,
    };
};

// Selectors
export const selectorBorrowerRegisterStep = (state) => {
    return state[REDUCER_NAMESPACE].step;
};

export const selectorBorrowerRegisterSubmitted = (state) => {
    return state[REDUCER_NAMESPACE].submitted;
};

export const selectorBorrowerRegisterSubmissionErrors = (state) => {
    return state[REDUCER_NAMESPACE].errors;
};

export const selectorDuplicateEmailError = (state) => {
    return state[REDUCER_NAMESPACE].duplicateEmailError;
};

export const selectorAreErrorsVisible = (state) => {
    return state[REDUCER_NAMESPACE].errorsVisible;
};

export const selectorUseCompositeForm = (state) => {
    return state[REDUCER_NAMESPACE].useCompositeForm;
};

// Default export reducer
export default reducer;
