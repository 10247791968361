import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { push } from 'react-router-redux';
import { RollupCommandContainer } from '../../../../pitch4_tabular/components';
import { PrimaryButton } from '../../../../pitch4_layout/components/buttons';
import { handleDownload } from '../../../../pitch4_documents/src/components/DownloadButton';
import { graphql } from 'react-apollo';
import { EnumLabel } from '../../../../pitch4_layout/components/data';
import { ROLE_BORROWER } from '../../../authentication/enum/Roles/NameEnum';
import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import DocumentTypeEnum from '../../../../pitch4_enum/enum/DocumentTypeEnum';
import DeleteDocumentButton from '../components/DeleteDocumentButton';
import AddToUserStoreForm from '../../documents_widget/components/AddToUserStoreForm';
import EditForm from '../components/EditDocumentForm';
import { sagaActionModalHide, sagaActionModalInteractive, sagaActionModalShow } from '../../../modal/sagas/container';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../notifications/sagas/container';
import DownloadDocumentButton from '../components/DownloadDocumentButton';
import filesize from 'filesize';
import { Loading } from '../../../../pitchblack_react_utils/form/renderIfSubmitting';
import { CONTEXT_USER_STORE } from '../../enum/Documents/ContextEnum';
import ModalLayout from '../../../../pitch4_modal/src/component/ModalLayout';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import createP4ApolloFetch from '../../../../bootstrap/middlewares';
import { ROWS_PER_PAGE, PAGE_SIZE } from '../../../../pitch4_enum/enum/DataGridEnum';
import { actionSetJoyrideType } from '../../../joyride/redux';
import HeaderWithCount from '../../../../pitch4_elements/screen-headers/HeaderWithCount';
import Toolbar from '../components/Toolbar';
import { DEFAULT_SEARCH_FILTER_DELAY } from '../../../../pitch4_utils/FiltersDefaults';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

class TableStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFetchError: null,
            searchFilter: '',
            dataGrid: {
                rows: [],
                totalRowCount: 0,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: [{ column: 'createdAt', direction: 'desc' }],
            },
            dataRequest: {
                filters: [],
                sorts: [
                    {
                        column: 'createdAt',
                        direction: 'desc',
                    },
                ],
                pages: [],
                cursor: null,
                storedDataFilters: [],
            },
        };
    }

    componentDidMount() {
        const { setJoyrideType } = this.props;
        const data = this.getData();
        this.processData(data);
        setJoyrideType(null);
    }

    refreshData = () => {
        this.setState(
            {
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages: [], cursor: null },
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    processData(data) {
        const { currentPage } = this.state.dataGrid;
        const { cursor } = this.state.dataRequest;

        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            const documents = data.documents.edges.map((user) => user.node);
            const rows = documents.map((document, index) => {
                return {
                    id: document.id,
                    type: document.type,
                    fileName: document.fileName,
                    fileSize: document.fileSize,
                    mimeType: document.mimeType,
                    createdAt: document.createdAt,
                    updatedAt: document.updatedAt,
                    downloadUrl: document.downloadUrl,
                };
            });

            let pages = this.state.dataRequest.pages;
            pages[currentPage] = {
                cursor: cursor,
                hasNextPage: data.documents.pageInfo.hasNextPage,
                endCursor: data.documents.pageInfo.endCursor,
                startCursor: data.documents.pageInfo.startCursor,
                hasPreviousPage: data.documents.pageInfo.hasPreviousPage,
            };

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: rows,
                    totalRowCount: data.documents.totalCount,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages, cursor: data.documents.pageInfo.endCursor },
                },
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    getData() {
        const query = `
            query getDocuments(
                $cursor: String
                $first: Int!
                $sorts: [SortInput]
                $filters: [FilterInput]
            ) {
                documents(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
                    edges {
                        node {
                            id
                            fileName
                            fileSize
                            mimeType
                            downloadUrl
                            status
                            type
                            createdAt
                            updatedAt
                        }
                        cursor
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                        startCursor
                        hasPreviousPage
                    }
                    totalCount
                }
            }
        `;

        return apolloFetch({
            query: query,
            variables: {
                cursor: this.state.dataRequest.cursor,
                filters: this.state.dataRequest.filters,
                first: this.state.dataGrid.pageSize,
                sorts: this.state.dataRequest.sorts,
                storedDataFilters: this.state.dataRequest.storedDataFilters,
            },
        });
    }

    handlePageChange = (pageConfig) => {
        const previousPage = this.state.dataGrid.currentPage;
        const newPage = pageConfig;
        const { pages } = this.state.dataRequest;

        if (newPage > previousPage) {
            // Next page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[previousPage].endCursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        } else {
            // Previous page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[newPage].cursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handlePageSizeChange = (pageConfig) => {
        this.setState(
            {
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: true,
                    pageSize: pageConfig,
                    rows: [],
                    currentPage: 0,
                    totalRowCount: 0,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    pages: [],
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    buildSearchRequestFilters = () => {
        const { searchFilter } = this.state;
        let searchRequestFilter = [{ operation: 'eq', operand: CONTEXT_USER_STORE, column: 'context', not: false }];
        const searchableFields = ['fileName'];

        if (searchFilter.length > 0) {
            searchRequestFilter = [
                ...searchRequestFilter,
                ...searchableFields.map((field) => {
                    return {
                        operation: 'ilike',
                        operand: '%' + searchFilter + '%',
                        column: field,
                        not: false,
                    };
                }),
            ];
        }

        return searchRequestFilter;
    };

    handleSearchFilterChange = (event) => {
        this.setState(
            {
                searchFilter: event.target.value,
            },
            () => {
                this.setState({
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: [...this.buildSearchRequestFilters()],
                    },
                });
            }
        );

        _.delay(
            (value) => {
                if (this.state.searchFilter === value) {
                    const data = this.getData();
                    this.processData(data);
                }
            },
            DEFAULT_SEARCH_FILTER_DELAY,
            event.target.value
        );
    };

    handleClearAllFilters = () => {
        this.setState(
            {
                searchFilter: '',
                dataGrid: { ...this.state.dataGrid, rows: [] },
                dataRequest: { ...this.state.dataRequest, filters: [], cursor: null },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    handleSortModelChange = (params) => {
        const { sortModel } = this.state.dataGrid;
        const sortableFieldsMapping = {
            type: 'type',
            fileName: 'fileName',
            createdAt: 'createdAt',
            updatedAt: 'updatedAt',
        };

        if (params.sortModel !== null && typeof params.sortModel !== 'undefined' && params.sortModel !== sortModel) {
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        sortModel: params.sortModel,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        sorts: [
                            {
                                column: sortableFieldsMapping[params.sortModel[0].field],
                                direction: params.sortModel[0].sort,
                            },
                        ],
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    openAddDocumentModal = (outerRefetch) => () => {
        let {
            setModalClose,
            setModalComponent,
            setModalInteractive,
            id,
            createMetaMutate,
            successNotification,
            errorNotification,
        } = this.props;

        setModalComponent(
            <ModalLayout title="Add Document">
                <AddToUserStoreForm
                    id={id}
                    onPending={() => setModalInteractive(false)}
                    onSuccess={(message) => {
                        successNotification(message);
                        outerRefetch();
                        setModalClose();
                    }}
                    onError={(error) => {
                        errorNotification(error);
                        setModalInteractive(true);
                    }}
                    createMetaMutate={createMetaMutate}
                />
            </ModalLayout>
        );
    };

    openEditDocumentModal = (outerRefetch, document) => {
        let {
            setModalClose,
            setModalComponent,
            setModalInteractive,
            successNotification,
            errorNotification,
        } = this.props;

        setModalComponent(
            <ModalLayout title="Edit Document">
                <EditForm
                    document={document}
                    onPending={() => setModalInteractive(false)}
                    onError={(error) => {
                        errorNotification('Unable to modify document. Please contact support.');
                        setModalInteractive(true);
                    }}
                    onSuccess={(data) => {
                        successNotification('Document successfully modified');
                        outerRefetch();
                        setModalClose();
                    }}
                />
            </ModalLayout>
        );
    };

    downloadDocument = (document) => {
        const { setModalClose, setModalComponent } = this.props;

        handleDownload(document, {
            onPending: () => setModalComponent(<Loading heading="Please wait. Downloading file." />, false),
            onSuccess: () => setModalClose(),
            onFailure: () => setModalClose(),
        });
    };

    render() {
        const { sortingModel, totalRowCount, dataLoading } = this.state.dataGrid;
        const { searchFilter } = this.state;
        const { successNotification, errorNotification, setModalClose, setModalComponent } = this.props;

        return (
            <div>
                <div className={'le-header-container'}>
                    <div className={'header-text'}>
                        <HeaderWithCount title={'Documents'} count={totalRowCount} countText={'documents'} />
                        <div className={'btn-mobile'}>
                            <Button
                                className={'btn-add'}
                                variant="contained"
                                color="primary"
                                size={'large'}
                                onClick={this.openAddDocumentModal(this.refreshData)}
                            >
                                Add
                            </Button>
                        </div>
                    </div>
                    <Toolbar
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                        handleClearAllFilters={this.handleClearAllFilters}
                        createDocument={this.openAddDocumentModal}
                        refreshData={this.refreshData}
                    />
                </div>

                <div className={`le-table-container`}>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortingModel}
                        paginationMode="server"
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        sortingMode="server"
                        onSortModelChange={this.handleSortModelChange}
                        rowCount={this.state.dataGrid.totalRowCount}
                        pageSize={this.state.dataGrid.pageSize}
                        rowsPerPageOptions={this.state.dataGrid.rowsPerPage}
                        rows={this.state.dataGrid.rows}
                        loading={true}
                        disableColumnFilter={true}
                        disableMultipleColumnsSorting={true}
                        disableColumnReorder={true}
                        disableMultipleColumnsFiltering={true}
                        disableSelectionOnClick={true}
                        disableColumnMenu={true}
                        onCellClick={(params) => {
                            if (params.field !== 'actions') {
                                this.downloadDocument(params.row);
                            }
                        }}
                        components={{
                            LoadingOverlay: () => {
                                return (
                                    <GridOverlay>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {this.state.dataGrid.dataLoading ? (
                                                <LinearProgress />
                                            ) : this.state.dataGrid.rows.length === 0 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        width: '100%',
                                                        paddingTop: 100,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    No data
                                                </div>
                                            ) : null}
                                        </div>
                                    </GridOverlay>
                                );
                            },
                        }}
                        columns={[
                            {
                                field: 'type',
                                headerName: 'TYPE',
                                width: 300,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => (
                                    <EnumLabel enumIn={new DocumentTypeEnum()} value={params.row.type} />
                                ),
                            },
                            {
                                field: 'fileName',
                                headerName: 'NAME',
                                width: 400,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'fileSize',
                                headerName: 'SIZE',
                                width: 100,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                valueFormatter: ({ value }) => filesize(value),
                                sortable: false,
                            },
                            {
                                field: 'mimeType',
                                headerName: 'FILE TYPE',
                                width: 150,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'createdAt',
                                headerName: 'CREATED',
                                width: 170,
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'updatedAt',
                                headerName: 'UPDATED',
                                width: 170,
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'actions',
                                renderHeader: () => <div />,
                                cellClassName: 'le-table-cell le-table-cell-content-right',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                renderCell: (params) => {
                                    return (
                                        <div className={'le-table-cell-context-menu'} style={{ marginTop: 10 }}>
                                            <RollupCommandContainer>
                                                <DownloadDocumentButton
                                                    document={params.row}
                                                    onPending={() =>
                                                        setModalComponent(
                                                            <Loading heading="Please wait. Downloading file." />,
                                                            false
                                                        )
                                                    }
                                                    onSuccess={() => setModalClose()}
                                                    onFailure={() => setModalClose()}
                                                >
                                                    Download
                                                </DownloadDocumentButton>
                                                <PrimaryButton
                                                    onClick={() =>
                                                        this.openEditDocumentModal(this.refreshData, params.row)
                                                    }
                                                >
                                                    Edit
                                                </PrimaryButton>
                                                <DeleteDocumentButton
                                                    outerRefetch={this.refreshData}
                                                    id={params.row.id}
                                                    setModalComponent={setModalComponent}
                                                    setModalClose={setModalClose}
                                                    successNotification={successNotification}
                                                    errorNotification={errorNotification}
                                                    document={params.row}
                                                    onSuccess={(data) => {
                                                        successNotification('Document deleted successfully');
                                                        setModalClose();
                                                        this.refreshData();
                                                    }}
                                                    onError={() => {
                                                        errorNotification(
                                                            'Error has occurred. Please contact support.'
                                                        );
                                                        setModalClose();
                                                    }}
                                                    onPending={() =>
                                                        setModalComponent(
                                                            <Loading heading="Please wait. Removing file." />,
                                                            false
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </DeleteDocumentButton>
                                            </RollupCommandContainer>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        successNotification: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        errorNotification: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    graphql(
        gql`
            mutation CreateDocument($input: borrower_createDocumentInput!) {
                createUserStoreDocument(input: $input) {
                    document {
                        id
                        uploadUrl
                    }
                }
            }
        `,
        {
            options() {
                return {
                    context: {
                        uri: BORROWER_URL,
                    },
                };
            },
            name: 'createMetaMutate',
        }
    ),
    renderErrorIfAuthorisationFail([ROLE_BORROWER])
)(TableStyleContainer);
