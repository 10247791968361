import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckboxMUI from '@material-ui/core/Checkbox/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import _ from 'lodash';

const CheckboxRaw = ({
    input: { name, ...props },
    meta,
    label = '',
    disabled = false,
    inputStyle = {},
    defaultChecked = false,
    autoFocus = false,
    inputProps = {},
    ...rest
}) => {
    const value = props.value ? '1' : '0';

    if (_.includes(['development', 'qa', 'test'], process.env.NODE_ENV)) {
        inputProps['data-cy'] = `${name}-input`;
    }

    return (
        <div className={`checkbox`}>
            <FormControlLabel
                control={
                    <CheckboxMUI
                        {...props}
                        name={name}
                        value={value}
                        disabled={disabled}
                        style={inputStyle}
                        defaultChecked={defaultChecked}
                        autoFocus={autoFocus}
                        inputProps={inputProps}
                    />
                }
                label={label}
                style={{ padding: 0 }}
            />
            {meta.error && meta.touched && <FormHelperText style={{ color: 'red' }}>{meta.error}</FormHelperText>}
        </div>
    );
};

class Checkbox extends React.Component {
    render() {
        let { name = '', label = '', onChangeFunc, autoFocus, ...rest } = this.props;
        const value = this.props.value ? '1' : '0';

        return (
            <div>
                <Field
                    component={CheckboxRaw}
                    id={name}
                    name={name}
                    label={label}
                    onChange={onChangeFunc}
                    value={value}
                    autoFocus={autoFocus}
                    {...rest}
                />
            </div>
        );
    }
}

Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    style: PropTypes.object,
    wrapperStyle: PropTypes.object,
    onChangeFunc: PropTypes.func,
};

export default Checkbox;
