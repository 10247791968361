import createP4ApolloFetch from 'bootstrap/middlewares';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

export const mutationCreate = `
    mutation createLoanEnquiryDocumentPermission($id: [ID!], $input: pitch4admin_createDocumentPermissionInput!) {
      createLoanEnquiryDocumentPermission(id: $id, input: $input) {
        documentPermission {
          id
        }
      }
    }
`;

export const mutationRemove = `
    mutation removeDocumentPermissionsFromLoanEnquiry($id: ID!) {
      removeDocumentPermissionsFromLoanEnquiry(id: $id) {
        deleted
      }
    }
`;

export const createLEDocumentPermission = (docId, leId) => {
    return apolloFetch({
        query: mutationCreate,
        variables: {
            id: [docId],
            input: {
                contextEntity: leId,
            },
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};

export const removeLEDocumentPermission = (docId) => {
    return apolloFetch({
        query: mutationRemove,
        variables: {
            id: docId,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};
