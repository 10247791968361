import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Icon from '../../../assets/images/document-sent-modal-icon.svg';
import { Modal } from '../../../pitch4_modal';
import { PrimaryButton } from '../../../pitchblack_react_utils/form';

class DocumentSentModal extends React.Component {
    render() {
        const { toggleDocumentSentModal, modalShow } = this.props;

        return (
            <div>
                <Modal
                    modalShow={modalShow}
                    handleClose={toggleDocumentSentModal}
                    render={() => (
                        <div className={'document-sent-modal'}>
                            <div className={'icon'}>
                                <img src={Icon} alt={''} />
                            </div>
                            <div className={'header'}>
                                Your Document has been <br />
                                sent to Lenders.
                            </div>
                            <div className={'text'}>
                                Please note you can view documents and messages sent to individual lenders by clicking
                                on them from your Enquiry.
                            </div>
                            <div>
                                <PrimaryButton
                                    label={'Close'}
                                    onClick={() => {
                                        toggleDocumentSentModal();
                                    }}
                                    className={'button primary-button'}
                                />
                            </div>
                        </div>
                    )}
                    modalInteractive={false}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DocumentSentModal);
