export const INVOICE_DISCOUNTING = 'INVOICE_DISCOUNTING';
export const FACTORING = 'INVOICE_FACTORING';
export const SELECTIVE_INVOICE_FINANCE = 'SELECTIVE_INVOICE_FINANCE';
export const SPOT_FACTORING = 'SPOT_FACTORING';
export const I_DO_NOT_KNOW = 'I_DO_NOT_KNOW';
export const I_SOLEMNLY_SWEAR_I_AM_UP_TO_NO_GOOD = 'I_DO_NOT_KNOW';

class InvoiceFinancingTypes {
    getTypes = () => {
        return [
            { value: INVOICE_DISCOUNTING, label: 'Invoice Discounting' },
            { value: FACTORING, label: 'Factoring' },
            { value: SELECTIVE_INVOICE_FINANCE, label: 'Selective Invoice Finance' },
            { value: SPOT_FACTORING, label: 'Spot Factoring' },
            { value: I_SOLEMNLY_SWEAR_I_AM_UP_TO_NO_GOOD, label: "I don't know, show me all available options" },
        ];
    };

    getOptions = () => {
        return {
            [INVOICE_DISCOUNTING]: { value: INVOICE_DISCOUNTING, label: 'Invoice Discounting' },
            [FACTORING]: { value: FACTORING, label: 'Factoring' },
            [SELECTIVE_INVOICE_FINANCE]: { value: SELECTIVE_INVOICE_FINANCE, label: 'Selective Invoice Finance' },
            [SPOT_FACTORING]: { value: SPOT_FACTORING, label: 'Spot Factoring' },
            [I_DO_NOT_KNOW]: { value: I_DO_NOT_KNOW, label: "I don't know, show me all available options" },
        };
    };
}

export default InvoiceFinancingTypes;
