import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import { isEmail, isRequired } from '../../../pitchblack_react_utils/form/validation';
import { CenteringContainer, Container, RightAligned, RowItem } from '../../../pitch4_layout/components/div';
import { TextField } from '../../../pitch4_forms/components/inputs';
import { Paper } from '@material-ui/core';

const emailValidate = [isRequired, isEmail];

class PasswordResetRequestForm extends React.Component {
    render() {
        let { handleSubmit, submitting, onSubmit, hasForgottenPasswordBeenRequested } = this.props;

        let submitFunc = handleSubmit(onSubmit);

        return (
            <Paper style={{ padding: 20 }}>
                <Container>
                    <Container>
                        <h1 className={'heading'}>Request a Password Reset</h1>
                    </Container>
                    <CenteringContainer className={'reset-password'}>
                        <form onSubmit={submitFunc}>
                            <CenteringContainer>
                                <RowItem>
                                    <CenteringContainer>
                                        <TextField
                                            variant={`outlined`}
                                            InputProps={{}}
                                            name={`email`}
                                            label={`Email address`}
                                            disabled={submitting}
                                            validate={emailValidate}
                                        />
                                    </CenteringContainer>
                                </RowItem>
                                <RowItem>
                                    <RightAligned className={'button-panel'}>
                                        <PrimaryButton
                                            type="submit"
                                            disabled={submitting || hasForgottenPasswordBeenRequested}
                                        >
                                            Send me a reset link
                                        </PrimaryButton>
                                    </RightAligned>
                                </RowItem>
                            </CenteringContainer>
                        </form>
                    </CenteringContainer>
                </Container>
            </Paper>
        );
    }
}

PasswordResetRequestForm.propTypes = {
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    hasForgottenPasswordBeenRequested: PropTypes.bool.isRequired,
};

export default compose(
    reduxForm({
        form: 'flag-password-reset',
    })
)(PasswordResetRequestForm);
