import React from 'react';
import { Container, Item } from '../../../../../pitch4_layout/components/div';
import { Switch } from '@material-ui/core';
import moment from 'moment';
import { formatterInPounds } from '../../../../../pitchblack_react_utils/currency/Formatter';

export default function CompletedBar(props) {
    const {
        handleIsInvoiced,
        handleInvoicePaid,
        isInvoicePaid,
        isInvoiced,
        completedDate,
        finalAmount,
        handleCompletedModal,
    } = props;

    return (
        <Container className={`admin-completed-bar`} justifyContent={`space-between`}>
            <Item>
                <span className={'completion-details-header'}>COMPLETION DETAILS</span>
                <br />
                <span className={'completion-details-link'} onClick={() => handleCompletedModal()}>
                    edit
                </span>
            </Item>
            <Item className={'label'}>
                Invoice paid?
                <Switch
                    checked={isInvoicePaid}
                    onChange={(event) => handleInvoicePaid()}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={false}
                    id={'isPaid_'}
                />
            </Item>
            <Item className={'label'}>
                Invoiced?
                <Switch
                    checked={isInvoiced}
                    onChange={(event) => handleIsInvoiced()}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                    disabled={false}
                    id={'invoiced_'}
                />
            </Item>
            <Item className={'label'}>
                Completed:{' '}
                <span className={'value'}>
                    {' '}
                    {null !== completedDate ? `on ${moment(completedDate).format('Do MMM YYYY')}` : '-'}
                </span>
            </Item>
            <Item className={'label'}>
                Final Amount:{' '}
                <span className={'value'}> {null !== finalAmount ? formatterInPounds(finalAmount) : '-'}</span>
            </Item>
        </Container>
    );
}
