import { API_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const GetDashboardDataQuery = `
    query dashboard {
      dashboard {
        chartData {
          year
          activeBorrowers
          activeIntermediaries
          activeLenders
          activeUsers
          lenderEnquiryResponse
          newBorrowerEnquiries
          newIntermediaryEnquiries
          newNonYsIntermediaryEnquiries
          totalBorrowers
          totalIntermediaries
          totalLenders
          totalUsers
        }
        chartStats {
          borrowerLoanEnquiriesToday
          borrowerLoanEnquiriesNewThisMonth
          borrowerLoanEnquiriesEndOfLastMonth
          borrowerLoanEnquiriesIncreaseFromPreviousMonth
          intermediaryLoanEnquiriesToday
          intermediaryLoanEnquiriesNewThisMonth
          intermediaryLoanEnquiriesEndOfLastMonth
          intermediaryLoanEnquiriesIncreaseFromPreviousMonth
          nonYSLoanEnquiriesToday
          nonYSLoanEnquiriesNewThisMonth
          nonYSLoanEnquiriesEndOfLastMonth
          nonYSLoanEnquiriesIncreaseFromPreviousMonth
          borrowersTotal
          borrowersNewThisMonth
          borrowersEndOfLastMonth
          borrowersIncreaseFromPreviousMonth
          intermediaryTotal
          intermediaryNewThisMonth
          intermediaryEndOfLastMonth
          intermediaryIncreaseFromPreviousMonth
          lenderTotal
          lenderNewThisMonth
          lenderEndOfLastMonth
          lenderIncreaseFromPreviousMonth
          userTotal
          userNewThisMonth
          userEndOfLastMonth
          userIncreaseFromPreviousMonth
        }
        filters
        quickStats {
            movedToApplicationLastMonth
            movedToApplicationLast30Days
            movedToCompletedLastMonth
            movedToCompletedLast30Days
        }
      }
    }
`;

export const getDashboardData = () => {
    return apolloFetch({
        query: GetDashboardDataQuery,
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
