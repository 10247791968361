import { BaseEnum } from 'pitch4_enum';

export const TYPE_ENGLAND = 'england';
export const TYPE_SCOTLAND = 'scotland';
export const TYPE_WALES = 'wales';
export const TYPE_NIRELAND = 'northern_ireland';
export const TYPE_OTHER = 'other';

class Country extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_ENGLAND]: 'England',
            [TYPE_SCOTLAND]: 'Scotland',
            [TYPE_NIRELAND]: 'Northern Ireland',
            [TYPE_WALES]: 'Wales',
            [TYPE_OTHER]: 'Other',
        };
    };
}

export default Country;
