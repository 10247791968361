import BaseLevel from './BaseLevel';
import { WARNING_LEVEL } from '../enum/LevelEnum';

class Warning extends BaseLevel {
    constructor(message) {
        super(WARNING_LEVEL, message);
    }
}

export default Warning;
