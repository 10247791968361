import { API_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const assumedIdentityEndGql = `
  mutation DeleteAssumedIdentity{
    deleteAssumedIdentity{
      deleted
    }
  }
`;
export const assumedIdentityEnd = async () => {
    return apolloFetch({
        query: assumedIdentityEndGql,
    });
};
