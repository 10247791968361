import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { reduxForm } from 'redux-form';
import gql from 'graphql-tag';

import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { actionSetValidationErrors, actionSetInitialValues, selectorGetInitialValues } from '../redux/edit';
import { NoQueryMutation } from 'pitch4_forms/components';
import Container from 'pitchblack_react_utils/layout/Container';
import { CenteringContainer } from 'pitchblack_react_utils/layout/index';
import Item from 'pitchblack_react_utils/layout/Item';
import RowItem from 'pitchblack_react_utils/layout/RowItem';
import { TextInput } from 'pitchblack_react_utils/form/FieldHelpers';
import { PrimaryButton } from 'pitch4_layout/components/buttons';

const StyledContainer = styled(Container)``;

const FieldItem = styled(Item)`
    padding: 15px;
`;

const FieldRowItem = styled(RowItem)`
    padding: 30px;
`;

class EditDocumentForm extends React.Component {
    componentDidMount() {
        let { document, setInitialValues } = this.props;
        setInitialValues(document);
    }

    onSubmit = (mutate) => ({ fileName }) => {
        const {
            document: { id },
            onPending,
            onError,
            onSuccess,
        } = this.props;

        onPending();

        //map the variables if necessary
        return mutate({
            variables: {
                id,
                input: {
                    fileName,
                },
            },
        })
            .then(({ data }) => {
                onSuccess(data);
            })
            .catch((error) => {
                onError(error);
            });
    };

    render() {
        return (
            <NoQueryMutation
                mutationQuery={gql`
                    mutation editDocument($id: ID!, $input: pitch4admin_editDocumentInput!) {
                        editDocument(id: $id, input: $input) {
                            document {
                                id
                            }
                        }
                    }
                `}
                renderFormFunction={(mutate, props) => {
                    const { submitting, reset, handleSubmit } = this.props;

                    return (
                        <CenteringContainer>
                            <Item xs={12}>
                                <form onSubmit={handleSubmit(this.onSubmit(mutate, props))}>
                                    <StyledContainer>
                                        <RowItem>
                                            <Container>
                                                <FieldItem xs={12}>
                                                    <TextInput name={'fileName'} label={'File name'} />
                                                </FieldItem>
                                            </Container>
                                        </RowItem>
                                        <FieldRowItem>
                                            <CenteringContainer>
                                                <PrimaryButton type="submit" disabled={submitting}>
                                                    Submit
                                                </PrimaryButton>
                                                <PrimaryButton disabled={submitting} onClick={reset}>
                                                    Reset
                                                </PrimaryButton>
                                            </CenteringContainer>
                                        </FieldRowItem>
                                    </StyledContainer>
                                </form>
                            </Item>
                        </CenteringContainer>
                    );
                }}
            />
        );
    }
}

EditDocumentForm.propTypes = {
    document: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    setValidationErrors: PropTypes.func.isRequired,
    setInitialValues: PropTypes.func.isRequired,
    onPending: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        initialValues: selectorGetInitialValues(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValidationErrors: (errors) => {
            dispatch(actionSetValidationErrors(errors));
        },
        setInitialValues: (values, types) => {
            dispatch(actionSetInitialValues(values, types));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'administer_borrowers_document_edit_form',
        enableReinitialize: true,
        destroyOnUnmount: false,
        pure: true,
        updateUnregisteredFields: false,
    }),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(EditDocumentForm);
