import { BaseEnum } from 'pitch4_enum';

export const TYPE_YES = 'yes';
export const TYPE_NO = 'no';

class YesNoEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_YES]: 'Yes',
            [TYPE_NO]: 'No',
        };
    };
}

export default YesNoEnum;
