import React, { useEffect, useState } from 'react';
import Icon from '../../assets/images/legal-contract-progress-icon.svg';
import { LinearProgress } from '@material-ui/core';

export default function ContractProgressPopup(props) {
    const [init, setInit] = useState(false);
    const { handleLegalContractSigned = () => {} } = props;

    useEffect(() => {
        if (false === init) handleLegalContractSigned();
        setInit(true);
    });

    return (
        <>
            <div className={'contract-progress-container'}>
                <img src={Icon} alt={'icon'} />
                <div className={'header'}>GENERATING YOUR CONTRACT</div>
                <div className={'text'}>
                    We are saving your contract as a PDF which you are free to download from this page.
                </div>
            </div>
            <LinearProgress />
        </>
    );
}
