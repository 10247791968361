import React from 'react';
import Header from '../../../../../pitch4_elements/dashboard/Header';
import { compose } from 'recompose';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import BasicStats from '../components/basic-stats/BasicStatsContainer';
import ChartContainer from '../components/chart/ChartContainer';
import { getDashboardData } from '../api/get_dashboard_data';
import moment from 'moment';
import ListsContainer from '../components/lists/ListsContainer';
import { connect } from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import QuickStatsContainer from '../components/quick-stats/QuickStatsContainer';
import RebrandRedirect from '../../../../../pitch4_elements/popups/RebrandRedirect';
import { BRAND_NAME } from '../../../../../bootstrap/constants/constants';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

class DashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            listExpanded: false,
            basicStats: {
                unassignedEnquiries: 0,
                assignedEnquiries: 0,
                favouritedEnquiries: 0,
                enquiriesWithUpdates: 0,
                newBorrowerIntAccounts: 0,
            },
            chartStats: {
                borrowerLoanEnquiriesEndOfLastMonth: 0,
                borrowerLoanEnquiriesIncreaseFromPreviousMonth: 0,
                borrowerLoanEnquiriesNewThisMonth: 0,
                borrowerLoanEnquiriesToday: 0,
                borrowersEndOfLastMonth: 0,
                borrowersIncreaseFromPreviousMonth: 0,
                borrowersNewThisMonth: 0,
                borrowersTotal: 0,
                intermediaryEndOfLastMonth: 0,
                intermediaryIncreaseFromPreviousMonth: 0,
                intermediaryLoanEnquiriesEndOfLastMonth: 0,
                intermediaryLoanEnquiriesIncreaseFromPreviousMonth: 0,
                intermediaryLoanEnquiriesNewThisMonth: 0,
                intermediaryLoanEnquiriesToday: 0,
                intermediaryNewThisMonth: 0,
                intermediaryTotal: 0,
                lenderEndOfLastMonth: 0,
                lenderIncreaseFromPreviousMonth: 0,
                lenderNewThisMonth: 0,
                lenderTotal: 0,
                nonYSLoanEnquiriesEndOfLastMonth: 0,
                nonYSLoanEnquiriesIncreaseFromPreviousMonth: 0,
                nonYSLoanEnquiriesNewThisMonth: 0,
                nonYSLoanEnquiriesToday: 0,
                userEndOfLastMonth: 0,
                userIncreaseFromPreviousMonth: 0,
                userNewThisMonth: 0,
                userTotal: 0,
                dataUpdated: false,
            },
            chartData: {
                [this.getCurrentYear()]: {
                    // Default starting chart data
                    total_users: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // each value represents a month from Jan to Dec
                    total_borrowers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    total_lenders: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    total_intermediaries: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    active_users: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    active_lenders: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    active_borrowers: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    active_intermediaries: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    new_intermediary_enquiries: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    new_non_ys_intermediary_enquiries: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    new_borrower_enquiries: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                    lender_enquiry_response: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                },
            },
            chartFilters: {
                years: [this.getCurrentYear()], // This is for date range filter select
            },
            tabValue: 0,
            quickStats: {
                movedToApplicationLastMonth: 0,
                movedToApplicationLast30Days: 0,
                movedToCompletedLastMonth: 0,
                movedToCompletedLast30Days: 0,
            },
        };
    }

    componentDidMount() {
        const data = this.getData();

        data.then((resp) => {
            const { dashboard } = resp;

            this.processData(dashboard);
        });
    }

    getData = () => getDashboardData().then((resp) => resp);

    setLEsWithUpdatesCount = (count) =>
        this.setState({
            basicStats: {
                ...this.state.basicStats,
                ...{ enquiriesWithUpdates: count },
            },
        });

    setMyFavouritedEnquiriesCount = (count) =>
        this.setState({
            basicStats: {
                ...this.state.basicStats,
                ...{ favouritedEnquiries: count },
            },
        });

    setNewAccountsCount = (count) =>
        this.setState({
            basicStats: {
                ...this.state.basicStats,
                ...{ newBorrowerIntAccounts: count },
            },
        });

    setUnassignedEnquiriesCount = (count) =>
        this.setState({
            basicStats: {
                ...this.state.basicStats,
                ...{ unassignedEnquiries: count },
            },
        });

    setAssignedEnquiriesCount = (count) =>
        this.setState({
            basicStats: {
                ...this.state.basicStats,
                ...{ assignedEnquiries: count },
            },
        });

    processData = (data) => {
        this.setState({
            chartFilters: {
                ...this.state.chartFilters,
                ...{ years: this.getYearFilter(data.chartData) },
            },
            chartData: this.getChartData(data.chartData),
            chartStats: {
                ...this.state.chartStats,
                ...{
                    borrowerLoanEnquiriesEndOfLastMonth: data.chartStats.borrowerLoanEnquiriesEndOfLastMonth || 0,
                    borrowerLoanEnquiriesIncreaseFromPreviousMonth:
                        data.chartStats.borrowerLoanEnquiriesIncreaseFromPreviousMonth || 0,
                    borrowerLoanEnquiriesNewThisMonth: data.chartStats.borrowerLoanEnquiriesNewThisMonth || 0,
                    borrowerLoanEnquiriesToday: data.chartStats.borrowerLoanEnquiriesToday || 0,
                    borrowersEndOfLastMonth: data.chartStats.borrowersEndOfLastMonth || 0,
                    borrowersIncreaseFromPreviousMonth: data.chartStats.borrowersIncreaseFromPreviousMonth || 0,
                    borrowersNewThisMonth: data.chartStats.borrowersNewThisMonth || 0,
                    borrowersTotal: data.chartStats.borrowersTotal || 0,
                    intermediaryEndOfLastMonth: data.chartStats.intermediaryEndOfLastMonth || 0,
                    intermediaryIncreaseFromPreviousMonth: data.chartStats.intermediaryIncreaseFromPreviousMonth || 0,
                    intermediaryLoanEnquiriesEndOfLastMonth:
                        data.chartStats.intermediaryLoanEnquiriesEndOfLastMonth || 0,
                    intermediaryLoanEnquiriesIncreaseFromPreviousMonth:
                        data.chartStats.intermediaryLoanEnquiriesIncreaseFromPreviousMonth || 0,
                    intermediaryLoanEnquiriesNewThisMonth: data.chartStats.intermediaryLoanEnquiriesNewThisMonth || 0,
                    intermediaryLoanEnquiriesToday: data.chartStats.intermediaryLoanEnquiriesToday || 0,
                    intermediaryNewThisMonth: data.chartStats.intermediaryNewThisMonth || 0,
                    intermediaryTotal: data.chartStats.intermediaryTotal || 0,
                    lenderEndOfLastMonth: data.chartStats.lenderEndOfLastMonth || 0,
                    lenderIncreaseFromPreviousMonth: data.chartStats.lenderIncreaseFromPreviousMonth || 0,
                    lenderNewThisMonth: data.chartStats.lenderNewThisMonth || 0,
                    lenderTotal: data.chartStats.lenderTotal || 0,
                    nonYSLoanEnquiriesEndOfLastMonth: data.chartStats.nonYSLoanEnquiriesEndOfLastMonth || 0,
                    nonYSLoanEnquiriesIncreaseFromPreviousMonth:
                        data.chartStats.nonYSLoanEnquiriesIncreaseFromPreviousMonth || 0,
                    nonYSLoanEnquiriesNewThisMonth: data.chartStats.nonYSLoanEnquiriesNewThisMonth || 0,
                    nonYSLoanEnquiriesToday: data.chartStats.nonYSLoanEnquiriesToday || 0,
                    userEndOfLastMonth: data.chartStats.userEndOfLastMonth || 0,
                    userIncreaseFromPreviousMonth: data.chartStats.userIncreaseFromPreviousMonth || 0,
                    userNewThisMonth: data.chartStats.userNewThisMonth || 0,
                    userTotal: data.chartStats.userTotal || 0,
                    dataUpdated: true, // This is only use to trigger change of chart stats on the dashboard - check src/apps/admin/pitch4admin/dashboard/components/chart/ChartContainer.js
                },
            },
            quickStats: {
                ...this.state.quickStats,
                ...data.quickStats,
            },
        });
    };

    getCurrentYear = () => moment().year();

    getChartData = (data) => {
        let chartData = {};

        data.map(
            (yearData) =>
                (chartData[yearData.year] = {
                    total_users: yearData.totalUsers || 0,
                    total_borrowers: yearData.totalBorrowers || 0,
                    total_lenders: yearData.totalLenders || 0,
                    total_intermediaries: yearData.totalIntermediaries || 0,
                    active_users: yearData.activeUsers || 0,
                    active_lenders: yearData.activeLenders || 0,
                    active_borrowers: yearData.activeBorrowers || 0,
                    active_intermediaries: yearData.activeIntermediaries || 0,
                    new_intermediary_enquiries: yearData.newIntermediaryEnquiries || 0,
                    new_non_ys_intermediary_enquiries: yearData.newNonYsIntermediaryEnquiries || 0,
                    new_borrower_enquiries: yearData.newBorrowerEnquiries || 0,
                    lender_enquiry_response: yearData.lenderEnquiryResponse || 0,
                })
        );

        return chartData;
    };

    getYearFilter = (data) => {
        let yearFilter = [];

        data.map((data) => yearFilter.push(data.year));

        // Sort array ASC - just in case ;-)
        return yearFilter.sort(function (a, b) {
            return a - b;
        });
    };

    setListExpanded = (list) => this.setState({ listExpanded: list });

    render() {
        const { session, dispatch } = this.props;
        const { basicStats, chartStats, chartFilters, chartData, listExpanded } = this.state;
        const currentSession = session.getRelevantSession();
        const handleTabChange = (event, newValue) => {
            this.setState({ tabValue: newValue });
        };

        return (
            <div className={'dashboard-admin'}>
                <RebrandRedirect />
                <div>
                    <Header
                        showHelp={false}
                        user={{
                            name: currentSession.getName(),
                            email: currentSession.getEmail(),
                            type: currentSession.getMostSeniorRole().label,
                        }}
                    />
                </div>
                <BasicStats
                    setListExpanded={this.setListExpanded}
                    unassignedEnquiries={basicStats.unassignedEnquiries}
                    assignedEnquiries={basicStats.assignedEnquiries}
                    favouritedEnquiries={basicStats.favouritedEnquiries}
                    enquiriesWithUpdates={basicStats.enquiriesWithUpdates}
                    newBorrowerIntAccounts={basicStats.newBorrowerIntAccounts}
                />
                <div style={{ paddingTop: 20, backgroundColor: 'white' }}>
                    <hr className={'tabs-indicator-top-line'} />
                    <Tabs
                        value={this.state.tabValue}
                        onChange={handleTabChange}
                        style={{ marginBottom: 20 }}
                        TabIndicatorProps={{
                            children: (
                                <div className={'tab-indicator-container'}>
                                    <div className={'tab-active-indicator'} />
                                </div>
                            ),
                        }}
                    >
                        <Tab label={`${BRAND_NAME} Statistics`} {...a11yProps(0)} className={'tab'} />
                        <Tab label="Loan Enquiry Status Statistics" {...a11yProps(1)} className={'tab'} />
                    </Tabs>
                    <TabPanel value={this.state.tabValue} index={0}>
                        <ChartContainer chartStats={chartStats} chartFilters={chartFilters} chartData={chartData} />
                    </TabPanel>
                    <TabPanel value={this.state.tabValue} index={1}>
                        <QuickStatsContainer data={this.state.quickStats} />
                    </TabPanel>
                </div>
                <ListsContainer
                    listExpanded={listExpanded}
                    setListExpanded={this.setListExpanded}
                    dispatch={dispatch}
                    setLEsWithUpdatesCount={this.setLEsWithUpdatesCount}
                    setMyFavouritedEnquiriesCount={this.setMyFavouritedEnquiriesCount}
                    setNewAccountsCount={this.setNewAccountsCount}
                    setUnassignedEnquiriesCount={this.setUnassignedEnquiriesCount}
                    setAssignedEnquiriesCount={this.setAssignedEnquiriesCount}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(withSession, connect(mapStateToProps, mapDispatchToProps))(DashboardContainer);
