import React from 'react';
import { compose } from 'recompose';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';

const actionsStyles = (theme) => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(1) * 2.5,
    },
});

export const PAGINATION_START = '|<';
export const PAGINATION_NEXT = '>';
export const PAGINATION_PREV = '<';

class TablePaginationControls extends React.Component {
    handleFirstPageButtonClick = (event) => {
        let { onChangePage } = this.props;
        onChangePage(event, PAGINATION_START);
    };

    handleBackButtonClick = (event) => {
        let { onChangePage } = this.props;
        onChangePage(event, PAGINATION_PREV);
    };

    handleNextButtonClick = (event) => {
        let { onChangePage } = this.props;
        onChangePage(event, PAGINATION_NEXT);
    };

    render() {
        const { classes = {}, count, page, rowsPerPage, theme } = this.props;

        return (
            <div className={!_.isEmpty(classes) ? classes.root : ''}>
                <IconButton onClick={this.handleFirstPageButtonClick} disabled={page === 0} aria-label="First Page">
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton onClick={this.handleBackButtonClick} disabled={page === 0} aria-label="Previous Page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
            </div>
        );
    }
}

TablePaginationControls.propTypes = {
    classes: PropTypes.object.isRequired,
    count: PropTypes.any.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.any.isRequired,
    rowsPerPage: PropTypes.any.isRequired,
    theme: PropTypes.object.isRequired,
};

export default compose(withStyles(actionsStyles, { withTheme: true }))(TablePaginationControls);
