import React from 'react';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Paper from '@material-ui/core/Paper';
import {
    path,
    ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY,
    ROUTE_INTERMEDIARY_DOCUMENTS_VIEW,
    ROUTE_INTERMEDIARY_LOAN_ENQUIRIES,
    ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW,
    ROUTE_LANDING,
    ROUTE_PITCH4_BLOG,
    ROUTE_PITCH4_KNOWLEDGE_BASE_NEW_TEAM_MEMBER_INTERMEDIARY,
    ROUTE_PITCH4_KNOWLEDGE_BASE_CREATE_ENQUIRY,
    ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY,
} from '../../../../../pitch4_routing';
import { actionSetJoyrideType } from '../../../../joyride/redux';
import { Grid } from '@material-ui/core';
import Header from '../../../../../pitch4_elements/dashboard/Header';
import LECard from '../../../../../pitch4_elements/dashboard/LECard';
import LECardSkeleton from '../../../../../pitch4_elements/dashboard/LECardSkeleton';
import LEEmptyCard from '../../../../../pitch4_elements/dashboard/LEEmptyCard';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import StarIcon from '../../../../../assets/images/star.svg';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import RouteManagerFactory from '../../../../../pitch4_routing/src/RouteManagerFactory';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { BRAND_NAME, INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import HelpIcon from '@material-ui/icons/Help';
import BuildIcon from '@material-ui/icons/Build';
import EmptyRecentIcon from '../../../../../assets/images/dashboards/intermediary/empty-recent-icon.svg';
import P4Logo from '../../../../../assets/images/logo.svg';
import RebrandRedirect from '../../../../../pitch4_elements/popups/RebrandRedirect';

const routeManagerFactory = new RouteManagerFactory();
const routeManager = routeManagerFactory.create();
const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });

class DashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: null,
            draftLEs: null,
            favouritedLEs: null,
            recentlyUpdatedLEs: null,
            recentlyAddedLEs: null,
            completedLoanEnquiries: 0,
            activeLoanEnquiries: 0,
            totalLoanEnquiryUpdates: 0,
        };
    }

    componentDidMount() {
        const { session, redirect, setJoyrideType } = this.props;

        if (!session.isAuthenticated()) {
            redirect(ROUTE_LANDING);
        }

        setJoyrideType(null);

        this.getData().then((resp) => {
            let expandedSection = 'panel-favourites';
            if (resp.data.dashboard.loanEnquiry.favourites.length === 0) {
                expandedSection = 'panel-drafts';
            }
            if (resp.data.dashboard.loanEnquiry.drafts.length === 0) {
                expandedSection = 'panel-recently-updated';
            }

            this.setState({
                draftLEs: resp.data.dashboard.loanEnquiry.drafts,
                favouritedLEs: resp.data.dashboard.loanEnquiry.favourites,
                recentlyUpdatedLEs: resp.data.dashboard.loanEnquiry.updated,
                recentlyAddedLEs: resp.data.dashboard.loanEnquiry.created,
                expanded: expandedSection,
                completedLoanEnquiries: resp.data.dashboard.completedLoanEnquiries,
                activeLoanEnquiries: resp.data.dashboard.activeLoanEnquiries,
                totalLoanEnquiryUpdates: resp.data.dashboard.totalLoanEnquiryUpdates,
            });
        });
    }

    getData() {
        const query = `
            query dashboard {
              dashboard {
                loanEnquiry {
                  updated {
                    id
                    alertActive
                    status
                    favourite
                    createdAt
                    updatedAt
                    lenderLoanEnquiries{
                      id
                    }
                    loanEnquiryApplicants {
                        isMainApplicant
                        applicant {
                            given
                            family
                            dateOfBirth
                        }
                    }
                    submittedBy {
                        userProfile {
                            given
                            family
                        }
                    }
                    loanEnquiryApplicants {
                      isMainApplicant
                      applicant {
                        given
                        family
                      }
                    }
                    submittedBy {
                      userProfile {
                          title
                          given
                          family
                      }
                    }
                    isNewForm
                    loanEnquiryData {
                        loanToValue
                        loanToCost
                        loanToGrossDevelopmentValue
                        dayOneLoanAmountPercentage
                        additionalInformation
                        rentalIncomeResidential
                        hmoBedroom
                        hmoRoomsSelfContained
                        hmoHasLicense
                        hmoNeedsLicense
                        buyToLetExperience
                        rentalIncomeCommercial
                        rentalIncomePortfolio
                        rentalIncome
                        commercialUnitsRentedOut
                        commercialUnitOwnerOccupied
                        commercialUnitAmount
                        commercialType
                        commercialTypeOther
                        siteDescription
                        siteIntention
                        refinance
                        businessSeasonal
                        businessType
                        businessName
                        businessWebsite
                        businessTradingLength
                        businessSector
                        businessFacilityNeededBy
                        businessFacilityTerm
                        businessCurrentPaymentTerms
                        businessOutstandingInvoices
                        businessPdqIncome
                        propertyType
                        loanAmount
                        loanPurpose
                        turnOver
                        businessProfitable
                        businessOverdraftFacility
                        businessOutstandingDebt
                        businessAdverseCredit
                        businessAdverseCreditExplanation
                        businessDirectorsAdverseCredit
                        businessDirectorsAdverseCreditExplanation
                        shareholdersAreHomeOwners
                        propertyAvailableForAdditionalSecurity
                        planningFor
                        developmentLength
                        refurbishmentLength
                        outstandingLoan
                        dayOneLoanAmount
                        unitsPreSold
                        worksExplanation
                        totalDevelopmentCosts
                        buildCostsBorrowing
                        expectedGrossDevelopmentValue
                        previousDevelopmentExperience
                        previousRefurbishmentExperience
                        teamDevelopmentExperience
                        epcRating
                        loanType
                        fundingType
                        businessSecuredFinance
                        loanUsedInUkEu
                        planningInPlace
                        endValue
                        percentageResidential
                        percentageCommercial
                        lender
                        firstChargeAmount
                        currentValue
                        properties
                        portfolioValue
                        portfolioAlreadyOwned
                        loanTerm
                        loanNeededBy
                        preApproveMatches
                        loanAmountRefurbishment
                        percentageResidentialSquareFeet
                        percentageResidentialValue
                        percentageCommercialValue
                        residentialUnitCount
                        residentialUnitValue
                        businessOccupyEntireProperty
                        commercialUnitRentBreakdown
                        commercialTenantAmount
                        ownsOtherCommercialProperty
                        ownOtherProperties
                        occupyProperty
                        ownsOtherInvestmentProperties
                        familyHasOccupied
                        secondCharge
                        occupyingGreaterThan40Percent
                        location
                        locationOther
                        address
                        postcode
                        propertyOwnership
                        refurbishmentCost
                        invoiceFinanceType
                        leaseHirePurchase
                        mainApplicantCreditDifficulties
                        mainApplicantCreditDifficultiesExplanation
                        mainApplicantCitizenshipStatus
                        mainApplicantCountryOfResidence
                        mainApplicantCountryOfResidenceOther
                        mainApplicantNationality
                        mainApplicantNationalityOther
                        mainApplicantOccupation
                        mainApplicantGrossIncome
                        mainApplicantExitStrategy
                        agreeToTerms
                    }
                    storedData{
                      meta{
                        key
                      }
                      value
                    }
                  }
                  created {
                    id
                    alertActive
                    status
                    createdAt
                    updatedAt
                    favourite
                    lenderLoanEnquiries{
                      id
                    }
                    loanEnquiryApplicants {
                        isMainApplicant
                        applicant {
                            given
                            family
                            dateOfBirth
                        }
                    }
                    submittedBy {
                        userProfile {
                            given
                            family
                        }
                    }
                    isNewForm
                    loanEnquiryData {
                        loanToValue
                        loanToCost
                        loanToGrossDevelopmentValue
                        dayOneLoanAmountPercentage
                        additionalInformation
                        rentalIncomeResidential
                        hmoBedroom
                        hmoRoomsSelfContained
                        hmoHasLicense
                        hmoNeedsLicense
                        buyToLetExperience
                        rentalIncomeCommercial
                        rentalIncomePortfolio
                        rentalIncome
                        commercialUnitsRentedOut
                        commercialUnitOwnerOccupied
                        commercialUnitAmount
                        commercialType
                        commercialTypeOther
                        siteDescription
                        siteIntention
                        refinance
                        businessSeasonal
                        businessType
                        businessName
                        businessWebsite
                        businessTradingLength
                        businessSector
                        businessFacilityNeededBy
                        businessFacilityTerm
                        businessCurrentPaymentTerms
                        businessOutstandingInvoices
                        businessPdqIncome
                        propertyType
                        loanAmount
                        loanPurpose
                        turnOver
                        businessProfitable
                        businessOverdraftFacility
                        businessOutstandingDebt
                        businessAdverseCredit
                        businessAdverseCreditExplanation
                        businessDirectorsAdverseCredit
                        businessDirectorsAdverseCreditExplanation
                        shareholdersAreHomeOwners
                        propertyAvailableForAdditionalSecurity
                        planningFor
                        developmentLength
                        refurbishmentLength
                        outstandingLoan
                        dayOneLoanAmount
                        unitsPreSold
                        worksExplanation
                        totalDevelopmentCosts
                        buildCostsBorrowing
                        expectedGrossDevelopmentValue
                        previousDevelopmentExperience
                        previousRefurbishmentExperience
                        teamDevelopmentExperience
                        epcRating
                        loanType
                        fundingType
                        businessSecuredFinance
                        loanUsedInUkEu
                        planningInPlace
                        endValue
                        percentageResidential
                        percentageCommercial
                        lender
                        firstChargeAmount
                        currentValue
                        properties
                        portfolioValue
                        portfolioAlreadyOwned
                        loanTerm
                        loanNeededBy
                        preApproveMatches
                        loanAmountRefurbishment
                        percentageResidentialSquareFeet
                        percentageResidentialValue
                        percentageCommercialValue
                        residentialUnitCount
                        residentialUnitValue
                        businessOccupyEntireProperty
                        commercialUnitRentBreakdown
                        commercialTenantAmount
                        ownsOtherCommercialProperty
                        ownOtherProperties
                        occupyProperty
                        ownsOtherInvestmentProperties
                        familyHasOccupied
                        secondCharge
                        occupyingGreaterThan40Percent
                        location
                        locationOther
                        address
                        postcode
                        propertyOwnership
                        refurbishmentCost
                        invoiceFinanceType
                        leaseHirePurchase
                        mainApplicantCreditDifficulties
                        mainApplicantCreditDifficultiesExplanation
                        mainApplicantCitizenshipStatus
                        mainApplicantCountryOfResidence
                        mainApplicantCountryOfResidenceOther
                        mainApplicantNationality
                        mainApplicantNationalityOther
                        mainApplicantOccupation
                        mainApplicantGrossIncome
                        mainApplicantExitStrategy
                        agreeToTerms
                    }
                    storedData{
                      meta{
                        key
                      }
                      value
                    }
                  }
                  favourites {
                    id
                    alertActive
                    status
                    favourite
                    createdAt
                    updatedAt
                    lenderLoanEnquiries{
                      id
                    }
                    loanEnquiryApplicants {
                        isMainApplicant
                        applicant {
                            given
                            family
                            dateOfBirth
                        }
                    }
                    submittedBy {
                        userProfile {
                            given
                            family
                        }
                    }
                    isNewForm
                    loanEnquiryData {
                        loanToValue
                        loanToCost
                        loanToGrossDevelopmentValue
                        dayOneLoanAmountPercentage
                        additionalInformation
                        rentalIncomeResidential
                        hmoBedroom
                        hmoRoomsSelfContained
                        hmoHasLicense
                        hmoNeedsLicense
                        buyToLetExperience
                        rentalIncomeCommercial
                        rentalIncomePortfolio
                        rentalIncome
                        commercialUnitsRentedOut
                        commercialUnitOwnerOccupied
                        commercialUnitAmount
                        commercialType
                        commercialTypeOther
                        siteDescription
                        siteIntention
                        refinance
                        businessSeasonal
                        businessType
                        businessName
                        businessWebsite
                        businessTradingLength
                        businessSector
                        businessFacilityNeededBy
                        businessFacilityTerm
                        businessCurrentPaymentTerms
                        businessOutstandingInvoices
                        businessPdqIncome
                        propertyType
                        loanAmount
                        loanPurpose
                        turnOver
                        businessProfitable
                        businessOverdraftFacility
                        businessOutstandingDebt
                        businessAdverseCredit
                        businessAdverseCreditExplanation
                        businessDirectorsAdverseCredit
                        businessDirectorsAdverseCreditExplanation
                        shareholdersAreHomeOwners
                        propertyAvailableForAdditionalSecurity
                        planningFor
                        developmentLength
                        refurbishmentLength
                        outstandingLoan
                        dayOneLoanAmount
                        unitsPreSold
                        worksExplanation
                        totalDevelopmentCosts
                        buildCostsBorrowing
                        expectedGrossDevelopmentValue
                        previousDevelopmentExperience
                        previousRefurbishmentExperience
                        teamDevelopmentExperience
                        epcRating
                        loanType
                        fundingType
                        businessSecuredFinance
                        loanUsedInUkEu
                        planningInPlace
                        endValue
                        percentageResidential
                        percentageCommercial
                        lender
                        firstChargeAmount
                        currentValue
                        properties
                        portfolioValue
                        portfolioAlreadyOwned
                        loanTerm
                        loanNeededBy
                        preApproveMatches
                        loanAmountRefurbishment
                        percentageResidentialSquareFeet
                        percentageResidentialValue
                        percentageCommercialValue
                        residentialUnitCount
                        residentialUnitValue
                        businessOccupyEntireProperty
                        commercialUnitRentBreakdown
                        commercialTenantAmount
                        ownsOtherCommercialProperty
                        ownOtherProperties
                        occupyProperty
                        ownsOtherInvestmentProperties
                        familyHasOccupied
                        secondCharge
                        occupyingGreaterThan40Percent
                        location
                        locationOther
                        address
                        postcode
                        propertyOwnership
                        refurbishmentCost
                        invoiceFinanceType
                        leaseHirePurchase
                        mainApplicantCreditDifficulties
                        mainApplicantCreditDifficultiesExplanation
                        mainApplicantCitizenshipStatus
                        mainApplicantCountryOfResidence
                        mainApplicantCountryOfResidenceOther
                        mainApplicantNationality
                        mainApplicantNationalityOther
                        mainApplicantOccupation
                        mainApplicantGrossIncome
                        mainApplicantExitStrategy
                        agreeToTerms
                    }
                    storedData{
                      meta{
                        key
                      }
                      value
                    }
                  }
                  drafts {
                    id
                    alertActive
                    status
                    favourite
                    createdAt
                    updatedAt
                    lenderLoanEnquiries{
                      id
                    }
                    loanEnquiryApplicants {
                        isMainApplicant
                        applicant {
                            given
                            family
                            dateOfBirth
                        }
                    }
                    submittedBy {
                        userProfile {
                            given
                            family
                        }
                    }
                    isNewForm
                    loanEnquiryData {
                        loanToValue
                        loanToCost
                        loanToGrossDevelopmentValue
                        dayOneLoanAmountPercentage
                        additionalInformation
                        rentalIncomeResidential
                        hmoBedroom
                        hmoRoomsSelfContained
                        hmoHasLicense
                        hmoNeedsLicense
                        buyToLetExperience
                        rentalIncomeCommercial
                        rentalIncomePortfolio
                        rentalIncome
                        commercialUnitsRentedOut
                        commercialUnitOwnerOccupied
                        commercialUnitAmount
                        commercialType
                        commercialTypeOther
                        siteDescription
                        siteIntention
                        refinance
                        businessSeasonal
                        businessType
                        businessName
                        businessWebsite
                        businessTradingLength
                        businessSector
                        businessFacilityNeededBy
                        businessFacilityTerm
                        businessCurrentPaymentTerms
                        businessOutstandingInvoices
                        businessPdqIncome
                        propertyType
                        loanAmount
                        loanPurpose
                        turnOver
                        businessProfitable
                        businessOverdraftFacility
                        businessOutstandingDebt
                        businessAdverseCredit
                        businessAdverseCreditExplanation
                        businessDirectorsAdverseCredit
                        businessDirectorsAdverseCreditExplanation
                        shareholdersAreHomeOwners
                        propertyAvailableForAdditionalSecurity
                        planningFor
                        developmentLength
                        refurbishmentLength
                        outstandingLoan
                        dayOneLoanAmount
                        unitsPreSold
                        worksExplanation
                        totalDevelopmentCosts
                        buildCostsBorrowing
                        expectedGrossDevelopmentValue
                        previousDevelopmentExperience
                        previousRefurbishmentExperience
                        teamDevelopmentExperience
                        epcRating
                        loanType
                        fundingType
                        businessSecuredFinance
                        loanUsedInUkEu
                        planningInPlace
                        endValue
                        percentageResidential
                        percentageCommercial
                        lender
                        firstChargeAmount
                        currentValue
                        properties
                        portfolioValue
                        portfolioAlreadyOwned
                        loanTerm
                        loanNeededBy
                        preApproveMatches
                        loanAmountRefurbishment
                        percentageResidentialSquareFeet
                        percentageResidentialValue
                        percentageCommercialValue
                        residentialUnitCount
                        residentialUnitValue
                        businessOccupyEntireProperty
                        commercialUnitRentBreakdown
                        commercialTenantAmount
                        ownsOtherCommercialProperty
                        ownOtherProperties
                        occupyProperty
                        ownsOtherInvestmentProperties
                        familyHasOccupied
                        secondCharge
                        occupyingGreaterThan40Percent
                        location
                        locationOther
                        address
                        postcode
                        propertyOwnership
                        refurbishmentCost
                        invoiceFinanceType
                        leaseHirePurchase
                        mainApplicantCreditDifficulties
                        mainApplicantCreditDifficultiesExplanation
                        mainApplicantCitizenshipStatus
                        mainApplicantCountryOfResidence
                        mainApplicantCountryOfResidenceOther
                        mainApplicantNationality
                        mainApplicantNationalityOther
                        mainApplicantOccupation
                        mainApplicantGrossIncome
                        mainApplicantExitStrategy
                        agreeToTerms
                    }
                    storedData{
                      meta{
                        key
                      }
                      value
                    }
                  }
                }
                completedLoanEnquiries
                activeLoanEnquiries
                totalLoanEnquiryUpdates
              }
            }
        `;

        return apolloFetch({
            query: query,
        });
    }

    render() {
        const { session, dispatch, redirect } = this.props;
        const {
            expanded,
            draftLEs,
            favouritedLEs,
            recentlyUpdatedLEs,
            recentlyAddedLEs,
            activeLoanEnquiries,
            totalLoanEnquiryUpdates,
            completedLoanEnquiries,
        } = this.state;
        const currentSession = session.getRelevantSession();
        // Generate empty LE spots
        const buildEmptyLES = (loanEnquiries, count = 6) => {
            let emptyLEs = [];

            if (loanEnquiries) {
                if (loanEnquiries.length < count) {
                    for (let i = 0; i < count - loanEnquiries.length; i++) {
                        emptyLEs.push(
                            <LEEmptyCard url={ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY} label={'ADD A NEW ENQUIRY'} />
                        );
                    }
                }
            }

            return emptyLEs;
        };
        const handleChange = (panel) => (event, isExpanded) => {
            this.setState({ expanded: isExpanded ? panel : false });
        };

        return (
            <div className={'dashboard-intermediary'}>
                <RebrandRedirect />
                <div>
                    <Header
                        user={{
                            name: currentSession.getName(),
                            email: currentSession.getEmail(),
                            type: currentSession.getMostSeniorRole().label,
                        }}
                    />
                </div>
                <Grid container className={'main-container'}>
                    <Grid container style={{ marginTop: 20 }}>
                        <Grid lg={10} md={10} xs={12} sm={12}>
                            <div className={'main-le'} style={{ padding: 0 }}>
                                <Accordion
                                    className={'favourited'}
                                    elevation={1}
                                    expanded={expanded === 'panel-favourites'}
                                    onChange={handleChange('panel-favourites')}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-favourites-content"
                                        id="panel-favourites-header"
                                        className={'header'}
                                    >
                                        Your Favourited Enquiries:
                                        <img style={{ marginLeft: 5 }} src={StarIcon} alt={''} />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {favouritedLEs ? (
                                            <Grid container spacing={4} style={{ justifyContent: 'center' }}>
                                                {favouritedLEs &&
                                                    favouritedLEs.map((le, index) => {
                                                        // Render only first three LEs
                                                        if (index <= 2) {
                                                            return (
                                                                <Grid item lg={4} md={4} xs={12}>
                                                                    <Paper
                                                                        elevation={0}
                                                                        className={'other-le'}
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                push(
                                                                                    path(
                                                                                        ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW,
                                                                                        {
                                                                                            loanEnquiryId: le.id,
                                                                                        }
                                                                                    )
                                                                                )
                                                                            );
                                                                        }}
                                                                    >
                                                                        <LECard intermediary le={le} />
                                                                    </Paper>
                                                                </Grid>
                                                            );
                                                        }

                                                        return null;
                                                    })}
                                                {favouritedLEs && favouritedLEs.length === 0 ? (
                                                    <div className={'no-favourites'}>
                                                        <img src={EmptyRecentIcon} className={'icon'} alt={''} />
                                                        <div className={'header'}>
                                                            Favourited Enquiries will appear here!
                                                        </div>
                                                        <div className={'text'}>
                                                            You can favourite Enquiries
                                                            <br /> from the List View
                                                        </div>
                                                    </div>
                                                ) : favouritedLEs && buildEmptyLES(favouritedLEs, 3).length > 0 ? (
                                                    buildEmptyLES(favouritedLEs, 3).map((item) => item)
                                                ) : null}
                                            </Grid>
                                        ) : (
                                            <Grid container>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginLeft: 10,
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </AccordionDetails>
                                    {favouritedLEs && favouritedLEs.length > 0 && (
                                        <>
                                            <Divider />
                                            <AccordionActions style={{ justifyContent: 'flex-start' }}>
                                                <a className={'view-all-link'} href={ROUTE_INTERMEDIARY_LOAN_ENQUIRIES}>
                                                    VIEW ALL
                                                </a>
                                            </AccordionActions>
                                        </>
                                    )}
                                </Accordion>
                                <Accordion
                                    className={'drafts'}
                                    elevation={1}
                                    expanded={expanded === 'panel-drafts'}
                                    onChange={handleChange('panel-drafts')}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-drafts-content"
                                        id="panel-drafts-header"
                                        className={'header'}
                                    >
                                        Draft Enquiries
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {draftLEs ? (
                                            <Grid container spacing={4} style={{ justifyContent: 'center' }}>
                                                {draftLEs &&
                                                    draftLEs.map((le, index) => (
                                                        <Grid item lg={4} md={4} xs={12}>
                                                            <Paper
                                                                elevation={0}
                                                                className={'other-le'}
                                                                onClick={() => {
                                                                    dispatch(
                                                                        push(
                                                                            path(ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY, {
                                                                                loanEnquiryId: le.id,
                                                                            })
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <LECard intermediary le={le} />
                                                            </Paper>
                                                        </Grid>
                                                    ))}
                                                {draftLEs &&
                                                    buildEmptyLES(draftLEs, 3).length > 0 &&
                                                    buildEmptyLES(draftLEs, 3).map((item) => item)}
                                            </Grid>
                                        ) : (
                                            <Grid container>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginLeft: 10,
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </AccordionDetails>
                                    <Divider />
                                    <AccordionActions style={{ justifyContent: 'flex-start' }}>
                                        <a className={'view-all-link'} href={ROUTE_INTERMEDIARY_LOAN_ENQUIRIES}>
                                            VIEW ALL
                                        </a>
                                    </AccordionActions>
                                </Accordion>
                                <Accordion
                                    className={'recently-updated'}
                                    elevation={1}
                                    expanded={expanded === 'panel-recently-updated'}
                                    onChange={handleChange('panel-recently-updated')}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-recently-updated-content"
                                        id="panel-recently-updated-header"
                                        className={'header'}
                                    >
                                        Recently Updated Enquiries
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {recentlyUpdatedLEs ? (
                                            <Grid container spacing={4} style={{ justifyContent: 'center' }}>
                                                {recentlyUpdatedLEs &&
                                                    recentlyUpdatedLEs.map((le, index) => (
                                                        <Grid item lg={4} md={4} xs={12}>
                                                            <Paper
                                                                elevation={0}
                                                                className={'other-le'}
                                                                onClick={() => {
                                                                    dispatch(
                                                                        push(
                                                                            path(ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW, {
                                                                                loanEnquiryId: le.id,
                                                                            })
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <LECard intermediary le={le} />
                                                            </Paper>
                                                        </Grid>
                                                    ))}
                                                {recentlyUpdatedLEs &&
                                                    buildEmptyLES(recentlyUpdatedLEs).length > 0 &&
                                                    buildEmptyLES(recentlyUpdatedLEs).map((item) => item)}
                                            </Grid>
                                        ) : (
                                            <Grid container>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginLeft: 10,
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </AccordionDetails>
                                    <Divider />
                                    <AccordionActions style={{ justifyContent: 'flex-start' }}>
                                        <a className={'view-all-link'} href={ROUTE_INTERMEDIARY_LOAN_ENQUIRIES}>
                                            VIEW ALL
                                        </a>
                                    </AccordionActions>
                                </Accordion>
                                <Accordion
                                    className={'recently-added'}
                                    elevation={1}
                                    expanded={expanded === 'panel-recently-added'}
                                    onChange={handleChange('panel-recently-added')}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-recently-added-content"
                                        id="panel-recently-added-header"
                                        className={'header'}
                                    >
                                        Recently Added Enquiries
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {recentlyAddedLEs ? (
                                            <Grid container spacing={4} style={{ justifyContent: 'center' }}>
                                                {recentlyAddedLEs &&
                                                    recentlyAddedLEs.map((le, index) => (
                                                        <Grid item lg={4} md={4} xs={12}>
                                                            <Paper
                                                                elevation={0}
                                                                className={'other-le'}
                                                                onClick={() => {
                                                                    dispatch(
                                                                        push(
                                                                            path(ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW, {
                                                                                loanEnquiryId: le.id,
                                                                            })
                                                                        )
                                                                    );
                                                                }}
                                                            >
                                                                <LECard intermediary le={le} />
                                                            </Paper>
                                                        </Grid>
                                                    ))}
                                                {recentlyAddedLEs &&
                                                    buildEmptyLES(recentlyAddedLEs).length > 0 &&
                                                    buildEmptyLES(recentlyAddedLEs).map((item) => item)}
                                            </Grid>
                                        ) : (
                                            <Grid container>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginLeft: 10,
                                                            marginRight: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={12}>
                                                    <Paper
                                                        className={'other-le-skeleton'}
                                                        elevation={0}
                                                        style={{
                                                            marginLeft: 10,
                                                        }}
                                                    >
                                                        <LECardSkeleton height={175} />
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </AccordionDetails>
                                    <Divider />
                                    <AccordionActions className={'actions'} style={{ justifyContent: 'flex-start' }}>
                                        <a className={'view-all-link'} href={ROUTE_INTERMEDIARY_LOAN_ENQUIRIES}>
                                            VIEW ALL
                                        </a>
                                    </AccordionActions>
                                </Accordion>
                            </div>
                        </Grid>
                        <Grid lg={2} md={2} xs={12} sm={12} className={'right-column'}>
                            {false === currentSession.belongsToBrokerNetwork() && (
                                <Paper className={'powered-by'}>
                                    <Grid container>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <div className={'text'}>Powered by</div>
                                            <div className={'logo'}>
                                                <img src={P4Logo} alt={''} />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            )}
                            <Paper className={'stats'}>
                                <Grid container>
                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                        <div
                                            className={'active-le'}
                                            style={{ display: 'flex', flexDirection: 'column' }}
                                        >
                                            <span className={'number'}>{activeLoanEnquiries}</span>
                                            <span className={'label'}>Active Enquiries</span>
                                        </div>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                        <div
                                            className={'le-updates'}
                                            style={{ display: 'flex', flexDirection: 'column' }}
                                        >
                                            <span className={'number'}>{totalLoanEnquiryUpdates}</span>
                                            <span className={'label'}>Enquiry Updates</span>
                                        </div>
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} xs={4}>
                                        <div
                                            className={'completed-le'}
                                            style={{ display: 'flex', flexDirection: 'column' }}
                                        >
                                            <span className={'number'}>{completedLoanEnquiries}</span>
                                            <span className={'label'}>Completed Enquiries</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Paper className={'toolbox'}>
                                <div className={'header'}>
                                    <BuildIcon className={'icon'} /> Your Toolbox
                                </div>
                                <div
                                    className={'item'}
                                    onClick={() => redirect(routeManager.route(ROUTE_INTERMEDIARY_LOAN_ENQUIRIES))}
                                >
                                    View <strong>All Enquiries</strong>
                                </div>
                                <div
                                    className={'item'}
                                    onClick={() => redirect(routeManager.route(ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY))}
                                >
                                    Add <strong>New Enquiry</strong>
                                </div>
                                <div
                                    className={'item'}
                                    onClick={() => redirect(routeManager.route(ROUTE_INTERMEDIARY_DOCUMENTS_VIEW))}
                                >
                                    Manage <strong>My Documents</strong>
                                </div>
                                <div className={'item'} onClick={() => window.HubSpotConversations.widget.open()}>
                                    Speak to <strong>Admin</strong>
                                </div>
                            </Paper>
                            <Paper className={'resources'}>
                                <div className={'header'}>
                                    <HelpIcon className={'icon'} />
                                    Resources
                                </div>
                                <div className={'item'}>
                                    <a
                                        href={ROUTE_PITCH4_KNOWLEDGE_BASE_NEW_TEAM_MEMBER_INTERMEDIARY}
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                    >
                                        How to Add a Team Member
                                    </a>
                                </div>
                                <div className={'item'}>
                                    <a
                                        href={ROUTE_PITCH4_KNOWLEDGE_BASE_CREATE_ENQUIRY}
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                    >
                                        Learn how to use {BRAND_NAME}
                                    </a>
                                </div>
                                <div className={'item'}>
                                    <a href={ROUTE_PITCH4_BLOG} rel={'noreferrer'} target={'_blank'}>
                                        Read the latest from our Blog
                                    </a>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => dispatch(push(url)),
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(withSession, connect(mapStateToProps, mapDispatchToProps))(DashboardContainer);
