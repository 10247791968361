class EditMapper {
    mapToForm = (props) => {
        let { fileName } = props;

        return {
            fileName,
        };
    };

    mapFromForm = ({ fileName = '' }) => {
        return {
            fileName,
        };
    };
}

export default EditMapper;
