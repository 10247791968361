import React from 'react';
import { SORT_ASC, SORT_DESC } from '../enum/SortDirectionEnum';
import { CenteringContainer, Item } from '../../pitch4_layout/components/div';
import PropTypes from 'prop-types';
import { Select } from '../../pitch4_forms/components/inputs';

let interval;
const EmptySortValue = 'none';

class Sort extends React.Component {
    constructor(props) {
        super(props);

        let { direction, column } = { ...props };

        if (column === null) {
            column = EmptySortValue;
        }

        if (direction === null) {
            direction = SORT_DESC;
        }

        this.state = {
            direction: direction,
            column: column,
        };
    }

    handleSortDirectionChange = (event, value, formerValue, inputName) => {
        clearTimeout(interval);

        let newDirection = value;

        let { onChange, onClear, column } = this.props;

        if (!newDirection) {
            onClear(column);
        } else {
            const newState = { column: this.state.column, direction: newDirection };

            this.setState(newState);

            onChange(newState);
        }
    };

    handleSortsOnClear = (value) => {
        let { onClear, column } = this.props;

        if (value === EmptySortValue) {
            onClear(column);

            this.setState({ direction: this.state.direction, column: value });
        }
    };

    handleSortsOnChange = (event, value, formerValue, inputName) => {
        let { onChange } = this.props;

        clearTimeout(interval);

        interval = setTimeout(() => {
            clearTimeout(interval);

            if (!value) {
                value = EmptySortValue;
                this.handleSortsOnClear(value);
            } else {
                const newState = { direction: this.state.direction, column: value };

                this.setState(newState);

                onChange(newState);
            }
        }, 100);
    };

    render() {
        let { options, isMobile = false } = this.props;
        let disabled = this.state.column === EmptySortValue;
        let defaultOptions = [];

        const sortOptions = (
            <>
                <Item className={'sort'}>
                    <Select
                        variant={'outlined'}
                        onChange={this.handleSortsOnChange}
                        value={this.state.column}
                        name="sort_name"
                        options={[...defaultOptions, ...options]}
                        placeholder={'Sort'}
                    />
                </Item>
                <Item className={'sort'}>
                    <Select
                        variant={'outlined'}
                        disabled={disabled}
                        onChange={this.handleSortDirectionChange}
                        value={this.state.direction}
                        name="sort_dir"
                        options={[
                            { value: SORT_ASC, label: 'Asc' },
                            { value: SORT_DESC, label: 'Desc' },
                        ]}
                        placeholder={'Direction'}
                    />
                </Item>
            </>
        );

        return <>{isMobile ? <>{sortOptions}</> : <CenteringContainer>{sortOptions}</CenteringContainer>}</>;
    }
}

Sort.propTypes = {
    column: PropTypes.string,
    direction: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
};

export default Sort;
