import { call, put, takeEvery } from 'redux-saga/effects';
import { actionSetDynamicEnum } from '../redux/dynamic_enum';

export const SAGA_NAMESPACE = 'sagas.dynamic_enum';

export const SAGA_ACTION_SET_DYNAMIC_ENUM = `${SAGA_NAMESPACE}.SAGA_ACTION_GET_DYNAMIC_ENUM`;
export const sagaSetDynamicEnum = (enumType, query, args, mappingFunction) => ({
    type: SAGA_ACTION_SET_DYNAMIC_ENUM,
    enumType,
    query,
    args,
    mappingFunction,
});

function* setDynamicEnum({ enumType, query, args, mappingFunction }) {
    const response = yield call(query);
    let dynamicEnum = mappingFunction(response);
    yield put(actionSetDynamicEnum(enumType, dynamicEnum));
}

function* main() {
    yield takeEvery(SAGA_ACTION_SET_DYNAMIC_ENUM, setDynamicEnum);
}

export default main;
