import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';

const WrappedChip = (props) => {
    const chipProps = props['inputProps'] || props;
    const className = props['inputProps'] ? props['className'] : '';
    return (
        <span className={`chip ${className}`}>
            <MuiChip {...chipProps} />
        </span>
    );
};

WrappedChip.muiName = MuiChip.muiName;

class Chip extends React.Component {
    render() {
        return <WrappedChip {...this.props} />;
    }
}

export default Chip;
