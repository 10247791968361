import React from 'react';
import RegistrationLayout from './RegistrationLayout';
import IntermediaryRegistrationContainer from '../../apps/registration/containers/IntermediaryRegistrationContainer';

const IntermediaryRegistrationPage = (props) => {
    return (
        <RegistrationLayout>
            <IntermediaryRegistrationContainer />
        </RegistrationLayout>
    );
};

export default IntermediaryRegistrationPage;
