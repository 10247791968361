import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { path } from '../../../../../pitch4_routing';
import { push } from 'react-router-redux';
import { RollupCommandContainer } from '../../../../../pitch4_tabular/components';
import { PrimaryButton } from '../../../../../pitch4_layout/components/buttons';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import StatusEnum, { STATUS_ENABLED, STATUS_DISABLED } from '../../enum/Lenders/StatusEnum';
import SetStatusButton from '../components/SetStatusButton';
import { ROUTE_P4A_ADMINISTER_LENDERS_CREATE, ROUTE_P4A_ADMINISTER_LENDERS_EDIT } from '../../../../../pitch4_routing';
import _ from 'lodash';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { Button, FormControlLabel, Switch } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import { ROWS_PER_PAGE, PAGE_SIZE } from '../../../../../pitch4_enum/enum/DataGridEnum';
import HeaderWithCount from '../../../../../pitch4_elements/screen-headers/HeaderWithCount';
import Toolbar from '../components/Toolbar';
import { setLenderExtraNotifications } from '../../../../../pitch4_api/set_lender_extra-notifications';
import { DEFAULT_SEARCH_FILTER_DELAY } from '../../../../../pitch4_utils/FiltersDefaults';
import { setLenderOffPanel } from '../api/set_lender_off-panel';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });
const statusEnum = new StatusEnum();

class TableStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFetchError: null,
            searchFilter: '',
            dataGrid: {
                rows: [],
                totalRowCount: 0,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: [{ column: 'createdAt', direction: 'desc' }],
            },
            dataRequest: {
                filters: [],
                sorts: [
                    {
                        column: 'createdAt',
                        direction: 'desc',
                    },
                ],
                pages: [],
                cursor: null,
                storedDataFilters: [],
            },
        };
    }

    componentDidMount() {
        const data = this.getData();
        this.processData(data);
    }

    refreshData = () => {
        this.setState(
            {
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages: [], cursor: null },
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    processData(data) {
        const { currentPage } = this.state.dataGrid;
        const { cursor } = this.state.dataRequest;

        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            const lenders = data.lenders.edges.map((user) => user.node);
            const rows = lenders.map((lender, index) => {
                return {
                    id: lender.id,
                    name: lender.name,
                    status: lender.status,
                    createdAt: lender.createdAt,
                    realTimeNotifications: lender.realTimeNotifications,
                    offPanel: lender.offPanel,
                };
            });

            let pages = this.state.dataRequest.pages;
            pages[currentPage] = {
                cursor: cursor,
                hasNextPage: data.lenders.pageInfo.hasNextPage,
                endCursor: data.lenders.pageInfo.endCursor,
                startCursor: data.lenders.pageInfo.startCursor,
                hasPreviousPage: data.lenders.pageInfo.hasPreviousPage,
            };

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: rows,
                    totalRowCount: data.lenders.totalCount,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages, cursor: data.lenders.pageInfo.endCursor },
                },
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    getData() {
        const query = `
            query getLenders($cursor: String, $first: Int!, $sorts: [SortInput], $filters: [FilterInput]) {
                lenders(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            name
                            primaryEmail
                            fcaIdentifier
                            companyNumber
                            status
                            createdAt
                            updatedAt
                            realTimeNotifications
                            primaryAddress {
                                address1
                                address2
                                address3
                                address4
                                country
                                postcode
                            }
                            primaryPhone {
                                number
                            }
                            offPanel
                        }
                    }
                    totalCount
                }
            }
        `;

        return apolloFetch({
            query: query,
            variables: {
                cursor: this.state.dataRequest.cursor,
                filters: this.state.dataRequest.filters,
                first: this.state.dataGrid.pageSize,
                sorts: this.state.dataRequest.sorts,
                storedDataFilters: this.state.dataRequest.storedDataFilters,
            },
        });
    }

    handlePageChange = (pageConfig) => {
        const previousPage = this.state.dataGrid.currentPage;
        const newPage = pageConfig;
        const { pages } = this.state.dataRequest;

        if (newPage > previousPage) {
            // Next page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[previousPage].endCursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        } else {
            // Previous page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[newPage].cursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handlePageSizeChange = (pageConfig) => {
        this.setState(
            {
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: true,
                    pageSize: pageConfig,
                    rows: [],
                    currentPage: 0,
                    totalRowCount: 0,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    pages: [],
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    buildSearchRequestFilters = () => {
        const { searchFilter } = this.state;
        let searchRequestFilter = [];
        const searchableFields = ['name'];

        if (searchFilter.length > 0) {
            searchRequestFilter = searchableFields.map((field) => {
                return {
                    operation: 'ilike',
                    operand: '%' + searchFilter + '%',
                    column: field,
                    not: false,
                    logicOperator: 'or',
                };
            });
        }

        return searchRequestFilter;
    };

    handleSearchFilterChange = (event) => {
        this.setState(
            {
                searchFilter: event.target.value,
            },
            () => {
                this.setState({
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: [...this.buildSearchRequestFilters()],
                    },
                });
            }
        );

        _.delay(
            (value) => {
                if (this.state.searchFilter === value) {
                    const data = this.getData();
                    this.processData(data);
                }
            },
            DEFAULT_SEARCH_FILTER_DELAY,
            event.target.value
        );
    };

    handleClearAllFilters = () => {
        this.setState(
            {
                searchFilter: '',
                dataGrid: { ...this.state.dataGrid, rows: [] },
                dataRequest: { ...this.state.dataRequest, filters: [], cursor: null },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    handleSortModelChange = (params) => {
        const { sortModel } = this.state.dataGrid;
        const sortableFieldsMapping = { name: 'name', createdAt: 'createdAt', status: 'status', offPanel: 'offPanel' };

        if (params.sortModel !== null && typeof params.sortModel !== 'undefined' && params.sortModel !== sortModel) {
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        sortModel: params.sortModel,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        sorts: [
                            {
                                column: sortableFieldsMapping[params.sortModel[0].field],
                                direction: params.sortModel[0].sort,
                            },
                        ],
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handleExtraNotifications = (lender) =>
        setLenderExtraNotifications(API_URL, lender.id, !lender.realTimeNotifications).then((resp) => {
            const rows = this.state.dataGrid.rows;
            let le = rows.find((le) => le.id === lender.id);
            le.realTimeNotifications = !lender.realTimeNotifications;

            const newRows = rows.map((item) => (item.id !== le.id ? item : le));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
        });

    handleOffPanel = (lender) =>
        setLenderOffPanel(API_URL, lender.id, !lender.offPanel).then((resp) => {
            const rows = this.state.dataGrid.rows;
            let le = rows.find((le) => le.id === lender.id);
            le.offPanel = !lender.offPanel;

            const newRows = rows.map((item) => (item.id !== le.id ? item : le));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
        });

    render() {
        const { redirect } = this.props;
        const { searchFilter } = this.state;
        const { sortingModel, totalRowCount, dataLoading } = this.state.dataGrid;

        return (
            <div>
                <div className={'le-header-container'}>
                    <div className={'header-text'}>
                        <HeaderWithCount title={'Lenders'} count={totalRowCount} countText={'loan lenders'} />
                        <div className={'btn-mobile'}>
                            <Button
                                className={'btn-create'}
                                variant="contained"
                                color="primary"
                                size={'large'}
                                style={{
                                    marginLeft: 20,
                                    height: 45,
                                    paddingTop: 11,
                                    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                                }}
                                onClick={redirect(ROUTE_P4A_ADMINISTER_LENDERS_CREATE)}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                    <Toolbar
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                        handleClearAllFilters={this.handleClearAllFilters}
                        redirect={redirect}
                    />
                </div>

                <div className={`le-table-container`}>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortingModel}
                        paginationMode="server"
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        sortingMode="server"
                        onSortModelChange={this.handleSortModelChange}
                        rowCount={this.state.dataGrid.totalRowCount}
                        pageSize={this.state.dataGrid.pageSize}
                        rowsPerPageOptions={this.state.dataGrid.rowsPerPage}
                        rows={this.state.dataGrid.rows}
                        loading={true}
                        disableColumnFilter={true}
                        disableMultipleColumnsSorting={true}
                        disableColumnReorder={true}
                        disableMultipleColumnsFiltering={true}
                        disableSelectionOnClick={true}
                        disableColumnMenu={true}
                        components={{
                            LoadingOverlay: () => {
                                return (
                                    <GridOverlay>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {this.state.dataGrid.dataLoading ? (
                                                <LinearProgress />
                                            ) : this.state.dataGrid.rows.length === 0 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        width: '100%',
                                                        paddingTop: 100,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    No data
                                                </div>
                                            ) : null}
                                        </div>
                                    </GridOverlay>
                                );
                            },
                        }}
                        columns={[
                            {
                                field: 'name',
                                headerName: 'NAME',
                                width: 300,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'realTimeNotifications',
                                headerName: 'EXTRA NOTIFICATIONS',
                                width: 200,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={params.row.realTimeNotifications}
                                                        onChange={(event) => this.handleExtraNotifications(params.row)}
                                                        color="primary"
                                                        name="realTimeNotifications"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={false}
                                                        id={params.row.id}
                                                    />
                                                }
                                                label={''}
                                            />
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'createdAt',
                                headerName: 'CREATED',
                                width: 170,
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'status',
                                headerName: 'STATUS',
                                width: 100,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                valueFormatter: ({ value }) => statusEnum.getType(value),
                            },
                            {
                                field: 'offPanel',
                                headerName: 'OFF-PANEL',
                                width: 150,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={params.row.offPanel}
                                                        onChange={(event) => this.handleOffPanel(params.row)}
                                                        color="primary"
                                                        name="offPanel"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={false}
                                                        id={params.row.id}
                                                    />
                                                }
                                                label={''}
                                            />
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'actions',
                                renderHeader: () => <div />,
                                cellClassName: 'le-table-cell le-table-cell-content-right',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                renderCell: (params) => {
                                    return (
                                        <div className={'le-table-cell-context-menu'} style={{ marginTop: 10 }}>
                                            <RollupCommandContainer>
                                                <PrimaryButton
                                                    onClick={redirect(
                                                        path(ROUTE_P4A_ADMINISTER_LENDERS_EDIT, { id: params.row.id })
                                                    )}
                                                >
                                                    Edit
                                                </PrimaryButton>
                                                {params.row.status === STATUS_ENABLED ? (
                                                    <SetStatusButton
                                                        outerRefetch={this.refreshData}
                                                        selectedItems={[params.row.id]}
                                                        useStatus={STATUS_DISABLED}
                                                    >
                                                        Disable
                                                    </SetStatusButton>
                                                ) : (
                                                    <SetStatusButton
                                                        outerRefetch={this.refreshData}
                                                        selectedItems={[params.row.id]}
                                                        useStatus={STATUS_ENABLED}
                                                    >
                                                        Enable
                                                    </SetStatusButton>
                                                )}
                                            </RollupCommandContainer>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(TableStyleContainer);
