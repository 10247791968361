import React from 'react';
import { Route, Switch } from 'react-router';

import ResetPasswordContainer from '../apps/password_reset/containers/PasswordResetContainer';
import FlagForgottenPasswordContainer from '../apps/password_reset/containers/PasswordResetRequestContainer';
import NotificationsContainer from '../apps/notifications/containers/Container';
import AuthenticationLayout from './layouts/AuthenticationLayout';

import { ROUTE_PASSWORD_RESET, ROUTE_REQUEST_PASSWORD_RESET } from '../pitch4_routing';

class PasswordResetPage extends React.Component {
    render() {
        return (
            <AuthenticationLayout notifications={<NotificationsContainer />}>
                <Switch>
                    <Route path={ROUTE_REQUEST_PASSWORD_RESET} component={FlagForgottenPasswordContainer} />
                    <Route path={ROUTE_PASSWORD_RESET} component={ResetPasswordContainer} />
                </Switch>
            </AuthenticationLayout>
        );
    }
}

export default PasswordResetPage;
