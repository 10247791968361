import capitaliseFirstLetter from 'lodash/capitalize';
import RoleUtil from './RoleUtil';
import { DEFAULT_TITLE, EMPTY_TITLE } from '../../pitch4_enum/enum/DefaultEnum';

class Session {
    constructor(session) {
        this.session = session;
        this.roleUtil = new RoleUtil();
    }

    getId = () => {
        return this.session.id;
    };

    getRoles = () => {
        return this.session.roles ? this.session.roles : [];
    };

    getSupplementaryData = () => {
        if (null === this.session || undefined === this.session) {
            return {};
        }

        if (false === this.session.hasOwnProperty('supplementaryData')) {
            return {};
        }

        return this.session.supplementaryData;
    };

    smsVerificationRequested = () => {
        const { sms_verification_requested } = this.getSupplementaryData();
        return true === sms_verification_requested;
    };

    isVerified = () => {
        const { verified } = this.getSupplementaryData();
        return true === verified;
    };

    getEmail = () => {
        const { email } = this.getSupplementaryData();
        return email;
    };

    getPrimaryPhone = () => (this.session.primaryPhone ? this.session.primaryPhone.number : null);

    getRoleNames = () => {
        let out = [];

        if (this.session.roles.length) {
            out = this.session.roles.map((item) => {
                return item.name;
            });
        }

        return out;
    };

    getMostSeniorRole = () => {
        if (!this.session) {
            return null;
        }

        return this.roleUtil.getMostSeniorRole(this.getRoles());
    };

    getRealTimeNotifications = () => true === this.session.realTimeNotifications;

    getTitle = () =>
        null === this.session.title || this.session.title.toLowerCase() === DEFAULT_TITLE.toLowerCase()
            ? EMPTY_TITLE
            : this.session.title;

    getFullname = () => `${capitaliseFirstLetter(this.getTitle())} ${this.getName()}`;

    getName = () => `${this.session.given} ${this.session.family}`;

    getFamily = () => this.session.family;
    getGiven = () => this.session.given;
    getDateOfBirth = () => this.session.user.userProfile.dateOfBirth;

    isNetwork = () => {
        const { is_network_broker } = this.getSupplementaryData();
        return true === is_network_broker;
    };

    belongsToBrokerNetwork = () => {
        const { belongsToNetwork } = this.getSupplementaryData();
        return true === belongsToNetwork;
    };

    isPartnerBroker = () => {
        const { is_broker_partner } = this.getSupplementaryData();
        return true === is_broker_partner;
    };

    getBrokerNetworkCode = () => {
        const { network_code } = this.getSupplementaryData();
        return network_code ?? null;
    };
}

export default Session;
