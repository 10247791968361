import React from 'react';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';

const style = {
    margin: 0,
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
    zIndex: 999,
};

class AddButton extends React.Component {
    render() {
        let { onClick } = this.props;

        return (
            <Fab color="primary" aria-label="Add" style={style} onClick={onClick}>
                <AddIcon />
            </Fab>
        );
    }
}

AddButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default AddButton;
