import { spawn } from 'redux-saga/effects';

import viewLenderLoanEnquirySaga from '../administer_loanenquiries_viewlenderloanenquiry/sagas';
import slamTabularStyleSagas from '../../../../pitch4_tabular/sagas';
import businessStreamSaga from '../business_streams/sagas';
import clearUssrAlerts from '../administer_loanenquiries/sagas';

function* main() {
    yield spawn(viewLenderLoanEnquirySaga);
    yield spawn(slamTabularStyleSagas);
    yield spawn(businessStreamSaga);
    yield spawn(clearUssrAlerts);
}

export default main;
