export const REDUCER_NAMESPACE = 'loan_usage_in_uk';

const initialState = {
    isLoanUsageInUk: false,
};

// Actions
export const SET_LOAN_USAGE_IN_UK = `${REDUCER_NAMESPACE}.set_loan_usage_in_uk`;
export const actionSetLoanUsageInUk = (isLoanUsageInUk) => {
    return {
        type: SET_LOAN_USAGE_IN_UK,
        isLoanUsageInUk,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LOAN_USAGE_IN_UK:
            return {
                ...state,
                isLoanUsageInUk: action.isLoanUsageInUk,
            };

        default:
            return { ...state };
    }
};

export const selectorGetLoanUsageInUk = (state) => {
    return state[REDUCER_NAMESPACE].isLoanUsageInUk;
};

export default { [REDUCER_NAMESPACE]: reducer };
