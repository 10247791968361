import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import {
    ROUTE_INTERMEDIARY_2FA_INDEX,
    ROUTE_INTERMEDIARY_APPLICANTS_VIEW,
    ROUTE_INTERMEDIARY_CONFIGURE_PROFILE,
    ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY,
    ROUTE_INTERMEDIARY_DOCUMENTS_VIEW,
    ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY,
    ROUTE_INTERMEDIARY_LENDERLOANENQUIRY_VIEW,
    ROUTE_INTERMEDIARY_LOAN_ENQUIRIES,
    ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW,
    ROUTE_INTERMEDIARY_NETWORK_BROKERS_VIEW,
    ROUTE_INTERMEDIARY_ROOT,
} from '../../../../../pitch4_routing';
import DashboardContainer from '../../dashboard/containers/DashboardContainer';
import ConfigureProfileContainer from '../../../../user/configure_profile/containers/ConfigureProfileContainer';
import LoanEnquiriesContainer from '../../loan_enquiries/containers/LoanEnquiriesContainer';
import ViewLoanEnquiryContainer from '../../view_loan_enquiry/containers/MasonryStyleContainer';
import ViewLenderLoanEnquiryContainer from '../../view_lender_loan_enquiry/containers/MasonryStyleContainer';
import TwoFactorAuthIndexContainer from '../../../../user/two_factor_auth/containers/IndexContainer';
import ApplicantsContainer from '../../applicants/containers/Container';
import DocumentsContainer from '../../documents/containers/TableStyleContainer';
import NetworkBrokersTableStyleContainer from '../../network_brokers/NetworkBrokersTableStyleContainer';
import MakeLoanEnquiryContainer from '../../../../MakeLoanEnquiry/containers/MakeLoanEnquiryContainer';

class ContentContainer extends React.Component {
    render() {
        return (
            <div id="content">
                <Switch location={this.props.router.location}>
                    <Route
                        path={ROUTE_INTERMEDIARY_NETWORK_BROKERS_VIEW}
                        render={() => <NetworkBrokersTableStyleContainer />}
                    />
                    <Route path={ROUTE_INTERMEDIARY_DOCUMENTS_VIEW} render={() => <DocumentsContainer />} />
                    <Route path={ROUTE_INTERMEDIARY_APPLICANTS_VIEW} render={() => <ApplicantsContainer />} />
                    <Route path={ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY} render={() => <MakeLoanEnquiryContainer />} />
                    <Route path={ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY} render={() => <MakeLoanEnquiryContainer />} />
                    <Route
                        path={ROUTE_INTERMEDIARY_LENDERLOANENQUIRY_VIEW}
                        render={() => <ViewLenderLoanEnquiryContainer />}
                    />
                    <Route path={ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW} render={() => <ViewLoanEnquiryContainer />} />
                    <Route path={ROUTE_INTERMEDIARY_LOAN_ENQUIRIES} render={() => <LoanEnquiriesContainer />} />
                    <Route path={ROUTE_INTERMEDIARY_CONFIGURE_PROFILE} render={() => <ConfigureProfileContainer />} />
                    <Route path={ROUTE_INTERMEDIARY_2FA_INDEX} render={() => <TwoFactorAuthIndexContainer />} />
                    <Route path={ROUTE_INTERMEDIARY_ROOT} render={() => <DashboardContainer />} />
                </Switch>
            </div>
        );
    }
}

ContentContainer.propTypes = {};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentContainer);
