export const NAMESPACE = 'intermediary.mle.applicant';

export const GET_INIT_API_GET_APPLICANTS = `${NAMESPACE}.init_api_get_applicants`;
export const SET_APPLICANTS = `${NAMESPACE}.set_applicants`;
export const ADD_APPLICANT = `${NAMESPACE}.add_applicant`;

export const actionInitApiGetApplicants = (filters) => ({
    type: GET_INIT_API_GET_APPLICANTS,
    filters,
});

export const actionAddApplicant = (applicant) => ({
    type: ADD_APPLICANT,
    applicant,
});

const initialState = {
    applicants: new Map(),
    applicantsFetched: false,
};

export const selectorGetApplicants = (state) => {
    return state[NAMESPACE].applicants;
};

export const selectorApplicantsFetched = (state) => {
    return state[NAMESPACE].applicantsFetched;
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_APPLICANTS:
            const newApplicants = new Map();
            for (let applicant of action.applicants) {
                newApplicants.set(applicant.id, applicant);
            }

            return {
                ...state,
                applicants: newApplicants,
                applicantsFetched: true,
            };
        case ADD_APPLICANT:
            const { applicants } = state;
            const { applicant } = action;

            return {
                ...state,
                applicants: applicants.set(applicant.id, applicant),
            };
        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;
