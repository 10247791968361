import React from 'react';
import { Container, Item, P4StyleComponentContainer } from '../../../../pitch4_layout/components/div';
import { KeyValueTable, KeyValueTableRow } from '../../../../pitch4_tabular/components/kvtable';
import { Body1, Subtitle1 } from '../../../../pitch4_layout/components/text';
import { DateFormatter, FactValue } from '../../../../pitch4_layout/components/data';
import { LOAN_AMOUNT } from '../../enum/LoanEnquiries/MetaKeyEnum';
import { ExpandedDateTimeLayout } from '../../../../pitch4_layout/components/content';
import { BRAND_NAME } from '../../../../bootstrap/constants/constants';
import ContractSigned from '../../../legal/ContractSigned';
import RevisionHistoryContainer from '../../../../pitch4_layout/components/le_revision_history';
import { ROLE_BORROWER as BORROWER } from '../../../../pitch4_enum/enum/RoleNameEnum';
import { NewLoanEnquirySummaryTable } from '../../../MakeLoanEnquiry/containers/NewLoanEnquirySummaryTable';
import { STATUS_DRAFT } from '../../enum/LoanEnquiries/StatusEnum';

export function NewEnquiryContent(props) {
    const {
        handleLegalContractDownload = null,
        currentStatus = null,
        contractInProgress = false,
        contractSigned = false,
        loanEnquiry = null,
    } = props;

    return (
        <Container justifyContent={`space-between`} spacing={4}>
            <Item xs={12} className={`view-enquiry-summary`} key={11}>
                <P4StyleComponentContainer
                    styles={{ backgroundColor: '#fafafa', border: 'none' }}
                    header={'Enquiry summary'}
                    buttons={<div />}
                    content={
                        <KeyValueTable style={{ backgroundColor: '#fafafa', border: 'none' }}>
                            <KeyValueTableRow
                                key={'kvtr_1'}
                                term={
                                    <Subtitle1>
                                        <strong>Loan amount:</strong>
                                    </Subtitle1>
                                }
                                def={
                                    <Body1>
                                        <FactValue name={LOAN_AMOUNT} value={loanEnquiry.loanEnquiryData.loanAmount} />
                                    </Body1>
                                }
                            />
                            <KeyValueTableRow
                                key={'kvtr_2'}
                                term={<Subtitle1>Reference number:</Subtitle1>}
                                def={<Body1>{loanEnquiry.borrowerReference}</Body1>}
                            />
                            <KeyValueTableRow
                                key={'kvtr_3'}
                                term={<Subtitle1>Created:</Subtitle1>}
                                def={
                                    <Body1>
                                        <ExpandedDateTimeLayout value={loanEnquiry.createdAt} />
                                    </Body1>
                                }
                            />
                            {currentStatus !== STATUS_DRAFT && currentStatus !== null && (
                                <KeyValueTableRow
                                    key={'kvtr_4'}
                                    term={<Subtitle1>{BRAND_NAME} Contract:</Subtitle1>}
                                    def={
                                        <ContractSigned
                                            contractSigned={contractSigned}
                                            contractInProgress={contractInProgress}
                                            handleLegalContractDownload={handleLegalContractDownload}
                                        />
                                    }
                                />
                            )}
                        </KeyValueTable>
                    }
                />
            </Item>
            <Item xs={12} className={`view-enquiry-applicants`} key={12}>
                <P4StyleComponentContainer
                    styles={{ backgroundColor: '#fafafa', border: 'none' }}
                    header={'Applicant details'}
                    buttons={<div />}
                    content={
                        <React.Fragment>
                            {loanEnquiry.loanEnquiryApplicants.map((loanEnquiryApplicant, index) => (
                                <KeyValueTable
                                    key={`applicant${index}`}
                                    style={{ backgroundColor: '#fafafa', border: 'none' }}
                                >
                                    <KeyValueTableRow
                                        key={'kvtr_5'}
                                        term={
                                            <Subtitle1>
                                                {loanEnquiryApplicant.isMainApplicant
                                                    ? 'Main Applicant Name'
                                                    : 'Applicant ' + (index + 1) + ' Name'}
                                            </Subtitle1>
                                        }
                                        def={
                                            <span>
                                                {loanEnquiryApplicant.applicant.given}{' '}
                                                {loanEnquiryApplicant.applicant.family}
                                            </span>
                                        }
                                    />
                                    <KeyValueTableRow
                                        key={'kvtr_6'}
                                        term={<Subtitle1>Date of birth</Subtitle1>}
                                        def={<DateFormatter value={loanEnquiryApplicant.applicant.dateOfBirth} />}
                                    />
                                </KeyValueTable>
                            ))}
                        </React.Fragment>
                    }
                />
            </Item>
            {props.additionalItems}
            <Item xs={12} className={`view-enquiry-info`} key={13}>
                <P4StyleComponentContainer
                    styles={{ backgroundColor: '#fafafa', border: 'none' }}
                    header={'Enquiry information'}
                    buttons={<div />}
                    content={
                        <NewLoanEnquirySummaryTable
                            loanEnquiry={loanEnquiry}
                            loanEnquiryData={loanEnquiry.loanEnquiryData}
                            lenderLoanEnquiry={null}
                        />
                    }
                />
            </Item>
            <Item xs={12} className={`view-enquiry-summary`} key={14}>
                <P4StyleComponentContainer
                    styles={{ backgroundColor: '#fafafa', border: 'none' }}
                    header={'Enquiry Revision History'}
                    buttons={<div />}
                    content={
                        <RevisionHistoryContainer
                            elkey={'rhc' + loanEnquiry.id}
                            loanEnquiry={loanEnquiry}
                            userRole={BORROWER}
                        />
                    }
                />
            </Item>
        </Container>
    );
}
