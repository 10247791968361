import { API_URL, INTERMEDIARY_URL, SLAM_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const getAssignableUsers = (uri) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    let query;

    switch (uri) {
        case API_URL:
            query = `
                query adminUsers {
                  adminUsers {
                    id
                    userProfile {
                      given
                      family
                    }
                  }
                }
            `;
            break;
        case SLAM_URL:
            query = `
                query lenderUsers {
                  lenderUsers {
                    id
                    userProfile {
                      given
                      family
                    }
                  }
                }
            `;
            break;
        case INTERMEDIARY_URL:
            query = `
                query brokerUsers {
                  brokerUsers {
                    id
                    userProfile {
                      given
                      family
                    }
                  }
                }
            `;
            break;
        default:
            query = `
                query adminUsers {
                  adminUsers {
                    id
                    userProfile {
                      given
                      family
                    }
                  }
                }
            `;
    }

    return apolloFetch({
        query: query,
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            let users = [];

            switch (uri) {
                case API_URL:
                    data.adminUsers.map((user) =>
                        users.push({
                            id: user.id,
                            name: user.userProfile.given + ' ' + user.userProfile.family,
                        })
                    );
                    break;
                case SLAM_URL:
                    data.lenderUsers.map((user) =>
                        users.push({
                            id: user.id,
                            name: user.userProfile.given + ' ' + user.userProfile.family,
                        })
                    );
                    break;
                case INTERMEDIARY_URL:
                    data.brokerUsers.map((user) =>
                        users.push({
                            id: user.id,
                            name: user.userProfile.given + ' ' + user.userProfile.family,
                        })
                    );
                    break;
                default:
                    data.adminUsers.map((user) =>
                        users.push({
                            id: user.id,
                            name: user.userProfile.given + ' ' + user.userProfile.family,
                        })
                    );
            }

            return users;
        })
        .catch((error) => {
            return {};
        });
};
