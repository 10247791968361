import createP4ApolloFetch from 'bootstrap/middlewares';
import { ANON_URL } from '../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: ANON_URL });

const mutation = `
    mutation PasswordResetMutation($input: anon_resetPasswordInput!){
        resetPassword(input: $input){
            result
        }
    }
`;

export const passwordReset = (token, confirmPassword, newPassword) => {
    return apolloFetch({
        query: mutation,
        variables: {
            input: {
                confirmPassword,
                newPassword,
                token,
            },
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};
