import React from 'react';

import Autocomplete from './autocomplete/redux_form/Autocomplete';
import _ from 'lodash';

const Select = ({
    name,
    label = '',
    options = [],
    style = { paddingTop: 0 },
    className,
    validate,
    displayItems,
    inputProps = {},
    sortLabels = true,
    ...props
}) => {
    if (_.includes(['development', 'qa', 'test'], process.env.NODE_ENV)) {
        inputProps['data-cy'] = `${name}-input`;
    }

    // Do not sort list of countries. We want to display it as provided bu the API enums.json
    if (name === 'nationality' || name === 'country_of_residence') {
        sortLabels = false;
    }

    return (
        <Autocomplete
            displayItems={displayItems}
            name={name}
            label={label}
            suggestions={options}
            style={style}
            className={className}
            validate={validate}
            inputProps={inputProps}
            {...props}
            sortLabels={sortLabels}
        />
    );
};

export default Select;
