import React from 'react';
import { DialogContent, DialogTitle } from '@material-ui/core';
import PropTypes from 'prop-types';

export const ModalLayoutMuiName = 'ModalLayout';

const ModalLayoutComponent = (props) => {
    const { title = null, contentClassName = '', children, modalTitleStartAdornment = null } = props;
    return (
        <React.Fragment>
            {modalTitleStartAdornment}
            {title && <DialogTitle className={`modal-title`}>{title}</DialogTitle>}
            <DialogContent className={`modal-content ${contentClassName}`}>{children}</DialogContent>
        </React.Fragment>
    );
};
ModalLayoutComponent.MuiName = ModalLayoutMuiName;

class ModalLayout extends React.Component {
    render() {
        return <ModalLayoutComponent {...this.props} />;
    }
}

ModalLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
};

export default ModalLayout;
