import React from 'react';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { USER_URL } from '../../../../bootstrap/constants/constants';
import { ROLE_USER } from '../../../authentication/enum/Roles/NameEnum';
import { SubmissionError } from 'redux-form';

import Form from '../components/Form';
import { connect } from 'react-redux';
import { NoQueryMutation } from 'pitch4_forms/components';

class ChangePasswordContainer extends React.Component {
    renderFormFunction = (mutate, props) => {
        return <Form onSubmit={this.onSubmit(mutate, props)} />;
    };

    render() {
        return (
            <NoQueryMutation
                mutationQuery={gql`
                    mutation changePassword($input: user_changePasswordInput!) {
                        changePassword(input: $input) {
                            response
                        }
                    }
                `}
                renderFormFunction={this.renderFormFunction}
            />
        );
    }

    onSubmit = (mutate, props) => (values, dispatch, formProps) => {
        let { logout, notificationSuccess, notificationError } = props;

        let { oldPassword, newPassword, newPasswordConfirmation } = values;

        //map the variables if necessary
        return mutate({
            variables: {
                input: {
                    oldPassword,
                    newPassword,
                    newPasswordConfirmation,
                },
            },
            context: {
                uri: USER_URL,
            },
        })
            .then(({ data }) => {
                logout();
                notificationSuccess('Password changed successfully! Please log in with your new password.');
            })
            .catch((error) => {
                notificationError('Unable to change password.');
                throw new SubmissionError({ _error: error.graphQLErrors });
            });
    };
}

const mapDispatchToProps = (dispatch) => {
    return {};
};

const mapStateToProps = (state) => {
    return {};
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_USER])
)(ChangePasswordContainer);
