import React from 'react';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import moment from 'moment';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { path, ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW } from '../../../../../pitch4_routing';
import { Grid, Paper } from '@material-ui/core';
import EnquiryOwnerIcon from './icons/EnquiryOwnerIcon';
import StatusEnum from '../../enum/LoanEnquiries/StatusEnum';
import { calculateLoanType } from '../../../../MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';

class LECard extends React.Component {
    render() {
        const { le, dispatch } = this.props;
        const leStatusEnum = new StatusEnum();
        const getLELoanAmount = (value) => {
            let loanAmount = null;
            const formatter = new Intl.NumberFormat('en-UK', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 0,
            });
            if (true === le.isNewForm) {
                loanAmount = le.loanEnquiryData.loanAmount;
                return loanAmount ? formatter.format(loanAmount) : '-';
            } else {
                loanAmount = le.storedData.find((item) => item.meta.key === 'LOAN_AMOUNT');
                return loanAmount ? formatter.format(loanAmount.value / 100) : '-'; // Amount in pennies
            }
        };

        const getUserRole = (le) => {
            return le.submittedBy.roles[0].label;
        };

        const getLTV = (le) => {
            const data = le.storedData.find((item) => item.meta.key === 'LOAN_LTV');
            if (typeof data === 'undefined') {
                return 'N/A';
            }
            return Math.round(data.value * 100) / 100;
        };

        const getNeededBy = (le) => {
            const data = le.storedData.find((item) => item.meta.key === 'LOAN_NEEDED_BY');
            if (typeof data === 'undefined') {
                return 'N/A';
            }
            return data.value.split('_');
        };

        const getLEAdditionalNotes = (le) => {
            const data = le.storedData.find((item) => item.meta.key === 'ADDITIONAL_INFO_NOTES');
            return data ? data.value.substring(0, 140) + '...' : null;
        };

        return (
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Paper
                    elevation={0}
                    className={'lead-card'}
                    onClick={() => {
                        dispatch(push(path(ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW, { loanEnquiryId: le.id })));
                    }}
                >
                    <div className={'lead'}>
                        <div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className={'amount'}>{getLELoanAmount(le ? le.loanAmount : 0)}</div>
                                <div className={'update'}>{le && le.alertActive && <Badge label={'update'} />}</div>
                            </div>
                            <div className={'loan-type'}>{calculateLoanType(le.loanEnquiryData).label}</div>
                        </div>
                        {le && le.loanEnquiryApplicants && (
                            <div className={'applicant'}>
                                <div className={'name'}>
                                    {le.loanEnquiryApplicants[0].applicant.given +
                                        ' ' +
                                        le.loanEnquiryApplicants[0].applicant.family}
                                </div>
                            </div>
                        )}
                        {le && (
                            <div
                                className={'applicant'}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    margin: '5px 0',
                                }}
                            >
                                <div className={`user-type user-type-${getUserRole(le).toLowerCase()}`}>
                                    {getUserRole(le)}
                                </div>
                                <div className={`le-status ${le.status}`}>{leStatusEnum.getType(le.status)}</div>
                            </div>
                        )}
                        <>
                            {le && (
                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0' }}>
                                    <div className={'ltv'}>
                                        <div className={'label'}>LTV</div>
                                        <div className={'value'}>
                                            {getLTV(le) === 'N/A' ? 'Not Available' : getLTV(le) + '%'}
                                        </div>
                                    </div>
                                    <div className={'needed-by'}>
                                        <div className={'label'}>Needed By</div>
                                        <div className={'value'}>
                                            {getNeededBy(le) === 'N/A'
                                                ? 'Not Available'
                                                : getNeededBy(le)[1]
                                                ? getNeededBy(le)[0] + '-' + getNeededBy(le)[1] + ' Days'
                                                : getNeededBy(le)[0] + ' Days'}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {le && getLEAdditionalNotes(le) && (
                                <div className={'notes'}>
                                    <div className={'label'}>LOAN NOTES</div>
                                    <div className={'note'}>{getLEAdditionalNotes(le)}</div>
                                </div>
                            )}
                        </>
                        {le && (
                            <Grid container className={'footer'}>
                                <Grid item lg={6} md={6} sm={12} xs={12} className={'created'}>
                                    <div>Date Posted</div>
                                    <div className={'date'}>{moment(le.createdAt).format('MMM DD, YYYY')}</div>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12} className={'owner'}>
                                    <div className={'icon-container'}>
                                        <EnquiryOwnerIcon />
                                        <span className={'owner-name'}>No owner</span>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                </Paper>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LECard);
