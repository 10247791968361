import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import ModalLayout from 'pitch4_modal/src/component/ModalLayout';
import { Container, CenteringContainer, Item, RowItem } from 'pitch4_layout/components/div';
import FormMessage, { MESSAGE_ERROR } from 'pitch4_forms/components/FormMessage';
import { PrimaryButton, SecondaryButton } from 'pitch4_layout/components/buttons';
import { selectorAreErrorsVisible, selectorGetSubmitted, selectorSetStatusSubmissionErrors } from '../redux/main';
import { TextArea } from 'pitch4_forms/components/inputs';
import HelpIcon from '@material-ui/icons/Help';
import { isRequired } from 'pitchblack_react_utils/form/validation';

const CompletionRejectedForm = ({ errors = [], errorsVisible, handleSubmit, handleClose, submitting, submitted }) => {
    return (
        <ModalLayout
            title="Completion Request Rejection"
            contentClassName="modal-content-one-pane completion-rejected-modal"
        >
            <CenteringContainer>
                <Item xs={12} className={'content-container'}>
                    <CenteringContainer className={`grey-box`}>
                        <Item xs={1}>
                            <HelpIcon />
                        </Item>
                        <Item xs={10}>
                            <p className="typography-default">
                                So we can investigate, please give the reason you have rejected this request to
                                complete.
                            </p>
                        </Item>
                    </CenteringContainer>

                    {errorsVisible &&
                        errors.map((error) => <FormMessage type={MESSAGE_ERROR} messages={error} key={error} />)}
                    <form onSubmit={handleSubmit}>
                        <RowItem>
                            <div className={'completion-rejected-reason'}>
                                <TextArea
                                    name="rejectReason"
                                    placeholder="Reason for Rejection"
                                    inputProps={{
                                        className: 'typography-default',
                                        style: { fontSize: '14px', height: '290px' },
                                    }}
                                    validate={[isRequired]}
                                />
                                <Container
                                    className={'button-panel'}
                                    justifyContent={'flex-end'}
                                    direction={'row'}
                                    alignContent={'flex-end'}
                                >
                                    <Item xs={12}>
                                        <SecondaryButton
                                            onClick={handleClose}
                                            name={'cancel-btn'}
                                            disabled={submitting || submitted}
                                        >
                                            Cancel
                                        </SecondaryButton>
                                        <PrimaryButton
                                            type="submit"
                                            name={'next-btn'}
                                            disabled={submitting || submitted}
                                        >
                                            Reject
                                        </PrimaryButton>
                                    </Item>
                                </Container>
                            </div>
                        </RowItem>
                    </form>
                </Item>
            </CenteringContainer>
        </ModalLayout>
    );
};

CompletionRejectedForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleReject: PropTypes.func.isRequired,
    errors: PropTypes.array,
    errorsVisible: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitted: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    lenderName: PropTypes.string.isRequired,
    lenderLink: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return {
        submitted: selectorGetSubmitted(state),
        errors: selectorSetStatusSubmissionErrors(state),
        errorsVisible: selectorAreErrorsVisible(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'completion_rejected',
    })
)(CompletionRejectedForm);
