import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Switch,
    TextField,
} from '@material-ui/core';
import { PrimaryRedirectButton } from '../../../../../pitch4_layout/components/buttons';
import { ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY } from '../../../../../pitch4_routing';
import React from 'react';
import RouteManagerFactory from '../../../../../pitch4_routing/src/RouteManagerFactory';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TuneIcon from '@material-ui/icons/Tune';
import UpdatesFilter from '../../../../../pitch4_elements/toolbar/UpdatesFilter';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import AssigneeFilter from '../../../../../pitch4_elements/toolbar/AssigneeFilter';
import { getStatusFilterName } from '../../../../utils/filters';

export default function Toolbar(props) {
    const {
        updatesFilter,
        statusFilter,
        searchFilter,
        dataLoading,
        showExpired,
        statuses,
        handleUpdatesFilterChange,
        handleShowExpired,
        handleClearAllFilters,
        handleStatusFilterChange,
        handleSearchFilterChange,
        assigneeFilter,
        handleAssigneeFilterChange,
    } = props;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const routeManagerFactory = new RouteManagerFactory();
    const routeManager = routeManagerFactory.create();

    return (
        <>
            <div className={'header-toolbar'} id={'le-filters'}>
                <AssigneeFilter
                    assigneeFilter={assigneeFilter}
                    handleAssigneeFilterChange={handleAssigneeFilterChange}
                    uri={INTERMEDIARY_URL}
                />
                <UpdatesFilter updatesFilter={updatesFilter} handleUpdatesFilterChange={handleUpdatesFilterChange} />
                <FormControl variant="outlined" className={'select-status'} disabled={dataLoading}>
                    <InputLabel id="select-status-label">
                        <strong>Status</strong> Filter
                    </InputLabel>
                    <Select
                        labelId="select-status-label"
                        id="select-status"
                        name={'status'}
                        label={'Status Filter'}
                        value={statusFilter}
                        onChange={handleStatusFilterChange}
                        renderValue={(selected) => {
                            const labels = selected.map((label) => getStatusFilterName(label));
                            return labels.join(', ');
                        }}
                        multiple={true}
                        MenuProps={MenuProps}
                    >
                        {statuses.map((status) => {
                            return (
                                <MenuItem key={status} value={status}>
                                    <Checkbox checked={statusFilter.indexOf(status) > -1} />
                                    <ListItemText primary={getStatusFilterName(status)} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <TextField
                    name={'search'}
                    className={'search'}
                    label="Search"
                    variant="outlined"
                    disabled={dataLoading}
                    value={searchFilter}
                    onChange={handleSearchFilterChange}
                />
                <Grid
                    container
                    alignItems="center"
                    spacing={0}
                    style={{ width: 'unset', marginRight: 15 }}
                    className={'switch-expired'}
                >
                    <Grid item>
                        <strong>Expired:</strong>
                        <span> Hide</span>
                        <Switch
                            checked={showExpired}
                            onChange={(event) => handleShowExpired(event.target.checked)}
                            color="primary"
                            name="showExpired"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={dataLoading}
                        />
                        <span>Show</span>
                    </Grid>
                </Grid>
                <Button
                    variant="outlined"
                    size={'large'}
                    style={{ marginRight: 20, height: 45, paddingTop: 11 }}
                    disabled={dataLoading}
                    onClick={handleClearAllFilters}
                >
                    Clear all
                </Button>
                <PrimaryRedirectButton
                    id={'create-button'}
                    url={routeManager.route(ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY)}
                >
                    Create
                </PrimaryRedirectButton>
            </div>

            <div className={'header-toolbar-mobile'}>
                <Divider className={'divider'} />
                <Accordion className={'container'}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: '0 5px' }}
                    >
                        <TuneIcon style={{ marginRight: 10 }} /> Filters
                    </AccordionSummary>
                    <AccordionDetails className={'details'}>
                        <AssigneeFilter
                            assigneeFilter={assigneeFilter}
                            handleAssigneeFilterChange={handleAssigneeFilterChange}
                            uri={INTERMEDIARY_URL}
                        />
                        <UpdatesFilter
                            updatesFilter={updatesFilter}
                            handleUpdatesFilterChange={handleUpdatesFilterChange}
                        />
                        <FormControl variant="outlined" className={'select-status'} disabled={dataLoading}>
                            <InputLabel id="select-status-label">
                                <strong>Status</strong> Filter
                            </InputLabel>
                            <Select
                                labelId="select-status-label"
                                id="select-status"
                                name={'status'}
                                label={'Status Filter'}
                                value={statusFilter}
                                onChange={handleStatusFilterChange}
                                renderValue={(selected) => {
                                    const labels = selected.map((label) => getStatusFilterName(label));
                                    return labels.join(', ');
                                }}
                                multiple={true}
                                MenuProps={MenuProps}
                            >
                                {statuses.map((status) => {
                                    return (
                                        <MenuItem key={status} value={status}>
                                            <Checkbox checked={statusFilter.indexOf(status) > -1} />
                                            <ListItemText primary={getStatusFilterName(status)} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <TextField
                            name={'search'}
                            className={'search'}
                            label="Search"
                            variant="outlined"
                            disabled={dataLoading}
                            value={searchFilter}
                            onChange={handleSearchFilterChange}
                        />
                        <Grid
                            container
                            alignItems="center"
                            spacing={0}
                            style={{ width: 'unset', marginRight: 15 }}
                            className={'switch-expired'}
                        >
                            <Grid item>
                                <strong>Expired:</strong>
                                <span> Hide</span>
                                <Switch
                                    checked={showExpired}
                                    onChange={(event) => handleShowExpired(event.target.checked)}
                                    color="primary"
                                    name="showExpired"
                                    inputProps={{ 'aria-label': 'primary checkbox' }}
                                    disabled={dataLoading}
                                />
                                <span>Show</span>
                            </Grid>
                        </Grid>
                        <Button
                            variant="outlined"
                            size={'large'}
                            className={'btn-clear-all'}
                            disabled={dataLoading}
                            onClick={handleClearAllFilters}
                        >
                            Clear all
                        </Button>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}
