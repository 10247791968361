import { BaseEnum } from 'pitch4_enum';

export const OPERATION_NULLABLE_EQ = 'null_compat_eq';
export const OPERATION_EQ = 'eq';
export const OPERATION_LIKE = 'like';
export const OPERATION_ILIKE = 'ilike';
export const OPERATION_LT = 'lt';
export const OPERATION_LTE = 'lte';
export const OPERATION_GT = 'gt';
export const OPERATION_GTE = 'gte';
export const OPERATION_REGEXP = 'regexp';
export const OPERATION_BETWEEN = 'between';

class OperationEnum extends BaseEnum {
    getTypes = () => {
        return {
            [OPERATION_NULLABLE_EQ]: 'Is (or empty)',
            [OPERATION_EQ]: 'Is',
            [OPERATION_LIKE]: 'Matches text',
            [OPERATION_ILIKE]: 'Matches text (case-insensitive)',
            [OPERATION_LT]: 'Less than',
            [OPERATION_LTE]: 'Less than or equal to',
            [OPERATION_GT]: 'Greater than',
            [OPERATION_GTE]: 'Greater than or equal to',
            [OPERATION_REGEXP]: 'Regular Expression',
            [OPERATION_BETWEEN]: 'Between',
        };
    };
}

export default OperationEnum;
