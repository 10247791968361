import createP4ApolloFetch from '../bootstrap/middlewares';

export const deleteTag = (uri, tagId) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    const mutation = `
        mutation deleteTag($tagId: Float!) {
            deleteTag(tagId: $tagId) {
              success
            }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            tagId: tagId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
