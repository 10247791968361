import BaseEnum from '../../enums/BaseEnum';
import Option from './Option';

class OptionFactory {
    create = (value, label = null) => {
        let out = new Option();

        if (label === null) {
            label = value;
        }

        out.setValue(value).setLabel(label);

        return out;
    };

    createOptionsFromType = (type) => {
        if (!(type instanceof BaseEnum)) {
            throw new Error('type is not instance of Enum');
        }

        return this.createOptionsFromObject(type.getTypes());
    };

    createOptionsFromObject = (obj, keys = null) => {
        if (keys === null) {
            keys = Object.keys(obj);
        }

        //array of option objects
        return keys.map((key) => {
            return this.create(key, obj[key]);
        });
    };

    createOptionsFromColumnNames = (dataItems) => {
        return dataItems
            .filter((item) => item.getFilterable() !== false)
            .map((item) => this.create(item.getId(), item.getLabel()));
    };
}

export default OptionFactory;
