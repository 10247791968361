import React from 'react';
import { createOptionsFromEnum } from 'pitch4_forms/components/inputs/select/index';
import { Select } from 'pitch4_forms/components/inputs';
import PropTypes from 'prop-types';
import { NumericOperationEnum } from 'pitch4_enum';

class OperationSelect extends React.Component {
    render() {
        let { isNumericFact, ...props } = this.props;

        return isNumericFact ? (
            <Select {...props} sortLabels={false} options={createOptionsFromEnum(new NumericOperationEnum())} />
        ) : null;
    }
}

OperationSelect.propTypes = {
    columnName: PropTypes.any,
};

export default OperationSelect;
