import { BaseEnum } from 'pitch4_enum';

import { OPERATION_EQ, OPERATION_ILIKE, OPERATION_LIKE, OPERATION_NULLABLE_EQ } from './OperationEnum';

class TextOperationEnum extends BaseEnum {
    getTypes = () => {
        return {
            [OPERATION_NULLABLE_EQ]: 'Is (or empty)',
            [OPERATION_EQ]: 'Is',
            [OPERATION_LIKE]: 'Matches text',
            [OPERATION_ILIKE]: 'Matches text (case-insenitive)',
        };
    };
}

export default TextOperationEnum;
