import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const MenuDotIndicatorIcon = (props) => {
    const { active } = props;
    const className = active ? 'menu-dot-indicator active' : 'menu-dot-indicator inactive';
    return <FiberManualRecordIcon className={className} style={{}} />;
};

export default MenuDotIndicatorIcon;
