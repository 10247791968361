class UserSettingsMapper {
    setIds = (initialQueryResponse) => {
        this.keyIds = {};
        this.keyTypes = {};
        this.keyLabels = {};
        this.keyCategories = {};
        Object.keys(initialQueryResponse.edges).forEach((i) => {
            this.keyIds[initialQueryResponse.edges[i].node.key] = initialQueryResponse.edges[i].node.id;
            this.keyTypes[initialQueryResponse.edges[i].node.key] = initialQueryResponse.edges[i].node.type;
            this.keyLabels[initialQueryResponse.edges[i].node.key] = initialQueryResponse.edges[i].node.label;
            this.keyCategories[initialQueryResponse.edges[i].node.key] = initialQueryResponse.edges[i].node.category;
        });
        return this;
    };

    mapToForm = (props) => {
        let formObj = {};
        Object.keys(props.edges).forEach((i) => {
            let v = props.edges[i].node.value;
            if (this.getValueType(v) === 'BOOL') {
                v = v === 'true';
            }
            formObj[props.edges[i].node.key] = v;
        });

        return formObj;
    };

    getType = (k) => this.keyTypes[k];
    getId = (k) => this.keyIds[k];
    getLabel = (k) => this.keyLabels[k];
    getCategory = (k) => this.keyCategories[k];

    getValueType = (v) => {
        if (v === 'true' || v === 'false') {
            return 'BOOL';
        }

        return 'STRING';
    };

    mapFromForm = (values) => {
        if (typeof this.keyIds === 'undefined') {
            return {};
        }

        return {
            userSettings: Object.entries(values).map((entry) => {
                let k = entry[0],
                    v = entry[1];

                if (this.keyTypes[k] === 'BOOL') {
                    v = v > 0 ? 'true' : 'false';
                }

                return {
                    id: this.keyIds[k],
                    key: k,
                    label: this.keyLabels[k],
                    category: this.keyCategories[k],
                    value: v,
                    type: this.keyTypes[k],
                };
            }),
        };
    };
}

export default UserSettingsMapper;
