import { select, call, takeLatest, put } from 'redux-saga/effects';
import {
    actionUpdateLoanEnquiryMatchesSuccess,
    selectorLoanEnquiryMatched,
    UPDATE_LOAN_ENQUIRY_MATCH_FAVOURITE,
} from '../redux/main';
import _ from 'lodash';
import apolloFetchCreator, { setMatchFavourite } from '../../../../favourites/api/set_match_favourites';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';

function* updateLenderLoanEnquiryFavourite(action) {
    const response = yield call(
        setMatchFavourite,
        apolloFetchCreator(INTERMEDIARY_URL),
        action.lenderLoanEnquiryId,
        action.loanEnquiryId,
        action.favourite
    );

    if (action.lenderLoanEnquiryId) {
        const matches = yield select(selectorLoanEnquiryMatched);

        // Deep clone
        const clonedMatches = _.cloneDeep(matches);

        // Find LLE
        const matchedId = clonedMatches.findIndex((match) => {
            return match.lenderLoanEnquiry === action.lenderLoanEnquiryId || match.loanEnquiry === action.loanEnquiryId;
        });

        // Update favourite
        if (-1 !== matchedId) {
            clonedMatches[matchedId].favourite = action.favourite;
            clonedMatches[matchedId].createdAt = response.setLenderLoanEnquiryFavourite.matched.createdAt;
        }

        // Sort matches, favourite and then createdAt
        clonedMatches.sort((itemA, itemB) => {
            if (true === itemA.favourite && false === itemB.favourite) {
                return -1;
            }

            if (false === itemA.favourite && true === itemB.favourite) {
                return 1;
            }

            return 0;
        });

        // Trigger redux update
        yield put(actionUpdateLoanEnquiryMatchesSuccess(clonedMatches));
    }
}

// Main Saga
function* main() {
    yield takeLatest(UPDATE_LOAN_ENQUIRY_MATCH_FAVOURITE, updateLenderLoanEnquiryFavourite);
}

export default main;
