import React from 'react';
import { Button } from '@material-ui/core';

export default function SetUserRealTimeNotifications(props) {
    const { user, setUserRealTimeNotifications, setModalClose } = props;

    return (
        <div className={'set-user-real-time-notifications-container-modal'}>
            <div className={'header'}>Set user real-time notifications</div>
            <div className={'content'}>
                Are you sure you want to change the user ({user.email}) <br />
                real-time notifications to <strong>{!user.realTimeNotifications ? 'ON' : 'OFF'}</strong>
            </div>
            <div className={'actions'}>
                <Button className={'btn-back'} onClick={() => setModalClose()}>
                    BACK
                </Button>
                <Button
                    className={'btn-submit'}
                    variant={'contained'}
                    color="primary"
                    onClick={() => setUserRealTimeNotifications(user)}
                >
                    SUBMIT
                </Button>
            </div>
        </div>
    );
}
