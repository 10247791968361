import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ModalLayout from 'pitch4_modal/src/component/ModalLayout';
import { Container, CenteringContainer, Item, RowItem } from 'pitch4_layout/components/div';
import FormMessage, { MESSAGE_ERROR } from 'pitch4_forms/components/FormMessage';
import { PrimaryButton, SecondaryButton } from 'pitch4_layout/components/buttons';
import { selectorAreErrorsVisible, selectorGetSubmitted, selectorSetStatusSubmissionErrors } from '../redux/main';
import { TextArea } from 'pitch4_forms/components/inputs';
import HelpIcon from '@material-ui/icons/Help';
import { isRequired } from 'pitchblack_react_utils/form/validation';
import { isNumeric } from '../../../../../pitchblack_react_utils/form/validation';
import { TextField } from '../../../../../pitch4_forms/components/inputs';

const adminOverrideForm = ({
    errors = [],
    errorsVisible,
    handleSubmit,
    handleClose,
    netLoanRequired,
    submitting,
    submitted,
}) => {
    return (
        <ModalLayout
            title="Reason for overriding this Lender Loan Enquiry status"
            contentClassName="modal-content-one-pane completion-rejected-modal"
        >
            <CenteringContainer>
                <Item xs={12} className={'content-container'}>
                    <CenteringContainer className={`grey-box`}>
                        <Item xs={1}>
                            <HelpIcon />
                        </Item>
                        <Item xs={10}>
                            <p className="typography-default">
                                In order to properly audit this action, please enter a reason for overriding the Lender
                                Loan Enquiry status.
                            </p>
                        </Item>
                    </CenteringContainer>

                    {errorsVisible &&
                        errors.map((error) => <FormMessage type={MESSAGE_ERROR} messages={error} key={error} />)}
                    <form onSubmit={handleSubmit}>
                        <RowItem>
                            <div className={'completion-rejected-reason'}>
                                <TextArea
                                    name="reason"
                                    placeholder="Reason for changing status"
                                    inputProps={{
                                        className: 'typography-default',
                                        style: { fontSize: '14px', height: '290px' },
                                    }}
                                    validate={[isRequired]}
                                />
                                {netLoanRequired && (
                                    <div className={'form-spacer'}>
                                        <TextField
                                            name="netLoanAmount"
                                            type="number"
                                            placeholder="Enter the net loan amount. Required if setting this Lender Loan Enquiry to complete"
                                            inputProps={{
                                                className: 'typography-default',
                                                style: { fontSize: '14px', margin: '0' },
                                            }}
                                            validate={[isRequired, isNumeric]}
                                        />
                                    </div>
                                )}
                                <Container
                                    className={'button-panel'}
                                    justifyContent={'flex-end'}
                                    direction={'row'}
                                    alignContent={'flex-end'}
                                >
                                    <Item xs={12}>
                                        <SecondaryButton
                                            onClick={handleClose}
                                            name={'cancel-btn'}
                                            disabled={submitting}
                                        >
                                            Cancel
                                        </SecondaryButton>
                                        <PrimaryButton type="submit" name={'next-btn'} disabled={submitting}>
                                            Confirm
                                        </PrimaryButton>
                                    </Item>
                                </Container>
                            </div>
                        </RowItem>
                    </form>
                </Item>
            </CenteringContainer>
        </ModalLayout>
    );
};

adminOverrideForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleReject: PropTypes.func.isRequired,
    errors: PropTypes.array,
    errorsVisible: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitted: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        submitted: selectorGetSubmitted(state),
        errors: selectorSetStatusSubmissionErrors(state),
        errorsVisible: selectorAreErrorsVisible(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'admin_override',
    })
)(adminOverrideForm);
