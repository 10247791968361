import React from 'react';
import { Field } from 'redux-form';
import { TextField as ErikasTextField } from 'redux-form-material-ui';
import _ from 'lodash';
import { ADDITIONAL_INFO_NOTES } from '../../../pitch4_enum/enum/FactEnum';

class TextArea extends React.Component {
    render() {
        const {
            name,
            fullWidth = true,
            InputProps = { disableUnderline: true },
            value,
            variant = 'standard',
            rows = 10,
            placeholder = '',
            ...rest
        } = this.props;

        if (_.includes(['development', 'qa', 'test'], process.env.NODE_ENV)) {
            InputProps['data-cy'] = `${name}-input`;
        }

        if (name === ADDITIONAL_INFO_NOTES) {
            return (
                <div className={`text-area ${fullWidth ? 'block' : 'inline-block'}`}>
                    <Field
                        className={'text-area-auto-height'}
                        type={`text`}
                        multiline={true}
                        placeholder={{ ...rest }.placeholder || ''}
                        rows={rows}
                        name={name}
                        variant={variant}
                        component={ErikasTextField}
                        fullWidth={fullWidth}
                        InputProps={InputProps}
                        value={value}
                    />
                </div>
            );
        }

        return (
            <div className={`text-area ${fullWidth ? 'block' : 'inline-block'}`}>
                <Field
                    type={`text`}
                    multiline={true}
                    rows={rows}
                    name={name}
                    variant={variant}
                    component={ErikasTextField}
                    fullWidth={fullWidth}
                    InputProps={InputProps}
                    value={value}
                    placeholder={placeholder}
                    {...rest}
                />
            </div>
        );
    }
}

export default TextArea;
