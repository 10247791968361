import React from 'react';
import PropTypes from 'prop-types';
import {
    STATUS_COMPLETION_REJECTED,
    STATUS_COMPLETION_REQUESTED,
    STATUS_ENDED,
} from '../../borrower/enum/LenderLoanEnquiries/StatusEnum';
import PrimaryButton from 'pitch4_layout/components/buttons/PrimaryButton';
import BannerNoticeError from '../components/BannerNoticeError';

const StatusBannerNotice = ({ status, onClick }) => {
    switch (status) {
        case STATUS_COMPLETION_REQUESTED:
            return (
                <BannerNoticeError
                    message={
                        <>
                            <span>This enquiry has been marked as complete by a lender.</span>
                            <PrimaryButton onClick={onClick}>RESPOND</PrimaryButton>
                        </>
                    }
                />
            );
        case STATUS_COMPLETION_REJECTED:
            return (
                <BannerNoticeError message="We are currently reviewing your rejection of the lender's completion request." />
            );
        case STATUS_ENDED:
            return (
                <BannerNoticeError message="The enquiry this match is for has been marked as complete by another lender and has therefore ended." />
            );
        default:
            return null;
    }
};

StatusBannerNotice.propTypes = {
    status: PropTypes.string.isRequired,
    onClick: PropTypes.func,
};

StatusBannerNotice.defaultProps = {
    onClick: () => {},
};

export default StatusBannerNotice;
