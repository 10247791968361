import SessionManager from '../../../../pitchblack_react_utils/session/SessionManager';

const sm = new SessionManager();

export const REDUCER_NAMESPACE = 'user.configure_profile';

// Actions
export const EDIT_PROFILE_SUBMISSION_SUCCESS = `${REDUCER_NAMESPACE}.submission_success`;
export const SET_MISSING_REQUIRED_FIELDS = `${REDUCER_NAMESPACE}.set_missing_required_fields`;
export const SET_MISSING_REQUIRED_FIELDS_LIST = `${REDUCER_NAMESPACE}.set_missing_required_fields_list`;
export const SET_USER_ALERTS_ACTIVE = `${REDUCER_NAMESPACE}.set_user_alerts_active`;

// Reducer
const initialState = {
    hasMissingRequiredFields: true,
    missingRequiredFieldsList: [],
    alertActive: sm.getAlertsActive(),
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MISSING_REQUIRED_FIELDS:
            return {
                ...state,
                hasMissingRequiredFields: action.hasMissingRequiredFields,
            };
        case SET_MISSING_REQUIRED_FIELDS_LIST:
            return {
                ...state,
                missingRequiredFieldsList: action.missingRequiredFieldsList,
            };
        case SET_USER_ALERTS_ACTIVE:
            return {
                ...state,
                alertActive: action.alertActive,
            };

        default:
            return { ...state };
    }
};

// Default export reducer
export default reducer;

// Action Creators
export const actionSetProfileSubmissionSuccess = () => {
    return {
        type: EDIT_PROFILE_SUBMISSION_SUCCESS,
    };
};

export const actionSetHasMissingRequiredFields = (hasMissingRequiredFields) => {
    return {
        type: SET_MISSING_REQUIRED_FIELDS,
        hasMissingRequiredFields,
    };
};

export const actionSetMissingRequiredFieldsList = (missingRequiredFieldsList) => {
    return {
        type: SET_MISSING_REQUIRED_FIELDS_LIST,
        missingRequiredFieldsList,
    };
};

export const actionSetSetUserAlertsActive = (alertActive) => {
    return {
        type: SET_USER_ALERTS_ACTIVE,
        alertActive,
    };
};

// Selectors
export const selectorHasMissingRequiredFields = (state) => {
    return state[REDUCER_NAMESPACE].hasMissingRequiredFields;
};

export const selectorGetMissingRequiredFieldsList = (state) => {
    return state[REDUCER_NAMESPACE].missingRequiredFieldsList;
};

export const selectorGetUserAlertsActive = (state) => {
    return state[REDUCER_NAMESPACE].alertActive;
};
