import React from 'react';
import PropTypes from 'prop-types';
import { Error } from '@material-ui/icons';

class ErrorIcon extends React.Component {
    render() {
        const { style } = this.props;

        return <Error style={style} />;
    }
}

ErrorIcon.propTypes = {
    style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

ErrorIcon.defaultProps = {
    style: { display: 'inline-block', width: 25, height: 25 },
};

export default ErrorIcon;
