import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AdministerLoanEnquiryInboxCentricContainer from '../../administer_lenders_business_streams/containers/TableStyleContainer';
import AdministerActiveLoanEnquiriesContainer from '../../administer_loanenquiries/containers/ActiveTableStyleContainer';
import AdministerInactiveLoanEnquiriesContainer from '../../administer_loanenquiries/containers/InactiveTableStyleContainer';
import AdministerLenderLoanEnquiryContainer from '../../administer_loanenquiries_viewlenderloanenquiry/containers/MasonryStyleContainer';
import AdministerUsersContainer from '../../administer_users/containers/TableStyleContainer';
import AdministerDocumentsContainer from '../../administer_documents/containers/TableStyleContainer';
import UserConfigureProfile from '../../../../user/configure_profile/containers/ConfigureProfileContainer';
import DashboardContainer from '../../dashboard/containers/DashboardContainer';
import CreateBusinessStreamContainer from '../../business_streams/containers/CreateContainer';
import ViewBusinessStreamContainer from '../../business_streams/containers/ViewContainer';
import EditBusinessStreamContainer from '../../business_streams/containers/EditContainer';
import TwoFactorAuthIndexContainer from '../../../../user/two_factor_auth/containers/IndexContainer';

import {
    ROUTE_SLAM_2FA_INDEX,
    ROUTE_SLAM_ADMINISTER_USERS_LIST,
    ROUTE_SLAM_BUSINESS_STREAM_CREATE,
    ROUTE_SLAM_BUSINESS_STREAM_EDIT,
    ROUTE_SLAM_BUSINESS_STREAM_VIEW,
    ROUTE_SLAM_BUSINESS_STREAMS,
    ROUTE_SLAM_CONFIGURE_PROFILE,
    ROUTE_SLAM_DOCUMENTS_VIEW,
    ROUTE_SLAM_LEAD,
    ROUTE_SLAM_LEADS_ACTIVE,
    ROUTE_SLAM_LEADS_INACTIVE,
    ROUTE_SLAM_ROOT,
} from '../../../../../pitch4_routing';

class ContentContainer extends React.Component {
    render() {
        return (
            <div id="content">
                <Switch>
                    <Route path={ROUTE_SLAM_DOCUMENTS_VIEW} render={() => <AdministerDocumentsContainer />} />
                    <Route
                        path={ROUTE_SLAM_CONFIGURE_PROFILE}
                        render={() => <UserConfigureProfile showJobTitle={true} />}
                    />
                    <Route path={ROUTE_SLAM_2FA_INDEX} render={() => <TwoFactorAuthIndexContainer />} />
                    <Route path={ROUTE_SLAM_BUSINESS_STREAM_EDIT} render={() => <EditBusinessStreamContainer />} />
                    <Route path={ROUTE_SLAM_BUSINESS_STREAM_CREATE} render={() => <CreateBusinessStreamContainer />} />
                    <Route path={ROUTE_SLAM_BUSINESS_STREAM_VIEW} render={() => <ViewBusinessStreamContainer />} />
                    <Route
                        path={ROUTE_SLAM_BUSINESS_STREAMS}
                        render={() => <AdministerLoanEnquiryInboxCentricContainer />}
                    />
                    <Route path={ROUTE_SLAM_ADMINISTER_USERS_LIST} render={() => <AdministerUsersContainer />} />
                    <Route path={ROUTE_SLAM_LEAD} render={() => <AdministerLenderLoanEnquiryContainer />} />
                    <Route path={ROUTE_SLAM_LEADS_ACTIVE} render={() => <AdministerActiveLoanEnquiriesContainer />} />
                    <Route
                        path={ROUTE_SLAM_LEADS_INACTIVE}
                        render={() => <AdministerInactiveLoanEnquiriesContainer />}
                    />
                    <Route path={ROUTE_SLAM_ROOT} render={() => <DashboardContainer />} />
                </Switch>
            </div>
        );
    }
}

ContentContainer.propTypes = {};

export default ContentContainer;
