import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Item } from '../../../pitch4_layout/components/div';
import { TextLabel } from '../../../pitch4_forms/components/inputs';
import {
    getConfigFieldLabel,
    getConfigFieldPlaceholder,
    getConfigFieldType,
    getConfigFieldTooltip,
    getConfigFieldValidators,
} from '../utils/configFieldLookup';
import Tooltip from '../../utils/tooltip/Tooltip';
import { filterTokens } from '../../utils/filter-tokens';

class GenericFormSection extends React.Component {
    render() {
        const {
            sectionConfig,
            sectionName,
            getConfigEnumValues,
            fieldInputProps = {},
            fieldTypeInputProps = {},
            fieldRowComponent,
        } = this.props;
        const props = this.props;

        return (
            <React.Fragment>
                {Object.keys(sectionConfig['fields']).map(function (fieldName) {
                    const customSectionInputProps = _.get(fieldInputProps, [sectionName, fieldName], {}) || {};
                    const type = getConfigFieldType(sectionConfig, fieldName);
                    const tooltip = getConfigFieldTooltip(sectionConfig, fieldName);
                    const customFieldTypeInputProps = _.get(fieldTypeInputProps, type, {}) || {};
                    const label = (
                        <span>
                            {filterTokens(getConfigFieldLabel(sectionConfig, fieldName))}
                            {tooltip && <Tooltip content={tooltip} />}
                        </span>
                    );

                    const getColumnOptions = () => {
                        const columnOptions = getConfigEnumValues(sectionConfig['fields'][fieldName]['enum'] || null);
                        const fieldConfig = sectionConfig['fields'][fieldName];

                        // Remove 'select_all' option if field config is not multi=true
                        if (!fieldConfig.multi) {
                            return columnOptions.filter((option) => option.value !== 'select_all');
                        }

                        return columnOptions;
                    };

                    return (
                        <Item xs={12} key={`${fieldName}_item`}>
                            {React.createElement(fieldRowComponent, {
                                key: `${fieldName}_row`,
                                label: (
                                    <TextLabel label={label} sectionName={sectionName} sectionConfig={sectionConfig} />
                                ),
                                sectionName: sectionName,
                                sectionConfig: sectionConfig,
                                columnName: fieldName,
                                columnType: type || 'text',
                                columnOptions: getColumnOptions(),
                                inputProps: {
                                    validate: getConfigFieldValidators(sectionConfig, fieldName),
                                    placeholder: getConfigFieldPlaceholder(sectionConfig, fieldName),
                                    ...customSectionInputProps,
                                    ...customFieldTypeInputProps,
                                },
                                ...props,
                            })}
                        </Item>
                    );
                })}
            </React.Fragment>
        );
    }
}

GenericFormSection.propTypes = {
    getFormValue: PropTypes.func.isRequired,
    sectionName: PropTypes.string.isRequired,
    sectionConfig: PropTypes.object.isRequired,
    fieldInputProps: PropTypes.object,
    fieldRowComponent: PropTypes.func.isRequired,
    getConfigEnumValues: PropTypes.func.isRequired,
};

export default GenericFormSection;
