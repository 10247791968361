import { INTERMEDIARY_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });

const LoanEnquirySetStatusesMutation = `
  mutation setLoanEnquiryStatus($id: ID!, $input: intermediary_setLoanEnquiryStatusInput!){
    setLoanEnquiryStatus(id: $id, input: $input){
      loanEnquiry {
        id
        status
      }
    }
  } 
`;

export const setLoanEnquiryStatus = (loanEnquiryId, newStatus) => {
    let out = apolloFetch({
        query: LoanEnquirySetStatusesMutation,
        variables: {
            id: loanEnquiryId,
            input: {
                newStatus: newStatus,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });

    return out;
};
