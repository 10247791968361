import { API_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const setLETermsSent = (uri = API_URL, lenderLoanEnquiry, termsSent) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation setLoanEnquiryTermsSent($id: ID!, $termsSent: Boolean!) {
          setLoanEnquiryTermsSent(id: $id, termsSent: $termsSent) {
             id
             termsSent
          }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            id: lenderLoanEnquiry,
            termsSent: termsSent,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
