import { call, put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import { registerBorrowerInterest } from '../api/register_interest';
import {
    BORROWER_REGISTER_NEXT_STEP,
    BORROWER_REGISTER_NEXT_STEP_SUCCESS,
    BORROWER_REGISTER_STEP_SUBMISSION_ERRORS,
    selectorBorrowerRegisterStep,
} from '../redux/step';

import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../notifications/sagas/container';
import { actionHubspotTrackingIdentifyVisitor } from '../../hubspot/redux/tracking';

export const SAGA_NAMESPACE = 'sagas.borrower.registration';

//Saga Action Creators

//Worker Sagas

function* borrowerRegisterNextStep(action) {
    const step = yield select(selectorBorrowerRegisterStep);
    if (step === 1) {
        const email = action.input.email;
        const registerInterestResponse = yield call(registerBorrowerInterest, { email });
        if (_.get(registerInterestResponse, ['data', 'registerBorrowerInterest', 'success']) !== undefined) {
            yield put({ type: SAGA_ACTION_SUCCESS, message: 'Successfully registered interest' });
            yield put(actionHubspotTrackingIdentifyVisitor(email, {}));
            yield put({ type: BORROWER_REGISTER_NEXT_STEP_SUCCESS });
        } else if (_.get(registerInterestResponse, ['errors']) !== undefined) {
            const errors = _.map(registerInterestResponse.errors, (err) => err.message);
            const duplicateEmailError = _.includes(errors, 'This email address is already registered. Please log in.');
            yield put({ type: BORROWER_REGISTER_STEP_SUBMISSION_ERRORS, errors, duplicateEmailError });
            yield put({ type: SAGA_ACTION_ERROR, message: 'There was problem with your submission' });
        }
    } else {
        yield put({ type: BORROWER_REGISTER_NEXT_STEP_SUCCESS });
    }
}

//Main Saga
function* main() {
    yield takeLatest(BORROWER_REGISTER_NEXT_STEP, borrowerRegisterNextStep);
}

export default main;
