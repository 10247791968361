import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Item, RowItem } from '../../../../../pitch4_layout/components/div';
import ChatContainer from '../../chat/containers/ChatContainer';
import { STATUS_ENDED } from '../../enum/LenderLoanEnquiries/StatusEnum';
import {
    STATUS_ON_HOLD as LE_STATUS_ON_HOLD,
    STATUS_WITHDRAWN as LE_STATUS_WITHDRAWN,
} from '../../enum/LoanEnquiries/StatusEnum';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import ChannelMessage from '../../../../../pitch4_chat/messaging/ChannelMessage';
import P4Logo from '../../../../../assets/images/logo.svg';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import DocumentList from '../../view_lender_loan_enquiry/components/DocumentList';
import { BRAND_NAME } from '../../../../../bootstrap/constants/constants';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const channelMessageFactory = new ChannelMessage();

class AdminCaseManagementDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
        };
    }

    buildChatLabel = () => {
        const { acmChatAlertActive } = this.props;

        if (acmChatAlertActive) {
            return (
                <span>
                    Admin Chat <Badge label={'update'} style={{ marginLeft: 5 }} />
                </span>
            );
        }

        return 'Admin Chat';
    };

    buildDocumentsLabel = () => {
        const { acmDocumentsAlertActive } = this.props;

        if (acmDocumentsAlertActive) {
            return (
                <span>
                    Documents <Badge label={'update'} style={{ marginLeft: 5 }} />
                </span>
            );
        }

        return 'Documents';
    };

    buildDocumentList = () => {
        const { session, loanEnquiry, handleACMDocumentsAlert } = this.props;

        const isGloballyDisabled = loanEnquiry ? loanEnquiry.status === STATUS_ENDED : true;
        const enquiryWithdrawn = loanEnquiry ? loanEnquiry.status === LE_STATUS_WITHDRAWN : true;
        const enquiryOnHold = loanEnquiry ? loanEnquiry.status === LE_STATUS_ON_HOLD : true;

        return (
            <div className={'document-list'}>
                <DocumentList
                    adminCaseManagement={true}
                    disabled={isGloballyDisabled || session.hasAssumedToken() || enquiryOnHold}
                    loanEnquiry={loanEnquiry}
                    disableUploadButton={
                        enquiryWithdrawn || isGloballyDisabled || session.hasAssumedToken() || enquiryOnHold
                    }
                    handleACMDocumentsAlert={handleACMDocumentsAlert}
                />
            </div>
        );
    };

    render() {
        const { anchor, open, toggleAdminCaseManagementDrawer, loanEnquiry, session, handleACMChatAlert } = this.props;

        const isGloballyDisabled = loanEnquiry ? loanEnquiry.status === STATUS_ENDED : true;
        const enquiryWithdrawn = loanEnquiry ? loanEnquiry.status === LE_STATUS_WITHDRAWN : true;
        const enquiryOnHold = loanEnquiry ? loanEnquiry.status === LE_STATUS_ON_HOLD : true;

        // !!! This is a default message and is not stored in the database
        const defaultMessage = channelMessageFactory
            .setContent({
                value:
                    'This is where our <strong>case management team</strong> will send you any updates or respond / help you with your enquiries. If you would like help on any matter or an update on your case please <strong>message the team here.</strong>',
            })
            .setUserRole('ROLE_SUPER_ADMIN')
            .setUserProfile({ given: BRAND_NAME })
            .setType('TYPE_TEXT')
            .setSentAt(null);

        const handleTabChange = (event, newValue) => {
            this.setState({ tabValue: newValue });
        };

        return (
            <Drawer variant={'temporary'} anchor={anchor} open={open} className={'admin-case-management-drawer'}>
                {loanEnquiry && (
                    <div>
                        <div className={'header'}>
                            <div className={'title'}>Admin Case Management</div>
                            <IconButton onClick={toggleAdminCaseManagementDrawer()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className={'description'}>
                            <div>
                                <img src={P4Logo} alt={`${BRAND_NAME} Logo`} style={{ height: 51, marginBottom: 20 }} />
                            </div>
                            <div>
                                <p>
                                    We’re here to help you smoothly use the {BRAND_NAME} Platform to attain the funding
                                    you need. You can ask us any questions right here in the{' '}
                                    <strong>Admin Case Management</strong> chat, or even{' '}
                                    <strong>upload documents</strong> for us to view.
                                </p>
                                <p style={{ marginTop: 10 }}>
                                    From time to time we may also send you important documents for your funding
                                    application, which you can view on the <strong>Document</strong> tab.
                                </p>
                            </div>
                        </div>
                        <div style={{ marginTop: 20 }}>
                            <hr className={'tabs-indicator-top-line'} />
                            <Tabs
                                value={this.state.tabValue}
                                onChange={handleTabChange}
                                style={{ paddingLeft: 40 }}
                                TabIndicatorProps={{
                                    children: (
                                        <div className={'tab-indicator-container'}>
                                            <div className={'tab-active-indicator'} />
                                        </div>
                                    ),
                                }}
                            >
                                <Tab label={this.buildChatLabel()} {...a11yProps(0)} className={'tab'} />
                                <Tab label={this.buildDocumentsLabel()} {...a11yProps(1)} className={'tab'} />
                            </Tabs>
                            <TabPanel value={this.state.tabValue} index={0}>
                                <div className={'view-lead'}>
                                    <div className={'view-lead-content'}>
                                        <Item xs={12} className={`view-lead-lender-comms`} id={'lender-chat'}>
                                            <div className={'p4style-component-container'}>
                                                <RowItem className={`p4style-component-body`}>
                                                    <ChatContainer
                                                        defaultMessages={[defaultMessage]}
                                                        loanEnquiryId={loanEnquiry.id}
                                                        channelId={
                                                            loanEnquiry && loanEnquiry.channel
                                                                ? loanEnquiry.channel.id
                                                                : null
                                                        }
                                                        disableForm={
                                                            session.hasAssumedToken() ||
                                                            enquiryWithdrawn ||
                                                            isGloballyDisabled ||
                                                            enquiryOnHold
                                                        }
                                                        handleACMChatAlert={handleACMChatAlert}
                                                    />
                                                </RowItem>
                                            </div>
                                        </Item>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={1}>
                                {this.buildDocumentList()}
                            </TabPanel>
                        </div>
                    </div>
                )}
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return { dispatch };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(AdminCaseManagementDrawer);
