import { API_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const setBrokerExtraNotifications = (uri = API_URL, brokerId, enabled) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation setBrokerExtraNotifications($brokerId: ID!, $enabled: Boolean!) {
          setBrokerExtraNotifications(brokerId: $brokerId, enabled: $enabled) {
             success
          }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            brokerId: brokerId,
            enabled: enabled,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
