import React from 'react';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import {
    ROUTE_LANDING,
    ROUTE_PITCH4_BLOG,
    ROUTE_PITCH4_HOW_TO_SETUP_BUSINESS_STREAMS,
    ROUTE_PITCH4_KNOWLEDGE_BASE,
    ROUTE_PITCH4_KNOWLEDGE_BASE_NEW_TEAM_MEMBER_LENDER,
    ROUTE_PITCH4_NEW_FEATURES_2021,
    ROUTE_PITCH4_RESOURCES_FOR_LENDERS,
    ROUTE_SLAM_BUSINESS_STREAM_CREATE,
    ROUTE_SLAM_BUSINESS_STREAMS,
    ROUTE_SLAM_DOCUMENTS_VIEW,
    ROUTE_SLAM_LEADS_ACTIVE,
} from '../../../../../pitch4_routing';
import Paper from '@material-ui/core/Paper';
import { actionSetJoyrideType } from '../../../../joyride/redux';
import Header from '../../../../../pitch4_elements/dashboard/Header';
import { FormControl, Grid, Hidden, Select } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Divider from '@material-ui/core/Divider';
import AccordionActions from '@material-ui/core/AccordionActions';
import BuildIcon from '@material-ui/icons/Build';
import HelpIcon from '@material-ui/icons/Help';
import RouteManagerFactory from '../../../../../pitch4_routing/src/RouteManagerFactory';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { BRAND_NAME, SLAM_URL } from '../../../../../bootstrap/constants/constants';
import SLAMLECard from '../../../../../pitch4_elements/dashboard/SLAMLECard';
import TimeRangeEnum, { ALL_TIME, PAST_30_DAYS, PAST_60_DAYS, PAST_6_MONTHS } from '../../enum/Stats/TimeRangeEnum';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import { formatterInPounds } from '../../../../../pitchblack_react_utils/currency/Formatter';
import RebrandRedirect from '../../../../../pitch4_elements/popups/RebrandRedirect';

const routeManagerFactory = new RouteManagerFactory();
const routeManager = routeManagerFactory.create();
const timeRangeEnum = new TimeRangeEnum();
const apolloFetch = createP4ApolloFetch({ uri: SLAM_URL });

class DashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: null,
            leadsStatsTimeRangeSelected: ALL_TIME,
            completionsStatsTimeRangeSelected: ALL_TIME,
            timeRanges: [ALL_TIME, PAST_30_DAYS, PAST_60_DAYS, PAST_6_MONTHS],
            newLeads: {
                intermediaryLeads: 0,
                borrowerLeads: 0,
                totalLeadsValue: 0,
                numberOfLeads: 0,
                leads: [],
            },
            completedLeads: {
                intermediaryLeads: 0,
                borrowerLeads: 0,
                totalLeadsValue: 0,
                numberOfLeads: 0,
                leads: [],
            },
            leadsInProgress: {
                intermediaryLeads: 0,
                borrowerLeads: 0,
                totalLeadsValue: 0,
                numberOfLeads: 0,
                leads: [],
            },
            leadsInApplication: {
                intermediaryLeads: 0,
                borrowerLeads: 0,
                totalLeadsValue: 0,
                numberOfLeads: 0,
                leads: [],
            },
            leadStatistics: {
                oneMonth: {
                    averageValue: 0,
                    totalLeads: 0,
                    totalValue: 0,
                },
                twoMonths: {
                    averageValue: 0,
                    totalLeads: 0,
                    totalValue: 0,
                },
                sixMonths: {
                    averageValue: 0,
                    totalLeads: 0,
                    totalValue: 0,
                },
                allTime: {
                    averageValue: 0,
                    totalLeads: 0,
                    totalValue: 0,
                },
            },
            completedStatistics: {
                oneMonth: {
                    averageValue: 0,
                    totalLeads: 0,
                    totalValue: 0,
                },
                twoMonths: {
                    averageValue: 0,
                    totalLeads: 0,
                    totalValue: 0,
                },
                sixMonths: {
                    averageValue: 0,
                    totalLeads: 0,
                    totalValue: 0,
                },
                allTime: {
                    averageValue: 0,
                    totalLeads: 0,
                    totalValue: 0,
                },
            },
        };
    }

    componentDidMount() {
        const { session, redirect, setJoyrideType } = this.props;

        if (!session.isAuthenticated()) {
            redirect(ROUTE_LANDING);
        }

        setJoyrideType(null);

        this.getData().then((resp) => {
            const { lenderDashboard } = resp.data.dashboard;

            // Add lead stats
            this.setState({
                leadStatistics: lenderDashboard.leadStatistics,
                completedStatistics: lenderDashboard.completedStatistics,
            });
            // Add new leads
            if (lenderDashboard.new) {
                this.setState({
                    newLeads: lenderDashboard.new,
                });
            }
            // Add leads in progress
            if (lenderDashboard.inProgress) {
                this.setState({
                    leadsInProgress: lenderDashboard.inProgress,
                });
            }
            // Add leads in application
            if (lenderDashboard.application) {
                this.setState({
                    leadsInApplication: lenderDashboard.application,
                });
            }
            // Add completed leads
            if (lenderDashboard.completed) {
                this.setState({
                    completedLeads: lenderDashboard.completed,
                });
            }
        });
    }

    getData() {
        const query = `
            query dashboard {
              dashboard {
                lenderDashboard {
                  new {
                    intermediaryLeads
                    borrowerLeads
                    totalLeadsValue
                    numberOfLeads
                    leads {
                      loanEnquiry{
                        id
                        isNewForm
                        loanEnquiryData {
                            loanToValue
                            loanToCost
                            loanToGrossDevelopmentValue
                            dayOneLoanAmountPercentage
                            additionalInformation
                            rentalIncomeResidential
                            hmoBedroom
                            hmoRoomsSelfContained
                            hmoHasLicense
                            hmoNeedsLicense
                            buyToLetExperience
                            rentalIncomeCommercial
                            rentalIncomePortfolio
                            rentalIncome
                            commercialUnitsRentedOut
                            commercialUnitOwnerOccupied
                            commercialUnitAmount
                            commercialType
                            commercialTypeOther
                            siteDescription
                            siteIntention
                            refinance
                            businessSeasonal
                            businessType
                            businessName
                            businessWebsite
                            businessTradingLength
                            businessSector
                            businessFacilityNeededBy
                            businessFacilityTerm
                            businessCurrentPaymentTerms
                            businessOutstandingInvoices
                            businessPdqIncome
                            propertyType
                            loanAmount
                            loanPurpose
                            turnOver
                            businessProfitable
                            businessOverdraftFacility
                            businessOutstandingDebt
                            businessAdverseCredit
                            businessAdverseCreditExplanation
                            businessDirectorsAdverseCredit
                            businessDirectorsAdverseCreditExplanation
                            shareholdersAreHomeOwners
                            propertyAvailableForAdditionalSecurity
                            planningFor
                            developmentLength
                            refurbishmentLength
                            outstandingLoan
                            dayOneLoanAmount
                            unitsPreSold
                            worksExplanation
                            totalDevelopmentCosts
                            buildCostsBorrowing
                            expectedGrossDevelopmentValue
                            previousDevelopmentExperience
                            previousRefurbishmentExperience
                            teamDevelopmentExperience
                            epcRating
                            loanType
                            fundingType
                            businessSecuredFinance
                            loanUsedInUkEu
                            planningInPlace
                            endValue
                            percentageResidential
                            percentageCommercial
                            lender
                            firstChargeAmount
                            currentValue
                            properties
                            portfolioValue
                            portfolioAlreadyOwned
                            loanTerm
                            loanNeededBy
                            preApproveMatches
                            loanAmountRefurbishment
                            percentageResidentialSquareFeet
                            percentageResidentialValue
                            percentageCommercialValue
                            residentialUnitCount
                            residentialUnitValue
                            businessOccupyEntireProperty
                            commercialUnitRentBreakdown
                            commercialTenantAmount
                            ownsOtherCommercialProperty
                            ownOtherProperties
                            occupyProperty
                            ownsOtherInvestmentProperties
                            familyHasOccupied
                            secondCharge
                            occupyingGreaterThan40Percent
                            location
                            locationOther
                            address
                            postcode
                            propertyOwnership
                            refurbishmentCost
                            invoiceFinanceType
                            leaseHirePurchase
                            mainApplicantCreditDifficulties
                            mainApplicantCreditDifficultiesExplanation
                            mainApplicantCitizenshipStatus
                            mainApplicantCountryOfResidence
                            mainApplicantCountryOfResidenceOther
                            mainApplicantNationality
                            mainApplicantNationalityOther
                            mainApplicantOccupation
                            mainApplicantGrossIncome
                            mainApplicantExitStrategy
                            agreeToTerms
                        }
                        submittedBy{
                          roles{
                            name
                            label
                          }
                        }
                        
                      }
                      status
                      id
                      submittedBy{
                        title
                        given
                        family
                      }
                      loanEnquiryApplicants{
                        applicant{
                          given
                          family
                        }
                      }
                      storedData{
                        meta{
                          key
                        }
                        value
                      }
                      status
                      createdAt
                      updatedAt
                      alertActive
                    }
                  }
                  completed {
                    intermediaryLeads
                    borrowerLeads
                    totalLeadsValue
                    numberOfLeads
                    leads {
                      loanEnquiry{
                        id
                        isNewForm
                        loanEnquiryData {
                            loanToValue
                            loanToCost
                            loanToGrossDevelopmentValue
                            dayOneLoanAmountPercentage
                            additionalInformation
                            rentalIncomeResidential
                            hmoBedroom
                            hmoRoomsSelfContained
                            hmoHasLicense
                            hmoNeedsLicense
                            buyToLetExperience
                            rentalIncomeCommercial
                            rentalIncomePortfolio
                            rentalIncome
                            commercialUnitsRentedOut
                            commercialUnitOwnerOccupied
                            commercialUnitAmount
                            commercialType
                            commercialTypeOther
                            siteDescription
                            siteIntention
                            refinance
                            businessSeasonal
                            businessType
                            businessName
                            businessWebsite
                            businessTradingLength
                            businessSector
                            businessFacilityNeededBy
                            businessFacilityTerm
                            businessCurrentPaymentTerms
                            businessOutstandingInvoices
                            businessPdqIncome
                            propertyType
                            loanAmount
                            loanPurpose
                            turnOver
                            businessProfitable
                            businessOverdraftFacility
                            businessOutstandingDebt
                            businessAdverseCredit
                            businessAdverseCreditExplanation
                            businessDirectorsAdverseCredit
                            businessDirectorsAdverseCreditExplanation
                            shareholdersAreHomeOwners
                            propertyAvailableForAdditionalSecurity
                            planningFor
                            developmentLength
                            refurbishmentLength
                            outstandingLoan
                            dayOneLoanAmount
                            unitsPreSold
                            worksExplanation
                            totalDevelopmentCosts
                            buildCostsBorrowing
                            expectedGrossDevelopmentValue
                            previousDevelopmentExperience
                            previousRefurbishmentExperience
                            teamDevelopmentExperience
                            epcRating
                            loanType
                            fundingType
                            businessSecuredFinance
                            loanUsedInUkEu
                            planningInPlace
                            endValue
                            percentageResidential
                            percentageCommercial
                            lender
                            firstChargeAmount
                            currentValue
                            properties
                            portfolioValue
                            portfolioAlreadyOwned
                            loanTerm
                            loanNeededBy
                            preApproveMatches
                            loanAmountRefurbishment
                            percentageResidentialSquareFeet
                            percentageResidentialValue
                            percentageCommercialValue
                            residentialUnitCount
                            residentialUnitValue
                            businessOccupyEntireProperty
                            commercialUnitRentBreakdown
                            commercialTenantAmount
                            ownsOtherCommercialProperty
                            ownOtherProperties
                            occupyProperty
                            ownsOtherInvestmentProperties
                            familyHasOccupied
                            secondCharge
                            occupyingGreaterThan40Percent
                            location
                            locationOther
                            address
                            postcode
                            propertyOwnership
                            refurbishmentCost
                            invoiceFinanceType
                            leaseHirePurchase
                            mainApplicantCreditDifficulties
                            mainApplicantCreditDifficultiesExplanation
                            mainApplicantCitizenshipStatus
                            mainApplicantCountryOfResidence
                            mainApplicantCountryOfResidenceOther
                            mainApplicantNationality
                            mainApplicantNationalityOther
                            mainApplicantOccupation
                            mainApplicantGrossIncome
                            mainApplicantExitStrategy
                            agreeToTerms
                        }
                        submittedBy{
                          roles{
                            name
                            label
                          }
                        }
                      }
                      id
                      submittedBy{
                        title
                        given
                        family
                      }
                      loanEnquiryApplicants{
                        applicant{
                          given
                          family
                        }
                      }
                      storedData{
                        meta{
                          key
                        }
                        value
                      }
                      createdAt
                      updatedAt
                      alertActive
                    }
                  }
                  inProgress {
                    intermediaryLeads
                    borrowerLeads
                    totalLeadsValue
                    numberOfLeads
                    leads {
                      loanEnquiry{
                        id
                        isNewForm
                        loanEnquiryData {
                            loanToValue
                            loanToCost
                            loanToGrossDevelopmentValue
                            dayOneLoanAmountPercentage
                            additionalInformation
                            rentalIncomeResidential
                            hmoBedroom
                            hmoRoomsSelfContained
                            hmoHasLicense
                            hmoNeedsLicense
                            buyToLetExperience
                            rentalIncomeCommercial
                            rentalIncomePortfolio
                            rentalIncome
                            commercialUnitsRentedOut
                            commercialUnitOwnerOccupied
                            commercialUnitAmount
                            commercialType
                            commercialTypeOther
                            siteDescription
                            siteIntention
                            refinance
                            businessSeasonal
                            businessType
                            businessName
                            businessWebsite
                            businessTradingLength
                            businessSector
                            businessFacilityNeededBy
                            businessFacilityTerm
                            businessCurrentPaymentTerms
                            businessOutstandingInvoices
                            businessPdqIncome
                            propertyType
                            loanAmount
                            loanPurpose
                            turnOver
                            businessProfitable
                            businessOverdraftFacility
                            businessOutstandingDebt
                            businessAdverseCredit
                            businessAdverseCreditExplanation
                            businessDirectorsAdverseCredit
                            businessDirectorsAdverseCreditExplanation
                            shareholdersAreHomeOwners
                            propertyAvailableForAdditionalSecurity
                            planningFor
                            developmentLength
                            refurbishmentLength
                            outstandingLoan
                            dayOneLoanAmount
                            unitsPreSold
                            worksExplanation
                            totalDevelopmentCosts
                            buildCostsBorrowing
                            expectedGrossDevelopmentValue
                            previousDevelopmentExperience
                            previousRefurbishmentExperience
                            teamDevelopmentExperience
                            epcRating
                            loanType
                            fundingType
                            businessSecuredFinance
                            loanUsedInUkEu
                            planningInPlace
                            endValue
                            percentageResidential
                            percentageCommercial
                            lender
                            firstChargeAmount
                            currentValue
                            properties
                            portfolioValue
                            portfolioAlreadyOwned
                            loanTerm
                            loanNeededBy
                            preApproveMatches
                            loanAmountRefurbishment
                            percentageResidentialSquareFeet
                            percentageResidentialValue
                            percentageCommercialValue
                            residentialUnitCount
                            residentialUnitValue
                            businessOccupyEntireProperty
                            commercialUnitRentBreakdown
                            commercialTenantAmount
                            ownsOtherCommercialProperty
                            ownOtherProperties
                            occupyProperty
                            ownsOtherInvestmentProperties
                            familyHasOccupied
                            secondCharge
                            occupyingGreaterThan40Percent
                            location
                            locationOther
                            address
                            postcode
                            propertyOwnership
                            refurbishmentCost
                            invoiceFinanceType
                            leaseHirePurchase
                            mainApplicantCreditDifficulties
                            mainApplicantCreditDifficultiesExplanation
                            mainApplicantCitizenshipStatus
                            mainApplicantCountryOfResidence
                            mainApplicantCountryOfResidenceOther
                            mainApplicantNationality
                            mainApplicantNationalityOther
                            mainApplicantOccupation
                            mainApplicantGrossIncome
                            mainApplicantExitStrategy
                            agreeToTerms
                        }
                        submittedBy{
                          roles{
                            name
                            label
                          }
                        }
                      }
                      id
                      submittedBy{
                        title
                        given
                        family
                      }
                      loanEnquiryApplicants{
                        applicant{
                          given
                          family
                        }
                      }
                      storedData{
                        meta{
                          key
                        }
                        value
                      }
                      createdAt
                      updatedAt
                      alertActive
                    }
                  }
                  application {
                    intermediaryLeads
                    borrowerLeads
                    totalLeadsValue
                    numberOfLeads
                    leads {
                      loanEnquiry{
                        id
                        isNewForm
                        loanEnquiryData {
                            loanToValue
                            loanToCost
                            loanToGrossDevelopmentValue
                            dayOneLoanAmountPercentage
                            additionalInformation
                            rentalIncomeResidential
                            hmoBedroom
                            hmoRoomsSelfContained
                            hmoHasLicense
                            hmoNeedsLicense
                            buyToLetExperience
                            rentalIncomeCommercial
                            rentalIncomePortfolio
                            rentalIncome
                            commercialUnitsRentedOut
                            commercialUnitOwnerOccupied
                            commercialUnitAmount
                            commercialType
                            commercialTypeOther
                            siteDescription
                            siteIntention
                            refinance
                            businessSeasonal
                            businessType
                            businessName
                            businessWebsite
                            businessTradingLength
                            businessSector
                            businessFacilityNeededBy
                            businessFacilityTerm
                            businessCurrentPaymentTerms
                            businessOutstandingInvoices
                            businessPdqIncome
                            propertyType
                            loanAmount
                            loanPurpose
                            turnOver
                            businessProfitable
                            businessOverdraftFacility
                            businessOutstandingDebt
                            businessAdverseCredit
                            businessAdverseCreditExplanation
                            businessDirectorsAdverseCredit
                            businessDirectorsAdverseCreditExplanation
                            shareholdersAreHomeOwners
                            propertyAvailableForAdditionalSecurity
                            planningFor
                            developmentLength
                            refurbishmentLength
                            outstandingLoan
                            dayOneLoanAmount
                            unitsPreSold
                            worksExplanation
                            totalDevelopmentCosts
                            buildCostsBorrowing
                            expectedGrossDevelopmentValue
                            previousDevelopmentExperience
                            previousRefurbishmentExperience
                            teamDevelopmentExperience
                            epcRating
                            loanType
                            fundingType
                            businessSecuredFinance
                            loanUsedInUkEu
                            planningInPlace
                            endValue
                            percentageResidential
                            percentageCommercial
                            lender
                            firstChargeAmount
                            currentValue
                            properties
                            portfolioValue
                            portfolioAlreadyOwned
                            loanTerm
                            loanNeededBy
                            preApproveMatches
                            loanAmountRefurbishment
                            percentageResidentialSquareFeet
                            percentageResidentialValue
                            percentageCommercialValue
                            residentialUnitCount
                            residentialUnitValue
                            businessOccupyEntireProperty
                            commercialUnitRentBreakdown
                            commercialTenantAmount
                            ownsOtherCommercialProperty
                            ownOtherProperties
                            occupyProperty
                            ownsOtherInvestmentProperties
                            familyHasOccupied
                            secondCharge
                            occupyingGreaterThan40Percent
                            location
                            locationOther
                            address
                            postcode
                            propertyOwnership
                            refurbishmentCost
                            invoiceFinanceType
                            leaseHirePurchase
                            mainApplicantCreditDifficulties
                            mainApplicantCreditDifficultiesExplanation
                            mainApplicantCitizenshipStatus
                            mainApplicantCountryOfResidence
                            mainApplicantCountryOfResidenceOther
                            mainApplicantNationality
                            mainApplicantNationalityOther
                            mainApplicantOccupation
                            mainApplicantGrossIncome
                            mainApplicantExitStrategy
                            agreeToTerms
                        }
                        submittedBy{
                          roles{
                            name
                            label
                          }
                        }
                      }
                      id
                      submittedBy{
                        title
                        given
                        family
                      }
                      loanEnquiryApplicants{
                        applicant{
                          given
                          family
                        }
                      }
                      storedData{
                        meta{
                          key
                        }
                        value
                      }
                      createdAt
                      updatedAt
                      alertActive
                    }
                  }
                  leadStatistics {
                    oneMonth {
                      averageValue
                      totalLeads
                      totalValue
                    }
                    twoMonths {
                      averageValue
                      totalLeads
                      totalValue
                    }
                    sixMonths {
                      averageValue
                      totalLeads
                      totalValue
                    }
                    allTime {
                      averageValue
                      totalLeads
                      totalValue
                    }
                  }
                  completedStatistics {
                    oneMonth {
                        averageValue
                        totalLeads
                        totalValue
                    }
                    twoMonths {
                        averageValue
                        totalLeads
                        totalValue
                    } 
                    sixMonths { 
                        averageValue
                        totalLeads
                        totalValue
                    } 
                    allTime {
                        averageValue
                        totalLeads
                        totalValue
                    } 
                  }
                }
              }
            }
        `;

        return apolloFetch({
            query: query,
        });
    }

    viewAllLeadsButton = () => {
        return (
            <a ref={''} href={ROUTE_SLAM_LEADS_ACTIVE} className={'view-all-link'}>
                VIEW ALL
            </a>
        );
    };

    handleLeadsStatsTimeRangeChange = (event) => {
        this.setState({
            leadsStatsTimeRangeSelected: event.target.value,
        });
    };

    handleCompletionsStatsTimeRangeChange = (event) => {
        this.setState({
            completionsStatsTimeRangeSelected: event.target.value,
        });
    };

    checkLeadsWithAlerts = (leads) => {
        const data = leads.filter((lead) => lead.alertActive);
        return data.length > 0;
    };

    render() {
        const { session, redirect } = this.props;
        const {
            expanded,
            newLeads,
            completedLeads,
            leadsInProgress,
            leadsInApplication,
            leadsStatsTimeRangeSelected,
            completionsStatsTimeRangeSelected,
            timeRanges,
            leadStatistics,
            completedStatistics,
        } = this.state;

        const currentSession = session.getRelevantSession();

        const handleChange = (panel) => (event, isExpanded) => {
            this.setState({ expanded: isExpanded ? panel : false });
        };

        return (
            <div className={'dashboard-slam'}>
                <RebrandRedirect />
                <div>
                    <Header
                        user={{
                            name: currentSession.getName(),
                            email: currentSession.getEmail(),
                            type: currentSession.getMostSeniorRole().label,
                        }}
                    />
                </div>
                <Grid container className={'main-container'}>
                    <Grid container style={{ marginTop: 20 }}>
                        <Grid item={true} lg={10} md={10} xs={12} sm={12}>
                            <div className={'main-le'}>
                                <Accordion
                                    elevation={1}
                                    expanded={expanded === 'new-leads'}
                                    onChange={handleChange('new-leads')}
                                    style={{ marginBottom: 20 }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-new-leads-content"
                                        id="panel-new-leads-header"
                                        className={'summary'}
                                        style={{ borderTopLeftRadius: 4 }}
                                    >
                                        <Grid
                                            item={true}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className={'header-container'}
                                            style={{ display: 'flex' }}
                                        >
                                            <Grid lg={2} md={2} sm={6} xs={6} className={'new-leads'} item={true}>
                                                <div>New Leads</div>
                                                <div className={'value'}>{newLeads.numberOfLeads}</div>
                                            </Grid>
                                            <Hidden smDown>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className={'intermediary-leads'}
                                                    item={true}
                                                >
                                                    <div>Intermediary Leads</div>
                                                    <div className={'value'}>{newLeads.intermediaryLeads}</div>
                                                </Grid>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className={'borrower-leads'}
                                                    item={true}
                                                >
                                                    <div>Borrower Leads</div>
                                                    <div className={'value'}>{newLeads.borrowerLeads}</div>
                                                </Grid>
                                            </Hidden>
                                            <Grid lg={6} md={6} sm={6} xs={6} className={'total-value'} item={true}>
                                                <div
                                                    style={{
                                                        padding: '20px 0 0 20px',
                                                    }}
                                                >
                                                    <div>Total New Lead Value</div>
                                                    <div className={'value'}>
                                                        {newLeads && formatterInPounds(newLeads.totalLeadsValue)}
                                                    </div>
                                                </div>
                                                {this.checkLeadsWithAlerts(newLeads.leads) && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>
                                                            <Badge label={'update'} />
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails className={'content'}>
                                        <Grid
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className={'content-container'}
                                            item={true}
                                        >
                                            <Hidden smDown>
                                                <Grid lg={2} md={2} sm={6} xs={6} className={'new-leads'} item={true} />
                                            </Hidden>
                                            <Grid
                                                lg={10}
                                                md={10}
                                                sm={10}
                                                xs={10}
                                                className={'content-leads'}
                                                item={true}
                                            >
                                                <Grid container lg={12} md={12} item={true}>
                                                    {newLeads && (
                                                        <Grid container>
                                                            {newLeads.leads.map((le, index) => (
                                                                <SLAMLECard le={le} key={index} type={'new'} dispatch />
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                    {newLeads.leads.length > 0 && (
                                        <>
                                            <Divider />
                                            <AccordionActions className={'footer-container'}>
                                                {this.viewAllLeadsButton()}
                                            </AccordionActions>
                                        </>
                                    )}
                                </Accordion>
                                <Accordion
                                    elevation={1}
                                    expanded={expanded === 'leads-in-progress'}
                                    onChange={handleChange('leads-in-progress')}
                                    style={{ marginBottom: 20 }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-leads-in-progress-content"
                                        id="panel-leads-in-progress-header"
                                        className={'summary'}
                                    >
                                        <Grid
                                            item={true}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className={'header-container'}
                                            style={{ display: 'flex' }}
                                        >
                                            <Grid
                                                lg={2}
                                                md={2}
                                                sm={6}
                                                xs={6}
                                                className={'leads-in-progress'}
                                                item={true}
                                            >
                                                <div>Leads In Progress</div>
                                                <div className={'value'}>{leadsInProgress.numberOfLeads}</div>
                                            </Grid>
                                            <Hidden smDown>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className={'intermediary-leads'}
                                                    item={true}
                                                >
                                                    <div>Intermediary Leads</div>
                                                    <div className={'value'}>{leadsInProgress.intermediaryLeads}</div>
                                                </Grid>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className={'borrower-leads'}
                                                    item={true}
                                                >
                                                    <div>Borrower Leads</div>
                                                    <div className={'value'}>{leadsInProgress.borrowerLeads}</div>
                                                </Grid>
                                            </Hidden>
                                            <Grid lg={6} md={6} sm={6} xs={6} className={'total-value'} item={true}>
                                                <div
                                                    style={{
                                                        padding: '20px 0 0 20px',
                                                    }}
                                                >
                                                    <div>Total In Progress Value</div>
                                                    <div className={'value'}>
                                                        {leadsInProgress &&
                                                            formatterInPounds(leadsInProgress.totalLeadsValue)}
                                                    </div>
                                                </div>
                                                {this.checkLeadsWithAlerts(leadsInProgress.leads) && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>
                                                            <Badge label={'update'} />
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails className={'content'}>
                                        <Grid
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className={'content-container'}
                                            item={true}
                                        >
                                            <Hidden smDown>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className={'leads-in-progress'}
                                                    item={true}
                                                />
                                            </Hidden>
                                            <Grid
                                                lg={10}
                                                md={10}
                                                sm={10}
                                                xs={10}
                                                className={'content-leads'}
                                                item={true}
                                            >
                                                <Grid container lg={12} md={12} item={true}>
                                                    {leadsInProgress && (
                                                        <Grid container>
                                                            {leadsInProgress.leads.map((le, index) => (
                                                                <SLAMLECard le={le} key={index} dispatch />
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                    {leadsInProgress.leads.length > 0 && (
                                        <>
                                            <Divider />
                                            <AccordionActions className={'footer-container'}>
                                                {this.viewAllLeadsButton()}
                                            </AccordionActions>
                                        </>
                                    )}
                                </Accordion>
                                <Accordion
                                    elevation={1}
                                    expanded={expanded === 'leads-in-application'}
                                    onChange={handleChange('leads-in-application')}
                                    style={{ marginBottom: 20 }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-leads-in-application-content"
                                        id="panel-leads-in-application-header"
                                        className={'summary'}
                                    >
                                        <Grid
                                            item={true}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className={'header-container'}
                                            style={{ display: 'flex' }}
                                        >
                                            <Grid
                                                lg={2}
                                                md={2}
                                                sm={6}
                                                xs={6}
                                                className={'leads-in-application'}
                                                item={true}
                                            >
                                                <div>Leads In Application</div>
                                                <div className={'value'}>{leadsInApplication.numberOfLeads}</div>
                                            </Grid>
                                            <Hidden smDown>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className={'intermediary-leads'}
                                                    item={true}
                                                >
                                                    <div>Intermediary Leads</div>
                                                    <div className={'value'}>
                                                        {leadsInApplication.intermediaryLeads}
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className={'borrower-leads'}
                                                    item={true}
                                                >
                                                    <div>Borrower Leads</div>
                                                    <div className={'value'}>{leadsInApplication.borrowerLeads}</div>
                                                </Grid>
                                            </Hidden>
                                            <Grid lg={6} md={6} sm={6} xs={6} className={'total-value'} item={true}>
                                                <div
                                                    style={{
                                                        padding: '20px 0 0 20px',
                                                    }}
                                                >
                                                    <div>Total Application Value</div>
                                                    <div className={'value'}>
                                                        {leadsInApplication &&
                                                            formatterInPounds(leadsInApplication.totalLeadsValue)}
                                                    </div>
                                                </div>
                                                {this.checkLeadsWithAlerts(leadsInApplication.leads) && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>
                                                            <Badge label={'update'} />
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails className={'content'}>
                                        <Grid
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className={'content-container'}
                                            item={true}
                                        >
                                            <Hidden smDown>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className={'leads-in-application'}
                                                    item={true}
                                                />
                                            </Hidden>
                                            <Grid
                                                lg={10}
                                                md={10}
                                                sm={10}
                                                xs={10}
                                                className={'content-leads'}
                                                item={true}
                                            >
                                                <Grid container lg={12} md={12} item={true}>
                                                    {leadsInApplication && (
                                                        <Grid container>
                                                            {leadsInApplication.leads.map((le, index) => (
                                                                <SLAMLECard le={le} dispatch />
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                    {leadsInApplication.leads.length > 0 && (
                                        <>
                                            <Divider />
                                            <AccordionActions className={'footer-container'}>
                                                {this.viewAllLeadsButton()}
                                            </AccordionActions>
                                        </>
                                    )}
                                </Accordion>
                                <Accordion
                                    elevation={1}
                                    expanded={expanded === 'completed-leads'}
                                    onChange={handleChange('completed-leads')}
                                    style={{ marginBottom: 20 }}
                                >
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel-completed-leads-content"
                                        id="panel-completed-leads-header"
                                        className={'summary'}
                                        style={{ borderTopLeftRadius: 4 }}
                                    >
                                        <Grid
                                            item={true}
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className={'header-container'}
                                            style={{ display: 'flex' }}
                                        >
                                            <Grid lg={2} md={2} sm={6} xs={6} className={'completed-leads'} item={true}>
                                                <div>Completed Leads</div>
                                                <div className={'value'}>{completedLeads.numberOfLeads}</div>
                                            </Grid>
                                            <Hidden smDown>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className={'intermediary-leads'}
                                                    item={true}
                                                >
                                                    <div>Intermediary Leads</div>
                                                    <div className={'value'}>{completedLeads.intermediaryLeads}</div>
                                                </Grid>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={2}
                                                    xs={2}
                                                    className={'borrower-leads'}
                                                    item={true}
                                                >
                                                    <div>Borrower Leads</div>
                                                    <div className={'value'}>{completedLeads.borrowerLeads}</div>
                                                </Grid>
                                            </Hidden>
                                            <Grid lg={6} md={6} sm={6} xs={6} className={'total-value'} item={true}>
                                                <div
                                                    style={{
                                                        padding: '20px 0 0 20px',
                                                    }}
                                                >
                                                    <div>Total Application Value</div>
                                                    <div className={'value'}>
                                                        {completedLeads &&
                                                            formatterInPounds(completedLeads.totalLeadsValue)}
                                                    </div>
                                                </div>
                                                {this.checkLeadsWithAlerts(completedLeads.leads) && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div>
                                                            <Badge label={'Update'} />
                                                        </div>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails className={'content'}>
                                        <Grid
                                            lg={12}
                                            md={12}
                                            sm={12}
                                            xs={12}
                                            className={'content-container'}
                                            item={true}
                                        >
                                            <Hidden smDown>
                                                <Grid
                                                    lg={2}
                                                    md={2}
                                                    sm={6}
                                                    xs={6}
                                                    className={'completed-leads'}
                                                    item={true}
                                                />
                                            </Hidden>
                                            <Grid
                                                lg={10}
                                                md={10}
                                                sm={10}
                                                xs={10}
                                                className={'content-leads'}
                                                item={true}
                                            >
                                                <Grid container lg={12} md={12} item={true}>
                                                    {completedLeads && (
                                                        <Grid container>
                                                            {completedLeads.leads.map((le, index) => (
                                                                <SLAMLECard le={le} type={'new'} dispatch />
                                                            ))}
                                                        </Grid>
                                                    )}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionDetails>
                                    {completedLeads.leads.length > 0 && (
                                        <>
                                            <Divider />
                                            <AccordionActions className={'footer-container'}>
                                                {this.viewAllLeadsButton()}
                                            </AccordionActions>
                                        </>
                                    )}
                                </Accordion>
                            </div>
                        </Grid>
                        <Grid lg={2} md={2} xs={12} sm={12} className={'right-column'} item={true}>
                            <Paper className={'lead-stats'}>
                                <div className={'header'}>
                                    <div className={'icon-container'}>
                                        <AssessmentIcon className={'icon'} />
                                    </div>{' '}
                                    Lead Statistics
                                </div>
                                <div>
                                    <FormControl variant="outlined" className={'stats-period-container'}>
                                        <Select
                                            native
                                            value={leadsStatsTimeRangeSelected}
                                            onChange={this.handleLeadsStatsTimeRangeChange}
                                        >
                                            {timeRanges.map((timeRange, index) => {
                                                return (
                                                    <option value={timeRange} key={index}>
                                                        {timeRangeEnum.getType(timeRange)}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className={'avg-lead-value'}>
                                    <div className={'label'}>Avg Lead Value</div>
                                    <div className={'value'}>
                                        {leadStatistics[timeRangeEnum.getFieldMapping(leadsStatsTimeRangeSelected)] &&
                                            formatterInPounds(
                                                leadStatistics[
                                                    timeRangeEnum.getFieldMapping(leadsStatsTimeRangeSelected)
                                                ].averageValue
                                            )}
                                    </div>
                                </div>
                                {/* Just hiding the below until it gets fixed. Or not. */}
                                {/*<div className={'total-leads'}>*/}
                                {/*    <div className={'label'}>Total Leads</div>*/}
                                {/*    <div className={'value'}>*/}
                                {/*        {leadStatistics[timeRangeEnum.getFieldMapping(leadsStatsTimeRangeSelected)]*/}
                                {/*            ? leadStatistics[timeRangeEnum.getFieldMapping(leadsStatsTimeRangeSelected)]*/}
                                {/*                  .totalLeads*/}
                                {/*            : 0}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className={'total-value'}>*/}
                                {/*    <div className={'label'}>Total Value</div>*/}
                                {/*    <div className={'value'}>*/}
                                {/*        {leadStatistics[timeRangeEnum.getFieldMapping(leadsStatsTimeRangeSelected)] &&*/}
                                {/*            formatterInPounds(*/}
                                {/*                leadStatistics[*/}
                                {/*                    timeRangeEnum.getFieldMapping(leadsStatsTimeRangeSelected)*/}
                                {/*                ].totalValue*/}
                                {/*            )}*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </Paper>
                            <Paper className={'completions-stats'}>
                                <div className={'header'}>
                                    <div className={'icon-container'}>
                                        <AssessmentIcon className={'icon'} />
                                    </div>{' '}
                                    Completions
                                </div>
                                <div>
                                    <FormControl variant="outlined" className={'stats-period-container'}>
                                        <Select
                                            native
                                            value={completionsStatsTimeRangeSelected}
                                            onChange={this.handleCompletionsStatsTimeRangeChange}
                                        >
                                            {timeRanges.map((timeRange, index) => {
                                                return (
                                                    <option value={timeRange} key={index}>
                                                        {timeRangeEnum.getType(timeRange)}
                                                    </option>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className={'avg-lead-value'}>
                                    <div className={'label'}>Avg Completion Value</div>
                                    <div className={'value'}>
                                        {completedStatistics[
                                            timeRangeEnum.getFieldMapping(completionsStatsTimeRangeSelected)
                                        ] &&
                                            formatterInPounds(
                                                completedStatistics[
                                                    timeRangeEnum.getFieldMapping(completionsStatsTimeRangeSelected)
                                                ].averageValue
                                            )}
                                    </div>
                                </div>
                                <div className={'total-completions'}>
                                    <div className={'label'}>Total Completions</div>
                                    <div className={'value'}>
                                        {completedStatistics[
                                            timeRangeEnum.getFieldMapping(completionsStatsTimeRangeSelected)
                                        ] &&
                                            completedStatistics[
                                                timeRangeEnum.getFieldMapping(completionsStatsTimeRangeSelected)
                                            ].totalLeads}
                                    </div>
                                </div>
                                <div className={'total-value'}>
                                    <div className={'label'}>Total Value</div>
                                    <div className={'value'}>
                                        {completedStatistics[
                                            timeRangeEnum.getFieldMapping(completionsStatsTimeRangeSelected)
                                        ] &&
                                            formatterInPounds(
                                                completedStatistics[
                                                    timeRangeEnum.getFieldMapping(completionsStatsTimeRangeSelected)
                                                ].totalValue
                                            )}
                                    </div>
                                </div>
                            </Paper>
                            <Paper className={'toolbox'}>
                                <div className={'header'}>
                                    <div className={'icon-container'}>
                                        <BuildIcon className={'icon'} />
                                    </div>
                                    Your Toolbox
                                </div>
                                <div
                                    className={'item'}
                                    onClick={() => redirect(routeManager.route(ROUTE_SLAM_DOCUMENTS_VIEW))}
                                >
                                    View <strong>My Document</strong>
                                </div>
                                <div
                                    className={'item'}
                                    onClick={() => redirect(routeManager.route(ROUTE_SLAM_BUSINESS_STREAMS))}
                                >
                                    View <strong>My Business Streams</strong>
                                </div>
                                <div
                                    className={'item'}
                                    onClick={() => redirect(routeManager.route(ROUTE_SLAM_BUSINESS_STREAM_CREATE))}
                                >
                                    Add <strong>New Business Stream</strong>
                                </div>
                                <div className={'item'}>
                                    <a
                                        href={ROUTE_PITCH4_KNOWLEDGE_BASE_NEW_TEAM_MEMBER_LENDER}
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                    >
                                        Add <strong>New Team Member</strong>
                                    </a>
                                </div>
                                <div className={'item'} onClick={() => window.HubSpotConversations.widget.open()}>
                                    Speak to <strong>Admin</strong>
                                </div>
                            </Paper>
                            <Paper className={'resources'}>
                                <div className={'header'}>
                                    <div className={'icon-container'}>
                                        <HelpIcon className={'icon'} />
                                    </div>
                                    Resources
                                </div>
                                <div className={'item'}>
                                    <a href={ROUTE_PITCH4_NEW_FEATURES_2021} rel={'noreferrer'} target={'_blank'}>
                                        <strong>New</strong> {BRAND_NAME} Features
                                    </a>
                                </div>
                                <div className={'item'}>
                                    <a href={ROUTE_PITCH4_RESOURCES_FOR_LENDERS} rel={'noreferrer'} target={'_blank'}>
                                        <strong>Resources</strong> for Lenders
                                    </a>
                                </div>
                                <div className={'item'}>
                                    <a href={ROUTE_PITCH4_KNOWLEDGE_BASE} rel={'noreferrer'} target={'_blank'}>
                                        <strong>Learn how</strong> to use {BRAND_NAME}
                                    </a>
                                </div>
                                <div className={'item'}>
                                    <a href={ROUTE_PITCH4_BLOG} rel={'noreferrer'} target={'_blank'}>
                                        Read the <strong>latest from our Blog</strong>
                                    </a>
                                </div>
                                <div className={'item'}>
                                    <a
                                        href={ROUTE_PITCH4_HOW_TO_SETUP_BUSINESS_STREAMS}
                                        rel={'noreferrer'}
                                        target={'_blank'}
                                    >
                                        Learn how to <strong>get the most from Business Streams</strong>
                                    </a>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => dispatch(push(url)),
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(withSession, connect(mapStateToProps, mapDispatchToProps))(DashboardContainer);
