import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import styled from 'styled-components';
import logo from '../../landing/assets/images/pitch4-d.svg';

import { Body1, PrimaryColour, SecondaryColour } from 'pitch4_layout/components/text';
import { Centered, Container, RowItem } from 'pitch4_layout/components/div';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { BRAND_NAME } from '../../../bootstrap/constants/constants';

const ResetPasswordWidget = styled(Container)`
    padding-top: 10%;
`;

const Logo = styled.img`
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 15px;
    padding-top: 10px;
`;

const StyledHeadingRow = styled(RowItem)`
    padding-top: 1em;
    padding-bottom: 2em;
    text-align: center;
`;

const StyledNoteRow = styled(RowItem)`
    padding-top: 3em;
    text-align: center;
`;

class LinkExpiredContainer extends React.Component {
    render() {
        return (
            <ResetPasswordWidget justify="center">
                <RowItem>
                    <Centered>
                        <Logo src={logo} alt={`${BRAND_NAME} &copy; Borrowing Made Simple`} />
                    </Centered>
                </RowItem>
                <StyledHeadingRow>
                    <h6>
                        <PrimaryColour>Sorry, this link has expired.</PrimaryColour>
                    </h6>
                </StyledHeadingRow>
                <StyledNoteRow>
                    <Body1>
                        <SecondaryColour>
                            This reset link has expired. If you want to try again, please ask an admin to send out
                            another link.
                        </SecondaryColour>
                    </Body1>
                </StyledNoteRow>
            </ResetPasswordWidget>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (uri) => () => dispatch(push(uri)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LinkExpiredContainer);
