import createP4ApolloFetch from '../../../bootstrap/middlewares';
import { ANON_URL } from '../../../bootstrap/constants/constants';
import { BORROWER_REGISTRATION_RECAPTCHA_ACTION } from '../containers/BorrowerRegistrationContainer';

const apolloFetch = createP4ApolloFetch({ uri: ANON_URL });

const mutation = `
    mutation syncHubspotPPC($input: anon_hubspotPPC!) {
      syncHubspotPPC(input: $input) {
        success
      }
    }

`;

export const registerHubspot = (
    firstName,
    lastName,
    email,
    phoneNumber,
    loanAmount,
    loanLength,
    loanType,
    customerSource,
    agreedToMarketing,
    fromPage
) => {
    return apolloFetch({
        query: mutation,
        variables: {
            input: {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phoneNumber: phoneNumber,
                loanAmount: loanAmount,
                loanLength: loanLength,
                loanType: loanType,
                customerSource: customerSource,
                agreedToMarketing: agreedToMarketing,
                page: fromPage,
            },
            recaptcha: {
                token: email,
                action: BORROWER_REGISTRATION_RECAPTCHA_ACTION,
            },
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};
