import React, { useEffect, useState } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, CircularProgress, Grid, Hidden } from '@material-ui/core';
import { Badge } from '../../../../../../pitch4_layout/components/badge/Badge';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import { formatterInPounds } from '../../../../../../pitchblack_react_utils/currency/Formatter';
import AdminLECard from '../LECard';
import { getDashboardLEsWithUpdates } from '../../api/get_dashboard_list_les_with_updates';
import { initialLELoadCount, loadMoreLECount } from './config';

export default function EnquiriesWithUpdates(props) {
    const { expanded, handleChange, dispatch, setLEsWithUpdatesCount } = props;
    const [requestCursor, setRequestCursor] = useState(null);
    const [nextPage, setNextPage] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [data, setData] = useState({
        intermediaryCount: 0,
        borrowerCount: 0,
        totalCount: 0,
        totalValue: 0,
        leads: [],
    });

    // Component did mount
    useEffect(() => {
        getData();
    }, []);

    const getData = () =>
        getDashboardLEsWithUpdates(initialLELoadCount, requestCursor).then((resp) => {
            setLEsWithUpdatesCount(resp.loanEnquiriesWithStatistics.totalCount);
            setData({
                ...data,
                ...{
                    intermediaryCount: resp.loanEnquiriesWithStatistics.intermediaryCount,
                    borrowerCount: resp.loanEnquiriesWithStatistics.borrowerCount,
                    totalCount: resp.loanEnquiriesWithStatistics.totalCount,
                    totalValue: resp.loanEnquiriesWithStatistics.totalValue,
                    leads: resp.loanEnquiriesWithStatistics.edges,
                },
            });
            setRequestCursor(resp.loanEnquiriesWithStatistics.pageInfo.endCursor);
            setNextPage(resp.loanEnquiriesWithStatistics.pageInfo.hasNextPage);
        });

    const checkLeadsWithAlerts = (leads) => {
        const data = leads.filter((lead) => lead.node.alertActive);
        return data.length > 0;
    };

    const loadMore = () => {
        setLoadingMore(true);
        getDashboardLEsWithUpdates(loadMoreLECount, requestCursor).then((resp) => {
            setRequestCursor(resp.loanEnquiriesWithStatistics.pageInfo.endCursor);
            setNextPage(resp.loanEnquiriesWithStatistics.pageInfo.hasNextPage);
            setData({
                ...data,
                ...{ leads: [...data.leads, ...resp.loanEnquiriesWithStatistics.edges] },
            });
            setLoadingMore(false);
        });
    };

    const loadLess = () => {
        setRequestCursor(null);
        setNextPage(true);
        setLoadingMore(true);
        getDashboardLEsWithUpdates(initialLELoadCount, null).then((resp) => {
            setLEsWithUpdatesCount(resp.loanEnquiriesWithStatistics.totalCount);
            setData({
                ...data,
                ...{
                    intermediaryCount: resp.loanEnquiriesWithStatistics.intermediaryCount,
                    borrowerCount: resp.loanEnquiriesWithStatistics.borrowerCount,
                    totalCount: resp.loanEnquiriesWithStatistics.totalCount,
                    totalValue: resp.loanEnquiriesWithStatistics.totalValue,
                    leads: resp.loanEnquiriesWithStatistics.edges,
                },
            });
            setRequestCursor(resp.loanEnquiriesWithStatistics.pageInfo.endCursor);
            setNextPage(resp.loanEnquiriesWithStatistics.pageInfo.hasNextPage);
            setLoadingMore(false);
        });
    };

    return (
        <Accordion
            id={'enquiries-with-updates'}
            className={'enquiries-with-updates'}
            elevation={1}
            expanded={expanded === 'enquiries-with-updates'}
            onChange={handleChange('enquiries-with-updates')}
            style={{ marginBottom: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-enquiries-with-updates-content"
                id="panel-enquiries-with-updates-header"
                className={'summary'}
                style={{ borderTopLeftRadius: 20 }}
            >
                <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={'header-container'}
                    item={true}
                    style={{ display: 'flex' }}
                >
                    <Grid lg={2} md={2} sm={6} xs={12} className={'enquiries-with-updates'} item={true}>
                        <div>Enquiries With Updates</div>
                        <div className={'value'}>{data.totalCount}</div>
                    </Grid>
                    <Hidden smDown>
                        <Grid lg={2} md={2} sm={2} xs={2} className={'intermediary-leads'} item={true}>
                            <div>Intermediary Leads</div>
                            <div className={'value'}>{data.intermediaryCount}</div>
                        </Grid>
                        <Grid lg={2} md={2} sm={2} xs={2} className={'borrower-leads'} item={true}>
                            <div>Borrower Leads</div>
                            <div className={'value'}>{data.borrowerCount}</div>
                        </Grid>
                    </Hidden>
                    <Hidden xsDown>
                        <Grid lg={6} md={6} sm={6} xs={6} className={'total-value'} item={true}>
                            <div
                                style={{
                                    padding: '20px 0 0 20px',
                                }}
                            >
                                <div>Total Value</div>
                                <div className={'value'}>{formatterInPounds(data.totalValue, '')}</div>
                            </div>
                            {checkLeadsWithAlerts(data.leads) && (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div>
                                        <Badge label={'update'} />
                                    </div>
                                </div>
                            )}
                        </Grid>
                    </Hidden>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={'content'}>
                <Grid lg={12} md={12} sm={12} xs={12} className={'content-container'} item={true}>
                    <Hidden smDown>
                        <Grid lg={2} md={2} sm={6} xs={6} className={'enquiries-with-updates'} item={true} />
                    </Hidden>
                    <Grid lg={10} md={10} sm={10} xs={10} className={'content-leads'} item={true}>
                        <Grid container lg={12} md={12} item={true}>
                            <Grid container>
                                {data.leads.map(({ node }, index) => (
                                    <AdminLECard key={index} le={node} dispatch={dispatch} />
                                ))}
                                {data.totalCount > initialLELoadCount ? (
                                    true === nextPage ? (
                                        <Grid container style={{ margin: 10 }}>
                                            <Button
                                                className={'btn-load-more-less'}
                                                variant={'contained'}
                                                color={'primary'}
                                                onClick={loadMore}
                                            >
                                                {true === loadingMore ? (
                                                    <CircularProgress
                                                        size={18}
                                                        style={{ color: '#ffffff', marginLeft: 5 }}
                                                    />
                                                ) : (
                                                    'Load more'
                                                )}
                                            </Button>
                                        </Grid>
                                    ) : (
                                        <Grid container style={{ margin: 10 }}>
                                            <Button
                                                className={'btn-load-more-less'}
                                                variant={'contained'}
                                                color={'primary'}
                                                onClick={loadLess}
                                            >
                                                Load less
                                            </Button>
                                        </Grid>
                                    )
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
