import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import withSession from 'pitchblack_react_utils/session/withSession';
import { sagaActionRedirectToDashboard } from '../../authentication/sagas/dashboard';

class LandingContainer extends Component {
    componentDidMount() {
        let { dashboardRedirect } = this.props;
        dashboardRedirect();
    }

    render() {
        return <React.Fragment></React.Fragment>;
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dashboardRedirect: () => dispatch(sagaActionRedirectToDashboard()),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(LandingContainer);
