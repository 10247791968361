import { Grid } from '@material-ui/core';
import Card from './BasicStatsCard';
import UnassignedEnquiriesIcon from '../icons/UnassignedEnquiriesIcon';
import AssignedEnquiriesIcon from '../icons/AssignedEnquiriesIcon';
import FavouriteEnquiriesIcon from '../icons/FavouriteEnquiriesIcon';
import EnquiriesWithUpdatesIcon from '../icons/EnquiriesWithUpdatesIcon';
import NewBorrowerAccountsIcon from '../icons/NewAccountsIcon';
import React from 'react';

export default function (props) {
    const {
        unassignedEnquiries,
        assignedEnquiries,
        favouritedEnquiries,
        enquiriesWithUpdates,
        newBorrowerIntAccounts,
        setListExpanded,
    } = props;

    const stats = [
        {
            icon: <UnassignedEnquiriesIcon />,
            label: (
                <span>
                    <strong>Unassigned</strong>
                    <br />
                    Enquiries
                </span>
            ),
            count: unassignedEnquiries,
            type: 'unassigned-enquiries',
        },
        {
            icon: <AssignedEnquiriesIcon />,
            label: (
                <span>
                    My <strong>Assigned</strong>
                    <br />
                    Enquiries
                </span>
            ),
            count: assignedEnquiries,
            type: 'my-assigned-enquiries',
        },
        {
            icon: <FavouriteEnquiriesIcon />,
            label: (
                <span>
                    My <strong>Favourited</strong>
                    <br />
                    Enquiries
                </span>
            ),
            count: favouritedEnquiries,
            type: 'my-favourited-enquiries',
        },
        {
            icon: <EnquiriesWithUpdatesIcon />,
            label: (
                <span>
                    Enquiries with
                    <br />
                    <strong>Updates</strong>
                </span>
            ),
            count: enquiriesWithUpdates,
            type: 'enquiries-with-updates',
        },
        {
            icon: <NewBorrowerAccountsIcon />,
            label: (
                <span>
                    New <strong>Accounts</strong>
                    <br />
                    (Borrowers and Intermediaries)
                </span>
            ),
            count: newBorrowerIntAccounts,
            type: 'new-accounts',
        },
    ];
    return (
        <Grid container className={'basic-stats-container'} spacing={3}>
            {stats.map((stat, index) => (
                <Grid item xs={12} sm md lg xl key={index}>
                    <Card
                        setListExpanded={setListExpanded}
                        type={stat.type}
                        count={stat.count}
                        icon={stat.icon}
                        label={stat.label}
                    />
                </Grid>
            ))}
        </Grid>
    );
}
