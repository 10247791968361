import _ from 'lodash';

import { EQ } from 'pitch4_tabular/enum/OperationEnum';
import { isEmpty } from 'pitch4_validation';

export const mapFactsForSection = (sectionData) => {
    let mapped = {};

    Object.keys(sectionData).forEach((factName) => {
        let operand = sectionData[factName].operand;
        let operandStart = sectionData[factName]['operand-start'];
        let operandEnd = sectionData[factName]['operand-end'];
        let operation = sectionData[factName].operation;
        let not = sectionData[factName].not;

        if (isEmpty(operand) && (isEmpty(operandStart) || isEmpty(operandEnd))) {
            return;
        }

        // Don't assign directly to sectionData as we need a copy
        mapped[factName] = { operand, operation, not, column: factName };

        // Convert string operand to array - multi-checkbox elements will populate operand correctly so no need to convert.
        if (_.isString(operand) && operand.length) {
            mapped[factName].operand = [operand];
        } else if (!isEmpty(operandStart) && !isEmpty(operandEnd)) {
            mapped[factName].operand = [operandStart, operandEnd];
        } else {
            mapped[factName].operand = operand;
        }

        // Ensure not = false
        mapped[factName].not = false;

        // Ensure default operation is set
        if (isEmpty(operation)) {
            mapped[factName].operation = EQ;
        }
    });

    return mapped;
};
