import React from 'react';
import CenteringContainer from './CenteringContainer';
import Item from './Item';

class Centered extends React.Component {
    render() {
        let { children, className = '', containerProps = {}, style = {}, ...itemProps } = this.props;

        return (
            <CenteringContainer {...containerProps}>
                <Item {...itemProps} style={style} className={className}>
                    {children}
                </Item>
            </CenteringContainer>
        );
    }
}

export default Centered;
