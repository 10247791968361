import _ from 'lodash';
import { call, put, takeLatest } from 'redux-saga/effects';
import { apiGetLatestTerms } from '../api/get_terms';
import { actionSetLatestTerms, TERMS_GET_LATEST } from '../redux';

function* getLatestTerms() {
    const response = yield call(apiGetLatestTerms);

    if (!_.isEmpty(response)) {
        yield put(actionSetLatestTerms(response));
    }
}

function* main() {
    yield takeLatest(TERMS_GET_LATEST, getLatestTerms);
}

export default main;
