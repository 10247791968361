import _ from 'lodash';
import { isEmpty } from 'pitch4_validation';
import { getDeterminedSections, getFormHeadings, getInitialSection, getStepsMap } from '../utils/cachedValues';

export const defaultInitialState = {
    formStack: [],
    submitting: false,
    config: {},
    initialValues: {},
    fieldValueSelector: (value) => (_.isArray(value) ? value[0] : value),
    formHeadings: {},
    determinedSections: [],
    stepsMap: {},
    initialSection: {},
};

export const SET_CURRENT_FORM = `set_current_form`;
export const REMOVE_LAST_FORM = `remove_last_form`;
export const REMOVE_TO = `remove_to`;
export const RESET = `reset`;
export const NEXT_PAGE = `next_page`;
export const RESET_FIELDS = `reset_fields`;
export const GET_CONFIG = `get_config`;
export const SET_CONFIG = `set_config`;
export const SET_INITIAL_VALUES = `set_initial_values`;
export const SET_FIELD_VALUE_SELECTOR = `set_field_value_selector`;
export const SKIP_TO = `skip_to`;

// actions
export const actionSetFieldValueSelector = (namespace) => (fieldValueSelector) => ({
    type: `${namespace}.${SET_FIELD_VALUE_SELECTOR}`,
    fieldValueSelector,
    namespace,
});

export const actionSetInitialValues = (namespace) => (initialValues) => ({
    type: `${namespace}.${SET_INITIAL_VALUES}`,
    initialValues,
    namespace,
});

export const actionSetCurrentForm = (namespace) => (form) => ({
    type: `${namespace}.${SET_CURRENT_FORM}`,
    form,
    namespace,
});

export const actionRemoveLastForm = (namespace) => () => ({
    type: `${namespace}.${REMOVE_LAST_FORM}`,
    namespace,
});

export const actionRemoveTo = (namespace) => (form) => ({
    type: `${namespace}.${REMOVE_TO}`,
    form,
    namespace,
});

export const actionReset = (namespace) => () => ({
    type: `${namespace}.${RESET}`,
    namespace,
});

export const actionResetFields = (namespace) => (form, section, fieldNames) => ({
    type: `${namespace}.${RESET_FIELDS}`,
    form,
    section,
    fieldNames,
    namespace,
});

export const actionNextPage = (namespace) => (values) => ({
    type: `${namespace}.${NEXT_PAGE}`,
    values,
    namespace,
});

export const actionGetConfig = (namespace) => () => ({
    type: `${namespace}.${GET_CONFIG}`,
    namespace,
});

export const actionSetConfig = (namespace) => (config) => ({
    type: `${namespace}.${SET_CONFIG}`,
    config,
    namespace,
});

export const actionSkipToSection = (namespace) => (section) => ({
    type: `${namespace}.${SKIP_TO}`,
    section,
    namespace,
});

const reducer = (state = defaultInitialState, action, baseNamespace) => {
    const namespace = action.namespace;

    // Ignore actions without a namespace
    if (isEmpty(namespace)) {
        return { ...state };
    }

    if (namespace !== baseNamespace) {
        return { ...state };
    }

    let formStack;

    switch (action.type) {
        case `${namespace}.${SET_FIELD_VALUE_SELECTOR}`:
            const fieldValueSelector = action.fieldValueSelector;

            return {
                ...state,
                fieldValueSelector,
            };

        case `${namespace}.${SET_INITIAL_VALUES}`:
            const initialValues = action.initialValues;

            return {
                ...state,
                initialValues,
            };

        case `${namespace}.${SET_CURRENT_FORM}`:
            formStack = [...state.formStack];
            formStack.push(action.form);

            return {
                ...state,
                formStack,
            };

        case `${namespace}.${REMOVE_LAST_FORM}`:
            formStack = [...state.formStack];
            formStack.pop();

            return {
                ...state,
                formStack,
            };

        case `${namespace}.${REMOVE_TO}`:
            formStack = [...state.formStack];
            const index = _.indexOf(formStack, action.form);
            formStack.length = index + 1;

            return {
                ...state,
                formStack,
            };

        case `${namespace}.${RESET}`:
            return {
                ...state,
                formStack: [],
                submitting: false,
            };

        case `${namespace}.${SET_CONFIG}`:
            const config = action.config;

            const sectionKeys = Object.keys(config['sections']);
            const initialSection = getInitialSection(config, sectionKeys);

            return {
                ...state,
                config,
                formHeadings: getFormHeadings(config, sectionKeys),
                determinedSections: getDeterminedSections(config, initialSection),
                stepsMap: getStepsMap(config, sectionKeys),
                initialSection,
            };

        case `${namespace}.${SKIP_TO}`:
            formStack = [...state.formStack];
            formStack.pop();
            formStack.push(action.section);

            return {
                ...state,
                formStack,
            };

        default:
            return { ...state };
    }
};

//Selectors
export const selectorGetFieldValueSelector = (namespace) => (state) => {
    return state[namespace].fieldValueSelector;
};

export const selectorGetInitialValues = (namespace) => (state) => {
    return state[namespace].initialValues;
};

export const selectorGetCurrentForm = (namespace) => (state) => {
    const formStack = state[namespace].formStack;
    return formStack.length > 0 ? formStack[formStack.length - 1] : '';
};

export const selectorGetFormStack = (namespace) => (state) => {
    return state[namespace].formStack;
};

export const selectorGetFormSubmitting = (namespace) => (state) => {
    return state[namespace].submitting;
};

export const selectorGetConfig = (namespace) => (state) => {
    return state[namespace].config;
};

export const selectorGetConfigVersion = (namespace) => (state) => {
    return state[namespace].config.version;
};

export const selectorGetFormHeadings = (namespace) => (state) => {
    return state[namespace].formHeadings;
};

export const selectorGetDeterminedSections = (namespace) => (state) => {
    return state[namespace].determinedSections;
};

export const selectorGetInitialSection = (namespace) => (state) => {
    return state[namespace].initialSection;
};

export const selectorGetStepsMap = (namespace) => (state) => {
    return state[namespace].stepsMap;
};

export const selectorGetConfigEnumValues = (namespace) => (state, enumName) => {
    return _.get(state, [namespace, 'config', 'enums', enumName, 'options'], []) || [];
};

//Default export reducer
export default reducer;
