import createP4ApolloFetch from '../../../bootstrap/middlewares';
import { ANON_URL } from '../../../bootstrap/constants/constants';
import { BORROWER_REGISTRATION_RECAPTCHA_ACTION } from '../containers/BorrowerRegistrationContainer';
import { DEFAULT_TITLE, DEFAULT_GENDER, DEFAULT_NATIONALITY } from '../../../pitch4_enum/enum/DefaultEnum';
import Bowser from 'bowser';

const apolloFetch = createP4ApolloFetch({ uri: ANON_URL });
const userEnvironment = Bowser.parse(window.navigator.userAgent);

const mutation = `
    mutation register($input: anon_registerBorrowerInput!, $recaptcha: RecaptchaV3Input!) {
        registerBorrower (input: $input, recaptcha: $recaptcha) {
            token {
                token
                expires
            }
            session {
                roles {
                    label
                    name
                }
                id
                title
                given
                family
                authExpiry
                supplementaryData
                user {
                    userProfile {
                        dateOfBirth
                    }
                }
                primaryPhone {
                    number
                }
            }
        }
    }
`;

export const registerBorrower = (
    {
        title = DEFAULT_TITLE,
        family,
        given,
        email,
        nationality = DEFAULT_NATIONALITY,
        gender = DEFAULT_GENDER,
        dateOfBirth,
        phoneNumber,
        password,
        passwordConfirm = password,
        sms_verification = true,
        hubspotCookie = null,
        pageUri = null,
        pageName = null,
        ppcPage = null,
        tcPpAgreement = false,
        commsAgreement = false,
        page,
        customerSource,
    },
    acceptedTermsVersion,
    recaptchaToken = ''
) => {
    return apolloFetch({
        query: mutation,
        variables: {
            input: {
                title,
                family,
                given,
                email,
                nationality,
                gender,
                dateOfBirth,
                primaryPhone: {
                    number: phoneNumber,
                },
                password,
                passwordConfirm,
                acceptedTermsVersion,
                customerPage: customerSource,
                smsVerificationRequested: sms_verification,
                osName: userEnvironment.os.name || null,
                browserName: userEnvironment.browser.name || null,
                hubspotCookie,
                pageUri,
                pageName,
                ppcPage,
                commsAgreement,
            },
            recaptcha: {
                token: recaptchaToken,
                action: BORROWER_REGISTRATION_RECAPTCHA_ACTION,
            },
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};
