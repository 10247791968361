import React from 'react';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import { reduxForm } from 'redux-form';
import { CenteringContainer, Item } from '../../../../../pitchblack_react_utils/layout/index';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_INTERMEDIARY } from '../../../../authentication/enum/Roles/NameEnum';
import { NoQueryMutation } from '../../../../../pitch4_forms/components';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import FormContent from '../../../../../pitch4_elements/applicant/FormContent';

class CreateContainer extends React.Component {
    render() {
        const { handleSubmit, submitting, reset } = this.props;

        return (
            <NoQueryMutation
                mutationQuery={gql`
                    mutation createApplicant($input: intermediary_createApplicantInput!) {
                        createApplicant(input: $input) {
                            applicant {
                                id
                                given
                                family
                                dateOfBirth
                                email
                                address {
                                    address1
                                    address2
                                    address3
                                    address4
                                    postcode
                                    country
                                }
                                createdAt
                                updatedAt
                            }
                        }
                    }
                `}
                renderFormFunction={(mutate, props) => (
                    <CenteringContainer>
                        <Item xs={12}>
                            <form onSubmit={handleSubmit(this.onSubmit(mutate, props))}>
                                <FormContent submitting={submitting} reset={reset} />
                            </form>
                        </Item>
                    </CenteringContainer>
                )}
            />
        );
    }

    onSubmit = (mutate, props) => (values) => {
        const { inProgress, onSuccess, onFailure } = this.props;
        const { notificationSuccess, notificationError } = props;

        inProgress();

        //map the variables if necessary
        return mutate({
            variables: {
                input: values,
            },
            context: {
                uri: INTERMEDIARY_URL,
            },
        })
            .then(({ data }) => {
                notificationSuccess('Applicant created');
                onSuccess(data);
            })
            .catch((error) => {
                notificationError('Unable to create applicant. Please contact support.');
                onFailure(error);
            });
    };
}

export default compose(
    reduxForm({
        form: 'intermediary_applicant_create',
        enableReinitialize: true,
        updateUnregisteredFields: false,
    }),
    renderErrorIfAuthorisationFail([ROLE_INTERMEDIARY])
)(CreateContainer);
