export const EPC_A = 'EPC_A';
export const EPC_B = 'EPC_B';
export const EPC_C = 'EPC_C';
export const EPC_D = 'EPC_D';
export const EPC_E = 'EPC_E';
export const EPC_F = 'EPC_F';
export const EPC_G = 'EPC_G';

class EPCRatings {
    getOptions = () => {
        return {
            [EPC_A]: { value: EPC_A, label: 'A' },
            [EPC_B]: { value: EPC_B, label: 'B' },
            [EPC_C]: { value: EPC_C, label: 'C' },
            [EPC_D]: { value: EPC_D, label: 'D' },
            [EPC_E]: { value: EPC_E, label: 'E' },
            [EPC_F]: { value: EPC_F, label: 'F' },
            [EPC_G]: { value: EPC_G, label: 'G' },
        };
    };

    getTypes = () => {
        return [
            { value: EPC_A, label: 'A' },
            { value: EPC_B, label: 'B' },
            { value: EPC_C, label: 'C' },
            { value: EPC_D, label: 'D' },
            { value: EPC_E, label: 'E' },
            { value: EPC_F, label: 'F' },
            { value: EPC_G, label: 'G' },
        ];
    };
}

export default EPCRatings;
