import React from 'react';
import { Paper } from '@material-ui/core';

export default function QuickStatsCard(props) {
    const { count, label } = props;
    return (
        <Paper elevation={1} className={'card'}>
            <div>
                <div className={'count'}>{count}</div>
                <div className={'label'}>{label}</div>
            </div>
        </Paper>
    );
}
