import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Container from 'pitchblack_react_utils/layout/Container';
import { Body1 } from 'pitch4_layout/components/text';

const StyledContainer = styled(Container)`
    padding: 10px 10px;
`;

class AssumedIdentitySessionExpired extends React.Component {
    render() {
        const { session } = this.props;

        return (
            <StyledContainer>
                <Body1>
                    For security reasons you are no longer acting on behalf of {session.getFullname()}.<br />
                    <br />
                    You can try again from the View users screen.
                </Body1>
            </StyledContainer>
        );
    }
}

AssumedIdentitySessionExpired.propTypes = {
    session: PropTypes.object.isRequired,
};

export default AssumedIdentitySessionExpired;
