import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import { PrimaryButton } from '../../../../../pitch4_layout/components/buttons';
import { BRAND_MAIN_CONTACT } from '../../../../../bootstrap/constants/constants';

class DeleteButton extends React.Component {
    render() {
        let { inputRef = () => null, children } = this.props;

        return (
            <PrimaryButton inputRef={inputRef} onClick={this.onClick}>
                {children}
            </PrimaryButton>
        );
    }

    onClick = () => {
        let { mutate, outerRefetch, userId, lenderId, notificationSuccess, notificationError } = this.props;

        return mutate({
            variables: {
                userId: userId,
                input: {
                    lenderIds: [lenderId],
                },
            },
        })
            .then(() => {
                notificationSuccess('Lender is no longer associated with user.');
                outerRefetch();
            })
            .catch((error) => {
                notificationError('An error occurred. Please contact support on ' + BRAND_MAIN_CONTACT);
                outerRefetch();
            });
    };
}

DeleteButton.propTypes = {
    userId: PropTypes.string.isRequired,
    lenderId: PropTypes.string.isRequired,
    mutate: PropTypes.func.isRequired,
    outerRefetch: PropTypes.func.isRequired,
    children: PropTypes.any,
    inputRef: PropTypes.any,
};

const DeleteMutation = gql`
    mutation disassociateLender($userId: ID!, $input: pitch4admin_disassociateLendersInput!) {
        disassociateLenders(userId: $userId, input: $input) {
            lendersRemoved
        }
    }
`;

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(DeleteMutation, {
        options: {
            context: {
                uri: API_URL,
            },
        },
    })
)(DeleteButton);
