import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import landingReducers from '../../apps/landing/redux/index';
import authenticationReducers from '../../apps/authentication/redux/index';
import userReducers from '../../apps/user/redux/index';
import adminReducers from '../../apps/admin/pitch4admin/redux/index';
import intermediaryReducers from '../../apps/admin/intermediary/redux/index';
import slamReducers from '../../apps/admin/slam/redux/index';
import borrowerReducers from '../../apps/borrower/redux/index';
import passwordResetReducers from '../../apps/password_reset/redux/index';
import routingReducers from '../../pitch4_routing/redux/index';
import { routerReducer } from 'react-router-redux';
import notificationsReducer from '../../apps/notifications/redux';
import modalsReducer from '../../apps/modal/redux';
import chatReducer from '../../pitch4_chat/redux';
import hubspotReducer from '../../apps/hubspot/redux';
import navigationReducer, { REDUCER_NAMESPACE as NAV_NAMESPACE } from '../../apps/navigation/redux/leftsidemenu';
import twoFactorReducer from '../../apps/user/two_factor_auth/redux';
import twoFactorResetReducer from '../../apps/two_factor_auth_reset/redux';
import headerReducer from '../../apps/header/redux';
import registerReducers from '../../apps/registration/redux';
import termsAndConditionsReducers from '../../apps/terms_and_conditions/redux';
import loanUsageInUkReducers from '../../apps/loan_usage_in_uk/redux';
import splashReducers from '../../apps/splash/redux';
import bannerNoticeReducer from '../../apps/bannernotice/redux';
import joyrideReducer, { REDUCER_NAMESPACE as JOYRIDE_NAMESPACE } from '../../apps/joyride/redux';

const reducers = combineReducers({
    ...landingReducers,
    ...adminReducers,
    ...intermediaryReducers,
    ...slamReducers,
    ...borrowerReducers,
    ...registerReducers,
    ...authenticationReducers,
    ...userReducers,
    ...routingReducers,
    ...notificationsReducer,
    ...modalsReducer,
    ...chatReducer,
    ...passwordResetReducers,
    ...hubspotReducer,
    ...twoFactorReducer,
    ...twoFactorResetReducer,
    ...headerReducer,
    ...termsAndConditionsReducers,
    ...loanUsageInUkReducers,
    ...splashReducers,
    ...bannerNoticeReducer,
    [JOYRIDE_NAMESPACE]: joyrideReducer,
    [NAV_NAMESPACE]: navigationReducer,
    form: reduxFormReducer,
    router: routerReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'authentication.logout') {
        state = undefined;
    }

    return reducers(state, action);
};

export default rootReducer;
