import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Notice from '../../../pitch4_layout/components/content/Notice';
import _ from 'lodash';
import { BRAND_MAIN_CONTACT } from '../../../bootstrap/constants/constants';

class TelephoneHelperBox extends React.Component {
    render() {
        const { section } = this.props;

        const skipToSection = this.skipToSection(section);
        const assistanceMessage = 'Do You Need Any Assistance With This Process?';
        const telephoneMessage = `Please call us on ${BRAND_MAIN_CONTACT} to talk to a member of our admin team.`;
        const skipToTelephoneMessage = `For assistance call us on ${BRAND_MAIN_CONTACT} to talk to a member of our admin team.`;

        return (
            <div className={'business-stream-telephone-box-wrapper'}>
                <div className={'business-stream-telephone-box'} id={'business-stream-telephone-box'}>
                    <Notice
                        messageHeader={skipToSection ? this.renderSkipToLink(skipToSection) : assistanceMessage}
                        message={<span>{skipToSection ? skipToTelephoneMessage : telephoneMessage}</span>}
                    />
                </div>
            </div>
        );
    }

    renderSkipToLink = (skipToSection) => {
        const { skipTo, formHeadings } = this.props;

        return (
            <>
                As this section is optional, you can skip to:{' '}
                <Link
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        return skipTo(skipToSection);
                    }}
                >
                    {formHeadings[skipToSection]} &raquo;
                </Link>
            </>
        );
    };

    skipToSection = (section) => {
        const { config } = this.props;
        return _.get(config, ['sections', section, 'skip_to_section'], false) || false;
    };
}

TelephoneHelperBox.propTypes = {
    skipTo: PropTypes.func.isRequired,
    section: PropTypes.string.isRequired,
    formHeadings: PropTypes.array.isRequired,
    config: PropTypes.object.isRequired,
};

export default TelephoneHelperBox;
