import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { push } from 'react-router-redux';
import { RollupCommandContainer } from '../../../../pitch4_tabular/components';
import { PrimaryButton } from '../../../../pitch4_layout/components/buttons';
import { ROLE_SUPER_ADMIN } from '../../../authentication/enum/Roles/NameEnum';
import { API_URL } from '../../../../bootstrap/constants/constants';
import { sagaActionModalHide, sagaActionModalInteractive, sagaActionModalShow } from '../../../modal/sagas/container';
import { Button } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import HeaderWithCount from '../../../../pitch4_elements/screen-headers/HeaderWithCount';
import Toolbar from './Toolbar';
import { getAvailableTags } from '../../../../pitch4_api/get_available_tags';
import { textContrastColor } from '../../../../pitchblack_react_utils/color/Contrast';
import TagProperties from '../../../../pitch4_elements/le-tags/TagProperties';
import DeleteTagModal from '../../../../pitch4_elements/le-tags/DeleteTagModal';

class TableStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFetchError: null,
            searchFilter: '',
            originalData: [],
            dataGrid: {
                rows: [],
                dataLoading: false,
            },
            dataRequest: {
                filters: [],
                sorts: [
                    {
                        column: 'createdAt',
                        direction: 'desc',
                    },
                ],
                pages: [],
                cursor: null,
                storedDataFilters: [],
            },
        };
    }

    componentDidMount() {
        const data = this.getData();
        this.processData(data);
    }

    refreshData = () => {
        const data = this.getData();
        this.processData(data);
    };

    processData(data) {
        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            const rows = data.tags.map((tag, index) => {
                return {
                    id: tag.id,
                    label: tag.label,
                    color: tag.color,
                };
            });

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: rows,
                    originalData: rows,
                },
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    getData() {
        return getAvailableTags(API_URL, 1)();
    }

    handleSearchFilterChange = (event) => {
        const searchString = event.target.value;
        this.setState({
            searchFilter: searchString,
            dataGrid: { ...this.state.dataGrid, rows: this.filterTags(searchString) },
        });
    };

    filterTags = (searchString) =>
        this.state.dataGrid.originalData.filter((row) => row.label.toLowerCase().includes(searchString.toLowerCase()));

    handleClearAllFilters = () => {
        this.setState({
            searchFilter: '',
            dataGrid: { ...this.state.dataGrid, rows: this.state.dataGrid.originalData },
        });
    };

    createTag = () => {
        const { setModalComponent, setModalClose } = this.props;

        setModalComponent(
            <TagProperties setModalClose={setModalClose} uri={API_URL} isNew={true} refreshData={this.refreshData} />,
            false
        );
    };

    editTag = (tag) => {
        const { setModalComponent, setModalClose } = this.props;

        setModalComponent(
            <TagProperties setModalClose={setModalClose} uri={API_URL} tag={tag} refreshData={this.refreshData} />,
            false
        );
    };

    deleteTag = (tag) => {
        const { setModalComponent, setModalClose } = this.props;

        setModalComponent(
            <DeleteTagModal setModalClose={setModalClose} tag={tag} refreshData={this.refreshData} />,
            false
        );
    };

    render() {
        const { dataLoading, rows } = this.state.dataGrid;
        const { searchFilter } = this.state;

        return (
            <div>
                <div className={'le-header-container tags-container'}>
                    <div className={'header-text'}>
                        <HeaderWithCount title={'Tags'} count={rows.length} countText={'tags'} />
                        <div className={'btn-mobile'}>
                            <Button
                                className={'btn-create'}
                                variant="contained"
                                color="primary"
                                size={'large'}
                                style={{
                                    marginLeft: 20,
                                    height: 45,
                                    paddingTop: 11,
                                    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                                }}
                                onClick={() => this.createTag()}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                    <Toolbar
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                        handleClearAllFilters={this.handleClearAllFilters}
                        createTag={this.createTag}
                    />
                </div>

                <div className={`le-table-container`}>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        rows={rows}
                        loading={true}
                        disableColumnFilter={true}
                        disableMultipleColumnsSorting={true}
                        disableColumnReorder={true}
                        disableMultipleColumnsFiltering={true}
                        disableSelectionOnClick={true}
                        disableColumnMenu={true}
                        components={{
                            LoadingOverlay: () => {
                                return (
                                    <GridOverlay>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {this.state.dataGrid.dataLoading ? (
                                                <LinearProgress />
                                            ) : rows.length === 0 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        width: '100%',
                                                        paddingTop: 100,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    No data
                                                </div>
                                            ) : null}
                                        </div>
                                    </GridOverlay>
                                );
                            },
                        }}
                        columns={[
                            {
                                field: 'label',
                                headerName: 'LABEL',
                                width: 300,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'color',
                                headerName: 'COLOUR',
                                width: 400,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <div
                                                className={'tag-list-color'}
                                                style={{
                                                    backgroundColor: params.row.color,
                                                    color: textContrastColor(params.row.color, ['#000000', '#ffffff']),
                                                }}
                                            >
                                                {params.row.label}
                                            </div>
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'actions',
                                cellClassName: 'le-table-cell le-table-cell-content-right',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                renderHeader: () => <div />,
                                renderCell: (params) => {
                                    return (
                                        <div className={'le-table-cell-context-menu'} style={{ marginTop: 10 }}>
                                            <RollupCommandContainer>
                                                <PrimaryButton onClick={() => this.editTag(params.row)}>
                                                    Edit
                                                </PrimaryButton>
                                                <PrimaryButton onClick={() => this.deleteTag(params.row)}>
                                                    Delete
                                                </PrimaryButton>
                                            </RollupCommandContainer>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => () => dispatch(push(url)),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(TableStyleContainer);
