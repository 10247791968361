import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';

import styled from 'styled-components';
import { BRAND_NAME } from '../../../bootstrap/constants/constants';
import { BrandTextFull, Subtitle1 } from '../../../pitch4_layout/components/text';

const HeadingText = styled(Subtitle1)`
    padding-top: 1em;
    font-family: 'degular-display', sans-serif;
    padding-bottom: 1em;
`;

const StyledBulletList = styled.ul`
    margin-left: 3em;
    padding-bottom: 1em;
    padding-top: 0.5em;
`;

const StyledLi = styled.li`
    margin-top: 1em;
`;

const StyledContainer = styled.div`
    padding-bottom: 2em;
    font-family: 'degular-display', sans-serif;
`;

const StyledP = styled.p`
    padding-top: 0.3em;
    padding-bottom: 0.3em;
`;

class PrivacyPolicyContainer extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta
                        name="description"
                        content="{ PITCH4_BRAND_NAME } | property & business finance | free comparison"
                    />
                    <meta
                        name={`${BRAND_NAME} matches borrowers with specialist business & property lenders. Enter details, receive offers, chat and complete the finance. Simple & free.`}
                        content=""
                    />
                    <title>{BRAND_NAME} | property & business finance | free comparison | Privacy Policy</title>
                </Helmet>
                <Header />
                <StyledContainer className="container">
                    <div>
                        <div className="col-md-12 col-sm-12 col-xs-12" style={{ paddingTop: '2em' }}>
                            <h5>
                                <BrandTextFull />
                                &nbsp;Privacy Policy
                            </h5>
                            <div className="entry-content" style={{ paddingTop: '1em' }}>
                                <section>
                                    <div className="wpb_column vc_column_container vc_col-sm-12  col-xs-mobile-fullwidth">
                                        <div className="vc_column-inner ">
                                            <div className="wpb_wrapper">
                                                <HeadingText>Collection and Use of Personal Information</HeadingText>
                                                <div className="last-paragraph-no-margin">
                                                    <StyledP>
                                                        This privacy policy applies to www.providefinance.com (
                                                        <BrandTextFull />
                                                        /us/we/our) and its related mobile applications. &nbsp;It tells
                                                        you (the customer) what to expect when we collect personally
                                                        identifiable information about you.
                                                    </StyledP>

                                                    <StyledP>
                                                        We will not collect any personally identifiable information
                                                        about you unless it is in response to you using our
                                                        application(s) or actively applying for one of our online
                                                        products or services. &nbsp;We value your privacy and the
                                                        security of your personally identifiable information is
                                                        extremely important to us. &nbsp;The lawful basis by which we
                                                        are collecting information from you is{' '}
                                                        <em>
                                                            "<u>the performance of a contract</u>".
                                                        </em>
                                                    </StyledP>

                                                    <StyledP>
                                                        This privacy policy is regularly reviewed to make sure that we
                                                        continue to serve your privacy interests. &nbsp;We reserve the
                                                        right to update this privacy policy as we deem necessary. Any
                                                        changes will be posted on our site and if you have an account
                                                        with us we will contact you directly if we deem it necessary.
                                                    </StyledP>

                                                    <StyledP>
                                                        For the purpose of the General Data Protection Regulation (GDPR)
                                                        2018, the registered data controller is <BrandTextFull />.
                                                    </StyledP>

                                                    <StyledP>
                                                        Our registered office is at Unit 1, Verney House, 1b Hollywood
                                                        Road, London SW10 9HS
                                                    </StyledP>
                                                    <StyledP>
                                                        Our trading address is Unit 1, Verney House, 1b Hollywood Road,
                                                        London SW10 9HS
                                                    </StyledP>

                                                    <StyledP>
                                                        Our Information Commissioner's Office (ICO) registration number
                                                        is ZA361922
                                                    </StyledP>

                                                    <HeadingText>What information we collect:</HeadingText>
                                                    <StyledP>
                                                        When you create an account and complete the Loan Enquiry form on
                                                        our website, we collect the following information from you:
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>Name and contact details;</StyledLi>
                                                        <StyledLi>Residency and legal status;</StyledLi>
                                                        <StyledLi>Employment details;</StyledLi>
                                                        <StyledLi>
                                                            Financial information such as income, financial commitments
                                                            and credit history
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Any other personally identifiable information that may be
                                                            necessary for the completion of a loan enquiry. This may
                                                            depend on and vary with the type of loan you are enquiring
                                                            about.
                                                        </StyledLi>
                                                    </StyledBulletList>

                                                    <StyledP>
                                                        In addition to the above {BRAND_NAME} may prompt you to
                                                        provide/upload additional information during the subsequent
                                                        stages of the matching and loan application process as well as
                                                        that information may be collected via direct communication with
                                                        our platform and client administrators by phone, email or the
                                                        platform’s live chat feature – for example:
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>Identification and address verification;</StyledLi>
                                                        <StyledLi>Payslips and contracts of employment;</StyledLi>
                                                        <StyledLi>Accounts, SA302s and tax year overviews;</StyledLi>
                                                        <StyledLi>Bank statements;</StyledLi>
                                                        <StyledLi>Credit report;</StyledLi>
                                                        <StyledLi>
                                                            Any other information that may be relevant and necessary for
                                                            the completion of a loan application. This may depend on and
                                                            vary with the type of loan you are enquiring about;
                                                        </StyledLi>
                                                    </StyledBulletList>

                                                    <StyledP>
                                                        We also collect information about your computer, including your
                                                        browser type, your operating system, your device type and your
                                                        IP address. &nbsp;We collect this information for site
                                                        optimisation, product and service improvements and statistical
                                                        purposes. For more information on this please refer to our
                                                        cookie policy.
                                                    </StyledP>

                                                    <StyledP>
                                                        As we may also collect personal information from you over the
                                                        telephone please note that telephone calls are recorded for
                                                        training and monitoring purposes.
                                                    </StyledP>

                                                    <HeadingText>How we will use your information:</HeadingText>
                                                    <StyledP>
                                                        The information we collect about you will in the main be used
                                                        for the purpose of applying for a loan or any other product or
                                                        service we offer.
                                                    </StyledP>

                                                    <StyledP>
                                                        Other ways in which we will use your information include:
                                                    </StyledP>
                                                    <StyledP>We will use your information to:</StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            Operate and manage any <BrandTextFull />
                                                            &nbsp;account you hold with us;
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Operate and manage the matching process for your loan
                                                            enquiries;
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Operate and manage the application process for your loan
                                                            enquiries;
                                                        </StyledLi>
                                                    </StyledBulletList>

                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            Carry out market research, business and statistical
                                                            analysis;
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Test our systems and develop our products and features (or
                                                            any other similar purpose);
                                                        </StyledLi>
                                                        <StyledLi>Fraud and crime prevention</StyledLi>
                                                        <StyledLi>
                                                            Comply with any regulatory obligations or criminal
                                                            investigations
                                                        </StyledLi>
                                                        <StyledLi>
                                                            If you have consented we will contact you by any medium you
                                                            have agreed to or provided us with details of, in relation
                                                            to our services.
                                                        </StyledLi>
                                                    </StyledBulletList>

                                                    <HeadingText>
                                                        We will hold your personal data on our systems as follows:{' '}
                                                    </HeadingText>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            Your basic account details such as your name and various
                                                            contact information will be retained until such time that
                                                            you delete your <BrandTextFull />
                                                            &nbsp; account. It is your responsibility to ensure the
                                                            information we hold is up to date.
                                                        </StyledLi>
                                                        <StyledLi>
                                                            All data relating to a completed loan application will be
                                                            kept on our systems for a minimum of 6 years after repayment
                                                            of the loan in line with our regulatory requirements;
                                                        </StyledLi>
                                                        <StyledLi>
                                                            All data relating to any loan enquiry that has progressed to
                                                            loan application but that has not resulted in a drawdown of
                                                            funds will be kept on our systems for 6 years or, if prior,
                                                            until such time that you delete your <BrandTextFull />
                                                            &nbsp;account.
                                                        </StyledLi>
                                                        <StyledLi>
                                                            All data relating to any loan enquiry that has not
                                                            progressed to loan application will be kept on our systems
                                                            for 6 years or, if prior, until such time that you delete
                                                            your <BrandTextFull />
                                                            &nbsp;account.
                                                        </StyledLi>
                                                        <StyledLi>
                                                            We may also use your anonymized data for internal
                                                            statistical analysis purposes and reserve the right to do so
                                                            even if you have deleted your &nbsp;
                                                            <BrandTextFull />
                                                            &nbsp;account.
                                                        </StyledLi>
                                                    </StyledBulletList>

                                                    <HeadingText>
                                                        We may share your information with the following entities:
                                                    </HeadingText>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            Any <BrandTextFull />
                                                            &nbsp;group company;
                                                        </StyledLi>
                                                        <StyledLi>Any Pitch Black Technologies group company;</StyledLi>
                                                        <StyledLi>
                                                            Yellow Stone Finance Group Ltd – FCA authorized and
                                                            regulated by the Financial Conduct Authority (FCA) No
                                                            814533.
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Any regulatory/governmental body, ombudsmen or law
                                                            enforcement agency with relevant jurisdiction;
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Any firm we may use for electronic identification and
                                                            address verification purposes, sanction searches or PEP
                                                            monitoring;
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Any firm we may use for credit referencing and/or credit
                                                            scoring;
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Any loan provider registered to <BrandTextFull />
                                                            &nbsp;to facilitate the loan application process;
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Any third parties that participate in your loan process
                                                            (e.g. solicitors, valuers and conveyancers);
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Suppliers who process your data on our behalf; we will have
                                                            written contracts in place with such entities which require
                                                            them to process your data only in accordance with our
                                                            instructions; and
                                                        </StyledLi>
                                                    </StyledBulletList>

                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            Any person or legal entity to whom we sell or transfer (or
                                                            initiate discussions with to sell or transfer) our business
                                                            or any part of it or any of our rights or obligations under
                                                            any agreement we may have with you. If the transfer goes
                                                            ahead, you agree that the purchaser can use your data in the
                                                            same way as us.
                                                        </StyledLi>
                                                    </StyledBulletList>

                                                    <StyledP>
                                                        We will ensure your data remains within the EEA and as a result
                                                        is captured under the General Data Protection Regulation.
                                                        &nbsp;If for any reason we use third parties that are domiciled
                                                        outside of the EEA any such data storage will undergo further
                                                        enhanced controls and checks, to ensure actual data storage
                                                        remains compliant with the General Data Protection
                                                        Regulation.&nbsp; We will inform you of any such instance where
                                                        this may occur.
                                                    </StyledP>

                                                    <HeadingText>Google Analytics</HeadingText>
                                                    <StyledP>
                                                        The <BrandTextFull />
                                                        &nbsp;platform also uses Google Analytics to allow us to monitor
                                                        how users use our Website. We use services provided by Google to
                                                        do this including Google's Remarketing and Advertising Reporting
                                                        Features. The features that we use include Google Display
                                                        Network Impression Reporting, Google Analytics Demographics and
                                                        Interest Reporting and integrated services that require Google
                                                        Analytics to collect data for advertising purposes including the
                                                        collection of the data via advertising cookies and identifiers.
                                                        As part of this activity, if you have a Google account and have
                                                        allowed Google to associate your web and app browsing history so
                                                        that you may receive personalised ads then Google may collect a
                                                        Google identifier which allows them to identify your Google
                                                        account when you are using this Website (including where you use
                                                        different devices to access this website). Google use this data
                                                        alongside other data they may collect about you, which may
                                                        include your location, search history, YouTube history and data
                                                        from other sites that partner with Google, to provide us with
                                                        aggregated and anonymised information which assists us in
                                                        understanding how individuals are using our website. Alongside
                                                        Google Analytics, this website uses Accelerated Mobile Pages
                                                        (AMP), this is a service provided by Google which allows
                                                        webpages to load more quickly on a mobile device by allocating a
                                                        Client ID to your mobile device if you have loaded the web page
                                                        before. Please refer to our cookie policy for details of the
                                                        cookies that are used in relation to Google Analytics activity.
                                                        If you require further information or wish to opt out of Google
                                                        Analytics Remarketing and Advertising Reporting Features then
                                                        please visit&nbsp;
                                                        <a href="https://tools.google.com/dlpage/gaoptout">
                                                            Google's currently available opt-outs
                                                        </a>
                                                        .
                                                    </StyledP>

                                                    <HeadingText>
                                                        Your rights under the General Data Protection Regulation (GDPR)
                                                        2018
                                                    </HeadingText>
                                                    <StyledP>
                                                        Under the General Data Protection Regulation 2018, you have
                                                        various rights in relation to your information. Detailed below
                                                        are your rights and a description of each one.
                                                    </StyledP>

                                                    <StyledP>You have the right to:</StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <u>Be informed</u>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                    <StyledP>
                                                        This means you have the right to be informed about the
                                                        collection and use of your personally identifiable data.
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <u>Have access to your information</u>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                    <StyledP>
                                                        You have the right to access your personally identifiable data
                                                        and supplementary information. This will be provided free of
                                                        charge. However, when a request is manifestly unfounded,
                                                        excessive or repetitive we reserve the right to charge a fee. We
                                                        may also charge a reasonable fee to comply with requests for
                                                        further copies of the same information. You can request access
                                                        to your information by emailing us at{' '}
                                                        <a href="mailto:subjectaccessrequest@providefinance.com">
                                                            subjectaccessrequest@providefinance.com
                                                        </a>
                                                        .
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <u>Have personally identifiable information rectified</u>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                    <StyledP>
                                                        You have a right to have inaccurate personal data rectified, or
                                                        completed if it is incomplete. We will inform you when any
                                                        inaccurate information is corrected.
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <u>Erase your personally identifiable information</u>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                    <StyledP>
                                                        The right to erasure is also known as ‘the right to be
                                                        forgotten’. You can make a request for erasure of information
                                                        verbally or in writing. We will respond to your request within
                                                        one month. The right is not absolute and only applies in certain
                                                        circumstances. For example it does not apply when we have
                                                        statutory or regulatory obligations to keep your data.
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <u>Restrict processing of personally identifiable data</u>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                    <StyledP>
                                                        You have the right to request the restriction or suppression of
                                                        your personal data. This is not an absolute right and only
                                                        applies in certain circumstances. When processing is restricted,
                                                        we are permitted to store the data, but not use it.
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <u>Port your personally identifiable data</u>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                    <StyledP>
                                                        This right allows you to obtain and reuse your personally
                                                        identifiable data for your own purposes across different
                                                        services. It allows you to move, copy or transfer personally
                                                        identifiable data easily from one IT environment to another in a
                                                        safe and secure way, without hindrance to usability.
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <u>Object to the processing of your data</u>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                    <StyledP>
                                                        You have a right to object to the processing of your data for
                                                        purposes of scientific or historical research and statistics, as
                                                        well as for the purposes of direct marketing (including
                                                        profiling).
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <u>
                                                                Challenge any decision made by automated decision making
                                                            </u>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                    <StyledP>
                                                        We will inform you when we are employ automated decision making
                                                        and when we do we will give you information about the processing
                                                        activity. We will introduce simple ways for you to request human
                                                        intervention or challenge a decision made by automated decision
                                                        making.
                                                    </StyledP>

                                                    <HeadingText>Marketing Communications</HeadingText>
                                                    <StyledP>
                                                        If you have changed your mind about receiving marketing from us,
                                                        you can opt out at any time by clicking the unsubscribe button
                                                        or the link at the bottom of any of our marketing emails.
                                                        Alternatively you can email us at unsubscribe@providefinance.com
                                                    </StyledP>

                                                    <HeadingText>Information security</HeadingText>
                                                    <StyledP>
                                                        We pride ourselves on treating your data with the utmost care
                                                        and security. Our systems have been designed to meet or exceed
                                                        required standards and we are constantly monitoring these to
                                                        provide improvements where available.
                                                    </StyledP>

                                                    <StyledP>
                                                        We will never store passwords in plain text, nor will we allow
                                                        anyone to access your data unless they have a justifiable reason
                                                        to do so. All of our employees are background checked before
                                                        they are granted access to your data.
                                                    </StyledP>

                                                    <HeadingText>
                                                        Credit decisions, the prevention of fraud and money laundering
                                                    </HeadingText>
                                                    <StyledP>
                                                        Your lender may use credit reference and fraud prevention
                                                        agencies to help them make decisions. A short and general guide
                                                        on how a lender may use your information is detailed
                                                        below.&nbsp; Access to a lender’s privacy policy will be made
                                                        available to you at the point of progressing an Agreement in
                                                        Principle (AIP) to application stage with a selected lender.
                                                    </StyledP>

                                                    <StyledP>
                                                        Please contact the lender direct should you require details
                                                        beyond those set out in their privacy statement.
                                                    </StyledP>

                                                    <HeadingText>
                                                        A condensed guide to the use of your personally identifiable
                                                        information by a lender, credit reference and fraud prevention
                                                        agencies
                                                    </HeadingText>
                                                    <StyledP>
                                                        If you apply for a loan via <BrandTextFull />, we will provide
                                                        the information we hold about you to our registered lenders. The
                                                        lenders will use this information to help make a decision about
                                                        whether or not to lend to you. This may involve checking the
                                                        following records about you and others:
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>Its own records;</StyledLi>
                                                        <StyledLi>
                                                            Information that you have provided/uploaded to{' '}
                                                            <BrandTextFull />
                                                            &nbsp;for the purpose of a loan enquiry or loan application
                                                        </StyledLi>
                                                        <StyledLi>
                                                            Those at Credit Reference Agencies (CRAs); and
                                                        </StyledLi>
                                                        <StyledLi>Those at fraud prevention agencies (FPAs).</StyledLi>
                                                    </StyledBulletList>

                                                    <StyledP>
                                                        When Credit Reference Agencies receive a search request from the
                                                        lender they will place a search footprint on your credit file
                                                        that may be seen by other lenders. They supply to lenders both
                                                        public (including the electoral register) and shared credit and
                                                        fraud prevention information. Having multiple search footprints
                                                        on your credit file may affect your ability to borrow in the
                                                        future.
                                                    </StyledP>

                                                    <StyledP>
                                                        The lender may make checks such as assessing your application
                                                        for credit and verifying the applicants’ identities to prevent
                                                        and detect crime and money laundering. The lender may also make
                                                        periodic searches at CRAs and FPAs to manage your account with
                                                        them.
                                                    </StyledP>

                                                    <StyledP>
                                                        If you are making a joint application or tell us or the lender
                                                        that you have a spouse or financial associate, the lender may
                                                        link your records together, so you must be sure that you have
                                                        their agreement to disclose their information. &nbsp;CRAs also
                                                        link your records together and these links will remain on your
                                                        and their files until such time as you or your financial
                                                        associate successfully files for a disassociation with the CRAs
                                                        to break that link.
                                                    </StyledP>

                                                    <StyledP>
                                                        Information on loan applications will be sent to CRAs and will
                                                        be recorded by them. Where you borrow from a lender, the lender
                                                        will give details of your accounts and how you manage it/them to
                                                        CRAs. &nbsp;Hence if you borrow and do not repay in full and on
                                                        time, CRAs will record the outstanding debt. This information
                                                        may be supplied to other organizations by CRAs and FPAs to
                                                        perform similar checks and to trace your whereabouts and recover
                                                        debts that you owe. &nbsp;Records remain on file for 6 years
                                                        after they are closed, whether settled by you or defaulted.
                                                    </StyledP>

                                                    <StyledP>
                                                        If you give false or inaccurate information and a lender
                                                        suspects or identifies fraud, a lender may record this and may
                                                        also pass this information to FPAs and other organisations
                                                        involved in crime and fraud prevention.
                                                    </StyledP>

                                                    <StyledP>
                                                        If you have borrowed from a lender and do not make payments that
                                                        you owe them, the lender will trace your whereabouts in order to
                                                        recover debts. The lender and other organisations may access and
                                                        use the information recorded by fraud prevention agencies based
                                                        in other countries.
                                                    </StyledP>

                                                    <HeadingText>Automated credit decisions</HeadingText>
                                                    <StyledP>
                                                        Your loan application may be assessed by the lender by the means
                                                        of automated decision making and if it is declined we will
                                                        endeavour to request from the lender the actual reason why the
                                                        application has been declined. Once we know this reason we will
                                                        contact you as soon as we are able to convey this to you.
                                                    </StyledP>

                                                    <HeadingText>Complaints</HeadingText>
                                                    <StyledP>
                                                        If you have a complaint about how we handle your data please
                                                        write to our Technical Director, Adam White:
                                                    </StyledP>
                                                    <StyledBulletList>
                                                        <StyledLi>By email at info@providefinance.com</StyledLi>
                                                        <StyledLi>
                                                            By letter, addressed to: <BrandTextFull />
                                                            &nbsp; Ltd, Unit 1, Verney House, 1b Hollywood Road, London
                                                            SW10 9HS; or
                                                        </StyledLi>
                                                        <StyledLi>By telephone on 0207 495 6211.</StyledLi>
                                                    </StyledBulletList>

                                                    <StyledP>
                                                        Please include your name and address, a contact telephone
                                                        number, the email address you signed up with and details of why
                                                        you are unhappy. If we do not have enough information to
                                                        investigate your complaint we will contact you to ask for
                                                        further information. We will investigate your complaint promptly
                                                        and will respond to you as soon as we can detailing our findings
                                                        of your complaint.
                                                    </StyledP>

                                                    <StyledP>
                                                        If we have been unable to resolve your information rights
                                                        concern, you can raise the matter with Information
                                                        Commissioner's Office (“ICO”). They will use the information you
                                                        have provided, including our response to your concerns, to
                                                        decide if your concern provides an opportunity to improve
                                                        information rights practice.
                                                    </StyledP>

                                                    <StyledP style={{ paddingBottom: '1em' }}>
                                                        You can contact the ICO by either by calling their helpline on
                                                        0303 123 1113 or by using their '
                                                        <u>
                                                            <a href="https://ico.org.uk/global/contact-us/live-chat/">
                                                                live chat
                                                            </a>
                                                        </u>
                                                        ' system.
                                                    </StyledP>

                                                    <h6>How to find out more</h6>
                                                    <StyledP>
                                                        Your lender will have their own separate privacy policy, for
                                                        more information on how they handle your personal information
                                                        please refer to them direct.
                                                    </StyledP>

                                                    <StyledP>
                                                        If you wish, you can also contact the Credit Reference Agencies
                                                        (CRAs) directly. The information they each hold about you may
                                                        not be the same so you might need to contact them all. You
                                                        should check with them if a fee is payable for this information.
                                                    </StyledP>

                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <em>CallCredit</em>
                                                            <StyledP>
                                                                Consumer Services Team, PO Box 491, Leeds, LS3 1WZ
                                                            </StyledP>
                                                            <StyledP>0870 0601414</StyledP>
                                                            <StyledP>
                                                                <a href="https://www.callcredit.co.uk">
                                                                    www.callcredit.co.uk
                                                                </a>
                                                            </StyledP>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <em>Equifax PLC</em>
                                                            <StyledP>
                                                                Credit File Advice Centre, PO Box 3001, Bradford, BD1
                                                                5US
                                                            </StyledP>
                                                            <StyledP>0870 010 0583</StyledP>
                                                            <StyledP>
                                                                <a href="https://www.myequifax.co.uk/">
                                                                    www.myequifax.co.uk
                                                                </a>
                                                            </StyledP>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                    <StyledBulletList>
                                                        <StyledLi>
                                                            <em>Experian</em>
                                                            <StyledP>
                                                                Consumer Help Service, PO Box 8000, Nottingham NG80
                                                            </StyledP>
                                                            <StyledP>0844 4818000</StyledP>
                                                            <StyledP>
                                                                <a href="https://www.experian.co.uk/">
                                                                    www.experian.co.uk
                                                                </a>
                                                            </StyledP>
                                                        </StyledLi>
                                                    </StyledBulletList>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </StyledContainer>
                <Footer />
            </div>
        );
    }
}

export default PrivacyPolicyContainer;
