import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { Container, Item } from '../../pitchblack_react_utils/layout/index';
import { BRAND_NAME } from '../../bootstrap/constants/constants';

const RootContainer = styled(Container)`
    background-color: white;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
`;

const Header = styled(Item)`
    width: 100%;
    min-height: 50px;
    margin: 0;
    padding: 0;
`;

const ContentAreaItem = styled(Item)`
    background-color: white;
    height: 100%;
    padding-top: 20px;
`;

const SingleColumnLayout = ({ children, header, notifications }) => (
    <>
        <Helmet>
            <title>{BRAND_NAME}</title>
        </Helmet>
        <RootContainer alignItems="flex-start" justify="center">
            <Header>{header}</Header>
            <ContentAreaItem md={6} sm={12} xs={12}>
                {children}
            </ContentAreaItem>
            {notifications}
        </RootContainer>
    </>
);

SingleColumnLayout.propTypes = {
    header: PropTypes.element.isRequired,
    notifications: PropTypes.element.isRequired,
    children: PropTypes.any.isRequired,
};

export default SingleColumnLayout;
