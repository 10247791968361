class Mapper {
    mapToForm = (props) => {
        let { id = '' } = props;

        return {
            id,
        };
    };

    mapFromForm = ({ id, logo }) => {
        return {
            id,
            logo,
        };
    };
}

export default Mapper;
