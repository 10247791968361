import React from 'react';
import OptionFactory from 'pitchblack_react_utils/form/AutoCompleteSelect/OptionFactory';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Autocomplete from 'pitch4_forms/components/inputs/autocomplete/redux_form/Autocomplete';

class UserStoreSelect extends React.Component {
    render() {
        let { types, userDocuments, submittedDocuments = [], loading = false } = this.props;

        const submittedDocIds = submittedDocuments.map(({ node: { id } }) => id);

        userDocuments = _.filter(userDocuments, ({ node: { id } }) => _.indexOf(submittedDocIds, id) < 0);

        const optionFactory = new OptionFactory();
        const opts = userDocuments.map(({ node: { id, fileName, type } }) => {
            return optionFactory.create(id, `${types[type]} - ${fileName}`);
        });

        return (
            <Autocomplete
                suggestions={opts}
                placeholder="Or share document from your store"
                name="document"
                disabled={loading || opts.length < 1}
            />
        );
    }
}

UserStoreSelect.propTypes = {
    userDocuments: PropTypes.array.isRequired,
    types: PropTypes.object.isRequired,
    submittedDocuments: PropTypes.array,
    loading: PropTypes.bool,
};

export default UserStoreSelect;
