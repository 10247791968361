import { API_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const setLEChaseData = (uri = API_URL, loanEnquiryId, chaseDate, chaseNote) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation setChaseData($id: ID!, $chaseNote: String, $chaseDate: DateTime) {
            setChaseData(
                loanEnquiryId: $id
                chaseNote: $chaseNote
                chaseDate: $chaseDate
            ) {
                success
            }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            id: loanEnquiryId,
            chaseNote: chaseNote.length === 0 ? null : chaseNote,
            chaseDate: chaseDate,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
