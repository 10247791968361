import {
    ROUTE_P4A_2FA_INDEX,
    ROUTE_P4A_ADMINISTER_LENDER_BUSINESS_STREAMS,
    ROUTE_P4A_ADMINISTER_LENDER_CREATE_BUSINESS_STREAM,
    ROUTE_P4A_ADMINISTER_LENDER_EDIT_BUSINESS_STREAM,
    ROUTE_P4A_ADMINISTER_LENDERS_CREATE,
    ROUTE_P4A_ADMINISTER_LENDERS_EDIT,
    ROUTE_P4A_ADMINISTER_LENDERS_LIST,
    ROUTE_P4A_ADMINISTER_LOANENQUIRIES_IGNOREITEMS,
    ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW,
    ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW,
    ROUTE_P4A_ADMINISTER_USER_LENDERS_CREATE,
    ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST,
    ROUTE_P4A_ADMINISTER_USER_LENDERTEAMS_LIST,
    ROUTE_P4A_ADMINISTER_USERS_CREATE,
    ROUTE_P4A_ADMINISTER_USERS_EDIT,
    ROUTE_P4A_ADMINISTER_USERS_LIST,
    ROUTE_P4A_BORROWERS_DOCUMENTS_VIEW,
    ROUTE_P4A_BROKERS_LIST,
    ROUTE_P4A_CONFIGURE_PROFILE,
    ROUTE_P4A_LENDERS_DOCUMENTS_VIEW,
    ROUTE_P4A_LLE,
    ROUTE_P4A_NETWORK_BROKERS_LIST,
    ROUTE_P4A_ROOT,
    ROUTE_P4A_TAGS_LIST,
    ROUTE_P4A_VIEW_EVENTS,
} from '../../../pitch4_routing';

export default {
    [ROUTE_P4A_ROOT]: [{ path: ROUTE_P4A_ROOT, label: 'dashboard', link: false }],
    [ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW]: [
        { path: ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW, label: 'loan enquiries', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW]: [
        { path: ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW, label: 'loan enquiries', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW]: [
        { path: ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW, label: 'loan enquiries', link: true },
        { path: ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW, label: 'single loan enquiry', link: false },
    ],
    [ROUTE_P4A_LLE]: [
        { path: ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW, label: 'loan enquiries', link: true },
        { path: ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW, label: 'single loan enquiry', link: true },
        { path: ROUTE_P4A_LLE, label: 'loan match', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_LOANENQUIRIES_IGNOREITEMS]: [
        { path: ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW, label: 'loan enquiries', link: true },
        { path: ROUTE_P4A_ADMINISTER_LOANENQUIRIES_IGNOREITEMS, label: 'ignore enquiry', link: false },
    ],
    [ROUTE_P4A_CONFIGURE_PROFILE]: [{ path: ROUTE_P4A_CONFIGURE_PROFILE, label: 'account settings', link: false }],
    [ROUTE_P4A_2FA_INDEX]: [
        { path: ROUTE_P4A_CONFIGURE_PROFILE, label: 'account settings', link: true },
        { path: ROUTE_P4A_2FA_INDEX, label: 'two-factor authentication', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_LENDERS_LIST]: [{ path: ROUTE_P4A_ADMINISTER_LENDERS_LIST, label: 'Lenders', link: false }],
    [ROUTE_P4A_BROKERS_LIST]: [{ path: ROUTE_P4A_BROKERS_LIST, label: 'Brokers', link: false }],
    [ROUTE_P4A_NETWORK_BROKERS_LIST]: [
        { path: ROUTE_P4A_BROKERS_LIST, label: 'brokers', link: false },
        { path: ROUTE_P4A_NETWORK_BROKERS_LIST, label: 'network brokers', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_LENDERS_CREATE]: [
        { path: ROUTE_P4A_ADMINISTER_LENDERS_LIST, label: 'lenders', link: true },
        { path: ROUTE_P4A_ADMINISTER_LENDERS_CREATE, label: 'add lender', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_LENDERS_EDIT]: [
        { path: ROUTE_P4A_ADMINISTER_LENDERS_LIST, label: 'lenders', link: true },
        { path: ROUTE_P4A_ADMINISTER_LENDERS_EDIT, label: 'edit lender', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_LENDER_BUSINESS_STREAMS]: [
        { path: ROUTE_P4A_ADMINISTER_LENDERS_LIST, label: 'lenders', link: true },
        { path: ROUTE_P4A_ADMINISTER_LENDER_BUSINESS_STREAMS, label: 'business streams', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_LENDER_CREATE_BUSINESS_STREAM]: [
        { path: ROUTE_P4A_ADMINISTER_LENDERS_LIST, label: 'lenders', link: true },
        { path: ROUTE_P4A_ADMINISTER_LENDER_BUSINESS_STREAMS, label: 'business streams', link: true },
        { path: ROUTE_P4A_ADMINISTER_LENDER_CREATE_BUSINESS_STREAM, label: 'add lead stream', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_LENDER_EDIT_BUSINESS_STREAM]: [
        { path: ROUTE_P4A_ADMINISTER_LENDERS_LIST, label: 'lenders', link: true },
        { path: ROUTE_P4A_ADMINISTER_LENDER_BUSINESS_STREAMS, label: 'business streams', link: true },
        { path: ROUTE_P4A_ADMINISTER_LENDER_EDIT_BUSINESS_STREAM, label: 'edit lead stream', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_USERS_LIST]: [{ path: ROUTE_P4A_ADMINISTER_USERS_LIST, label: 'users', link: false }],
    [ROUTE_P4A_ADMINISTER_USERS_CREATE]: [
        { path: ROUTE_P4A_ADMINISTER_USERS_LIST, label: 'users', link: true },
        { path: ROUTE_P4A_ADMINISTER_USERS_CREATE, label: 'create user', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_USERS_EDIT]: [
        { path: ROUTE_P4A_ADMINISTER_USERS_LIST, label: 'users', link: true },
        { path: ROUTE_P4A_ADMINISTER_USERS_EDIT, label: 'edit user', link: false },
    ],
    [ROUTE_P4A_VIEW_EVENTS]: [{ path: ROUTE_P4A_VIEW_EVENTS, label: 'system events', link: false }],
    [ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST]: [
        { path: ROUTE_P4A_ADMINISTER_USERS_LIST, label: 'users', link: true },
        { path: ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST, label: 'associated lenders', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_USER_LENDERS_CREATE]: [
        { path: ROUTE_P4A_ADMINISTER_USERS_LIST, label: 'users', link: true },
        { path: ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST, label: 'associated lenders', link: true },
        { path: ROUTE_P4A_ADMINISTER_USER_LENDERS_CREATE, label: 'associate lender', link: false },
    ],
    [ROUTE_P4A_ADMINISTER_USER_LENDERTEAMS_LIST]: [
        { path: ROUTE_P4A_ADMINISTER_USERS_LIST, label: 'users', link: true },
        { path: ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST, label: 'associated lenders', link: true },
        { path: ROUTE_P4A_ADMINISTER_USER_LENDERTEAMS_LIST, label: 'lender teams', link: false },
    ],
    [ROUTE_P4A_LENDERS_DOCUMENTS_VIEW]: [
        { path: ROUTE_P4A_ADMINISTER_USERS_LIST, label: 'users', link: true },
        { path: ROUTE_P4A_LENDERS_DOCUMENTS_VIEW, label: 'lender documents', link: false },
    ],
    [ROUTE_P4A_BORROWERS_DOCUMENTS_VIEW]: [
        { path: ROUTE_P4A_ADMINISTER_USERS_LIST, label: 'users', link: true },
        { path: ROUTE_P4A_BORROWERS_DOCUMENTS_VIEW, label: 'borrower documents', link: false },
    ],
    [ROUTE_P4A_TAGS_LIST]: [{ path: ROUTE_P4A_TAGS_LIST, label: 'Tags', link: false }],
};
