import {
    CODE_ICON,
    DASHBOARD_ICON,
    INCOMING_ENQUIRIES_ICON,
    PEOPLE_ICON,
    SETTINGS_ICON,
} from '../../../pitchblack_react_utils/icons/IconFactory';

import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import {
    ROLE_BORROWER,
    ROLE_INTERMEDIARY,
    ROLE_SLAM,
    ROLE_SUPER_ADMIN,
} from '../../authentication/enum/Roles/NameEnum';
import {
    ROUTE_BORROWER_DOCUMENTS_VIEW,
    ROUTE_BORROWER_LOAN_ENQUIRIES,
    ROUTE_BORROWER_ROOT,
    ROUTE_INTERMEDIARY_APPLICANTS_VIEW,
    ROUTE_INTERMEDIARY_DOCUMENTS_VIEW,
    ROUTE_INTERMEDIARY_LOAN_ENQUIRIES,
    ROUTE_INTERMEDIARY_ROOT,
    ROUTE_P4A_ADMINISTER_LENDERS_LIST,
    ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW,
    ROUTE_P4A_ADMINISTER_USERS_LIST,
    ROUTE_P4A_ROOT,
    ROUTE_P4A_VIEW_EVENTS,
    ROUTE_SLAM_BUSINESS_STREAMS,
    ROUTE_SLAM_DOCUMENTS_VIEW,
    ROUTE_SLAM_LEADS_ACTIVE,
    ROUTE_SLAM_LEADS_INACTIVE,
    ROUTE_SLAM_ROOT,
    ROUTE_BORROWER_APPLICANTS_VIEW,
    ROUTE_P4A_BROKERS_LIST,
    ROUTE_INTERMEDIARY_NETWORK_BROKERS_VIEW,
    ROUTE_P4A_TAGS_LIST,
} from '../../../pitch4_routing';

// see https://github.com/erikras/ducks-modular-redux
export const REDUCER_NAMESPACE = 'nav.left.sidemenu';

// Actions
export const NAV_LEFT_SIDEMENU_ITEM_SELECTED = `${REDUCER_NAMESPACE}.selected`;
export const NAV_LEFT_SIDEMENU_OPEN = `${REDUCER_NAMESPACE}.open`;
export const NAV_LEFT_SIDEMENU_CLOSE = `${REDUCER_NAMESPACE}.close`;
export const NAV_LEFT_SIDEMENU_TOGGLE = `${REDUCER_NAMESPACE}.toggle`;

const menuItems = {
    [ROLE_SLAM]: [
        {
            id: 'dashboard',
            label: 'dashboard',
            url: ROUTE_SLAM_ROOT,
            requiredRoles: [ROLE_SLAM],
            icon: DASHBOARD_ICON,
        },
        {
            id: 'administer_criteria',
            label: 'business streams',
            url: ROUTE_SLAM_BUSINESS_STREAMS,
            requiredRoles: [ROLE_SLAM],
            icon: CODE_ICON,
        },
        {
            id: 'incoming-active-enquiries',
            label: 'active leads',
            url: ROUTE_SLAM_LEADS_ACTIVE,
            requiredRoles: [ROLE_SLAM],
            icon: INCOMING_ENQUIRIES_ICON,
            alertIndicator: true,
        },
        {
            id: 'incoming-inactive-enquiries',
            label: 'inactive leads',
            url: ROUTE_SLAM_LEADS_INACTIVE,
            requiredRoles: [ROLE_SLAM],
            icon: INCOMING_ENQUIRIES_ICON,
        },
        {
            id: 'administer_company_documents',
            label: 'company documents',
            url: ROUTE_SLAM_DOCUMENTS_VIEW,
            requiredRoles: [ROLE_SLAM],
            icon: DASHBOARD_ICON,
        },
    ],
    [ROLE_SUPER_ADMIN]: [
        {
            id: 'dashboard',
            label: 'dashboard',
            url: ROUTE_P4A_ROOT,
            requiredRoles: [ROLE_SUPER_ADMIN],
            icon: DASHBOARD_ICON,
        },
        {
            id: 'incoming-enquiries',
            label: 'enquiries',
            url: ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW,
            requiredRoles: [ROLE_SUPER_ADMIN],
            icon: INCOMING_ENQUIRIES_ICON,
        },
        {
            id: 'administer_users',
            label: 'users',
            url: ROUTE_P4A_ADMINISTER_USERS_LIST,
            requiredRoles: [ROLE_SUPER_ADMIN],
            icon: PEOPLE_ICON,
        },
        {
            id: 'administer_lenders',
            label: 'lenders',
            url: ROUTE_P4A_ADMINISTER_LENDERS_LIST,
            requiredRoles: [ROLE_SUPER_ADMIN],
            icon: PEOPLE_ICON,
        },
        {
            id: 'administer_brokers',
            label: 'brokers',
            url: ROUTE_P4A_BROKERS_LIST,
            requiredRoles: [ROLE_SUPER_ADMIN],
            icon: PEOPLE_ICON,
        },
        {
            id: 'administer_tags',
            label: 'tags',
            url: ROUTE_P4A_TAGS_LIST,
            requiredRoles: [ROLE_SUPER_ADMIN],
            icon: PEOPLE_ICON,
        },
        {
            id: 'view-events',
            label: 'system events',
            url: ROUTE_P4A_VIEW_EVENTS,
            requiredRoles: [ROLE_SUPER_ADMIN],
            icon: SETTINGS_ICON,
        },
    ],
    [ROLE_INTERMEDIARY]: [
        {
            id: 'intermediary-dashboard',
            label: 'dashboard',
            url: ROUTE_INTERMEDIARY_ROOT,
            requiredRoles: [ROLE_INTERMEDIARY],
            icon: DASHBOARD_ICON,
        },
        {
            id: 'intermediary-enquiries',
            label: 'enquiries',
            url: ROUTE_INTERMEDIARY_LOAN_ENQUIRIES,
            requiredRoles: [ROLE_INTERMEDIARY],
            icon: INCOMING_ENQUIRIES_ICON,
            alertIndicator: true,
        },
        {
            id: 'intermediary-applicants',
            label: 'applicants',
            url: ROUTE_INTERMEDIARY_APPLICANTS_VIEW,
            requiredRoles: [ROLE_INTERMEDIARY],
            icon: DASHBOARD_ICON,
        },
        {
            id: 'intermediary-network-brokers',
            label: 'network brokers',
            url: ROUTE_INTERMEDIARY_NETWORK_BROKERS_VIEW,
            requiredRoles: [ROLE_INTERMEDIARY],
            icon: DASHBOARD_ICON,
        },
        {
            id: 'intermediary-documents',
            label: 'documents',
            url: ROUTE_INTERMEDIARY_DOCUMENTS_VIEW,
            requiredRoles: [ROLE_INTERMEDIARY],
            icon: DASHBOARD_ICON,
        },
    ],
    [ROLE_BORROWER]: [
        {
            id: 'borrower-dashboard',
            label: 'dashboard',
            url: ROUTE_BORROWER_ROOT,
            requiredRoles: [ROLE_BORROWER],
            icon: DASHBOARD_ICON,
        },
        {
            id: 'borrower-loan-enquiries',
            label: 'enquiries',
            url: ROUTE_BORROWER_LOAN_ENQUIRIES,
            requiredRoles: [ROLE_BORROWER],
            icon: INCOMING_ENQUIRIES_ICON,
            alertIndicator: true,
        },
        {
            id: 'borrower-applicants',
            label: 'applicants',
            url: ROUTE_BORROWER_APPLICANTS_VIEW,
            requiredRoles: [ROLE_BORROWER],
            icon: DASHBOARD_ICON,
        },
        {
            id: 'borrower-documents',
            label: 'my documents',
            url: ROUTE_BORROWER_DOCUMENTS_VIEW,
            requiredRoles: [ROLE_BORROWER],
            icon: DASHBOARD_ICON,
        },
    ],
};

//Reducer
const initialState = {
    isLeftSideMenuOpen: false,
};

const reducer = (state = initialState, action) => {
    let open = state.isLeftSideMenuOpen;

    switch (action.type) {
        case NAV_LEFT_SIDEMENU_TOGGLE:
            return { ...state, isLeftSideMenuOpen: !open };
        case NAV_LEFT_SIDEMENU_OPEN:
            return { ...state, isLeftSideMenuOpen: true };
        case NAV_LEFT_SIDEMENU_CLOSE:
            return { ...state, isLeftSideMenuOpen: false };
        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;

//Action Creators
export const actionToggleLeftSideMenu = () => ({
    type: NAV_LEFT_SIDEMENU_TOGGLE,
});

export const actionOpenLeftSideMenu = () => ({
    type: NAV_LEFT_SIDEMENU_OPEN,
});

export const actionCloseLeftSideMenu = () => ({
    type: NAV_LEFT_SIDEMENU_CLOSE,
});

export const actionLeftSideMenuItemSelected = (url) => ({
    type: NAV_LEFT_SIDEMENU_ITEM_SELECTED,
    url: url,
});

//Selectors
export const selectorIsLeftSideMenuOpen = (state) => {
    return state[REDUCER_NAMESPACE].isLeftSideMenuOpen;
};

export const selectorLeftMenuItems = () => {
    let allItems = menuItems;

    let sessionManager = new SessionManager();

    if (!sessionManager.isAuthenticated()) {
        return [];
    }

    allItems = allItems[sessionManager.getRelevantSession().getMostSeniorRole().name].filter((item, index) => {
        return sessionManager.isAuthorised(item.requiredRoles);
    });

    return allItems;
};
