import React from 'react';
import { isEmpty } from 'pitch4_validation';
import { TextField } from 'pitch4_forms/components/inputs';

const currencyParser = (value) => {
    if (true === isEmpty(value)) {
        return '';
    }

    const cleansed = value.replace(/[^0-9]/g, '');

    if (true === isEmpty(cleansed)) {
        return '';
    }

    return parseInt(cleansed, 10) * 100;
};

class CurrencyField extends React.Component {
    render() {
        let formatter = new Intl.NumberFormat('en-UK', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
        });

        return (
            <TextField
                format={(value) => (false === isEmpty(value) ? formatter.format(value / 100) : '')}
                parse={currencyParser}
                placeholder={this.props.placeholder}
                value={this.props.value}
                {...this.props}
            />
        );
    }
}

export default CurrencyField;
