import React from 'react';
import {
    STATUS_COMPLETION_REJECTED,
    STATUS_WITHDRAWN_EDITED,
    STATUS_ENDED,
    STATUS_ON_HOLD,
} from '../../admin/enum/LenderLoanEnquiries/StatusEnum';
import BannerNoticeError from '../components/BannerNoticeError';
import PrimaryButton from '../../../pitch4_layout/components/buttons/PrimaryButton';
import { WEB_HOST } from '../../../bootstrap/constants/constants';
import BannerNoticeInfo from '../components/BannerNoticeInfo';
import { STATUS_DRAFT } from '../../borrower/enum/LoanEnquiries/StatusEnum';
import { STATUS_CLOSED } from '../../../pitch4_enum/enum/LoanEnquiryStatusEnum';

const AdminStatusBannerNotice = ({ status, onClick, isAdmin = false, adminOverrideReason = '', isLender = false }) => {
    switch (status) {
        case STATUS_DRAFT:
            return (
                <BannerNoticeInfo
                    message={
                        <>
                            <span>This is a Draft Loan Enquiry</span>
                            <PrimaryButton onClick={onClick}>Edit this enquiry</PrimaryButton>
                        </>
                    }
                />
            );
        case STATUS_COMPLETION_REJECTED:
            return (
                <BannerNoticeError
                    message={
                        <>
                            {isLender && <span>This completion request has been rejected.</span>}
                            {!isLender && (
                                <>
                                    <span>This completion request has been marked as rejected.</span>
                                    <PrimaryButton onClick={onClick}>ADMINISTER</PrimaryButton>
                                </>
                            )}
                        </>
                    }
                />
            );
        case STATUS_WITHDRAWN_EDITED:
        case STATUS_CLOSED:
        case STATUS_ENDED:
            return (
                <BannerNoticeError
                    message={
                        <>
                            {isLender && <strong>This Lead was closed and resubmitted as a new version.</strong>}
                            {!isLender && <span>This enquiry was closed and resubmitted as a new version.</span>}
                        </>
                    }
                />
            );
        case STATUS_ON_HOLD:
            return (
                <BannerNoticeError
                    message={
                        <>
                            <span>
                                {isLender && <strong>This Lead has been put on hold by an Administrator.</strong>}
                                {!isLender && (
                                    <strong>This Enquiry has been put on hold by an Admin due to inactivity</strong>
                                )}
                                {!isAdmin && !isLender && (
                                    <div>
                                        Please{' '}
                                        <a href={`${WEB_HOST}/support/`} target={'_blank'} rel={'noreferrer'}>
                                            contact support
                                        </a>{' '}
                                        if you wish to re-instate this funding request.
                                    </div>
                                )}
                                {isAdmin && <div>The reason given for this: {adminOverrideReason}</div>}
                            </span>
                        </>
                    }
                />
            );
        default:
            return null;
    }
};

AdminStatusBannerNotice.propTypes = {};

export default AdminStatusBannerNotice;
