import React from 'react';
import { Container, Item } from 'pitch4_layout/components/div';
import PropTypes from 'prop-types';

class SimpleCommandContainer extends React.Component {
    render() {
        let { children } = this.props;
        return (
            <Container className={'simple-command-container'} justifyContent={`flex-end`}>
                {React.Children.map(children, (item, index) => (
                    <Item key={index}>{item}</Item>
                ))}
            </Container>
        );
    }
}

SimpleCommandContainer.propTypes = {
    children: PropTypes.any,
};

export default SimpleCommandContainer;
