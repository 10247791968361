import { BaseEnum } from 'pitch4_enum';

export const TYPE_PROPERTY = 'TYPE_PROPERTY';
export const TYPE_BUSINESS_FINANCE = 'TYPE_BUSINESS_FINANCE';
export const TYPE_PORTFOLIO = 'TYPE_PORTFOLIO';
export const TYPE_RESIDENTIAL_BRIDGE = 'TYPE_RESIDENTIAL_BRIDGE';
export const TYPE_RESIDENTIAL_DEVELOPMENT = 'TYPE_RESIDENTIAL_DEVELOPMENT';
export const TYPE_RESIDENTIAL_REFURBISHMENT = 'TYPE_RESIDENTIAL_REFURBISHMENT';
export const TYPE_RESIDENTIAL_SECOND_CHARGE = 'TYPE_RESIDENTIAL_SECOND_CHARGE';
export const TYPE_RESIDENTIAL_MEZZANINE = 'TYPE_RESIDENTIAL_MEZZANINE';
export const TYPE_RESIDENTIAL_TERM = 'TYPE_RESIDENTIAL_TERM';
export const TYPE_COMMERCIAL_BRIDGE = 'TYPE_COMMERCIAL_BRIDGE';
export const TYPE_COMMERCIAL_DEVELOPMENT = 'TYPE_COMMERCIAL_DEVELOPMENT';
export const TYPE_COMMERCIAL_REFURBISHMENT = 'TYPE_COMMERCIAL_REFURBISHMENT';
export const TYPE_COMMERCIAL_SECOND_CHARGE = 'TYPE_COMMERCIAL_SECOND_CHARGE';
export const TYPE_COMMERCIAL_MEZZANINE = 'TYPE_COMMERCIAL_MEZZANINE';
export const TYPE_COMMERCIAL_TERM = 'TYPE_COMMERCIAL_TERM';
export const TYPE_SEMI_COMMERCIAL_BRIDGE = 'TYPE_SEMI_COMMERCIAL_BRIDGE';
export const TYPE_SEMI_COMMERCIAL_DEVELOPMENT = 'TYPE_SEMI_COMMERCIAL_DEVELOPMENT';
export const TYPE_SEMI_COMMERCIAL_REFURBISHMENT = 'TYPE_SEMI_COMMERCIAL_REFURBISHMENT';
export const TYPE_SEMI_COMMERCIAL_SECOND_CHARGE = 'TYPE_SEMI_COMMERCIAL_SECOND_CHARGE';
export const TYPE_SEMI_COMMERCIAL_MEZZANINE = 'TYPE_SEMI_COMMERCIAL_MEZZANINE';
export const TYPE_SEMI_COMMERCIAL_TERM = 'TYPE_SEMI_COMMERCIAL_TERM';
export const TYPE_HMO_BRIDGE = 'TYPE_HMO_BRIDGE';
export const TYPE_HMO_TERM = 'TYPE_HMO_TERM';
export const TYPE_LAND_WITHOUT_PLANNING = 'TYPE_LAND_WITHOUT_PLANNING';
export const TYPE_BUSINESS_FINANCE_RCF = 'TYPE_BUSINESS_FINANCE_RCF';
export const TYPE_BUSINESS_FINANCE_ASSET = 'TYPE_BUSINESS_FINANCE_ASSET';
export const TYPE_BUSINESS_FINANCE_MERCHANT = 'TYPE_BUSINESS_FINANCE_MERCHANT';
export const TYPE_BUSINESS_FINANCE_INVOICE = 'TYPE_BUSINESS_FINANCE_INVOICE';
export const TYPE_BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE_FINANCE = 'TYPE_BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE_FINANCE';
export const TYPE_BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE = 'TYPE_BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE';
export const TYPE_BUSINESS_FINANCE_SECURED = 'TYPE_BUSINESS_FINANCE_SECURED';
export const BUSINESS_FINANCE_SECURED = 'business_finance_secured';
export const TYPE_BUSINESS_FINANCE_UNSECURED = 'TYPE_BUSINESS_FINANCE_UNSECURED';
export const BUSINESS_FINANCE_UNSECURED = 'business_finance_unsecured';

class BusinessStreamTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_PROPERTY]: 'Property Finance',
            [TYPE_BUSINESS_FINANCE]: 'Business Finance',
            [TYPE_PORTFOLIO]: 'Portfolio Finance',
            [TYPE_RESIDENTIAL_BRIDGE]: 'Residential Bridge',
            [TYPE_RESIDENTIAL_DEVELOPMENT]: 'Residential Development',
            [TYPE_RESIDENTIAL_REFURBISHMENT]: 'Residential Refurbishment',
            [TYPE_RESIDENTIAL_SECOND_CHARGE]: 'Residential Second Charge',
            [TYPE_RESIDENTIAL_MEZZANINE]: 'Residential Mezzanine',
            [TYPE_RESIDENTIAL_TERM]: 'Residential Term',
            [TYPE_COMMERCIAL_BRIDGE]: 'Commercial Bridge',
            [TYPE_COMMERCIAL_DEVELOPMENT]: 'Commercial Development',
            [TYPE_COMMERCIAL_REFURBISHMENT]: 'Commercial Refurbishment',
            [TYPE_COMMERCIAL_SECOND_CHARGE]: 'Commercial Second Charge',
            [TYPE_COMMERCIAL_MEZZANINE]: 'Commercial Mezzanine',
            [TYPE_COMMERCIAL_TERM]: 'Commercial Term',
            [TYPE_SEMI_COMMERCIAL_BRIDGE]: 'Semi Commercial Bridge',
            [TYPE_SEMI_COMMERCIAL_DEVELOPMENT]: 'Semi Commercial Development',
            [TYPE_SEMI_COMMERCIAL_REFURBISHMENT]: 'Semi Commercial Refurbishment',
            [TYPE_SEMI_COMMERCIAL_SECOND_CHARGE]: 'Semi Commercial Second Charge',
            [TYPE_SEMI_COMMERCIAL_MEZZANINE]: 'Semi Commercial Mezzanine',
            [TYPE_SEMI_COMMERCIAL_TERM]: 'Semi Commercial Term',
            [TYPE_HMO_BRIDGE]: 'HMO Bridge',
            [TYPE_HMO_TERM]: 'HMO Term',
            [TYPE_LAND_WITHOUT_PLANNING]: 'Land Without Planning Bridge',
            [TYPE_BUSINESS_FINANCE_RCF]: 'Business RCF/Long Term/Short Term Finance',
            [TYPE_BUSINESS_FINANCE_ASSET]: 'Business Asset Finance',
            [TYPE_BUSINESS_FINANCE_MERCHANT]: 'Business Merchant Cash Advance',
            [TYPE_BUSINESS_FINANCE_INVOICE]: 'Business Invoice Finance',
            [TYPE_BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE_FINANCE]: 'Business Supply Chain/Trade Finance',
            [TYPE_BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE]: 'Business Supply Chain/Trade Finance',
            [TYPE_BUSINESS_FINANCE_SECURED]: 'Business Secured RCF/Long Term/Short Term Finance',
            [BUSINESS_FINANCE_SECURED]: 'Business Secured RCF/Long Term/Short Term Finance',
            [TYPE_BUSINESS_FINANCE_UNSECURED]: 'Business Unsecured Loans; Short term/Long Term/RCF',
            [BUSINESS_FINANCE_UNSECURED]: 'Business Unsecured Loans; Short term/Long Term/RCF',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default BusinessStreamTypeEnum;
