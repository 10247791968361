import CreateDocumentManager from './CreateDocumentManager';
import SessionManager from 'pitchblack_react_utils/session/SessionManager';

class CreateDocumentManagerFactory {
    create(createDocumentMetaMutation) {
        return new CreateDocumentManager(createDocumentMetaMutation, new SessionManager());
    }
}

export default CreateDocumentManagerFactory;
