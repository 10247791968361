import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { push } from 'react-router-redux';
import { RollupCommandContainer } from '../../../../../pitch4_tabular/components';
import { PrimaryButton, PrimaryRedirectButton } from '../../../../../pitch4_layout/components/buttons';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import StatusEnum, { STATUS_ENABLED, STATUS_DISABLED } from '../../../../../pitch4_enum/enum/BrokerStatusEnum';
import SetStatusButton from '../components/SetStatusButton';
import ModalLayout from '../../../../../pitch4_modal/src/component/ModalLayout';
import CreateContainer from './CreateContainer';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import EditContainer from './EditContainer';
import _ from 'lodash';
import { Button, FormControlLabel, Switch } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import { ROWS_PER_PAGE, PAGE_SIZE } from '../../../../../pitch4_enum/enum/DataGridEnum';
import HeaderWithCount from '../../../../../pitch4_elements/screen-headers/HeaderWithCount';
import Toolbar from '../components/Toolbar';
import { setBrokerExtraNotifications } from '../../../../../pitch4_api/set_broker_extra-notifications';
import { getBrokers } from '../../../../../pitch4_api/get_brokers';
import { path, ROUTE_P4A_NETWORK_BROKERS_LIST } from '../../../../../pitch4_routing';
import { setBrokerAsPartner } from '../../../../../pitch4_api/set_broker_as_partner';
import { DEFAULT_SEARCH_FILTER_DELAY } from '../../../../../pitch4_utils/FiltersDefaults';

const statusEnum = new StatusEnum();

class TableStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFetchError: null,
            searchFilter: '',
            dataGrid: {
                rows: [],
                totalRowCount: 0,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: [{ column: 'createdAt', direction: 'desc' }],
            },
            dataRequest: {
                filters: [],
                sorts: [
                    {
                        column: 'createdAt',
                        direction: 'desc',
                    },
                ],
                pages: [],
                cursor: null,
                storedDataFilters: [],
            },
        };
    }

    componentDidMount() {
        const data = this.getData();
        this.processData(data);
    }

    refreshData = () => {
        this.setState(
            {
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages: [], cursor: null },
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    processData(data) {
        const { currentPage } = this.state.dataGrid;
        const { cursor } = this.state.dataRequest;

        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            const brokers = data.brokers.edges.map((user) => user.node);
            const rows = brokers.map((broker, index) => {
                return {
                    id: broker.id,
                    name: broker.name,
                    hasDataProtectionLicence: broker.hasDataProtectionLicence,
                    isNetwork: broker.isNetwork ?? false,
                    parentBroker: broker.parentBroker ?? null,
                    primaryEmail: broker.primaryEmail,
                    fcaIdentifier: broker.fcaIdentifier,
                    companyNumber: broker.companyNumber,
                    primaryAddressAddress1: broker.primaryAddress.address1,
                    primaryAddressAddress2: broker.primaryAddress.address2,
                    primaryAddressAddress3: broker.primaryAddress.address3,
                    primaryAddressAddress4: broker.primaryAddress.address4,
                    primaryAddressCountry: broker.primaryAddress.country,
                    primaryAddressPostcode: broker.primaryAddress.postcode,
                    primaryPhoneNumber: broker.primaryPhone.number,
                    status: broker.status,
                    createdAt: broker.createdAt,
                    realTimeNotifications: broker.realTimeNotifications,
                    partnerBroker: broker.isPartner ?? false,
                };
            });

            let pages = this.state.dataRequest.pages;
            pages[currentPage] = {
                cursor: cursor,
                hasNextPage: data.brokers.pageInfo.hasNextPage,
                endCursor: data.brokers.pageInfo.endCursor,
                startCursor: data.brokers.pageInfo.startCursor,
                hasPreviousPage: data.brokers.pageInfo.hasPreviousPage,
            };

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: rows,
                    totalRowCount: data.brokers.totalCount,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages, cursor: data.brokers.pageInfo.endCursor },
                },
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    getData() {
        return getBrokers(API_URL)(
            this.state.dataRequest.cursor,
            this.state.dataRequest.filters,
            this.state.dataGrid.pageSize,
            this.state.dataRequest.sorts,
            this.state.dataRequest.storedDataFilters
        );
    }

    handlePageChange = (pageConfig) => {
        const previousPage = this.state.dataGrid.currentPage;
        const newPage = pageConfig;
        const { pages } = this.state.dataRequest;

        if (newPage > previousPage) {
            // Next page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[previousPage].endCursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        } else {
            // Previous page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[newPage].cursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handlePageSizeChange = (pageConfig) => {
        this.setState(
            {
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: true,
                    pageSize: pageConfig,
                    rows: [],
                    currentPage: 0,
                    totalRowCount: 0,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    pages: [],
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    buildSearchRequestFilters = () => {
        const { searchFilter } = this.state;
        let searchRequestFilter = [];
        const searchableFields = ['name'];

        if (searchFilter.length > 0) {
            searchRequestFilter = searchableFields.map((field) => {
                return {
                    operation: 'ilike',
                    operand: '%' + searchFilter + '%',
                    column: field,
                    not: false,
                    logicOperator: 'or',
                };
            });
        }

        return searchRequestFilter;
    };

    handleSearchFilterChange = (event) => {
        this.setState(
            {
                searchFilter: event.target.value,
            },
            () => {
                this.setState({
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: [...this.buildSearchRequestFilters()],
                    },
                });
            }
        );

        _.delay(
            (value) => {
                if (this.state.searchFilter === value) {
                    const data = this.getData();
                    this.processData(data);
                }
            },
            DEFAULT_SEARCH_FILTER_DELAY,
            event.target.value
        );
    };

    handleClearAllFilters = () => {
        this.setState(
            {
                searchFilter: '',
                dataGrid: { ...this.state.dataGrid, rows: [] },
                dataRequest: { ...this.state.dataRequest, filters: [], cursor: null },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    handleSortModelChange = (params) => {
        const { sortModel } = this.state.dataGrid;
        const sortableFieldsMapping = {
            name: 'name',
            createdAt: 'createdAt',
            status: 'status',
        };

        if (params.sortModel !== null && typeof params.sortModel !== 'undefined' && params.sortModel !== sortModel) {
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        sortModel: params.sortModel,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        sorts: [
                            {
                                column: sortableFieldsMapping[params.sortModel[0].field],
                                direction: params.sortModel[0].sort,
                            },
                        ],
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    createBroker = () => () => {
        const { setModalInteractive, setModalComponent, setModalClose } = this.props;

        setModalComponent(
            <ModalLayout title="Create Broker">
                <CreateContainer
                    inProgress={() => setModalInteractive(true)}
                    onSuccess={(data) => {
                        this.refreshData();
                        setModalInteractive(false);
                        setModalClose();
                    }}
                    onFailure={() => {
                        this.refreshData();
                        setModalInteractive(true);
                    }}
                />
            </ModalLayout>
        );
    };

    findBroker = (id) => this.state.dataGrid.rows.find((row) => row.id === id);

    editBroker = (id) => () => {
        const { setModalInteractive, setModalComponent, setModalClose } = this.props;

        setModalComponent(
            <ModalLayout title="Edit Broker">
                <EditContainer
                    inProgress={() => setModalInteractive(true)}
                    onSuccess={(data) => {
                        this.refreshData();
                        setModalInteractive(false);
                        setModalClose();
                    }}
                    onFailure={() => {
                        this.refreshData();
                        setModalInteractive(true);
                    }}
                    broker={this.findBroker(id)}
                />
            </ModalLayout>
        );
    };

    handleExtraNotifications = (broker) =>
        setBrokerExtraNotifications(API_URL, broker.id, !broker.realTimeNotifications).then((resp) => {
            const rows = this.state.dataGrid.rows;
            let brokers = rows.find((brokers) => brokers.id === broker.id);
            brokers.realTimeNotifications = !broker.realTimeNotifications;

            const newRows = rows.map((item) => (item.id !== brokers.id ? item : brokers));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
        });

    handlePartnerBroker = (broker) =>
        setBrokerAsPartner(API_URL, broker.id, !broker.partnerBroker).then((resp) => {
            const rows = this.state.dataGrid.rows;
            let brokers = rows.find((brokers) => brokers.id === broker.id);
            brokers.partnerBroker = !broker.partnerBroker;

            const newRows = rows.map((item) => (item.id !== brokers.id ? item : brokers));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
        });

    render() {
        const { sortingModel, totalRowCount, dataLoading } = this.state.dataGrid;
        const { searchFilter } = this.state;

        return (
            <div>
                <div className={'le-header-container'}>
                    <div className={'header-text'}>
                        <HeaderWithCount title={'Brokers'} count={totalRowCount} countText={'loan brokers'} />
                        <div className={'btn-mobile'}>
                            <Button
                                className={'btn-create'}
                                variant="contained"
                                color="primary"
                                size={'large'}
                                style={{
                                    marginLeft: 20,
                                    height: 45,
                                    paddingTop: 11,
                                    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                                }}
                                onClick={this.createBroker()}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                    <Toolbar
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                        handleClearAllFilters={this.handleClearAllFilters}
                        createBroker={this.createBroker}
                    />
                </div>

                <div className={`le-table-container`}>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortingModel}
                        paginationMode="server"
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        sortingMode="server"
                        onSortModelChange={this.handleSortModelChange}
                        rowCount={this.state.dataGrid.totalRowCount}
                        pageSize={this.state.dataGrid.pageSize}
                        rowsPerPageOptions={this.state.dataGrid.rowsPerPage}
                        rows={this.state.dataGrid.rows}
                        loading={true}
                        disableColumnFilter={true}
                        disableMultipleColumnsSorting={true}
                        disableColumnReorder={true}
                        disableMultipleColumnsFiltering={true}
                        disableSelectionOnClick={true}
                        disableColumnMenu={true}
                        components={{
                            LoadingOverlay: () => {
                                return (
                                    <GridOverlay>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {this.state.dataGrid.dataLoading ? (
                                                <LinearProgress />
                                            ) : this.state.dataGrid.rows.length === 0 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        width: '100%',
                                                        paddingTop: 100,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    No data
                                                </div>
                                            ) : null}
                                        </div>
                                    </GridOverlay>
                                );
                            },
                        }}
                        columns={[
                            {
                                field: 'name',
                                headerName: 'NAME',
                                width: 300,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'hasDataProtectionLicence',
                                headerName: 'DATA PROTECTION LICENCE',
                                width: 220,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                valueFormatter: ({ value }) => ('true' === value ? 'Yes' : 'No'),
                                sortable: false,
                            },
                            {
                                field: 'realTimeNotifications',
                                headerName: 'EXTRA NOTIFICATIONS',
                                width: 200,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={params.row.realTimeNotifications}
                                                        onChange={(event) => this.handleExtraNotifications(params.row)}
                                                        color="primary"
                                                        name="realTimeNotifications"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={false}
                                                        id={params.row.id}
                                                    />
                                                }
                                                label={''}
                                            />
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'isNetwork',
                                headerName: 'IS NETWORK BROKER',
                                width: 220,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) =>
                                    true === params.row.isNetwork ? (
                                        <strong>
                                            <span style={{ color: '#2ec4b6' }}> Yes </span>
                                        </strong>
                                    ) : (
                                        <span style={{ color: '#c7c7c7' }}>No</span>
                                    ),
                                sortable: false,
                            },
                            {
                                field: 'parentBroker',
                                headerName: 'PARENT BROKER',
                                width: 220,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                valueFormatter: ({ value }) => (null !== value ? value.name : '-'),
                                sortable: false,
                            },
                            {
                                field: 'partnerBroker',
                                headerName: 'PARTNER BROKER',
                                width: 200,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={params.row.partnerBroker}
                                                        onChange={(event) => this.handlePartnerBroker(params.row)}
                                                        color="primary"
                                                        name="partnerBroker"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={false}
                                                        id={params.row.id}
                                                    />
                                                }
                                                label={''}
                                            />
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'createdAt',
                                headerName: 'CREATED',
                                width: 170,
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'status',
                                headerName: 'STATUS',
                                width: 100,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                valueFormatter: ({ value }) => statusEnum.getType(value),
                            },
                            {
                                field: 'actions',
                                cellClassName: 'le-table-cell le-table-cell-content-right',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                renderHeader: () => <div />,
                                renderCell: (params) => {
                                    return (
                                        <div className={'le-table-cell-context-menu'} style={{ marginTop: 10 }}>
                                            <RollupCommandContainer>
                                                <PrimaryButton onClick={this.editBroker(params.row.id)}>
                                                    Edit
                                                </PrimaryButton>
                                                {params.row.status === STATUS_ENABLED ? (
                                                    <SetStatusButton
                                                        outerRefetch={this.refreshData}
                                                        id={params.row.id}
                                                        useStatus={STATUS_DISABLED}
                                                    >
                                                        Disable
                                                    </SetStatusButton>
                                                ) : (
                                                    <SetStatusButton
                                                        outerRefetch={this.refreshData}
                                                        id={params.row.id}
                                                        useStatus={STATUS_ENABLED}
                                                    >
                                                        Enable
                                                    </SetStatusButton>
                                                )}
                                                {true === params.row.isNetwork && (
                                                    <PrimaryRedirectButton
                                                        url={path(ROUTE_P4A_NETWORK_BROKERS_LIST, {
                                                            networkId: params.row.id,
                                                        })}
                                                    >
                                                        Network Brokers
                                                    </PrimaryRedirectButton>
                                                )}
                                            </RollupCommandContainer>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => () => dispatch(push(url)),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(TableStyleContainer);
