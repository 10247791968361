export const REDUCER_NAMESPACE = 'chat.messages';

export const ADD_MESSAGE = `${REDUCER_NAMESPACE}.ADD_MESSAGE`;
export const ADD_MESSAGES = `${REDUCER_NAMESPACE}.ADD_MESSAGES`;
export const SORT_MESSAGES = `${REDUCER_NAMESPACE}.SORT_MESSAGES`;
export const CLEAR_MESSAGES = `${REDUCER_NAMESPACE}.CLEAR_MESSAGES`;

const initialState = {
    messagesById: {},
    messages: [],
};

const reducer = (state = initialState, action) => {
    let messages = [...state.messages];
    let messagesById = { ...state.messagesById };

    switch (action.type) {
        case ADD_MESSAGES:
            let newMessages = action.messages;
            newMessages.forEach((newMessage) => {
                if (newMessage.getId() in messagesById) {
                    return { ...state };
                }

                messages.push(newMessage);
                messagesById[newMessage.getId()] = newMessage;
            });
            return { ...state, messages: messages, messagesById: messagesById };

        case ADD_MESSAGE:
            let message = null;
            message = action.message;

            if (message.getId() in messagesById) {
                return { ...state };
            }

            messages.push(message);
            messagesById[message.getId()] = message;

            return { ...state, messages: messages, messagesById: messagesById };

        case SORT_MESSAGES:
            let messagesTemp = state.messages;
            messagesTemp.sort((a, b) => {
                if (a.getTimestamp() < b.getTimestamp()) {
                    return -1;
                } else if (a.getTimestamp() > b.getTimestamp()) {
                    return 1;
                }

                return 0;
            });

            return {
                ...state,
                messages: [...messagesTemp],
            };

        case CLEAR_MESSAGES:
            return { ...state, messages: [], messagesById: {} };

        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;

//Action Creators
export const actionAddMessage = (message) => ({
    type: ADD_MESSAGE,
    message: message, //Message object
});

export const actionAddMessages = (messages) => ({
    type: ADD_MESSAGES,
    messages: messages, //Message object
});

export const actionSortMessages = () => ({
    type: SORT_MESSAGES,
});

export const actionClearMessages = () => ({
    type: CLEAR_MESSAGES,
});

export const selectorGetMessages = (state) => {
    return state[REDUCER_NAMESPACE].messages;
};
