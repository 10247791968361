import { API_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const query = `
    query generateConsentForms($id: ID!) {
        generateConsentForms(id: $id) {
            id
            fileName
            type
            uploaderRole
            downloadUrl
            createdAt
            visibleByBorrower
        }
    }
`;

export const generateCreditConsentForms = (loanEnquiryId) => {
    return apolloFetch({
        query: query,
        variables: {
            id: loanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
