import React from 'react';
import PropTypes from 'prop-types';
import { FormControl } from '@material-ui/core';
import { ILIKE } from '../enum/OperationEnum';
import { Select } from '../../pitch4_forms/components/inputs';

class EnumFilter extends React.Component {
    handleFiltersOnChange = (onFilterChange) => (event, value) => {
        event.preventDefault();

        let { filterValues } = this.props;
        let { operand, operation, column, not } = filterValues;
        let newFilterOperand = value;

        if (operand === newFilterOperand) {
            return;
        }

        onFilterChange({ operand: newFilterOperand, operation, column, not });
    };

    render() {
        let { onFilterChange, filterValues, enumIn, label, style = {}, name = '' } = this.props;

        if (
            filterValues.operation === ILIKE &&
            filterValues.operand.match(/^[%]/) &&
            filterValues.operand.match(/[%]$/)
        ) {
            filterValues.operand = filterValues.operand.replace(/[%]/g, '');
        }

        const selectOptions = Object.entries(enumIn.getTypes()).map(([key, value]) => ({ value: key, label: value }));

        return (
            <FormControl className={'filter'} style={style}>
                <Select
                    displayEmpty={true}
                    value={filterValues.operand}
                    onChange={this.handleFiltersOnChange(onFilterChange)}
                    placeholder={label}
                    options={selectOptions}
                    name={name}
                />
            </FormControl>
        );
    }
}

EnumFilter.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    filterValues: PropTypes.object.isRequired,
    enumIn: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default EnumFilter;
