import createP4ApolloFetch from 'bootstrap/middlewares';
import { ANON_URL } from 'bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: ANON_URL });

const query = `
    query termsVersionLatest {
        termsVersionLatest {
          id,
          version
        }
    }
`;

export const apiGetLatestTerms = () => {
    return apolloFetch({
        query: query,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};
