import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const LoanEnquirySetStatusesMutation = `
  mutation updateBorrowerWaiting($lenderLoanEnquiry: ID!, $borrowerWaiting: DateTime) {
      setBorrowerWaiting(lenderLoanEnquiry: $lenderLoanEnquiry, borrowerWaiting: $borrowerWaiting) {
        success
      }
    }
`;

export const updateBorrowerWaiting = (lenderLoanEnquiry, borrowerWaiting) => {
    return apolloFetch({
        query: LoanEnquirySetStatusesMutation,
        variables: {
            lenderLoanEnquiry: lenderLoanEnquiry,
            borrowerWaiting: borrowerWaiting,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
