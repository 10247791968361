import React from 'react';
import _ from 'lodash';
import { Container, Item } from '../../../../pitch4_layout/components/div';
import NoMutationQuery from '../../../../pitch4_forms/components/NoMutationQuery';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ROLE_BORROWER } from '../../../authentication/enum/Roles/NameEnum';
import { goBack, push } from 'react-router-redux';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { Subtitle1 } from '../../../../pitch4_layout/components/text';
import { P4StyleComponentContainer } from '../../../../pitch4_layout/components/div';
import { ScreenTitle } from '../../../../pitch4_layout';
import gql from 'graphql-tag';
import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import { Body1 } from '../../../../pitch4_layout/components/text';
import { DateFormatter } from '../../../../pitch4_layout/components/data';
import { KeyValueTable, KeyValueTableRow } from '../../../../pitch4_tabular/components/kvtable/index';
import ChatContainer from '../../chat/containers/ChatContainer';
import DocumentList from '../components/DocumentList';
import { Loading } from '../../../../pitch4_layout/components/waiting';
import FactSummaryTable from '../../../view_loan_enquiry/components/FactSummaryTable';
import {
    actionGetMatchingCriteriaConfig,
    actionUpdateLoanEnquiryMatchesSuccess,
    selectorGetMatchingCriteriaConfig,
    actionRemoveLenderLoanEnquiryAlert,
} from '../redux/main';
import { ExpandedDateTimeLayout } from '../../../../pitch4_layout/components/content';
import {
    STATUS_COMPLETION_REQUESTED,
    STATUS_COMPLETION_REJECTED,
    STATUS_COMPLETE,
    STATUS_ENDED,
} from '../../enum/LenderLoanEnquiries/StatusEnum';
import {
    STATUS_WITHDRAWN as LE_STATUS_WITHDRAWN,
    STATUS_ON_HOLD as LE_STATUS_ON_HOLD,
} from '../../enum/LoanEnquiries/StatusEnum';
import Notice, { NOTICE_TYPE_ERROR } from '../../../../pitch4_layout/components/content/Notice';
import BorStatusBannerNotice from '../../../bannernotice/containers/BorStatusBannerNotice';
import CompletionRequestedRespondForm from '../../view_loan_enquiry/components/CompletionRequestedForm';
import CompletionRejectedForm from '../../view_loan_enquiry/components/CompletionRejectedForm';
import {
    actionApiSetLeadStatus,
    actionCleanup,
    actionClearStatus,
    selectorGetStatus,
    selectorGetSubmitted,
} from '../../view_loan_enquiry/redux';
import { sagaActionModalHide, sagaActionModalInteractive, sagaActionModalShow } from '../../../modal/sagas/container';
import { path, ROUTE_BORROWER_LENDER_LOAN_ENQUIRY_VIEW } from '../../../../pitch4_routing';
import withSession from '../../../../pitchblack_react_utils/session/withSession';
import AdminStatusBannerNotice from '../../../bannernotice/containers/AdminStatusBannerNotice';
import LenderLoanEnquiryStatusWidget from '../components/LenderLoanEnquiryStatusWidget';
import { BORROWER_LENDER_LOAN_ENQUIRY } from '../../../joyride/config';
import { actionSetJoyrideType } from '../../../joyride/redux';

class MasonryStyleContainer extends React.Component {
    componentDidMount() {
        const { getConfig, setJoyrideType } = this.props;
        getConfig();
        setJoyrideType(BORROWER_LENDER_LOAN_ENQUIRY);
    }

    getMainApplicantName = (loanEnquiryApplicants) => {
        let applicantName = '';
        loanEnquiryApplicants.forEach((loanEnquiryApplicant) => {
            if (loanEnquiryApplicant.isMainApplicant) {
                applicantName = loanEnquiryApplicant.applicant.given + ' ' + loanEnquiryApplicant.applicant.family;
            }
        });
        return applicantName;
    };

    handleSubmitCompletionConfirm = (leadId) => () => {
        const { updateStatusOnChange } = this.props;
        updateStatusOnChange(leadId, STATUS_COMPLETE);
    };

    handleSubmitCompletionReject = (leadId) => ({ rejectReason }) => {
        const { updateStatusOnChange } = this.props;
        updateStatusOnChange(leadId, STATUS_COMPLETION_REJECTED, rejectReason);
    };

    handleCompletionRequestedRespond = (lenderLoanEnquiryId, lenderName, loanEnquiryId) => {
        const { setModalComponent, setModalClose, submitted } = this.props;
        if (null !== lenderName && null !== lenderLoanEnquiryId && !submitted) {
            setModalComponent(
                <CompletionRequestedRespondForm
                    lenderName={lenderName}
                    lenderLink={path(ROUTE_BORROWER_LENDER_LOAN_ENQUIRY_VIEW, {
                        loanEnquiryId: loanEnquiryId,
                        lenderLoanEnquiryId: lenderLoanEnquiryId,
                    })}
                    onSubmit={() => {}}
                    handleClose={setModalClose}
                    handleConfirm={this.handleSubmitCompletionConfirm(lenderLoanEnquiryId)}
                    handleReject={() => this.handleCompletionRejectedForm(lenderLoanEnquiryId)}
                />,
                true
            );
        }
    };

    handleCompletionRejectedForm = (lenderLoanEnquiryId) => {
        const { setModalComponent, setModalClose } = this.props;
        if (null !== lenderLoanEnquiryId) {
            setModalComponent(
                <CompletionRejectedForm
                    handleClose={setModalClose}
                    onSubmit={this.handleSubmitCompletionReject(lenderLoanEnquiryId)}
                />,
                true
            );
        }
    };

    render() {
        let { match, currentStatus, config = {}, updateFavourite, session, removeLenderLoanEnquiryAlert } = this.props;

        return (
            <NoMutationQuery
                queryContext={{
                    uri: BORROWER_URL,
                }}
                queryVariables={{
                    lenderLoanEnquiry: match.params.lenderLoanEnquiryId,
                }}
                query={gql`
                    query getLenderLoanEnquiry($lenderLoanEnquiry: ID!) {
                        lenderLoanEnquiry(id: $lenderLoanEnquiry) {
                            id
                            status
                            lenderReference
                            alertActive
                            lenderTermsUpdatedAlertActive
                            channel {
                                id
                            }
                            lender {
                                name
                            }
                            loanEnquiry {
                                id
                                status
                                isNewForm
                                loanEnquiryData {
                                    loanToValue
                                    loanToCost
                                    loanToGrossDevelopmentValue
                                    dayOneLoanAmountPercentage
                                    additionalInformation
                                    rentalIncomeResidential
                                    hmoBedroom
                                    hmoRoomsSelfContained
                                    hmoHasLicense
                                    hmoNeedsLicense
                                    buyToLetExperience
                                    rentalIncomeCommercial
                                    rentalIncomePortfolio
                                    rentalIncome
                                    commercialUnitsRentedOut
                                    commercialUnitOwnerOccupied
                                    commercialUnitAmount
                                    commercialType
                                    commercialTypeOther
                                    siteDescription
                                    siteIntention
                                    refinance
                                    businessSeasonal
                                    businessType
                                    businessName
                                    businessWebsite
                                    businessTradingLength
                                    businessSector
                                    businessFacilityNeededBy
                                    businessFacilityTerm
                                    businessCurrentPaymentTerms
                                    businessOutstandingInvoices
                                    businessPdqIncome
                                    propertyType
                                    loanAmount
                                    loanPurpose
                                    turnOver
                                    businessProfitable
                                    businessOverdraftFacility
                                    businessOutstandingDebt
                                    businessAdverseCredit
                                    businessAdverseCreditExplanation
                                    businessDirectorsAdverseCredit
                                    businessDirectorsAdverseCreditExplanation
                                    shareholdersAreHomeOwners
                                    propertyAvailableForAdditionalSecurity
                                    planningFor
                                    developmentLength
                                    refurbishmentLength
                                    outstandingLoan
                                    dayOneLoanAmount
                                    unitsPreSold
                                    worksExplanation
                                    totalDevelopmentCosts
                                    buildCostsBorrowing
                                    expectedGrossDevelopmentValue
                                    previousDevelopmentExperience
                                    previousRefurbishmentExperience
                                    teamDevelopmentExperience
                                    epcRating
                                    loanType
                                    fundingType
                                    businessSecuredFinance
                                    loanUsedInUkEu
                                    planningInPlace
                                    endValue
                                    percentageResidential
                                    percentageCommercial
                                    lender
                                    firstChargeAmount
                                    currentValue
                                    properties
                                    portfolioValue
                                    portfolioAlreadyOwned
                                    loanTerm
                                    loanNeededBy
                                    preApproveMatches
                                    loanAmountRefurbishment
                                    percentageResidentialSquareFeet
                                    percentageResidentialValue
                                    percentageCommercialValue
                                    residentialUnitCount
                                    residentialUnitValue
                                    businessOccupyEntireProperty
                                    commercialUnitRentBreakdown
                                    commercialTenantAmount
                                    ownsOtherCommercialProperty
                                    ownOtherProperties
                                    occupyProperty
                                    ownsOtherInvestmentProperties
                                    familyHasOccupied
                                    secondCharge
                                    occupyingGreaterThan40Percent
                                    location
                                    locationOther
                                    address
                                    postcode
                                    propertyOwnership
                                    refurbishmentCost
                                    invoiceFinanceType
                                    leaseHirePurchase
                                    mainApplicantCreditDifficulties
                                    mainApplicantCreditDifficultiesExplanation
                                    mainApplicantCitizenshipStatus
                                    mainApplicantCountryOfResidence
                                    mainApplicantCountryOfResidenceOther
                                    mainApplicantNationality
                                    mainApplicantNationalityOther
                                    mainApplicantOccupation
                                    mainApplicantGrossIncome
                                    mainApplicantExitStrategy
                                    agreeToTerms
                                }
                                storedData {
                                    id
                                    meta {
                                        key
                                        type
                                    }
                                    value
                                }
                                loanEnquiryApplicants {
                                    isMainApplicant
                                    applicant {
                                        given
                                        family
                                        dateOfBirth
                                    }
                                }
                                submissionData {
                                    rawSubmission
                                }
                            }
                            createdAt
                            updatedAt
                            matchedAt
                            favourite
                        }
                    }
                `}
                mapQueryFunction={({ lenderLoanEnquiry = {} }) => {
                    return {
                        lenderLoanEnquiry,
                    };
                }}
                renderFunction={({ mappedValues, errors, error, data, loading, refetch, ...stuff }) => {
                    let { lenderLoanEnquiry = {} } = mappedValues;

                    if (_.isEmpty(config) || _.isEmpty(lenderLoanEnquiry)) {
                        return (
                            <Loading
                                onTimeoutResponse={
                                    <Notice
                                        message={`Sorry, we cannot display this loan match`}
                                        type={NOTICE_TYPE_ERROR}
                                    />
                                }
                            />
                        );
                    }

                    currentStatus = currentStatus || lenderLoanEnquiry.status;

                    const isGloballyDisabled = lenderLoanEnquiry.status === STATUS_ENDED;
                    const enquiryWithdrawn = lenderLoanEnquiry.loanEnquiry.status === LE_STATUS_WITHDRAWN;
                    const enquiryOnHold = lenderLoanEnquiry.loanEnquiry.status === LE_STATUS_ON_HOLD;
                    const disabledClassName = isGloballyDisabled ? 'compare-icon-disabled' : '';

                    const completionRequested = currentStatus === STATUS_COMPLETION_REQUESTED;

                    let statusBanner = null;
                    if (true === completionRequested) {
                        this.handleCompletionRequestedRespond(
                            lenderLoanEnquiry.id,
                            lenderLoanEnquiry.lender.name,
                            lenderLoanEnquiry.loanEnquiry.id
                        );
                        statusBanner = (
                            <BorStatusBannerNotice
                                status={lenderLoanEnquiry.status}
                                onClick={() =>
                                    this.handleCompletionRequestedRespond(
                                        lenderLoanEnquiry.id,
                                        lenderLoanEnquiry.lender.name,
                                        lenderLoanEnquiry.loanEnquiry.id
                                    )
                                }
                            />
                        );
                    } else if (true === isGloballyDisabled) {
                        statusBanner = <AdminStatusBannerNotice status={lenderLoanEnquiry.status} />;
                    }
                    updateFavourite(lenderLoanEnquiry.favourite);

                    if (lenderLoanEnquiry.alertActive) {
                        removeLenderLoanEnquiryAlert(lenderLoanEnquiry.id);
                    }

                    return (
                        <div className={`view-lead borrower-view-lead`}>
                            {statusBanner}
                            <Container
                                className={`view-lead-header`}
                                justifyContent={`space-between`}
                                spacing={4}
                                alignItems={`center`}
                            >
                                <Item md={12}>
                                    <ScreenTitle
                                        title={
                                            <React.Fragment>
                                                <span>
                                                    Loan Match:{' '}
                                                    {this.getMainApplicantName(
                                                        lenderLoanEnquiry.loanEnquiry.loanEnquiryApplicants
                                                    )}
                                                </span>
                                                <span className={`compare-icon ${disabledClassName}`} />
                                                <span>{lenderLoanEnquiry.lender.name}</span>
                                            </React.Fragment>
                                        }
                                    />
                                </Item>
                            </Container>
                            <Container spacing={4}>
                                <Item md={12}>
                                    <LenderLoanEnquiryStatusWidget
                                        lenderLoanEnquiry={lenderLoanEnquiry}
                                        lenderLoanEnquiryStatus={currentStatus}
                                    />
                                </Item>
                            </Container>
                            {/*<JoyrideFactory name={BORROWER_VIEW_LENDER_LOAN_ENQUIRY} />*/}
                            <Container className={`view-lead-content`} justifyContent={`space-between`} spacing={4}>
                                <Item xs={12} md={5}>
                                    <Container justifyContent={`space-between`} spacing={4}>
                                        <Item xs={12} className={`view-lead-summary`}>
                                            <P4StyleComponentContainer
                                                header={'Match summary'}
                                                buttons={<div />}
                                                content={
                                                    <KeyValueTable>
                                                        <KeyValueTableRow
                                                            term={<Subtitle1>Lender:</Subtitle1>}
                                                            def={<Body1>{lenderLoanEnquiry.lender.name}</Body1>}
                                                        />
                                                        <KeyValueTableRow
                                                            term={<Subtitle1>Main applicant:</Subtitle1>}
                                                            def={
                                                                <Body1>
                                                                    {this.getMainApplicantName(
                                                                        lenderLoanEnquiry.loanEnquiry
                                                                            .loanEnquiryApplicants
                                                                    )}
                                                                </Body1>
                                                            }
                                                        />
                                                        <KeyValueTableRow
                                                            term={<Subtitle1>Reference number:</Subtitle1>}
                                                            def={<Body1>{lenderLoanEnquiry.lenderReference}</Body1>}
                                                        />

                                                        <KeyValueTableRow
                                                            term={<Subtitle1>Date created:</Subtitle1>}
                                                            def={
                                                                <Body1>
                                                                    <ExpandedDateTimeLayout
                                                                        value={lenderLoanEnquiry.createdAt}
                                                                    />
                                                                </Body1>
                                                            }
                                                        />

                                                        <KeyValueTableRow
                                                            term={<Subtitle1>Date matched:</Subtitle1>}
                                                            def={
                                                                <Body1>
                                                                    <ExpandedDateTimeLayout
                                                                        value={lenderLoanEnquiry.matchedAt}
                                                                    />
                                                                </Body1>
                                                            }
                                                        />
                                                    </KeyValueTable>
                                                }
                                            />
                                        </Item>

                                        <Item xs={12} className={`view-lead-applicants`}>
                                            <P4StyleComponentContainer
                                                header={'Applicant details'}
                                                buttons={<div />}
                                                content={
                                                    <React.Fragment>
                                                        {lenderLoanEnquiry.loanEnquiry.loanEnquiryApplicants.map(
                                                            (loanEnquiryApplicant, index) => (
                                                                <KeyValueTable key={`applicant${index}`}>
                                                                    <KeyValueTableRow
                                                                        term={
                                                                            loanEnquiryApplicant.isMainApplicant
                                                                                ? 'Main Applicant Name'
                                                                                : 'Applicant ' + (index + 1) + ' Name'
                                                                        }
                                                                        def={
                                                                            <span className="text-highlight-green">
                                                                                {loanEnquiryApplicant.applicant.given}{' '}
                                                                                {loanEnquiryApplicant.applicant.family}
                                                                            </span>
                                                                        }
                                                                    />
                                                                    <KeyValueTableRow
                                                                        term={`Date of birth`}
                                                                        def={
                                                                            <DateFormatter
                                                                                value={
                                                                                    loanEnquiryApplicant.applicant
                                                                                        .dateOfBirth
                                                                                }
                                                                            />
                                                                        }
                                                                    />
                                                                </KeyValueTable>
                                                            )
                                                        )}
                                                    </React.Fragment>
                                                }
                                            />
                                        </Item>

                                        <Item xs={12} className={`view-lead-enquiry-info`}>
                                            <P4StyleComponentContainer
                                                header={'Enquiry information'}
                                                buttons={<div />}
                                                content={
                                                    <FactSummaryTable
                                                        config={config}
                                                        loanEnquiry={lenderLoanEnquiry.loanEnquiry}
                                                        loanEnquiryStoredData={lenderLoanEnquiry.loanEnquiry.storedData}
                                                        loanEnquirySubmissionData={JSON.parse(
                                                            lenderLoanEnquiry.loanEnquiry.submissionData[
                                                                'rawSubmission'
                                                            ] || '{}'
                                                        )}
                                                    />
                                                }
                                            />
                                        </Item>
                                    </Container>
                                </Item>
                                <Item xs={12} md={7}>
                                    <Container justifyContent={`space-between`} spacing={4}>
                                        <Item xs={12} className={`view-lead-lender-comms`} id={'lender-chat'}>
                                            <P4StyleComponentContainer
                                                header={`Lender Communications`}
                                                buttons={<div />}
                                                content={
                                                    <ChatContainer
                                                        channelId={lenderLoanEnquiry.channel.id}
                                                        disableForm={
                                                            enquiryWithdrawn || isGloballyDisabled || enquiryOnHold
                                                        }
                                                    />
                                                }
                                            />
                                        </Item>
                                        <Item xs={12} className={`view-lead-docs`} id={'lead-documents'}>
                                            <DocumentList
                                                disabled={
                                                    isGloballyDisabled || session.hasAssumedToken() || enquiryOnHold
                                                }
                                                loanEnquiry={lenderLoanEnquiry.loanEnquiry}
                                                lenderLoanEnquiry={lenderLoanEnquiry}
                                                disableUploadButton={
                                                    enquiryWithdrawn ||
                                                    isGloballyDisabled ||
                                                    session.hasAssumedToken() ||
                                                    enquiryOnHold
                                                }
                                            />
                                        </Item>
                                    </Container>
                                </Item>
                            </Container>
                        </div>
                    );
                }}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        config: selectorGetMatchingCriteriaConfig(state),
        currentStatus: selectorGetStatus(state),
        submitted: selectorGetSubmitted(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        getConfig: () => dispatch(actionGetMatchingCriteriaConfig()),
        cleanup: () => dispatch(actionCleanup()),
        updateStatusOnChange: (leadId, newStatus, metadata) => {
            dispatch(actionApiSetLeadStatus(leadId, newStatus, metadata));
        },
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        clearStatus: () => dispatch(actionClearStatus()),
        updateFavourite: (favourite) => dispatch(actionUpdateLoanEnquiryMatchesSuccess(favourite)),
        removeLenderLoanEnquiryAlert: (status) => {
            dispatch(actionRemoveLenderLoanEnquiryAlert(status));
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withSession,
    renderErrorIfAuthorisationFail([ROLE_BORROWER])
)(MasonryStyleContainer);
