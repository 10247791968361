import React from 'react';
import MetaKeyEnum, { LOAN_LTV, REGISTER_USER_AGE_POINTINTIME } from '../../admin/enum/LoanEnquiries/MetaKeyEnum';
import { SECTION_BUSINESS_FINANCE, SECTION_LOAN_TYPE, SECTION_UNIVERSAL } from '../../borrower/mle/constants';
import { Body1, Subtitle1 } from '../../../pitch4_layout/components/text';
import { EnumLabel, FactValue } from '../../../pitch4_layout/components/data';
import { Table, TableRow } from '../../../pitch4_tabular/components/table/index';
import { Container, Item } from '../../../pitch4_layout/components/div';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

class FactSummaryTable extends React.Component {
    render() {
        const {
            actionsRenderer = null,
            config,
            loanEnquiryStoredData,
            loanEnquiry = null,
            loanEnquirySubmissionData,
            lenderLoanEnquiry = null,
        } = this.props;

        if (_.isEmpty(loanEnquiryStoredData) || _.isEmpty(config)) {
            return <span>No data found</span>;
        }

        const mappedStoredData = this.mapStoredData(loanEnquiryStoredData);

        const submissionData = _.cloneDeep(loanEnquirySubmissionData);

        // Add the computed facts to the submission data.
        [LOAN_LTV, REGISTER_USER_AGE_POINTINTIME].forEach((computedFact) => {
            if (mappedStoredData.has(computedFact)) {
                const loanType = submissionData[SECTION_LOAN_TYPE].reduce(
                    (accumulator, field) => field['column'] === 'LOAN_TYPE'
                );
                const section =
                    loanType['value'][0] === 'business_finance' ? SECTION_BUSINESS_FINANCE : SECTION_UNIVERSAL;

                submissionData[section].push({
                    column: computedFact,
                    value: mappedStoredData.get(computedFact).value,
                });
            }
        });

        if (submissionData['Applicants'] !== undefined) {
            delete submissionData['Applicants'];
        }

        return (
            <Container>
                <Table style={{ border: 'none' }}>
                    {Object.keys(submissionData).map((sectionName, index) => {
                        return submissionData[sectionName].map((fact) => {
                            const storedDataMetaItem = mappedStoredData.get(fact['column']);
                            const renderActions = _.isFunction(actionsRenderer);

                            return (
                                <TableRow key={fact['column']}>
                                    <Item xs={renderActions ? 5 : 6}>
                                        <Subtitle1>
                                            <EnumLabel enumIn={new MetaKeyEnum()} value={fact['column']} />
                                        </Subtitle1>
                                    </Item>
                                    <Item xs={renderActions ? 4 : 6}>
                                        <Body1>
                                            <FactValue name={fact['column']} value={fact['value']} inPennies={true} />
                                        </Body1>
                                    </Item>
                                    {actionsRenderer && (
                                        <Item xs={3} style={{ textAlign: 'right' }}>
                                            {actionsRenderer(fact, storedDataMetaItem, loanEnquiry, lenderLoanEnquiry)}
                                        </Item>
                                    )}
                                </TableRow>
                            );
                        });
                    })}
                </Table>
            </Container>
        );
    }

    mapStoredData = () => {
        const { loanEnquiryStoredData } = this.props;

        if (!!loanEnquiryStoredData === false) {
            return new Map();
        }

        const mapped = new Map();

        loanEnquiryStoredData.forEach((item) => {
            if (item.node) {
                mapped.set(item.node.meta.key, item.node);
            } else {
                mapped.set(item.meta.key, item);
            }
        });

        return mapped;
    };
}

FactSummaryTable.propTypes = {
    config: PropTypes.object.isRequired,
    loanEnquiryStoredData: PropTypes.array.isRequired,
    loanEnquirySubmissionData: PropTypes.object.isRequired,
    loanEnquiry: PropTypes.object,
    lenderLoanEnquiry: PropTypes.object,

    /**
     * (optional)
     *
     * A function to display action buttons at the far-right of each row.
     *
     * Receives these parameters in this order:
     *
     *   "fact" (object): The submitted fact e.g. { "column": "EXAMPLE", "value": [123] }
     *   "storedDataMetaItem" (object): The stored data meta item.
     *   "loanEnquiry" (object|null): The loan enquiry object if relevant.
     *   "lenderLoanEnquiry" (object|null): The lender loan enquiry object if relevant.
     */
    actionsRenderer: PropTypes.func,
};

export default compose(withRouter)(FactSummaryTable);
