import React from 'react';
import { reduxForm } from 'redux-form';
import { FieldRow, TextField, TextLabel } from 'pitch4_forms/components/inputs';
import { Container, RowItem } from 'pitch4_layout/components/div';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { hasMaxLength255, hasMinLength6, isRequired } from 'pitchblack_react_utils/form/validation';
import { PrimaryButton, SecondaryButton } from 'pitch4_layout/components/buttons';
import { ServerErrors } from 'pitch4_forms/components';
import { RightAligned } from '../../../../pitch4_layout/components/div';

class Form extends React.Component {
    render() {
        let {
            onSubmit,
            submitting,
            handleSubmit,
            error = [],
            reset,
            //initialValues - used by the HOC
        } = this.props;

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container>
                    <RowItem>
                        <ServerErrors errors={error} visible={true} />
                    </RowItem>
                    <FieldRow
                        label={<TextLabel label="Old password" />}
                        input={
                            <TextField
                                name="oldPassword"
                                disabled={submitting}
                                validate={[isRequired, hasMinLength6, hasMaxLength255]}
                                type={`password`}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="New password" />}
                        input={
                            <TextField
                                name="newPassword"
                                disabled={submitting}
                                validate={[isRequired, hasMinLength6, hasMaxLength255]}
                                type={`password`}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Repeat password" />}
                        input={
                            <TextField
                                name="newPasswordConfirmation"
                                disabled={submitting}
                                validate={[isRequired, hasMinLength6, hasMaxLength255]}
                                type={`password`}
                            />
                        }
                    />
                    <RightAligned className={'button-panel create-reset-buttons'}>
                        <SecondaryButton disabled={submitting} onClick={reset}>
                            Reset
                        </SecondaryButton>
                        <PrimaryButton type="submit" disabled={submitting}>
                            Submit
                        </PrimaryButton>
                    </RightAligned>
                </Container>
            </form>
        );
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    reset: PropTypes.func,
    initialValues: PropTypes.any,
};

export default compose(
    reduxForm({
        form: 'administer_users_change_password_form',
        enableReinitialize: true,
    })
)(Form);
