// Actions
export const FIRST_PAGE = `redux.reset_page`;
export const NEXT_PAGE = `redux.next_page`;
export const PREVIOUS_PAGE = `redux.prev_page`;
export const TOGGLE_SELECT_ALL_ITEMS = `redux.toggle_select_all_items`;
export const TOGGLE_SELECT_ITEM = `redux.toggle_select_item`;
export const SET_IGNORE = `redux.set_ignore`;
export const CHANGE_ROWS = `redux.rows`;
export const SET_FILTERS = `redux.set_filters`;
export const CLEAR_FILTER = `redux.clear_filter`;
export const SET_FILTER = `redux.set_filter`;
export const CLEAR_SORT = `redux.clear_sort`;
export const SET_SORT = `redux.set_sort`;
export const SET_SORTS = `redux.set_sorts`;
export const SET_STATE = `redux.set_state`;

export const defaultInitialState = {
    totalCount: null,
    pages: [],
    cursor: null,
    first: 10,
    filters: [],
    sorts: [],
    selected: {},
    ignoreSelected: [],
    disableUrlFunctions: false,
    stale: false,
};

export const actionGotoFirstPage = (namespace) => () => ({
    type: `${namespace}.${FIRST_PAGE}`,
    namespace,
});

export const actionGotoNextPage = (namespace) => ({ startCursor, endCursor }) => ({
    type: `${namespace}.${NEXT_PAGE}`,
    namespace,
    startCursor,
    endCursor,
});

export const actionGotoPreviousPage = (namespace) => () => ({
    type: `${namespace}.${PREVIOUS_PAGE}`,
    namespace,
});

export const actionToggleSelectAllItems = (namespace) => (visibleItemIds) => ({
    type: `${namespace}.${TOGGLE_SELECT_ALL_ITEMS}`,
    namespace,
    visibleItemIds,
});

export const actionToggleSelectItem = (namespace) => (itemId) => ({
    type: `${namespace}.${TOGGLE_SELECT_ITEM}`,
    namespace,
    itemId,
});

export const actionSetIgnore = (namespace) => (enquiries) => ({
    type: `${namespace}.${SET_IGNORE}`,
    namespace,
    ignoreEnquires: enquiries,
});

export const actionChangeRows = (namespace) => (first = 10) => ({
    type: `${namespace}.${CHANGE_ROWS}`,
    namespace,
    first,
});

export const actionSetFilters = (namespace) => (filters) => {
    return {
        type: `${namespace}.${SET_FILTERS}`,
        namespace,
        filters,
    };
};

export const actionClearFilter = (namespace) => (column) => {
    return {
        type: `${namespace}.${CLEAR_FILTER}`,
        namespace,
        column,
    };
};

export const actionSetFilter = (namespace) => (filter) => {
    return {
        type: `${namespace}.${SET_FILTER}`,
        namespace,
        filter,
    };
};

export const actionClearSort = (namespace) => (column) => {
    return {
        type: `${namespace}.${CLEAR_SORT}`,
        namespace,
        column,
    };
};

export const actionSetSort = (namespace) => (sort) => ({
    type: `${namespace}.${SET_SORT}`,
    namespace,
    sort,
});

export const actionSetSorts = (namespace) => (sorts) => ({
    type: `${namespace}.${SET_SORTS}`,
    namespace,
    sorts,
});

export const actionSetState = (namespace) => (newState) => ({
    type: `${namespace}.${SET_STATE}`,
    namespace,
    newState,
});

const reducer = (state, action, baseNamespace) => {
    let selectedItems;
    let selected;
    let cursors;
    let newCursor;
    let prevCursors;
    let newFilter = {};
    let currentFilters = [];
    let currentSorts = [];
    let filteredSorts = [];
    let filteredFilters = [];

    let namespace = action.namespace;

    if (namespace === undefined || namespace === null || namespace === '') {
        // console.info('NOTE namespace is not defined! You should check your that creates action!');
        return { ...state };
    }

    if (namespace !== baseNamespace) {
        //the base namespace must match the action namespace - so we cut here for optimisations purposes.
        return { ...state };
    }

    switch (action.type) {
        case `${namespace}.${SET_STATE}`:
            return {
                ...state,
                ...action.newState,
            };

        case `${namespace}.${TOGGLE_SELECT_ALL_ITEMS}`:
            selectedItems = state.selected;

            if (Object.keys(selectedItems).length > 0) {
                selectedItems = {};
            } else {
                selectedItems = {};
                action.visibleItemIds.forEach((item) => {
                    selectedItems[item] = item;
                });
            }

            return {
                ...state,
                selected: selectedItems,
            };

        case `${namespace}.${TOGGLE_SELECT_ITEM}`:
            selected = state.selected;

            if (selected.hasOwnProperty(action.itemId)) {
                delete selected[action.itemId];
            } else {
                selected[action.itemId] = action.itemId;
            }

            return {
                ...state,
                selected: selected,
            };

        case `${namespace}.${CHANGE_ROWS}`:
            return {
                ...state,
                pages: [],
                first: action.first,
            };

        case `${namespace}.${FIRST_PAGE}`:
            return {
                ...state,
                pages: [],
                cursor: null,
            };

        case `${namespace}.${NEXT_PAGE}`:
            cursors = [...state.pages];
            let currentCursor = state.cursor;

            cursors.push(currentCursor);

            return {
                ...state,
                cursor: action.endCursor,
                pages: [...cursors],
            };

        case `${namespace}.${PREVIOUS_PAGE}`:
            prevCursors = [...state.pages];

            newCursor = prevCursors.pop();

            return {
                ...state,
                cursor: newCursor,
                pages: [...prevCursors],
            };

        case `${namespace}.${SET_FILTER}`:
            newFilter = action.filter;

            currentFilters = state.filters;

            let currentFilter = currentFilters.filter(({ column }) => {
                return column === action.filter.column;
            });

            if (currentFilter.length > 0) {
                let { operand, operation } = currentFilter;
                if (action.filter.operand === operand && operation === action.filter.operation) {
                    return { ...state };
                }
            }

            filteredFilters = currentFilters.filter(({ column }) => {
                return column !== action.filter.column;
            });

            filteredFilters.push(newFilter);

            return {
                ...state,
                pages: [],
                filters: [...filteredFilters],
            };

        case `${namespace}.${SET_FILTERS}`:
            return {
                ...state,
                pages: [],
                filters: [...action.filters],
            };

        case `${namespace}.${CLEAR_FILTER}`:
            currentFilters = [...state.filters];

            filteredFilters = currentFilters.filter(({ column }) => {
                return column !== action.column;
            });

            return {
                ...state,
                filters: [...filteredFilters],
                pages: [],
            };

        case `${namespace}.${CLEAR_SORT}`:
            currentSorts = [...state.sorts];

            filteredSorts = currentSorts.filter(({ column }) => {
                return column !== action.column;
            });

            return {
                ...state,
                sorts: [...filteredSorts],
            };

        case `${namespace}.${SET_SORT}`:
            currentSorts = [...state.sorts];
            filteredSorts = currentSorts.filter(({ column }) => {
                return column !== action.sort.column;
            });

            return {
                ...state,
                sorts: [...filteredSorts, action.sort],
                pages: [],
            };

        case `${namespace}.${SET_SORTS}`:
            return {
                ...state,
                sorts: [...action.sorts],
                pages: [],
            };

        case `${namespace}.${SET_IGNORE}`:
            let ignoreSelected = action.ignoreEnquires;
            state.selected = [];

            return {
                ...state,
                ignoreSelected,
            };

        default:
            return { ...state };
    }
};

//Selectors
export const selectorGetCurrentCursor = (namespace) => (state) => {
    return state[`${namespace}`].cursor;
};

export const selectorGetPreviousCursor = (namespace) => (state) => {
    let cursors = state[`${namespace}`].pages;
    let offset = cursors.length - 2;

    if (offset < 0) {
        return null;
    }

    return cursors[offset];
};

export const selectorGetPerPage = (namespace) => (state) => {
    return state[`${namespace}`].first;
};

export const selectorGetPages = (namespace) => (state) => {
    return state[`${namespace}`].pages;
};

export const selectorGetCurrentPageNo = (namespace) => (state) => {
    return state[`${namespace}`].page;
};

export const selectorGetColumnData = (namespace) => (state) => {
    return state[`${namespace}`].columns;
};

export const selectorGetSelectedIdsHash = (namespace) => (state) => {
    return state[`${namespace}`].selected;
};

export const selectorGetSelectedIds = (namespace) => (state) => {
    return Object.keys(state[`${namespace}`].selected);
};

export const selectorGetSortSingle = (namespace) => (state) => {
    return state[`${namespace}`].sorts;
};

export const selectorGetSorts = (namespace) => (state) => {
    return state[`${namespace}`].sorts;
};

export const selectorGetSort = (namespace) => (state) => (sortColumn) => {
    let sorts = state[`${namespace}`].sorts.filter(({ column }) => {
        return column === sortColumn;
    });

    return sorts.length > 0 ? sorts[0] : null;
};

export const selectorGetFilters = (namespace) => (state) => {
    return state[`${namespace}`].filters;
};

export const selectorGetFilter = (namespace) => (state) => (sortColumn) => {
    let g = state[`${namespace}`].filters.filter(({ column }) => {
        return column === sortColumn;
    });

    return g.length > 0 ? g[0] : null;
};

export const selectorGetIgnored = (namespace) => (state) => {
    return state[`${namespace}`].ignoreSelected;
};

export const selectorUrlFunctionsAreDisabled = (namespace) => (state) => {
    if (namespace === null || namespace === undefined) {
        return true;
    }

    return state[`${namespace}`].disableUrlFunctions;
};

export const selectorGetRawState = (namespace) => (state) => {
    return state[`${namespace}`];
};

export const selectorIsStale = (namespace) => (state) => {
    return state[`${namespace}`];
};

export default reducer;
