// see https://github.com/erikras/ducks-modular-redux
export const REDUCER_NAMESPACE = 'anon.password-reset';

// Actions
export const INITIALIZE = `${REDUCER_NAMESPACE}.initialize`;
export const SUBMIT_SUCCESS = `${REDUCER_NAMESPACE}.submit_success`;
export const SUBMIT_ERRORS = `${REDUCER_NAMESPACE}.submit_errors`;
export const RESET = `${REDUCER_NAMESPACE}.reset`;
export const RESET_SUBMITTED = `${REDUCER_NAMESPACE}.reset_submitted`;
export const SET_TOKEN = `${REDUCER_NAMESPACE}.set_token`;

const initialState = {
    initialized: false,
    loading: false,
    token: null,
    resetSuccess: false,
    resetSubmitted: false,
    submitErrors: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INITIALIZE:
            return {
                ...state,
                initialized: true,
                loading: true,
            };

        case SET_TOKEN:
            return {
                ...state,
                token: action.token,
                loading: false,
            };

        case RESET_SUBMITTED:
            return {
                ...state,
                resetSubmitted: true,
            };

        case SUBMIT_SUCCESS:
            return {
                ...state,
                resetSuccess: true,
                resetSubmitted: false,
                submitErrors: [],
            };

        case SUBMIT_ERRORS:
            return {
                ...state,
                resetSuccess: false,
                resetSubmitted: false,
                submitErrors: action.errors,
            };

        case RESET:
            return {
                ...initialState,
            };

        default:
            return { ...state };
    }
};

// Action creators
export const actionPasswordResetRequestInitialise = (token) => {
    return {
        type: INITIALIZE,
        token,
    };
};

export const actionResetPasswordSubmit = (newPassword, confirmPassword) => {
    return {
        type: RESET_SUBMITTED,
        newPassword,
        confirmPassword,
    };
};

export const actionReset = () => {
    return {
        type: RESET,
    };
};

// Selectors
export const selectorLoading = (state) => {
    return state[REDUCER_NAMESPACE].loading;
};

export const selectorToken = (state) => {
    return state[REDUCER_NAMESPACE].token;
};

export const selectorResetSuccess = (state) => {
    return state[REDUCER_NAMESPACE].resetSuccess;
};

export const selectorSubmitErrors = (state) => {
    return state[REDUCER_NAMESPACE].submitErrors;
};

export const selectorResetSubmitted = (state) => {
    return state[REDUCER_NAMESPACE].resetSubmitted;
};

// Default export reducer
export default reducer;
