import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { API_URL } from 'bootstrap/constants/constants';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import { STATUS_ACTIVE } from '../../enum/Users/StatusEnum';
import { PrimaryButton } from 'pitch4_layout/components/buttons';

class SetStatusButton extends React.Component {
    render() {
        let { useLabel, disabled, inputRef = () => null, children } = this.props;

        return (
            <PrimaryButton inputRef={inputRef} disabled={disabled} onClick={this.onClick}>
                {useLabel}
                {children}
            </PrimaryButton>
        );
    }

    onClick = () => {
        let { outerRefetch, mutate, id, useStatus, notificationSuccess, notificationError } = this.props;

        return mutate({
            variables: {
                id: id,
                input: {
                    newStatus: useStatus,
                },
            },
        })
            .then(({ data }) => {
                const msg = useStatus === STATUS_ACTIVE ? 'Broker enabled' : 'Broker disabled';
                notificationSuccess(msg);
                outerRefetch();
            })
            .catch((error) => {
                notificationError('An error occurred. Please contact support.');
                outerRefetch();
            });
    };
}

SetStatusButton.propTypes = {
    selectedItems: PropTypes.arrayOf(PropTypes.string.isRequired),
    mutate: PropTypes.func.isRequired,
    outerRefetch: PropTypes.func.isRequired,
    useStatus: PropTypes.string.isRequired,
    useLabel: PropTypes.string,
    children: PropTypes.any,
    inputRef: PropTypes.any,
};

const SetStatusesGql = gql`
    mutation setBrokerStatus($id: ID!, $input: pitch4admin_setBrokerStatusInput!) {
        setBrokerStatus(id: $id, input: $input) {
            broker {
                id
                status
            }
        }
    }
`;

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(SetStatusesGql, {
        options: {
            context: {
                uri: API_URL,
            },
        },
    })
)(SetStatusButton);
