import ModalLayout from '../../../../pitch4_modal/src/component/ModalLayout';
import { Grid } from '@material-ui/core';
import Question from '../Question/containers/Question';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import React from 'react';
import { getDebugLenders } from '../../../borrower/view_loan_enquiry/api/get_debug_lenders';
import { getDebugStreams } from '../../../borrower/view_loan_enquiry/api/get_debug_streams';
import { getMatchesStreamDebug } from '../../../borrower/view_loan_enquiry/api/get_match_debug';
import { BORROWER_URL, INTERMEDIARY_URL, API_URL } from '../../../../bootstrap/constants/constants';
import SessionManager from '../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_INTERMEDIARY, ROLE_SUPER_ADMIN } from '../../../authentication/enum/Roles/NameEnum';

class DebugMatch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lenderDebug: [],
            matchDebug: false,
            formData: { lender: '', businessStream: '' },
            formValidation: { lender: '', businessStream: '' },
            userUri: null,
            debugStreams: [],
        };
    }

    componentDidMount = () => {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = new sessionManager.getMostSeniorRole();
        const roleName = userRole !== null ? userRole.name : ROLE_BORROWER;
        let uri = null;
        switch (roleName) {
            case ROLE_BORROWER:
                uri = BORROWER_URL;
                break;
            case ROLE_INTERMEDIARY:
                uri = INTERMEDIARY_URL;
                break;
            case ROLE_SUPER_ADMIN:
                uri = API_URL;
                break;
            default:
                break;
        }
        this.setState({ userUri: uri }, () => {
            getDebugLenders(uri).then((response) => {
                this.setState({
                    lenderDebug: response.getMatchDebugLenders,
                });
            });
        });
    };

    onChangeFunction = (event) => {
        const { formData, userUri } = this.state;
        const { loanEnquiry } = this.props;
        const field = event.target.name;
        const value = event.target.value;

        if (field === 'lender' && value !== '') {
            this.setState({ debugStreams: [] }, this.handleLenderSelection);
            getDebugStreams(userUri, value).then((response) => {
                this.setState(
                    {
                        debugStreams: response.getMatchDebugStreams,
                        formData: formData,
                    },
                    () => {
                        this.handleLenderSelection();
                    }
                );
            });
        } else if (field === 'businessStream' && value !== '') {
            this.setState({ matchDebug: false }, this.handleBusinessStreamSelection);
            getMatchesStreamDebug(userUri, loanEnquiry.id, value).then((response) => {
                this.setState({ matchDebug: JSON.parse(response.getMatchDebug.data), formData: formData }, () => {
                    this.handleBusinessStreamSelection();
                });
            });
        } else {
            this.setState({
                formData: formData,
            });
        }
    };

    processNext() {
        const { setModalClose } = this.props;
        const { debugStreams } = this.state;
        if (false === debugStreams) {
            return;
        }
        setModalClose();
        this.handleBusinessStreamSelection();
    }

    processShowDebug() {
        const { setModalClose } = this.props;
        const { debugStreams } = this.state;
        setModalClose();
        if (debugStreams !== false) {
            this.openMatchingDebugModal();
        }
    }

    handleLenderSelection = () => {
        const { setModalComponent } = this.props;
        const { lenderDebug, formValidation, formData, debugStreams } = this.state;

        setModalComponent(
            <ModalLayout title="Select Lender" contentClassName={'lender-select'}>
                <Grid container spacing={3}>
                    <Question
                        formData={formData}
                        formValidation={formValidation}
                        label={''}
                        type={'no_label_select'}
                        value={formData['lender']}
                        fieldName={'lender'}
                        validation={'lender'}
                        placeholder={'Select a Lender'}
                        fieldOptions={lenderDebug}
                        onChangeFunction={this.onChangeFunction}
                    ></Question>
                    <Button
                        variant="contained"
                        disabled={debugStreams.length === 0}
                        onClick={() => {
                            this.processNext();
                        }}
                        color="primary"
                        className={'next-button'}
                    >
                        Next
                    </Button>
                </Grid>
            </ModalLayout>
        );
    };

    handleBusinessStreamSelection = () => {
        const { setModalComponent } = this.props;
        const { formValidation, formData, debugStreams, matchDebug } = this.state;

        let streams = [];
        debugStreams.map((businessStreams, index) => {
            streams[index] = { label: businessStreams.name, value: businessStreams.id };
            return businessStreams.name;
        });

        setModalComponent(
            <ModalLayout title="Select a business stream" contentClassName={'lender-select'}>
                <Grid container spacing={3}>
                    <Question
                        formData={formData}
                        formValidation={formValidation}
                        label={''}
                        type={'no_label_select'}
                        value={formData['businessStream']}
                        fieldName={'businessStream'}
                        validation={'businessStream'}
                        placeholder={'Select a Business Stream'}
                        fieldOptions={streams}
                        onChangeFunction={this.onChangeFunction}
                    ></Question>
                    <Button
                        variant="contained"
                        disabled={matchDebug === false}
                        onClick={() => {
                            this.processShowDebug();
                        }}
                        color="primary"
                        className={'next-button'}
                    >
                        Show Debug
                    </Button>
                </Grid>
            </ModalLayout>
        );
    };

    openMatchingDebugModal = () => {
        const { setModalComponent } = this.props;
        const { matchDebug } = this.state;
        const columns = [
            { field: 'id', headerName: 'ID', width: 50 },
            { field: 'field', headerName: 'Field', width: 100 },
            { field: 'criteria', headerName: 'Matching Criteria', width: 400 },
            { field: 'loan_value', headerName: 'Enquiry Value', width: 120 },
            { field: 'match', headerName: 'Match', width: 90 },
        ];
        setModalComponent(
            <ModalLayout
                contentClassName={'match-debug'}
                title="Matching Debug"
                children={
                    <div className={'text'}>
                        <DataGrid rows={matchDebug} columns={columns} initialState={{}} />
                    </div>
                }
            ></ModalLayout>
        );
    };

    render() {
        const { style } = this.props;
        const { lenderDebug } = this.state;
        return (
            <>
                <Button
                    disabled={lenderDebug.length === 0}
                    variant="outlined"
                    size={'large'}
                    onClick={this.handleLenderSelection}
                    style={style}
                >
                    <strong>Match Debugging</strong>
                </Button>
            </>
        );
    }
}

export default DebugMatch;
