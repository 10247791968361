import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Query } from 'react-apollo';
import { Centered, P4StyleErrorContainer } from '../../pitch4_layout/components/div';
import { goBack, push } from 'react-router-redux';
import PropTypes from 'prop-types';
import { TabularContentLayout } from '../../pitch4_layout/components/content';
import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TablePagination,
    TablePaginationControls,
    TableRow,
} from '../../pitch4_layout/components/tables';
import { Loading } from '../../pitch4_layout/components/waiting';
import { parseSearchString, WithQueryStringVariables } from '../../pitch4_layout/components/uri';
import {
    PAGINATION_NEXT,
    PAGINATION_PREV,
    PAGINATION_START,
} from '../../pitch4_layout/components/tables/TablePaginationControls';
import GenericTableBody from '../components/GenericTableBody';
import { withRouter } from 'react-router-dom';
import { actionSetState, selectorUrlFunctionsAreDisabled } from '../redux/index_ns';
import {
    sagaActionChangeRows,
    sagaActionGotoFirstPage,
    sagaActionGotoNextPage,
    sagaActionGotoPreviousPage,
} from '../sagas';
import { ILIKE } from '../enum/OperationEnum';
import { REFRESH_NORM } from '../../pitch4_enum/enum/PollingSpeedEnum';
import Paper from '@material-ui/core/Paper/Paper';

class GenericTableContainer extends React.Component {
    componentDidMount() {
        let { onMount = (props) => {}, setStateFromUrl, namespace = null, urlFunctionsDisabled } = this.props;

        if (namespace !== null && urlFunctionsDisabled !== true) {
            setStateFromUrl();
            onMount(this.props);
        }
    }

    handleChangePage = ({ pageInfo = {}, totalCount = 0, ...rest }) => (event, direction) => {
        let { dispatch, location, namespace } = this.props;

        switch (direction) {
            case PAGINATION_NEXT:
                dispatch(
                    sagaActionGotoNextPage(
                        namespace,
                        location.pathname
                    )({
                        startCursor: pageInfo.startCursor,
                        endCursor: pageInfo.endCursor,
                    })
                );
                break;

            case PAGINATION_PREV:
                dispatch(sagaActionGotoPreviousPage(namespace, location.pathname)());
                break;

            case PAGINATION_START:
                dispatch(sagaActionGotoFirstPage(namespace, location.pathname)());
                break;

            default:
                break;
        }
    };

    handleChangeRowsPerPage = (event) => {
        let { dispatch, location, namespace } = this.props;
        dispatch(sagaActionChangeRows(namespace, location.pathname)(event.target.value));
    };

    renderRows = (items = [], refetch, renderRowFunc = null) => {
        let out = [];

        if (renderRowFunc === null) {
            renderRowFunc = this.renderRow;
        }

        items.forEach((item) => {
            out.push(renderRowFunc(item, refetch));
        });

        return out;
    };

    renderEmptyRow = () => {
        let { noItemsMessage = 'No items to display', placeholderComponent = null } = this.props;
        if (placeholderComponent) {
            return placeholderComponent;
        }
        return (
            <TableCell>
                <Centered>{noItemsMessage}</Centered>
            </TableCell>
        );
    };

    render() {
        let {
            title = 'No Title',
            description = '',
            commands = () => <div />,
            query,
            context,
            filters,
            sorts,
            contextMenu,
            variables,
            extractResultDataFunction,
            extractPaginationDataFunction,
            renderHeaderFunction,
            renderRowFunction,
            renderEmpty = null,
            onPaginationChange = this.handleChangePage,
            onRowChange = this.handleChangeRowsPerPage,
            pollingInterval = REFRESH_NORM,
            toolbarMobile = null,
        } = this.props;

        if (renderEmpty === null) {
            renderEmpty = ({ errors, error, data, loading, refetch, ...stuff }) => (
                <TabularContentLayout
                    title={title}
                    description={description}
                    filters={filters}
                    sorts={sorts}
                    contextMenu={contextMenu}
                    commands={() => commands(refetch)}
                    toolbarMobile={toolbarMobile}
                    content={
                        <Paper elevation={1} className={`table-wrapper`}>
                            <GenericTableBody
                                content={
                                    <div>
                                        <Table>
                                            <TableHead className={`table-head`}>{renderHeaderFunction()}</TableHead>
                                        </Table>
                                        <Table>
                                            <TableBody className={`table-body table-empty`}>
                                                <TableRow>{this.renderEmptyRow()}</TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                }
                            />
                        </Paper>
                    }
                />
            );
        }

        variables.filters = variables.filters.map(({ operation, operand, ...other }) => {
            if (operation === ILIKE && !operand.match(/[%]/)) {
                operand = `%${operand}%`;
            }
            return { operation, operand, ...other };
        });

        return (
            <Query
                fetchPolicy={'no-cache'}
                context={context}
                query={query}
                variables={{ ...variables }}
                pollInterval={pollingInterval}
            >
                {({ errors, error, data, loading, refetch, ...stuff }) => {
                    if (error) return <P4StyleErrorContainer />;

                    let paginationData = { ...extractPaginationDataFunction(data) };
                    let { totalCount = 0 } = { ...paginationData };
                    let resultData = [...extractResultDataFunction(data)];

                    if (!resultData) {
                        return <Loading />;
                    }

                    if (resultData.length === 0) {
                        return renderEmpty({ errors, error, data, loading, refetch, ...stuff });
                    }

                    return (
                        <TabularContentLayout
                            title={title}
                            description={description}
                            filters={filters}
                            sorts={sorts}
                            contextMenu={contextMenu}
                            commands={() => commands(refetch)}
                            content={
                                <Paper elevation={1} className={`table-wrapper`}>
                                    <GenericTableBody
                                        content={
                                            <Table>
                                                <TableHead className={`table-head`}>{renderHeaderFunction()}</TableHead>
                                                <TableBody className={`table-body`}>
                                                    {this.renderRows(resultData, refetch, renderRowFunction)}
                                                </TableBody>
                                                <TableFooter className={`table-footer`}>
                                                    <TableRow>
                                                        <TablePagination
                                                            rowsPerPage={variables.first}
                                                            count={totalCount}
                                                            page={variables.pages.length}
                                                            onChangePage={onPaginationChange(paginationData)}
                                                            onChangeRowsPerPage={onRowChange}
                                                            ActionsComponent={TablePaginationControls}
                                                        />
                                                    </TableRow>
                                                </TableFooter>
                                            </Table>
                                        }
                                    />
                                </Paper>
                            }
                        />
                    );
                }}
            </Query>
        );
    }
}

GenericTableContainer.propTypes = {
    title: PropTypes.any,
    namespace: PropTypes.string,
    noItemsMessage: PropTypes.string,
    description: PropTypes.any,
    commands: PropTypes.func,
    query: PropTypes.any.isRequired,
    uriVars: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
    variables: PropTypes.object.isRequired,
    redirect: PropTypes.func.isRequired,
    extractResultDataFunction: PropTypes.func.isRequired,
    extractPaginationDataFunction: PropTypes.func.isRequired,
    renderHeaderFunction: PropTypes.func.isRequired,
    renderRowFunction: PropTypes.func.isRequired,
    renderEmpty: PropTypes.func,
    onPaginationChange: PropTypes.func,
    onRowChange: PropTypes.func,
    onMount: PropTypes.func,
    pollingInterval: PropTypes.number,
    placeholderComponent: PropTypes.node,
};

const mapStateToProps = (state, ownProps) => {
    return {
        urlFunctionsDisabled: selectorUrlFunctionsAreDisabled(ownProps.namespace)(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        setStateFromUrl: () =>
            dispatch(actionSetState(ownProps.namespace)(parseSearchString(ownProps.location.search))),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    WithQueryStringVariables
)(GenericTableContainer);
