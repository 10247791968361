import createP4ApolloFetch from 'bootstrap/middlewares';
import { ANON_URL } from '../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: ANON_URL });

const mutation = `
    query registerBorrowerInterest($input: anon_registerBorrowerInterestInput!) {
        registerBorrowerInterest (input: $input) {
            success
        }
    }
`;

export const registerBorrowerInterest = ({ email }) => {
    return apolloFetch({
        query: mutation,
        variables: {
            input: {
                email,
            },
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};
