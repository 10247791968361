import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

export default function UpdatesFilter(props) {
    const { updatesFilter, handleUpdatesFilterChange } = props;

    return (
        <FormControl variant="outlined" className={'select-updates-filter'}>
            <InputLabel id="select-status-label">
                <strong>Updates</strong> Filter
            </InputLabel>
            <Select
                labelId="select-update-filter-label"
                id="select-update-filter"
                name={'updateFilter'}
                label={'Updates Filter'}
                value={updatesFilter}
                onChange={handleUpdatesFilterChange}
                multiple={false}
            >
                <MenuItem value={'updatesOnly'}>
                    Show Only&nbsp;<span className={'label'}>Updates</span>
                </MenuItem>
                <MenuItem value={'noUpdatesOnly'}>
                    Show Only&nbsp;<span className={'label'}>No Updates</span>
                </MenuItem>
            </Select>
        </FormControl>
    );
}
