import React from 'react';
import { compose } from 'recompose';
import withSession from '../../../../pitchblack_react_utils/session/withSession';
import { RollupCommandContainer } from '../../../../pitch4_tabular/components';
import { PrimaryButton } from '../../../../pitch4_layout/components/buttons';
import { connect } from 'react-redux';
import { actionClearUserAlerts, actionRevertLoanEnquiryToDraft } from '../redux';
import { push } from 'react-router-redux';
import {
    STATUS_DRAFT,
    STATUS_IN_PROGRESS,
    STATUS_WITHDRAWN,
    STATUS_WITHDRAWN_EDITED,
    STATUS_WITHDRAWN_FULLY,
} from '../../enum/LoanEnquiries/StatusEnum';
import { path, ROUTE_BORROWER_EDIT_LOAN_ENQUIRY } from '../../../../pitch4_routing';
import { sagaActionModalShow } from '../../../modal/sagas/container';
import RevertLoanEnquiryToDraftModal from '../../../revert_loan_enquiry_to_draft_modal/components/RevertLoanEnquiryToDraftModal';

class LoanEnquiryContextMenuWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: { lender: '', businessStream: '' },
            formValidation: { lender: '', businessStream: '' },
        };
    }

    handleRevertToDraft = (loanEnquiryId) => () => {
        const { revertToDraftOnConfirm, setModalComponent } = this.props;
        const handleRTD = () => {
            revertToDraftOnConfirm(loanEnquiryId);
        };
        setModalComponent(<RevertLoanEnquiryToDraftModal handleRevertToDraft={handleRTD} />, true);
    };

    handleClearAllLoanEnquiryAlert = () => () => {
        const { clearUserAlerts, loanEnquiry } = this.props;
        clearUserAlerts(loanEnquiry.id);
    };

    handleStatusChange = () => {
        const { handleStatusChange } = this.props;
        handleStatusChange(STATUS_WITHDRAWN);
    };

    render() {
        const { session, loanEnquiry, redirect } = this.props;

        if (session.getRelevantSession().isVerified()) {
            return (
                <>
                    {loanEnquiry.status === STATUS_WITHDRAWN_EDITED ? (
                        <></>
                    ) : (
                        <div className="borrower-loan-enquiry-context-menu">
                            <RollupCommandContainer label={'Actions'}>
                                {loanEnquiry.status === STATUS_DRAFT && (
                                    <PrimaryButton
                                        onClick={redirect(
                                            path(ROUTE_BORROWER_EDIT_LOAN_ENQUIRY, {
                                                loanEnquiryId: loanEnquiry.id,
                                            })
                                        )}
                                    >
                                        <strong>Edit</strong>&nbsp; enquiry
                                    </PrimaryButton>
                                )}
                                {loanEnquiry.status === STATUS_IN_PROGRESS && (
                                    <PrimaryButton onClick={this.handleRevertToDraft(loanEnquiry.id)}>
                                        <strong>Revert enquiry</strong>&nbsp; to draft & edit
                                    </PrimaryButton>
                                )}
                                {loanEnquiry.status !== STATUS_WITHDRAWN &&
                                    loanEnquiry.status !== STATUS_WITHDRAWN_EDITED &&
                                    loanEnquiry.status !== STATUS_WITHDRAWN_FULLY && (
                                        <PrimaryButton onClick={this.handleStatusChange}>
                                            <strong>Withdraw enquiry</strong>&nbsp; permanently
                                        </PrimaryButton>
                                    )}
                                <PrimaryButton onClick={this.handleClearAllLoanEnquiryAlert()}>
                                    <strong>Mark all updates</strong>&nbsp; as read
                                </PrimaryButton>
                            </RollupCommandContainer>
                        </div>
                    )}
                </>
            );
        } else {
            return <></>;
        }
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => () => dispatch(push(url)),
        revertToDraftOnConfirm: (loanEnquiryId) => {
            dispatch(actionRevertLoanEnquiryToDraft(loanEnquiryId));
        },
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        clearUserAlerts: (loanEnquiryId) => {
            dispatch(actionClearUserAlerts(loanEnquiryId));
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(LoanEnquiryContextMenuWidget);
