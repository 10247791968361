class Mapper {
    mapToForm = (props) => {
        let {
            email = '',
            title = '',
            gender = '',
            given = '',
            family = '',
            primaryAddress: { address1 = '', address2 = '', address3 = '', address4 = '', postcode = '', country = '' },
            primaryPhone: { number = '' },
            jobTitle = '',
        } = props;

        return {
            email,
            title,
            gender,
            given,
            family,
            primaryAddressAddress1: address1,
            primaryAddressAddress2: address2,
            primaryAddressAddress3: address3,
            primaryAddressAddress4: address4,
            primaryAddressCountry: country,
            primaryAddressPostcode: postcode,
            primaryPhoneNumber: number,
            jobTitle: jobTitle,
        };
    };

    mapFromForm = ({
        title = '',
        gender = '',
        given = '',
        family = '',
        primaryAddressAddress1 = '',
        primaryAddressAddress2 = '',
        primaryAddressAddress3 = '',
        primaryAddressAddress4 = '',
        primaryAddressCountry = '',
        primaryAddressPostcode = '',
        primaryPhoneNumber = '',
        jobTitle = '',
    }) => {
        return {
            title,
            gender,
            given,
            family,
            primaryAddress: {
                address1: primaryAddressAddress1,
                address2: primaryAddressAddress2,
                address3: primaryAddressAddress3,
                address4: primaryAddressAddress4,
                postcode: primaryAddressPostcode,
                country: primaryAddressCountry,
            },
            primaryPhone: {
                number: primaryPhoneNumber,
            },
            jobTitle,
        };
    };
}

export default Mapper;
