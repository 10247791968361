import { BaseEnum } from '../../../../pitch4_enum';

export const LOAN_TYPE_PROPERTY = 'residential';
export const LOAN_TYPE_BUSINESS_FINANCE = 'business_finance';

class LoanTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [LOAN_TYPE_PROPERTY]: 'Property Finance',
            [LOAN_TYPE_BUSINESS_FINANCE]: 'Business Finance',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default LoanTypeEnum;
