class Mapper {
    mapToForm = (props) => {
        let { lender = '' } = props;

        return {
            lender,
        };
    };

    mapFromForm = ({ lender = '' }) => {
        return {
            lender,
        };
    };
}

export default Mapper;
