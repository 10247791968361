import base_reducer, { defaultInitialState } from '../../../../../pitch4_tabular/redux/index_ns';
const initialState = {
    ...defaultInitialState,
};

export const NAMESPACE = 'pitch4admin.loanenquiries';

const reducer = (state = initialState, action) => {
    return base_reducer(state, action, NAMESPACE);
};

//Default export reducer
export default reducer;
