import { BaseEnum } from 'pitch4_enum';

export const NETWORK_BROKER_CONNECT = 'connect';
export const NETWORK_BROKER_WEALTHMAX = 'wealthmax';
export const NETWORK_BROKER_WARREN_FINANCE_LTD = 'warren-finance-ltd';
export const NETWORK_BROKER_ACCESS_FINANCIAL_SERVICES = 'access-financial-services';
export const NETWORK_BROKER_GIFTWARE = 'giftware';

class NetworkBrokerEnum extends BaseEnum {
    getLoginPaths = () => {
        return {
            [NETWORK_BROKER_CONNECT]: `/${NETWORK_BROKER_CONNECT}`,
            [NETWORK_BROKER_WEALTHMAX]: `/${NETWORK_BROKER_WEALTHMAX}`,
            [NETWORK_BROKER_WARREN_FINANCE_LTD]: `/${NETWORK_BROKER_WARREN_FINANCE_LTD}`,
            [NETWORK_BROKER_ACCESS_FINANCIAL_SERVICES]: `/${NETWORK_BROKER_ACCESS_FINANCIAL_SERVICES}`,
            [NETWORK_BROKER_GIFTWARE]: `/${NETWORK_BROKER_GIFTWARE}`,
        };
    };

    getLoginPath = (nb) => this.getLoginPaths()[nb];
}

export default NetworkBrokerEnum;
