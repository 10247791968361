import { BaseEnum } from 'pitch4_enum';

export const TYPE_BUSINESS_FINANCE = 'business_finance';
export const TYPE_RESIDENTIAL = 'residential';
export const TYPE_PORTFOLIO = 'portfolio';
export const TYPE_RESIDENTIAL_BRIDGE = 'residential_bridge';
export const TYPE_RESIDENTIAL_DEVELOPMENT = 'residential_development';
export const TYPE_RESIDENTIAL_REFURBISHMENT = 'residential_refurbishment';
export const TYPE_RESIDENTIAL_SECOND_CHARGE = 'residential_second_charge';
export const TYPE_RESIDENTIAL_MEZZANINE = 'residential_mezzanine';
export const TYPE_RESIDENTIAL_TERM = 'residential_term';
export const TYPE_COMMERCIAL_BRIDGE = 'commercial_bridge';
export const TYPE_COMMERCIAL_DEVELOPMENT = 'commercial_development';
export const TYPE_COMMERCIAL_REFURBISHMENT = 'commercial_refurbishment';
export const TYPE_COMMERCIAL_SECOND_CHARGE = 'commercial_second_charge';
export const TYPE_COMMERCIAL_MEZZANINE = 'commercial_mezzanine';
export const TYPE_COMMERCIAL_TERM = 'commercial_term';
export const TYPE_SEMI_COMMERCIAL_BRIDGE = 'semi_commercial_bridge';
export const TYPE_SEMI_COMMERCIAL_DEVELOPMENT = 'semi_commercial_development';
export const TYPE_SEMI_COMMERCIAL_REFURBISHMENT = 'semi_commercial_refurbishment';
export const TYPE_SEMI_COMMERCIAL_SECOND_CHARGE = 'semi_commercial_second_charge';
export const TYPE_SEMI_COMMERCIAL_MEZZANINE = 'semi_commercial_mezzanine';
export const TYPE_SEMI_COMMERCIAL_TERM = 'semi_commercial_term';
export const TYPE_HMO_BRIDGE = 'hmo_bridge';
export const TYPE_HMO_TERM = 'hmo_term';
export const TYPE_LAND_WITHOUT_PLANNING = 'land_without_planning';
export const TYPE_BUSINESS_FINANCE_RCF = 'business_finance_rcf';
export const TYPE_BUSINESS_FINANCE_ASSET = 'business_finance_asset';
export const TYPE_BUSINESS_FINANCE_MERCHANT = 'business_finance_merchant';
export const TYPE_BUSINESS_FINANCE_INVOICE = 'business_finance_invoice';
export const TYPE_BUSINESS_FINANCE_UNSECURED = 'business_finance_unsecured';
export const TYPE_BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE_FINANCE = 'business_finance_supply_chain_trade_finance';
export const TYPE_BUSINESS_FINANCE_SECURED = 'business_finance_secured';

class LoanTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_BUSINESS_FINANCE]: 'Business Finance',
            [TYPE_RESIDENTIAL]: 'Property Finance',
            [TYPE_PORTFOLIO]: 'Portfolio Finance',
            [TYPE_RESIDENTIAL_BRIDGE]: 'Residential Bridge',
            [TYPE_RESIDENTIAL_DEVELOPMENT]: 'Residential Development',
            [TYPE_RESIDENTIAL_REFURBISHMENT]: 'Residential Refurbishment',
            [TYPE_RESIDENTIAL_SECOND_CHARGE]: 'Residential Second Charge',
            [TYPE_RESIDENTIAL_MEZZANINE]: 'Residential Mezzanine',
            [TYPE_RESIDENTIAL_TERM]: 'Residential Term',
            [TYPE_COMMERCIAL_BRIDGE]: 'Commercial Bridge',
            [TYPE_COMMERCIAL_DEVELOPMENT]: 'Commercial Development',
            [TYPE_COMMERCIAL_REFURBISHMENT]: 'Commercial Refurbishment',
            [TYPE_COMMERCIAL_SECOND_CHARGE]: 'Commercial Second Charge',
            [TYPE_COMMERCIAL_MEZZANINE]: 'Commercial Mezzanine',
            [TYPE_COMMERCIAL_TERM]: 'Commercial Term',
            [TYPE_SEMI_COMMERCIAL_BRIDGE]: 'Semi Commercial Bridge',
            [TYPE_SEMI_COMMERCIAL_DEVELOPMENT]: 'Semi Commercial Development',
            [TYPE_SEMI_COMMERCIAL_REFURBISHMENT]: 'Semi Commercial Refurbishment',
            [TYPE_SEMI_COMMERCIAL_SECOND_CHARGE]: 'Semi Commercial Second Charge',
            [TYPE_SEMI_COMMERCIAL_MEZZANINE]: 'Semi Commercial Mezzanine',
            [TYPE_SEMI_COMMERCIAL_TERM]: 'Semi Commercial Term',
            [TYPE_HMO_BRIDGE]: 'HMO Bridge',
            [TYPE_HMO_TERM]: 'HMO Term',
            [TYPE_LAND_WITHOUT_PLANNING]: 'Land Without Planning Bridge',
            [TYPE_BUSINESS_FINANCE_RCF]: 'Business RCF/Long Term/Short Term Finance',
            [TYPE_BUSINESS_FINANCE_ASSET]: 'Business Asset Finance',
            [TYPE_BUSINESS_FINANCE_MERCHANT]: 'Business Merchant Cash Advance',
            [TYPE_BUSINESS_FINANCE_INVOICE]: 'Business Invoice Finance',
            [TYPE_BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE_FINANCE]: 'Business Supply Chain/Trade Finance',
            [TYPE_BUSINESS_FINANCE_UNSECURED]: 'Business Unsecured Loans; Short term/Long Term/RCF',
            [TYPE_BUSINESS_FINANCE_SECURED]: 'Business Secured Loans; Short term/Long Term/RCF',
        };
    };
}

export default LoanTypeEnum;
