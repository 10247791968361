// see https://github.com/erikras/ducks-modular-redux
export const REDUCER_NAMESPACE = 'hubspot.tracking';

// Actions
export const HUBSPOT_TRACKING_INITIALISE = `${REDUCER_NAMESPACE}.initialise`;
export const actionHubspotTrackingInitialise = () => {
    return {
        type: HUBSPOT_TRACKING_INITIALISE,
    };
};

export const HUBSPOT_TRACKING_IDENTIFY_VISITOR = `${REDUCER_NAMESPACE}.identify_visitor`;
export const actionHubspotTrackingIdentifyVisitor = (email, extraProperties) => {
    return {
        type: HUBSPOT_TRACKING_IDENTIFY_VISITOR,
        email,
        extraProperties,
    };
};

export const HUBSPOT_TRACKING_PAGE_VIEW = `${REDUCER_NAMESPACE}.page_view`;
export const actionHubspotTrackingPageView = (path) => {
    return {
        type: HUBSPOT_TRACKING_PAGE_VIEW,
        path,
    };
};

export const actionHubspotTrackingGetVisitor = (state) => {
    return state[REDUCER_NAMESPACE].visitor;
};

export const actionHubspotTrackingGetLastPageViewed = (state) => {
    return state[REDUCER_NAMESPACE].lastPage;
};

export const HUBSPOT_TRACKING_REMOVE_COOKIE = `${REDUCER_NAMESPACE}.remove_cookie`;
export const actionHubspotTrackingRemoveCookie = () => {
    return {
        type: HUBSPOT_TRACKING_REMOVE_COOKIE,
    };
};

const initialState = {
    initialised: false,
    visitor: {
        email: '',
        extraProperties: {},
    },
    lastPage: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case HUBSPOT_TRACKING_INITIALISE:
            return {
                ...state,
                initialised: true,
            };

        case HUBSPOT_TRACKING_IDENTIFY_VISITOR:
            return {
                ...state,
                visitor: {
                    email: action.email,
                    extraProperties: action.extraProperties,
                },
            };

        case HUBSPOT_TRACKING_PAGE_VIEW:
            return {
                ...state,
                lastPage: action.path,
            };

        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;
