import BaseEnum from 'pitchblack_react_utils/enums/BaseEnum';

export const LIMITED_COMPANY = 'limited_company';
export const TRADING_COMPANY = 'trading_co';
export const PUBLIC_LIMITED_COMPANY = 'public_limited_company';
export const NON_LIMITED_PARTNERSHIP = 'non_limited_partnership';
export const OFFSHORE_LIMITED_COMPANY = 'offshore_limited';
export const SOLE_TRADER = 'sole_trader';
export const LLP = 'llp';

class BusinessStructureEnum extends BaseEnum {
    getTypes = () => {
        return {
            [LIMITED_COMPANY]: 'Limited Company',
            [TRADING_COMPANY]: 'Trading Company',
            [PUBLIC_LIMITED_COMPANY]: 'Public Limited Company',
            [NON_LIMITED_PARTNERSHIP]: 'Non-limited partnership',
            [OFFSHORE_LIMITED_COMPANY]: 'Offshore Limited Company',
            [SOLE_TRADER]: 'Sole Trader',
            [LLP]: 'LLP',
        };
    };
}

export default BusinessStructureEnum;
