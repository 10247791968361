import createP4ApolloFetch from '../bootstrap/middlewares';
import { API_URL } from '../bootstrap/constants/constants';

export const getAvailableTags = (uri = API_URL, id) => () => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    const query = `
        query getTags {
          tags {
            id
            value
            label
            color
            useLightFont
          }
        }
    `;

    return apolloFetch({
        query: query,
        variables: {},
    });
};
