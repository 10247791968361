import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { compose } from 'recompose';
import StarIcon from '../../../../../assets/images/star.svg';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ASSETS_LOGO_URL, API_URL } from '../../../../../bootstrap/constants/constants';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import StatusEnum from '../../enum/LoanEnquiries/StatusEnum';
import { Chip, CircularProgress } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

const statusEnum = new StatusEnum();
const apolloFetch = createP4ApolloFetch({ uri: API_URL });

class LenderDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 1,
            lender: null,
        };
    }

    componentDidMount() {
        const { lenderId } = this.props;
        this.getData(lenderId).then(({ data }) => {
            this.setState({ lender: data.lender });
        });
    }

    getData(lenderId) {
        const query = `
            query getLender($id: ID!) {
                lender(id: $id) {
                    id
                    name
                    primaryEmail
                    fcaIdentifier
                    companyNumber
                    status
                    createdAt
                    updatedAt
                    profileUrl
                    profileDescription
                    profileEnabled
                    profileLogo
                    profilePrimaryColor
                    primaryAddress {
                        address1
                        address2
                        address3
                        address4
                        country
                        postcode
                    }
                    primaryPhone {
                        number
                    }
                    offPanel
                }
            }
        `;

        return apolloFetch({
            query: query,
            variables: {
                id: lenderId,
            },
        });
    }

    buildChat = () => {
        return <></>;
    };

    buildDocumentList = () => {
        return <></>;
    };

    toggleLenderDrawer = () => {
        const { toggleLenderDrawer } = this.props;
        toggleLenderDrawer(false);
    };

    render() {
        const { anchor, open } = this.props;
        const { lender } = this.state;

        const handleTabChange = (event, newValue) => {
            this.setState({ tabValue: newValue });
        };

        let tabIndicatorStyle = {};
        let learnMoreStyle = {};
        if (lender && lender.profilePrimaryColor) {
            tabIndicatorStyle = {
                style: {
                    backgroundColor: lender.profilePrimaryColor,
                },
            };

            learnMoreStyle = { color: lender.profilePrimaryColor };
        }

        return (
            <Drawer variant={'temporary'} anchor={anchor} open={open} className={'lender-profile-modal'}>
                {lender ? (
                    <>
                        <div className={'lender-drawer-profile-icons'}>
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
                                1 of 10
                                <ArrowLeftIcon fontSize={'large'} />
                                <ArrowRightIcon fontSize={'large'} />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Chip
                                    className={`match-status in_progress`}
                                    label={statusEnum.getType('in_progress')}
                                />
                                <img
                                    style={{ position: 'unset', top: 'unset', marginRight: 15, marginLeft: 20 }}
                                    src={StarIcon}
                                    alt="Favourite"
                                    className={'favourite'}
                                />
                                <IconButton
                                    style={{ position: 'unset', top: 'unset' }}
                                    className={`close-button`}
                                    onClick={this.toggleLenderDrawer}
                                >
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className={'lender-profile-image'}>
                            {lender.profileLogo ? (
                                <div>
                                    <img
                                        alt={lender.name + ' Logo'}
                                        src={ASSETS_LOGO_URL.replace('{{lenderGuid}}', lender.id)}
                                        className={'lender-profile-logo'}
                                    />
                                </div>
                            ) : (
                                <div className={'lender-profile-title'}>
                                    <h5 className={'typography-page-title'}>{lender.name}</h5>
                                </div>
                            )}
                        </div>
                        <div style={{ paddingTop: 20, backgroundColor: 'white' }}>
                            <Tabs
                                value={this.state.tabValue}
                                onChange={handleTabChange}
                                TabIndicatorProps={tabIndicatorStyle}
                            >
                                <Tab
                                    label={'Lender Chat'}
                                    {...a11yProps(0)}
                                    style={{
                                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif',
                                        textTransform: 'none',
                                        fontSize: 14,
                                    }}
                                />
                                <Tab
                                    label={'About'}
                                    {...a11yProps(1)}
                                    style={{
                                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif',
                                        textTransform: 'none',
                                        fontSize: 14,
                                    }}
                                />
                                <Tab
                                    label={'Documents'}
                                    {...a11yProps(2)}
                                    style={{
                                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif',
                                        textTransform: 'none',
                                        fontSize: 14,
                                    }}
                                />
                            </Tabs>
                            <TabPanel value={this.state.tabValue} index={0}>
                                {this.buildChat()}
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={1}>
                                <p className={'lender-profile-description'}>
                                    {lender.profileDescription && lender.profileDescription.length === 0
                                        ? 'Details on this lender are coming soon.'
                                        : lender.profileDescription}
                                </p>
                                {lender.profileUrl && (
                                    <p className={'lender-profile-link'}>
                                        <a
                                            href={lender.profileUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                            style={learnMoreStyle}
                                        >
                                            Learn more
                                        </a>{' '}
                                        about {lender.name}.
                                    </p>
                                )}
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={2}>
                                {this.buildDocumentList()}
                            </TabPanel>
                        </div>
                    </>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 40 }}>
                        <CircularProgress />
                    </div>
                )}
            </Drawer>
        );
    }
}

export default compose(LenderDrawer);
