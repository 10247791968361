import React from 'react';
import PropTypes from 'prop-types';

class Link extends React.Component {
    render() {
        let {
            onClickIn = () => {},
            inputRef = () => null,
            children,
            style = { textDecoration: `none` },
            className,
        } = this.props;

        let wrappedOnClick = (event) => {
            event.preventDefault();
            onClickIn(event);
        };

        return (
            <button ref={inputRef} onClick={wrappedOnClick} style={style} className={className + ' hyperlink'}>
                {children}
            </button>
        );
    }
}

Link.propTypes = {
    onClickIn: PropTypes.func,
    children: PropTypes.any.isRequired,
};

Link.defaultProps = {
    className: '',
    onClickIn: () => {},
    inputRef: () => null,
    children: {},
    style: { textDecoration: `none` },
};

export default Link;
