import React from 'react';

class ScreenTitle extends React.Component {
    render() {
        let { title = '', ...other } = this.props;

        return (
            <h5 {...other} className={`typography-page-title`}>
                {title}
            </h5>
        );
    }
}

export default ScreenTitle;
