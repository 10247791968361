import { BaseEnum } from '../../pitch4_enum';

export const COUNTRY_AFGHANISTAN = 'COUNTRY_AFGHANISTAN';
export const COUNTRY_ALBANIA = 'COUNTRY_ALBANIA';
export const COUNTRY_ALGERIA = 'COUNTRY_ALGERIA';
export const COUNTRY_ANDORRA = 'COUNTRY_ANDORRA';
export const COUNTRY_ANGOLA = 'COUNTRY_ANGOLA';
export const COUNTRY_ANTIGUA_AND_BARBUDA = 'COUNTRY_ANTIGUA_AND_BARBUDA';
export const COUNTRY_ARGENTINA = 'COUNTRY_ARGENTINA';
export const COUNTRY_ARGENTINE_REPUBLIC_ARMENIA = 'COUNTRY_ARGENTINE_REPUBLIC_ARMENIA';
export const COUNTRY_AUSTRALIA = 'COUNTRY_AUSTRALIA';
export const COUNTRY_AUSTRIA = 'COUNTRY_AUSTRIA';
export const COUNTRY_AZERBAIJAN = 'COUNTRY_AZERBAIJAN';
export const COUNTRY_BAHAMAS = 'COUNTRY_BAHAMAS';
export const COUNTRY_BAHRAIN = 'COUNTRY_BAHRAIN';
export const COUNTRY_BANGLADESH = 'COUNTRY_BANGLADESH';
export const COUNTRY_BARBADOS = 'COUNTRY_BARBADOS';
export const COUNTRY_BELARUS = 'COUNTRY_BELARUS';
export const COUNTRY_BELGIUM = 'COUNTRY_BELGIUM';
export const COUNTRY_BELIZE = 'COUNTRY_BELIZE';
export const COUNTRY_BENIN = 'COUNTRY_BENIN';
export const COUNTRY_BHUTAN = 'COUNTRY_BHUTAN';
export const COUNTRY_BOLIVIA = 'COUNTRY_BOLIVIA';
export const COUNTRY_BOSNIA_AND_HERZEGOVINA = 'COUNTRY_BOSNIA_AND_HERZEGOVINA';
export const COUNTRY_BOTSWANA = 'COUNTRY_BOTSWANA';
export const COUNTRY_BRAZIL = 'COUNTRY_BRAZIL';
export const COUNTRY_BRUNEI = 'COUNTRY_BRUNEI';
export const COUNTRY_BULGARIA = 'COUNTRY_BULGARIA';
export const COUNTRY_BURKINA_FASO = 'COUNTRY_BURKINA_FASO';
export const COUNTRY_BURUNDI = 'COUNTRY_BURUNDI';
export const COUNTRY_CAMBODIA = 'COUNTRY_CAMBODIA';
export const COUNTRY_CAMEROON = 'COUNTRY_CAMEROON';
export const COUNTRY_CANADA = 'COUNTRY_CANADA';
export const COUNTRY_CAPE_VERDE = 'COUNTRY_CAPE_VERDE';
export const COUNTRY_CENTRAL_AFRICAN_REPUBLIC = 'COUNTRY_CENTRAL_AFRICAN_REPUBLIC';
export const COUNTRY_CHAD = 'COUNTRY_CHAD';
export const COUNTRY_CHILE = 'COUNTRY_CHILE';
export const COUNTRY_CHINA = 'COUNTRY_CHINA';
export const COUNTRY_COLOMBIA = 'COUNTRY_COLOMBIA';
export const COUNTRY_COMOROS = 'COUNTRY_COMOROS';
export const COUNTRY_CONGO = 'COUNTRY_CONGO';
export const COUNTRY_CONGO_DEMOCRATIC_REPUBLIC = 'COUNTRY_CONGO_DEMOCRATIC_REPUBLIC';
export const COUNTRY_COSTA_RICA = 'COUNTRY_COSTA_RICA';
export const COUNTRY_CROATIA = 'COUNTRY_CROATIA';
export const COUNTRY_CUBA = 'COUNTRY_CUBA';
export const COUNTRY_CYPRUS = 'COUNTRY_CYPRUS';
export const COUNTRY_CZECHIA = 'COUNTRY_CZECHIA';
export const COUNTRY_DENMARK = 'COUNTRY_DENMARK';
export const COUNTRY_DJIBOUTI = 'COUNTRY_DJIBOUTI';
export const COUNTRY_DOMINICA = 'COUNTRY_DOMINICA';
export const COUNTRY_DOMINICAN_REPUBLIC = 'COUNTRY_DOMINICAN_REPUBLIC';
export const COUNTRY_EAST_TIMOR = 'COUNTRY_EAST_TIMOR';
export const COUNTRY_ECUADOR = 'COUNTRY_ECUADOR';
export const COUNTRY_EGYPT = 'COUNTRY_EGYPT';
export const COUNTRY_EL_SALVADOR = 'COUNTRY_EL_SALVADOR';
export const COUNTRY_EQUATORIAL_GUINEA = 'COUNTRY_EQUATORIAL_GUINEA';
export const COUNTRY_ERITREA = 'COUNTRY_ERITREA';
export const COUNTRY_ESTONIA = 'COUNTRY_ESTONIA';
export const COUNTRY_ESWATINI = 'COUNTRY_ESWATINI';
export const COUNTRY_ETHIOPIA = 'COUNTRY_ETHIOPIA';
export const COUNTRY_FIJI = 'COUNTRY_FIJI';
export const COUNTRY_FINLAND = 'COUNTRY_FINLAND';
export const COUNTRY_FRANCE = 'COUNTRY_FRANCE';
export const COUNTRY_GABON = 'COUNTRY_GABON';
export const COUNTRY_GAMBIA = 'COUNTRY_GAMBIA';
export const COUNTRY_GEORGIA = 'COUNTRY_GEORGIA';
export const COUNTRY_GERMANY = 'COUNTRY_GERMANY';
export const COUNTRY_GHANA = 'COUNTRY_GHANA';
export const COUNTRY_GREECE = 'COUNTRY_GREECE';
export const COUNTRY_GRENADA = 'COUNTRY_GRENADA';
export const COUNTRY_GUATEMALA = 'COUNTRY_GUATEMALA';
export const COUNTRY_GUINEA = 'COUNTRY_GUINEA';
export const COUNTRY_GUINEA_BISSAU = 'COUNTRY_GUINEA_BISSAU';
export const COUNTRY_GUYANA = 'COUNTRY_GUYANA';
export const COUNTRY_HAITI = 'COUNTRY_HAITI';
export const COUNTRY_HONDURAS = 'COUNTRY_HONDURAS';
export const COUNTRY_HUNGARY = 'COUNTRY_HUNGARY';
export const COUNTRY_ICELAND = 'COUNTRY_ICELAND';
export const COUNTRY_INDIA = 'COUNTRY_INDIA';
export const COUNTRY_INDONESIA = 'COUNTRY_INDONESIA';
export const COUNTRY_IRAN = 'COUNTRY_IRAN';
export const COUNTRY_IRAQ = 'COUNTRY_IRAQ';
export const COUNTRY_IRELAND = 'COUNTRY_IRELAND';
export const COUNTRY_ISRAEL = 'COUNTRY_ISRAEL';
export const COUNTRY_ITALY = 'COUNTRY_ITALY';
export const COUNTRY_IVORY_COAST = 'COUNTRY_IVORY_COAST';
export const COUNTRY_JAMAICA = 'COUNTRY_JAMAICA';
export const COUNTRY_JAPAN = 'COUNTRY_JAPAN';
export const COUNTRY_JORDAN = 'COUNTRY_JORDAN';
export const COUNTRY_KAZAKHSTAN = 'COUNTRY_KAZAKHSTAN';
export const COUNTRY_KENYA = 'COUNTRY_KENYA';
export const COUNTRY_KIRIBATI = 'COUNTRY_KIRIBATI';
export const COUNTRY_KOREA_NORTH = 'COUNTRY_KOREA_NORTH';
export const COUNTRY_KOREA_SOUTH = 'COUNTRY_KOREA_SOUTH';
export const COUNTRY_KOSOVO = 'COUNTRY_KOSOVO';
export const COUNTRY_KUWAIT = 'COUNTRY_KUWAIT';
export const COUNTRY_KYRGYZSTAN = 'COUNTRY_KYRGYZSTAN';
export const COUNTRY_LAOS = 'COUNTRY_LAOS';
export const COUNTRY_LATVIA = 'COUNTRY_LATVIA';
export const COUNTRY_LEBANON = 'COUNTRY_LEBANON';
export const COUNTRY_LESOTHO = 'COUNTRY_LESOTHO';
export const COUNTRY_LIBERIA = 'COUNTRY_LIBERIA';
export const COUNTRY_LIBYA = 'COUNTRY_LIBYA';
export const COUNTRY_LIECHTENSTEIN = 'COUNTRY_LIECHTENSTEIN';
export const COUNTRY_LITHUANIA = 'COUNTRY_LITHUANIA';
export const COUNTRY_LUXEMBOURG = 'COUNTRY_LUXEMBOURG';
export const COUNTRY_MADAGASCAR = 'COUNTRY_MADAGASCAR';
export const COUNTRY_MALAWI = 'COUNTRY_MALAWI';
export const COUNTRY_MALAYSIA = 'COUNTRY_MALAYSIA';
export const COUNTRY_MALDIVES = 'COUNTRY_MALDIVES';
export const COUNTRY_MALI = 'COUNTRY_MALI';
export const COUNTRY_MALTA = 'COUNTRY_MALTA';
export const COUNTRY_MARSHALL_ISLANDS = 'COUNTRY_MARSHALL_ISLANDS';
export const COUNTRY_MAURITANIA = 'COUNTRY_MAURITANIA';
export const COUNTRY_MAURITIUS = 'COUNTRY_MAURITIUS';
export const COUNTRY_MEXICO = 'COUNTRY_MEXICO';
export const COUNTRY_MICRONESIA = 'COUNTRY_MICRONESIA';
export const COUNTRY_MOLDOVA = 'COUNTRY_MOLDOVA';
export const COUNTRY_MONACO = 'COUNTRY_MONACO';
export const COUNTRY_MONGOLIA = 'COUNTRY_MONGOLIA';
export const COUNTRY_MONTENEGRO = 'COUNTRY_MONTENEGRO';
export const COUNTRY_MOROCCO = 'COUNTRY_MOROCCO';
export const COUNTRY_MOZAMBIQUE = 'COUNTRY_MOZAMBIQUE';
export const COUNTRY_MYANMAR_BURMA = 'COUNTRY_MYANMAR_BURMA';
export const COUNTRY_NAMIBIA = 'COUNTRY_NAMIBIA';
export const COUNTRY_NAURU = 'COUNTRY_NAURU';
export const COUNTRY_NEPAL = 'COUNTRY_NEPAL';
export const COUNTRY_NETHERLANDS = 'COUNTRY_NETHERLANDS';
export const COUNTRY_NEW_ZEALAND = 'COUNTRY_NEW_ZEALAND';
export const COUNTRY_NICARAGUA = 'COUNTRY_NICARAGUA';
export const COUNTRY_NIGER = 'COUNTRY_NIGER';
export const COUNTRY_NIGERIA = 'COUNTRY_NIGERIA';
export const COUNTRY_NORTH_MACEDONIA = 'COUNTRY_NORTH_MACEDONIA';
export const COUNTRY_NORWAY = 'COUNTRY_NORWAY';
export const COUNTRY_OMAN = 'COUNTRY_OMAN';
export const COUNTRY_PAKISTAN = 'COUNTRY_PAKISTAN';
export const COUNTRY_PALAU = 'COUNTRY_PALAU';
export const COUNTRY_PANAMA = 'COUNTRY_PANAMA';
export const COUNTRY_PAPUA_NEW_GUINEA = 'COUNTRY_PAPUA_NEW_GUINEA';
export const COUNTRY_PARAGUAY = 'COUNTRY_PARAGUAY';
export const COUNTRY_PERU = 'COUNTRY_PERU';
export const COUNTRY_PHILIPPINES = 'COUNTRY_PHILIPPINES';
export const COUNTRY_POLAND = 'COUNTRY_POLAND';
export const COUNTRY_PORTUGAL = 'COUNTRY_PORTUGAL';
export const COUNTRY_QATAR = 'COUNTRY_QATAR';
export const COUNTRY_ROMANIA = 'COUNTRY_ROMANIA';
export const COUNTRY_RUSSIA = 'COUNTRY_RUSSIA';
export const COUNTRY_RWANDA = 'COUNTRY_RWANDA';
export const COUNTRY_ST_KITTS_AND_NEVIS = 'COUNTRY_ST_KITTS_AND_NEVIS';
export const COUNTRY_ST_LUCIA = 'COUNTRY_ST_LUCIA';
export const COUNTRY_ST_VINCENT = 'COUNTRY_ST_VINCENT';
export const COUNTRY_SAMOA = 'COUNTRY_SAMOA';
export const COUNTRY_SAN_MARINO = 'COUNTRY_SAN_MARINO';
export const COUNTRY_SAO_TOME_AND_PRINCIPE = 'COUNTRY_SAO_TOME_AND_PRINCIPE';
export const COUNTRY_SAUDI_ARABIA = 'COUNTRY_SAUDI_ARABIA';
export const COUNTRY_SENEGAL = 'COUNTRY_SENEGAL';
export const COUNTRY_SERBIA = 'COUNTRY_SERBIA';
export const COUNTRY_SEYCHELLES = 'COUNTRY_SEYCHELLES';
export const COUNTRY_SIERRA_LEONE = 'COUNTRY_SIERRA_LEONE';
export const COUNTRY_SINGAPORE = 'COUNTRY_SINGAPORE';
export const COUNTRY_SLOVAKIA = 'COUNTRY_SLOVAKIA';
export const COUNTRY_SLOVENIA = 'COUNTRY_SLOVENIA';
export const COUNTRY_SOLOMON_ISLANDS = 'COUNTRY_SOLOMON_ISLANDS';
export const COUNTRY_SOMALIA = 'COUNTRY_SOMALIA';
export const COUNTRY_SOUTH_AFRICA = 'COUNTRY_SOUTH_AFRICA';
export const COUNTRY_SOUTH_SUDAN = 'COUNTRY_SOUTH_SUDAN';
export const COUNTRY_SPAIN = 'COUNTRY_SPAIN';
export const COUNTRY_SRI_LANKA = 'COUNTRY_SRI_LANKA';
export const COUNTRY_SUDAN = 'COUNTRY_SUDAN';
export const COUNTRY_SURINAME = 'COUNTRY_SURINAME';
export const COUNTRY_SWEDEN = 'COUNTRY_SWEDEN';
export const COUNTRY_SWITZERLAND = 'COUNTRY_SWITZERLAND';
export const COUNTRY_SYRIA = 'COUNTRY_SYRIA';
export const COUNTRY_TAJIKISTAN = 'COUNTRY_TAJIKISTAN';
export const COUNTRY_TANZANIA = 'COUNTRY_TANZANIA';
export const COUNTRY_THAILAND = 'COUNTRY_THAILAND';
export const COUNTRY_TOGO = 'COUNTRY_TOGO';
export const COUNTRY_TONGA = 'COUNTRY_TONGA';
export const COUNTRY_TRINIDAD_AND_TOBAGO = 'COUNTRY_TRINIDAD_AND_TOBAGO';
export const COUNTRY_TUNISIA = 'COUNTRY_TUNISIA';
export const COUNTRY_TURKEY = 'COUNTRY_TURKEY';
export const COUNTRY_TURKMENISTAN = 'COUNTRY_TURKMENISTAN';
export const COUNTRY_TUVALU = 'COUNTRY_TUVALU';
export const COUNTRY_UGANDA = 'COUNTRY_UGANDA';
export const COUNTRY_UKRAINE = 'COUNTRY_UKRAINE';
export const COUNTRY_UNITED_ARAB_EMIRATES = 'COUNTRY_UNITED_ARAB_EMIRATES';
export const COUNTRY_UNITED_KINGDOM = 'COUNTRY_UNITED_KINGDOM';
export const COUNTRY_UNITED_STATES = 'COUNTRY_UNITED_STATES';
export const COUNTRY_URUGUAY = 'COUNTRY_URUGUAY';
export const COUNTRY_UZBEKISTAN = 'COUNTRY_UZBEKISTAN';
export const COUNTRY_VANUATU = 'COUNTRY_VANUATU';
export const COUNTRY_VATICAN_CITY = 'COUNTRY_VATICAN_CITY';
export const COUNTRY_VENEZUELA = 'COUNTRY_VENEZUELA';
export const COUNTRY_VIETNAM = 'COUNTRY_VIETNAM';
export const COUNTRY_YEMEN = 'COUNTRY_YEMEN';
export const COUNTRY_ZAMBIA = 'COUNTRY_ZAMBIA';
export const COUNTRY_ZIMBABWE = 'COUNTRY_ZIMBABWE';
export const COUNTRY_OTHER = 'COUNTRY_OTHER';

class CountryNameEnum extends BaseEnum {
    getTypes = () => {
        return {
            [COUNTRY_AFGHANISTAN]: 'Afghanistan',
            [COUNTRY_ALBANIA]: 'Albania',
            [COUNTRY_ALGERIA]: 'Algeria',
            [COUNTRY_ANDORRA]: 'Andorra',
            [COUNTRY_ANGOLA]: 'Angola',
            [COUNTRY_ANTIGUA_AND_BARBUDA]: 'Antigua and Barbuda',
            [COUNTRY_ARGENTINA]: 'Argentina',
            [COUNTRY_ARGENTINE_REPUBLIC_ARMENIA]: 'Argentine Republic Armenia',
            [COUNTRY_AUSTRALIA]: 'Australia',
            [COUNTRY_AUSTRIA]: 'Austria',
            [COUNTRY_AZERBAIJAN]: 'Azerbaijan',
            [COUNTRY_BAHAMAS]: 'Bahamas',
            [COUNTRY_BAHRAIN]: 'Bahrain',
            [COUNTRY_BANGLADESH]: 'Bangladesh',
            [COUNTRY_BARBADOS]: 'Barbados',
            [COUNTRY_BELARUS]: 'Belarus',
            [COUNTRY_BELGIUM]: 'Belgium',
            [COUNTRY_BELIZE]: 'Belize',
            [COUNTRY_BENIN]: 'Benin',
            [COUNTRY_BHUTAN]: 'Bhutan',
            [COUNTRY_BOLIVIA]: 'Bolivia',
            [COUNTRY_BOSNIA_AND_HERZEGOVINA]: 'Bosnia and Herzegovina',
            [COUNTRY_BOTSWANA]: 'Botswana',
            [COUNTRY_BRAZIL]: 'Brazil',
            [COUNTRY_BRUNEI]: 'Brunei',
            [COUNTRY_BULGARIA]: 'Bulgaria',
            [COUNTRY_BURKINA_FASO]: 'Burkina Faso',
            [COUNTRY_BURUNDI]: 'Burundi',
            [COUNTRY_CAMBODIA]: 'Cambodia',
            [COUNTRY_CAMEROON]: 'Cameroon',
            [COUNTRY_CANADA]: 'Canada',
            [COUNTRY_CAPE_VERDE]: 'Cape Verde',
            [COUNTRY_CENTRAL_AFRICAN_REPUBLIC]: 'Central African Republic',
            [COUNTRY_CHAD]: 'Chad',
            [COUNTRY_CHILE]: 'Chile',
            [COUNTRY_CHINA]: 'China',
            [COUNTRY_COLOMBIA]: 'Colombia',
            [COUNTRY_COMOROS]: 'Comoros',
            [COUNTRY_CONGO]: 'Congo',
            [COUNTRY_CONGO_DEMOCRATIC_REPUBLIC]: 'Congo (Democratic Republic)',
            [COUNTRY_COSTA_RICA]: 'Costa Rica',
            [COUNTRY_CROATIA]: 'Croatia',
            [COUNTRY_CUBA]: 'Cuba',
            [COUNTRY_CYPRUS]: 'Cyprus',
            [COUNTRY_CZECHIA]: 'Czechia',
            [COUNTRY_DENMARK]: 'Denmark',
            [COUNTRY_DJIBOUTI]: 'Djibouti',
            [COUNTRY_DOMINICA]: 'Dominica',
            [COUNTRY_DOMINICAN_REPUBLIC]: 'Dominican Republic',
            [COUNTRY_EAST_TIMOR]: 'East Timor',
            [COUNTRY_ECUADOR]: 'Ecuador',
            [COUNTRY_EGYPT]: 'Egypt',
            [COUNTRY_EL_SALVADOR]: 'El Salvador',
            [COUNTRY_EQUATORIAL_GUINEA]: 'Equatorial Guinea',
            [COUNTRY_ERITREA]: 'Eritrea',
            [COUNTRY_ESTONIA]: 'Estonia',
            [COUNTRY_ESWATINI]: 'Eswatini',
            [COUNTRY_ETHIOPIA]: 'Ethiopia',
            [COUNTRY_FIJI]: 'Fiji',
            [COUNTRY_FINLAND]: 'Finland',
            [COUNTRY_FRANCE]: 'France',
            [COUNTRY_GABON]: 'Gabon',
            [COUNTRY_GAMBIA]: 'Gambia',
            [COUNTRY_GEORGIA]: 'Georgia',
            [COUNTRY_GERMANY]: 'Germany',
            [COUNTRY_GHANA]: 'Ghana',
            [COUNTRY_GREECE]: 'Greece',
            [COUNTRY_GRENADA]: 'Grenada',
            [COUNTRY_GUATEMALA]: 'Guatemala',
            [COUNTRY_GUINEA]: 'Guinea',
            [COUNTRY_GUINEA_BISSAU]: 'Guinea-Bissau',
            [COUNTRY_GUYANA]: 'Guyana',
            [COUNTRY_HAITI]: 'Haiti',
            [COUNTRY_HONDURAS]: 'Honduras',
            [COUNTRY_HUNGARY]: 'Hungary',
            [COUNTRY_ICELAND]: 'Iceland',
            [COUNTRY_INDIA]: 'India',
            [COUNTRY_INDONESIA]: 'Indonesia',
            [COUNTRY_IRAN]: 'Iran',
            [COUNTRY_IRAQ]: 'Iraq',
            [COUNTRY_IRELAND]: 'Ireland',
            [COUNTRY_ISRAEL]: 'Israel',
            [COUNTRY_ITALY]: 'Italy',
            [COUNTRY_IVORY_COAST]: 'Ivory Coast',
            [COUNTRY_JAMAICA]: 'Jamaica',
            [COUNTRY_JAPAN]: 'Japan',
            [COUNTRY_JORDAN]: 'Jordan',
            [COUNTRY_KAZAKHSTAN]: 'Kazakhstan',
            [COUNTRY_KENYA]: 'Kenya',
            [COUNTRY_KIRIBATI]: 'Kiribati',
            [COUNTRY_KOREA_NORTH]: 'Korea (North)',
            [COUNTRY_KOREA_SOUTH]: 'Korea (South)',
            [COUNTRY_KOSOVO]: 'Kosovo',
            [COUNTRY_KUWAIT]: 'Kuwait',
            [COUNTRY_KYRGYZSTAN]: 'Kyrgyzstan',
            [COUNTRY_LAOS]: 'Laos',
            [COUNTRY_LATVIA]: 'Latvia',
            [COUNTRY_LEBANON]: 'Lebanon',
            [COUNTRY_LESOTHO]: 'Lesotho',
            [COUNTRY_LIBERIA]: 'Liberia',
            [COUNTRY_LIBYA]: 'Libya',
            [COUNTRY_LIECHTENSTEIN]: 'Liechtenstein',
            [COUNTRY_LITHUANIA]: 'Lithuania',
            [COUNTRY_LUXEMBOURG]: 'Luxembourg',
            [COUNTRY_MADAGASCAR]: 'Madagascar',
            [COUNTRY_MALAWI]: 'Malawi',
            [COUNTRY_MALAYSIA]: 'Malaysia',
            [COUNTRY_MALDIVES]: 'Maldives',
            [COUNTRY_MALI]: 'Mali',
            [COUNTRY_MALTA]: 'Malta',
            [COUNTRY_MARSHALL_ISLANDS]: 'Marshall Islands',
            [COUNTRY_MAURITANIA]: 'Mauritania',
            [COUNTRY_MAURITIUS]: 'Mauritius',
            [COUNTRY_MEXICO]: 'Mexico',
            [COUNTRY_MICRONESIA]: 'Micronesia',
            [COUNTRY_MOLDOVA]: 'Moldova',
            [COUNTRY_MONACO]: 'Monaco',
            [COUNTRY_MONGOLIA]: 'Mongolia',
            [COUNTRY_MONTENEGRO]: 'Montenegro',
            [COUNTRY_MOROCCO]: 'Morocco',
            [COUNTRY_MOZAMBIQUE]: 'Mozambique',
            [COUNTRY_MYANMAR_BURMA]: 'Myanmar (Burma)',
            [COUNTRY_NAMIBIA]: 'Namibia',
            [COUNTRY_NAURU]: 'Nauru',
            [COUNTRY_NEPAL]: 'Nepal',
            [COUNTRY_NETHERLANDS]: 'Netherlands',
            [COUNTRY_NEW_ZEALAND]: 'New Zealand',
            [COUNTRY_NICARAGUA]: 'Nicaragua',
            [COUNTRY_NIGER]: 'Niger',
            [COUNTRY_NIGERIA]: 'Nigeria',
            [COUNTRY_NORTH_MACEDONIA]: 'North Macedonia',
            [COUNTRY_NORWAY]: 'Norway',
            [COUNTRY_OMAN]: 'Oman',
            [COUNTRY_PAKISTAN]: 'Pakistan',
            [COUNTRY_PALAU]: 'Palau',
            [COUNTRY_PANAMA]: 'Panama',
            [COUNTRY_PAPUA_NEW_GUINEA]: 'Papua New Guinea',
            [COUNTRY_PARAGUAY]: 'Paraguay',
            [COUNTRY_PERU]: 'Peru',
            [COUNTRY_PHILIPPINES]: 'Philippines',
            [COUNTRY_POLAND]: 'Poland',
            [COUNTRY_PORTUGAL]: 'Portugal',
            [COUNTRY_QATAR]: 'Qatar',
            [COUNTRY_ROMANIA]: 'Romania',
            [COUNTRY_RUSSIA]: 'Russia',
            [COUNTRY_RWANDA]: 'Rwanda',
            [COUNTRY_ST_KITTS_AND_NEVIS]: 'St Kitts and Nevis',
            [COUNTRY_ST_LUCIA]: 'St Lucia',
            [COUNTRY_ST_VINCENT]: 'St Vincent',
            [COUNTRY_SAMOA]: 'Samoa',
            [COUNTRY_SAN_MARINO]: 'San Marino',
            [COUNTRY_SAO_TOME_AND_PRINCIPE]: 'Sao Tome and Principe',
            [COUNTRY_SAUDI_ARABIA]: 'Saudi Arabia',
            [COUNTRY_SENEGAL]: 'Senegal',
            [COUNTRY_SERBIA]: 'Serbia',
            [COUNTRY_SEYCHELLES]: 'Seychelles',
            [COUNTRY_SIERRA_LEONE]: 'Sierra Leone',
            [COUNTRY_SINGAPORE]: 'Singapore',
            [COUNTRY_SLOVAKIA]: 'Slovakia',
            [COUNTRY_SLOVENIA]: 'Slovenia',
            [COUNTRY_SOLOMON_ISLANDS]: 'Solomon Islands',
            [COUNTRY_SOMALIA]: 'Somalia',
            [COUNTRY_SOUTH_AFRICA]: 'South Africa',
            [COUNTRY_SOUTH_SUDAN]: 'South Sudan',
            [COUNTRY_SPAIN]: 'Spain',
            [COUNTRY_SRI_LANKA]: 'Sri Lanka',
            [COUNTRY_SUDAN]: 'Sudan',
            [COUNTRY_SURINAME]: 'Suriname',
            [COUNTRY_SWEDEN]: 'Sweden',
            [COUNTRY_SWITZERLAND]: 'Switzerland',
            [COUNTRY_SYRIA]: 'Syria',
            [COUNTRY_TAJIKISTAN]: 'Tajikistan',
            [COUNTRY_TANZANIA]: 'Tanzania',
            [COUNTRY_THAILAND]: 'Thailand',
            [COUNTRY_TOGO]: 'Togo',
            [COUNTRY_TONGA]: 'Tonga',
            [COUNTRY_TRINIDAD_AND_TOBAGO]: 'Trinidad and Tobago',
            [COUNTRY_TUNISIA]: 'Tunisia',
            [COUNTRY_TURKEY]: 'Turkey',
            [COUNTRY_TURKMENISTAN]: 'Turkmenistan',
            [COUNTRY_TUVALU]: 'Tuvalu',
            [COUNTRY_UGANDA]: 'Uganda',
            [COUNTRY_UKRAINE]: 'Ukraine',
            [COUNTRY_UNITED_ARAB_EMIRATES]: 'United Arab Emirates',
            [COUNTRY_UNITED_KINGDOM]: 'United Kingdom',
            [COUNTRY_UNITED_STATES]: 'United States',
            [COUNTRY_URUGUAY]: 'Uruguay',
            [COUNTRY_UZBEKISTAN]: 'Uzbekistan',
            [COUNTRY_VANUATU]: 'Vanuatu',
            [COUNTRY_VATICAN_CITY]: 'Vatican City',
            [COUNTRY_VENEZUELA]: 'Venezuela',
            [COUNTRY_VIETNAM]: 'Vietnam',
            [COUNTRY_YEMEN]: 'Yemen',
            [COUNTRY_ZAMBIA]: 'Zambia',
            [COUNTRY_ZIMBABWE]: 'Zimbabwe',
            [COUNTRY_OTHER]: 'Other',
        };
    };

    getOptions = () => {
        return [
            { value: COUNTRY_UNITED_KINGDOM, label: 'United Kingdom' },
            { value: COUNTRY_AFGHANISTAN, label: 'Afghanistan' },
            { value: COUNTRY_ALBANIA, label: 'Albania' },
            { value: COUNTRY_ALGERIA, label: 'Algeria' },
            { value: COUNTRY_ANDORRA, label: 'Andorra' },
            { value: COUNTRY_ANGOLA, label: 'Angola' },
            { value: COUNTRY_ANTIGUA_AND_BARBUDA, label: 'Antigua and Barbuda' },
            { value: COUNTRY_ARGENTINA, label: 'Argentina' },
            { value: COUNTRY_ARGENTINE_REPUBLIC_ARMENIA, label: 'Argentine Republic Armenia' },
            { value: COUNTRY_AUSTRALIA, label: 'Australia' },
            { value: COUNTRY_AUSTRIA, label: 'Austria' },
            { value: COUNTRY_AZERBAIJAN, label: 'Azerbaijan' },
            { value: COUNTRY_BAHAMAS, label: 'Bahamas' },
            { value: COUNTRY_BAHRAIN, label: 'Bahrain' },
            { value: COUNTRY_BANGLADESH, label: 'Bangladesh' },
            { value: COUNTRY_BARBADOS, label: 'Barbados' },
            { value: COUNTRY_BELARUS, label: 'Belarus' },
            { value: COUNTRY_BELGIUM, label: 'Belgium' },
            { value: COUNTRY_BELIZE, label: 'Belize' },
            { value: COUNTRY_BENIN, label: 'Benin' },
            { value: COUNTRY_BHUTAN, label: 'Bhutan' },
            { value: COUNTRY_BOLIVIA, label: 'Bolivia' },
            { value: COUNTRY_BOSNIA_AND_HERZEGOVINA, label: 'Bosnia and Herzegovina' },
            { value: COUNTRY_BOTSWANA, label: 'Botswana' },
            { value: COUNTRY_BRAZIL, label: 'Brazil' },
            { value: COUNTRY_BRUNEI, label: 'Brunei' },
            { value: COUNTRY_BULGARIA, label: 'Bulgaria' },
            { value: COUNTRY_BURKINA_FASO, label: 'Burkina Faso' },
            { value: COUNTRY_BURUNDI, label: 'Burundi' },
            { value: COUNTRY_CAMBODIA, label: 'Cambodia' },
            { value: COUNTRY_CAMEROON, label: 'Cameroon' },
            { value: COUNTRY_CANADA, label: 'Canada' },
            { value: COUNTRY_CAPE_VERDE, label: 'Cape Verde' },
            { value: COUNTRY_CENTRAL_AFRICAN_REPUBLIC, label: 'Central African Republic' },
            { value: COUNTRY_CHAD, label: 'Chad' },
            { value: COUNTRY_CHILE, label: 'Chile' },
            { value: COUNTRY_CHINA, label: 'China' },
            { value: COUNTRY_COLOMBIA, label: 'Colombia' },
            { value: COUNTRY_COMOROS, label: 'Comoros' },
            { value: COUNTRY_CONGO, label: 'Congo' },
            { value: COUNTRY_CONGO_DEMOCRATIC_REPUBLIC, label: 'Congo (Democratic Republic)' },
            { value: COUNTRY_COSTA_RICA, label: 'Costa Rica' },
            { value: COUNTRY_CROATIA, label: 'Croatia' },
            { value: COUNTRY_CUBA, label: 'Cuba' },
            { value: COUNTRY_CYPRUS, label: 'Cyprus' },
            { value: COUNTRY_CZECHIA, label: 'Czechia' },
            { value: COUNTRY_DENMARK, label: 'Denmark' },
            { value: COUNTRY_DJIBOUTI, label: 'Djibouti' },
            { value: COUNTRY_DOMINICA, label: 'Dominica' },
            { value: COUNTRY_DOMINICAN_REPUBLIC, label: 'Dominican Republic' },
            { value: COUNTRY_EAST_TIMOR, label: 'East Timor' },
            { value: COUNTRY_ECUADOR, label: 'Ecuador' },
            { value: COUNTRY_EGYPT, label: 'Egypt' },
            { value: COUNTRY_EL_SALVADOR, label: 'El Salvador' },
            { value: COUNTRY_EQUATORIAL_GUINEA, label: 'Equatorial Guinea' },
            { value: COUNTRY_ERITREA, label: 'Eritrea' },
            { value: COUNTRY_ESTONIA, label: 'Estonia' },
            { value: COUNTRY_ESWATINI, label: 'Eswatini' },
            { value: COUNTRY_ETHIOPIA, label: 'Ethiopia' },
            { value: COUNTRY_FIJI, label: 'Fiji' },
            { value: COUNTRY_FINLAND, label: 'Finland' },
            { value: COUNTRY_FRANCE, label: 'France' },
            { value: COUNTRY_GABON, label: 'Gabon' },
            { value: COUNTRY_GAMBIA, label: 'Gambia' },
            { value: COUNTRY_GEORGIA, label: 'Georgia' },
            { value: COUNTRY_GERMANY, label: 'Germany' },
            { value: COUNTRY_GHANA, label: 'Ghana' },
            { value: COUNTRY_GREECE, label: 'Greece' },
            { value: COUNTRY_GRENADA, label: 'Grenada' },
            { value: COUNTRY_GUATEMALA, label: 'Guatemala' },
            { value: COUNTRY_GUINEA, label: 'Guinea' },
            { value: COUNTRY_GUINEA_BISSAU, label: 'Guinea-Bissau' },
            { value: COUNTRY_GUYANA, label: 'Guyana' },
            { value: COUNTRY_HAITI, label: 'Haiti' },
            { value: COUNTRY_HONDURAS, label: 'Honduras' },
            { value: COUNTRY_HUNGARY, label: 'Hungary' },
            { value: COUNTRY_ICELAND, label: 'Iceland' },
            { value: COUNTRY_INDIA, label: 'India' },
            { value: COUNTRY_INDONESIA, label: 'Indonesia' },
            { value: COUNTRY_IRAN, label: 'Iran' },
            { value: COUNTRY_IRAQ, label: 'Iraq' },
            { value: COUNTRY_IRELAND, label: 'Ireland' },
            { value: COUNTRY_ISRAEL, label: 'Israel' },
            { value: COUNTRY_ITALY, label: 'Italy' },
            { value: COUNTRY_IVORY_COAST, label: 'Ivory Coast' },
            { value: COUNTRY_JAMAICA, label: 'Jamaica' },
            { value: COUNTRY_JAPAN, label: 'Japan' },
            { value: COUNTRY_JORDAN, label: 'Jordan' },
            { value: COUNTRY_KAZAKHSTAN, label: 'Kazakhstan' },
            { value: COUNTRY_KENYA, label: 'Kenya' },
            { value: COUNTRY_KIRIBATI, label: 'Kiribati' },
            { value: COUNTRY_KOREA_NORTH, label: 'Korea (North)' },
            { value: COUNTRY_KOREA_SOUTH, label: 'Korea (South)' },
            { value: COUNTRY_KOSOVO, label: 'Kosovo' },
            { value: COUNTRY_KUWAIT, label: 'Kuwait' },
            { value: COUNTRY_KYRGYZSTAN, label: 'Kyrgyzstan' },
            { value: COUNTRY_LAOS, label: 'Laos' },
            { value: COUNTRY_LATVIA, label: 'Latvia' },
            { value: COUNTRY_LEBANON, label: 'Lebanon' },
            { value: COUNTRY_LESOTHO, label: 'Lesotho' },
            { value: COUNTRY_LIBERIA, label: 'Liberia' },
            { value: COUNTRY_LIBYA, label: 'Libya' },
            { value: COUNTRY_LIECHTENSTEIN, label: 'Liechtenstein' },
            { value: COUNTRY_LITHUANIA, label: 'Lithuania' },
            { value: COUNTRY_LUXEMBOURG, label: 'Luxembourg' },
            { value: COUNTRY_MADAGASCAR, label: 'Madagascar' },
            { value: COUNTRY_MALAWI, label: 'Malawi' },
            { value: COUNTRY_MALAYSIA, label: 'Malaysia' },
            { value: COUNTRY_MALDIVES, label: 'Maldives' },
            { value: COUNTRY_MALI, label: 'Mali' },
            { value: COUNTRY_MALTA, label: 'Malta' },
            { value: COUNTRY_MARSHALL_ISLANDS, label: 'Marshall Islands' },
            { value: COUNTRY_MAURITANIA, label: 'Mauritania' },
            { value: COUNTRY_MAURITIUS, label: 'Mauritius' },
            { value: COUNTRY_MEXICO, label: 'Mexico' },
            { value: COUNTRY_MICRONESIA, label: 'Micronesia' },
            { value: COUNTRY_MOLDOVA, label: 'Moldova' },
            { value: COUNTRY_MONACO, label: 'Monaco' },
            { value: COUNTRY_MONGOLIA, label: 'Mongolia' },
            { value: COUNTRY_MONTENEGRO, label: 'Montenegro' },
            { value: COUNTRY_MOROCCO, label: 'Morocco' },
            { value: COUNTRY_MOZAMBIQUE, label: 'Mozambique' },
            { value: COUNTRY_MYANMAR_BURMA, label: 'Myanmar (Burma)' },
            { value: COUNTRY_NAMIBIA, label: 'Namibia' },
            { value: COUNTRY_NAURU, label: 'Nauru' },
            { value: COUNTRY_NEPAL, label: 'Nepal' },
            { value: COUNTRY_NETHERLANDS, label: 'Netherlands' },
            { value: COUNTRY_NEW_ZEALAND, label: 'New Zealand' },
            { value: COUNTRY_NICARAGUA, label: 'Nicaragua' },
            { value: COUNTRY_NIGER, label: 'Niger' },
            { value: COUNTRY_NIGERIA, label: 'Nigeria' },
            { value: COUNTRY_NORTH_MACEDONIA, label: 'North Macedonia' },
            { value: COUNTRY_NORWAY, label: 'Norway' },
            { value: COUNTRY_OMAN, label: 'Oman' },
            { value: COUNTRY_PAKISTAN, label: 'Pakistan' },
            { value: COUNTRY_PALAU, label: 'Palau' },
            { value: COUNTRY_PANAMA, label: 'Panama' },
            { value: COUNTRY_PAPUA_NEW_GUINEA, label: 'Papua New Guinea' },
            { value: COUNTRY_PARAGUAY, label: 'Paraguay' },
            { value: COUNTRY_PERU, label: 'Peru' },
            { value: COUNTRY_PHILIPPINES, label: 'Philippines' },
            { value: COUNTRY_POLAND, label: 'Poland' },
            { value: COUNTRY_PORTUGAL, label: 'Portugal' },
            { value: COUNTRY_QATAR, label: 'Qatar' },
            { value: COUNTRY_ROMANIA, label: 'Romania' },
            { value: COUNTRY_RUSSIA, label: 'Russia' },
            { value: COUNTRY_RWANDA, label: 'Rwanda' },
            { value: COUNTRY_ST_KITTS_AND_NEVIS, label: 'St Kitts and Nevis' },
            { value: COUNTRY_ST_LUCIA, label: 'St Lucia' },
            { value: COUNTRY_ST_VINCENT, label: 'St Vincent' },
            { value: COUNTRY_SAMOA, label: 'Samoa' },
            { value: COUNTRY_SAN_MARINO, label: 'San Marino' },
            { value: COUNTRY_SAO_TOME_AND_PRINCIPE, label: 'Sao Tome and Principe' },
            { value: COUNTRY_SAUDI_ARABIA, label: 'Saudi Arabia' },
            { value: COUNTRY_SENEGAL, label: 'Senegal' },
            { value: COUNTRY_SERBIA, label: 'Serbia' },
            { value: COUNTRY_SEYCHELLES, label: 'Seychelles' },
            { value: COUNTRY_SIERRA_LEONE, label: 'Sierra Leone' },
            { value: COUNTRY_SINGAPORE, label: 'Singapore' },
            { value: COUNTRY_SLOVAKIA, label: 'Slovakia' },
            { value: COUNTRY_SLOVENIA, label: 'Slovenia' },
            { value: COUNTRY_SOLOMON_ISLANDS, label: 'Solomon Islands' },
            { value: COUNTRY_SOMALIA, label: 'Somalia' },
            { value: COUNTRY_SOUTH_AFRICA, label: 'South Africa' },
            { value: COUNTRY_SOUTH_SUDAN, label: 'South Sudan' },
            { value: COUNTRY_SPAIN, label: 'Spain' },
            { value: COUNTRY_SRI_LANKA, label: 'Sri Lanka' },
            { value: COUNTRY_SUDAN, label: 'Sudan' },
            { value: COUNTRY_SURINAME, label: 'Suriname' },
            { value: COUNTRY_SWEDEN, label: 'Sweden' },
            { value: COUNTRY_SWITZERLAND, label: 'Switzerland' },
            { value: COUNTRY_SYRIA, label: 'Syria' },
            { value: COUNTRY_TAJIKISTAN, label: 'Tajikistan' },
            { value: COUNTRY_TANZANIA, label: 'Tanzania' },
            { value: COUNTRY_THAILAND, label: 'Thailand' },
            { value: COUNTRY_TOGO, label: 'Togo' },
            { value: COUNTRY_TONGA, label: 'Tonga' },
            { value: COUNTRY_TRINIDAD_AND_TOBAGO, label: 'Trinidad and Tobago' },
            { value: COUNTRY_TUNISIA, label: 'Tunisia' },
            { value: COUNTRY_TURKEY, label: 'Turkey' },
            { value: COUNTRY_TURKMENISTAN, label: 'Turkmenistan' },
            { value: COUNTRY_TUVALU, label: 'Tuvalu' },
            { value: COUNTRY_UGANDA, label: 'Uganda' },
            { value: COUNTRY_UKRAINE, label: 'Ukraine' },
            { value: COUNTRY_UNITED_ARAB_EMIRATES, label: 'United Arab Emirates' },
            { value: COUNTRY_UNITED_STATES, label: 'United States' },
            { value: COUNTRY_URUGUAY, label: 'Uruguay' },
            { value: COUNTRY_UZBEKISTAN, label: 'Uzbekistan' },
            { value: COUNTRY_VANUATU, label: 'Vanuatu' },
            { value: COUNTRY_VATICAN_CITY, label: 'Vatican City' },
            { value: COUNTRY_VENEZUELA, label: 'Venezuela' },
            { value: COUNTRY_VIETNAM, label: 'Vietnam' },
            { value: COUNTRY_YEMEN, label: 'Yemen' },
            { value: COUNTRY_ZAMBIA, label: 'Zambia' },
            { value: COUNTRY_ZIMBABWE, label: 'Zimbabwe' },
            { value: COUNTRY_OTHER, label: 'Other' },
        ];
    };
}

export default CountryNameEnum;
