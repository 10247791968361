import React from 'react';
import { Field } from 'redux-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { InputLabel, FormControl } from '@material-ui/core';
import _ from 'lodash';

const DateFieldInput = (props) => {
    const {
        meta: { submitting, error, touched, invalid },
        input: { onBlur, value, name, ...InputProps },
        label,
        dateFormat = 'DD/MM/YYYY',
        fullWidth = false,
        ...others
    } = props;
    if (_.includes(['development', 'qa', 'test'], process.env.NODE_ENV)) {
        InputProps['data-cy'] = `${name}-input`;
    }
    const onChange = (date) => {
        date && InputProps.onChange(date.format('YYYY-MM-DD'));
    };

    return (
        <FormControl variant="outlined" fullWidth={fullWidth}>
            {label && (
                <InputLabel htmlFor="component-outlined" shrink={true}>
                    {label}
                </InputLabel>
            )}
            <KeyboardDatePicker
                id="component-outlined"
                className="form-control"
                {...InputProps}
                {...others}
                format={dateFormat}
                value={value ? new Date(value) : null}
                disabled={submitting}
                error={touched && invalid}
                helperText={touched && error}
                onChange={onChange}
                inputVariant="outlined"
            />
        </FormControl>
    );
};

const DateField = ({
    name = '',
    value = '',
    placeholder = '',
    autoComplete = 'off',
    fullWidth = true,
    InputProps,
    ...rest
}) => {
    return (
        <div className={`text-field ${fullWidth ? 'block' : 'inline-block'}`}>
            <Field
                name={name}
                variant={`outlined`}
                autoComplete={autoComplete}
                component={DateFieldInput}
                fullWidth={fullWidth}
                InputProps={InputProps}
                placeholder={placeholder}
                value={value}
                {...rest}
            />
        </div>
    );
};

export default DateField;
