import BaseEnum from 'pitchblack_react_utils/enums/BaseEnum';

export const STATUS_ACTIVE = 'STATUS_ACTIVE';
export const STATUS_INACTIVE = 'STATUS_INACTIVE';

class StatusEnum extends BaseEnum {
    getTypes = () => {
        return {
            [STATUS_ACTIVE]: 'Active',
            [STATUS_INACTIVE]: 'Inactive',
        };
    };
}

export default StatusEnum;
