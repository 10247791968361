import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goBack, push } from 'react-router-redux';
import _ from 'lodash';
import { Container, Item, P4StyleComponentContainer } from '../../../../../pitch4_layout/components/div';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { Body1, Subtitle1 } from '../../../../../pitch4_layout/components/text';
import { ScreenTitle } from '../../../../../pitch4_layout';
import { DateFormatter, FactValue } from '../../../../../pitch4_layout/components/data';
import { KeyValueTable, KeyValueTableRow } from '../../../../../pitch4_tabular/components/kvtable';
import StatusEnum, {
    STATUS_COMPLETION_REQUESTED,
    STATUS_IN_PROGRESS,
    STATUS_WITHDRAWN,
    STATUS_WITHDRAWN_EDITED,
    STATUS_ON_HOLD,
    STATUS_LEGALS,
    STATUS_COMPLETE,
    STATUS_COMPLETION_REJECTED,
} from '../../enum/LoanEnquiries/StatusEnum';
import { ROLE_BORROWER, ROLE_INTERMEDIARY } from '../../../../authentication/enum/Roles/NameEnum';
import { Loading } from '../../../../../pitch4_layout/components/waiting';
import FactSummaryTable from '../../../../view_loan_enquiry/components/FactSummaryTable';
import LeadsContainer from './LeadsContainer';
import { getMainApplicantName, getStoredDataValueFromArray } from '../../../../view_loan_enquiry/HelperFunctions';
import { LOAN_AMOUNT } from '../../../enum/MatchingRules/MetaKeyEnum';
import { ExpandedDateTimeLayout } from '../../../../../pitch4_layout/components/content';
import Notice, { NOTICE_TYPE_ERROR } from '../../../../../pitch4_layout/components/content/Notice';
import StatusWidget from '../../../../view_lender_loan_enquiry/components/StatusWidget';
import BorStatusBannerNotice from '../../../../bannernotice/containers/BorStatusBannerNotice';
import {
    actionApiSetLeadStatus,
    actionApiSetStatus,
    actionCleanup,
    actionClearStatus,
    actionInitiate,
    actionReloadLoanEnquiry,
    selectorGetLoanEnquiry,
    selectorGetLoanEnquiryMatch,
    selectorGetMatchingCriteriaConfig,
    selectorGetStatus,
    selectorGetSubmitted,
    actionRemoveLoanEnquiryAlert,
    actionSendMessageToLenders,
    actionProcessingMatchApprovals,
} from '../redux/main';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import WithdrawEnquiryConfirm from '../components/WithdrawEnquiryConfirm';
import {
    path,
    ROUTE_BORROWER_EDIT_LOAN_ENQUIRY,
    ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY,
    ROUTE_INTERMEDIARY_LENDERLOANENQUIRY_VIEW,
} from '../../../../../pitch4_routing';
import CompletionRequestedRespondForm from '../components/CompletionRequestedForm';
import CompletionRejectedForm from '../components/CompletionRejectedForm';
import RevisionHistoryContainer from '../../../../../pitch4_layout/components/le_revision_history';
import AdminStatusBannerNotice from '../../../../bannernotice/containers/AdminStatusBannerNotice';
import LoanEnquiryContextMenuWidget from '../components/LoanEnquiryContextMenuWidget';
import LenderMessageDrawer from '../../../../../pitch4_layout/components/lender_message_drawer/LenderMessageDrawer';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import { INTERMEDIARY_LOAN_ENQUIRY } from '../../../../joyride/config';
import { actionSetJoyrideType } from '../../../../joyride/redux';
import { BRAND_NAME, INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import LenderDrawer from '../components/LenderDrawer';
import { Grid } from '@material-ui/core';
import AdminCaseManagementDrawer from '../components/AdminCaseManagementDrawer';
import { Button } from '@material-ui/core';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import DocumentSentModal from '../../../../../pitch4_layout/components/lender_message_drawer/DocumentSentModal';
import { clearChannelAlert } from '../../../../../pitch4_chat/api/channel';
import { clearDocumentsAlert } from '../../../../../pitch4_documents/api/alerts';
import { getLenderLoanEnquiry } from '../api/get_ll_enquiry';
import ContractTermsPopup from '../../../../legal/ContractTermsPopup';
import ContractProgressPopup from '../../../../legal/ContractProgressPopup';
import ContractSigned from '../../../../legal/ContractSigned';
import { getEnquiry } from '../api';
import { signLegalContract } from '../../../../legal/api/sign_legal_contract';
import ContractDownloadPopup from '../../../../legal/ContractDownoadFailedPopup';
import { getDocument } from '../../../../../pitch4_documents/api/get_document';
import { handleDownload } from '../../../../../pitch4_documents/src/components/DownloadButton';
import { BRAND_MAIN_CONTACT } from '../../../../../bootstrap/constants/constants';
import PersonIcon from '@material-ui/icons/Person';
import { getAssignableUsers } from '../../../../../pitch4_api/get_assignable_users';
import AssignToModal from '../../../../../pitch4_elements/loan-enquiry-owner/AssignToModal';
import { setLEAssignee } from '../../../../../pitch4_api/set_le_assignee';
import { removeLEAssignee } from '../../../../../pitch4_api/remove_le_assignee';
import { STATUS_MATCH_REJECTED } from '../../../../../pitch4_enum/enum/LenderLoanEnquiryStatusEnum';
import LenderTermsMatrix from '../../../../../pitch4_layout/components/lender_terms_matrix/LenderTermsMatrix';
import moment from 'moment';
import CompleteModal from '../../../../../pitch4_elements/le-completed/CompleteModal';
import CompleteAlert from '../../../../../pitch4_elements/le-completed/CompleteAlert';
import { formatterInPounds } from '../../../../../pitchblack_react_utils/currency/Formatter';
import { NewEnquiryContent } from '../../../../borrower/view_loan_enquiry/components/NewEnquiryContent';
import { STATUS_DRAFT } from '../../../../borrower/enum/LoanEnquiries/StatusEnum';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import Konami from 'react-konami-code';
import DebugMatch from '../../../../MakeLoanEnquiry/components/Debugging/DebugMatch';

class MasonryStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDebug: false,
            lenderProfileDrawer: {
                open: false,
                anchor: 'right',
                lender: null,
                lenderLoanEnquiry: null,
                handleDrawerFavouriteMatchChange: null,
                handleFavouriteMatchChange: null,
            },
            adminCaseManagementDrawer: {
                open: false,
                anchor: 'right',
                lender: null,
                loanEnquiry: null,
                acmChatAlertActive: false,
                acmChatAlertActiveUpdated: false,
                acmDocumentsAlertActive: false,
                acmDocumentsAlertUpdated: false,
            },
            lenderMessageDrawer: {
                open: false,
                anchor: 'right',
                documentSent: false,
            },
            legal: {
                contractUpdated: false,
                contractSigned: true,
                contractInProgress: false,
                contractPreviouslyContactedLenders: '',
                contract: null,
            },
            lenderMessageDrawOpen: false,
            selectedLenders: [],
            lendersMessage: null,
            messageSent: false,
            tabValue: 0,
            leAlertRemoved: false,
            leAssignedTo: [
                {
                    id: 'no_owner',
                    name: 'No owner',
                },
            ],
            leAssignedToUpdated: false,
            lenderTermsMatrixOpen: false,
        };
    }

    componentDidMount() {
        const {
            match: {
                params: { loanEnquiryId },
            },
            initiate,
            setJoyrideType,
            setProcessingApprovals,
        } = this.props;

        initiate(loanEnquiryId);
        setJoyrideType(INTERMEDIARY_LOAN_ENQUIRY);
        setProcessingApprovals(false);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { acmChatAlertActiveUpdated, acmDocumentsAlertUpdated } = this.state.adminCaseManagementDrawer;
        const { contractUpdated } = this.state.legal;
        const { loanEnquiry } = this.props;
        const { leAssignedToUpdated } = this.state;

        if (loanEnquiry) {
            if (!acmChatAlertActiveUpdated && loanEnquiry.channel) {
                this.setState({
                    adminCaseManagementDrawer: {
                        ...this.state.adminCaseManagementDrawer,
                        acmChatAlertActive: loanEnquiry.channel.alertActive,
                        acmChatAlertActiveUpdated: true,
                    },
                });
            }

            if (!acmDocumentsAlertUpdated) {
                this.setState({
                    adminCaseManagementDrawer: {
                        ...this.state.adminCaseManagementDrawer,
                        acmDocumentsAlertActive: loanEnquiry.adminDocumentsAlertActive,
                        acmDocumentsAlertUpdated: true,
                    },
                });
            }

            if (!contractUpdated) {
                this.setState({
                    legal: {
                        ...this.state.legal,
                        contractUpdated: true,
                        contractSigned: loanEnquiry.contractSigned || false,
                        contractInProgress: loanEnquiry.contractCreationProgress,
                        contract: loanEnquiry.contract || null,
                    },
                });
            }

            if (!leAssignedToUpdated) {
                this.setState({
                    leAssignedTo: this.getAssignees(loanEnquiry.assignees),
                    leAssignedToUpdated: true,
                });
            }
        }
    }

    getAssignees = (assignees) => {
        if (assignees.length === 0) {
            return [
                {
                    id: 'no_owner',
                    name: 'No owner',
                },
            ];
        }

        return [
            {
                id: assignees[0].id,
                name: assignees[0].userProfile.given + ' ' + assignees[0].userProfile.family,
            },
        ];
    };

    componentWillUnmount() {
        const { cleanup, clearStatus } = this.props;
        cleanup();
        clearStatus();
    }

    toggleMessageDrawer = () => {
        if (!this.state.lenderMessageDrawer.open) {
            return this.setState({
                lenderMessageDrawer: {
                    ...this.state.lenderMessageDrawer,
                    open: true,
                },
                adminCaseManagementDrawer: {
                    ...this.state.adminCaseManagementDrawer,
                    open: false,
                },
                lenderProfileDrawer: {
                    ...this.state.lenderProfileDrawer,
                    open: false,
                    lender: null,
                },
            });
        }

        return this.setState({
            lenderMessageDrawer: {
                ...this.state.lenderMessageDrawer,
                open: false,
            },
            adminCaseManagementDrawer: {
                ...this.state.adminCaseManagementDrawer,
                open: false,
            },
            selectedLenders: [],
            lendersMessage: null,
            messageSent: false,
        });
    };

    toggleLenderDrawer = (
        open = false,
        lenderLoanEnquiry = null,
        handleFavouriteMatchChange = null,
        // handleRemoveAlertMatchAlert method is executed in src/apps/borrower/view_loan_enquiry/containers/LeadsContainer.js
        handleRemoveAlertMatchAlert = null
    ) => (event) => {
        this.setState({
            lenderMessageDrawer: {
                ...this.state.lenderMessageDrawer,
                open: false,
            },
            adminCaseManagementDrawer: {
                ...this.state.adminCaseManagementDrawer,
                open: false,
            },
            lenderProfileDrawer: {
                ...this.state.lenderProfileDrawer,
                open: open,
                lenderLoanEnquiry: lenderLoanEnquiry,
                handleFavouriteMatchChange: handleFavouriteMatchChange,
                handleRemoveAlertMatchAlert: handleRemoveAlertMatchAlert,
            },
        });

        if (null !== lenderLoanEnquiry) {
            getLenderLoanEnquiry(lenderLoanEnquiry.id).then((response) => {
                const data = response.lenderLoanEnquiry;
                this.setState({
                    lenderProfileDrawer: {
                        ...this.state.lenderProfileDrawer,
                        handleFavouriteMatchChange: handleFavouriteMatchChange,
                        handleRemoveAlertMatchAlert: handleRemoveAlertMatchAlert,
                        lenderLoanEnquiry: { ...this.state.lenderProfileDrawer.lenderLoanEnquiry, ...data },
                    },
                });
            });
        }
    };

    toggleAdminCaseManagementDrawer = (open = false, loanEnquiry = null) => (event) => {
        this.setState({
            lenderMessageDrawer: {
                ...this.state.lenderMessageDrawer,
                open: false,
            },
            lenderProfileDrawer: {
                ...this.state.lenderProfileDrawer,
                open: false,
            },
            adminCaseManagementDrawer: {
                ...this.state.adminCaseManagementDrawer,
                open: open,
                loanEnquiry: loanEnquiry,
            },
        });
    };

    setLendersMessage = (lendersMessage) => this.setState({ ...lendersMessage, lendersMessage });

    getLendersMessage = () => this.state.lendersMessage;

    setMessageSent = (isMessageSent) => this.setState({ messageSent: isMessageSent });

    getMessageSent = () => this.state.messageSent;

    findRejectedMatches = () => {
        return (this.props.loanEnquiry.lenderLoanEnquiries || [])
            .filter((match) => match.status === STATUS_MATCH_REJECTED)
            .map((match) => match.id);
    };

    handleLenderSelection = (lenders) => {
        if (lenders === null) {
            this.setState({ selectedLenders: [] });
            return;
        }
        this.setState({
            selectedLenders: lenders.filter((lenderId) => {
                return !this.findRejectedMatches().includes(lenderId);
            }),
        });
    };

    handleSubmitCompletionConfirm = (leadId) => () => {
        const { updateLeadStatusOnChange } = this.props;
        updateLeadStatusOnChange(leadId, STATUS_COMPLETE, null, true);
    };

    handleSubmitCompletionReject = (leadId) => ({ rejectReason }) => {
        const { updateLeadStatusOnChange } = this.props;
        updateLeadStatusOnChange(leadId, STATUS_COMPLETION_REJECTED, rejectReason, true);
    };

    handleCompletionRequestedRespond = ({ lenderLoanEnquiryId, lenderName }, loanEnquiryId) => {
        const { setModalComponent, setModalClose, submitted } = this.props;
        if (null !== lenderName && null !== lenderLoanEnquiryId && !submitted) {
            setModalComponent(
                <CompletionRequestedRespondForm
                    lenderName={lenderName}
                    lenderLink={path(ROUTE_INTERMEDIARY_LENDERLOANENQUIRY_VIEW, {
                        loanEnquiryId: loanEnquiryId,
                        lenderLoanEnquiryId: lenderLoanEnquiryId,
                    })}
                    onSubmit={() => {}}
                    handleClose={setModalClose}
                    handleConfirm={this.handleSubmitCompletionConfirm(lenderLoanEnquiryId)}
                    handleReject={() => this.handleCompletionRejectedForm(lenderLoanEnquiryId)}
                />,
                true
            );
        }
    };

    handleCompletionRejectedForm = (lenderLoanEnquiryId) => {
        const { setModalComponent, setModalClose } = this.props;
        if (null !== lenderLoanEnquiryId) {
            setModalComponent(
                <CompletionRejectedForm
                    handleClose={setModalClose}
                    onSubmit={this.handleSubmitCompletionReject(lenderLoanEnquiryId)}
                />,
                true
            );
        }
    };

    handleStatusChange = (status, loanEnquiryId) => (newStatus) => {
        const { updateStatusOnChange, setModalComponent } = this.props;
        const handleConfirmWithdrawal = () => {
            updateStatusOnChange(loanEnquiryId, newStatus);
        };
        if (!newStatus || newStatus === status) {
            return;
        }
        if (newStatus === STATUS_WITHDRAWN) {
            setModalComponent(<WithdrawEnquiryConfirm handleSubmit={handleConfirmWithdrawal} />, true);
        }
    };

    handleTabSelect = (selectedTabIndex = 0) => this.setState({ tabValue: selectedTabIndex });

    toggleDocumentSentModal = (open = false) => {
        this.setState({
            lenderMessageDrawer: {
                ...this.state.lenderMessageDrawer,
                open: false,
                documentSent: open,
            },
            lenderProfileDrawer: {
                ...this.state.lenderProfileDrawer,
                open: false,
            },
            adminCaseManagementDrawer: {
                ...this.state.adminCaseManagementDrawer,
                open: false,
            },
            selectedLenders: [],
        });
    };

    handleACMChatAlert = (acmChatAlertActive = false, channelId = null) => {
        if (channelId) {
            clearChannelAlert(channelId, INTERMEDIARY_URL).then((resp) => {
                this.setState({
                    adminCaseManagementDrawer: {
                        ...this.state.adminCaseManagementDrawer,
                        acmChatAlertActive: false,
                    },
                });
            });
        } else {
            this.setState({
                adminCaseManagementDrawer: {
                    ...this.state.adminCaseManagementDrawer,
                    acmChatAlertActive: false,
                },
            });
        }
    };

    handleACMDocumentsAlert = (leId = null) => {
        if (leId) {
            clearDocumentsAlert(leId, null, INTERMEDIARY_URL).then((resp) => {
                this.setState({
                    adminCaseManagementDrawer: {
                        ...this.state.adminCaseManagementDrawer,
                        acmDocumentsAlertActive: false,
                    },
                });
            });
        }
    };

    handleLegalContractTerms = () =>
        this.setState({
            legal: {
                ...this.state.legal,
                contractUpdated: true,
                contractSigned: true,
            },
        });

    handleLegalContractSigned = () => {
        const { loanEnquiry, session } = this.props;
        const { contractPreviouslyContactedLenders } = this.state.legal;
        const sessionManager = new SessionManager();
        const userRole = sessionManager.getMostSeniorRole().label;
        const userEmail = sessionManager.getSession().getEmail();
        if ('prod' === window._env_.APP_ENV) {
            window.dataLayer.push({
                event: 'contract_signed',
                userDetails: {
                    email: userEmail,
                    userType: userRole,
                    userID: sessionManager.getSession().getId(),
                    timestamp: new Date().toISOString(),
                },
                contractSigned: true,
                loanEnquiryId: loanEnquiry.id,
            });
        }

        signLegalContract(
            loanEnquiry.id,
            contractPreviouslyContactedLenders.length === 0 ? null : contractPreviouslyContactedLenders
        ).then((resp) => {
            let interval = null;

            interval = setInterval(() => {
                if (true === session.isAuthenticated()) {
                    getEnquiry(loanEnquiry.id).then((resp) => {
                        if (false === resp.loanEnquiry.contractCreationProgress) {
                            clearInterval(interval);
                            window.location.reload(false);
                        }
                    });
                } else {
                    clearInterval(interval);
                }
            }, 5000);
        });
    };

    handleContractPreviouslyContactedLenders = (lenders = null) =>
        this.setState({
            legal: {
                ...this.state.legal,
                contractPreviouslyContactedLenders: lenders || '',
            },
        });

    handleLegalContractDownload = () => {
        const { setModalClose, setModalComponent } = this.props;
        const { contract } = this.state.legal;

        if (null !== contract) {
            setModalComponent(
                <ContractDownloadPopup
                    type={'progress'}
                    content={
                        <div className={'progress'}>
                            <Loading />
                            <br />
                            Downloading your contract.
                        </div>
                    }
                />,
                false
            );
            getDocument(contract).then((resp) => {
                handleDownload(resp.document, {
                    onPending: () => () => {},
                    onSuccess: () => setModalClose(),
                    onFailure: () => setModalClose(),
                });
            });
        } else {
            setModalComponent(
                <ContractDownloadPopup
                    type={'failed'}
                    content={
                        <div className={'failed'}>
                            Unfortunately, downloading your contract failed.
                            <br />
                            Please contact our support {BRAND_MAIN_CONTACT}
                        </div>
                    }
                />,
                true
            );
        }
    };

    handleAssignLETo = () => {
        const { setModalComponent, setModalClose, loanEnquiry } = this.props;
        const { leAssignedTo } = this.state;
        getAssignableUsers(INTERMEDIARY_URL).then((resp) => {
            setModalComponent(
                <AssignToModal
                    assignees={resp}
                    setModalClose={setModalClose}
                    userRole={ROLE_INTERMEDIARY}
                    assignedTo={leAssignedTo[0]}
                    handleUpdateLEAssignee={this.handleUpdateLEAssignee(loanEnquiry.id)}
                />,
                true
            );
        });
    };

    handleUpdateLEAssignee = (leId) => (newAssignedTo) => {
        const { setModalClose } = this.props;
        let data;

        if (newAssignedTo.id === 'no_owner') {
            data = removeLEAssignee(INTERMEDIARY_URL, null, leId, [newAssignedTo.id]);
        } else {
            data = setLEAssignee(INTERMEDIARY_URL, null, leId, [newAssignedTo.id]);
        }

        data.then((resp) => {
            this.setState({ leAssignedTo: [newAssignedTo] }, () => setModalClose());
        });
    };

    handleLenderTermsMatrix = (lenderLoanEnquiries) => {
        const { setModalComponent, setModalClose, loanEnquiry, dispatch } = this.props;

        if (true === this.state.lenderTermsMatrixOpen) {
            setModalComponent(
                <LenderTermsMatrix
                    uri={INTERMEDIARY_URL}
                    loanEnquiryId={loanEnquiry.id}
                    lenders={lenderLoanEnquiries || []}
                    selectedLenders={null !== this.state.selectedLenders ? this.state.selectedLenders : []}
                    setModalClose={setModalClose}
                    setModalComponent={setModalComponent}
                    toggleLenderTermsMatrixModal={this.toggleLenderTermsMatrixModal}
                    handleLenderSelection={this.handleLenderSelection}
                    dispatch={dispatch}
                />,
                true
            );
        }
    };

    toggleLenderTermsMatrixModal = (isOpen = false) =>
        this.setState({
            lenderTermsMatrixOpen: isOpen,
        });

    sortMatches = (matches) =>
        matches.sort((first) => {
            if (null !== first.lenderTerms) {
                return -1;
            } else {
                return 1;
            }
        });

    isNetworkBroker = () => {
        const { session } = this.props;
        return session.getRelevantSession().isNetwork();
    };

    setDebug = () => {
        this.setState({
            isDebug: true,
        });
    };

    render() {
        let {
            config,
            loanEnquiry,
            loanEnquiryMatch,
            currentStatus = null,
            removeLoanEnquiryAlert,
            sendMessageToLenders,
            setModalComponent,
            session,
            setModalClose,
            dispatch,
        } = this.props;
        const { acmChatAlertActive, acmDocumentsAlertActive } = this.state.adminCaseManagementDrawer;
        const { contractSigned, contractInProgress, contractPreviouslyContactedLenders } = this.state.legal;
        const { leAlertRemoved, leAssignedTo, isDebug } = this.state;

        if (_.isEmpty(config) || _.isEmpty(loanEnquiry) || _.isEmpty(loanEnquiryMatch)) {
            return (
                <Loading
                    onTimeoutResponse={
                        <Notice message={`Sorry, we cannot display this loan enquiry`} type={NOTICE_TYPE_ERROR} />
                    }
                />
            );
        }

        currentStatus = currentStatus || loanEnquiry.status;

        const userRole = new SessionManager().getMostSeniorRole();
        const roleName = userRole !== null ? userRole.name : ROLE_BORROWER;
        const enquiryWithdrawn = currentStatus === STATUS_WITHDRAWN;
        const enquiryWithdrawnEdited = currentStatus === STATUS_WITHDRAWN_EDITED;
        const completionRequested = currentStatus === STATUS_COMPLETION_REQUESTED;
        let completionRequestData = null;
        let statusBanner = null;
        const lendersMessageAllowed = !session.hasAssumedToken() && loanEnquiry.status !== STATUS_ON_HOLD;
        const enquiryIsDraft = currentStatus === STATUS_DRAFT;
        const loanEnquiryUrl =
            roleName === ROLE_INTERMEDIARY ? ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY : ROUTE_BORROWER_EDIT_LOAN_ENQUIRY;

        if (true === completionRequested) {
            completionRequestData = loanEnquiry.completionRequest;
            this.handleCompletionRequestedRespond(completionRequestData, loanEnquiry.id);
            statusBanner = (
                <BorStatusBannerNotice
                    status={loanEnquiry.status}
                    onClick={() => this.handleCompletionRequestedRespond(completionRequestData)}
                />
            );
        } else if (true === enquiryWithdrawnEdited) {
            statusBanner = <AdminStatusBannerNotice status={loanEnquiry.status} />;
        } else if (true === enquiryIsDraft) {
            statusBanner = (
                <AdminStatusBannerNotice
                    status={loanEnquiry.status}
                    onClick={() =>
                        dispatch(
                            push(
                                path(loanEnquiryUrl, {
                                    loanEnquiryId: loanEnquiry.id,
                                })
                            )
                        )
                    }
                />
            );
        }

        const toStates = [STATUS_WITHDRAWN];
        const editableStates = {
            [STATUS_IN_PROGRESS]: toStates,
        };

        if (false === leAlertRemoved && loanEnquiry.alertActive) {
            this.setState({ leAlertRemoved: true }, () => removeLoanEnquiryAlert(loanEnquiry.id));
        }

        const loanType = loanEnquiry.loanEnquiryData.loanType;

        return (
            <div className={'view-enquiry'}>
                {loanEnquiry.status === STATUS_LEGALS && (
                    <CompleteAlert
                        setModalComponent={setModalComponent}
                        modalComponent={
                            <CompleteModal
                                setModalClose={setModalClose}
                                lenders={loanEnquiryMatch.matched || []}
                                amount={getStoredDataValueFromArray(loanEnquiry.storedData, LOAN_AMOUNT)}
                                url={INTERMEDIARY_URL}
                                loanEnquiryId={loanEnquiry.id}
                            />
                        }
                        header={'Has this customer received funding? - '}
                        text={'Click here to move to completed status as soon funds are received'}
                    />
                )}
                {currentStatus !== STATUS_DRAFT &&
                    (false === contractSigned
                        ? setModalComponent(
                              <ContractTermsPopup
                                  handleLegalContractTerms={this.handleLegalContractTerms}
                                  handleContractPreviouslyContactedLenders={
                                      this.handleContractPreviouslyContactedLenders
                                  }
                                  contractPreviouslyContactedLenders={contractPreviouslyContactedLenders}
                                  applicants={loanEnquiry.loanEnquiryApplicants}
                                  submittedBy={loanEnquiry.submittedBy.userProfile}
                              />,
                              false
                          )
                        : true === contractInProgress
                        ? setModalComponent(
                              <ContractProgressPopup handleLegalContractSigned={this.handleLegalContractSigned} />,
                              false
                          )
                        : null)}
                {this.state.lenderTermsMatrixOpen &&
                    this.handleLenderTermsMatrix(this.sortMatches(loanEnquiryMatch.matched))}
                {this.state.lenderMessageDrawer.documentSent && (
                    <DocumentSentModal
                        modalShow={this.state.lenderMessageDrawer.documentSent}
                        toggleDocumentSentModal={this.toggleDocumentSentModal}
                    />
                )}
                <LenderMessageDrawer
                    anchor={this.state.lenderMessageDrawer.anchor}
                    open={this.state.lenderMessageDrawer.open}
                    selectedLenders={this.state.selectedLenders}
                    toggleMessageDrawer={this.toggleMessageDrawer}
                    setMessageSent={this.setMessageSent}
                    sendMessageToLenders={sendMessageToLenders}
                    getLendersMessage={this.getLendersMessage}
                    setLendersMessage={this.setLendersMessage}
                    getMessageSent={this.getMessageSent}
                    userRole={ROLE_INTERMEDIARY}
                    toggleDocumentSentModal={this.toggleDocumentSentModal}
                />
                {this.state.lenderProfileDrawer.open && (
                    <LenderDrawer
                        anchor={this.state.lenderProfileDrawer.anchor}
                        open={this.state.lenderProfileDrawer.open}
                        toggleLenderDrawer={this.toggleLenderDrawer}
                        lenderLoanEnquiry={this.state.lenderProfileDrawer.lenderLoanEnquiry}
                        channelId={
                            this.state.lenderProfileDrawer.lenderLoanEnquiry &&
                            this.state.lenderProfileDrawer.lenderLoanEnquiry.channel
                                ? this.state.lenderProfileDrawer.lenderLoanEnquiry.channel.id
                                : null
                        }
                        handleFavouriteMatchChange={this.state.lenderProfileDrawer.handleFavouriteMatchChange}
                        handleRemoveAlertMatchAlert={this.state.lenderProfileDrawer.handleRemoveAlertMatchAlert}
                        matches={loanEnquiryMatch.matched || []}
                        loanEnquiry={loanEnquiry}
                        loanType={loanType}
                    />
                )}
                {this.state.adminCaseManagementDrawer.open && (
                    <AdminCaseManagementDrawer
                        anchor={this.state.adminCaseManagementDrawer.anchor}
                        open={this.state.adminCaseManagementDrawer.open}
                        toggleAdminCaseManagementDrawer={this.toggleAdminCaseManagementDrawer}
                        loanEnquiry={this.state.adminCaseManagementDrawer.loanEnquiry}
                        acmChatAlertActive={this.state.adminCaseManagementDrawer.acmChatAlertActive}
                        acmDocumentsAlertActive={this.state.adminCaseManagementDrawer.acmDocumentsAlertActive}
                        handleACMChatAlert={this.handleACMChatAlert}
                        handleACMDocumentsAlert={this.handleACMDocumentsAlert}
                    />
                )}
                {statusBanner}
                <Konami action={this.setDebug} code={[17, 16, 83]}>
                    <></>
                </Konami>
                <Container
                    className={`view-enquiry-header`}
                    justifyContent={`space-between`}
                    spacing={4}
                    alignItems={`center`}
                >
                    <Grid item className={'screen-title'}>
                        <ScreenTitle
                            title={`Loan enquiry: ${getMainApplicantName(loanEnquiry.loanEnquiryApplicants)}`}
                        />
                    </Grid>
                    <Grid item className={'toolbar'} style={{ paddingRight: 0 }}>
                        {loanEnquiry.status === STATUS_COMPLETE && (
                            <Grid item className={'completion-details'}>
                                <div className={'header'}>
                                    Completion
                                    <br />
                                    Details:
                                </div>
                                <div>
                                    <div>
                                        <strong>Completed:</strong>
                                        <span className={'value'}>
                                            {' '}
                                            {loanEnquiry && loanEnquiry.completedDate
                                                ? `on ${moment(loanEnquiry.completedDate).format('Do MMM YYYY')}`
                                                : '-'}
                                        </span>
                                    </div>
                                    <div>
                                        <strong>Final Amount:</strong>
                                        <span className={'value'}>
                                            {' '}
                                            {loanEnquiry && loanEnquiry.finalAmount
                                                ? formatterInPounds(loanEnquiry.finalAmount)
                                                : '-'}
                                        </span>
                                    </div>
                                </div>
                            </Grid>
                        )}
                        <Grid item style={{ marginRight: 20 }}>
                            <StatusWidget
                                onChange={this.handleStatusChange(currentStatus, loanEnquiry.id)}
                                transitions={editableStates}
                                statusEnum={new StatusEnum()}
                                initialValues={{
                                    status: loanEnquiry.status,
                                }}
                                matchStatus={currentStatus}
                                enquiryWithdrawn={enquiryWithdrawn}
                                ignored={false}
                                loanType={loanType}
                            />
                        </Grid>
                        {isDebug === true && (
                            <Grid item style={{ marginRight: 20 }}>
                                <DebugMatch
                                    setModalComponent={setModalComponent}
                                    setModalClose={setModalClose}
                                    style={{
                                        height: 64,
                                        paddingTop: 11,
                                        borderRadius: 2,
                                    }}
                                    loanEnquiry={loanEnquiry}
                                ></DebugMatch>
                            </Grid>
                        )}
                        <Grid item style={{ marginRight: 20 }}>
                            <div>
                                <Button
                                    variant="outlined"
                                    size={'large'}
                                    style={{
                                        height: 64,
                                        paddingTop: 11,
                                        borderRadius: 2,
                                    }}
                                    onClick={() => this.toggleAdminCaseManagementDrawer(true, loanEnquiry)()}
                                >
                                    Admin Case Management
                                    {(acmChatAlertActive || acmDocumentsAlertActive) && (
                                        <Badge
                                            label={'update'}
                                            style={{ position: 'absolute', right: -15, top: -15 }}
                                        />
                                    )}
                                </Button>
                            </div>
                        </Grid>
                        <Grid item>
                            <LoanEnquiryContextMenuWidget
                                toggleMessageDrawer={this.toggleMessageDrawer}
                                loanEnquiry={loanEnquiry}
                                lendersMessageAllowed={lendersMessageAllowed}
                                handleTabSelect={this.handleTabSelect}
                                toggleLenderTermsMatrixModal={this.toggleLenderTermsMatrixModal}
                                selectedLenders={this.state.selectedLenders}
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Container className={`view-enquiry-content`} justifyContent={`space-between`} spacing={4}>
                    <Item xs={12} md={5}>
                        {loanEnquiry.isNewForm === false ? (
                            <Container justifyContent={`space-between`} spacing={4}>
                                <Item xs={12} className={`view-enquiry-summary`} id={'enquiry-summary'}>
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={'Enquiry summary'}
                                        buttons={<div />}
                                        content={
                                            <KeyValueTable style={{ backgroundColor: '#fafafa', border: 'none' }}>
                                                <KeyValueTableRow
                                                    term={<Subtitle1>Loan amount:</Subtitle1>}
                                                    def={
                                                        <Body1>
                                                            <FactValue
                                                                name={LOAN_AMOUNT}
                                                                value={getStoredDataValueFromArray(
                                                                    loanEnquiry.storedData,
                                                                    LOAN_AMOUNT
                                                                )}
                                                                inPennies={true}
                                                            />
                                                        </Body1>
                                                    }
                                                />
                                                <KeyValueTableRow
                                                    term={<Subtitle1>Reference number:</Subtitle1>}
                                                    def={<Body1>{loanEnquiry.borrowerReference}</Body1>}
                                                />
                                                <KeyValueTableRow
                                                    term={<Subtitle1>Created</Subtitle1>}
                                                    def={
                                                        <Body1>
                                                            <ExpandedDateTimeLayout value={loanEnquiry.createdAt} />
                                                        </Body1>
                                                    }
                                                />
                                                <KeyValueTableRow
                                                    term={<Subtitle1>{BRAND_NAME} Contract:</Subtitle1>}
                                                    def={
                                                        <ContractSigned
                                                            contractSigned={contractSigned}
                                                            contractInProgress={contractInProgress}
                                                            handleLegalContractDownload={
                                                                this.handleLegalContractDownload
                                                            }
                                                        />
                                                    }
                                                />
                                                {
                                                    // Network Broker
                                                    ((true === this.isNetworkBroker() &&
                                                        null === loanEnquiry.broker.parentBroker) ||
                                                        // Child Broker
                                                        (false === this.isNetworkBroker() &&
                                                            false === loanEnquiry.broker.isNetwork &&
                                                            null !== loanEnquiry.broker.parentBroker) ||
                                                        // Regular Broker
                                                        (false === this.isNetworkBroker() &&
                                                            false === loanEnquiry.broker.isNetwork &&
                                                            null === loanEnquiry.broker.parentBroker)) && (
                                                        <KeyValueTableRow
                                                            term={<Subtitle1>Enquiry owner:</Subtitle1>}
                                                            def={
                                                                <div
                                                                    className={'assign-to'}
                                                                    onClick={() => this.handleAssignLETo()}
                                                                >
                                                                    <div className={'icon'}>
                                                                        <PersonIcon />
                                                                    </div>
                                                                    <span
                                                                        className={`name-${
                                                                            leAssignedTo[0].id !== 'no_owner'
                                                                                ? 'active'
                                                                                : null
                                                                        }`}
                                                                    >
                                                                        {leAssignedTo[0].name}
                                                                    </span>
                                                                </div>
                                                            }
                                                        />
                                                    )
                                                }
                                                {null !== loanEnquiry.broker.parentBroker && (
                                                    <>
                                                        <KeyValueTableRow
                                                            term={`Brokerage Name`}
                                                            def={
                                                                <span className={`body-1`}>
                                                                    {loanEnquiry.broker.name}
                                                                </span>
                                                            }
                                                        />
                                                        <KeyValueTableRow
                                                            term={`Broker Email`}
                                                            def={
                                                                <span className={`body-1`}>
                                                                    {loanEnquiry.broker.primaryEmail || '-'}
                                                                </span>
                                                            }
                                                        />
                                                        <KeyValueTableRow
                                                            term={`Broker Phone`}
                                                            def={
                                                                <span className={`body-1`}>
                                                                    {null !== loanEnquiry.broker.primaryPhone
                                                                        ? loanEnquiry.broker.primaryPhone.number
                                                                        : '-'}
                                                                </span>
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </KeyValueTable>
                                        }
                                    />
                                </Item>
                                <Item xs={12} className={`view-enquiry-applicants`}>
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={'Applicant details'}
                                        buttons={<div />}
                                        content={
                                            <React.Fragment>
                                                {loanEnquiry.loanEnquiryApplicants.map(
                                                    (loanEnquiryApplicant, index) => (
                                                        <KeyValueTable
                                                            key={`applicant-${index}`}
                                                            elkey={`applicant${index}`}
                                                            style={{ backgroundColor: '#fafafa', border: 'none' }}
                                                        >
                                                            <KeyValueTableRow
                                                                elkey={`applicant--${index}`}
                                                                term={
                                                                    loanEnquiryApplicant.isMainApplicant
                                                                        ? 'Main Applicant Name'
                                                                        : 'Applicant ' + (index + 1) + ' Name'
                                                                }
                                                                def={
                                                                    <span className="text-highlight-green">
                                                                        {loanEnquiryApplicant.applicant.given}{' '}
                                                                        {loanEnquiryApplicant.applicant.family}
                                                                    </span>
                                                                }
                                                            />
                                                            <KeyValueTableRow
                                                                term={`Date of birth`}
                                                                def={
                                                                    <DateFormatter
                                                                        value={
                                                                            loanEnquiryApplicant.applicant.dateOfBirth
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </KeyValueTable>
                                                    )
                                                )}
                                            </React.Fragment>
                                        }
                                    />
                                </Item>
                                <Item xs={12} className={`view-enquiry-info`}>
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={'Enquiry information'}
                                        buttons={<div />}
                                        content={
                                            <FactSummaryTable
                                                config={config}
                                                loanEnquiry={loanEnquiry}
                                                loanEnquiryStoredData={loanEnquiry.storedData}
                                                loanEnquirySubmissionData={JSON.parse(
                                                    loanEnquiry.submissionData['rawSubmission'] || '{}'
                                                )}
                                            />
                                        }
                                    />
                                </Item>
                                <Item xs={12} className={`view-enquiry-summary`}>
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={'Enquiry Revision History'}
                                        buttons={<div />}
                                        content={
                                            <RevisionHistoryContainer
                                                key={'rev_hist_cont'}
                                                elkey={`rev_hist_cont_`}
                                                loanEnquiry={loanEnquiry}
                                                userRole={ROLE_INTERMEDIARY}
                                            />
                                        }
                                    />
                                </Item>
                            </Container>
                        ) : (
                            <>
                                <NewEnquiryContent
                                    loanEnquiry={loanEnquiry}
                                    contractSigned={contractSigned}
                                    contractInProgress={contractInProgress}
                                    handleLegalContractDownload={this.handleLegalContractDownload}
                                    currentStatus={currentStatus}
                                    additionalItems={
                                        <Item xs={12} className={`view-enquiry-summary`} id={'enquiry-summary'}>
                                            <P4StyleComponentContainer
                                                styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                                header={'Broker summary'}
                                                buttons={<div />}
                                                content={
                                                    <KeyValueTable
                                                        style={{ backgroundColor: '#fafafa', border: 'none' }}
                                                    >
                                                        {
                                                            // Network Broker
                                                            ((true === this.isNetworkBroker() &&
                                                                null === loanEnquiry.broker.parentBroker) ||
                                                                // Child Broker
                                                                (false === this.isNetworkBroker() &&
                                                                    false === loanEnquiry.broker.isNetwork &&
                                                                    null !== loanEnquiry.broker.parentBroker) ||
                                                                // Regular Broker
                                                                (false === this.isNetworkBroker() &&
                                                                    false === loanEnquiry.broker.isNetwork &&
                                                                    null === loanEnquiry.broker.parentBroker)) && (
                                                                <KeyValueTableRow
                                                                    term={<Subtitle1>Enquiry owner:</Subtitle1>}
                                                                    def={
                                                                        <div
                                                                            className={'assign-to'}
                                                                            onClick={() => this.handleAssignLETo()}
                                                                        >
                                                                            <div className={'icon'}>
                                                                                <PersonIcon />
                                                                            </div>
                                                                            <span
                                                                                className={`name-${
                                                                                    leAssignedTo[0].id !== 'no_owner'
                                                                                        ? 'active'
                                                                                        : null
                                                                                }`}
                                                                            >
                                                                                {leAssignedTo[0].name}
                                                                            </span>
                                                                        </div>
                                                                    }
                                                                />
                                                            )
                                                        }
                                                        {null !== loanEnquiry.broker.parentBroker && (
                                                            <>
                                                                <KeyValueTableRow
                                                                    term={`Brokerage Name`}
                                                                    def={
                                                                        <span className={`body-1`}>
                                                                            {loanEnquiry.broker.name}
                                                                        </span>
                                                                    }
                                                                />
                                                                <KeyValueTableRow
                                                                    term={`Broker Email`}
                                                                    def={
                                                                        <span className={`body-1`}>
                                                                            {loanEnquiry.broker.primaryEmail || '-'}
                                                                        </span>
                                                                    }
                                                                />
                                                                <KeyValueTableRow
                                                                    term={`Broker Phone`}
                                                                    def={
                                                                        <span className={`body-1`}>
                                                                            {null !== loanEnquiry.broker.primaryPhone
                                                                                ? loanEnquiry.broker.primaryPhone.number
                                                                                : '-'}
                                                                        </span>
                                                                    }
                                                                />
                                                            </>
                                                        )}
                                                    </KeyValueTable>
                                                }
                                            />
                                        </Item>
                                    }
                                />
                            </>
                        )}
                    </Item>
                    <Item className={'view-enquiry-matches'} xs={12} md={7}>
                        <LeadsContainer
                            contractSigned={contractSigned}
                            contractInProgress={contractInProgress}
                            loanEnquiryId={loanEnquiry.id}
                            matches={this.sortMatches(loanEnquiryMatch.matched) || []}
                            loanEnquiryStatus={loanEnquiry.status}
                            handleLenderSelection={this.handleLenderSelection}
                            selectedLenders={this.state.selectedLenders}
                            lendersMessageAllowed={lendersMessageAllowed}
                            matchCount={loanEnquiryMatch.matched.length || 0}
                            userIsVerified={session.getSession().isVerified()}
                            toggleLenderDrawer={this.toggleLenderDrawer}
                            loanType={loanType}
                        />
                    </Item>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        config: selectorGetMatchingCriteriaConfig(state),
        loanEnquiry: selectorGetLoanEnquiry(state),
        loanEnquiryMatch: selectorGetLoanEnquiryMatch(state),
        currentStatus: selectorGetStatus(state),
        submitted: selectorGetSubmitted(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        initiate: (loanEnquiryId) => dispatch(actionInitiate(loanEnquiryId)),
        reloadLoanEnquiry: () => dispatch(actionReloadLoanEnquiry()),
        cleanup: () => dispatch(actionCleanup()),
        updateLeadStatusOnChange: (leadId, newStatus, metadata, refetchMatches) => {
            dispatch(actionApiSetLeadStatus(leadId, newStatus, metadata, refetchMatches));
        },
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        updateStatusOnChange: (leadId, newStatus) => {
            dispatch(actionApiSetStatus(leadId, newStatus));
        },
        clearStatus: () => dispatch(actionClearStatus()),
        removeLoanEnquiryAlert: (status) => {
            dispatch(actionRemoveLoanEnquiryAlert(status));
        },
        sendMessageToLenders: (lenders, message, toggleMessageDrawer, setMessageSent) => () => {
            dispatch(actionSendMessageToLenders(lenders, message, toggleMessageDrawer, setMessageSent));
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
        setProcessingApprovals: (processing_approvals) =>
            dispatch(actionProcessingMatchApprovals(processing_approvals)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withSession,
    renderErrorIfAuthorisationFail([ROLE_INTERMEDIARY])
)(MasonryStyleContainer);
