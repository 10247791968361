import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import _ from 'lodash';
import { mapErrors } from '../../../pitch4_forms/utils/Pitch4ConstraintErrors';
import { VALIDATION_ERROR_DELAY } from '../constants/constants';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { sagaActionRedirectToDashboard } from './dashboard';
import { logout } from '../api/logout';
import { actionSetAcceptedPrivacyValue } from '../../landing/redux/landing';
import { sagaActionEndAssumedIdentitySession } from './assumedIdentity';
import { twoFactorAuthLogin } from '../api/two_factor_auth_login';
import { actionHubspotTrackingIdentifyVisitor, actionHubspotTrackingRemoveCookie } from '../../hubspot/redux/tracking';
import {
    actionHideValidationErrors,
    actionLoginSuccess,
    actionRedirectSuccess,
    actionSetTwoFactorAuthValidationErrors,
    actionSetValidationErrors,
    AUTHORISATION_TOKEN_FAILED,
    LOGIN_SET_VALIDATION_ERRORS,
    LOGIN_SUCCESS,
    LOGOUT as OLD_LOGOUT,
    selectorGetGotoPath,
    SHOW_2FA_FORM,
    SUBMIT_2FA_FORM,
} from '../redux/login';
import { ROUTE_ANON_2FA_LOGIN, ROUTE_ANON_LOGIN } from '../../../pitch4_routing';
import { SAGA_ACTION_SUCCESS } from '../../notifications/sagas/container';

let sessionManager = new SessionManager();

export const LOGOUT = 'sagas.authentication.logout_action';
export const sagaActionLogout = (targetUrl = '/') => ({
    type: LOGOUT,
    targetUrl,
});

//Worker Sagas
function* setValidationErrors(action) {
    //wait before hiding
    yield delay(VALIDATION_ERROR_DELAY);
    yield put(actionHideValidationErrors());
}

function* handleLoginSuccess(action) {
    const gotoPath = yield select(selectorGetGotoPath);

    if (gotoPath !== null) {
        yield put(actionRedirectSuccess());
        yield put(push(gotoPath));
    } else {
        yield put(sagaActionRedirectToDashboard());
    }
}

function* handleLogout(action) {
    logout(sessionManager.getToken().token);
    sessionManager.logout();
    yield put(actionSetAcceptedPrivacyValue(false));
    yield put(actionHubspotTrackingRemoveCookie());
    yield put(push(action.targetUrl));
}

function* handleAuthorisationFailed(action) {
    if (_.get(action, 'networkError.result.message') === 'Assumed identity session has expired') {
        yield put(sagaActionEndAssumedIdentitySession(true));
    } else {
        //log out of frontend
        sessionManager.logout();

        //redirect to login screen
        yield put(push(ROUTE_ANON_LOGIN));

        yield put(
            actionSetValidationErrors([
                {
                    message: 'Authorisation token was not valid - please login again.',
                },
            ])
        );
    }
}

function* showTwoFactorAuthForm(action) {
    yield put(push(ROUTE_ANON_2FA_LOGIN));
}

function* submitTwoFactorAuthLogin(action) {
    const { user, code } = action;

    const response = yield call(twoFactorAuthLogin, user, code);

    switch (response.getClassName()) {
        case 'TwoFactorAuthLoginSuccess':
            const data = response.getData();

            sessionManager.login(data.token, data.session);

            if (data.assumedIdentity !== null) {
                sessionManager.assumedIdentity(data.assumedIdentity.token, data.assumedIdentity.session);
            }

            yield put({ type: SAGA_ACTION_SUCCESS, message: 'Login successful' });
            yield put(actionLoginSuccess());
            yield put(actionHubspotTrackingIdentifyVisitor(user.username, {}));
            break;

        case 'TwoFactorAuthLoginErrors':
            yield put(actionSetTwoFactorAuthValidationErrors(mapErrors(response.getErrors())));
            break;

        default:
            break;
    }
}

//Main Saga
function* main() {
    yield takeLatest(LOGIN_SET_VALIDATION_ERRORS, setValidationErrors);
    yield takeLatest(LOGIN_SUCCESS, handleLoginSuccess);
    yield takeLatest(OLD_LOGOUT, handleLogout);
    yield takeLatest(LOGOUT, handleLogout);
    yield takeLatest(AUTHORISATION_TOKEN_FAILED, handleAuthorisationFailed);
    yield takeLatest(SHOW_2FA_FORM, showTwoFactorAuthForm);
    yield takeLatest(SUBMIT_2FA_FORM, submitTwoFactorAuthLogin);
}

export default main;
