import React, { useState } from 'react';
import { sendChatMessage } from '../../pitch4_api/send_chat_message';
import moment from 'moment';
import SessionManager from '../../pitchblack_react_utils/session/SessionManager';
import { Button } from '@material-ui/core';
import { STATUS_IN_PROGRESS } from '../../pitch4_enum/enum/LenderLoanEnquiryStatusEnum';

export default function RequestLenderDetails(props) {
    const {
        chatChannel,
        handleTabChange,
        chatTabIndex,
        sendMessageURL,
        updateLLEStatus = null,
        matchSelected = null,
        updateLLELocalStatus = null,
    } = props;
    const [sendingMessage, setSendingMessage] = useState(false);
    const session = new SessionManager();
    const buildMessage = () => {
        return `${session.getRelevantSession().getFullname()} has requested more details at ${moment().format(
            'h:mma'
        )} on ${moment().format('dddd Do MMM')}. This Lender will get back to you as soon as possible.`;
    };
    const handleClick = () => {
        setSendingMessage(true);
        sendChatMessage(sendMessageURL, buildMessage(), chatChannel).then((resp) => {
            if (null !== resp) {
                setSendingMessage(false);
                handleTabChange(null, chatTabIndex);

                if (updateLLEStatus && matchSelected && updateLLELocalStatus) {
                    updateLLEStatus(matchSelected, STATUS_IN_PROGRESS);
                    updateLLELocalStatus(STATUS_IN_PROGRESS);
                }
            }
        });
    };

    return (
        <div className={'request-lender-container'}>
            <div className={'header'}>Find out more about this lender?</div>
            <Button className={'btn'} onClick={handleClick} disabled={true === sendingMessage || null === chatChannel}>
                {true === sendingMessage ? 'REQUESTING DETAILS' : 'REQUEST DETAILS'}
            </Button>
        </div>
    );
}
