import React from 'react';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { SubmissionError } from 'redux-form';

import ChangePasswordForm from '../form/ChangePasswordForm';
import { NoQueryMutation } from 'pitch4_forms/components';
import { API_URL } from 'bootstrap/constants/constants';
import { ROLE_SUPER_ADMIN } from '../../../../../authentication/enum/Roles/NameEnum';
import { withRouter } from 'react-router-dom';

class ChangeUserPasswordContainer extends React.Component {
    renderFormFunction = (mutate, props) => {
        return <ChangePasswordForm onSubmit={this.onSubmit(mutate, props)} />;
    };

    render() {
        return (
            <NoQueryMutation
                mutationQuery={gql`
                    mutation changeUserPassword($id: ID!, $input: pitch4admin_changeUserPasswordInput!) {
                        changeUserPassword(id: $id, input: $input) {
                            response
                        }
                    }
                `}
                renderFormFunction={this.renderFormFunction}
            />
        );
    }

    onSubmit = (mutate, props) => (values) => {
        let { match } = this.props;

        let { notificationSuccess, notificationError } = props;

        let { newPassword } = values;

        return mutate({
            variables: {
                id: match.params.id,
                input: {
                    newPassword,
                },
            },
            context: {
                uri: API_URL,
            },
        })
            .then(({ data }) => {
                notificationSuccess('Password changed successfully!');
            })
            .catch((error) => {
                notificationError('Unable to change password.');
                throw new SubmissionError({ _error: error.graphQLErrors });
            });
    };
}

export default compose(withRouter, renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN]))(ChangeUserPasswordContainer);
