import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Grid } from '@material-ui/core';
import { formatCurrency } from '../../../../../pitch4_layout/components/data/formatter/CurrencyFormatter';
import BorrowerQuestions, {
    QUESTION_ADDITIONAL_INFO,
    QUESTION_AGREE_TO_TERMS,
    QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
    QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
    QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
    QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
    QUESTION_APPLICANT_MAIN_NATIONALITY,
    QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
    QUESTION_APPLICANT_MAIN_OCCUPATION,
    QUESTION_BUILD_COSTS_BORROWING,
    QUESTION_BUSINESS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
    QUESTION_BUSINESS_ASSET_HIRE_PURCHASE_OR_LEASE,
    QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
    QUESTION_BUSINESS_FACILITY_NEEDED_BY,
    QUESTION_BUSINESS_INVOICE_FINANCE_TYPE,
    QUESTION_BUSINESS_IS_SECURE_FINANCE,
    QUESTION_BUSINESS_NAME,
    QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY,
    QUESTION_BUSINESS_OUTSTANDING_DEBT,
    QUESTION_BUSINESS_OUTSTANDING_INVOICES,
    QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
    QUESTION_BUSINESS_PDQ_INCOME,
    QUESTION_BUSINESS_PROFITABLE,
    QUESTION_BUSINESS_REFINANCE,
    QUESTION_BUSINESS_SEASONAL,
    QUESTION_BUSINESS_SECTOR,
    QUESTION_BUSINESS_TRADING_LENGTH,
    QUESTION_BUSINESS_TYPE,
    QUESTION_BUSINESS_WEBSITE,
    QUESTION_BUY_TO_LET_EXPERIENCE,
    QUESTION_COMMERCIAL_OWNER_OCCUPIED,
    QUESTION_COMMERCIAL_TENANT_AMOUNT,
    QUESTION_COMMERCIAL_TYPE_OTHER,
    QUESTION_COMMERCIAL_TYPES,
    QUESTION_COMMERCIAL_UNIT_COUNT,
    QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN,
    QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
    QUESTION_CURRENT_VALUE,
    QUESTION_DAY_ONE_LOAN_AMOUNT,
    QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE,
    QUESTION_DEVELOPMENT_LENGTH,
    QUESTION_EPC_RATING,
    QUESTION_EXIT_STRATEGY,
    QUESTION_EXPECTED_END_VALUE,
    QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE,
    QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
    QUESTION_FIRST_CHARGE_AMOUNT,
    QUESTION_FUNDING_TYPE,
    QUESTION_HMO_BEDROOMS,
    QUESTION_HMO_HAS_LICENSE,
    QUESTION_HMO_LICENSE_IS_REQUIRED,
    QUESTION_HMO_ROOMS_SELF_CONTAINED,
    QUESTION_IS_CASH_ADVANCE,
    QUESTION_IS_INVOICE_FINANCE,
    QUESTION_LENDER,
    QUESTION_LOAN_AMOUNT,
    QUESTION_LOAN_AMOUNT_REFURBISHMENT,
    QUESTION_LOAN_NEEDED_BY,
    QUESTION_LOAN_PURPOSE,
    QUESTION_LOAN_PURPOSE_MEZZANINE,
    QUESTION_LOAN_TERM,
    QUESTION_LOAN_TERM_BUSINESS_TERM,
    QUESTION_LOAN_TYPE,
    QUESTION_LOAN_USAGE_LOCATION,
    QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
    QUESTION_OCCUPY_PROPERTY,
    QUESTION_OUTSTANDING_LOAN,
    QUESTION_OUTSTANDING_LOAN_AMOUNT,
    QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY,
    QUESTION_OWN_ANY_OTHER_PROPERTY,
    QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES,
    QUESTION_PERCENTAGE_COMMERCIAL_VALUE,
    QUESTION_PERCENTAGE_COMMERCIAL_VALUE_DEVELOPMENT,
    QUESTION_PERCENTAGE_RESIDENTIAL,
    QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
    QUESTION_PERCENTAGE_RESIDENTIAL_VALUE,
    QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT,
    QUESTION_PLANNING_FOR,
    QUESTION_PLANNING_IN_PLACE,
    QUESTION_PORTFOLIO_PROPERTIES,
    QUESTION_PORTFOLIO_REFINANCE,
    QUESTION_PORTFOLIO_VALUE,
    QUESTION_PRE_APPROVE,
    QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
    QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE,
    QUESTION_PROPERTY_ADDRESS,
    QUESTION_PROPERTY_LOCATION,
    QUESTION_PROPERTY_LOCATION_BUSINESS,
    QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
    QUESTION_PROPERTY_LOCATION_OTHER,
    QUESTION_PROPERTY_OWNERSHIP,
    QUESTION_PROPERTY_POSTCODE,
    QUESTION_PROPERTY_TYPE,
    QUESTION_PROPERTY_TYPE_MEZZANINE,
    QUESTION_PROPERTY_TYPE_PORTFOLIO,
    QUESTION_PROPERTY_TYPE_REFURBISHMENT,
    QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
    QUESTION_REFURBISHMENT_LENGTH,
    QUESTION_RENTAL_INCOME,
    QUESTION_RENTAL_INCOME_PORTFOLIO,
    QUESTION_RENTAL_INCOME_RESIDENTIAL,
    QUESTION_RESIDENTIAL_REFINANCE,
    QUESTION_RESIDENTIAL_UNIT_COUNT,
    QUESTION_RESIDENTIAL_UNIT_VALUE,
    QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
    QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
    QUESTION_SITE_DESCRIPTION,
    QUESTION_SITE_INTENTIONS,
    QUESTION_SITE_INTENTIONS_MEZZANINE,
    QUESTION_SITE_INTENTIONS_REFURBISHMENT,
    QUESTION_TEAM_DEVELOPMENT_EXPERIENCE,
    QUESTION_TOTAL_DEVELOPMENT_COSTS,
    QUESTION_TURN_OVER,
    QUESTION_UNITS_PRE_SOLD,
    QUESTION_WORKS_EXPLANATION,
} from '../../../enums/Borrower/BorrowerQuestions';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_INTERMEDIARY, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';
import BorrowerLoanType, {
    BRIDGE_TYPE,
    BUSINESS_TYPE,
    DEVELOPMENT_TYPE,
    MEZZANINE_TYPE,
    PORTFOLIO_TYPE,
    PROPERTY_TYPE,
    REFURBISHMENT_TYPE,
    SECOND_CHARGE_TYPE,
    TERM_TYPE,
} from '../../../enums/Borrower/BorrowerLoanType';
import BrokerLoanType, { ASSET_TYPE } from '../../../enums/Broker/BrokerLoanType';
import { filterTokens } from '../../../../utils/filter-tokens';
import SecuredUnsecuredFinance from '../../../enums/Shared/SecuredUnsecuredFinance';
import YesNo, { NO, YES } from '../../../enums/Shared/YesNo';
import FacilityNeededBy from '../../../enums/Shared/FacilityNeededBy';
import PropertyLocation from '../../../enums/Shared/PropertyLocation';
import PortfolioRefinance from '../../../enums/Shared/PortfolioRefinance';
import InvoiceFinancingTypes from '../../../enums/Shared/InvoiceFinancingTypes';
import LeaseHirePurchaseTypes from '../../../enums/Shared/LeaseHirePurchaseTypes';
import BusinessTypes from '../../../enums/Shared/BusinessTypes';
import CommercialStructure from '../../../enums/Shared/CommercialStructure';
import BusinessLoanPurposes from '../../../enums/Shared/BusinessLoanPurposes';
import EPCRatings from '../../../enums/Shared/EPCRatings';
import LoanPurposes from '../../../enums/Shared/LoanPurposes';
import LoanNeededBy from '../../../enums/Shared/LoanNeededBy';
import CommercialFundingType, { LAND_WITHOUT_PLANNING_FUNDING_TYPE } from '../../../enums/Shared/CommercialFundingType';
import OwnAnyOtherProperty from '../../../enums/Shared/OwnAnyOtherProperty';
import Ownership from '../../../enums/Shared/Ownership';
import CommercialTypes from '../../../enums/Shared/CommercialTypes';
import CountryNameEnum from '../../../../../pitch4_enum/enum/CountryNameEnum';
import NationalityEnum from '../../../../../pitch4_enum/enum/NationalityEnum';
import CitizenshipTypes from '../../../enums/Shared/CitizenshipTypes';
import CommercialTypeEnum from '../../../../admin/enum/matching/enum/CommercialTypeEnum';
import AssetRefinance from '../../../enums/Shared/AssetRefinance';

class SummaryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
            funding: [QUESTION_LOAN_TYPE, QUESTION_FUNDING_TYPE, QUESTION_BUSINESS_IS_SECURE_FINANCE],
            propertyType: [
                QUESTION_PROPERTY_TYPE,
                QUESTION_PROPERTY_TYPE_MEZZANINE,
                QUESTION_PROPERTY_TYPE_PORTFOLIO,
                QUESTION_PROPERTY_TYPE_REFURBISHMENT,
                QUESTION_RESIDENTIAL_REFINANCE,
                QUESTION_PORTFOLIO_REFINANCE,
            ],
            propertySelection: [
                QUESTION_OCCUPY_PROPERTY,
                QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
                QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
                QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
                QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES,
                QUESTION_OUTSTANDING_LOAN_AMOUNT,
                QUESTION_LOAN_PURPOSE_MEZZANINE,
                QUESTION_LOAN_AMOUNT,
                QUESTION_LOAN_TERM,
                QUESTION_LOAN_NEEDED_BY,
            ],
            propertyInformation: [
                QUESTION_CURRENT_VALUE,
                QUESTION_DAY_ONE_LOAN_AMOUNT,
                QUESTION_OWN_ANY_OTHER_PROPERTY,
                QUESTION_PROPERTY_ADDRESS,
                QUESTION_PROPERTY_POSTCODE,
                QUESTION_PROPERTY_LOCATION,
                QUESTION_PROPERTY_LOCATION_OTHER,
                QUESTION_PROPERTY_OWNERSHIP,
            ],
            businessDetails: [
                QUESTION_BUSINESS_REFINANCE,
                QUESTION_BUSINESS_SEASONAL,
                QUESTION_BUSINESS_TYPE,
                QUESTION_BUSINESS_NAME,
                QUESTION_BUSINESS_WEBSITE,
                QUESTION_BUSINESS_TRADING_LENGTH,
                QUESTION_BUSINESS_SECTOR,
                QUESTION_BUSINESS_FACILITY_NEEDED_BY,
                QUESTION_PROPERTY_LOCATION_BUSINESS,
                QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
                QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES,
                QUESTION_BUSINESS_OUTSTANDING_INVOICES,
                QUESTION_BUSINESS_INVOICE_FINANCE_TYPE,
                QUESTION_BUSINESS_ASSET_HIRE_PURCHASE_OR_LEASE,
                QUESTION_IS_CASH_ADVANCE,
                QUESTION_IS_INVOICE_FINANCE,
                QUESTION_BUSINESS_PDQ_INCOME,
            ],
            businessFinances: [
                QUESTION_BUSINESS_ADVERSE_CREDIT,
                QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
                QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
                QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
                QUESTION_BUSINESS_OUTSTANDING_DEBT,
                QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
                QUESTION_BUSINESS_PROFITABLE,
                QUESTION_LOAN_AMOUNT,
                QUESTION_LOAN_PURPOSE,
                QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
                QUESTION_TURN_OVER,
                QUESTION_LOAN_TERM,
                QUESTION_LOAN_TERM_BUSINESS_TERM,
            ],
            portfolio: [
                QUESTION_LOAN_AMOUNT,
                QUESTION_LOAN_NEEDED_BY,
                QUESTION_LOAN_TERM,
                QUESTION_OUTSTANDING_LOAN_AMOUNT,
                QUESTION_PORTFOLIO_PROPERTIES,
                QUESTION_PORTFOLIO_REFINANCE,
                QUESTION_PORTFOLIO_VALUE,
                QUESTION_RENTAL_INCOME_PORTFOLIO,
            ],
            bridge: [
                QUESTION_BUY_TO_LET_EXPERIENCE,
                QUESTION_COMMERCIAL_OWNER_OCCUPIED,
                QUESTION_COMMERCIAL_TYPE_OTHER,
                QUESTION_COMMERCIAL_TYPES,
                QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
                QUESTION_HMO_BEDROOMS,
                QUESTION_HMO_HAS_LICENSE,
                QUESTION_HMO_LICENSE_IS_REQUIRED,
                QUESTION_HMO_ROOMS_SELF_CONTAINED,
                QUESTION_SITE_DESCRIPTION,
                QUESTION_SITE_INTENTIONS,
            ],
            refurbishment: [
                QUESTION_COMMERCIAL_OWNER_OCCUPIED,
                QUESTION_COMMERCIAL_TYPE_OTHER,
                QUESTION_COMMERCIAL_TYPES,
                QUESTION_COMMERCIAL_UNIT_COUNT,
                QUESTION_DAY_ONE_LOAN_AMOUNT,
                QUESTION_EXPECTED_END_VALUE,
                QUESTION_HMO_BEDROOMS,
                QUESTION_HMO_HAS_LICENSE,
                QUESTION_HMO_LICENSE_IS_REQUIRED,
                QUESTION_LOAN_AMOUNT_REFURBISHMENT,
                QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
                QUESTION_PERCENTAGE_RESIDENTIAL_VALUE,
                QUESTION_PERCENTAGE_COMMERCIAL_VALUE,
                QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE,
                QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
                QUESTION_REFURBISHMENT_LENGTH,
                QUESTION_RENTAL_INCOME_RESIDENTIAL,
                QUESTION_SITE_DESCRIPTION,
                QUESTION_SITE_INTENTIONS_REFURBISHMENT,
            ],
            secondCharge: [
                QUESTION_COMMERCIAL_UNIT_COUNT,
                QUESTION_LENDER,
                QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
                QUESTION_RENTAL_INCOME,
            ],
            development: [
                QUESTION_BUILD_COSTS_BORROWING,
                QUESTION_COMMERCIAL_TYPE_OTHER,
                QUESTION_COMMERCIAL_TYPES,
                QUESTION_DAY_ONE_LOAN_AMOUNT,
                QUESTION_DEVELOPMENT_LENGTH,
                QUESTION_EPC_RATING,
                QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE,
                QUESTION_OUTSTANDING_LOAN,
                QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
                QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT,
                QUESTION_PERCENTAGE_COMMERCIAL_VALUE_DEVELOPMENT,
                QUESTION_PLANNING_IN_PLACE,
                QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
                QUESTION_TEAM_DEVELOPMENT_EXPERIENCE,
                QUESTION_TOTAL_DEVELOPMENT_COSTS,
                QUESTION_UNITS_PRE_SOLD,
                QUESTION_WORKS_EXPLANATION,
            ],
            mezzanine: [
                QUESTION_COMMERCIAL_TYPE_OTHER,
                QUESTION_COMMERCIAL_TYPES,
                QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE,
                QUESTION_DEVELOPMENT_LENGTH,
                QUESTION_EXPECTED_END_VALUE,
                QUESTION_FIRST_CHARGE_AMOUNT,
                QUESTION_LENDER,
                QUESTION_PERCENTAGE_RESIDENTIAL,
                QUESTION_PLANNING_IN_PLACE,
                QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
                QUESTION_SITE_INTENTIONS_MEZZANINE,
                QUESTION_TOTAL_DEVELOPMENT_COSTS,
            ],
            credit: [
                QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
                QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
                QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
                QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
                QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
                QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
                QUESTION_APPLICANT_MAIN_NATIONALITY,
                QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
                QUESTION_APPLICANT_MAIN_OCCUPATION,
                QUESTION_EXIT_STRATEGY,
            ],
            term: [
                QUESTION_RENTAL_INCOME_RESIDENTIAL,
                QUESTION_RESIDENTIAL_UNIT_COUNT,
                QUESTION_HMO_HAS_LICENSE,
                QUESTION_HMO_LICENSE_IS_REQUIRED,
                QUESTION_HMO_BEDROOMS,
                QUESTION_RESIDENTIAL_UNIT_VALUE,
                QUESTION_PERCENTAGE_RESIDENTIAL_VALUE,
                QUESTION_PERCENTAGE_COMMERCIAL_VALUE,
                QUESTION_COMMERCIAL_OWNER_OCCUPIED,
                QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY,
                QUESTION_COMMERCIAL_UNIT_COUNT,
                QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN,
                QUESTION_COMMERCIAL_TENANT_AMOUNT,
                QUESTION_COMMERCIAL_TYPES,
                QUESTION_COMMERCIAL_TYPE_OTHER,
                QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY,
            ],
            additional: [QUESTION_ADDITIONAL_INFO],
            pre_approve: [QUESTION_PRE_APPROVE],
            confirmation: [QUESTION_LOAN_USAGE_LOCATION, QUESTION_AGREE_TO_TERMS],
        };
    }

    renderSummaryForSection = (field) => {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const loanTypes =
            userRole === ROLE_BORROWER ? new BorrowerLoanType().getTypes() : new BrokerLoanType().getTypes();
        const securedUnsecuredOptions = new SecuredUnsecuredFinance().getOptions();
        const yesNoOptions = new YesNo().getOptions();
        const facilityNeededByOptions = new FacilityNeededBy().getOptions();
        const propertyLocations = new PropertyLocation().getOptions();
        const businessRefinanceOptions = new PortfolioRefinance().getBusinessOptionTypes();
        const assetRefinanceOptions = new AssetRefinance().getBusinessOptionTypes();
        const invoiceFinanceOptions = new InvoiceFinancingTypes().getOptions();
        const leaseHirePurchaseOptions = new LeaseHirePurchaseTypes().getOptions();
        const businessTypes = new BusinessTypes().getOptions();
        const commercialStructure = new CommercialStructure().getOptions();
        const businessFinanceLoanPurpose = new BusinessLoanPurposes().getOptions();
        const propertyFinanceLoanPurpose = new LoanPurposes().getOptions();
        const loanNeededByOptions = new LoanNeededBy().getOptions();
        const ownAnyOtherPropertyOptions = new OwnAnyOtherProperty().getOptions();
        const portfolioRefinanceOptions = new PortfolioRefinance().getPortfolioOptions();
        const ownershipOptions = new Ownership().getOptions();
        const commercialFundingTypes = new CommercialFundingType().getTypes();
        const commercialUnitTypes = new CommercialTypeEnum().getTypes();
        const commercialPlanningTypeOptions = new CommercialTypes().getSummaryPlanningOptions();
        const countryOptions = new CountryNameEnum().getTypes();
        const nationalityOptions = new NationalityEnum().getTypes();
        const citizenshipTypes = new CitizenshipTypes().getOptions();
        const epcRatingTypes = new EPCRatings().getOptions();
        const mainApplicant = this.state.formData.applicants.main;
        let value = '';
        let label = '';

        if (field === null) {
            return <></>;
        }

        if (!(field in questions)) {
            return <></>;
        }

        if (!(questions[field].fieldName in this.state.formData)) {
            return <></>;
        }

        let fieldValue = this.state.formData[questions[field].fieldName];
        if (fieldValue === false || fieldValue === 'false') {
            fieldValue = NO;
        }
        if (fieldValue === true || fieldValue === 'true') {
            fieldValue = YES;
        }
        if (fieldValue === '0' || fieldValue === 0) {
            return <></>;
        }

        if (QUESTION_ADDITIONAL_INFO !== field && fieldValue === '') {
            return <></>;
        }
        if (QUESTION_ADDITIONAL_INFO === field && fieldValue === '') {
            value = 'No additional information provided.';
        }
        if (
            QUESTION_PROPERTY_TYPE === field &&
            [MEZZANINE_TYPE, PORTFOLIO_TYPE, REFURBISHMENT_TYPE, DEVELOPMENT_TYPE].includes(
                this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName]
            )
        ) {
            return <></>;
        }
        if (
            QUESTION_PROPERTY_TYPE_MEZZANINE === field &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== MEZZANINE_TYPE
        ) {
            return <></>;
        }
        if (
            QUESTION_PROPERTY_TYPE_PORTFOLIO === field &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE
        ) {
            return <></>;
        }
        if (
            QUESTION_PROPERTY_TYPE_REFURBISHMENT === field &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== REFURBISHMENT_TYPE &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== DEVELOPMENT_TYPE
        ) {
            return <></>;
        }
        if (
            QUESTION_PORTFOLIO_REFINANCE === field &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE
        ) {
            return <></>;
        }
        if (
            QUESTION_RESIDENTIAL_REFINANCE === field &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === PORTFOLIO_TYPE
        ) {
            return <></>;
        }
        if (
            QUESTION_LOAN_TERM_BUSINESS_TERM === field &&
            this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] !== BUSINESS_TYPE
        ) {
            return <></>;
        }
        if (
            QUESTION_LOAN_PURPOSE === field &&
            this.state.formData[questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName] !== YES
        ) {
            return <></>;
        }
        switch (field) {
            case QUESTION_LOAN_TYPE:
                label = questions[field].label;
                value = loanTypes[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_FUNDING_TYPE:
                if (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === null) {
                    return <></>;
                }
                label =
                    'What is the main purpose for this [bold|' +
                    loanTypes[this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName]].label.toLowerCase() +
                    '] finance?';
                value = loanTypes[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_OCCUPY_PROPERTY:
                let property = 'property';
                let on_wording = 'in';
                if (
                    (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === BRIDGE_TYPE &&
                        this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] ===
                            LAND_WITHOUT_PLANNING_FUNDING_TYPE) ||
                    (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === DEVELOPMENT_TYPE &&
                        this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] ===
                            LAND_WITHOUT_PLANNING_FUNDING_TYPE)
                ) {
                    property = 'site';
                    on_wording = 'on';
                }
                label = 'Do you intend to live ' + on_wording + ' the ' + property + '?';
                if (userRole === ROLE_INTERMEDIARY) {
                    label = 'Does your client intend to live ' + on_wording + ' the ' + property + '?';
                }
                value = yesNoOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_BUSINESS_IS_SECURE_FINANCE:
                label = questions[field].label;
                value = securedUnsecuredOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_BUSINESS_REFINANCE:
                label = questions[field].label;
                if (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === ASSET_TYPE) {
                    value = assetRefinanceOptions[this.state.formData[questions[field].fieldName]].label;
                } else {
                    value = businessRefinanceOptions[this.state.formData[questions[field].fieldName]].label;
                }
                break;
            case QUESTION_COMMERCIAL_TYPES:
                const multiselectValues = this.state.formData[questions[field].fieldName];
                label = questions[field].label;
                let mappedValues = [];
                for (const multiselectValue of multiselectValues) {
                    mappedValues.push(commercialUnitTypes[multiselectValue]);
                }
                value = mappedValues.join(', ');
                break;
            case QUESTION_PLANNING_FOR:
                label = questions[field].label;
                value = commercialPlanningTypeOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_PRE_APPROVE:
            case QUESTION_BUSINESS_SEASONAL:
            case QUESTION_BUSINESS_PROFITABLE:
            case QUESTION_BUSINESS_OUTSTANDING_DEBT:
            case QUESTION_BUSINESS_ADVERSE_CREDIT:
            case QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT:
            case QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS:
            case QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES:
            case QUESTION_OCCUPY_GREATER_THAN_40_PERCENT:
            case QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY:
            case QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES:
            case QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY:
            case QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY:
            case QUESTION_COMMERCIAL_OWNER_OCCUPIED:
            case QUESTION_HMO_HAS_LICENSE:
            case QUESTION_HMO_LICENSE_IS_REQUIRED:
            case QUESTION_PLANNING_IN_PLACE:
            case QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE:
            case QUESTION_PORTFOLIO_REFINANCE:
            case QUESTION_LOAN_USAGE_LOCATION:
            case QUESTION_TEAM_DEVELOPMENT_EXPERIENCE:
            case QUESTION_UNITS_PRE_SOLD:
            case QUESTION_BUY_TO_LET_EXPERIENCE:
            case QUESTION_COMMERCIAL_UNITS_RENTED_OUT:
            case QUESTION_HMO_ROOMS_SELF_CONTAINED:
            case QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE:
                label = questions[field].label;
                value = yesNoOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_BUSINESS_TYPE:
                label = questions[field].label;
                value = commercialStructure[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_BUSINESS_SECTOR:
                label = questions[field].label;
                value = businessTypes[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_BUSINESS_FACILITY_NEEDED_BY:
                label = questions[field].label;
                value = facilityNeededByOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_PROPERTY_LOCATION_BUSINESS:
            case QUESTION_PROPERTY_LOCATION:
                label = questions[field].label;
                value = propertyLocations[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_BUSINESS_INVOICE_FINANCE_TYPE:
                label = questions[field].label;
                value = invoiceFinanceOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_OWN_ANY_OTHER_PROPERTY:
                label = questions[field].label;
                value = ownAnyOtherPropertyOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_PROPERTY_OWNERSHIP:
                label = questions[field].label;
                value = ownershipOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_BUSINESS_ASSET_HIRE_PURCHASE_OR_LEASE:
                label = questions[field].label;
                value = leaseHirePurchaseOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_LOAN_NEEDED_BY:
                label = questions[field].label;
                value = loanNeededByOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_RESIDENTIAL_REFINANCE:
                label = questions[field].label;
                value = portfolioRefinanceOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_EPC_RATING:
                label = questions[field].label;
                value = epcRatingTypes[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_PROPERTY_TYPE:
                label = questions[field].label;
                value = commercialFundingTypes[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_PROPERTY_TYPE_REFURBISHMENT:
                label = questions[field].label;
                value = commercialFundingTypes[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_PROPERTY_TYPE_MEZZANINE:
                label = questions[field].label;
                value = commercialFundingTypes[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_PROPERTY_TYPE_PORTFOLIO:
                label = questions[field].label;
                value = commercialFundingTypes[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_LOAN_PURPOSE:
            case QUESTION_LOAN_PURPOSE_MEZZANINE:
                if (this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === BUSINESS_TYPE) {
                    label = questions[field].label;
                    value = businessFinanceLoanPurpose[this.state.formData[questions[field].fieldName]].label;
                } else {
                    label = questions[field].label;
                    value = propertyFinanceLoanPurpose[this.state.formData[questions[field].fieldName]].label;
                }
                break;
            case QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS:
                label = 'What is' + mainApplicant.given + ' ' + mainApplicant.family + "'s citizenship status?";
                value = citizenshipTypes[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE:
                label = 'What country does ' + mainApplicant.given + ' ' + mainApplicant.family + ' reside in?';
                value = countryOptions[this.state.formData[questions[field].fieldName]];
                break;
            case QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES:
                label = 'Has ' + mainApplicant.given + ' ' + mainApplicant.family + ' had any credit difficulties?';
                value = yesNoOptions[this.state.formData[questions[field].fieldName]].label;
                break;
            case QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME:
                label = 'What is ' + mainApplicant.given + ' ' + mainApplicant.family + ' annual gross income?';
                value = this.state.formData[questions[field].fieldName];
                break;
            case QUESTION_APPLICANT_MAIN_NATIONALITY:
                label = 'What is ' + mainApplicant.given + ' ' + mainApplicant.family + "'s nationality?";
                value = nationalityOptions[this.state.formData[questions[field].fieldName]];
                break;
            case QUESTION_APPLICANT_MAIN_OCCUPATION:
                label = 'What is ' + mainApplicant.given + ' ' + mainApplicant.family + ' occupation?';
                value = this.state.formData[questions[field].fieldName];
                break;
            default:
                label = questions[field].label;
                value = this.state.formData[questions[field].fieldName];
                break;
        }
        return (
            <>
                <Grid item xs={12} md={6}>
                    {filterTokens(label)}
                </Grid>
                <Grid item xs={12} md={4}>
                    {this.valueTypeConverter(questions[field].type, filterTokens(value))}
                </Grid>
            </>
        );
    };

    valueTypeConverter = (fieldType, value) => {
        switch (fieldType) {
            case 'currency':
                return formatCurrency({
                    value: value,
                });
            case 'percent':
                return `${value}%`;
            default:
                return value;
        }
    };

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">
                        Summary
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    Applicants
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box style={{ marginBottom: 10 }}>{this.state.formData['applicants']['main']['label']}</Box>
                    {this.state.formData['applicants']['sub'].length > 0 && (
                        <>
                            <Box>Sub applicants:</Box>
                            <Box>
                                {this.state.formData['applicants']['sub'].map((sa) => (
                                    <div>- {sa.label}</div>
                                ))}
                            </Box>
                        </>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <h4>Funding</h4>
                </Grid>
                {this.state.funding.map((item) => {
                    return this.renderSummaryForSection(item);
                })}
                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === BUSINESS_TYPE && (
                    <>
                        <Grid item xs={12}>
                            <h4>Business Details</h4>
                        </Grid>
                        {this.state.businessDetails.map((item) => {
                            return this.renderSummaryForSection(item);
                        })}
                        <Grid item xs={12}>
                            <h4>Business Financials</h4>
                        </Grid>
                        {this.state.businessFinances.map((item) => {
                            return this.renderSummaryForSection(item);
                        })}
                    </>
                )}
                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE && (
                    <>
                        <Grid item xs={12}>
                            <h4>Property Type</h4>
                        </Grid>
                        {this.state.propertyType.map((item) => {
                            return this.renderSummaryForSection(item);
                        })}
                        <Grid item xs={12}>
                            <h4>Property Information</h4>
                        </Grid>
                        {this.state.propertyInformation.map((item) => {
                            return this.renderSummaryForSection(item);
                        })}
                    </>
                )}
                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === PORTFOLIO_TYPE && (
                        <>
                            <Grid item xs={12}>
                                <h4>Portfolio Financing</h4>
                            </Grid>
                            {this.state.portfolio.map((item) => {
                                return this.renderSummaryForSection(item);
                            })}
                        </>
                    )}
                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE && (
                        <>
                            <Grid item xs={12}>
                                <h4>Property Financing</h4>
                            </Grid>
                            {this.state.propertySelection.map((item) => {
                                return this.renderSummaryForSection(item);
                            })}
                        </>
                    )}
                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === TERM_TYPE && (
                        <>
                            <Grid item xs={12}>
                                <h4>Term Financing</h4>
                            </Grid>
                            {this.state.term.map((item) => {
                                return this.renderSummaryForSection(item);
                            })}
                        </>
                    )}
                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === BRIDGE_TYPE && (
                        <>
                            <Grid item xs={12}>
                                <h4>Bridging Financing</h4>
                            </Grid>
                            {this.state.bridge.map((item) => {
                                return this.renderSummaryForSection(item);
                            })}
                        </>
                    )}
                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === REFURBISHMENT_TYPE && (
                        <>
                            <Grid item xs={12}>
                                <h4>Refurbishment Financing</h4>
                            </Grid>
                            {this.state.refurbishment.map((item) => {
                                return this.renderSummaryForSection(item);
                            })}
                        </>
                    )}
                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === SECOND_CHARGE_TYPE && (
                        <>
                            <Grid item xs={12}>
                                <h4>Second Charge Financing</h4>
                            </Grid>
                            {this.state.secondCharge.map((item) => {
                                return this.renderSummaryForSection(item);
                            })}
                        </>
                    )}
                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === DEVELOPMENT_TYPE && (
                        <>
                            <Grid item xs={12}>
                                <h4>Development Financing</h4>
                            </Grid>
                            {this.state.development.map((item) => {
                                return this.renderSummaryForSection(item);
                            })}
                        </>
                    )}
                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === MEZZANINE_TYPE && (
                        <>
                            <Grid item xs={12}>
                                <h4>Mezzanine Financing</h4>
                            </Grid>
                            {this.state.mezzanine.map((item) => {
                                return this.renderSummaryForSection(item);
                            })}
                        </>
                    )}
                <Grid item xs={12}>
                    <h4>Credit & Citizenship</h4>
                </Grid>
                {this.state.credit.map((item) => {
                    return this.renderSummaryForSection(item);
                })}
                <Grid item xs={12}>
                    <h4>Additional Information</h4>
                </Grid>
                {this.state.additional.map((item) => {
                    return this.renderSummaryForSection(item);
                })}
                <Grid item xs={12}>
                    <h4>Pre-Approve Lenders</h4>
                </Grid>
                {this.state.pre_approve.map((item) => {
                    return this.renderSummaryForSection(item);
                })}
                <Grid item xs={12}>
                    <h4>Terms and Conditions</h4>
                </Grid>
                {this.state.confirmation.map((item) => {
                    return this.renderSummaryForSection(item);
                })}
            </Grid>
        );
    }
}

export default SummaryContainer;
