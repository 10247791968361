import { BORROWER_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const getSMSVerificationCode = (uri = BORROWER_URL, userId, phoneNumber, osName, browserName) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation getSMSVerificationCode($id: ID!, $phoneNumber: String, $osName: String, $browserName: String) {
          getSMSVerificationCode(id: $id, phoneNumber: $phoneNumber, osName: $osName, browserName: $browserName) {
             success
          }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            id: userId,
            phoneNumber: phoneNumber,
            osName: osName,
            browserName: browserName,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
