import createP4ApolloFetch from '../../../bootstrap/middlewares';

const GetEnquiryQuery = `
    query getLoanEnquiry($loanEnquiryId: ID!) {
      loanEnquiry(loanEnquiryId: $loanEnquiryId) {
        id
        borrowerReference
        statistic {
            finalised
            approved
            pending
            considered
            rejected
            createdAt
            updatedAt
        }
        loanEnquiryApplicants {
            isMainApplicant
            applicant {
                id
                given
                family
                dateOfBirth
                createdAt
                updatedAt
            }
        }
        submissionData {
            rawSubmission
        }
        isNewForm
        loanEnquiryData {
            loanToValue
            loanToCost
            loanToGrossDevelopmentValue
            dayOneLoanAmountPercentage
            additionalInformation
            rentalIncomeResidential
            hmoBedroom
            hmoRoomsSelfContained
            hmoHasLicense
            hmoNeedsLicense
            buyToLetExperience
            rentalIncomeCommercial
            rentalIncomePortfolio
            rentalIncome
            commercialUnitsRentedOut
            commercialUnitOwnerOccupied
            commercialUnitAmount
            commercialType
            commercialTypeOther
            siteDescription
            siteIntention
            refinance
            businessSeasonal
            businessType
            businessName
            businessWebsite
            businessTradingLength
            businessSector
            businessFacilityNeededBy
            businessFacilityTerm
            businessCurrentPaymentTerms
            businessOutstandingInvoices
            businessPdqIncome
            propertyType
            loanAmount
            loanPurpose
            turnOver
            businessProfitable
            businessOverdraftFacility
            businessOutstandingDebt
            businessAdverseCredit
            businessAdverseCreditExplanation
            businessDirectorsAdverseCredit
            businessDirectorsAdverseCreditExplanation
            shareholdersAreHomeOwners
            propertyAvailableForAdditionalSecurity
            planningFor
            developmentLength
            refurbishmentLength
            outstandingLoan
            dayOneLoanAmount
            unitsPreSold
            worksExplanation
            totalDevelopmentCosts
            buildCostsBorrowing
            expectedGrossDevelopmentValue
            previousDevelopmentExperience
            previousRefurbishmentExperience
            teamDevelopmentExperience
            epcRating
            loanType
            fundingType
            businessSecuredFinance
            loanUsedInUkEu
            planningInPlace
            endValue
            percentageResidential
            percentageCommercial
            lender
            firstChargeAmount
            currentValue
            properties
            portfolioValue
            portfolioAlreadyOwned
            loanTerm
            loanNeededBy
            preApproveMatches
            loanAmountRefurbishment
            percentageResidentialSquareFeet
            percentageResidentialValue
            percentageCommercialValue
            residentialUnitCount
            residentialUnitValue
            businessOccupyEntireProperty
            commercialUnitRentBreakdown
            commercialTenantAmount
            ownsOtherCommercialProperty
            ownOtherProperties
            occupyProperty
            ownsOtherInvestmentProperties
            familyHasOccupied
            secondCharge
            occupyingGreaterThan40Percent
            location
            locationOther
            address
            postcode
            propertyOwnership
            refurbishmentCost
            invoiceFinanceType
            leaseHirePurchase
            mainApplicantCreditDifficulties
            mainApplicantCreditDifficultiesExplanation
            mainApplicantCitizenshipStatus
            mainApplicantCountryOfResidence
            mainApplicantCountryOfResidenceOther
            mainApplicantNationality
            mainApplicantNationalityOther
            mainApplicantOccupation
            mainApplicantGrossIncome
            mainApplicantExitStrategy
            agreeToTerms
        }
        storedData {
            id
            meta {
              key
              type
            }
            value
            createdAt
            updatedAt
        }
        status
        createdAt
        updatedAt
        completionRequest {
            lenderLoanEnquiryId
            lenderName
        }
      }
    }
`;

export const getEnquiry = (uri, { loanEnquiryId }) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    return apolloFetch({
        query: GetEnquiryQuery,
        variables: {
            loanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
