import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Avatar } from '@material-ui/core';

const StyledAvatar = styled(Avatar)`
    width: 20,
    height: 20
`;

/**
 * Formats an integer as percentage.
 */
class BooleanFormatter extends React.Component {
    render() {
        let { value } = this.props;

        return <StyledAvatar style={{ backgroundColor: value ? 'green' : 'red' }} />;
    }
}

BooleanFormatter.propTypes = {
    value: PropTypes.bool.isRequired,
};

export default BooleanFormatter;
