import React from 'react';
import CenteringContainer from './CenteringContainer';
import Item from './Item';
import PropTypes from 'prop-types';

class Centered extends React.Component {
    render() {
        let { children, className = '', containerProps = {}, style = {}, ...itemProps } = this.props;

        return (
            <CenteringContainer {...containerProps}>
                <Item {...itemProps} style={style} className={className}>
                    {children}
                </Item>
            </CenteringContainer>
        );
    }
}

Centered.propTypes = {
    children: PropTypes.any.isRequired,
    itemProps: PropTypes.any,
    containerProps: PropTypes.any,
};

export default Centered;
