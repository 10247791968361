import { BaseEnum } from '../../pitch4_enum';
import bridgingFinanceIcon from '../../assets/images/product-bridging-finance-icon.svg';
import businessFinanceIcon from '../../assets/images/product-business-finance-icon.svg';
import commMortIcon from '../../assets/images/product-comm-mortg-icon.svg';
import hmoIcon from '../../assets/images/product-hmo-icon.svg';
import propertyDevelopmentIcon from '../../assets/images/product-property-development-icon.svg';
import resiInvestIcon from '../../assets/images/product-resi-invest-icon.svg';
import careHomeIcon from '../../assets/images/product-care-home-icon.svg';
import { BRAND_NAME } from '../../bootstrap/constants/constants';

export const PRODUCT_COMMERCIAL_MORTGAGES = 'commercial-mortgages';
export const PRODUCT_RESIDENTIAL_INVESTMENT_PROPERTIES = 'residential-investment-properties';
export const PRODUCT_BRIDGING_FINANCE = 'bridging-finance';
export const PRODUCT_BRIDGING_TO_LET = 'bridging-to-let';
export const PRODUCT_TERM_LOAN = 'term-loan';
export const PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS = 'property-development-and-refurbishments';
export const PRODUCT_HMO_LENDING = 'hmo-lending';
export const PRODUCT_BUSINESS_FINANCE = 'business-finance';
export const PRODUCT_CARE_HOME = 'care-home';

const configs = {
    [PRODUCT_COMMERCIAL_MORTGAGES]: {
        pageTitle: 'Funding for Commercial Mortgages',
        formHeaderText: 'Secure your <span class="strong">Commercial <br> Mortgage</span> Funding Now...',
        name: 'commercial-mortgages',
        icon: commMortIcon,
        headerText: 'Funding for <br> Commercial <br> Mortgages',
        subHeaderText: `Source the best lenders for your loan enquiry <br/> with ${BRAND_NAME}. Secure your funding fast!`,
    },
    [PRODUCT_RESIDENTIAL_INVESTMENT_PROPERTIES]: {
        pageTitle: 'Funding for Residential Investment Properties',
        formHeaderText: 'Secure your <span class="strong">Residential Investment <br> Property</span> Funding Now...',
        name: 'residential-investment-properties',
        icon: resiInvestIcon,
        headerText: 'Funding for <br> Residential <br> Investment <br> Properties',
        subHeaderText: `Source the best lenders for your loan enquiry <br/> with ${BRAND_NAME}. Secure your funding fast!`,
    },
    [PRODUCT_BRIDGING_FINANCE]: {
        pageTitle: 'Your finance needs are our funding solutions',
        formHeaderText: 'Secure your <span class="strong">Bridging Finance <br> Funding</span> Now...',
        name: 'bridging-finance',
        icon: bridgingFinanceIcon,
        headerText: 'Your finance needs <br> are our <br> funding solutions',
        subHeaderText: `See why ${BRAND_NAME} is a breakthrough for better borrowing - now`,
    },
    [PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS]: {
        pageTitle: 'Funding for Property Development',
        formHeaderText: 'Secure your <span class="strong">Property Development</span> <br> Funding Now...',
        name: 'property-development-and-refurbishments',
        icon: propertyDevelopmentIcon,
        headerText: 'Funding for <br> Property <br> Development',
        subHeaderText: `Source the best lenders for your loan enquiry <br/> with ${BRAND_NAME}. Secure your funding fast!`,
    },
    [PRODUCT_HMO_LENDING]: {
        pageTitle: 'Funding for HMO Lending',
        formHeaderText: 'Secure your <span class="strong">HMO Funding</span> Now...',
        name: 'hmo-lending',
        icon: hmoIcon,
        headerText: 'Funding for <br> HMO Lending',
        subHeaderText: `Source the best lenders for your loan enquiry <br/> with ${BRAND_NAME}. Secure your funding fast!`,
    },
    [PRODUCT_BUSINESS_FINANCE]: {
        pageTitle: 'Funding for Business Finance',
        formHeaderText: 'Secure your <span class="strong">Business Finance</span> <br> Funding Now...',
        name: 'business-finance',
        icon: businessFinanceIcon,
        headerText: 'Funding for <br> Business <br> Finance',
        subHeaderText: `Source the best lenders for your loan enquiry <br/> with ${BRAND_NAME}. Secure your funding fast!`,
    },
    [PRODUCT_CARE_HOME]: {
        pageTitle: 'Funding for Care Home',
        formHeaderText: 'Secure your <span class="strong">Care Home</span> <br> Funding Now...',
        name: 'care-home',
        icon: careHomeIcon,
        headerText: 'Funding for <br> Care Home',
        subHeaderText: `Source the best lenders for your loan enquiry <br/> with ${BRAND_NAME}. Secure your funding fast!`,
    },
};

export const getConfig = (product) => {
    return configs[product];
};

class ProductEnum extends BaseEnum {
    getTypes = () => {
        return {
            [PRODUCT_COMMERCIAL_MORTGAGES]: 'commercial-mortgages',
            [PRODUCT_RESIDENTIAL_INVESTMENT_PROPERTIES]: 'Residential Investment Properties',
            [PRODUCT_BRIDGING_FINANCE]: 'bridging-finance',
            [PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS]: 'property-development-and-refurbishments',
            [PRODUCT_HMO_LENDING]: 'hmo-lending',
            [PRODUCT_BUSINESS_FINANCE]: 'business-finance',
            [PRODUCT_CARE_HOME]: 'care-home',
        };
    };

    getNames = () => {
        return {
            [PRODUCT_COMMERCIAL_MORTGAGES]: 'Commercial Mortgages',
            [PRODUCT_RESIDENTIAL_INVESTMENT_PROPERTIES]: 'Residential Investment Properties',
            [PRODUCT_BRIDGING_FINANCE]: 'Bridging Finance',
            [PRODUCT_BRIDGING_TO_LET]: 'Bridging to Let',
            [PRODUCT_TERM_LOAN]: 'Term Loan',
            [PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS]: 'Property Development and Refurbishments',
            [PRODUCT_HMO_LENDING]: 'HMP Lending',
            [PRODUCT_BUSINESS_FINANCE]: 'Business Finance',
            [PRODUCT_CARE_HOME]: 'Care Home',
        };
    };

    getName = (type) => this.getNames()[type];
}

export default ProductEnum;
