import React from 'react';
import PropTypes from 'prop-types';
import Snackbar from '@material-ui/core/Snackbar';
import Message from '../components/Message';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
    actionNotifcationSetVisibility,
    selectorNotifcationLevel,
    selectorNotifcationVisibilty,
} from '../redux/container';

class Container extends React.Component {
    handleClose = () => {
        let { handleClose } = this.props;
        handleClose();
    };

    render() {
        let { visible, level } = this.props;
        let type = level ? level.getType() : null;
        let message = level ? level.getMessage() : '';

        if (message === '') {
            return null;
        }

        return (
            <div className={`snackbar`}>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={visible}
                    onClose={this.handleClose}
                >
                    <Message onClose={this.handleClose} variant={type} message={message} />
                </Snackbar>
            </div>
        );
    }
}

Container.propTypes = {
    visible: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        visible: selectorNotifcationVisibilty(state),
        level: selectorNotifcationLevel(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleClose: () => {
            dispatch(actionNotifcationSetVisibility(false));
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Container);
