import React from 'react';
import moment from 'moment';
import LoanTypeEnum from '../../apps/admin/slam/enum/LoanEnquiries/LoanTypeEnum';
import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { push } from 'react-router-redux';
import { path, ROUTE_SLAM_LEAD } from '../../pitch4_routing';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Badge } from '../../pitch4_layout/components/badge/Badge';
import LeadStatusEnum from '../../apps/admin/slam/enum/LenderLoanEnquiries/StatusEnum';
import AnonDots from '../anon-dots/AnonDots';
import { calculateLoanType } from '../../apps/MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';

class LECard extends React.Component {
    render() {
        const { le, dispatch, type } = this.props;

        const loanTypeEnum = new LoanTypeEnum();
        const leadStatusEnum = new LeadStatusEnum();

        const getLoanType = (le) => {
            if (le.isNewForm) {
                return calculateLoanType(le.loanEnquiryData).label;
            } else {
                if (le.storedData === null || typeof le.storedData === 'undefined') {
                    return 'N/A';
                }
                const data = le.storedData.find((item) => item.meta.key === 'LOAN_TYPE');
                return loanTypeEnum.getType(data.value);
            }
        };

        const getLTV = (le) => {
            if (le.isNewForm) {
                const data = le.loanEnquiryData.loanToValue;
                return data ? data : '-';
            } else {
                if (le.storedData === null || typeof le.storedData === 'undefined') {
                    return 'N/A';
                }
                const data = le.storedData.find((item) => item.meta.key === 'LOAN_LTV');
                if (typeof data === 'undefined') {
                    return 'N/A';
                }
                return data.value;
            }
        };

        const getNeededBy = (le) => {
            if (le.isNewForm) {
                if (le.storedData === null || typeof le.storedData === 'undefined') {
                    return 'N/A';
                }
                const data = le.loanEnquiryData.loanNeededBy;
                if (typeof data === 'undefined') {
                    return 'N/A';
                }
                return data.split('_');
            } else {
                if (le.storedData === null || typeof le.storedData === 'undefined') {
                    return 'N/A';
                }
                const data = le.storedData.find((item) => item.meta.key === 'LOAN_NEEDED_BY');
                if (typeof data === 'undefined') {
                    return 'N/A';
                }
                return data.value.split('_');
            }
        };

        const getUserRole = (le) => {
            return le.loanEnquiry.submittedBy.roles[0].label;
        };

        const getLELoanAmount = (le) => {
            let loanAmount = null;
            const formatter = new Intl.NumberFormat('en-UK', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 0,
            });
            if (true === le.isNewForm) {
                loanAmount = le.loanEnquiryData.loanAmount;
                return loanAmount ? formatter.format(loanAmount) : '-';
            } else {
                if (le.storedData === null || typeof le.storedData === 'undefined') {
                    return 'N/A';
                }
                loanAmount = le.storedData.find((item) => item.meta.key === 'LOAN_AMOUNT');
                if (typeof loanAmount === 'undefined') {
                    return 'N/A';
                }
                return loanAmount ? formatter.format(loanAmount.value / 100) : '-'; // Amount in pennies
            }
        };

        const getLEAdditionalNotes = (le) => {
            if (le.isNewForm) {
                const data = le.loanEnquiryData.additionalInformation;
                return data ? data.substring(0, 140) + '...' : null;
            } else {
                if (le.storedData === null || typeof le.storedData === 'undefined') {
                    return 'N/A';
                }
                const data = le.storedData.find((item) => item.meta.key === 'ADDITIONAL_INFO_NOTES');
                return data ? data.value.substring(0, 140) + '...' : null;
            }
        };

        return (
            <Grid item lg={4} md={4} sm={6} xs={12}>
                <Paper
                    elevation={0}
                    className={'lead-card'}
                    onClick={() => {
                        dispatch(push(path(ROUTE_SLAM_LEAD, { lenderLoanEnquiryId: le.id })));
                    }}
                >
                    <div className={'lead'}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className={'amount'}>
                                <div>
                                    {getLELoanAmount(le.loanEnquiry) === 'N/A'
                                        ? 'Not Available'
                                        : getLELoanAmount(le.loanEnquiry)}
                                </div>
                            </div>
                            {le.alertActive && (
                                <div>
                                    <Badge label={'update'} />
                                </div>
                            )}
                        </div>
                        <div className={'loan-type'}>{getLoanType(le.loanEnquiry)}</div>
                        <div className={'applicant'}>
                            <div className={'name'}>
                                {true === leadStatusEnum.isAnonStatus(le.status) ? (
                                    <AnonDots count={7} />
                                ) : (
                                    le.loanEnquiryApplicants[0].applicant.given +
                                    ' ' +
                                    le.loanEnquiryApplicants[0].applicant.family
                                )}
                            </div>
                        </div>
                        <div className={`user-type user-type-${getUserRole(le).toLowerCase()}`}>{getUserRole(le)}</div>
                        {type === 'new' && (
                            <>
                                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0' }}>
                                    <div className={'ltv'}>
                                        <div className={'label'}>LTV</div>
                                        <div className={'value'}>
                                            {getLTV(le.loanEnquiry) === 'N/A'
                                                ? 'Not Available'
                                                : getLTV(le.loanEnquiry) + '%'}
                                        </div>
                                    </div>
                                    <div className={'needed-by'}>
                                        <div className={'label'}>Needed By</div>
                                        <div className={'value'}>
                                            {getNeededBy(le.loanEnquiry) === 'N/A'
                                                ? 'Not Available'
                                                : getNeededBy(le.loanEnquiry)[0] +
                                                  '-' +
                                                  getNeededBy(le.loanEnquiry)[1] +
                                                  ' Days'}
                                        </div>
                                    </div>
                                </div>
                                {getLEAdditionalNotes(le.loanEnquiry) && (
                                    <div className={'notes'}>
                                        <div className={'label'}>LOAN NOTES</div>
                                        <div className={'note'}>{getLEAdditionalNotes(le.loanEnquiry)}</div>
                                    </div>
                                )}
                            </>
                        )}
                        {type === 'new' ? (
                            <div className={'date-posted'}>
                                <div className={'label'}>Date Posted</div>
                                <div className={'date'}>{moment(le.createdAt).format('D MMM, YYYY')}</div>
                            </div>
                        ) : type === 'inProgress' ? (
                            <div className={'date-updated'}>
                                <div className={'label'}>Date Updated</div>
                                <div className={'date'}>{moment(le.updatedAt).format('D MMM, YYYY')}</div>
                            </div>
                        ) : (
                            <div className={'dates'}>
                                <div className={'updated'}>
                                    <div style={{ fontWeight: 600 }}>Date Updated</div>
                                    <div className={'date'}>{moment(le.updatedAt).format('D MMM, YYYY')}</div>
                                </div>
                                <div className={'created'}>
                                    <div style={{ fontWeight: 600 }}>Date Applied</div>
                                    <div className={'date'}>{moment(le.createdAt).format('D MMM, YYYY')}</div>
                                </div>
                            </div>
                        )}
                    </div>
                </Paper>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LECard);
