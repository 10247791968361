import { call, put, takeLatest } from 'redux-saga/effects';
import { API_DELETE } from '../redux';
import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../../notifications/sagas/container';
import { deleteLoanEnquiry } from '../api/delete_loan_enquiry';
import { push } from 'react-router-redux';
import { path, ROUTE_BORROWER_LOAN_ENQUIRIES } from '../../../../pitch4_routing';

function* apiDelete(action) {
    if (action.loanEnquiryId) {
        const response = yield call(deleteLoanEnquiry, action.loanEnquiryId);
        const { errors } = response;
        if (errors) {
            yield put({ type: SAGA_ACTION_ERROR, message: 'Could not delete loan enquiry' });
        } else {
            yield put({ type: SAGA_ACTION_SUCCESS, message: 'Loan Enquiry deleted!' });
            yield put(push(path(ROUTE_BORROWER_LOAN_ENQUIRIES)));
        }
    }
}

//Main Saga
function* main() {
    yield takeLatest(API_DELETE, apiDelete);
}

export default main;
