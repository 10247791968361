import React from 'react';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';
import { getValue } from './Utils';

export default function MainInterestRate(props) {
    const { matchSelected } = props;
    const lenderTerms = JSON.parse(matchSelected.lenderTerms) || null;
    const item = lenderTerms ? lenderTerms.terms.filter((item) => item.termType === 'mainInterestRate')[0] : null;

    return (
        <div className={'terms-main-interest-rate-container'}>
            <div className={'rate-container'}>
                <div className={'header'}>Current Offered Interest Rate</div>
                <div className={'rate'}>{getValue(item, true)}</div>
            </div>
            {null === lenderTerms || (lenderTerms && !item) || (lenderTerms && !item && null === item.value) ? (
                <div className={'awaiting-lender-container'}>
                    <div className={'header'}>
                        <AccessTimeIcon className={'icon'} /> Awaiting Terms
                    </div>
                    <div className={'date-container'}>
                        <div>Lender Notified at</div>
                        <div className={'date'}>
                            {matchSelected.createdAt
                                ? moment(matchSelected.createdAt).format('HH:mm Do MMMM YYYY')
                                : '-'}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
}
