import React from 'react';
import styled from 'styled-components';
import { CenteringContainer } from 'pitchblack_react_utils/layout/index';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import PropTypes from 'prop-types';

const Actions = styled(CardActions)`
    padding: 0;
    margin: 0;
`;

const Info = styled(Collapse)`
    padding: 0;
    margin: 0;
`;

const ExpandButton = styled(IconButton)`
    margin-left: auto !important;
    margin-right: -9px !important;
`;

const CardContainer = styled(CenteringContainer)``;

class Document extends React.Component {
    state = {
        expanded: false,
    };

    handleExpandClick = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    renderExpandIcon = () => {
        return this.state.expanded === true ? <ExpandLessIcon /> : <ExpandMore />;
    };

    render() {
        let { downloadButton, fileName, subHeader, removeButton, documentInfo } = this.props;

        return (
            <CardContainer>
                <Card>
                    <CardHeader avatar={downloadButton()} title={fileName()} subheader={subHeader()} />
                    <Actions>
                        {removeButton()}
                        <ExpandButton
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                            aria-label="Show more"
                            style={{}}
                        >
                            {this.renderExpandIcon()}
                        </ExpandButton>
                    </Actions>
                    <Info in={this.state.expanded} timeout="auto" unmountOnExit>
                        {documentInfo()}
                    </Info>
                </Card>
            </CardContainer>
        );
    }
}

Document.propTypes = {
    downloadButton: PropTypes.func.isRequired,
    fileName: PropTypes.func.isRequired,
    subHeader: PropTypes.func.isRequired,
    removeButton: PropTypes.func.isRequired,
    documentInfo: PropTypes.func.isRequired,
};

export default Document;
