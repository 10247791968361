import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import SessionManager from 'pitchblack_react_utils/session/SessionManager';
import { ROUTE_P4A_ADMINISTER_USERS_LIST } from 'pitch4_routing';
import { assumedIdentityEnd } from '../api/assumedIdentityEnd';
import {
    sagaEndOfAssumedIdentitySessionForced,
    sagaEndOfAssumedIdentitySessionVoluntary,
} from '../../admin/pitch4admin/administer_users/assumed_identity/sagas/assumed_identity_session';

let sessionManager = new SessionManager();

export const ASSUMED_IDENTITY_END = 'sagas.authentication.assumed_identity_end';
export const sagaActionEndAssumedIdentitySession = (forced = false) => ({
    type: ASSUMED_IDENTITY_END,
    forced,
});

function* handleAssumedIdentityEnd(action) {
    if (sessionManager.hasAssumedToken() === false) {
        return;
    }

    const session = sessionManager.getAssumedSession();

    //log out of frontend
    sessionManager.deleteAssumedIdentity();

    yield put(push(ROUTE_P4A_ADMINISTER_USERS_LIST));

    if (action.forced) {
        yield put(sagaEndOfAssumedIdentitySessionForced(session));
    } else {
        yield put(sagaEndOfAssumedIdentitySessionVoluntary(session));
    }

    yield call(assumedIdentityEnd);
}

//Main Saga
function* main() {
    yield takeLatest(ASSUMED_IDENTITY_END, handleAssumedIdentityEnd);
}

export default main;
