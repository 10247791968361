import createP4ApolloFetch from '../bootstrap/middlewares';

export const removeLETags = (uri, loanEnquiryId, tags) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    const mutation = `
        mutation removeEnquiryTags($loanEnquiryId: ID, $tags: [TagInput]) {
          removeEnquiryTags(loanEnquiryId: $loanEnquiryId, tags: $tags) {
            success
          }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            loanEnquiryId: loanEnquiryId,
            tags: tags,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
