import SessionManager from 'pitchblack_react_utils/session/SessionManager';

export const REDUCER_NAMESPACE = 'new_landing';

//Actions
export const ACCEPTED_PRIVACY = `${REDUCER_NAMESPACE}.ACCEPTED_PRIVACY`;
export const actionAcceptedPrivacy = () => {
    return {
        type: ACCEPTED_PRIVACY,
    };
};

export const SET_ACCEPTED_PRIVACY = `${REDUCER_NAMESPACE}.SET_ACCEPTED_PRIVACY`;
export const actionSetAcceptedPrivacyValue = (value) => {
    return {
        type: SET_ACCEPTED_PRIVACY,
        value: value,
    };
};

export const SET_ENABLE_TERMS_ACCEPT_BUTTON = `${REDUCER_NAMESPACE}.SET_ENABLE_TERMS_ACCEPT_BUTTON`;
export const actionSetEnableTermsAcceptButtonActive = (value) => {
    return {
        type: SET_ENABLE_TERMS_ACCEPT_BUTTON,
        value: value,
    };
};

let sm = new SessionManager();

//Reducer
const initialState = {
    acceptedPrivacy: sm.getPrivacyPolicyAccepted(),
    enableAcceptButton: false,
};

const reducer = (state = initialState, action) => {
    if (!action) return state;

    switch (action.type) {
        case ACCEPTED_PRIVACY:
            sm.setPrivacyPolicyAccepted(true);
            return {
                ...state,
                acceptedPrivacy: true,
            };

        case SET_ACCEPTED_PRIVACY:
            sm.setPrivacyPolicyAccepted(action.value);
            return {
                ...state,
                acceptedPrivacy: action.value,
            };

        case SET_ENABLE_TERMS_ACCEPT_BUTTON:
            return {
                ...state,
                enableAcceptButton: action.value,
            };

        default:
            return { ...state };
    }
};

export default reducer;

//Selectors
export const selectorIsPrivacyAccepted = (state) => {
    return sm.getPrivacyPolicyAccepted() || state[REDUCER_NAMESPACE].acceptedPrivacy;
};

export const selectorHasReadTerms = (state) => {
    return state[REDUCER_NAMESPACE].enableAcceptButton;
};
