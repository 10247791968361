import { API_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const GetAdminNotesQuery = `
    query getLoanEnquiryAdminNotes($id: ID) {
      loanEnquiryAdminNotes(id: $id) {
        adminNoteId
        adminNote
        adminNoteTitle
        submittedBy {
          userProfile{
            given
            family
          }
        }
        createdAt
        updatedAt
      }
    }
`;

const DeleteAdminNoteMutation = `
    mutation deleteAdminNote($id: ID) {
      deleteAdminNotes(id: $id) {
        success
        reason
      }
    }
`;

const ClearAdminNotesAlerts = `
    mutation clearAdminNotesAlerts($loanEnquiryId: ID, $lenderLoanEnquiryId: ID) {
      clearAdminNotesAlerts(loanEnquiryId: $loanEnquiryId, lenderLoanEnquiryId: $lenderLoanEnquiryId) {
        success
      }
    }
`;

const AddAdminNoteMutation = `
    mutation addAdminNote($input: adminNotesInput) {
      addAdminNote(input: $input) {
        adminNote
        adminNoteId
        adminNoteTitle
        createdAt
        updatedAt
        submittedBy {
          userProfile{
            given
            family
          }
        }
      }
    }
`;

const EditAdminNoteMutation = `
    mutation editAdminNote($id: ID, $input: adminNotesInput) {
      editAdminNotes(id: $id, input: $input) {
        adminNote
        adminNoteId
        adminNoteTitle
        submittedBy {
          userProfile{
            given
            family
          }
        }
        createdAt
        updatedAt
      }
    }

`;

export const getAdminNotes = (id) => {
    return apolloFetch({
        query: GetAdminNotesQuery,
        variables: {
            id,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return null;
            }

            return data;
        })
        .catch((error) => {
            return null;
        });
};

export const deleteAdminNote = (id) => {
    return apolloFetch({
        query: DeleteAdminNoteMutation,
        variables: {
            id,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return null;
            }

            return data;
        })
        .catch((error) => {
            return null;
        });
};

export const addAdminNote = (title, note, loanEnquiryId) => {
    return apolloFetch({
        query: AddAdminNoteMutation,
        variables: {
            input: {
                adminNote: note,
                adminNoteTitle: title,
                entityType: 'loan_enquiry',
                entityId: loanEnquiryId,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};

export const editAdminNote = (noteId, title, note, loanEnquiryId) => {
    return apolloFetch({
        query: EditAdminNoteMutation,
        variables: {
            id: noteId,
            input: {
                adminNote: note,
                adminNoteTitle: title,
                entityType: 'loan_enquiry',
                entityId: loanEnquiryId,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};

export const clearAdminNotesAlerts = (loanEnquiryId = null, lenderLoanEnquiryId = null) => {
    return apolloFetch({
        query: ClearAdminNotesAlerts,
        variables: {
            loanEnquiryId,
            lenderLoanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return null;
            }

            return data;
        })
        .catch((error) => {
            return null;
        });
};
