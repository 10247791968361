import moment from 'moment';

class FormMapper {
    mapToForm = (props) => {
        const { given, family, dateOfBirth, address = null, email = '' } = props;

        return {
            given: given,
            family: family,
            dateOfBirth: moment(dateOfBirth).format('YYYY-MM-DD'),
            address1: null !== address ? address.address1 : '',
            address2: null !== address ? address.address2 : '',
            address3: null !== address ? address.address3 : '',
            postcode: null !== address ? address.postcode : '',
            email: email,
        };
    };

    mapFromForm = ({
        given = '',
        family = '',
        dateOfBirth = '',
        address1 = '',
        address2 = '',
        address3 = '',
        postcode = '',
        email = '',
    }) => {
        return {
            given,
            family,
            dateOfBirth,
            address1,
            address2,
            address3,
            postcode,
            email,
        };
    };
}

export default FormMapper;
