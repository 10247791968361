import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import SidetrayMessageList from '../../../../../pitch4_chat/components/SidetrayMessageList';
import MessageList from '../../../../../pitch4_chat/components/MessageList';
import { withRouter } from 'react-router-dom';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { actionClearMessages, selectorGetMessages } from '../../../../../pitch4_chat/redux/chatMessages';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import { getChannelMessages } from '../api/get_channel_messages';
import {
    startPollingChannelMessages,
    stopPollingChannelMessages,
} from '../../../../../pitch4_chat/sagas/pollChannelMessages';

const SendMessageMutation = gql`
    mutation SendTextMessage($input: pitch4admin_liveChatSendMessageInput!) {
        liveChatSendMessage(input: $input) {
            message {
                id
                senderId
                type
                category
                userProfile {
                    given
                }
                userRole
                content
                sentAt
            }
        }
    }
`;

class ChatContainer extends React.Component {
    componentDidMount() {
        let { dispatch, channelId, loanEnquiryId, handleACMChatAlert = null } = this.props;
        dispatch(startPollingChannelMessages(channelId, loanEnquiryId, getChannelMessages, 60000));

        if (handleACMChatAlert) {
            handleACMChatAlert(false, channelId);
        }
    }

    componentWillUnmount() {
        let { dispatch } = this.props;
        dispatch(stopPollingChannelMessages());
        dispatch(actionClearMessages());
    }

    render() {
        let {
            channelId,
            sendMessageMutate,
            messages,
            session,
            disableForm,
            loanEnquiryId = null,
            chatLocation = 'page',
        } = this.props;

        switch (chatLocation) {
            case 'sidetray':
                return (
                    <SidetrayMessageList
                        channelId={channelId}
                        sendMessageMutate={sendMessageMutate}
                        messages={messages}
                        chatPlaceholderText={
                            <div>
                                <h3 className={'typography-card-title'}>No messages yet</h3>
                            </div>
                        }
                        disableForm={session.hasAssumedToken() || disableForm}
                        loanEnquiryId={loanEnquiryId}
                    />
                );
            default:
                return (
                    <MessageList
                        channelId={channelId}
                        sendMessageMutate={sendMessageMutate}
                        messages={messages}
                        chatPlaceholderText={
                            <div>
                                <h3 className={'typography-card-title'}>No messages yet</h3>
                            </div>
                        }
                        disableForm={session.hasAssumedToken() || disableForm}
                        loanEnquiryId={loanEnquiryId}
                    />
                );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        messages: selectorGetMessages(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withSession,
    withRouter,
    graphql(SendMessageMutation, {
        options() {
            return {
                context: {
                    uri: API_URL,
                },
            };
        },
        name: 'sendMessageMutate',
    }),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(ChatContainer);
