import React from 'react';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import { ServerErrors } from 'pitch4_forms/components';
import { TextField, TextLabel, FieldRow } from 'pitch4_forms/components/inputs';
import { Container, RowItem } from 'pitch4_layout/components/div';
import { Upload } from '../../../../../pitch4_forms/components/inputs';
import { RightAligned } from '../../../../../pitch4_layout/components/div';

const SpacerItem = styled(RowItem)``;
export const MAX_FILE_UPLOAD_SIZE_MB = 0.1;
export const MAX_FILE_UPLOAD_SIZE_BYTES = MAX_FILE_UPLOAD_SIZE_MB * 1024 * 1024;
export const ACCEPTED_FILE_MIME_TYPES = ['image/png'];
export const FORM_NAME = 'administer_lenders_upload_lender_logo_form';

class FormLogoUpload extends React.Component {
    fileNotEmpty = (value, formValues, { userStoreDocument }) => {
        const document = typeof userStoreDocument === 'undefined' ? '' : String(userStoreDocument);
        return String(document).length > 0 || value instanceof File ? undefined : 'File is required.';
    };

    fileSizeAcceptable = (value) => {
        if (value instanceof File) {
            return value.size <= MAX_FILE_UPLOAD_SIZE_BYTES
                ? undefined
                : 'That logo is too large. We accept uploads of up to ' + MAX_FILE_UPLOAD_SIZE_MB + 'MB';
        }

        return undefined;
    };

    render() {
        let { onSubmit, submitting, handleSubmit, error = [] } = this.props;

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container>
                    <RowItem>
                        <ServerErrors errors={error} visible={true} />
                    </RowItem>
                    <FieldRow
                        label={<TextLabel label="ID" />}
                        input={<TextField hidden={true} name="id" placeholder="ID" disabled={true} />}
                    />

                    <FieldRow
                        label={<TextLabel label="Logo" />}
                        input={
                            <Upload
                                name="logo"
                                placeholder="Lender Logo"
                                mimeTypes={ACCEPTED_FILE_MIME_TYPES}
                                maxFileSize={MAX_FILE_UPLOAD_SIZE_MB}
                                disabled={submitting}
                                fullWidth={true}
                                validate={[this.fileNotEmpty, this.fileSizeAcceptable]}
                            />
                        }
                    />

                    <SpacerItem />
                    <RightAligned className={'button-panel create-reset-buttons'}>
                        <PrimaryButton type="submit" disabled={submitting} onSubmit={onSubmit}>
                            Upload
                        </PrimaryButton>
                    </RightAligned>
                </Container>
            </form>
        );
    }
}

FormLogoUpload.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    reset: PropTypes.func,
    initialValues: PropTypes.any,
};

export default compose(
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
    })
)(FormLogoUpload);
