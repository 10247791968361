import { BaseEnum } from 'pitch4_enum';

export const TYPE_TEXT = 'TYPE_TEXT';

class MessageTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_TEXT]: 'Text Message',
        };
    };
}

export default MessageTypeEnum;
