export const ASAP = 'ASAP';
export const ONE_MONTH = 'ONE_MONTH';
export const TWO_MONTHS = 'TWO_MONTHS';
export const THREE_MONTHS = 'THREE_MONTHS';
class FacilityNeededBy {
    getTypes = () => {
        return [
            { value: ASAP, label: 'As soon as possible' },
            { value: ONE_MONTH, label: 'One month' },
            { value: TWO_MONTHS, label: 'Two months' },
            { value: THREE_MONTHS, label: 'Three months' },
        ];
    };

    getOptions = () => {
        return {
            [ASAP]: { value: ASAP, label: 'As soon as possible' },
            [ONE_MONTH]: { value: ONE_MONTH, label: 'One month' },
            [TWO_MONTHS]: { value: TWO_MONTHS, label: 'Two months' },
            [THREE_MONTHS]: { value: THREE_MONTHS, label: 'Three months' },
        };
    };
}

export default FacilityNeededBy;
