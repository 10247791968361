import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { deleteTag } from '../../pitch4_api/delete_tag';
import { textContrastColor } from '../../pitchblack_react_utils/color/Contrast';
import { API_URL } from '../../bootstrap/constants/constants';

export default function DeleteTagModal(props) {
    const { setModalClose, tag, refreshData } = props;

    return (
        <Grid container className={'delete-tag-modal'}>
            <Grid item>
                <h2 className={'header'}>Are you sure?</h2>
                <div className={'text'}>
                    Are you sure you want to delete{' '}
                    <span
                        className={'tag'}
                        style={{
                            backgroundColor: tag.color,
                            color: textContrastColor(tag.color, ['#000000', '#ffffff']),
                        }}
                    >
                        {tag.label}
                    </span>{' '}
                    tag?
                    <br />
                    It will be also removed from Loan Enquiries.
                </div>
                <div className={'actions'}>
                    <Button
                        onClick={() => {
                            setModalClose();
                        }}
                        color={'default'}
                    >
                        Close
                    </Button>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        onClick={() => {
                            deleteTag(API_URL, tag.id).then(() => {
                                setModalClose();
                                refreshData();
                            });
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}
