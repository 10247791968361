import React from 'react';

class Subtitle1 extends React.Component {
    render() {
        let { children, elkey = '', ...other } = this.props;

        return (
            <span key={elkey} className={`subtitle-1`} {...other}>
                {children}
            </span>
        );
    }
}

export default Subtitle1;
