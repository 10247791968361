import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'pitch4_layout/components/div';

export const ModalLayoutMuiName = 'ModalTwoPaneLayout';

const ModalLayoutComponent = (props) => {
    const { infoPane, formPane } = props;
    return (
        <React.Fragment>
            <Container className="modal-content-two-pane">
                <div className="modal-pane modal-pane-info">{infoPane}</div>
                <div className="modal-pane modal-pane-form">{formPane}</div>
            </Container>
        </React.Fragment>
    );
};
ModalLayoutComponent.MuiName = ModalLayoutMuiName;

class ModalLayout extends React.Component {
    render() {
        return <ModalLayoutComponent {...this.props} />;
    }
}

ModalLayout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
};

export default ModalLayout;
