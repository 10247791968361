import React from 'react';
import PropTypes from 'prop-types';

/**
 * Formats an integer as percentage.
 */
class PercentFormatter extends React.Component {
    render() {
        let { value } = this.props;

        value = value > 100 ? 100 : value < 0 ? 0 : value;

        return <span>{value}%</span>;
    }
}

PercentFormatter.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default PercentFormatter;
