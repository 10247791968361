import _ from 'lodash';
import { SECTION_LOAN_TYPE } from '../constants';
import { OPERATION_BETWEEN } from 'pitch4_enum/enum/OperationEnum';
import { getType } from 'pitch4_layout/components/data/FactValue';
import { isEmpty } from 'pitch4_validation';

export const globalValidator = (data, props) => {
    const { currentForm } = props;

    let errors = { [currentForm]: {} };

    Object.keys(data).forEach((section) => {
        const sectionData = data[section];

        Object.keys(sectionData).forEach((fact) => {
            const factData = sectionData[fact];
            const operation = _.get(factData, 'operation');
            const operand = _.get(factData, 'operand');
            const startVal = factData['operand-start'];
            const endVal = factData['operand-end'];
            const type = getType(fact);
            const isEnumType = type.match(/_enum$/);

            // Validate that operand cannot be set without operation
            // The isEnumType check is because we do not show operation for those types so no check is needed here.
            if (
                section !== SECTION_LOAN_TYPE &&
                !isEmpty(factData.operand) &&
                isEmpty(factData.operation) &&
                isEnumType === false
            ) {
                errors[currentForm][fact] = {
                    operation: 'Match operation must be given',
                };
            }

            // Validate that operation cannot be set without operand
            if (
                (!isEmpty(operation) && operation !== OPERATION_BETWEEN && isEmpty(operand)) ||
                (operation === OPERATION_BETWEEN && isEmpty(startVal) && isEmpty(endVal))
            ) {
                errors[currentForm][fact] = { operand: 'Required' };
            }

            // Validate 'between' values
            if (startVal && endVal && startVal >= endVal) {
                errors[currentForm][fact] = { 'operand-start': 'Start value must be less than the end value' };
            }
            if (startVal && isEmpty(endVal)) {
                errors[currentForm][fact] = { 'operand-end': 'End value must be given' };
            }
            if (isEmpty(startVal) && endVal) {
                errors[currentForm][fact] = { 'operand-start': 'Start value must be given' };
            }
        });
    });

    return errors;
};
