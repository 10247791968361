import React from 'react';
import { Container } from 'pitchblack_react_utils/layout';
import PropTypes from 'prop-types';

class PlaceholderImageLayout extends React.Component {
    render() {
        let { classNames, children } = this.props;

        return (
            <Container className={`placeholder-image-layout ${classNames}`}>
                <div>
                    <Container className={`placeholder-image-content`}>{children}</Container>
                </div>
            </Container>
        );
    }
}

PlaceholderImageLayout.propTypes = {
    classNames: PropTypes.string.isRequired,
};

export default PlaceholderImageLayout;
