import { API_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

export const setLenderOffPanel = (uri = API_URL, lenderId, enabled) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation toggleOffPanel($id: ID!, $offPanel: Boolean!) {
          toggleLenderOffPanel(id: $id, offPanel: $offPanel) {
            success
          }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            id: lenderId,
            offPanel: enabled,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
