import React from 'react';
import { BrandText } from '../../../pitch4_layout/components/text';
import { ROUTE_COOKIE_POLICY, ROUTE_PRIVACY_POLICY } from '../../../pitch4_routing';

class Footer extends React.Component {
    render() {
        return (
            <footer
                id="colophon"
                className="pofo-footer bg-extra-dark-gray site-footer"
                itemScope="itemscope"
                itemType="http://schema.org/WPFooter"
                style={{ backgroundColor: 'rgba(0,0,0,.75)', color: '#ffffff', padding: '10px 0px' }}
            >
                <div
                    className="container"
                    style={{
                        width: 1000,
                        margin: '0 auto',
                    }}
                >
                    <div className="footer__text-container">
                        <p className="footer__text">
                            &nbsp;
                            <BrandText />
                            &nbsp;is a trading name of{' '}
                            <a style={{ color: 'yellow' }} href="//yellowstonefinance.com/">
                                Yellow Stone Finance Group Ltd
                            </a>
                            . It is a broker, not a lender & is regulated & authorised by the FCA registration no.
                            814533.
                        </p>
                        <p className="footer__text">
                            Trading & registered address: Unit 1, Verney House, 1b Hollywood Road, London, SW10 9HS.
                            Company Registration no. 11224486.
                        </p>
                        <p className="footer__text">
                            <a style={{ color: '#ffffff' }} href="/">
                                Home
                            </a>{' '}
                            | <a href={ROUTE_PRIVACY_POLICY}>Privacy policy</a> |{' '}
                            <a style={{ color: '#ffffff' }} href={ROUTE_COOKIE_POLICY}>
                                Cookie policy
                            </a>
                        </p>
                        <p className="footer__text">
                            © 2018 <BrandText />
                            &nbsp; Ltd
                        </p>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
