export const FOURTEEN_DAYS = 'FOURTEEN_DAYS';
export const TWENTY_EIGHT_DAYS = 'TWENTY_EIGHT_DAYS';
export const SIXTY_DAYS = 'SIXTY_DAYS';
export const OVER_SIXTY_DAYS = 'OVER_SIXTY_DAYS';

class LoanNeededBy {
    getTypes = () => {
        return [
            { value: FOURTEEN_DAYS, label: '1 to 14 days' },
            { value: TWENTY_EIGHT_DAYS, label: '14 to 28 days' },
            { value: SIXTY_DAYS, label: '28 to 60 days' },
            { value: OVER_SIXTY_DAYS, label: 'More than 60 days' },
        ];
    };

    getOptions = () => {
        return {
            [FOURTEEN_DAYS]: { value: FOURTEEN_DAYS, label: '1 to 14 days' },
            [TWENTY_EIGHT_DAYS]: { value: TWENTY_EIGHT_DAYS, label: '14 to 28 days' },
            [SIXTY_DAYS]: { value: SIXTY_DAYS, label: '28 to 60 days' },
            [OVER_SIXTY_DAYS]: { value: OVER_SIXTY_DAYS, label: 'More than 60 days' },
        };
    };
}

export default LoanNeededBy;
