export const UK_CITIZEN = 'UK_CITIZEN';
export const BRITISH_EXPAT = 'BRITISH_EXPAT';
export const FOREIGN_NAT_IN_UK_PERMANENT = 'FOREIGN_NAT_IN_UK_PERMANENT';
export const FOREIGN_NAT_IN_UK_VISA = 'FOREIGN_NAT_IN_UK_VISA';
export const FOREIGN_NAT_NOT_IN_UK = 'FOREIGN_NAT_NOT_IN_UK';

class CitizenshipTypes {
    getTypes = () => {
        return [
            { value: UK_CITIZEN, label: 'UK Citizen' },
            { value: BRITISH_EXPAT, label: 'British Expat' },
            { value: FOREIGN_NAT_IN_UK_PERMANENT, label: 'Foreign National Living In The UK (Right to Reside)' },
            { value: FOREIGN_NAT_IN_UK_VISA, label: 'Foreign National Living In The UK (VISA)' },
            { value: FOREIGN_NAT_NOT_IN_UK, label: 'Foreign National' },
        ];
    };

    getOptions = () => {
        return {
            [UK_CITIZEN]: { value: UK_CITIZEN, label: 'UK Citizen' },
            [BRITISH_EXPAT]: { value: BRITISH_EXPAT, label: 'British Expat' },
            [FOREIGN_NAT_IN_UK_PERMANENT]: {
                value: FOREIGN_NAT_IN_UK_PERMANENT,
                label: 'Foreign National Living In The UK (Right to Reside)',
            },
            [FOREIGN_NAT_IN_UK_VISA]: {
                value: FOREIGN_NAT_IN_UK_VISA,
                label: 'Foreign National Living In The UK (VISA)',
            },
            [FOREIGN_NAT_NOT_IN_UK]: { value: FOREIGN_NAT_NOT_IN_UK, label: 'Foreign National' },
        };
    };
}

export default CitizenshipTypes;
