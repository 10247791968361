import React from 'react';

class SecondaryColour extends React.Component {
    render() {
        let { children, ...other } = this.props;

        return (
            <span className={`secondary-colour`} {...other}>
                {children}
            </span>
        );
    }
}

export default SecondaryColour;
