import BaseEnum from 'pitchblack_react_utils/enums/BaseEnum';

//MISC AUTH
export const EVENT_TYPE_LOGIN = 'EVENT_TYPE_LOGIN';

//ANON MAKE LOAN ENQUIRY
export const EVENT_TYPE_USER_MAKE_LOAN_ENQUIRY_SUBMITTED = 'EVENT_TYPE_USER_MAKE_LOAN_ENQUIRY_SUBMITTED';

//BORROWER REGISTRATION
export const EVENT_TYPE_REGISTER_BORROWER = 'EVENT_TYPE_REGISTER_BORROWER';

//BORROWER MAKE LOAN ENQUIRY
export const EVENT_TYPE_BORROWER_MAKE_LOAN_ENQUIRY_SUBMITTED = 'EVENT_TYPE_BORROWER_MAKE_LOAN_ENQUIRY_SUBMITTED';
export const EVENT_TYPE_INTERMEDIARY_MAKE_LOAN_ENQUIRY_SUBMITTED =
    'EVENT_TYPE_INTERMEDIARY_MAKE_LOAN_ENQUIRY_SUBMITTED';
export const EVENT_TYPE_BUSINESS_STREAM_CREATED = 'EVENT_TYPE_BUSINESS_STREAM_CREATED';
export const EVENT_TYPE_BUSINESS_STREAM_EDITED = 'EVENT_TYPE_BUSINESS_STREAM_EDITED';
export const EVENT_TYPE_BUSINESS_STREAM_SETSTATUS = 'EVENT_TYPE_BUSINESS_STREAM_SETSTATUS';
export const EVENT_TYPE_BUSINESS_STREAM_DELETED = 'EVENT_TYPE_BUSINESS_STREAM_DELETED';

//LENDER REGISTRATION
export const EVENT_TYPE_REGISTER_LENDER = 'EVENT_TYPE_REGISTER_LENDER';
export const EVENT_TYPE_REGISTER_SLAM = 'EVENT_TYPE_REGISTER_SLAM';

//LENDER INBOXES
export const EVENT_TYPE_INBOX_CREATED = 'EVENT_TYPE_INBOX_CREATED';
export const EVENT_TYPE_INBOX_DELETED = 'EVENT_TYPE_INBOX_DELETED';
export const EVENT_TYPE_INBOX_CLONED = 'EVENT_TYPE_INBOX_CLONED';
export const EVENT_TYPE_INBOX_EDITED = 'EVENT_TYPE_INBOX_EDITED';
export const EVENT_TYPE_INBOX_SETSTATUS = 'EVENT_TYPE_INBOX_SETSTATUS';

//LEAD OFFER
export const EVENT_TYPE_LENDER_LOAN_OFFERED = 'EVENT_TYPE_LENDER_LOAN_OFFERED';

//RESET PASSWORD
export const EVENT_TYPE_FLAG_FORGOTTEN_PASSWORD = 'EVENT_TYPE_FLAG_FORGOTTEN_PASSWORD';
export const EVENT_TYPE_PROMPT_RESET_PASSWORD = 'EVENT_TYPE_PROMPT_RESET_PASSWORD';
export const EVENT_TYPE_FORGOTTEN_PASSWORD_RESET = 'EVENT_TYPE_FORGOTTEN_PASSWORD_RESET';
export const EVENT_TYPE_BUSINESS_STREAM_CLONED = 'EVENT_TYPE_BUSINESS_STREAM_CLONED';

//Documents
export const EVENT_TYPE_CREATED_LLE_DOCUMENT = 'EVENT_TYPE_CREATED_LLE_DOCUMENT';
export const EVENT_TYPE_CREATED_LLE_DOCUMENT_PERMISSION = 'EVENT_TYPE_CREATED_LLE_DOCUMENT_PERMISSION';

//Messaging
export const EVENT_TYPE_LENDER_LOAN_ENQUIRY_LIVECHAT_SEND_MESSAGE =
    'EVENT_TYPE_LENDER_LOAN_ENQUIRY_LIVECHAT_SEND_MESSAGE';

class TypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [EVENT_TYPE_LOGIN]: 'Login',
            [EVENT_TYPE_USER_MAKE_LOAN_ENQUIRY_SUBMITTED]: 'Make loan enquiry',
            [EVENT_TYPE_REGISTER_BORROWER]: 'Register Borrower',
            [EVENT_TYPE_BORROWER_MAKE_LOAN_ENQUIRY_SUBMITTED]: 'Make loan enquiry',
            [EVENT_TYPE_REGISTER_LENDER]: 'New Lender Registration',
            [EVENT_TYPE_REGISTER_SLAM]: 'New SLAM registration',
            [EVENT_TYPE_INBOX_CREATED]: 'Business stream created',
            [EVENT_TYPE_INBOX_DELETED]: 'Business stream deleted',
            [EVENT_TYPE_INBOX_CLONED]: 'Business stream cloned',
            [EVENT_TYPE_INBOX_EDITED]: 'Business stream edited',
            [EVENT_TYPE_INBOX_SETSTATUS]: 'Business stream status changed',
            [EVENT_TYPE_LENDER_LOAN_OFFERED]: 'Lead offered terms',
            [EVENT_TYPE_FLAG_FORGOTTEN_PASSWORD]: 'Flag forgotten password',
            [EVENT_TYPE_PROMPT_RESET_PASSWORD]: 'Prompt reset password',
            [EVENT_TYPE_CREATED_LLE_DOCUMENT]: 'New lead document uploaded',
            [EVENT_TYPE_CREATED_LLE_DOCUMENT_PERMISSION]: 'Lead document shared from store',
            [EVENT_TYPE_LENDER_LOAN_ENQUIRY_LIVECHAT_SEND_MESSAGE]: 'New lead message',
            [EVENT_TYPE_FORGOTTEN_PASSWORD_RESET]: 'Password reset',
            [EVENT_TYPE_INTERMEDIARY_MAKE_LOAN_ENQUIRY_SUBMITTED]: 'Broker loan enquiry created',
            [EVENT_TYPE_BUSINESS_STREAM_CREATED]: 'Business Stream created',
            [EVENT_TYPE_BUSINESS_STREAM_EDITED]: 'Business Stream edited',
            [EVENT_TYPE_BUSINESS_STREAM_SETSTATUS]: 'Business Stream status changed',
            [EVENT_TYPE_BUSINESS_STREAM_DELETED]: 'Business Stream deleted',
            [EVENT_TYPE_BUSINESS_STREAM_CLONED]: 'Business Stream cloned',
        };
    };
}

export default TypeEnum;
