import React, { useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { getStoredDataValueFromArray } from '../../../../view_loan_enquiry/HelperFunctions';
import { LOAN_AMOUNT } from '../../../enum/MatchingRules/MetaKeyEnum';
import moment from 'moment';

export default function CompleteEditModal(props) {
    const { setModalClose, handleCompletedUpdate, loanEnquiry } = props;
    const [selectedDate, setSelectedDate] = useState(
        loanEnquiry && loanEnquiry.completedDate ? loanEnquiry.completedDate : moment().toISOString()
    );
    const [finalAmount, setFinalAmount] = useState(
        loanEnquiry && loanEnquiry.finalAmount
            ? loanEnquiry.finalAmount
            : getStoredDataValueFromArray(loanEnquiry.storedData, LOAN_AMOUNT) / 100
    );

    const handleDateChange = (date) => {
        setSelectedDate(moment(date).toISOString());
    };

    return (
        <div className={'admin-complete-modal-container'}>
            <div className={'header'}>Update Completion Details</div>
            <div>
                <div className={'label'}>Completion Date</div>
                <KeyboardDatePicker
                    margin="normal"
                    id="completion-date"
                    format="D MMMM yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    inputVariant="outlined"
                    className={'completion-date-picker'}
                />
            </div>
            <div>
                <div className={'label'}>Final Amount</div>
                <TextField
                    id="final-amount"
                    variant="outlined"
                    className={'final-amount-input'}
                    type={'number'}
                    placeholder={'£'}
                    inputProps={{
                        step: '0.01',
                        min: 0,
                    }}
                    onChange={(event) => setFinalAmount(event.target.value ? parseFloat(event.target.value) : null)}
                    value={finalAmount}
                />
            </div>
            <div className={'actions'}>
                <Button variant={'contained'} onClick={() => setModalClose()} className={'btn-back'}>
                    BACK
                </Button>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => {
                        handleCompletedUpdate(selectedDate, finalAmount);
                        setModalClose();
                    }}
                    disabled={null === selectedDate || null === finalAmount}
                >
                    SUBMIT
                </Button>
            </div>
        </div>
    );
}
