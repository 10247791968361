// import React from 'react';
import { call, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { reset } from 'redux-form';
import { registerBorrower } from '../api/register_borrower';
import { registerIntermediary } from '../api/register_intermediary';
import {
    BORROWER_REGISTER_RESET,
    BORROWER_REGISTER_STEP_SUBMISSION_ERRORS,
    BORROWER_REGISTER_SUBMITTED,
} from '../redux/step';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../notifications/sagas/container';
import { SAGA_ACTION_REDIRECT_TO_DASHBOARD } from '../../authentication/sagas/dashboard';
// import { sagaActionModalShow } from '../../modal/sagas/container';
// import Welcome from '../components/Welcome';
// import { ROUTE_BORROWER_CREATE_LOAN_ENQUIRY } from '../../../pitch4_routing';
import {
    INTERMEDIARY_REGISTER_RESET,
    INTERMEDIARY_REGISTER_SUBMISSION_ERRORS,
    INTERMEDIARY_REGISTER_SUBMITTED,
} from '../redux/intermediary';
// import { registerBorrowerInterest } from '../api/register_interest';
// import { actionHubspotTrackingIdentifyVisitor } from '../../hubspot/redux/tracking';

function* borrowerRegister(action) {
    /*
     * Register interest API call - this was usable when we had multiple steps in registration.
     * Since we have only one step this is no longer needed.
     */
    // const email = action.input.email;
    // const registerInterestResponse = yield call(registerBorrowerInterest, { email });
    //
    // if (_.get(registerInterestResponse, ['data', 'registerBorrowerInterest', 'success']) !== undefined) {
    //     yield put(actionHubspotTrackingIdentifyVisitor(email, {}));
    // } else if (_.get(registerInterestResponse, ['errors']) !== undefined) {
    //     yield put({ type: SAGA_ACTION_ERROR, message: 'There was problem with your submission' });
    // }

    /*
     * Register borrower API call
     */
    const registerBorrowerResponse = yield call(
        registerBorrower,
        action.input,
        action.acceptedTermsVersion,
        action.recaptchaToken
    );

    if (_.get(registerBorrowerResponse, ['data', 'registerBorrower']) !== undefined) {
        const { token, session } = registerBorrowerResponse.data.registerBorrower;
        const sessionManager = new SessionManager();
        sessionManager.login(token, session);
        yield put({ type: SAGA_ACTION_SUCCESS, message: 'Successfully registered' });
        yield put({ type: SAGA_ACTION_REDIRECT_TO_DASHBOARD });
        // yield put(sagaActionModalShow(<Welcome url={ROUTE_BORROWER_CREATE_LOAN_ENQUIRY} inModal={true} />, true));
        yield put({ type: BORROWER_REGISTER_RESET });
        yield put(reset('registration'));
    } else if (_.get(registerBorrowerResponse, ['errors']) !== undefined) {
        const errors = _.map(registerBorrowerResponse.errors, (err) => err.message);
        const duplicateEmailError = _.includes(errors, 'This email address is already registered. Please log in.');
        yield put({ type: BORROWER_REGISTER_STEP_SUBMISSION_ERRORS, errors, duplicateEmailError });
        yield put({ type: SAGA_ACTION_ERROR, message: 'There was problem with your submission' });
    }
}

function* intermediaryRegister(action) {
    const registerIntermediaryResponse = yield call(
        registerIntermediary,
        action.input,
        action.acceptedTermsVersion,
        action.recaptchaToken
    );

    if (_.get(registerIntermediaryResponse, ['data', 'registerIntermediary']) !== undefined) {
        const { token, session } = registerIntermediaryResponse.data.registerIntermediary;
        const sessionManager = new SessionManager();
        sessionManager.login(token, session);
        yield put({ type: SAGA_ACTION_SUCCESS, message: 'Successfully registered' });
        yield put({ type: SAGA_ACTION_REDIRECT_TO_DASHBOARD });
        // yield put(sagaActionModalShow(<Welcome url={ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY} inModal={true} />, true));
        yield put({ type: INTERMEDIARY_REGISTER_RESET });
        yield put(reset('registration'));
    } else if (_.get(registerIntermediaryResponse, ['errors']) !== undefined) {
        const errors = _.map(registerIntermediaryResponse.errors, (err) => err.message);
        const duplicateEmailError = _.includes(errors, 'This email address is already registered. Please log in.');
        yield put({ type: INTERMEDIARY_REGISTER_SUBMISSION_ERRORS, errors, duplicateEmailError });
        yield put({ type: SAGA_ACTION_ERROR, message: 'There was problem with your submission' });
    }
}

//Main Saga
function* main() {
    yield takeLatest(BORROWER_REGISTER_SUBMITTED, borrowerRegister);
    yield takeLatest(INTERMEDIARY_REGISTER_SUBMITTED, intermediaryRegister);
}

export default main;
