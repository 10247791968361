import SessionManager from 'pitchblack_react_utils/session/SessionManager';
import ViewDocumentManager from './ViewDocumentManager';

class ViewDocumentManagerFactory {
    create() {
        return new ViewDocumentManager(new SessionManager());
    }
}

export default ViewDocumentManagerFactory;
