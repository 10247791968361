import React from 'react';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Typography from '@material-ui/core/Typography/Typography';
import filesize from 'filesize';
import DateTimeFactory from 'pitchblack_react_utils/datetime/DateTimeFactory';
import PropTypes from 'prop-types';

const dateTimeFactory = new DateTimeFactory();

class DocumentInfo extends React.Component {
    state = {
        expanded: false,
    };

    render() {
        let { document } = this.props;
        const createdAt = dateTimeFactory.create(document.createdAt);
        const updatedAt = dateTimeFactory.create(document.updatedAt);

        return (
            <CardContent>
                <Typography>Uploaded: {createdAt.toLocaleString()}</Typography>
                <Typography>Modified: {updatedAt.toLocaleString()}</Typography>
                <Typography>Mime Type: {document.mimeType}</Typography>
                <Typography>File Size: {filesize(document.fileSize)}</Typography>
            </CardContent>
        );
    }
}

DocumentInfo.propTypes = {
    document: PropTypes.object.isRequired,
};

export default DocumentInfo;
