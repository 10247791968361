import React from 'react';
import { Table } from '../table';

import PropTypes from 'prop-types';

class KeyValueTable extends React.Component {
    render() {
        let { children, style = {}, elkey = '' } = this.props;

        return (
            <Table style={style} elkey={elkey}>
                {children}
            </Table>
        );
    }
}

KeyValueTable.propTypes = {
    children: PropTypes.any,
};

export default KeyValueTable;
