import React, { useEffect, useState } from 'react';
import { FormControl, Grid, InputLabel, ListSubheader, MenuItem, Paper, Select } from '@material-ui/core';
import ChartHeaderIcon from '../icons/ChartHeaderIcon';
import BarChart from './BarChart';
import moment from 'moment';
import _ from 'lodash';
import {
    filterTypes,
    chartStatsMapping,
    NEW_INTERMEDIARY_ENQUIRIES,
    NEW_NON_YS_INTERMEDIARY_ENQUIRIES,
    NEW_BORROWER_ENQUIRIES,
} from './config';
import { BRAND_NAME } from '../../../../../../bootstrap/constants/constants';

export default function ChartContainer(props) {
    const { chartStats, chartFilters, chartData } = props;
    const defaultYear = _.last(chartFilters.years);
    const defaultFilterType = filterTypes[0];
    const [initialDataLoad, setInitialDataLoad] = useState(false);
    const [filterType, setFilterType] = useState(defaultFilterType);
    const [yearRange, setYearRange] = useState(defaultYear);
    const [chartStatsFiltered, setChartStatsFiltered] = useState({
        today: 0,
        newThisMonth: 0,
        endOfLastMonth: 0,
        increaseFromPreviousMonth: 0,
    });
    const getChartFilteredData = (year = defaultYear, filterType = defaultFilterType) => {
        return chartData[year][filterType];
    };
    const [data, setData] = useState(getChartFilteredData(defaultYear, defaultFilterType));
    const getChartStatsFiltered = (type) => {
        return {
            today: chartStats[chartStatsMapping[type]['today']],
            newThisMonth: chartStats[chartStatsMapping[type]['newThisMonth']],
            endOfLastMonth: chartStats[chartStatsMapping[type]['endOfLastMonth']],
            increaseFromPreviousMonth: chartStats[chartStatsMapping[type]['increaseFromPreviousMonth']],
        };
    };

    const handleFilterTypeChange = (event) => {
        setFilterType(event.target.value);
        setData(getChartFilteredData(yearRange, event.target.value));
        setChartStatsFiltered(getChartStatsFiltered(event.target.value));
    };
    const handleYearRangeChange = (event) => {
        setYearRange(event.target.value);
        setData(getChartFilteredData(event.target.value, filterType));
    };

    const getPreviousMonth = () => moment().subtract(1, 'month').format('MMMM YY');

    useEffect(() => {
        // Set default chart filter selection - this is required to refresh the chart after the initial page open
        setData(getChartFilteredData(defaultYear, defaultFilterType));
    }, []);

    useEffect(() => {
        setData(getChartFilteredData(yearRange, filterType));
        if (false === initialDataLoad && true === chartStats.dataUpdated) {
            setChartStatsFiltered(getChartStatsFiltered('total_users'));
            setInitialDataLoad(true);
        }
    });

    return (
        <Paper elevation={1} style={{ borderRadius: 20 }}>
            <Grid container className={'main-chart-container'}>
                <Grid className={'chart-container'} item lg={7} md={7} sm={7} xs={12}>
                    <div className={'header'}>
                        <div className={'icon-container'}>
                            <ChartHeaderIcon /> {BRAND_NAME} Statistics
                        </div>
                    </div>
                    <div className={'chart'}>
                        <BarChart data={data} year={yearRange} />
                    </div>
                </Grid>
                <Grid container className={'filters-container'} item lg={5} md={5} sm={5} xs={12}>
                    <Grid container className={'filters-container1'}>
                        <Grid item xs={12} sm={12} md={6} lg={6} className={'left-column'}>
                            <div>
                                <FormControl variant="outlined" className={'filter-type-select'}>
                                    <InputLabel id="filter-type-select-outlined-label">Filter Type</InputLabel>
                                    <Select
                                        labelId="filter-type-select-outlined-label"
                                        id="filter-type-select-outlined"
                                        value={filterType}
                                        onChange={handleFilterTypeChange}
                                        label="Filter type"
                                    >
                                        <MenuItem value={'total_users'} className={'label'}>
                                            <span className={'label-bold'}>Total</span>&nbsp;Users
                                        </MenuItem>
                                        <MenuItem value={'total_borrowers'}>
                                            <span className={'label-bold'}>Total</span>&nbsp;Borrowers
                                        </MenuItem>
                                        <MenuItem value={'total_lenders'}>
                                            <span className={'label-bold'}>Total</span>&nbsp;Lenders
                                        </MenuItem>
                                        <MenuItem value={'total_intermediaries'}>
                                            <span className={'label-bold'}>Total</span>&nbsp;Intermediaries
                                        </MenuItem>
                                        <ListSubheader>
                                            <hr />
                                        </ListSubheader>
                                        <MenuItem value={'new_intermediary_enquiries'}>
                                            <span className={'label-bold'}>New</span>&nbsp;Intermediary Enquiries
                                        </MenuItem>
                                        <MenuItem value={'new_non_ys_intermediary_enquiries'}>
                                            New Non-YS Intermediary Enquiries
                                        </MenuItem>
                                        <MenuItem value={'new_borrower_enquiries'}>
                                            <span className={'label-bold'}>New</span>&nbsp;Borrower Enquiries
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className={'right-column'}>
                            <div>
                                <FormControl variant="outlined" className={'filter-type-select'}>
                                    <InputLabel id="data-range-select-outlined-label">Date Range</InputLabel>
                                    <Select
                                        labelId="data-range-select-outlined-label"
                                        id="data-range-select-outlined"
                                        value={yearRange}
                                        onChange={handleYearRangeChange}
                                        label="Date Range"
                                    >
                                        {chartFilters.years.map((year, index) => (
                                            <MenuItem value={year} key={index}>
                                                Year: <strong>{year}</strong>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid className={'chart-stats-header'} item lg={12} md={12} sm={12} xs={12}>
                            Current Year:
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className={'left-column'}>
                            <div className={'stats-today'}>
                                <div className={'header'}>
                                    {filterType === NEW_INTERMEDIARY_ENQUIRIES ||
                                    filterType === NEW_NON_YS_INTERMEDIARY_ENQUIRIES ||
                                    filterType === NEW_BORROWER_ENQUIRIES ? (
                                        <strong>Today</strong>
                                    ) : (
                                        <strong>Total on Platform</strong>
                                    )}
                                </div>
                                <div className={'value'}>{chartStatsFiltered.today}</div>
                            </div>
                            <div className={'stats-end-of-last-month'}>
                                <div className={'header'}>
                                    <strong>End of Last Month</strong>{' '}
                                    <span className={'last-month'}>({getPreviousMonth()})</span>
                                </div>
                                <div className={'value'}>{chartStatsFiltered.endOfLastMonth}</div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className={'right-column'}>
                            <div className={'stats-new-this-month'}>
                                <div className={'header'}>
                                    New <strong>this month</strong>
                                </div>
                                <div className={'value'}>{chartStatsFiltered.newThisMonth}</div>
                            </div>
                            <div className={'stats-increase-from-previous-month'}>
                                <div className={'header'}>
                                    <strong>Change</strong> from the Previous Month
                                </div>
                                <div className={'value'}>
                                    {chartStatsFiltered.increaseFromPreviousMonth > 0
                                        ? '+'
                                        : chartStatsFiltered.increaseFromPreviousMonth < 0
                                        ? '-'
                                        : null}
                                    {Math.abs(chartStatsFiltered.increaseFromPreviousMonth)}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}
