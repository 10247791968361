import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectorBorrowerRegisterStep } from '../../apps/registration/redux/step';
import { CenteringContainer, Container } from '../../pitchblack_react_utils/layout';
import { BRAND_NAME, TOS_URL, WEB_HOST } from '../../bootstrap/constants/constants';
import { Grid } from '@material-ui/core';
import Helmet from 'react-helmet';
import { getNetworkLoginCookie } from '../../pitch4_utils/LoginCookie';

const ProductRegistrationLayout = ({ children, notifications, introducerConfig }) => {
    const introducer = getNetworkLoginCookie();
    return (
        <>
            <Helmet>
                <title>
                    {BRAND_NAME} | {introducerConfig.pageTitle}
                </title>
            </Helmet>
            <Grid container className={'introducer-borrower-registration'}>
                <Grid item lg={12} sm={12} md={12} xs={12} className={'page-header'}>
                    <div className={`${introducerConfig.name}-logo`} />
                </Grid>
                <Grid
                    container
                    lg={12}
                    sm={12}
                    md={12}
                    xs={12}
                    style={{ position: 'absolute', top: 0, paddingTop: 100, height: '100vh' }}
                >
                    <Grid item lg={6} md={6} sm={12} xs={12} className={'container'}>
                        {children}
                        {notifications}
                        <Container className={'footer-container'}>
                            <CenteringContainer className={'links'}>
                                <p>
                                    Already have an account?{' '}
                                    <a id="login" href={`/${introducer}`}>
                                        Click here to login instead
                                    </a>
                                </p>
                            </CenteringContainer>
                            <CenteringContainer className={'links'}>
                                <a href={WEB_HOST}>About {BRAND_NAME}</a>
                                <a href={`${WEB_HOST}/contact-us`}>Contact Us</a>
                                <a href={TOS_URL} target={'_blank'} rel={'noreferrer'}>
                                    Terms & Conditions
                                </a>
                            </CenteringContainer>
                        </Container>
                    </Grid>
                    <Grid
                        item
                        lg={6}
                        md={6}
                        sm={12}
                        xs={12}
                        className={`right-container introducer-${introducerConfig.name}`}
                    >
                        <CenteringContainer className={'heading-container'}>
                            <h1
                                className={`${introducerConfig.name + '-'}heading`}
                                dangerouslySetInnerHTML={{
                                    __html: introducerConfig.headerText,
                                }}
                            />
                        </CenteringContainer>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        step: selectorBorrowerRegisterStep(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

ProductRegistrationLayout.propTypes = {
    notifications: PropTypes.element.isRequired,
    children: PropTypes.any.isRequired,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ProductRegistrationLayout);
