import React, { useEffect, useState } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import DirtyTermIndicator from './DirtyTermIndicator';

export default function EditTermItemNumber(props) {
    const { item, itemIndex, isLast, updateTermChoice, updateTermValue } = props;
    const [choice, setChoice] = useState(item.choice ? item.choice[item.choiceIndex] : '');
    const [isDirty, setIsDirty] = useState(false);
    const [value, setValue] = useState(item.value);
    const [initialValue, setInitialValue] = useState(null);
    const [initialChoice, setInitialChoice] = useState(null);

    //Did mount
    useEffect(() => {
        setInitialValue(item.value);
        setInitialChoice(item.choice ? item.choice[item.choiceIndex] : '');
    }, []);

    return (
        <Grid className={`item ${true === isLast ? 'last' : ''}`} item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={4} md={4} sm={4} xs={4} className={`name ${null !== item.value ? 'filled' : 'empty'}`}>
                {item.name}
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={7}>
                {item.valueType === 'number' && item.choice !== null && item.choice.length > 1 ? (
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <FormControl variant="outlined" style={{ marginRight: 10 }}>
                            <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={choice}
                                onChange={(event) => {
                                    const choice = event.target.value === '' ? null : event.target.value;
                                    setChoice(choice);
                                    updateTermChoice(item, itemIndex, choice);
                                    setIsDirty(choice !== initialChoice || value !== initialValue);
                                }}
                                label={'Type'}
                            >
                                {item.choice.map((choiceItem, index) => (
                                    <MenuItem key={index} value={choiceItem}>
                                        {choiceItem}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <OutlinedInput
                            onChange={(event) => {
                                const value = event.target.value === '' ? null : event.target.value;
                                setValue(value);
                                updateTermValue(item, itemIndex, value);
                                setIsDirty(value !== initialValue || choice !== initialChoice);
                            }}
                            type={'number'}
                            value={value}
                            labelWidth={0}
                            required={!item.isOptional}
                        />
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <OutlinedInput
                            onChange={(event) => {
                                const value = event.target.value === '' ? null : event.target.value;
                                setValue(value);
                                updateTermValue(item, itemIndex, value);
                                setIsDirty(value !== initialValue);
                            }}
                            type={'number'}
                            value={value}
                            labelWidth={0}
                            required={!item.isOptional}
                        />
                    </div>
                )}
            </Grid>
            <DirtyTermIndicator value={item.value} isDirty={isDirty} />
        </Grid>
    );
}
