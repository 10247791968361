import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goBack, push } from 'react-router-redux';
import _ from 'lodash';
import { Container, Item, RowItem } from '../../../../../pitch4_layout/components/div';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { Subtitle1 } from '../../../../../pitch4_layout/components/text';
import { P4StyleComponentContainer } from '../../../../../pitch4_layout/components/div';
import { ScreenTitle } from '../../../../../pitch4_layout';
import { Body1 } from '../../../../../pitch4_layout/components/text';
import { DateFormatter, EnumLabel, FactValue } from '../../../../../pitch4_layout/components/data';
import { KeyValueTable, KeyValueTableRow } from '../../../../../pitch4_tabular/components/kvtable';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import StatusEnum, {
    STATUS_WITHDRAWN,
    STATUS_COMPLETION_REJECTED,
    STATUS_IN_PROGRESS,
    STATUS_APPLICATION,
    STATUS_PENDING,
    STATUS_VALUATION,
    STATUS_LEGALS,
    STATUS_COMPLETION_REQUESTED,
    STATUS_COMPLETION_TIMED_OUT,
    STATUS_ON_HOLD,
    STATUS_ENDED,
} from '../../enum/LoanEnquiries/StatusEnum';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { Loading } from '../../../../../pitch4_layout/components/waiting';
import FactSummaryTable from '../../../../view_loan_enquiry/components/FactSummaryTable';
import LeadsContainer from './LeadsContainer';
import { getMainApplicantName } from '../../../../view_loan_enquiry/HelperFunctions';
import { LOAN_AMOUNT } from '../../../enum/MatchingRules/MetaKeyEnum';
import { ExpandedDateTimeLayout } from '../../../../../pitch4_layout/components/content';
import Notice, { NOTICE_TYPE_ERROR } from '../../../../../pitch4_layout/components/content/Notice';
import StatusWidget from '../../../../view_lender_loan_enquiry/components/StatusWidget';
import {
    actionInitiate,
    actionCleanup,
    selectorGetMatchingCriteriaConfig,
    selectorGetLoanEnquiry,
    actionApiSetStatus,
    selectorGetStatus,
} from '../redux/main';
import CreateGlobalIgnoreContainer from '../../administer_loanenquiries/containers/CreateGlobalIgnoreContainer';
import PrimaryButton from '../../../../../pitch4_layout/components/buttons/PrimaryButton';
import ModalLayout from '../../../../../pitch4_modal/src/component/ModalLayout';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import { actionReloadLoanEnquiry, actionSendMessageToLenders } from '../redux/main';
import AdminStatusBannerNotice from '../../../../bannernotice/containers/AdminStatusBannerNotice';
import { path, ROUTE_P4A_LLE } from '../../../../../pitch4_routing';
import LenderMessageDrawer from '../../../../../pitch4_layout/components/lender_message_drawer/LenderMessageDrawer';
import LoanEnquiryContextMenuWidget from '../components/LoanEnquiryContextMenuWidget';
import AdminEnquiryStatusOverride from '../../../intermediary/view_loan_enquiry/components/AdminEnquiryStatusOverride';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import ChatContainer from '../../chat/containers/ChatContainer';
import DocumentList from '../../administer_lenders_loanenquiries_view/components/DocumentList';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import DocumentSentModal from '../../../../../pitch4_layout/components/lender_message_drawer/DocumentSentModal';
import AdminNotesContainer from '../components/admin-notes/AdminNotesContainer';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import { clearChannelAlert } from '../../../../../pitch4_chat/api/channel';
import { clearAdminNotesAlerts } from '../api/admin_notes';
import { clearDocumentsAlert } from '../../../../../pitch4_documents/api/alerts';
import { getAssignableUsers } from '../../../../../pitch4_api/get_assignable_users';
import AssignToModal from '../../../../../pitch4_elements/loan-enquiry-owner/AssignToModal';
import { setLEAssignee } from '../../../../../pitch4_api/set_le_assignee';
import PersonIcon from '@material-ui/icons/Person';
import { removeLEAssignee } from '../../../../../pitch4_api/remove_le_assignee';
import RoleNameEnum from '../../../../../pitch4_enum/enum/RoleNameEnum';
import TitleEnum from '../../../enum/UserProfile/TitleEnum';
import { actionRemoveLoanEnquiryAlert } from '../redux/main';
import { Button, CircularProgress, Switch } from '@material-ui/core';
import { setLETermsSent } from '../../../../../pitch4_api/set_le_terms_sent';
import moment from 'moment';
import LenderTermsMatrix from '../../../../../pitch4_layout/components/lender_terms_matrix/LenderTermsMatrix';
import { STATUS_MATCH_REJECTED } from '../../enum/LenderLoanEnquiry/StatusEnum';
import RevenueModal from '../../../../../pitch4_elements/loan-enquiry-revenue/RevenueModal';
import { setLERevenue } from '../../../../../pitch4_api/set_le_revenue';
import { formatterInPounds } from '../../../../../pitchblack_react_utils/currency/Formatter';
import CompletedBar from '../components/CompletedBar';
import CompleteEditModal from '../components/CompleteEditModal';
import { STATUS_COMPLETE } from '../../../intermediary/enum/LoanEnquiries/StatusEnum';
import CompleteAlert from '../../../../../pitch4_elements/le-completed/CompleteAlert';
import CompleteModal from '../../../../../pitch4_elements/le-completed/CompleteModal';
import { setLEInvoiceSent } from '../../../../../pitch4_api/set_le_invoice_sent';
import { setLEInvoicePaid } from '../../../../../pitch4_api/set_le_invoice_paid';
import { setLECompletedDate } from '../../../../../pitch4_api/set_le_completed_date';
import { setLEFinalAmount } from '../../../../../pitch4_api/set_le_final_amount';
import { generateCreditConsentForms } from '../../../../../pitch4_api/generate_credit_consent_forms';
import DownloadCreditConsentButton from '../components/credit-consent/DownloadCreditConsentButton';
import LETags from '../../../../../pitch4_elements/le-tags/LETags';
import BrokerTypeEnum, {
    BROKER_TYPE_PARTNER,
    BROKER_TYPE_STANDARD,
} from '../../../../../pitch4_enum/enum/BrokerTypeEnum';
import ChaseDataModal from '../../administer_loanenquiries/components/ChaseDataModal';
import EditIcon from '@material-ui/icons/Edit';
import EventIcon from '@material-ui/icons/Event';
import { calculateLoanType } from '../../../../MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';
import BorrowerQuestions from '../../../../MakeLoanEnquiry/enums/Borrower/BorrowerQuestions';
import { NewLoanEnquirySummaryTable } from '../../../../MakeLoanEnquiry/containers/NewLoanEnquirySummaryTable';
import MatchDebug from '../components/match-debug/MatchDebug';
import Konami from 'react-konami-code';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const brokerTypeEnum = new BrokerTypeEnum();

class MasonryStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDebug: false,
            lenderMessageDrawOpen: false,
            lenderTermsMatrixOpen: false,
            documentSent: false,
            selectedLenders: [],
            lendersMessage: null,
            messageSent: false,
            tabValue: 0,
            acmChatAlertActive: false,
            acmChatAlertActiveUpdated: false,
            adminNotesAlertActive: false,
            adminNotesAlertUpdated: false,
            acmDocumentsAlertActive: false,
            acmDocumentsAlertUpdated: false,
            leAssignedTo: [
                {
                    id: 'no_owner',
                    name: 'No owner',
                },
            ],
            leAlertRemoved: false,
            leAssignedToUpdated: false,
            lenderDrawer: {
                open: false,
                anchor: 'right',
                lender: null,
                lenderLoanEnquiry: null,
            },
            termsSent: false,
            termsSentUpdated: null,
            termsSentStateUpdated: false,
            revenue: 0,
            revenueUpdated: false,
            completedDate: null,
            finalAmount: null,
            isInvoicePaid: false,
            isInvoicePaidUpdated: false,
            isInvoiced: false,
            isInvoicedUpdated: false,
            completedUpdated: false,
            creditConsentFormsGenerating: false,
            generatedCreditConsentForms: [],
            chaseDate: null,
            chaseNote: null,
            isChaseDateAndNoteUpdated: false,
        };
    }

    componentDidMount() {
        const {
            match: {
                params: { loanEnquiryId },
            },
            initiate,
        } = this.props;
        initiate(loanEnquiryId);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {
            acmChatAlertActiveUpdated,
            adminNotesAlertUpdated,
            acmDocumentsAlertUpdated,
            leAssignedToUpdated,
            termsSentStateUpdated,
            revenueUpdated,
            completedUpdated,
            isInvoicedUpdated,
            isInvoicePaidUpdated,
            isChaseDateAndNoteUpdated,
        } = this.state;
        const { loanEnquiry } = this.props;

        if (loanEnquiry) {
            if (!acmChatAlertActiveUpdated && loanEnquiry.channel) {
                this.setState({ acmChatAlertActive: loanEnquiry.channel.alertActive, acmChatAlertActiveUpdated: true });
            }

            if (!adminNotesAlertUpdated) {
                this.setState({
                    adminNotesAlertActive: loanEnquiry.adminNotesAlertActive,
                    adminNotesAlertUpdated: true,
                });
            }

            if (!acmDocumentsAlertUpdated) {
                this.setState({
                    acmDocumentsAlertActive: loanEnquiry.adminDocumentsAlertActive,
                    acmDocumentsAlertUpdated: true,
                });
            }

            if (!leAssignedToUpdated) {
                this.setState({
                    leAssignedTo: this.getAssignees(loanEnquiry.assignees),
                    leAssignedToUpdated: true,
                });
            }

            if (!termsSentStateUpdated) {
                this.setState({
                    termsSent: loanEnquiry.termsSent,
                    termsSentUpdated: loanEnquiry.termsSentUpdated,
                    termsSentStateUpdated: true,
                });
            }

            if (!revenueUpdated) {
                this.setState({
                    revenue: loanEnquiry.revenue || 0,
                    revenueUpdated: true,
                });
            }

            if (!completedUpdated) {
                this.setState({
                    completedDate: loanEnquiry.completedDate || null,
                    finalAmount: loanEnquiry.finalAmount || null,
                    isInvoicePaid: loanEnquiry.isInvoicePaid || false,
                    isInvoiced: loanEnquiry.isInvoiced || false,
                    completedUpdated: true,
                });
            }

            if (!isInvoicedUpdated) {
                this.setState({
                    isInvoiced: loanEnquiry.invoiceSent,
                    isInvoicedUpdated: true,
                });
            }

            if (!isInvoicePaidUpdated) {
                this.setState({
                    isInvoicePaid: loanEnquiry.invoicePaid,
                    isInvoicePaidUpdated: true,
                });
            }

            if (!isChaseDateAndNoteUpdated) {
                this.setState({
                    chaseDate: loanEnquiry.chaseDate,
                    chaseNote: loanEnquiry.chaseNote,
                    isChaseDateAndNoteUpdated: true,
                });
            }
        }
    }

    getAssignees = (assignees) => {
        if (assignees.length === 0) {
            return [
                {
                    id: 'no_owner',
                    name: 'No owner',
                },
            ];
        }

        // Always take the last assignee. This will change in the future once we handle multiple assignees
        const assigneeIndex = assignees.length - 1;

        return [
            {
                id: assignees[assigneeIndex].id,
                name: assignees[assigneeIndex].userProfile.given + ' ' + assignees[assigneeIndex].userProfile.family,
            },
        ];
    };

    componentWillUnmount() {
        const { cleanup } = this.props;
        cleanup();
    }

    toggleMessageDrawer = () => {
        if (!this.state.lenderMessageDrawOpen) {
            return this.setState({
                lenderMessageDrawOpen: true,
            });
        }

        return this.setState({
            lenderMessageDrawOpen: false,
            selectedLenders: [],
            lendersMessage: null,
            messageSent: false,
        });
    };

    setLendersMessage = (lendersMessage) => this.setState({ ...lendersMessage, lendersMessage });

    getLendersMessage = () => this.state.lendersMessage;

    setMessageSent = (isMessageSent) => this.setState({ messageSent: isMessageSent });

    getMessageSent = () => this.state.messageSent;

    findRejectedMatches = () =>
        (this.props.loanEnquiry.lenderLoanEnquiries || [])
            .filter((match) => match.status === STATUS_MATCH_REJECTED)
            .map((match) => match.id);

    handleLenderSelection = (selectedLenders) => {
        if (selectedLenders === null) {
            this.setState({ selectedLenders: [] });
            return;
        }
        this.setState({
            selectedLenders: selectedLenders.filter((lenderId) => {
                return !this.findRejectedMatches().includes(lenderId);
            }),
        });
    };

    handleAdminEnquiryStatusOverride = (status, lenderLoanEnquiryId) => (newStatus) => {
        const { setModalComponent, setModalClose } = this.props;
        if (null !== lenderLoanEnquiryId) {
            setModalComponent(
                <AdminEnquiryStatusOverride
                    handleClose={setModalClose}
                    onSubmit={this.handleAdminEnquiryStatusOverrideSubmit(status, newStatus, lenderLoanEnquiryId)}
                />,
                true
            );
        }
    };

    handleAdminEnquiryStatusOverrideSubmit = (status, newStatus, loanEnquiryId) => ({ reason }) => {
        const { updateStatusOnChange } = this.props;
        if (!newStatus || newStatus === status) {
            return;
        }

        updateStatusOnChange(loanEnquiryId, newStatus, reason);
    };

    getGlobalIgnoreSection = (loanEnquiry, reloadLoanEnquiry) => {
        let { setModalInteractive, setModalClose, setModalComponent } = this.props;
        if (loanEnquiry.loanEnquiryGlobalIgnore === null) {
            return (
                <PrimaryButton
                    onClick={() =>
                        setModalComponent(
                            <ModalLayout title="Create Global Ignore">
                                <CreateGlobalIgnoreContainer
                                    selectedLoanEnquiries={[loanEnquiry.id]}
                                    inProgress={() => setModalInteractive(true)}
                                    onSuccess={(data) => {
                                        setModalInteractive(false);
                                        reloadLoanEnquiry();
                                        setModalClose();
                                    }}
                                    onFailure={(error) => setModalInteractive(true)}
                                />
                            </ModalLayout>
                        )
                    }
                >
                    Ignore
                </PrimaryButton>
            );
        }

        return <Subtitle1 style={{ marginRight: 10 }}>This enquiry has been ignored </Subtitle1>;
    };

    handleTabSelect = (selectedTabIndex = 0) => this.setState({ tabValue: selectedTabIndex });

    buildAdminNotesLabel = () => {
        const { adminNotesAlertActive } = this.state;

        if (adminNotesAlertActive) {
            return (
                <span>
                    Admin Notes <Badge type={'admin-note-alert'} label={'New Note'} />
                </span>
            );
        }

        return 'Admin Notes';
    };

    buildMatchDebugLabel = () => {
        return 'Match Debug';
    };

    buildCaseDocumentsLabel = () => {
        const { acmDocumentsAlertActive } = this.state;

        if (acmDocumentsAlertActive) {
            return (
                <span>
                    Case Documents <Badge label={'update'} style={{ marginLeft: 5 }} />
                </span>
            );
        }

        return 'Case Documents';
    };

    buildCaseChatLabel = () => {
        const { acmChatAlertActive } = this.state;

        if (acmChatAlertActive) {
            return (
                <span>
                    Case Chat <Badge label={'update'} style={{ marginLeft: 5 }} />
                </span>
            );
        }

        return 'Case Chat';
    };

    toggleDocumentSentModal = (open = false) => {
        this.setState({
            documentSent: open,
            lenderMessageDrawOpen: false,
            selectedLenders: [],
        });
    };

    handleACMChatAlert = (acmChatAlertActive = false, channelId = null) => {
        if (channelId) {
            clearChannelAlert(channelId, API_URL).then((resp) => {
                this.setState({ acmChatAlertActive: acmChatAlertActive });
            });
        } else {
            this.setState({ acmChatAlertActive: acmChatAlertActive });
        }
    };

    handleACMDocumentsAlert = (leId = null) => {
        if (leId) {
            clearDocumentsAlert(leId, null, API_URL).then((resp) => {
                this.setState({
                    acmDocumentsAlertActive: false,
                });
            });
        } else {
            this.setState({
                acmDocumentsAlertActive: false,
            });
        }
    };

    handleAdminNotesAlert = (isActive = false, leId) => {
        clearAdminNotesAlerts(leId).then((resp) => {
            if (resp && resp.clearAdminNotesAlerts && resp.clearAdminNotesAlerts.success)
                this.setState({ adminNotesAlertActive: isActive, adminNotesAlertUpdated: true });
        });
    };

    handleAssignLETo = () => {
        const { setModalComponent, setModalClose, loanEnquiry } = this.props;
        const { leAssignedTo } = this.state;
        getAssignableUsers(API_URL).then((resp) => {
            setModalComponent(
                <AssignToModal
                    assignees={resp}
                    setModalClose={setModalClose}
                    userRole={ROLE_SUPER_ADMIN}
                    assignedTo={leAssignedTo[0]}
                    handleUpdateLEAssignee={this.handleUpdateLEAssignee(loanEnquiry.id)}
                />,
                true
            );
        });
    };

    handleUpdateLEAssignee = (leId) => (newAssignedTo) => {
        const { setModalClose } = this.props;
        let data;

        if (newAssignedTo.id === 'no_owner') {
            data = removeLEAssignee(API_URL, null, leId, [newAssignedTo.id]);
        } else {
            data = setLEAssignee(API_URL, null, leId, [newAssignedTo.id]);
        }

        data.then((resp) => {
            this.setState({ leAssignedTo: [newAssignedTo] }, () => setModalClose());
        });
    };

    handleRevenue = () => {
        const { setModalComponent, setModalClose, loanEnquiry } = this.props;
        const { revenue } = this.state;

        setModalComponent(
            <RevenueModal
                setModalClose={setModalClose}
                currentRevenue={revenue}
                handleRevenueUpdate={this.handleRevenueUpdate(loanEnquiry.id)}
            />,
            true
        );
    };

    handleRevenueUpdate = (leId) => (newRevenue) => {
        const { setModalClose } = this.props;

        setLERevenue(API_URL, leId, newRevenue).then((resp) => {
            this.setState({ revenue: newRevenue }, () => setModalClose());
        });
    };

    roleToClass = (role) => {
        if (typeof role !== 'string') {
            return '';
        }

        return role.toLowerCase().replace('_', '-');
    };

    handleTermsSent = (loanEnquiry) =>
        setLETermsSent(API_URL, loanEnquiry.id, !this.state.termsSent).then((resp) => {
            this.setState({
                termsSent: !this.state.termsSent,
                termsSentUpdated: moment(),
                termsSentStateUpdated: true,
            });
        });

    handleBrokerTypeChange = (loanEnquiry) =>
        setLETermsSent(API_URL, loanEnquiry.id, !this.state.termsSent).then((resp) => {
            this.setState({
                termsSent: !this.state.termsSent,
                termsSentUpdated: moment(),
                termsSentStateUpdated: true,
            });
        });

    handleLenderTermsMatrix = (lenderLoanEnquiries) => {
        const { setModalComponent, setModalClose, loanEnquiry, dispatch } = this.props;

        if (true === this.state.lenderTermsMatrixOpen) {
            setModalComponent(
                <LenderTermsMatrix
                    uri={API_URL}
                    loanEnquiryId={loanEnquiry.id}
                    lenders={lenderLoanEnquiries || []}
                    selectedLenders={null !== this.state.selectedLenders ? this.state.selectedLenders : []}
                    setModalClose={setModalClose}
                    setModalComponent={setModalComponent}
                    toggleLenderTermsMatrixModal={this.toggleLenderTermsMatrixModal}
                    handleLenderSelection={this.handleLenderSelection}
                    dispatch={dispatch}
                />,
                true
            );
        }
    };

    toggleLenderTermsMatrixModal = (isOpen = false) =>
        this.setState({
            lenderTermsMatrixOpen: isOpen,
        });

    sortMatches = (matches) =>
        matches.sort((first) => {
            if (null !== first.lenderTerms) {
                return -1;
            } else {
                return 1;
            }
        });

    setDebug = () => {
        this.setState({
            isDebug: true,
        });
    };

    render() {
        let {
            config,
            loanEnquiry,
            redirect,
            removeLoanEnquiryAlert,
            sendMessageToLenders,
            currentStatus = null,
            session,
            setModalComponent,
            setModalClose,
        } = this.props;
        const {
            leAlertRemoved,
            leAssignedTo,
            revenue,
            creditConsentFormsGenerating,
            chaseDate,
            chaseNote,
        } = this.state;

        if (_.isEmpty(config) || _.isEmpty(loanEnquiry)) {
            return (
                <Loading
                    onTimeoutResponse={
                        <Notice message={`Sorry, we cannot display this loan enquiry`} type={NOTICE_TYPE_ERROR} />
                    }
                />
            );
        }

        currentStatus = currentStatus || loanEnquiry.status;
        const questions = new BorrowerQuestions().getTypes();
        const enquiryWithdrawn = currentStatus === STATUS_WITHDRAWN;
        const completionRejected = currentStatus === STATUS_COMPLETION_REJECTED;
        const isGloballyDisabled = currentStatus === STATUS_ENDED;
        const enquiryOnHold = currentStatus === STATUS_ON_HOLD;
        const toStates = [STATUS_IN_PROGRESS, STATUS_ON_HOLD, STATUS_WITHDRAWN];
        const editableStates = {
            [STATUS_PENDING]: toStates,
            [STATUS_IN_PROGRESS]: [STATUS_ON_HOLD, STATUS_WITHDRAWN],
            [STATUS_WITHDRAWN]: [STATUS_IN_PROGRESS, STATUS_ON_HOLD],
            [STATUS_COMPLETION_REQUESTED]: toStates,
            [STATUS_COMPLETION_REJECTED]: toStates,
            [STATUS_COMPLETION_TIMED_OUT]: toStates,
            [STATUS_APPLICATION]: toStates,
            [STATUS_VALUATION]: toStates,
            [STATUS_LEGALS]: toStates,
            [STATUS_ON_HOLD]: [STATUS_IN_PROGRESS, STATUS_WITHDRAWN],
        };
        const isAdmin = session.getMostSeniorRole().name === ROLE_SUPER_ADMIN;
        const leSubmittedByRole = _.get(loanEnquiry, ['submittedBy', 'roles', 0, 'name'], null);
        const hasLeSubmittedByRole = leSubmittedByRole !== null;
        const brokerageName = _.get(loanEnquiry, ['submittedBy', 'companyName'], null);
        const hasBrokerageName = brokerageName !== null;
        const phoneNumber =
            loanEnquiry.submittedBy.userProfile.primaryPhone.number !== ''
                ? loanEnquiry.submittedBy.userProfile.primaryPhone.number
                : null;
        const submissionData = JSON.parse(loanEnquiry.submissionData['rawSubmission'] || '{}');
        const loanType = calculateLoanType(loanEnquiry.loanEnquiryData).value;

        const handleTabChange = (event, newValue) => {
            this.setState({ tabValue: newValue });
        };

        if (false === leAlertRemoved && loanEnquiry.alertActive) {
            this.setState({ leAlertRemoved: true }, () => removeLoanEnquiryAlert(loanEnquiry.id));
        }

        const downloadCreditConsentPDF = (document, index) => {
            const { setModalComponent, setModalClose } = this.props;
            return (
                <DownloadCreditConsentButton
                    key={index}
                    index={index}
                    document={document}
                    setModalComponent={setModalComponent}
                    setModalClose={setModalClose}
                />
            );
        };

        const handleCreditConsentGenerate = () => {
            this.setState(
                {
                    creditConsentFormsGenerating: true,
                    generatedCreditConsentForms: [],
                },
                () => {
                    generateCreditConsentForms(loanEnquiry.id).then((res) => {
                        this.setState({
                            generatedCreditConsentForms: res.generateConsentForms,
                            creditConsentFormsGenerating: false,
                        });
                    });
                }
            );
        };

        const handleCompletedModal = () => {
            setModalComponent(
                <CompleteEditModal
                    setModalClose={setModalClose}
                    handleCompletedUpdate={handleCompletedUpdate}
                    loanEnquiry={loanEnquiry}
                />,
                false
            );
        };

        const handleCompletedUpdate = (completedDate, finalAmount) => {
            setLEFinalAmount(API_URL, loanEnquiry.id, parseFloat(finalAmount)).then(() =>
                this.setState(
                    {
                        finalAmount: parseFloat(finalAmount),
                    },
                    () =>
                        setLECompletedDate(API_URL, loanEnquiry.id, moment(completedDate).format('YYYY-MM-DD')).then(
                            () =>
                                this.setState({
                                    completedDate: moment(completedDate),
                                })
                        )
                )
            );
        };

        const handleIsInvoiced = () =>
            setLEInvoiceSent(API_URL, loanEnquiry.id, !this.state.isInvoiced).then((resp) => {
                this.setState({
                    isInvoiced: !this.state.isInvoiced,
                    isInvoicedUpdated: true,
                });
            });

        const handleIsInvoicePaid = () =>
            setLEInvoicePaid(API_URL, loanEnquiry.id, !this.state.isInvoicePaid).then((resp) => {
                this.setState({
                    isInvoicePaid: !this.state.isInvoicePaid,
                    isInvoicePaidUpdated: true,
                });
            });

        const handleChaseDateChange = (leId, newChaseDate, newChaseNote) => {
            this.setState({ chaseDate: newChaseDate, chaseNote: newChaseNote });
        };

        const buildChaseDateContent = () => {
            const { chaseDate } = this.state;

            if (!chaseDate) {
                return (
                    <div>
                        <div>-</div>
                        <EventIcon />
                    </div>
                );
            }

            return (
                <div>
                    <div>{moment(chaseDate).format('D MMMM yyyy')}</div>
                    <EventIcon />
                </div>
            );
        };

        const buildChaseNoteContent = () => {
            const { chaseNote } = this.state;

            if (!chaseNote) {
                return (
                    <div>
                        <div>-</div>
                        <EditIcon />
                    </div>
                );
            }

            return (
                <div>
                    <div style={{ display: 'block' }}>
                        <div>{chaseNote}</div>
                        <div className={'read-more'}>read more</div>
                    </div>
                    <EditIcon style={{ alignSelf: 'center' }} />
                </div>
            );
        };

        return (
            <div className={'view-enquiry'}>
                {(loanEnquiry.status === STATUS_LEGALS || loanEnquiry.status === STATUS_VALUATION) && (
                    <CompleteAlert
                        setModalComponent={setModalComponent}
                        modalComponent={
                            <CompleteModal
                                setModalClose={setModalClose}
                                lenders={loanEnquiry.lenderLoanEnquiries || []}
                                amount={loanEnquiry.loanEnquiryData.loanAmount}
                                url={API_URL}
                                loanEnquiryId={loanEnquiry.id}
                            />
                        }
                        header={'Has this customer received funding? - '}
                        text={'Click here to mark this enquiry as completed'}
                    />
                )}
                <Konami action={this.setDebug} code={[17, 16, 83]}>
                    <></>
                </Konami>
                <AdminStatusBannerNotice
                    status={loanEnquiry.status}
                    adminOverrideReason={loanEnquiry.adminStatusOverrideReason}
                    isAdmin={isAdmin}
                    onClick={
                        completionRejected
                            ? redirect(
                                  path(ROUTE_P4A_LLE, {
                                      loanEnquiryId: loanEnquiry.id,
                                      lenderLoanEnquiryId: loanEnquiry.completionRequest.lenderLoanEnquiryId,
                                  })
                              )
                            : null
                    }
                />
                {this.state.documentSent && (
                    <DocumentSentModal
                        modalShow={this.state.documentSent}
                        toggleDocumentSentModal={this.toggleDocumentSentModal}
                    />
                )}
                {this.state.lenderTermsMatrixOpen &&
                    this.handleLenderTermsMatrix(
                        this.sortMatches([
                            ...loanEnquiry.lenderLoanEnquiries,
                            ...loanEnquiry.offPanelLenderLoanEnquiries,
                        ])
                    )}
                {this.state.lenderMessageDrawOpen && (
                    <LenderMessageDrawer
                        selectedLenders={this.state.selectedLenders}
                        toggleMessageDrawer={this.toggleMessageDrawer}
                        setMessageSent={this.setMessageSent}
                        sendMessageToLenders={sendMessageToLenders}
                        getLendersMessage={this.getLendersMessage}
                        setLendersMessage={this.setLendersMessage}
                        getMessageSent={this.getMessageSent}
                        open={this.state.lenderMessageDrawOpen}
                        anchor={'right'}
                        userRole={ROLE_SUPER_ADMIN}
                        toggleDocumentSentModal={this.toggleDocumentSentModal}
                    />
                )}
                <Container
                    className={`view-enquiry-header`}
                    justifyContent={`space-between`}
                    spacing={4}
                    alignItems={`center`}
                >
                    <Item>
                        <ScreenTitle
                            title={`Loan enquiry: ${getMainApplicantName(loanEnquiry.loanEnquiryApplicants)}`}
                        />
                    </Item>
                    <Item style={{ display: 'flex', alignItems: 'center' }}>
                        <Item style={{ marginRight: 20 }}>
                            <StatusWidget
                                onChange={this.handleAdminEnquiryStatusOverride(currentStatus, loanEnquiry.id)}
                                transitions={editableStates}
                                statusEnum={new StatusEnum()}
                                initialValues={{
                                    status: loanEnquiry.status,
                                }}
                                matchStatus={currentStatus}
                                enquiryWithdrawn={enquiryWithdrawn}
                                disabled={false}
                                loanType={loanType}
                            />
                        </Item>
                        <Item>
                            <LoanEnquiryContextMenuWidget
                                toggleMessageDrawer={this.toggleMessageDrawer}
                                handleTabSelect={this.handleTabSelect}
                                toggleLenderTermsMatrixModal={this.toggleLenderTermsMatrixModal}
                                selectedLenders={this.state.selectedLenders}
                                setModalComponent={setModalComponent}
                                setModalClose={setModalClose}
                            />
                        </Item>
                    </Item>
                </Container>
                <Container className={`view-enquiry-content`} justifyContent={`space-between`} spacing={4}>
                    <Item xs={12} md={5}>
                        <Container justifyContent={`space-between`} spacing={4}>
                            <Item xs={12} className={`view-enquiry-summary`}>
                                <P4StyleComponentContainer
                                    styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                    header={'Enquiry summary'}
                                    buttons={<div />}
                                    content={
                                        <KeyValueTable style={{ backgroundColor: '#fafafa', border: 'none' }}>
                                            <KeyValueTableRow
                                                term={<Subtitle1>Tags:</Subtitle1>}
                                                def={
                                                    <LETags
                                                        leId={loanEnquiry.id}
                                                        leTags={
                                                            loanEnquiry.tags || [
                                                                {
                                                                    value: 'ocean',
                                                                    label: 'Ocean',
                                                                    color: '#00B8D9',
                                                                },
                                                                {
                                                                    value: 'blue',
                                                                    label: 'Blue',
                                                                    color: '#0052CC',
                                                                },
                                                            ]
                                                        }
                                                        uri={API_URL}
                                                        setModalComponent={setModalComponent}
                                                        setModalClose={setModalClose}
                                                    />
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={<Subtitle1>Loan amount:</Subtitle1>}
                                                def={
                                                    <Body1>
                                                        <FactValue
                                                            name={LOAN_AMOUNT}
                                                            value={loanEnquiry.loanEnquiryData.loanAmount}
                                                        />
                                                    </Body1>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={<Subtitle1>Chase date:</Subtitle1>}
                                                def={
                                                    <Body1>
                                                        <div
                                                            className={'chase-date-container'}
                                                            onClick={() => {
                                                                setModalComponent(
                                                                    <ChaseDataModal
                                                                        setModalClose={setModalClose}
                                                                        chaseDate={chaseDate}
                                                                        chaseNote={chaseNote}
                                                                        loanEnquiryId={loanEnquiry.id}
                                                                        handleLEChaseDateChange={handleChaseDateChange}
                                                                    />,
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            {buildChaseDateContent()}
                                                        </div>
                                                    </Body1>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={<Subtitle1>Chase note:</Subtitle1>}
                                                def={
                                                    <Body1>
                                                        <div
                                                            className={'chase-note-container'}
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                setModalComponent(
                                                                    <ChaseDataModal
                                                                        setModalClose={setModalClose}
                                                                        chaseDate={chaseDate}
                                                                        chaseNote={chaseNote}
                                                                        loanEnquiryId={loanEnquiry.id}
                                                                        handleLEChaseDateChange={handleChaseDateChange}
                                                                    />,
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            {buildChaseNoteContent()}
                                                        </div>
                                                    </Body1>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={<Subtitle1>Reference number:</Subtitle1>}
                                                def={<Body1>{loanEnquiry.borrowerReference}</Body1>}
                                            />
                                            {true === hasLeSubmittedByRole && (
                                                <KeyValueTableRow
                                                    term={`Customer Type`}
                                                    def={
                                                        <span
                                                            className={`pill pill-${this.roleToClass(
                                                                leSubmittedByRole
                                                            )}`}
                                                        >
                                                            <EnumLabel
                                                                enumIn={new RoleNameEnum()}
                                                                value={leSubmittedByRole}
                                                            />
                                                        </span>
                                                    }
                                                />
                                            )}
                                            <KeyValueTableRow
                                                term={`Submitted by`}
                                                def={
                                                    <span className={`body-1`}>
                                                        <div
                                                            className={`${this.roleToClass(leSubmittedByRole)}-hint`}
                                                        />
                                                        <EnumLabel
                                                            enumIn={new TitleEnum()}
                                                            value={loanEnquiry.submittedBy.userProfile.title}
                                                        />{' '}
                                                        {loanEnquiry.submittedBy.userProfile.given}{' '}
                                                        {loanEnquiry.submittedBy.userProfile.family}
                                                    </span>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={true === hasBrokerageName ? 'Broker Email' : 'Borrower Email'}
                                                def={
                                                    <span className={`body-1`}>
                                                        <div
                                                            className={`${this.roleToClass(leSubmittedByRole)}-hint`}
                                                        />
                                                        {loanEnquiry.submittedBy.email ?? 'N/A'}
                                                    </span>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={true === hasBrokerageName ? 'Broker Phone' : 'Borrower Phone'}
                                                def={
                                                    <span className={`body-1`}>
                                                        <div
                                                            className={`${this.roleToClass(leSubmittedByRole)}-hint`}
                                                        />
                                                        {phoneNumber ?? 'N/A'}
                                                    </span>
                                                }
                                            />
                                            {null !== loanEnquiry.broker && (
                                                <KeyValueTableRow
                                                    term={`Broker Type`}
                                                    def={
                                                        <>
                                                            <span className={`body-1`}>
                                                                <div
                                                                    className={`${this.roleToClass(
                                                                        leSubmittedByRole
                                                                    )}-hint`}
                                                                />
                                                                {true === loanEnquiry.broker.isPartner
                                                                    ? brokerTypeEnum.getType(BROKER_TYPE_PARTNER)
                                                                    : brokerTypeEnum.getType(BROKER_TYPE_STANDARD)}
                                                            </span>
                                                        </>
                                                    }
                                                />
                                            )}
                                            <KeyValueTableRow
                                                term={<Subtitle1>Enquiry owner:</Subtitle1>}
                                                def={
                                                    <div
                                                        className={'assign-to'}
                                                        onClick={() => this.handleAssignLETo()}
                                                    >
                                                        <div className={'icon'}>
                                                            <PersonIcon />
                                                        </div>
                                                        <span
                                                            className={`name-${
                                                                leAssignedTo[0].id !== 'no_owner' ? 'active' : null
                                                            }`}
                                                        >
                                                            {leAssignedTo[0].name}
                                                        </span>
                                                    </div>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={<Subtitle1>Created</Subtitle1>}
                                                def={
                                                    <Body1>
                                                        <ExpandedDateTimeLayout value={loanEnquiry.createdAt} />
                                                    </Body1>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={<Subtitle1>Terms Out?</Subtitle1>}
                                                def={
                                                    <div>
                                                        <Switch
                                                            checked={this.state.termsSent}
                                                            onChange={(event) => this.handleTermsSent(loanEnquiry)}
                                                            color="primary"
                                                            name="termsSent"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            disabled={false}
                                                            id={loanEnquiry.id}
                                                        />
                                                        <span>
                                                            {null !== this.state.termsSentUpdated
                                                                ? moment(this.state.termsSentUpdated).format(
                                                                      'MMM D, YYYY'
                                                                  )
                                                                : ''}
                                                        </span>
                                                    </div>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={<Subtitle1>Enquiry revenue:</Subtitle1>}
                                                def={
                                                    <div className={'assign-to'} onClick={() => this.handleRevenue()}>
                                                        {formatterInPounds(revenue)}
                                                    </div>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={<Subtitle1>Contract signed:</Subtitle1>}
                                                def={
                                                    <Body1>
                                                        <span className={'contract-previously-contacted-lenders'}>
                                                            {true === loanEnquiry.contractSigned ? 'Yes' : 'No'}
                                                        </span>
                                                    </Body1>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={
                                                    <Subtitle1>
                                                        Any Lenders previously
                                                        <br />
                                                        contacted by Applicant:
                                                    </Subtitle1>
                                                }
                                                def={
                                                    <Body1>
                                                        <span className={'contract-previously-contacted-lenders'}>
                                                            {loanEnquiry.contractPreviouslyContactedLenders || '-'}
                                                        </span>
                                                    </Body1>
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={<Subtitle1>Generate Credit Consent Forms:</Subtitle1>}
                                                def={
                                                    <Body1>
                                                        <Button
                                                            variant={'contained'}
                                                            color={'primary'}
                                                            size={'small'}
                                                            style={{ marginBottom: 10, width: 140, height: 25 }}
                                                            onClick={() => handleCreditConsentGenerate()}
                                                        >
                                                            {true === creditConsentFormsGenerating ? (
                                                                <CircularProgress
                                                                    style={{ color: '#f1f3f4' }}
                                                                    size={18}
                                                                />
                                                            ) : (
                                                                'Generate PDF Forms'
                                                            )}
                                                        </Button>
                                                    </Body1>
                                                }
                                            />
                                            {this.state.generatedCreditConsentForms.length > 0 && (
                                                <KeyValueTableRow
                                                    term={
                                                        <>
                                                            <Subtitle1>Download Credit Consent Forms:</Subtitle1>
                                                            <br />
                                                            <small>(to be uploaded to DocuSign)</small>
                                                        </>
                                                    }
                                                    def={
                                                        <Body1>
                                                            {this.state.generatedCreditConsentForms.map((form, index) =>
                                                                downloadCreditConsentPDF(form, index)
                                                            )}
                                                        </Body1>
                                                    }
                                                />
                                            )}
                                        </KeyValueTable>
                                    }
                                />
                            </Item>
                            <Item xs={12} className={`view-enquiry-applicants`}>
                                <P4StyleComponentContainer
                                    styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                    header={'Applicant details'}
                                    buttons={<div />}
                                    content={
                                        <React.Fragment>
                                            {loanEnquiry.loanEnquiryApplicants.map((loanEnquiryApplicant, index) => (
                                                <KeyValueTable
                                                    key={`applicant${index}`}
                                                    style={{ backgroundColor: '#fafafa', border: 'none' }}
                                                >
                                                    <KeyValueTableRow
                                                        term={
                                                            loanEnquiryApplicant.isMainApplicant ? (
                                                                <div>Main Applicant Name</div>
                                                            ) : (
                                                                <div>Applicant Name</div>
                                                            )
                                                        }
                                                        def={
                                                            <span
                                                                key={'le_applicant_' + index}
                                                                className="text-highlight-green"
                                                            >
                                                                {loanEnquiryApplicant.applicant.given}{' '}
                                                                {loanEnquiryApplicant.applicant.family}
                                                            </span>
                                                        }
                                                    />
                                                    <KeyValueTableRow
                                                        term={<div>Date of birth</div>}
                                                        def={
                                                            <DateFormatter
                                                                value={loanEnquiryApplicant.applicant.dateOfBirth}
                                                            />
                                                        }
                                                    />
                                                </KeyValueTable>
                                            ))}
                                        </React.Fragment>
                                    }
                                />
                            </Item>
                            <Item xs={12} className={`view-enquiry-info`}>
                                {loanEnquiry.isNewForm === true && (
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={'Enquiry information'}
                                        buttons={<div />}
                                        content={
                                            <NewLoanEnquirySummaryTable
                                                loanEnquiry={loanEnquiry}
                                                loanEnquiryData={loanEnquiry.loanEnquiryData}
                                                lenderLoanEnquiry={null}
                                            />
                                        }
                                    />
                                )}

                                {loanEnquiry.isNewForm === false && (
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={'Enquiry information'}
                                        buttons={<div />}
                                        content={
                                            <FactSummaryTable
                                                config={config}
                                                loanEnquiry={loanEnquiry}
                                                loanEnquiryStoredData={loanEnquiry.storedData}
                                                loanEnquirySubmissionData={submissionData}
                                                questions={questions}
                                            />
                                        }
                                    />
                                )}
                            </Item>
                        </Container>
                    </Item>
                    <Item className={'view-enquiry-matches'} xs={12} md={7}>
                        {loanEnquiry.status === STATUS_COMPLETE && (
                            <CompletedBar
                                completedDate={this.state.completedDate}
                                finalAmount={this.state.finalAmount}
                                isInvoicePaid={this.state.isInvoicePaid}
                                isInvoiced={this.state.isInvoiced}
                                setModalComponent={setModalComponent}
                                setModalClose={setModalClose}
                                handleIsInvoiced={handleIsInvoiced}
                                handleInvoicePaid={handleIsInvoicePaid}
                                handleCompletedModal={handleCompletedModal}
                            />
                        )}
                        <AppBar className={'app-bar'} position="static">
                            <Tabs value={this.state.tabValue} onChange={handleTabChange}>
                                <Tab
                                    label={'Lenders'}
                                    {...a11yProps(0)}
                                    style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif' }}
                                />
                                <Tab
                                    label={'Off-Panel Lenders'}
                                    {...a11yProps(1)}
                                    style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif' }}
                                />
                                <Tab
                                    label={this.buildCaseChatLabel()}
                                    {...a11yProps(2)}
                                    style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif' }}
                                />
                                <Tab
                                    label={this.buildCaseDocumentsLabel()}
                                    {...a11yProps(3)}
                                    style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif' }}
                                />
                                <Tab
                                    label={this.buildAdminNotesLabel()}
                                    {...a11yProps(4)}
                                    style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif' }}
                                />
                                <Tab
                                    label={this.buildMatchDebugLabel()}
                                    {...a11yProps(5)}
                                    style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif' }}
                                />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={this.state.tabValue} index={0}>
                            <div className={'admin-leads-container-main'}>
                                <LeadsContainer
                                    loanEnquiry={loanEnquiry}
                                    loanEnquiryId={loanEnquiry.id}
                                    matches={this.sortMatches(loanEnquiry.lenderLoanEnquiries) || []}
                                    loanEnquiryStatus={loanEnquiry.status}
                                    handleLenderSelection={this.handleLenderSelection}
                                    selectedLenders={this.state.selectedLenders}
                                    toggleLenderDrawer={this.toggleLenderDrawer}
                                    matchCount={loanEnquiry.lenderLoanEnquiries.length}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={1}>
                            <div className={'admin-leads-container-main'}>
                                <LeadsContainer
                                    title={'Off-Panel Lenders'}
                                    loanEnquiry={loanEnquiry}
                                    loanEnquiryId={loanEnquiry.id}
                                    matches={this.sortMatches(loanEnquiry.offPanelLenderLoanEnquiries) || []}
                                    loanEnquiryStatus={loanEnquiry.status}
                                    handleLenderSelection={this.handleLenderSelection}
                                    selectedLenders={this.state.selectedLenders}
                                    toggleLenderDrawer={this.toggleLenderDrawer}
                                    matchCount={loanEnquiry.offPanelLenderLoanEnquiries.length}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={2} className={'admin-case-chat-container'}>
                            <P4StyleComponentContainer
                                buttonStyles={{ paddingRight: 36 }}
                                headerStyles={{ paddingLeft: 36 }}
                                content={
                                    <div className={'view-lead'}>
                                        <div className={'view-lead-content'}>
                                            <Item xs={12} className={`view-lead-lender-comms`} id={'lender-chat'}>
                                                <div className={'p4style-component-container'}>
                                                    <RowItem className={`p4style-component-body`}>
                                                        <ChatContainer
                                                            loanEnquiryId={loanEnquiry.id}
                                                            channelId={
                                                                loanEnquiry && loanEnquiry.channel
                                                                    ? loanEnquiry.channel.id
                                                                    : null
                                                            }
                                                            disableForm={
                                                                enquiryWithdrawn || isGloballyDisabled || enquiryOnHold
                                                            }
                                                            handleACMChatAlert={this.handleACMChatAlert}
                                                        />
                                                    </RowItem>
                                                </div>
                                            </Item>
                                        </div>
                                    </div>
                                }
                            />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={3} className={'admin-case-documents-container'}>
                            <div>
                                <DocumentList
                                    disabled={isGloballyDisabled || session.hasAssumedToken() || enquiryOnHold}
                                    loanEnquiry={loanEnquiry}
                                    disableUploadButton={
                                        enquiryWithdrawn ||
                                        isGloballyDisabled ||
                                        session.hasAssumedToken() ||
                                        enquiryOnHold
                                    }
                                    handleACMDocumentsAlert={this.handleACMDocumentsAlert}
                                />
                            </div>
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={4}>
                            <AdminNotesContainer
                                loanEnquiryId={loanEnquiry.id}
                                handleAdminNotesAlert={this.handleAdminNotesAlert}
                            />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={5}>
                            <MatchDebug
                                loanEnquiryId={loanEnquiry.id}
                                loanEnquiryStatus={loanEnquiry.status}
                                loanEnquiry={loanEnquiry}
                                setModalComponent={setModalComponent}
                                setModalClose={setModalClose}
                                isDebug={this.state.isDebug}
                            />
                        </TabPanel>
                    </Item>
                </Container>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        config: selectorGetMatchingCriteriaConfig(state),
        loanEnquiry: selectorGetLoanEnquiry(state),
        currentStatus: selectorGetStatus(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        initiate: (loanEnquiryId) => dispatch(actionInitiate(loanEnquiryId)),
        cleanup: () => dispatch(actionCleanup()),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        updateStatusOnChange: (leadId, newStatus, reason) => {
            dispatch(actionApiSetStatus(leadId, newStatus, reason));
        },
        removeLoanEnquiryAlert: (status) => {
            dispatch(actionRemoveLoanEnquiryAlert(status));
        },
        reloadLoanEnquiry: () => dispatch(actionReloadLoanEnquiry()),
        sendMessageToLenders: (lenders, message, toggleMessageDrawer, setMessageSent) => () => {
            dispatch(actionSendMessageToLenders(lenders, message, toggleMessageDrawer, setMessageSent));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN]),
    withSession
)(MasonryStyleContainer);
