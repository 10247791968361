import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { SubmissionError } from 'redux-form';
import { SingleFormContentLayout } from 'pitch4_layout/components/content';
import Mapper from '../form/Mapper';
import Form from '../components/Form';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import { path } from 'pitch4_routing';
import NoQueryMutation from 'pitch4_forms/components/NoQueryMutation';
import { ROUTE_P4A_ADMINISTER_LENDERS_LIST } from 'pitch4_routing';
import { Container } from '../../../../../pitch4_layout/components/div';

class CreateContainer extends React.Component {
    render() {
        return (
            <Container className={`account-settings`}>
                <SingleFormContentLayout
                    label={'Add Lender'}
                    content={
                        <NoQueryMutation
                            mutationQuery={gql`
                                mutation CreateLender($input: pitch4admin_createLenderInput!) {
                                    createLender(input: $input) {
                                        lender {
                                            id
                                            name
                                            primaryEmail
                                            fcaIdentifier
                                            companyNumber
                                            status
                                            createdAt
                                            updatedAt
                                            profileUrl
                                            profileDescription
                                            profileEnabled
                                            primaryAddress {
                                                address1
                                                address2
                                                address3
                                                address4
                                                country
                                                postcode
                                            }
                                            primaryPhone {
                                                number
                                            }
                                            offPanel
                                        }
                                    }
                                }
                            `}
                            renderFormFunction={(mutate, props) => {
                                return <Form onSubmit={this.onSubmit(mutate, props)} />;
                            }}
                        />
                    }
                />
            </Container>
        );
    }

    onSubmit = (mutate, props) => (values, dispatch, formProps) => {
        let { notificationSuccess, notificationError, redirect, goBack } = props;
        let { reset } = formProps;
        let mapper = new Mapper();
        let mappedValues = mapper.mapFromForm(values);

        //map the variables if necessary
        return mutate({
            variables: {
                input: mappedValues,
            },
            context: {
                uri: API_URL,
            },
        })
            .then(({ data }) => {
                reset();

                if (!data || !data.lender) {
                    goBack();
                    notificationError(`Lender creation failed due to fatal error. Contact support`);
                }

                let configRedirect = redirect(path(ROUTE_P4A_ADMINISTER_LENDERS_LIST));
                configRedirect();

                notificationSuccess('Lender created successfully');
            })
            .catch((error) => {
                notificationError(`Lender creation failed.`);
                throw new SubmissionError({ _error: error.graphQLErrors });
            });
    };
}

CreateContainer.propTypes = {
    match: PropTypes.any,
};

export default compose(withRouter, renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN]))(CreateContainer);
