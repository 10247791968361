import { BaseEnum } from 'pitch4_enum';

export const TYPE_RESIDENTIAL_OCCUPIED = 'RESIDENTIAL_OCCUPIED';
export const TYPE_RESIDENTIAL_AND_INVESTMENT = 'RESIDENTIAL_AND_INVESTMENT';
export const TYPE_INVESTMENT_ONLY = 'INVESTMENT_ONLY';
export const TYPE_NO = 'NO';

class OwnAnyOtherPropertyTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_RESIDENTIAL_OCCUPIED]: 'Residential (Home Owner/Occupier)',
            [TYPE_RESIDENTIAL_AND_INVESTMENT]: 'Residential & Investment',
            [TYPE_INVESTMENT_ONLY]: 'Investment properties only',
            [TYPE_NO]: 'No',
        };
    };

    getOptions = () => {
        return [
            { value: TYPE_RESIDENTIAL_OCCUPIED, label: 'Residential (Home Owner/Occupier)' },
            { value: TYPE_RESIDENTIAL_AND_INVESTMENT, label: 'Residential & Investment' },
            { value: TYPE_INVESTMENT_ONLY, label: 'Investment properties only' },
            { value: TYPE_NO, label: 'No' },
        ];
    };
}

export default OwnAnyOtherPropertyTypeEnum;
