import React from 'react';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import { STATUS_ACTIVE } from '../../enum/BusinessStreams/StatusEnum';
import { PrimaryButton } from 'pitch4_layout/components/buttons';

class ToggleStatusButton extends React.Component {
    render() {
        let { children, disabled, ...other } = this.props;

        return (
            <PrimaryButton disabled={disabled} onClick={this.onClick} {...other}>
                {children}
            </PrimaryButton>
        );
    }

    onClick = () => {
        let { outerRefetch, mutate, id, useStatus, notificationSuccess, notificationError } = this.props;

        return mutate({
            variables: {
                id: id,
                input: {
                    newStatus: useStatus,
                },
            },
        })
            .then(({ data }) => {
                const msg = useStatus === STATUS_ACTIVE ? 'Business stream enabled' : 'Business stream disabled';
                notificationSuccess(msg);
                outerRefetch();
            })
            .catch((error) => {
                notificationError('Sorry, an unexpected error occurred. Please contact support if it persists.');
            });
    };
}

ToggleStatusButton.propTypes = {
    id: PropTypes.number.isRequired,
    mutate: PropTypes.func.isRequired,
    outerRefetch: PropTypes.func.isRequired,
    useStatus: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.any,
};

const SetStatusesGql = gql`
    mutation setStatus($id: ID!, $input: slam_setBusinessStreamStatusInput!) {
        setBusinessStreamStatus(id: $id, input: $input) {
            businessStream {
                id
                status
            }
        }
    }
`;

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(SetStatusesGql, {
        options: {
            context: {
                uri: SLAM_URL,
            },
        },
    })
)(ToggleStatusButton);
