import { spawn } from 'redux-saga/effects';

import hubspotConversationsRootSaga from './conversations';
import hubspotTrackingRootSaga from './tracking';

function* main() {
    yield spawn(hubspotConversationsRootSaga);
    yield spawn(hubspotTrackingRootSaga);
}

export default main;
