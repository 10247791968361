import React from 'react';
import { RightAligned, RowItem } from '../../pitch4_layout/components/div';
import { Container, Item } from '../../pitchblack_react_utils/layout';
import { DateField, TextField } from '../../pitch4_forms/components/inputs';
import {
    hasMaxLength255,
    hasMinLength2,
    isDateTime,
    isEmail,
    isInDateRange,
    isRequired,
    isValidPostcode,
} from '../../pitchblack_react_utils/form/validation';
import { PrimaryButton, SecondaryButton } from '../../pitch4_layout/components/buttons';

const isValidApplicantAge = isInDateRange(
    -100,
    'years',
    'The maximum age allowed for an applicant is 99',
    -18,
    'years',
    'The minimum age allowed for an applicant is 18'
);

export default function FormContent(props) {
    const { submitting, reset } = props;
    return (
        <Container>
            <RowItem>
                <Container>
                    <Item xs={12} sm={12} md={6} lg={6} style={{ paddingRight: 10 }}>
                        <TextField
                            name="given"
                            placeholder={'First name'}
                            disabled={submitting}
                            validate={[isRequired, hasMinLength2, hasMaxLength255]}
                        />
                    </Item>
                    <Item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            name="family"
                            placeholder={'Last name'}
                            disabled={submitting}
                            validate={[isRequired, hasMinLength2, hasMaxLength255]}
                        />
                    </Item>
                </Container>
            </RowItem>
            <RowItem>
                <Container>
                    <Item xs={12} sm={12} md={6} lg={6} style={{ paddingRight: 10 }}>
                        <TextField
                            name="address1"
                            placeholder={'Address line 1'}
                            disabled={submitting}
                            validate={[hasMinLength2, hasMaxLength255]}
                        />
                    </Item>
                    <Item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            name="address2"
                            placeholder={'Address line 2'}
                            disabled={submitting}
                            validate={[hasMinLength2, hasMaxLength255]}
                        />
                    </Item>
                </Container>
            </RowItem>
            <RowItem>
                <Container>
                    <Item xs={12} sm={12} md={6} lg={6} style={{ paddingRight: 10 }}>
                        <TextField
                            name="postcode"
                            placeholder={'Postcode'}
                            disabled={submitting}
                            validate={[hasMinLength2, hasMaxLength255, isValidPostcode]}
                        />
                    </Item>
                    <Item xs={12} sm={12} md={6} lg={6}>
                        <TextField
                            name="address3"
                            placeholder={'City'}
                            disabled={submitting}
                            validate={[hasMinLength2, hasMaxLength255]}
                        />
                    </Item>
                </Container>
            </RowItem>
            <RowItem>
                <Container>
                    <Item xs={12} sm={12} md={6} lg={6} style={{ paddingRight: 10 }}>
                        <TextField
                            name="email"
                            placeholder={'Email address'}
                            disabled={submitting}
                            validate={[hasMinLength2, hasMaxLength255, isEmail]}
                        />
                    </Item>
                    <Item xs={12} sm={12} md={6} lg={6} style={{ paddingRight: 10 }}>
                        <DateField
                            name="dateOfBirth"
                            placeholder="DOB [dd/mm/yyyy]"
                            disabled={submitting}
                            validate={[isRequired, isDateTime, isValidApplicantAge]}
                            disableFuture={true}
                            fullWidth={true}
                            className="applicant-date-of-birth"
                        />
                    </Item>
                </Container>
            </RowItem>
            <RowItem>
                <RightAligned className={'button-panel create-reset-buttons'}>
                    <SecondaryButton disabled={submitting} onClick={reset}>
                        Reset
                    </SecondaryButton>
                    <PrimaryButton type="submit" disabled={submitting}>
                        Submit
                    </PrimaryButton>
                </RightAligned>
            </RowItem>
        </Container>
    );
}
