import React from 'react';
import { Container, Item, P4StyleComponentContainer } from '../../../../../pitch4_layout/components/div';
import NoMutationQuery from '../../../../../pitch4_forms/components/NoMutationQuery';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { goBack, push } from 'react-router-redux';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { Body1, Subtitle1 } from '../../../../../pitch4_layout/components/text';
import { ScreenTitle } from '../../../../../pitch4_layout';
import gql from 'graphql-tag';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import { DateFormatter } from '../../../../../pitch4_layout/components/data';
import { KeyValueTable, KeyValueTableRow } from '../../../../../pitch4_tabular/components/kvtable/index';
import ChatContainer from '../../chat/containers/ChatContainer';
import DocumentList from '../components/DocumentList';
import { Loading } from '../../../../../pitch4_layout/components/waiting';
import FactSummaryTable from '../../../../view_loan_enquiry/components/FactSummaryTable';
import {
    actionApiSetStatus,
    actionClearStatus,
    actionGetMatchingCriteriaConfig,
    selectorGetMatchingCriteriaConfig,
    selectorGetStatus,
} from '../redux/main';
import { DateTimeFormatter } from '../../../../../pitch4_layout/components/data/formatter';
import {
    STATUS_COMPLETION_TIMED_OUT,
    STATUS_ON_HOLD,
    STATUS_PENDING,
    STATUS_WITHDRAWN,
    STATUS_WITHDRAWN as LE_STATUS_WITHDRAWN,
} from '../../enum/LoanEnquiries/StatusEnum';
import AdminStatusBannerNotice from '../../../../bannernotice/containers/AdminStatusBannerNotice';
import _ from 'lodash';
import Notice, { NOTICE_TYPE_ERROR } from '../../../../../pitch4_layout/components/content/Notice';
import StatusWidget from '../../../../view_lender_loan_enquiry/components/StatusWidget';
import AdministerCompletionRejectionForm from '../components/AdministerCompletionRejectionForm';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import RoleNameEnum from '../../../../../pitch4_enum/enum/RoleNameEnum';
import EnumLabel from '../../../../../pitch4_layout/components/data/EnumLabel';
import StatusEnum, {
    STATUS_MATCHED,
    STATUS_APPLICATION,
    STATUS_COMPLETION_REQUESTED,
    STATUS_IN_PROGRESS,
    STATUS_LEGALS,
    STATUS_VALUATION,
    STATUS_COMPLETE,
    STATUS_COMPLETION_REJECTED,
} from '../../../enum/LenderLoanEnquiries/StatusEnum';
import AdminLeadStatusOverride from '../components/AdminLeadStatusOverride';

class MasonryStyleContainer extends React.Component {
    componentDidMount() {
        const { getConfig } = this.props;

        getConfig();
    }

    componentWillUnmount() {
        const { clearStatus } = this.props;

        clearStatus();
    }

    getMainApplicantName = (loanEnquiryApplicants) => {
        let applicantName = '';
        loanEnquiryApplicants.forEach((loanEnquiryApplicant) => {
            if (loanEnquiryApplicant.isMainApplicant) {
                applicantName = loanEnquiryApplicant.applicant.given + ' ' + loanEnquiryApplicant.applicant.family;
            }
        });
        return applicantName;
    };

    manageCompletionRejected = (leadId) => {
        const { updateStatusOnChange, setModalComponent, setModalClose } = this.props;
        const handleSubmit = () => ({ newStatus, metadata }) => {
            updateStatusOnChange(leadId, newStatus, metadata);
        };
        setModalComponent(
            <AdministerCompletionRejectionForm handleClose={setModalClose} onSubmit={handleSubmit()} />,
            true
        );
    };

    roleToClass = (role) => {
        if (typeof role !== 'string') {
            return '';
        }

        return role.toLowerCase().replace('_', '-');
    };

    handleAdminLeadStatusOverride = (status, leadId) => (newStatus) => {
        const { setModalComponent, setModalClose } = this.props;
        const netLoanRequired = newStatus === STATUS_COMPLETE;
        if (null !== leadId) {
            setModalComponent(
                <AdminLeadStatusOverride
                    handleClose={setModalClose}
                    netLoanRequired={netLoanRequired}
                    onSubmit={this.handleAdminLeadStatusOverrideSubmit(status, newStatus, leadId)}
                />,
                true
            );
        }
    };

    handleAdminLeadStatusOverrideSubmit = (status, newStatus, leadId) => ({ reason, netLoanAmount }) => {
        const { updateStatusOnChange } = this.props;
        if (!newStatus || newStatus === status) {
            return;
        }

        if (!netLoanAmount) {
            netLoanAmount = 0;
        }

        updateStatusOnChange(leadId, newStatus, reason, netLoanAmount);
    };

    render() {
        let { currentStatus = null, match, config = null } = this.props;

        return (
            <NoMutationQuery
                queryContext={{
                    uri: API_URL,
                }}
                queryVariables={{
                    lenderLoanEnquiry: match.params.lenderLoanEnquiryId,
                    loanEnquiry: match.params.loanEnquiryId,
                }}
                query={gql`
                    query getLenderLoanEnquiry($lenderLoanEnquiry: ID!, $loanEnquiry: ID!) {
                        lenderLoanEnquiry(id: $lenderLoanEnquiry) {
                            id
                            status
                            lenderReference
                            channel {
                                id
                            }
                            lender {
                                name
                            }
                            createdAt
                            updatedAt
                            matchedAt
                        }
                        loanEnquiry(id: $loanEnquiry) {
                            id
                            status
                            isNewForm
                            loanEnquiryData {
                                loanToValue
                                loanToCost
                                loanToGrossDevelopmentValue
                                dayOneLoanAmountPercentage
                                additionalInformation
                                rentalIncomeResidential
                                hmoBedroom
                                hmoRoomsSelfContained
                                hmoHasLicense
                                hmoNeedsLicense
                                buyToLetExperience
                                rentalIncomeCommercial
                                rentalIncomePortfolio
                                rentalIncome
                                commercialUnitsRentedOut
                                commercialUnitOwnerOccupied
                                commercialUnitAmount
                                commercialType
                                commercialTypeOther
                                siteDescription
                                siteIntention
                                refinance
                                businessSeasonal
                                businessType
                                businessName
                                businessWebsite
                                businessTradingLength
                                businessSector
                                businessFacilityNeededBy
                                businessFacilityTerm
                                businessCurrentPaymentTerms
                                businessOutstandingInvoices
                                businessPdqIncome
                                propertyType
                                loanAmount
                                loanPurpose
                                turnOver
                                businessProfitable
                                businessOverdraftFacility
                                businessOutstandingDebt
                                businessAdverseCredit
                                businessAdverseCreditExplanation
                                businessDirectorsAdverseCredit
                                businessDirectorsAdverseCreditExplanation
                                shareholdersAreHomeOwners
                                propertyAvailableForAdditionalSecurity
                                planningFor
                                developmentLength
                                refurbishmentLength
                                outstandingLoan
                                dayOneLoanAmount
                                unitsPreSold
                                worksExplanation
                                totalDevelopmentCosts
                                buildCostsBorrowing
                                expectedGrossDevelopmentValue
                                previousDevelopmentExperience
                                previousRefurbishmentExperience
                                teamDevelopmentExperience
                                epcRating
                                loanType
                                fundingType
                                businessSecuredFinance
                                loanUsedInUkEu
                                planningInPlace
                                endValue
                                percentageResidential
                                percentageCommercial
                                lender
                                firstChargeAmount
                                currentValue
                                properties
                                portfolioValue
                                portfolioAlreadyOwned
                                loanTerm
                                loanNeededBy
                                preApproveMatches
                                loanAmountRefurbishment
                                percentageResidentialSquareFeet
                                percentageResidentialValue
                                percentageCommercialValue
                                residentialUnitCount
                                residentialUnitValue
                                businessOccupyEntireProperty
                                commercialUnitRentBreakdown
                                commercialTenantAmount
                                ownsOtherCommercialProperty
                                ownOtherProperties
                                occupyProperty
                                ownsOtherInvestmentProperties
                                familyHasOccupied
                                secondCharge
                                occupyingGreaterThan40Percent
                                location
                                locationOther
                                address
                                postcode
                                propertyOwnership
                                refurbishmentCost
                                invoiceFinanceType
                                leaseHirePurchase
                                mainApplicantCreditDifficulties
                                mainApplicantCreditDifficultiesExplanation
                                mainApplicantCitizenshipStatus
                                mainApplicantCountryOfResidence
                                mainApplicantCountryOfResidenceOther
                                mainApplicantNationality
                                mainApplicantNationalityOther
                                mainApplicantOccupation
                                mainApplicantGrossIncome
                                mainApplicantExitStrategy
                                agreeToTerms
                            }
                            storedData {
                                id
                                meta {
                                    key
                                    type
                                }
                                value
                            }
                            submittedBy {
                                roles {
                                    name
                                }
                                userProfile {
                                    title
                                    given
                                    family
                                }
                                broker {
                                    name
                                }
                            }
                            loanEnquiryApplicants {
                                isMainApplicant
                                applicant {
                                    given
                                    family
                                    dateOfBirth
                                }
                            }
                            submissionData {
                                rawSubmission
                            }
                        }
                    }
                `}
                mapQueryFunction={({ lenderLoanEnquiry = {}, loanEnquiry = {} }) => {
                    return {
                        lenderLoanEnquiry,
                        loanEnquiry,
                    };
                }}
                renderFunction={({ mappedValues, errors, error, data, loading, refetch, ...stuff }) => {
                    let { lenderLoanEnquiry = {}, loanEnquiry = {} } = mappedValues;

                    if (_.isEmpty(config) || _.isEmpty(lenderLoanEnquiry) || _.isEmpty(loanEnquiry)) {
                        return (
                            <Loading
                                onTimeoutResponse={
                                    <Notice
                                        message={`Sorry, we cannot display this loan match`}
                                        type={NOTICE_TYPE_ERROR}
                                    />
                                }
                            />
                        );
                    }

                    const enquiryWithdrawn = loanEnquiry.status === LE_STATUS_WITHDRAWN;
                    currentStatus = currentStatus || lenderLoanEnquiry.status;
                    const completionRejected = lenderLoanEnquiry.status === STATUS_COMPLETION_REJECTED;

                    const leSubmittedByRole = _.get(loanEnquiry, ['submittedBy', 'roles', 0, 'name'], null);
                    const hasLeSubmittedByRole = leSubmittedByRole !== null;
                    const brokerageName = _.get(loanEnquiry, ['submittedBy', 'broker', 'name'], null);
                    const hasBrokerageName = brokerageName !== null;

                    const editableStates = {
                        [STATUS_PENDING]: [STATUS_IN_PROGRESS, STATUS_ON_HOLD, STATUS_WITHDRAWN],
                        [STATUS_MATCHED]: [STATUS_IN_PROGRESS, STATUS_WITHDRAWN],
                        [STATUS_IN_PROGRESS]: [STATUS_COMPLETE, STATUS_ON_HOLD, STATUS_WITHDRAWN],
                        [STATUS_WITHDRAWN]: [STATUS_IN_PROGRESS, STATUS_ON_HOLD],
                        [STATUS_COMPLETION_REQUESTED]: [
                            STATUS_IN_PROGRESS,
                            STATUS_COMPLETE,
                            STATUS_ON_HOLD,
                            STATUS_WITHDRAWN,
                        ],
                        [STATUS_COMPLETION_REJECTED]: [
                            STATUS_IN_PROGRESS,
                            STATUS_COMPLETE,
                            STATUS_ON_HOLD,
                            STATUS_WITHDRAWN,
                        ],
                        [STATUS_COMPLETION_TIMED_OUT]: [
                            STATUS_IN_PROGRESS,
                            STATUS_COMPLETE,
                            STATUS_ON_HOLD,
                            STATUS_WITHDRAWN,
                        ],
                        [STATUS_APPLICATION]: [STATUS_IN_PROGRESS, STATUS_COMPLETE, STATUS_ON_HOLD, STATUS_WITHDRAWN],
                        [STATUS_VALUATION]: [STATUS_IN_PROGRESS, STATUS_COMPLETE, STATUS_ON_HOLD, STATUS_WITHDRAWN],
                        [STATUS_LEGALS]: [STATUS_IN_PROGRESS, STATUS_COMPLETE, STATUS_ON_HOLD, STATUS_WITHDRAWN],
                        [STATUS_ON_HOLD]: [STATUS_IN_PROGRESS, STATUS_WITHDRAWN],
                    };

                    if (true === completionRejected) {
                        this.manageCompletionRejected(lenderLoanEnquiry.id);
                    }

                    return (
                        <div className={`view-lead`}>
                            <AdminStatusBannerNotice
                                status={currentStatus}
                                onClick={() => this.manageCompletionRejected(lenderLoanEnquiry.id)}
                            />
                            <Container
                                className={`view-lead-header`}
                                justifyContent={`space-between`}
                                spacing={4}
                                alignItems={`center`}
                            >
                                <Item>
                                    <ScreenTitle
                                        title={
                                            <React.Fragment>
                                                <span>
                                                    Loan Match:{' '}
                                                    {this.getMainApplicantName(loanEnquiry.loanEnquiryApplicants)}
                                                </span>
                                                <span className={`compare-icon`} />
                                                <span>{lenderLoanEnquiry.lender.name}</span>
                                            </React.Fragment>
                                        }
                                    />
                                </Item>
                                <Item>
                                    <StatusWidget
                                        onChange={this.handleAdminLeadStatusOverride(
                                            lenderLoanEnquiry.status,
                                            lenderLoanEnquiry.id
                                        )}
                                        transitions={editableStates}
                                        statusEnum={new StatusEnum()}
                                        initialValues={{
                                            status: lenderLoanEnquiry.status,
                                        }}
                                        matchStatus={currentStatus}
                                        enquiryWithdrawn={enquiryWithdrawn}
                                        disabled={false}
                                    />
                                </Item>
                            </Container>

                            <Container className={`view-lead-content`} justifyContent={`space-between`} spacing={4}>
                                <Item xs={12} md={5}>
                                    <Container justifyContent={`space-between`} spacing={4}>
                                        <Item xs={12} className={`view-lead-summary`}>
                                            <P4StyleComponentContainer
                                                header={'Match summary'}
                                                buttons={<div />}
                                                content={
                                                    <KeyValueTable>
                                                        <KeyValueTableRow
                                                            term={<Subtitle1>Lender:</Subtitle1>}
                                                            def={<Body1>{lenderLoanEnquiry.lender.name}</Body1>}
                                                        />

                                                        <KeyValueTableRow
                                                            term={<Subtitle1>Main applicant:</Subtitle1>}
                                                            def={
                                                                <Body1>
                                                                    {this.getMainApplicantName(
                                                                        loanEnquiry.loanEnquiryApplicants
                                                                    )}
                                                                </Body1>
                                                            }
                                                        />

                                                        <KeyValueTableRow
                                                            term={`Date & Time Created`}
                                                            def={
                                                                <DateTimeFormatter
                                                                    value={lenderLoanEnquiry.createdAt}
                                                                    format={'MMMM D, YYYY HH:mm A'}
                                                                />
                                                            }
                                                        />

                                                        <KeyValueTableRow
                                                            term={`Date & Time Matched`}
                                                            def={
                                                                <DateTimeFormatter
                                                                    value={lenderLoanEnquiry.matchedAt}
                                                                    format={'MMMM D, YYYY HH:mm A'}
                                                                />
                                                            }
                                                        />

                                                        {true === hasLeSubmittedByRole && (
                                                            <KeyValueTableRow
                                                                term={`Customer Type`}
                                                                def={
                                                                    <span
                                                                        className={`pill pill-${this.roleToClass(
                                                                            leSubmittedByRole
                                                                        )}`}
                                                                    >
                                                                        <EnumLabel
                                                                            enumIn={new RoleNameEnum()}
                                                                            value={leSubmittedByRole}
                                                                        />
                                                                    </span>
                                                                }
                                                            />
                                                        )}

                                                        {true === hasBrokerageName && (
                                                            <KeyValueTableRow
                                                                term={`Brokerage Name`}
                                                                def={
                                                                    <span className="text-highlight">
                                                                        {brokerageName}
                                                                    </span>
                                                                }
                                                            />
                                                        )}

                                                        <KeyValueTableRow
                                                            term={<Subtitle1>Reference Number:</Subtitle1>}
                                                            def={<Body1>{lenderLoanEnquiry.lenderReference}</Body1>}
                                                        />
                                                    </KeyValueTable>
                                                }
                                            />
                                        </Item>

                                        <Item xs={12} className={`view-lead-applicants`}>
                                            <P4StyleComponentContainer
                                                header={'Applicant details'}
                                                buttons={<div />}
                                                content={
                                                    <React.Fragment>
                                                        {loanEnquiry.loanEnquiryApplicants.map(
                                                            (loanEnquiryApplicant, index) => (
                                                                <KeyValueTable key={`applicant${index}`}>
                                                                    <KeyValueTableRow
                                                                        term={
                                                                            loanEnquiryApplicant.isMainApplicant
                                                                                ? 'Main Applicant Name'
                                                                                : 'Applicant ' + (index + 1) + ' Name'
                                                                        }
                                                                        def={
                                                                            <span className="text-highlight-green">
                                                                                {loanEnquiryApplicant.applicant.given}{' '}
                                                                                {loanEnquiryApplicant.applicant.family}
                                                                            </span>
                                                                        }
                                                                    />
                                                                    <KeyValueTableRow
                                                                        term={`Date of birth`}
                                                                        def={
                                                                            <DateFormatter
                                                                                value={
                                                                                    loanEnquiryApplicant.applicant
                                                                                        .dateOfBirth
                                                                                }
                                                                            />
                                                                        }
                                                                    />
                                                                </KeyValueTable>
                                                            )
                                                        )}
                                                    </React.Fragment>
                                                }
                                            />
                                        </Item>

                                        <Item xs={12} className={`view-lead-enquiry-info`}>
                                            <P4StyleComponentContainer
                                                header={'Enquiry information'}
                                                buttons={<div />}
                                                content={
                                                    <FactSummaryTable
                                                        config={config}
                                                        loanEnquiry={loanEnquiry}
                                                        loanEnquiryStoredData={loanEnquiry.storedData}
                                                        loanEnquirySubmissionData={JSON.parse(
                                                            loanEnquiry.submissionData['rawSubmission'] || '{}'
                                                        )}
                                                    />
                                                }
                                            />
                                        </Item>
                                    </Container>
                                </Item>
                                <Item xs={12} md={7}>
                                    <Container justifyContent={`space-between`} spacing={4}>
                                        <Item xs={12} className={`view-lead-lender-comms`}>
                                            <P4StyleComponentContainer
                                                header={`Lender Communications`}
                                                buttons={<div />}
                                                content={
                                                    <ChatContainer
                                                        channelId={lenderLoanEnquiry.channel.id}
                                                        disableForm={enquiryWithdrawn}
                                                    />
                                                }
                                            />
                                        </Item>
                                        <Item xs={12} className={`view-lead-docs`}>
                                            <div className={'document-list'}>
                                                <DocumentList
                                                    lenderLoanEnquiry={lenderLoanEnquiry}
                                                    disableUploadButton={enquiryWithdrawn}
                                                />
                                            </div>
                                        </Item>
                                    </Container>
                                </Item>
                            </Container>
                        </div>
                    );
                }}
            />
        );
    }
}

MasonryStyleContainer.propTypes = {};

const mapStateToProps = (state) => {
    return {
        config: selectorGetMatchingCriteriaConfig(state),
        currentStatus: selectorGetStatus(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        getConfig: () => dispatch(actionGetMatchingCriteriaConfig()),
        updateStatusOnChange: (leadId, newStatus, reason, metadata) => {
            dispatch(actionApiSetStatus(leadId, newStatus, reason, metadata));
        },
        clearStatus: () => dispatch(actionClearStatus()),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(MasonryStyleContainer);
