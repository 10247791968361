//Admin redux
import administerUsersContainer, {
    NAMESPACE as ADMINISTER_USERS_REDUCER_NAMESPACE,
} from '../administer_users/list/redux';
import administerUserLendersContainer, {
    NAMESPACE as ADMINISTER_USER_LENDERS_REDUCER_NAMESPACE,
} from '../administer_user_lenders/redux';
import administerLendersContainer, {
    NAMESPACE as ADMINISTER_LENDERS_REDUCER_NAMESPACE,
} from '../administer_lenders/redux';
import administerLoanEnquiryContainer, {
    NAMESPACE as ADMINISTER_LOANENQUIRY_REDUCER_NAMESPACE,
} from '../administer_loanenquiries/redux';
import administerLendersDocumentsContainer, {
    NAMESPACE as ADMINISTER_LENDERS_DOCUMENTS_REDUCER_NAMESPACE,
} from '../administer_lenders_documents/redux';
import administerDocumentsAddContainer, {
    REDUCER_NAMESPACE as ADMINISTER_DOCUMENTS_ADD_REDUCER_NAMESPACE,
} from '../administer_documents_widget/redux/add';
import administerLendersDocumentsEditContainer, {
    NAMESPACE as ADMINISTER_LENDERS_DOCUMENTS_EDIT_REDUCER_NAMESPACE,
} from '../administer_lenders_documents/redux/edit';
import administerBorrowersDocumentsContainer, {
    NAMESPACE as ADMINISTER_BORROWERS_DOCUMENTS_REDUCER_NAMESPACE,
} from '../administer_borrowers_documents/redux';
import administerBorrowersDocumentsEditContainer, {
    NAMESPACE as ADMINISTER_BORROWERS_DOCUMENTS_EDIT_REDUCER_NAMESPACE,
} from '../administer_borrowers_documents/redux/edit';
import administerLenderLoanEnquiryContainer, {
    NAMESPACE as ADMINISTER_LENDERLOANENQUIRY_REDUCER_NAMESPACE,
} from '../administer_lenders_loanenquiries_view/redux/main';
import viewEvents, { NAMESPACE as VIEW_EVENTS_NAMESPACE } from '../view_events/redux';
import viewLoanEnquiry, {
    NAMESPACE as VIEW_LOAN_ENQUIRY_NAMESPACE,
} from '../administer_loanenquiries_viewloanenquiry/redux/main';
import createEditUser, { NAMESPACE as CREATE_OR_EDIT_USER_NAMESPACE } from '../administer_users/shared/redux/form';
import brokerList, { NAMESPACE as BROKER_LIST_NAMESPACE } from '../administer_brokers/redux';
import lenderLoanEnquiryDocumentsAdd, {
    REDUCER_NAMESPACE as LLE_DOCS_ADD_NAMESPACE,
} from '../documents_widget/redux/add';

export default {
    //Administer Users
    [ADMINISTER_USER_LENDERS_REDUCER_NAMESPACE]: administerUserLendersContainer,
    [ADMINISTER_USERS_REDUCER_NAMESPACE]: administerUsersContainer,

    //Administer Lenders
    [ADMINISTER_LENDERS_REDUCER_NAMESPACE]: administerLendersContainer,

    //Administer loan enquiries
    [ADMINISTER_LOANENQUIRY_REDUCER_NAMESPACE]: administerLoanEnquiryContainer,

    //Documents
    [ADMINISTER_DOCUMENTS_ADD_REDUCER_NAMESPACE]: administerDocumentsAddContainer,
    [LLE_DOCS_ADD_NAMESPACE]: lenderLoanEnquiryDocumentsAdd,

    [ADMINISTER_LENDERS_DOCUMENTS_REDUCER_NAMESPACE]: administerLendersDocumentsContainer,
    [ADMINISTER_LENDERS_DOCUMENTS_EDIT_REDUCER_NAMESPACE]: administerLendersDocumentsEditContainer,

    [ADMINISTER_BORROWERS_DOCUMENTS_REDUCER_NAMESPACE]: administerBorrowersDocumentsContainer,
    [ADMINISTER_BORROWERS_DOCUMENTS_EDIT_REDUCER_NAMESPACE]: administerBorrowersDocumentsEditContainer,

    //Lender loan enquiries
    [ADMINISTER_LENDERLOANENQUIRY_REDUCER_NAMESPACE]: administerLenderLoanEnquiryContainer,

    //view events
    [VIEW_EVENTS_NAMESPACE]: viewEvents,

    [VIEW_LOAN_ENQUIRY_NAMESPACE]: viewLoanEnquiry,
    [CREATE_OR_EDIT_USER_NAMESPACE]: createEditUser,
    [BROKER_LIST_NAMESPACE]: brokerList,
};
