import { call, put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import {
    INITIALIZE,
    RESET_SUBMITTED,
    selectorToken,
    SET_TOKEN,
    SUBMIT_ERRORS,
    SUBMIT_SUCCESS,
} from '../redux/passwordReset';
import { passwordReset } from '../api/password_reset';
import { getPasswordResetToken } from '../api/get_password_reset_token';
import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../notifications/sagas/container';

function* initialize(action) {
    const tokenResponse = yield call(getPasswordResetToken, action.token);
    yield put({ type: SET_TOKEN, token: tokenResponse.passwordResetToken });
}

function* resetPassword(action) {
    const token = yield select(selectorToken);
    const resetResponse = yield call(passwordReset, token.id, action.newPassword, action.confirmPassword);

    if (_.has(resetResponse, ['errors'])) {
        const errors = _.map(resetResponse.errors, (error) => error.message);
        yield put({ type: SUBMIT_ERRORS, errors });
        yield put({ type: SAGA_ACTION_ERROR, message: 'Password reset failed' });
    } else {
        yield put({ type: SUBMIT_SUCCESS });
        yield put({
            type: SAGA_ACTION_SUCCESS,
            message: 'Password reset successful. Please login with your new password!',
        });
    }
}

//Main Saga
function* main() {
    yield takeLatest(INITIALIZE, initialize);
    yield takeLatest(RESET_SUBMITTED, resetPassword);
}

export default main;
