export const defaultSortModel = [
    {
        field: 'favourite',
        sort: 'desc',
    },
    {
        field: 'createdAt',
        sort: 'desc',
    },
];
export const defaultRequestSort = [
    {
        column: 'favourite',
        direction: 'desc',
    },
    {
        column: 'createdAt',
        direction: 'desc',
    },
];
