import BaseEnum from '../../../../pitchblack_react_utils/enums/BaseEnum';

export const CONTEXT_USER_STORE = 'CONTEXT_USER_STORE';
export const CONTEXT_LENDER_LOAN_ENQUIRY = 'CONTEXT_LENDER_LOAN_ENQUIRY';

class ContextEnum extends BaseEnum {
    getTypes = () => {
        return {
            [CONTEXT_USER_STORE]: 'User store',
            [CONTEXT_LENDER_LOAN_ENQUIRY]: 'Loan Enquiry',
        };
    };
}

export default ContextEnum;
