import React from 'react';
import { Container, RowItem } from 'pitchblack_react_utils/layout';
import ScreenTitle from 'pitch4_layout/components/ScreenTitle';
import PropTypes from 'prop-types';

class BasicContentLayout extends React.Component {
    render() {
        let { title, content } = this.props;

        return (
            <Container className={`basic-content-layout`}>
                <RowItem>
                    <Container>
                        <ScreenTitle title={title} />
                    </Container>
                </RowItem>
                <RowItem className={`content-row`}>{content}</RowItem>
            </Container>
        );
    }
}

BasicContentLayout.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    content: PropTypes.object.isRequired,
};

export default BasicContentLayout;
