import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { path, ROUTE_2FA_RESET_SUCCESS, ROUTE_LANDING } from 'pitch4_routing';
import { sagaActionNotificationSetError } from '../../notifications/sagas/container';
import {
    actionResetTwoFactorAuth,
    actionSetResetToken,
    GET_RESET_2FA_TOKEN,
    getResetToken,
    RESET_2FA,
} from '../redux/reset';
import { apiGetResetToken } from '../api/get_token';
import { apiResetTwoFactorAuth } from '../api/reset_two_factor_auth';

function* fetchTokenAndReset(action) {
    const tokenId = action.tokenId;
    const response = yield call(apiGetResetToken, tokenId);

    switch (response.getClassName()) {
        case 'GetTokenSuccess':
            const token = response.getData();

            yield put(actionSetResetToken(token));
            yield put(actionResetTwoFactorAuth(token));
            break;

        case 'GetTokenErrors':
            yield put(sagaActionNotificationSetError('Unable to retrieve the reset token'));
            yield put(push(ROUTE_LANDING));
            break;

        default:
            break;
    }
}

function* resetTwoFactorAuth(action) {
    const token = yield select(getResetToken);

    if (token.hasExpired === false) {
        const response = yield call(apiResetTwoFactorAuth, token.id);

        switch (response.getClassName()) {
            case 'ResetTwoFactorAuthSuccess':
                yield put(push(path(ROUTE_2FA_RESET_SUCCESS, { token: token.id })));
                break;

            case 'ResetTwoFactorAuthErrors':
                yield put(sagaActionNotificationSetError('Unable to reset the reset token'));
                yield put(push(ROUTE_LANDING));
                break;

            default:
                break;
        }
    }
}

function* main() {
    yield takeLatest(GET_RESET_2FA_TOKEN, fetchTokenAndReset);
    yield takeLatest(RESET_2FA, resetTwoFactorAuth);
}

export default main;
