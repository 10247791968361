import React from 'react';

import { TablePagination as MTablePagination } from '@material-ui/core';
import PropTypes from 'prop-types';

class TablePagination extends React.Component {
    render() {
        let { children, rowsPerPageOptions = [5, 10, 25, 50, 100], ...other } = this.props;

        return (
            <MTablePagination id={`pagination`} rowsPerPageOptions={rowsPerPageOptions} {...other}>
                {children}
            </MTablePagination>
        );
    }
}

TablePagination.propTypes = {
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired,
    page: PropTypes.number,
    rowsPerPage: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    colSpan: PropTypes.number,
    rowsPerPageOptions: PropTypes.array,
    ActionsComponent: PropTypes.object.isRequired,
};

export default TablePagination;
