import React from 'react';
import { put, select, takeLatest } from 'redux-saga/effects';
import { STATUS_MATCHING } from '../../enum/LoanEnquiries/StatusEnum';
import ReviewMatchedLendersContainer from '../containers/ReviewMatchedLendersContainer';
import ModalLayout from 'pitch4_modal/src/component/ModalLayout';
import { CenteringContainer } from 'pitch4_layout/components/div';
import { CLEANUP, SET_API_GET_LOAN_ENQUIRY_MATCHES_RESPONSE } from '../redux/main';
import { actionModalHide, actionModalSetComponent, selectorVisible } from '../../../../modal/redux/container';
import matchingAnimation from '../../../../../assets/images/matching-animation.webm';

function* showMatchModal(action) {
    const { loanEnquiryMatch } = action;
    const {
        loanEnquiry: { status },
        match_pending,
    } = loanEnquiryMatch;

    if (status === STATUS_MATCHING) {
        yield put(
            actionModalSetComponent(
                <ModalLayout>
                    <CenteringContainer>
                        <video autoPlay={true} loop={true}>
                            <source src={matchingAnimation} type={'video/webm'} />
                        </video>
                        <h2>Matching in progress</h2>
                    </CenteringContainer>
                </ModalLayout>,
                false
            )
        );
        return;
    }

    if (match_pending.length > 0) {
        yield put(
            actionModalSetComponent(
                <ModalLayout title={`Approve Matched Lenders`}>
                    <ReviewMatchedLendersContainer matches={match_pending} />
                </ModalLayout>,
                false
            )
        );
        return;
    }

    const modalVisible = yield select(selectorVisible);
    if (modalVisible) {
        yield put(actionModalHide());
    }
}

function* closeModal() {
    const modalVisible = yield select(selectorVisible);
    if (modalVisible) {
        yield put(actionModalHide());
    }
}

function* main() {
    yield takeLatest(SET_API_GET_LOAN_ENQUIRY_MATCHES_RESPONSE, showMatchModal);
    yield takeLatest(CLEANUP, closeModal);
}

export default main;
