import React from 'react';
import PropTypes from 'prop-types';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { CenteringContainer, Item } from 'pitch4_layout/components/div';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

class ServerErrors extends React.Component {
    handleError = (error, index) => {
        return (
            <Item xs={10} key={index} style={{ paddingTop: 15 }}>
                <P4SnackBar message={`${error.message}`} />
            </Item>
        );
    };

    render() {
        let { errors = [], visible } = this.props;

        if (errors.length > 0 && visible) {
            return (
                <CenteringContainer>{errors.map((error, index) => this.handleError(error, index))}</CenteringContainer>
            );
        } else {
            return <div className="noFormErrors" />;
        }
    }
}

class P4SnackBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        };
    }

    render() {
        let { message } = this.props;

        if (this.state.open === false) {
            return <div />;
        }

        return (
            <SnackbarContent
                style={{
                    backgroundColor: 'red',
                    maxWidth: '100%',
                    minWidth: '100%',
                }}
                aria-describedby="servererror-snackbar"
                message={
                    <span id="client-snackbar">
                        <ErrorIcon />
                        {message}
                    </span>
                }
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        onClick={() => {
                            this.setState({ ...this.state, open: false });
                        }}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        );
    }
}

ServerErrors.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.object),
    visible: PropTypes.bool,
};

export default ServerErrors;
