import React from 'react';
import { reduxForm } from 'redux-form';
import { Container, RowItem } from 'pitch4_layout/components/div';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import { FieldButtonPanelRow, TextLabel, Select, FieldRow } from 'pitch4_forms/components/inputs';
import { ServerErrors } from 'pitch4_forms/components';
import styled from 'styled-components';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { isRequired } from 'pitchblack_react_utils/form/validation';
import { NoMutationQuery } from 'pitch4_forms/components';
import gql from 'graphql-tag';
import { createOptionsFromData } from 'pitch4_forms/components/inputs/select/index';
import { MenuItem } from '@material-ui/core';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const SpacerItem = styled(RowItem)``;

class Form extends React.Component {
    render() {
        let {
            onSubmit,
            submitting,
            handleSubmit,
            error = [],
            reset,
            //initialValues - used by the HOC
        } = this.props;

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container>
                    <RowItem>
                        <ServerErrors errors={error} visible={true} />
                    </RowItem>
                    <FieldRow
                        label={<TextLabel label="Lender" />}
                        input={
                            <NoMutationQuery
                                queryContext={{
                                    uri: API_URL,
                                }}
                                queryVariables={{
                                    count: 99999,
                                    sorts: [
                                        {
                                            column: 'name',
                                            direction: 'asc',
                                        },
                                    ],
                                }}
                                query={gql`
                                    query getLenders($count: Int!, $sorts: [SortInput]) {
                                        lenders(first: $count, sorts: $sorts) {
                                            edges {
                                                cursor
                                                node {
                                                    id
                                                    name
                                                }
                                            }
                                        }
                                    }
                                `}
                                mapQueryFunction={({ lenders = {} }) => {
                                    if (!lenders || !lenders.edges) {
                                        return [];
                                    }
                                    return [...lenders.edges];
                                }}
                                renderFunction={({ mappedValues, errors, error, data, loading, refetch, ...stuff }) => {
                                    let options = createOptionsFromData(mappedValues, (key) => {
                                        return {
                                            value: mappedValues[key].node.id,
                                            label: mappedValues[key].node.name,
                                        };
                                    });

                                    options.unshift({
                                        value: '',
                                        label: '',
                                    });

                                    return (
                                        <Select
                                            name="lender"
                                            disabled={submitting}
                                            validate={[isRequired]}
                                            options={options}
                                        >
                                            <MenuItem key={''} value={``}>{``}</MenuItem>
                                        </Select>
                                    );
                                }}
                            />
                        }
                    />

                    <SpacerItem />

                    <FieldButtonPanelRow>
                        <PrimaryButton type="submit" disabled={submitting}>
                            Submit
                        </PrimaryButton>
                        <PrimaryButton disabled={submitting} onClick={reset}>
                            Reset
                        </PrimaryButton>
                    </FieldButtonPanelRow>
                </Container>
            </form>
        );
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    reset: PropTypes.func,
    initialValues: PropTypes.object,
};

export default compose(
    reduxForm({
        form: 'administer_user_createuserlenderinvite_form',
        enableReinitialize: true,
        updateUnregisteredFields: false,
    })
)(Form);
