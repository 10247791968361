export const NAMESPACE = 'borrower.delete_le.main';

export const API_DELETE = `${NAMESPACE}.delete`;

const initialState = {
    submitted: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case API_DELETE:
            return {
                ...state,
                submitted: true,
            };

        default:
            return {
                ...state,
            };
    }
};

export const selectorGetSubmitted = (state) => {
    return state[NAMESPACE].submitted;
};

export const actionDelete = (loanEnquiryId) => ({
    type: API_DELETE,
    loanEnquiryId,
});

// Default export reducer
export default reducer;
