import { BRAND_MAIN_CONTACT } from '../../bootstrap/constants/constants';
import _ from 'lodash';

export const mapErrors = (serverErrors) => {
    const errorResults = serverErrors.getResults();

    let errors = {};

    if (errorResults.length > 0) {
        errorResults.forEach(function (errorResult) {
            const message = errorResult['message'];
            const category = message['category'];

            if (['userException', 'user'].indexOf(category) === -1) {
                return;
            }

            const constraints = message['constraints'];

            if (!_.isObject(constraints)) {
                errors['unknown'] = message['message'];
                return;
            }

            Object.keys(constraints).forEach(function (fieldName) {
                errors[fieldName] = constraints[fieldName]['message'];
            });
        });

        // Default error message
        if (errors.length === 0) {
            errors['unknown'] =
                'An unexpected error occurred. If this problem persists please call us on ' + BRAND_MAIN_CONTACT;
        }
    }

    return errors;
};
