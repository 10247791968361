import createP4ApolloFetch from '../../bootstrap/middlewares';

const ClearDocumentsAlertMutation = `
    mutation clearDocumentAlerts($loanEnquiryId: ID, $lenderLoanEnquiryId: ID) {
      clearDocumentAlerts(loanEnquiryId: $loanEnquiryId, lenderLoanEnquiryId: $lenderLoanEnquiryId) {
        id
      }
    }
`;

export const clearDocumentsAlert = (loanEnquiryId = null, lenderLoanEnquiryId = null, uri) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    return apolloFetch({
        query: ClearDocumentsAlertMutation,
        variables: {
            loanEnquiryId,
            lenderLoanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
