import { API_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const setLERevenue = (uri = API_URL, loanEnquiryId, revenue) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation setLoanEnquiryRevenue($id: ID!, $revenue: String!) {
          setLoanEnquiryRevenue(id: $id, revenue: $revenue) {
             success
          }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            id: loanEnquiryId,
            revenue: revenue,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
