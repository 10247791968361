import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: SLAM_URL });

const ClearUserAlertsMutation = `
  mutation clearUserAlerts {
    clearUserAlerts {
      success
    }
  } 
`;

export const clearUserAlerts = () => {
    return apolloFetch({
        query: ClearUserAlertsMutation,
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
