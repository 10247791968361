import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import { AUTH_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: AUTH_URL });

const LoginMutation = `
    mutation twoFactorAuthLogin($input: loginInput!) {
      twoFactorAuthLogin(input: $input) {
        token {
            token
            expires          
        }
        session {
            roles {
              label
              name
            }
            id
            title
            given
            family
            authExpiry
            supplementaryData
        }
        assumedIdentity{
          token{
            token
            expires
          }
          session{
            id
            roles {
              name
            }
            title
            given
            family
            authExpiry
            supplementaryData
          }
        }
      }
    }
`;

//response classes
const errorFactory = new ValidationResultFactory();

class TwoFactorAuthLogin {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class TwoFactorAuthLoginErrors extends TwoFactorAuthLogin {
    constructor(errors) {
        super('TwoFactorAuthLoginErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class TwoFactorAuthLoginSuccess extends TwoFactorAuthLogin {
    constructor(data) {
        super('TwoFactorAuthLoginSuccess');
        this.data = data;
    }

    getData = () => {
        return this.data['twoFactorAuthLogin'];
    };
}

export const twoFactorAuthLogin = (user, code) => {
    const { username, password } = user;

    let out = apolloFetch({
        query: LoginMutation,
        variables: {
            input: {
                username,
                password,
                code,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new TwoFactorAuthLoginErrors(vR);
            }

            return new TwoFactorAuthLoginSuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new TwoFactorAuthLoginErrors(vR);
        });

    return out;
};
