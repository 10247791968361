import ValidationResultFactory from '../../../../../pitch4_validation/validation/ValidationResultFactory';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });

const LoanEnquiryToDraftMutation = `
    mutation revertToDraft($loanEnquiryId: ID!){
      revertToDraftLoanEnquiry(loanEnquiryId: $loanEnquiryId) {
          loanEnquiryId
      }
    }
`;

const errorFactory = new ValidationResultFactory();

class RevertLoanEnquiryToDraft {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class RevertLoanEnquiryToDraftErrors extends RevertLoanEnquiryToDraft {
    constructor(errors) {
        super('RevertLoanEnquiryToDraftErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class RevertLoanEnquiryToDraftSuccess extends RevertLoanEnquiryToDraft {
    constructor(data) {
        super('RevertLoanEnquiryToDraftSuccess');
        this.data = data;
    }

    getData = () => {
        return this.data;
    };
}

export const revertLoanEnquiryToDraft = (loanEnquiryId) => {
    return apolloFetch({
        query: LoanEnquiryToDraftMutation,
        variables: { loanEnquiryId },
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new RevertLoanEnquiryToDraftErrors(vR);
            }

            return new RevertLoanEnquiryToDraftSuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new RevertLoanEnquiryToDraftErrors(vR);
        });
};
