import { BaseEnum } from 'pitch4_enum';

export const TYPE_LESS_THAN_TWO_YEARS = 'less_than_two_years';
export const TYPE_TWO_YEARS_OR_MORE = 'two_years_or_more';

class LoanTermEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_LESS_THAN_TWO_YEARS]: 'Less than 2 years',
            [TYPE_TWO_YEARS_OR_MORE]: '2 years or more',
        };
    };
}

export default LoanTermEnum;
