export const WORKING_CAPITAL = 'WORKING_CAPITAL';
export const EXPANSION_GROWTH = 'EXPANSION_GROWTH';
export const START_UP = 'START_UP';
export const REFINANCING = 'REFINANCING';
export const CASH_FLOW = 'CASH_FLOW';
export const STOCK = 'STOCK';
export const SUPPLY_CHAIN = 'SUPPLY_CHAIN';
export const ACQUISITION = 'ACQUISITION';
export const MANAGEMENT_BUY_OUT_IN = 'MANAGEMENT_BUY_IN_OUT';
export const ASSET_PURCHASE = 'ASSET_PURCHASE';
export const ASSET_REFINANCE = 'ASSET_REFINANCE';

class BusinessLoanPurposes {
    getTypes = () => {
        return [
            { label: 'Please Select', value: '' },
            { label: 'Working Capital', value: WORKING_CAPITAL },
            { label: 'Expansion/Growth', value: EXPANSION_GROWTH },
            { label: 'Start up funding', value: START_UP },
            { label: 'Refinancing', value: REFINANCING },
            { label: 'Cash flow', value: CASH_FLOW },
            { label: 'Purchasing stock', value: STOCK },
            { label: 'Supply Chain', value: SUPPLY_CHAIN },
            { label: 'Acquisition', value: ACQUISITION },
            { label: 'Management buy-in/Management buy-out', value: MANAGEMENT_BUY_OUT_IN },
            { label: 'Asset Purchase', value: ASSET_PURCHASE },
            { label: 'Asset Refinance', value: ASSET_REFINANCE },
        ];
    };

    getAssetTypes = () => {
        return [
            { label: 'Please Select', value: '' },
            { label: 'Asset Purchase', value: ASSET_PURCHASE },
            { label: 'Asset Refinance', value: ASSET_REFINANCE },
        ];
    };

    getMerchantSupplyTypes = () => {
        return [
            { label: 'Please Select', value: '' },
            { label: 'Working Capital', value: WORKING_CAPITAL },
            { label: 'Expansion/Growth', value: EXPANSION_GROWTH },
            { label: 'Start up funding', value: START_UP },
            { label: 'Refinancing', value: REFINANCING },
            { label: 'Cash flow', value: CASH_FLOW },
            { label: 'Purchasing stock', value: STOCK },
            { label: 'Supply Chain', value: SUPPLY_CHAIN },
        ];
    };

    getOptions = () => {
        return {
            [WORKING_CAPITAL]: { label: 'Working Capital', value: WORKING_CAPITAL },
            [EXPANSION_GROWTH]: { label: 'Expansion/Growth', value: EXPANSION_GROWTH },
            [START_UP]: { label: 'Start up funding', value: START_UP },
            [REFINANCING]: { label: 'Refinancing', value: REFINANCING },
            [CASH_FLOW]: { label: 'Cash flow', value: CASH_FLOW },
            [STOCK]: { label: 'Purchasing stock', value: STOCK },
            [SUPPLY_CHAIN]: { label: 'Supply Chain', value: SUPPLY_CHAIN },
            [ACQUISITION]: { label: 'Acquisition', value: ACQUISITION },
            [MANAGEMENT_BUY_OUT_IN]: { label: 'Management buy-in/Management buy-out', value: MANAGEMENT_BUY_OUT_IN },
            [ASSET_PURCHASE]: { label: 'Asset Purchase', value: ASSET_PURCHASE },
            [ASSET_REFINANCE]: { label: 'Asset Refinance', value: ASSET_REFINANCE },
        };
    };
}

export default BusinessLoanPurposes;
