import { BaseEnum } from '../../pitch4_enum';

export const STATUS_DISABLED = 'STATUS_DISABLED';
export const STATUS_ENABLED = 'STATUS_ENABLED';

class BrokerStatusEnum extends BaseEnum {
    getTypes = () => {
        return {
            [STATUS_DISABLED]: 'Disabled',
            [STATUS_ENABLED]: 'Enabled',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default BrokerStatusEnum;
