import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const LenderLoanEnquirySetStatusesMutation = `
  mutation setLenderLoanEnquiryStatus($id: ID!, $input: setLenderLoanEnquiryStatusInput!) {
      setLenderLoanEnquiryStatus(id: $id, input: $input) {
        lead {
          id
        }
      }
    }
`;

export const setLenderLoanEnquiryStatus = (lenderLoanEnquiryId, newStatus, reason = '') => {
    return apolloFetch({
        query: LenderLoanEnquirySetStatusesMutation,
        variables: {
            id: lenderLoanEnquiryId,
            input: {
                newStatus: newStatus,
                reason: reason,
            },
        },
    })
        .then((resp) => resp)
        .catch((error) => {
            throw error;
        });
};
