import React from 'react';
import OptionFactory from 'pitchblack_react_utils/form/AutoCompleteSelect/OptionFactory';
import PropTypes from 'prop-types';
import Autocomplete from 'pitch4_forms/components/inputs/autocomplete/redux_form/Autocomplete';

const typeNotEmpty = (value, formValues, { userStoreDocument }) => {
    const type = typeof value === 'undefined' ? String('') : String(value);
    const document = typeof userStoreDocument === 'undefined' ? '' : String(userStoreDocument);

    return String(document).length > 0 || type.length > 0 ? undefined : 'Document type is required.';
};

class DocumentTypeSelect extends React.Component {
    render() {
        let { types, disabled = false } = this.props;

        const vals = Object.keys(types).sort();

        const optionFactory = new OptionFactory();
        const opts = optionFactory.createOptionsFromObject(types, vals);

        return (
            <Autocomplete
                suggestions={opts}
                placeholder="Document Type"
                name="type"
                validate={[typeNotEmpty]}
                disabled={disabled}
            />
        );
    }
}

DocumentTypeSelect.propTypes = {
    types: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};

export default DocumentTypeSelect;
