import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { path, ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW } from '../../../../../pitch4_routing';
import { push } from 'react-router-redux';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import StatusEnum, {
    STATUS_APPLICATION,
    STATUS_COMPLETE,
    STATUS_COMPLETION_REJECTED,
    STATUS_COMPLETION_REQUESTED,
    STATUS_COMPLETION_TIMED_OUT,
    STATUS_DRAFT,
    STATUS_IN_PROGRESS,
    STATUS_LEGALS,
    STATUS_MATCHED,
    STATUS_ON_HOLD,
    STATUS_VALUATION,
} from '../../enum/LoanEnquiries/StatusEnum';
import moment from 'moment';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Status from '../../../../borrower/loan_enquiries/components/Status';
import _ from 'lodash';
import { PAGE_SIZE, ROWS_PER_PAGE } from '../../../../../pitch4_enum/enum/DataGridEnum';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import StarIcon from '../../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../../assets/images/star-gray.svg';
import { updateLEFavourite } from '../api/le_favourite';
import Toolbar from '../components/Toolbar';
import HeaderWithCount from '../../../../../pitch4_elements/screen-headers/HeaderWithCount';
import { getLEs } from '../api/get_les';
import { sagaActionModalHide, sagaActionModalShow } from '../../../../modal/sagas/container';
import AssignToModal from '../../../../../pitch4_elements/loan-enquiry-owner/AssignToModal';
import { getAssignableUsers } from '../../../../../pitch4_api/get_assignable_users';
import { setLEAssignee } from '../../../../../pitch4_api/set_le_assignee';
import PersonIcon from '@material-ui/icons/Person';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import { removeLEAssignee } from '../../../../../pitch4_api/remove_le_assignee';
import { FormControlLabel, Switch } from '@material-ui/core';
import { setLETermsSent } from '../../../../../pitch4_api/set_le_terms_sent';
import RevenueModal from '../../../../../pitch4_elements/loan-enquiry-revenue/RevenueModal';
import { setLERevenue } from '../../../../../pitch4_api/set_le_revenue';
import { formatterInPounds } from '../../../../../pitchblack_react_utils/currency/Formatter';
import { setLEInvoiceSent } from '../../../../../pitch4_api/set_le_invoice_sent';
import { setLEInvoicePaid } from '../../../../../pitch4_api/set_le_invoice_paid';
import CompletedDateModal from '../../../../../pitch4_elements/le-completed/CompletedDateModal';
import { setLECompletedDate } from '../../../../../pitch4_api/set_le_completed_date';
import ChaseDataModal from '../components/ChaseDataModal';
import { DEFAULT_SEARCH_FILTER_DELAY, defaultRequestFilters } from '../../../../../pitch4_utils/FiltersDefaults';
import { defaultRequestSort, defaultSortModel } from '../../../../../pitch4_utils/SortsDefaults';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { calculateLoanType } from '../../../../MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';

const statusEnum = new StatusEnum();
const defaultFilters = {
    requestFilters: defaultRequestFilters,
    statusFilter: [],
    updatesFilter: '',
    assigneeFilter: '',
    searchFilter: '',
    showExpired: false,
};
const defaultSorts = {
    model: defaultSortModel,
    sorts: defaultRequestSort,
};

class TableStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previousSortModel: [],
            loanEnquiriesFetchError: null,
            statusFilter: defaultFilters.statusFilter,
            updatesFilter: defaultFilters.updatesFilter,
            assigneeFilter: defaultFilters.assigneeFilter,
            searchFilter: defaultFilters.searchFilter,
            showExpired: defaultFilters.showExpired,
            statuses: [
                STATUS_APPLICATION,
                STATUS_IN_PROGRESS,
                STATUS_LEGALS,
                STATUS_MATCHED,
                STATUS_VALUATION,
                STATUS_ON_HOLD,
                STATUS_COMPLETE,
                STATUS_COMPLETION_REQUESTED,
                STATUS_COMPLETION_REJECTED,
                STATUS_COMPLETION_TIMED_OUT,
            ],
            expiredStatuses: statusEnum.getExpiredTypes(),
            expiredFilters: [],
            dataGrid: {
                rows: [],
                totalRowCount: 0,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: defaultSorts.model,
            },
            dataRequest: {
                filters: defaultFilters.requestFilters,
                sorts: defaultSorts.sorts,
                pages: [],
                cursor: null,
                storedDataFilters: [],
            },
        };
    }

    componentDidMount() {
        const data = this.getData();
        this.processData(data);
    }

    getAssignees = (assignees) => {
        if (assignees.length === 0) {
            return [
                {
                    id: 'no_owner',
                    name: 'No owner',
                },
            ];
        }

        // Always take the last assignee. This will change in the future once we handle multiple assignees
        const assigneeIndex = assignees.length - 1;

        return [
            {
                id: assignees[assigneeIndex].id,
                name: assignees[assigneeIndex].userProfile.given + ' ' + assignees[assigneeIndex].userProfile.family,
            },
        ];
    };

    getData = () =>
        getLEs(
            this.state.dataRequest.cursor,
            this.state.dataRequest.filters,
            this.state.dataGrid.pageSize,
            this.state.dataRequest.sorts,
            this.state.dataRequest.storedDataFilters,
            this.state.dataGrid.currentPage
        );

    processData(data) {
        const { currentPage } = this.state.dataGrid;
        const { cursor } = this.state.dataRequest;

        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then((resp) => {
            if (!resp) {
                return {};
            }

            const loanEnquiries = resp.loanEnquiries.edges.map((le) => le.node);
            const les = loanEnquiries.map((le, index) => {
                if (le.loanEnquiryData === null) {
                    le.loanEnquiryData = {
                        loanAmount: 0,
                    };
                }
                return {
                    id: le.id,
                    createdAt: le.createdAt,
                    alertActive: le.alertActive,
                    adminNotesAlertActive: le.adminNotesAlertActive,
                    adminDocumentsAlertActive: le.adminDocumentsAlertActive,
                    amount: le.loanEnquiryData.loanAmount,
                    favourite: le.favourite,
                    submittedBy: {
                        id: le.submittedBy.id,
                        email: le.submittedBy.email,
                        title: le.submittedBy.userProfile.title,
                        firstName: le.submittedBy.userProfile.given,
                        lastName: le.submittedBy.userProfile.family,
                        roles: le.submittedBy.roles,
                        phoneNumber: le.submittedBy.userProfile.primaryPhone.number ?? '-',
                    },
                    assignedTo: this.getAssignees(le.assignees),
                    applicant: {
                        id:
                            le.loanEnquiryApplicants.length > 0
                                ? le.loanEnquiryApplicants[0].applicant.id
                                : le.submittedBy.id,
                        firstName:
                            le.loanEnquiryApplicants.length > 0
                                ? le.loanEnquiryApplicants[0].applicant.given
                                : le.submittedBy.userProfile.given,
                        lastName:
                            le.loanEnquiryApplicants.length > 0
                                ? le.loanEnquiryApplicants[0].applicant.family
                                : le.submittedBy.userProfile.family,
                    },
                    status: le.status,
                    contractSigned: le.contractSigned,
                    termsSent: le.termsSent,
                    termsSentUpdated: le.termsSentUpdated,
                    revenue: le.revenue || 0,
                    isInvoiced: le.invoiceSent || false,
                    isInvoicePaid: le.invoicePaid || false,
                    completedDate: le.completedDate || null,
                    tags: le.tags || [
                        // Remove array after development
                        {
                            value: 'ocean',
                            label: 'Ocean',
                            color: '#00B8D9',
                        },
                        {
                            value: 'blue',
                            label: 'Blue',
                            color: '#0052CC',
                        },
                    ],
                    chaseDate: le.chaseDate || null,
                    chaseNote: le.chaseNote || null,
                    loanType: calculateLoanType(le.loanEnquiryData).value,
                    lenderWaiting: le.lenderWaiting || null,
                    brokerWaiting: le.brokerWaiting || null,
                    borrowerWaiting: le.borrowerWaiting || null,
                };
            });

            let pages = this.state.dataRequest.pages;
            pages[currentPage] = {
                cursor: cursor,
                hasNextPage: resp.loanEnquiries.pageInfo.hasNextPage,
                endCursor: resp.loanEnquiries.pageInfo.endCursor,
                startCursor: resp.loanEnquiries.pageInfo.startCursor,
                hasPreviousPage: resp.loanEnquiries.pageInfo.hasPreviousPage,
            };

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: les,
                    totalRowCount: resp.loanEnquiries.totalCount,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages, cursor: resp.loanEnquiries.pageInfo.endCursor },
                },
            });
        }).catch((error) => {
            this.setState({ loanEnquiriesFetchError: error });
        });
    }

    handlePageChange = (pageConfig) => {
        const previousPage = this.state.dataGrid.currentPage;
        const newPage = pageConfig;
        const { pages } = this.state.dataRequest;

        if (newPage > previousPage) {
            // Next page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[previousPage].endCursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        } else {
            // Previous page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[newPage].cursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handlePageSizeChange = (newPageSize) => {
        this.setState(
            {
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: true,
                    pageSize: newPageSize,
                    rows: [],
                    currentPage: 0,
                    totalRowCount: 0,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    pages: [],
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    buildSearchRequestFilters = () => {
        const { searchFilter } = this.state;
        let searchRequestFilter = [];
        const searchableFields = ['email', 'title', 'given', 'family'];

        if (searchFilter.length > 0) {
            searchRequestFilter = searchableFields.map((field) => {
                return {
                    operation: 'ilike',
                    operand: '%' + searchFilter + '%',
                    column: field,
                    not: false,
                    logicOperator: 'or',
                };
            });
        }

        return searchRequestFilter;
    };

    buildStatusRequestFilters = () => {
        const { statusFilter } = this.state;
        let filters = [];

        if (statusFilter.length > 0) {
            filters = statusFilter.map((status) => {
                return {
                    column: 'status',
                    operation: 'eq',
                    not: false,
                    operand: status,
                };
            });
        } else {
            filters = [...defaultRequestFilters];

            if (this.state.showExpired === true) {
                filters = [...filters, ...this.buildExpiredFilters()];
            }
        }

        return filters;
    };

    buildAssigneeRequestFilters = () => {
        const { assigneeFilter } = this.state;
        let filters = [];

        if ('' !== assigneeFilter) {
            filters.push({
                operation: assigneeFilter,
                operand: 'true',
                column: 'leAssignedActive',
                not: false,
            });
        }

        return filters;
    };

    handleStatusFilterChange = (event) => {
        let statusFilter = event.target.value;

        this.setState(
            {
                statusFilter: statusFilter,
                dataRequest: {
                    ...this.state.dataRequest,
                    filters: [],
                },
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            filters: this.buildRequestFilters(),
                        },
                        // showExpired: false,
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    buildRequestFilters = () => [
        ...this.buildSearchRequestFilters(),
        ...this.buildStatusRequestFilters(),
        ...this.buildUpdatesRequestFilters(),
        ...this.buildAssigneeRequestFilters(),
    ];

    buildUpdatesRequestFilters = () => {
        const { updatesFilter } = this.state;
        let filters = [];

        if (updatesFilter.length > 0) {
            filters.push({
                operation: 'eq',
                operand: 'updatesOnly' === updatesFilter ? 'true' : 'false',
                column: 'leAlertActive',
                not: false,
            });
        }

        return filters;
    };

    handleUpdatesFilterChange = (event) => {
        const updatesFilter = event.target.value;

        this.setState(
            {
                updatesFilter: updatesFilter,
                dataRequest: {
                    ...this.state.dataRequest,
                    filters: [],
                },
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            filters: this.buildRequestFilters(),
                        },
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    handleSearchFilterChange = (event) => {
        this.setState(
            {
                searchFilter: event.target.value,
                dataRequest: {
                    ...this.state.dataRequest,
                    filters: [],
                },
            },
            () => {
                this.setState({
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: this.buildRequestFilters(),
                    },
                });
            }
        );

        _.delay(
            (value) => {
                if (this.state.searchFilter === value) {
                    const data = this.getData();
                    this.processData(data);
                }
            },
            DEFAULT_SEARCH_FILTER_DELAY,
            event.target.value
        );
    };

    handleAssigneeFilterChange = (event) => {
        const assigneeFilter = event.target.value;

        this.setState(
            {
                assigneeFilter: assigneeFilter,
                dataRequest: {
                    ...this.state.dataRequest,
                    filters: [],
                },
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            filters: this.buildRequestFilters(),
                        },
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    handleClearAllFilters = () => {
        window.location.reload();
    };

    buildExpiredFilters = () =>
        statusEnum.getExpiredTypes().map((status) => ({
            column: 'status',
            operation: 'eq',
            not: false,
            operand: status,
        }));

    handleShowExpired = (showExpired) => {
        let filters = [];

        if (true === showExpired) {
            filters = _.concat(this.state.dataRequest.filters, this.buildExpiredFilters());
        } else {
            const expiredFilters = this.buildExpiredFilters();
            const currentFilters = this.state.dataRequest.filters;
            filters = currentFilters.filter(
                (cr) => !expiredFilters.find((ef) => ef.operand === cr.operand && cr.column === ef.column)
            );
        }

        this.setState(
            {
                // statusFilter: [],
                showExpired: showExpired,
                dataGrid: { ...this.state.dataGrid, rows: [], totalRowCount: 0 },
                dataRequest: {
                    ...this.state.dataRequest,
                    filters: filters,
                    cursor: null,
                },
                filters: filters,
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    handleSortModelChange = (params) => {
        const { sortModel } = this.state.dataGrid;
        if (
            params === null ||
            typeof params === 'undefined' ||
            JSON.stringify(params) === JSON.stringify(defaultSortModel)
        ) {
            return;
        }
        if (JSON.stringify(params) === JSON.stringify(this.state.previousSortModel)) {
            return;
        }
        const sortableFieldsMapping = {
            favourite: 'favourite',
            createdAt: 'createdAt',
            chaseDate: 'chaseDate',
            submittedBy: 'submitter',
            applicant: 'mainApplicant',
            applicants: 'mainApplicant',
            status: 'status',
            assignedTo: 'assignedToAdmin',
            lenderWaiting: 'lenderWaiting',
            contractSigned: 'contractSigned',
        };
        let sorts = params.map((sort) => ({
            column: sortableFieldsMapping[sort.field],
            direction: sort.sort,
        }));

        if (JSON.stringify(params) !== JSON.stringify(sortModel)) {
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        sortModel: params,
                    },
                    previousSortModel: sortModel,
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        sorts: sorts,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handleLEFavouriteChange = (leId, isFavourite) => {
        const data = updateLEFavourite(leId, isFavourite);

        data.then((resp) => {
            const rows = this.state.dataGrid.rows;
            const le = (rows.find((le) => le.id === leId).favourite = isFavourite);
            const newRows = rows.map((item) => (le.id !== leId ? item : le));

            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
        });
    };

    handleLEChaseDateChange = (leId, chaseDate, chaseNote) => {
        const rows = this.state.dataGrid.rows;
        let le = (rows.find((le) => le.id === leId).chaseDate = chaseDate);
        le = rows.find((le) => le.id === leId).chaseNote = chaseNote;
        const newRows = rows.map((item) => (le.id !== leId ? item : le));

        this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
    };

    handleUpdateLEAssignee = (leId) => (newAssignedTo) => {
        const { setModalClose } = this.props;
        let data;

        if (newAssignedTo.id === 'no_owner') {
            data = removeLEAssignee(API_URL, null, leId, [newAssignedTo.id]);
        } else {
            data = setLEAssignee(API_URL, null, leId, [newAssignedTo.id]);
        }

        data.then((resp) => {
            const rows = this.state.dataGrid.rows;
            const le = (rows.find((le) => le.id === leId).assignedTo = [
                {
                    id: newAssignedTo.id,
                    name: newAssignedTo.name,
                },
            ]);
            const newRows = rows.map((item) => (le.id !== leId ? item : le));

            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } }, () => setModalClose());
        });
    };

    getTermsSentLabel = (le) => {
        if (null !== le.termsSentUpdated) {
            return moment(le.termsSentUpdated).format('MMM D, YYYY');
        }

        return '-';
    };

    handleTermsSent = (rowLe) =>
        setLETermsSent(API_URL, rowLe.id, !rowLe.termsSent).then((resp) => {
            const rows = this.state.dataGrid.rows;
            let le = rows.find((le) => le.id === rowLe.id);
            le.termsSent = !rowLe.termsSent;
            le.termsSentUpdated = moment();

            const newRows = rows.map((item) => (item.id !== le.id ? item : le));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
        });

    handleIsInvoiced = (rowLe) =>
        setLEInvoiceSent(API_URL, rowLe.id, !rowLe.isInvoiced).then((resp) => {
            const rows = this.state.dataGrid.rows;
            let le = rows.find((le) => le.id === rowLe.id);
            le.isInvoiced = !rowLe.isInvoiced;
            // le.isInvoicedUpdated = moment();

            const newRows = rows.map((item) => (item.id !== le.id ? item : le));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
        });

    handleIsInvoicePaid = (rowLe) =>
        setLEInvoicePaid(API_URL, rowLe.id, !rowLe.isInvoicePaid).then((resp) => {
            const rows = this.state.dataGrid.rows;
            let le = rows.find((le) => le.id === rowLe.id);
            le.isInvoicePaid = !rowLe.isInvoicePaid;

            const newRows = rows.map((item) => (item.id !== le.id ? item : le));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
        });

    handleAssignLETo = (le) => {
        const { setModalComponent, setModalClose } = this.props;
        getAssignableUsers(API_URL).then((resp) => {
            setModalComponent(
                <AssignToModal
                    assignees={resp}
                    setModalClose={setModalClose}
                    userRole={ROLE_SUPER_ADMIN}
                    assignedTo={le.assignedTo[0]}
                    handleUpdateLEAssignee={this.handleUpdateLEAssignee(le.id)}
                />,
                true
            );
        });
    };

    handleRevenue = (le) => {
        const { setModalComponent, setModalClose } = this.props;

        setModalComponent(
            <RevenueModal
                setModalClose={setModalClose}
                currentRevenue={le.revenue}
                handleRevenueUpdate={this.handleRevenueUpdate(le.id)}
            />,
            true
        );
    };

    handleRevenueUpdate = (leId) => (newRevenue) => {
        const { setModalClose } = this.props;

        setLERevenue(API_URL, leId, newRevenue).then((resp) => {
            const rows = this.state.dataGrid.rows;
            const le = (rows.find((le) => le.id === leId).revenue = newRevenue);
            const newRows = rows.map((item) => (le.id !== leId ? item : le));

            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } }, () => setModalClose());
        });
    };

    handleCompletedDate = (le) => {
        const { setModalComponent, setModalClose } = this.props;
        setModalComponent(
            <CompletedDateModal
                setModalClose={setModalClose}
                loanEnquiry={le}
                handleUpdateCompletedDate={this.handleUpdateCompletedDate}
            />,
            false
        );
    };

    handleUpdateCompletedDate = (rowLe, completedDate) => {
        setLECompletedDate(API_URL, rowLe.id, completedDate).then(() => {
            const rows = this.state.dataGrid.rows;
            let le = rows.find((le) => le.id === rowLe.id);
            le.completedDate = completedDate;

            const newRows = rows.map((item) => (item.id !== le.id ? item : le));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
        });
    };

    render() {
        const { dispatch, setModalComponent, setModalClose } = this.props;
        let { statuses, statusFilter, updatesFilter, searchFilter, showExpired, assigneeFilter } = this.state;
        const { sortModel, rows, pageSize, currentPage, rowsPerPage, dataLoading, totalRowCount } = this.state.dataGrid;

        return (
            <div>
                <div className={'le-header-container'}>
                    <div className={'header-text'} style={{ display: 'block' }}>
                        <HeaderWithCount title={'Enquiries'} count={totalRowCount} countText={'loan enquiries'} />
                    </div>
                    <Toolbar
                        updatesFilter={updatesFilter}
                        assigneeFilter={assigneeFilter}
                        statusFilter={statusFilter}
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        showExpired={showExpired}
                        statuses={statuses}
                        handleAssigneeFilterChange={this.handleAssigneeFilterChange}
                        handleUpdatesFilterChange={this.handleUpdatesFilterChange}
                        handleShowExpired={this.handleShowExpired}
                        handleClearAllFilters={this.handleClearAllFilters}
                        handleStatusFilterChange={this.handleStatusFilterChange}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                    />
                </div>

                <div className={`le-table-container`}>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onCellClick={(params) => {
                            switch (params.field) {
                                case 'applicant':
                                    break;
                                case 'termsSent':
                                case 'isInvoiced':
                                case 'isInvoicePaid':
                                    break;
                                case 'completedDate':
                                    if (params.row.status === STATUS_COMPLETE) {
                                        this.handleCompletedDate(params.row);
                                    }
                                    break;
                                case 'favourite':
                                    this.handleLEFavouriteChange(params.row.id, !params.row.favourite);
                                    break;
                                case 'assignedTo':
                                    this.handleAssignLETo(params.row);
                                    break;
                                case 'revenue':
                                    this.handleRevenue(params.row);
                                    break;
                                case 'chaseDate':
                                case 'chaseNote':
                                    setModalComponent(
                                        <ChaseDataModal
                                            setModalClose={setModalClose}
                                            chaseDate={params.row.chaseDate}
                                            chaseNote={params.row.chaseNote}
                                            loanEnquiryId={params.row.id}
                                            handleLEChaseDateChange={this.handleLEChaseDateChange}
                                        />,
                                        false
                                    );

                                    break;
                                default:
                                    dispatch(
                                        push(
                                            path(ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW, {
                                                loanEnquiryId: params.row.id,
                                            })
                                        )
                                    );
                                    break;
                            }
                        }}
                        paginationMode="server"
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        sortingMode="server"
                        onSortModelChange={this.handleSortModelChange}
                        rowCount={totalRowCount}
                        pageSize={pageSize}
                        page={currentPage}
                        rowsPerPageOptions={rowsPerPage}
                        rows={rows}
                        loading={true}
                        disableColumnFilter={true}
                        disableMultipleColumnsSorting={true}
                        disableColumnReorder={true}
                        disableMultipleColumnsFiltering={true}
                        disableSelectionOnClick={true}
                        disableColumnMenu={true}
                        components={{
                            LoadingOverlay: () => {
                                return (
                                    <GridOverlay>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {dataLoading ? (
                                                <LinearProgress />
                                            ) : rows.length === 0 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        width: '100%',
                                                        paddingTop: 100,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    No data
                                                </div>
                                            ) : null}
                                        </div>
                                    </GridOverlay>
                                );
                            },
                        }}
                        columns={[
                            {
                                field: 'favourite',
                                headerName: ' ',
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                width: 50,
                                renderCell: (params) => {
                                    return (
                                        <img
                                            src={params.row.favourite ? StarIcon : StarGrayIcon}
                                            alt="Favourite"
                                            className={'favourite'}
                                        />
                                    );
                                },
                                disableColumnMenu: true,
                            },
                            {
                                field: 'alert',
                                renderHeader: () => <div />,
                                width: 80,
                                cellClassName: 'le-table-cell le-table-cell-content-center badge-container',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                renderCell: (params) => {
                                    if (params.row.alertActive || params.row.adminDocumentsAlertActive) {
                                        return <Badge label={'update'} />;
                                    } else {
                                        return <></>;
                                    }
                                },
                            },
                            {
                                field: 'amount',
                                headerName: 'LOAN AMOUNT',
                                width: 200,
                                valueFormatter: ({ value }) => {
                                    const formatter = new Intl.NumberFormat('en-UK', {
                                        style: 'currency',
                                        currency: 'GBP',
                                        minimumFractionDigits: 0,
                                    });
                                    return formatter.format(value);
                                },
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'applicant',
                                headerName: 'APPLICANT',
                                width: 200,
                                renderCell: (params) => (
                                    <div style={{ lineHeight: 'normal' }}>
                                        <div>
                                            <a
                                                style={{ textDecoration: 'none', color: '#000000' }}
                                                href={'/pitch4admin/loanenquiries/' + params.row.id}
                                                rel="noreferrer"
                                            >
                                                <strong>
                                                    {params.row.applicant.firstName} {params.row.applicant.lastName}
                                                </strong>
                                            </a>
                                        </div>
                                    </div>
                                ),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'tags',
                                headerName: 'TAGS',
                                width: 250,
                                renderCell: (params) => (
                                    <div style={{ lineHeight: 'normal' }} className={'table-tags-container'}>
                                        <>
                                            {params.row.tags.length > 0
                                                ? params.row.tags.map((tag) => {
                                                      return (
                                                          <div
                                                              className={'table-tag-item'}
                                                              style={{
                                                                  backgroundColor: tag.color,
                                                              }}
                                                          >
                                                              {' '}
                                                              {tag.label}
                                                          </div>
                                                      );
                                                  })
                                                : '-'}
                                        </>
                                    </div>
                                ),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'chaseDate',
                                headerName: 'CHASE DATE',
                                width: 200,
                                renderCell: (params) => (
                                    <div>
                                        {params.row.chaseDate
                                            ? moment(params.row.chaseDate).format('D MMMM yyyy')
                                            : '-'}
                                    </div>
                                ),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'chaseNote',
                                headerName: 'CHASE NOTE',
                                width: 200,
                                renderCell: (params) => params.row.chaseNote || '-',
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'submittedBy',
                                headerName: 'SUBMITTED BY',
                                width: 200,
                                renderCell: (params) => (
                                    <div style={{ lineHeight: 'normal' }}>
                                        <div>
                                            <strong>
                                                {params.row.submittedBy.firstName} {params.row.submittedBy.lastName}
                                            </strong>
                                        </div>
                                        <div style={{ fontSize: 10 }}>{params.row.submittedBy.email}</div>
                                    </div>
                                ),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'lenderWaiting',
                                headerName: 'LENDER WAITING',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-terms-sent',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <>
                                            {params.row.lenderWaiting ? (
                                                <>
                                                    <CheckCircleIcon style={{ marginRight: 5 }} />
                                                    <span>
                                                        {moment(params.row.lenderWaiting).format('MMM Do, YYYY')}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <CheckCircleIcon style={{ color: '#c7c7c7' }} />
                                                </>
                                            )}
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'brokerWaiting',
                                headerName: 'BROKER WAITING',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-terms-sent',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <>
                                            {params.row.brokerWaiting ? (
                                                <>
                                                    <CheckCircleIcon style={{ marginRight: 5 }} />
                                                    <span>
                                                        {moment(params.row.brokerWaiting).format('MMM Do, YYYY')}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <CheckCircleIcon style={{ color: '#c7c7c7' }} />
                                                </>
                                            )}
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'borrowerWaiting',
                                headerName: 'BORROWER WAITING',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-terms-sent',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <>
                                            {params.row.borrowerWaiting ? (
                                                <>
                                                    <CheckCircleIcon style={{ marginRight: 5 }} />
                                                    <span>
                                                        {moment(params.row.borrowerWaiting).format('MMM Do, YYYY')}
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <CheckCircleIcon style={{ color: '#c7c7c7' }} />
                                                </>
                                            )}
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'phoneNumber',
                                headerName: 'PHONE NUMBER',
                                width: 200,
                                renderCell: (params) => params.row.submittedBy.phoneNumber,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'assignedTo',
                                headerName: 'ENQUIRY OWNER',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-assigned-to',
                                headerClassName: 'le-table-header-no-separator',
                                disableColumnMenu: true,
                                filterable: false,
                                resizable: false,
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <div className={'icon'}>
                                                <PersonIcon />
                                            </div>
                                            <div
                                                className={`name-${
                                                    params.value[0].id !== 'no_owner' ? 'active' : null
                                                }`}
                                            >
                                                {params.value[0].name}
                                            </div>
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'createdAt',
                                headerName: 'CREATED',
                                width: 170,
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'status',
                                headerName: 'STATUS',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-status',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                valueFormatter: ({ value }) => statusEnum.getType(value),
                                renderCell: (params) => {
                                    return (
                                        <>
                                            {params.value === STATUS_DRAFT ? (
                                                <div className={'label-draft'}>
                                                    {statusEnum.getType(params.value, params.row.loanType)}
                                                </div>
                                            ) : params.value === STATUS_COMPLETION_REQUESTED ||
                                              params.value === STATUS_COMPLETION_REJECTED ? (
                                                <div>
                                                    {statusEnum.getType(params.value, params.row.loanType)}

                                                    <Status status={params.value} />
                                                </div>
                                            ) : (
                                                <>
                                                    <div>{statusEnum.getType(params.value, params.row.loanType)}</div>
                                                    <div>
                                                        <Status status={params.value} />
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'revenue',
                                headerName: 'REVENUE',
                                width: 150,
                                cellClassName: 'le-table-cell le-table-cell-revenue',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                valueFormatter: ({ value }) => formatterInPounds(value),
                                sortable: false,
                            },
                            {
                                field: 'termsSent',
                                headerName: 'TERMS OUT?',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-terms-sent',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={params.row.termsSent}
                                                        onChange={(event) => this.handleTermsSent(params.row)}
                                                        color="primary"
                                                        name="termsSent"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={false}
                                                        id={params.row.id}
                                                    />
                                                }
                                                label={this.getTermsSentLabel(params.row)}
                                            />
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'contractSigned',
                                headerName: 'CONTRACT SIGNED',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-status',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                                renderCell: (params) => (
                                    <>{true === params.row.contractSigned ? <div>Yes</div> : <div>No</div>}</>
                                ),
                            },
                            {
                                field: 'isInvoiced',
                                headerName: 'IS INVOICED',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-status',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                                renderCell: (params) => {
                                    if (params.row.status === STATUS_COMPLETE) {
                                        return (
                                            <>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={params.row.isInvoiced}
                                                            onChange={(event) => this.handleIsInvoiced(params.row)}
                                                            color="primary"
                                                            name="termsSent"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            disabled={false}
                                                            id={params.row.id}
                                                        />
                                                    }
                                                    label={false}
                                                />
                                            </>
                                        );
                                    }

                                    return '-';
                                },
                            },
                            {
                                field: 'isInvoicePaid',
                                headerName: 'IS INVOICE PAID',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-status',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                                renderCell: (params) => {
                                    if (params.row.status === STATUS_COMPLETE) {
                                        return (
                                            <>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={params.row.isInvoicePaid}
                                                            onChange={(event) => this.handleIsInvoicePaid(params.row)}
                                                            color="primary"
                                                            name="termsSent"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            disabled={false}
                                                            id={params.row.id}
                                                        />
                                                    }
                                                    label={false}
                                                />
                                            </>
                                        );
                                    }

                                    return '-';
                                },
                            },
                            {
                                field: 'completedDate',
                                headerName: 'COMPLETED DATE',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-status',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                                renderCell: (params) => (
                                    <>
                                        {params.row.completedDate ? (
                                            moment(params.row.completedDate).format('MMM D, YYYY')
                                        ) : (
                                            <div>-</div>
                                        )}
                                    </>
                                ),
                            },
                            {
                                field: 'adminNoteAlert',
                                renderHeader: () => <div />,
                                width: 90,
                                cellClassName: 'le-table-cell le-table-cell-content-center badge-container',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                renderCell: (params) => {
                                    if (params.row.adminNotesAlertActive) {
                                        return <Badge type={'admin-note-alert'} label={'New Note'} />;
                                    } else {
                                        return <></>;
                                    }
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(TableStyleContainer);
