import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';
import withSession from '../../../pitchblack_react_utils/session/withSession';
import { ClickAwayListener, IconButton, MenuItem, Paper, Popper } from '@material-ui/core';
import Chip from '../../../pitch4_forms/components/inputs/Chip';
import { default as IconFactory } from './StatusWidget/DefaultIconFactory';

/* StatusEnum is only imported here to build the validStatuses object used in propTypes validation
 *  The parent object passes the specific StatusEnum as a prop to ensure correct role based version */
import {
    STATUS_APPLICATION,
    STATUS_COMPLETE,
    STATUS_COMPLETION_REJECTED,
    STATUS_COMPLETION_REQUESTED,
    STATUS_IN_PROGRESS,
    STATUS_LEGALS,
    STATUS_MATCHED,
    STATUS_VALUATION,
} from '../../admin/enum/LenderLoanEnquiries/StatusEnum';
import { ROLE_SUPER_ADMIN } from '../../../pitch4_enum/enum/RoleNameEnum';
import { LOAN_TYPE_PROPERTY } from '../../../pitch4_enum/enum/LoanTypeEnum';

const statusSteps = [
    STATUS_MATCHED,
    STATUS_IN_PROGRESS,
    STATUS_APPLICATION,
    STATUS_VALUATION,
    STATUS_LEGALS,
    STATUS_COMPLETE,
];
const statusStepsMap = {
    [STATUS_MATCHED]: { step: 1, status: STATUS_MATCHED },
    [STATUS_IN_PROGRESS]: { step: 2, status: STATUS_IN_PROGRESS },
    [STATUS_APPLICATION]: { step: 3, status: STATUS_APPLICATION },
    [STATUS_VALUATION]: { step: 4, status: STATUS_VALUATION },
    [STATUS_LEGALS]: { step: 5, status: STATUS_LEGALS },
    [STATUS_COMPLETION_REJECTED]: {
        step: 5,
        status: STATUS_COMPLETION_REJECTED,
    },
    [STATUS_COMPLETION_REQUESTED]: {
        step: 5,
        status: STATUS_COMPLETION_REQUESTED,
    },
    [STATUS_COMPLETE]: { step: 6, status: STATUS_COMPLETE },
};

const getOptionsFromState = (status, transitions, statusEnum, loanType) => {
    let options = [];
    if (status && status.length > 0 && transitions && transitions[status] && statusEnum) {
        const types = statusEnum.getTypes(loanType);

        transitions[status].forEach((s) => {
            options.push({ value: s, label: types[s] });
        });
    }

    return options;
};

const StatusSteps = ({ statusSteps, currentStep }) => {
    return statusSteps.map((step, index) => {
        const statusStep =
            index < currentStep ? `status-step-${step.replace('_', '-')}-color` : 'status-step-incomplete-color';
        return <span className={`status-step ${statusStep}`} key={index} />;
    });
};

const StatusMenu = ({ options, isOpen, handleClose, handleSelect, anchorRef }) => {
    return (
        <Popper
            id="status"
            open={isOpen}
            onClose={handleClose}
            anchorEl={anchorRef.current}
            placement="bottom-end"
            className="input-dropdown-wrapper status-widget-dropdown"
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Paper
                    square
                    style={{
                        minWidth: '200px',
                    }}
                    elevation={4}
                    className={`input-dropdown`}
                >
                    <div
                        className={`input-dropdown-scrollable-items`}
                        style={{
                            height: `${options.length * 40}px`,
                        }}
                    >
                        {options.map((option, index) => (
                            <MenuItem
                                key={index}
                                value={option.value}
                                style={{ width: '100%' }}
                                onClick={() => {
                                    handleSelect(option.value);
                                }}
                            >
                                <Chip
                                    inputProps={{
                                        key: option.value,
                                        size: 'small',
                                        color: 'primary',
                                        label: option.label,
                                        classes: {
                                            root: `MuiChip-status-${option.value.replace('_', '-')}`,
                                        },
                                        style: {
                                            width: '100%',
                                            justifyContent: 'left',
                                            cursor: 'pointer',
                                            fontWeight: 700,
                                            color: '#ffffff',
                                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                        },
                                    }}
                                    className="full-width"
                                />
                            </MenuItem>
                        ))}
                    </div>
                </Paper>
            </ClickAwayListener>
        </Popper>
    );
};

class StatusWidget extends React.Component {
    constructor() {
        super();
        this.state = { isOpen: false };
        this.anchorRef = React.createRef();
    }

    handleOpen = () => {
        this.setState({ isOpen: true });
    };

    handleSelect = (selectedStatus) => {
        const { onChange, matchStatus } = this.props;
        if (selectedStatus !== matchStatus) {
            onChange(selectedStatus);
        }
        this.handleClose();
    };

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    render() {
        const {
            matchStatus,
            statusEnum,
            transitions,
            session,
            enquiryWithdrawn,
            disabled = false,
            showActionMenu = true,
            loanType = LOAN_TYPE_PROPERTY,
        } = this.props;

        const IconFactoryProp = undefined === this.props.iconFactory ? IconFactory : this.props.iconFactory;

        const canTransition =
            disabled === false &&
            enquiryWithdrawn === false &&
            Object.keys(transitions).includes(matchStatus) &&
            session.hasAssumedToken() === false &&
            session.getMostSeniorRole().name !== ROLE_SUPER_ADMIN;

        const options = getOptionsFromState(matchStatus, transitions, statusEnum, loanType);
        const currentStep = (statusStepsMap[matchStatus] && statusStepsMap[matchStatus].step) || 0;
        const statusClassName = matchStatus.replace('_', '-');
        const selectedStatus = statusEnum.getType(matchStatus, loanType);
        const isActionMenuDisabled = session.getMostSeniorRole().name === ROLE_SUPER_ADMIN ? false : !canTransition;
        const canAlterStatus = session.getMostSeniorRole().name === ROLE_SUPER_ADMIN ? true : canTransition;

        return (
            <>
                <div className="status-widget" id={'status-widget'} ref={this.anchorRef}>
                    <span className={`status-widget-status status-${statusClassName}-color`}>{selectedStatus}</span>
                    <div className="status-widget-steps">
                        <StatusSteps statusSteps={statusSteps} currentStep={currentStep} />
                    </div>

                    {true === showActionMenu && (
                        <IconButton
                            aria-controls="status"
                            aria-haspopup="true"
                            onClick={this.handleOpen}
                            className={`status-button status-button-${statusClassName}`}
                            disabled={isActionMenuDisabled}
                        >
                            <IconFactoryProp leadStatus={matchStatus} />
                        </IconButton>
                    )}

                    {canAlterStatus && (
                        <StatusMenu
                            options={options}
                            isOpen={this.state.isOpen}
                            anchorRef={this.anchorRef}
                            handleClose={this.handleClose}
                            handleSelect={this.handleSelect}
                        />
                    )}

                    {session.getMostSeniorRole() === ROLE_SUPER_ADMIN && (
                        <StatusMenu
                            options={options}
                            isOpen={this.state.isOpen}
                            anchorRef={this.anchorRef}
                            handleClose={this.handleClose}
                            handleSelect={this.handleSelect}
                        />
                    )}
                </div>
            </>
        );
    }
}

StatusWidget.propTypes = {
    /**
     * String. The current match / lead state.
     */
    matchStatus: PropTypes.string.isRequired,

    /**
     * Boolean. Is the enquiry of this lead currently in the withdrawn state or not?
     */
    enquiryWithdrawn: PropTypes.bool.isRequired,

    /**
     * Object defining the initial value for the status.
     *
     * @example
     *
     * { status: lenderLoanEnquiry.status }
     */
    initialValues: PropTypes.object.isRequired,

    /**
     * The enum object used to determine the option values and labels in the dropdown.
     */
    statusEnum: PropTypes.object.isRequired,

    /**
     * Function executed when the dropdown is changed. Must accept the new status as the only parameter.
     *
     * @example
     *
     * newStatus => {
     *     console.log('Status changed to ' + newStatus);
     * }
     */
    onChange: PropTypes.func.isRequired,

    /**
     * Boolean. Has an ignore been set for this match / lead?
     */
    ignored: PropTypes.bool,

    /**
     * Boolean. If true the status dropdown will not be displayed, just a static view of the current status.
     */
    disabled: PropTypes.bool,

    iconFactory: PropTypes.object,
};

export default compose(
    reduxForm({
        form: 'edit_status',
        enableReinitialize: true,
    }),
    withSession
)(StatusWidget);
