import _ from 'lodash';
import BorrowerQuestions, {
    QUESTION_RESIDENTIAL_REFINANCE,
    QUESTION_LOAN_PURPOSE,
    QUESTION_LOAN_AMOUNT,
    QUESTION_OUTSTANDING_LOAN,
    QUESTION_PROPERTY_LOCATION_OTHER,
    QUESTION_PROPERTY_LOCATION,
    QUESTION_HMO_HAS_LICENSE,
    QUESTION_HMO_LICENSE_IS_REQUIRED,
    QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY,
    QUESTION_COMMERCIAL_UNIT_COUNT,
    QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN,
    QUESTION_COMMERCIAL_TENANT_AMOUNT,
    QUESTION_PROPERTY_TYPE,
    QUESTION_COMMERCIAL_TYPES,
    QUESTION_COMMERCIAL_TYPE_OTHER,
    QUESTION_BUSINESS_WEBSITE,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
    QUESTION_BUSINESS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
    QUESTION_BUSINESS_IS_SECURE_FINANCE,
    QUESTION_FUNDING_TYPE,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
    QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
    QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
    QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
    QUESTION_APPLICANT_MAIN_NATIONALITY,
    QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
    QUESTION_LOAN_TYPE,
    QUESTION_EXIT_STRATEGY,
    QUESTION_PORTFOLIO_REFINANCE,
    QUESTION_LOAN_USAGE_LOCATION,
    QUESTION_PRE_APPROVE,
    QUESTION_AGREE_TO_TERMS,
    QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
    QUESTION_PROPERTY_LOCATION_BUSINESS,
    QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS,
    QUESTION_DAY_ONE_LOAN_AMOUNT,
    QUESTION_BUSINESS_REQUIRE_FACILITY,
    QUESTION_BUSINESS_FACILITY_NEEDED_BY,
    QUESTION_LOAN_TERM_BUSINESS_TERM,
    QUESTION_COMMERCIAL_OWNER_OCCUPIED,
    QUESTION_RENTAL_INCOME,
} from '../enums/Borrower/BorrowerQuestions';
import {
    BUSINESS_TYPE,
    DEVELOPMENT_TYPE,
    PORTFOLIO_TYPE,
    REFURBISHMENT_TYPE,
    SECOND_CHARGE_TYPE,
    TERM_TYPE,
    UNKNOWN_TYPE,
} from '../enums/Borrower/BorrowerLoanType';
import {
    QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
    QUESTION_OCCUPY_PROPERTY,
    QUESTION_OUTSTANDING_LOAN_AMOUNT,
    QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
} from '../enums/Broker/BrokerQuestions';
import { NO, YES } from '../enums/Shared/YesNo';
import Validation, { ALL_COMMERCIAL_TYPES } from '../enums/Shared/Validation';
import { validateFundingTypes } from './Validate';
import { OTHER } from '../enums/Shared/PropertyLocation';
import {
    COMMERCIAL_FUNDING_TYPE,
    HMO_FUNDING_TYPE,
    SEMI_COMMERCIAL_FUNDING_TYPE,
} from '../enums/Shared/CommercialFundingType';
import {
    BRITISH_EXPAT,
    FOREIGN_NAT_IN_UK_PERMANENT,
    FOREIGN_NAT_IN_UK_VISA,
    UK_CITIZEN,
} from '../enums/Shared/CitizenshipTypes';
import { COUNTRY_OTHER } from '../../../pitch4_enum/enum/CountryNameEnum';
import { NATIONALITY_OTHER } from '../../../pitch4_enum/enum/NationalityEnum';

function isValidHttpUrl(string) {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
}

export function validateSection(validationData, formData, section) {
    const validationFields = new Validation().getTypes();
    const questions = new BorrowerQuestions().getTypes();
    const questionsByFieldName = Object.fromEntries(
        Object.entries(questions).map(([key, question]) => [question.fieldName, { ...question, question: key }])
    );
    let fundingType = ALL_COMMERCIAL_TYPES;
    let loanFundingType = '';
    let isValidated = true;
    let fields = [];
    switch (section) {
        case 'applicant':
            // Don't care about Sub Applicant validation here. Don't @ me. This is reasonable.
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            break;
        case 'loan-type':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            loanFundingType = formData[questions[QUESTION_FUNDING_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (formData[questions[QUESTION_LOAN_TYPE].fieldName] !== BUSINESS_TYPE) {
                if (formData[questions[QUESTION_FUNDING_TYPE].fieldName] === UNKNOWN_TYPE) {
                    isValidated = false;
                }
                if (loanFundingType === '') {
                    isValidated = false;
                }
            }
            break;
        case 'commercial-type':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            break;
        case 'property-information':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                formData[questions[QUESTION_PROPERTY_LOCATION].fieldName] === OTHER &&
                formData[questions[QUESTION_PROPERTY_LOCATION_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_PROPERTY_LOCATION_OTHER].fieldName + '_error'] =
                    'Please enter the location of the property';
                isValidated = false;
            }
            break;
        case 'portfolio-selection':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== REFURBISHMENT_TYPE &&
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== DEVELOPMENT_TYPE &&
                (formData[questions[QUESTION_LOAN_AMOUNT].fieldName] === '' ||
                    _.toInteger(formData[questions[QUESTION_LOAN_AMOUNT].fieldName]) < 100000)
            ) {
                validationData[questions[QUESTION_LOAN_AMOUNT].fieldName + '_error'] =
                    'Please enter a loan amount. It must be above £100,000.';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PORTFOLIO_REFINANCE].fieldName] === YES &&
                formData[questions[QUESTION_OUTSTANDING_LOAN].fieldName] === ''
            ) {
                validationData[questions[QUESTION_OUTSTANDING_LOAN].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }
            break;
        case 'property-selection':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            const isNotRefurbOrDevelopment =
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== REFURBISHMENT_TYPE &&
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== TERM_TYPE &&
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== SECOND_CHARGE_TYPE &&
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== DEVELOPMENT_TYPE;
            if (
                isNotRefurbOrDevelopment &&
                (formData[questions[QUESTION_LOAN_AMOUNT].fieldName] === '' ||
                    _.toInteger(formData[questions[QUESTION_LOAN_AMOUNT].fieldName]) < 100000)
            ) {
                validationData[questions[QUESTION_LOAN_AMOUNT].fieldName + '_error'] =
                    'Please enter a loan amount. It must be greater than £100,000';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] === TERM_TYPE &&
                (formData[questions[QUESTION_LOAN_AMOUNT].fieldName] === '' ||
                    _.toInteger(formData[questions[QUESTION_LOAN_AMOUNT].fieldName]) < 50000)
            ) {
                validationData[questions[QUESTION_LOAN_AMOUNT].fieldName + '_error'] =
                    'Please enter a loan amount. It must be greater than £50,000';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] === SECOND_CHARGE_TYPE &&
                (formData[questions[QUESTION_LOAN_AMOUNT].fieldName] === '' ||
                    _.toInteger(formData[questions[QUESTION_LOAN_AMOUNT].fieldName]) < 20000)
            ) {
                validationData[questions[QUESTION_LOAN_AMOUNT].fieldName + '_error'] =
                    'Please enter a loan amount. It must be greater than £20,000';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_OCCUPY_PROPERTY].fieldName] === YES &&
                formData[questions[QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES].fieldName] === ''
            ) {
                validationData[questions[QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES].fieldName + '_error'] =
                    'Please select an option';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_OCCUPY_PROPERTY].fieldName] === YES &&
                formData[questions[QUESTION_OCCUPY_GREATER_THAN_40_PERCENT].fieldName] === ''
            ) {
                validationData[questions[QUESTION_OCCUPY_GREATER_THAN_40_PERCENT].fieldName + '_error'] =
                    'Please select an option';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName] === YES &&
                formData[questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].fieldName] === ''
            ) {
                validationData[questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].fieldName + '_error'] =
                    'Please select an option';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName] === YES &&
                formData[questions[QUESTION_LOAN_PURPOSE].fieldName] === ''
            ) {
                validationData[questions[QUESTION_LOAN_PURPOSE].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }

            break;
        case 'term-selection':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                fundingType === HMO_FUNDING_TYPE &&
                formData[questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName] === YES &&
                formData[questions[QUESTION_HMO_HAS_LICENSE].fieldName] === ''
            ) {
                validationData[questions[QUESTION_HMO_HAS_LICENSE].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }
            if (
                fundingType === HMO_FUNDING_TYPE &&
                formData[questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName] === YES &&
                formData[questions[QUESTION_HMO_LICENSE_IS_REQUIRED].fieldName] === ''
            ) {
                validationData[questions[QUESTION_HMO_LICENSE_IS_REQUIRED].fieldName + '_error'] =
                    'Please select an option';
                isValidated = false;
            }
            if (
                fundingType === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY].fieldName] === NO &&
                formData[questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }
            if (
                fundingType === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY].fieldName] === NO &&
                formData[questions[QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN].fieldName + '_error'] =
                    'Please enter a value';
                isValidated = false;
            }
            if (
                fundingType === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY].fieldName] === NO &&
                formData[questions[QUESTION_COMMERCIAL_TENANT_AMOUNT].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TENANT_AMOUNT].fieldName + '_error'] =
                    'Please enter a value';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }

            break;
        case 'bridge-selection':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                fundingType === HMO_FUNDING_TYPE &&
                formData[questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName] === YES
            ) {
                fields = [QUESTION_HMO_HAS_LICENSE, QUESTION_HMO_LICENSE_IS_REQUIRED];
                fields.forEach((field) => {
                    if (formData[questions[field].fieldName] === '') {
                        validationData[questions[field].fieldName + '_error'] = 'Please select an option';
                        isValidated = false;
                    }
                });
            }
            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }
            break;
        case 'refurbishment-selection':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName] !== '' &&
                _.toInteger(formData[questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName]) >
                    _.toInteger(formData[questions[QUESTION_LOAN_AMOUNT].fieldName])
            ) {
                validationData[questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error'] =
                    'Your Day One Loan Amount cannot be greater than the amount you wish to borrow.';
                isValidated = false;
            }
            break;
        case 'second-charge-selection':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                NO === formData[questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName] &&
                '' === formData[questions[QUESTION_RENTAL_INCOME].fieldName]
            ) {
                isValidated = false;
            }
            if ('' === formData[questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName]) {
                isValidated = true;
            }
            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }
            break;
        case 'development-selection':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                formData[questions[QUESTION_RESIDENTIAL_REFINANCE]] === YES &&
                formData[questions[QUESTION_OUTSTANDING_LOAN].fieldName] === ''
            ) {
                validationData[questions[QUESTION_OUTSTANDING_LOAN].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName] !== '' &&
                _.toInteger(formData[questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName]) >
                    _.toInteger(formData[questions[QUESTION_LOAN_AMOUNT].fieldName])
            ) {
                validationData[questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error'] =
                    'Your Day One Loan Amount cannot be greater than the amount you wish to borrow.';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }
            break;
        case 'mezzanine-selection':
            fundingType = formData[questions[QUESTION_PROPERTY_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                (formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE ||
                    formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE) &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) &&
                formData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }

            if (
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].length === 0
            ) {
                validationData[questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'] = 'Please select an option';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName] !== '' &&
                _.toInteger(formData[questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName]) >
                    _.toInteger(formData[questions[QUESTION_LOAN_AMOUNT].fieldName])
            ) {
                validationData[questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error'] =
                    'Your Day One Loan Amount cannot be greater than the amount you wish to borrow.';
                isValidated = false;
            }
            break;
        case 'business-details':
            fundingType = formData[questions[QUESTION_FUNDING_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                formData[questions[QUESTION_BUSINESS_WEBSITE].fieldName] !== '' &&
                isValidHttpUrl(formData[questions[QUESTION_BUSINESS_WEBSITE].fieldName]) === false
            ) {
                validationData[questions[QUESTION_BUSINESS_WEBSITE].fieldName + '_error'] =
                    'Please ensure your business website URL is correct (e.g. https://google.com/)';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_BUSINESS_REQUIRE_FACILITY].fieldName] === YES &&
                formData[questions[QUESTION_BUSINESS_FACILITY_NEEDED_BY].fieldName] === ''
            ) {
                validationData[questions[QUESTION_BUSINESS_FACILITY_NEEDED_BY].fieldName + '_error'] =
                    'Please select a value';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_PROPERTY_LOCATION_BUSINESS].fieldName] === OTHER &&
                formData[questions[QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER].fieldName + '_error'] =
                    'Please enter the location of the facility';
                isValidated = false;
            }

            break;
        case 'business-financials':
            fundingType = formData[questions[QUESTION_FUNDING_TYPE].fieldName];
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                formData[questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].fieldName] === YES &&
                formData[questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION].fieldName] === ''
            ) {
                validationData[questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION].fieldName + '_error'] =
                    'Please enter an explanation';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_BUSINESS_ADVERSE_CREDIT].fieldName] === YES &&
                formData[questions[QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION].fieldName] === ''
            ) {
                validationData[questions[QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION].fieldName + '_error'] =
                    'Please enter an explanation';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_BUSINESS_IS_SECURE_FINANCE].fieldName] === YES &&
                formData[questions[QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS].fieldName] === ''
            ) {
                validationData[
                    questions[QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS].fieldName + '_error'
                ] = 'Please enter a value';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_BUSINESS_REQUIRE_FACILITY].fieldName] === YES &&
                formData[questions[QUESTION_LOAN_TERM_BUSINESS_TERM].fieldName] === ''
            ) {
                validationData[questions[QUESTION_LOAN_TERM_BUSINESS_TERM].fieldName + '_error'] =
                    'Please enter a value';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_LOAN_AMOUNT].fieldName] === '' ||
                _.toInteger(formData[questions[QUESTION_LOAN_AMOUNT].fieldName]) < 10000
            ) {
                validationData[questions[QUESTION_LOAN_AMOUNT].fieldName + '_error'] =
                    'Please enter a loan amount. It must be greater than £10,000';
                isValidated = false;
            }
            break;

        case 'credit':
            fundingType = ALL_COMMERCIAL_TYPES;
            fields = validationFields[section];
            isValidated = validateFundingTypes(
                section,
                fundingType,
                fields,
                questions,
                formData,
                validationData,
                isValidated
            );
            if (
                formData[questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES].fieldName] === YES &&
                formData[questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION].fieldName] === ''
            ) {
                validationData[
                    questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION].fieldName + '_error'
                ] = 'Please enter an explanation';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName] !==
                    FOREIGN_NAT_IN_UK_PERMANENT &&
                formData[questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName] !== FOREIGN_NAT_IN_UK_VISA &&
                formData[questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE].fieldName] === ''
            ) {
                validationData[questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE].fieldName + '_error'] =
                    'Please select a value';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE].fieldName] === COUNTRY_OTHER &&
                formData[questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER].fieldName + '_error'] =
                    'Please enter a country of residence';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName] !== UK_CITIZEN &&
                formData[questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName] !== BRITISH_EXPAT &&
                formData[questions[QUESTION_APPLICANT_MAIN_NATIONALITY].fieldName] === ''
            ) {
                validationData[questions[QUESTION_APPLICANT_MAIN_NATIONALITY].fieldName + '_error'] =
                    'Please select a value';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_APPLICANT_MAIN_NATIONALITY].fieldName] === NATIONALITY_OTHER &&
                formData[questions[QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER].fieldName] === ''
            ) {
                validationData[questions[QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER].fieldName + '_error'] =
                    'Please enter a value';
                isValidated = false;
            }
            if (
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE &&
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== TERM_TYPE &&
                formData[questions[QUESTION_LOAN_TYPE].fieldName] !== BUSINESS_TYPE &&
                formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== SECOND_CHARGE_TYPE &&
                formData[questions[QUESTION_PROPERTY_TYPE].fieldName] !== SEMI_COMMERCIAL_FUNDING_TYPE &&
                formData[questions[QUESTION_EXIT_STRATEGY].fieldName] === ''
            ) {
                validationData[questions[QUESTION_EXIT_STRATEGY].fieldName + '_error'] = 'Please enter a value';
                isValidated = false;
            }
            break;
        case 'preapproved':
            validationData[questions[QUESTION_PRE_APPROVE].fieldName + '_error'] =
                formData[questions[QUESTION_PRE_APPROVE].fieldName] === '' ? 'Please select a value' : '';
            isValidated = validationData[questions[QUESTION_PRE_APPROVE].fieldName + '_error'] === '';
            break;
        case 'location_confirmation':
            validationData[questions[QUESTION_LOAN_USAGE_LOCATION].fieldName + '_error'] =
                formData[questions[QUESTION_LOAN_USAGE_LOCATION].fieldName] === '' ? 'Please select a value' : '';
            if (formData[questions[QUESTION_LOAN_USAGE_LOCATION].fieldName] === NO) {
                validationData[questions[QUESTION_LOAN_USAGE_LOCATION].fieldName + '_error'] =
                    formData[questions[QUESTION_LOAN_USAGE_LOCATION].fieldName] === NO
                        ? 'Currently we only offer links to funding solutions in the UK or EU'
                        : '';
            }
            validationData[questions[QUESTION_AGREE_TO_TERMS].fieldName + '_error'] =
                formData[questions[QUESTION_AGREE_TO_TERMS].fieldName] !== true ? 'You must agree to the terms' : '';
            isValidated =
                validationData[questions[QUESTION_LOAN_USAGE_LOCATION].fieldName + '_error'] === '' &&
                validationData[questions[QUESTION_AGREE_TO_TERMS].fieldName + '_error'] === '';
            break;
        case 'summary':
            isValidated = true;
            break;
        case 'additional':
            isValidated = true;
            break;
        default:
            break;
    }

    // Validation applied to all fields
    for (const [key, value] of Object.entries(formData)) {
        // All numbers must be greater than or equal to 0
        if (!questionsByFieldName.hasOwnProperty(key)) {
            continue;
        }
        if (
            value !== '' &&
            (questionsByFieldName[key].type === 'number' || questionsByFieldName[key].type === 'currency') &&
            _.toInteger(value) < 0
        ) {
            validationData[key + '_error'] = 'Value must not be less than 0';
            isValidated = false;
        }
    }

    return { isValidated: isValidated, validationData: validationData };
}
