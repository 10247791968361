import createP4ApolloFetch from '../../bootstrap/middlewares';
import { getUserUrl } from '../../pitchblack_react_utils/user/Url';

export const getDocument = (id) => {
    const GetDocumentQuery = `
        query getDocument($id: ID!) {
          document(id: $id) {
            createdAt
            downloadUrl
            fileName
            fileSize
            id
            mimeType
            status
            type
            updatedAt
            uploaderRole
          }
        }
    `;

    const apolloFetch = createP4ApolloFetch({ uri: getUserUrl() });

    return apolloFetch({
        query: GetDocumentQuery,
        variables: {
            id,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
