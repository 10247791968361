import { call, delay, put, race, take } from 'redux-saga/effects';
import { SAGA_NAMESPACE } from './chat';
import ChannelMessageFactory from '../messaging/factory/ChannelMessageFactory';
import { actionAddMessages, actionSortMessages } from '../redux/chatMessages';

export const POLL_CHANNEL_MESSAGES_START = `${SAGA_NAMESPACE}.POLL_CHANNEL_MESSAGES_START`;
export const startPollingChannelMessages = (channel, loanEnquiryId, query, time) => ({
    type: POLL_CHANNEL_MESSAGES_START,
    channel,
    loanEnquiryId,
    query,
    time,
});

export const POLL_CHANNEL_MESSAGES_STOP = `${SAGA_NAMESPACE}.POLL_CHANNEL_MESSAGES_STOP`;
export const stopPollingChannelMessages = () => ({
    type: POLL_CHANNEL_MESSAGES_STOP,
});

function* pollChannelMessages(channel, loanEnquiryId, query, time) {
    while (true) {
        try {
            const response = yield call(query, channel, loanEnquiryId);
            switch (response.getClassName()) {
                case 'ChannelMessagesSuccess':
                    const channelMessageFactory = new ChannelMessageFactory();
                    let messages = response.getChannelMessages().map((channelMessage) => {
                        return channelMessageFactory.createFromApiMessage(channelMessage);
                    });
                    yield put(actionAddMessages(messages));
                    yield put(actionSortMessages());
                    break;
                case 'ChannelMessagesError':
                    yield put({ type: POLL_CHANNEL_MESSAGES_STOP });
                    break;
                default:
                    yield put({ type: POLL_CHANNEL_MESSAGES_STOP });
                    break;
            }
            yield delay(time);
        } catch (err) {
            yield put({ type: POLL_CHANNEL_MESSAGES_STOP });
        }
    }
}

export default function* pollChannelMessagesWatch() {
    while (true) {
        const action = yield take(POLL_CHANNEL_MESSAGES_START);
        yield race([
            call(pollChannelMessages, action.channel, action.loanEnquiryId, action.query, action.time),
            take(POLL_CHANNEL_MESSAGES_STOP),
        ]);
    }
}
