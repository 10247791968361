import React from 'react';
import styled from 'styled-components';
import NavigationBar from '../components/NavigationBar';
import LeftSideMenuContainer from './LeftSideMenuContainer';

const StyledNavigationContainer = styled.div`
    width: 100%;
`;

class NavigationContainer extends React.Component {
    render() {
        return (
            <StyledNavigationContainer className="header">
                <LeftSideMenuContainer />
                <NavigationBar noHamburger={true} />
            </StyledNavigationContainer>
        );
    }
}

export default NavigationContainer;
