import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_BORROWER, ROLE_INTERMEDIARY, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import PlaceholderImageLayout from '../../../../../pitch4_layout/components/content/PlaceholderImageLayout';
import ErrorIcon from '../../../../../pitch4_layout/components/icons/ErrorIcon';
import { DataGrid } from '@material-ui/data-grid';
import moment from 'moment';
import Status from '../../../../borrower/loan_enquiries/components/Status';
import StatusEnum, { STATUS_COMPLETION_REJECTED } from '../../enum/LenderLoanEnquiry/StatusEnum';
import { connect } from 'react-redux';
import { ROWS_PER_PAGE, PAGE_SIZE } from '../../../../../pitch4_enum/enum/DataGridEnum';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import StarIcon from '../../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../../assets/images/star-gray.svg';
import LenderDrawer from '../components/LenderDrawer';
import { setLLEFav } from '../api/set_lle_favourite';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { getStoredDataValueFromArray } from '../../../../view_loan_enquiry/HelperFunctions';
import { LOAN_TYPE } from '../../../../../pitch4_enum/enum/FactEnum';
import { FormControlLabel, Switch } from '@material-ui/core';
import { updateLenderWaiting } from '../api/set_lender_waiting';
import { updateBrokerWaiting } from '../api/set_broker_waiting';
import { updateBorrowerWaiting } from '../api/set_borrower_waiting';

const statusEnum = new StatusEnum();

class LeadsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loanEnquiryMatches: [],
            matchSelected: null,
            lenderDrawer: {
                open: false,
                anchor: 'right',
                lender: null,
                lenderLoanEnquiry: null,
            },
            submitterRole: props.loanEnquiry.submittedBy.roles[0].name,
            matches: props.matches,
            data: props.matches.map((match, index) => {
                return {
                    id: match.id,
                    lenderId: match.lender.id,
                    lenderName: match.lender.name,
                    lenderAnonName: match.lender.anonName ?? null,
                    lenderProfileEnabled: match.lender.profileEnabled,
                    lenderProfile: match.lender,
                    updatedAt: match.updatedAt,
                    status: match.status,
                    alertActive: match.alertActive,
                    favourite: match.favourite,
                    leType: getStoredDataValueFromArray(props.loanEnquiry.storedData, LOAN_TYPE),
                    lenderTerms: JSON.parse(match.lenderTerms),
                    lenderWaiting: match.lenderWaiting || null,
                    borrowerWaiting: match.borrowerWaiting || null,
                    brokerWaiting: match.brokerWaiting || null,
                };
            }),
            showOnlyFavEnabled: false,
        };
    }

    toggleLenderDrawer = (open = false, lenderLoanEnquiry = null) => (event) => {
        this.setState({
            lenderDrawer: {
                ...this.state.lenderDrawer,
                open: open,
                lenderLoanEnquiry: lenderLoanEnquiry,
            },
        });
    };

    handleFavouriteMatchChange = (lenderLoanEnquiryId = null, loanEnquiryId = null, isFavourite = null) => () => {
        if (null !== lenderLoanEnquiryId && null !== isFavourite) {
            setLLEFav(lenderLoanEnquiryId, loanEnquiryId, !isFavourite).then(() => {
                const data = this.state.data;
                const dMatchIndex = this.state.data.findIndex((m) => m.id === lenderLoanEnquiryId);
                data[dMatchIndex].favourite = !isFavourite;

                // We need to do the same as above to update props matches objects in state.
                // Props matches kept in state  - we need them to filter out Fav matches and then show all matches again
                const matches = this.state.matches;
                const mMatchIndex = this.state.matches.findIndex((m) => m.id === lenderLoanEnquiryId);
                matches[mMatchIndex].favourite = !isFavourite;

                this.setState({ data: [], matches: [] }, () =>
                    this.setState({
                        // Filter out not Fav LLEs
                        data:
                            true === this.state.showOnlyFavEnabled
                                ? data.filter((match) => match.id !== lenderLoanEnquiryId)
                                : data,
                        matches: matches,
                    })
                );
            });
        }
    };

    handleShowOnlyFav = (selectFav) => {
        const { matches } = this.state;
        const data = matches
            .filter((match) => (true === selectFav ? selectFav === match.favourite : match))
            .map((match) => {
                return {
                    id: match.id,
                    lenderId: match.lender.id,
                    lenderName: match.lender.name,
                    lenderAnonName: match.lender.anonName ?? null,
                    lenderProfileEnabled: match.lender.profileEnabled,
                    lenderProfile: match.lender,
                    updatedAt: match.updatedAt,
                    status: match.status,
                    alertActive: match.alertActive,
                    favourite: match.favourite,
                    lenderTerms: JSON.parse(match.lenderTerms) || null,
                };
            });

        this.setState({
            data: data,
            refreshedFavData: true,
            showOnlyFavEnabled: selectFav,
        });
    };

    updateLenderTermsInGrid = (lleId, newTerms) => {
        const { data } = this.state;
        const newData = data.map((m) => {
            return {
                id: m.id,
                lenderId: m.lenderId,
                lenderName: m.lenderName,
                lenderAnonName: m.anonName ?? null,
                lenderProfileEnabled: m.lenderProfileEnabled,
                lenderProfile: m.lenderProfile,
                updatedAt: m.updatedAt,
                status: m.status,
                alertActive: m.alertActive,
                favourite: m.favourite,
                leType: getStoredDataValueFromArray(this.props.loanEnquiry.storedData, LOAN_TYPE),
                lenderTerms: m.id !== lleId ? m.lenderTerms : newTerms,
                lenderWaiting: m.lenderWaiting || null,
                brokerWaiting: m.brokerWaiting || null,
                borrowerWaiting: m.borrowerWaiting || null,
            };
        });

        this.setState({
            data: newData,
        });
    };

    handleLenderWaiting = (event, match) => {
        const checked = event.target.checked;
        updateLenderWaiting(match.id, checked ? moment() : null).then((resp) => {
            const matches = this.state.data;
            let m = matches.find((m) => m.id === match.id);
            m.lenderWaiting = checked ? moment() : null;

            const newMatches = matches.map((item) => (item.id !== m.id ? item : m));
            this.setState({ data: newMatches });
        });
    };

    handleBrokerWaiting = (event, match) => {
        const checked = event.target.checked;
        updateBrokerWaiting(match.id, checked ? moment() : null).then((resp) => {
            const matches = this.state.data;
            let m = matches.find((m) => m.id === match.id);
            m.brokerWaiting = checked ? moment() : null;

            const newMatches = matches.map((item) => (item.id !== m.id ? item : m));
            this.setState({ data: newMatches });
        });
    };

    handleBorrowerWaiting = (event, match) => {
        const checked = event.target.checked;
        updateBorrowerWaiting(match.id, checked ? moment() : null).then((resp) => {
            const matches = this.state.data;
            let m = matches.find((m) => m.id === match.id);
            m.borrowerWaiting = checked ? moment() : null;

            const newMatches = matches.map((item) => (item.id !== m.id ? item : m));
            this.setState({ data: newMatches });
        });
    };

    getLenderWaitingLabel = (match) => {
        if (null !== match.lenderWaiting) {
            return moment(match.lenderWaiting).format('MMM Do, YYYY');
        }

        return '';
    };
    getBrokerWaitingLabel = (match) => {
        if (null !== match.brokerWaiting) {
            return moment(match.brokerWaiting).format('MMM Do, YYYY');
        }

        return '';
    };
    getBorrowerWaitingLabel = (match) => {
        if (null !== match.borrowerWaiting) {
            return moment(match.borrowerWaiting).format('MMM Do, YYYY');
        }

        return '';
    };

    render() {
        let { handleLenderSelection, selectedLenders, matchCount, title = 'Lender communications' } = this.props;
        const { data } = this.state;

        return (
            <>
                {this.state.lenderDrawer.open && (
                    <LenderDrawer
                        anchor={this.state.lenderDrawer.anchor}
                        open={this.state.lenderDrawer.open}
                        toggleLenderDrawer={this.toggleLenderDrawer}
                        lenderLoanEnquiry={this.state.lenderDrawer.lenderLoanEnquiry}
                        channelId={
                            this.state.lenderDrawer.lenderLoanEnquiry &&
                            this.state.lenderDrawer.lenderLoanEnquiry.channel
                                ? this.state.lenderDrawer.lenderLoanEnquiry.channel.id
                                : null
                        }
                        handleFavouriteMatchChange={this.handleFavouriteMatchChange}
                        matches={data || []}
                        updateLenderTermsInGrid={this.updateLenderTermsInGrid}
                        handleLenderWaiting={this.handleLenderWaiting}
                        handleBrokerWaiting={this.handleBrokerWaiting}
                        handleBorrowerWaiting={this.handleBorrowerWaiting}
                        getLenderWaitingLabel={this.getLenderWaitingLabel}
                        getBrokerWaitingLabel={this.getBrokerWaitingLabel}
                        getBorrowerWaitingLabel={this.getBorrowerWaitingLabel}
                    />
                )}
                <div className="leads-container data-grid-table-container" style={{ margin: 0 }}>
                    <div className={'admin-leads-container-header'}>
                        <div>{title}</div>
                        <div className={'select-all'}>
                            <span className={'label'}>Show Only Starred:</span>{' '}
                            {this.state.selectAll ? (
                                <CheckBoxIcon
                                    className={'icon'}
                                    onClick={() => {
                                        this.setState({ selectAll: false }, () => {
                                            this.handleShowOnlyFav(false);
                                        });
                                    }}
                                />
                            ) : (
                                <CheckBoxOutlineBlankIcon
                                    className={'icon'}
                                    onClick={() => {
                                        this.setState({ selectAll: true }, () => {
                                            this.handleShowOnlyFav(true);
                                        });
                                    }}
                                />
                            )}
                            <div className={'divider'} />
                        </div>
                        <div className={'lenders-badge'}>
                            <div className={'count'}>{matchCount}</div>
                            <div>LENDER{matchCount !== 1 && 'S'}</div>
                        </div>
                    </div>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={[
                            {
                                field: 'updatedAt',
                                sort: 'desc',
                            },
                        ]}
                        checkboxSelection={true}
                        onSelectionModelChange={(selected) => {
                            handleLenderSelection(selected);
                        }}
                        selectionModel={selectedLenders && selectedLenders.length > 0 ? selectedLenders : []}
                        className={'data-grid-no-border'}
                        onCellClick={(params) => {
                            switch (params.field) {
                                case '__check__':
                                case 'lenderWaiting':
                                case 'brokerWaiting':
                                case 'borrowerWaiting':
                                    break;
                                case 'favourite':
                                    this.handleFavouriteMatchChange(params.row.id, null, params.row.favourite)();
                                    break;
                                default:
                                    this.toggleLenderDrawer(true, params.row)();
                            }
                        }}
                        pageSize={PAGE_SIZE}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                        rows={this.state.data}
                        disableSelectionOnClick={true}
                        columns={[
                            {
                                field: 'favourite',
                                renderHeader: () => <div />,
                                headerClassName: 'data-grid-table-header-no-separator',
                                cellClassName: 'data-grid-table-cell',
                                width: 50,
                                renderCell: (params) => {
                                    return (
                                        <img
                                            src={params.row.favourite ? StarIcon : StarGrayIcon}
                                            alt="Favourite"
                                            className={'favourite'}
                                        />
                                    );
                                },
                                disableColumnMenu: true,
                            },
                            {
                                field: 'lenderName',
                                headerName: 'LENDER',
                                headerClassName:
                                    'data-grid-table-header-no-separator data-grid-table-header-fixed-height',
                                width: 200,
                                cellClassName: 'data-grid-table-cell',
                                renderCell: (params) => {
                                    return (
                                        <div className={'lender-name'}>
                                            <div>{params.row.lenderName}</div>
                                            {null !== params.row.lenderAnonName && (
                                                <div className={'anon-name'}>{params.row.lenderAnonName}</div>
                                            )}
                                        </div>
                                    );
                                },
                            },
                            {
                                field: 'terms',
                                headerName: 'TERMS',
                                headerClassName:
                                    'data-grid-table-header-no-separator data-grid-table-header-fixed-height',
                                width: 150,
                                cellClassName: 'data-grid-table-cell',
                                renderCell: (params) => {
                                    const terms = params.row.lenderTerms ? params.row.lenderTerms.terms : null;
                                    const totalItemsCount = null === terms || undefined === terms ? 0 : terms.length;
                                    const doneItemsCount =
                                        null === terms || undefined === terms
                                            ? 0
                                            : terms.filter((item) => item.value !== null).length;
                                    return (
                                        <div className={doneItemsCount !== 0 ? 'terms-added' : 'terms-empty'}>
                                            {null !== terms ? (
                                                <strong>
                                                    {doneItemsCount} / {totalItemsCount} Terms
                                                </strong>
                                            ) : (
                                                <strong>Terms not set</strong>
                                            )}
                                        </div>
                                    );
                                },
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                            },
                            {
                                field: 'updatedAt',
                                headerName: 'UPDATED',
                                headerClassName:
                                    'data-grid-table-header-no-separator data-grid-table-header-fixed-height',
                                width: 170,
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                                cellClassName: 'data-grid-table-cell',
                            },
                            {
                                field: 'status',
                                headerName: 'STATUS',
                                headerClassName:
                                    'data-grid-table-header-no-separator data-grid-table-header-fixed-height',
                                width: 150,
                                cellClassName: 'data-grid-table-cell le-table-cell-status',
                                valueFormatter: ({ value }) => statusEnum.getType(value),
                                renderCell: (params) => (
                                    <>
                                        <div>{statusEnum.getType(params.value, params.row.leType)}</div>
                                        {params.value === STATUS_COMPLETION_REJECTED ? (
                                            <ErrorIcon
                                                style={{
                                                    display: 'inline-block',
                                                    width: 25,
                                                    height: 25,
                                                    marginLeft: 50,
                                                }}
                                            />
                                        ) : null}
                                        <Status style={{ marginLeft: 20 }} status={params.value} />
                                    </>
                                ),
                            },
                            {
                                field: 'lenderWaiting',
                                headerName: 'LENDER WAITING',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-terms-sent',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={params.row.lenderWaiting !== null}
                                                onChange={(event) => this.handleLenderWaiting(event, params.row)}
                                                color="primary"
                                                name="lenderWaiting"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                disabled={false}
                                                id={params.row.id}
                                            />
                                        }
                                        label={this.getLenderWaitingLabel(params.row)}
                                    />
                                ),
                            },
                            {
                                field: 'brokerWaiting',
                                headerName: 'BROKER WAITING',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-terms-sent',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                hide: this.state.submitterRole !== ROLE_INTERMEDIARY,
                                renderCell: (params) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={params.row.brokerWaiting !== null}
                                                onChange={(event) => this.handleBrokerWaiting(event, params.row)}
                                                color="primary"
                                                name="brokerWaiting"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                disabled={false}
                                                id={params.row.id}
                                            />
                                        }
                                        label={this.getBrokerWaitingLabel(params.row)}
                                    />
                                ),
                            },
                            {
                                field: 'borrowerWaiting',
                                headerName: 'BORROWER WAITING',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-terms-sent',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                hide: this.state.submitterRole !== ROLE_BORROWER,
                                renderCell: (params) => (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={params.row.borrowerWaiting !== null}
                                                onChange={(event) => this.handleBorrowerWaiting(event, params.row)}
                                                color="primary"
                                                name="borrowerWaiting"
                                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                                disabled={false}
                                                id={params.row.id}
                                            />
                                        }
                                        label={this.getBorrowerWaitingLabel(params.row)}
                                    />
                                ),
                            },
                            {
                                field: 'alertActive',
                                renderHeader: () => <div />,
                                cellClassName: 'data-grid-table-cell badge-container',
                                headerClassName:
                                    'data-grid-table-header-no-separator data-grid-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                renderCell: (params) => {
                                    if (params.row.alertActive) {
                                        return <Badge label={'update'} />;
                                    } else {
                                        return <></>;
                                    }
                                },
                            },
                        ]}
                    />
                    {this.state.data.length === 0 && (
                        <PlaceholderImageLayout classNames={'empty-table-placeholder'}>
                            <div>
                                <h3 className={'typography-card-title'}>No matches found.</h3>
                                <p className={'typography-default'}>
                                    This enquiry has not matched with any lenders. This may be due to the critera being
                                    too specific.
                                </p>
                            </div>
                        </PlaceholderImageLayout>
                    )}
                </div>
            </>
        );
    }
}

LeadsContainer.propTypes = {
    matches: PropTypes.array.isRequired,
    loanEnquiryStatus: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
    };
};

LeadsContainer.propTypes = {
    matches: PropTypes.array.isRequired,
    loanEnquiryStatus: PropTypes.string,
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(LeadsContainer);
