import React from 'react';
import { reduxForm, SubmissionError } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { Mutation, withApollo } from 'react-apollo';
import { withRouter } from 'react-router';
import { goBack, push } from 'react-router-redux';
import { CenteringContainer, Container, RowItem } from '../../../pitchblack_react_utils/layout/index';
import { hasMinLength, isEmail, isRequired } from '../../../pitchblack_react_utils/form/validation';
import TokenFragment from '../gql/fragments/TokenFragment';
import SessionFragment from '../gql/fragments/SessionFragment';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { AUTH_URL, TAG_MANAGER_EVENT_PLATFORM_LOGIN_SUCCESS } from '../../../bootstrap/constants/constants';
import { sagaActionNotificationSetError, sagaActionNotificationSetSuccess } from '../../notifications/sagas/container';
import { Link } from '../../../pitch4_layout/components/href';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import { ROUTE_ANON_LOGIN, ROUTE_REQUEST_PASSWORD_RESET } from '../../../pitch4_routing';
import { RightAligned } from '../../../pitch4_layout/components/div';
import { TextField } from '../../../pitch4_forms/components/inputs';
import { sagaActionLogout } from '../sagas/login';
import { actionHubspotTrackingIdentifyVisitor } from '../../hubspot/redux/tracking';
import {
    actionLoginSuccess,
    actionSetGotoPath,
    actionSetValidationErrors,
    actionShowTwoFactorAuthForm,
    selectorAreErrorsVisible,
    selectorGetValidationErrors,
} from '../redux/login';
import {
    actionSetHasMissingRequiredFields,
    actionSetMissingRequiredFieldsList,
} from '../../user/configure_profile/redux';
import { Paper } from '@material-ui/core';
import { ROLE_SLAM } from '../../../pitch4_enum/enum/RoleNameEnum';

const usernameValidate = [isRequired, isEmail];
const passwordValidate = [isRequired, hasMinLength(7)];

class LoginForm extends React.Component {
    componentDidMount() {
        const { setGotoPath } = this.props;
        if (
            !!document.referrer &&
            document.referrer.includes(window.location.hostname) &&
            document.referrer !== window.location.href
        ) {
            const url = new URL(document.referrer);
            setGotoPath(decodeURIComponent(url.pathname));
        }
    }

    onSubmit = (mutate, props) => (values) => {
        const { reset, dispatch } = props;
        const { username, password } = values;
        const { client } = this.props;

        //map the variables if necessary
        return mutate({
            variables: {
                input: {
                    username,
                    password,
                },
            },
            context: {
                uri: AUTH_URL,
            },
        })
            .then((props) => {
                client.clearStore().then(() => {
                    const {
                        data: { login },
                    } = props;

                    if (login['requiresTwoFactorAuth'] === true) {
                        dispatch(actionShowTwoFactorAuthForm(username, password));
                        return;
                    }

                    const sessionManager = new SessionManager();
                    sessionManager.login(login.token, login.session);

                    if (login.assumedIdentity !== null) {
                        sessionManager.assumedIdentity(login.assumedIdentity.token, login.assumedIdentity.session);
                    }

                    dispatch(actionSetMissingRequiredFieldsList(sessionManager.getMissingRequiredFieldsList()));
                    dispatch(actionSetHasMissingRequiredFields(sessionManager.hasMissingRequiredFields()));
                    dispatch(actionLoginSuccess());
                    dispatch(actionHubspotTrackingIdentifyVisitor(username, {}));
                    dispatch(sagaActionNotificationSetSuccess('Login successful'));

                    // Trigger GTM event
                    if (sessionManager.getRelevantSession().getMostSeniorRole().name === ROLE_SLAM) {
                        window.dataLayer.push({
                            event: TAG_MANAGER_EVENT_PLATFORM_LOGIN_SUCCESS,
                            userId: props.data.login.session.id,
                            userRole: ROLE_SLAM,
                            userEmail: props.data.login.session.supplementaryData.email,
                        });
                    }
                });
            })
            .catch((error) => {
                reset();

                dispatch(sagaActionNotificationSetError('Login failed'));
                dispatch(actionSetValidationErrors(error.graphQLErrors));
                throw new SubmissionError({ _error: error.graphQLErrors });
            });
    };

    handleKeyDown = function (e, cb) {
        if (e.key === 'Enter' && e.shiftKey === false) {
            e.preventDefault();
            cb();
        }
    };

    render() {
        const { handleSubmit, submitting, redirect } = this.props;

        return (
            <Paper style={{ padding: 20 }}>
                <Container>
                    <Container>
                        <h1 className={'heading'}>Log In</h1>
                    </Container>
                    <CenteringContainer className={'login'}>
                        <Mutation
                            mutation={gql`
                                mutation login($input: loginInput!) {
                                    login(input: $input) {
                                        token {
                                            ...TokenFragmentToken
                                        }
                                        session {
                                            primaryPhone {
                                                number
                                            }
                                            ...SessionFragmentSession
                                        }
                                        assumedIdentity {
                                            token {
                                                token
                                                expires
                                            }
                                            session {
                                                id
                                                roles {
                                                    name
                                                }
                                                title
                                                gender
                                                given
                                                family
                                                authExpiry
                                                supplementaryData
                                            }
                                        }
                                        requiresTwoFactorAuth
                                    }
                                }
                                ${TokenFragment.fragments.token}
                                ${SessionFragment.fragments.session}
                            `}
                        >
                            {(mutate) => (
                                <form
                                    onSubmit={handleSubmit(this.onSubmit(mutate, this.props))}
                                    onKeyDown={(e) => {
                                        this.handleKeyDown(e, handleSubmit(this.onSubmit(mutate, this.props)));
                                    }}
                                >
                                    <CenteringContainer>
                                        <RowItem>
                                            <CenteringContainer>
                                                <TextField
                                                    name="username"
                                                    autoComplete={`email`}
                                                    label="Username"
                                                    variant={`outlined`}
                                                    disabled={submitting}
                                                    InputProps={{}}
                                                    validate={usernameValidate}
                                                    autoFocus={true}
                                                />
                                            </CenteringContainer>
                                        </RowItem>
                                        <RowItem>
                                            <CenteringContainer>
                                                <TextField
                                                    name="password"
                                                    type="password"
                                                    autoComplete={`password`}
                                                    variant={`outlined`}
                                                    disabled={submitting}
                                                    label="Password"
                                                    validate={passwordValidate}
                                                    InputProps={{}}
                                                />
                                            </CenteringContainer>
                                        </RowItem>
                                        <RowItem className={'forgot-password'}>
                                            <Link onClickIn={redirect(ROUTE_REQUEST_PASSWORD_RESET)}>
                                                Forgot Password?
                                            </Link>
                                        </RowItem>
                                        <RowItem>
                                            <RightAligned className={'button-panel'}>
                                                <PrimaryButton type="submit" disabled={submitting} name={`submit`}>
                                                    Login
                                                </PrimaryButton>
                                            </RightAligned>
                                        </RowItem>
                                    </CenteringContainer>
                                </form>
                            )}
                        </Mutation>
                    </CenteringContainer>
                </Container>
            </Paper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        errorsVisible: selectorAreErrorsVisible(state),
        validationErrors: selectorGetValidationErrors(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        logout: () => dispatch(sagaActionLogout(ROUTE_ANON_LOGIN)),
        setGotoPath: (gotoPath) => dispatch(actionSetGotoPath(gotoPath)),
    };
};

LoginForm.propTypes = {
    handleSubmit: PropTypes.func,
    error: PropTypes.array,
    errorsVisible: PropTypes.bool,
    reset: PropTypes.func,
    submitting: PropTypes.bool,
    submitSucceeded: PropTypes.bool,
    dispatch: PropTypes.func,
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withApollo,
    reduxForm({
        form: 'login',
        validate: (values) => {
            // use this to check form level validation
            return {};
        },
    })
)(LoginForm);
