import React from 'react';
import { Paper } from '@material-ui/core';
import ApplicationPlaceholderIcon from '../../../../../assets/icons/user-plus-placeholder.svg';
import { sagaActionModalHide, sagaActionModalShow } from '../../../../modal/sagas/container';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import NoApplicationModal from './NoApplicationModal';

class ApplicationPlaceholder extends React.Component {
    render() {
        const {
            setModalComponent,
            setModalClose,
            interactive = false,
            lender = null,
            toggleLenderDrawer = false,
            handleFavouriteMatchChange,
            handleRemoveAlertMatchAlert,
            updateLLEStatus,
        } = this.props;
        return (
            <Paper
                elevation={0}
                onClick={() => {
                    if (true === interactive) {
                        setModalComponent(
                            <NoApplicationModal
                                lender={lender}
                                toggleLenderDrawer={toggleLenderDrawer}
                                setModalClose={setModalClose}
                                handleFavouriteMatchChange={handleFavouriteMatchChange}
                                handleRemoveAlertMatchAlert={handleRemoveAlertMatchAlert}
                                updateLLEStatus={updateLLEStatus}
                            />,
                            true
                        );
                    }
                }}
                className={`placeholder-card ${true === interactive ? 'interactive' : ''}`}
            >
                <img className={'icon'} src={ApplicationPlaceholderIcon} alt={''} />
            </Paper>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ApplicationPlaceholder);
