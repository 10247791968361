import queryString from 'query-string';
import { DEFAULT_SEARCH_PARAMS } from '../../../bootstrap/constants/constants';

const parseSearchString = (paramString) => {
    let defaultVars = DEFAULT_SEARCH_PARAMS;

    try {
        const uriVars = queryString.parse(paramString);

        if (uriVars.first) {
            uriVars.first = parseInt('' + uriVars.first);
        }

        if (uriVars.sorts) {
            uriVars.sorts = JSON.parse(uriVars.sorts);
        }

        if (uriVars.filters) {
            uriVars.filters = JSON.parse(uriVars.filters);
        }

        return { ...defaultVars, ...uriVars };
    } catch (e) {
        return defaultVars;
    }
};

/**
 * Builds a search string from tabular state. Always starts from the beginning to ensure consistency of result set
 * @param variables
 * @returns {string|*}
 */
const buildSearchString = (variables) => {
    let { first = null, sorts = null, filters = null } = variables;

    let out = {};

    if (first) {
        out.first = first;
    }

    if (sorts && sorts.length > 0) {
        out.sorts = JSON.stringify(sorts);
    }

    if (filters && filters.length > 0) {
        out.filters = JSON.stringify(filters);
    }

    return queryString.stringify(out);
};

export { buildSearchString, parseSearchString };
