import { BaseEnum } from '../../../../../pitch4_enum';
import { LOAN_TYPE_BUSINESS_FINANCE, LOAN_TYPE_PROPERTY } from '../../../pitch4admin/enum/LoanEnquiries/LoanTypeEnum';

export const STATUS_DRAFT = 'draft';
export const STATUS_PENDING = 'pending';
export const STATUS_MATCHING = 'matching';
export const STATUS_IN_PROGRESS = 'in_progress';
export const STATUS_WITHDRAWN = 'withdrawn';
export const STATUS_WITHDRAWN_FULLY = 'withdrawn_fully';
export const STATUS_WITHDRAWN_EDITED = 'withdrawn_edited';
export const STATUS_COMPLETE = 'completed';
export const STATUS_COMPLETION_REQUESTED = 'completion_requested';
export const STATUS_COMPLETION_REJECTED = 'completion_rejected';
export const STATUS_COMPLETION_TIMED_OUT = 'completion_timed_out';
export const STATUS_APPLICATION = 'application';
export const STATUS_MATCH_PENDING = 'match_pending';
export const STATUS_MATCH_REJECTED = 'match_rejected';
export const STATUS_MATCHED = 'matched';
export const STATUS_VALUATION = 'valuation';
export const STATUS_LEGALS = 'legals';
export const STATUS_DECLINED = 'lender_declined';
export const STATUS_ENDED = 'ended';
export const STATUS_ON_HOLD = 'on_hold';

class StatusEnum extends BaseEnum {
    getTypes = (loanType = LOAN_TYPE_PROPERTY) => {
        return {
            [STATUS_DRAFT]: 'Draft',
            [STATUS_PENDING]: 'Pending',
            [STATUS_MATCHING]: 'Matched',
            [STATUS_IN_PROGRESS]: 'In progress',
            [STATUS_WITHDRAWN]: 'Withdrawn',
            [STATUS_WITHDRAWN_FULLY]: 'Withdrawn Fully',
            [STATUS_WITHDRAWN_EDITED]: 'Withdrawn for Editing',
            [STATUS_COMPLETE]: 'Completed',
            [STATUS_COMPLETION_REQUESTED]: 'Completion Requested',
            [STATUS_COMPLETION_REJECTED]: 'Completion Rejected',
            [STATUS_COMPLETION_TIMED_OUT]: 'Completed',
            [STATUS_MATCH_PENDING]: 'Match Pending',
            [STATUS_MATCH_REJECTED]: 'Match Rejected',
            [STATUS_MATCHED]: 'Matched',
            [STATUS_APPLICATION]: 'Application',
            [STATUS_VALUATION]: loanType === LOAN_TYPE_BUSINESS_FINANCE ? 'Approval' : 'Valuation',
            [STATUS_LEGALS]: loanType === LOAN_TYPE_BUSINESS_FINANCE ? 'Payout' : 'Legals',
            [STATUS_COMPLETION_REQUESTED]: 'Completion Requested',
            [STATUS_COMPLETION_REJECTED]: 'Completion Rejected',
            [STATUS_COMPLETE]: 'Completed',
            [STATUS_DECLINED]: 'No Longer Available',
            [STATUS_ENDED]: 'Ended',
            [STATUS_ON_HOLD]: 'On Hold',
        };
    };

    getClosedTypes = () => {
        return [
            STATUS_WITHDRAWN,
            STATUS_WITHDRAWN_FULLY,
            STATUS_WITHDRAWN_EDITED,
            STATUS_COMPLETE,
            STATUS_COMPLETION_REQUESTED,
            STATUS_COMPLETION_REJECTED,
            STATUS_COMPLETION_TIMED_OUT,
        ];
    };

    getExpiredTypes = () => {
        return [
            STATUS_WITHDRAWN,
            STATUS_WITHDRAWN_FULLY,
            STATUS_WITHDRAWN_EDITED,
            STATUS_COMPLETION_TIMED_OUT,
            STATUS_COMPLETION_REQUESTED,
            STATUS_COMPLETION_REJECTED,
            STATUS_COMPLETE,
            STATUS_DECLINED,
            STATUS_ENDED,
        ];
    };

    getType = (type, loanType) => this.getTypes(loanType)[type];
}

export default StatusEnum;
