// see https://github.com/erikras/ducks-modular-redux

export const REDUCER_NAMESPACE = 'splash.controller';

// Actions
export const SHOW = `${REDUCER_NAMESPACE}.show`;
export const HIDE = `${REDUCER_NAMESPACE}.hide`;

const initialState = {
    show: false,
    component: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW:
            return {
                ...state,
                show: true,
                component: action.component,
            };
        case HIDE:
            return {
                ...state,
                show: false,
                component: null,
            };
        default:
            return { ...state };
    }
};

// Action creators
export const actionSplashHide = () => {
    return {
        type: HIDE,
    };
};

export const actionSplashShow = (component) => {
    return {
        type: SHOW,
        component,
    };
};

// Selectors
export const selectorSplashShow = (state) => {
    return state[REDUCER_NAMESPACE].show;
};

export const selectorSplashComponent = (state) => {
    return state[REDUCER_NAMESPACE].component;
};

// Default export reducer
export default reducer;
