import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { DataGrid } from '@material-ui/data-grid';
import { connect } from 'react-redux';
import { PAGE_SIZE, ROWS_PER_PAGE } from '../../../../../../pitch4_enum/enum/DataGridEnum';
import PlaceholderImageLayout from '../../../../../../pitch4_layout/components/content/PlaceholderImageLayout';
import renderErrorIfAuthorisationFail from '../../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SUPER_ADMIN } from '../../../../../authentication/enum/Roles/NameEnum';
import GreenCircleSvg from '../../../../../../assets/images/green-circle.svg';
import RedCircleSvg from '../../../../../../assets/images/red-circle.svg';
import BusinessStreamTypeEnum from '../../../../slam/enum/BusinessStreams/BusinessStreamTypeEnum';
import ModalLayout from '../../../../../../pitch4_modal/src/component/ModalLayout';
import { RollupCommandContainer } from '../../../../../../pitch4_tabular/components';
import { PrimaryButton } from '../../../../../../pitch4_layout/components/buttons';
import { forceMatchEnquiry } from '../../api/forceMatch';

const businessStreamTypeEnum = new BusinessStreamTypeEnum();

class MatchDebug extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDebug: props.isDebug,
            matchDebug: props.loanEnquiry.debugData,
            data: props.loanEnquiry.debugData.map((match, index) => {
                return {
                    id: match.businessStreamId,
                    lenderName: match.lenderName,
                    streamName: match.businessStreamName,
                    streamType: businessStreamTypeEnum.getType(match.type),
                    matchDebug: match.data,
                    doesMatch: match.doesMatch,
                };
            }),
        };
    }

    forceMatch = (params) => () => {
        forceMatchEnquiry(this.props.loanEnquiry.id, params.row.id).then((response) => {
            window.location.reload();
        });
    };

    openMatchingDebugModal = (matchDebug) => () => {
        const debugData = matchDebug.row.matchDebug;
        const { setModalComponent } = this.props;
        const columns = [
            { field: 'id', headerName: 'ID', width: 50 },
            { field: 'field', headerName: 'Field', width: 100 },
            { field: 'criteria', headerName: 'Matching Criteria', width: 400 },
            { field: 'loan_value', headerName: 'Enquiry Value', width: 120 },
            { field: 'match', headerName: 'Match', width: 90 },
        ];
        setModalComponent(
            <ModalLayout
                contentClassName={'match-debug'}
                title="Matching Debug"
                children={
                    <div className={'text'}>
                        <DataGrid rows={debugData} columns={columns} initialState={{}} />
                    </div>
                }
            ></ModalLayout>
        );
    };

    render() {
        let { title = 'Matching Debug' } = this.props;
        const { data } = this.state;

        return (
            <>
                <div className="leads-container data-grid-table-container" style={{ margin: 0 }}>
                    <div className={'admin-leads-container-header'}>
                        <div>{title}</div>
                    </div>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={[
                            {
                                field: 'lenderName',
                                sort: 'asc',
                            },
                        ]}
                        checkboxSelection={false}
                        className={'data-grid-no-border'}
                        pageSize={PAGE_SIZE}
                        rowsPerPageOptions={ROWS_PER_PAGE}
                        rows={data}
                        disableSelectionOnClick={true}
                        columns={[
                            {
                                field: 'doesMatch',
                                renderHeader: () => <div />,
                                headerClassName: 'data-grid-table-header-no-separator',
                                cellClassName: 'data-grid-table-cell',
                                width: 50,
                                sortable: true,
                                renderCell: (params) => {
                                    return (
                                        <img
                                            src={params.row.doesMatch === true ? GreenCircleSvg : RedCircleSvg}
                                            alt="Matches"
                                            className={'doesMatch'}
                                        />
                                    );
                                },
                                disableColumnMenu: true,
                            },
                            {
                                field: 'lenderName',
                                headerName: 'Lender Name',
                                sortable: true,
                                headerClassName:
                                    'data-grid-table-header-no-separator data-grid-table-header-fixed-height',
                                width: 400,
                                cellClassName: 'data-grid-table-cell',
                                renderCell: (params) => {
                                    return (
                                        <div className={'lender-name'}>
                                            <div>{params.row.lenderName}</div>
                                        </div>
                                    );
                                },
                            },
                            {
                                field: 'streamType',
                                headerName: 'Stream Type',
                                sortable: true,
                                headerClassName:
                                    'data-grid-table-header-no-separator data-grid-table-header-fixed-height',
                                width: 400,
                                cellClassName: 'data-grid-table-cell',
                                renderCell: (params) => {
                                    return (
                                        <div className={'lender-name'}>
                                            <div>{params.row.streamType}</div>
                                        </div>
                                    );
                                },
                            },
                            {
                                field: 'streamName',
                                headerName: 'Stream Name',
                                sortable: true,
                                headerClassName:
                                    'data-grid-table-header-no-separator data-grid-table-header-fixed-height',
                                width: 400,
                                cellClassName: 'data-grid-table-cell',
                                renderCell: (params) => {
                                    return (
                                        <div className={'lender-name'}>
                                            <div>{params.row.streamName}</div>
                                        </div>
                                    );
                                },
                            },
                            {
                                field: 'actions',
                                disableSelectionOnClick: true,
                                cellClassName: 'le-table-cell le-table-cell-content-right',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                renderHeader: () => <div />,
                                renderCell: (params) => {
                                    return (
                                        <div className={'le-table-cell-context-menu'} style={{ marginTop: 10 }}>
                                            <RollupCommandContainer>
                                                <PrimaryButton onClick={this.openMatchingDebugModal(params)}>
                                                    View
                                                </PrimaryButton>
                                                {this.state.isDebug === true && (
                                                    <PrimaryButton onClick={this.forceMatch(params)}>
                                                        Force Match
                                                    </PrimaryButton>
                                                )}
                                            </RollupCommandContainer>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                    {this.state.data.length === 0 && (
                        <PlaceholderImageLayout classNames={'empty-table-placeholder'}>
                            <div>
                                <h3 className={'typography-card-title'}>Unable to load match debug</h3>
                                <p className={'typography-default'}>We've been unable to load any debug data.</p>
                            </div>
                        </PlaceholderImageLayout>
                    )}
                </div>
            </>
        );
    }
}

MatchDebug.propTypes = {
    matchDebug: PropTypes.array.isRequired,
    loanEnquiryStatus: PropTypes.string,
    isDebug: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
    };
};

MatchDebug.propTypes = {
    matchDebug: PropTypes.array.isRequired,
    loanEnquiryStatus: PropTypes.string,
    isDebug: PropTypes.bool,
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(MatchDebug);
