import React from 'react';
import PropTypes from 'prop-types';
import { P4StyleComponentContainer } from '../div';

class SingleFormContentLayout extends React.Component {
    render() {
        let { label, content } = this.props;
        return <P4StyleComponentContainer header={label} content={content} />;
    }
}

SingleFormContentLayout.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.object.isRequired,
};

export default SingleFormContentLayout;
