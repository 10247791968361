import { call, put, takeLatest } from 'redux-saga/effects';
import {
    actionHubspotConversationsClosed,
    actionHubspotConversationsLoaded,
    actionHubspotConversationsOpened,
    actionHubspotConversationsRefreshed,
    actionHubspotConversationsRemoved,
    HUBSPOT_CONVERSATIONS_CLOSE,
    HUBSPOT_CONVERSATIONS_LOAD,
    HUBSPOT_CONVERSATIONS_OPEN,
    HUBSPOT_CONVERSATIONS_REFRESH,
    HUBSPOT_CONVERSATIONS_REMOVE,
} from '../redux/conversations';

export const SAGA_NAMESPACE = 'hubspot.conversations';

/**
 * Run the given function with the given arguments when the Conversations SDK has loaded.
 *
 * @param func
 * @param arrayArgs
 */
const runOnReady = (func, arrayArgs) => {
    let args = arrayArgs || [];

    // If external API methods are already available, use them.
    if (window.HubSpotConversations) {
        func(...args);
    } else {
        //  Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
        // These callbacks will be called once the external API has been initialized.
        window.hsConversationsOnReady = [
            () => {
                func(...args);
            },
        ];
    }
};

function* openConversationWidget() {
    yield call(runOnReady, () => {
        window.HubSpotConversations.widget.open();
    });

    yield put(actionHubspotConversationsOpened());
}

function* closeConversationWidget() {
    yield call(runOnReady, () => {
        window.HubSpotConversations.widget.close();
    });

    yield put(actionHubspotConversationsClosed());
}

function* removeConversationWidget() {
    yield call(runOnReady, () => {
        window.HubSpotConversations.widget.remove();
    });

    yield put(actionHubspotConversationsRemoved());
}

function* loadConversationWidget() {
    yield call(runOnReady, () => {
        window.HubSpotConversations.widget.load();
    });

    yield put(actionHubspotConversationsLoaded());
}

function* refreshConversationWidget() {
    yield call(runOnReady, () => {
        const status = window.HubSpotConversations.widget.status();

        if (status.loaded) {
            window.HubSpotConversations.widget.refresh();
        } else {
            window.HubSpotConversations.widget.load();
        }
    });

    yield put(actionHubspotConversationsRefreshed());
}

// Main Saga
function* main() {
    yield takeLatest(HUBSPOT_CONVERSATIONS_OPEN, openConversationWidget);
    yield takeLatest(HUBSPOT_CONVERSATIONS_CLOSE, closeConversationWidget);
    yield takeLatest(HUBSPOT_CONVERSATIONS_REMOVE, removeConversationWidget);
    yield takeLatest(HUBSPOT_CONVERSATIONS_LOAD, loadConversationWidget);
    yield takeLatest(HUBSPOT_CONVERSATIONS_REFRESH, refreshConversationWidget);
}

export default main;
