import React, { useState } from 'react';
import TextField from '../../redux-form-material-ui/TextField';
import { Button } from '@material-ui/core';
import { slugify } from '../../pitchblack_react_utils/text/Slugify';
import { presetTagColors } from '../../pitch4_enum/enum/LETagPresetColors';
import { HexColorPicker } from 'react-colorful';
import { textContrastColor } from '../../pitchblack_react_utils/color/Contrast';
import { addLETags } from '../../pitch4_api/add_le_tags';

export default function LETagProperties(props) {
    const {
        tag,
        setModalClose,
        selectedTags,
        availableTags,
        uri,
        leId,
        updateSelectedTags,
        updateAvailableTags,
    } = props;
    const [color, setColor] = useState(presetTagColors[0]);
    const [activeElementId, setActiveElementId] = useState(0);
    const [submitting, setSubmitting] = useState(false);
    const [newTag, setNewTag] = useState(tag);
    const [colorPickerVisible, setColorPickerVisible] = useState(false);
    const handleNewTagChange = (newTag) => setNewTag(newTag);
    const handleColorChange = (color) => setColor(color);
    const handleSubmit = () => {
        const tag = [{ value: slugify(newTag), label: newTag, color: color, isNew: true }];
        const newLETags = selectedTags.concat(tag);
        const newAvailableTags = availableTags.concat(tag);
        setSubmitting(true);

        addLETags(uri, leId, tag).then(() => {
            updateSelectedTags(newLETags);
            updateAvailableTags(newAvailableTags);
            setSubmitting(false);
            setModalClose();
        });
    };

    return (
        <div className={'tag-properties-container'}>
            <h2 className={'header'}>Set tag properties</h2>
            <TextField
                className={'tag-label'}
                id="tag-label"
                variant="outlined"
                value={newTag}
                onChange={(event) => handleNewTagChange(event.target.value)}
                autoFocus={true}
            />
            <div className="picker__swatches">
                {presetTagColors.map((presetColor, index) => (
                    <button
                        key={index}
                        className={`picker__swatch ${
                            index !== activeElementId || !presetColor.includes(color) ? '' : 'active'
                        }`}
                        style={{ background: presetColor }}
                        onClick={() => {
                            setActiveElementId(index);
                            handleColorChange(presetColor);
                        }}
                    />
                ))}
            </div>
            <div className={`color-picker-container ${true === colorPickerVisible ? 'visible' : ''}`}>
                <HexColorPicker
                    className={'color-picker'}
                    color={color}
                    onChange={(color) => handleColorChange(color)}
                />
                <div
                    className="color-value"
                    style={{ backgroundColor: color, color: textContrastColor(color, ['#000000', '#ffffff']) }}
                >
                    Current color is {color}
                </div>
            </div>
            <div className={'more-colors-container'}>
                <div onClick={() => setColorPickerVisible(!colorPickerVisible)} className={'link-more-colors'}>
                    {false === colorPickerVisible ? 'More' : 'Less'} colours
                </div>
            </div>
            <div className={'actions'}>
                <Button
                    className={'btn-cancel'}
                    variant={'contained'}
                    color={'default'}
                    onClick={() => setModalClose()}
                >
                    Cancel
                </Button>
                <Button
                    className={'btn-submit'}
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => handleSubmit()}
                    disabled={submitting || newTag === ''}
                >
                    Save
                </Button>
            </div>
        </div>
    );
}
