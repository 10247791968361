import React from 'react';
import PropTypes from 'prop-types';
import HelpIcon from '@material-ui/icons/Help';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import { Container, Item } from '../../../pitch4_layout/components/div';

export const NOTICE_TYPE_DEFAULT = 'notice-default';
export const NOTICE_TYPE_INFO = 'notice-info';
export const NOTICE_TYPE_WARNING = 'notice-warning';
export const NOTICE_TYPE_ERROR = 'notice-error';
export const NOTICE_TYPE_SUCCESS = 'notice-success';
export const NOTICE_ALL_TYPES = [
    NOTICE_TYPE_DEFAULT,
    NOTICE_TYPE_INFO,
    NOTICE_TYPE_WARNING,
    NOTICE_TYPE_ERROR,
    NOTICE_TYPE_SUCCESS,
];

class Notice extends React.Component {
    render() {
        let {
            message,
            className,
            displayIcon = true,
            customIcon = null,
            type = NOTICE_TYPE_DEFAULT,
            messageHeader = false,
            customIconColor = null,
            style = {},
        } = this.props;

        if (NOTICE_ALL_TYPES.indexOf(type) === -1) {
            type = NOTICE_TYPE_DEFAULT;
        }

        if (className) {
            className += ' notice';
        } else {
            className = 'notice';
        }

        className += ' ' + type;

        return (
            <div className={className} style={style}>
                <Container spacing={1} alignItems={`center`}>
                    {displayIcon && (
                        <Item
                            xs={false}
                            sm={1}
                            className={`notice-icon`}
                            style={{ color: customIconColor || '#606060' }}
                        >
                            {null === customIcon ? this.getIcon(type) : customIcon}
                        </Item>
                    )}
                    {messageHeader && (
                        <Item xs={12} sm={11} className={`notice-header`}>
                            {messageHeader}
                        </Item>
                    )}
                    <Item xs={12} sm={11} className={`notice-message`}>
                        {message}
                    </Item>
                </Container>
            </div>
        );
    }

    getIcon(type) {
        switch (type) {
            case NOTICE_TYPE_INFO:
                return <InfoIcon />;

            case NOTICE_TYPE_WARNING:
                return <WarningIcon />;

            case NOTICE_TYPE_SUCCESS:
                return <CheckCircleIcon />;

            case NOTICE_TYPE_ERROR:
                return <ErrorIcon />;

            default:
                return <HelpIcon />;
        }
    }
}

Notice.propTypes = {
    message: PropTypes.string.isRequired,
    className: PropTypes.string,
    type: PropTypes.string,
    displayIcon: PropTypes.bool,
};

export default Notice;
