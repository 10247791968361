import { call, put, takeLatest } from 'redux-saga/effects';
import { FLAG_PASSWORD_RESET_REQUESTED, FLAG_PASSWORD_RESET_REQUESTED_SUCCESSFULLY } from '../redux/flagPasswordReset';
import { flagPasswordReset } from '../api/flag_password_reset';

//Worker Sagas
function* handleRequestForgottenPassword(action) {
    yield call(flagPasswordReset, action.email);
    yield put({ type: FLAG_PASSWORD_RESET_REQUESTED_SUCCESSFULLY });
}

//Main Saga
function* main() {
    yield takeLatest(FLAG_PASSWORD_RESET_REQUESTED, handleRequestForgottenPassword);
}

export default main;
