/**
 * SECTIONS
 */
export const SECTION_SUBMIT = 'Submit';
export const SECTION_APPLICANTS = 'Applicants';

/**
 * FIELD TYPES
 */
export const FIELD_TYPE_CURRENCY = 'currency';
export const FIELD_TYPE_ENUM = 'enum';
export const FIELD_TYPE_TEXT = 'text';
export const FIELD_TYPE_INTEGER = 'integer';
export const FIELD_TYPE_PERCENT = 'percent';
export const FIELD_TYPE_DATE = 'date';
