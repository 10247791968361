import {
    STATUS_APPLICATION,
    STATUS_DRAFT,
    STATUS_IN_PROGRESS,
    STATUS_LEGALS,
    STATUS_MATCH_PENDING,
    STATUS_MATCHED,
    STATUS_VALUATION,
} from '../../enum/LoanEnquiries/StatusEnum';

export const defaultFilters = () => [
    {
        operation: 'eq',
        operand: STATUS_MATCHED,
        column: 'status',
        not: false,
    },
    {
        operation: 'eq',
        operand: STATUS_IN_PROGRESS,
        column: 'status',
        not: false,
    },
    {
        operation: 'eq',
        operand: STATUS_APPLICATION,
        column: 'status',
        not: false,
    },
    {
        operation: 'eq',
        operand: STATUS_VALUATION,
        column: 'status',
        not: false,
    },
    {
        operation: 'eq',
        operand: STATUS_LEGALS,
        column: 'status',
        not: false,
    },
    {
        operation: 'eq',
        operand: STATUS_IN_PROGRESS,
        column: 'loanEnquiryStatus',
        not: false,
    },
    {
        operation: 'eq',
        operand: STATUS_APPLICATION,
        column: 'loanEnquiryStatus',
        not: false,
    },
    {
        operation: 'eq',
        operand: STATUS_VALUATION,
        column: 'loanEnquiryStatus',
        not: false,
    },
    {
        operation: 'eq',
        operand: STATUS_LEGALS,
        column: 'loanEnquiryStatus',
        not: false,
    },
];

export const defaultInactiveFilters = () => [
    {
        operation: 'eq',
        operand: STATUS_MATCHED,
        column: 'status',
        not: true,
    },
    {
        operation: 'eq',
        operand: STATUS_DRAFT,
        column: 'status',
        not: true,
    },
    {
        operation: 'eq',
        operand: STATUS_MATCH_PENDING,
        column: 'status',
        not: true,
    },
    {
        operation: 'eq',
        operand: STATUS_IN_PROGRESS,
        column: 'status',
        not: true,
    },
    {
        operation: 'eq',
        operand: STATUS_APPLICATION,
        column: 'status',
        not: true,
    },
    {
        operation: 'eq',
        operand: STATUS_VALUATION,
        column: 'status',
        not: true,
    },
    {
        operation: 'eq',
        operand: STATUS_LEGALS,
        column: 'status',
        not: true,
    },
    {
        operation: 'eq',
        operand: STATUS_IN_PROGRESS,
        column: 'loanEnquiryStatus',
        not: true,
    },
    {
        operation: 'eq',
        operand: STATUS_APPLICATION,
        column: 'loanEnquiryStatus',
        not: true,
    },
    {
        operation: 'eq',
        operand: STATUS_VALUATION,
        column: 'loanEnquiryStatus',
        not: true,
    },
    {
        operation: 'eq',
        operand: STATUS_LEGALS,
        column: 'loanEnquiryStatus',
        not: true,
    },
];

export const defaultSorts = () => [
    {
        column: 'favourite',
        direction: 'desc',
    },
    {
        column: 'createdAt',
        direction: 'desc',
    },
];
