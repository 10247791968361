import ValidationResultFactory from '../../../../../pitch4_validation/validation/ValidationResultFactory';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });

const channelMessagesQuery = `
    query getChannelMessages($id: ID!) {
      channelMessages(id: $id) {
        id
        senderId
        type
        category
        userProfile{
          given
        }
        userRole
        content
        sentAt
      }
    }
`;

const channelLEMessagesQuery = `
    query getChannelMessages($id: ID, $loanEnquiryId: ID) {
      channelMessages(id: $id, loanEnquiryId: $loanEnquiryId) {
        id
        senderId
        type
        category
        userProfile{
          given
        }
        userRole
        content
        sentAt
      }
    }
`;

const errorFactory = new ValidationResultFactory();

class ChannelMessages {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class ChannelMessagesErrors extends ChannelMessages {
    constructor(errors) {
        super('ChannelMessagesErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class ChannelMessagesSuccess extends ChannelMessages {
    constructor(data) {
        super('ChannelMessagesSuccess');
        this.channelMessages = data;
    }

    getChannelMessages = () => {
        return this.channelMessages;
    };
}

export const getChannelMessages = (channelId, loanEnquiryId = null) => {
    let resp = null;

    if (loanEnquiryId) {
        resp = apolloFetch({
            query: channelLEMessagesQuery,
            pollInterval: 10000,
            variables: {
                id: channelId,
                loanEnquiryId: loanEnquiryId,
            },
        });
    } else {
        resp = apolloFetch({
            query: channelMessagesQuery,
            pollInterval: 10000,
            variables: {
                id: channelId,
            },
        });
    }

    return resp
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new ChannelMessagesErrors(vR);
            }

            return new ChannelMessagesSuccess(data.channelMessages);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new ChannelMessagesErrors(vR);
        });
};
