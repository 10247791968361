import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';

import { PrimaryButton } from 'pitch4_layout/components/buttons';

class DeleteButton extends React.Component {
    render() {
        let { inputRef = () => null, children } = this.props;

        return (
            <PrimaryButton inputRef={inputRef} onClick={this.onClick}>
                {children}
            </PrimaryButton>
        );
    }

    onClick = () => {
        let { mutate, outerRefetch, id, notificationSuccess, notificationError } = this.props;

        return mutate({
            variables: {
                id: id,
            },
        })
            .then(() => {
                notificationSuccess('Inbox criteria(s) removed');
                outerRefetch();
            })
            .catch((error) => {
                notificationError('An error occurred. Please contact support.');
            });
    };
}

DeleteButton.propTypes = {
    id: PropTypes.string.isRequired,
    mutate: PropTypes.func.isRequired,
    outerRefetch: PropTypes.func.isRequired,
    inputRef: PropTypes.any,
};

const DeleteMutation = gql`
    mutation deleteBusinessStream($id: ID!) {
        deleteBusinessStream(id: $id) {
            deleted
        }
    }
`;

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(DeleteMutation, {
        options: {
            context: {
                uri: SLAM_URL,
            },
        },
    })
)(DeleteButton);
