// see https://github.com/erikras/ducks-modular-redux
export const REDUCER_NAMESPACE = 'notifications';

// Actions
export const SET_LEVEL = `${REDUCER_NAMESPACE}.set_level`;
export const SET_VISIBILITY = `${REDUCER_NAMESPACE}.set_visible`;

//Reducer
const initialState = {
    level: null,
    visible: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LEVEL:
            return {
                ...state,
                level: action.level,
            };
        case SET_VISIBILITY:
            return {
                ...state,
                visible: action.visible,
            };
        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;

//Actions
export const actionNotifcationSetVisibility = (visible) => {
    return {
        type: SET_VISIBILITY,
        visible,
    };
};

export const actionNotifcationSetLevel = (level) => {
    return {
        type: SET_LEVEL,
        level,
    };
};

//Selectors
export const selectorNotifcationVisibilty = (state) => {
    return state[REDUCER_NAMESPACE].visible;
};

export const selectorNotifcationLevel = (state) => {
    return state[REDUCER_NAMESPACE].level;
};
