import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { getAssignableUsers } from '../../pitch4_api/get_assignable_users';
import _ from 'lodash';

export default function AssigneeFilter(props) {
    const { assigneeFilter, handleAssigneeFilterChange, uri } = props;
    const [assignableUsers, setAssignableUsers] = useState([]);

    useEffect(() => {
        getAssignableUsers(uri).then((resp) => {
            if (!_.isEmpty(resp)) {
                resp.unshift({ id: 'no_owner', name: 'No owner' });
                setAssignableUsers(resp);
            }
        });
    }, []);

    return (
        <FormControl variant="outlined" className={'select-assignee-filter'}>
            <InputLabel id="select-status-label">
                <strong>Owner</strong> Filter
            </InputLabel>
            <Select
                labelId="select-assignee-filter-label"
                id="select-assignee-filter"
                name={'assigneeFilter'}
                label={'Owner Filter'}
                value={assigneeFilter}
                onChange={handleAssigneeFilterChange}
                multiple={false}
            >
                {assignableUsers.map((assignee) => (
                    <MenuItem value={assignee.id} key={assignee.id}>
                        {assignee.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
