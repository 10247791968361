import StreamsLoanTypes from './StreamsLoanTypes';

export function calculateLoanType(loanEnquiryData) {
    const streamLoanTypes = new StreamsLoanTypes();
    let prefix = streamLoanTypes.convertToStreamType(loanEnquiryData.loanType);
    let suffix = '';
    let fullLoanType = '';
    if (loanEnquiryData.fundingType != null) {
        suffix = streamLoanTypes.convertToStreamType(
            loanEnquiryData.fundingType,
            loanEnquiryData.propertyType,
            loanEnquiryData.businessSecuredFinance
        );
    }
    fullLoanType = prefix + suffix;

    let result = streamLoanTypes.getOptions()[fullLoanType];
    if (!result) {
        result = { value: 'unknown', label: 'Unknown' };
    }
    return result;
}
