import { BaseEnum } from '../../pitch4_enum';
import {
    LOAN_TYPE_BUSINESS_FINANCE,
    LOAN_TYPE_PROPERTY,
} from '../../apps/admin/pitch4admin/enum/LoanEnquiries/LoanTypeEnum';

export const STATUS_PENDING = 'STATUS_PENDING';
export const STATUS_APPROVED = 'STATUS_APPROVED';
export const STATUS_FINALISED = 'STATUS_FINALISED';
export const STATUS_IGNORED = 'STATUS_IGNORED';
export const STATUS_MATCH_PENDING = 'match_pending';
export const STATUS_MATCH_REJECTED = 'match_rejected';
export const STATUS_MATCHED = 'matched';
export const STATUS_IN_PROGRESS = 'in_progress';
export const STATUS_APPLICATION = 'application';
export const STATUS_VALUATION = 'valuation';
export const STATUS_LEGALS = 'legals';
export const STATUS_COMPLETE = 'completed';
export const STATUS_COMPLETION_REQUESTED = 'completion_requested';
export const STATUS_COMPLETION_REJECTED = 'completion_rejected';
export const STATUS_DECLINED = 'lender_declined';
export const STATUS_ENDED = 'ended';
export const STATUS_WITHDRAWN = 'withdrawn';
export const STATUS_ON_HOLD = 'on_hold';

class LenderLoanEnquiryStatusEnum extends BaseEnum {
    getTypes = (loanType = LOAN_TYPE_PROPERTY) => {
        return {
            [STATUS_PENDING]: 'Pending',
            [STATUS_APPROVED]: 'Approved',
            [STATUS_FINALISED]: 'Finalised',
            [STATUS_IGNORED]: 'Ignored',
            [STATUS_MATCH_REJECTED]: 'Match Rejected',
            [STATUS_MATCH_PENDING]: 'Match Pending',
            [STATUS_MATCHED]: 'Matched',
            [STATUS_IN_PROGRESS]: 'In-progress',
            [STATUS_APPLICATION]: 'Application',
            [STATUS_VALUATION]: loanType === LOAN_TYPE_BUSINESS_FINANCE ? 'Approval' : 'Valuation',
            [STATUS_LEGALS]: loanType === LOAN_TYPE_BUSINESS_FINANCE ? 'Payout' : 'Legals',
            [STATUS_COMPLETE]: 'Completed',
            [STATUS_COMPLETION_REQUESTED]: 'Completion Requested',
            [STATUS_COMPLETION_REJECTED]: 'Completion Rejected',
            [STATUS_DECLINED]: 'Declined',
            [STATUS_ENDED]: 'Ended',
            [STATUS_WITHDRAWN]: 'Withdrawn',
            [STATUS_ON_HOLD]: 'On Hold',
        };
    };

    getCompletionAllowedTypes = () => {
        return [STATUS_VALUATION, STATUS_LEGALS];
    };

    getType = (type, loanType) => this.getTypes(loanType)[type];
}

export default LenderLoanEnquiryStatusEnum;
