import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { API_URL } from 'bootstrap/constants/constants';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../../notifications/sagas/container';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import styled from 'styled-components';
import SessionManager from 'pitchblack_react_utils/session/SessionManager';
import { sagaActionRedirectToDashboard } from '../../../../../authentication/sagas/dashboard';

const StyledPrimaryButton = styled(PrimaryButton)`
    min-width: 80px !important;
`;

class AssumeIdentityButton extends React.Component {
    render() {
        let { disabled = false, useLabel = 'Admin Assist', inputRef = () => null, children } = this.props;

        return (
            <StyledPrimaryButton inputRef={inputRef} disabled={disabled} onClick={this.onClick}>
                {useLabel}
                {children}
            </StyledPrimaryButton>
        );
    }

    onClick = () => {
        let { mutate, id, notificationSuccess, notificationError, redirectToRoleDashboard } = this.props;

        return mutate({
            variables: {
                input: {
                    user: id,
                },
            },
        })
            .then(
                ({
                    data: {
                        createAssumedIdentity: {
                            assumedIdentity: { token, session },
                        },
                    },
                }) => {
                    const sessionManager = new SessionManager();
                    sessionManager.assumedIdentity(token, session);
                    notificationSuccess('Admin assist session started');
                    redirectToRoleDashboard();
                }
            )
            .catch((error) => {
                notificationError('An error occurred. Please contact support.');
            });
    };
}

AssumeIdentityButton.propTypes = {
    mutate: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    useLabel: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    notificationSuccess: PropTypes.func.isRequired,
    notificationError: PropTypes.func.isRequired,
    children: PropTypes.any,
    inputRef: PropTypes.any,
};

const AssumeIdentityGql = gql`
    mutation CreateAssumedIdentity($input: pitch4admin_createAssumedIdentityInput!) {
        createAssumedIdentity(input: $input) {
            assumedIdentity {
                token {
                    token
                    expires
                }
                session {
                    id
                    roles {
                        name
                        label
                    }
                    title
                    given
                    family
                    authExpiry
                    supplementaryData
                }
            }
        }
    }
`;

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
        redirectToRoleDashboard: () => {
            dispatch(sagaActionRedirectToDashboard());
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(AssumeIdentityGql, {
        options: {
            context: {
                uri: API_URL,
            },
        },
    })
)(AssumeIdentityButton);
