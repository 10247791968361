import React from 'react';
import { Grid } from '@material-ui/core';
import UnassignedEnquiries from './UnassignedEnquiries';
import MyAssignedEnquiries from './MyAssignedEnquiries';
import MyFavouritedEnquiries from './MyFavouritedEnquiries';
import EnquiriesWithUpdates from './EnquiriesWithUpdates';
import NewAccounts from './NewAccounts';

export default function ListsContainer(props) {
    const {
        dispatch,
        setLEsWithUpdatesCount,
        setMyFavouritedEnquiriesCount,
        setNewAccountsCount,
        listExpanded,
        setListExpanded,
        setUnassignedEnquiriesCount,
        setAssignedEnquiriesCount,
    } = props;
    const handleChange = (panel) => (event, isExpanded) => {
        setListExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid container className={'lists-container'} spacing={3}>
            <Grid item lg md sm xs>
                <UnassignedEnquiries
                    handleChange={handleChange}
                    expanded={listExpanded}
                    dispatch={dispatch}
                    setUnassignedEnquiriesCount={setUnassignedEnquiriesCount}
                />
                <MyAssignedEnquiries
                    handleChange={handleChange}
                    expanded={listExpanded}
                    dispatch={dispatch}
                    setAssignedEnquiriesCount={setAssignedEnquiriesCount}
                />
                <MyFavouritedEnquiries
                    handleChange={handleChange}
                    expanded={listExpanded}
                    dispatch={dispatch}
                    setMyFavouritedEnquiriesCount={setMyFavouritedEnquiriesCount}
                />
                <EnquiriesWithUpdates
                    handleChange={handleChange}
                    expanded={listExpanded}
                    dispatch={dispatch}
                    setLEsWithUpdatesCount={setLEsWithUpdatesCount}
                />
                <NewAccounts
                    handleChange={handleChange}
                    expanded={listExpanded}
                    dispatch={dispatch}
                    setNewAccountsCount={setNewAccountsCount}
                />
            </Grid>
        </Grid>
    );
}
