import { BaseEnum } from 'pitch4_enum';

class DynamicEnum extends BaseEnum {
    constructor() {
        super();
        this.types = [];
    }

    getTypes = () => {
        return this.types;
    };

    setTypes = (types) => {
        this.types = types;
        return this;
    };
}

export default DynamicEnum;
