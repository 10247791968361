import {
    ROLE_ADMIN,
    ROLE_BORROWER,
    ROLE_INTERMEDIARY,
    ROLE_SLAM,
    ROLE_SUPER_ADMIN,
} from '../../apps/authentication/enum/Roles/NameEnum';

class RoleUtil {
    /**
     * Given an array of roles (as returned from the API), return the most senior role.
     *
     * Roles must be an array of objects like: [{name: ROLE_ADMIN}, {name: ROLE_SLAM}]
     *
     * @param roles
     * @returns {*}
     */
    getMostSeniorRole = (roles) => {
        if (!roles) {
            return null;
        }

        let mostSeniorRole = null;

        const orderedRolesBySeniority = [ROLE_BORROWER, ROLE_SLAM, ROLE_INTERMEDIARY, ROLE_ADMIN, ROLE_SUPER_ADMIN];

        roles.forEach((role) => {
            if (
                mostSeniorRole === null ||
                orderedRolesBySeniority.indexOf(role.name) > orderedRolesBySeniority.indexOf(mostSeniorRole.name)
            ) {
                mostSeniorRole = role;
            }
        });

        return mostSeniorRole;
    };
}

export default RoleUtil;
