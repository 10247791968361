import React from 'react';
import _ from 'lodash';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import StepButton from '@material-ui/core/StepButton';
import { FormSection } from 'redux-form';
import PropTypes from 'prop-types';
import { BasicFormContentLayout } from '../../../pitch4_layout/components/content';
import { Container } from '../../../pitch4_layout/components/div';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import { FieldButtonPanelRow } from '../../../pitch4_forms/components/inputs';
import { SECTION_SUBMIT } from '../constants';
import { isEmpty } from '../../../pitch4_validation';
import { wait } from '../../utils/wait';
import { scrollTo } from '../../utils/scrollTo';
import withSession from '../../../pitchblack_react_utils/session/withSession';
import { STATUS_DRAFT } from '../../../pitch4_enum/enum/LoanEnquiryStatusEnum';
import TelephoneHelperBox from './TelephoneHelperBox';
import { ROLE_BORROWER } from '../../../pitch4_enum/enum/RoleNameEnum';
import AdditionalInformationHelperBox from './AdditionalInformationHelperBox';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';

const activeRef = React.createRef();

class WizardController extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentStep: 0,
            previousStep: 0,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        window.onpopstate = (e) => {
            const url = window.location;
            const { currentStep } = this.state;
            const urlStep = parseInt(url.hash.substring(1));

            if (urlStep < currentStep) {
                this.handleGoBack()();
            }
        };
    }

    componentDidMount() {
        const { nextPage } = this.props;

        // Set initial step for back button functionality
        const history = window.history;
        history.pushState({}, 'form', '#0');

        nextPage();
    }

    componentWillUnmount() {
        const { resetForm } = this.props;

        resetForm();
    }

    render() {
        const { currentForm, formStack, goBackTo, formHeadings, formName, skipTo, config } = this.props;

        const steps = this.getSteps();
        const onExited = this.onExited;
        const activeStep = _.indexOf(steps, currentForm);
        return (
            <Stepper className={'stepper'} activeStep={activeStep} orientation="vertical">
                {_.map(steps, (section) => {
                    const completed = _.indexOf(formStack, section) >= 0 && formStack[formStack.length - 1] !== section;

                    return (
                        <Step
                            ref={currentForm === section ? activeRef : null}
                            completed={completed}
                            key={section}
                            className={section + '-section'}
                        >
                            {completed === true && (
                                <StepButton id={section + 'Section'} onClick={() => goBackTo(section)}>
                                    {formHeadings[section]}
                                </StepButton>
                            )}
                            {completed === false && (
                                <StepLabel id={section + 'Section'}>{formHeadings[section]}</StepLabel>
                            )}
                            <StepContent TransitionProps={{ onExited: onExited }}>
                                {currentForm === section &&
                                    (formName === 'slam-create-business-stream' ||
                                        formName === 'slam-edit-business-stream') && (
                                        <TelephoneHelperBox
                                            skipTo={skipTo}
                                            section={section}
                                            formHeadings={formHeadings}
                                            config={config}
                                        />
                                    )}
                                {currentForm === 'AdditionalInformation' && <AdditionalInformationHelperBox />}
                                {this.renderForm(section)}
                            </StepContent>
                        </Step>
                    );
                })}
            </Stepper>
        );
    }

    onExited = (isAppearing) => {
        if (isAppearing) {
            wait(100).then(function () {
                scrollTo(activeRef.current.offsetTop - 10, null, 500);
            });
        }
    };

    getSteps = () => {
        const { formValues, stepsTriggerField, fieldValueSelector, stepsMap } = this.props;

        const determinedSections = this.props.determinedSections.map((section) => section.name);

        for (let section of determinedSections) {
            const stepsTrigger = _.get(formValues, [section, stepsTriggerField]);
            const stepsTriggerValue = fieldValueSelector(stepsTrigger);

            if (_.get(stepsMap, [stepsTriggerValue]) !== undefined) {
                return _.get(stepsMap, [stepsTriggerValue]);
            }
        }

        return [...determinedSections, SECTION_SUBMIT];
    };

    renderSection = (section) => {
        const { startSection, config, formSectionRender } = this.props;

        if (isEmpty(section)) {
            section = startSection;
        }

        let props = {
            ...this.props,
            sectionName: section,
            sectionConfig: config['sections'][section],
        };

        return formSectionRender(props);
    };

    nextPage = (values) => {
        const { nextPage, currentForm } = this.props;
        const sessionManager = new SessionManager();
        const userRole = sessionManager.getMostSeniorRole().label;
        const userEmail = sessionManager.getSession().getEmail();

        if (currentForm === SECTION_SUBMIT) {
            return this.submit(values);
        }

        // Handling go back button browser's functionality
        const history = window.history;
        const { currentStep } = this.state;
        const newCurrentStep = currentStep + 1;
        const newPreviousStep = newCurrentStep - 1 < 0 ? 0 : newCurrentStep - 1;

        if (newCurrentStep >= 2) {
            if ('prod' === window._env_.APP_ENV) {
                window.dataLayer.push({
                    event: 'enquiry_creation_step_completed',
                    userDetails: {
                        email: userEmail,
                        userType: userRole,
                        userID: sessionManager.getSession().getId(),
                        timestamp: new Date().toISOString(),
                    },
                    step: newCurrentStep,
                });
            }
        }

        this.setState({
            previousStep: newPreviousStep,
            currentStep: newCurrentStep,
        });

        history.pushState({}, 'form', '#' + newCurrentStep);

        return nextPage(values);
    };

    submit = (values) => {
        const { submitFormHandler, configFormVersion, loanEnquiry } = this.props;

        const pruned = this.getSubmitHandler()(values);
        if (loanEnquiry) {
            let publishFromDraft = false;
            let loanEnquiryId = '';
            if (loanEnquiry !== '' && loanEnquiry.status === STATUS_DRAFT) {
                publishFromDraft = true;
                loanEnquiryId = loanEnquiry.id;
            }

            return submitFormHandler(pruned, configFormVersion, publishFromDraft, loanEnquiryId);
        } else {
            let loanEnquiryId = null;
            if (typeof loanEnquiry !== 'undefined' && loanEnquiry !== '') {
                loanEnquiryId = loanEnquiry.id;
            }
            return submitFormHandler(pruned, configFormVersion, false, loanEnquiryId);
        }
    };

    submitDraft = (values) => {
        const { draftSubmitHandler, configFormVersion } = this.props;
        const pruned = this.getSubmitHandler()(values);
        return draftSubmitHandler()(pruned, configFormVersion);
    };

    getSubmitHandler = () => {
        const { preSubmitHandler } = this.props;

        return !isEmpty(preSubmitHandler) ? preSubmitHandler : this.defaultPreSubmitHandler;
    };

    defaultPreSubmitHandler = (values) => {
        const { formStack } = this.props;

        if (!values) {
            return [];
        }

        // Remove forms that aren't specific to a submission
        const formSubmissionSections = formStack.filter((item) => [SECTION_SUBMIT].indexOf(item) === -1);

        // Remove any form sections that aren't in the path that the user ended up taking.
        return Object.keys(values)
            .filter((key) => formSubmissionSections.includes(key))
            .reduce((obj, key) => {
                return {
                    ...obj,
                    [key]: this.mapFactsForSection(values[key]),
                };
            }, {});
    };

    showDraftButton = () => {
        const { formValues = {}, formStack = [] } = this.props;
        return (
            typeof formValues.LoanType !== 'undefined' &&
            typeof formValues.Applicants !== 'undefined' &&
            formStack.length !== 1
        );
    };

    mapFactsForSection = (sectionData) => {
        let mapped = {};

        Object.keys(sectionData).forEach((factName) => {
            mapped[factName] = {
                column: factName,
                value: _.isArray(sectionData[factName]) ? sectionData[factName] : [sectionData[factName]],
            };
        });

        return mapped;
    };

    isUserVerified = () => {
        const { session } = this.props;
        return session.getRelevantSession().isVerified();
    };

    getUserType = () => {
        const { session } = this.props;
        return session.getRelevantSession().getMostSeniorRole().name;
    };

    // Handling bo back browser functionality
    handleGoBack = () => () => {
        const history = window.history;
        const { goBack } = this.props;
        const { currentStep } = this.state;
        const newCurrentStep = currentStep - 1;
        const newPreviousStep = newCurrentStep - 1 < 0 ? 0 : newCurrentStep - 1;

        this.setState({
            previousStep: newPreviousStep,
            currentStep: newCurrentStep,
        });

        history.pushState({}, 'form', '#' + newCurrentStep);

        goBack();
    };

    disableSaveDraft = (formValues) => {
        if ('LoanType' in formValues) {
            // Prevents from saving a draft without an applicant and loan type values
            return formValues.LoanType.LOAN_TYPE === '';
        }

        return false;
    };

    renderForm = (section) => {
        const {
            formHeadings,
            handleSubmit,
            formValues = {},
            formName,
            formStack,
            formSubmitting,
            currentForm,
            submitSummaryComponent,
            submitSummaryProps = {},
            deleteHandler,
            loanEnquiry,
            isLoanUsageInUk,
        } = this.props;
        const nextButtonLabel = currentForm === SECTION_SUBMIT ? 'Submit' : 'Next';
        const saveDraftButtonLabel = 'Save Draft';
        const deleteDraftButtonLabel = 'Delete Draft';

        return (
            <BasicFormContentLayout
                content={
                    <form name={formName} onSubmit={handleSubmit(this.nextPage)} className={formName}>
                        <Container className={'full-width'}>
                            {section === SECTION_SUBMIT && (
                                <FormSection name={SECTION_SUBMIT}>
                                    {React.createElement(submitSummaryComponent, {
                                        submissionData: this.getSubmitHandler()(formValues),
                                        headings: formHeadings,
                                        ...submitSummaryProps,
                                    })}
                                </FormSection>
                            )}

                            {section !== SECTION_SUBMIT && (
                                <FormSection name={section}>{this.renderSection(section)}</FormSection>
                            )}

                            <FieldButtonPanelRow justify="space-between">
                                <div className={'progress-buttons'}>
                                    {formStack.length > 1 && (
                                        <PrimaryButton
                                            onClick={this.handleGoBack()}
                                            name={'previous'}
                                            disabled={formSubmitting}
                                        >
                                            Previous
                                        </PrimaryButton>
                                    )}

                                    <PrimaryButton
                                        type="submit"
                                        name={nextButtonLabel.toLowerCase()}
                                        disabled={
                                            this.getUserType() === ROLE_BORROWER &&
                                            nextButtonLabel === 'Submit' &&
                                            false === isLoanUsageInUk
                                                ? true
                                                : formSubmitting
                                        }
                                    >
                                        {nextButtonLabel}
                                    </PrimaryButton>
                                </div>

                                {this.showDraftButton() && this.isUserVerified() && (
                                    <div className={'draft-le-buttons'}>
                                        <div className={'save-draft-button'}>
                                            <PrimaryButton
                                                name={saveDraftButtonLabel.toLowerCase()}
                                                onClick={() => this.submitDraft(formValues)}
                                                disabled={this.disableSaveDraft(formValues)}
                                            >
                                                {saveDraftButtonLabel}
                                            </PrimaryButton>
                                        </div>
                                        {loanEnquiry.id && (
                                            <div className={'delete-draft-button'}>
                                                <PrimaryButton
                                                    name={deleteDraftButtonLabel.toLowerCase()}
                                                    onClick={deleteHandler}
                                                    disabled={formSubmitting}
                                                >
                                                    {deleteDraftButtonLabel}
                                                </PrimaryButton>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </FieldButtonPanelRow>
                        </Container>
                    </form>
                }
            />
        );
    };
}

WizardController.propTypes = {
    formStack: PropTypes.array.isRequired,
    formValues: PropTypes.object,
    currentForm: PropTypes.string.isRequired,
    formSubmitting: PropTypes.bool.isRequired,
    fieldValueSelector: PropTypes.func.isRequired,
    configFormVersion: PropTypes.string.isRequired,
    getFormValue: PropTypes.func.isRequired,
    getConfigEnumValues: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    goBackTo: PropTypes.func.isRequired,
    addForm: PropTypes.func.isRequired,
    skipTo: PropTypes.func.isRequired,
    nextPage: PropTypes.func.isRequired,
    resetFields: PropTypes.func.isRequired,
    getConfig: PropTypes.func.isRequired,
    setConfig: PropTypes.func.isRequired,
    resetForm: PropTypes.func.isRequired,
    setFieldValueSelector: PropTypes.func.isRequired,
    formSectionRender: PropTypes.func.isRequired,
    determinedSections: PropTypes.array.isRequired,
    formHeadings: PropTypes.object.isRequired,
    stepsMap: PropTypes.object.isRequired,
    submitFormHandler: PropTypes.func.isRequired,
    draftSubmitHandler: PropTypes.func.isRequired,
    deleteHandler: PropTypes.func,
};

export default compose(withRouter, withSession)(WizardController);
