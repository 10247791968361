import base_reducer, { defaultInitialState } from '../../../../form_wizard/redux';
export const NAMESPACE = 'intermediary.mle';
export const RESET = `${NAMESPACE}.reset`;
export const INITIATE = `${NAMESPACE}.initiate`;
export const SUBMIT_FORM = `${NAMESPACE}.submit_form`;
export const SUBMIT_PUBLISH_FORM = `${NAMESPACE}.submit_publish_form`;
export const SUBMIT_DRAFT_FORM = `${NAMESPACE}.submit_draft_form`;
export const SUBMIT_FORM_ERROR = `${NAMESPACE}.submit_form_error`;
export const SET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.set_matching_criteria_config`;
export const GET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.get_matching_criteria_config`;
export const SET_API_GET_LOAN_ENQUIRY_RESPONSE = `${NAMESPACE}.set_api_get_loan_enquiry_response`;

export const actionGetMatchingCriteriaConfig = () => ({
    type: GET_MATCHING_CRITERIA_CONFIG,
});

export const actionSubmitPublishForm = (
    acceptedTermsVersion,
    reviewMatches,
    formSections,
    isBusinessFinance,
    version,
    loanEnquiryId
) => ({
    type: SUBMIT_PUBLISH_FORM,
    acceptedTermsVersion,
    reviewMatches,
    formSections,
    isBusinessFinance,
    version,
    loanEnquiryId,
});

export const actionSubmitForm = (acceptedTermsVersion, reviewMatches, formSections, isBusinessFinance, version) => ({
    type: SUBMIT_FORM,
    acceptedTermsVersion,
    reviewMatches,
    formSections,
    isBusinessFinance,
    version,
});

export const actionSubmitDraftForm = (
    acceptedTermsVersion,
    reviewMatches,
    formSections,
    isBusinessFinance,
    version,
    loanEnquiryId
) => ({
    type: SUBMIT_DRAFT_FORM,
    acceptedTermsVersion,
    reviewMatches,
    formSections,
    isBusinessFinance,
    version,
    loanEnquiryId,
});

const initialState = {
    ...defaultInitialState,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATCHING_CRITERIA_CONFIG:
            const config = action.config;

            return {
                ...state,
                config,
            };

        case RESET:
            return {
                ...state,
                formStack: [],
                submitting: false,
            };

        case SUBMIT_FORM:
            return {
                ...state,
                submitting: true,
            };

        case SUBMIT_PUBLISH_FORM:
            return {
                ...state,
                submitting: true,
            };

        case SUBMIT_DRAFT_FORM:
            return {
                ...state,
                submitting: true,
            };

        case SUBMIT_FORM_ERROR:
            return {
                ...state,
                submitting: false,
            };

        case INITIATE:
            const loanEnquiryId = action.loanEnquiryId;

            return {
                ...initialState,
                loanEnquiryId,
            };

        case SET_API_GET_LOAN_ENQUIRY_RESPONSE:
            const { loanEnquiry } = action;

            return {
                ...state,
                loanEnquiry,
            };

        default:
            break;
    }

    return base_reducer(state, action, NAMESPACE);
};

export const selectorGetLoanEnquiry = (state) => {
    return state[NAMESPACE].loanEnquiry;
};

export const actionInitiate = (loanEnquiryId) => ({
    type: INITIATE,
    loanEnquiryId,
});

export const actionSetApiGetLoanEnquiryResponse = (response) => ({
    type: SET_API_GET_LOAN_ENQUIRY_RESPONSE,
    ...response,
});

//Default export reducer
export default reducer;
