import createP4ApolloFetch from '../../../bootstrap/middlewares';
import { getUserUrl } from '../../../pitchblack_react_utils/user/Url';

export const signLegalContract = (loanEnquiryId, contactedLenders) => {
    const SignLegalContractMutation = `
        mutation createContract($loanEnquiryId: ID!, $contactedLenders: String) {
            createContract(loanEnquiryId: $loanEnquiryId, contactedLenders: $contactedLenders) {
              success
            }
        }

    `;

    const apolloFetch = createP4ApolloFetch({ uri: getUserUrl() });

    return apolloFetch({
        query: SignLegalContractMutation,
        variables: {
            loanEnquiryId,
            contactedLenders,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
