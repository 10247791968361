import React, { useEffect, useState } from 'react';
import { compose } from 'recompose';
import { FieldRowInputOnly } from '../../../../../pitch4_forms/components/inputs';
import { LeftAligned } from '../../../../../pitch4_layout/components/div';
import { PrimaryButton } from '../../../../../pitch4_layout/components/buttons';
import {
    hasMinLength2,
    isValidPostcode,
    isEmailValid,
    isMinLength,
} from '../../../../../pitchblack_react_utils/form/validation';
import { TextField, Select, Checkbox, FormControlLabel, MenuItem, FormControl, Button, Paper } from '@material-ui/core';
import { getBrokers } from '../../../../../pitch4_api/get_brokers';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

function Form(props) {
    const [broker, setBroker] = useState({
        primaryEmail: typeof props.broker !== 'undefined' ? props.broker.primaryEmail : '',
        id: typeof props.broker !== 'undefined' ? props.broker.id : null,
        name: typeof props.broker !== 'undefined' ? props.broker.name : '',
        fcaIdentifier: typeof props.broker !== 'undefined' ? props.broker.fcaIdentifier : '',
        companyNumber: typeof props.broker !== 'undefined' ? props.broker.companyNumber : '',
        primaryAddressAddress1: typeof props.broker !== 'undefined' ? props.broker.primaryAddressAddress1 : '',
        primaryAddressAddress2: typeof props.broker !== 'undefined' ? props.broker.primaryAddressAddress2 : '',
        primaryAddressAddress3: typeof props.broker !== 'undefined' ? props.broker.primaryAddressAddress3 : '',
        primaryAddressAddress4: typeof props.broker !== 'undefined' ? props.broker.primaryAddressAddress4 : '',
        primaryAddressCountry: typeof props.broker !== 'undefined' ? props.broker.primaryAddressCountry : '',
        primaryAddressPostcode: typeof props.broker !== 'undefined' ? props.broker.primaryAddressPostcode : '',
        primaryPhoneNumber: typeof props.broker !== 'undefined' ? props.broker.primaryPhoneNumber : '',
        hasDataProtectionLicence:
            typeof props.broker !== 'undefined' ? props.broker.hasDataProtectionLicence === 'true' : false,
        isNetwork: typeof props.broker !== 'undefined' ? props.broker.isNetwork === true : false,
        parentBroker: typeof props.broker !== 'undefined' ? props.broker.parentBroker : null,
        brokerNetwork: 'undefined',
    });
    const [brokerNetworks, setBrokerNetworks] = useState(null);
    const { handleSubmit, submitting } = props;
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = React.useState(false);
    const getNetworks = () =>
        getBrokers(API_URL)(null, [
            {
                operation: 'eq',
                operand: 'true',
                column: 'isNetwork',
                not: false,
            },
        ]).then((resp) => {
            return null !== resp.data.brokers
                ? resp.data.brokers.edges
                      .filter((item) => item.node.id !== broker.id)
                      .map((broker) => {
                          return {
                              id: broker.node.id,
                              name: broker.node.name,
                          };
                      })
                : [];
        });
    const handleModalOpen = () => {
        setOpen(true);
    };
    const handleModalClose = () => {
        setOpen(false);
    };

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 400,
            backgroundColor: theme.palette.background.paper,
            // border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }));
    const classes = useStyles();

    useEffect(() => {
        if (null !== broker.parentBroker) {
            setBroker({
                ...broker,
                brokerNetwork: broker.parentBroker.id,
            });
        }
        getNetworks().then((networks) => setBrokerNetworks(networks));
    }, []);

    return (
        <>
            <Modal open={open} onClose={handleModalClose}>
                <Paper elevation={1}>
                    <div style={modalStyle} className={classes.paper}>
                        <h2 className={'network-modal-header'}>ARE YOU SURE?</h2>
                        <p>All associated broker accounts will revert to standard accounts!</p>
                        <p style={{ textAlign: 'center' }}>
                            <strong>This is irreversible!</strong>
                        </p>
                        <p style={{ textAlign: 'center', marginTop: 20 }}>
                            <Button variant={'contained'} color={'primary'} onClick={handleModalClose}>
                                OK
                            </Button>
                        </p>
                    </div>
                </Paper>
            </Modal>

            <form className={`broker-form`} onSubmit={handleSubmit(broker)}>
                <div className={`broker-form-scrollable`}>
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="primaryEmail"
                                value={broker.primaryEmail}
                                placeholder="Company Email Address"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        primaryEmail: event.target.value,
                                    })
                                }
                                error={!isEmailValid(broker.primaryEmail)}
                                helperText={false === isEmailValid(broker.primaryEmail) ? 'Incorrect entry.' : null}
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="name"
                                value={broker.name}
                                placeholder="Company name"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        name: event.target.value,
                                    })
                                }
                                error={false === isMinLength(2, broker.name)}
                                helperText={
                                    false === isMinLength(2, broker.name) ? `Must be 2 characters or more` : null
                                }
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="fcaIdentifier"
                                value={broker.fcaIdentifier}
                                placeholder="FCA Number"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        fcaIdentifier: event.target.value,
                                    })
                                }
                                validate={[hasMinLength2]}
                                error={false === isMinLength(2, broker.fcaIdentifier)}
                                helperText={
                                    false === isMinLength(2, broker.fcaIdentifier)
                                        ? `Must be 2 characters or more`
                                        : null
                                }
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <FormControl
                                variant="outlined"
                                className={'select-broker-network'}
                                disabled={null === brokerNetworks}
                            >
                                <Select
                                    labelId="select-broker-network-label"
                                    id="select-broker-network"
                                    name={'parentBroker'}
                                    value={
                                        null !== broker.parentBroker
                                            ? broker.parentBroker.id
                                            : broker.brokerNetwork ?? 'undefined'
                                    }
                                    disabled={
                                        true === submitting || true === broker.isNetwork || null === brokerNetworks
                                    }
                                    fullWidth={true}
                                    onChange={(event) => {
                                        setBroker({
                                            ...broker,
                                            brokerNetwork: event.target.value,
                                            parentBroker: null,
                                            isNetwork: '' !== event.target.value ? false : broker.network,
                                        });
                                    }}
                                    multiple={false}
                                    style={{ marginBottom: 15 }}
                                >
                                    <MenuItem key={1} value={'undefined'}>
                                        {null === brokerNetworks
                                            ? ' Loading Broker Networks...'
                                            : 'Select Broker Network'}
                                    </MenuItem>
                                    {brokerNetworks &&
                                        brokerNetworks.map((brokerNetwork) => {
                                            return (
                                                <MenuItem key={brokerNetwork.id} value={brokerNetwork.id}>
                                                    {brokerNetwork.name}
                                                </MenuItem>
                                            );
                                        })}
                                </Select>
                            </FormControl>
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="companyNumber"
                                value={broker.companyNumber}
                                placeholder="Company Number"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        companyNumber: event.target.value,
                                    })
                                }
                                error={false === isMinLength(2, broker.companyNumber)}
                                helperText={
                                    false === isMinLength(2, broker.companyNumber)
                                        ? `Must be 2 characters or more`
                                        : null
                                }
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="primaryAddressAddress1"
                                value={broker.primaryAddressAddress1}
                                placeholder="Address 1"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        primaryAddressAddress1: event.target.value,
                                    })
                                }
                                error={false === isMinLength(2, broker.primaryAddressAddress1)}
                                helperText={
                                    false === isMinLength(2, broker.primaryAddressAddress1)
                                        ? `Must be 2 characters or more`
                                        : null
                                }
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="primaryAddressAddress2"
                                value={broker.primaryAddressAddress2}
                                placeholder="Address 2"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        primaryAddressAddress2: event.target.value,
                                    })
                                }
                                error={false === isMinLength(2, broker.primaryAddressAddress2)}
                                helperText={
                                    false === isMinLength(2, broker.primaryAddressAddress2)
                                        ? `Must be 2 characters or more`
                                        : null
                                }
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="primaryAddressAddress3"
                                value={broker.primaryAddressAddress3}
                                placeholder="Address 3"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        primaryAddressAddress3: event.target.value,
                                    })
                                }
                                error={false === isMinLength(2, broker.primaryAddressAddress3)}
                                helperText={
                                    false === isMinLength(2, broker.primaryAddressAddress3)
                                        ? `Must be 2 characters or more`
                                        : null
                                }
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="primaryAddressAddress4"
                                value={broker.primaryAddressAddress4}
                                placeholder="Address 4"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        primaryAddressAddress4: event.target.value,
                                    })
                                }
                                error={false === isMinLength(2, broker.primaryAddressAddress4)}
                                helperText={
                                    false === isMinLength(2, broker.primaryAddressAddress4)
                                        ? `Must be 2 characters or more`
                                        : null
                                }
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="primaryAddressCountry"
                                value={broker.primaryAddressCountry}
                                placeholder="Country"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        primaryAddressCountry: event.target.value,
                                    })
                                }
                                error={false === isMinLength(2, broker.primaryAddressCountry)}
                                helperText={
                                    false === isMinLength(2, broker.primaryAddressCountry)
                                        ? `Must be 2 characters or more`
                                        : null
                                }
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="primaryAddressPostcode"
                                value={broker.primaryAddressPostcode}
                                placeholder="Postcode"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        primaryAddressPostcode: event.target.value,
                                    })
                                }
                                error={
                                    '' !== broker.primaryAddressPostcode &&
                                    typeof isValidPostcode(broker.primaryAddressPostcode) !== 'undefined'
                                }
                                helperText={
                                    '' !== broker.primaryAddressPostcode &&
                                    typeof isValidPostcode(broker.primaryAddressPostcode) !== 'undefined'
                                        ? 'Must be a valid UK postcode'
                                        : null
                                }
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <TextField
                                style={{ marginBottom: 15 }}
                                variant="outlined"
                                fullWidth={true}
                                name="primaryPhoneNumber"
                                value={broker.primaryPhoneNumber}
                                placeholder="Number"
                                disabled={submitting}
                                onChange={(event) =>
                                    setBroker({
                                        ...broker,
                                        primaryPhoneNumber: event.target.value,
                                    })
                                }
                                error={false === isMinLength(6, broker.primaryPhoneNumber)}
                                helperText={
                                    false === isMinLength(6, broker.primaryPhoneNumber)
                                        ? `Must be 2 characters or more`
                                        : null
                                }
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="hasDataProtectionLicence"
                                        disabled={submitting}
                                        checked={broker.hasDataProtectionLicence}
                                        onChange={(event) =>
                                            setBroker({
                                                ...broker,
                                                hasDataProtectionLicence: event.target.checked,
                                            })
                                        }
                                    />
                                }
                                label="Has a Data Protection Licence?"
                            />
                        }
                    />
                    <FieldRowInputOnly
                        input={
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="isNetwork"
                                        disabled={
                                            submitting ||
                                            null !== broker.parentBroker ||
                                            'undefined' !== broker.brokerNetwork
                                        }
                                        checked={broker.isNetwork ?? false}
                                        onChange={(event) => {
                                            const checked = event.target.checked;
                                            setBroker({
                                                ...broker,
                                                isNetwork: checked,
                                                brokerNetwork: true === checked ? 'undefined' : broker.brokerNetwork,
                                            });
                                            if (false === checked) {
                                                handleModalOpen();
                                            }
                                        }}
                                    />
                                }
                                label="Is Network Broker"
                            />
                        }
                    />
                </div>
                <LeftAligned className={'button-panel create-reset-buttons'}>
                    <PrimaryButton type="submit" disabled={submitting}>
                        Submit
                    </PrimaryButton>
                </LeftAligned>
            </form>
        </>
    );
}

export default compose()(Form);
