import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_USER } from '../../../authentication/enum/Roles/NameEnum';
import {
    actionEnableTwoFactorAuth,
    actionGetTwoFactorAuthDetails,
    selectorGetTwoFactorAuthDetails,
    selectorGetTwoFactorAuthFormErrors,
} from '../redux';
import SettingsIndex from '../components/SettingsIndex';
import { actionSetJoyrideType } from '../../../joyride/redux';

class IndexContainer extends React.Component {
    componentDidMount() {
        const { getTwoFactorAuthDetails, setJoyrideType } = this.props;

        getTwoFactorAuthDetails(true);
        setJoyrideType(null);
    }

    render() {
        const { twoFactorAuthDetails = {}, enableTwoFactorAuth, errors } = this.props;

        return (
            <SettingsIndex
                twoFactorAuthDetails={twoFactorAuthDetails}
                enableTwoFactorAuth={enableTwoFactorAuth}
                errors={errors}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        twoFactorAuthDetails: selectorGetTwoFactorAuthDetails(state),
        errors: selectorGetTwoFactorAuthFormErrors(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTwoFactorAuthDetails: (redirectOnFailure) => {
            dispatch(actionGetTwoFactorAuthDetails(redirectOnFailure));
        },
        enableTwoFactorAuth: (code) => {
            dispatch(actionEnableTwoFactorAuth(code));
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_USER])
)(IndexContainer);
