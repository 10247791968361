import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

const removeLoanEnquiryAlertMutation = `
  mutation removeLoanEnquiryAlert($id: ID!){
    removeLoanEnquiryAlert(id: $id){
      id
    }
  } 
`;

export const removeLoanEnquiryAlert = (loanEnquiryId) => {
    return apolloFetch({
        query: removeLoanEnquiryAlertMutation,
        variables: {
            id: loanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
