import React from 'react';
import PropTypes from 'prop-types';

export const MESSAGE_ERROR = 'error';
const VALID_MESSAGE_TYPES = [MESSAGE_ERROR];

function FormMessage({ type, messages }) {
    const className = 'form-message' + (VALID_MESSAGE_TYPES.indexOf(type) === -1 ? '' : ' form-message-' + type);
    return <div className={className}>{buildMessages(messages)}</div>;
}

function buildMessages(messages) {
    if (typeof messages === 'string') {
        return <p>{messages}</p>;
    }

    return messages.map((err, index) => <p key={index}>{err}</p>);
}

FormMessage.defaultProps = {
    type: MESSAGE_ERROR,
};

FormMessage.propTypes = {
    type: PropTypes.oneOf(VALID_MESSAGE_TYPES),
    messages: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

export default FormMessage;
