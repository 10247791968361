import React from 'react';
import BorrowerRegistrationContainer from '../../apps/registration/containers/BorrowerRegistrationContainer';
import DefaultRegistrationLayout from './DefaultRegistrationLayout';
import ProductRegistrationLayout from './ProductRegistrationLayout';
import NotificationsContainer from '../../apps/notifications/containers/Container';
import {
    PRODUCT_COMMERCIAL_MORTGAGES,
    PRODUCT_RESIDENTIAL_INVESTMENT_PROPERTIES,
    PRODUCT_BRIDGING_FINANCE,
    PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS,
    PRODUCT_HMO_LENDING,
    PRODUCT_BUSINESS_FINANCE,
    PRODUCT_CARE_HOME,
} from '../../pitch4_enum/enum/ProductEnum';
import _ from 'lodash';
import { getConfig as getProductConfig } from '../../pitch4_enum/enum/ProductEnum';
import { getConfig as getIntroducerConfig, INTRODUCER_GIFTWARE } from '../../pitch4_enum/enum/IntroducerEnum';
import IntroducerRegistrationLayout from './IntroducerRegistrationLayout';
import { setNetworkLoginCookie } from '../../pitch4_utils/LoginCookie';

const BorrowerRegistrationPage = (props) => {
    const {
        location: { pathname, search },
    } = props;

    const queryParams = new URLSearchParams(search);
    const formValuesType = queryParams.get('fvt'); //FormValuesType - used for pre populated form values
    const pathArr = _.split(pathname, '/');
    const element = _.last(pathArr);
    // const productConfig = getConfig(product);

    switch (element) {
        case INTRODUCER_GIFTWARE:
            setNetworkLoginCookie(INTRODUCER_GIFTWARE);
            return (
                <IntroducerRegistrationLayout
                    notifications={<NotificationsContainer />}
                    introducerConfig={getIntroducerConfig(element)}
                >
                    <BorrowerRegistrationContainer
                        formType={'introducer'}
                        productConfig={getIntroducerConfig(element)}
                        formValuesType={formValuesType}
                    />
                </IntroducerRegistrationLayout>
            );
        case PRODUCT_COMMERCIAL_MORTGAGES:
        case PRODUCT_RESIDENTIAL_INVESTMENT_PROPERTIES:
        case PRODUCT_BRIDGING_FINANCE:
        case PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS:
        case PRODUCT_HMO_LENDING:
        case PRODUCT_BUSINESS_FINANCE:
        case PRODUCT_CARE_HOME:
            return (
                <ProductRegistrationLayout
                    notifications={<NotificationsContainer />}
                    productConfig={getProductConfig(element)}
                >
                    <BorrowerRegistrationContainer
                        formType={'product'}
                        productConfig={getProductConfig(element)}
                        formValuesType={formValuesType}
                    />
                </ProductRegistrationLayout>
            );
        default:
            return (
                <DefaultRegistrationLayout notifications={<NotificationsContainer />}>
                    <BorrowerRegistrationContainer formType={'default'} />
                </DefaultRegistrationLayout>
            );
    }
};

export default BorrowerRegistrationPage;
