import { BaseEnum } from 'pitch4_enum';

export const GENDER_FEMALE = 'FEMALE';
export const GENDER_MALE = 'MALE';
export const GENDER_PREFER_NOT_TO_SAY = 'PREFER_NOT_TO_SAY';

class GenderEnum extends BaseEnum {
    getTypes = () => {
        return {
            [GENDER_FEMALE]: 'Female',
            [GENDER_MALE]: 'Male',
            [GENDER_PREFER_NOT_TO_SAY]: 'Prefer not to say',
        };
    };
}

export default GenderEnum;
