import {
    ASSET_TYPE,
    BRIDGE_TYPE,
    BUSINESS_TYPE,
    DEVELOPMENT_TYPE,
    INVOICE_TYPE,
    MERCHANT_CASH_ADVANCE_TYPE,
    MEZZANINE_TYPE,
    PORTFOLIO_TYPE,
    PROPERTY_TYPE,
    R_C_F_TYPE,
    REFURBISHMENT_TYPE,
    SECOND_CHARGE_TYPE,
    SUPPLY_CHAIN_TYPE,
    TERM_LONG_TYPE,
    TERM_SHORT_TYPE,
    TERM_TYPE,
    UNKNOWN_TYPE,
} from '../Broker/BrokerLoanType';
import {
    COMMERCIAL_FUNDING_TYPE,
    COMMERCIAL_PORTFOLIO_FUNDING_TYPE,
    HMO_FUNDING_TYPE,
    LAND_WITHOUT_PLANNING_FUNDING_TYPE,
    MIXED_PORTFOLIO_FUNDING_TYPE,
    RESIDENTIAL_FUNDING_TYPE,
    RESIDENTIAL_PORTFOLIO_FUNDING_TYPE,
    SEMI_COMMERCIAL_FUNDING_TYPE,
} from './CommercialFundingType';

export const PORTFOLIO = 'portfolio';
export const RESIDENTIAL_BRIDGE = 'residential_bridge';
export const RESIDENTIAL_DEVELOPMENT = 'residential_development';
export const RESIDENTIAL_REFURBISHMENT = 'residential_refurbishment';
export const RESIDENTIAL_SECOND_CHARGE = 'residential_second_charge';
export const RESIDENTIAL_MEZZANINE = 'residential_mezzanine';
export const RESIDENTIAL_TERM = 'residential_term';
export const COMMERCIAL_BRIDGE = 'commercial_bridge';
export const COMMERCIAL_DEVELOPMENT = 'commercial_development';
export const COMMERCIAL_REFURBISHMENT = 'commercial_refurbishment';
export const COMMERCIAL_SECOND_CHARGE = 'commercial_second_charge';
export const COMMERCIAL_MEZZANINE = 'commercial_mezzanine';
export const COMMERCIAL_TERM = 'commercial_term';
export const SEMI_COMMERCIAL_BRIDGE = 'semi_commercial_bridge';
export const SEMI_COMMERCIAL_DEVELOPMENT = 'semi_commercial_development';
export const SEMI_COMMERCIAL_REFURBISHMENT = 'semi_commercial_refurbishment';
export const SEMI_COMMERCIAL_SECOND_CHARGE = 'semi_commercial_second_charge';
export const SEMI_COMMERCIAL_MEZZANINE = 'semi_commercial_mezzanine';
export const SEMI_COMMERCIAL_TERM = 'semi_commercial_term';
export const HMO_BRIDGE = 'hmo_bridge';
export const HMO_TERM = 'hmo_term';
export const LAND_WITHOUT_PLANNING = 'land_without_planning';
export const BUSINESS_FINANCE_UNSECURED = 'business_finance_unsecured';
export const BUSINESS_FINANCE_UNSECURED_LONG_TERM = 'business_finance_unsecured_long_term';
export const BUSINESS_FINANCE_UNSECURED_SHORT_TERM = 'business_finance_unsecured_short_term';
export const BUSINESS_FINANCE_UNSECURED_REVOLVING_CREDIT = 'business_finance_unsecured_revolving_credit';
export const BUSINESS_FINANCE_SECURED = 'business_finance_secured';
export const BUSINESS_FINANCE_SECURED_LONG_TERM = 'business_finance_secured_long_term';
export const BUSINESS_FINANCE_SECURED_SHORT_TERM = 'business_finance_secured_short_term';
export const BUSINESS_FINANCE_SECURED_REVOLVING_CREDIT = 'business_finance_secured_revolving_credit';
export const BUSINESS_FINANCE_ASSET = 'business_finance_asset';
export const BUSINESS_FINANCE_MERCHANT = 'business_finance_merchant';
export const BUSINESS_FINANCE_INVOICE = 'business_finance_invoice';
export const BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE_FINANCE = 'business_finance_supply_chain_trade_finance';
export const RESIDENTIAL = 'residential';
export const BUSINESS_FINANCE = 'business_finance';

const BUSINESS_FINANCE_TYPE = 'BUSINESS_FINANCE';
const RESIDENTIAL_TYPE = 'RESIDENTIAL';

class StreamsLoanTypes {
    getTypes = () => {
        return [
            { value: PORTFOLIO, label: 'Portfolio Finance' },
            { value: RESIDENTIAL_BRIDGE, label: 'Residential Bridge' },
            { value: RESIDENTIAL_DEVELOPMENT, label: 'Residential Development' },
            { value: RESIDENTIAL_REFURBISHMENT, label: 'Residential Refurbishment' },
            { value: RESIDENTIAL_SECOND_CHARGE, label: 'Residential Second Charge' },
            { value: RESIDENTIAL_MEZZANINE, label: 'Residential Mezzanine' },
            { value: RESIDENTIAL_TERM, label: 'Residential Term' },
            { value: COMMERCIAL_BRIDGE, label: 'Commercial Bridge' },
            { value: COMMERCIAL_DEVELOPMENT, label: 'Commercial Development' },
            { value: COMMERCIAL_REFURBISHMENT, label: 'Commercial Refurbishment' },
            { value: COMMERCIAL_SECOND_CHARGE, label: 'Commercial Second Charge' },
            { value: COMMERCIAL_MEZZANINE, label: 'Commercial Mezzanine' },
            { value: COMMERCIAL_TERM, label: 'Commercial Term' },
            { value: SEMI_COMMERCIAL_BRIDGE, label: 'Semi Commercial Bridge' },
            { value: SEMI_COMMERCIAL_DEVELOPMENT, label: 'Semi Commercial Development' },
            { value: SEMI_COMMERCIAL_REFURBISHMENT, label: 'Semi Commercial Refurbishment' },
            { value: SEMI_COMMERCIAL_SECOND_CHARGE, label: 'Semi Commercial Second Charge' },
            { value: SEMI_COMMERCIAL_MEZZANINE, label: 'Semi Commercial Mezzanine' },
            { value: SEMI_COMMERCIAL_TERM, label: 'Semi Commercial Term' },
            { value: HMO_BRIDGE, label: 'HMO Bridge' },
            { value: HMO_TERM, label: 'HMO Term' },
            { value: LAND_WITHOUT_PLANNING, label: 'Land Without Planning Bridge' },
            { value: BUSINESS_FINANCE_UNSECURED, label: 'Business Unsecured Loans; Short term/Long Term/RCF' },
            { value: BUSINESS_FINANCE_SECURED, label: 'Business Secured Loans; Short term/Long Term/RCF' },
            { value: BUSINESS_FINANCE_UNSECURED_LONG_TERM, label: 'Business Finance (Unsecured Long Term)' },
            { value: BUSINESS_FINANCE_UNSECURED_SHORT_TERM, label: 'Business Finance (Unsecured Short Term)' },
            {
                value: BUSINESS_FINANCE_UNSECURED_REVOLVING_CREDIT,
                label: 'Business Finance (Unsecured Revolving Credit)',
            },
            { value: BUSINESS_FINANCE_SECURED_LONG_TERM, label: 'Business Finance (Secured Long Term)' },
            { value: BUSINESS_FINANCE_SECURED_SHORT_TERM, label: 'Business Finance (Secured Short Term)' },
            { value: BUSINESS_FINANCE_SECURED_REVOLVING_CREDIT, label: 'Business Finance (Secured Revolving Credit)' },
            { value: BUSINESS_FINANCE_ASSET, label: 'Business Asset Finance' },
            { value: BUSINESS_FINANCE_MERCHANT, label: 'Business Merchant Cash Advance' },
            { value: BUSINESS_FINANCE_INVOICE, label: 'Business Invoice Finance' },
            { value: BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE_FINANCE, label: 'Business Supply Chain/Trade Finance' },
            { value: RESIDENTIAL, label: 'Property' },
            { value: BUSINESS_FINANCE, label: 'Business Finance' },
        ];
    };

    getOptions = () => {
        return {
            [PORTFOLIO]: { value: PORTFOLIO, label: 'Portfolio Finance' },
            [RESIDENTIAL_BRIDGE]: { value: RESIDENTIAL_BRIDGE, label: 'Residential Bridge' },
            [RESIDENTIAL_DEVELOPMENT]: { value: RESIDENTIAL_DEVELOPMENT, label: 'Residential Development' },
            [RESIDENTIAL_REFURBISHMENT]: { value: RESIDENTIAL_REFURBISHMENT, label: 'Residential Refurbishment' },
            [RESIDENTIAL_SECOND_CHARGE]: { value: RESIDENTIAL_SECOND_CHARGE, label: 'Residential Second Charge' },
            [RESIDENTIAL_MEZZANINE]: { value: RESIDENTIAL_MEZZANINE, label: 'Residential Mezzanine' },
            [RESIDENTIAL_TERM]: { value: RESIDENTIAL_TERM, label: 'Residential Term' },
            [COMMERCIAL_BRIDGE]: { value: COMMERCIAL_BRIDGE, label: 'Commercial Bridge' },
            [COMMERCIAL_DEVELOPMENT]: { value: COMMERCIAL_DEVELOPMENT, label: 'Commercial Development' },
            [COMMERCIAL_REFURBISHMENT]: { value: COMMERCIAL_REFURBISHMENT, label: 'Commercial Refurbishment' },
            [COMMERCIAL_SECOND_CHARGE]: { value: COMMERCIAL_SECOND_CHARGE, label: 'Commercial Second Charge' },
            [COMMERCIAL_MEZZANINE]: { value: COMMERCIAL_MEZZANINE, label: 'Commercial Mezzanine' },
            [COMMERCIAL_TERM]: { value: COMMERCIAL_TERM, label: 'Commercial Term' },
            [SEMI_COMMERCIAL_BRIDGE]: { value: SEMI_COMMERCIAL_BRIDGE, label: 'Semi Commercial Bridge' },
            [SEMI_COMMERCIAL_DEVELOPMENT]: { value: SEMI_COMMERCIAL_DEVELOPMENT, label: 'Semi Commercial Development' },
            [SEMI_COMMERCIAL_REFURBISHMENT]: {
                value: SEMI_COMMERCIAL_REFURBISHMENT,
                label: 'Semi Commercial Refurbishment',
            },
            [SEMI_COMMERCIAL_SECOND_CHARGE]: {
                value: SEMI_COMMERCIAL_SECOND_CHARGE,
                label: 'Semi Commercial Second Charge',
            },
            [SEMI_COMMERCIAL_MEZZANINE]: { value: SEMI_COMMERCIAL_MEZZANINE, label: 'Semi Commercial Mezzanine' },
            [SEMI_COMMERCIAL_TERM]: { value: SEMI_COMMERCIAL_TERM, label: 'Semi Commercial Term' },
            [HMO_BRIDGE]: { value: HMO_BRIDGE, label: 'HMO Bridge' },
            [HMO_TERM]: { value: HMO_TERM, label: 'HMO Term' },
            [LAND_WITHOUT_PLANNING]: { value: LAND_WITHOUT_PLANNING, label: 'Land Without Planning Bridge' },
            [BUSINESS_FINANCE_UNSECURED]: {
                value: BUSINESS_FINANCE_UNSECURED,
                label: 'Business Unsecured Loans; Short term/Long Term/RCF',
            },
            [BUSINESS_FINANCE_SECURED]: {
                value: BUSINESS_FINANCE_SECURED,
                label: 'Business Secured Loans; Short term/Long Term/RCF',
            },
            [BUSINESS_FINANCE_UNSECURED_LONG_TERM]: {
                value: BUSINESS_FINANCE_UNSECURED_LONG_TERM,
                label: 'Business Finance (Unsecured Long Term)',
            },
            [BUSINESS_FINANCE_UNSECURED_SHORT_TERM]: {
                value: BUSINESS_FINANCE_UNSECURED_SHORT_TERM,
                label: 'Business Finance (Unsecured Short Term)',
            },
            [BUSINESS_FINANCE_UNSECURED_REVOLVING_CREDIT]: {
                value: BUSINESS_FINANCE_UNSECURED_REVOLVING_CREDIT,
                label: 'Business Finance (Unsecured Revolving Credit)',
            },
            [BUSINESS_FINANCE_SECURED_LONG_TERM]: {
                value: BUSINESS_FINANCE_SECURED_LONG_TERM,
                label: 'Business Finance (Secured Long Term)',
            },
            [BUSINESS_FINANCE_SECURED_SHORT_TERM]: {
                value: BUSINESS_FINANCE_SECURED_SHORT_TERM,
                label: 'Business Finance (Secured Short Term)',
            },
            [BUSINESS_FINANCE_SECURED_REVOLVING_CREDIT]: {
                value: BUSINESS_FINANCE_SECURED_REVOLVING_CREDIT,
                label: 'Business Finance (Secured Revolving Credit)',
            },
            [BUSINESS_FINANCE_ASSET]: { value: BUSINESS_FINANCE_ASSET, label: 'Business Asset Finance' },
            [BUSINESS_FINANCE_MERCHANT]: { value: BUSINESS_FINANCE_MERCHANT, label: 'Business Merchant Cash Advance' },
            [BUSINESS_FINANCE_INVOICE]: { value: BUSINESS_FINANCE_INVOICE, label: 'Business Invoice Finance' },
            [BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE_FINANCE]: {
                value: BUSINESS_FINANCE_SUPPLY_CHAIN_TRADE_FINANCE,
                label: 'Business Supply Chain/Trade Finance',
            },
            [RESIDENTIAL]: { value: RESIDENTIAL, label: 'Property' },
            [BUSINESS_FINANCE]: { value: BUSINESS_FINANCE, label: 'Business Finance' },
        };
    };

    getCommercialType = (commercialType) => {
        switch (commercialType) {
            case HMO_FUNDING_TYPE:
                return 'hmo';
            case RESIDENTIAL_FUNDING_TYPE:
                return 'residential';
            case SEMI_COMMERCIAL_FUNDING_TYPE:
                return 'semi_commercial';
            case COMMERCIAL_FUNDING_TYPE:
                return 'commercial';
            case RESIDENTIAL_PORTFOLIO_FUNDING_TYPE:
            case COMMERCIAL_PORTFOLIO_FUNDING_TYPE:
            case MIXED_PORTFOLIO_FUNDING_TYPE:
                return 'portfolio';
            case LAND_WITHOUT_PLANNING_FUNDING_TYPE:
                return 'land_without_planning';
            default:
                return null;
        }
    };
    convertToStreamType = (type, commercialType, isSecured) => {
        const propertyType = this.getCommercialType(commercialType);
        if (propertyType === 'land_without_planning') {
            return 'land_without_planning';
        }
        switch (type) {
            case BUSINESS_TYPE:
                return 'business_finance_';
            case TERM_TYPE:
                return propertyType + '_term';
            case BRIDGE_TYPE:
                return propertyType + '_bridge';
            case REFURBISHMENT_TYPE:
                return propertyType + '_refurbishment';
            case SECOND_CHARGE_TYPE:
                return propertyType + '_second_charge';
            case DEVELOPMENT_TYPE:
                return propertyType + '_development';
            case MEZZANINE_TYPE:
                return propertyType + '_mezzanine';
            case PORTFOLIO_TYPE:
                return 'portfolio';
            case TERM_LONG_TYPE:
                return isSecured === true
                    ? 'secured_long_term'
                    : isSecured === false
                    ? 'unsecured_long_term'
                    : 'long_term';
            case TERM_SHORT_TYPE:
                return isSecured === true
                    ? 'secured_short_term'
                    : isSecured === false
                    ? 'unsecured_short_term'
                    : 'short_term';
            case R_C_F_TYPE:
                return isSecured === true
                    ? 'secured_revolving_credit'
                    : isSecured === false
                    ? 'unsecured_revolving_credit'
                    : 'revolving_credit';
            case INVOICE_TYPE:
                return 'invoice';
            case ASSET_TYPE:
                return 'asset';
            case MERCHANT_CASH_ADVANCE_TYPE:
                return 'merchant';
            case SUPPLY_CHAIN_TYPE:
                return 'supply_chain_trade_finance';
            case RESIDENTIAL_TYPE:
                return 'residential';
            case BUSINESS_FINANCE_TYPE:
                return 'business_finance';
            case PROPERTY_TYPE:
                return '';
            case UNKNOWN_TYPE:
            default:
                return 'Unknown';
        }
    };
}

export default StreamsLoanTypes;
