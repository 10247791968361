import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { parseSearchString } from 'pitch4_layout/components/uri';

//function
export default function WithQueryStringVariables(WrappedComponent) {
    function getDisplayName(WrappedComponent) {
        return WrappedComponent.displayName || WrappedComponent.name || 'Component';
    }

    class WithQueryStringVariables extends React.Component {
        render() {
            let { location } = this.props;

            // Wraps the input component in a container, without mutating it. Good!
            return <WrappedComponent uriVars={{ variables: parseSearchString(location.search) }} {...this.props} />;
        }
    }

    WithQueryStringVariables.displayName = `WithQueryStringVariables(${getDisplayName(WrappedComponent)})`;

    return compose(withRouter)(WithQueryStringVariables);
}
