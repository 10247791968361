import { BaseEnum } from 'pitch4_enum';

export const TYPE_PROFESSIONAL_SERVICES = 'professional_services';
export const TYPE_PROPERTY_MANAGEMENT = 'property_management';
export const TYPE_PROPERTY_DEVELOPMENT = 'property_development';
export const TYPE_AUTOMOBILES = 'automobiles';
export const TYPE_MARINE = 'marine';
export const TYPE_AVIATION = 'aviation';
export const TYPE_CONSTRUCTION = 'construction';
export const TYPE_INTELLECTUAL_PROPERTY = 'intellectual_property';
export const TYPE_RETAIL = 'retail';
export const TYPE_FOOD = 'food';
export const TYPE_RECRUITMENT = 'recruitment';
export const TYPE_MANUFACTURING = 'manufacturing';
export const TYPE_MARKETING = 'marketing';
export const TYPE_HOSPITALITY = 'hospitality';
export const TYPE_TECHNOLOGY = 'technology';
export const TYPE_HEALTHCARE = 'healthcare';
export const TYPE_CREATIVE = 'creative';
export const TYPE_UTILITIES = 'utilities';
export const TYPE_ENVIRONMENT = 'environment';
export const TYPE_LAW = 'law';
export const TYPE_LEISURE = 'leisure';
export const TYPE_MEDIA = 'media';
export const TYPE_SALES = 'sales';
export const TYPE_SCIENCE = 'science';
export const TYPE_TRANSPORT = 'transport';
export const TYPE_EDUCATION = 'education';
export const TYPE_SECURITY = 'security';
export const TYPE_ACCOUNTING = 'accounting';
export const TYPE_CONSULTANCY = 'consultancy';

class BusinessFinanceSectorEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_PROFESSIONAL_SERVICES]: 'Professional Services',
            [TYPE_PROPERTY_MANAGEMENT]: 'Property Management',
            [TYPE_PROPERTY_DEVELOPMENT]: 'Property Development',
            [TYPE_AUTOMOBILES]: 'Automobiles',
            [TYPE_MARINE]: 'Marine',
            [TYPE_AVIATION]: 'Aviation',
            [TYPE_CONSTRUCTION]: 'Construction',
            [TYPE_INTELLECTUAL_PROPERTY]: 'Intellectual Property',
            [TYPE_RETAIL]: 'Retail',
            [TYPE_FOOD]: 'Food',
            [TYPE_RECRUITMENT]: 'Recruitment and HR',
            [TYPE_MANUFACTURING]: 'Manufacturing',
            [TYPE_MARKETING]: 'Marketing, advertising and PR',
            [TYPE_HOSPITALITY]: 'Hospitality and events management',
            [TYPE_TECHNOLOGY]: 'Technology',
            [TYPE_HEALTHCARE]: 'Healthcare',
            [TYPE_CREATIVE]: 'Creative arts and design',
            [TYPE_UTILITIES]: 'Energy and Utilities',
            [TYPE_ENVIRONMENT]: 'Environment and Agriculture',
            [TYPE_LAW]: 'Law',
            [TYPE_LEISURE]: 'Leisure, sport and tourism',
            [TYPE_MEDIA]: 'Media and internet',
            [TYPE_SALES]: 'Sales',
            [TYPE_SCIENCE]: 'Science and Pharmaceuticals',
            [TYPE_TRANSPORT]: 'Transport and logistics',
            [TYPE_EDUCATION]: 'Education',
            [TYPE_SECURITY]: 'Security',
            [TYPE_ACCOUNTING]: 'Accounting',
            [TYPE_CONSULTANCY]: 'Consultancy and management',
        };
    };
}

export default BusinessFinanceSectorEnum;
