import React from 'react';
import { TableRow as MTableRow } from '@material-ui/core';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';

class TableRow extends React.Component {
    render() {
        let {
            children,
            className = null,
            viewUrl = null,
            onClickHandler = null,
            redirect, // Keep this outside ...other props as it is not correct prop for MTableRow
            staticContext, // Keep this outside ...other props as it is not correct prop for MTableRow
            ...other
        } = this.props;

        if (className) {
            className += ' table-row';
        } else {
            className = 'table-row';
        }

        if (viewUrl || onClickHandler) {
            className += ' table-row-link';
        }

        return (
            <MTableRow {...other} className={className} onClick={this.handleClick(onClickHandler)}>
                {children}
            </MTableRow>
        );
    }

    handleClick = (onClickHandler) => (event) => {
        let { viewUrl = null, redirect } = this.props;

        if (['TR', 'TD'].indexOf(event.target.nodeName) === -1) {
            return;
        }

        if (onClickHandler) {
            onClickHandler();
        }

        if (viewUrl !== null) {
            redirect(viewUrl);
        }
    };
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(TableRow);
