import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import NavigationBar from '../../navigation/components/NavigationBar';
import LeftSideMenuContainer from '../../navigation/containers/LeftSideMenuContainer';
import withSession from 'pitchblack_react_utils/session/withSession';
import { selectorIsPrivacyAccepted } from '../redux/landing';

class Header extends React.Component {
    render() {
        let { session } = this.props;

        if (!session.getPrivacyPolicyAccepted()) {
            return <div className={`no_menu_no_priv_accepted`} />;
        }

        return (
            <div className="header">
                <LeftSideMenuContainer />
                <NavigationBar />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        privacyAccepted: selectorIsPrivacyAccepted(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(Header);
