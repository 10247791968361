import React from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';

import { ERROR_LEVEL, INFO_LEVEL, SUCCESS_LEVEL, WARNING_LEVEL } from '../enum/LevelEnum';
import { Container, Item } from '../../../pitch4_layout/components/div';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
};

function Message(props) {
    const { className, message, onClose, variant, ...other } = props;
    const Icon = variantIcon[variant];

    return (
        <SnackbarContent
            message={
                <Container>
                    <Item className={`icon-container icon-container-${variant}`}>
                        <Icon fontSize="inherit" />
                    </Item>
                    <Item className={`message-container`}>
                        <span>{message}</span>
                    </Item>
                </Container>
            }
            action={[
                <IconButton key="close" color="inherit" onClick={onClose}>
                    <CloseIcon />
                </IconButton>,
            ]}
            {...other}
        />
    );
}

Message.propTypes = {
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf([SUCCESS_LEVEL, WARNING_LEVEL, ERROR_LEVEL, INFO_LEVEL]).isRequired,
};

export default Message;
