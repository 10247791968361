import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { TextLabel, Select, FieldRow } from 'pitch4_forms/components/inputs';
import { Container } from 'pitch4_layout/components/div';
import styled from 'styled-components';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { isRequired } from 'pitchblack_react_utils/form/validation';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import CreateContainer from '../containers/CreateContainer';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import ModalLayout from 'pitch4_modal/src/component/ModalLayout';

const ActionButtonContainer = styled(Container)`
    display: flex;
    align-items: center;
`;

const AddIcon = styled(AddCircleOutlineIcon)`
    margin-right: 5px;
`;

class BrokerSelect extends React.Component {
    render() {
        const { submitting, brokers } = this.props;

        return (
            <FieldRow
                label={<TextLabel label="Broker" />}
                input={
                    <Select
                        name="broker"
                        sortLabels={false}
                        placeholder="Attach Broker"
                        style={{ padding: 0 }}
                        disabled={submitting}
                        validate={[isRequired]}
                        suggestions={this.mapBrokers(brokers)}
                        callToActionItem={{
                            content: (
                                <ActionButtonContainer>
                                    <AddIcon />
                                    Create new
                                </ActionButtonContainer>
                            ),
                            onClick: this.createBroker(this.props),
                        }}
                    />
                }
            />
        );
    }

    createBroker = () => () => {
        const {
            onSuccessFunc = () => null,
            onFailureFunc = () => null,
            setModalInteractive,
            setModalComponent,
            setModalClose,
        } = this.props;

        setModalComponent(
            <ModalLayout title="Create Broker">
                <CreateContainer
                    inProgress={() => setModalInteractive(true)}
                    onSuccess={(data) => {
                        onSuccessFunc(data);
                        setModalInteractive(false);
                        setModalClose();
                    }}
                    onFailure={() => {
                        onFailureFunc();
                        setModalInteractive(true);
                    }}
                />
            </ModalLayout>
        );
    };

    mapBrokers(brokers) {
        if (_.isEmpty(brokers)) {
            return [];
        }

        let mapped = [];

        brokers.brokers.edges.forEach((edge) => {
            mapped.push({
                value: edge.node.id,
                label: edge.node.name,
            });
        });

        return mapped;
    }
}

BrokerSelect.propTypes = {
    submitting: PropTypes.bool,
    initialValues: PropTypes.any,
    brokers: PropTypes.object.isRequired,
    onSuccessFunc: PropTypes.func,
    onFailureFunc: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BrokerSelect);
