import React from 'react';

export default function ContractDownloadPopup(props) {
    const { type, content } = props;

    switch (type) {
        case 'failed':
            return <div className={'contract-download'}>{content}</div>;
        case 'progress':
            return <div className={'contract-download'}>{content}</div>;
        default:
            return null;
    }
}
