import { BaseEnum } from '../../pitch4_enum';

export const BROKER_TYPE_STANDARD = 'BROKER_TYPE_STANDARD';
export const BROKER_TYPE_PARTNER = 'BROKER_TYPE_PARTNER';

class BrokerTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [BROKER_TYPE_STANDARD]: 'Standard',
            [BROKER_TYPE_PARTNER]: 'Partner',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default BrokerTypeEnum;
