import React from 'react';
import { Container as LayoutContainer } from 'pitchblack_react_utils/layout/index';
import TwoFactorAuthLoginForm from '../components/TwoFactorAuthLoginForm';

class TwoFactorAuthLoginContainer extends React.Component {
    render() {
        return (
            <LayoutContainer>
                <TwoFactorAuthLoginForm />
            </LayoutContainer>
        );
    }
}

export default TwoFactorAuthLoginContainer;
