import React from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';
import { TextField as MUITextField } from 'redux-form-material-ui';

export default function HiddenField(props) {
    const { name = '', value = '', InputProps = { disableUnderline: true } } = props;

    if (_.includes(['development', 'qa', 'test'], process.env.NODE_ENV)) {
        InputProps['data-cy'] = `${name}-input`;
    }

    return <Field type={'hidden'} name={name} component={MUITextField} value={value} />;
}
