import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import DocumentTypeEnum from '../../../../../pitch4_enum/enum/DocumentTypeEnum';
import { EnumLabel } from '../../../../../pitch4_layout/components/data';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import { withRouter } from 'react-router-dom';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { handleDownload } from '../../../../../pitch4_documents/src/components/DownloadButton';
import { Loading } from '../../../../../pitchblack_react_utils/form/renderIfSubmitting';
import DeleteDocumentButton from '../../administer_documents/components/DeleteDocumentButton';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import moment from 'moment';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import RoleNameEnum, {
    ROLE_ADMIN,
    ROLE_INTERMEDIARY,
    ROLE_SUPER_ADMIN,
    ROLE_SLAM,
    ROLE_BORROWER,
} from '../../../../../pitch4_enum/enum/RoleNameEnum';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';

const roleEnum = new RoleNameEnum();
const roleStyleClassMapper = {
    [ROLE_SUPER_ADMIN]: 'admin',
    [ROLE_ADMIN]: 'admin',
    [ROLE_INTERMEDIARY]: 'intermediary',
    [ROLE_BORROWER]: 'borrower',
    [ROLE_SLAM]: 'lender',
};

class DocumentListItem extends React.Component {
    render() {
        const {
            setModalClose,
            setModalComponent,
            errorNotification,
            successNotification,
            docTypeStyles = {},
            document,
            refetch,
            session,
        } = this.props;

        return (
            <div className={`document-row`}>
                <div className={`doc-icon`}>
                    <InsertDriveFileIcon fontSize={'large'} />
                </div>
                <div>
                    <div className={`doc-filename`} onClick={this.downloadDocument(document)}>
                        <span title={document.fileName}>{document.fileName}</span>
                    </div>
                    <div className={`doc-type`} style={docTypeStyles}>
                        <EnumLabel enumIn={new DocumentTypeEnum()} value={document.type} />
                    </div>
                </div>
                {document.uploaderRole && (
                    <div className={'doc-uploader'}>
                        <Badge
                            label={
                                ROLE_SUPER_ADMIN === document.uploaderRole
                                    ? roleEnum.getType(ROLE_ADMIN)
                                    : ROLE_SLAM === document.uploaderRole
                                    ? 'Lender'
                                    : roleEnum.getType(document.uploaderRole)
                            }
                            type={roleStyleClassMapper[document.uploaderRole]}
                        />
                    </div>
                )}
                <span className={`doc-created`}>{moment(document.createdAt).format('HH:mm - MMM Do YYYY')}</span>
                <div className={`doc-actions`}>
                    <div>
                        <CloudDownloadIcon className={`cloud-icon`} onClick={this.downloadDocument(document)} />
                    </div>
                    {session.getMostSeniorRole().name === document.uploaderRole && (
                        <DeleteDocumentButton
                            outerRefetch={refetch}
                            id={document.id}
                            setModalComponent={setModalComponent}
                            setModalClose={setModalClose}
                            successNotification={successNotification}
                            errorNotification={errorNotification}
                            document={document}
                            onSuccess={(data) => {
                                successNotification('Document deleted successfully');
                                setModalClose();
                                refetch();
                            }}
                            onError={() => {
                                errorNotification('Error has occurred. Please contact support.');
                                setModalClose();
                            }}
                            onPending={() =>
                                setModalComponent(<Loading heading="Please wait. Removing file." />, false)
                            }
                            type={'icon'}
                        >
                            <DeleteIcon className={`delete-icon`} />
                        </DeleteDocumentButton>
                    )}
                </div>
            </div>
        );
    }

    downloadDocument = (document) => () => {
        let { setModalClose, setModalComponent } = this.props;

        handleDownload(document, {
            onPending: () => setModalComponent(<Loading heading="Please wait. Downloading file." />, false),
            onSuccess: () => setModalClose(),
            onFailure: () => setModalClose(),
        });
    };
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        successNotification: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        errorNotification: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withSession,
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_SLAM])
)(DocumentListItem);
