import base_reducer, { defaultInitialState } from 'pitch4_tabular/redux/index_ns';

export const NAMESPACE = 'borrower.documents';

const initialState = {
    ...defaultInitialState,
};

const reducer = (state = initialState, action) => {
    return base_reducer(state, action, NAMESPACE);
};

//Default export reducer
export default reducer;
