import { API_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { SORT_ASC } from '../../../../../pitch4_tabular/enum/SortDirectionEnum';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const GetBrokersQuery = `
    query getBrokers($first: Int!, $sorts: [SortInput]) {
        brokers(first: $first, sorts: $sorts) {
            edges {
                node {
                    id
                    name
                }
                cursor
            }
            pageInfo{
              hasNextPage,
              endCursor,
              startCursor,
              hasPreviousPage
            }
            totalCount
        }
    }
`;

export const getBrokersLegacy = (size = 99999) => {
    let out = apolloFetch({
        query: GetBrokersQuery,
        variables: {
            first: size,
            sorts: [
                {
                    column: 'name',
                    direction: SORT_ASC,
                },
            ],
        },
    })
        .then(({ data, errors }) => {
            return data;
        })
        .catch(() => {
            return [];
        });

    return out;
};
