import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import { SLAM_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';
import _ from 'lodash';

const apolloFetch = createP4ApolloFetch({ uri: SLAM_URL });

const GetStoredDataMetaQuery = `
query getStoredDataMetas($count: Int, $cursor: String, $filters: [FilterInput]){
  storedDataMetas(first: $count, after: $cursor, filters: $filters){
    edges {
      node {
        key
        type
      }
      cursor
    }
    pageInfo {
      hasNextPage,
      endCursor,
      startCursor,
      hasPreviousPage
    }
  }
}
`;

//response classes
const errorFactory = new ValidationResultFactory();

class GetStoredDataMeta {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class GetStoredDataMetaErrors extends GetStoredDataMeta {
    constructor(errors) {
        super('GetStoredDataMetaErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class GetStoredDataMetaSuccess extends GetStoredDataMeta {
    constructor(data) {
        super('GetStoredDataMetaSuccess');
        this.data = data;
        this.formInitialValues = {};
    }

    getData = () => {
        const edges = _.get(this.data, ['storedDataMetas', 'edges']);

        if (Array.isArray(edges) === false) {
            return {};
        }

        let ret = {};
        _.forEach(edges, (meta) => {
            ret[meta.node.key] = meta.node;
        });

        return ret;
    };
}

export const getStoredDataMeta = (count = 99990) => {
    let out = apolloFetch({
        query: GetStoredDataMetaQuery,
        variables: {
            count: count,
            filters: [],
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new GetStoredDataMetaErrors(vR);
            }

            return new GetStoredDataMetaSuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new GetStoredDataMetaErrors(vR);
        });

    return out;
};
