import React from 'react';
import { Container, Item, RowItem } from '../../pitchblack_react_utils/layout/index';
import DateTimeFactory from '../../pitchblack_react_utils/datetime/DateTimeFactory';
import Tooltip from '@material-ui/core/Tooltip';
import ChatIcon from '../components/ChatIcon';
import { formatDate } from '../../pitch4_layout/components/data/formatter/DateTimeFormatter';
import BubbleText from './BubbleText';
import DateTime from '../../pitchblack_react_utils/datetime/DateTime';
import chatAdminImg from '../assets/chat-admin.svg';
import chatBorrowerImg from '../assets/chat-borrower.svg';
import chatIntermediaryImg from '../assets/chat-intermediary.svg';
import chatSlamImg from '../assets/chat-slam.svg';
import _ from 'lodash';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { handleDownload } from '../../pitch4_documents/src/components/DownloadButton';
import { Loading } from '../../pitchblack_react_utils/form/renderIfSubmitting';
import { sagaActionModalHide, sagaActionModalShow } from '../../apps/modal/sagas/container';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import withSession from '../../pitchblack_react_utils/session/withSession';
import {
    ROLE_ADMIN,
    ROLE_BORROWER,
    ROLE_INTERMEDIARY,
    ROLE_SLAM,
    ROLE_SUPER_ADMIN,
} from '../../pitch4_enum/enum/RoleNameEnum';

const urlRoleMapper = {
    [ROLE_SUPER_ADMIN]: 'pitch4admin',
    [ROLE_ADMIN]: 'pitch4admin',
    [ROLE_INTERMEDIARY]: 'intermediary',
    [ROLE_BORROWER]: 'borrower',
    [ROLE_SLAM]: 'slam',
};

const side = (isSender) => {
    if (isSender) {
        return 'row-reverse';
    }
    return 'row';
};

const dateTimeFactory = new DateTimeFactory();

class Message extends React.Component {
    buildIcon(userRole) {
        switch (userRole) {
            case ROLE_BORROWER:
                return chatBorrowerImg;
            case ROLE_INTERMEDIARY:
                return chatIntermediaryImg;
            case ROLE_SLAM:
                return chatSlamImg;
            case ROLE_SUPER_ADMIN:
                return chatAdminImg;

            default:
                throw new Error('Unknown userRole' + userRole);
        }
    }

    buildText(userRole, text) {
        switch (userRole) {
            case ROLE_SLAM:
                return 'Lender';
            default:
                return text;
        }
    }

    buildSubText(userRole) {
        return userRole === ROLE_SUPER_ADMIN ? <div className={'is-admin'}>Admin</div> : <></>;
    }

    downloadDocument = (document) => () => {
        let { setModalClose, setModalComponent } = this.props;

        handleDownload(document, {
            onPending: () => setModalComponent(<Loading heading="Please wait. Downloading file." />, false),
            onSuccess: () => setModalClose(),
            onFailure: () => setModalClose(),
        });
    };

    render() {
        const { message, isSender, session } = this.props;
        const status = isSender ? 'You @ ' + dateTimeFactory.create(message.getSentAt()).toLocaleString() : '@ ';
        const today = new DateTime();
        const dateFormat = today.isSame(message.getSentAt(), 'day') ? 'HH:mm a' : 'MMMM D, HH:mm a';
        const icon = this.buildIcon(message.getUserRole());
        const iconText = this.buildText(message.getUserRole(), message.getUserProfile()['given']);
        const iconSubText = this.buildSubText(message.getUserRole());
        let messageContent = message.getContent()['value'];
        let docUrl = null;
        let cleanName = null;
        const docId = messageContent.match(/DOC_ID_(.*?)_DOC_ID/g);
        const docName = messageContent.match(/DOC_NAME_(.*?)_DOC_NAME/g);

        if (docName) {
            cleanName = docName[0].replace('DOC_NAME_', '').replace('_DOC_NAME', '');
            messageContent = _.replace(messageContent, docName[0], cleanName);
        }

        if (docId) {
            docUrl =
                '/document/' +
                urlRoleMapper[session.getMostSeniorRole().name] +
                '/download/' +
                docId[0].replace('DOC_ID_', '').replace('_DOC_ID', '');
            messageContent = _.replace(messageContent, docId[0], '');
        }

        return (
            <RowItem>
                <Container direction={side(isSender)}>
                    {isSender ? (
                        ''
                    ) : (
                        <Item xs={1} className={'message-admin-logo'}>
                            <ChatIcon icon={icon} text={iconText} subText={iconSubText} />
                        </Item>
                    )}
                    <Item xs={11}>
                        <Container>
                            <RowItem>
                                <Container justifyContent={isSender ? 'flex-end' : 'flex-start'}>
                                    <Tooltip title={status} enterDelay={300} placement="top">
                                        <BubbleText classNames={isSender ? 'outgoing top-right' : 'incoming top-left'}>
                                            <React.Fragment>
                                                <Item xs={12} className={`message-body`}>
                                                    <div
                                                        style={{ wordBreak: 'break-word' }}
                                                        dangerouslySetInnerHTML={{
                                                            __html: messageContent,
                                                        }}
                                                    />
                                                    {docId && docUrl && (
                                                        <div style={{ textAlign: 'right', marginRight: 10 }}>
                                                            <CloudDownloadIcon
                                                                fontSize={'large'}
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={this.downloadDocument({
                                                                    downloadUrl: docUrl,
                                                                    fileName: cleanName,
                                                                })}
                                                            />
                                                        </div>
                                                    )}
                                                </Item>
                                                <Item xs={12} className={`message-timestamp`}>
                                                    {message.getSentAt() && formatDate(message.getSentAt(), dateFormat)}
                                                </Item>
                                            </React.Fragment>
                                        </BubbleText>
                                    </Tooltip>
                                </Container>
                            </RowItem>
                        </Container>
                    </Item>
                </Container>
            </RowItem>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
    };
};

export default compose(withSession, connect(mapStateToProps, mapDispatchToProps))(Message);
