import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DashboardContainer from '../../dashboard/containers/DashboardContainer';
import ConfigureProfileContainer from '../../../user/configure_profile/containers/ConfigureProfileContainer';
import LenderLoanEnquiriesContainer from '../../loan_enquiries/containers/TableStyleContainer';
import LoanEnquiryViewContainer from '../../view_loan_enquiry/containers/MasonryStyleContainer';
import DocumentsContainer from '../../documents/containers/TableStyleContainer';
import LenderLoanEnquiryViewContainer from '../../lender_loan_enquiries_view/containers/Container';
import ApplicantsContainer from '../../applicants/containers/Container';
import TwoFactorAuthIndexContainer from '../../../user/two_factor_auth/containers/IndexContainer';

import {
    ROUTE_BORROWER_2FA_INDEX,
    ROUTE_BORROWER_APPLICANTS_VIEW,
    ROUTE_BORROWER_CONFIGURE_PROFILE,
    ROUTE_BORROWER_CREATE_LOAN_ENQUIRY,
    ROUTE_BORROWER_DOCUMENTS_VIEW,
    ROUTE_BORROWER_EDIT_LOAN_ENQUIRY,
    ROUTE_BORROWER_LOAN_ENQUIRIES,
    ROUTE_BORROWER_LENDER_LOAN_ENQUIRY_VIEW,
    ROUTE_BORROWER_LOAN_ENQUIRY_VIEW,
    ROUTE_BORROWER_ROOT,
} from '../../../../pitch4_routing';
import MakeLoanEnquiryContainer from '../../../MakeLoanEnquiry/containers/MakeLoanEnquiryContainer';
class ContentContainer extends React.Component {
    render() {
        return (
            <div id="content">
                <Switch>
                    {/*<Route path={ROUTE_BORROWER_CREATE_LOAN_ENQUIRY} render={() => <CreateLoanEnquiryContainer />} />*/}
                    <Route path={ROUTE_BORROWER_EDIT_LOAN_ENQUIRY} render={() => <MakeLoanEnquiryContainer />} />
                    <Route path={ROUTE_BORROWER_CREATE_LOAN_ENQUIRY} render={() => <MakeLoanEnquiryContainer />} />
                    <Route
                        path={ROUTE_BORROWER_LENDER_LOAN_ENQUIRY_VIEW}
                        render={() => <LenderLoanEnquiryViewContainer />}
                    />
                    <Route path={ROUTE_BORROWER_APPLICANTS_VIEW} render={() => <ApplicantsContainer />} />
                    <Route path={ROUTE_BORROWER_LOAN_ENQUIRY_VIEW} render={() => <LoanEnquiryViewContainer />} />
                    <Route path={ROUTE_BORROWER_LOAN_ENQUIRIES} render={() => <LenderLoanEnquiriesContainer />} />
                    <Route path={ROUTE_BORROWER_DOCUMENTS_VIEW} render={() => <DocumentsContainer />} />
                    <Route path={ROUTE_BORROWER_CONFIGURE_PROFILE} render={() => <ConfigureProfileContainer />} />
                    <Route path={ROUTE_BORROWER_2FA_INDEX} render={() => <TwoFactorAuthIndexContainer />} />
                    <Route path={ROUTE_BORROWER_ROOT} render={() => <DashboardContainer />} />
                </Switch>
            </div>
        );
    }
}

ContentContainer.propTypes = {};

export default ContentContainer;
