// See https://github.com/erikras/ducks-modular-redux
export const NAMESPACE = 'header.account-menu';

// Actions
export const ACCOUNT_MENU_OPEN = `${NAMESPACE}.open`;
export const ACCOUNT_MENU_CLOSE = `${NAMESPACE}.close`;

export const actionAccountMenuOpen = () => {
    return {
        type: ACCOUNT_MENU_OPEN,
    };
};

export const actionAccountMenuClose = () => {
    return {
        type: ACCOUNT_MENU_CLOSE,
    };
};

const initialState = {
    open: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ACCOUNT_MENU_OPEN:
            return {
                ...state,
                open: true,
            };

        case ACCOUNT_MENU_CLOSE:
            return {
                ...state,
                open: false,
            };

        default:
            return { ...state };
    }
};

// Selectors
export const selectorIsOpen = (state) => {
    return state[NAMESPACE].open;
};

// Default export reducer
export default reducer;
