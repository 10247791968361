import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import _ from 'lodash';

class SecondaryButton extends React.Component {
    render() {
        let { className, children, name = null, inputRef = () => null, style = {}, ...other } = this.props;

        const baseClassNames = 'secondary-button';

        if (name !== null && _.includes(['development', 'test'], process.env.NODE_ENV)) {
            other['data-cy'] = `${name}-input`;
        }

        return (
            <Button
                ref={inputRef}
                variant="contained"
                color="primary"
                {...other}
                className={`${baseClassNames} ${className}`}
                style={style}
            >
                {children}
            </Button>
        );
    }
}

SecondaryButton.propTypes = {
    classes: PropTypes.any,
    className: PropTypes.any,
    children: PropTypes.any,
    inputRef: PropTypes.any,
};

export default SecondaryButton;
