import React from 'react';
import { Container, Item } from 'pitch4_layout/components/div';
import PropTypes from 'prop-types';

// ANW This class has been maintained for Backwards Compatibility. It can be removed when it is no longer used by the code.
class CommandContainer extends React.Component {
    render() {
        let { children } = this.props;
        return (
            <Container className={'command-container'} justifyContent={`flex-end`}>
                {React.Children.map(children, (item, index) => (
                    <Item key={index}>{item}</Item>
                ))}
            </Container>
        );
    }
}

CommandContainer.propTypes = {
    children: PropTypes.any,
};

export default CommandContainer;
