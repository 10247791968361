import createP4ApolloFetch from 'bootstrap/middlewares';

const getDebugLendersQuery = `
    query getMatchDebugLenders{
        getMatchDebugLenders {
            id
            name
            status
            label
            value
        }
    }
`;

export const getDebugLenders = (uri) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    return apolloFetch({
        query: getDebugLendersQuery,
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            if (error) {
                throw error;
            }

            return error;
        });
};
