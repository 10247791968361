import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Field } from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CheckboxMUI from '@material-ui/core/Checkbox/Checkbox';
import _ from 'lodash';

class CheckboxMultiValueGroup extends React.Component {
    field = ({ input, meta, options }) => {
        const { onChange, onBlur, onFocus } = input;
        const { name } = this.props;
        const { touched, error } = meta;
        const inputValue = input.value;

        const checkboxes = options.map(({ label, value }, index) => {
            const handleChange = (event) => {
                let values = [...inputValue];
                if (event.target.checked) {
                    values.push(value);
                } else {
                    values.splice(values.indexOf(value), 1);
                }

                if (value === 'select_all' && false === event.target.checked) {
                    values = [];
                } else if (value === 'select_all' && true === event.target.checked) {
                    values = [];
                    options.map((option) => values.push(option.value));
                }

                // If a non select_all checkbox has been unchecked, also uncheck select_all
                if (
                    value !== 'select_all' &&
                    false === event.target.checked &&
                    true === values.includes('select_all')
                ) {
                    values.splice(values.indexOf('select_all'), 1);
                }

                if (values.length === 0) {
                    // Set redux form state to empty string when no checkboxes are selected otherwise it
                    // thinks a value is set and required validation does not fire.
                    values = '';
                }

                onBlur(values);
                return onChange(values);
            };

            const checked = inputValue.includes(value);

            const cbName = [name, value].join('.');
            const inputProps = {};
            if (_.includes(['development', 'qa', 'test'], process.env.NODE_ENV)) {
                inputProps['data-cy'] = `${cbName}-input`;
            }

            return (
                <div className={`checkbox`} key={name + '-' + index}>
                    <FormControlLabel
                        key={name + '-' + index}
                        control={
                            <CheckboxMUI
                                key={name + '-' + index}
                                name={cbName}
                                onChange={handleChange}
                                onFocus={onFocus}
                                defaultChecked={checked}
                                checked={checked}
                                inputProps={inputProps}
                            />
                        }
                        label={checked && value === 'select_all' ? 'Deselect all' : label}
                    />
                </div>
            );
        });

        return (
            <div>
                <div className={options.length > 5 ? 'checkbox-columns' : ''}>{checkboxes}</div>
                {error && touched && <FormHelperText style={{ color: 'red' }}>{error}</FormHelperText>}
            </div>
        );
    };

    render() {
        return <Field {...this.props} type="checkbox" component={this.field} />;
    }
}

CheckboxMultiValueGroup.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ).isRequired,
};

export default CheckboxMultiValueGroup;
