import { call, delay, put, race, take } from 'redux-saga/effects';

import { getMatches } from '../api';

import {
    actionSetApiGetLoanEnquiryMatchesResponse,
    actionStopPollingMatches,
    CLEANUP,
    INITIATE,
    STOP_POLLING_MATCHES,
} from '../redux/main';
import { STATUS_MATCHING } from '../../enum/LoanEnquiries/StatusEnum';

function* pollMatches(action) {
    while (true) {
        try {
            const response = yield call(getMatches, action.loanEnquiryId);
            yield put(actionSetApiGetLoanEnquiryMatchesResponse(response));
            let pollDelay = 120000;
            if (
                response.loanEnquiryMatch &&
                response.loanEnquiryMatch.loanEnquiry.status === STATUS_MATCHING &&
                true === response.loanEnquiryMatch.loanEnquiry.contractSigned
            ) {
                pollDelay = 5000;
            }
            yield delay(pollDelay);
        } catch (err) {
            yield put(actionStopPollingMatches());
        }
    }
}

export default function* pollMatchesWatch() {
    while (true) {
        const action = yield take(INITIATE);
        yield race({
            task: call(pollMatches, action),
            cancel: take([STOP_POLLING_MATCHES, CLEANUP]),
        });
    }
}
