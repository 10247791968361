import React from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';
import { TextField as MUITextField } from 'redux-form-material-ui';

class TextField extends React.Component {
    render() {
        let {
            name = '',
            value = '',
            autoComplete = 'off',
            fullWidth = true,
            InputProps = { disableUnderline: true },
            placeholder = '',
            ...rest
        } = this.props;

        if (_.includes(['development', 'qa', 'test'], process.env.NODE_ENV)) {
            InputProps['data-cy'] = `${name}-input`;
        }

        return (
            <div className={`text-field ${fullWidth ? 'block' : 'inline-block'}`}>
                <Field
                    type={`text`}
                    name={name}
                    variant={`outlined`}
                    autoComplete={autoComplete}
                    component={MUITextField}
                    fullWidth={fullWidth}
                    InputProps={InputProps}
                    value={value}
                    placeholder={placeholder}
                    {...rest}
                />
            </div>
        );
    }
}

export default TextField;
