import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { CommercialTypeEnum } from '../../../../admin/enum/matching/enum';
import {
    QUESTION_COMMERCIAL_TYPE_OTHER,
    QUESTION_COMMERCIAL_TYPES,
    QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE,
    QUESTION_DEVELOPMENT_LENGTH,
    QUESTION_EXPECTED_END_VALUE,
    QUESTION_FIRST_CHARGE_AMOUNT,
    QUESTION_LENDER,
    QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD,
    QUESTION_PLANNING_IN_PLACE,
    QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
    QUESTION_PROPERTY_TYPE,
    QUESTION_SITE_INTENTIONS_MEZZANINE,
    QUESTION_TOTAL_DEVELOPMENT_COSTS,
} from '../../../enums/Borrower/BorrowerQuestions';
import YesNo from '../../../enums/Shared/YesNo';
import Question from '../../Question/containers/Question';
import { COMMERCIAL_FUNDING_TYPE, SEMI_COMMERCIAL_FUNDING_TYPE } from '../../../enums/Shared/CommercialFundingType';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BorrowerQuestions from '../../../enums/Borrower/BorrowerQuestions';
import BrokerQuestions, { QUESTION_COMMERCIAL_TYPES_REFURBISHMENT } from '../../../enums/Broker/BrokerQuestions';
import { OTHER } from '../../../enums/Shared/PropertyLocation';

class MezzanineSelectionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        const commercialTypeOptions = new CommercialTypeEnum().getOptions();
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const yesNoOptions = new YesNo().getTypes();

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER
                            ? 'About your Mezzanine Finance'
                            : 'About your clients Mezzanine Finance'}
                    </Typography>
                </Grid>
                <Question
                    type={questions[QUESTION_PLANNING_IN_PLACE].type}
                    label={questions[QUESTION_PLANNING_IN_PLACE].label}
                    questionEnum={'QUESTION_PLANNING_IN_PLACE'}
                    fieldName={questions[QUESTION_PLANNING_IN_PLACE].fieldName}
                    placeholder={questions[QUESTION_PLANNING_IN_PLACE].placeholder}
                    validation={questions[QUESTION_PLANNING_IN_PLACE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_FIRST_CHARGE_AMOUNT].type}
                    label={questions[QUESTION_FIRST_CHARGE_AMOUNT].label}
                    questionEnum={'QUESTION_FIRST_CHARGE_AMOUNT'}
                    fieldName={questions[QUESTION_FIRST_CHARGE_AMOUNT].fieldName}
                    placeholder={questions[QUESTION_FIRST_CHARGE_AMOUNT].placeholder}
                    validation={questions[QUESTION_FIRST_CHARGE_AMOUNT].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_LENDER].type}
                    label={questions[QUESTION_LENDER].label}
                    questionEnum={'QUESTION_LENDER'}
                    fieldName={questions[QUESTION_LENDER].fieldName}
                    placeholder={questions[QUESTION_LENDER].placeholder}
                    validation={questions[QUESTION_LENDER].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_SITE_INTENTIONS_MEZZANINE].type}
                    label={questions[QUESTION_SITE_INTENTIONS_MEZZANINE].label}
                    questionEnum={'QUESTION_SITE_INTENTIONS_MEZZANINE'}
                    fieldName={questions[QUESTION_SITE_INTENTIONS_MEZZANINE].fieldName}
                    placeholder={questions[QUESTION_SITE_INTENTIONS_MEZZANINE].placeholder}
                    validation={questions[QUESTION_SITE_INTENTIONS_MEZZANINE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={questions[QUESTION_SITE_INTENTIONS_MEZZANINE].rows}
                />
                <Question
                    type={questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].type}
                    label={questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].label}
                    questionEnum={'QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE'}
                    fieldName={questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].fieldName}
                    placeholder={questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].placeholder}
                    validation={questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].type}
                    label={questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].label}
                    questionEnum={'QUESTION_TOTAL_DEVELOPMENT_COSTS'}
                    fieldName={questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName}
                    placeholder={questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].placeholder}
                    validation={questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_EXPECTED_END_VALUE].type}
                    label={questions[QUESTION_EXPECTED_END_VALUE].label}
                    questionEnum={'QUESTION_EXPECTED_END_VALUE'}
                    fieldName={questions[QUESTION_EXPECTED_END_VALUE].fieldName}
                    placeholder={questions[QUESTION_EXPECTED_END_VALUE].placeholder}
                    validation={questions[QUESTION_EXPECTED_END_VALUE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_DEVELOPMENT_LENGTH].type}
                    label={questions[QUESTION_DEVELOPMENT_LENGTH].label}
                    questionEnum={'QUESTION_DEVELOPMENT_LENGTH'}
                    fieldName={questions[QUESTION_DEVELOPMENT_LENGTH].fieldName}
                    placeholder={questions[QUESTION_DEVELOPMENT_LENGTH].placeholder}
                    validation={questions[QUESTION_DEVELOPMENT_LENGTH].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].type}
                    label={questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].label}
                    questionEnum={'QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE'}
                    fieldName={questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName}
                    placeholder={questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].placeholder}
                    validation={questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />

                {this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE && (
                    <Question
                        type={questions[QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD].type}
                        label={questions[QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD].label}
                        questionEnum={'QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD'}
                        fieldName={questions[QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD].fieldName}
                        placeholder={questions[QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD].placeholder}
                        validation={questions[QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={[]}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}

                {(this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE ||
                    this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] ===
                        SEMI_COMMERCIAL_FUNDING_TYPE) && (
                    <>
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_COMMERCIAL_TYPES_REFURBISHMENT].type}
                            label={questions[QUESTION_COMMERCIAL_TYPES_REFURBISHMENT].label}
                            questionEnum={'QUESTION_COMMERCIAL_TYPES_REFURBISHMENT'}
                            fieldName={questions[QUESTION_COMMERCIAL_TYPES_REFURBISHMENT].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_TYPES_REFURBISHMENT].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_TYPES_REFURBISHMENT].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={commercialTypeOptions}
                            rows={0}
                        />
                        {this.state.formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) && (
                            <Question
                                formData={this.state.formData}
                                formValidation={this.state.validation}
                                type={questions[QUESTION_COMMERCIAL_TYPE_OTHER].type}
                                label={questions[QUESTION_COMMERCIAL_TYPE_OTHER].label}
                                questionEnum={'QUESTION_COMMERCIAL_TYPE_OTHER'}
                                fieldName={questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName}
                                placeholder={questions[QUESTION_COMMERCIAL_TYPE_OTHER].placeholder}
                                validation={questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'}
                                onChangeFunction={this.props.onChangeHandler}
                                fieldOptions={[]}
                                rows={0}
                            />
                        )}
                    </>
                )}
            </Grid>
        );
    }
}

export default MezzanineSelectionContainer;
