import React from 'react';
import { getConfigFieldLabel, getConfigFieldTooltip } from '../../../apps/form_wizard/utils/configFieldLookup';
import { ADDITIONAL_INFO_NOTES } from '../../../pitch4_enum/enum/FactEnum';
import Tooltip from '../../../apps/utils/tooltip/Tooltip';

class TextLabel extends React.Component {
    render() {
        let { label, className = null, sectionName = null, sectionConfig = null } = this.props;

        if (className) {
            className += ' text-label';
        } else {
            className = 'text-label';
        }

        if (sectionName === 'AdditionalInformation') {
            const label = getConfigFieldLabel(sectionConfig, ADDITIONAL_INFO_NOTES);
            const tooltip = getConfigFieldTooltip(sectionConfig, ADDITIONAL_INFO_NOTES);

            return (
                <div
                    style={{
                        paddingRight: 80,
                    }}
                >
                    <span
                        className={className}
                        dangerouslySetInnerHTML={{
                            __html: label,
                        }}
                    />
                    {tooltip && <Tooltip content={tooltip} />}
                </div>
            );
        }

        return <span className={className}>{label}</span>;
    }
}

export default TextLabel;
