import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import ModalTwoPaneLayout from 'pitch4_modal/src/component/ModalTwoPaneLayout';
import { Item, RowItem, RightAligned } from 'pitch4_layout/components/div';
import Container from 'pitchblack_react_utils/layout/Container';
import FormMessage, { MESSAGE_ERROR } from 'pitch4_forms/components/FormMessage';
import { PrimaryButton, SecondaryButton } from 'pitch4_layout/components/buttons';
import { Select } from 'pitch4_forms/components/inputs';
import completionRejectedImage from '../../../../../assets/images/dashboard-completion.svg';
import { selectorGetSubmitted, selectorAreErrorsVisible, selectorSetStatusSubmissionErrors } from '../redux/main';
import { isRequired } from 'pitchblack_react_utils/form/validation';
import StatusEnum from '../../enum/LenderLoanEnquiry/StatusEnum';
import { createOptionsFromEnumArray } from 'pitch4_forms/components/inputs/select/index';
import { TextArea } from '../../../../../pitch4_forms/components/inputs';
const leadStatusEnum = new StatusEnum();

const AdministerCompletionRejectionForm = ({
    errors = [],
    errorsVisible,
    handleSubmit,
    handleClose,
    submitting,
    submitted,
}) => {
    return (
        <ModalTwoPaneLayout
            infoPane={
                <>
                    <img className="info-pane-image" src={completionRejectedImage} alt="Completion Rejected" />
                    <h3 className="typography-card-title">Has this enquiry been completed?</h3>
                    <p className="typography-default">
                        To close this dispute please mark this as completed or select a status to roll-back to.
                    </p>
                    <p className="typography-default">
                        Please ensure the ticket is updated within HubSpot and <strong>both</strong> parties are
                        contacted to inform them of the decision.
                    </p>
                </>
            }
            formPane={
                <>
                    <Item className={'content-container'}>
                        <Container className={'two-pane-form'}>
                            <h2 className="typography-card-title two-pane-form__title">
                                Please choose and confirm the correct status for this lead
                            </h2>
                            {errorsVisible &&
                                errors.map((error) => (
                                    <FormMessage type={MESSAGE_ERROR} messages={error} key={error} />
                                ))}
                            <form onSubmit={handleSubmit} className={'two-pane-form__form'}>
                                <RowItem>
                                    <Select
                                        name="newStatus"
                                        sortLabels={false}
                                        fullWidth={true}
                                        placeholder="New status"
                                        style={{ padding: 0 }}
                                        options={createOptionsFromEnumArray(leadStatusEnum.getTransitionableTypes())}
                                        validate={isRequired}
                                    />
                                </RowItem>
                                <RowItem>
                                    <TextArea
                                        inputProps={{
                                            className: 'typography-default',
                                            style: { fontSize: '14px', height: '130px' },
                                        }}
                                        name="metadata"
                                        placeholder="Notes"
                                    />
                                </RowItem>
                                <RowItem>
                                    <RightAligned className={'button-panel'}>
                                        <SecondaryButton
                                            onClick={handleClose}
                                            name={'cancel-btn'}
                                            disabled={submitting}
                                        >
                                            Cancel
                                        </SecondaryButton>
                                        <PrimaryButton type="submit" name={'next-btn'} disabled={submitting}>
                                            Confirm
                                        </PrimaryButton>
                                    </RightAligned>
                                </RowItem>
                            </form>
                        </Container>
                    </Item>
                </>
            }
        />
    );
};

AdministerCompletionRejectionForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.array,
    errorsVisible: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitted: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        submitted: selectorGetSubmitted(state),
        errors: selectorSetStatusSubmissionErrors(state),
        errorsVisible: selectorAreErrorsVisible(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'administer_completion_rejection',
    })
)(AdministerCompletionRejectionForm);
