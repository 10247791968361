import React from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';

const Menu = ({ id, children, className, ...props }) => {
    return (
        <List id={id} className={className}>
            {children}
        </List>
    );
};

Menu.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.any,
    children: PropTypes.any.isRequired,
};

export default Menu;
