import { BORROWER_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

const LenderLoanEnquirySetStatusesMutation = `
  mutation setStatuses($input: [setLenderLoanEnquiryStatusesInput!]!){
    setLenderLoanEnquiryStatuses(input: $input){
      leads {
        id
        status
      }
    }
  }
`;

export const setLenderLoanEnquiryStatuses = (input) => {
    return apolloFetch({
        query: LenderLoanEnquirySetStatusesMutation,
        variables: {
            input,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
