import { formatRoute } from 'react-router-named-routes';
import SessionManager from '../../pitchblack_react_utils/session/SessionManager';
import { ROUTE_BORROWER_ROOT, ROUTE_INTERMEDIARY_ROOT, ROUTE_P4A_ROOT, ROUTE_SLAM_ROOT } from '../index';
import {
    ROLE_BORROWER,
    ROLE_INTERMEDIARY,
    ROLE_SLAM,
    ROLE_SUPER_ADMIN,
} from '../../apps/authentication/enum/Roles/NameEnum';

export const path = (name, parameters = {}) => {
    return formatRoute(name, parameters);
};

export const determineAuthenticatedBaseRoute = () => {
    const sessionManager = new SessionManager();

    if (sessionManager.isAuthenticated() === false) {
        return;
    }

    let role = sessionManager.getRelevantSession().getMostSeniorRole();

    if (role && role.name) {
        switch (role.name) {
            case ROLE_SUPER_ADMIN:
                return ROUTE_P4A_ROOT;
            case ROLE_SLAM:
                return ROUTE_SLAM_ROOT;
            case ROLE_BORROWER:
                return ROUTE_BORROWER_ROOT;
            case ROLE_INTERMEDIARY:
                return ROUTE_INTERMEDIARY_ROOT;
            default:
                break;
        }
    }
};
