import createP4ApolloFetch from 'bootstrap/middlewares';
import { ANON_URL } from '../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: ANON_URL });

const mutation = `
    mutation FlagPasswordResetMutation($input: anon_flagForgottenPasswordInput!) {
        flagForgottenPassword(input: $input){
            result
        }
    }
`;

export const flagPasswordReset = (email) => {
    return apolloFetch({
        query: mutation,
        variables: {
            input: {
                email,
            },
        },
    })
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            return {};
        });
};
