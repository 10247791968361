import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import _ from 'lodash';

class PrimaryButton extends React.Component {
    render() {
        let {
            className,
            children,
            inputRef = () => null,
            color = null,
            name = null,
            disabled = false,
            style = {},
            ...other
        } = this.props;

        const baseClassNames = 'primary-button';

        if (color === null) {
            color = 'primary';
        }

        if (name !== null && _.includes(['development', 'test'], process.env.NODE_ENV)) {
            other['data-cy'] = `${name}-input`;
        }

        return (
            <Button
                style={style}
                disabled={disabled}
                ref={inputRef}
                variant="contained"
                color={color}
                {...other}
                className={`${baseClassNames} ${className}`}
            >
                {children}
            </Button>
        );
    }
}

PrimaryButton.propTypes = {
    classes: PropTypes.any,
    className: PropTypes.any,
    children: PropTypes.any,
    inputRef: PropTypes.any,
};

export default PrimaryButton;
