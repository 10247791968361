import { BaseEnum } from '../../../../../pitch4_enum';

export const ALL_TIME = 'ALL_TIME';
export const PAST_30_DAYS = 'PAST_30_DAYS';
export const PAST_60_DAYS = 'PAST_60_DAYS';
export const PAST_6_MONTHS = 'PAST_6_MONTHS';

class LoanTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [ALL_TIME]: 'All time',
            [PAST_30_DAYS]: 'Past 30 days',
            [PAST_60_DAYS]: 'Past 60 days',
            [PAST_6_MONTHS]: 'Past 6 months',
        };
    };

    getType = (type) => this.getTypes()[type];

    getFieldsMapping = () => {
        return {
            [ALL_TIME]: 'allTime',
            [PAST_30_DAYS]: 'oneMonth',
            [PAST_60_DAYS]: 'twoMonths',
            [PAST_6_MONTHS]: 'sixMonths',
        };
    };

    getFieldMapping = (field) => this.getFieldsMapping()[field];
}

export default LoanTypeEnum;
