import React from 'react';
import { Grid } from '@material-ui/core';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { getValue, getTermName } from '../../../../../../pitch4_elements/terms/Utils';

export default function TermItem(props) {
    const { item, isLast } = props;

    return (
        <Grid className={`item ${true === isLast ? 'last' : ''}`} item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={3} md={3} sm={3} xs={3} className={`name ${null !== item.value ? 'filled' : 'empty'}`}>
                {getTermName(item)}
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3} className={'optional'}>
                {item.isOptional && 'Optional'}
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3} className={'value'}>
                {null !== item.value ? <CheckCircleRoundedIcon className={'filled'} /> : <div className={'empty'} />}
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={3}>
                {getValue(item)}
            </Grid>
        </Grid>
    );
}
