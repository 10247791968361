import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import StarIcon from '../../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../../assets/images/star-gray.svg';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { ASSETS_LOGO_URL, INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChatContainer from '../../chat/containers/ChatContainer';
import { STATUS_ENDED } from '../../enum/LenderLoanEnquiries/StatusEnum';
import StatusEnum, {
    STATUS_ON_HOLD as LE_STATUS_ON_HOLD,
    STATUS_WITHDRAWN as LE_STATUS_WITHDRAWN,
} from '../../enum/LoanEnquiries/StatusEnum';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import DocumentList from '../../view_lender_loan_enquiry/components/DocumentList';
import { Chip } from '@material-ui/core';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getLenderLoanEnquiry } from '../api/get_ll_enquiry';
import DetailTerms from '../../../../../pitch4_elements/terms/DetailTerms';
import FeeTerms from '../../../../../pitch4_elements/terms/FeeTerms';
import RequestLenderDetails from '../../../../../pitch4_elements/terms/RequestLenderDetails';
import MainInterestRate from '../../../../../pitch4_elements/terms/MainInterestRate';
import TopTerms from '../../../../../pitch4_elements/terms/TopTerms';
import { clearLenderTermsUpdatedAlerts } from '../../../../../pitch4_api/lender_terms_alerts';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const statusEnum = new StatusEnum();

class LenderDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            matches: props.matches,
            matchSelected: null,
            chatChannel: null,
            loanEnquiry: props.loanEnquiry,
            lenderTermsUpdatedAlertActive: false,
        };
    }

    componentDidMount() {
        const { lenderLoanEnquiry } = this.props;
        const lle = this.state.matches.find((match) => match.id === lenderLoanEnquiry.id);

        this.setState({ matchSelected: lle }, () => {
            this.getLLEData();
        });
    }

    buildChat = () => {
        const { matchSelected, chatChannel } = this.state;
        const { handleRemoveAlertMatchAlert } = this.props;
        const isGloballyDisabled = matchSelected.loanEnquiry ? matchSelected.loanEnquiry.status === STATUS_ENDED : true;
        const enquiryWithdrawn = matchSelected.loanEnquiry
            ? matchSelected.loanEnquiry.status === LE_STATUS_WITHDRAWN
            : true;
        const enquiryOnHold = matchSelected.loanEnquiry ? matchSelected.loanEnquiry.status === LE_STATUS_ON_HOLD : true;

        if (chatChannel) {
            // Remove 'Update' alert badge
            if (matchSelected.alertActive && this.state.tabValue === 1) {
                this.setState({ matchSelected: { ...this.state.matchSelected, alertActive: false } }, () => {
                    // handleRemoveAlertMatchAlert method is executed in src/apps/borrower/view_loan_enquiry/containers/LeadsContainer.js
                    handleRemoveAlertMatchAlert(this.state.matchSelected);
                });
            }

            return (
                <ChatContainer
                    chatLocation={'sidetray'}
                    channelId={chatChannel}
                    disableForm={enquiryWithdrawn || isGloballyDisabled || enquiryOnHold}
                />
            );
        }

        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 100 }}>
                <CircularProgress />
            </div>
        );
    };

    buildDocumentList = (lenderLoanEnquiry) => {
        const { session } = this.props;
        const { matchSelected } = this.state;

        const isGloballyDisabled = matchSelected.loanEnquiry ? matchSelected.loanEnquiry.status === STATUS_ENDED : true;
        const enquiryWithdrawn = matchSelected.loanEnquiry
            ? matchSelected.loanEnquiry.status === LE_STATUS_WITHDRAWN
            : true;
        const enquiryOnHold = matchSelected.loanEnquiry ? matchSelected.loanEnquiry.status === LE_STATUS_ON_HOLD : true;

        if (lenderLoanEnquiry.loanEnquiry) {
            return (
                <div className={'document-list'}>
                    <DocumentList
                        componentContainerStyles={{ boxShadow: 'none', width: '100%' }}
                        docActionsStyles={{ display: 'flex', justifyContent: 'flex-end' }}
                        docTypeStyles={{ fontWeight: 600 }}
                        showIcon={false}
                        disabled={isGloballyDisabled || session.hasAssumedToken() || enquiryOnHold}
                        loanEnquiry={matchSelected.loanEnquiry}
                        lenderLoanEnquiry={matchSelected}
                        disableUploadButton={
                            enquiryWithdrawn || isGloballyDisabled || session.hasAssumedToken() || enquiryOnHold
                        }
                    />
                </div>
            );
        }

        return <></>;
    };

    handleFavouriteMatchChange = (match) => {
        const { handleFavouriteMatchChange } = this.props;
        const { matchSelected } = this.state;

        handleFavouriteMatchChange(match.id, null, matchSelected.favourite)();
        this.setState({
            matchSelected: {
                ...this.state.matchSelected,
                ...{
                    favourite: !matchSelected.favourite,
                },
            },
        });
    };

    handlePrevious = () => {
        const { matchSelected, matches } = this.state;
        const matchIndex = matches.findIndex((match) => (matchSelected ? match.id === matchSelected.id : null));
        const nextMatchIndex = matchIndex === 0 ? matches.length - 1 : matchIndex - 1;

        this.setState({ matchSelected: null, chatChannel: null }, () => {
            this.setState({ matchSelected: matches[nextMatchIndex] }, () => {
                this.getLLEData();
            });
        });
    };

    handleNext = () => {
        const { matchSelected, matches } = this.state;
        const matchIndex = matches.findIndex((match) => (matchSelected ? match.id === matchSelected.id : null));
        const nextMatchIndex = matchIndex + 1 === matches.length ? 0 : matchIndex + 1;

        this.setState({ matchSelected: null, chatChannel: null }, () => {
            this.setState({ matchSelected: matches[nextMatchIndex] }, () => {
                this.getLLEData();
            });
        });
    };

    getLLEData = () => {
        const { matchSelected } = this.state;

        getLenderLoanEnquiry(matchSelected.id).then((resp) => {
            this.setState({
                matchSelected: {
                    ...{
                        ...resp.lenderLoanEnquiry,
                        lenderTerms: JSON.parse(resp.lenderLoanEnquiry.lenderTerms),
                    },
                    ...matchSelected,
                },
                chatChannel: resp.lenderLoanEnquiry.channel.id,
                lenderTermsUpdatedAlertActive: resp.lenderLoanEnquiry.lenderTermsUpdatedAlertActive === true,
            });
        });
    };

    buildChatLabel = () => {
        const { matchSelected } = this.state;

        if (matchSelected.alertActive) {
            return (
                <span>
                    Lender Chat <Badge label={'update'} style={{ marginLeft: 5 }} />
                </span>
            );
        }

        return 'Lender Chat';
    };

    buildTermsLabel = () => {
        const { lenderTermsUpdatedAlertActive } = this.state;

        if (lenderTermsUpdatedAlertActive) {
            return (
                <span>
                    Term Details & Fees <Badge label={'update'} style={{ marginLeft: 5 }} />
                </span>
            );
        }

        return 'Term Details & Fees';
    };

    handleClearLenderTermsUpdatedAlerts = (leId = null, lleId = null) => {
        if (leId && lleId) {
            clearLenderTermsUpdatedAlerts(leId, lleId, INTERMEDIARY_URL).then((resp) => {
                this.setState({
                    lenderTermsUpdatedAlertActive: false,
                });
            });
        }
    };

    render() {
        const { anchor, open, toggleLenderDrawer, loanType } = this.props;
        const { matchSelected, matches, chatChannel, loanEnquiry } = this.state;
        const matchIndex = matches.findIndex((match) => (matchSelected ? match.id === matchSelected.id : null));

        const handleTabChange = (event, newValue) => {
            this.setState({ tabValue: newValue });
            // Clear alert badges
            if (newValue === 1) {
                this.handleClearLenderTermsUpdatedAlerts(matchSelected.loanEnquiry.id, matchSelected.id);
            }
        };

        const arrowsStyles = chatChannel
            ? {
                  cursor: 'pointer',
              }
            : {
                  color: '#C7C7C7',
              };

        return (
            <Drawer variant={'temporary'} anchor={anchor} open={open} className={'lender-profile-modal'}>
                {matchSelected && (
                    <>
                        <div className={'lender-drawer-profile-icons'}>
                            <div style={{ display: 'flex', alignItems: 'center', fontSize: 16 }}>
                                {matchIndex + 1} of {matches.length}
                                <ArrowLeftIcon
                                    style={arrowsStyles}
                                    fontSize={'large'}
                                    onClick={chatChannel && this.handlePrevious}
                                />
                                <ArrowRightIcon
                                    style={arrowsStyles}
                                    fontSize={'large'}
                                    onClick={chatChannel && this.handleNext}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    style={{ position: 'unset', top: 'unset', marginRight: 55, marginLeft: 20 }}
                                    src={true === matchSelected.favourite ? StarIcon : StarGrayIcon}
                                    alt="Favourite"
                                    className={'favourite'}
                                    onClick={() => this.handleFavouriteMatchChange(matchSelected)}
                                />
                                <IconButton className={`close-button`} onClick={toggleLenderDrawer()}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                        <div className={'lender-profile-image'}>
                            {matchSelected.lender.profileLogo &&
                            null !== matchSelected.lender.lenderAnonName &&
                            matchSelected.lender.lenderName !== matchSelected.lender.lenderAnonName ? (
                                <div>
                                    <img
                                        alt={matchSelected.lender.name + ' Logo'}
                                        src={ASSETS_LOGO_URL.replace('{{lenderGuid}}', matchSelected.lender.id)}
                                        className={'lender-profile-logo'}
                                    />
                                </div>
                            ) : (
                                <div className={'lender-profile-title'}>
                                    <h5 className={'typography-page-title'}>{matchSelected.lender.name}</h5>
                                </div>
                            )}
                            <div style={{ color: '#888888' }}>{matchSelected.lender.anonName}</div>
                        </div>
                        <MainInterestRate matchSelected={matchSelected} />
                        <div style={{ padding: '0 30px' }}>
                            <Chip
                                className={`match-status ${matchSelected.status}`}
                                label={statusEnum.getType(matchSelected.status, loanType)}
                            />
                        </div>
                        <TopTerms matchSelected={matchSelected} loanEnquiry={loanEnquiry} />
                        <div style={{ paddingTop: 20, backgroundColor: 'white' }}>
                            <hr className={'tabs-indicator-top-line'} />
                            <Tabs
                                value={this.state.tabValue}
                                onChange={handleTabChange}
                                TabIndicatorProps={{
                                    children: (
                                        <div className={'tab-indicator-container'}>
                                            <div className={'tab-active-indicator'} />
                                        </div>
                                    ),
                                }}
                            >
                                <Tab label={this.buildChatLabel()} {...a11yProps(0)} className={'tab-title'} />
                                <Tab label={this.buildTermsLabel()} {...a11yProps(1)} className={'tab-title'} />
                                <Tab label={'Documents'} {...a11yProps(2)} className={'tab-title'} />
                            </Tabs>
                            <TabPanel value={this.state.tabValue} index={0}>
                                {this.buildChat()}
                            </TabPanel>
                            <TabPanel className={'detail-terms-container'} value={this.state.tabValue} index={1}>
                                <DetailTerms matchSelected={matchSelected} loanEnquiry={loanEnquiry} />
                                <div className={'divider'} />
                                <FeeTerms matchSelected={matchSelected} loanEnquiry={loanEnquiry} />
                                <RequestLenderDetails
                                    chatChannel={chatChannel}
                                    handleTabChange={handleTabChange}
                                    chatTabIndex={0}
                                    sendMessageURL={INTERMEDIARY_URL}
                                />
                            </TabPanel>
                            <TabPanel value={this.state.tabValue} index={2}>
                                {this.buildDocumentList(matchSelected)}
                            </TabPanel>
                        </div>
                    </>
                )}
            </Drawer>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(LenderDrawer);
