import { Bar } from 'react-chartjs-2';
import React from 'react';
import moment from 'moment';

export default function BarChart(props) {
    const { data, year } = props;
    const months = moment.monthsShort();
    const darkGrey = 'rgba(96, 96, 96, 1)';
    const orange = 'rgba(46, 196, 182, 1)';
    const grey = 'rgb(156,169,178)';
    const getChartBarColor = () => {
        const colors = [];
        months.map((month) =>
            month === moment().format('MMM') && year === moment().year() ? colors.push(orange) : colors.push(darkGrey)
        );
        return colors;
    };
    //Sums up all previous numbers so we see accumulative totals per month
    const sumItems = (data) => {
        let sums = [];

        data.forEach((item, index) => {
            if (index === 0) {
                sums.push(item);
            } else {
                sums.push(item + sums[index - 1]);
            }
        });

        return sums;
    };
    const chartData = {
        labels: months,
        datasets: [
            {
                label: 'New per month',
                data: data,
                backgroundColor: getChartBarColor(),
            },
            {
                label: 'Total per year',
                data: sumItems(data),
                backgroundColor: grey,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        plugins: {
            legend: {
                display: true,
            },
        },
    };

    return <Bar height={300} data={chartData} options={options} />;
}
