import React from 'react';
import { Container, Item } from 'pitch4_layout/components/div';
import { compose } from 'redux';
import withSession from 'pitchblack_react_utils/session/withSession';
import { TextField, TextLabel } from 'pitch4_forms/components/inputs';
import { reduxForm } from 'redux-form';
import Notice from '../../../../pitch4_layout/components/content/Notice';
import PrimaryButton from 'pitch4_layout/components/buttons/PrimaryButton';
import RightAligned from 'pitch4_layout/components/div/RightAligned';
import { resendVerificationEmail } from '../api/resend_verification_email';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../notifications/sagas/container';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class ResendVerificationEmailSection extends React.Component {
    onSubmit = async () => {
        const { notificationSuccess, notificationError } = this.props;
        const response = await resendVerificationEmail();
        if (_.get(response, ['data', 'resendVerificationEmail']) !== undefined) {
            if (response.data.resendVerificationEmail.success === true) {
                notificationSuccess('Verification email sent. Please check your inbox.');
                return;
            }
        }
        notificationError('Failed to send email.');
    };

    getEmail = () => {
        const { session } = this.props;
        const currentSession = session.getRelevantSession();
        return currentSession.getEmail();
    };

    render() {
        let { handleSubmit, submitting } = this.props;

        return (
            <Container className={`container`}>
                <form onSubmit={handleSubmit(this.onSubmit)} className={'full-width'}>
                    <Container>
                        <Item xs={12} md={6}>
                            <Notice
                                customIconColor={'#2ec4b6'}
                                message={
                                    <p>
                                        To verify your account you need to click the confirmation link in the email sent
                                        to your provided address.{' '}
                                        <strong>Use this form if you need this to be sent again.</strong>{' '}
                                    </p>
                                }
                            />
                        </Item>
                    </Container>
                    <Container>
                        <Item xs={12} md={2} className={`label-container`}>
                            <TextLabel label="Email Address" />
                        </Item>
                        <Item xs={12} md={4}>
                            <TextField
                                type={`text`}
                                name={'email_address'}
                                variant={`outlined`}
                                component={TextField}
                                fullWidth={true}
                                disabled={true}
                                placeholder={this.getEmail()}
                            />
                        </Item>
                    </Container>
                    <Item xs={12} md={12}>
                        <RightAligned className={'button-panel'}>
                            <PrimaryButton
                                name={`submit_resend_verification_email`}
                                type="submit"
                                disabled={submitting}
                            >
                                Resend
                            </PrimaryButton>
                        </RightAligned>
                    </Item>
                </form>
            </Container>
        );
    }
}

ResendVerificationEmailSection.propTypes = {
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'resend_verification_email',
    }),
    withSession
)(ResendVerificationEmailSection);
