import { fork, spawn } from 'redux-saga/effects';

import chatRootSaga from './chat';
import pollChannelMessagesWatch from './pollChannelMessages';

function* main() {
    yield spawn(chatRootSaga);
    yield fork(pollChannelMessagesWatch);
}

export default main;
