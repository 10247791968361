import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ResetPasswordForm from '../components/PasswordResetForm';
import { CenteringContainer, RightAligned } from '../../../pitch4_layout/components/div';
import { Container, Item, RowItem } from '../../../pitchblack_react_utils/layout';
import { Loading } from '../../../pitch4_layout/components/waiting';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import { ROUTE_ANON_LOGIN, ROUTE_REQUEST_PASSWORD_RESET } from '../../../pitch4_routing';
import {
    actionPasswordResetRequestInitialise,
    actionReset,
    actionResetPasswordSubmit,
    selectorLoading,
    selectorResetSubmitted,
    selectorResetSuccess,
    selectorSubmitErrors,
    selectorToken,
} from '../redux/passwordReset';
import { BRAND_NAME, PLATFORM_REGISTER_URL, TOS_URL, WEB_HOST } from '../../../bootstrap/constants/constants';
import { Paper } from '@material-ui/core';

class PasswordResetContainer extends React.Component {
    componentDidMount() {
        const { initialize, match } = this.props;
        initialize(match.params.token);
    }

    componentWillUnmount() {
        const { reset } = this.props;
        reset();
    }

    render() {
        return (
            <React.Fragment>
                <Item className={'content-container'}>{this.renderForm()}</Item>
                <Container className={'footer-container'}>
                    <React.Fragment>
                        <CenteringContainer className={'links'}>
                            <a href={WEB_HOST}>About {BRAND_NAME}</a>
                            <a href={PLATFORM_REGISTER_URL}>Register New Account</a>
                            <a href={`${WEB_HOST}/contact-us`}>Contact Us</a>
                            <a href={TOS_URL}>Terms & Conditions</a>
                        </CenteringContainer>
                    </React.Fragment>
                </Container>
            </React.Fragment>
        );
    }

    renderForm = () => {
        const { loading, token, resetSuccess, submitErrors, resetSubmitted } = this.props;

        if (loading) {
            return <Loading />;
        }

        if (token === null || token.hasExpired === true) {
            return this.renderExpiredMessage();
        }

        if (resetSuccess === true) {
            return this.renderSuccessMessage();
        }

        return <ResetPasswordForm onSubmit={this.onSubmit} errors={submitErrors} resetSubmitted={resetSubmitted} />;
    };

    onSubmit = (values) => {
        const { confirmPassword, newPassword } = values;
        const { resetPasswordSubmit } = this.props;

        resetPasswordSubmit(newPassword, confirmPassword);
    };

    renderExpiredMessage() {
        const { redirect } = this.props;

        return (
            <React.Fragment>
                <Paper style={{ padding: 20 }}>
                    <Container>
                        <h1 className={'heading'}>Sorry, this link has expired</h1>
                    </Container>
                    <Container className={'margin-top-25'}>
                        <p className={'body'}>The reset link has expired. To try again press the button below.</p>
                    </Container>
                    <RowItem>
                        <RightAligned className={'button-panel'}>
                            <PrimaryButton onClick={() => redirect(ROUTE_REQUEST_PASSWORD_RESET)}>
                                Reset Password
                            </PrimaryButton>
                        </RightAligned>
                    </RowItem>
                </Paper>
            </React.Fragment>
        );
    }

    renderSuccessMessage() {
        const { redirect } = this.props;
        const href = window.location.href;
        const arr = new URL(href);
        const networkBroker = arr.searchParams.get('nb');

        return (
            <React.Fragment>
                <Container>
                    <h1 className={'heading'}>Password Reset Successful</h1>
                </Container>
                <Container className={'margin-top-25'}>
                    <p className={'body margin-bottom-15'}>
                        You can now use your new password to log in to your account.
                    </p>
                </Container>
                <RowItem>
                    <RightAligned className={'button-panel'}>
                        <PrimaryButton
                            type="submit"
                            onClick={() =>
                                redirect(networkBroker ? ROUTE_ANON_LOGIN + `?nb=${networkBroker}` : ROUTE_ANON_LOGIN)
                            }
                        >
                            Login
                        </PrimaryButton>
                    </RightAligned>
                </RowItem>
            </React.Fragment>
        );
    }
}

PasswordResetContainer.propTypes = {
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired,
    resetPasswordSubmit: PropTypes.func.isRequired,
    token: PropTypes.object,
    resetSuccess: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    submitErrors: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
    return {
        token: selectorToken(state),
        loading: selectorLoading(state),
        resetSuccess: selectorResetSuccess(state),
        submitErrors: selectorSubmitErrors(state),
        resetSubmitted: selectorResetSubmitted(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
        initialize: (token) => dispatch(actionPasswordResetRequestInitialise(token)),
        reset: () => dispatch(actionReset()),
        resetPasswordSubmit: (newPassword, confirmPassword) =>
            dispatch(actionResetPasswordSubmit(newPassword, confirmPassword)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PasswordResetContainer);
