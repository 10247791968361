import React from 'react';
import styled from 'styled-components';
import { Centered, Container, Item, RowItem } from '../../../pitchblack_react_utils/layout';
import PropTypes from 'prop-types';
import { Error } from '@material-ui/icons';
import { Body1, PrimaryColour, SecondaryColour } from '../text';
import P4StyleComponentContainer from './P4StyleComponentContainer';
import { BRAND_MAIN_CONTACT } from '../../../bootstrap/constants/constants';

const DEFAULT_HEADER = 'Sorry, something has gone wrong...';

const DEFAULT_MESSAGE = `We are experiencing a problem handling your request.`;

const StyledRowItem = styled(RowItem)`
    padding-bottom: 24px;
`;

const StyledContent = styled(Container)`
    margin-top: 25px;
    margin-bottom: 25px;
`;

const StyledIcon = styled(Item)`
    padding-right: 25px;
`;

const StyledHeaderContainer = styled(Container)`
    width: 100%;
`;

class P4StyleErrorContainer extends React.Component {
    render() {
        let { title = DEFAULT_HEADER, error = DEFAULT_MESSAGE } = { ...this.props };
        return (
            <P4StyleComponentContainer
                header={
                    <StyledHeaderContainer>
                        <StyledIcon>
                            <Centered>
                                <PrimaryColour>
                                    <Error />
                                </PrimaryColour>
                            </Centered>
                        </StyledIcon>
                        <Item>
                            <SecondaryColour>
                                <h5>{title}</h5>
                            </SecondaryColour>
                        </Item>
                    </StyledHeaderContainer>
                }
                content={
                    <StyledContent>
                        <StyledRowItem>
                            <Centered style={{ textAlign: 'center' }}>
                                <SecondaryColour>
                                    <Body1>{error}</Body1>
                                </SecondaryColour>
                            </Centered>
                        </StyledRowItem>

                        <StyledRowItem>
                            <Centered style={{ textAlign: 'center' }}>
                                <SecondaryColour>
                                    <Body1>
                                        Still at a loss? Dont hesitate to call us now on{' '}
                                        <a href={`tel:${BRAND_MAIN_CONTACT}`}>{BRAND_MAIN_CONTACT}</a> and one of our
                                        dedicated advisors will help
                                    </Body1>
                                </SecondaryColour>
                            </Centered>
                        </StyledRowItem>
                    </StyledContent>
                }
            />
        );
    }
}

P4StyleErrorContainer.propTypes = {
    title: PropTypes.string,
    error: PropTypes.string,
};

export default P4StyleErrorContainer;
