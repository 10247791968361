import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionActions from '@material-ui/core/AccordionActions';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import moment from 'moment';
import { Badge } from '../../../../../../pitch4_layout/components/badge/Badge';
import { Hidden } from '@material-ui/core';

export default function AdminNote(props) {
    const { adminNote, index, expanded, handleNoteChange, handleDeleteNote, handleEditNote } = props;

    return (
        <Accordion expanded={expanded === `note-${index}`} onChange={handleNoteChange(`note-${index}`)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`note-${index}-content`}
                id={`note-${index}-header`}
            >
                <div className={'summary'}>
                    <Hidden xsDown>
                        <div className={'icon'}>
                            <InsertDriveFileIcon fontSize={'large'} />
                        </div>
                    </Hidden>
                    <div className={'title'}>{adminNote.adminNoteTitle}</div>
                    <div className={'submitted-by'}>
                        <Badge style={{ whiteSpace: 'nowrap' }} label={adminNote.submittedBy} />
                    </div>
                    <div className={'created-at'}>{moment(adminNote.updatedAt).format('HH:mm - Do MMM YYYY')}</div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div
                    className={'note'}
                    dangerouslySetInnerHTML={{
                        __html: adminNote.adminNote,
                    }}
                />
            </AccordionDetails>
            <Divider />
            <AccordionActions className={'note-actions'}>
                <div style={{ marginLeft: 10 }}>
                    Created at: {moment(adminNote.createdAt).format('HH:mm - Do MMM YYYY')}
                </div>
                <div>
                    <Button
                        className={'btn-edit'}
                        onClick={() => {
                            handleEditNote(adminNote);
                        }}
                    >
                        Edit
                    </Button>
                    <Button
                        className={'btn-delete'}
                        onClick={() => {
                            handleDeleteNote(adminNote);
                        }}
                    >
                        Delete
                    </Button>
                </div>
            </AccordionActions>
        </Accordion>
    );
}
