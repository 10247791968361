import {
    ROUTE_SLAM_ADMINISTER_USERS_LIST,
    ROUTE_SLAM_BUSINESS_STREAM_CREATE,
    ROUTE_SLAM_BUSINESS_STREAM_EDIT,
    ROUTE_SLAM_BUSINESS_STREAM_VIEW,
    ROUTE_SLAM_BUSINESS_STREAMS,
    ROUTE_SLAM_CONFIGURE_PROFILE,
    ROUTE_SLAM_DOCUMENTS_VIEW,
    ROUTE_SLAM_LEAD,
    ROUTE_SLAM_LEADS_ACTIVE,
    ROUTE_SLAM_LEADS_INACTIVE,
    ROUTE_SLAM_ROOT,
} from '../../../pitch4_routing';

export default {
    [ROUTE_SLAM_ROOT]: [{ path: ROUTE_SLAM_ROOT, label: 'Dashboard', link: false }],
    //Business streams
    [ROUTE_SLAM_BUSINESS_STREAMS]: [{ path: ROUTE_SLAM_BUSINESS_STREAMS, label: 'Business Streams', link: false }],
    [ROUTE_SLAM_BUSINESS_STREAM_CREATE]: [
        { path: ROUTE_SLAM_BUSINESS_STREAMS, label: 'Business Streams', link: true },
        { path: ROUTE_SLAM_BUSINESS_STREAM_CREATE, label: 'Add Stream', link: false },
    ],
    [ROUTE_SLAM_BUSINESS_STREAM_EDIT]: [
        { path: ROUTE_SLAM_BUSINESS_STREAMS, label: 'Business Streams', link: true },
        { path: ROUTE_SLAM_BUSINESS_STREAM_VIEW, label: 'Single Business Stream', link: true },
        { path: ROUTE_SLAM_BUSINESS_STREAM_EDIT, label: 'Edit Stream', link: false },
    ],
    [ROUTE_SLAM_BUSINESS_STREAM_VIEW]: [
        { path: ROUTE_SLAM_BUSINESS_STREAMS, label: 'Business Streams', link: true },
        { path: ROUTE_SLAM_BUSINESS_STREAM_VIEW, label: 'View Stream', link: false },
    ],
    //Enquiries
    [ROUTE_SLAM_LEADS_ACTIVE]: [{ path: ROUTE_SLAM_LEADS_ACTIVE, label: 'Active Leads', link: false }],
    [ROUTE_SLAM_LEADS_INACTIVE]: [{ path: ROUTE_SLAM_LEADS_INACTIVE, label: 'Inactive Leads', link: false }],
    [ROUTE_SLAM_LEAD]: [
        { path: ROUTE_SLAM_LEADS_ACTIVE, label: 'Active Leads', link: true },
        { path: ROUTE_SLAM_LEAD, label: 'View Lead', link: false },
    ],
    //Documents
    [ROUTE_SLAM_DOCUMENTS_VIEW]: [{ path: ROUTE_SLAM_DOCUMENTS_VIEW, label: 'Company Documents', link: false }],
    //Users
    [ROUTE_SLAM_ADMINISTER_USERS_LIST]: [{ path: ROUTE_SLAM_ADMINISTER_USERS_LIST, label: 'Users', link: false }],
    //Configure Profile
    [ROUTE_SLAM_CONFIGURE_PROFILE]: [{ path: ROUTE_SLAM_CONFIGURE_PROFILE, label: 'Configure Profile', link: false }],
};
