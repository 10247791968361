import React from 'react';
import PropTypes from 'prop-types';
import { Container, Item } from 'pitch4_layout/components/div';
import InfoIcon from '@material-ui/icons/Info';

export const BANNER_NOTICE_TYPE_ERROR = 'BANNER_NOTICE_TYPE_INFO';

const BannerNoticeInfo = ({ message }) => {
    return (
        <Container>
            <div className="banner-notice banner-notice-info">
                <Container spacing={1} alignItems={`center`}>
                    <Item xs={12} sm={12} className={`notice-message`}>
                        <InfoIcon />
                        {message}
                    </Item>
                </Container>
            </div>
        </Container>
    );
};

export default BannerNoticeInfo;

BannerNoticeInfo.PropType = {
    message: PropTypes.string.isRequired,
};
