import React, { useEffect, useState } from 'react';
import { Grid, OutlinedInput } from '@material-ui/core';
import DirtyTermIndicator from './DirtyTermIndicator';

export default function EditTermItemText(props) {
    const { item, itemIndex, isLast, updateTermValue } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [value, setValue] = useState(item.value);
    const [initialValue, setInitialValue] = useState(null);

    //Did mount
    useEffect(() => {
        setInitialValue(item.value);
    }, []);

    return (
        <Grid className={`item ${true === isLast ? 'last' : ''}`} item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={4} md={4} sm={4} xs={4} className={`name ${null !== item.value ? 'filled' : 'empty'}`}>
                {item.name}
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={7} className={'optional'}>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <OutlinedInput
                        onChange={(event) => {
                            const value = event.target.value === '' ? null : event.target.value;
                            setValue(value);
                            updateTermValue(item, itemIndex, value);
                            setIsDirty(value !== initialValue);
                        }}
                        type={'text'}
                        value={value}
                        labelWidth={0}
                        required={!item.isOptional}
                    />
                </div>
            </Grid>
            <DirtyTermIndicator value={item.value} isDirty={isDirty} />
        </Grid>
    );
}
