import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import {
    PRODUCT_BRIDGING_TO_LET,
    PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS,
    PRODUCT_BRIDGING_FINANCE,
    PRODUCT_TERM_LOAN,
    PRODUCT_BUSINESS_FINANCE,
    PRODUCT_CARE_HOME,
} from '../../pitch4_enum/enum/ProductEnum';

export default function TermsSelectProduct(props) {
    const { lleProduct, handleProductSelectChange } = props;
    const [product, setProduct] = React.useState(lleProduct);
    const handleChange = (event) => {
        const newProduct = event.target.value;
        setProduct(newProduct);
        handleProductSelectChange(newProduct);
    };

    return (
        <div className={'product-select-container'}>
            <div className={'header'}>Please select product:</div>
            <FormControl variant="outlined" className={'product-select'}>
                <InputLabel id="terms-product-select-outlined-label">Select product</InputLabel>
                <Select
                    labelId="terms-product-select-outlined-label"
                    id="terms-product-select-outlined"
                    value={product}
                    onChange={handleChange}
                    label="Select product"
                >
                    <MenuItem
                        value={PRODUCT_BUSINESS_FINANCE}
                        className={lleProduct === PRODUCT_BUSINESS_FINANCE && 'product-select-product-active'}
                    >
                        <span className={lleProduct === PRODUCT_BUSINESS_FINANCE && 'active'}>Business Finance</span>
                    </MenuItem>
                    <MenuItem
                        value={PRODUCT_BRIDGING_FINANCE}
                        className={lleProduct === PRODUCT_BRIDGING_FINANCE && 'product-select-product-active'}
                    >
                        <span className={lleProduct === PRODUCT_BRIDGING_FINANCE && 'active'}>Bridging</span>
                    </MenuItem>
                    <MenuItem
                        value={PRODUCT_TERM_LOAN}
                        className={lleProduct === PRODUCT_TERM_LOAN && 'product-select-product-active'}
                    >
                        <span className={lleProduct === PRODUCT_TERM_LOAN && 'active'}>Term Loan</span>
                    </MenuItem>
                    <MenuItem
                        value={PRODUCT_BRIDGING_TO_LET}
                        className={lleProduct === PRODUCT_BRIDGING_TO_LET && 'product-select-product-active'}
                    >
                        <span className={lleProduct === PRODUCT_BRIDGING_TO_LET && 'active'}>Bridge To Let</span>
                    </MenuItem>
                    <MenuItem
                        value={PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS}
                        className={
                            lleProduct === PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS &&
                            'product-select-product-active'
                        }
                    >
                        <span className={lleProduct === PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS && 'active'}>
                            Refurbs and Developments
                        </span>
                    </MenuItem>
                    <MenuItem
                        value={PRODUCT_CARE_HOME}
                        className={lleProduct === PRODUCT_CARE_HOME && 'product-select-product-active'}
                    >
                        <span className={lleProduct === PRODUCT_CARE_HOME && 'active'}>Care Home</span>
                    </MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}
