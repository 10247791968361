import { BaseEnum } from 'pitch4_enum';

export const TYPE_LESS_THAN_A_YEAR = 'less_than_a_year';
export const TYPE_A_YEAR = 'a_year';
export const TYPE_TWO_YEARS = 'two_years';
export const TYPE_TWO_YEARS_OR_MORE = 'two_years_or_more';

class BusinessLoanTermEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_LESS_THAN_A_YEAR]: 'Less than a year',
            [TYPE_A_YEAR]: '1 year',
            [TYPE_TWO_YEARS]: '2 years',
            [TYPE_TWO_YEARS_OR_MORE]: 'More than 2 years',
        };
    };
}

export default BusinessLoanTermEnum;
