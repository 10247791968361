export const REDUCER_NAMESPACE = 'bannernotice';

// Actions
export const BANNER_NOTICE_SHOW = `${REDUCER_NAMESPACE}.show`;
export const BANNER_NOTICE_HIDE = `${REDUCER_NAMESPACE}.hide`;

// Reducer
const initialState = null;

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case BANNER_NOTICE_SHOW:
            return {
                visible: true,
                noticeType: action.payload.noticeType,
                noticeProps: action.payload.noticeProps,
            };
        case BANNER_NOTICE_HIDE:
            return null;
        default:
            return state;
    }
};

// Default export reducer
export default reducer;

// Action Creators
export const actionBannerNoticeShow = (noticeType, noticeProps) => {
    return {
        type: BANNER_NOTICE_SHOW,
        payload: {
            noticeType,
            noticeProps,
        },
    };
};

export const actionBannerNoticeHide = () => {
    return {
        type: BANNER_NOTICE_HIDE,
    };
};

// Action Selectors
export const selectorBannerNotice = (state) => state[REDUCER_NAMESPACE];
