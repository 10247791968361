import { BaseEnum } from '../../pitch4_enum';
import {
    PRODUCT_BRIDGING_FINANCE,
    PRODUCT_BRIDGING_TO_LET,
    PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS,
    PRODUCT_TERM_LOAN,
    PRODUCT_BUSINESS_FINANCE,
    PRODUCT_CARE_HOME,
} from './ProductEnum';

const MAIN_MATRIX_TERMS_NAME = 'Main Terms';
const MAIN_MATRIX_TERMS_CODE = 'main';
const DETAIL_MATRIX_TERMS_NAME = 'Detail Terms';
const DETAIL_MATRIX_TERMS_CODE = 'detail';
const FEES_MATRIX_TERMS_NAME = 'Fees';
const FEES_MATRIX_TERMS_CODE = 'fees';

class TermsAndFeesEnum extends BaseEnum {
    getTypes = () => {
        return {
            [PRODUCT_BUSINESS_FINANCE]: {
                updatedAt: null,
                product: PRODUCT_BUSINESS_FINANCE,
                terms: [
                    {
                        name: 'Interest Rate',
                        choice: ['% PCM', '% PA'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'mainInterestRate',
                        units: null,
                    },
                    {
                        name: 'Gross Loan Amount (£)',
                        choice: null,
                        choiceIndex: null,
                        valueType: 'number',
                        value: null,
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'Term In Months',
                        valueType: 'number',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Interest Type',
                        choice: ['Fixed', 'Variable'],
                        choiceIndex: null,
                        value: null,
                        valueType: 'choice',
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Monthly Payment (£)',
                        choice: null,
                        choiceIndex: null,
                        valueType: 'number',
                        value: null,
                        isOptional: false,
                        termType: 'details',
                        units: '£',
                    },
                    {
                        name: 'Lender Arrangement Fees',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        valueType: 'number',
                        value: null,
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Admin Fees',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Early Repayment Charges',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                ],
            },
            [PRODUCT_BRIDGING_FINANCE]: {
                updatedAt: null,
                product: PRODUCT_BRIDGING_FINANCE,
                terms: [
                    {
                        name: 'Interest Rate',
                        choice: ['% PCM', '% PA'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'mainInterestRate',
                        units: null,
                    },
                    {
                        name: 'Total GROSS Loan (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'Total NET Loan (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'LTV (%)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '%',
                    },
                    {
                        name: 'Term In Months',
                        valueType: 'number',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Interest Type',
                        choice: ['Rolled Up', 'Retained', 'Serviced'],
                        choiceIndex: null,
                        value: null,
                        valueType: 'choice',
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Interest Per Month',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Lender Arrangement Fees',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Proc Fee',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Admin Fees',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Legal Fees (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: '£',
                    },
                    {
                        name: 'Exit Fees',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Early Repayment Charges',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                ],
            },
            [PRODUCT_TERM_LOAN]: {
                updatedAt: null,
                product: PRODUCT_TERM_LOAN,
                terms: [
                    {
                        name: 'Interest Rate',
                        choice: ['% PCM', '% PA'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'mainInterestRate',
                        units: null,
                    },
                    {
                        name: 'Total GROSS Loan (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'Total NET Loan (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'LTV (%)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '%',
                    },
                    {
                        name: 'Term of Loan',
                        choice: ['Months', 'Years'],
                        valueType: 'number',
                        choiceIndex: 0,
                        value: null,
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Initial Interest Term (years)',
                        valueType: 'number',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Interest Type',
                        choice: ['Fixed', 'Variable'],
                        choiceIndex: null,
                        value: null,
                        valueType: 'choice',
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Interest Payments',
                        choice: ['Interest Only', 'Capital & Repayment', 'Part and Part'],
                        choiceIndex: null,
                        value: null,
                        valueType: 'choice',
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Interest Per Year (%)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: '%',
                    },
                    {
                        name: 'Lender Arrangement Fees',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Proc Fee',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Admin Fees',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Legal Fees (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: '£',
                    },
                    {
                        name: 'Exit Fees ',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Early Repayment Charges',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                ],
            },
            [PRODUCT_BRIDGING_TO_LET]: {
                updatedAt: null,
                product: PRODUCT_BRIDGING_TO_LET,
                terms: [
                    {
                        name: 'Interest Rate',
                        choice: ['% PCM', '% PA'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'mainInterestRate',
                        units: null,
                    },
                    {
                        name: 'Total GROSS Loan (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'Total NET Loan (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'LTV (%)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '%',
                    },
                    {
                        name: 'Term In Months',
                        valueType: 'number',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Interest Type',
                        choice: ['Rolled Up', 'Retained', 'Serviced', 'Part and Part'],
                        choiceIndex: null,
                        value: null,
                        valueType: 'choice',
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Interest Per Month (%)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: '%',
                    },
                    {
                        name: 'Lender Arrangement Fees',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Proc Fee',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Admin Fees',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Legal Fees',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Exit Fees',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Early Repayment Charges',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                ],
            },
            [PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS]: {
                updatedAt: null,
                product: PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS,
                terms: [
                    {
                        name: 'Interest Rate',
                        choice: ['% PCM', '% PA'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'mainInterestRate',
                        units: null,
                    },
                    {
                        name: 'Day One GROSS Loan (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'Day One NET Loan (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'LTGDV (%)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '%',
                    },
                    {
                        name: 'Total Facility inc Fees and Interest (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: '£',
                    },
                    {
                        name: 'Total NET Facility (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: '£',
                    },
                    {
                        name: 'Term In Months',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Refurbishment / Development Facility (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: '£',
                    },
                    {
                        name: 'Interest Type',
                        choice: ['Rolled Up', 'Retained', 'Serviced'],
                        choiceIndex: null,
                        value: null,
                        valueType: 'choice',
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Interest Amount (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: '£',
                    },
                    {
                        name: 'Lender Arrangement Fees',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Proc Fee',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Admin Fees',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Legal Fees (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: '£',
                    },
                    {
                        name: 'Exit Fees',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Early Repayment Charges',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Monitoring Surveyor Fees (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: '£',
                    },
                    {
                        name: 'Valuation Fee (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: '£',
                    },
                ],
            },
            [PRODUCT_CARE_HOME]: {
                updatedAt: null,
                product: PRODUCT_CARE_HOME,
                terms: [
                    {
                        name: 'Interest Rate',
                        choice: ['% PCM', '% PA'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'mainInterestRate',
                        units: null,
                    },
                    {
                        name: 'Day One GROSS Loan (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'Day One NET Loan (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '£',
                    },
                    {
                        name: 'LTGDV (%)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'top',
                        units: '%',
                    },
                    {
                        name: 'Total Facility inc Fees and Interest (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: '£',
                    },
                    {
                        name: 'Total NET Facility (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: '£',
                    },
                    {
                        name: 'Term In Months',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Refurbishment / Development Facility (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: '£',
                    },
                    {
                        name: 'Interest Type',
                        choice: ['Rolled Up', 'Retained', 'Serviced'],
                        choiceIndex: null,
                        value: null,
                        valueType: 'choice',
                        isOptional: false,
                        termType: 'details',
                        units: null,
                    },
                    {
                        name: 'Interest Amount (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'details',
                        units: '£',
                    },
                    {
                        name: 'Lender Arrangement Fees',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Proc Fee',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Admin Fees',
                        choice: ['£', '%'],
                        choiceIndex: 0,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Legal Fees (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: '£',
                    },
                    {
                        name: 'Exit Fees',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Early Repayment Charges',
                        choice: ['£', '%'],
                        choiceIndex: 1,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: null,
                    },
                    {
                        name: 'Monitoring Surveyor Fees (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: '£',
                    },
                    {
                        name: 'Valuation Fee (£)',
                        choice: null,
                        choiceIndex: null,
                        value: null,
                        valueType: 'number',
                        isOptional: false,
                        termType: 'fees',
                        units: '£',
                    },
                ],
            },
        };
    };

    getMatrixTerms = () => {
        return {
            [MAIN_MATRIX_TERMS_CODE]: [
                {
                    name: 'Interest Rate',
                    code: 'INTEREST_RATE',
                    selected: true,
                },
                {
                    name: 'Day One GROSS Loan',
                    code: 'DAY_ONE_GROSS_LOAN',
                    selected: true,
                },
                {
                    name: 'Day One NET Loan',
                    code: 'DAY_ONE_NET_LOAN',
                    selected: true,
                },
                {
                    name: 'LTGDV',
                    code: 'LTGDV',
                    selected: true,
                },
                {
                    name: 'Total GROSS Loan',
                    code: 'TOTAL_GROSS_LOAN',
                    selected: true,
                },
                {
                    name: 'Gross Loan Amount',
                    code: 'GROSS_LOAN_AMOUNT',
                    selected: true,
                },
                {
                    name: 'Total NET Loan',
                    code: 'TOTAL_NET_LOAN',
                    selected: true,
                },
                {
                    name: 'LTV',
                    code: 'LTV',
                    selected: true,
                },
            ],
            [DETAIL_MATRIX_TERMS_CODE]: [
                {
                    name: 'Term of Loan',
                    code: 'TERM_OF_LOAN',
                    selected: true,
                },
                {
                    name: 'Initial Interest Term',
                    code: 'INITIAL_INTEREST_TERM',
                    selected: true,
                },
                {
                    name: 'Interest Payments',
                    code: 'INTEREST_PAYMENTS',
                    selected: true,
                },
                {
                    name: 'Interest Per Year',
                    code: 'INTEREST_PER_YEAR',
                    selected: true,
                },
                {
                    name: 'Interest Type',
                    code: 'INTEREST_TYPE',
                    selected: true,
                },
                {
                    name: 'Interest Per Month',
                    code: 'INTEREST_PER_MONTH',
                    selected: true,
                },
                {
                    name: 'Total Facility inc Fees and Interest',
                    code: 'TOTAL_FACILITY_INC_FEES_AND_INTEREST',
                    selected: true,
                },
                {
                    name: 'Total NET Facility',
                    code: 'TOTAL_NET_FACILITY',
                    selected: true,
                },
                {
                    name: 'Refurbishment / Development Facility',
                    code: 'REFURBISHMENT_DEVELOPMENT_FACILITY',
                    selected: true,
                },
                {
                    name: 'Interest Amount',
                    code: 'INTEREST_AMOUNT',
                    selected: true,
                },
                {
                    name: 'Term In Months',
                    code: 'TERM_IN_MONTHS',
                    selected: true,
                },
                {
                    name: 'Monthly Payment',
                    code: 'MONTHLY_PAYMENT',
                    selected: true,
                },
            ],
            [FEES_MATRIX_TERMS_CODE]: [
                {
                    name: 'Legal Fees',
                    code: 'LEGAL_FEES',
                    selected: true,
                },
                {
                    name: 'Monitoring Surveyor Fees',
                    code: 'MONITORING_SURVEYOR_FEES',
                    selected: true,
                },
                {
                    name: 'Valuation Fee',
                    code: 'VALUATION_FEE',
                    selected: true,
                },
                {
                    name: 'Admin Fees',
                    code: 'ADMIN_FEES',
                    selected: true,
                },
                {
                    name: 'Early Repayment Charges',
                    code: 'EARLY_REPAYMENT_CHARGES',
                    selected: true,
                },
                {
                    name: 'Lender Arrangement Fees',
                    code: 'LENDER_ARRANGEMENT_FEES',
                    selected: true,
                },
                {
                    name: 'Proc Fee',
                    code: 'PROC_FEE',
                    selected: true,
                },
                {
                    name: 'Exit Fees',
                    code: 'EXIT_FEES',
                    selected: true,
                },
            ],
        };
    };

    getMatrixTermsSectionName = (code) => {
        switch (code) {
            case MAIN_MATRIX_TERMS_CODE:
                return MAIN_MATRIX_TERMS_NAME;
            case DETAIL_MATRIX_TERMS_CODE:
                return DETAIL_MATRIX_TERMS_NAME;
            case FEES_MATRIX_TERMS_CODE:
                return FEES_MATRIX_TERMS_NAME;
            default:
                return null;
        }
    };

    getType = (type) => this.getTypes()[type];
}

export const testTerms = {
    // this is for development only
    updatedAt: '2021-09-13T13:04:11+0100',
    product: 'business-finance',
    terms: [
        {
            name: 'Interest Rate (% PCM)',
            choice: ['%'],
            choiceIndex: 0,
            valueType: 'number',
            value: 10,
            isOptional: false,
            termType: 'top',
        },
        {
            name: 'Gross Loan Amount (£)',
            choice: ['£'],
            choiceIndex: 0,
            valueType: 'number',
            value: 23456,
            isOptional: false,
            termType: 'top',
        },
        {
            name: 'NET Loan Amount Estimate (£)',
            choice: ['£'],
            choiceIndex: 0,
            valueType: 'number',
            value: 234567,
            isOptional: false,
            termType: 'top',
        },
        {
            name: 'Term In Months',
            valueType: 'number',
            choice: null,
            choiceIndex: null,
            value: 34,
            isOptional: true,
            termType: 'details',
        },
        {
            name: 'Interest Type',
            valueType: 'text',
            choice: null,
            choiceIndex: null,
            value: 'Accumulation',
            isOptional: false,
            termType: 'details',
        },
        {
            name: 'Monthly Payment (£)',
            choice: ['£'],
            choiceIndex: 0,
            valueType: 'number',
            value: 43534,
            isOptional: false,
            termType: 'details',
        },
        {
            name: 'Lender Arrangement Fees',
            choice: ['£', '%'],
            choiceIndex: 0,
            valueType: 'number',
            value: null,
            isOptional: false,
            termType: 'fees',
        },
        {
            name: 'Admin Fees',
            choice: ['£', '%'],
            choiceIndex: 0,
            value: 22,
            valueType: 'number',
            isOptional: false,
            termType: 'fees',
        },
        {
            name: 'Legal Fees',
            choice: ['£', '%'],
            choiceIndex: 0,
            value: 1222,
            valueType: 'number',
            isOptional: false,
            termType: 'fees',
        },
        {
            name: 'Early Repayment Charges',
            choice: ['£', '%'],
            choiceIndex: 1,
            value: null,
            valueType: 'number',
            isOptional: false,
            termType: 'fees',
        },
    ],
};

export default TermsAndFeesEnum;
