import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import { CenteringContainer, Container, Item, RowItem } from '../../../pitchblack_react_utils/layout/index';
import {
    isEmail,
    isRequired,
    isDateTime,
    hasMaxLength,
    hasMinLength,
    isValidPassword,
    isInDateRange,
    hasMinLength8,
    hasMaxLength255,
    isPhoneNumber,
} from '../../../pitchblack_react_utils/form/validation';
import { normalizeEmail } from '../../../pitchblack_react_utils/form/Normalize';
import { TextField, DateField, Checkbox } from '../../../pitch4_forms/components/inputs';
import FormMessage, { MESSAGE_ERROR } from '../../../pitch4_forms/components/FormMessage';
import { Box } from '@mui/material';
import { connect } from 'react-redux';

export const FormFields = ({
    disabled,
    firstFieldAutoFocus,
    setTCAndPPAgreement,
    tcAndPPAgreement,
    setCommsAgreement,
    commsAgreement,
}) => {
    const emailValidate = [isRequired, isEmail];
    const companyNameValidate = [isRequired, hasMinLength(2)];
    const nameValidate = [isRequired, hasMinLength(2), hasMaxLength(255)];
    const passwordValidate = [isRequired, isValidPassword, hasMaxLength(255)];
    const dobValidate = [
        isRequired,
        isDateTime,
        isInDateRange(
            -100,
            'years',
            'The maximum age allowed for an applicant is 99',
            -18,
            'years',
            'The minimum age allowed for an applicant is 18'
        ),
    ];
    const phoneValidate = [isPhoneNumber, hasMinLength8, hasMaxLength255];

    return (
        <>
            <RowItem>
                <CenteringContainer>
                    <Item className={'form-heading'}>Intermediary Sign-Up:</Item>
                </CenteringContainer>
            </RowItem>
            <RowItem>
                <CenteringContainer alignItems={'flex-start'} style={{ display: 'block' }}>
                    <Box sx={{ display: { md: 'flex', xs: 'none' } }}>
                        <Item lg={6} md={6} className={'item-left'}>
                            <TextField
                                style={{ marginRight: 20 }}
                                name="given"
                                autoComplete={`given-name`}
                                placeholder="First Name"
                                variant={`outlined`}
                                disabled={disabled}
                                InputProps={{}}
                                validate={nameValidate}
                                autoFocus={firstFieldAutoFocus}
                            />
                        </Item>
                        <Item lg={6} md={6}>
                            <TextField
                                name="family"
                                autoComplete={`additional-name`}
                                placeholder="Surname"
                                variant={`outlined`}
                                disabled={disabled}
                                InputProps={{}}
                                validate={nameValidate}
                            />
                        </Item>
                    </Box>
                    <Box sx={{ display: { md: 'none', xs: 'block' }, width: '100%' }}>
                        <Item>
                            <TextField
                                name="given"
                                autoComplete={`given-name`}
                                placeholder="First Name"
                                variant={`outlined`}
                                disabled={disabled}
                                InputProps={{}}
                                validate={nameValidate}
                                autoFocus={firstFieldAutoFocus}
                            />
                        </Item>
                        <Item>
                            <TextField
                                name="family"
                                autoComplete={`additional-name`}
                                placeholder="Surname"
                                variant={`outlined`}
                                disabled={disabled}
                                InputProps={{}}
                                validate={nameValidate}
                            />
                        </Item>
                    </Box>
                </CenteringContainer>
            </RowItem>
            <RowItem>
                <CenteringContainer>
                    <Item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            name="email"
                            autoComplete={`email`}
                            placeholder="Email address"
                            variant={`outlined`}
                            disabled={disabled}
                            InputProps={{}}
                            validate={emailValidate}
                            normalize={normalizeEmail}
                        />
                    </Item>
                </CenteringContainer>
            </RowItem>
            <RowItem>
                <CenteringContainer>
                    <Item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            name="companyName"
                            autoComplete={`companyName`}
                            placeholder="Company Name"
                            variant={`outlined`}
                            disabled={disabled}
                            InputProps={{}}
                            validate={companyNameValidate}
                        />
                    </Item>
                </CenteringContainer>
            </RowItem>
            <RowItem>
                <CenteringContainer alignItems={'flex-start'} style={{ display: 'block' }}>
                    <Box sx={{ display: { md: 'flex', xs: 'none' } }}>
                        <Item lg={6} md={6} className={'item-left'}>
                            <TextField
                                style={{ marginRight: 20 }}
                                name="phoneNumber"
                                placeholder="Phone Number"
                                variant={`outlined`}
                                disabled={disabled}
                                InputProps={{}}
                                validate={phoneValidate}
                            />
                        </Item>
                        <Item lg={6} md={6}>
                            <DateField
                                name="dateOfBirth"
                                placeholder="DOB [dd/mm/yyyy]"
                                disabled={disabled}
                                validate={dobValidate}
                                disableFuture={true}
                                autoOk={true}
                            />
                        </Item>
                    </Box>
                    <Box sx={{ display: { md: 'none', xs: 'block' }, width: '100%' }}>
                        <Item>
                            <TextField
                                name="phoneNumber"
                                placeholder="Phone Number"
                                variant={`outlined`}
                                disabled={disabled}
                                InputProps={{}}
                                validate={phoneValidate}
                            />
                        </Item>
                        <Item>
                            <DateField
                                name="dateOfBirth"
                                placeholder="DOB [dd/mm/yyyy]"
                                disabled={disabled}
                                validate={dobValidate}
                                disableFuture={true}
                                autoOk={true}
                            />
                        </Item>
                    </Box>
                </CenteringContainer>
            </RowItem>
            <RowItem>
                <CenteringContainer>
                    <Item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            variant={`outlined`}
                            InputProps={{}}
                            name="password"
                            type="password"
                            placeholder="Password"
                            disabled={disabled}
                            validate={passwordValidate}
                        />
                    </Item>
                </CenteringContainer>
            </RowItem>
            <RowItem>
                <Container justifyContent={'space-between'} alignItems={'flex-start'} className={'dpl-panel'}>
                    <Item>
                        <Checkbox
                            name={'hasDataProtectionLicence'}
                            label={'Do you have a relevant Data Protection Licence?'}
                        />
                    </Item>
                </Container>
            </RowItem>
            <RowItem className={'sms-verification-container'}>
                <Item lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                        name="tcPpAgreement"
                        label={
                            <span>
                                I agree to the{' '}
                                <a
                                    href={'https://providefinance.com/borrowers/terms-of-business/'}
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    Terms and Conditions
                                </a>{' '}
                                and acknowledge the{' '}
                                <a
                                    href={'https://providefinance.com/privacy-policy'}
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    Provide Finance Privacy Policy
                                </a>
                            </span>
                        }
                        onChangeFunc={(event) => {
                            setTCAndPPAgreement(event.target.value !== '1');
                        }}
                        defaultChecked={tcAndPPAgreement}
                    />
                </Item>
            </RowItem>
            <RowItem className={'sms-verification-container'}>
                <Item lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                        name="commsAgreement"
                        label={
                            <span>
                                I’m happy to receive communications regarding the latest news, insights, products and
                                services from Provide Finance.
                            </span>
                        }
                        onChangeFunc={(event) => {
                            setCommsAgreement(event.target.value !== '1');
                        }}
                        defaultChecked={commsAgreement}
                    />
                </Item>
            </RowItem>
        </>
    );
};

let IntermediaryRegistrationForm = ({ errors = [], errorsVisible, handleSubmit, submitted, firstFieldAutoFocus }) => {
    const [tcAndPPAgreement, setTCAndPPAgreement] = useState(false);
    const [commsAgreement, setCommsAgreement] = useState(false);
    return (
        <Item className={'content-container borrower-registration-form-item'}>
            <Container className={'registration-form borrower-registration-form-container'}>
                {errorsVisible &&
                    errors.map((error) => <FormMessage type={MESSAGE_ERROR} messages={error} key={error} />)}
                <CenteringContainer>
                    <form onSubmit={handleSubmit} className={'registration-form__form'}>
                        <FormFields
                            disabled={submitted}
                            firstFieldAutoFocus={firstFieldAutoFocus}
                            setTCAndPPAgreement={setTCAndPPAgreement}
                            tcAndPPAgreement={tcAndPPAgreement}
                            setCommsAgreement={setCommsAgreement}
                            commsAgreement={commsAgreement}
                        />
                        <RowItem>
                            <CenteringContainer className={'button-panel'}>
                                <PrimaryButton type="submit" disabled={submitted || !tcAndPPAgreement}>
                                    Create Account
                                </PrimaryButton>
                            </CenteringContainer>
                        </RowItem>
                    </form>
                </CenteringContainer>
            </Container>
        </Item>
    );
};

const mapStateToProps = (state) => {
    return {
        initialValues: {
            phoneNumber: '+44',
        },
    };
};

IntermediaryRegistrationForm.defaultProps = {
    firstFieldAutoFocus: true,
    errors: [],
};

IntermediaryRegistrationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.array,
    errorsVisible: PropTypes.bool.isRequired,
    submitted: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

IntermediaryRegistrationForm = reduxForm({
    form: 'registration',
    asyncBlurFields: [],
})(IntermediaryRegistrationForm);

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'registration',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
)(IntermediaryRegistrationForm);
