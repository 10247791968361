import createP4ApolloFetch from '../../../bootstrap/middlewares';

const query = `
    mutation deleteDraft($id: ID!) {
        deleteDraft(id: $id) {
            success
        }
    }
`;

export const deleteDraft = (uri, { data, enquiryId = null }) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    return apolloFetch({
        query: query,
        variables: {
            id: enquiryId,
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};
