import React from 'react';
import LoginContainer from '../apps/authentication/containers/LoginContainer';
import AuthenticationLayout from './layouts/AuthenticationLayout';
import NotificationsContainer from '../apps/notifications/containers/Container';

const LoginPage = () => (
    <AuthenticationLayout notifications={<NotificationsContainer />}>
        <LoginContainer />
    </AuthenticationLayout>
);

export default LoginPage;
