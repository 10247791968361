import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import { API_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const GetConfigQuery = `
query getConfig($version: String){
  matchingCriteriaConfig(version: $version){
    version
    sections {
        name
        required
        frontend {
            label
        }
        skip
        fields {
            name
            required
            label
            enum
            multi
            validators
            dependencies {
                field
                value
            }
            type
            placeholder
        }
        categories
        next_section {
            name
            conditions {
                field
                value
            }
        }
    }
    enums {
        name,
        options {
            value
            label
        }
    }
  }
}
`;

//response classes
const errorFactory = new ValidationResultFactory();

class GetConfig {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class GetConfigErrors extends GetConfig {
    constructor(errors) {
        super('GetConfigErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class GetConfigSuccess extends GetConfig {
    constructor(data) {
        super('GetConfigSuccess');
        this.data = data;
    }

    getData = () => {
        const config = this.data.matchingCriteriaConfig || {};
        let mapped = { ...config, sections: {}, enums: {} };

        if (!!config['sections']) {
            Object.keys(config['sections']).forEach(function (sectionKey) {
                const section = config['sections'][sectionKey];
                let mappedSection = { ...section, fields: {}, next_section: {} };

                if (!!section['fields']) {
                    Object.keys(section['fields']).forEach(function (fieldKey) {
                        const field = section['fields'][fieldKey];
                        mappedSection['fields'][field['name']] = field;
                    });
                }

                if (!!section['next_section']) {
                    Object.keys(section['next_section']).forEach(function (nextSectionKey) {
                        const nextSection = section['next_section'][nextSectionKey];
                        mappedSection['next_section'][nextSection['name']] = nextSection;
                    });
                } else {
                    delete mappedSection['next_section'];
                }

                mapped['sections'][section['name']] = mappedSection;
            });
        }

        if (!!config['enums']) {
            Object.keys(config['enums']).forEach(function (enumKey) {
                const enumObj = config['enums'][enumKey];
                mapped['enums'][enumObj['name']] = enumObj;
            });
        }

        return mapped;
    };
}

export const getConfig = (version = null) => {
    let out = apolloFetch({
        query: GetConfigQuery,
        variables: version ? { version } : {},
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new GetConfigErrors(vR);
            }

            return new GetConfigSuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new GetConfigErrors(vR);
        });

    return out;
};
