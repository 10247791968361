import React from 'react';
import styled from 'styled-components';
import { CenteringContainer } from '../../../pitchblack_react_utils/layout';
import IconFactory, { SPINNER_ICON } from '../../../pitchblack_react_utils/icons/IconFactory';

const LoadingContainer = styled(CenteringContainer)`
    padding: 20px;
`;

export default function Loading() {
    let ic = new IconFactory();

    return <LoadingContainer>{ic.createIcon(SPINNER_ICON)}</LoadingContainer>;
}
