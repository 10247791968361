import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import Paper from '@material-ui/core/Paper';
import withSession from '../../../../pitchblack_react_utils/session/withSession';
import {
    path,
    ROUTE_BORROWER_CONFIGURE_PROFILE,
    ROUTE_BORROWER_CREATE_LOAN_ENQUIRY,
    ROUTE_BORROWER_DOCUMENTS_VIEW,
    ROUTE_BORROWER_EDIT_LOAN_ENQUIRY,
    ROUTE_BORROWER_LOAN_ENQUIRIES,
    ROUTE_BORROWER_LOAN_ENQUIRY_VIEW,
    ROUTE_LANDING,
    ROUTE_PITCH4_KNOWLEDGE_BASE,
    ROUTE_PITCH4_PROCESSES,
} from '../../../../pitch4_routing';
import { actionSetJoyrideType } from '../../../joyride/redux';
import createP4ApolloFetch from '../../../../bootstrap/middlewares';
import { BORROWER_URL, BRAND_NAME_UPPERCASE } from '../../../../bootstrap/constants/constants';
import { Button, Grid, Hidden } from '@material-ui/core';
import { LOAN_TYPE_PROPERTY, LOAN_TYPE_BUSINESS_FINANCE } from '../../enum/LoanEnquiries/LoanTypeEnum';
import RouteManagerFactory from '../../../../pitch4_routing/src/RouteManagerFactory';
import Header from '../../../../pitch4_elements/dashboard/Header';
import LECard from '../../../../pitch4_elements/dashboard/LECard';
import LECardSkeleton from '../../../../pitch4_elements/dashboard/LECardSkeleton';
import { PropertyTypeEnum } from '../../../admin/enum/matching/enum';
import P4ProcessIcon from '../../../../assets/images/dashboards/borrower/p4-process-icon.svg';
import P4AdminsIcon from '../../../../assets/images/dashboards/borrower/p4-admins-icon.svg';
import FAQIcon from '../../../../assets/images/dashboards/borrower/faq-icon.svg';
import AccountIcon from '../../../../assets/images/dashboards/borrower/account-icon.svg';
import DocumentsIcon from '../../../../assets/images/dashboards/borrower/documents-icon.svg';
import SearchIcon from '../../../../assets/images/dashboards/borrower/search-icon.svg';
import Skeleton from '@material-ui/lab/Skeleton';
import LEEmptyCard from '../../../../pitch4_elements/dashboard/LEEmptyCard';
import {
    STATUS_WITHDRAWN,
    STATUS_WITHDRAWN_EDITED,
    STATUS_WITHDRAWN_FULLY,
    STATUS_DRAFT,
} from '../../enum/LoanEnquiries/StatusEnum';
import BusinessFinanceLoanPurposeEnum from '../../../admin/enum/matching/enum/BusinessFinanceLoanPurposeEnum';
import LoanPurposes from '../../../MakeLoanEnquiry/enums/Shared/LoanPurposes';
import { Item } from '../../../../pitch4_layout/components/div';
import RebrandRedirect from '../../../../pitch4_elements/popups/RebrandRedirect';
import Welcome from '../../../registration/components/Welcome';
import { sagaActionModalHide, sagaActionModalShow } from '../../../modal/sagas/container';
import SMSVerificationModal from '../../../../pitch4_elements/sms-verification/SMSVerificationModal';
import { actionBannerNoticeHide } from '../../../bannernotice/redux/container';
import CommercialFundingType from '../../../MakeLoanEnquiry/enums/Shared/CommercialFundingType';

const routeManagerFactory = new RouteManagerFactory();
const routeManager = routeManagerFactory.create();
const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

class DashboardContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loanEnquiries: null,
            recentLE: null,
        };
    }

    componentDidMount() {
        const { session, redirect, setJoyrideType, setModalComponent } = this.props;

        if (!session.isAuthenticated()) {
            redirect(ROUTE_LANDING);
        }

        setJoyrideType(null);

        this.getData().then((resp) => {
            const loanEnquiries = resp.data.loanEnquiries.edges;
            const recentLE = null !== loanEnquiries && loanEnquiries.length > 0 ? loanEnquiries.shift() : null;

            this.setState({ loanEnquiries: loanEnquiries, recentLE: recentLE }, () => {
                const currentSession = session.getRelevantSession();

                if (null === recentLE) {
                    if (true === currentSession.isVerified()) {
                        setModalComponent(<Welcome url={ROUTE_BORROWER_CREATE_LOAN_ENQUIRY} inModal={true} />, false);
                    } else {
                        if (false === currentSession.smsVerificationRequested()) {
                            setModalComponent(
                                <Welcome url={ROUTE_BORROWER_CREATE_LOAN_ENQUIRY} inModal={true} />,
                                false
                            );
                        }
                    }
                }
            });
        });
    }

    getData() {
        const query = `
            query getLoanEnquiries(
                $cursor: String
                $first: Int!
                $sorts: [SortInput]
                $filters: [FilterInput]
                $storedDataFilters: [String]
            ) {
                loanEnquiries(
                    first: $first
                    after: $cursor
                    sorts: $sorts
                    filters: $filters
                    storedDataFilters: $storedDataFilters
                ) {
                    edges {
                        node {
                            id
                            borrowerReference
                            alertActive
                            lenderLoanEnquiries{
                                id
                            }
                            loanEnquiryApplicants{
                                applicant{
                                    given
                                    family
                                }
                                isMainApplicant
                            }
                            isNewForm
                            loanEnquiryData {
                                loanToValue
                                loanToCost
                                loanToGrossDevelopmentValue
                                dayOneLoanAmountPercentage
                                additionalInformation
                                rentalIncomeResidential
                                hmoBedroom
                                hmoRoomsSelfContained
                                hmoHasLicense
                                hmoNeedsLicense
                                buyToLetExperience
                                rentalIncomeCommercial
                                rentalIncomePortfolio
                                rentalIncome
                                commercialUnitsRentedOut
                                commercialUnitOwnerOccupied
                                commercialUnitAmount
                                commercialType
                                commercialTypeOther
                                siteDescription
                                siteIntention
                                refinance
                                businessSeasonal
                                businessType
                                businessName
                                businessWebsite
                                businessTradingLength
                                businessSector
                                businessFacilityNeededBy
                                businessFacilityTerm
                                businessCurrentPaymentTerms
                                businessOutstandingInvoices
                                businessPdqIncome
                                propertyType
                                loanAmount
                                loanPurpose
                                turnOver
                                businessProfitable
                                businessOverdraftFacility
                                businessOutstandingDebt
                                businessAdverseCredit
                                businessAdverseCreditExplanation
                                businessDirectorsAdverseCredit
                                businessDirectorsAdverseCreditExplanation
                                shareholdersAreHomeOwners
                                propertyAvailableForAdditionalSecurity
                                planningFor
                                developmentLength
                                refurbishmentLength
                                outstandingLoan
                                dayOneLoanAmount
                                unitsPreSold
                                worksExplanation
                                totalDevelopmentCosts
                                buildCostsBorrowing
                                expectedGrossDevelopmentValue
                                previousDevelopmentExperience
                                previousRefurbishmentExperience
                                teamDevelopmentExperience
                                epcRating
                                loanType
                                fundingType
                                businessSecuredFinance
                                loanUsedInUkEu
                                planningInPlace
                                endValue
                                percentageResidential
                                percentageCommercial
                                lender
                                firstChargeAmount
                                currentValue
                                properties
                                portfolioValue
                                portfolioAlreadyOwned
                                loanTerm
                                loanNeededBy
                                preApproveMatches
                                loanAmountRefurbishment
                                percentageResidentialSquareFeet
                                percentageResidentialValue
                                percentageCommercialValue
                                residentialUnitCount
                                residentialUnitValue
                                businessOccupyEntireProperty
                                commercialUnitRentBreakdown
                                commercialTenantAmount
                                ownsOtherCommercialProperty
                                ownOtherProperties
                                occupyProperty
                                ownsOtherInvestmentProperties
                                familyHasOccupied
                                secondCharge
                                occupyingGreaterThan40Percent
                                location
                                locationOther
                                address
                                postcode
                                propertyOwnership
                                refurbishmentCost
                                invoiceFinanceType
                                leaseHirePurchase
                                mainApplicantCreditDifficulties
                                mainApplicantCreditDifficultiesExplanation
                                mainApplicantCitizenshipStatus
                                mainApplicantCountryOfResidence
                                mainApplicantCountryOfResidenceOther
                                mainApplicantNationality
                                mainApplicantNationalityOther
                                mainApplicantOccupation
                                mainApplicantGrossIncome
                                mainApplicantExitStrategy
                                agreeToTerms
                            }
                            storedData {
                                meta {
                                    key
                                }
                                value
                            }
                            status
                            createdAt
                            updatedAt
                        }
                        cursor
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                        startCursor
                        hasPreviousPage
                    }
                    totalCount
                }
            }
        `;

        return apolloFetch({
            query: query,
            variables: {
                cursor: null,
                filters: [
                    {
                        operation: 'eq',
                        operand: STATUS_WITHDRAWN,
                        column: 'status',
                        not: true,
                    },
                    {
                        operation: 'eq',
                        operand: STATUS_WITHDRAWN_EDITED,
                        column: 'status',
                        not: true,
                    },
                    {
                        operation: 'eq',
                        operand: STATUS_WITHDRAWN_FULLY,
                        column: 'status',
                        not: true,
                    },
                ],
                first: 4,
                sorts: [
                    {
                        column: 'updatedAt',
                        direction: 'desc',
                    },
                ],
                storedDataFilters: [
                    'LOAN_AMOUNT',
                    'LOAN_TYPE',
                    'BUSINESS_SECTOR',
                    'BUSINESS_NAME',
                    'PROPERTY_PURCHASE_PRICE',
                    'PROPERTY_TYPE',
                    'LOAN_PURPOSE',
                    'LOAN_REASON',
                    'ADDITIONAL_INFO_NOTES',
                ],
            },
        });
    }

    getLELoanType = (le) => {
        if (true === le.node.isNewForm) {
            const data = le.node.loanEnquiryData.loanType;
            return data ?? '-';
        } else {
            const data = le.node.storedData.find((item) => item.meta.key === 'LOAN_TYPE');
            return data ? data.value : '-';
        }
    };

    getLEAdditionalNotes = (le) => {
        if (true === le.node.isNewForm) {
            const data = le.node.loanEnquiryData.additionalData;
            return data ? data : '-';
        } else {
            const data = le.node.storedData.find((item) => item.meta.key === 'ADDITIONAL_INFO_NOTES');
            return data ? data.value.substring(0, 140) + '...' : null;
        }
    };

    getLELoanReason = (le) => {
        const data = le.node.storedData.find((item) => item.meta.key === 'LOAN_REASON');
        return data ? data.value : '-';
    };

    getLELoanPurpose = (le) => {
        if (true === le.node.isNewForm) {
            const loanPurposeEnum = new LoanPurposes().getOptions();
            const data = le.node.loanEnquiryData.loanPurpose;
            return data && loanPurposeEnum[data] ? loanPurposeEnum[data].label : '-';
        } else {
            const businessFinanceLoanPurposeEnum = new BusinessFinanceLoanPurposeEnum();
            const data = le.node.storedData.find((item) => item.meta.key === 'LOAN_PURPOSE');
            return data ? businessFinanceLoanPurposeEnum.getType(data.value) : '-';
        }
    };

    getLEBusinessName = (le) => {
        if (true === le.node.isNewForm) {
            const data = le.node.loanEnquiryData.businessName;
            return data ? data : '-';
        } else {
            const data = le.node.storedData.find((item) => item.meta.key === 'BUSINESS_NAME');
            return data ? data.value : '-';
        }
    };

    getLEBusinessSector = (le) => {
        if (true === le.node.isNewForm) {
            const data = le.node.loanEnquiryData.businessSector;
            return data ? data : '-';
        } else {
            const data = le.node.storedData.find((item) => item.meta.key === 'BUSINESS_SECTOR');
            return data ? data.value : '-';
        }
    };

    getLEPropertyType = (le) => {
        if (true === le.node.isNewForm) {
            const commercialFundingTypes = new CommercialFundingType().getTypes();
            const data = le.node.loanEnquiryData.propertyType;
            return data && commercialFundingTypes[data] ? commercialFundingTypes[data].label : '-';
        } else {
            const propertyTypeEnum = new PropertyTypeEnum();
            const data = le.node.storedData.find((item) => item.meta.key === 'PROPERTY_TYPE');
            return data ? propertyTypeEnum.getType(data.value) : '-';
        }
    };

    getLEPropertyValue = (le) => {
        const formatter = new Intl.NumberFormat('en-UK', {
            style: 'currency',
            currency: 'GBP',
            minimumFractionDigits: 0,
        });
        if (true === le.node.isNewForm) {
            const data = le.node.loanEnquiryData.currentValue;
            return formatter.format(data ? data : 0);
        } else {
            const data = le.node.storedData.find((item) => item.meta.key === 'PROPERTY_PURCHASE_PRICE');
            return formatter.format(data ? data.value / 100 : 0); // Amount in pennies
        }
    };

    getLEMainApplicant = (le) => {
        const data = le.node.loanEnquiryApplicants.find((item) => item.isMainApplicant);
        return data ? data.applicant.given + ' ' + data.applicant.family : '-';
    };

    render() {
        const { session, redirect, dispatch, setModalComponent, setModalClose, hideBannerNotice } = this.props;
        const { loanEnquiries, recentLE } = this.state;
        const currentSession = session.getRelevantSession();
        let emptyLEs = [];

        // Generate empty LE spots
        if (loanEnquiries) {
            if (loanEnquiries.length < 3) {
                for (let i = 0; i < 3 - loanEnquiries.length; i++) {
                    emptyLEs.push(<LEEmptyCard url={ROUTE_BORROWER_CREATE_LOAN_ENQUIRY} label={'ADD A NEW ENQUIRY'} />);
                }
            }
        }

        if (false === currentSession.isVerified() && true === currentSession.smsVerificationRequested()) {
            setModalComponent(
                <SMSVerificationModal
                    setModalClose={setModalClose}
                    resendCode={false}
                    hideBannerNotice={hideBannerNotice}
                    welcomeModal={
                        null === recentLE ? <Welcome url={ROUTE_BORROWER_CREATE_LOAN_ENQUIRY} inModal={true} /> : null
                    }
                    setModalComponent={null === recentLE ? setModalComponent : null}
                />,
                false
            );
        }

        return (
            <div className={'dashboard-borrower'}>
                <RebrandRedirect />
                <div>
                    <Header
                        user={{
                            name: currentSession.getName(),
                            email: currentSession.getEmail(),
                            type: currentSession.getMostSeniorRole().label,
                        }}
                    />
                </div>
                <Grid container className={'le-container'} key={'dkg_1'}>
                    <Grid container style={{ marginTop: 20 }} key={'dkg_2'}>
                        <Item lg={10} md={10} xs={12} sm={12} key={'dkg_3'}>
                            <Paper className={'main-le'}>
                                <Grid key={'dkg_4'}>
                                    <div className={'header'}>
                                        <div className={'title'}>Your Recent Enquiries:</div>
                                        <div className={'link'}>
                                            <a className={'view-all-link'} href={ROUTE_BORROWER_LOAN_ENQUIRIES}>
                                                <span style={{ marginRight: 10 }}>VIEW ALL ENQUIRIES</span>{' '}
                                                <svg
                                                    width="22"
                                                    height="22"
                                                    viewBox="0 0 22 22"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path
                                                        d="M11 1.42652e-06C13.9174 1.299e-06 16.7153 1.15893 18.7782 3.22183C20.8411 5.28473 22 8.08262 22 11C22 13.9174 20.8411 16.7153 18.7782 18.7782C16.7153 20.8411 13.9174 22 11 22C8.08262 22 5.28473 20.8411 3.22183 18.7782C1.15893 16.7153 -3.53302e-07 13.9174 -4.80825e-07 11C-6.08348e-07 8.08262 1.15892 5.28473 3.22183 3.22183C5.28473 1.15893 8.08262 1.55405e-06 11 1.42652e-06ZM6.1875 10.3125C6.00516 10.3125 5.8303 10.3849 5.70136 10.5139C5.57243 10.6428 5.5 10.8177 5.5 11C5.5 11.1823 5.57243 11.3572 5.70136 11.4861C5.8303 11.6151 6.00516 11.6875 6.1875 11.6875L14.1529 11.6875L11.2008 14.6382C11.0717 14.7673 10.9991 14.9424 10.9991 15.125C10.9991 15.3076 11.0717 15.4827 11.2008 15.6118C11.3298 15.7408 11.5049 15.8134 11.6875 15.8134C11.8701 15.8134 12.0452 15.7408 12.1743 15.6118L16.2993 11.4867C16.3633 11.4229 16.4141 11.347 16.4487 11.2635C16.4834 11.18 16.5012 11.0904 16.5012 11C16.5012 10.9096 16.4834 10.82 16.4487 10.7365C16.4141 10.653 16.3633 10.5771 16.2993 10.5133L12.1743 6.38825C12.0452 6.25916 11.8701 6.18663 11.6875 6.18663C11.5049 6.18663 11.3298 6.25916 11.2008 6.38825C11.0717 6.51734 10.9991 6.69243 10.9991 6.875C10.9991 7.05757 11.0717 7.23266 11.2008 7.36175L14.1529 10.3125L6.1875 10.3125Z"
                                                        fill="#596F7E"
                                                    />
                                                </svg>
                                            </a>
                                        </div>
                                    </div>
                                </Grid>
                                {!recentLE && !loanEnquiries ? (
                                    <Paper elevation={0} className={'recent-le'} style={{ minHeight: 300 }}>
                                        <Grid container key={'dkg_5'}>
                                            <LECardSkeleton
                                                style={{
                                                    paddingLeft: 20,
                                                    paddingRight: 20,
                                                    marginTop: 20,
                                                    width: '100%',
                                                }}
                                                height={195}
                                            />
                                        </Grid>
                                    </Paper>
                                ) : recentLE ? (
                                    <Paper elevation={0} className={'recent-le'} style={{ minHeight: 300 }}>
                                        <Grid container>
                                            <>
                                                <Item
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    sm={12}
                                                    xs={12}
                                                    className={'box-1'}
                                                    key={'dkg_6'}
                                                >
                                                    {recentLE ? (
                                                        <LECard
                                                            borrower
                                                            le={recentLE.node}
                                                            cardStyle={{ border: 'none' }}
                                                            mobileLEViewButton={true}
                                                            dispatch={dispatch}
                                                        />
                                                    ) : (
                                                        <div
                                                            style={{
                                                                paddingLeft: 20,
                                                                paddingRight: 20,
                                                                marginTop: 20,
                                                            }}
                                                        >
                                                            <Skeleton variant="text" />
                                                            <Skeleton variant="text" />
                                                            <Skeleton variant="text" />
                                                            <Skeleton variant="rect" height={195} />
                                                        </div>
                                                    )}
                                                </Item>
                                                <Item
                                                    item
                                                    lg={4}
                                                    md={4}
                                                    sm={12}
                                                    xs={12}
                                                    style={{ padding: 20 }}
                                                    className={'box-2'}
                                                    key={'dkg_7'}
                                                >
                                                    <div className={'applicant-header'}>Main Applicant:</div>
                                                    <div className={'applicant-value'}>
                                                        {this.getLEMainApplicant(recentLE)}
                                                    </div>
                                                    <div className={'loan-purpose-header'}>Loan Purpose:</div>
                                                    {recentLE.node.isNewForm ? (
                                                        <div className={'loan-purpose-value'}>
                                                            {this.getLELoanPurpose(recentLE)}
                                                        </div>
                                                    ) : (
                                                        <div className={'loan-purpose-value'}>
                                                            {this.getLELoanType(recentLE) === LOAN_TYPE_BUSINESS_FINANCE
                                                                ? this.getLELoanPurpose(recentLE)
                                                                : [
                                                                      this.getLELoanType(recentLE) ===
                                                                      LOAN_TYPE_PROPERTY
                                                                          ? this.getLELoanReason(recentLE)
                                                                          : null,
                                                                  ]}
                                                        </div>
                                                    )}
                                                    {this.getLEAdditionalNotes(recentLE) && (
                                                        <>
                                                            <div className={'loan-note-header'}>Loan Notes:</div>
                                                            <div className={'loan-note-value'}>
                                                                {this.getLEAdditionalNotes(recentLE)}
                                                            </div>
                                                        </>
                                                    )}
                                                </Item>
                                                <Hidden smDown>
                                                    <Item
                                                        item
                                                        lg={4}
                                                        md={4}
                                                        sm={12}
                                                        xs={12}
                                                        style={{ padding: 20 }}
                                                        className={'box-3'}
                                                        key={'dkg_8'}
                                                    >
                                                        <div style={{ marginBottom: 40, paddingTop: 10 }}>
                                                            <Button
                                                                size={'large'}
                                                                variant="contained"
                                                                color={'primary'}
                                                                className={'primary-button'}
                                                                onClick={() => {
                                                                    if (recentLE.node.status === STATUS_DRAFT) {
                                                                        dispatch(
                                                                            push(
                                                                                path(ROUTE_BORROWER_EDIT_LOAN_ENQUIRY, {
                                                                                    loanEnquiryId: recentLE.node.id,
                                                                                })
                                                                            )
                                                                        );
                                                                    } else {
                                                                        dispatch(
                                                                            push(
                                                                                path(ROUTE_BORROWER_LOAN_ENQUIRY_VIEW, {
                                                                                    loanEnquiryId: recentLE.node.id,
                                                                                })
                                                                            )
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                VIEW LOAN ENQUIRY
                                                            </Button>
                                                        </div>
                                                        <div>
                                                            {this.getLELoanType(recentLE) ===
                                                            LOAN_TYPE_BUSINESS_FINANCE ? (
                                                                <>
                                                                    <div className={'business-name-header'}>
                                                                        Business Name:
                                                                    </div>
                                                                    <div className={'business-name-value'}>
                                                                        {this.getLEBusinessName(recentLE)}
                                                                    </div>
                                                                    <div className={'business-sector-header'}>
                                                                        Business Sector:
                                                                    </div>
                                                                    <div className={'business-sector-value'}>
                                                                        {this.getLEBusinessSector(recentLE)}
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                [
                                                                    this.getLELoanType(recentLE) ===
                                                                    LOAN_TYPE_PROPERTY ? (
                                                                        <>
                                                                            <div className={'property-type-header'}>
                                                                                Type of Property:
                                                                            </div>
                                                                            <div className={'property-type-value'}>
                                                                                {this.getLEPropertyType(recentLE)}
                                                                            </div>
                                                                            <div className={'property-value-header'}>
                                                                                Property Value:
                                                                            </div>
                                                                            <div className={'property-value-value'}>
                                                                                {this.getLEPropertyValue(recentLE)}
                                                                            </div>
                                                                        </>
                                                                    ) : null,
                                                                ]
                                                            )}
                                                        </div>
                                                    </Item>
                                                </Hidden>
                                            </>
                                        </Grid>
                                    </Paper>
                                ) : (
                                    <></>
                                )}
                                {loanEnquiries ? (
                                    <Grid container spacing={4} style={{ justifyContent: 'center' }} key={'dkg_9'}>
                                        {loanEnquiries &&
                                            loanEnquiries.map((le, index) => (
                                                <Item item lg={4} md={4} xs={12} key={'dkg_10' + index}>
                                                    <Paper
                                                        elevation={0}
                                                        className={'other-le'}
                                                        onClick={() => {
                                                            if (le.node.status === STATUS_DRAFT) {
                                                                dispatch(
                                                                    push(
                                                                        path(ROUTE_BORROWER_EDIT_LOAN_ENQUIRY, {
                                                                            loanEnquiryId: le.node.id,
                                                                        })
                                                                    )
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    push(
                                                                        path(ROUTE_BORROWER_LOAN_ENQUIRY_VIEW, {
                                                                            loanEnquiryId: le.node.id,
                                                                        })
                                                                    )
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        <LECard borrower le={le.node} />
                                                    </Paper>
                                                </Item>
                                            ))}
                                        {loanEnquiries && emptyLEs.length > 0 && emptyLEs.map((item) => item)}
                                    </Grid>
                                ) : (
                                    <Grid container key={'dkg_11'}>
                                        <Item item lg={4} md={4} xs={12} key={'dkg_12'}>
                                            <Paper
                                                className={'other-le-skeleton'}
                                                elevation={0}
                                                style={{
                                                    marginRight: 10,
                                                }}
                                            >
                                                <LECardSkeleton height={175} />
                                            </Paper>
                                        </Item>
                                        <Item item lg={4} md={4} xs={12} key={'dkg_13'}>
                                            <Paper
                                                className={'other-le-skeleton'}
                                                elevation={0}
                                                style={{
                                                    marginLeft: 10,
                                                    marginRight: 10,
                                                }}
                                            >
                                                <LECardSkeleton height={175} />
                                            </Paper>
                                        </Item>
                                        <Item item lg={4} md={4} xs={12} key={'dkg_14'}>
                                            <Paper
                                                className={'other-le-skeleton'}
                                                elevation={0}
                                                style={{
                                                    marginLeft: 10,
                                                }}
                                            >
                                                <LECardSkeleton height={175} />
                                            </Paper>
                                        </Item>
                                    </Grid>
                                )}
                            </Paper>
                        </Item>
                        <Item lg={2} md={2} xs={12} sm={12} className={'right-column'} key={'dkg_15'}>
                            <Paper className={'process'}>
                                <div className={'icon'}>
                                    <img src={P4ProcessIcon} alt={''} />
                                </div>
                                <div className={'header'}>
                                    I'VE APPLIED, WHAT
                                    <br />
                                    COMES NEXT?
                                </div>
                                <div className={'cta'}>
                                    <Button
                                        variant="contained"
                                        color={'primary'}
                                        className={'primary-button'}
                                        style={{ marginRight: 0 }}
                                    >
                                        <a
                                            rel={'noreferrer'}
                                            style={{ color: 'white', textDecoration: 'none' }}
                                            target={'_blank'}
                                            href={ROUTE_PITCH4_PROCESSES}
                                        >
                                            THE {BRAND_NAME_UPPERCASE} PROCESS
                                        </a>
                                    </Button>
                                </div>
                            </Paper>
                            <Paper className={'faqs'}>
                                <div className={'icon'}>
                                    <img src={FAQIcon} alt={''} />
                                </div>
                                <div className={'header'}>
                                    HAVE A QUESTION
                                    <br />
                                    ABOUT FUNDING?
                                </div>
                                <div className={'cta'}>
                                    <Button
                                        variant="contained"
                                        color={'primary'}
                                        className={'primary-button'}
                                        style={{ marginRight: 0 }}
                                    >
                                        <a
                                            rel={'noreferrer'}
                                            style={{ color: 'white', textDecoration: 'none' }}
                                            target={'_blank'}
                                            href={ROUTE_PITCH4_KNOWLEDGE_BASE}
                                        >
                                            READ OUR FAQS
                                        </a>
                                    </Button>
                                </div>
                            </Paper>
                        </Item>
                    </Grid>
                </Grid>
                <Grid key={'dkg_16'}>
                    <Paper className={'other-to-do'}>
                        <Grid container style={{}} key={'dkg_17'}>
                            <Item lg={12} md={12} sm={12} xs={12} key={'dkg_18'}>
                                <div className={'header'}>What Else Do You Want to DO?</div>
                            </Item>
                            <Item lg={3} md={3} sm={12} xs={12} key={'dkg_19'}>
                                <Paper
                                    className={'box-1'}
                                    elevation={0}
                                    onClick={() => redirect(routeManager.route(ROUTE_BORROWER_CREATE_LOAN_ENQUIRY))}
                                >
                                    <div className={'icon'}>
                                        <img src={SearchIcon} alt={''} />
                                    </div>
                                    <div className={'header'}>
                                        START A NEW FUNDING
                                        <br />
                                        SEARCH
                                    </div>
                                </Paper>
                            </Item>
                            <Item lg={3} md={3} sm={12} xs={12} key={'dkg_20'}>
                                <Paper
                                    className={'box-2'}
                                    elevation={0}
                                    onClick={() => redirect(ROUTE_BORROWER_DOCUMENTS_VIEW)}
                                >
                                    <div className={'icon'}>
                                        <img src={DocumentsIcon} alt={''} />
                                    </div>
                                    <div className={'header'}>
                                        MANAGE YOUR <br />
                                        DOCUMENTS
                                    </div>
                                </Paper>
                            </Item>
                            <Item lg={3} md={3} sm={12} xs={12} key={'dkg_21'}>
                                <Paper
                                    className={'box-3'}
                                    elevation={0}
                                    onClick={() => redirect(ROUTE_BORROWER_CONFIGURE_PROFILE)}
                                >
                                    <div className={'icon'}>
                                        <img src={AccountIcon} alt={''} />
                                    </div>
                                    <div className={'header'}>
                                        REVIEW YOUR
                                        <br />
                                        ACCOUNT DETAILS
                                    </div>
                                </Paper>
                            </Item>
                            <Item lg={3} md={3} sm={12} xs={12} key={'dkg_22'}>
                                <Paper
                                    className={'box-4'}
                                    elevation={0}
                                    onClick={() => window.HubSpotConversations.widget.open()}
                                >
                                    <div className={'icon'}>
                                        <img src={P4AdminsIcon} alt={''} />
                                    </div>
                                    <div className={'header'}>
                                        SPEAK TO {BRAND_NAME_UPPERCASE}
                                        <br />
                                        ADMINS
                                    </div>
                                </Paper>
                            </Item>
                        </Grid>
                    </Paper>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => dispatch(push(url)),
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        hideBannerNotice: () => dispatch(actionBannerNoticeHide()),
    };
};

DashboardContainer.propTypes = {};

export default compose(withSession, connect(mapStateToProps, mapDispatchToProps), withSession)(DashboardContainer);
