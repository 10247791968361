import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import InputFile from '../InputFile';
import { MAX_FILE_UPLOAD_SIZE_BYTES, MAX_FILE_UPLOAD_SIZE_MB } from '../../../constants';

const fileNotEmpty = (value, formValues, { userStoreDocument }) => {
    const document = typeof userStoreDocument === 'undefined' ? '' : String(userStoreDocument);
    return String(document).length > 0 || value instanceof File ? undefined : 'File is required.';
};

const fileSizeAcceptable = (value) => {
    if (value instanceof File) {
        return value.size <= MAX_FILE_UPLOAD_SIZE_BYTES
            ? undefined
            : 'That file is too large. We accept uploads of up to ' + MAX_FILE_UPLOAD_SIZE_MB + 'MB';
    }

    return undefined;
};

class UploadButton extends React.Component {
    render() {
        return (
            <Field
                name="file"
                component={InputFile}
                label="Upload Document"
                validate={[fileNotEmpty, fileSizeAcceptable]}
                disabled={true}
            />
        );
    }
}

UploadButton.propTypes = {
    disabled: PropTypes.bool,
};

export default UploadButton;
