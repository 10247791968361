import React from 'react';

import { Container } from './index';

const CenteringContainer = ({ justify = 'center', alignItems = 'center', className = '', ...props }) => (
    <Container justifyContent={justify} alignItems={alignItems} className={className} {...props}>
        {props.children}
    </Container>
);

export default CenteringContainer;
