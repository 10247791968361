import React from 'react';

import { Field, FieldArray as ReduxFieldArray } from 'redux-form';

import MenuItem from '@material-ui/core/MenuItem';
import CheckboxMUI from '@material-ui/core/Checkbox/Checkbox';

import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';

import MUIIconButton from '@material-ui/core/IconButton';

import { Select as WrappedSelect, TextField } from 'redux-form-material-ui';

import TextFieldMUI from '@material-ui/core/TextField/TextField';
import IconFactory from '../icons/IconFactory';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const iconFactory = new IconFactory();

export const ReadOnlyField = ({ label = '', value = '', type = 'text' }) => (
    <TextFieldMUI label={label} value={value} type={type} fullWidth={true} disabled={true} variant={`filled`} />
);

ReadOnlyField.propTypes = {
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
};

export const TextInput = ({
    name,
    autoFill = null,
    hintText = '',
    placeholder = '',
    type = 'text',
    className = '',
    style = {},
    variant = 'outlined',
    margin = 'normal',
    ...props
}) => {
    return (
        <Field
            type={type}
            name={name}
            className={className}
            style={style}
            margin={margin}
            placeholder={``}
            label={placeholder}
            autoComplete={`off`}
            component={TextField}
            fullWidth={true}
            {...props}
        />
    );
};

TextInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    validate: PropTypes.any,
    className: PropTypes.string,
    style: PropTypes.object,
    value: PropTypes.string,
};

export const TextAreaInput = ({
    name,
    hintText = '',
    label = '',
    rows = 3,
    maxRows = 10,
    className,
    validate,
    variant = 'outlined',
    ...props
}) => {
    return (
        <Field
            multiline={true}
            name={name}
            label={label}
            className={className}
            placeholder={hintText}
            component={TextField}
            fullWidth={true}
            validate={validate}
            {...props}
        />
    );
};

TextAreaInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
};

export const IconButton = ({ icon = '', onClick = () => {}, style = {}, className, ...props }) => (
    <MUIIconButton className={className} onClick={onClick} style={style} {...props}>
        {iconFactory.createIcon(icon)}
    </MUIIconButton>
);

IconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
};

export const PrimaryButton = ({
    name,
    label = '',
    type = 'button',
    onClick = () => {},
    style = {},
    className,
    ...props
}) => (
    <Button name={name} label={label} type={type} className={className} onClick={onClick} style={style} {...props}>
        {label}
    </Button>
);

export const Select = ({
    name,
    label = '',
    options = [],
    style = { paddingTop: 15 },
    className,
    validate,
    ...props
}) => {
    return (
        <Field name={name} component={WrappedSelect} validate={validate} style={style} {...props}>
            {options.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                    {item.label}
                </MenuItem>
            ))}
        </Field>
    );
};

Select.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ),
};

const CheckboxRaw = ({ input: { name, ...props }, label = '', disabled = false }) => {
    const value = props.value ? '1' : '0';

    return (
        <FormControlLabel
            control={<CheckboxMUI {...props} name={name} value={value} disabled={disabled} />}
            label={label}
            checked={value === '1'}
        />
    );
};

export const Checkbox = ({ name, label = '', validate, disabled = false, ...props }) => {
    return (
        <Field
            component={CheckboxRaw}
            id={name}
            name={name}
            label={label}
            validate={validate}
            disabled={disabled}
            {...props}
        />
    );
};

export const FieldArray = ({ name, component, ...props }) => {
    return <ReduxFieldArray name={name} component={component} />;
};

FieldArray.propTypes = {
    name: PropTypes.string.isRequired,
};
