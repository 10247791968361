import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { actionGetResetToken, getResetToken } from '../redux/reset';
import { Loading } from 'pitch4_layout/components/waiting';
import LinkExpiredContainer from './LinkExpiredContainer';

class ResetTwoFactorAuthContainer extends React.Component {
    componentDidMount() {
        const { match, fetchResetToken } = this.props;

        fetchResetToken(match.params.token);
    }

    render() {
        const { resetToken = null } = this.props;

        if (resetToken === null || resetToken.hasExpired === false) {
            return <Loading />;
        }

        return <LinkExpiredContainer />;
    }
}

const mapStateToProps = (state) => {
    return {
        resetToken: getResetToken(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchResetToken: (tokenId) => dispatch(actionGetResetToken(tokenId)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ResetTwoFactorAuthContainer);
