import { spawn } from 'redux-saga/effects';

import adminRoot from '../../apps/admin/sagas/index';
import authRoot from '../../apps/authentication/sagas/index';
import routingRoot from '../../pitch4_routing/sagas/routingRoot';
import routingEvents from '../../pitch4_routing/sagas/routingEvents';
import notificationsRoot from '../../apps/notifications/sagas';
import navigationRoot from '../../apps/navigation/sagas/navigation';
import modalRoot from '../../apps/modal/sagas';
import hubspotRoot from '../../apps/hubspot/sagas';
import borrowerRoot from '../../apps/borrower/sagas';
import chatRoot from '../../pitch4_chat/sagas/index';
import twoFactorRoot from '../../apps/user/two_factor_auth/sagas';
import twoFactorResetRoot from '../../apps/two_factor_auth_reset/sagas';
import passwordResetRoot from '../../apps/password_reset/sagas';
import registrationRoot from '../../apps/registration/sagas';
import termsAndConditionsRoot from '../../apps/terms_and_conditions/sagas';
import configureProfileRoot from '../../apps/user/configure_profile/sagas/';

function* main() {
    yield spawn(routingEvents);
    yield spawn(routingRoot);
    yield spawn(adminRoot);
    yield spawn(authRoot);
    yield spawn(notificationsRoot);
    yield spawn(navigationRoot);
    yield spawn(modalRoot);
    yield spawn(borrowerRoot);
    yield spawn(hubspotRoot);
    yield spawn(chatRoot);
    yield spawn(twoFactorRoot);
    yield spawn(twoFactorResetRoot);
    yield spawn(passwordResetRoot);
    yield spawn(registrationRoot);
    yield spawn(termsAndConditionsRoot);
    yield spawn(configureProfileRoot);
}

export default main;
