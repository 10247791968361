import axios from 'axios';
import FileSaver from 'file-saver';
import { HOST } from '../../bootstrap/constants/constants';

class ViewDocumentManager {
    constructor(sessionManager) {
        this.sessionManager = sessionManager;
    }

    downloadFile(downloadUrl) {
        return axios.get(HOST + downloadUrl, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Bearer ' + this.sessionManager.getToken().token,
            },
            responseType: 'blob',
        });
    }

    forceDownload(response, document) {
        const blob = new Blob([response.data], { type: `${document.mimeType || response.data.type};charset=utf-8` });
        FileSaver.saveAs(blob, document.fileName);
    }
}

export default ViewDocumentManager;
