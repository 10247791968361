import { BaseEnum } from '../../../../pitch4_enum';

export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_INTERMEDIARY = 'ROLE_INTERMEDIARY';
export const ROLE_SLAM = 'ROLE_SLAM';
export const ROLE_BORROWER = 'ROLE_BORROWER';
export const IS_AUTHENTICATED_FULLY = 'IS_AUTHENTICATED_FULLY';
export const ROLE_SKELETON_USER = 'ROLE_SKELETON_USER';
export const ROLE_USER = 'ROLE_USER';

class NameEnum extends BaseEnum {
    getTypes = () => {
        return {
            [ROLE_SUPER_ADMIN]: 'System Administrator',
            [ROLE_ADMIN]: 'Administrator',
            [ROLE_INTERMEDIARY]: 'Intermediary',
            [ROLE_SLAM]: 'Primary Account Manager',
            [ROLE_BORROWER]: 'Borrower',
            [IS_AUTHENTICATED_FULLY]: 'Authenticated Fully',
            [ROLE_SKELETON_USER]: 'Anonymous user',
            [ROLE_USER]: 'User',
        };
    };
}

export default NameEnum;
