import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const forceMatchEnquiryMutation = `
  mutation forceMatch($loanEnquiryId: ID!, $businessStreamId: ID!) {
      forceMatch(loanEnquiryId: $loanEnquiryId, businessStreamId: $businessStreamId) {
        success
      }
    }
`;

export const forceMatchEnquiry = (loanEnquiryId, businessStreamId) => {
    return apolloFetch({
        query: forceMatchEnquiryMutation,
        variables: {
            businessStreamId: businessStreamId,
            loanEnquiryId: loanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
