import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import BorrowerQuestions, {
    QUESTION_BUSINESS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION,
    QUESTION_BUSINESS_IS_SECURE_FINANCE,
    QUESTION_BUSINESS_OUTSTANDING_DEBT,
    QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
    QUESTION_BUSINESS_PROFITABLE,
    QUESTION_LOAN_AMOUNT,
    QUESTION_LOAN_TERM_BUSINESS_TERM,
    QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
    QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS,
    QUESTION_TURN_OVER,
} from '../../../enums/Borrower/BorrowerQuestions';
import YesNo, { YES } from '../../../enums/Shared/YesNo';
import Question from '../../Question/containers/Question';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';

class BusinessFinancialsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const yesNoOptions = new YesNo().getTypes();

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER
                            ? 'Can you provide some details on your business financials?'
                            : 'Can you provide some details on your clients business financials?'}
                    </Typography>
                </Grid>
                <Question
                    type={questions[QUESTION_LOAN_AMOUNT].type}
                    label={questions[QUESTION_LOAN_AMOUNT].label}
                    questionEnum={'QUESTION_LOAN_AMOUNT'}
                    fieldName={questions[QUESTION_LOAN_AMOUNT].fieldName}
                    placeholder={questions[QUESTION_LOAN_AMOUNT].placeholder}
                    validation={questions[QUESTION_LOAN_AMOUNT].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_LOAN_TERM_BUSINESS_TERM].type}
                    label={questions[QUESTION_LOAN_TERM_BUSINESS_TERM].label}
                    questionEnum={'QUESTION_LOAN_TERM_BUSINESS_TERM'}
                    fieldName={questions[QUESTION_LOAN_TERM_BUSINESS_TERM].fieldName}
                    placeholder={questions[QUESTION_LOAN_TERM_BUSINESS_TERM].placeholder}
                    validation={questions[QUESTION_LOAN_TERM_BUSINESS_TERM].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_TURN_OVER].type}
                    label={questions[QUESTION_TURN_OVER].label}
                    questionEnum={'QUESTION_TURN_OVER'}
                    fieldName={questions[QUESTION_TURN_OVER].fieldName}
                    placeholder={questions[QUESTION_TURN_OVER].placeholder}
                    validation={questions[QUESTION_TURN_OVER].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_PROFITABLE].type}
                    label={questions[QUESTION_BUSINESS_PROFITABLE].label}
                    questionEnum={'QUESTION_BUSINESS_PROFITABLE'}
                    fieldName={questions[QUESTION_BUSINESS_PROFITABLE].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_PROFITABLE].placeholder}
                    validation={questions[QUESTION_BUSINESS_PROFITABLE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_OVER_DRAFT_FACILITY].type}
                    label={questions[QUESTION_BUSINESS_OVER_DRAFT_FACILITY].label}
                    questionEnum={'QUESTION_BUSINESS_OVER_DRAFT_FACILITY'}
                    fieldName={questions[QUESTION_BUSINESS_OVER_DRAFT_FACILITY].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_OVER_DRAFT_FACILITY].placeholder}
                    validation={questions[QUESTION_BUSINESS_OVER_DRAFT_FACILITY].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={questions[QUESTION_BUSINESS_OVER_DRAFT_FACILITY].rows}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_OUTSTANDING_DEBT].type}
                    label={questions[QUESTION_BUSINESS_OUTSTANDING_DEBT].label}
                    questionEnum={'QUESTION_BUSINESS_OUTSTANDING_DEBT'}
                    fieldName={questions[QUESTION_BUSINESS_OUTSTANDING_DEBT].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_OUTSTANDING_DEBT].placeholder}
                    validation={questions[QUESTION_BUSINESS_OUTSTANDING_DEBT].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_ADVERSE_CREDIT].type}
                    label={questions[QUESTION_BUSINESS_ADVERSE_CREDIT].label}
                    questionEnum={'QUESTION_BUSINESS_ADVERSE_CREDIT'}
                    fieldName={questions[QUESTION_BUSINESS_ADVERSE_CREDIT].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_ADVERSE_CREDIT].placeholder}
                    validation={questions[QUESTION_BUSINESS_ADVERSE_CREDIT].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />

                {this.state.formData[questions[QUESTION_BUSINESS_ADVERSE_CREDIT].fieldName] === YES && (
                    <>
                        <Question
                            type={questions[QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION].type}
                            label={questions[QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION].label}
                            questionEnum={'QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION'}
                            fieldName={questions[QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION].fieldName}
                            placeholder={questions[QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION].placeholder}
                            validation={questions[QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={questions[QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION].rows}
                        />
                    </>
                )}
                <Question
                    type={questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].type}
                    label={questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].label}
                    questionEnum={'QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT'}
                    fieldName={questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].placeholder}
                    validation={questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                {this.state.formData[questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].fieldName] === YES && (
                    <>
                        <Question
                            type={questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION].type}
                            label={questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION].label}
                            questionEnum={'QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION'}
                            fieldName={questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION].fieldName}
                            placeholder={questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION].placeholder}
                            validation={
                                questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION].fieldName + '_error'
                            }
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION].rows}
                        />
                    </>
                )}
                {this.state.formData[questions[QUESTION_BUSINESS_IS_SECURE_FINANCE].fieldName] === YES && (
                    <Question
                        type={questions[QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS].type}
                        label={questions[QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS].label}
                        questionEnum={'QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS'}
                        fieldName={questions[QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS].fieldName}
                        placeholder={
                            questions[QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS].placeholder
                        }
                        validation={
                            questions[QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS].fieldName + '_error'
                        }
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={[]}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
                <Question
                    type={questions[QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS].type}
                    label={questions[QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS].label}
                    questionEnum={'QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS'}
                    fieldName={questions[QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS].fieldName}
                    placeholder={questions[QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS].placeholder}
                    validation={questions[QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
            </Grid>
        );
    }
}

export default BusinessFinancialsContainer;
