import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const RowItem = ({ maxUnits = 12, ...props }) => (
    <Grid item xs={maxUnits} {...props}>
        {props.children}
    </Grid>
);

RowItem.propTypes = {
    maxUnits: PropTypes.number,
    className: PropTypes.any,
};

export default RowItem;
