import React from 'react';
import { Button } from '@material-ui/core';
import { massDeclineLender } from '../api/mass_decline_lenders';

export default function MassDeclineLenderModal(props) {
    const { setModalClose, selectedLenders } = props;
    const handleSubmit = () => {
        massDeclineLender(selectedLenders)
            .then((resp) => {
                setModalClose();
                window.location.reload();
            })
            .catch((e) => {
                // console.log(e);
            });
    };

    return (
        <div className={'assign-le-container-modal'}>
            <div className={'header'}>Mass Decline Selected Lenders</div>
            <div className={'content'}>Please confirm you want to set these selected Lenders to Declined</div>
            <div className={'actions'}>
                <Button className={'btn-back'} onClick={() => setModalClose()}>
                    CANCEL
                </Button>
                <Button
                    className={'btn-submit'}
                    variant={'contained'}
                    disabled={false}
                    color="primary"
                    onClick={() => handleSubmit()}
                >
                    DECLINE
                </Button>
            </div>
        </div>
    );
}
