import createP4ApolloFetch from '../../../../bootstrap/middlewares';
import { BORROWER_URL } from '../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

const removeLenderLoanEnquiryAlertMutation = `
  mutation removeLenderLoanEnquiryAlert($id: ID!){ 
    removeLenderLoanEnquiryAlert(id: $id){
      id
    }
  }
`;

export const removeLenderLoanEnquiryAlert = (lenderLoanEnquiryId) => {
    return apolloFetch({
        query: removeLenderLoanEnquiryAlertMutation,
        variables: {
            id: lenderLoanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
