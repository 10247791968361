import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import connect from 'react-redux/src/connect/connect';
import Modal from 'pitch4_modal/src/component/Modal';
import { sagaActionModalHide } from '../sagas/container';
import { selectorClassName, selectorComponent, selectorInteractive, selectorVisible } from '../redux/container';

class Container extends React.Component {
    render() {
        let { show, component, isInteractive, modalHide, className } = this.props;

        return (
            <Modal
                modalShow={show}
                handleClose={modalHide}
                render={() => component}
                modalInteractive={isInteractive}
                className={className}
            />
        );
    }
}

Container.propTypes = {
    show: PropTypes.bool.isRequired,
    component: PropTypes.object.isRequired,
    isInteractive: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        show: selectorVisible(state),
        component: selectorComponent(state),
        isInteractive: selectorInteractive(state),
        className: selectorClassName(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        modalHide: () => {
            dispatch(sagaActionModalHide());
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Container);
