import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import CookiePolicyPage from './CookiePolicyPage';
import LandingPage from './LandingPage';
import LoginPage from './LoginPage';
import TwoFactorAuthLoginPage from './TwoFactorAuthLoginPage';
import DefaultAdminPage from './admin/DefaultAdminPage';
import DefaultIntermediaryPage from './intermediary/DefaultIntermediaryPage';
import DefaultBorrowerPage from './borrower/DefaultBorrowerPage';
import DefaultSlamPage from './slam/DefaultSlamPage';
import PasswordResetPage from './PasswordResetPage';
import VerifyEmailPage from './VerifyEmailPage';
import BorrowerRegistrationPage from './borrower/RegistrationPage';
import IntermediaryRegistrationPage from './intermediary/RegistrationPage';
import TwoFactorAuthResetPage from './TwoFactorAuthResetPage';
import {
    ROUTE_ANON_LOGIN,
    ROUTE_ANON_2FA_LOGIN,
    ROUTE_BC_ROOT,
    ROUTE_BORROWER_ROOT,
    ROUTE_BORROWER_REGISTRATION_DEFAULT,
    ROUTE_BORROWER_REGISTRATION_COMMERCIAL_MORTGAGES,
    ROUTE_BORROWER_REGISTRATION_RESIDENTIAL_INVESTMENT_PROPERTIES,
    ROUTE_BORROWER_REGISTRATION_BRIDGING_FINANCE,
    ROUTE_BORROWER_REGISTRATION_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS,
    ROUTE_BORROWER_REGISTRATION_HMO_LENDING,
    ROUTE_BORROWER_REGISTRATION_BUSINESS_FINANCE,
    ROUTE_BORROWER_REGISTRATION_CARE_HOME,
    ROUTE_LANDING,
    ROUTE_P4A_ROOT,
    ROUTE_INTERMEDIARY_ROOT,
    ROUTE_SLAM_ROOT,
    ROUTE_PRIVACY_POLICY,
    ROUTE_COOKIE_POLICY,
    ROUTE_REQUEST_PASSWORD_RESET,
    ROUTE_PASSWORD_RESET,
    ROUTE_2FA_RESET,
    ROUTE_2FA_RESET_SUCCESS,
    ROUTE_VERIFY_EMAIL,
    ROUTE_INTERMEDIARY_REGISTRATION,
    ROUTE_ANON_LOGIN_CONNECT,
    ROUTE_ANON_LOGIN_WEALTHMAX,
    ROUTE_ANON_LOGIN_WARREN_FINANCE_LTD,
    ROUTE_ANON_LOGIN_ACCESS_FINANCIAL_SERVICES,
    ROUTE_ANON_LOGIN_GIFTWARE,
} from '../pitch4_routing';
import { actionHubspotTrackingInitialise } from '../apps/hubspot/redux/tracking';
import '../assets/sass/app.scss';
import { p4MuiTheme } from './layouts/theme';
import { MuiThemeProvider } from '@material-ui/core';

class App extends React.Component {
    componentDidMount() {
        const { initialiseHubspotTracking } = this.props;

        // Add the Hubspot tracking code.
        initialiseHubspotTracking();
    }

    render() {
        const { history } = this.props;

        return (
            <MuiThemeProvider theme={p4MuiTheme}>
                <ConnectedRouter history={history}>
                    <Switch>
                        <Route path={ROUTE_BC_ROOT} component={DefaultAdminPage} />
                        <Route path={ROUTE_P4A_ROOT} component={DefaultAdminPage} />

                        <Route path={ROUTE_INTERMEDIARY_ROOT} component={DefaultIntermediaryPage} />
                        <Route path={ROUTE_INTERMEDIARY_REGISTRATION} component={IntermediaryRegistrationPage} />

                        <Route path={ROUTE_BORROWER_ROOT} component={DefaultBorrowerPage} />
                        <Route path={ROUTE_BORROWER_REGISTRATION_DEFAULT} component={BorrowerRegistrationPage} />
                        <Route
                            path={ROUTE_BORROWER_REGISTRATION_COMMERCIAL_MORTGAGES}
                            component={BorrowerRegistrationPage}
                        />
                        <Route
                            path={ROUTE_BORROWER_REGISTRATION_RESIDENTIAL_INVESTMENT_PROPERTIES}
                            component={BorrowerRegistrationPage}
                        />
                        <Route
                            path={ROUTE_BORROWER_REGISTRATION_BRIDGING_FINANCE}
                            component={BorrowerRegistrationPage}
                        />
                        <Route
                            path={ROUTE_BORROWER_REGISTRATION_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS}
                            component={BorrowerRegistrationPage}
                        />
                        <Route path={ROUTE_BORROWER_REGISTRATION_CARE_HOME} component={BorrowerRegistrationPage} />
                        <Route path={ROUTE_BORROWER_REGISTRATION_HMO_LENDING} component={BorrowerRegistrationPage} />
                        <Route
                            path={ROUTE_BORROWER_REGISTRATION_BUSINESS_FINANCE}
                            component={BorrowerRegistrationPage}
                        />

                        <Route path={ROUTE_SLAM_ROOT} component={DefaultSlamPage} />

                        <Route path={ROUTE_ANON_2FA_LOGIN} component={TwoFactorAuthLoginPage} />
                        <Route path={ROUTE_ANON_LOGIN} component={LoginPage} />
                        {/*//Network Brokers*/}
                        <Route path={ROUTE_ANON_LOGIN_CONNECT} component={LoginPage} />
                        <Route path={ROUTE_ANON_LOGIN_WEALTHMAX} component={LoginPage} />
                        <Route path={ROUTE_ANON_LOGIN_WARREN_FINANCE_LTD} component={LoginPage} />
                        <Route path={ROUTE_ANON_LOGIN_ACCESS_FINANCIAL_SERVICES} component={LoginPage} />
                        <Route path={ROUTE_ANON_LOGIN_GIFTWARE} component={LoginPage} />
                        {/*// End Network Brokers*/}
                        <Route path={ROUTE_PRIVACY_POLICY} component={PrivacyPolicyPage} />
                        <Route path={ROUTE_COOKIE_POLICY} component={CookiePolicyPage} />

                        <Route path={ROUTE_REQUEST_PASSWORD_RESET} component={PasswordResetPage} />
                        <Route path={ROUTE_PASSWORD_RESET} component={PasswordResetPage} />

                        <Route path={ROUTE_VERIFY_EMAIL} component={VerifyEmailPage} />

                        <Route path={ROUTE_2FA_RESET_SUCCESS} component={TwoFactorAuthResetPage} />
                        <Route path={ROUTE_2FA_RESET} component={TwoFactorAuthResetPage} />
                        <Route path={ROUTE_LANDING} component={LandingPage} />
                    </Switch>
                </ConnectedRouter>
            </MuiThemeProvider>
        );
    }
}

App.propTypes = {
    history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        initialiseHubspotTracking: () => dispatch(actionHubspotTrackingInitialise()),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
