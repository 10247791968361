export const LIMITED_COMPANY = 'LIMITED_COMPANY';
export const PUBLIC_LIMITED_COMPANY = 'PUBLIC_LIMITED_COMPANY';
export const NON_LIMITED_PARTNERSHIP = 'NON_LIMITED_PARTNERSHIP';
export const OFFSHORE_LIMITED_COMPANY = 'OFFSHORE_LIMITED';
export const SOLE_TRADER = 'SOLE_TRADER';
export const LLP = 'LLP';
export const TRUSTS = 'TRUSTS';

class CommercialStructure {
    getTypes = () => {
        return [
            { value: LIMITED_COMPANY, label: 'Limited Company' },
            { value: PUBLIC_LIMITED_COMPANY, label: 'Public Limited Company' },
            { value: NON_LIMITED_PARTNERSHIP, label: 'Non-limited Partnership' },
            { value: OFFSHORE_LIMITED_COMPANY, label: 'Offshore Limited Company' },
            { value: SOLE_TRADER, label: 'Sole Trader' },
            { value: LLP, label: 'LLP' },
            { value: TRUSTS, label: 'Trusts' },
        ];
    };

    getOptions = () => {
        return {
            [LIMITED_COMPANY]: { value: LIMITED_COMPANY, label: 'Limited Company' },
            [PUBLIC_LIMITED_COMPANY]: { value: PUBLIC_LIMITED_COMPANY, label: 'Public Limited Company' },
            [NON_LIMITED_PARTNERSHIP]: { value: NON_LIMITED_PARTNERSHIP, label: 'Non-limited Partnership' },
            [OFFSHORE_LIMITED_COMPANY]: { value: OFFSHORE_LIMITED_COMPANY, label: 'Offshore Limited Company' },
            [SOLE_TRADER]: { value: SOLE_TRADER, label: 'Sole Trader' },
            [LLP]: { value: LLP, label: 'LLP' },
            [TRUSTS]: { value: TRUSTS, label: 'Trusts' },
        };
    };
}

export default CommercialStructure;
