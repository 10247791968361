import { NO, YES } from './YesNo';

export const REFINANCE = 'REFINANCE';
export const PURCHASE = 'PURCHASE';

class AssetRefinance {
    getTypes = () => {
        return {
            [REFINANCE]: { value: YES, label: 'Refinance' },
            [PURCHASE]: { value: NO, label: 'Purchase' },
        };
    };

    getOptions = () => {
        return [
            { value: YES, label: 'Refinance' },
            { value: NO, label: 'Purchase' },
        ];
    };

    getBusinessOptions = () => {
        return [
            { value: YES, label: 'Refinance of asset already owned' },
            { value: NO, label: 'Asset Purchase' },
        ];
    };

    getBusinessOptionTypes = () => {
        return {
            [YES]: { value: YES, label: 'Refinance of asset already owned' },
            [NO]: { value: NO, label: 'Asset Purchase' },
        };
    };
}

export default AssetRefinance;
