import React from 'react';
import moment from 'moment';
import { Grid, Paper } from '@material-ui/core';

export default function NewAccountCard(props) {
    const { user } = props;

    const getUserRole = (user) => {
        return user.roles[0].label;
    };

    return (
        <Grid item lg={4} md={4} sm={6} xs={12}>
            <Paper elevation={0} className={'lead-card'}>
                <div className={'lead'}>
                    {user && (
                        <>
                            <div className={'applicant'}>
                                <div className={'name'}>{user.userProfile.given + ' ' + user.userProfile.family}</div>
                                <div className={'email'}>{user.email}</div>
                            </div>
                            <div className={'applicant'}>
                                <div className={`user-type user-type-${getUserRole(user).toLowerCase()}`}>
                                    {getUserRole(user)}
                                </div>
                            </div>
                            <Grid container className={'footer'}>
                                <Grid item lg={6} md={6} sm={12} xs={12} className={'created'}>
                                    <div style={{ fontWeight: 600 }}>Date Created</div>
                                    <div className={'date'}>{moment(user.createdAt).format('MMM DD, YYYY')}</div>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </div>
            </Paper>
        </Grid>
    );
}
