import React from 'react';
import { connect } from 'react-redux';
import { actionLeftSideMenuItemSelected, selectorLeftMenuItems } from '../redux/leftsidemenu';
import { Container } from '../../../pitchblack_react_utils/layout';
import HorizontalMenuItem from './HorizontalMenuItem';
import { withRouter } from 'react-router';
import getConfig from '../../breadcrumbs/config';
import _ from 'lodash';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';

class HorizontalMenu extends React.Component {
    renderItems = (menuItems, breadcrumbConfig) => {
        let out = [];
        const sm = new SessionManager();
        const session = sm.getRelevantSession();

        menuItems.forEach((item, index) => {
            let { id, label, url, alertIndicator } = item;

            // Do not show 'Network Brokers' menu for Int not a network
            if (!session.isNetwork() && id === 'intermediary-network-brokers') {
                return;
            }

            out.push(
                <HorizontalMenuItem
                    key={index}
                    isActive={this.isActive(url, breadcrumbConfig)}
                    id={id}
                    label={label}
                    url={url}
                    alertIndicator={alertIndicator ?? false}
                />
            );
        });

        return out;
    };

    render() {
        const {
            menuItems,
            match: { path },
        } = this.props;

        const breadcrumbConfig = getConfig(path, window.location.pathname);

        if (!menuItems) {
            return <div className={`no_menu_items`} />;
        }

        let items = this.renderItems(menuItems, breadcrumbConfig);

        return <Container className={`horizontal-menu full-height`}>{items}</Container>;
    }

    isActive(url, breadcrumbConfig) {
        const trail = _.get(breadcrumbConfig, ['trail']);

        if (trail) {
            return url === trail[0].path;
        }

        return false;
    }
}

const mapStateToProps = (state) => {
    return {
        menuItems: selectorLeftMenuItems(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleMenuItemClick: (url) => {
        dispatch(actionLeftSideMenuItemSelected(url));
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HorizontalMenu));
