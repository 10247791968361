import React from 'react';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../notifications/sagas/container';

import { PrimaryButton } from '../../../../pitch4_layout/components/buttons';

class DeleteApplicantButton extends React.Component {
    render() {
        const { children = 'Delete', inputRef = () => null, disabled = false } = this.props;

        return (
            <PrimaryButton inputRef={inputRef} disabled={disabled} onClick={this.onClick}>
                {children}
            </PrimaryButton>
        );
    }

    onClick = () => {
        let { mutate, outerRefetch, id, notificationSuccess, notificationError } = this.props;

        return mutate({
            variables: {
                id: id,
            },
        })
            .then(() => {
                notificationSuccess('Applicant deleted');
                outerRefetch();
            })
            .catch((error) => {
                notificationError('An error occurred. Please contact support.');
            });
    };
}

const DeleteMutation = gql`
    mutation DeleteApplicant($id: ID!) {
        deleteApplicant(id: $id) {
            deleted
        }
    }
`;

DeleteApplicantButton.propTypes = {
    id: PropTypes.string.isRequired,
    mutate: PropTypes.func.isRequired,
    outerRefetch: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

const mapDispatchToProps = (dispatch) => {
    return {
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(null, mapDispatchToProps),
    graphql(DeleteMutation, {
        options: {
            context: {
                uri: BORROWER_URL,
            },
        },
    })
)(DeleteApplicantButton);
