import React from 'react';
import { compose } from 'recompose';
import withSession from '../../../../../../pitchblack_react_utils/session/withSession';
import { connect } from 'react-redux';
import { P4StyleComponentContainer } from '../../../../../../pitch4_layout/components/div';
import SecondaryButton from '../../../../../../pitch4_layout/components/buttons/SecondaryButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AdminNote from './AdminNote';
import AdminNoteDrawer from './AdminNoteDrawer';
import { addAdminNote, deleteAdminNote, editAdminNote, getAdminNotes } from '../../api/admin_notes';
import { sagaActionModalHide, sagaActionModalShow } from '../../../../../modal/sagas/container';
import AdminNotesErrorModal from './AdminNotesErrorModal';
import { CircularProgress } from '@material-ui/core';
import AdminNoteDeleteModal from './AdminNoteDeleteModal';
import { Loading } from '../../../../../../pitchblack_react_utils/form/renderIfSubmitting';

class AdminNotesContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminNotes: [],
            adminNote: null,
            loading: false,
            expanded: null,
            adminNoteDrawerOpen: false,
        };
    }

    componentDidMount() {
        const { loanEnquiryId, setModalComponent } = this.props;

        this.setState({ loading: true }, () => {
            const data = getAdminNotes(loanEnquiryId);
            data.then((resp) => {
                if (null !== resp) {
                    this.processData(resp.loanEnquiryAdminNotes);
                } else {
                    this.setState({ loading: false }, () =>
                        setModalComponent(
                            <AdminNotesErrorModal message={'Oops, something went wrong while reading Admin Notes.'} />
                        )
                    );
                }
            });
        });
    }

    processData(data) {
        const { handleAdminNotesAlert, loanEnquiryId } = this.props;
        const adminNotes = data.map((adminNote) => {
            return {
                id: adminNote.adminNoteId,
                createdAt: adminNote.createdAt,
                updatedAt: adminNote.updatedAt,
                submittedBy: adminNote.submittedBy.userProfile.given + ' ' + adminNote.submittedBy.userProfile.family,
                adminNoteTitle: adminNote.adminNoteTitle,
                adminNote: adminNote.adminNote,
            };
        });

        this.setState(
            {
                adminNotes: adminNotes,
                loading: false,
            },
            () => handleAdminNotesAlert(false, loanEnquiryId)
        );
    }

    handleNoteChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded ? panel : null });
    };

    handleDeleteNote = (note) => {
        const { setModalComponent, setModalClose } = this.props;
        setModalComponent(
            <AdminNoteDeleteModal deleteAdminNote={this.deleteAdminNote} setModalClose={setModalClose} note={note} />,
            false
        );
    };

    deleteAdminNote = (adminNote) => () => {
        const { setModalComponent, setModalClose } = this.props;

        setModalComponent(<Loading heading="Please wait. Deleting admin note." />, false);

        const data = deleteAdminNote(adminNote.id);

        data.then((resp) => {
            if (true === resp.deleteAdminNotes.success) {
                const { adminNotes } = this.state;
                this.setState({ expanded: null, adminNotes: adminNotes.filter((n) => n.id !== adminNote.id) }, () =>
                    setModalClose()
                );
            } else {
                setModalComponent(
                    <AdminNotesErrorModal
                        message={`Oops, something went wrong while deleting Admin Note - ${resp.deleteAdminNotes.reason}.`}
                    />
                );
            }
        });
    };

    toggleAdminNoteDrawer = (status = false) => (event) =>
        this.setState({
            adminNoteDrawerOpen: status,
            adminNote: null,
        });

    handleAddNote = (title, note, setAddingNote, clearForm) => {
        let { adminNotes } = this.state;
        const { loanEnquiryId, setModalComponent } = this.props;

        addAdminNote(title, note, loanEnquiryId).then((data, errors) => {
            if (errors) {
                setModalComponent(
                    <AdminNotesErrorModal message={'Oops, something went wrong while adding Admin Note.'} />
                );
            } else {
                adminNotes.unshift({
                    id: data.addAdminNote.adminNoteId,
                    createdAt: data.addAdminNote.createdAt,
                    submittedBy:
                        data.addAdminNote.submittedBy.userProfile.given +
                        ' ' +
                        data.addAdminNote.submittedBy.userProfile.family,
                    adminNoteTitle: data.addAdminNote.adminNoteTitle,
                    adminNote: data.addAdminNote.adminNote,
                });

                this.setState(
                    {
                        adminNotes: adminNotes,
                        expanded: 'note-0',
                    },
                    () => {
                        setAddingNote(false);
                        clearForm();
                        this.toggleAdminNoteDrawer()();
                    }
                );
            }
        });
    };

    handleEditNote = (note) => {
        this.setState({ adminNoteDrawerOpen: true, adminNote: note });
    };

    handleUpdateAdminNote = (id, title, note, setAddingNote, clearForm) => {
        const { loanEnquiryId, setModalComponent } = this.props;
        editAdminNote(id, title, note, loanEnquiryId).then((data, errors) => {
            if (errors) {
                setModalComponent(
                    <AdminNotesErrorModal message={'Oops, something went wrong while updating the Admin Note.'} />
                );
            } else {
                const data = getAdminNotes(loanEnquiryId);
                data.then((resp) => {
                    if (null !== resp) {
                        this.setState(
                            {
                                adminNote: null,
                                expanded: 'note-0',
                            },
                            () => {
                                setAddingNote(false);
                                clearForm();
                                this.toggleAdminNoteDrawer()();
                                this.processData(resp.loanEnquiryAdminNotes);
                            }
                        );
                    } else {
                        this.setState({ loading: false }, () =>
                            setModalComponent(
                                <AdminNotesErrorModal
                                    message={'Oops, something went wrong while reading Admin Notes.'}
                                />
                            )
                        );
                    }
                });
            }
        });
    };

    render() {
        const { adminNotes, expanded, adminNoteDrawerOpen, loading, adminNote } = this.state;
        return (
            <div className={'admin-note-container'}>
                {true === adminNoteDrawerOpen && (
                    <AdminNoteDrawer
                        toggleAdminNoteDrawer={this.toggleAdminNoteDrawer}
                        adminNoteDrawerOpen={adminNoteDrawerOpen}
                        handleAddNote={this.handleAddNote}
                        handleUpdateAdminNote={this.handleUpdateAdminNote}
                        adminNote={adminNote}
                    />
                )}

                <div className={'admin-notes'}>
                    <P4StyleComponentContainer
                        content={
                            <>
                                <div className={'top-bar'}>
                                    <SecondaryButton
                                        onClick={() => {
                                            this.toggleAdminNoteDrawer(true)();
                                        }}
                                        style={{ marginRight: 0, marginTop: 20, marginBottom: 10, color: '#ffffff' }}
                                    >
                                        Add a Note <AddCircleIcon style={{ marginLeft: 5 }} />
                                    </SecondaryButton>
                                </div>
                                {adminNotes.length > 0 ? (
                                    <div className={'notes'}>
                                        {adminNotes.map((adminNote, index) => (
                                            <AdminNote
                                                key={'an_' + index}
                                                expanded={expanded}
                                                index={index}
                                                adminNote={adminNote}
                                                handleNoteChange={this.handleNoteChange}
                                                handleDeleteNote={this.handleDeleteNote}
                                                handleEditNote={this.handleEditNote}
                                            />
                                        ))}
                                    </div>
                                ) : loading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <div>
                                            <CircularProgress />
                                        </div>
                                        <div>Loading admin notes...</div>
                                    </div>
                                ) : (
                                    <div>No notes</div>
                                )}
                            </>
                        }
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(AdminNotesContainer);
