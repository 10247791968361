import { BORROWER_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const verifyUserWithSMSCode = (uri = BORROWER_URL, userId, code) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation verifyUserWithSMSCode($id: ID!, $code: Int!) {
                verifyUserWithSMSCode(id: $id, code: $code) {
                success
            }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            id: userId,
            code: code,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
