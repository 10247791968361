import React from 'react';
import { Paper } from '@material-ui/core';

export default function BasicStatsCard(props) {
    const { icon, count, label, type, setListExpanded } = props;
    return (
        <Paper
            elevation={1}
            className={'card'}
            onClick={() => {
                setListExpanded(type);
                document.getElementById(type).scrollIntoView();
            }}
        >
            <div className={'icon-container'}>{icon}</div>
            <div>
                <div className={'count'}>{count}</div>
                <div className={'label'}>{label}</div>
            </div>
        </Paper>
    );
}
