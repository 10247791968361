import { createTheme } from '@material-ui/core/styles';

export const p4MuiTheme = createTheme({
    palette: {
        primary: {
            main: 'rgb(12, 10, 62)',
        },
    },
    overrides: {
        MuiFilledInput: {
            input: {
                padding: 2.5,
                height: 42,
                backgroundColor: `rgba(245, 245, 245, 1)`,
            },
        },
        MuiInput: {
            underline: {
                '&:before': {
                    //underline color when textfield is inactive
                    backgroundColor: 'transparent',
                },
                '&:hover:not($disabled):before': {
                    //underline color when hovered
                    backgroundColor: 'transparent',
                },
            },
        },
        MuiInputAdornment: {
            filled: false,
        },
    },
    typography: {
        useNextVariants: true,
        // In Japanese the characters are usually larger.
        fontSize: 12,
        fontFamily: 'degular-display',
        color: 'rgb(62,62,62)',
    },
});
