import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import BorrowerQuestions, {
    QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
    QUESTION_FUNDING_TYPE,
    QUESTION_LOAN_AMOUNT,
    QUESTION_LOAN_NEEDED_BY,
    QUESTION_LOAN_PURPOSE,
    QUESTION_LOAN_PURPOSE_MEZZANINE,
    QUESTION_LOAN_TERM,
    QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
    QUESTION_OCCUPY_PROPERTY,
    QUESTION_OUTSTANDING_LOAN_AMOUNT,
    QUESTION_PROPERTY_TYPE,
    QUESTION_RESIDENTIAL_REFINANCE,
    QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
} from '../../../enums/Borrower/BorrowerQuestions';
import YesNo, { YES } from '../../../enums/Shared/YesNo';
import Question from '../../Question/containers/Question';
import LoanNeededBy from '../../../enums/Shared/LoanNeededBy';
import {
    BRIDGE_TYPE,
    DEVELOPMENT_TYPE,
    REFURBISHMENT_TYPE,
    SECOND_CHARGE_TYPE,
    TERM_TYPE,
    MEZZANINE_TYPE,
} from '../../../enums/Borrower/BorrowerLoanType';
import { LAND_WITHOUT_PLANNING_FUNDING_TYPE } from '../../../enums/Shared/CommercialFundingType';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_INTERMEDIARY, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';
import LoanPurposes from '../../../enums/Shared/LoanPurposes';

class PropertySelectionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const yesNoOptions = new YesNo().getTypes();
        const loanNeededByOptions = new LoanNeededBy().getTypes();

        let refinanceLoanPurposes = [];
        switch (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName]) {
            case BRIDGE_TYPE:
                refinanceLoanPurposes = new LoanPurposes().getBridgingTypes();
                break;
            case TERM_TYPE:
                refinanceLoanPurposes = new LoanPurposes().getTermTypes();
                break;
            case REFURBISHMENT_TYPE:
                refinanceLoanPurposes = new LoanPurposes().getRefurbishmentTypes();
                break;
            case SECOND_CHARGE_TYPE:
                refinanceLoanPurposes = new LoanPurposes().getSecondChargeTypes();
                break;
            case DEVELOPMENT_TYPE:
                refinanceLoanPurposes = new LoanPurposes().getDevelopmentTypes();
                break;
            case MEZZANINE_TYPE:
                refinanceLoanPurposes = new LoanPurposes().getMezzanineTypes();
                break;
            default:
                refinanceLoanPurposes = new LoanPurposes().getTypes();
                break;
        }
        let property = 'property';
        let on_wording = 'in';
        if (
            (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === BRIDGE_TYPE &&
                this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] ===
                    LAND_WITHOUT_PLANNING_FUNDING_TYPE) ||
            (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === DEVELOPMENT_TYPE &&
                this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === LAND_WITHOUT_PLANNING_FUNDING_TYPE)
        ) {
            property = 'site';
            on_wording = 'on';
        }
        let occupyPropertyWording = 'Do you intend to live ' + on_wording + ' the ' + property + '?';
        if (userRole === ROLE_INTERMEDIARY) {
            occupyPropertyWording = 'Does your client intend to live ' + on_wording + ' the ' + property + '?';
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER
                            ? 'About your Property Finance'
                            : 'About your clients Property Finance'}
                    </Typography>
                </Grid>
                <Question
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    type={questions[QUESTION_OCCUPY_PROPERTY].type}
                    label={occupyPropertyWording}
                    questionEnum={'QUESTION_OCCUPY_PROPERTY'}
                    fieldName={questions[QUESTION_OCCUPY_PROPERTY].fieldName}
                    placeholder={questions[QUESTION_OCCUPY_PROPERTY].placeholder}
                    validation={questions[QUESTION_OCCUPY_PROPERTY].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    rows={0}
                />

                <Grid item xs={12} md={12}></Grid>
                {this.state.formData[questions[QUESTION_OCCUPY_PROPERTY].fieldName] === YES ? (
                    <>
                        <Question
                            type={questions[QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES].type}
                            label={questions[QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES].label}
                            questionEnum={'QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES'}
                            fieldName={questions[QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES].fieldName}
                            placeholder={questions[QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES].placeholder}
                            validation={questions[QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_OCCUPY_GREATER_THAN_40_PERCENT].type}
                            label={questions[QUESTION_OCCUPY_GREATER_THAN_40_PERCENT].label}
                            questionEnum={'QUESTION_OCCUPY_GREATER_THAN_40_PERCENT'}
                            fieldName={questions[QUESTION_OCCUPY_GREATER_THAN_40_PERCENT].fieldName}
                            placeholder={questions[QUESTION_OCCUPY_GREATER_THAN_40_PERCENT].placeholder}
                            validation={questions[QUESTION_OCCUPY_GREATER_THAN_40_PERCENT].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    </>
                ) : (
                    ''
                )}
                <Question
                    type={questions[QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY].type}
                    label={questions[QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY].label}
                    questionEnum={'QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY'}
                    fieldName={questions[QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY].fieldName}
                    placeholder={questions[QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY].placeholder}
                    validation={questions[QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Grid item xs={12} md={12}></Grid>
                {this.state.formData[questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName] === YES && (
                    <>
                        <Question
                            type={questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].type}
                            label={questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].label}
                            questionEnum={'QUESTION_OUTSTANDING_LOAN_AMOUNT'}
                            fieldName={questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].fieldName}
                            placeholder={questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].placeholder}
                            validation={questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_LOAN_PURPOSE].type}
                            label={questions[QUESTION_LOAN_PURPOSE_MEZZANINE].label}
                            questionEnum={'QUESTION_LOAN_PURPOSE'}
                            fieldName={questions[QUESTION_LOAN_PURPOSE].fieldName}
                            placeholder={questions[QUESTION_LOAN_PURPOSE].placeholder}
                            validation={questions[QUESTION_LOAN_PURPOSE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={refinanceLoanPurposes}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    </>
                )}
                {this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== REFURBISHMENT_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== DEVELOPMENT_TYPE && (
                        <Question
                            type={questions[QUESTION_LOAN_AMOUNT].type}
                            label={questions[QUESTION_LOAN_AMOUNT].label}
                            questionEnum={'QUESTION_LOAN_AMOUNT'}
                            fieldName={questions[QUESTION_LOAN_AMOUNT].fieldName}
                            placeholder={questions[QUESTION_LOAN_AMOUNT].placeholder}
                            validation={questions[QUESTION_LOAN_AMOUNT].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    )}
                <Question
                    type={questions[QUESTION_LOAN_TERM].type}
                    label={questions[QUESTION_LOAN_TERM].label}
                    questionEnum={'QUESTION_LOAN_TERM'}
                    fieldName={questions[QUESTION_LOAN_TERM].fieldName}
                    placeholder={questions[QUESTION_LOAN_TERM].placeholder}
                    validation={questions[QUESTION_LOAN_TERM].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_LOAN_NEEDED_BY].type}
                    label={questions[QUESTION_LOAN_NEEDED_BY].label}
                    questionEnum={'QUESTION_LOAN_NEEDED_BY'}
                    fieldName={questions[QUESTION_LOAN_NEEDED_BY].fieldName}
                    placeholder={questions[QUESTION_LOAN_NEEDED_BY].placeholder}
                    validation={questions[QUESTION_LOAN_NEEDED_BY].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={loanNeededByOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
            </Grid>
        );
    }
}

export default PropertySelectionContainer;
