import React from 'react';
import PropTypes from 'prop-types';
import { Item } from '../../../pitch4_layout/components/div';
import { TableRow } from '../table';

class KeyValueTableRow extends React.Component {
    render() {
        let { term = null, def, xs, sm, md, lg, elkey = '' } = this.props;
        xs = xs || [6, 6];
        sm = sm || xs;
        md = md || sm;
        lg = lg || md;

        if (term === null) {
            return (
                <TableRow>
                    <Item className={`key-value-term`} xs={12} elkey={'kvt_' + elkey}>
                        {def}
                    </Item>
                </TableRow>
            );
        }

        return (
            <TableRow elkey={'trkvtr_' + elkey}>
                <Item xs={xs[0]} sm={sm[0]} md={md[0]} lg={lg[0]} elkey={'ikvt_' + elkey}>
                    <div className={`key-value-term`}>{term}</div>
                </Item>
                <Item xs={xs[1]} sm={sm[1]} md={md[1]} lg={lg[1]} elkey={'ikvd_' + elkey}>
                    <div className={`key-value-definition`}>{def}</div>
                </Item>
            </TableRow>
        );
    }
}

KeyValueTableRow.propTypes = {
    term: PropTypes.any,
    def: PropTypes.any.isRequired,

    /**
     * The xs|sm|md|lg props control the width of term and definition.
     *
     * If given they must be an array of 2 integers which denote the column width of the term and definition respectively.
     *
     * E.g.
     *
     *   <KeyValueTableRow xs={[6,6]} md={[4,8]} ... />
     */
    xs: PropTypes.array,
    sm: PropTypes.array,
    md: PropTypes.array,
    lg: PropTypes.array,
    elkey: PropTypes.string,
};

export default KeyValueTableRow;
