import _ from 'lodash';

import { SECTION_SUBMIT } from '../constants';

export const pruneSections = (formStack, mapFactsForSection) => (values) => {
    if (!values) {
        return [];
    }

    // Clean up the submission data
    return (
        Object.keys(values)
            // Remove sections that we don't want in the submission
            .filter((key) => formStack.includes(key))

            // Remove any form sections that aren't in the path that the user ended up taking.
            .reduce((obj, key) => {
                if (_.isEmpty(values[key])) {
                    return {
                        ...obj,
                    };
                }

                if (key === SECTION_SUBMIT) {
                    return {
                        ...obj,
                        [key]: values[key],
                    };
                }

                return {
                    ...obj,
                    [key]: mapFactsForSection(values[key]),
                };
            }, {})
    );
};
