// see https://github.com/erikras/ducks-modular-redux
export const REDUCER_NAMESPACE = 'modals';

// Actions
export const MODAL_SHOW = `${REDUCER_NAMESPACE}.show`;
export const MODAL_HIDE = `${REDUCER_NAMESPACE}.hide`;
export const MODAL_SET_COMPONENT = `${REDUCER_NAMESPACE}.set_component`;
export const MODAL_SET_COMPONENT_INTERACTION = `${REDUCER_NAMESPACE}.set_component_interaction`;

//Reducer
const initialState = {
    visible: false,
    component: {},
    isInteractive: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_SHOW:
            return {
                ...state,
                visible: true,
            };

        case MODAL_HIDE:
            return {
                ...state,
                visible: false,
                isInteractive: true,
            };

        case MODAL_SET_COMPONENT:
            return {
                ...state,
                visible: true,
                component: action.component,
                isInteractive: action.isInteractive,
                className: action.className,
            };

        case MODAL_SET_COMPONENT_INTERACTION:
            return {
                ...state,
                isInteractive: action.isInteractive,
            };

        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;

//Action Creators
export const actionModalHide = () => {
    return {
        type: MODAL_HIDE,
    };
};

export const actionModalSetComponent = (component, isInteractive = true, className = null) => {
    return {
        type: MODAL_SET_COMPONENT,
        visible: true,
        component,
        isInteractive,
        className,
    };
};

export const actionModalSetComponentInteractive = (isInteractive) => {
    return {
        type: MODAL_SET_COMPONENT_INTERACTION,
        isInteractive,
    };
};

//Selectors
export const selectorVisible = (state) => {
    return state[REDUCER_NAMESPACE].visible;
};

export const selectorComponent = (state) => {
    return state[REDUCER_NAMESPACE].component;
};

export const selectorInteractive = (state) => {
    return state[REDUCER_NAMESPACE].isInteractive;
};

export const selectorClassName = (state) => {
    return state[REDUCER_NAMESPACE].className;
};
