import React from 'react';
import SecureRouteContainer from '../../apps/authentication/containers/SecureRouteContainer';
import DefaultAdminLayout from '../layouts/DefaultAdminLayout';
import NavigationContainer from '../../apps/navigation/containers/NavigationContainer';
import ContentContainer from '../../apps/admin/pitch4admin/routing/containers/ContentContainer';
import NotificationsContainer from '../../apps/notifications/containers/Container';
import ModalContainer from '../../apps/modal/containers/Container';
import '../../assets/sass/admin.scss';

class DefaultAdminPage extends React.Component {
    render() {
        return (
            <SecureRouteContainer>
                <DefaultAdminLayout
                    header={<NavigationContainer key={11} />}
                    content={<ContentContainer key={12} />}
                    notifications={<NotificationsContainer key={14} />}
                    modal={<ModalContainer key={15} />}
                    className={`role-admin`}
                />
            </SecureRouteContainer>
        );
    }
}

export default DefaultAdminPage;
