import { BaseEnum } from 'pitch4_enum';

import { OPERATION_BETWEEN, OPERATION_GTE, OPERATION_LTE } from './OperationEnum';

class TextOperationEnum extends BaseEnum {
    getTypes = () => {
        return {
            [OPERATION_BETWEEN]: 'Between',
            [OPERATION_LTE]: 'Less than or equal to',
            [OPERATION_GTE]: 'Greater than or equal to',
        };
    };
}

export default TextOperationEnum;
