import { put, takeLatest } from 'redux-saga/effects';

import { actionAddMessage } from '../redux/chatMessages';
import ChannelMessageFactory from '../messaging/factory/ChannelMessageFactory';

export const SAGA_NAMESPACE = 'chat_lle';

export const SAGA_ACTION_NEW_MESSAGE = `${SAGA_NAMESPACE}.SAGA_ACTION_NEW_MESSAGE`;
export const sagaHandleNewChannelMessage = (message) => ({
    type: SAGA_ACTION_NEW_MESSAGE,
    message: message,
});

function* handleNewChannelMessage(action) {
    const channelMessageFactory = new ChannelMessageFactory();
    let message = channelMessageFactory.createFromApiMessage(action.message);
    yield put(actionAddMessage(message));
}

function* main() {
    yield takeLatest(SAGA_ACTION_NEW_MESSAGE, handleNewChannelMessage);
}

export default main;
