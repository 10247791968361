import React from 'react';

import SecureRouteContainer from '../../apps/authentication/containers/SecureRouteContainer';
import DefaultAdminLayout from '../layouts/DefaultAdminLayout';
import NavigationContainer from '../../apps/navigation/containers/NavigationContainer';
import ContentContainer from '../../apps/admin/intermediary/routing/containers/ContentContainer';
import NotificationsContainer from '../../apps/notifications/containers/Container';
import ModalContainer from '../../apps/modal/containers/Container';
import '../../assets/sass/intermediary.scss';
import { sagaActionModalHide, sagaActionModalShow } from '../../apps/modal/sagas/container';
import { connect } from 'react-redux';

class DefaultIntermediaryPage extends React.Component {
    render() {
        return (
            <SecureRouteContainer>
                <DefaultAdminLayout
                    header={<NavigationContainer key={11} />}
                    content={<ContentContainer key={12} />}
                    notifications={<NotificationsContainer key={14} />}
                    modal={<ModalContainer key={15} />}
                    className={`role-intermediary`}
                />
            </SecureRouteContainer>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
    };
};

export default connect(null, mapDispatchToProps)(DefaultIntermediaryPage);
