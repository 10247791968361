import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { KeyValueTable, KeyValueTableRow } from '../../../../../pitch4_tabular/components/kvtable';
import MetaKeyEnum from '../../../enum/LoanEnquiries/MetaKeyEnum';
import { EnumLabel, FactValue } from '../../../../../pitch4_layout/components/data';
import { OPERATION_BETWEEN } from '../../../../../pitch4_enum/enum/OperationEnum';
import { SECTION_SUBMIT } from '../../../../form_wizard/constants';
import {
    BUILD_COST,
    DAY_ONE_LOAN_AMOUNT,
    DEVELOPMENT_COSTS,
    END_VALUE,
    RESIDENTIAL_UNITS_VALUE,
} from '../../enum/MatchingRules/MetaKeyEnum';

class BusinessStreamSummaryTable extends React.Component {
    render() {
        const { formSections, headings } = this.props;

        return (
            <div className={`business-stream-summary-table`}>
                {_.map(
                    Object.keys(formSections).filter((item) => [SECTION_SUBMIT].indexOf(item) === -1),
                    (section) => (
                        <div key={section} className={`summary-table-section`}>
                            <h5 className={`summary-table-section-heading`}>{headings[section]}</h5>
                            <KeyValueTable key={section} style={{ backgroundColor: '#fafafa', border: 'none' }}>
                                {_.map(Object.keys(formSections[section]), (fact) => {
                                    return (
                                        <KeyValueTableRow
                                            xs={[4, 8]}
                                            key={fact}
                                            term={<EnumLabel enumIn={new MetaKeyEnum()} value={fact} />}
                                            def={
                                                <React.Fragment>
                                                    {formSections[section][fact]['operation'] === OPERATION_BETWEEN ? (
                                                        <span>
                                                            <FactValue
                                                                key={fact + '-start'}
                                                                name={fact}
                                                                value={this.getOperandStart(
                                                                    formSections[section][fact]
                                                                )}
                                                                inPennies={
                                                                    !(
                                                                        fact === DEVELOPMENT_COSTS ||
                                                                        fact === BUILD_COST ||
                                                                        fact === DAY_ONE_LOAN_AMOUNT ||
                                                                        fact === RESIDENTIAL_UNITS_VALUE ||
                                                                        fact === END_VALUE
                                                                    )
                                                                }
                                                            />
                                                            &nbsp;and&nbsp;
                                                            <FactValue
                                                                key={fact + '-end'}
                                                                name={fact}
                                                                value={this.getOperandEnd(formSections[section][fact])}
                                                                inPennies={
                                                                    !(
                                                                        fact === DEVELOPMENT_COSTS ||
                                                                        fact === BUILD_COST ||
                                                                        fact === DAY_ONE_LOAN_AMOUNT ||
                                                                        fact === RESIDENTIAL_UNITS_VALUE ||
                                                                        fact === END_VALUE
                                                                    )
                                                                }
                                                            />
                                                        </span>
                                                    ) : (
                                                        <FactValue
                                                            name={fact}
                                                            value={formSections[section][fact]['operand']}
                                                            inPennies={
                                                                !(
                                                                    fact === DEVELOPMENT_COSTS ||
                                                                    fact === BUILD_COST ||
                                                                    fact === DAY_ONE_LOAN_AMOUNT ||
                                                                    fact === RESIDENTIAL_UNITS_VALUE ||
                                                                    fact === END_VALUE
                                                                )
                                                            }
                                                        />
                                                    )}
                                                </React.Fragment>
                                            }
                                        />
                                    );
                                })}
                            </KeyValueTable>
                        </div>
                    )
                )}
            </div>
        );
    }

    getOperandStart(field) {
        if (!_.isUndefined(field['operand'])) {
            return field['operand'][0];
        }

        return field['operand-start'];
    }

    getOperandEnd(field) {
        if (!_.isUndefined(field['operand'])) {
            return field['operand'][1];
        }

        return field['operand-end'];
    }
}

BusinessStreamSummaryTable.propTypes = {
    formSections: PropTypes.object.isRequired,
    headings: PropTypes.object.isRequired,
};

export default BusinessStreamSummaryTable;
