// see https://github.com/erikras/ducks-modular-redux
export const REDUCER_NAMESPACE = 'hubspot.conversations';

// Actions
export const HUBSPOT_CONVERSATIONS_OPEN = `${REDUCER_NAMESPACE}.open`;
export const actionHubspotConversationsOpen = () => {
    return {
        type: HUBSPOT_CONVERSATIONS_OPEN,
    };
};

export const HUBSPOT_CONVERSATIONS_OPENED = `${REDUCER_NAMESPACE}.opened`;
export const actionHubspotConversationsOpened = () => {
    return {
        type: HUBSPOT_CONVERSATIONS_OPENED,
    };
};

export const HUBSPOT_CONVERSATIONS_CLOSE = `${REDUCER_NAMESPACE}.close`;
export const actionHubspotConversationsClose = () => {
    return {
        type: HUBSPOT_CONVERSATIONS_CLOSE,
    };
};

export const HUBSPOT_CONVERSATIONS_CLOSED = `${REDUCER_NAMESPACE}.closed`;
export const actionHubspotConversationsClosed = () => {
    return {
        type: HUBSPOT_CONVERSATIONS_CLOSED,
    };
};

export const HUBSPOT_CONVERSATIONS_LOAD = `${REDUCER_NAMESPACE}.load`;
export const actionHubspotConversationsLoad = () => {
    return {
        type: HUBSPOT_CONVERSATIONS_LOAD,
    };
};

export const HUBSPOT_CONVERSATIONS_LOADED = `${REDUCER_NAMESPACE}.loaded`;
export const actionHubspotConversationsLoaded = () => {
    return {
        type: HUBSPOT_CONVERSATIONS_LOADED,
    };
};

export const HUBSPOT_CONVERSATIONS_REMOVE = `${REDUCER_NAMESPACE}.remove`;
export const actionHubspotConversationsRemove = () => {
    return {
        type: HUBSPOT_CONVERSATIONS_REMOVE,
    };
};

export const HUBSPOT_CONVERSATIONS_REMOVED = `${REDUCER_NAMESPACE}.removed`;
export const actionHubspotConversationsRemoved = () => {
    return {
        type: HUBSPOT_CONVERSATIONS_REMOVED,
    };
};

export const HUBSPOT_CONVERSATIONS_REFRESH = `${REDUCER_NAMESPACE}.refresh`;
export const actionHubspotConversationsRefresh = () => {
    return {
        type: HUBSPOT_CONVERSATIONS_REFRESH,
    };
};

export const HUBSPOT_CONVERSATIONS_REFRESHED = `${REDUCER_NAMESPACE}.refreshed`;
export const actionHubspotConversationsRefreshed = () => {
    return {
        type: HUBSPOT_CONVERSATIONS_REFRESHED,
    };
};

const initialState = {
    opening: false,
    opened: false,
    closing: false,
    closed: false,
    loading: false,
    loaded: false,
    removing: false,
    removed: false,
    refreshing: false,
    refreshed: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case HUBSPOT_CONVERSATIONS_OPEN:
            return {
                ...state,
                opening: true,
                opened: false,
            };

        case HUBSPOT_CONVERSATIONS_OPENED:
            return {
                ...state,
                opening: false,
                opened: true,
            };

        case HUBSPOT_CONVERSATIONS_CLOSE:
            return {
                ...state,
                closing: true,
                closed: false,
            };

        case HUBSPOT_CONVERSATIONS_CLOSED:
            return {
                ...state,
                closing: false,
                closed: true,
            };

        case HUBSPOT_CONVERSATIONS_LOAD:
            return {
                ...state,
                loading: true,
                loaded: false,
            };

        case HUBSPOT_CONVERSATIONS_LOADED:
            return {
                ...state,
                loading: false,
                loaded: true,
            };

        case HUBSPOT_CONVERSATIONS_REMOVE:
            return {
                ...state,
                removing: true,
                removed: false,
            };

        case HUBSPOT_CONVERSATIONS_REMOVED:
            return {
                ...state,
                removing: false,
                removed: true,
            };

        case HUBSPOT_CONVERSATIONS_REFRESH:
            return {
                ...state,
                refreshing: true,
                refreshed: false,
            };

        case HUBSPOT_CONVERSATIONS_REFRESHED:
            return {
                ...state,
                refreshing: false,
                refreshed: true,
            };

        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;
