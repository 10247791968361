import { API_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const removeLenderLoanEnquiryAlertMutation = `
  mutation removeLenderLoanEnquiryAlert($id: ID!){ 
    removeLenderLoanEnquiryAlert(id: $id){
      id
    }
  } 
`;

export const removeLenderLoanEnquiryAlert = (lenderLoanEnquiryId) => {
    return apolloFetch({
        query: removeLenderLoanEnquiryAlertMutation,
        variables: {
            id: lenderLoanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
