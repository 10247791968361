import React from 'react';
import { DateTimeFormatter } from '../data/formatter';
import PropTypes from 'prop-types';

class TableDateTimeLayout extends React.Component {
    render() {
        let { value } = this.props;
        return (
            <React.Fragment>
                <DateTimeFormatter value={value} format={'MMM D, YYYY \u00A0 h:mm A'} />
            </React.Fragment>
        );
    }
}

TableDateTimeLayout.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TableDateTimeLayout;
