import { delay, put, takeLatest } from 'redux-saga/effects';

import { actionNotifcationSetLevel, actionNotifcationSetVisibility } from '../redux/container';
import { HIDE_DELAY } from '../constants/constants';
import LevelFactory from '../levels/factory/LevelFactory';

const levelFactory = new LevelFactory();

export const SAGA_NAMESPACE = 'sagas.notifications';

//Saga Action Creators
export const SAGA_ACTION_SUCCESS = `${SAGA_NAMESPACE}.SAGA_ACTION_SUCCESS`;
export const sagaActionNotificationSetSuccess = (message) => ({
    type: SAGA_ACTION_SUCCESS,
    message,
});

export const SAGA_ACTION_INFO = `${SAGA_NAMESPACE}.SAGA_ACTION_INFO`;
export const sagaActionNotificationSetInfo = (message) => ({
    type: SAGA_ACTION_INFO,
    message,
});

export const SAGA_ACTION_WARNING = `${SAGA_NAMESPACE}.SAGA_ACTION_WARNING`;
export const sagaActionNotificationSetWarning = (message) => ({
    type: SAGA_ACTION_WARNING,
    message,
});

export const SAGA_ACTION_ERROR = `${SAGA_NAMESPACE}.SAGA_ACTION_ERROR`;
export const sagaActionNotificationSetError = (message) => ({
    type: SAGA_ACTION_ERROR,
    message,
});

//Worker Sagas
function* showNotification(action) {
    const level = levelFactory.createFromSagaType(action.message, action.type);
    yield put(actionNotifcationSetLevel(level));
    yield put(actionNotifcationSetVisibility(true));
    yield delay(HIDE_DELAY);
    yield put(actionNotifcationSetVisibility(false));
}

//Main Saga
function* main() {
    yield takeLatest(SAGA_ACTION_SUCCESS, showNotification);
    yield takeLatest(SAGA_ACTION_INFO, showNotification);
    yield takeLatest(SAGA_ACTION_WARNING, showNotification);
    yield takeLatest(SAGA_ACTION_ERROR, showNotification);
}

export default main;
