import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_BORROWER } from '../../../authentication/enum/Roles/NameEnum';
import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import { sagaActionModalHide, sagaActionModalInteractive, sagaActionModalShow } from '../../../modal/sagas/container';
import CreateContainer from './CreateContainer';
import EditContainer from './EditContainer';
import Mapper from '../../../../pitch4_elements/applicant/FormMapper';
import ModalLayout from '../../../../pitch4_modal/src/component/ModalLayout';
import _ from 'lodash';
import { Button } from '@material-ui/core';
import createP4ApolloFetch from '../../../../bootstrap/middlewares';
import { ROWS_PER_PAGE, PAGE_SIZE } from '../../../../pitch4_enum/enum/DataGridEnum';
import { actionSetJoyrideType } from '../../../joyride/redux';
import HeaderWithCount from '../../../../pitch4_elements/screen-headers/HeaderWithCount';
import Toolbar from '../components/Toolbar';
import List from '../../../../pitch4_elements/applicant/List';
import { DEFAULT_SEARCH_FILTER_DELAY } from '../../../../pitch4_utils/FiltersDefaults';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

class Container extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFetchError: null,
            searchFilter: '',
            dataGrid: {
                rows: [],
                totalRowCount: 0,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: [{ column: 'createdAt', direction: 'desc' }],
            },
            dataRequest: {
                filters: [],
                sorts: [
                    {
                        column: 'createdAt',
                        direction: 'desc',
                    },
                ],
                pages: [],
                cursor: null,
                storedDataFilters: [],
            },
        };
    }

    componentDidMount() {
        const { setJoyrideType } = this.props;
        const data = this.getData();
        this.processData(data);
        setJoyrideType(null);

        const url = window.location.href;
        const parts = url.split('/');

        if (parts[parts.length - 1] === 'create') {
            this.createApplicant()();
        }
    }

    refreshData = () => {
        this.setState(
            {
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages: [], cursor: null },
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    processData(data) {
        const { currentPage } = this.state.dataGrid;
        const { cursor } = this.state.dataRequest;

        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            const applicants = data.applicants.edges.map((user) => user.node);
            const rows = applicants.map((applicant, index) => {
                return {
                    id: applicant.id,
                    given: applicant.given,
                    family: applicant.family,
                    createdAt: applicant.createdAt,
                    dateOfBirth: applicant.dateOfBirth,
                    applicantLoanEnquiries: applicant.applicantLoanEnquiries,
                    email: applicant.email,
                    address: applicant.address,
                };
            });

            let pages = this.state.dataRequest.pages;
            pages[currentPage] = {
                cursor: cursor,
                hasNextPage: data.applicants.pageInfo.hasNextPage,
                endCursor: data.applicants.pageInfo.endCursor,
                startCursor: data.applicants.pageInfo.startCursor,
                hasPreviousPage: data.applicants.pageInfo.hasPreviousPage,
            };

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: rows,
                    totalRowCount: data.applicants.totalCount,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages, cursor: data.applicants.pageInfo.endCursor },
                },
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    getData() {
        const query = `
            query getApplicants($first: Int!, $sorts: [SortInput], $filters: [FilterInput], $cursor: String) {
                applicants(first: $first, sorts: $sorts, filters: $filters, after: $cursor) {
                    edges {
                        node {
                            id
                            given
                            family
                            dateOfBirth
                            applicantLoanEnquiries {
                                isMainApplicant
                            }
                            email
                            address {
                                address1
                                address2
                                address3
                                address4
                                postcode
                                country
                            }
                            createdAt
                            updatedAt
                        }
                        cursor
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                        startCursor
                        hasPreviousPage
                    }
                    totalCount
                }
            }
        `;

        return apolloFetch({
            query: query,
            variables: {
                cursor: this.state.dataRequest.cursor,
                filters: this.state.dataRequest.filters,
                first: this.state.dataGrid.pageSize,
                sorts: this.state.dataRequest.sorts,
                storedDataFilters: this.state.dataRequest.storedDataFilters,
            },
        });
    }

    handlePageChange = (pageConfig) => {
        const previousPage = this.state.dataGrid.currentPage;
        const newPage = pageConfig;
        const { pages } = this.state.dataRequest;

        if (newPage > previousPage) {
            // Next page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[previousPage].endCursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        } else {
            // Previous page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[newPage].cursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handlePageSizeChange = (pageConfig) => {
        this.setState(
            {
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: true,
                    pageSize: pageConfig,
                    rows: [],
                    currentPage: 0,
                    totalRowCount: 0,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    pages: [],
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    buildSearchRequestFilters = () => {
        const { searchFilter } = this.state;
        let searchRequestFilter = [];
        const searchableFields = ['family', 'given'];

        if (searchFilter.length > 0) {
            searchRequestFilter = searchableFields.map((field) => {
                return {
                    operation: 'ilike',
                    operand: '%' + searchFilter + '%',
                    column: field,
                    not: false,
                    logicOperator: 'or',
                };
            });
        }

        return searchRequestFilter;
    };

    handleSearchFilterChange = (event) => {
        this.setState(
            {
                searchFilter: event.target.value,
            },
            () => {
                this.setState({
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: [...this.buildSearchRequestFilters()],
                    },
                });
            }
        );

        _.delay(
            (value) => {
                if (this.state.searchFilter === value) {
                    const data = this.getData();
                    this.processData(data);
                }
            },
            DEFAULT_SEARCH_FILTER_DELAY,
            event.target.value
        );
    };

    handleClearAllFilters = () => {
        this.setState(
            {
                searchFilter: '',
                dataGrid: { ...this.state.dataGrid, rows: [] },
                dataRequest: { ...this.state.dataRequest, filters: [], cursor: null },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    handleSortModelChange = (params) => {
        const { sortModel } = this.state.dataGrid;
        const sortableFieldsMapping = {
            family: 'family',
            given: 'given',
            createdAt: 'createdAt',
            status: 'status',
        };

        if (params.sortModel !== null && typeof params.sortModel !== 'undefined' && params.sortModel !== sortModel) {
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        sortModel: params.sortModel,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        sorts: [
                            {
                                column: sortableFieldsMapping[params.sortModel[0].field],
                                direction: params.sortModel[0].sort,
                            },
                        ],
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    openEditApplicantModal = (outerRefetch, applicant) => {
        let { setModalClose, setModalComponent, setModalInteractive } = this.props;

        setModalComponent(
            <ModalLayout title={'Edit Applicant'}>
                <EditContainer
                    applicantId={applicant.id}
                    initialValues={this.mapValues(applicant)}
                    inProgress={() => setModalInteractive(false)}
                    onError={(error) => {
                        setModalInteractive(true);
                    }}
                    onSuccess={(data) => {
                        outerRefetch();
                        setModalClose();
                    }}
                />
            </ModalLayout>
        );
    };

    mapValues = (applicant) => {
        let mapper = new Mapper();
        return mapper.mapToForm(applicant);
    };

    createApplicant = () => () => {
        const { setModalInteractive, setModalComponent, setModalClose } = this.props;

        setModalComponent(
            <ModalLayout title="Create Applicant">
                <CreateContainer
                    inProgress={() => setModalInteractive(true)}
                    onSuccess={(data) => {
                        setModalInteractive(false);
                        this.refreshData();
                        setModalClose();
                    }}
                    onFailure={(error) => setModalInteractive(true)}
                />
            </ModalLayout>
        );
    };

    render() {
        const { sortingModel, totalRowCount, dataLoading } = this.state.dataGrid;
        const { searchFilter } = this.state;

        return (
            <div>
                <div className={'le-header-container'}>
                    <div className={'header-text'}>
                        <HeaderWithCount title={'Applicants'} count={totalRowCount} countText={'applicants'} />
                        <div className={'btn-mobile'}>
                            <Button
                                className={'btn-create'}
                                variant="contained"
                                color="primary"
                                size={'large'}
                                style={{
                                    marginLeft: 20,
                                    height: 45,
                                    paddingTop: 11,
                                    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                                }}
                                onClick={this.createApplicant()}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                    <Toolbar
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                        handleClearAllFilters={this.handleClearAllFilters}
                        createApplicant={this.createApplicant}
                    />
                </div>

                <div className={`le-table-container`}>
                    <List
                        sortingModel={sortingModel}
                        handlePageChange={this.handlePageChange}
                        handlePageSizeChange={this.handlePageSizeChange}
                        handleSortModelChange={this.handleSortModelChange}
                        totalRowCount={this.state.dataGrid.totalRowCount}
                        pageSize={this.state.dataGrid.pageSize}
                        rowsPerPage={this.state.dataGrid.rowsPerPage}
                        rows={this.state.dataGrid.rows}
                        dataLoading={this.state.dataGrid.dataLoading}
                        openEditApplicantModal={this.openEditApplicantModal}
                        refreshData={this.refreshData}
                        type={'borrower'}
                    />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => () => dispatch(push(url)),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(connect(null, mapDispatchToProps), renderErrorIfAuthorisationFail([ROLE_BORROWER]))(Container);
