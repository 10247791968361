import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Chip } from '@material-ui/core';
import {
    STATUS_MATCH_PENDING,
    STATUS_MATCH_REJECTED,
    STATUS_MATCHED,
    STATUS_IN_PROGRESS,
    STATUS_APPLICATION,
    STATUS_VALUATION,
    STATUS_LEGALS,
    STATUS_COMPLETION_REQUESTED,
    STATUS_COMPLETION_REJECTED,
    STATUS_COMPLETE,
    STATUS_DECLINED,
    STATUS_ENDED,
} from '../../enum/LenderLoanEnquiries/StatusEnum';

const statusStepsMap = {
    [STATUS_MATCH_PENDING]: { step: 1 },
    [STATUS_MATCH_REJECTED]: { step: 1 },
    [STATUS_MATCHED]: { step: 1 },
    [STATUS_IN_PROGRESS]: { step: 2 },
    [STATUS_APPLICATION]: { step: 3 },
    [STATUS_VALUATION]: { step: 4 },
    [STATUS_LEGALS]: { step: 5 },
    [STATUS_COMPLETION_REJECTED]: { step: 5 },
    [STATUS_COMPLETION_REQUESTED]: { step: 5 },
    [STATUS_COMPLETE]: { step: 6 },
    [STATUS_DECLINED]: { step: 6 },
};

class CardViewLLEStatusSteps extends React.Component {
    render() {
        const { status } = this.props;
        let i;
        let arr = [];

        for (i = 1; i <= 6; i++) {
            let statusClassName;

            if (status !== STATUS_ENDED) {
                if (statusStepsMap[status].step >= i) {
                    statusClassName = 'step-' + i;
                } else {
                    statusClassName = '';
                }
            }

            arr.push(<Chip className={'status-chip default ' + statusClassName} key={i} />);
        }

        return arr;
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CardViewLLEStatusSteps);
