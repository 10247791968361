import { BaseEnum } from 'pitch4_enum';

export const TYPE_RESIDENTIAL = 'residential';
export const TYPE_COMMERCIAL = 'commercial';
export const TYPE_SEMI_COMMERCIAL = 'semi_commercial';
export const TYPE_HMO = 'hmo';
export const TYPE_LAND = 'land';

class PropertyTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_RESIDENTIAL]: 'Residential Buy to Let',
            [TYPE_COMMERCIAL]: 'Commercial property',
            [TYPE_SEMI_COMMERCIAL]: 'Semi commercial property',
            [TYPE_HMO]: 'House in Multiple Occupation (HMO)',
            [TYPE_LAND]: 'Land',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default PropertyTypeEnum;
