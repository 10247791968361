import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

const SendMessageToLendersMutation = `
    mutation sendMultipleMessages($input: borrower_chatSendMultiMessageInput!) {
        sendMultiMessage (input: $input) {
            lenderLoanEnquiries
            message
        }
    }
`;

export const sendMessageToLenders = (lenders, message) => {
    return apolloFetch({
        query: SendMessageToLendersMutation,
        variables: {
            input: {
                lenderLoanEnquiries: lenders,
                message: message,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
