import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ScreenTitle } from '../../../../../pitch4_layout';
import { goBack, push } from 'react-router-redux';
import _ from 'lodash';
import { Container, Item, P4StyleComponentContainer } from '../../../../../pitch4_layout/components/div';
import { ROLE_SLAM } from '../../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';
import { DateFormatter, EnumLabel } from '../../../../../pitch4_layout/components/data';
import { DateTimeFormatter } from '../../../../../pitch4_layout/components/data/formatter';
import { KeyValueTable, KeyValueTableRow } from '../../../../../pitch4_tabular/components/kvtable/index';
import ChatContainer from '../../chat/containers/ChatContainer';
import DocumentList from '../components/DocumentList';
import { Loading } from '../../../../../pitch4_layout/components/waiting';
import FactSummaryTable from '../../../../view_loan_enquiry/components/FactSummaryTable';
import {
    actionApiSetStatus,
    actionClearStatus,
    actionGetMatchingCriteriaConfig,
    selectorGetMatchingCriteriaConfig,
    selectorGetStatus,
    actionRemoveLenderLoanEnquiryAlert,
} from '../redux';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import TitleEnum from '../../../enum/UserProfile/TitleEnum';
import { LOAN_AMOUNT } from '../../../enum/MatchingRules/MetaKeyEnum';
import { LOAN_TYPE } from '../../../enum/LoanEnquiries/MetaKeyEnum';
import FactValue from '../../../../../pitch4_layout/components/data/FactValue';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import Notice, { NOTICE_TYPE_ERROR } from '../../../../../pitch4_layout/components/content/Notice';
import StatusWidget from '../../../../view_lender_loan_enquiry/components/StatusWidget';
import LeadStatusEnum, {
    STATUS_APPLICATION,
    STATUS_COMPLETE,
    STATUS_COMPLETION_REQUESTED,
    STATUS_DECLINED,
    STATUS_IN_PROGRESS,
    STATUS_LEGALS,
    STATUS_MATCHED,
    STATUS_VALUATION,
} from '../../enum/LenderLoanEnquiries/StatusEnum';
import {
    STATUS_COMPLETE as LE_STATUS_COMPLETE,
    STATUS_COMPLETION_REQUESTED as LE_STATUS_COMPLETION_REQUESTED,
    STATUS_ON_HOLD,
    STATUS_WITHDRAWN as LE_STATUS_WITHDRAWN,
    STATUS_WITHDRAWN_EDITED,
} from '../../enum/LoanEnquiries/StatusEnum';
import SlamIconFactory from '../../../../view_lender_loan_enquiry/components/StatusWidget/SlamIconFactory';
import RoleNameEnum from '../../../../../pitch4_enum/enum/RoleNameEnum';
import { STATUS_ENDED } from '../../../../borrower/enum/LenderLoanEnquiries/StatusEnum';
import { SLAM_LENDER_LOAN_ENQUIRY } from '../../../../joyride/config';
import { actionSetJoyrideType } from '../../../../joyride/redux';
import { Subtitle1 } from '../../../../../pitch4_layout/components/text';
import PersonIcon from '@material-ui/icons/Person';
import { getAssignableUsers } from '../../../../../pitch4_api/get_assignable_users';
import AssignToModal from '../../../../../pitch4_elements/loan-enquiry-owner/AssignToModal';
import { setLEAssignee } from '../../../../../pitch4_api/set_le_assignee';
import { getLLE } from '../api/get_lle';
import { removeLEAssignee } from '../../../../../pitch4_api/remove_le_assignee';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import TermsContainer from '../components/terms/TermsContainer';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import { formatterInPounds } from '../../../../../pitchblack_react_utils/currency/Formatter';
import ErrorIcon from '@material-ui/icons/Error';
import CompleteFinalAmountModal from '../../../../../pitch4_elements/le-completed/CompletedFinalAmountModal';
import AnonDots from '../../../../../pitch4_elements/anon-dots/AnonDots';
import { calculateLoanType } from '../../../../MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';
import { NewLoanEnquirySummaryTable } from '../../../../MakeLoanEnquiry/containers/NewLoanEnquirySummaryTable';
import { clearLenderTermsUpdatedAlerts } from '../../../../../pitch4_api/lender_terms_alerts';
import { STATUS_CLOSED } from '../../../../../pitch4_enum/enum/LoanEnquiryStatusEnum';
import AdminStatusBannerNotice from '../../../../bannernotice/containers/AdminStatusBannerNotice';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

class MasonryStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
            lenderLoanEnquiry: null,
            chatAlertActive: false,
            lenderTermsUpdatedAlertActive: false,
            chatLabel: '',
            lleAssignedTo: [
                {
                    id: 'no_owner',
                    name: 'No owner',
                },
            ],
        };
    }

    componentDidMount() {
        const { getConfig, setJoyrideType, match } = this.props;

        this.setState({ chatLabel: this.buildCaseChatLabel() });

        getLLE(match.params.lenderLoanEnquiryId, [LOAN_AMOUNT, LOAN_TYPE]).then((resp) => {
            const { lenderLoanEnquiry } = resp;
            this.setState({
                lenderLoanEnquiry: lenderLoanEnquiry,
                lleAssignedTo: this.getAssignees(
                    typeof lenderLoanEnquiry.assignees == 'undefined' ? [] : lenderLoanEnquiry.assignees
                ),
                lenderTermsUpdatedAlertActive: lenderLoanEnquiry.lenderTermsUpdatedAlertActive === true,
            });
        });

        getConfig();
        setJoyrideType(SLAM_LENDER_LOAN_ENQUIRY);
    }

    componentWillUnmount() {
        const { clearStatus } = this.props;

        clearStatus();
    }

    getAssignees = (assignees) => {
        if (assignees.length === 0) {
            return [
                {
                    id: 'no_owner',
                    name: 'No owner',
                },
            ];
        }

        return [
            {
                id: assignees[0].id,
                name: assignees[0].userProfile.given + ' ' + assignees[0].userProfile.family,
            },
        ];
    };

    handleStatusChange = (status, leadId) => (newStatus) => {
        const { updateStatusOnChange, setModalComponent, setModalClose } = this.props;
        const { lenderLoanEnquiry } = this.state;

        if (!newStatus || newStatus === status) {
            return;
        }
        if (newStatus === STATUS_COMPLETION_REQUESTED) {
            setModalComponent(
                <CompleteFinalAmountModal
                    setModalClose={setModalClose}
                    lenderId={leadId}
                    amount={''}
                    url={SLAM_URL}
                    loanEnquiryId={lenderLoanEnquiry.loanEnquiry.id}
                />,
                false
            );
            return;
        }
        // Any other status just update it
        updateStatusOnChange(leadId, newStatus);
    };

    roleToClass = (role) => {
        if (typeof role !== 'string') {
            return '';
        }

        return role.toLowerCase().replace('_', '-');
    };

    handleAssignLETo = () => {
        const { setModalComponent, setModalClose } = this.props;
        const { lleAssignedTo, lenderLoanEnquiry } = this.state;
        getAssignableUsers(SLAM_URL).then((resp) => {
            setModalComponent(
                <AssignToModal
                    assignees={resp}
                    setModalClose={setModalClose}
                    userRole={ROLE_SLAM}
                    assignedTo={lleAssignedTo[0]}
                    handleUpdateLEAssignee={this.handleUpdateLEAssignee(lenderLoanEnquiry.id)}
                />,
                true
            );
        });
    };

    handleUpdateLEAssignee = (lleId) => (newAssignedTo) => {
        const { setModalClose } = this.props;
        let data;

        if (newAssignedTo.id === 'no_owner') {
            data = removeLEAssignee(SLAM_URL, lleId, null, [newAssignedTo.id]);
        } else {
            data = setLEAssignee(SLAM_URL, lleId, null, [newAssignedTo.id]);
        }

        data.then((resp) => {
            this.setState({ lleAssignedTo: [newAssignedTo] }, () => setModalClose());
        });
    };

    buildChat = (lenderLoanEnquiry, disableActions) => {
        const { chatAlertActive } = this.state;
        const { removeLenderLoanEnquiryAlert } = this.props;

        if (false === chatAlertActive && lenderLoanEnquiry.alertActive) {
            lenderLoanEnquiry.alertActive = false;
            this.setState({ chatAlertActive: true, chatLabel: this.buildCaseChatLabel() }, () =>
                removeLenderLoanEnquiryAlert(lenderLoanEnquiry.id)
            );
        }

        return <ChatContainer channelId={lenderLoanEnquiry.channel.id} disableForm={disableActions} />;
    };

    buildCaseChatLabel = () => {
        const { lenderLoanEnquiry } = this.state;

        if (lenderLoanEnquiry && lenderLoanEnquiry.alertActive) {
            return (
                <span>
                    Case Chat <Badge label={'update'} style={{ marginLeft: 5 }} />
                </span>
            );
        }

        return 'Case Chat';
    };

    buildTermsLabel = () => {
        const { lenderTermsUpdatedAlertActive } = this.state;

        if (lenderTermsUpdatedAlertActive) {
            return (
                <span>
                    Term Details & Fees <Badge label={'update'} style={{ marginLeft: 5 }} />
                </span>
            );
        }

        return 'Terms';
    };

    handleClearLenderTermsUpdatedAlerts = (leId = null, lleId = null) => {
        if (leId && lleId) {
            clearLenderTermsUpdatedAlerts(leId, lleId, SLAM_URL).then((resp) => {
                this.setState({
                    lenderTermsUpdatedAlertActive: false,
                });
            });
        }
    };

    render() {
        const { config = {}, currentStatus = null, session } = this.props;
        const { lleAssignedTo, lenderLoanEnquiry } = this.state;

        if (null === lenderLoanEnquiry) {
            return <Loading />;
        }

        const loanEnquirySubmissionData = JSON.parse(lenderLoanEnquiry.submissionData.rawSubmission);

        if (_.isEmpty(config) || _.isEmpty(lenderLoanEnquiry) || _.isEmpty(loanEnquirySubmissionData)) {
            return (
                <Loading
                    onTimeoutResponse={
                        <Notice message={`Sorry, we cannot display this lead`} type={NOTICE_TYPE_ERROR} />
                    }
                />
            );
        }

        const enquiryComplete =
            [LE_STATUS_COMPLETION_REQUESTED, LE_STATUS_COMPLETE].indexOf(lenderLoanEnquiry.loanEnquiry.status) !== -1;
        const leadComplete = [STATUS_COMPLETION_REQUESTED, STATUS_COMPLETE].indexOf(lenderLoanEnquiry.status) !== -1;
        const enquiryWithdrawn = lenderLoanEnquiry.loanEnquiry.status === LE_STATUS_WITHDRAWN;
        const enquiryWithdrawnEdited = lenderLoanEnquiry.loanEnquiry.status === STATUS_WITHDRAWN_EDITED;
        const enquiryOnHold = lenderLoanEnquiry.loanEnquiry.status === STATUS_ON_HOLD;
        const enquiryClosed = lenderLoanEnquiry.loanEnquiry.status === STATUS_CLOSED;
        const enquiryEnded = lenderLoanEnquiry.loanEnquiry.status === STATUS_ENDED;
        const disableActions =
            enquiryWithdrawn ||
            enquiryOnHold ||
            enquiryWithdrawnEdited ||
            enquiryClosed ||
            enquiryEnded ||
            (enquiryComplete && false === leadComplete);
        const isGloballyDisabled = lenderLoanEnquiry.status === STATUS_ENDED;
        const borrowerProfile = lenderLoanEnquiry.loanEnquiry.submittedBy.userProfile;
        const mainApplicant = this.getMainApplicant(lenderLoanEnquiry);
        const editableStates = {
            [STATUS_MATCHED]: [STATUS_IN_PROGRESS, STATUS_DECLINED],
            [STATUS_IN_PROGRESS]: [STATUS_APPLICATION, STATUS_DECLINED],
            [STATUS_APPLICATION]: [STATUS_VALUATION, STATUS_DECLINED],
            [STATUS_VALUATION]: [STATUS_LEGALS, STATUS_DECLINED],
            [STATUS_LEGALS]: [STATUS_COMPLETE, STATUS_DECLINED],
            [STATUS_DECLINED]: [STATUS_IN_PROGRESS],
        };

        const lleStatus = currentStatus || lenderLoanEnquiry.status;
        const leSubmittedByRole = _.get(lenderLoanEnquiry, ['loanEnquiry', 'submittedBy', 'roles', 0, 'name'], null);
        const hasLeSubmittedByRole = leSubmittedByRole !== null;
        const brokerageName = _.get(lenderLoanEnquiry, ['loanEnquiry', 'submittedBy', 'companyName'], null);
        const hasBrokerageName = brokerageName !== null;
        const leadStatusEnum = new LeadStatusEnum();
        const loanType = calculateLoanType(lenderLoanEnquiry.loanEnquiry.loanEnquiryData).value;

        const handleTabChange = (event, newValue) => {
            this.setState({ tabValue: newValue });

            // Clear alert badges
            if (newValue === 1) {
                this.handleClearLenderTermsUpdatedAlerts(lenderLoanEnquiry.loanEnquiry.id, lenderLoanEnquiry.id);
            }
        };

        return (
            <div className={`view-lead`}>
                {lleStatus === STATUS_LEGALS && (
                    <Container>
                        <div className="banner-notice banner-notice-error">
                            <Container spacing={1} alignItems={`center`}>
                                <Item xs={12} sm={12} className={`notice-message`}>
                                    <ErrorIcon />
                                    <strong>
                                        Has this customer received funding? -
                                        <span
                                            style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                            onClick={() =>
                                                this.handleStatusChange(
                                                    lleStatus,
                                                    lenderLoanEnquiry.id
                                                )(STATUS_COMPLETION_REQUESTED)
                                            }
                                        >
                                            Click here to mark as complete
                                        </span>
                                    </strong>
                                </Item>
                            </Container>
                        </div>
                    </Container>
                )}
                {true ===
                    (enquiryWithdrawn || enquiryWithdrawnEdited || enquiryClosed || enquiryOnHold || enquiryEnded) && (
                    <AdminStatusBannerNotice status={lenderLoanEnquiry.loanEnquiry.status} isLender={true} />
                )}
                <Container
                    className={`view-lead-header`}
                    justifyContent={`space-between`}
                    spacing={4}
                    alignItems={`center`}
                >
                    <Item>
                        <ScreenTitle
                            title={
                                true === leadStatusEnum.isAnonStatus(lenderLoanEnquiry.status) ? (
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={{ marginRight: 10 }}>Lead:</div>
                                        <div style={{ marginTop: 8 }}>
                                            <AnonDots count={7} />
                                        </div>
                                        <div style={{ fontSize: 20, marginTop: 4 }}>
                                            {' '}
                                            (engage to show applicant name)
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        Lead: {mainApplicant.applicant.given} {mainApplicant.applicant.family}
                                    </>
                                )
                            }
                        />
                    </Item>
                    <div style={{ display: 'flex' }}>
                        <Item>
                            {(lleStatus === STATUS_COMPLETE || lleStatus === STATUS_COMPLETION_REQUESTED) && (
                                <Grid item className={'completion-details'}>
                                    <div>
                                        <div>
                                            <strong className={'header'}>Completed:</strong>
                                            <span className={'value'}>
                                                {' '}
                                                {lenderLoanEnquiry && lenderLoanEnquiry.loanEnquiry.completedDate
                                                    ? `on ${moment(lenderLoanEnquiry.loanEnquiry.completedDate).format(
                                                          'Do MMM YYYY'
                                                      )}`
                                                    : '-'}
                                            </span>
                                        </div>
                                        <div>
                                            <strong className={'header'}>Final Amount:</strong>
                                            <span className={'value'}>
                                                {' '}
                                                {lenderLoanEnquiry && lenderLoanEnquiry.loanEnquiry.finalAmount
                                                    ? formatterInPounds(lenderLoanEnquiry.loanEnquiry.finalAmount)
                                                    : '-'}
                                            </span>
                                        </div>
                                    </div>
                                </Grid>
                            )}
                        </Item>
                        <Item>
                            <StatusWidget
                                onChange={this.handleStatusChange(lleStatus, lenderLoanEnquiry.id)}
                                transitions={editableStates}
                                statusEnum={leadStatusEnum}
                                initialValues={{
                                    status: lenderLoanEnquiry.status,
                                }}
                                matchStatus={lleStatus}
                                enquiryWithdrawn={enquiryWithdrawn}
                                iconFactory={SlamIconFactory}
                                loanType={loanType}
                            />
                        </Item>
                    </div>
                </Container>
                <Container className={`view-lead-content`} justifyContent={`space-between`} spacing={4}>
                    <Item xs={12} md={5}>
                        <Container justifyContent={`space-between`} spacing={4}>
                            <Item xs={12} className={`view-lead-summary`} id={'lead-summary'}>
                                <P4StyleComponentContainer
                                    styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                    header={`Lead Summary`}
                                    buttons={<div />}
                                    content={
                                        <KeyValueTable style={{ backgroundColor: '#fafafa', border: 'none' }}>
                                            <KeyValueTableRow
                                                term={`Loan Amount`}
                                                def={
                                                    <FactValue
                                                        name={LOAN_AMOUNT}
                                                        value={lenderLoanEnquiry.loanEnquiry.loanEnquiryData.loanAmount}
                                                    />
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={`Loan Type`}
                                                def={<FactValue name={LOAN_TYPE} value={loanType.label} />}
                                            />
                                            <KeyValueTableRow
                                                term={`Date & Time Created`}
                                                def={
                                                    <DateTimeFormatter
                                                        value={lenderLoanEnquiry.createdAt}
                                                        format={'MMMM D, YYYY HH:mm A'}
                                                    />
                                                }
                                            />
                                            <KeyValueTableRow
                                                term={`Date & Time Matched`}
                                                def={
                                                    <DateTimeFormatter
                                                        value={lenderLoanEnquiry.matchedAt}
                                                        format={'MMMM D, YYYY HH:mm A'}
                                                    />
                                                }
                                            />
                                            {true === hasLeSubmittedByRole && (
                                                <KeyValueTableRow
                                                    term={`Customer Type`}
                                                    def={
                                                        <span
                                                            className={`pill pill-${this.roleToClass(
                                                                leSubmittedByRole
                                                            )}`}
                                                        >
                                                            <EnumLabel
                                                                enumIn={new RoleNameEnum()}
                                                                value={leSubmittedByRole}
                                                            />
                                                        </span>
                                                    }
                                                />
                                            )}
                                            <KeyValueTableRow
                                                term={`Submitted by`}
                                                def={
                                                    <span className="text-highlight">
                                                        {true === hasBrokerageName ? ( // Broker
                                                            true ===
                                                                leadStatusEnum.isAnonStatus(lenderLoanEnquiry.status) ||
                                                            STATUS_IN_PROGRESS === lenderLoanEnquiry.status ? (
                                                                <AnonDots count={7} />
                                                            ) : (
                                                                <>
                                                                    {' '}
                                                                    {borrowerProfile.given} {borrowerProfile.family}
                                                                </>
                                                            )
                                                        ) : // Borrower
                                                        true ===
                                                          leadStatusEnum.isAnonStatus(lenderLoanEnquiry.status) ? (
                                                            <AnonDots count={7} />
                                                        ) : (
                                                            <>
                                                                <EnumLabel
                                                                    enumIn={new TitleEnum()}
                                                                    XX
                                                                    value={borrowerProfile.title}
                                                                />{' '}
                                                                {borrowerProfile.given} {borrowerProfile.family}
                                                            </>
                                                        )}
                                                    </span>
                                                }
                                            />
                                            {true === hasBrokerageName ? (
                                                true === leadStatusEnum.isAnonStatus(lenderLoanEnquiry.status) ||
                                                STATUS_IN_PROGRESS === lenderLoanEnquiry.status ? (
                                                    <KeyValueTableRow
                                                        term={`Brokerage Name`}
                                                        def={<AnonDots count={7} />}
                                                    />
                                                ) : (
                                                    <KeyValueTableRow
                                                        term={`Brokerage Name`}
                                                        def={<span className="text-highlight">{brokerageName}</span>}
                                                    />
                                                )
                                            ) : null}
                                            <KeyValueTableRow term={`Ref`} def={lenderLoanEnquiry.lenderReference} />
                                            <KeyValueTableRow
                                                term={<Subtitle1>Enquiry owner:</Subtitle1>}
                                                def={
                                                    <div
                                                        className={'assign-to'}
                                                        onClick={() => this.handleAssignLETo()}
                                                    >
                                                        <div className={'icon'}>
                                                            <PersonIcon />
                                                        </div>
                                                        <span
                                                            className={`name-${
                                                                lleAssignedTo[0].id !== 'no_owner' ? 'active' : null
                                                            }`}
                                                        >
                                                            {lleAssignedTo[0].name}
                                                        </span>
                                                    </div>
                                                }
                                            />
                                        </KeyValueTable>
                                    }
                                />
                            </Item>
                            <Item xs={12} className={`view-lead-applicants`}>
                                <P4StyleComponentContainer
                                    styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                    header={`Applicant Details`}
                                    buttons={<div />}
                                    content={
                                        <React.Fragment>
                                            {lenderLoanEnquiry.loanEnquiryApplicants.map(
                                                (loanEnquiryApplicant, index) => (
                                                    <KeyValueTable
                                                        key={`applicant${index}`}
                                                        style={{
                                                            backgroundColor: '#fafafa',
                                                            border: 'none',
                                                        }}
                                                    >
                                                        <KeyValueTableRow
                                                            term={
                                                                loanEnquiryApplicant.isMainApplicant
                                                                    ? 'Main Applicant Name'
                                                                    : 'Applicant Name'
                                                            }
                                                            def={
                                                                <span className="text-highlight-green">
                                                                    {true ===
                                                                    leadStatusEnum.isAnonStatus(
                                                                        lenderLoanEnquiry.status
                                                                    ) ? (
                                                                        <AnonDots count={7} />
                                                                    ) : (
                                                                        <>
                                                                            {loanEnquiryApplicant.applicant.given}{' '}
                                                                            {loanEnquiryApplicant.applicant.family}
                                                                        </>
                                                                    )}
                                                                </span>
                                                            }
                                                        />
                                                        <KeyValueTableRow
                                                            term={`Date of birth`}
                                                            def={
                                                                <DateFormatter
                                                                    value={loanEnquiryApplicant.applicant.dateOfBirth}
                                                                />
                                                            }
                                                        />
                                                    </KeyValueTable>
                                                )
                                            )}
                                        </React.Fragment>
                                    }
                                />
                            </Item>
                            {lenderLoanEnquiry.loanEnquiry.isNewForm === false && (
                                <Item xs={12} className={`view-lead-enquiry-info`}>
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={`Enquiry information`}
                                        buttons={<div />}
                                        content={
                                            <FactSummaryTable
                                                config={config}
                                                lenderLoanEnquiry={lenderLoanEnquiry}
                                                loanEnquiryStoredData={lenderLoanEnquiry.storedData}
                                                loanEnquirySubmissionData={loanEnquirySubmissionData}
                                            />
                                        }
                                    />
                                </Item>
                            )}
                            {lenderLoanEnquiry.loanEnquiry.isNewForm === true && (
                                <Item xs={12} className={`view-lead-enquiry-info`}>
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={`Enquiry information`}
                                        buttons={<div />}
                                        content={
                                            <NewLoanEnquirySummaryTable
                                                config={config}
                                                loanEnquiry={lenderLoanEnquiry.loanEnquiry}
                                                loanEnquiryData={lenderLoanEnquiry.loanEnquiry.loanEnquiryData}
                                                lenderLoanEnquiry={lenderLoanEnquiry}
                                            />
                                        }
                                    />
                                </Item>
                            )}
                        </Container>
                    </Item>
                    <Item xs={12} md={7}>
                        <AppBar className={'app-bar'} position="static">
                            <hr className={'tabs-indicator-top-line'} />
                            <Tabs
                                value={this.state.tabValue}
                                onChange={handleTabChange}
                                TabIndicatorProps={{
                                    children: (
                                        <div className={'tab-indicator-container'}>
                                            <div className={'tab-active-indicator'} />
                                        </div>
                                    ),
                                }}
                            >
                                <Tab
                                    label={this.state.chatLabel}
                                    {...a11yProps(0)}
                                    style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif' }}
                                />
                                <Tab
                                    label={this.buildTermsLabel()}
                                    {...a11yProps(1)}
                                    style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif' }}
                                />
                                <Tab
                                    label={'Case Documents'}
                                    {...a11yProps(2)}
                                    style={{ fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif' }}
                                />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={this.state.tabValue} index={0}>
                            <Item xs={12} className={`view-lead-lender-comms`} id={'lender-comms'}>
                                <P4StyleComponentContainer
                                    header={`Customer Communications`}
                                    buttons={<div />}
                                    content={this.buildChat(lenderLoanEnquiry, disableActions)}
                                />
                            </Item>
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={1}>
                            <TermsContainer lenderLoanEnquiry={lenderLoanEnquiry} disableActions={disableActions} />
                        </TabPanel>
                        <TabPanel value={this.state.tabValue} index={2}>
                            <Item xs={12} className={`view-lead-docs document-list`} id={'document-list'}>
                                <DocumentList
                                    lenderLoanEnquiry={lenderLoanEnquiry}
                                    disableUploadButton={
                                        disableActions || isGloballyDisabled || session.hasAssumedToken()
                                    }
                                    disabled={disableActions || isGloballyDisabled || session.hasAssumedToken()}
                                />
                            </Item>
                        </TabPanel>
                    </Item>
                </Container>
            </div>
        );
    }

    getMainApplicant(lenderLoanEnquiry) {
        for (let i in lenderLoanEnquiry.loanEnquiryApplicants) {
            if (lenderLoanEnquiry.loanEnquiryApplicants[i].isMainApplicant) {
                return lenderLoanEnquiry.loanEnquiryApplicants[i];
            }
        }
    }
}

MasonryStyleContainer.propTypes = {};

const mapStateToProps = (state) => {
    return {
        config: selectorGetMatchingCriteriaConfig(state),
        currentStatus: selectorGetStatus(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        getConfig: () => dispatch(actionGetMatchingCriteriaConfig()),
        updateStatusOnChange: (leadId, newStatus, metadata) => {
            dispatch(actionApiSetStatus(leadId, newStatus, metadata));
        },
        clearStatus: () => dispatch(actionClearStatus()),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        removeLenderLoanEnquiryAlert: (status) => {
            dispatch(actionRemoveLenderLoanEnquiryAlert(status));
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withSession,
    renderErrorIfAuthorisationFail([ROLE_SLAM])
)(MasonryStyleContainer);
