import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ROLE_BORROWER } from '../../../authentication/enum/Roles/NameEnum';
import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import { push } from 'react-router-redux';
import {
    path,
    ROUTE_BORROWER_CREATE_LOAN_ENQUIRY,
    ROUTE_BORROWER_EDIT_LOAN_ENQUIRY,
    ROUTE_BORROWER_LOAN_ENQUIRY_VIEW,
} from '../../../../pitch4_routing';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { PrimaryRedirectButton } from '../../../../pitch4_layout/components/buttons';
import { withRouter } from 'react-router-dom';
import RouteManagerFactory from '../../../../pitch4_routing/src/RouteManagerFactory';
import StatusEnum, {
    STATUS_APPLICATION,
    STATUS_COMPLETION_REJECTED,
    STATUS_COMPLETION_REQUESTED,
    STATUS_DRAFT,
    STATUS_IN_PROGRESS,
    STATUS_LEGALS,
    STATUS_MATCHED,
    STATUS_ON_HOLD,
    STATUS_VALUATION,
} from '../../enum/LoanEnquiries/StatusEnum';
import { LOAN_AMOUNT } from '../../../admin/enum/MatchingRules/MetaKeyEnum';
import { actionDelete } from '../../../borrower/delete_loan_enquiry/redux/index';
import { sagaActionModalHide, sagaActionModalShow } from '../../../modal/sagas/container';
import createP4ApolloFetch from '../../../../bootstrap/middlewares';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import moment from 'moment';
import { LOAN_TYPE_BUSINESS_FINANCE, LOAN_TYPE_PROPERTY } from '../../enum/LoanEnquiries/LoanTypeEnum';
import Status from '../components/Status';
import DeleteLoanEnquiryConfirm from '../../../delete_loan_enquiry/components/DeleteLoanEnquiryConfirm';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Badge } from '../../../../pitch4_layout/components/badge/Badge';
import _ from 'lodash';
import { PAGE_SIZE, ROWS_PER_PAGE } from '../../../../pitch4_enum/enum/DataGridEnum';
import { actionSetJoyrideType } from '../../../joyride/redux';
import StarIcon from '../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../assets/images/star-gray.svg';
import { actionUpdateMatchFavourite } from '../../view_loan_enquiry/redux';
import Toolbar from '../components/Toolbar';
import HeaderWithCount from '../../../../pitch4_elements/screen-headers/HeaderWithCount';
import { DEFAULT_SEARCH_FILTER_DELAY } from '../../../../pitch4_utils/FiltersDefaults';
import { calculateLoanType } from '../../../MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';
import { SECTION_LOAN_TYPE } from '../../mle/constants';
import { BUSINESS_TYPE } from '../../../MakeLoanEnquiry/enums/Borrower/BorrowerLoanType';
import { defaultRequestSort, defaultSortModel } from '../../../../pitch4_utils/SortsDefaults';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });
const routeManagerFactory = new RouteManagerFactory();
const routeManager = routeManagerFactory.create();
const statusEnum = new StatusEnum();

class TableStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataLoading: true,
            dataFetchError: null,
            previousSortModel: [],
            statusFilter: [],
            updatesFilter: '',
            statuses: [
                STATUS_APPLICATION,
                STATUS_DRAFT,
                STATUS_IN_PROGRESS,
                STATUS_LEGALS,
                STATUS_MATCHED,
                STATUS_VALUATION,
                STATUS_ON_HOLD,
            ],
            expiredStatuses: statusEnum.getExpiredTypes(),
            searchFilter: '',
            showExpired: false,
            dataGrid: {
                rows: [],
                totalRowCount: 0,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: defaultSortModel,
            },
            dataRequest: {
                expiredFilters: this.getExpiredFilters(),
                filters: [],
                sorts: defaultRequestSort,
                pages: [],
                cursor: null,
                storedDataFilters: [],
            },
        };
    }

    componentDidMount() {
        const { setJoyrideType } = this.props;
        const data = this.getData();
        this.processData(data);
        setJoyrideType(null);
    }

    refreshData() {
        const data = this.getData();
        this.processData(data);
    }

    processData(data) {
        const { currentPage } = this.state.dataGrid;
        const { cursor } = this.state.dataRequest;

        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            const loanEnquiries = data.loanEnquiries.edges.map((le) => le.node);
            const les = loanEnquiries.map((le, index) => {
                if (le.loanEnquiryData === null) {
                    le.loanEnquiryData = {
                        loanAmount: 0,
                    };
                }
                const loanEnquiryDataAmount = le.loanEnquiryData.loanAmount > 0 ? le.loanEnquiryData.loanAmount : 0;
                let loanType = calculateLoanType(le.loanEnquiryData);
                loanType = loanType != null ? loanType.label : 'Not Set';
                const submissionData = JSON.parse(le.submissionData['rawSubmission'] || '{}');
                let statusLoanType = LOAN_TYPE_PROPERTY;
                if (typeof submissionData === 'object' && SECTION_LOAN_TYPE in submissionData) {
                    statusLoanType = calculateLoanType(le.loanEnquiryData);
                    if (typeof statusLoanType === 'object' && 'value' in statusLoanType) {
                        statusLoanType = statusLoanType.value;
                    }
                } else {
                    statusLoanType =
                        le.loanEnquiryData.loanType === BUSINESS_TYPE ? LOAN_TYPE_BUSINESS_FINANCE : LOAN_TYPE_PROPERTY;
                }
                return {
                    id: le.id,
                    reference: le.borrowerReference,
                    createdAt: le.createdAt,
                    type: loanType,
                    statusLoanType: statusLoanType,
                    amount: le.storedData.find((item) => item.meta.key === LOAN_AMOUNT)
                        ? parseInt(le.storedData.find((item) => item.meta.key === LOAN_AMOUNT).value)
                        : loanEnquiryDataAmount,
                    status: le.status,
                    alertActive: le.alertActive,
                    favourite: le.favourite,
                    isNewForm: le.isNewForm,
                };
            });

            let pages = this.state.dataRequest.pages;
            pages[currentPage] = {
                cursor: cursor,
                hasNextPage: data.loanEnquiries.pageInfo.hasNextPage,
                endCursor: data.loanEnquiries.pageInfo.endCursor,
                startCursor: data.loanEnquiries.pageInfo.startCursor,
                hasPreviousPage: data.loanEnquiries.pageInfo.hasPreviousPage,
            };

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: les,
                    totalRowCount: data.loanEnquiries.totalCount,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages, cursor: data.loanEnquiries.pageInfo.endCursor },
                },
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    getData() {
        const query = `
            query getLoanEnquiries(
                $cursor: String
                $first: Int!
                $sorts: [SortInput]
                $filters: [FilterInput]
                $storedDataFilters: [String]
                $page: Int
            ) {
                loanEnquiries(
                    first: $first
                    after: $cursor
                    sorts: $sorts
                    filters: $filters
                    storedDataFilters: $storedDataFilters
                    page: $page
                ) {
                    edges {
                        node {
                            id
                            isNewForm
                            borrowerReference
                            alertActive
                            favourite
                            isNewForm
                            submissionData {
                                rawSubmission
                            }
                            loanEnquiryData {
                                loanToValue
                                loanToCost
                                loanToGrossDevelopmentValue
                                dayOneLoanAmountPercentage
                                additionalInformation
                                rentalIncomeResidential
                                hmoBedroom
                                hmoRoomsSelfContained
                                hmoHasLicense
                                hmoNeedsLicense
                                buyToLetExperience
                                rentalIncomeCommercial
                                rentalIncomePortfolio
                                rentalIncome
                                commercialUnitsRentedOut
                                commercialUnitOwnerOccupied
                                commercialUnitAmount
                                commercialType
                                commercialTypeOther
                                siteDescription
                                siteIntention
                                refinance
                                businessSeasonal
                                businessType
                                businessName
                                businessWebsite
                                businessTradingLength
                                businessSector
                                businessFacilityNeededBy
                                businessFacilityTerm
                                businessCurrentPaymentTerms
                                businessOutstandingInvoices
                                businessPdqIncome
                                propertyType
                                loanAmount
                                loanPurpose
                                turnOver
                                businessProfitable
                                businessOverdraftFacility
                                businessOutstandingDebt
                                businessAdverseCredit
                                businessAdverseCreditExplanation
                                businessDirectorsAdverseCredit
                                businessDirectorsAdverseCreditExplanation
                                shareholdersAreHomeOwners
                                propertyAvailableForAdditionalSecurity
                                planningFor
                                developmentLength
                                refurbishmentLength
                                outstandingLoan
                                dayOneLoanAmount
                                unitsPreSold
                                worksExplanation
                                totalDevelopmentCosts
                                buildCostsBorrowing
                                expectedGrossDevelopmentValue
                                previousDevelopmentExperience
                                previousRefurbishmentExperience
                                teamDevelopmentExperience
                                epcRating
                                loanType
                                fundingType
                                businessSecuredFinance
                                loanUsedInUkEu
                                planningInPlace
                                endValue
                                percentageResidential
                                percentageCommercial
                                lender
                                firstChargeAmount
                                currentValue
                                properties
                                portfolioValue
                                portfolioAlreadyOwned
                                loanTerm
                                loanNeededBy
                                preApproveMatches
                                loanAmountRefurbishment
                                percentageResidentialSquareFeet
                                percentageResidentialValue
                                percentageCommercialValue
                                residentialUnitCount
                                residentialUnitValue
                                businessOccupyEntireProperty
                                commercialUnitRentBreakdown
                                commercialTenantAmount
                                ownsOtherCommercialProperty
                                ownOtherProperties
                                occupyProperty
                                ownsOtherInvestmentProperties
                                familyHasOccupied
                                secondCharge
                                occupyingGreaterThan40Percent
                                location
                                locationOther
                                address
                                postcode
                                propertyOwnership
                                refurbishmentCost
                                invoiceFinanceType
                                leaseHirePurchase
                                mainApplicantCreditDifficulties
                                mainApplicantCreditDifficultiesExplanation
                                mainApplicantCitizenshipStatus
                                mainApplicantCountryOfResidence
                                mainApplicantCountryOfResidenceOther
                                mainApplicantNationality
                                mainApplicantNationalityOther
                                mainApplicantOccupation
                                mainApplicantGrossIncome
                                mainApplicantExitStrategy
                                agreeToTerms
                            }
                            storedData {
                                meta {
                                    key
                                }
                                value
                            }
                            status
                            createdAt
                            updatedAt
                            favourite
                        }
                        cursor
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                        startCursor
                        hasPreviousPage
                    }
                    totalCount
                }
            }
        `;

        return apolloFetch({
            query: query,
            variables: {
                cursor: this.state.dataRequest.cursor,
                filters: [...this.state.dataRequest.filters, ...this.state.dataRequest.expiredFilters],
                first: this.state.dataGrid.pageSize,
                sorts: this.state.dataRequest.sorts,
                storedDataFilters: this.state.dataRequest.storedDataFilters,
                page: this.state.dataGrid.currentPage,
            },
        });
    }

    getExpiredFilters = () =>
        statusEnum.getExpiredTypes().map((status) => ({
            column: 'status',
            operation: 'eq',
            not: true,
            operand: status,
        }));

    handlePageChange = (pageConfig) => {
        const previousPage = this.state.dataGrid.currentPage;
        const newPage = pageConfig;
        const { pages } = this.state.dataRequest;

        if (newPage > previousPage) {
            // Next page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[previousPage].endCursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        } else {
            // Previous page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[newPage].cursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handlePageSizeChange = (pageConfig) => {
        this.setState(
            {
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: true,
                    pageSize: pageConfig,
                    rows: [],
                    currentPage: 0,
                    totalRowCount: 0,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    pages: [],
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    buildSearchRequestFilters = () => {
        const { searchFilter } = this.state;
        let searchRequestFilter = [];
        const searchableFields = ['borrowerRef'];

        if (searchFilter.length > 0) {
            searchRequestFilter = searchableFields.map((field) => {
                return {
                    operation: 'ilike',
                    operand: '%' + searchFilter + '%',
                    column: field,
                    not: false,
                    // logicOperator: 'or', // Add only when there is more than one field in searchableFields
                };
            });
        }

        return searchRequestFilter;
    };

    buildStatusRequestFilters = () => {
        const { statusFilter } = this.state;

        return statusFilter.map((status) => {
            return {
                column: 'status',
                operation: 'eq',
                not: false,
                operand: status,
            };
        });
    };

    handleSearchFilterChange = (event) => {
        this.setState(
            {
                searchFilter: event.target.value,
            },
            () => {
                this.setState({
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: [
                            ...this.buildSearchRequestFilters(),
                            ...this.buildStatusRequestFilters(),
                            ...this.buildUpdatesRequestFilters(),
                        ],
                    },
                });
            }
        );

        _.delay(
            (value) => {
                if (this.state.searchFilter === value) {
                    const data = this.getData();
                    this.processData(data);
                }
            },
            DEFAULT_SEARCH_FILTER_DELAY,
            event.target.value
        );
    };

    handleClearAllFilters = () => {
        window.location.reload();
    };

    handleStatusFilterChange = (event) => {
        const selectedStatuses = event.target.value;

        this.setState(
            {
                statusFilter: selectedStatuses,
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            expiredFilters: this.getExpiredFilters(),
                            filters: [
                                ...this.buildSearchRequestFilters(),
                                ...this.buildStatusRequestFilters(),
                                ...this.buildUpdatesRequestFilters(),
                            ],
                        },
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    handleSortModelChange = (params) => {
        const { sortModel } = this.state.dataGrid;
        const sortableFieldsMapping = {
            favourite: 'favourite',
            name: 'mainApplicant',
            createdAt: 'createdAt',
            status: 'status',
        };
        if (
            params === null ||
            typeof params === 'undefined' ||
            JSON.stringify(params) === JSON.stringify(defaultSortModel)
        ) {
            return;
        }
        if (JSON.stringify(params) === JSON.stringify(this.state.previousSortModel)) {
            return;
        }
        let sorts = params.map((sort) => ({
            column: sortableFieldsMapping[sort.field],
            direction: sort.sort,
        }));

        if (JSON.stringify(params) !== JSON.stringify(sortModel)) {
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        sortModel: params,
                    },
                    previousSortModel: sortModel,
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        sorts: sorts,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handleShowExpired = (showExpired) => {
        let expiredFilters = [];

        if (!showExpired) {
            expiredFilters = this.getExpiredFilters();
        }

        this.setState(
            {
                statusFilter: [],
                showExpired: showExpired,
                dataGrid: { ...this.state.dataGrid, rows: [], totalRowCount: 0 },
                dataRequest: { ...this.state.dataRequest, expiredFilters: expiredFilters, filters: [], cursor: null },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    handleDeleteConfirm = (loanEnquiryId) => () => {
        const { deleteOnConfirm, setModalComponent, setModalClose } = this.props;
        const handleDelete = () => {
            deleteOnConfirm(loanEnquiryId);
            setModalClose();

            // Delay data refresh to let system delete the LE
            _.delay(() => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                        },
                    },
                    () => {
                        this.refreshData();
                    }
                );
            }, 1000);
        };
        setModalComponent(<DeleteLoanEnquiryConfirm handleDelete={handleDelete} />, true);
    };

    handleFavouriteChange = (leId, isFavourite) => {
        const { handleFavouriteMatchChange } = this.props;
        const { rows } = this.state.dataGrid;

        // eslint-disable-next-line array-callback-return
        rows.map((row) => {
            if (row.id === leId) {
                row.favourite = isFavourite;
            }
        });

        this.setState({ dataGrid: { ...this.state.dataGrid, rows: rows, totalRowCount: rows.length } }, () =>
            handleFavouriteMatchChange(null, leId, isFavourite)
        );
    };

    buildUpdatesRequestFilters = () => {
        const { updatesFilter } = this.state;
        let filters = [];

        if (updatesFilter.length > 0) {
            filters.push({
                operation: 'eq',
                operand: 'updatesOnly' === updatesFilter ? 'true' : 'false',
                column: 'leAlertActive',
                not: false,
            });
        }

        return filters;
    };

    handleUpdatesFilterChange = (event) => {
        const updatesFilter = event.target.value;

        this.setState(
            {
                updatesFilter: updatesFilter,
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            expiredFilters: this.getExpiredFilters(),
                            filters: [
                                ...this.buildSearchRequestFilters(),
                                ...this.buildStatusRequestFilters(),
                                ...this.buildUpdatesRequestFilters(),
                            ],
                        },
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    render() {
        const { dispatch } = this.props;
        let { statuses, statusFilter, showExpired, updatesFilter, searchFilter } = this.state;
        const { sortModel, rows, pageSize, rowsPerPage, dataLoading, totalRowCount } = this.state.dataGrid;

        return (
            <div>
                <div className={'le-header-container'}>
                    <div className={'header-text'}>
                        <HeaderWithCount title={'Enquiries'} count={totalRowCount} countText={'loan enquiries'} />
                        <div className={'btn-mobile'}>
                            <PrimaryRedirectButton
                                style={{ margin: 0 }}
                                url={routeManager.route(ROUTE_BORROWER_CREATE_LOAN_ENQUIRY)}
                            >
                                Create
                            </PrimaryRedirectButton>
                        </div>
                    </div>
                    <Toolbar
                        updatesFilter={updatesFilter}
                        statusFilter={statusFilter}
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        showExpired={showExpired}
                        statuses={statuses}
                        handleUpdatesFilterChange={this.handleUpdatesFilterChange}
                        handleShowExpired={this.handleShowExpired}
                        handleClearAllFilters={this.handleClearAllFilters}
                        handleStatusFilterChange={this.handleStatusFilterChange}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                    />
                </div>
                <div className={`le-table-container`}>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onCellClick={(params) => {
                            switch (params.field) {
                                case 'favourite':
                                    this.handleFavouriteChange(params.row.id, !params.row.favourite);
                                    break;
                                case 'actions':
                                    break;
                                default:
                                    if (params.row.status === STATUS_DRAFT) {
                                        dispatch(
                                            push(
                                                path(ROUTE_BORROWER_EDIT_LOAN_ENQUIRY, {
                                                    loanEnquiryId: params.row.id,
                                                })
                                            )
                                        );
                                    } else {
                                        dispatch(
                                            push(
                                                path(ROUTE_BORROWER_LOAN_ENQUIRY_VIEW, {
                                                    loanEnquiryId: params.row.id,
                                                })
                                            )
                                        );
                                    }
                                    break;
                            }
                        }}
                        paginationMode="server"
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        sortingMode="server"
                        onSortModelChange={this.handleSortModelChange}
                        rowCount={totalRowCount}
                        pageSize={pageSize}
                        rowsPerPageOptions={rowsPerPage}
                        rows={rows}
                        loading={true}
                        disableColumnFilter={true}
                        disableMultipleColumnsSorting={true}
                        disableColumnReorder={true}
                        disableMultipleColumnsFiltering={true}
                        disableSelectionOnClick={true}
                        disableColumnMenu={true}
                        components={{
                            LoadingOverlay: () => {
                                return (
                                    <GridOverlay>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {dataLoading ? (
                                                <LinearProgress />
                                            ) : rows.length === 0 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        width: '100%',
                                                        paddingTop: 100,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    No data
                                                </div>
                                            ) : null}
                                        </div>
                                    </GridOverlay>
                                );
                            },
                        }}
                        columns={[
                            {
                                field: 'favourite',
                                headerName: ' ',
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                width: 50,
                                renderCell: (params) => {
                                    return (
                                        <img
                                            src={params.row.favourite ? StarIcon : StarGrayIcon}
                                            alt="Favourite"
                                            className={'favourite'}
                                        />
                                    );
                                },
                                disableColumnMenu: true,
                            },
                            {
                                field: 'alert',
                                cellClassName: 'le-table-cell le-table-cell-content-center badge-container',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                width: 100,
                                renderCell: (params) => {
                                    if (params.row.alertActive) {
                                        return <Badge style={{ padding: '5px 10px 5px 10px' }} label={'update'} />;
                                    } else {
                                        return <></>;
                                    }
                                },
                                renderHeader: () => <div />,
                            },
                            {
                                field: 'amount',
                                headerName: 'LOAN AMOUNT',
                                width: 200,
                                valueFormatter: ({ value, row }) => {
                                    const formatter = new Intl.NumberFormat('en-UK', {
                                        style: 'currency',
                                        currency: 'GBP',
                                        minimumFractionDigits: 0,
                                    });
                                    if (true === row.isNewForm) {
                                        return formatter.format(value);
                                    } else {
                                        return formatter.format(value / 100); // Amount in pennies
                                    }
                                },
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'type',
                                headerName: 'LOAN TYPE',
                                width: 360,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'status',
                                headerName: 'STATUS',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-status',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => (
                                    <>
                                        {params.value === STATUS_DRAFT ? (
                                            <div className={'label-draft'}>
                                                {statusEnum.getType(params.value, params.row.statusLoanType)}
                                            </div>
                                        ) : params.value === STATUS_COMPLETION_REQUESTED ||
                                          params.value === STATUS_COMPLETION_REJECTED ? (
                                            <div>
                                                {statusEnum.getType(params.value, params.row.statusLoanType)}

                                                <Status status={params.value} />
                                            </div>
                                        ) : (
                                            <>
                                                <div>{statusEnum.getType(params.value, params.row.statusLoanType)}</div>
                                                <div>
                                                    <Status status={params.value} />
                                                </div>
                                            </>
                                        )}
                                    </>
                                ),
                            },
                            {
                                field: 'createdAt',
                                headerName: 'CREATED',
                                width: 170,
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'reference',
                                headerName: 'REFERENCE',
                                width: 130,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        deleteOnConfirm: (loanEnquiryId) => {
            dispatch(actionDelete(loanEnquiryId));
        },
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
        handleFavouriteMatchChange: (lenderLoanEnquiryId, loanEnquiryId, isFavourite) =>
            dispatch(actionUpdateMatchFavourite(lenderLoanEnquiryId, loanEnquiryId, isFavourite)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_BORROWER])
)(TableStyleContainer);
