import moment from 'moment';
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Format a date with Moment JS.
 *
 * @param date
 * @param format
 * @returns {string}
 */
export const formatDate = (date, format) => {
    return moment(date).format(format);
};

/**
 * Renders formatted date.
 *
 * @see DateTime
 */
class DateTimeFormatter extends React.Component {
    render() {
        let { value = null, format = 'HH:mm MMM Do YYYY' } = this.props;
        return value !== null ? formatDate(value, format) : '--';
    }
}

DateTimeFormatter.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    format: PropTypes.string,
};

export default DateTimeFormatter;
