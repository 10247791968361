export const ENGLAND = 'ENGLAND';
export const SCOTLAND = 'SCOTLAND';
export const NORTHERN_IRELAND = 'NORTHERN_IRELAND';
export const WALES = 'WALES';
export const OTHER = 'OTHER';

class PropertyLocation {
    getTypes = () => {
        return [
            { value: ENGLAND, label: 'England' },
            { value: SCOTLAND, label: 'Scotland' },
            { value: NORTHERN_IRELAND, label: 'Northern Ireland' },
            { value: WALES, label: 'Wales' },
            { value: OTHER, label: 'Other' },
        ];
    };

    getOptions = () => {
        return {
            [ENGLAND]: { value: ENGLAND, label: 'England' },
            [SCOTLAND]: { value: SCOTLAND, label: 'Scotland' },
            [NORTHERN_IRELAND]: { value: NORTHERN_IRELAND, label: 'Northern Ireland' },
            [WALES]: { value: WALES, label: 'Wales' },
            [OTHER]: { value: OTHER, label: 'Other' },
        };
    };
}

export default PropertyLocation;
