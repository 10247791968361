import React from 'react';
import { Container, RowItem, Item } from '../../../pitchblack_react_utils/layout';
import ScreenTitle from '../../../pitch4_layout/components/ScreenTitle';
import PropTypes from 'prop-types';
import { Accordion, AccordionDetails, AccordionSummary, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TuneIcon from '@material-ui/icons/Tune';

class TabularContentLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toolbarMobile: props.toolbarMobile,
        };
    }
    render() {
        let {
            title,
            content,
            commands = () => <Item className={`no-commands`} />,
            filters = <div />,
            sorts = <div />,
            contextMenu = <div />,
        } = this.props;

        return (
            <Container className={`tabular-content-layout`}>
                <RowItem>
                    <Container className={'table-header system-events-header-container'}>
                        <Container justify="flex-start">
                            <Item xs={12}>
                                <Container>
                                    <RowItem>
                                        <ScreenTitle className={`table-heading`} title={title} />
                                    </RowItem>
                                </Container>
                            </Item>
                        </Container>
                        <Container justify="flex-end" className={'header-toolbar'}>
                            <Item xs={12}>{filters}</Item>
                            <Item xs={12}>{sorts}</Item>
                            <Item xs={12}>{commands()}</Item>
                            <Item xs={12}>{contextMenu}</Item>
                        </Container>
                        {this.state.toolbarMobile && (
                            <div>
                                <div className={'header-toolbar-mobile'}>
                                    <Divider className={'divider'} />
                                    <Accordion className={'container'}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            style={{ padding: '0 5px' }}
                                        >
                                            <TuneIcon style={{ marginRight: 10 }} /> Filters
                                        </AccordionSummary>
                                        <AccordionDetails className={'details'}>
                                            {this.state.toolbarMobile}
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            </div>
                        )}
                    </Container>
                </RowItem>
                <RowItem className={`table-content`}>{content}</RowItem>
            </Container>
        );
    }
}

TabularContentLayout.propTypes = {
    title: PropTypes.any.isRequired,
    description: PropTypes.any,
    filters: PropTypes.object,
    sorts: PropTypes.object,
    commands: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
    contextMenu: PropTypes.object,
};

export default TabularContentLayout;
