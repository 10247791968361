import { call, put, takeLatest } from 'redux-saga/effects';
import { actionSetLenders, actionSetBrokers, GET_LENDERS, GET_BROKERS } from '../redux/form';
import { getLenders } from '../../../administer_lenders/api/get_lenders';
import { getBrokersLegacy } from '../../../administer_brokers/api/get_brokers_legacy';

function* apiGetLenders() {
    const response = yield call(getLenders);
    yield put(actionSetLenders(response));
}

function* apiGetBrokers() {
    const response = yield call(getBrokersLegacy);
    yield put(actionSetBrokers(response));
}

function* main() {
    yield takeLatest(GET_LENDERS, apiGetLenders);
    yield takeLatest(GET_BROKERS, apiGetBrokers);
}

export default main;
