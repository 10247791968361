import React from 'react';
import { KeyValueTable, KeyValueTableRow } from '../../../pitch4_tabular/components/kvtable';
import { Subtitle1 } from '../../../pitch4_layout/components/text';
import PropTypes from 'prop-types';
import moment from 'moment';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { path, ROUTE_BORROWER_LOAN_ENQUIRY_VIEW, ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW } from '../../../pitch4_routing';
import { ROLE_BORROWER, ROLE_INTERMEDIARY } from '../../../pitch4_enum/enum/RoleNameEnum';

class RevisionHistoryContainer extends React.Component {
    renderRevisionRows = (revisions) => {
        return revisions.map((revision, index) => {
            return (
                <div key={'1_' + index}>
                    <div key={'2_' + index}>
                        <span key={'3_' + index} className="text-highlight">
                            {revision.revisionReason}
                        </span>{' '}
                        {moment(revision.revisionTime).format('MMM, D YYYY LT')}
                    </div>
                </div>
            );
        });
    };

    renderVersionTitle = (loanEnquiryVersion, index) => {
        const { loanEnquiry, userRole, elkey = '' } = this.props;
        const route =
            userRole === ROLE_BORROWER
                ? ROUTE_BORROWER_LOAN_ENQUIRY_VIEW
                : userRole === ROLE_INTERMEDIARY
                ? ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW
                : ''; // '' will redirect to the dashboard page

        if (loanEnquiry.id !== loanEnquiryVersion.loanEnquiryId) {
            return (
                <a
                    key={`a_${elkey}_${index}`}
                    className={'loan-enquiry-version-title'}
                    href={path(route, {
                        loanEnquiryId: loanEnquiryVersion.loanEnquiryId,
                    })}
                >
                    Version {loanEnquiry.versions.loanEnquiryRevisions.length - index}
                </a>
            );
        } else {
            return (
                <Subtitle1 elkey={`s_${elkey}_${index}`}>
                    Version {loanEnquiry.versions.loanEnquiryRevisions.length - index}
                </Subtitle1>
            );
        }
    };

    render() {
        const { loanEnquiry, elkey = '' } = this.props;

        return (
            <React.Fragment key={'hgj' + elkey}>
                {loanEnquiry.versions.loanEnquiryRevisions.map((ler, index) => (
                    <KeyValueTable
                        key={`ler1_${elkey}_${index}`}
                        elkey={`ler_${elkey}_${index}`}
                        style={{ border: 'none' }}
                    >
                        <KeyValueTableRow
                            key={index}
                            elkey={'rev_hist_' + index}
                            term={this.renderVersionTitle(ler, index)}
                            def={this.renderRevisionRows(ler.revisions)}
                        />
                    </KeyValueTable>
                ))}
            </React.Fragment>
        );
    }
}

RevisionHistoryContainer.propTypes = {
    loanEnquiry: PropTypes.object,
    userRole: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RevisionHistoryContainer);
