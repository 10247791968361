import React, { useState } from 'react';
import TextField from '../../redux-form-material-ui/TextField';
import { Button } from '@material-ui/core';
import { slugify } from '../../pitchblack_react_utils/text/Slugify';
import { defaultTagColor, presetTagColors } from '../../pitch4_enum/enum/LETagPresetColors';
import { HexColorPicker } from 'react-colorful';
import { editTag } from '../../pitch4_api/edit_tag';
import { createTag } from '../../pitch4_api/create_tag';
import { textContrastColor } from '../../pitchblack_react_utils/color/Contrast';

export default function TagProperties(props) {
    const { tag = null, setModalClose, uri, isNew = false, refreshData } = props;
    const [color, setColor] = useState(tag ? tag.color : defaultTagColor);
    const [label, setLabel] = useState(tag ? tag.label : '');
    const [submitting, setSubmitting] = useState(false);
    const [colorPickerVisible, setColorPickerVisible] = useState(!!(tag && !presetTagColors.includes(tag.color)));
    const handleLabelChange = (label) => setLabel(label);
    const handleColorChange = (color) => setColor(color);

    const handleSubmit = () => {
        setSubmitting(true);
        if (false === isNew) {
            const tags = [{ id: tag.id, value: slugify(label), label: label, color: color, isNew: isNew }];
            editTag(uri, tags).then(() => {
                setSubmitting(false);
                setModalClose();
                refreshData();
            });
        } else {
            const tags = [{ value: slugify(label), label: label, color: color, isNew: isNew }];
            createTag(uri, tags).then(() => {
                setSubmitting(false);
                setModalClose();
                refreshData();
            });
        }
    };

    return (
        <div className={'tag-properties-container'}>
            <h2 className={'header'}>Set tag properties</h2>
            <TextField
                className={'tag-label'}
                id="tag-label"
                variant="outlined"
                value={label}
                onChange={(event) => handleLabelChange(event.target.value)}
                autoFocus={true}
            />
            <div className="picker__swatches">
                {presetTagColors.map((presetColor, index) => (
                    <button
                        key={index}
                        className={`picker__swatch ${!presetColor.includes(color) ? '' : 'active'}`}
                        style={{ background: presetColor }}
                        onClick={() => {
                            handleColorChange(presetColor);
                        }}
                    />
                ))}
            </div>
            <div className={`color-picker-container ${true === colorPickerVisible ? 'visible' : ''}`}>
                <HexColorPicker
                    className={'color-picker'}
                    color={color}
                    onChange={(color) => handleColorChange(color)}
                />
                <div
                    className="color-value"
                    style={{ backgroundColor: color, color: textContrastColor(color, ['#000000', '#ffffff']) }}
                >
                    Current color is {color}
                </div>
            </div>
            <div className={'more-colors-container'}>
                <div onClick={() => setColorPickerVisible(!colorPickerVisible)} className={'link-more-colors'}>
                    {false === colorPickerVisible ? 'More' : 'Less'} colours
                </div>
            </div>
            <div className={'actions'}>
                <Button
                    className={'btn-cancel'}
                    variant={'contained'}
                    color={'default'}
                    onClick={() => setModalClose()}
                >
                    Cancel
                </Button>
                <Button
                    className={'btn-submit'}
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => handleSubmit()}
                    disabled={submitting || label === ''}
                >
                    Save
                </Button>
            </div>
        </div>
    );
}
