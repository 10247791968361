import React from 'react';
import ReactDOM from 'react-dom';
import Root from './pages/Root';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

if (process.env.SENTRY_ENABLED === 'true') {
    Sentry.init({
        dsn: process.env.SENTRY_DNS,
        integrations: [new Integrations.BrowserTracing()],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control https://develop.sentry.dev/sdk/performance/
        tracesSampleRate: 1.0,
        environment: process.env.SENTRY_ENVIRONMENT,
        release: process.env.SENTRY_RELEASE,
    });
}

ReactDOM.render(
    <Sentry.ErrorBoundary>
        <Root />
    </Sentry.ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
