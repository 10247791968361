import React from 'react';
import { Container, Item } from '../../pitch4_layout/components/div';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { reduxForm } from 'redux-form';

class SortsContainer extends React.Component {
    render() {
        let { children, justify = 'flex-end', style = {} } = this.props;
        return (
            <Container className={'sort-container'} justifyContent={justify} style={style}>
                {React.Children.map(children, (item, index) => (
                    <Item key={'sc_' + index}>{item}</Item>
                ))}
            </Container>
        );
    }
}

SortsContainer.propTypes = {
    children: PropTypes.any,
};

export default compose(
    reduxForm({
        form: 'sorts_form',
    })
)(SortsContainer);
