import {
    SET_STATUS,
    SET_STATUS_SUBMISSION_ERRORS,
} from '../../../slam/administer_loanenquiries_viewlenderloanenquiry/redux';

export const NAMESPACE = 'admin.view_lender_loan_enquiry';

export const GET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.get_matching_criteria_config`;
export const SET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.set_matching_criteria_config`;
export const API_SET_STATUS = `${NAMESPACE}.api_set_status`;
export const CLEAR_STATUS = `${NAMESPACE}.clear_status`;

export const actionGetMatchingCriteriaConfig = () => ({
    type: GET_MATCHING_CRITERIA_CONFIG,
});

export const actionSetMatchingCriteriaConfig = (config) => ({
    type: SET_MATCHING_CRITERIA_CONFIG,
    config,
});

export const actionApiSetStatus = (leadId, status, reason, metadata) => {
    return {
        type: API_SET_STATUS,
        leadId,
        status,
        reason,
        metadata,
    };
};

export const actionSetStatus = (status) => ({
    type: SET_STATUS,
    status,
});

export const actionClearStatus = () => ({
    type: CLEAR_STATUS,
});

const initialState = {
    config: {},
    status: null,
    reason: null,
    metadata: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATCHING_CRITERIA_CONFIG:
            const config = action.config;

            return {
                ...state,
                config,
            };
        case API_SET_STATUS:
            return {
                ...state,
                submitted: true,
                errors: initialState.errors,
                errorsVisible: initialState.errorsVisible,
            };
        case SET_STATUS:
            const { status } = action;
            return {
                ...state,
                status,
                submitted: false,
            };
        case SET_STATUS_SUBMISSION_ERRORS:
            const { errors } = action;
            return {
                ...state,
                submitted: false,
                errors,
                errorsVisible: true,
            };
        case CLEAR_STATUS:
            return {
                ...state,
                status: initialState.status,
                metadata: initialState.metadata,
            };
        default:
            return { ...state };
    }
};

export const selectorGetMatchingCriteriaConfig = (state) => {
    return state[NAMESPACE].config;
};

export const selectorGetStatus = (state) => {
    return state[NAMESPACE].status;
};

export const selectorGetSubmitted = (state) => {
    return state[NAMESPACE].submitted;
};

export const selectorSetStatusSubmissionErrors = (state) => {
    return state[NAMESPACE].errors;
};

export const selectorAreErrorsVisible = (state) => {
    return state[NAMESPACE].errorsVisible;
};

//Default export reducer
export default reducer;
