import createP4ApolloFetch from '../../../bootstrap/middlewares';

const apolloFetchCreator = (URL) => {
    return createP4ApolloFetch({ uri: URL });
};

const favouriteMutation = `
mutation setLenderLoanEnquiryFavourite($lenderLoanEnquiry: ID, $loanEnquiry: ID, $input: setLenderLoanEnquiryFavouriteInput!) {
  setLenderLoanEnquiryFavourite(lenderLoanEnquiry: $lenderLoanEnquiry, loanEnquiry: $loanEnquiry, input: $input) {
    lenderLoanEnquiry
    loanEnquiry
    favourite
  }
}
`;

export const setMatchFavourite = (apolloFetch, lenderLoanEnquiryId, loanEnquiryId, favourite) => {
    return apolloFetch({
        query: favouriteMutation,
        variables: {
            lenderLoanEnquiry: lenderLoanEnquiryId,
            loanEnquiry: loanEnquiryId,
            input: {
                favourite,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};

export default apolloFetchCreator;
