import createP4ApolloFetch from 'bootstrap/middlewares';
import { ANON_URL } from '../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: ANON_URL });

const query = `
    query getPasswordResetToken($id: ID!) {
        passwordResetToken(id: $id) {
        id
        hasExpired
      }
    }
`;

export const getPasswordResetToken = (id) => {
    return apolloFetch({
        query: query,
        variables: {
            id,
        },
    })
        .then(({ data, errors }) => {
            return data;
        })
        .catch((error) => {
            return {
                passwordResetToken: null,
            };
        });
};
