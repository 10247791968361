import { ADMIN_LOGO_UPLOAD_URL } from '../../../../../bootstrap/constants/constants';
import axios from 'axios';

export default class LogoApi {
    uploadLogo = async (mappedValues, token) => {
        const formData = new FormData();
        const uploadUrl = ADMIN_LOGO_UPLOAD_URL.replace('{lenderGuid}', mappedValues.id);
        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
        };
        formData.append('logo', mappedValues.logo, mappedValues.logo.name);
        return axios.post(uploadUrl, formData, { headers });
    };

    deleteLogo = async (lenderGuid, token) => {
        const uploadUrl = ADMIN_LOGO_UPLOAD_URL.replace('{lenderGuid}', lenderGuid);
        const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
        };
        return axios.delete(uploadUrl, { headers });
    };
}
