import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import ModalLayout from 'pitch4_modal/src/component/ModalLayout';
import { Body1 } from 'pitch4_layout/components/text';
import { Container, RowItem } from 'pitch4_layout/components/div';
import { CenteringContainer, Item } from 'pitchblack_react_utils/layout/index';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import { connect } from 'react-redux';

const DeleteEnquiryConfirm = ({ handleDelete }) => {
    return (
        <ModalLayout>
            <CenteringContainer className={'modal-delete-modal-container'}>
                <Item xs={12}>
                    <Container>
                        <RowItem>
                            <CenteringContainer className={'margin-top-30 text-align-center'}>
                                <Body1>
                                    <h2
                                        style={{
                                            color: '#2ec4b6',
                                            fontWeight: 700,
                                            fontSize: '15px',
                                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                        }}
                                    >
                                        This will permanently delete this enquiry
                                    </h2>
                                </Body1>
                            </CenteringContainer>
                        </RowItem>
                        <RowItem>
                            <CenteringContainer className={'margin-top-30 text-align-center'}>
                                <Body1>
                                    <p className={'modal-delete-content'}>
                                        You’re about to permanently delete this enquiry. Please confirm this action as
                                        you will not be able to retrieve this enquiry once it is deleted.
                                    </p>
                                </Body1>
                            </CenteringContainer>
                        </RowItem>
                        <RowItem>
                            <CenteringContainer className={'button-panel'}>
                                <PrimaryButton type="submit" onClick={handleDelete}>
                                    YES, DELETE
                                </PrimaryButton>
                            </CenteringContainer>
                        </RowItem>
                    </Container>
                </Item>
            </CenteringContainer>
        </ModalLayout>
    );
};

DeleteEnquiryConfirm.propTypes = {
    handleDelete: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(DeleteEnquiryConfirm);
