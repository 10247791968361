import React from 'react';

import { TableCell as MTableCell } from '@material-ui/core';

class TableCell extends React.Component {
    render() {
        let { children, classes, stale, ...other } = this.props;

        return <MTableCell {...other}>{children}</MTableCell>;
    }
}

export default TableCell;
