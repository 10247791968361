import React from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { Container, Item } from '../../pitchblack_react_utils/layout/index';
import { compose } from 'recompose';
import withSession from '../../pitchblack_react_utils/session/withSession';
import ErrorIcon from '@material-ui/icons/Error';
import { RoleNameEnum } from '../../pitch4_enum';
import { BRAND_NAME } from '../../bootstrap/constants/constants';

const Header = styled(Item)`
    width: 100%;
    min-height: 50px;
    margin: 0;
    padding: 0;
`;

const Content = styled(Item)`
    width: 100%;
    height: 100%;
    padding: 10px 30px 90px;
`;

const DefaultAdminLayout = ({
    header,
    content,
    notifications,
    modal,
    splash = null,
    notice = null,
    className = '',
    session,
    ...rest
}) => {
    const roleEnum = new RoleNameEnum();
    const userRole = roleEnum.getType(session.getRelevantSession().getMostSeniorRole().name);

    return (
        <Container
            id="root_layout_container"
            alignItems="flex-start"
            justify="flex-start"
            direction="row"
            spacing={0}
            className={className}
        >
            <Helmet key={1}>
                <title>{BRAND_NAME}</title>
            </Helmet>
            <Header key={2}>{header}</Header>
            <div className={'main'} key={3}>
                {splash}
                <div className="banner-notice-system-container">{notice}</div>
                {true === session.hasAssumedToken() && (
                    <div className="banner-notice-system-container">
                        <div className={'banner-notice admin-assist'}>
                            <ErrorIcon />
                            <strong>You are admin assisting a '{userRole}' account!</strong>
                        </div>
                    </div>
                )}
                <Content>{content}</Content>
            </div>
            {modal}
            {notifications}
        </Container>
    );
};

export default compose(withRouter, withSession)(DefaultAdminLayout);
