import React from 'react';
import PropTypes from 'prop-types';
import { CheckboxMultiValueGroup, DateField, TextArea, TextField } from '../../../pitch4_forms/components/inputs';
import Select from '../../../pitch4_forms/components/inputs/Select';
import {
    FIELD_TYPE_CURRENCY,
    FIELD_TYPE_DATE,
    FIELD_TYPE_ENUM,
    FIELD_TYPE_INTEGER,
    FIELD_TYPE_PERCENT,
    FIELD_TYPE_TEXT,
} from '../constants';
import { isEmpty } from '../../../pitch4_validation';
import CurrencyField from '../../../pitch4_forms/components/inputs/CurrencyField';

class InputField extends React.Component {
    render() {
        let {
            columnName,
            onChangeFunc,
            columnOptions,
            columnType,
            value = '',
            multi = true,
            placeholder = '',
            ...props
        } = this.props;

        switch (columnType) {
            case FIELD_TYPE_INTEGER:
            case FIELD_TYPE_PERCENT:
                return (
                    <TextField
                        onChange={onChangeFunc}
                        type="number"
                        normalize={(value) => (!isEmpty(value) ? parseInt(value, 10) : '')}
                        format={(value) => (!isEmpty(value) ? parseInt(value, 10) : '')}
                        placeholder={placeholder}
                        value={value}
                        {...props}
                    />
                );

            case FIELD_TYPE_CURRENCY:
                return <CurrencyField value={value} placeholder={placeholder} onChange={onChangeFunc} {...props} />;

            case FIELD_TYPE_DATE:
                return <DateField value={value} placeholder={placeholder} onChange={onChangeFunc} {...props} />;

            case FIELD_TYPE_TEXT:
                if (props.multiLine === true) {
                    props = { ...props };

                    return <TextArea value={value} placeholder={placeholder} onChange={onChangeFunc} {...props} />;
                }

                return <TextField value={value} placeholder={placeholder} onChange={onChangeFunc} {...props} />;

            case FIELD_TYPE_ENUM:
                if (columnOptions === null) {
                    return <TextField value={value} disabled={true} {...props} />;
                }

                if (multi === true) {
                    return (
                        <CheckboxMultiValueGroup
                            options={columnOptions}
                            style={{ padding: '0px 10px 10px 12px' }}
                            value={value}
                            {...props}
                        />
                    );
                }

                return <Select {...props} onChange={onChangeFunc} inputProps={props} options={columnOptions} />;

            default:
                return (
                    <TextField
                        value={value}
                        placeholder={placeholder}
                        onChange={onChangeFunc}
                        disabled={true}
                        {...props}
                    />
                );
        }
    }
}

InputField.propTypes = {
    columnName: PropTypes.any,
    multi: PropTypes.bool,
};

export default InputField;
