export const presetTagColors = [
    '#f02d3a',
    '#e0ca3c',
    '#326273',
    '#fe7f2d',
    '#b744b8',
    '#ffd447',
    '#5899e2',
    '#914d76',
    '#2ec4b6',
    '#f4ac45',
    '#696d7d',
    '#858ae3',
    '#e7a977',
    '#ffefbf',
    '#c3df46',
    '#c64191',
    '#cac4ce',
    '#fdc5f5',
    '#3f6c51',
    '#0077b6',
];

export const defaultTagColor = presetTagColors[0];
