class Mapper {
    mapToForm = (props) => {
        let {
            id = '',
            name = '',
            primaryEmail = '',
            fcaIdentifier = '',
            companyNumber = '',
            createdAt = '',
            updatedAt = '',
            primaryAddress: { address1 = '', address2 = '', address3 = '', address4 = '', postcode = '', country = '' },
            primaryPhone: { number = '' },
            profileDescription = '',
            profileUrl = '',
            profileEnabled = false,
            profileLogo = false,
            profilePrimaryColor = '',
            offPanel = false,
        } = props;

        return {
            id,
            createdAt,
            updatedAt,
            name,
            fcaIdentifier,
            companyNumber,
            primaryEmail,
            primaryAddressAddress1: address1,
            primaryAddressAddress2: address2,
            primaryAddressAddress3: address3,
            primaryAddressAddress4: address4,
            primaryAddressCountry: country,
            primaryAddressPostcode: postcode,
            primaryPhoneNumber: number,
            profileDescription,
            profileUrl,
            profileEnabled,
            profileLogo,
            profilePrimaryColor,
            offPanel,
        };
    };

    mapFromForm = ({
        name = '',
        primaryEmail = '',
        fcaIdentifier = '',
        companyNumber = '',
        primaryAddressAddress1 = '',
        primaryAddressAddress2 = '',
        primaryAddressAddress3 = '',
        primaryAddressAddress4 = '',
        primaryAddressCountry = '',
        primaryAddressPostcode = '',
        primaryPhoneNumber = '',
        profileDescription = '',
        profileUrl = '',
        profileEnabled = false,
        profilePrimaryColor = '',
        offPanel = false,
    }) => {
        profileUrl = null === profileUrl ? '' : profileUrl.trimStart().trimEnd();
        return {
            name,
            primaryEmail,
            fcaIdentifier,
            companyNumber,
            primaryAddress: {
                address1: primaryAddressAddress1,
                address2: primaryAddressAddress2,
                address3: primaryAddressAddress3,
                address4: primaryAddressAddress4,
                postcode: primaryAddressPostcode,
                country: primaryAddressCountry,
            },
            primaryPhone: {
                number: primaryPhoneNumber,
            },
            profileDescription,
            profileUrl,
            profileEnabled,
            profilePrimaryColor,
            offPanel,
        };
    };
}

export default Mapper;
