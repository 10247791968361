import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import Query from 'react-apollo/Query';
import { change, formValueSelector, reduxForm } from 'redux-form';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_BORROWER } from '../../../authentication/enum/Roles/NameEnum';
import {
    actionSetInitialValues,
    actionSetValidationErrors,
    selectorGetInitialValues,
    selectorGetTypes,
} from '../redux/add';
import renderInitialFetchErrors from 'pitchblack_react_utils/apollo/renderInitialFetchErrors';
import renderIfSubmitting, { Loading } from 'pitchblack_react_utils/form/renderIfSubmitting';
import PropTypes from 'prop-types';
import { AddForm as AddFormModule, AddMapper } from 'pitch4_documents';
import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import DocumentTypeEnum from 'pitch4_enum/enum/DocumentTypeEnum';
import SessionManager from 'pitchblack_react_utils/session/SessionManager';
import { OPERATION_EQ } from 'pitch4_enum/enum/OperationEnum';

class AddToContextStoreForm extends React.Component {
    render() {
        let {
            id,
            apollo: { documents },
            contextDocsQuery,
            changeFile,
            createMetaMutate,
            createPermissionMutate,
            initialValues,
            setValidationErrors,
            handleSubmit,
            userStoreDocument,
        } = this.props;

        const docs = documents ? documents.edges : [];
        const typesEnum = new DocumentTypeEnum();
        const types = typesEnum.getBorrowerTypes();

        return (
            <Query
                fetchPolicy={'no-cache'}
                query={contextDocsQuery}
                context={{
                    uri: BORROWER_URL,
                }}
                variables={{
                    count: 9999,
                    filters: [
                        {
                            column: 'contextEntity',
                            operation: OPERATION_EQ,
                            operand: id,
                            not: false,
                        },
                    ],
                }}
                ssr={true}
            >
                {({ data: { documents: submittedDocuments }, loading }) => {
                    const submittedDocs = submittedDocuments ? submittedDocuments.edges : [];

                    return (
                        <AddFormModule
                            id={id}
                            types={types}
                            userDocuments={docs}
                            submittedDocuments={submittedDocs}
                            mapper={new AddMapper()}
                            loading={loading}
                            changeFile={changeFile}
                            createMetaMutate={createMetaMutate}
                            createPermissionMutate={createPermissionMutate}
                            initialValues={initialValues}
                            setValidationErrors={setValidationErrors}
                            handleSubmit={handleSubmit}
                            userStoreDocument={userStoreDocument}
                            allowAddToUserStore={true}
                        />
                    );
                }}
            </Query>
        );
    }
}

AddToContextStoreForm.propTypes = {
    id: PropTypes.string.isRequired,
    onPending: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    contextDocsQuery: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    setValidationErrors: PropTypes.func.isRequired,
    setInitialValues: PropTypes.func.isRequired,
    changeFile: PropTypes.func.isRequired,
    createMetaMutate: PropTypes.func.isRequired,
    createPermissionMutate: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    userStoreDocument: PropTypes.string,
};

const DocumentsQuery = gql`
    query getDocuments($count: Int!, $filters: [FilterInput]) {
        documents(first: $count, filters: $filters) {
            edges {
                node {
                    id
                    fileName
                    type
                }
                cursor
            }
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
            totalCount
        }
    }
`;

const GqlWrapper = graphql(DocumentsQuery, {
    options() {
        const sessionManager = new SessionManager();

        return {
            variables: {
                count: 9999,
                filters: [
                    {
                        column: 'contextEntity',
                        operation: 'eq',
                        operand: sessionManager.getRelevantSession().getId(),
                        not: false,
                    },
                ],
            },
            forceFetch: true,
            fetchPolicy: 'no-cache',
            context: {
                uri: BORROWER_URL,
            },
        };
    },

    // This function re-runs every time `data` changes, including after `updateQuery`,
    // meaning our loadMoreEntries function will always have the right cursor
    props({ data, ...params }) {
        return {
            ...params,
            apollo: {
                ...data,
            },
        };
    },
});

const mapStateToProps = (state) => {
    const selector = formValueSelector('borrower_document_add_to_context_store_form');

    return {
        initialValues: selectorGetInitialValues(state),
        types: selectorGetTypes(state),
        userStoreDocument: selector(state, 'document'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValidationErrors: (errors) => {
            dispatch(actionSetValidationErrors(errors));
        },
        setInitialValues: (values, types) => {
            dispatch(actionSetInitialValues(values, types));
        },
        changeFile: (file) => {
            dispatch(change('borrower_document_add_to_context_store_form', 'file', file));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    GqlWrapper,
    renderInitialFetchErrors(),
    reduxForm({
        form: 'borrower_document_add_to_context_store_form',
    }),
    renderErrorIfAuthorisationFail([ROLE_BORROWER]),
    renderIfSubmitting(() => <Loading heading="Please wait as we upload your file." />)
)(AddToContextStoreForm);
