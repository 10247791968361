import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import _ from 'lodash';
import StatusEnum, {
    STATUS_MATCHED,
    STATUS_IN_PROGRESS,
    STATUS_APPLICATION,
    STATUS_LEGALS,
    STATUS_VALUATION,
    STATUS_COMPLETE,
    STATUS_WITHDRAWN,
} from '../../enum/LoanEnquiries/StatusEnum';
import LoanEnquiryContextMenuWidget from './LoanEnquiryContextMenuWidget';
import WithdrawEnquiryConfirm from './WithdrawEnquiryConfirm';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { connect } from 'react-redux';
import withSession from '../../../../pitchblack_react_utils/session/withSession';
import Button from '@material-ui/core/Button';
import { Badge } from '../../../../pitch4_layout/components/badge/Badge';
import { Grid } from '@material-ui/core';
import { SECTION_LOAN_TYPE } from '../../mle/constants';
import { BUSINESS_TYPE } from '../../../MakeLoanEnquiry/enums/Borrower/BorrowerLoanType';
import { LOAN_TYPE_BUSINESS_FINANCE, LOAN_TYPE_PROPERTY } from '../../enum/LoanEnquiries/LoanTypeEnum';
import { calculateLoanType } from '../../../MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';
import DebugMatch from '../../../MakeLoanEnquiry/components/Debugging/DebugMatch';

const statusEnum = new StatusEnum();
const closedStatuses = statusEnum.getClosedTypes();

class LoanEnquiryStatusWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            statusStepsOrder: [STATUS_MATCHED, STATUS_IN_PROGRESS, STATUS_APPLICATION, STATUS_VALUATION, STATUS_LEGALS],
            statusStepsOrderRefresh: true,
            loanEnquiryStatus: props.loanEnquiry.status,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { statusStepsOrder, statusStepsOrderRefresh, loanEnquiryStatus } = this.state;

        // Set the last status
        if (true === statusStepsOrderRefresh) {
            if (_.includes(closedStatuses, loanEnquiryStatus) && statusEnum.getType(loanEnquiryStatus)) {
                // statusStepsOrder.pop();
                statusStepsOrder.push(loanEnquiryStatus);
                this.setState({ statusStepsOrderRefresh: false });
            } else {
                // statusStepsOrder.pop();
                statusStepsOrder.push(STATUS_COMPLETE);
                this.setState({ statusStepsOrderRefresh: false });
            }
        }
    }

    setStatus = (newStatus) => {
        const { statusStepsOrder } = this.state;
        const newStatusStepsOrder = statusStepsOrder;
        newStatusStepsOrder.pop();
        newStatusStepsOrder.push(newStatus);
        this.setState({
            statusStepsOrder: newStatusStepsOrder,
            statusStepsOrderRefresh: false,
            loanEnquiryStatus: newStatus,
        });
    };

    handleStatusChange = (status, loanEnquiryId) => (newStatus) => {
        const { updateStatusOnChange, setModalComponent } = this.props;
        const handleConfirmWithdrawal = () => {
            updateStatusOnChange(loanEnquiryId, newStatus);
            this.setStatus(STATUS_WITHDRAWN);
        };
        if (!newStatus || newStatus === status) {
            return;
        }
        if (newStatus === STATUS_WITHDRAWN) {
            setModalComponent(<WithdrawEnquiryConfirm handleSubmit={handleConfirmWithdrawal} />, true);
        }
    };

    render() {
        const {
            loanEnquiry,
            toggleMessageDrawer,
            lendersMessageAllowed,
            handleTabSelect,
            session,
            selectedLenders,
            acmChatAlertActive,
            acmDocumentsAlertActive,
            toggleAdminCaseManagementDrawer,
            isDebug,
            setDebug,
            setModalClose,
            setModalComponent,
        } = this.props;

        const submissionData = JSON.parse(loanEnquiry.submissionData['rawSubmission'] || '{}');
        let loanType = LOAN_TYPE_PROPERTY;
        if (typeof submissionData === 'object' && SECTION_LOAN_TYPE in submissionData) {
            loanType = calculateLoanType(loanEnquiry.loanEnquiryData).value;
        } else {
            loanType =
                loanEnquiry.loanEnquiryData.loanType === BUSINESS_TYPE
                    ? LOAN_TYPE_BUSINESS_FINANCE
                    : LOAN_TYPE_PROPERTY;
        }

        const { statusStepsOrder, loanEnquiryStatus } = this.state;
        let labelClass = 'inactive';

        const activeStatusIndex = Object.keys(this.state.statusStepsOrder).find(
            (key) => this.state.statusStepsOrder[key] === loanEnquiryStatus
        );

        return (
            <div className={'progress-bar-container'} key={'sdfgh_0'}>
                <div className={'progress-container'} id={'lead-status'} key={'sdfgh_1'}>
                    <span className={'sub-title'} key={'sdfgh_2'}>
                        enquiry status:
                    </span>
                    {statusStepsOrder.map((status, index) => {
                        if (status === loanEnquiryStatus) {
                            labelClass = 'active';
                        }

                        if (index > activeStatusIndex) {
                            labelClass = 'inactive';
                        }

                        return (
                            <div className={'status-container'} key={'1_' + index}>
                                <div style={{ whiteSpace: 'nowrap' }} key={'2_' + index}>
                                    <div
                                        className={`status-label ${labelClass} status-${loanEnquiryStatus}`}
                                        key={'3_' + index}
                                    >
                                        <FiberManualRecordIcon style={{ marginRight: 5 }} key={'4_' + index} />
                                        {index + 1}. {statusEnum.getType(status, loanType)}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <Grid container className={'buttons-container'} key={'lesw_c_0'}>
                    {isDebug === true && (
                        <Grid item style={{ marginRight: 20 }} key={'lesw_i_0'}>
                            <DebugMatch
                                setModalComponent={setModalComponent}
                                setModalClose={setModalClose}
                                style={{ height: 45, paddingTop: 11, backgroundColor: 'white', whiteSpace: 'nowrap' }}
                                loanEnquiry={loanEnquiry}
                            ></DebugMatch>
                        </Grid>
                    )}
                    <Grid item style={{ marginRight: 20, width: 190 }} key={'lesw_i_0'}>
                        <Button
                            variant="outlined"
                            size={'large'}
                            style={{ height: 45, paddingTop: 11, backgroundColor: 'white', whiteSpace: 'nowrap' }}
                            onClick={() => toggleAdminCaseManagementDrawer()}
                            key={'lesw_b_0'}
                        >
                            Admin Case Management
                        </Button>
                        {(acmChatAlertActive || acmDocumentsAlertActive) && (
                            <Badge label={'update'} style={{ position: 'relative', right: -160, top: -60 }} />
                        )}
                    </Grid>
                    {!session.hasAssumedToken() && lendersMessageAllowed && (
                        <Grid item style={{ marginRight: 20 }} className={'btn-send-to-selected'} key={'lesw_i_1'}>
                            <Button
                                key={'lesw_b_1'}
                                variant="outlined"
                                size={'large'}
                                style={{ height: 45, paddingTop: 11, backgroundColor: 'white', whiteSpace: 'nowrap' }}
                                onClick={() => {
                                    toggleMessageDrawer();
                                    handleTabSelect(0); // A tab index to be selected
                                }}
                                disabled={
                                    (selectedLenders && selectedLenders.selectionModel.length === 0) || !selectedLenders
                                }
                            >
                                Send to Selected
                            </Button>
                        </Grid>
                    )}
                    <Grid
                        item
                        className={'context-menu'}
                        style={{ backgroundColor: 'white', height: 45, borderRadius: 16, paddingLeft: 6 }}
                        key={'lesw_i_2'}
                    >
                        <LoanEnquiryContextMenuWidget
                            handleStatusChange={this.handleStatusChange(loanEnquiry.status, loanEnquiry.id)}
                            toggleMessageDrawer={toggleMessageDrawer}
                            loanEnquiry={loanEnquiry}
                            lendersMessageAllowed={lendersMessageAllowed}
                            handleTabSelect={handleTabSelect}
                            isDebug={isDebug}
                            setDebug={setDebug}
                            setModalClose={setModalClose}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    }
}

LoanEnquiryStatusWidget.propTypes = {
    loanEnquiry: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(LoanEnquiryStatusWidget);
