import { call, put, takeLatest } from 'redux-saga/effects';

import { actionSetMatchingCriteriaConfig, GET_MATCHING_CRITERIA_CONFIG } from '../redux';
import { getConfig } from '../api/get_config';
import { setLeadStatus } from '../api/set_status';
import { removeLenderLoanEnquiryAlert } from '../api/remove_lender_loan_enquiry_alert';
import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../../../notifications/sagas/container';
import {
    actionSetStatus,
    API_SET_STATUS,
    SET_STATUS_SUBMISSION_ERRORS,
    REMOVE_LENDER_LOAN_ENQUIRY_ALERT,
} from '../redux/index';
import { sagaActionModalHide } from '../../../../modal/sagas/container';

export const SAGA_NAMESPACE = 'slam.view_loan_enquiry';

//Worker Sagas
function* getMatchingCriteriaConfig() {
    const response = yield call(getConfig);

    switch (response.getClassName()) {
        case 'GetConfigSuccess':
            const config = response.getData();

            // Set the form initial values
            yield put(actionSetMatchingCriteriaConfig(config));
            break;
        case 'GetConfigError':
        default:
            break;
    }
}

function* apiSetStatus(action) {
    const response = yield call(setLeadStatus, action.leadId, action.status, action.metadata);
    const { errors } = response;
    if (errors) {
        const errors = response.errors.map((err) => err.message);
        yield put({ type: SAGA_ACTION_ERROR, message: 'Could not update lead status' });
        yield put({ type: SET_STATUS_SUBMISSION_ERRORS, errors });
    } else {
        yield put(actionSetStatus(action.status));
        if (action.metadata) {
            yield put(sagaActionModalHide());
        }
        yield put({ type: SAGA_ACTION_SUCCESS, message: 'Lead status saved!' });
    }
}

function* apiRemoveLenderLoanEnquiryAlert(action) {
    yield call(removeLenderLoanEnquiryAlert, action.lenderLoanEnquiryId);
}

//Main Saga
function* main() {
    //response handling
    yield takeLatest(GET_MATCHING_CRITERIA_CONFIG, getMatchingCriteriaConfig);
    yield takeLatest(API_SET_STATUS, apiSetStatus);
    yield takeLatest(REMOVE_LENDER_LOAN_ENQUIRY_ALERT, apiRemoveLenderLoanEnquiryAlert);
}

export default main;
