import React from 'react';

import { Table as MTable } from '@material-ui/core';

class Table extends React.Component {
    render() {
        let { children, ...other } = this.props;

        return <MTable {...other}>{children}</MTable>;
    }
}

export default Table;
