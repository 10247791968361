import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Button from './PrimaryButton';

class PrimaryRedirectButton extends React.Component {
    render() {
        let {
            url,
            children,
            className,
            disabled = false,
            inputRef = () => null,
            color = null,
            dispatch,
            ...other
        } = this.props;

        const baseClassNames = 'primary-button redirect-button';

        if (color === null) {
            color = 'primary';
        }

        if (disabled) {
            return (
                <Button
                    variant="contained"
                    disabled={true}
                    color={color}
                    className={`${baseClassNames} ${className}`}
                    {...other}
                >
                    {children}
                </Button>
            );
        }

        return (
            <Button
                inputRef={inputRef}
                onClick={() => dispatch(push(url))}
                variant="contained"
                disabled={disabled}
                color={color}
                className={`${baseClassNames} ${className}`}
                {...other}
            >
                {children}
            </Button>
        );
    }
}

PrimaryRedirectButton.propTypes = {
    url: PropTypes.string.isRequired,
    children: PropTypes.any,
    inputRef: PropTypes.any,
    disabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(PrimaryRedirectButton);
