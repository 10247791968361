import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import {
    actionSetValidationErrors,
    actionSetInitialValues,
    selectorGetInitialValues,
    selectorGetTypes,
} from '../redux/add';
import renderInitialFetchErrors from 'pitchblack_react_utils/apollo/renderInitialFetchErrors';
import { reduxForm, change } from 'redux-form';
import renderIfSubmitting, { Loading } from 'pitchblack_react_utils/form/renderIfSubmitting';
import PropTypes from 'prop-types';
import { AddForm as AddFormModule } from 'pitch4_documents';
import { AddMapper } from 'pitch4_documents';
import DocumentTypeEnum from 'pitch4_enum/enum/DocumentTypeEnum';

class AddToBorrowerStoreForm extends React.Component {
    componentDidMount() {
        let { setInitialValues } = this.props;
        const typesEnum = new DocumentTypeEnum();
        setInitialValues(null, typesEnum.getBorrowerTypes());
    }

    render() {
        let { changeFile, createMetaMutate, initialValues, handleSubmit, setValidationErrors, types } = this.props;

        return (
            <AddFormModule
                changeFile={changeFile}
                types={types}
                mapper={new AddMapper()}
                createMetaMutate={createMetaMutate}
                initialValues={initialValues}
                handleSubmit={handleSubmit}
                setValidationErrors={setValidationErrors}
            />
        );
    }
}

AddToBorrowerStoreForm.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    setValidationErrors: PropTypes.func.isRequired,
    setInitialValues: PropTypes.func.isRequired,
    changeFile: PropTypes.func.isRequired,
    createMetaMutate: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    types: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return {
        initialValues: selectorGetInitialValues(state),
        types: selectorGetTypes(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValidationErrors: (errors) => {
            dispatch(actionSetValidationErrors(errors));
        },
        setInitialValues: (values, types) => {
            dispatch(actionSetInitialValues(values, types));
        },
        changeFile: (file) => {
            dispatch(change('admin_document_add_to_borrower_store_form', 'file', file));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    renderInitialFetchErrors(),
    reduxForm({
        form: 'admin_document_add_to_borrower_store_form',
    }),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN]),
    renderIfSubmitting(() => <Loading heading="Please wait as we upload your file." />)
)(AddToBorrowerStoreForm);
