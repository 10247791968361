import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { SubmissionError } from 'redux-form';
import { SingleFormContentLayout } from '../../../../../pitch4_layout/components/content';
import Mapper from '../form/Mapper';
import Form from '../components/Form';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import { path } from '../../../../../pitch4_routing';
import NoQueryMutation from '../../../../../pitch4_forms/components/NoQueryMutation';
import { ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST } from '../../../../../pitch4_routing';
import { BRAND_MAIN_CONTACT } from '../../../../../bootstrap/constants/constants';

class CreateUserLenderFormContainer extends React.Component {
    render() {
        return (
            <SingleFormContentLayout
                label={'Associate Lender'}
                content={
                    <NoQueryMutation
                        mutationQuery={gql`
                            mutation associateLender($userId: ID!, $input: pitch4admin_associateLendersInput!) {
                                associateLenders(userId: $userId, input: $input) {
                                    lendersAdded {
                                        id
                                    }
                                    lendersIgnored
                                }
                            }
                        `}
                        renderFormFunction={(mutate, props) => {
                            return <Form onSubmit={this.onSubmit(mutate, props)} />;
                        }}
                    />
                }
            />
        );
    }

    onSubmit = (mutate, props) => (values, dispatch, formProps) => {
        let { match } = this.props;

        let { notificationSuccess, notificationError, redirect } = props;

        let { reset } = formProps;

        let mapper = new Mapper();

        let mappedValues = mapper.mapFromForm(values);

        //map the variables if necessary
        return mutate({
            variables: {
                userId: match.params.id,
                input: { lenderIds: [mappedValues.lender] },
            },
            context: {
                uri: API_URL,
            },
        })
            .then(({ data }) => {
                reset();

                let url = path(ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST, { id: match.params.id });

                //its a wrapped function
                let configRedirect = redirect(url);
                configRedirect();
                notificationSuccess('User successfully associated with lender');
            })
            .catch((error) => {
                notificationError(
                    `Failed to associate user with lender. Please contact support on ` + BRAND_MAIN_CONTACT
                );
                throw new SubmissionError({ _error: error.graphQLErrors });
            });
    };
}

CreateUserLenderFormContainer.propTypes = {
    match: PropTypes.any,
};

export default compose(withRouter, renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN]))(CreateUserLenderFormContainer);
