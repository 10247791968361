import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import createP4ApolloFetch from 'bootstrap/middlewares';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });
const BusinessFinanceEnquiryDraftMutation = `
    mutation createBusinessFinanceLoanEnquirySubmissionDraft($input: intermediary_BusinessFinanceEnquirySubmissionInput!){
      createBusinessFinanceLoanEnquirySubmissionDraft(input: $input){
        createLoanEnquirySubmission {
            id
            loanEnquiry {
                id
            }
        }
      }
    }
`;

const PropertyFinanceEnquiryDraftMutation = `
    mutation createLoanEnquirySubmissionDraft($input: intermediary_PropertyFinanceEnquirySubmissionInput!){
      createLoanEnquirySubmissionDraft(input: $input){
        createLoanEnquirySubmission {
            id
            loanEnquiry {
                id
            }
        }
      }
    }
`;

//response classes
const errorFactory = new ValidationResultFactory();

class MakeDraftLoanEnquiry {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class MakeDraftLoanEnquiryErrors extends MakeDraftLoanEnquiry {
    constructor(errors) {
        super('MakeLoanEnquiryErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class MakeDraftLoanEnquirySuccess extends MakeDraftLoanEnquiry {
    constructor(data) {
        super('MakeLoanEnquirySuccess');
        this.data = data;
    }

    getData = () => {
        return this.data;
    };
}

export const makeDraftLoanEnquiry = (
    acceptedTermsVersion,
    reviewMatches,
    formSections,
    isBusinessFinanceEnquiry,
    formVersion
) => {
    return apolloFetch({
        query:
            isBusinessFinanceEnquiry === true
                ? BusinessFinanceEnquiryDraftMutation
                : PropertyFinanceEnquiryDraftMutation,
        variables: {
            input: {
                acceptedTermsVersion,
                reviewMatches,
                formSections,
                formVersion,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new MakeDraftLoanEnquiryErrors(vR);
            }

            return new MakeDraftLoanEnquirySuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new MakeDraftLoanEnquiryErrors(vR);
        });
};
