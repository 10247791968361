import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, Select, MenuItem, Button } from '@material-ui/core';

export default function StepOne(props) {
    const { matches, blankLenderSpaces, setBlankLenderSpaces, setStep, matrixLenders, setMatrixLenders } = props;

    const handleOnChange = (event) => {
        if (event.target.checked) {
            setMatrixLenders([...matrixLenders, event.target.value]);
        } else {
            setMatrixLenders(matrixLenders.filter((item) => item !== event.target.value));
        }
    };

    const checkIfLenderSelected = (id) => {
        return matrixLenders.includes(id);
    };

    const termsAvailable = (lenderTerms) => null !== lenderTerms;

    const handleNext = () => {
        setStep(2);
    };

    return (
        <>
            <div className={'sub-header'}>
                Please confirm the <strong>lenders</strong> to be included in this terms matrix and the
                <br /> number of blank <strong>lenders</strong> columns to include.
            </div>
            <div className={'step-one'}>
                <table>
                    <thead>
                        <tr>
                            <th className={'th-lenders'}>Lenders</th>
                            <th className={'th-include'}>Include?</th>
                        </tr>
                    </thead>
                    <tbody>
                        {matches.map((match, index) => (
                            <tr key={index}>
                                <td width={'70%'}>
                                    {match.lender.name}
                                    <br />
                                    <span className={'no-terms'}>
                                        {false === termsAvailable(match.lenderTerms) && (
                                            <small>
                                                Terms <span>Pending</span> from Lender
                                            </small>
                                        )}
                                    </span>
                                </td>
                                <td width={'30%'} className={'lender-include'}>
                                    <Checkbox
                                        color={'primary'}
                                        defaultChecked={
                                            checkIfLenderSelected(match.id) &&
                                            true === termsAvailable(match.lenderTerms)
                                        }
                                        onChange={handleOnChange}
                                        value={match.id}
                                        disabled={false === termsAvailable(match.lenderTerms)}
                                    />
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td className={'blank-spaces'}>+ blank lender spaces</td>
                            <td className={'blank-spaces-select'}>
                                <FormControl variant="outlined">
                                    <Select
                                        value={blankLenderSpaces}
                                        onChange={(event) => setBlankLenderSpaces(event.target.value)}
                                    >
                                        <MenuItem value={0}>0</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                        <MenuItem value={2}>2</MenuItem>
                                        <MenuItem value={3}>3</MenuItem>
                                        <MenuItem value={4}>4</MenuItem>
                                        <MenuItem value={5}>5</MenuItem>
                                    </Select>
                                </FormControl>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={'actions-container'}>
                <Button
                    disabled={matrixLenders.length === 0}
                    className={'btn-next'}
                    color={'primary'}
                    variant={'contained'}
                    onClick={handleNext}
                >
                    Next
                </Button>
            </div>
        </>
    );
}
