import React, { useEffect, useState } from 'react';
import { Grid, InputLabel, MenuItem, Select, FormControl } from '@material-ui/core';
import DirtyTermIndicator from '../../../../../../pitch4_elements/terms/DirtyTermIndicator';

export default function TermItemChoice(props) {
    const { item, itemIndex, isLast, updateTermValue } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [initialChoice, setInitialChoice] = useState(null);
    const handleOnChange = (event) => {
        const choice = event.target.value === '' ? null : event.target.value;
        updateTermValue(item, itemIndex, choice);
        setIsDirty(choice !== initialChoice);
    };

    //Did mount
    useEffect(() => {
        setInitialChoice(item.value || item.choice ? item.choice[item.choiceIndex] : '');
        if (null !== item.choiceIndex) {
            updateTermValue(item, itemIndex, item.value || item.choice[item.choiceIndex]);
            if (null === item.value) setIsDirty(true);
        }
    }, []);

    return (
        <Grid className={`item ${true === isLast ? 'last' : ''}`} item lg={12} md={12} sm={12} xs={12}>
            <Grid item lg={4} md={4} sm={4} xs={4} className={`name ${null !== item.value ? 'filled' : 'empty'}`}>
                {item.name}
            </Grid>
            <Grid item lg={7} md={7} sm={7} xs={7} className={'optional'}>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <FormControl variant="outlined" style={{ minWidth: 151 }}>
                        <InputLabel id={`choice-select-outlined-label-${itemIndex}`}>Please select</InputLabel>
                        <Select
                            labelId={`choice-select-outlined-label-${itemIndex}`}
                            id={`choice-select-outlined-${itemIndex}`}
                            value={item.value || ''}
                            onChange={handleOnChange}
                            label="Please select"
                        >
                            <MenuItem value={''} key={9999}>
                                Please select
                            </MenuItem>
                            {item.choice.map((choiceItem, index) => (
                                <MenuItem key={index} value={choiceItem}>
                                    {choiceItem}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </Grid>
            <DirtyTermIndicator value={item.value} isDirty={isDirty} />
        </Grid>
    );
}
