import BaseEnum from '../../../../../pitchblack_react_utils/enums/BaseEnum';

export const STATUS_ENABLED = 'STATUS_ENABLED';
export const STATUS_DISABLED = 'STATUS_DISABLED';

class StatusEnum extends BaseEnum {
    getTypes = () => {
        return {
            [STATUS_ENABLED]: 'Enabled',
            [STATUS_DISABLED]: 'Disabled',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default StatusEnum;
