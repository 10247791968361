import { INTERMEDIARY_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });

const GetMatchesQuery = `
        query getLoanEnquiryMatch($loanEnquiryId: ID!) {
          loanEnquiryMatch(loanEnquiryId: $loanEnquiryId) {
            loanEnquiry {
                status
                contractSigned
            }
            matched {
              id
              ignored
              status
              updatedAt
          lenderTerms
              lender {
                id
                name
                anonName
                profileDescription
                profileUrl
                profileEnabled
                profileLogo
                profilePrimaryColor
              },
              favourite
              alertActive
              lenderTermsUpdatedAlertActive
            }
            match_pending {
              id
              ignored
              status
              updatedAt
              lender {
                id
                name
                profileDescription
                profileUrl
                profileEnabled
                profileLogo
                profilePrimaryColor
              },
              favourite
            }
          }
        }
`;

export const getMatches = (loanEnquiryId) => {
    return apolloFetch({
        query: GetMatchesQuery,
        variables: {
            loanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            if (error) {
                throw error;
            }

            return error;
        });
};
