import { BaseEnum } from 'pitch4_enum';

const createOptionsFromEnum = (type) => {
    if (!(type instanceof BaseEnum)) {
        throw new Error('type is not instance of Enum');
    }

    let types = type.getTypes();

    return Object.keys(types).map((item) => ({
        value: item,
        label: types[item],
    }));
};

const createOptionsFromEnumArray = (options) => {
    return Object.keys(options).map((item) => ({
        value: item,
        label: options[item],
    }));
};

const createOptionsFromData = (
    data = [],
    handlerFunc = (item) => ({
        value: 'test',
        label: 'replaceme',
    })
) => {
    return Object.keys(data).map((item) => handlerFunc(item));
};

export { createOptionsFromData, createOptionsFromEnum, createOptionsFromEnumArray };
