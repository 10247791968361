import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import { USER_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: USER_URL });

const Query = `
    query twoFactorAuthDetails {
        twoFactorAuthDetails {
            key
            deliveryMethod
            createdAt
            status
            user {
                username
            }
        }
    }
`;

//response classes
const errorFactory = new ValidationResultFactory();

class GetTwoFactorAuthDetails {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class GetTwoFactorAuthDetailsErrors extends GetTwoFactorAuthDetails {
    constructor(errors) {
        super('GetTwoFactorAuthDetailsErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class GetTwoFactorAuthDetailsSuccess extends GetTwoFactorAuthDetails {
    constructor(data) {
        super('GetTwoFactorAuthDetailsSuccess');
        this.data = data;
    }

    getData = () => {
        return this.data.twoFactorAuthDetails;
    };
}

export const getTwoFactorAuthDetails = () => {
    let out = apolloFetch({
        query: Query,
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new GetTwoFactorAuthDetailsErrors(vR);
            }

            return new GetTwoFactorAuthDetailsSuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new GetTwoFactorAuthDetailsErrors(vR);
        });

    return out;
};
