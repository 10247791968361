import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '../../../pitchblack_react_utils/material-ui/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { Container, Item } from '../../../pitchblack_react_utils/layout/index';
import withSession from '../../../pitchblack_react_utils/session/withSession';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import { ROUTE_ANON_LOGIN } from '../../../pitch4_routing';
import Avatar from '@material-ui/core/Avatar';
import NavLogo from '../../navigation/components/NavLogo';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { selectorIsPrivacyAccepted } from '../../landing/redux/landing';
import LeftSideMenu from './LeftSideMenu';
import { actionToggleLeftSideMenu } from '../redux/leftsidemenu';
import HorizontalMenu from './HorizontalMenu';
import AccountMenu from '../../header/components/AccountMenu';
import JoyrideContainer from '../../joyride/JoyrideContainer';

class NavigationBar extends React.Component {
    render() {
        let { toggleLeftSideMenu, pushToPage } = this.props;

        let sm = new SessionManager();

        return (
            <div>
                <LeftSideMenu />
                <AppBar>
                    <Toolbar className={`toolbar`}>
                        <Container alignItems="center" className={`full-height`}>
                            <Item xs={2} className={`full-height`} key={1}>
                                <NavLogo />
                            </Item>
                            <Item xs={5} sm={8} md={9} className={`full-height`} key={2}>
                                <HorizontalMenu />
                            </Item>
                            <Item xs={5} sm={2} md={1} className={`toolbar-actions`} key={3}>
                                <Container justify="flex-end" alignContent="center" className={`full-height`}>
                                    {sm.isAuthenticated() ? (
                                        <React.Fragment>
                                            <Item className={`toolbar-action-side-menu`}>
                                                <IconButton aria-label="Menu" onClick={() => toggleLeftSideMenu()}>
                                                    <MenuIcon />
                                                </IconButton>
                                            </Item>
                                            <Item className={`toolbar-action-account`}>
                                                <JoyrideContainer />
                                                <AccountMenu />
                                            </Item>
                                        </React.Fragment>
                                    ) : (
                                        <Item />
                                    )}

                                    {!sm.isAuthenticated() ? (
                                        <Item className={`toolbar-action-account`}>
                                            <IconButton
                                                onClick={() => {
                                                    this.forceUpdate();
                                                    return pushToPage(ROUTE_ANON_LOGIN);
                                                }}
                                            >
                                                <Avatar>
                                                    <LogoutIcon />
                                                </Avatar>
                                            </IconButton>
                                        </Item>
                                    ) : (
                                        <Item />
                                    )}
                                </Container>
                            </Item>
                        </Container>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

NavigationBar.propTypes = {
    session: PropTypes.object.isRequired,
    pushToPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        privacyAccepted: selectorIsPrivacyAccepted(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        pushToPage: (page) => {
            dispatch(push(page));
        },
        toggleLeftSideMenu: () => {
            dispatch(actionToggleLeftSideMenu());
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(NavigationBar);
