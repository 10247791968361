import React from 'react';
import { Container } from '../../pitchblack_react_utils/layout/index';
import PropTypes from 'prop-types';

class ChatIcon extends React.Component {
    render() {
        let { text, subText, icon } = this.props;

        return (
            <Container className={`chat-icon`}>
                <img src={icon} title={text} alt="Chat Icon" />
                <div className={'forename'}>{text}</div>
                {subText}
            </Container>
        );
    }
}

ChatIcon.propTypes = {
    img: PropTypes.string,
    text: PropTypes.string.isRequired,
    subText: PropTypes.element,
};

export default ChatIcon;
