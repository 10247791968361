import BaseEnum from 'pitchblack_react_utils/enums/BaseEnum';

export const SUCCESS_LEVEL = 'success';
export const INFO_LEVEL = 'info';
export const WARNING_LEVEL = 'warning';
export const ERROR_LEVEL = 'error';

class LevelEnum extends BaseEnum {
    getTypes = () => {
        return {
            [SUCCESS_LEVEL]: SUCCESS_LEVEL,
            [INFO_LEVEL]: INFO_LEVEL,
            [WARNING_LEVEL]: WARNING_LEVEL,
            [ERROR_LEVEL]: WARNING_LEVEL,
        };
    };

    getTypesArray = () => {
        return Object.keys(this.getTypes());
    };
}

export default LevelEnum;
