import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

class EnumLabel extends React.Component {
    render() {
        let { enumIn, value } = this.props;

        let types = enumIn.getTypes();

        let enumValues = [];

        if (_.isArray(value)) {
            value.forEach(function (key, val) {
                enumValues.push(types[key] ? types[key] : key);
            });
        } else if (_.isObject(value)) {
            Object.keys(value).forEach(function (key) {
                enumValues.push(types[key] ? types[key] : key);
            });
        } else {
            enumValues.push(types[value] ? types[value] : value);
        }

        const clearEnumValues = enumValues.filter((item) => item !== 'select_all');

        return clearEnumValues.join('\r\n');
    }
}

EnumLabel.propTypes = {
    enumIn: PropTypes.object.isRequired,
    value: PropTypes.any,
};

export default EnumLabel;
