import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

export default function Container({
    alignItems = 'flex-start',
    justify = 'flex-start',
    direction = 'row',
    spacing = 0,
    elkey = '',
    ...props
}) {
    return (
        <Grid
            container
            key={'c_' + elkey}
            alignItems={alignItems}
            justifyContent={justify}
            direction={direction}
            spacing={spacing}
            {...props}
        >
            {props.children}
        </Grid>
    );
}

Container.propTypes = {
    className: PropTypes.any,
    justify: PropTypes.oneOf(['center', 'flex-start', 'flex-end', 'space-between', 'space-around']),
    alignItems: PropTypes.oneOf(['center', 'flex-start', 'flex-end', 'stretch', 'baseline']),
    direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
    spacing: PropTypes.number,
};
