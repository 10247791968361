import { BaseEnum } from '../../pitch4_enum';
import { BRAND_NAME } from '../../bootstrap/constants/constants';

export const INTRODUCER_GIFTWARE = 'giftware';

const configs = {
    [INTRODUCER_GIFTWARE]: {
        pageTitle: 'Giftware',
        formHeaderText: 'For us to help, you will need a FREE <br/> Giftware Funding Account',
        formSubHeaderText: '<span style="font-weight: 300;">Simply add a password to start</span>',
        name: 'giftware',
        // logo: commMortIcon,
        headerText:
            '<div style="font-size: 48px;font-weight: 700; line-height: 45px;">Welcome to <br/>  Giftware <br/>  Funding <br/>  powered by <br/>  Provide <br/>  Finance</div>',
        subHeaderText: `Source the best lenders for your loan enquiry <br/> with ${BRAND_NAME}. Secure your funding fast!`,
    },
};

export const getConfig = (introducer) => {
    return configs[introducer];
};

class IntroducerEnum extends BaseEnum {
    getTypes = () => {
        return {
            [INTRODUCER_GIFTWARE]: 'commercial-mortgages',
        };
    };

    getNames = () => {
        return {
            [INTRODUCER_GIFTWARE]: 'Giftware',
        };
    };

    getName = (type) => this.getNames()[type];
}

export default IntroducerEnum;
