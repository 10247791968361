import React from 'react';
import { Button } from '@material-ui/core';

export default function ApplicationStartedModal(props) {
    const handleOnClick = () => {
        const { setModalClose } = props;
        setModalClose();
    };

    return (
        <div className={'application-started-modal'}>
            <div className={'header'}>Lender Application Started!</div>
            <div className={'text'}>You can choose to apply to as many of your lenders as you want.</div>
            <div className={'actions'}>
                <Button color={'primary'} variant={'contained'} size={'large'} onClick={handleOnClick}>
                    Done
                </Button>
            </div>
        </div>
    );
}
