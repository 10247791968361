/**
 * This class is the base class for Errors and Warnings. It is abstract really - so we should not use it directly.
 */
class ValidationResult {
    constructor() {
        this.data = [];
        this.message = null;
        this.type = null;
        this.constraints = null;
    }

    getMessage = () => {
        return this.message;
    };

    setMessage = (message) => {
        this.message = message;
        return this;
    };

    getData = () => {
        return this.data;
    };

    setData = (data) => {
        this.data = data;
        return this;
    };

    getType = () => {
        return this.type;
    };

    setType = (type) => {
        this.type = type;
        return this;
    };

    getConstraints = () => {
        return this.constraints;
    };

    setConstraints = (constraints) => {
        this.constraints = constraints;
        return this;
    };
}

export default ValidationResult;
