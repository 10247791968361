import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import {
    path,
    ROUTE_SLAM_BUSINESS_STREAM_CREATE,
    ROUTE_SLAM_BUSINESS_STREAM_EDIT,
    ROUTE_SLAM_BUSINESS_STREAM_VIEW,
} from '../../../../../pitch4_routing';
import { push } from 'react-router-redux';
import { RollupCommandContainer } from '../../../../../pitch4_tabular/components';
import { PrimaryRedirectButton } from '../../../../../pitch4_layout/components/buttons';
import { ROLE_SLAM } from '../../../../authentication/enum/Roles/NameEnum';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';
import DeleteButton from '../components/DeleteButton';
import SetStatusButton from '../components/SetStatusButton';
import { STATUS_ACTIVE, STATUS_INACTIVE } from '../../enum/BusinessStreams/StatusEnum';
import CloneButton from '../components/CloneButton';
import BusinessStreamTypeEnum from '../../enum/BusinessStreams/BusinessStreamTypeEnum';
import SetStatusToggle from '../components/SetStatusToggle';
import _ from 'lodash';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { ROWS_PER_PAGE, PAGE_SIZE } from '../../../../../pitch4_enum/enum/DataGridEnum';
import { actionSetJoyrideType } from '../../../../joyride/redux';
import HeaderWithCount from '../../../../../pitch4_elements/screen-headers/HeaderWithCount';
import Toolbar from '../components/Toolbar';
import RouteManagerFactory from '../../../../../pitch4_routing/src/RouteManagerFactory';
import { DEFAULT_SEARCH_FILTER_DELAY } from '../../../../../pitch4_utils/FiltersDefaults';

const apolloFetch = createP4ApolloFetch({ uri: SLAM_URL });
const businessStreamTypeEnum = new BusinessStreamTypeEnum();

class TableStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFetchError: null,
            searchFilter: '',
            dataGrid: {
                rows: [],
                totalRowCount: 0,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: [{ column: 'name', direction: 'asc' }],
            },
            dataRequest: {
                filters: [],
                sorts: [
                    {
                        column: 'name',
                        direction: 'asc',
                    },
                ],
                pages: [],
                cursor: null,
                storedDataFilters: [],
            },
        };
    }

    componentDidMount() {
        const { setJoyrideType } = this.props;
        const data = this.getData();
        this.processData(data);
        setJoyrideType(null);
    }

    refreshData = () => {
        this.setState(
            {
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages: [], cursor: null },
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    processData(data) {
        const { currentPage } = this.state.dataGrid;
        const { cursor } = this.state.dataRequest;

        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            const businessStreams = data.businessStreams.edges.map((user) => user.node);
            const rows = businessStreams.map((businessStream, index) => {
                return {
                    id: businessStream.id,
                    name: businessStream.name,
                    businessStreamType: businessStream.businessStreamType,
                    matchingRuleCount: businessStream.matchingRule.length,
                    status: businessStream.status,
                };
            });

            let pages = this.state.dataRequest.pages;
            pages[currentPage] = {
                cursor: cursor,
                hasNextPage: data.businessStreams.pageInfo.hasNextPage,
                endCursor: data.businessStreams.pageInfo.endCursor,
                startCursor: data.businessStreams.pageInfo.startCursor,
                hasPreviousPage: data.businessStreams.pageInfo.hasPreviousPage,
            };

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: rows,
                    totalRowCount: data.businessStreams.totalCount,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages, cursor: data.businessStreams.pageInfo.endCursor },
                },
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    getData() {
        const query = `
            query getBusinessStreams(
                $cursor: String
                $first: Int!
                $sorts: [SortInput]
                $filters: [FilterInput]
            ) {
                businessStreams(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
                    edges {
                        node {
                            id
                            name
                            businessStreamType
                            status
                            matchingRule {
                                id
                                column
                                type
                                operation
                                operand
                            }
                        }
                        cursor
                    }
                    pageInfo {
                        hasNextPage
                        endCursor
                        startCursor
                        hasPreviousPage
                    }
                    totalCount
                }
            }
        `;

        return apolloFetch({
            query: query,
            variables: {
                cursor: this.state.dataRequest.cursor,
                filters: this.state.dataRequest.filters,
                first: this.state.dataGrid.pageSize,
                sorts: this.state.dataRequest.sorts,
                storedDataFilters: this.state.dataRequest.storedDataFilters,
            },
        });
    }

    handlePageChange = (pageConfig) => {
        const previousPage = this.state.dataGrid.currentPage;
        const newPage = pageConfig;
        const { pages } = this.state.dataRequest;

        if (newPage > previousPage) {
            // Next page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[previousPage].endCursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        } else {
            // Previous page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[newPage].cursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handlePageSizeChange = (pageConfig) => {
        this.setState(
            {
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: true,
                    pageSize: pageConfig,
                    rows: [],
                    currentPage: 0,
                    totalRowCount: 0,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    pages: [],
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    buildSearchRequestFilters = () => {
        const { searchFilter } = this.state;
        let searchRequestFilter = [];
        const searchableFields = ['name'];

        if (searchFilter.length > 0) {
            searchRequestFilter = [
                ...searchRequestFilter,
                ...searchableFields.map((field) => {
                    return {
                        operation: 'ilike',
                        operand: '%' + searchFilter + '%',
                        column: field,
                        not: false,
                    };
                }),
            ];
        }

        return searchRequestFilter;
    };

    handleSearchFilterChange = (event) => {
        this.setState(
            {
                searchFilter: event.target.value,
            },
            () => {
                this.setState({
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: [...this.buildSearchRequestFilters()],
                    },
                });
            }
        );

        _.delay(
            (value) => {
                if (this.state.searchFilter === value) {
                    const data = this.getData();
                    this.processData(data);
                }
            },
            DEFAULT_SEARCH_FILTER_DELAY,
            event.target.value
        );
    };

    handleClearAllFilters = () => {
        this.setState(
            {
                searchFilter: '',
                dataGrid: { ...this.state.dataGrid, rows: [] },
                dataRequest: { ...this.state.dataRequest, filters: [], cursor: null },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    handleSortModelChange = (params) => {
        const { sortModel } = this.state.dataGrid;
        const sortableFieldsMapping = {
            name: 'name',
            status: 'status',
        };

        if (params.sortModel !== null && typeof params.sortModel !== 'undefined' && params.sortModel !== sortModel) {
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        sortModel: params.sortModel,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        sorts: [
                            {
                                column: sortableFieldsMapping[params.sortModel[0].field],
                                direction: params.sortModel[0].sort,
                            },
                        ],
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    render() {
        const { sortingModel, totalRowCount, dataLoading } = this.state.dataGrid;
        const { searchFilter } = this.state;
        const { dispatch } = this.props;
        const routeManagerFactory = new RouteManagerFactory();
        const routeManager = routeManagerFactory.create();

        return (
            <div>
                <div className={'le-header-container'}>
                    <div className={'header-text'}>
                        <HeaderWithCount
                            title={'Business Streams'}
                            count={totalRowCount}
                            countText={'business streams'}
                        />
                        <div className={'btn-mobile'}>
                            <PrimaryRedirectButton
                                style={{ marginLeft: 20 }}
                                url={routeManager.route(ROUTE_SLAM_BUSINESS_STREAM_CREATE)}
                            >
                                Create
                            </PrimaryRedirectButton>
                        </div>
                    </div>
                    <Toolbar
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                        handleClearAllFilters={this.handleClearAllFilters}
                        dispatch={dispatch}
                    />
                </div>

                <div className={`le-table-container`}>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortingModel}
                        paginationMode="server"
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        sortingMode="server"
                        onSortModelChange={this.handleSortModelChange}
                        rowCount={this.state.dataGrid.totalRowCount}
                        pageSize={this.state.dataGrid.pageSize}
                        rowsPerPageOptions={this.state.dataGrid.rowsPerPage}
                        rows={this.state.dataGrid.rows}
                        loading={true}
                        disableColumnFilter={true}
                        disableMultipleColumnsSorting={true}
                        disableColumnReorder={true}
                        disableMultipleColumnsFiltering={true}
                        disableSelectionOnClick={true}
                        disableColumnMenu={true}
                        onCellClick={(params) => {
                            if (params.field !== 'actions' && params.field !== 'status') {
                                dispatch(
                                    push(path(ROUTE_SLAM_BUSINESS_STREAM_VIEW, { businessStreamId: params.row.id }))
                                );
                            }
                        }}
                        components={{
                            LoadingOverlay: () => {
                                return (
                                    <GridOverlay>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {this.state.dataGrid.dataLoading ? (
                                                <LinearProgress />
                                            ) : this.state.dataGrid.rows.length === 0 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        width: '100%',
                                                        paddingTop: 100,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    No data
                                                </div>
                                            ) : null}
                                        </div>
                                    </GridOverlay>
                                );
                            },
                        }}
                        columns={[
                            {
                                field: 'name',
                                headerName: 'NAME',
                                width: 800,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'businessStreamType',
                                headerName: 'TYPE',
                                width: 400,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                                renderCell: (params) => businessStreamTypeEnum.getType(params.row.businessStreamType),
                            },
                            {
                                field: 'matchingRuleCount',
                                headerName: 'PARAMETERS',
                                width: 130,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'status',
                                headerName: 'STATUS',
                                width: 100,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => (
                                    <SetStatusToggle
                                        itemId={params.row.id}
                                        status={params.row.status}
                                        outerRefetch={this.refreshData}
                                        color={'primary'}
                                    />
                                ),
                            },
                            {
                                field: 'actions',
                                renderHeader: () => <div />,
                                cellClassName: 'le-table-cell le-table-cell-content-right',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                renderCell: (params) => {
                                    return (
                                        <div className={'le-table-cell-context-menu'} style={{ marginTop: 10 }}>
                                            <RollupCommandContainer>
                                                <CloneButton id={params.row.id} outerRefetch={this.refreshData}>
                                                    Clone
                                                </CloneButton>
                                                <PrimaryRedirectButton
                                                    url={path(ROUTE_SLAM_BUSINESS_STREAM_EDIT, {
                                                        businessStreamId: params.row.id,
                                                    })}
                                                >
                                                    Edit
                                                </PrimaryRedirectButton>
                                                <SetStatusButton
                                                    disabled={params.row.status === STATUS_INACTIVE}
                                                    outerRefetch={this.refreshData}
                                                    selectedItems={[params.row.id]}
                                                    useStatus={STATUS_INACTIVE}
                                                >
                                                    Disable
                                                </SetStatusButton>
                                                <SetStatusButton
                                                    disabled={params.row.status === STATUS_ACTIVE}
                                                    outerRefetch={this.refreshData}
                                                    selectedItems={[params.row.id]}
                                                    useStatus={STATUS_ACTIVE}
                                                >
                                                    Enable
                                                </SetStatusButton>
                                                <DeleteButton outerRefetch={this.refreshData} id={params.row.id}>
                                                    Delete
                                                </DeleteButton>
                                            </RollupCommandContainer>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SLAM])
)(TableStyleContainer);
