import React from 'react';
import { reduxForm } from 'redux-form';
import { CenteringContainer, Item } from '../../../../pitchblack_react_utils/layout/index';
import { compose } from 'recompose';
import FormContent from '../../../../pitch4_elements/applicant/FormContent';

class EditForm extends React.Component {
    render() {
        const { onSubmit, submitting, handleSubmit, reset } = this.props;

        return (
            <CenteringContainer>
                <Item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormContent submitting={submitting} reset={reset} />
                    </form>
                </Item>
            </CenteringContainer>
        );
    }
}

export default compose(
    reduxForm({
        form: 'borrower_applicant_edit',
    })
)(EditForm);
