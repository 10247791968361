import React from 'react';
import { TableCell as MTableCell } from '@material-ui/core';
import { Container, Item } from 'pitchblack_react_utils/layout';

class TableRowControls extends React.Component {
    render() {
        let { children, className = null, ...other } = this.props;

        if (className) {
            className += ' table-row-controls';
        } else {
            className = 'table-row-controls';
        }

        return (
            <MTableCell {...other} className={className}>
                <Container justifyContent={`flex-end`} alignItems={`center`}>
                    <Item xs={12}>
                        <span className="actions">{children}</span>
                    </Item>
                </Container>
            </MTableCell>
        );
    }
}

export default TableRowControls;
