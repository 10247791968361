import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import {
    QUESTION_LOAN_AMOUNT,
    QUESTION_LOAN_NEEDED_BY,
    QUESTION_LOAN_TERM,
    QUESTION_OUTSTANDING_LOAN_AMOUNT,
    QUESTION_PORTFOLIO_PROPERTIES,
    QUESTION_PORTFOLIO_REFINANCE,
    QUESTION_PORTFOLIO_VALUE,
    QUESTION_RENTAL_INCOME_PORTFOLIO,
} from '../../../enums/Borrower/BorrowerQuestions';
import { YES } from '../../../enums/Shared/YesNo';
import Question from '../../Question/containers/Question';
import LoanNeededBy from '../../../enums/Shared/LoanNeededBy';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BorrowerQuestions from '../../../enums/Borrower/BorrowerQuestions';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';

class PortfolioSelectionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const loanNeededByOptions = new LoanNeededBy().getTypes();
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER
                            ? 'About your Portfolio Finance'
                            : 'About your clients Portfolio Finance'}
                    </Typography>
                </Grid>
                <Question
                    type={questions[QUESTION_PORTFOLIO_PROPERTIES].type}
                    label={questions[QUESTION_PORTFOLIO_PROPERTIES].label}
                    questionEnum={'QUESTION_PORTFOLIO_PROPERTIES'}
                    fieldName={questions[QUESTION_PORTFOLIO_PROPERTIES].fieldName}
                    placeholder={questions[QUESTION_PORTFOLIO_PROPERTIES].placeholder}
                    validation={questions[QUESTION_PORTFOLIO_PROPERTIES].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_PORTFOLIO_VALUE].type}
                    label={questions[QUESTION_PORTFOLIO_VALUE].label}
                    questionEnum={'QUESTION_PORTFOLIO_VALUE'}
                    fieldName={questions[QUESTION_PORTFOLIO_VALUE].fieldName}
                    placeholder={questions[QUESTION_PORTFOLIO_VALUE].placeholder}
                    validation={questions[QUESTION_PORTFOLIO_VALUE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_RENTAL_INCOME_PORTFOLIO].type}
                    label={questions[QUESTION_RENTAL_INCOME_PORTFOLIO].label}
                    questionEnum={'QUESTION_RENTAL_INCOME_PORTFOLIO'}
                    fieldName={questions[QUESTION_RENTAL_INCOME_PORTFOLIO].fieldName}
                    placeholder={questions[QUESTION_RENTAL_INCOME_PORTFOLIO].placeholder}
                    validation={questions[QUESTION_RENTAL_INCOME_PORTFOLIO].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                {this.state.formData[questions[QUESTION_PORTFOLIO_REFINANCE].fieldName] === YES && (
                    <Question
                        type={questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].type}
                        label={questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].label}
                        questionEnum={'QUESTION_OUTSTANDING_LOAN_AMOUNT'}
                        fieldName={questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].fieldName}
                        placeholder={questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].placeholder}
                        validation={questions[QUESTION_OUTSTANDING_LOAN_AMOUNT].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={[]}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
                <Question
                    type={questions[QUESTION_LOAN_AMOUNT].type}
                    label={questions[QUESTION_LOAN_AMOUNT].label}
                    questionEnum={'QUESTION_LOAN_AMOUNT'}
                    fieldName={questions[QUESTION_LOAN_AMOUNT].fieldName}
                    placeholder={questions[QUESTION_LOAN_AMOUNT].placeholder}
                    validation={questions[QUESTION_LOAN_AMOUNT].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_LOAN_TERM].type}
                    label={questions[QUESTION_LOAN_TERM].label}
                    questionEnum={'QUESTION_LOAN_TERM'}
                    fieldName={questions[QUESTION_LOAN_TERM].fieldName}
                    placeholder={questions[QUESTION_LOAN_TERM].placeholder}
                    validation={questions[QUESTION_LOAN_TERM].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_LOAN_NEEDED_BY].type}
                    label={questions[QUESTION_LOAN_NEEDED_BY].label}
                    questionEnum={'QUESTION_LOAN_NEEDED_BY'}
                    fieldName={questions[QUESTION_LOAN_NEEDED_BY].fieldName}
                    placeholder={questions[QUESTION_LOAN_NEEDED_BY].placeholder}
                    validation={questions[QUESTION_LOAN_NEEDED_BY].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={loanNeededByOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
            </Grid>
        );
    }
}

export default PortfolioSelectionContainer;
