import React from 'react';
import Joyride, { ACTIONS } from 'react-joyride';
import JoyrideTooltip from './JoyrideTooltip';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import JoyrideConfig from './config';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { actionSetJoyrideActive, selectorGetJoyrideActive, selectorGetJoyrideType } from './redux';

class JoyrideContainer extends React.Component {
    handleJoyrideCallback = (data) => {
        const { action } = data;
        const { toggleJoyride } = this.props;

        if (action === ACTIONS.CLOSE || action === ACTIONS.RESET) {
            toggleJoyride(false);
        }
    };

    toggleJoyride = () => {
        const { toggleJoyride, joyrideActive } = this.props;
        toggleJoyride(!joyrideActive);
    };

    render() {
        const { joyrideType, joyrideActive } = this.props;
        const joyrideConfig = new JoyrideConfig();
        const steps = joyrideConfig.getSteps(joyrideType);

        return (
            <>
                {steps && (
                    <IconButton aria-label="Menu" onClick={this.toggleJoyride}>
                        <HelpIcon />
                    </IconButton>
                )}
                {joyrideActive && steps && (
                    <Joyride
                        callback={this.handleJoyrideCallback}
                        continuous={true}
                        showProgress={true}
                        steps={steps}
                        tooltipComponent={JoyrideTooltip}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        joyrideType: selectorGetJoyrideType(state),
        joyrideActive: selectorGetJoyrideActive(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleJoyride: (isActive) => dispatch(actionSetJoyrideActive(isActive)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(JoyrideContainer);
