import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { path } from '../../../../../pitch4_routing';
import { push, goBack } from 'react-router-redux';
import {
    RollupCommandContainer,
    SimpleCommandContainer,
    FiltersContainer,
    SortsContainer,
} from '../../../../../pitch4_tabular/components';
import { PrimaryRedirectButton } from '../../../../../pitch4_layout/components/buttons';
import { GenericTableContainer } from '../../../../../pitch4_tabular/containers';
import { TableRow, TableCell, TableRowControls } from '../../../../../pitch4_layout/components/tables';
import {
    selectorGetCurrentCursor,
    selectorGetFilters,
    selectorGetPages,
    selectorGetPerPage,
    selectorGetSorts,
} from '../../../../../pitch4_tabular/redux/index_ns';
import { sagaActionSetSort } from '../../../../../pitch4_tabular/sagas';
import { onSortChange, onSortClear, onFilterChange } from '../../../../../pitch4_tabular/utils';
import { ILIKE } from '../../../../../pitch4_tabular/enum/OperationEnum';
import { SORT_DESC } from '../../../../../pitch4_tabular/enum/SortDirectionEnum';
import { Sort, Filter } from '../../../../../pitch4_tabular/components';
import { NAMESPACE } from '../redux';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import {
    ROUTE_P4A_ADMINISTER_USER_LENDERS_CREATE,
    ROUTE_P4A_ADMINISTER_USER_LENDERTEAMS_LIST,
} from '../../../../../pitch4_routing';
import DeleteButton from '../components/DeleteButton';
import DateFormatter from '../../../../../pitch4_layout/components/data/formatter/DateFormatter';

class TableStyleContainer extends React.Component {
    componentDidMount() {
        let { match, dispatch } = { ...this.props };

        dispatch(
            sagaActionSetSort(
                NAMESPACE,
                match.url
            )({
                column: 'createdAt',
                direction: SORT_DESC,
            })
        );
    }

    render() {
        //put code to map filters to props here. If you need multiple props - escalate at stand up.
        let { match, sorts, pages, filters = [], first, cursor, dispatch } = this.props;

        let lenderFilter = filters.filter(({ column }) => {
            return column === 'lendername';
        });
        lenderFilter =
            lenderFilter.length > 0
                ? lenderFilter[0]
                : {
                      column: 'lendername',
                      operation: ILIKE,
                      operand: '',
                      not: false,
                  };

        let sort = sorts.filter(({ column }) => {
            return column === 'createdAt';
        });
        sort =
            sort.length > 0
                ? sort[0]
                : {
                      column: 'createdAt',
                      direction: SORT_DESC,
                  };

        return (
            <div>
                <GenericTableContainer
                    title={`Associated Lenders`}
                    namespace={NAMESPACE}
                    description={`Add or remove associated lenders.`}
                    commands={() => (
                        <SimpleCommandContainer>
                            <PrimaryRedirectButton
                                url={path(ROUTE_P4A_ADMINISTER_USER_LENDERS_CREATE, { id: match.params.id })}
                            >
                                Create
                            </PrimaryRedirectButton>
                        </SimpleCommandContainer>
                    )}
                    context={{
                        uri: API_URL,
                    }}
                    query={gql`
                        query getAssociatedLenders(
                            $userId: ID!
                            $cursor: String
                            $first: Int!
                            $sorts: [SortInput]
                            $filters: [FilterInput]
                        ) {
                            userLenders(
                                userId: $userId
                                first: $first
                                after: $cursor
                                sorts: $sorts
                                filters: $filters
                            ) {
                                edges {
                                    node {
                                        id
                                        createdAt
                                        updatedAt
                                        lender {
                                            id
                                            name
                                        }
                                        user {
                                            id
                                            email
                                        }
                                    }
                                    cursor
                                }
                                pageInfo {
                                    hasNextPage
                                    endCursor
                                    startCursor
                                    hasPreviousPage
                                }
                            }
                        }
                    `}
                    extractResultDataFunction={({ userLenders = {} }) => {
                        if (!userLenders || !userLenders.edges) {
                            return [];
                        }
                        return [...userLenders.edges];
                    }}
                    extractPaginationDataFunction={({ userLenders = {} }) => {
                        if (!userLenders || !userLenders.edges) {
                            return {};
                        }

                        return {
                            pageInfo: { ...userLenders.pageInfo },
                            totalCount: userLenders.totalCount,
                        };
                    }}
                    renderHeaderFunction={this.renderHeader}
                    renderRowFunction={this.renderRow}
                    filters={
                        <FiltersContainer>
                            <Filter
                                id={`lenders-filter`}
                                label={`Lender Name`}
                                filterValues={lenderFilter}
                                onFilterChange={onFilterChange(NAMESPACE, dispatch, filters, match)}
                            />
                        </FiltersContainer>
                    }
                    sorts={
                        <SortsContainer>
                            <Sort
                                column={sort.column}
                                direction={SORT_DESC}
                                onChange={onSortChange(NAMESPACE, dispatch, match)}
                                onClear={onSortClear(NAMESPACE, dispatch, match)}
                                options={[
                                    { value: 'lendername', label: 'Lender Name' },
                                    { value: 'createdAt', label: 'Created' },
                                ]}
                            />
                        </SortsContainer>
                    }
                    variables={{
                        sorts,
                        filters,
                        first,
                        pages,
                        cursor,
                        userId: match.params.id,
                    }}
                />
            </div>
        );
    }

    renderRow = ({ node, cursor }, refetch) => {
        let { match } = this.props;

        return (
            <TableRow key={node['id']}>
                <TableCell>{node.user.email}</TableCell>
                <TableCell>{node.lender.name}</TableCell>
                <TableCell>
                    <DateFormatter value={node.createdAt} />
                </TableCell>
                <TableRowControls xs={5}>
                    <RollupCommandContainer>
                        <DeleteButton outerRefetch={refetch} userId={match.params.id} lenderId={node.lender.id}>
                            Delete
                        </DeleteButton>
                        <PrimaryRedirectButton
                            url={path(ROUTE_P4A_ADMINISTER_USER_LENDERTEAMS_LIST, { id: match.params.id })}
                        >
                            Teams
                        </PrimaryRedirectButton>
                    </RollupCommandContainer>
                </TableRowControls>
            </TableRow>
        );
    };

    renderHeader = () => (
        <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Created At</TableCell>
            <TableCell />
        </TableRow>
    );
}

TableStyleContainer.propTypes = {
    sorts: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    pages: PropTypes.array.isRequired,
    cursor: PropTypes.any,
    first: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    return {
        sorts: selectorGetSorts(NAMESPACE)(state),
        pages: selectorGetPages(NAMESPACE)(state),
        cursor: selectorGetCurrentCursor(NAMESPACE)(state),
        filters: selectorGetFilters(NAMESPACE)(state),
        first: selectorGetPerPage(NAMESPACE)(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(TableStyleContainer);
