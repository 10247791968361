import BorrowerQuestions, {
    QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
    QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
    QUESTION_APPLICANT_MAIN_OCCUPATION,
    QUESTION_BUILD_COSTS_BORROWING,
    QUESTION_BUSINESS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_INVOICE_FINANCE_TYPE,
    QUESTION_BUSINESS_IS_UK_REGISTERED,
    QUESTION_BUSINESS_NAME,
    QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY,
    QUESTION_BUSINESS_OUTSTANDING_DEBT,
    QUESTION_BUSINESS_OUTSTANDING_INVOICES,
    QUESTION_BUSINESS_OVER_DRAFT_FACILITY,
    QUESTION_BUSINESS_PDQ_INCOME,
    QUESTION_BUSINESS_PROFITABLE,
    QUESTION_BUSINESS_REFINANCE,
    QUESTION_BUSINESS_SEASONAL,
    QUESTION_BUSINESS_SECTOR,
    QUESTION_BUSINESS_TRADING_LENGTH,
    QUESTION_BUSINESS_TYPE,
    QUESTION_BUY_TO_LET_EXPERIENCE,
    QUESTION_COMMERCIAL_OWNER_OCCUPIED,
    QUESTION_COMMERCIAL_TENANT_AMOUNT,
    QUESTION_COMMERCIAL_TYPES,
    QUESTION_COMMERCIAL_UNIT_COUNT,
    QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN,
    QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
    QUESTION_CURRENT_VALUE,
    QUESTION_DAY_ONE_LOAN_AMOUNT,
    QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE,
    QUESTION_DEVELOPMENT_LENGTH,
    QUESTION_EPC_RATING,
    QUESTION_EXPECTED_END_VALUE,
    QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE,
    QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
    QUESTION_FIRST_CHARGE_AMOUNT,
    QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE,
    QUESTION_HMO_BEDROOMS,
    QUESTION_HMO_HAS_LICENSE,
    QUESTION_HMO_LICENSE_IS_REQUIRED,
    QUESTION_HMO_ROOMS_SELF_CONTAINED,
    QUESTION_LENDER,
    QUESTION_LOAN_AMOUNT_REFURBISHMENT,
    QUESTION_LOAN_NEEDED_BY,
    QUESTION_LOAN_PURPOSE,
    QUESTION_LOAN_TYPE,
    QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY,
    QUESTION_OWN_ANY_OTHER_PROPERTY,
    QUESTION_PERCENTAGE_COMMERCIAL_VALUE,
    QUESTION_PERCENTAGE_RESIDENTIAL,
    QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
    QUESTION_PERCENTAGE_RESIDENTIAL_VALUE,
    QUESTION_PLANNING_IN_PLACE,
    QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
    QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE,
    QUESTION_PROPERTY_ADDRESS,
    QUESTION_PROPERTY_LOCATION,
    QUESTION_PROPERTY_LOCATION_BUSINESS,
    QUESTION_PROPERTY_OWNERSHIP,
    QUESTION_PROPERTY_POSTCODE,
    QUESTION_PROPERTY_TYPE,
    QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
    QUESTION_REFURBISHMENT_LENGTH,
    QUESTION_RENTAL_INCOME,
    QUESTION_RENTAL_INCOME_RESIDENTIAL,
    QUESTION_RESIDENTIAL_REFINANCE,
    QUESTION_RESIDENTIAL_UNIT_COUNT,
    QUESTION_RESIDENTIAL_UNIT_VALUE,
    QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
    QUESTION_SITE_DESCRIPTION,
    QUESTION_SITE_INTENTIONS,
    QUESTION_SITE_INTENTIONS_MEZZANINE,
    QUESTION_SITE_INTENTIONS_REFURBISHMENT,
    QUESTION_TEAM_DEVELOPMENT_EXPERIENCE,
    QUESTION_TOTAL_DEVELOPMENT_COSTS,
    QUESTION_TURN_OVER,
    QUESTION_UNITS_PRE_SOLD,
    QUESTION_WORKS_EXPLANATION,
} from '../Borrower/BorrowerQuestions';
import {
    QUESTION_LOAN_AMOUNT,
    QUESTION_LOAN_TERM,
    QUESTION_OCCUPY_PROPERTY,
    QUESTION_PORTFOLIO_PROPERTIES,
    QUESTION_PORTFOLIO_VALUE,
    QUESTION_RENTAL_INCOME_PORTFOLIO,
} from '../Broker/BrokerQuestions';
import { BaseEnum } from '../../../../pitch4_enum';
export const ALL_COMMERCIAL_TYPES = 'ALL';
export const COMMERCIAL_TYPES = 'COMMERCIAL_TYPES';
export const SEMI_COMMERCIAL_TYPES = 'SEMI_COMMERCIAL_TYPES';
export const RESIDENTIAL_TYPES = 'RESIDENTIAL_TYPES';
export const HMO_TYPES = 'HMO_TYPES';
export const RESIDENTIAL_PORTFOLIO_TYPES = 'RESIDENTIAL_PORTFOLIO_TYPES';
export const COMMERCIAL_PORTFOLIO_TYPES = 'COMMERCIAL_PORTFOLIO_TYPES';
export const MIXED_PORTFOLIO_TYPES = 'MIXED_PORTFOLIO_TYPES';
export const LAND_WITH_PLANNING_TYPES = 'LAND_WITH_PLANNING_TYPES';
export const MIXED_FUNDING_TYPE = 'MIXED_FUNDING_TYPE';
export const TERM_LONG_TYPES = 'TERM_LONG_TYPES';
export const TERM_SHORT_TYPES = 'TERM_SHORT_TYPES';
export const R_C_F_TYPES = 'R_C_F_TYPES';
export const INVOICE_TYPES = 'INVOICE_TYPES';
export const ASSET_TYPES = 'ASSET_TYPES';
export const MERCHANT_CASH_ADVANCE_TYPES = 'MERCHANT_CASH_ADVANCE_TYPES';
export const SUPPLY_CHAIN_TYPES = 'SUPPLY_CHAIN_TYPES';

/**
 * A brief explanation for this insanity.
 * This allows us to change validation per funding type, because it's insane and may be required.
 */
class Validation extends BaseEnum {
    getTypes = () => {
        const questions = new BorrowerQuestions().getTypes();
        return {
            applicant: {
                ALL: [
                    {
                        validation: 'applicants_error.main',
                        form: 'applicants.main',
                        message: 'Please select the main applicant',
                    },
                ],
            },
            'loan-type': {
                ALL: [
                    {
                        validation: questions[QUESTION_LOAN_TYPE].fieldName + '_error',
                        form: questions[QUESTION_LOAN_TYPE].fieldName,
                        message: 'Please select an option',
                    },
                ],
            },
            'commercial-type': {
                ALL: [
                    {
                        validation: questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName + '_error',
                        form: questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_PROPERTY_TYPE].fieldName + '_error',
                        form: questions[QUESTION_PROPERTY_TYPE].fieldName,
                        message: 'Please select an option',
                    },
                ],
            },
            'property-information': {
                // Should fall through so anything not "PORTFOLIO" will hit ALL
                ALL: [
                    {
                        validation: questions[QUESTION_PROPERTY_OWNERSHIP].fieldName + '_error',
                        form: questions[QUESTION_PROPERTY_OWNERSHIP].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_OWN_ANY_OTHER_PROPERTY].fieldName + '_error',
                        form: questions[QUESTION_OWN_ANY_OTHER_PROPERTY].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_PROPERTY_POSTCODE].fieldName + '_error',
                        form: questions[QUESTION_PROPERTY_POSTCODE].fieldName,
                        message: 'Please enter a valid postcode',
                    },
                    {
                        validation: questions[QUESTION_CURRENT_VALUE].fieldName + '_error',
                        form: questions[QUESTION_CURRENT_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PROPERTY_LOCATION].fieldName + '_error',
                        form: questions[QUESTION_PROPERTY_LOCATION].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_PROPERTY_ADDRESS].fieldName + '_error',
                        form: questions[QUESTION_PROPERTY_ADDRESS].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                RESIDENTIAL_PORTFOLIO_TYPES: [
                    {
                        validation: questions[QUESTION_PROPERTY_OWNERSHIP].fieldName + '_error',
                        form: questions[QUESTION_PROPERTY_OWNERSHIP].fieldName,
                        message: 'Please select an option',
                    },
                ],
                COMMERCIAL_PORTFOLIO_TYPES: [
                    {
                        validation: questions[QUESTION_PROPERTY_OWNERSHIP].fieldName + '_error',
                        form: questions[QUESTION_PROPERTY_OWNERSHIP].fieldName,
                        message: 'Please select an option',
                    },
                ],
                MIXED_PORTFOLIO_TYPES: [
                    {
                        validation: questions[QUESTION_PROPERTY_OWNERSHIP].fieldName + '_error',
                        form: questions[QUESTION_PROPERTY_OWNERSHIP].fieldName,
                        message: 'Please select an option',
                    },
                ],
            },
            'portfolio-selection': {
                ALL: [
                    {
                        validation: questions[QUESTION_PORTFOLIO_PROPERTIES].fieldName + '_error',
                        form: questions[QUESTION_PORTFOLIO_PROPERTIES].fieldName,
                        message: 'Please enter the number of properties in your portfolio',
                    },
                    {
                        validation: questions[QUESTION_PORTFOLIO_VALUE].fieldName + '_error',
                        form: questions[QUESTION_PORTFOLIO_VALUE].fieldName,
                        message: 'Please enter the value of your portfolio',
                    },
                    {
                        validation: questions[QUESTION_RENTAL_INCOME_PORTFOLIO].fieldName + '_error',
                        form: questions[QUESTION_RENTAL_INCOME_PORTFOLIO].fieldName,
                        message: 'Please enter the rental income from your whole portfolio',
                    },
                    {
                        validation: questions[QUESTION_LOAN_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_LOAN_AMOUNT].fieldName,
                        message: 'Please enter the amount you wish to borrow, it must be greater than £10,000',
                    },
                    {
                        validation: questions[QUESTION_LOAN_TERM].fieldName + '_error',
                        form: questions[QUESTION_LOAN_TERM].fieldName,
                        message: 'Please enter the loan term you are looking for',
                    },
                    {
                        validation: questions[QUESTION_LOAN_NEEDED_BY].fieldName + '_error',
                        form: questions[QUESTION_LOAN_NEEDED_BY].fieldName,
                        message: 'Please enter the loan term you are looking for',
                    },
                ],
            },
            'property-selection': {
                ALL: [
                    {
                        validation: questions[QUESTION_OCCUPY_PROPERTY].fieldName + '_error',
                        form: questions[QUESTION_OCCUPY_PROPERTY].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY].fieldName + '_error',
                        form: questions[QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_LOAN_TERM].fieldName + '_error',
                        form: questions[QUESTION_LOAN_TERM].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LOAN_NEEDED_BY].fieldName + '_error',
                        form: questions[QUESTION_LOAN_NEEDED_BY].fieldName,
                        message: 'Please select an option',
                    },
                ],
            },
            'term-selection': {
                COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY].fieldName + '_error',
                        form: questions[QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY].fieldName,
                        message: 'Please select an option',
                    },
                ],
                SEMI_COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TENANT_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TENANT_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY].fieldName + '_error',
                        form: questions[QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_RESIDENTIAL_UNIT_VALUE].fieldName + '_error',
                        form: questions[QUESTION_RESIDENTIAL_UNIT_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_RESIDENTIAL_UNIT_COUNT].fieldName + '_error',
                        form: questions[QUESTION_RESIDENTIAL_UNIT_COUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].fieldName + '_error',
                        form: questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName + '_error',
                        form: questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PERCENTAGE_COMMERCIAL_VALUE].fieldName + '_error',
                        form: questions[QUESTION_PERCENTAGE_COMMERCIAL_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_RENTAL_INCOME_RESIDENTIAL].fieldName + '_error',
                        form: questions[QUESTION_RENTAL_INCOME_RESIDENTIAL].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                RESIDENTIAL_TYPES: [
                    {
                        validation: questions[QUESTION_RENTAL_INCOME_RESIDENTIAL].fieldName + '_error',
                        form: questions[QUESTION_RENTAL_INCOME_RESIDENTIAL].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_RESIDENTIAL_UNIT_COUNT].fieldName + '_error',
                        form: questions[QUESTION_RESIDENTIAL_UNIT_COUNT].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                HMO_TYPES: [
                    {
                        validation: questions[QUESTION_RENTAL_INCOME_RESIDENTIAL].fieldName + '_error',
                        form: questions[QUESTION_RENTAL_INCOME_RESIDENTIAL].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_HMO_BEDROOMS].fieldName + '_error',
                        form: questions[QUESTION_HMO_BEDROOMS].fieldName,
                        message: 'Please select an option',
                    },
                ],
            },
            'bridge-selection': {
                HMO_TYPES: [
                    {
                        validation: questions[QUESTION_HMO_BEDROOMS].fieldName + '_error',
                        form: questions[QUESTION_HMO_BEDROOMS].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_HMO_ROOMS_SELF_CONTAINED].fieldName + '_error',
                        form: questions[QUESTION_HMO_ROOMS_SELF_CONTAINED].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_BUY_TO_LET_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_BUY_TO_LET_EXPERIENCE].fieldName,
                        message: 'Please select an option',
                    },
                ],
                COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_BUY_TO_LET_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_BUY_TO_LET_EXPERIENCE].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please select an option',
                    },
                ],
                SEMI_COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_BUY_TO_LET_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_BUY_TO_LET_EXPERIENCE].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_RESIDENTIAL_UNIT_COUNT].fieldName + '_error',
                        form: questions[QUESTION_RESIDENTIAL_UNIT_COUNT].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                LAND_WITH_PLANNING_TYPES: [
                    {
                        validation: questions[QUESTION_SITE_DESCRIPTION].fieldName + '_error',
                        form: questions[QUESTION_SITE_DESCRIPTION].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_SITE_INTENTIONS].fieldName + '_error',
                        form: questions[QUESTION_SITE_INTENTIONS].fieldName,
                        message: 'Please select an option',
                    },
                ],
            },
            'refurbishment-selection': {
                RESIDENTIAL_TYPES: [
                    {
                        validation: questions[QUESTION_SITE_DESCRIPTION].fieldName + '_error',
                        form: questions[QUESTION_SITE_DESCRIPTION].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].fieldName + '_error',
                        form: questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_REFURBISHMENT_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_REFURBISHMENT_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].fieldName + '_error',
                        form: questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName + '_error',
                        form: questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EXPECTED_END_VALUE].fieldName + '_error',
                        form: questions[QUESTION_EXPECTED_END_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].fieldName,
                        message: 'Please select an option',
                    },
                ],
                COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_SITE_DESCRIPTION].fieldName + '_error',
                        form: questions[QUESTION_SITE_DESCRIPTION].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].fieldName + '_error',
                        form: questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_REFURBISHMENT_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_REFURBISHMENT_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].fieldName + '_error',
                        form: questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName + '_error',
                        form: questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EXPECTED_END_VALUE].fieldName + '_error',
                        form: questions[QUESTION_EXPECTED_END_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                SEMI_COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_SITE_DESCRIPTION].fieldName + '_error',
                        form: questions[QUESTION_SITE_DESCRIPTION].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].fieldName + '_error',
                        form: questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_REFURBISHMENT_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_REFURBISHMENT_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].fieldName + '_error',
                        form: questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName + '_error',
                        form: questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EXPECTED_END_VALUE].fieldName + '_error',
                        form: questions[QUESTION_EXPECTED_END_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].fieldName + '_error',
                        form: questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName + '_error',
                        form: questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                HMO_TYPES: [
                    {
                        validation: questions[QUESTION_SITE_DESCRIPTION].fieldName + '_error',
                        form: questions[QUESTION_SITE_DESCRIPTION].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].fieldName + '_error',
                        form: questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_REFURBISHMENT_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_REFURBISHMENT_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].fieldName + '_error',
                        form: questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName + '_error',
                        form: questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EXPECTED_END_VALUE].fieldName + '_error',
                        form: questions[QUESTION_EXPECTED_END_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_HMO_BEDROOMS].fieldName + '_error',
                        form: questions[QUESTION_HMO_BEDROOMS].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_HMO_HAS_LICENSE].fieldName + '_error',
                        form: questions[QUESTION_HMO_HAS_LICENSE].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_HMO_LICENSE_IS_REQUIRED].fieldName + '_error',
                        form: questions[QUESTION_HMO_LICENSE_IS_REQUIRED].fieldName,
                        message: 'Please select an option',
                    },
                ],
            },
            'second-charge-selection': {
                RESIDENTIAL_TYPES: [
                    {
                        validation: questions[QUESTION_LENDER].fieldName + '_error',
                        form: questions[QUESTION_LENDER].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_RENTAL_INCOME].fieldName + '_error',
                        form: questions[QUESTION_RENTAL_INCOME].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_LENDER].fieldName + '_error',
                        form: questions[QUESTION_LENDER].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE].fieldName + '_error',
                        form: questions[QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE].fieldName,
                        message: 'Please enter a first charge amount',
                    },
                ],
                SEMI_COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_LENDER].fieldName + '_error',
                        form: questions[QUESTION_LENDER].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName + '_error',
                        form: questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].fieldName + '_error',
                        form: questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please select an option',
                    },
                    {
                        validation: questions[QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE].fieldName + '_error',
                        form: questions[QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE].fieldName,
                        message: 'Please enter a first charge amount',
                    },
                ],
            },
            'development-selection': {
                ALL: [
                    {
                        validation: questions[QUESTION_PLANNING_IN_PLACE].fieldName + '_error',
                        form: questions[QUESTION_PLANNING_IN_PLACE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_WORKS_EXPLANATION].fieldName + '_error',
                        form: questions[QUESTION_WORKS_EXPLANATION].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName + '_error',
                        form: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUILD_COSTS_BORROWING].fieldName + '_error',
                        form: questions[QUESTION_BUILD_COSTS_BORROWING].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE].fieldName + '_error',
                        form: questions[QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EPC_RATING].fieldName + '_error',
                        form: questions[QUESTION_EPC_RATING].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TEAM_DEVELOPMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_TEAM_DEVELOPMENT_EXPERIENCE].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_PLANNING_IN_PLACE].fieldName + '_error',
                        form: questions[QUESTION_PLANNING_IN_PLACE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_WORKS_EXPLANATION].fieldName + '_error',
                        form: questions[QUESTION_WORKS_EXPLANATION].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName + '_error',
                        form: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUILD_COSTS_BORROWING].fieldName + '_error',
                        form: questions[QUESTION_BUILD_COSTS_BORROWING].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE].fieldName + '_error',
                        form: questions[QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EPC_RATING].fieldName + '_error',
                        form: questions[QUESTION_EPC_RATING].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TEAM_DEVELOPMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_TEAM_DEVELOPMENT_EXPERIENCE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_UNITS_PRE_SOLD].fieldName + '_error',
                        form: questions[QUESTION_UNITS_PRE_SOLD].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please select a value',
                    },
                ],
                SEMI_COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_PLANNING_IN_PLACE].fieldName + '_error',
                        form: questions[QUESTION_PLANNING_IN_PLACE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_WORKS_EXPLANATION].fieldName + '_error',
                        form: questions[QUESTION_WORKS_EXPLANATION].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName + '_error',
                        form: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUILD_COSTS_BORROWING].fieldName + '_error',
                        form: questions[QUESTION_BUILD_COSTS_BORROWING].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE].fieldName + '_error',
                        form: questions[QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EPC_RATING].fieldName + '_error',
                        form: questions[QUESTION_EPC_RATING].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TEAM_DEVELOPMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_TEAM_DEVELOPMENT_EXPERIENCE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_UNITS_PRE_SOLD].fieldName + '_error',
                        form: questions[QUESTION_UNITS_PRE_SOLD].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName + '_error',
                        form: questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].fieldName + '_error',
                        form: questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].fieldName,
                        message: 'Please select a value',
                    },
                ],
            },
            'mezzanine-selection': {
                ALL: [
                    {
                        validation: questions[QUESTION_PLANNING_IN_PLACE].fieldName + '_error',
                        form: questions[QUESTION_PLANNING_IN_PLACE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_FIRST_CHARGE_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_FIRST_CHARGE_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LENDER].fieldName + '_error',
                        form: questions[QUESTION_LENDER].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_SITE_INTENTIONS_MEZZANINE].fieldName + '_error',
                        form: questions[QUESTION_SITE_INTENTIONS_MEZZANINE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].fieldName + '_error',
                        form: questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName + '_error',
                        form: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EXPECTED_END_VALUE].fieldName + '_error',
                        form: questions[QUESTION_EXPECTED_END_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_PLANNING_IN_PLACE].fieldName + '_error',
                        form: questions[QUESTION_PLANNING_IN_PLACE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_FIRST_CHARGE_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_FIRST_CHARGE_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LENDER].fieldName + '_error',
                        form: questions[QUESTION_LENDER].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_SITE_INTENTIONS_MEZZANINE].fieldName + '_error',
                        form: questions[QUESTION_SITE_INTENTIONS_MEZZANINE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].fieldName + '_error',
                        form: questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName + '_error',
                        form: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EXPECTED_END_VALUE].fieldName + '_error',
                        form: questions[QUESTION_EXPECTED_END_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                SEMI_COMMERCIAL_TYPES: [
                    {
                        validation: questions[QUESTION_PLANNING_IN_PLACE].fieldName + '_error',
                        form: questions[QUESTION_PLANNING_IN_PLACE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_FIRST_CHARGE_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_FIRST_CHARGE_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LENDER].fieldName + '_error',
                        form: questions[QUESTION_LENDER].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_SITE_INTENTIONS_MEZZANINE].fieldName + '_error',
                        form: questions[QUESTION_SITE_INTENTIONS_MEZZANINE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].fieldName + '_error',
                        form: questions[QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName + '_error',
                        form: questions[QUESTION_TOTAL_DEVELOPMENT_COSTS].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_EXPECTED_END_VALUE].fieldName + '_error',
                        form: questions[QUESTION_EXPECTED_END_VALUE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_DEVELOPMENT_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName + '_error',
                        form: questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PERCENTAGE_RESIDENTIAL].fieldName + '_error',
                        form: questions[QUESTION_PERCENTAGE_RESIDENTIAL].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error',
                        form: questions[QUESTION_COMMERCIAL_TYPES].fieldName,
                        message: 'Please enter a value',
                    },
                ],
            },
            'business-details': {
                ALL: [
                    {
                        validation: questions[QUESTION_LOAN_PURPOSE].fieldName + '_error',
                        form: questions[QUESTION_LOAN_PURPOSE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_SEASONAL].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_SEASONAL].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_TYPE].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_TYPE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_NAME].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_NAME].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_TRADING_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_TRADING_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_SECTOR].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_SECTOR].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PROPERTY_LOCATION_BUSINESS].fieldName + '_error',
                        form: questions[QUESTION_PROPERTY_LOCATION_BUSINESS].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_IS_UK_REGISTERED].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_IS_UK_REGISTERED].fieldName,
                        message: 'Please select a value',
                    },
                ],
                INVOICE_TYPES: [
                    {
                        validation: questions[QUESTION_BUSINESS_REFINANCE].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_REFINANCE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LOAN_PURPOSE].fieldName + '_error',
                        form: questions[QUESTION_LOAN_PURPOSE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_SEASONAL].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_SEASONAL].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_TYPE].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_TYPE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_NAME].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_NAME].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_TRADING_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_TRADING_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_SECTOR].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_SECTOR].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_OUTSTANDING_INVOICES].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_OUTSTANDING_INVOICES].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_INVOICE_FINANCE_TYPE].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_INVOICE_FINANCE_TYPE].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_PROPERTY_LOCATION_BUSINESS].fieldName + '_error',
                        form: questions[QUESTION_PROPERTY_LOCATION_BUSINESS].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_IS_UK_REGISTERED].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_IS_UK_REGISTERED].fieldName,
                        message: 'Please select a value',
                    },
                ],
                MERCHANT_CASH_ADVANCE_TYPES: [
                    {
                        validation: questions[QUESTION_BUSINESS_REFINANCE].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_REFINANCE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LOAN_PURPOSE].fieldName + '_error',
                        form: questions[QUESTION_LOAN_PURPOSE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_SEASONAL].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_SEASONAL].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_TYPE].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_TYPE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_NAME].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_NAME].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_TRADING_LENGTH].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_TRADING_LENGTH].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_SECTOR].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_SECTOR].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_PDQ_INCOME].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_PDQ_INCOME].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_IS_UK_REGISTERED].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_IS_UK_REGISTERED].fieldName,
                        message: 'Please select a value',
                    },
                ],
            },
            'business-financials': {
                ALL: [
                    {
                        validation: questions[QUESTION_LOAN_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_LOAN_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TURN_OVER].fieldName + '_error',
                        form: questions[QUESTION_TURN_OVER].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_PROFITABLE].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_PROFITABLE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_OVER_DRAFT_FACILITY].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_OVER_DRAFT_FACILITY].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_OUTSTANDING_DEBT].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_OUTSTANDING_DEBT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_ADVERSE_CREDIT].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_ADVERSE_CREDIT].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS].fieldName + '_error',
                        form: questions[QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_LOAN_TERM].fieldName + '_error',
                        form: questions[QUESTION_LOAN_TERM].fieldName,
                        message: 'Please enter a value',
                    },
                ],
                MERCHANT_CASH_ADVANCE_TYPES: [
                    {
                        validation: questions[QUESTION_LOAN_AMOUNT].fieldName + '_error',
                        form: questions[QUESTION_LOAN_AMOUNT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_LOAN_PURPOSE].fieldName + '_error',
                        form: questions[QUESTION_LOAN_PURPOSE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_TURN_OVER].fieldName + '_error',
                        form: questions[QUESTION_TURN_OVER].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_PROFITABLE].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_PROFITABLE].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_OVER_DRAFT_FACILITY].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_OVER_DRAFT_FACILITY].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_OUTSTANDING_DEBT].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_OUTSTANDING_DEBT].fieldName,
                        message: 'Please enter a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_ADVERSE_CREDIT].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_ADVERSE_CREDIT].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].fieldName + '_error',
                        form: questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS].fieldName + '_error',
                        form: questions[QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS].fieldName,
                        message: 'Please select a value',
                    },
                ],
            },
            credit: {
                ALL: [
                    {
                        validation: questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES].fieldName + '_error',
                        form: questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName + '_error',
                        form: questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_APPLICANT_MAIN_OCCUPATION].fieldName + '_error',
                        form: questions[QUESTION_APPLICANT_MAIN_OCCUPATION].fieldName,
                        message: 'Please select a value',
                    },
                    {
                        validation: questions[QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME].fieldName + '_error',
                        form: questions[QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME].fieldName,
                        message: 'Please select a value',
                    },
                ],
            },
        };
    };
}

export default Validation;
