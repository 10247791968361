import React from 'react';
import PlaceholderImageLayout from '../content/PlaceholderImageLayout';
import PropTypes from 'prop-types';

class ChatPlaceholder extends React.Component {
    render() {
        const { children, disabled } = this.props;
        const disabledClassName = disabled ? 'chat-placeholder-disabled' : '';

        return (
            <PlaceholderImageLayout classNames={`chat-placeholder ${disabledClassName}`} {...this.props}>
                {children}
            </PlaceholderImageLayout>
        );
    }
}

ChatPlaceholder.defaultProps = {
    disabled: false,
};

ChatPlaceholder.propTypes = {
    disabled: PropTypes.bool,
};

export default ChatPlaceholder;
