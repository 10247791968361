import React from 'react';
import { Button } from '@material-ui/core';

export default function ChatToLender(props) {
    const { handleTabChange, chatTabIndex, chatChannel } = props;
    const handleClick = () => handleTabChange(null, chatTabIndex);

    return (
        <div className={'request-lender-container'}>
            <div className={'header'}>Find out more about this lender?</div>
            <Button className={'btn'} onClick={handleClick} disabled={null === chatChannel}>
                CHAT TO LENDER
            </Button>
        </div>
    );
}
