import React from 'react';
import { branch, renderComponent } from 'recompose';
import styled from 'styled-components';

import { CenteringContainer } from 'pitchblack_react_utils/layout/index';
import { checkSessionIsAuthorised } from './security';

const AuthFailContainer = styled(CenteringContainer)`
    padding: 20px;
`;

class AuthenticationFailedComponent extends React.Component {
    render() {
        return (
            <AuthFailContainer>
                <div>Sorry you do not have sufficient privileges to view this component.</div>
            </AuthFailContainer>
        );
    }
}

//function
export default (requiredRoles) => {
    return branch((props) => !checkSessionIsAuthorised(requiredRoles), renderComponent(AuthenticationFailedComponent));
};
