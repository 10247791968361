import React from 'react';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
    isEmail,
    isRequired,
    hasMinLength2,
    hasMaxLength255,
    isValidPostcode,
    hasMinLength6,
    hasMaxLength30,
    hasMaxLength1500,
    isUrlValid,
    hasMaxLength7,
    hasMinLength7,
} from '../../../../../pitchblack_react_utils/form/validation';
import { PrimaryButton, SecondaryButton } from '../../../../../pitch4_layout/components/buttons';
import { ServerErrors } from '../../../../../pitch4_forms/components';
import { TextField, TextLabel, FieldRow, Checkbox, TextArea } from '../../../../../pitch4_forms/components/inputs';
import { Container, RightAligned, RowItem } from '../../../../../pitch4_layout/components/div';
import { TextInput } from '../../../../../pitchblack_react_utils/form';
import LenderDrawer from './LenderDrawer';

const SpacerItem = styled(RowItem)``;

class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lenderDrawerOpen: false,
        };
    }

    toggleLenderDrawer = (lenderDrawerOpen) => this.setState({ lenderDrawerOpen: lenderDrawerOpen });

    render() {
        let { onSubmit, submitting, handleSubmit, error = [], reset, initialValues } = this.props;

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container>
                    <RowItem>
                        <ServerErrors errors={error} visible={true} />
                    </RowItem>
                    <FieldRow
                        label={<TextLabel label="ID" />}
                        input={<TextField hidden={true} name="id" placeholder="ID" disabled={true} />}
                    />
                    <FieldRow
                        label={<TextLabel label="Created at" />}
                        input={<TextField hidden={true} name="createdAt" placeholder="Created at" disabled={true} />}
                    />
                    <FieldRow
                        label={<TextLabel label="Updated at" />}
                        input={<TextField hidden={true} name="updatedAt" placeholder="Updated at" disabled={true} />}
                    />

                    <FieldRow
                        label={<TextLabel label="Company Email Address" />}
                        input={
                            <TextField
                                name="primaryEmail"
                                placeholder="Company Email Address"
                                disabled={submitting}
                                validate={[isRequired, isEmail]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Company name" />}
                        input={
                            <TextField
                                name="name"
                                placeholder="Company name"
                                disabled={submitting}
                                validate={[isRequired, hasMinLength2]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="FCA Number" />}
                        input={
                            <TextField
                                name="fcaIdentifier"
                                placeholder="FCA Number"
                                disabled={submitting}
                                validate={[hasMinLength2]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Company Number" />}
                        input={
                            <TextField
                                name="companyNumber"
                                placeholder="Company Number"
                                disabled={submitting}
                                validate={[hasMinLength2]}
                            />
                        }
                    />

                    <FieldRow
                        label={<TextLabel label="Address 1" />}
                        input={
                            <TextField
                                name="primaryAddressAddress1"
                                placeholder="Address 2"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 2" />}
                        input={
                            <TextField
                                name="primaryAddressAddress2"
                                placeholder="Address 2"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 3" />}
                        input={
                            <TextField
                                name="primaryAddressAddress3"
                                placeholder="Address 3"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 4" />}
                        input={
                            <TextField
                                name="primaryAddressAddress4"
                                placeholder="Address 4"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Country" />}
                        input={
                            <TextField
                                name="primaryAddressCountry"
                                placeholder="Country"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Postcode" />}
                        input={
                            <TextField
                                name="primaryAddressPostcode"
                                placeholder="Postcode"
                                disabled={submitting}
                                validate={[isValidPostcode]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Phone" />}
                        input={
                            <TextField
                                name="primaryPhoneNumber"
                                placeholder="Number"
                                disabled={submitting}
                                validate={[hasMinLength6, hasMaxLength30]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Description" />}
                        input={
                            <TextArea
                                name="profileDescription"
                                placeholder="Description"
                                disabled={submitting}
                                validate={[hasMinLength6, hasMaxLength1500]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Website URL" />}
                        input={
                            <TextInput
                                name="profileUrl"
                                placeholder="https://example.com/"
                                disabled={submitting}
                                validate={[hasMaxLength255, isUrlValid]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Primary color" />}
                        input={
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div
                                    style={{
                                        width: 30,
                                        height: 30,
                                        marginRight: 20,
                                        backgroundColor: initialValues.profilePrimaryColor || '#ec407a',
                                    }}
                                />
                                <TextInput
                                    name="profilePrimaryColor"
                                    placeholder="#ff0000"
                                    disabled={submitting}
                                    validate={[hasMinLength7, hasMaxLength7]}
                                />
                            </div>
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Enable Profile" />}
                        input={<Checkbox name="profileEnabled" disabled={submitting} />}
                    />
                    <SpacerItem />
                    <RightAligned className={'button-panel create-reset-buttons'}>
                        <SecondaryButton disabled={submitting} onClick={reset}>
                            Reset
                        </SecondaryButton>
                        <PrimaryButton type="submit" disabled={submitting}>
                            Submit
                        </PrimaryButton>
                        <PrimaryButton onClick={() => this.toggleLenderDrawer(true)}>Preview</PrimaryButton>
                    </RightAligned>
                </Container>
                {this.state.lenderDrawerOpen && (
                    <LenderDrawer
                        open={this.state.lenderDrawerOpen}
                        anchor={'right'}
                        lenderId={initialValues.id}
                        toggleLenderDrawer={this.toggleLenderDrawer}
                    />
                )}
            </form>
        );
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    reset: PropTypes.func,
    initialValues: PropTypes.any,
};

export default compose(
    reduxForm({
        form: 'administer_lenders_editlender_form',
        enableReinitialize: true,
    })
)(Form);
