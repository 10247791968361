import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import CountIcon from 'pitch4_layout/components/icons/CountIcon';
import { Container, Item, P4StyleComponentContainer, RowItem } from 'pitch4_layout/components/div';
import { isRequired } from 'pitchblack_react_utils/form/validation';
import { TextField } from 'pitch4_forms/components/inputs';
import { getFormValues, reduxForm } from 'redux-form';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import Link from 'pitch4_layout/components/href/Link';
import { Body1, PrimaryColour } from 'pitch4_layout/components/text';
import { Loading } from 'pitch4_layout/components/waiting';
import SessionManager from 'pitchblack_react_utils/session/SessionManager';
import InfoIcon from '@material-ui/icons/Info';

const FORM_NAME = 'enable-two-factor-authentication';

const StyledP4Container = styled(P4StyleComponentContainer)`
    margin-bottom: 40px;
`;

const Heading = styled.h5``;

const SubHeading = styled.h6`
    position: relative;
    top: -5px;
    margin-left: 5px;
`;

const Para = styled(Body1)`
    display: block;
    width: 100%;
    margin: 20px 0;
`;

const Divider = styled.hr`
    margin: 20px 0;
    border: 1px solid #dddddd;
`;

const Errors = styled.ul`
    margin-top: 10px;
`;

const ErrorListItem = styled.li`
    margin-left: 35px;
    color: #f44336;
    line-height: 1.5em;
    list-style-type: none;
`;

const AppList = styled.ul`
    margin-top: 10px;
`;

const AppListItem = styled.li`
    margin-left: 52px;
    line-height: 1.5em;
`;

const Notice = styled.div`
    padding: 10px;
    border-radius: 5px;
    color: white;
    background-color: rgb(185, 21, 231);
`;

const NoticeIcon = styled(InfoIcon)`
    float: left;
    margin-right: 10px;
`;

const NoticeText = styled(Body1)``;

const ClearFix = styled.div`
    clear: both;
`;

/**
 * Enable 2FA using an authenticator app.
 */
class AppEnableForm extends React.Component {
    render() {
        const { handleSubmit, submitting, pristine, twoFactorAuthDetails = {} } = this.props;

        if (_.isEmpty(twoFactorAuthDetails)) {
            return <Loading />;
        }

        const qrCodeUri =
            'otpauth://totp/' +
            encodeURIComponent(twoFactorAuthDetails['user']['username']) +
            '?secret=' +
            twoFactorAuthDetails['key'] +
            '&issuer=Provide%20Finance';

        return (
            <StyledP4Container
                header={<Heading>Enable Two-Factor Authentication</Heading>}
                content={
                    <div style={{ padding: '10px 20px' }}>
                        {this.isTwoFactorAuthForced() && (
                            <Notice>
                                <NoticeIcon />
                                <NoticeText>
                                    Your site administrator has made Two Factor Authentication mandatory for your role.
                                    Until you enable 2FA you will not be able to perform any other actions.
                                </NoticeText>
                                <ClearFix />
                            </Notice>
                        )}

                        <Para>You can enable 2FA by following these simple steps:</Para>
                        <RowItem style={{ paddingTop: '1em' }}>
                            <CountIcon count={`1`} /> <SubHeading>Download an authenticator app</SubHeading>
                        </RowItem>
                        <RowItem>
                            <AppList>
                                <AppListItem>
                                    <Body1>
                                        iOS:{' '}
                                        {this.linkExt(
                                            'Authy for iOS',
                                            'https://itunes.apple.com/us/app/authy/id494168017'
                                        )}
                                    </Body1>
                                </AppListItem>
                                <AppListItem>
                                    <Body1>
                                        Android:{' '}
                                        {this.linkExt(
                                            'Authy for Android',
                                            'https://play.google.com/store/apps/details?id=com.authy.authy'
                                        )}
                                    </Body1>
                                </AppListItem>
                                <AppListItem>
                                    <Body1>
                                        Windows:{' '}
                                        {this.linkExt(
                                            'Microsoft Authenticator',
                                            'https://www.microsoft.com/en-us/store/apps/authenticator/9wzdncrfj3rj'
                                        )}
                                    </Body1>
                                </AppListItem>
                            </AppList>
                        </RowItem>
                        <Divider />

                        <RowItem style={{ paddingTop: '1em' }}>
                            <CountIcon count={`2`} />{' '}
                            <SubHeading>Use your authenticator app to scan the QR code below</SubHeading>
                        </RowItem>
                        <RowItem style={{ paddingTop: '1em' }}>
                            <Container>
                                <Item style={{ padding: '0 35px' }}>
                                    <QRCode level={`L`} size={256} renderAs={`svg`} value={qrCodeUri} />
                                </Item>
                                <Item>
                                    <Para>
                                        <strong>
                                            <u>Trouble scanning the code?</u>
                                        </strong>{' '}
                                        You can manually add the account using these details:
                                    </Para>
                                    <Para>
                                        <strong>Account: </strong>
                                        {twoFactorAuthDetails['user']['username']}
                                    </Para>
                                    <Para>
                                        <strong>Key: </strong>
                                        {twoFactorAuthDetails['key'].replace(/(.{4})/g, '$1 ').trim()}
                                    </Para>
                                    <Para>
                                        <strong>Type: </strong>Time-based
                                    </Para>
                                </Item>
                            </Container>
                        </RowItem>
                        <Divider />

                        <RowItem style={{ paddingTop: '1em' }}>
                            <CountIcon count={`3`} /> <SubHeading>Enter your verification code</SubHeading>
                        </RowItem>
                        <RowItem>
                            <form name={FORM_NAME} onSubmit={handleSubmit(this.onSubmit)}>
                                {this.getServerErrors(['code'])}
                                <TextField
                                    style={{ marginLeft: '35px', fontSize: '3em' }}
                                    fullWidth={false}
                                    name={`code`}
                                    label={`Verification code`}
                                    placeholder={`Verification code`}
                                    margin="normal"
                                    variant="outlined"
                                    validate={[isRequired]}
                                />
                                <RowItem style={{ marginLeft: '25px' }}>
                                    <PrimaryButton
                                        style={{ marginRight: 0, minWidth: '100px' }}
                                        type="submit"
                                        disabled={pristine || submitting}
                                    >
                                        Enable Two-Factor Authentication
                                    </PrimaryButton>
                                </RowItem>
                            </form>
                        </RowItem>
                    </div>
                }
            />
        );
    }

    getServerErrors = (fields) => {
        const { errors } = this.props;

        if (!_.isEmpty(errors)) {
            return (
                <RowItem>
                    <Errors>
                        {Object.keys(errors).map(function (fieldName) {
                            if (fields.indexOf(fieldName) !== -1 || fieldName === 'unknown') {
                                return <ErrorListItem>{errors[fieldName]}</ErrorListItem>;
                            }

                            return <></>;
                        })}
                    </Errors>
                </RowItem>
            );
        }
    };

    linkExt = (linkText, href) => {
        return (
            <Link
                onClickIn={() => {
                    window.location.href = href;
                }}
            >
                <Body1>
                    <PrimaryColour>{linkText}</PrimaryColour>
                </Body1>
            </Link>
        );
    };

    isTwoFactorAuthForced = () => {
        const sessionManager = new SessionManager();
        return sessionManager.isTwoFactorAuthForced();
    };

    onSubmit = () => {
        const { enableTwoFactorAuth, formValues } = this.props;

        const verificationCode = _.get(formValues, 'code');

        enableTwoFactorAuth(verificationCode);
    };
}

const mapStateToProps = (state) => {
    return {
        formValues: getFormValues(FORM_NAME)(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

AppEnableForm.propTypes = {
    twoFactorAuthDetails: PropTypes.object.isRequired,
    errors: PropTypes.object,
    enableTwoFactorAuth: PropTypes.func.isRequired,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
)(AppEnableForm);
