import React, { useState } from 'react';
import TermsSelectProduct from '../../../../../../pitch4_elements/terms/TermsSelectProduct';
import TermsProgress from './TermsProgress';
import { Grid } from '@material-ui/core';
import TermItem from './TermItem';
import TermsAndFeesEnum from '../../../../../../pitch4_enum/enum/TermsAndFeesEnum';
import TermsEditDrawer from './TermsEditDrawer';
import { calculateLoanType } from '../../../../../MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';

export default function TermsContainer(props) {
    const { lenderLoanEnquiry, disableActions } = props;
    const [newTerms, setNewTerms] = useState(null);
    const [termsEditDrawerOpen, setTermsEditDrawerOpen] = useState(false);
    const termsEnum = new TermsAndFeesEnum();
    const loanEnquiryData = lenderLoanEnquiry.loanEnquiry.loanEnquiryData;
    const getDefaultProduct = () => {
        const loanType = calculateLoanType(loanEnquiryData).value;
        const enumType = termsEnum.getType(loanType);
        if (enumType) {
            return loanType;
        }
        return 'term-loan';
    };

    const getDefaultTerms = () => termsEnum.getType(getDefaultProduct());
    const [lleTerms, setLLETerms] = useState(
        lenderLoanEnquiry.lenderTerms ? JSON.parse(lenderLoanEnquiry.lenderTerms) : getDefaultTerms()
    ); // Set this to getDefaultTerms() by default or for development - testTerms from '../../../../../../pitch4_enum/enum/TermsAndFeesEnum';
    const handleProductSelectChange = (product) => {
        if (lleTerms && product === lleTerms.product) {
            setNewTerms(lleTerms);
        } else {
            setNewTerms(termsEnum.getType(product));
        }
    };
    const toggleTermsEditDrawer = (revertTerms = false) => {
        setTermsEditDrawerOpen(!termsEditDrawerOpen);
        if (true === revertTerms) {
            setNewTerms(lenderLoanEnquiry.lenderTerms ? JSON.parse(lenderLoanEnquiry.lenderTerms) : getDefaultTerms());
        }
    };

    const calculateProgress = (terms) => {
        const totalItemsCount = terms.length;
        const doneItemsCount = terms.filter((item) => item.value !== null).length;

        return {
            totalItemsCount: terms.length,
            doneItemsCount: terms.filter((item) => item.value !== null).length,
            percentDone:
                totalItemsCount !== 0 && doneItemsCount !== 0
                    ? Math.round((doneItemsCount / totalItemsCount) * 100)
                    : 0,
        };
    };
    const getAllTerms = () => {
        let allTerms = [];
        let termsUpdatedAt = null;
        let product = null;

        if (newTerms) {
            // Check newTerms first
            allTerms = newTerms.terms;
            termsUpdatedAt = newTerms.updatedAt;
            product = newTerms.product;
        } else if (lleTerms) {
            allTerms = lleTerms.terms;
            termsUpdatedAt = lleTerms.updatedAt;
            product = lleTerms.product;
        }

        return {
            terms: allTerms,
            updatedAt: termsUpdatedAt,
            product: product,
        };
    };

    const updateLLETerms = (newTerms) => {
        setLLETerms(newTerms);
    };

    const renderTerms = () => {
        if (lleTerms || newTerms) {
            return (
                <>
                    <TermsProgress
                        termsProgress={calculateProgress(getAllTerms().terms)}
                        toggleTermsEditDrawer={toggleTermsEditDrawer}
                        disableActions={disableActions}
                    />
                    <Grid container className={'terms-and-fees-items-container'}>
                        {getAllTerms().terms.map((item, index) => {
                            return (
                                <TermItem key={index} item={item} isLast={index === getAllTerms().terms.length - 1} />
                            );
                        })}
                    </Grid>
                </>
            );
        }

        return null;
    };

    const terms = getAllTerms();

    return (
        <>
            {true === termsEditDrawerOpen && (
                <TermsEditDrawer
                    lleId={lenderLoanEnquiry.id}
                    termsUpdatedAt={terms.updatedAt}
                    allTerms={terms.terms}
                    product={terms.product}
                    disableActions={disableActions}
                    termsEditDrawerOpen={termsEditDrawerOpen}
                    toggleTermsEditDrawer={toggleTermsEditDrawer}
                    updateLLETerms={updateLLETerms}
                />
            )}
            <div className={'terms-and-fees-container'}>
                <TermsSelectProduct
                    lleProduct={lleTerms ? lleTerms.product : getDefaultProduct()}
                    handleProductSelectChange={handleProductSelectChange}
                />
                {renderTerms()}
            </div>
        </>
    );
}
