import Success from '../Success';
import Info from '../Info';
import Warning from '../Warning';
import Error from '../Error';
import { SAGA_ACTION_ERROR, SAGA_ACTION_INFO, SAGA_ACTION_SUCCESS, SAGA_ACTION_WARNING } from '../../sagas/container';

class LevelFactory {
    createFromSagaType = (message, type) => {
        switch (type) {
            case SAGA_ACTION_SUCCESS:
                return new Success(message);
            case SAGA_ACTION_WARNING:
                return new Warning(message);
            case SAGA_ACTION_ERROR:
                return new Error(message);
            case SAGA_ACTION_INFO:
                return new Info(message);
            default:
                break;
        }
    };
}

export default LevelFactory;
