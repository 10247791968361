export const NAMESPACE = 'slam.view_loan_enquiry';
export const GET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.get_matching_criteria_config`;
export const SET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.set_matching_criteria_config`;
export const API_SET_STATUS = `${NAMESPACE}.api_set_status`;
export const SET_STATUS = `${NAMESPACE}.set_status`;
export const SET_STATUS_SUBMISSION_ERRORS = `${NAMESPACE}.set_status_submission_errors`;
export const CLEAR_STATUS = `${NAMESPACE}.clear_status`;
export const REMOVE_LENDER_LOAN_ENQUIRY_ALERT = `${NAMESPACE}.remove_lender_loan_enquiry_alert`;

export const actionGetMatchingCriteriaConfig = () => ({
    type: GET_MATCHING_CRITERIA_CONFIG,
});

export const actionSetMatchingCriteriaConfig = (config) => ({
    type: SET_MATCHING_CRITERIA_CONFIG,
    config,
});

export const actionApiSetStatus = (leadId, status, metadata) => {
    return {
        type: API_SET_STATUS,
        leadId,
        status,
        metadata,
    };
};

export const actionClearStatus = () => ({
    type: CLEAR_STATUS,
});

export const actionSetStatus = (status, metaData) => ({
    type: SET_STATUS,
    status,
    metaData,
});

export const actionRemoveLenderLoanEnquiryAlert = (lenderLoanEnquiryId) => {
    return {
        type: REMOVE_LENDER_LOAN_ENQUIRY_ALERT,
        lenderLoanEnquiryId,
    };
};

const initialState = {
    config: {},
    status: null,
    metadata: null,
    submitted: false,
    errors: [],
    errorsVisible: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATCHING_CRITERIA_CONFIG:
            const config = action.config;

            return {
                ...state,
                config,
            };

        case API_SET_STATUS:
            return {
                ...state,
                submitted: true,
                errors: initialState.errors,
                errorsVisible: initialState.errorsVisible,
            };
        case SET_STATUS:
            const { status, metadata } = action;
            return {
                ...state,
                status,
                metadata,
                submitted: false,
            };
        case SET_STATUS_SUBMISSION_ERRORS:
            const { errors } = action;
            return {
                ...state,
                submitted: false,
                errors,
                errorsVisible: true,
            };
        case CLEAR_STATUS:
            return {
                ...state,
                status: initialState.status,
                metadata: initialState.metadata,
            };
        case REMOVE_LENDER_LOAN_ENQUIRY_ALERT:
            const { lenderLoanEnquiryId } = action;
            return {
                ...state,
                lenderLoanEnquiryId,
            };

        default:
            return { ...state };
    }
};

// Selectors
export const selectorGetMatchingCriteriaConfig = (state) => {
    return state[NAMESPACE].config;
};

export const selectorGetStatus = (state) => {
    return state[NAMESPACE].status;
};

export const selectorGetSubmitted = (state) => {
    return state[NAMESPACE].submitted;
};

export const selectorSetStatusSubmissionErrors = (state) => {
    return state[NAMESPACE].errors;
};

export const selectorAreErrorsVisible = (state) => {
    return state[NAMESPACE].errorsVisible;
};

export default reducer;
