export const REDUCER_NAMESPACE = 'anon.intermediary_register';

// Actions
export const INTERMEDIARY_REGISTER_SUBMISSION_ERRORS = `${REDUCER_NAMESPACE}.submission_errors`;
export const INTERMEDIARY_REGISTER_SUBMITTED = `${REDUCER_NAMESPACE}.submitted`;
export const INTERMEDIARY_REGISTER_RESET = `${REDUCER_NAMESPACE}.reset`;

const initialState = {
    submitted: false,
    duplicateEmailError: false,
    errors: [],
    errorsVisible: false,
    useCompositeForm: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INTERMEDIARY_REGISTER_SUBMITTED:
            return {
                ...state,
                submitted: true,
            };
        case INTERMEDIARY_REGISTER_SUBMISSION_ERRORS:
            return {
                ...state,
                submitted: false,
                errors: action.errors,
                errorsVisible: true,
                duplicateEmailError: action.duplicateEmailError,
            };
        case INTERMEDIARY_REGISTER_RESET:
            return {
                ...initialState,
            };
        default:
            return { ...state };
    }
};

export const actionIntermediaryRegisterSubmitted = (input, acceptedTermsVersion, recaptchaToken) => {
    return {
        type: INTERMEDIARY_REGISTER_SUBMITTED,
        input,
        acceptedTermsVersion,
        recaptchaToken,
    };
};

export const selectorIntermediaryRegisterSubmitted = (state) => {
    return state[REDUCER_NAMESPACE].submitted;
};

export const selectorIntermediaryRegisterSubmissionErrors = (state) => {
    return state[REDUCER_NAMESPACE].errors;
};

export const selectorDuplicateEmailError = (state) => {
    return state[REDUCER_NAMESPACE].duplicateEmailError;
};

export const selectorAreErrorsVisible = (state) => {
    return state[REDUCER_NAMESPACE].errorsVisible;
};

// Default export reducer
export default reducer;
