import { API_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const setLECompleted = (uri = API_URL, loanEnquiryId, lenderLoanEnquiryId, finalAmount, completedDate) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation setCompleted(
            $id: ID!
            $lenderLoanId: ID!
            $finalAmount: Float!
            $completedDate: DateTime
        ) {
            setCompleted(
                id: $id
                lenderLoanId: $lenderLoanId
                finalAmount: $finalAmount
                completedDate: $completedDate
            ) {
                id
                finalAmount
                completedDate
                invoiceSent
                invoicePaid
                createdAt
            }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            id: loanEnquiryId,
            lenderLoanId: lenderLoanEnquiryId,
            finalAmount: finalAmount,
            completedDate: completedDate,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
