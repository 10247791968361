import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, TextField } from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TuneIcon from '@material-ui/icons/Tune';
import { PrimaryRedirectButton } from '../../../../../pitch4_layout/components/buttons';

export default function Toolbar(props) {
    const {
        searchFilter,
        dataLoading,
        handleSearchFilterChange,
        handleClearAllFilters,
        createDocument,
        refreshData,
    } = props;

    return (
        <>
            <div className={'header-toolbar'}>
                <TextField
                    name={'search'}
                    className={'search'}
                    label="Search"
                    variant="outlined"
                    value={searchFilter}
                    disabled={dataLoading}
                    onChange={handleSearchFilterChange}
                />
                <Button
                    className={'btn-clear-all'}
                    variant="outlined"
                    size={'large'}
                    style={{ height: 45, paddingTop: 11 }}
                    onClick={handleClearAllFilters}
                >
                    Clear all
                </Button>
                <PrimaryRedirectButton style={{ margin: 0 }} onClick={() => createDocument(refreshData)}>
                    Add
                </PrimaryRedirectButton>
            </div>

            <div className={'header-toolbar-mobile'}>
                <Divider className={'divider'} />
                <Accordion className={'container'}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: '0 5px' }}
                    >
                        <TuneIcon style={{ marginRight: 10 }} /> Filters
                    </AccordionSummary>
                    <AccordionDetails className={'details'}>
                        <TextField
                            name={'search'}
                            className={'search'}
                            label="Search"
                            variant="outlined"
                            value={searchFilter}
                            disabled={dataLoading}
                            onChange={handleSearchFilterChange}
                        />
                        <Button
                            className={'btn-clear-all'}
                            variant="outlined"
                            size={'large'}
                            style={{ height: 45, paddingTop: 11 }}
                            onClick={handleClearAllFilters}
                        >
                            Clear all
                        </Button>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}
