export const NAMESPACE = 'borrower.view_le.main';
export const INITIATE = `${NAMESPACE}.initiate`;
export const SET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.set_matching_criteria_config`;
export const SET_API_GET_LOAN_ENQUIRY_RESPONSE = `${NAMESPACE}.set_api_get_loan_enquiry_response`;
export const SET_API_GET_LOAN_ENQUIRY_MATCHES_RESPONSE = `${NAMESPACE}.set_api_get_loan_enquiry_matches_response`;
export const STOP_POLLING_MATCHES = `${NAMESPACE}.stop_polling_matches`;
export const RELOAD_LOAN_ENQUIRY = `${NAMESPACE}.reload_loan_enquiry`;
export const CLEANUP = `${NAMESPACE}.cleanup`;
export const SET_MATCH_STATUSES = `${NAMESPACE}.set_match_statuses`;
export const API_SET_STATUS = `${NAMESPACE}.api_set_status`;
export const API_GET_MATCHES = `${NAMESPACE}.get_matches`;
export const API_SET_LEAD_STATUS = `${NAMESPACE}.api_set_lead_status`;
export const SET_STATUS = `${NAMESPACE}.set_status`;
export const SET_STATUS_SUBMISSION_ERRORS = `${NAMESPACE}.set_status_submission_errors`;
export const CLEAR_STATUS = `${NAMESPACE}.clear_status`;
export const UPDATE_LOAN_ENQUIRY_MATCH_FAVOURITE = `${NAMESPACE}.update_loan_enquiry_match_favourite`;
export const UPDATE_LOAN_ENQUIRY_MATCHES_SUCCESS = `${NAMESPACE}.update_loan_enquiry_matches_success`;
export const REVERT_LOAN_ENQUIRY_TO_DRAFT = `${NAMESPACE}.revert_loan_enquiry_to_draft`;
export const CLEAR_USER_ALERTS = `${NAMESPACE}.clear_user_alerts`;
export const REMOVE_LOAN_ENQUIRY_ALERT = `${NAMESPACE}.remove_loan_enquiry_alert`;
export const SEND_MESSAGE_TO_LENDERS = `${NAMESPACE}.send_message_to_lenders`;

const initialState = {
    config: {},
    loanEnquiryId: null,
    loanEnquiry: null,
    loanEnquiryMatches: null,
    pollingMatches: true,
    status: null,
    metadata: null,
    submitted: false,
    errors: [],
    errorsVisible: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATCHING_CRITERIA_CONFIG:
            const config = action.config;

            return {
                ...state,
                config,
            };

        case INITIATE:
            const loanEnquiryId = action.loanEnquiryId;

            return {
                ...initialState,
                loanEnquiryId,
            };

        case SET_API_GET_LOAN_ENQUIRY_RESPONSE:
            const { loanEnquiry } = action;

            return {
                ...state,
                loanEnquiry,
            };

        case SET_API_GET_LOAN_ENQUIRY_MATCHES_RESPONSE:
            const { loanEnquiryMatch } = action;

            return {
                ...state,
                loanEnquiryMatch,
            };
        case API_SET_STATUS:
            return {
                ...state,
                submitted: true,
                errors: initialState.errors,
                errorsVisible: initialState.errorsVisible,
            };
        case API_SET_LEAD_STATUS:
            return {
                ...state,
                submitted: true,
                errors: initialState.errors,
                errorsVisible: initialState.errorsVisible,
            };
        case SET_STATUS:
            const { status } = action;
            return {
                ...state,
                status,
                submitted: false,
            };
        case SET_STATUS_SUBMISSION_ERRORS:
            const { errors } = action;
            return {
                ...state,
                submitted: false,
                errors,
                errorsVisible: true,
            };
        case STOP_POLLING_MATCHES:
        case CLEANUP:
            return {
                ...state,
                pollingMatches: false,
            };
        case CLEAR_STATUS:
            return {
                ...state,
                status: initialState.status,
                metadata: initialState.metadata,
                loanEnquiry: initialState.loanEnquiry,
            };

        case UPDATE_LOAN_ENQUIRY_MATCHES_SUCCESS:
            const { matches } = action;

            return {
                ...state,
                loanEnquiryMatch: {
                    ...state.loanEnquiryMatch,
                    matched: matches,
                },
            };
        case REVERT_LOAN_ENQUIRY_TO_DRAFT:
            const { leId } = action.loanEnquiryId;
            return {
                ...state,
                leId,
            };
        case CLEAR_USER_ALERTS:
            const leuaId = action.loanEnquiryId;
            return {
                ...state,
                leuaId,
            };
        case REMOVE_LOAN_ENQUIRY_ALERT:
            const { leaId } = action.loanEnquiryId;
            return {
                ...state,
                leaId,
            };
        case SEND_MESSAGE_TO_LENDERS:
            const { lenders, message } = action;
            return {
                ...state,
                lenders,
                message,
            };

        default:
            return {
                ...state,
            };
    }
};

// Actions
export const actionInitiate = (loanEnquiryId) => ({
    type: INITIATE,
    loanEnquiryId,
});

export const actionSetMatchingCriteriaConfig = (config) => ({
    type: SET_MATCHING_CRITERIA_CONFIG,
    config,
});

export const actionSetApiGetLoanEnquiryResponse = (response) => ({
    type: SET_API_GET_LOAN_ENQUIRY_RESPONSE,
    ...response,
});

export const actionSetApiGetLoanEnquiryMatchesResponse = (response) => ({
    type: SET_API_GET_LOAN_ENQUIRY_MATCHES_RESPONSE,
    ...response,
});

export const actionStopPollingMatches = (polling) => ({
    type: STOP_POLLING_MATCHES,
});

export const actionReloadLoanEnquiry = () => ({
    type: RELOAD_LOAN_ENQUIRY,
});

export const actionSetMatchStatuses = (statuses) => ({
    type: SET_MATCH_STATUSES,
    statuses,
});

export const actionApiSetLeadStatus = (leadId, newStatus, metadata, refetchMatches = false) => {
    return {
        type: API_SET_LEAD_STATUS,
        leadId,
        newStatus,
        metadata,
        refetchMatches,
    };
};

export const actionApiSetStatus = (loanEnquiryId, status) => {
    return {
        type: API_SET_STATUS,
        loanEnquiryId,
        status,
    };
};

export const actionSetStatus = (status) => ({
    type: SET_STATUS,
    status,
});

export const actionGetMatches = () => ({
    type: API_GET_MATCHES,
});

export const actionClearStatus = () => ({
    type: CLEAR_STATUS,
});

export const actionCleanup = () => ({
    type: CLEANUP,
});

export const actionUpdateMatchFavourite = (lenderLoanEnquiryId, loanEnquiryId, favourite) => {
    return {
        type: UPDATE_LOAN_ENQUIRY_MATCH_FAVOURITE,
        lenderLoanEnquiryId,
        loanEnquiryId,
        favourite,
    };
};

export const actionUpdateLoanEnquiryMatchesSuccess = (matches) => {
    return {
        type: UPDATE_LOAN_ENQUIRY_MATCHES_SUCCESS,
        matches,
    };
};

export const actionRevertLoanEnquiryToDraft = (loanEnquiryId) => {
    return {
        type: REVERT_LOAN_ENQUIRY_TO_DRAFT,
        loanEnquiryId,
    };
};

export const actionRemoveLoanEnquiryAlert = (loanEnquiryId) => {
    return {
        type: REMOVE_LOAN_ENQUIRY_ALERT,
        loanEnquiryId,
    };
};

export const actionClearUserAlerts = (loanEnquiryId) => {
    return {
        type: CLEAR_USER_ALERTS,
        loanEnquiryId,
    };
};

export const actionSendMessageToLenders = (lenders, message, toggleMessageDrawer, setMessageSent) => {
    return {
        type: SEND_MESSAGE_TO_LENDERS,
        lenders,
        message,
        toggleMessageDrawer,
        setMessageSent,
    };
};

// Selectors
export const selectorGetStatus = (state) => {
    return state[NAMESPACE].status;
};

export const selectorGetSubmitted = (state) => {
    return state[NAMESPACE].submitted;
};

export const selectorGetMatchingCriteriaConfig = (state) => {
    return state[NAMESPACE].config;
};

export const selectorGetLoanEnquiry = (state) => {
    return state[NAMESPACE].loanEnquiry;
};

export const selectorGetLoanEnquiryId = (state) => {
    return state[NAMESPACE].loanEnquiryId;
};

export const selectorGetLoanEnquiryMatch = (state) => {
    return state[NAMESPACE].loanEnquiryMatch;
};

export const selectorSetStatusSubmissionErrors = (state) => {
    return state[NAMESPACE].errors;
};

export const selectorAreErrorsVisible = (state) => {
    return state[NAMESPACE].errorsVisible;
};

export const selectorLoanEnquiryMatched = (state) => {
    return state[NAMESPACE].loanEnquiryMatch.matched;
};

//Default export reducer
export default reducer;
