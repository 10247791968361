import React from 'react';
import PropTypes from 'prop-types';

class BubbleText extends React.Component {
    render() {
        let { classNames = '' } = this.props;

        return <div className={`bubble-text ${classNames}`}>{this.props.children}</div>;
    }
}

BubbleText.propTypes = {
    classNames: PropTypes.string.isRequired,
};

export default BubbleText;
