// see https://github.com/erikras/ducks-modular-redux
import UserSettingsMapper from '../form/UserSettingsMapper';

export const REDUCER_NAMESPACE = 'admin.administer_user_settings';

// Actions
export const ADMIN_USER_SETTINGS_HIDE_VALIDATION_ERRORS = `${REDUCER_NAMESPACE}.hide_server_side_errors`;
export const ADMIN_USER_SETTINGS_SHOW_VALIDATION_ERRORS = `${REDUCER_NAMESPACE}.show_server_side_errors`;
export const ADMIN_USER_SETTINGS_SET_VALIDATION_ERRORS = `${REDUCER_NAMESPACE}.set_validation_errors`;
export const ADMIN_USER_SETTINGS_SET_INITIAL_ERRORS = `${REDUCER_NAMESPACE}.set_initial_errors`;
export const ADMIN_USER_SETTINGS_SET_INITIAL_VALUES = `${REDUCER_NAMESPACE}.set_initial_values`;
export const ADMIN_USER_SETTINGS_SET_RAW_INITIAL_VALUES = `${REDUCER_NAMESPACE}.set_raw_initial_values`;

//Reducer
const initialState = {
    validationErrors: [],
    visible: true,
    initialValues: [],
    initialQueryResponse: [],
    initialFetchErrors: [],
};

const mapper = new UserSettingsMapper();

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADMIN_USER_SETTINGS_HIDE_VALIDATION_ERRORS:
            return {
                ...state,
                visible: false,
            };

        case ADMIN_USER_SETTINGS_SHOW_VALIDATION_ERRORS:
            return {
                ...state,
                visible: true,
            };

        case ADMIN_USER_SETTINGS_SET_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: action.validationErrors,
                visible: true,
            };
        case ADMIN_USER_SETTINGS_SET_INITIAL_VALUES:
            return {
                ...state,
                initialValues: action.initialValues,
                id: action.id,
                initialQueryResponse: action.initialQueryResponse,
            };
        case ADMIN_USER_SETTINGS_SET_RAW_INITIAL_VALUES:
            return {
                ...state,
                initialValues: action.initialValues,
                id: action.id,
            };

        case ADMIN_USER_SETTINGS_SET_INITIAL_ERRORS:
            return {
                ...state,
                initialFetchErrors: action.initialFetchErrors,
            };

        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;

//Action Creators
export const actionHideValidationErrors = (props) => {
    return {
        type: ADMIN_USER_SETTINGS_HIDE_VALIDATION_ERRORS,
    };
};

export const actionShowValidationErrors = (props) => {
    return {
        type: ADMIN_USER_SETTINGS_SHOW_VALIDATION_ERRORS,
    };
};

export const actionSetValidationErrors = (errors) => {
    return {
        type: ADMIN_USER_SETTINGS_SET_VALIDATION_ERRORS,
        validationErrors: errors,
    };
};

export const actionSetInitialErrors = (errors) => {
    return {
        type: ADMIN_USER_SETTINGS_SET_INITIAL_ERRORS,
        initialFetchErrors: errors,
    };
};

export const actionSetInitialValues = (initialQueryResponse) => {
    const mappedValues = mapper.mapToForm(initialQueryResponse);
    return {
        type: ADMIN_USER_SETTINGS_SET_INITIAL_VALUES,
        initialValues: mappedValues,
        initialQueryResponse: initialQueryResponse,
    };
};

export const actionSetRawInitialValues = (values) => {
    return {
        type: ADMIN_USER_SETTINGS_SET_RAW_INITIAL_VALUES,
        initialValues: values,
    };
};

//Selectors
export const selectorGetInitialFetchErrors = (state) => {
    return state[REDUCER_NAMESPACE].initialFetchErrors;
};

export const selectorAreErrorsVisible = (state) => {
    return state[REDUCER_NAMESPACE].visible;
};

export const selectorGetInitialValues = (state) => {
    return state[REDUCER_NAMESPACE].initialValues;
};

export const selectorGetInitialQueryResponseValues = (state) => {
    return state[REDUCER_NAMESPACE].initialQueryResponse;
};

export const selectorGetValidationErrors = (state) => {
    return state[REDUCER_NAMESPACE].validationErrors;
};
