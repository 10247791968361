import React, { useEffect, useState } from 'react';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default function ActionToolbar(props) {
    const { handleShowOnlyFav, handleLenderSelection, setStatusName, handleSortBy, data } = props;
    const [selectFav, setSelectFav] = useState(false);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() => {
        handleShowOnlyFav(selectFav);
    }, [selectFav]);

    useEffect(() => {
        if (false === selectAll) {
            handleLenderSelection({
                selectionModel: [],
            });
        } else {
            handleLenderSelection({
                selectionModel: data.map((item) => item.id),
            });
        }
    }, [selectAll]);

    return (
        <>
            <div className={'select-fav'}>
                <span className={'label'}>Show Only Starred:</span>{' '}
                {selectFav ? (
                    <CheckBoxIcon
                        className={'icon'}
                        onClick={() => {
                            setSelectFav(false);
                        }}
                    />
                ) : (
                    <CheckBoxOutlineBlankIcon
                        className={'icon'}
                        onClick={() => {
                            setSelectFav(true);
                        }}
                    />
                )}
                <div className={'divider'} />
            </div>
            <div className={'select-all'}>
                <span className={'label'}>Select all:</span>{' '}
                {selectAll ? (
                    <CheckBoxIcon
                        className={'icon'}
                        onClick={() => {
                            setSelectAll(false);
                        }}
                    />
                ) : (
                    <CheckBoxOutlineBlankIcon
                        className={'icon'}
                        onClick={() => {
                            setSelectAll(true);
                        }}
                    />
                )}
            </div>
            <div className={'sort-by'}>
                <div className={'divider'} />
                <span className={'label'}>Sort by:</span>
                <FormControl variant="outlined" style={{ minWidth: 130 }}>
                    <Select
                        native
                        value={setStatusName()}
                        onChange={(event) => {
                            let field, sort;
                            switch (event.target.value) {
                                case 'lenderNameAsc':
                                    field = 'lenderName';
                                    sort = 'asc';
                                    break;
                                case 'lenderNameDesc':
                                    field = 'lenderName';
                                    sort = 'desc';
                                    break;
                                case 'updatedAtAsc':
                                    field = 'updatedAt';
                                    sort = 'asc';
                                    break;
                                case 'updatedAtDesc':
                                    field = 'updatedAt';
                                    sort = 'desc';
                                    break;
                                case 'statusAsc':
                                    field = 'status';
                                    sort = 'asc';
                                    break;
                                case 'statusDesc':
                                    field = 'status';
                                    sort = 'desc';
                                    break;
                                default:
                                    field = 'status';
                                    sort = 'asc';
                            }

                            handleSortBy({ field: field, sort: sort });
                        }}
                        className={'list'}
                    >
                        <option value={'lenderNameAsc'} key={'lenderNameAsc'}>
                            Name (asc)
                        </option>
                        <option value={'lenderNameDesc'} key={'lenderNameDesc'}>
                            Name (desc)
                        </option>
                        <option value={'updatedAtAsc'} key={'updatedAtAsc'}>
                            Updated (asc)
                        </option>
                        <option value={'updatedAtDesc'} key={'updatedAtDesc'}>
                            Updated (desc)
                        </option>
                        <option value={'statusAsc'} key={'statusAsc'}>
                            Status (asc)
                        </option>
                        <option value={'statusDesc'} key={'statusDesc'}>
                            Status (desc)
                        </option>
                    </Select>
                </FormControl>
            </div>
        </>
    );
}
