import createP4ApolloFetch from '../bootstrap/middlewares';

export const editTag = (uri, tags) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    const mutation = `
        mutation editUserTags($tags: [TagInput]) {
          editTags(tags: $tags) {
            id
            value
            label
            color
            useLightFont
          }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            tags: tags,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
