import React from 'react';
import { put, takeLatest } from 'redux-saga/effects';
import { sagaActionModalShow } from '../../../../../modal/sagas/container';
import { sagaActionNotificationSetSuccess } from '../../../../../notifications/sagas/container';
import AssumedIdentitySessionExpired from '../../list/components/AssumedIdentitySessionExpired';

export const SAGA_NAMESPACE = 'sagas.p4a.users.assumed_identity_session';

export const SAGA_ACTION_END_OF_ASSUMED_IDENTITY_SESSION_FORCED = `${SAGA_NAMESPACE}.SAGA_ACTION_END_OF_ASSUMED_IDENTITY_SESSION_FORCED`;
export const sagaEndOfAssumedIdentitySessionForced = (session) => ({
    type: SAGA_ACTION_END_OF_ASSUMED_IDENTITY_SESSION_FORCED,
    session,
});

export const SAGA_ACTION_END_OF_ASSUMED_IDENTITY_SESSION_VOLUNTARY = `${SAGA_NAMESPACE}.SAGA_ACTION_END_OF_ASSUMED_IDENTITY_SESSION_VOLUNTARY`;
export const sagaEndOfAssumedIdentitySessionVoluntary = (session) => ({
    type: SAGA_ACTION_END_OF_ASSUMED_IDENTITY_SESSION_VOLUNTARY,
    session,
});

function* endOfAssumedIdentitySessionVoluntary(action) {
    const session = action.session;
    yield put(sagaActionNotificationSetSuccess(`You are no longer acting on behalf of user ${session.getFullname()}`));
}

function* endOfAssumedIdentitySessionForced(action) {
    const session = action.session;
    yield put(sagaActionModalShow(<AssumedIdentitySessionExpired session={session} />));
}

//Main Saga for Make Loan Enquiry
function* main() {
    //entry point functions
    yield takeLatest(SAGA_ACTION_END_OF_ASSUMED_IDENTITY_SESSION_VOLUNTARY, endOfAssumedIdentitySessionVoluntary);
    yield takeLatest(SAGA_ACTION_END_OF_ASSUMED_IDENTITY_SESSION_FORCED, endOfAssumedIdentitySessionForced);
}

export default main;
