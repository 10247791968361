import { call, put, takeLatest } from 'redux-saga/effects';
import { setLeadStatus } from '../api/set_status';
import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../../../notifications/sagas/container';
import { API_SET_STATUS } from '../redux/main';
import { getConfig } from '../api/get_config';
import { actionSetMatchingCriteriaConfig, GET_MATCHING_CRITERIA_CONFIG } from '../redux/main';
import { SET_STATUS_SUBMISSION_ERRORS } from '../redux/main';
import { sagaActionModalHide } from '../../../../modal/sagas/container';
import { actionSetStatus } from '../redux/main';

export const SAGA_NAMESPACE = 'admin.view_lender_loan_enquiry';

function* apiGetMatchingCriteriaConfig() {
    const response = yield call(getConfig);

    switch (response.getClassName()) {
        case 'GetConfigSuccess':
            const config = response.getData();

            // Set the form initial values
            yield put(actionSetMatchingCriteriaConfig(config));
            break;

        case 'GetConfigError':
        default:
            break;
    }
}

function* apiSetStatus(action) {
    const response = yield call(setLeadStatus, action.leadId, action.status, action.reason, action.metadata);
    const { errors } = response;
    if (errors) {
        const errors = response.errors.map((err) => err.message);
        yield put({ type: SAGA_ACTION_ERROR, message: 'Could not update lead status' });
        yield put({ type: SET_STATUS_SUBMISSION_ERRORS, errors });
    } else {
        yield put(actionSetStatus(action.status));
        yield put(sagaActionModalHide());
        yield put({ type: SAGA_ACTION_SUCCESS, message: 'Lead status saved!' });
    }
}

//Main Saga
function* main() {
    //response handling
    yield takeLatest(GET_MATCHING_CRITERIA_CONFIG, apiGetMatchingCriteriaConfig);
    yield takeLatest(API_SET_STATUS, apiSetStatus);
}

export default main;
