export const TOTAL_USERS = 'total_users';
export const TOTAL_BORROWERS = 'total_borrowers';
export const TOTAL_LENDERS = 'total_lenders';
export const TOTAL_INTERMEDIARIES = 'total_intermediaries';
export const ACTIVE_USERS = 'active_users';
export const ACTIVE_LENDERS = 'active_lenders';
export const ACTIVE_BORROWERS = 'active_borrowers';
export const ACTIVE_INTERMEDIARIES = 'active_intermediaries';
export const NEW_INTERMEDIARY_ENQUIRIES = 'new_intermediary_enquiries';
export const NEW_NON_YS_INTERMEDIARY_ENQUIRIES = 'new_non_ys_intermediary_enquiries';
export const NEW_BORROWER_ENQUIRIES = 'new_borrower_enquiries';
export const LENDER_ENQUIRY_RESPONSE = 'lender_enquiry_response';
export const filterTypes = [
    TOTAL_USERS,
    TOTAL_BORROWERS,
    TOTAL_LENDERS,
    TOTAL_INTERMEDIARIES,
    ACTIVE_USERS,
    ACTIVE_LENDERS,
    ACTIVE_BORROWERS,
    ACTIVE_INTERMEDIARIES,
    NEW_INTERMEDIARY_ENQUIRIES,
    NEW_NON_YS_INTERMEDIARY_ENQUIRIES,
    NEW_BORROWER_ENQUIRIES,
    LENDER_ENQUIRY_RESPONSE,
];
export const chartStatsMapping = {
    total_users: {
        today: 'userTotal',
        newThisMonth: 'userNewThisMonth',
        endOfLastMonth: 'userEndOfLastMonth',
        increaseFromPreviousMonth: 'userIncreaseFromPreviousMonth',
    },
    total_borrowers: {
        today: 'borrowersTotal',
        newThisMonth: 'borrowersNewThisMonth',
        endOfLastMonth: 'borrowersEndOfLastMonth',
        increaseFromPreviousMonth: 'borrowersIncreaseFromPreviousMonth',
    },
    total_lenders: {
        today: 'lenderTotal',
        newThisMonth: 'lenderNewThisMonth',
        endOfLastMonth: 'lenderEndOfLastMonth',
        increaseFromPreviousMonth: 'lenderIncreaseFromPreviousMonth',
    },
    total_intermediaries: {
        today: 'intermediaryTotal',
        newThisMonth: 'intermediaryNewThisMonth',
        endOfLastMonth: 'intermediaryEndOfLastMonth',
        increaseFromPreviousMonth: 'intermediaryIncreaseFromPreviousMonth',
    },
    new_intermediary_enquiries: {
        today: 'intermediaryLoanEnquiriesToday',
        newThisMonth: 'intermediaryLoanEnquiriesNewThisMonth',
        endOfLastMonth: 'intermediaryLoanEnquiriesEndOfLastMonth',
        increaseFromPreviousMonth: 'intermediaryLoanEnquiriesIncreaseFromPreviousMonth',
    },
    new_non_ys_intermediary_enquiries: {
        today: 'nonYSLoanEnquiriesToday',
        newThisMonth: 'nonYSLoanEnquiriesNewThisMonth',
        endOfLastMonth: 'nonYSLoanEnquiriesEndOfLastMonth',
        increaseFromPreviousMonth: 'nonYSLoanEnquiriesIncreaseFromPreviousMonth',
    },
    new_borrower_enquiries: {
        today: 'borrowerLoanEnquiriesToday',
        newThisMonth: 'borrowerLoanEnquiriesNewThisMonth',
        endOfLastMonth: 'borrowerLoanEnquiriesEndOfLastMonth',
        increaseFromPreviousMonth: 'borrowerLoanEnquiriesIncreaseFromPreviousMonth',
    },
};
