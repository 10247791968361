export const RESIDENTIAL_OCCUPIED = 'RESIDENTIAL_OCCUPIED';
export const RESIDENTIAL_AND_INVESTMENT = 'RESIDENTIAL_AND_INVESTMENT';
export const INVESTMENT_ONLY = 'INVESTMENT_ONLY';
export const NO = 'NO';

class OwnAnyOtherProperty {
    getTypes = () => {
        return [
            { value: RESIDENTIAL_OCCUPIED, label: 'Residential (Home Owner/Occupier)' },
            { value: RESIDENTIAL_AND_INVESTMENT, label: 'Residential & Investment' },
            { value: INVESTMENT_ONLY, label: 'Investment properties only' },
            { value: NO, label: 'No' },
        ];
    };

    getOptions = () => {
        return {
            [RESIDENTIAL_OCCUPIED]: { value: RESIDENTIAL_OCCUPIED, label: 'Residential (Home Owner/Occupier)' },
            [RESIDENTIAL_AND_INVESTMENT]: { value: RESIDENTIAL_AND_INVESTMENT, label: 'Residential & Investment' },
            [INVESTMENT_ONLY]: { value: INVESTMENT_ONLY, label: 'Investment properties only' },
            [NO]: { value: NO, label: 'No' },
        };
    };
}

export default OwnAnyOtherProperty;
