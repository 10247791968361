import { BaseEnum } from '../../../../../pitch4_enum';

export const TYPE_PURCHASE = 'no';
export const TYPE_REFINANCE = 'yes';

class RefinancePurchaseEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_PURCHASE]: 'Purchase',
            [TYPE_REFINANCE]: 'Refinance',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default RefinancePurchaseEnum;
