import React from 'react';
import styled from 'styled-components';
import Container from 'pitchblack_react_utils/layout/Container';
import PropTypes from 'prop-types';
import { FieldButtonPanelRow, FieldRow, TextField, TextLabel } from 'pitch4_forms/components/inputs';
import { PrimaryButton } from 'pitch4_layout/components/buttons';

const StyledContainer = styled(Container)``;

class EditForm extends React.Component {
    onSubmit = (values, setValidationErrors, props) => {
        let { mutate, document, mapper, onSuccess, onPending, onError } = this.props;

        onPending();

        const mappedValues = mapper.mapFromForm(values);

        //map the variables if necessary
        return mutate({
            variables: {
                id: document.id,
                input: mappedValues,
            },
        })
            .then(() => {
                onSuccess();
            })
            .catch((error) => {
                onError();
            });
    };

    render() {
        let { handleSubmit, reset, submitting } = this.props;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <StyledContainer>
                    <FieldRow label={<TextLabel label="File name" />} input={<TextField name="fileName" />} />
                    <FieldButtonPanelRow>
                        <PrimaryButton type="submit" disabled={submitting}>
                            Submit
                        </PrimaryButton>
                        <PrimaryButton disabled={submitting} onClick={reset}>
                            Reset
                        </PrimaryButton>
                    </FieldButtonPanelRow>
                </StyledContainer>
            </form>
        );
    }
}

EditForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    setValidationErrors: PropTypes.func.isRequired,
    mapper: PropTypes.object.isRequired,
    mutate: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    onPending: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
};

export default EditForm;
