import { call, put, takeLatest } from 'redux-saga/effects';
import { CLEAR_USER_ALERTS } from '../redux';
import { clearUserAlerts } from '../api';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { SET_USER_ALERTS_ACTIVE } from '../../../../user/configure_profile/redux';

function* apiClearUserAlerts() {
    const response = yield call(clearUserAlerts);
    if (response.clearUserAlerts.success) {
        const sm = new SessionManager();
        const alertActive = false;

        sm.setAlertsActive(alertActive);
        yield put({ type: SET_USER_ALERTS_ACTIVE, alertActive: alertActive });

        window.location.reload(false);
    }
}

//Main Saga
function* main() {
    yield takeLatest(CLEAR_USER_ALERTS, apiClearUserAlerts);
}

export default main;
