import SessionManager from './SessionManager';

export const checkSessionIsAuthorised = (requiredRoles) => {
    const sessionManager = new SessionManager();

    if (sessionManager.isAuthenticated() && sessionManager.isAuthorised(requiredRoles)) {
        return true;
    }

    return false;
};
