import createP4ApolloFetch from '../../../bootstrap/middlewares';
import { ANON_URL } from '../../../bootstrap/constants/constants';
import { INTERMEDIARY_REGISTRATION_RECAPTCHA_ACTION } from '../containers/IntermediaryRegistrationContainer';

const apolloFetch = createP4ApolloFetch({ uri: ANON_URL });

const mutation = `
    mutation register($input: anon_registerIntermediaryInput!, $recaptcha: RecaptchaV3Input!) {
        registerIntermediary (input: $input, recaptcha: $recaptcha) {
            token {
                token
                expires
            }
            session {
                roles {
                    label
                    name
                }
                id
                title
                given
                family
                authExpiry
                supplementaryData
            }
        }
    }
`;

export const registerIntermediary = (
    {
        family,
        given,
        email,
        dateOfBirth,
        phoneNumber,
        companyName,
        password,
        passwordConfirm = password,
        hasDataProtectionLicence = false,
        hubspotCookie = null,
        pageUri = null,
        pageName = null,
        ppcPage = null,
        tcPpAgreement = false,
        commsAgreement = false,
    },
    acceptedTermsVersion,
    recaptchaToken = ''
) => {
    return apolloFetch({
        query: mutation,
        variables: {
            input: {
                family,
                given,
                email,
                dateOfBirth,
                companyName,
                primaryPhone: {
                    number: phoneNumber,
                },
                password,
                passwordConfirm,
                acceptedTermsVersion,
                hasDataProtectionLicence,
                hubspotCookie,
                pageUri,
                pageName,
                ppcPage,
                commsAgreement,
            },
            recaptcha: {
                token: recaptchaToken,
                action: INTERMEDIARY_REGISTRATION_RECAPTCHA_ACTION,
            },
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};
