import RouteManagerFactory from '../src/RouteManagerFactory';

// see https://github.com/erikras/ducks-modular-redux
export const REDUCER_NAMESPACE = 'routing';

// Actions
// export const ADD_ROUTE = `${REDUCER_NAMESPACE}.add_route`;

//Reducer
const initialState = {
    factory: new RouteManagerFactory(),
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;

//Selectors
export const selectorGetRouteManager = (state) => {
    return state[REDUCER_NAMESPACE].factory.create();
};
