import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ROLE_BORROWER } from '../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { actionUpdateMatchFavourite, selectorGetFavourite } from '../redux/main';
import StarIcon from '../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../assets/images/star-gray.svg';
import React from 'react';

class LenderFavourite extends React.Component {
    render() {
        const { lenderLoanEnquiryId, favourite = false, handleFavouriteChange } = this.props;
        const starIcon = true === favourite ? StarIcon : StarGrayIcon;

        return (
            <img
                src={starIcon}
                alt="Favourite"
                className={'favourite'}
                onClick={() => handleFavouriteChange(lenderLoanEnquiryId, favourite)}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        favourite: selectorGetFavourite(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleFavouriteChange: (lenderLoanEnquiryId, favourite) =>
            dispatch(actionUpdateMatchFavourite(lenderLoanEnquiryId, !favourite)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_BORROWER])
)(LenderFavourite);
