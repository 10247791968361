import DynamicEnum from 'pitch4_enum/enum/DynamicEnum';

export const NAMESPACE = 'intermediary.dynamic_enum';

export const INTERMEDIARY_ADD_DYNAMIC_ENUM = `${NAMESPACE}.add`;

const initialState = {
    dynamicEnums: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case INTERMEDIARY_ADD_DYNAMIC_ENUM:
            let dynamicEnums = { ...state.dynamicEnums };
            dynamicEnums[action.key] = action.dynamicEnum;

            return {
                ...state,
                dynamicEnums: { ...dynamicEnums },
            };
        default:
            return { ...state };
    }
};

export default reducer;

export const actionSetDynamicEnum = (key, dynamicEnum) => ({
    type: INTERMEDIARY_ADD_DYNAMIC_ENUM,
    key,
    dynamicEnum,
});

export const selectorHasDynamicEnum = (key) => (state) => {
    if (state[NAMESPACE].dynamicEnums[key]) {
        return true;
    }
    return false;
};

export const selectorGetDynamicEnum = (key) => (state) => {
    if (!state[NAMESPACE].dynamicEnums[key]) {
        return new DynamicEnum();
    }
    return state[NAMESPACE].dynamicEnums[key];
};
