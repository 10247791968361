import { call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getTwoFactorAuthDetails } from '../api/get_two_factor_auth_details';
import { enableTwoFactorAuth } from '../api/enable_two_factor_auth';
import {
    actionSetEnableTwoFactorAuthErrors,
    actionSetTwoFactorAuthAccessDenied,
    actionSetTwoFactorAuthDetails,
    ENABLE_2FA,
    GET_2FA_DETAILS,
    selectorGetRedirectOnFailure,
    SET_2FA_ACCESS_DENIED,
} from '../redux';
import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../../notifications/sagas/container';
import { mapErrors } from '../../../../pitch4_forms/utils/Pitch4ConstraintErrors';
import SessionManager from '../../../../pitchblack_react_utils/session/SessionManager';
import { determineAuthenticatedBaseRoute } from '../../../../pitch4_routing/src/findRoute';

function* getTwoFactorAuth() {
    const response = yield call(getTwoFactorAuthDetails);

    switch (response.getClassName()) {
        case 'GetTwoFactorAuthDetailsSuccess':
            const twoFactorAuth = response.getData();

            yield put(actionSetTwoFactorAuthDetails(twoFactorAuth));
            break;
        case 'GetTwoFactorAuthDetailsErrors':
            yield put(actionSetTwoFactorAuthAccessDenied());
            break;
        default:
            break;
    }
}

function* submitEnableTwoFactorAuth(action) {
    const { code } = action;
    const response = yield call(enableTwoFactorAuth, code);

    switch (response.getClassName()) {
        case 'EnableTwoFactorAuthSuccess':
            const twoFactorAuth = response.getData();
            const sessionManager = new SessionManager();

            yield put(actionSetTwoFactorAuthDetails(twoFactorAuth));
            yield call([sessionManager, 'removeForceTwoFactorAuthFlag']);
            yield put({ type: SAGA_ACTION_SUCCESS, message: 'Successfully enabled 2FA for your account' });
            break;
        case 'EnableTwoFactorAuthErrors':
            yield put({ type: SAGA_ACTION_ERROR, message: 'There was a problem enabling 2FA for your account.' });
            yield put(actionSetEnableTwoFactorAuthErrors(mapErrors(response.getErrors())));
            break;
        default:
            break;
    }
}

function* accessDenied() {
    const redirectOnFailure = yield select(selectorGetRedirectOnFailure);

    if (redirectOnFailure === true) {
        const baseRoute = yield call(determineAuthenticatedBaseRoute);
        yield put(push(baseRoute));
    }
}

//Main Saga
function* main() {
    yield takeLatest(GET_2FA_DETAILS, getTwoFactorAuth);
    yield takeLatest(ENABLE_2FA, submitEnableTwoFactorAuth);
    yield takeLatest(SET_2FA_ACCESS_DENIED, accessDenied);
}

export default main;
