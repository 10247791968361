import React from 'react';

//material UI icons
import {
    ArrowBack,
    Call,
    CallEnd,
    Chat,
    Code,
    Dashboard,
    DeleteForever,
    Inbox,
    MailOutline,
    MoveToInbox,
    People,
    QuestionAnswer,
    Settings,
} from '@material-ui/icons';

import Spinner from 'react-spinkit';

//icon constants
export const INCOMING_ENQUIRIES_ICON = 'incoming.enquiries.icon';
export const LOAN_APPLICATIONS_INBOX_ICON = 'loan.applications.inbox.icon';
export const DASHBOARD_ICON = 'dashboard.icon';
export const SETTINGS_ICON = 'settings.icon';
export const TEXT_CHAT_ICON = 'textchat.icon';
export const CHAT_ICON = 'chat.icon';
export const CHAT_END_ICON = 'chat.end.icon';
export const WORKFLOW_ICON = 'workflow.icon';
export const SPINNER_ICON = 'spinner.icon';
export const CODE_ICON = 'code.icon';
export const PEOPLE_ICON = 'people.icon';
export const MAIL_ICON = 'mail.icon';
export const INBOX_ICON = 'inbox.icon';

export const REMOVE_ITEM_ICON = 'remove.item.icon';
export const NAVIGATE_BACK = 'navigate.back.item';

class IconFactory {
    createIcon = (name, ...other) => {
        switch (name) {
            case INCOMING_ENQUIRIES_ICON:
            case LOAN_APPLICATIONS_INBOX_ICON:
                return <MoveToInbox {...other} />;

            case SETTINGS_ICON:
                return <Settings {...other} />;

            case DASHBOARD_ICON:
                return <Dashboard {...other} />;

            case CODE_ICON:
                return <Code {...other} />;

            case PEOPLE_ICON:
                return <People {...other} />;

            case INBOX_ICON:
                return <Inbox {...other} />;

            case MAIL_ICON:
                return <MailOutline {...other} />;

            case REMOVE_ITEM_ICON:
                return <DeleteForever {...other} />;

            case NAVIGATE_BACK:
                return <ArrowBack {...other} />;

            case WORKFLOW_ICON:
                return <QuestionAnswer {...other} />;

            case TEXT_CHAT_ICON:
                return <Chat {...other} />;

            case CHAT_ICON:
                return <Call {...other} />;

            case CHAT_END_ICON:
                return <CallEnd {...other} />;

            case SPINNER_ICON:
                return <Spinner name={'circle'} {...other} key={'loading-spinner'} />;

            default:
                return <Dashboard {...other} />;
        }
    };
}

export default IconFactory;
