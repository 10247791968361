import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default class LECardSkeleton extends React.Component {
    render() {
        const { height, style = {} } = this.props;

        return (
            <div style={style}>
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="text" />
                <Skeleton variant="rect" height={height} />
            </div>
        );
    }
}
