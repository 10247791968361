import createP4ApolloFetch from '../bootstrap/middlewares';
import { PAGE_SIZE } from '../pitch4_enum/enum/DataGridEnum';
import { API_URL } from '../bootstrap/constants/constants';

export const getBrokers = (uri = API_URL) => (
    cursor = null,
    filters = [],
    first = PAGE_SIZE,
    sorts = [
        {
            column: 'createdAt',
            direction: 'desc',
        },
    ],
    storedDataFilters = []
) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    const query = `
        query getBrokers($cursor: String, $first: Int!, $sorts: [SortInput], $filters: [FilterInput]) {
            brokers(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                edges {
                    cursor
                    node {
                        id
                        name
                        primaryEmail
                        fcaIdentifier
                        companyNumber
                        status
                        createdAt
                        updatedAt
                        realTimeNotifications
                        primaryAddress {
                            address1
                            address2
                            address3
                            address4
                            country
                            postcode
                        }
                        primaryPhone {
                            number
                        }
                        hasDataProtectionLicence
                        isNetwork
                        parentBroker {
                          id
                          name
                          networkCode
                        }
                        joinedNetworkAt
                        isPartner
                    }
                }
                totalCount
            }
        }
    `;

    return apolloFetch({
        query: query,
        variables: {
            cursor,
            filters,
            first,
            sorts,
            storedDataFilters,
        },
    });
};
