import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../../../../bootstrap/constants/constants';
import { QUESTION_AGREE_TO_TERMS, QUESTION_LOAN_USAGE_LOCATION } from '../../../enums/Borrower/BorrowerQuestions';
import Question from '../../Question/containers/Question';
import YesNo from '../../../enums/Shared/YesNo';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BorrowerQuestions from '../../../enums/Borrower/BorrowerQuestions';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';

class LocationConfirmationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const yesNoOptions = new YesNo().getTypes();
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER
                            ? 'Please confirm where you intend to use the loan'
                            : 'Please confirm where your clients intend to use the loan'}
                    </Typography>
                </Grid>
                <Question
                    type={questions[QUESTION_LOAN_USAGE_LOCATION].type}
                    label={questions[QUESTION_LOAN_USAGE_LOCATION].label}
                    questionEnum={'QUESTION_LOAN_USAGE_LOCATION'}
                    fieldName={questions[QUESTION_LOAN_USAGE_LOCATION].fieldName}
                    placeholder={questions[QUESTION_LOAN_USAGE_LOCATION].placeholder}
                    validation={questions[QUESTION_LOAN_USAGE_LOCATION].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        By Submitting This Enquiry...
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}>
                    <p>
                        I hereby confirm that the information provided herein is accurate, correct and complete and that
                        the documents submitted along with and after submission of this form, are genuine.
                    </p>
                    <p>
                        I confirm that I have the required authority to submit this enquiry of behalf of all named
                        applicants.
                    </p>
                    <p>
                        I have read and agree to the{' '}
                        <a href={TOS_URL} target={'_blank'} rel="noreferrer">
                            Terms of Service
                        </a>{' '}
                        and{' '}
                        <a href={PRIVACY_POLICY_URL} target={'_blank'} rel="noreferrer">
                            Privacy Policy
                        </a>
                    </p>
                    <Question
                        type={questions[QUESTION_AGREE_TO_TERMS].type}
                        label={questions[QUESTION_AGREE_TO_TERMS].label}
                        questionEnum={'QUESTION_AGREE_TO_TERMS'}
                        fieldName={questions[QUESTION_AGREE_TO_TERMS].fieldName}
                        placeholder={questions[QUESTION_AGREE_TO_TERMS].placeholder}
                        validation={questions[QUESTION_AGREE_TO_TERMS].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={[]}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                </Grid>
                <Grid item xs={12} md={12}></Grid>
            </Grid>
        );
    }
}

export default LocationConfirmationContainer;
