import DateTimeFactory from '../../pitchblack_react_utils/datetime/DateTimeFactory';

const dtf = new DateTimeFactory();

class ChannelMessage {
    constructor() {
        this.id = null;
        this.senderId = null;
        this.type = null;
        this.userRole = null;
        this.content = null;
        this.channel = null;
        this.sentAt = null;
        this.userProfile = null;
    }

    getId = () => this.id;

    setId = (id) => {
        this.id = id;
        return this;
    };

    getSenderId = () => this.senderId;

    setSenderId = (senderId) => {
        this.senderId = senderId;
        return this;
    };

    getType = () => this.type;

    setType = (type) => {
        this.type = type;
        return this;
    };

    getUserRole = () => this.userRole;

    setUserRole = (type) => {
        this.userRole = type;
        return this;
    };

    getContent = () => this.content;

    setContent = (content) => {
        this.content = content;
        return this;
    };

    getChannel = () => this.channel;

    setChannel = (channel) => {
        this.channel = channel;
        return this;
    };

    getSentAt = () => this.sentAt;

    setSentAt = (sentAt) => {
        this.sentAt = sentAt;

        return this;
    };

    getUserProfile = () => {
        return this.userProfile;
    };

    setUserProfile = (userProfile) => {
        this.userProfile = userProfile;

        return this;
    };

    getTimestamp = () => {
        let dt = this.getSentAt();
        return dtf.create(dt).getUnix();
    };
}

export default ChannelMessage;
