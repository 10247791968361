import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { BrandTextFull, Subtitle1 } from '../../../pitch4_layout/components/text';
import styled from 'styled-components';
import { BRAND_NAME } from '../../../bootstrap/constants/constants';

const HeadingText = styled(Subtitle1)`
    padding-top: 1em;
    font-family: 'Roboto', sans-serif;
    padding-bottom: 1em;
`;

const StyledBulletList = styled.ul`
    margin-left: 3em;
    padding-bottom: 1em;
    padding-top: 0.5em;
`;

const StyledLi = styled.li`
    margin-top: 1em;
`;

const StyledContainer = styled.div`
    padding-bottom: 2em;
    font-family: 'Roboro', sans-serif;
    width: 80%;
    height: 100%;
    margin: 0 auto;
    background: white;
    -webkit-font-smoothing: antialiased;
`;

const StyledP = styled.p`
    padding-top: 0.3em;
    padding-bottom: 0.3em;
`;

class CookiePolicyContainer extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta
                        name="description"
                        content="{ PITCH4_BRAND_NAME } | property & business finance | free comparison"
                    />
                    <meta
                        name={`${BRAND_NAME} matches borrowers with specialist business & property lenders. Enter details, receive offers, chat and complete the finance. Simple & free.`}
                        content=""
                    />
                    <title>{BRAND_NAME} | property & business finance | free comparison | Cookie Policy</title>
                </Helmet>

                <Header />
                <StyledContainer className="container" style={{ backgroundColor: 'white' }}>
                    <div>
                        <div className="col-md-12 col-sm-12 col-xs-12" style={{ paddingTop: '1em' }}>
                            <h5>
                                <BrandTextFull />
                                &nbsp;Cookie Policy
                            </h5>
                            <div className="entry-content" style={{ paddingTop: '1em' }}>
                                <section>
                                    <div className="wpb_wrapper">
                                        <div className="last-paragraph-no-margin">
                                            <StyledP>
                                                A cookie is a small file of letters and numbers that we store on your
                                                browser or the hard drive of your computer if you agree. Cookies contain
                                                information that is transferred to your computer’s hard drive.
                                            </StyledP>
                                            <StyledP>We use the following cookies:</StyledP>
                                            <StyledBulletList>
                                                <StyledLi>
                                                    Strictly necessary cookies. These are cookies that are required for
                                                    the operation of our website. They include, for example, cookies
                                                    that enable you to log into secure areas of our website, use a
                                                    shopping cart or make use of e-billing services.
                                                </StyledLi>
                                                <StyledLi>
                                                    Analytical/performance cookies. They allow us to recognise and count
                                                    the number of visitors and to see how visitors move around our
                                                    website when they are using it. This helps us to improve the way our
                                                    website works, for example, by ensuring that users are finding what
                                                    they are looking for easily.
                                                </StyledLi>
                                                <StyledLi>
                                                    Functionality cookies. These are used to recognise you when you
                                                    return to our website. This enables us to personalise our content
                                                    for you, greet you by name and remember your preferences (for
                                                    example, your choice of language or region).
                                                </StyledLi>
                                                <StyledLi>
                                                    Targeting cookies. These cookies record your visit to our website,
                                                    the pages you have visited and the links you have followed. We will
                                                    use this information to make our website and the advertising
                                                    displayed on it more relevant to your interests. We may also share
                                                    this information with third parties for this purpose.
                                                </StyledLi>
                                            </StyledBulletList>
                                            <StyledP>
                                                You can find more information about the individual cookies we use and
                                                the purposes for which we use them in the table below:
                                            </StyledP>
                                            <HeadingText>
                                                <i>_cfduid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by CloudFlare, it is used to speed up page load
                                                times. This cookie does not contain any personal information. This is a
                                                third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>__utma</i>
                                            </HeadingText>
                                            <StyledP>
                                                The _utma cookie is part of Google analytics, and is primarily used to
                                                track visits to any site which uses Google analytics. _utma stores the
                                                number of visits made from your device, the time of the first visit, the
                                                previous visit, and the current visit. This cookie does not contain any
                                                personal information other than the IP address of your device. This is a
                                                third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>__utmb</i>
                                            </HeadingText>
                                            <StyledP>
                                                The _utmb cookie is part of Google analytics. __utmb and __utmc are used
                                                to check approximately how long you stay on any site which uses Google
                                                analytics: when a visit starts, and approximately ends. This cookie does
                                                not contain any personal information other than the IP address of your
                                                device. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>__utmc</i>
                                            </HeadingText>
                                            <StyledP>
                                                The _utmc cookie is part of Google analytics. __utmb and __utmc are used
                                                to check approximately how long you stay on any site which uses Google
                                                analytics: when a visit starts, and approximately ends. This cookie does
                                                not contain any personal information other than the IP address of your
                                                device. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>__utmt</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is set by Google Analytics. It is used to throttle the
                                                request rate for the service – limiting the collection of data on high
                                                traffic sites. It expires after 10 minutes. The main purpose of this
                                                cookie is: Performance. This cookie does not contain any personal
                                                information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>__utmz</i>
                                            </HeadingText>
                                            <StyledP>
                                                The __utmz cookie is part of Google analytics. __utmz keeps track of
                                                where the visitor came from, what search engine you used, what link you
                                                clicked on, what keyword you used, and where they were in the world when
                                                you accessed a website. This cookie is how Google Analytics knows to
                                                whom and to what source / medium / keyword to assign the credit for a
                                                Goal Conversion or an Ecommerce Transaction. This cookie does not
                                                contain any personal information other than the IP address of your
                                                device. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>_dc_gtm_UA-</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is part of Google Tag Manager and used to load other scripts
                                                and code into a page. Without it, other scripts may not function
                                                correctly. The end of the name is a unique number which is also an
                                                identifier for Borro’s Google Analytics account. This cookie does not
                                                contain any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>_ga</i>
                                            </HeadingText>
                                            <StyledP>
                                                The _ga cookie is part of Google analytics. _ga cookie is used to
                                                distinguish unique users by assigning a randomly generated number as a
                                                client identifier. This cookie does not contain any personal information
                                                other than the IP address of your device.
                                            </StyledP>
                                            <HeadingText>
                                                <i>_gid</i>
                                            </HeadingText>
                                            <StyledP>
                                                The _gid cookie is part of Google analytics. _gid cookie is used to
                                                distinguish unique users by assigning a randomly generated number as a
                                                client identifier. This cookie does not contain any personal information
                                                other than the IP address of your device. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>_mkto_trk</i>
                                            </HeadingText>
                                            <StyledP>
                                                The _mkto_trk cookie is used by Marketo. _mkto_trk cookie is used to
                                                link visitor behaviour to the recipient of an email marketing campaign,
                                                to measure campaign effectiveness. This cookie does not contain any
                                                personal information other than the IP address of your device. This is a
                                                third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>_uetsid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This website uses the Microsoft Bing remarketing service to advertise
                                                our products and services on other websites (including Bing) to previous
                                                visitors to our site. This could be in the form of an advertisement on
                                                the Bing search results page, or a site in the Bing Display Network. Any
                                                data collected will be used in accordance with our own privacy policy
                                                and Bing’s privacy policy. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>1P_JAR</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Google Adwords to allow user tracking. The main
                                                purpose of this cookie is Targeting/Advertising. This cookie does not
                                                contain any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Afid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first referring partner so we know
                                                what marketing efforts generating traffic to our website. It does not
                                                contain any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Bcookie</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Linkedin to allow user tracking. The main purpose
                                                of this cookie is Targeting/Advertising. This cookie does not contain
                                                any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>BizoID</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Linkedin to allow user tracking. The main purpose
                                                of this cookie is Targeting/Advertising. This cookie does not contain
                                                any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Buid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to identify visitors, it’s secure and unguessable it
                                                does not contain any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Cafid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first referring client affiliate or
                                                sub affiliate so we know what marketing efforts generating traffic to
                                                our website. It does not contain any personal information. This is a
                                                first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>cid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first referring campaign so we know
                                                what marketing efforts generating traffic to our website. It does not
                                                contain any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>COOKIE_SUPPORT</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record if your browser will accept cookies so we
                                                can tailor your experience on our website. It does not contain any
                                                personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>DUP</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Microsoft Bing to allow user tracking. The main
                                                purpose of this cookie is Targeting/Advertising. This cookie does not
                                                contain any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>GUEST_LANGUAGE_ID</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record your preferred language, it is currently
                                                unused. It does not contain any personal information. This is a first
                                                party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>IDE</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Google Adwords to allow user tracking. The main
                                                purpose of this cookie is Targeting/Advertising. This cookie does not
                                                contain any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>incap_ses_340_839751</i>
                                            </HeadingText>
                                            <StyledP>
                                                The incap_ses cookie is part of Incapsula our web application firewall,
                                                It is used to protect us against DDOS attacks, SQL injection and
                                                malicious requests. It does not contain any personal information. This
                                                is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>JSESSIONID</i>
                                            </HeadingText>
                                            <StyledP>
                                                This is an essential security cookie for the security of your online
                                                loan account. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Landed</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record when you first visited our website. We use
                                                this to understand how long it takes from first visit to making an
                                                enquiry. It does not contain any personal information. This is a first
                                                party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Landingpage</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first page you visited our website. We
                                                use this to understand which pages are convert better. It does not
                                                contain any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Lang</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Linkedin to allow user tracking. The main purpose
                                                of this cookie is Targeting/Advertising. This cookie does not contain
                                                any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>last_afid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first referring partner so we know
                                                what marketing efforts generating traffic to our website. It does not
                                                contain any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>last_cafid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the last referring client affiliate or sub
                                                affiliate so we know what marketing efforts generating traffic to our
                                                website. It does not contain any personal information. This is a first
                                                party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>last_cid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the last referring campaign so we know
                                                what marketing efforts generating traffic to our website. It does not
                                                contain any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>last_landed</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the last time you visited our website so
                                                we know what marketing efforts generating traffic to our website. It
                                                does not contain any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>last_landingpage</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the last page you landed on when you came
                                                to our site via an affiliate so we know what marketing efforts
                                                generating traffic to our website. It does not contain any personal
                                                information. This is a first party cookie.
                                            </StyledP>
                                            <StyledP>
                                                last_referrerThis cookie is used to record the last affiliate who
                                                directed you to our site so we know what marketing efforts generating
                                                traffic to our website. It does not contain any personal information.
                                                This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>last_scid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the last sub campaign so we know what
                                                marketing efforts generating traffic to our website. It does not contain
                                                any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>last_src</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the last referring website so we know what
                                                marketing efforts generating traffic to our website. It does not contain
                                                any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>last_utm_campaign</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the last adword campaign so we know what
                                                marketing efforts generating traffic to our website. It does not contain
                                                any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>last_utm_content</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the last adword content so we know can
                                                generate unique content for you on our website. It does not contain any
                                                personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Lidc</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Linked in to allow user tracking. The main
                                                purpose of this cookie is Targeting/Advertising. This cookie does not
                                                contain any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>MUID</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Microsoft to allow user tracking. The main
                                                purpose of this cookie is Targeting/Advertising. This cookie does not
                                                contain any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>MUIDB</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Microsoft to allow user tracking. The main
                                                purpose of this cookie is Targeting/Advertising. This cookie does not
                                                contain any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>NID</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Google Adwords to allow user tracking. The main
                                                purpose of this cookie is Targeting/Advertising. This cookie does not
                                                contain any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>nlbi_</i>
                                            </HeadingText>
                                            <StyledP>
                                                The nlbi cookie is part of Incapsula our web application firewall. It is
                                                used to protect us against DDOS attacks, SQL injection and malicious
                                                requests. It does not contain any personal information. This is a
                                                third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>PSMAFF</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Perfect Storm to help optimise our adword
                                                advertising campaigns. It does not contain any personal information.
                                                This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Safid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first referring affiliate so we know
                                                what marketing efforts generating traffic to our website. It does not
                                                contain any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Scid</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first sub campaign so we know what
                                                marketing efforts generating traffic to our website. It does not contain
                                                any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>searchTerms</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the search terms used to find our site. We
                                                use this to understand which pages are commonly found in search engines
                                                and for what terms. It does not contain any personal information. This
                                                is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Src</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first referring website so we know
                                                what marketing efforts generating traffic to our website. It does not
                                                contain any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>SRCHD</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used by Microsoft Bing to allow user tracking. The main
                                                purpose of this cookie is Targeting/Advertising. This cookie does not
                                                contain any personal information. This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Survey</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record if we have already asked you to take part
                                                in our survey. It does not contain any personal information. This is a
                                                first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>utm_campaign</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first adword campaign so we know what
                                                marketing efforts generating traffic to our website. It does not contain
                                                any personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>utm_content</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first adword content so we know can
                                                generate unique content for you on our website. It does not contain any
                                                personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>utm_medium</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first adword medium so we know can
                                                generate unique content for you on our website. It does not contain any
                                                personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>utm_source</i>
                                            </HeadingText>
                                            <StyledP>
                                                This cookie is used to record the first adword source so we know can
                                                generate unique content for you on our website. It does not contain any
                                                personal information. This is a first party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>visid_incap_</i>
                                            </HeadingText>
                                            <StyledP>
                                                The visid_incap cookie is part of Incapsula our web application
                                                firewall, It is used to protect us against DDOS attacks, SQL injection
                                                and malicious requests. It does not contain any personal information.
                                                This is a third-party cookie.
                                            </StyledP>
                                            <HeadingText>
                                                <i>Vsid</i>
                                            </HeadingText>
                                            <StyledP>
                                                The visid cookie is part of Incapsula our web application firewall, It
                                                is used to protect us against DDOS attacks, SQL injection and malicious
                                                requests. It does not contain any personal information. This is a
                                                third-party cookie.
                                            </StyledP>
                                            <StyledP>
                                                Please note that third parties (including, for example, advertising
                                                networks and providers of external services like web traffic analysis
                                                services) may also use cookies, over which we have no control. These
                                                cookies are likely to be analytical/performance cookies or targeting
                                                cookies.
                                            </StyledP>
                                            <StyledP>
                                                You block cookies by activating the setting on your browser that allows
                                                you to refuse the setting of all or some cookies. However, if you use
                                                your browser settings to block all cookies (including essential cookies)
                                                you may not be able to access all or parts of our site.
                                            </StyledP>
                                            <StyledP>
                                                If you want to delete any cookies that are already on your computer,
                                                please refer to the instructions for your file management software to
                                                locate the file or directory that stores cookies. Information on
                                                deleting or controlling cookies is available at www.AboutCookies.org.
                                                Please note that by deleting our cookies or disabling future cookies you
                                                may not be able to access certain areas or features of our site.
                                            </StyledP>
                                            <br />
                                            <h6>Contact information</h6>
                                            <StyledP>
                                                If you have any questions in relation to this policy, please contact us
                                                at:
                                            </StyledP>
                                            <StyledBulletList>
                                                <StyledLi>
                                                    <BrandTextFull />
                                                    &nbsp;Ltd, Unit 1, Verney House, 1B Hollywood Road, SW10 9HS
                                                </StyledLi>
                                                <StyledLi>Tel No: 020 7495 6215</StyledLi>
                                                <StyledLi>
                                                    enquiries@providefinance.com Reference / subject COMMUNICATIONS
                                                </StyledLi>
                                            </StyledBulletList>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </StyledContainer>
                <Footer />
            </div>
        );
    }
}

export default CookiePolicyContainer;
