import React from 'react';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { Grid, Tooltip } from '@material-ui/core';

export default function DirtyTermIndicator(props) {
    const { isDirty, value } = props;
    return (
        <Grid item lg={1} md={1} sm={1} xs={1} className={'value'}>
            {true === isDirty ? (
                <Tooltip title="Change detected" placement="top">
                    <InfoRoundedIcon className={'dirty'} />
                </Tooltip>
            ) : (
                <CheckCircleRoundedIcon className={null !== value ? 'filled' : 'empty'} />
            )}
        </Grid>
    );
}
