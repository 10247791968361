import { API_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { requestFirst } from './config';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const GetDashboardListQuery = `
    query getLoanEnquiriesWithStatistics($cursor: String, $first: Int!, $sorts: [SortInput], $filters: [FilterInput]) {
      loanEnquiriesWithStatistics(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
        edges {
          node {
            id
            borrowerReference
            alertActive
            loanAmount
            channel {
              id
              alertActive
            }
            isNewForm
            loanEnquiryData {
                loanToValue
                loanToCost
                loanToGrossDevelopmentValue
                dayOneLoanAmountPercentage
                additionalInformation
                rentalIncomeResidential
                hmoBedroom
                hmoRoomsSelfContained
                hmoHasLicense
                hmoNeedsLicense
                buyToLetExperience
                rentalIncomeCommercial
                rentalIncomePortfolio
                rentalIncome
                commercialUnitsRentedOut
                commercialUnitOwnerOccupied
                commercialUnitAmount
                commercialType
                commercialTypeOther
                siteDescription
                siteIntention
                refinance
                businessSeasonal
                businessType
                businessName
                businessWebsite
                businessTradingLength
                businessSector
                businessFacilityNeededBy
                businessFacilityTerm
                businessCurrentPaymentTerms
                businessOutstandingInvoices
                businessPdqIncome
                propertyType
                loanAmount
                loanPurpose
                turnOver
                businessProfitable
                businessOverdraftFacility
                businessOutstandingDebt
                businessAdverseCredit
                businessAdverseCreditExplanation
                businessDirectorsAdverseCredit
                businessDirectorsAdverseCreditExplanation
                shareholdersAreHomeOwners
                propertyAvailableForAdditionalSecurity
                planningFor
                developmentLength
                refurbishmentLength
                outstandingLoan
                dayOneLoanAmount
                unitsPreSold
                worksExplanation
                totalDevelopmentCosts
                buildCostsBorrowing
                expectedGrossDevelopmentValue
                previousDevelopmentExperience
                previousRefurbishmentExperience
                teamDevelopmentExperience
                epcRating
                loanType
                fundingType
                businessSecuredFinance
                loanUsedInUkEu
                planningInPlace
                endValue
                percentageResidential
                percentageCommercial
                lender
                firstChargeAmount
                currentValue
                properties
                portfolioValue
                portfolioAlreadyOwned
                loanTerm
                loanNeededBy
                preApproveMatches
                loanAmountRefurbishment
                percentageResidentialSquareFeet
                percentageResidentialValue
                percentageCommercialValue
                residentialUnitCount
                residentialUnitValue
                businessOccupyEntireProperty
                commercialUnitRentBreakdown
                commercialTenantAmount
                ownsOtherCommercialProperty
                ownOtherProperties
                occupyProperty
                ownsOtherInvestmentProperties
                familyHasOccupied
                secondCharge
                occupyingGreaterThan40Percent
                location
                locationOther
                address
                postcode
                propertyOwnership
                refurbishmentCost
                invoiceFinanceType
                leaseHirePurchase
                mainApplicantCreditDifficulties
                mainApplicantCreditDifficultiesExplanation
                mainApplicantCitizenshipStatus
                mainApplicantCountryOfResidence
                mainApplicantCountryOfResidenceOther
                mainApplicantNationality
                mainApplicantNationalityOther
                mainApplicantOccupation
                mainApplicantGrossIncome
                mainApplicantExitStrategy
                agreeToTerms
            }
            storedData{
              meta{
                key
              }
              value
            }
            submissionData {
              rawSubmission
            }
            submittedBy {
              id
              email
              userProfile {
                title
                given
                family
              }
              roles {
                name
                label
              }
            }
            lenderLoanEnquiries {
              id
            }
            loanEnquiryApplicants {
                isMainApplicant
                applicant {
                    given
                    family
                    dateOfBirth
                }
            }
            status
            createdAt
            updatedAt
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
          startCursor
          hasPreviousPage
        }
        totalCount
        totalValue
        borrowerCount
        intermediaryCount
      }
    }
`;

export const getDashboardListUnassugned = (first = requestFirst, cursor) => {
    return apolloFetch({
        query: GetDashboardListQuery,
        variables: {
            sorts: [],
            filters: [
                {
                    column: 'leAssignedActive',
                    not: false,
                    operand: 'true',
                    operation: 'no_owner',
                },
                {
                    column: 'status',
                    operation: 'eq',
                    not: true,
                    operand: 'draft',
                },
                {
                    column: 'status',
                    operation: 'eq',
                    not: true,
                    operand: 'withdrawn',
                },
                {
                    column: 'status',
                    operation: 'eq',
                    not: true,
                    operand: 'withdrawn_fully',
                },
                {
                    column: 'status',
                    operation: 'eq',
                    not: true,
                    operand: 'withdrawn_edited',
                },
                {
                    column: 'status',
                    operation: 'eq',
                    not: true,
                    operand: 'completion_timed_out',
                },
                {
                    column: 'status',
                    operation: 'eq',
                    not: true,
                    operand: 'completed',
                },
                {
                    column: 'status',
                    operation: 'eq',
                    not: true,
                    operand: 'lender_declined',
                },
                {
                    column: 'status',
                    operation: 'eq',
                    not: true,
                    operand: 'ended',
                },
                {
                    column: 'status',
                    operation: 'eq',
                    not: true,
                    operand: 'on_hold',
                },
            ],
            first: first,
            pages: [],
            cursor: cursor,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
