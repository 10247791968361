import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import { BRAND_MAIN_CONTACT } from '../../../../../bootstrap/constants/constants';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { SubmissionError } from 'redux-form';
import { P4StyleComponentContainer, Container, RowItem } from '../../../../../pitch4_layout/components/div';
import { QueryMutation } from '../../../../../pitch4_forms/components';
import Mapper from '../form/Mapper';
import Form from '../components/Form';
import styled from 'styled-components';
import LogoUploadContainer from './LogoUploadContainer';

const StyledRowItem = styled(RowItem)`
    padding-bottom: 20px;
`;

class EditContainer extends React.Component {
    mapValues = (data) => {
        let mapper = new Mapper();

        let mappedValues = {};

        if (data && data.lender) {
            mappedValues = mapper.mapToForm(data.lender);
        }

        return mappedValues;
    };

    render() {
        let { match } = this.props;

        return (
            <Container className={`account-settings`}>
                <StyledRowItem className={`account-settings-item`}>
                    <P4StyleComponentContainer
                        header={`Edit Lender`}
                        content={
                            <QueryMutation
                                query={gql`
                                    query getLender($id: ID!) {
                                        lender(id: $id) {
                                            id
                                            name
                                            primaryEmail
                                            fcaIdentifier
                                            companyNumber
                                            status
                                            createdAt
                                            updatedAt
                                            profileUrl
                                            profileDescription
                                            profileEnabled
                                            profileLogo
                                            profilePrimaryColor
                                            primaryAddress {
                                                address1
                                                address2
                                                address3
                                                address4
                                                country
                                                postcode
                                            }
                                            primaryPhone {
                                                number
                                            }
                                            offPanel
                                        }
                                    }
                                `}
                                queryContext={{
                                    uri: API_URL,
                                }}
                                queryVariables={{
                                    id: match.params.id,
                                }}
                                mutationQuery={gql`
                                    mutation EditLender($id: ID!, $input: pitch4admin_editLenderInput!) {
                                        editLender(id: $id, input: $input) {
                                            lender {
                                                id
                                                name
                                                primaryEmail
                                                fcaIdentifier
                                                companyNumber
                                                status
                                                createdAt
                                                updatedAt
                                                profileUrl
                                                profileDescription
                                                profileEnabled
                                                profilePrimaryColor
                                                primaryAddress {
                                                    address1
                                                    address2
                                                    address3
                                                    address4
                                                    country
                                                    postcode
                                                }
                                                primaryPhone {
                                                    number
                                                }
                                                offPanel
                                            }
                                        }
                                    }
                                `}
                                renderFormFunction={(mappedValues, mutate, refetch, props) => {
                                    return (
                                        <Form
                                            initialValues={mappedValues}
                                            onSubmit={this.onSubmit(mutate, refetch, props)}
                                        />
                                    );
                                }}
                                mapQueryFunction={this.mapValues}
                            />
                        }
                    />
                </StyledRowItem>
                <StyledRowItem>
                    <LogoUploadContainer lenderId={{ lander: { id: match.params.id } }} />
                </StyledRowItem>
            </Container>
        );
    }

    onSubmit = (mutate, refetch, props) => (values, dispatch, formProps) => {
        let { notificationSuccess, notificationError } = props;
        let mapper = new Mapper();

        let mappedValues = mapper.mapFromForm(values);

        //map the variables if necessary
        return mutate({
            variables: {
                id: values.id,
                input: mappedValues,
            },
            context: {
                uri: API_URL,
            },
        })
            .then(({ data }) => {
                refetch();
                notificationSuccess('Lender updated successfully');
            })
            .catch((error) => {
                notificationError('Unable to save lender data. Contact support on ' + BRAND_MAIN_CONTACT);
                throw new SubmissionError({ _error: error.graphQLErrors });
            });
    };
}

export default compose(withRouter, renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN]))(EditContainer);
