import React from 'react';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import AddToContextStoreForm from '../../documents_widget/components/AddToContextStoreForm';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import { ROLE_INTERMEDIARY } from '../../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import { connect } from 'react-redux';
import ModalLayout from '../../../../../pitch4_modal/src/component/ModalLayout';

class UploadDocumentButton extends React.Component {
    openAddDocumentModal = () => {
        let {
            setModalClose,
            setModalComponent,
            setModalInteractive,
            successNotification,
            errorNotification,
            lenderLoanEnquiryId = null,
            createMetaMutate,
            outerRefetch = () => {},
            createPermissionMutate,
            toggleDocumentSentModal,
        } = this.props;

        setModalComponent(
            <ModalLayout title="Add Document">
                <AddToContextStoreForm
                    id={lenderLoanEnquiryId}
                    onPending={() => setModalInteractive(false)}
                    onSuccess={(message) => {
                        successNotification(message);
                        outerRefetch();
                        setModalClose();
                        if (toggleDocumentSentModal) {
                            toggleDocumentSentModal(true);
                        }
                    }}
                    onError={(error) => {
                        errorNotification(error);
                        setModalInteractive(true);
                    }}
                    createMetaMutate={createMetaMutate}
                    createPermissionMutate={createPermissionMutate}
                    contextDocsQuery={gql`
                        query getLenderLoanEnquiryDocuments($count: Int!, $filters: [FilterInput]) {
                            documents(first: $count, filters: $filters) {
                                edges {
                                    node {
                                        id
                                        fileName
                                        type
                                    }
                                    cursor
                                }
                                pageInfo {
                                    hasNextPage
                                    endCursor
                                    startCursor
                                    hasPreviousPage
                                }
                                totalCount
                            }
                        }
                    `}
                />
            </ModalLayout>
        );
    };

    render() {
        let { buttonRender } = this.props;
        return buttonRender(() => this.openAddDocumentModal());
    }
}

const CreateMultipleLenderLoanEnquiryDocument = gql`
    mutation CreateMultipleLenderLoanEnquiryDocument($input: intermediary_createMultiDocumentInput!) {
        createMultiLenderLoanEnquiryDocument(input: $input) {
            document {
                id
                uploadUrl
            }
        }
    }
`;

const CreateLenderLoanEnquiryDocumentPermissionMutation = gql`
    mutation createDocumentPermission($id: ID!, $input: intermediary_createDocumentPermissionInput!) {
        createLenderLoanEnquiryDocumentPermission(id: $id, input: $input) {
            documentPermission {
                id
            }
        }
    }
`;

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        successNotification: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        errorNotification: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    graphql(CreateMultipleLenderLoanEnquiryDocument, {
        options() {
            return {
                context: {
                    uri: INTERMEDIARY_URL,
                },
            };
        },
        name: 'createMetaMutate',
    }),
    graphql(CreateLenderLoanEnquiryDocumentPermissionMutation, {
        options() {
            return {
                context: {
                    uri: INTERMEDIARY_URL,
                },
            };
        },
        name: 'createPermissionMutate',
    }),
    renderErrorIfAuthorisationFail([ROLE_INTERMEDIARY])
)(UploadDocumentButton);
