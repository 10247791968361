import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });

const LoanEnquirySetDeleteMutation = `
  mutation deleteLoanEnquiry($id: ID!) {
        deleteLoanEnquiry(id: $id) {
            success
        }
    }
`;

export const deleteLoanEnquiry = (loanEnquiryId) => {
    return apolloFetch({
        query: LoanEnquirySetDeleteMutation,
        variables: {
            id: loanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
