import { BaseEnum } from '../../../../../pitch4_enum';

export const TYPE_WORKING_CAPITAL = 'working_capital';
export const TYPE_EXPANSION_GROWTH = 'expansion_growth';
export const TYPE_STARTUP = 'start_up';
export const TYPE_REFINANCING = 'refinancing';
export const TYPE_TAX = 'tax';
export const TYPE_CASH_FLOW = 'cash_flow';
export const TYPE_STOCK = 'stock';
export const TYPE_SUPPLY_CHAIN = 'supply_chain';
export const TYPE_ASSET_PURCHASE = 'asset_purchase';
export const TYPE_ASSET_REFINANCE = 'asset_refinance';
export const TYPE_ACQUISITION = 'acquisition';
export const TYPE_MANAGEMENT_BUY_IN_OUT = 'management_buy_in_out';

class BusinessFinanceLoanPurposeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_WORKING_CAPITAL]: 'Working Capital',
            [TYPE_EXPANSION_GROWTH]: 'Expansion/Growth',
            [TYPE_STARTUP]: 'Start up funding',
            [TYPE_REFINANCING]: 'Refinancing',
            [TYPE_TAX]: 'Tax Payments',
            [TYPE_CASH_FLOW]: 'Cash flow',
            [TYPE_STOCK]: 'Purchasing stock',
            [TYPE_SUPPLY_CHAIN]: 'Supply Chain',
            [TYPE_ASSET_REFINANCE]: 'Asset refinance',
            [TYPE_ASSET_PURCHASE]: 'Asset purchase',
            [TYPE_ACQUISITION]: 'Acquisition',
            [TYPE_MANAGEMENT_BUY_IN_OUT]: 'Management buy-in / management buy-out',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default BusinessFinanceLoanPurposeEnum;
