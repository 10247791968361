import React, { useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import chroma from 'chroma-js';
import { getAvailableTags } from '../../pitch4_api/get_available_tags';
import LETagProperties from './LETagProperties';
import _ from 'lodash';
import { addLETags } from '../../pitch4_api/add_le_tags';
import { removeLETags } from '../../pitch4_api/remove_le_tags';

export default function LETags(props) {
    const { leId, uri, leTags, setModalComponent, setModalClose } = props;
    const [selectedTags, setSelectedTags] = useState(leTags);
    const [availableTags, setAvailableTags] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleChange = (newTags) => {
        setIsDisabled(true);
        setIsLoading(true);

        const oldTags = selectedTags;

        if (oldTags.length > newTags.length) {
            const tags = _.difference(oldTags, newTags);
            removeLETags(
                uri,
                leId,
                tags.map((tag) => {
                    return { id: tag.id };
                })
            ).then(() => {
                setSelectedTags(newTags);
                setIsLoading(false);
                setIsDisabled(false);
            });
        } else {
            const tags = _.difference(newTags, oldTags);
            addLETags(
                uri,
                leId,
                tags.map((tag) => {
                    return { id: tag.id };
                })
            ).then(() => {
                setSelectedTags(newTags);
                setIsLoading(false);
                setIsDisabled(false);
            });
        }
    };
    const handleCreate = (tag) => {
        setModalComponent(
            <LETagProperties
                updateSelectedTags={updateSelectedTags}
                updateAvailableTags={updateAvailableTags}
                tag={tag}
                uri={uri}
                leId={leId}
                selectedTags={selectedTags}
                availableTags={availableTags}
                setModalClose={setModalClose}
            />,
            false
        );
    };
    const colourStyles = {
        control: (styles) => {
            return { ...styles, backgroundColor: 'white' };
        },
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color || 'black');
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? data.color
                    : isFocused
                    ? color.alpha(0.1).css()
                    : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,
                cursor: isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled ? (isSelected ? data.color : color.alpha(0.3).css()) : undefined,
                },
            };
        },
        multiValue: (styles, { data }) => {
            const color = chroma(data.color || 'black');
            return {
                ...styles,
                backgroundColor: color.alpha(0.1).css(),
            };
        },
        multiValueLabel: (styles, { data }) => {
            return {
                ...styles,
                color: data.color || 'black',
            };
        },
        multiValueRemove: (styles, { data }) => {
            return {
                ...styles,
                color: data.color || 'black',
                ':hover': {
                    backgroundColor: data.color || 'black',
                    color: 'white',
                },
            };
        },
    };
    const updateSelectedTags = (newLETags) => setSelectedTags(newLETags);
    const updateAvailableTags = (newAvailableTags) => setAvailableTags(newAvailableTags);

    useEffect(() => {
        setIsLoading(true);
        setIsDisabled(true);
        getAvailableTags(uri)().then((res) => {
            setAvailableTags(res.data.tags);
            setIsLoading(false);
            setIsDisabled(false);
        });
    }, []);

    return (
        <div className={'le-tags-container'}>
            <CreatableSelect
                closeMenuOnSelect={false}
                isSearchable={true}
                isMulti={true}
                isLoading={isLoading}
                isDisabled={isDisabled}
                options={availableTags}
                value={selectedTags}
                onChange={(tags) => handleChange(tags)}
                onCreateOption={(data) => handleCreate(data)}
                styles={colourStyles}
            />
        </div>
    );
}
