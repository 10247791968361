import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import styled from 'styled-components';
import { reduxForm } from 'redux-form';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import withSession from 'pitchblack_react_utils/session/withSession';
import { TextField, FieldRowInputOnly } from 'pitch4_forms/components/inputs';
import {
    isRequired,
    hasMinLength2,
    hasMaxLength255,
    isEmail,
    hasMaxLength30,
    hasMinLength6,
    isValidPostcode,
} from 'pitchblack_react_utils/form/validation';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import { NoQueryMutation } from 'pitch4_forms/components';
import { API_URL } from 'bootstrap/constants/constants';
import Mapper from '../form/Mapper';
import { RightAligned } from 'pitch4_layout/components/div';
import { SecondaryButton } from 'pitch4_layout/components/buttons';

const ScrollableFields = styled.div`
    max-height: 400px;
    overflow-y: scroll;
    clear: both;
`;

const StyledForm = styled.form`
    width: 400px;
    padding: 20px 30px;
`;

class CreateContainer extends React.Component {
    render() {
        let { handleSubmit, submitting, reset } = this.props;

        return (
            <NoQueryMutation
                mutationQuery={gql`
                    mutation createLender($input: pitch4admin_createLenderInput!) {
                        createLender(input: $input) {
                            lender {
                                id
                                name
                            }
                        }
                    }
                `}
                renderFormFunction={(mutate, props) => (
                    <StyledForm onSubmit={handleSubmit(this.onSubmit(mutate, props))}>
                        <ScrollableFields>
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        fullWidth={true}
                                        name="primaryEmail"
                                        placeholder="Company Email Address"
                                        disabled={submitting}
                                        validate={[isRequired, isEmail]}
                                    />
                                }
                            />
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        fullWidth={true}
                                        name="name"
                                        placeholder="Company name"
                                        disabled={submitting}
                                        validate={[isRequired, hasMinLength2]}
                                    />
                                }
                            />
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        name="fcaIdentifier"
                                        placeholder="FCA Number"
                                        disabled={submitting}
                                        validate={[hasMinLength2]}
                                    />
                                }
                            />
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        name="companyNumber"
                                        placeholder="Company Number"
                                        disabled={submitting}
                                        validate={[hasMinLength2]}
                                    />
                                }
                            />
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        name="primaryAddressAddress1"
                                        placeholder="Address 1"
                                        disabled={submitting}
                                        validate={[hasMinLength2, hasMaxLength255]}
                                    />
                                }
                            />
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        name="primaryAddressAddress2"
                                        placeholder="Address 2"
                                        disabled={submitting}
                                        validate={[hasMinLength2, hasMaxLength255]}
                                    />
                                }
                            />
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        name="primaryAddressAddress3"
                                        placeholder="Address 3"
                                        disabled={submitting}
                                        validate={[hasMinLength2, hasMaxLength255]}
                                    />
                                }
                            />
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        name="primaryAddressAddress4"
                                        placeholder="Address 4"
                                        disabled={submitting}
                                        validate={[hasMinLength2, hasMaxLength255]}
                                    />
                                }
                            />
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        name="primaryAddressCountry"
                                        placeholder="Country"
                                        disabled={submitting}
                                        validate={[hasMinLength2, hasMaxLength255]}
                                    />
                                }
                            />
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        name="primaryAddressPostcode"
                                        placeholder="Postcode"
                                        disabled={submitting}
                                        validate={[isValidPostcode]}
                                    />
                                }
                            />
                            <FieldRowInputOnly
                                input={
                                    <TextField
                                        name="primaryPhoneNumber"
                                        placeholder="Number"
                                        disabled={submitting}
                                        validate={[hasMinLength6, hasMaxLength30]}
                                    />
                                }
                            />
                        </ScrollableFields>
                        <RightAligned>
                            <SecondaryButton disabled={submitting} onClick={reset}>
                                Reset
                            </SecondaryButton>
                            <PrimaryButton type="submit" disabled={submitting}>
                                Submit
                            </PrimaryButton>
                        </RightAligned>
                    </StyledForm>
                )}
            />
        );
    }

    onSubmit = (mutate, props) => (values) => {
        const { inProgress, onSuccess, onFailure } = this.props;
        const { notificationSuccess, notificationError } = props;

        inProgress();

        let mapper = new Mapper();

        let mappedValues = mapper.mapFromForm(values);

        if (!('profileEnabled' in mappedValues)) {
            mappedValues.profileEnabled = true;
        }

        //map the variables if necessary
        return mutate({
            variables: {
                input: mappedValues,
            },
            context: {
                uri: API_URL,
            },
        })
            .then(({ data }) => {
                notificationSuccess('Lender created');
                onSuccess(data);
            })
            .catch((error) => {
                notificationError('Unable to create lender. Please contact support.');
                onFailure(error);
            });
    };
}

CreateContainer.propTypes = {
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    reset: PropTypes.func,
    inProgress: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(
    reduxForm({
        form: 'pitch4admin_create_lender',
        enableReinitialize: true,
        updateUnregisteredFields: false,
    }),
    withSession,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(CreateContainer);
