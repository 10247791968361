import { slugify } from '../../../../../pitchblack_react_utils/text/Slugify';

class Mapper {
    mapToForm = (props) => {
        let {
            name = '',
            primaryEmail = '',
            fcaIdentifier = null,
            companyNumber = '',
            primaryAddress: { address1 = '', address2 = '', address3 = '', address4 = '', postcode = '', country = '' },
            primaryPhone: { number = '' },
            hasDataProtectionLicence = 'false',
            isNetwork = 'false',
            brokerNetwork = '',
        } = props;
        hasDataProtectionLicence = hasDataProtectionLicence === 'false' ? 0 : 1;
        isNetwork = isNetwork === 'false' ? 0 : 1;
        return {
            name,
            fcaIdentifier,
            companyNumber,
            primaryEmail,
            primaryAddressAddress1: address1,
            primaryAddressAddress2: address2,
            primaryAddressAddress3: address3,
            primaryAddressAddress4: address4,
            primaryAddressCountry: country,
            primaryAddressPostcode: postcode,
            primaryPhoneNumber: number,
            hasDataProtectionLicence,
            isNetwork,
            brokerNetwork,
        };
    };

    mapFromForm = ({
        name = '',
        primaryEmail = '',
        fcaIdentifier = null,
        companyNumber = '',
        primaryAddressAddress1 = '',
        primaryAddressAddress2 = '',
        primaryAddressAddress3 = '',
        primaryAddressAddress4 = '',
        primaryAddressCountry = '',
        primaryAddressPostcode = '',
        primaryPhoneNumber = '',
        hasDataProtectionLicence = 'false',
        isNetwork = 'false',
        brokerNetwork = 'undefined',
    }) => {
        hasDataProtectionLicence = hasDataProtectionLicence !== false;
        return {
            name,
            primaryEmail,
            fcaIdentifier,
            companyNumber,
            primaryAddress: {
                address1: primaryAddressAddress1,
                address2: primaryAddressAddress2,
                address3: primaryAddressAddress3,
                address4: primaryAddressAddress4,
                postcode: primaryAddressPostcode,
                country: primaryAddressCountry,
            },
            primaryPhone: {
                number: primaryPhoneNumber,
            },
            hasDataProtectionLicence,
            isNetwork,
            brokerNetwork: brokerNetwork === 'undefined' ? null : brokerNetwork,
            networkCode: isNetwork === true ? slugify(name) : null,
        };
    };
}

export default Mapper;
