import BaseEnum from 'pitchblack_react_utils/enums/BaseEnum';

export const EVENT_OUTCOME_SUCCESS = 'EVENT_OUTCOME_SUCCESS';
export const EVENT_OUTCOME_FAILED = 'EVENT_OUTCOME_FAILED';
export const EVENT_OUTCOME_UNKNOWN = 'EVENT_OUTCOME_UNKNOWN';

class OutcomeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [EVENT_OUTCOME_SUCCESS]: 'Success',
            [EVENT_OUTCOME_FAILED]: 'Failed',
            [EVENT_OUTCOME_UNKNOWN]: '',
        };
    };
}

export default OutcomeEnum;
