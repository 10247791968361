import { API_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const setDigestNotifications = (uri = API_URL, userId, status) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation setUserDigestNotifications($id: ID!, $status: Boolean!) {
          setUserDigestNotifications(id: $id, status: $status) {
             success
          }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            id: userId,
            status: status,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
