import { path } from './src/findRoute';
import {
    PRODUCT_COMMERCIAL_MORTGAGES,
    PRODUCT_RESIDENTIAL_INVESTMENT_PROPERTIES,
    PRODUCT_BRIDGING_FINANCE,
    PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS,
    PRODUCT_HMO_LENDING,
    PRODUCT_BUSINESS_FINANCE,
    PRODUCT_CARE_HOME,
} from '../pitch4_enum/enum/ProductEnum';
import { PRIVACY_POLICY_URL, WEB_HELP_HOST, WEB_HOST } from '../bootstrap/constants/constants';
import {
    NETWORK_BROKER_CONNECT,
    NETWORK_BROKER_WEALTHMAX,
    NETWORK_BROKER_WARREN_FINANCE_LTD,
    NETWORK_BROKER_ACCESS_FINANCIAL_SERVICES,
    NETWORK_BROKER_GIFTWARE,
} from '../pitch4_enum/enum/NetworkBrokerEnum';

/**
 * PUBLIC ROUTES
 */
export const ROUTE_LANDING = `/`;
export const ROUTE_PRIVACY_POLICY = PRIVACY_POLICY_URL;
export const ROUTE_COOKIE_POLICY = `/cookie-policy`;
export const ROUTE_2FA_RESET = `/two-factor-auth-reset/:token`;
export const ROUTE_2FA_RESET_SUCCESS = `/two-factor-auth-reset/:token/success`;
export const ROUTE_ANON_LOGIN = `/login`;
//Network Brokers
export const ROUTE_ANON_LOGIN_CONNECT = `/${NETWORK_BROKER_CONNECT}`;
export const ROUTE_ANON_LOGIN_WEALTHMAX = `/${NETWORK_BROKER_WEALTHMAX}`;
export const ROUTE_ANON_LOGIN_WARREN_FINANCE_LTD = `/${NETWORK_BROKER_WARREN_FINANCE_LTD}`;
export const ROUTE_ANON_LOGIN_ACCESS_FINANCIAL_SERVICES = `/${NETWORK_BROKER_ACCESS_FINANCIAL_SERVICES}`;
export const ROUTE_ANON_LOGIN_GIFTWARE = `/${NETWORK_BROKER_GIFTWARE}`;
//End Network Brokers
export const ROUTE_ANON_2FA_LOGIN = `/login/two-factor`;
export const ROUTE_BORROWER_REGISTRATION_DEFAULT = `/register`;
export const ROUTE_BORROWER_REGISTRATION_COMMERCIAL_MORTGAGES = '/register/' + PRODUCT_COMMERCIAL_MORTGAGES;
export const ROUTE_BORROWER_REGISTRATION_RESIDENTIAL_INVESTMENT_PROPERTIES =
    '/register/' + PRODUCT_RESIDENTIAL_INVESTMENT_PROPERTIES;
export const ROUTE_BORROWER_REGISTRATION_BRIDGING_FINANCE = '/register/' + PRODUCT_BRIDGING_FINANCE;
export const ROUTE_BORROWER_REGISTRATION_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS =
    '/register/' + PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS;
export const ROUTE_BORROWER_REGISTRATION_CARE_HOME = '/register/' + PRODUCT_CARE_HOME;
export const ROUTE_BORROWER_REGISTRATION_HMO_LENDING = '/register/' + PRODUCT_HMO_LENDING;
export const ROUTE_BORROWER_REGISTRATION_BUSINESS_FINANCE = '/register/' + PRODUCT_BUSINESS_FINANCE;
export const ROUTE_INTERMEDIARY_REGISTRATION = `/register/intermediary`;
export const ROUTE_PITCH4_KNOWLEDGE_BASE = `${WEB_HELP_HOST}/en/knowledge`;
export const ROUTE_PITCH4_RESOURCES_FOR_INTERMEDIARIES = `${WEB_HELP_HOST}/en/knowledge/intermediary-resources`;
export const ROUTE_PITCH4_RESOURCES_FOR_LENDERS = `${WEB_HELP_HOST}/en/knowledge/lender-resources`;
export const ROUTE_PITCH4_NEW_FEATURES_2021 = `${WEB_HELP_HOST}/en/knowledge/new-features-in-2021`;
export const ROUTE_PITCH4_PLATFORM_FEEDBACK_LENDER = `${WEB_HOST}/lender-feedback-survey/`;
export const ROUTE_PITCH4_KNOWLEDGE_BASE_NEW_TEAM_MEMBER_INTERMEDIARY = `${WEB_HELP_HOST}/en/knowledge/how-to-add-a-team-member`;
export const ROUTE_PITCH4_KNOWLEDGE_BASE_NEW_TEAM_MEMBER_LENDER = `${WEB_HELP_HOST}/en/knowledge/how-to-add-a-team-member-1`;
export const ROUTE_PITCH4_KNOWLEDGE_BASE_CREATE_ENQUIRY = `${WEB_HELP_HOST}/en/knowledge/lesson-1-how-to-create-your-first-enquiry`;
export const ROUTE_PITCH4_BLOG = `${WEB_HOST}/borrowers/blog/`;
export const ROUTE_PITCH4_HOW_TO_SETUP_BUSINESS_STREAMS = `${WEB_HELP_HOST}/en/knowledge/how-should-i-setup-my-business-streams`;
export const ROUTE_PITCH4_PROCESSES = `${WEB_HELP_HOST}/en/knowledge/the-provide-process`;

/**
 * PUBLIC PASSWORD RESET ROUTES
 */
export const ROUTE_REQUEST_PASSWORD_RESET = `/forgot-password`;
export const ROUTE_PASSWORD_RESET = `/reset-password/:token`;

/**
 * PUBLIC VERIFY EMAIL ROUTES
 */
export const ROUTE_VERIFY_EMAIL = `/verify-email/:token`;

/**
 * P4A ROUTES
 */
export const ROUTE_BC_ROOT = `/admin`;
export const ROUTE_P4A_ROOT = `/pitch4admin`;
export const ROUTE_P4A_DASHBOARD = ROUTE_P4A_ROOT; //`${ROUTE_P4A_ROOT}/dashboard`;
export const ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW = `${ROUTE_P4A_ROOT}/loanenquiries`;
export const ROUTE_P4A_CONFIGURE_PROFILE = `${ROUTE_P4A_ROOT}/profile`;
export const ROUTE_P4A_2FA_INDEX = `${ROUTE_P4A_ROOT}/two-factor-auth`;
export const ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW = `${ROUTE_P4A_ROOT}/loanenquiries/:loanEnquiryId`;
export const ROUTE_P4A_ADMINISTER_LENDERS_LIST = `${ROUTE_P4A_ROOT}/lenders`;
export const ROUTE_P4A_ADMINISTER_LENDERS_CREATE = `${ROUTE_P4A_ROOT}/lenders/create`;
export const ROUTE_P4A_ADMINISTER_LENDERS_EDIT = `${ROUTE_P4A_ROOT}/lenders/edit/:id`;
export const ROUTE_P4A_ADMINISTER_USERS_LIST = `${ROUTE_P4A_ROOT}/users`;
export const ROUTE_P4A_ADMINISTER_USERS_EDIT = `${ROUTE_P4A_ROOT}/users/edit/:id`;
export const ROUTE_P4A_ADMINISTER_USERS_CREATE = `${ROUTE_P4A_ROOT}/users/create`;
export const ROUTE_P4A_VIEW_EVENTS = `${ROUTE_P4A_ROOT}/events`;
export const ROUTE_P4A_ADMINISTER_USER_LENDERS_CREATE = `${ROUTE_P4A_ROOT}/users/associatedlenders/:id/create`;
export const ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST = `${ROUTE_P4A_ROOT}/users/associatedlenders/:id`;
export const ROUTE_P4A_ADMINISTER_USER_LENDERTEAMS_LIST = `${ROUTE_P4A_ROOT}/users/team/:id`;
export const ROUTE_P4A_LENDERS_DOCUMENTS_VIEW = `${ROUTE_BC_ROOT}/lenders/documents/:id`;
export const ROUTE_P4A_BORROWERS_DOCUMENTS_VIEW = `${ROUTE_BC_ROOT}/borrowers/documents/:id`;
export const ROUTE_P4A_LLE = `${ROUTE_P4A_ROOT}/lle/:loanEnquiryId/:lenderLoanEnquiryId`;
export const ROUTE_P4A_BROKERS_LIST = `${ROUTE_P4A_ROOT}/brokers`;
export const ROUTE_P4A_NETWORK_BROKERS_LIST = `${ROUTE_P4A_ROOT}/network/:networkId/brokers`;
export const ROUTE_P4A_TAGS_LIST = `${ROUTE_P4A_ROOT}/tags`;

/**
 * INTERMEDIARY ROUTES
 */
export const ROUTE_INTERMEDIARY_ROOT = `/intermediary`;
export const ROUTE_INTERMEDIARY_CONFIGURE_PROFILE = `${ROUTE_INTERMEDIARY_ROOT}/profile`;
export const ROUTE_INTERMEDIARY_2FA_INDEX = `${ROUTE_INTERMEDIARY_ROOT}/two-factor-auth`;
export const ROUTE_INTERMEDIARY_LOAN_ENQUIRIES = `${ROUTE_INTERMEDIARY_ROOT}/enquiries`;
export const ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY = `${ROUTE_INTERMEDIARY_ROOT}/enquiries/create`;
export const ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY = `${ROUTE_INTERMEDIARY_ROOT}/enquiries/create/:loanEnquiryId`;
export const ROUTE_INTERMEDIARY_LENDERLOANENQUIRY_VIEW = `${ROUTE_INTERMEDIARY_ROOT}/enquiries/:loanEnquiryId/matches/:lenderLoanEnquiryId`;
export const ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW = `${ROUTE_INTERMEDIARY_ROOT}/enquiries/:loanEnquiryId`;
export const ROUTE_INTERMEDIARY_APPLICANTS_VIEW = `${ROUTE_INTERMEDIARY_ROOT}/applicants`;
export const ROUTE_INTERMEDIARY_DOCUMENTS_VIEW = `${ROUTE_INTERMEDIARY_ROOT}/documents`;
export const ROUTE_INTERMEDIARY_NETWORK_BROKERS_VIEW = `${ROUTE_INTERMEDIARY_ROOT}/network/brokers`;

/**
 * SLAM ROUTES
 */
export const ROUTE_SLAM_ROOT = `/lender`;
export const ROUTE_SLAM_CONFIGURE_PROFILE = `${ROUTE_SLAM_ROOT}/profile`;
export const ROUTE_SLAM_2FA_INDEX = `${ROUTE_SLAM_ROOT}/two-factor-auth`;
export const ROUTE_SLAM_ADMINISTER_USERS_LIST = `${ROUTE_SLAM_ROOT}/users`;
export const ROUTE_SLAM_LEADS_ACTIVE = `${ROUTE_SLAM_ROOT}/leads-active`;
export const ROUTE_SLAM_LEADS_INACTIVE = `${ROUTE_SLAM_ROOT}/leads-inactive`;
export const ROUTE_SLAM_LEAD = `${ROUTE_SLAM_ROOT}/leads/:lenderLoanEnquiryId`;
export const ROUTE_SLAM_DOCUMENTS_VIEW = `${ROUTE_SLAM_ROOT}/documents`;
export const ROUTE_SLAM_BUSINESS_STREAMS = `${ROUTE_SLAM_ROOT}/business-streams`;
export const ROUTE_SLAM_BUSINESS_STREAM_CREATE = `${ROUTE_SLAM_ROOT}/business-streams/create`;
export const ROUTE_SLAM_BUSINESS_STREAM_EDIT = `${ROUTE_SLAM_ROOT}/business-streams/:businessStreamId/edit`;
export const ROUTE_SLAM_BUSINESS_STREAM_VIEW = `${ROUTE_SLAM_ROOT}/business-streams/:businessStreamId`;

/**
 * BORROWER ROUTES
 */
export const ROUTE_BORROWER_ROOT = `/borrower`;
export const ROUTE_BORROWER_LOAN_ENQUIRIES = `${ROUTE_BORROWER_ROOT}/enquiries`;
export const ROUTE_BORROWER_CREATE_LOAN_ENQUIRY = `${ROUTE_BORROWER_ROOT}/enquiries/create`;
export const ROUTE_BORROWER_EDIT_LOAN_ENQUIRY = `${ROUTE_BORROWER_ROOT}/enquiries/create/:loanEnquiryId`;
export const ROUTE_BORROWER_LOAN_ENQUIRY_VIEW = `${ROUTE_BORROWER_ROOT}/enquiries/:loanEnquiryId`;
export const ROUTE_BORROWER_LENDER_LOAN_ENQUIRY_VIEW = `${ROUTE_BORROWER_ROOT}/enquiries/:loanEnquiryId/matches/:lenderLoanEnquiryId`;
export const ROUTE_BORROWER_DOCUMENTS_VIEW = `${ROUTE_BORROWER_ROOT}/documents`;
export const ROUTE_BORROWER_CONFIGURE_PROFILE = `${ROUTE_BORROWER_ROOT}/profile`;
export const ROUTE_BORROWER_2FA_INDEX = `${ROUTE_BORROWER_ROOT}/two-factor-auth`;
export const ROUTE_BORROWER_APPLICANTS_VIEW = `${ROUTE_BORROWER_ROOT}/applicants`;
export const ROUTE_BORROWER_APPLICANTS_CREATE = `${ROUTE_BORROWER_ROOT}/applicants/create`;

export { path };
