import CryptoJS from 'crypto-js';
import axios from 'axios';
import { HOST } from '../../bootstrap/constants/constants';

function arrayBufferToWordArray(ab) {
    let i8a = new Uint8Array(ab);
    let a = [];
    for (let i = 0; i < i8a.length; i += 4) {
        a.push((i8a[i] << 24) | (i8a[i + 1] << 16) | (i8a[i + 2] << 8) | i8a[i + 3]);
    }
    return CryptoJS.lib.WordArray.create(a, i8a.length);
}

function generateFileHash(fileReaderResult) {
    return String(CryptoJS.SHA256(arrayBufferToWordArray(fileReaderResult)));
}

class CreateDocumentManager {
    constructor(createDocumentMetaMutation, sessionManager) {
        this.createDocumentMetaMutation = createDocumentMetaMutation;
        this.sessionManager = sessionManager;
    }

    setContextId(id) {
        this.id = id;
        return this;
    }

    setFileReader(fileReader) {
        this.fileReader = fileReader;
        return this;
    }

    setFile(file) {
        this.file = file;
        return this;
    }

    setType(type) {
        this.type = type;
        return this;
    }

    setAddToUserStore(addToUserStore) {
        this.addToUserStore = addToUserStore === true;
        return this;
    }

    createMeta(allowAddToUserStore = false) {
        let input = {
            related: this.id,
            fileName: this.file.name,
            fileHash: generateFileHash(this.fileReader.target.result),
            type: this.type,
        };

        if (allowAddToUserStore === true) {
            input.addToUserStore = this.addToUserStore;
        }

        return this.createDocumentMetaMutation({
            variables: {
                input: input,
            },
        });
    }

    async uploadFile(uploadUrl) {
        let formData = new FormData();
        formData.append('file', this.file, this.file.name);

        const headers = {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + this.sessionManager.getToken().token,
        };

        if (this.sessionManager.hasAssumedToken() && uploadUrl.match(/slam|intermediary|borrower/).length > 0) {
            headers['X-Authorization-Assumed-Identity'] = 'Bearer ' + this.sessionManager.getAssumedToken().token;
        }

        return await axios.post(HOST + uploadUrl, formData, {
            headers,
        });
    }
}

export default CreateDocumentManager;
