import React from 'react';
import { withRouter } from 'react-router';
import { compose } from 'recompose';
import styled from 'styled-components';
import logo from '../../landing/assets/images/pitch4-d.svg';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import { Body1, PrimaryColour, SecondaryColour } from 'pitch4_layout/components/text';
import { Centered, CenteringContainer, Container, RowItem } from 'pitch4_layout/components/div';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { ROUTE_ANON_LOGIN, ROUTE_LANDING } from 'pitch4_routing';
import SessionManager from 'pitchblack_react_utils/session/SessionManager';
import { sagaActionRedirectToDashboard } from '../../authentication/sagas/dashboard';
import { getResetToken } from '../redux/reset';
import { BRAND_NAME } from '../../../bootstrap/constants/constants';

const sessionManager = new SessionManager();

const ResetPasswordWidget = styled(Container)`
    padding-top: 10%;
`;

const Logo = styled.img`
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 15px;
    padding-top: 10px;
`;

const StyledHeadingRow = styled(RowItem)`
    padding-top: 1em;
    padding-bottom: 2em;
    text-align: center;
`;

const StyledNoteRow = styled(RowItem)`
    padding-top: 3em;
    text-align: center;
`;

const ResetPasswordButtonContainer = styled(CenteringContainer)`
    margin-top: 1em;
`;

class ResetTwoFactorAuthSuccessContainer extends React.Component {
    render() {
        const { match, dashboardRedirect, redirect, resetToken = null } = this.props;

        if (sessionManager.hasAssumedToken()) {
            dashboardRedirect();
        }

        if (resetToken === null || resetToken.id !== match.params.token) {
            redirect(ROUTE_LANDING)();
        }

        return (
            <ResetPasswordWidget justify="center">
                <RowItem>
                    <Centered>
                        <Logo src={logo} alt={`${BRAND_NAME} &copy; Borrowing Made Simple`} />
                    </Centered>
                </RowItem>
                <StyledHeadingRow>
                    <h6>
                        <PrimaryColour>2FA reset request successful</PrimaryColour>
                    </h6>
                </StyledHeadingRow>
                <StyledNoteRow>
                    <Body1>
                        <SecondaryColour>Please use the link below to login to your account.</SecondaryColour>
                    </Body1>
                </StyledNoteRow>

                <RowItem>
                    <ResetPasswordButtonContainer>
                        <PrimaryButton onClick={redirect(ROUTE_ANON_LOGIN)}>
                            <span>
                                <Body1>Login</Body1>
                            </span>
                        </PrimaryButton>
                    </ResetPasswordButtonContainer>
                </RowItem>
            </ResetPasswordWidget>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        resetToken: getResetToken(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (uri) => () => dispatch(push(uri)),
        dashboardRedirect: () => dispatch(sagaActionRedirectToDashboard()),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(ResetTwoFactorAuthSuccessContainer);
