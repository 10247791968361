import { API_URL } from '../constants/constants';
import { createApolloFetch } from 'apollo-fetch';
import SessionManager from 'pitchblack_react_utils/session/SessionManager';

export const defaultFetchParams = { uri: API_URL };

export const initMiddleware = ({ request, options }, next) => {
    if (!options.headers) {
        options.headers = {}; // Create the headers object if needed.
    }

    next();
};

export const addAuthHeadersMiddleware = ({ request, options }, next) => {
    if (!options.headers) {
        options.headers = {}; // Create the headers object if needed.
    }

    let sessionManager = new SessionManager();

    if (sessionManager.isAuthenticated() && sessionManager.hasAssumedToken()) {
        const assumedIdentityToken = sessionManager.getAssumedToken();

        options.headers['X-Authorization-Assumed-Identity'] = `Bearer ${assumedIdentityToken.token}`;
    }

    const token = sessionManager.getToken();

    if (sessionManager.isAuthenticated()) {
        options.headers['Authorization'] = `Bearer ${token.token}`;
    }

    next();
};

export const handleAuthErrorAfterware = ({ response }, next) => {
    if ((response.status === 401 || response.status === 403) && !response.parsed) {
        throw new Error('There was an error');
    }

    next();
};

export const handleServerErrorAfterware = ({ response }, next) => {
    if ((response.status === 500 || response.status === 503) && !response.parsed) {
        throw new Error('There was an error with the API');
    }

    next();
};

export const createP4ApolloFetch = (params = defaultFetchParams) => {
    let af = createApolloFetch({ ...params });

    af.use(initMiddleware).use(addAuthHeadersMiddleware);

    af.useAfter(handleAuthErrorAfterware).useAfter(handleServerErrorAfterware);

    return af;
};

export default createP4ApolloFetch;
