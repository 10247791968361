import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

const ClearAllLoanEnquiryAlertMutation = `
  mutation clearAllLoanEnquiryAlert($id: ID!){
    clearAllLoanEnquiryAlert(id: $id){
      success
    }
  } 
`;

const ClearUserAlertsMutation = `
  mutation clearUserAlerts {
    clearUserAlerts {
      success
    }
  } 
`;

export const clearUserAlerts = (loanEnquiryId) => {
    return apolloFetch({
        query: loanEnquiryId ? ClearAllLoanEnquiryAlertMutation : ClearUserAlertsMutation,
        variables: {
            id: loanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
