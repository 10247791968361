import React, { useState } from 'react';
import { Button, Checkbox, Link, Paper, TextField } from '@material-ui/core';
import { BRAND_NAME, TOS_URL } from '../../bootstrap/constants/constants';

export default function ContractTermsPopup(props) {
    const [termOne, setTermOne] = useState(false);
    const [termTwo, setTermTwo] = useState(false);
    const [termThree, setTermThree] = useState(false);
    const {
        handleLegalContractTerms,
        handleContractPreviouslyContactedLenders,
        contractPreviouslyContactedLenders,
        applicants,
        submittedBy,
    } = props;

    const handleTermOne = () => setTermOne(!termOne);
    const handleTermTwo = () => setTermTwo(!termTwo);
    const handleTermThree = () => {
        setTermThree(!termThree);
        if (false === !termThree) {
            handleContractPreviouslyContactedLenders(null);
        }
    };
    const getApplicants = () =>
        applicants.map((applicant) => applicant.applicant.given + ' ' + applicant.applicant.family).join(' and ');

    return (
        <div className={'contract-confirmation-container'}>
            <div className={'header'}>Please Complete All Sections</div>
            <Paper className={'section'} elevation={2}>
                <div>
                    <Checkbox className={'checkbox'} checked={termOne} onClick={handleTermOne} />
                </div>
                <div className={'text-content'} onClick={handleTermOne}>
                    I, <strong>{submittedBy.given + ' ' + submittedBy.family}</strong>, agree to not externally contact
                    lenders sourced through the {BRAND_NAME} process, concerning the funding application of{' '}
                    <strong>{getApplicants()}</strong>.
                </div>
            </Paper>
            <Paper className={'section'} elevation={2} style={{ marginTop: 25, marginBottom: 25 }}>
                <div>
                    <Checkbox className={'checkbox'} checked={termTwo} onClick={handleTermTwo} />
                </div>
                <div className={'text-content'} onClick={handleTermTwo}>
                    I agree to all <strong>Terms and Conditions</strong> relating to <strong>fair-use</strong> of the
                    {BRAND_NAME} platform.{' '}
                    <Link className={'t-and-c-link'} target={'_blank'} href={TOS_URL}>
                        <strong>Please read T&C’s here</strong>.
                    </Link>
                </div>
            </Paper>
            <Paper className={'section'} elevation={2}>
                <div>
                    <Checkbox className={'checkbox'} checked={termThree} onClick={handleTermThree} />
                </div>
                <div>
                    <div className={'text-content'} onClick={handleTermThree}>
                        I have not <strong>previously contacted any lenders</strong> other than the following, regarding
                        this funding application <strong>(leave blank if you have not contacted any lenders)</strong>:
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <TextField
                            onChange={(event) => handleContractPreviouslyContactedLenders(event.target.value)}
                            disabled={!termThree}
                            className={'lenders-input'}
                            id="outlined-multiline-static"
                            multiline
                            rows={4}
                            placeholder={'Type lender names here...'}
                            variant="outlined"
                            value={contractPreviouslyContactedLenders}
                        />
                    </div>
                </div>
            </Paper>
            <div className={'actions'}>
                <Button
                    onClick={() => handleLegalContractTerms()}
                    color={'primary'}
                    variant={'contained'}
                    className={'primary-button'}
                    disabled={false === termOne || false === termTwo || false === termThree}
                >
                    Create Contract
                </Button>
            </div>
        </div>
    );
}
