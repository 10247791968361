// see https://github.com/erikras/ducks-modular-redux
import { AddMapper } from 'pitch4_documents';
export const REDUCER_NAMESPACE = 'pitch4admin.documents_add';

// Actions
export const P4A_DOCUMENTS_ADD_HIDE_VALIDATION_ERRORS = `${REDUCER_NAMESPACE}.hide_server_side_errors`;
export const P4A_DOCUMENTS_ADD_SHOW_VALIDATION_ERRORS = `${REDUCER_NAMESPACE}.show_server_side_errors`;
export const P4A_DOCUMENTS_ADD_SET_VALIDATION_ERRORS = `${REDUCER_NAMESPACE}.set_validation_errors`;
export const P4A_DOCUMENTS_ADD_SET_INITIAL_VALUES = `${REDUCER_NAMESPACE}.set_initial_values`;

const mapper = new AddMapper();

//Reducer
const initialState = {
    validationErrors: [],
    visible: true,
    id: null,
    initialValues: {},
    types: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case P4A_DOCUMENTS_ADD_HIDE_VALIDATION_ERRORS:
            return {
                ...state,
                visible: false,
            };

        case P4A_DOCUMENTS_ADD_SHOW_VALIDATION_ERRORS:
            return {
                ...state,
                visible: true,
            };

        case P4A_DOCUMENTS_ADD_SET_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: action.validationErrors,
                visible: true,
            };

        case P4A_DOCUMENTS_ADD_SET_INITIAL_VALUES:
            return {
                type: P4A_DOCUMENTS_ADD_SET_INITIAL_VALUES,
                id: action.id,
                initialValues: action.initialValues,
                types: action.types,
            };

        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;

//Action Creators
export const actionHideValidationErrors = () => {
    return {
        type: P4A_DOCUMENTS_ADD_HIDE_VALIDATION_ERRORS,
    };
};

export const actionShowValidationErrors = () => {
    return {
        type: P4A_DOCUMENTS_ADD_SHOW_VALIDATION_ERRORS,
    };
};

export const actionSetValidationErrors = (errors) => {
    return {
        type: P4A_DOCUMENTS_ADD_SET_VALIDATION_ERRORS,
        validationErrors: errors,
    };
};

export const actionSetInitialValues = (id, types) => {
    let initialValues = mapper.mapToForm({});

    return {
        type: P4A_DOCUMENTS_ADD_SET_INITIAL_VALUES,
        id,
        initialValues,
        types,
    };
};

//Selectors
export const selectorGetValidationErrors = (state) => {
    return state[REDUCER_NAMESPACE].validationErrors;
};

export const selectorAreErrorsVisible = (state) => {
    return state[REDUCER_NAMESPACE].visible;
};

export const selectorGetId = (state) => {
    return state[REDUCER_NAMESPACE].id;
};

export const selectorGetInitialValues = (state) => {
    return state[REDUCER_NAMESPACE].initialValues;
};

export const selectorGetTypes = (state) => {
    return state[REDUCER_NAMESPACE].types;
};
