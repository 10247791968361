class AddMapper {
    mapToForm = (props) => {
        let { file = null, type = '', document = '', addToUserStore = false } = props;

        return {
            file,
            type,
            document,
            addToUserStore,
        };
    };

    mapFromForm = ({ file = null, type = '', document = '', addToUserStore = false }) => {
        return {
            file,
            type,
            document,
            addToUserStore,
        };
    };
}

export default AddMapper;
