// see https://github.com/erikras/ducks-modular-redux
export const REDUCER_NAMESPACE = 'anon.flag-password-reset';

// Actions
export const FLAG_PASSWORD_RESET_REQUESTED = `${REDUCER_NAMESPACE}.requested`;
export const FLAG_PASSWORD_RESET_REQUESTED_SUCCESSFULLY = `${REDUCER_NAMESPACE}.requested_successfully`;
export const RESET = `${REDUCER_NAMESPACE}.reset`;

const initialState = {
    flagPasswordResetRequested: false,
    flagPasswordResetRequestedSuccessfully: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FLAG_PASSWORD_RESET_REQUESTED:
            return {
                ...state,
                flagPasswordResetRequested: true,
                flagPasswordResetRequestedSuccessfully: false,
            };
        case FLAG_PASSWORD_RESET_REQUESTED_SUCCESSFULLY:
            return {
                ...state,
                flagPasswordResetRequested: false,
                flagPasswordResetRequestedSuccessfully: true,
            };
        case RESET:
            return {
                ...initialState,
            };
        default:
            return { ...state };
    }
};

// Action creators
export const actionFlagPasswordResetRequested = (email) => {
    return {
        type: FLAG_PASSWORD_RESET_REQUESTED,
        email,
    };
};

export const actionReset = () => {
    return {
        type: RESET,
    };
};

// Selectors
export const selectorHasForgottenPasswordBeenRequested = (state) => {
    return state[REDUCER_NAMESPACE].flagPasswordResetRequested;
};

export const selectorHasForgottenPasswordBeenRequestedSuccessfully = (state) => {
    return state[REDUCER_NAMESPACE].flagPasswordResetRequestedSuccessfully;
};

// Default export reducer
export default reducer;
