import React from 'react';
import { DateTimeFormatter } from '../data/formatter';
import PropTypes from 'prop-types';

class DateLayout extends React.Component {
    render() {
        let { value } = this.props;
        return (
            <React.Fragment>
                <DateTimeFormatter value={value} format={'MMMM D, YYYY'} />
            </React.Fragment>
        );
    }
}

DateLayout.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default DateLayout;
