import React from 'react';
import { Query } from 'react-apollo/index';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Container, P4StyleComponentContainer } from '../../../../../pitch4_layout/components/div';
import { OPERATION_EQ } from '../../../../../pitch4_enum/enum/OperationEnum';
import { SORT_ASC, SORT_DESC } from '../../../../../pitch4_tabular/enum/SortDirectionEnum';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';
import { Loading } from '../../../../../pitchblack_react_utils/form/renderIfSubmitting';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import { withRouter } from 'react-router-dom';
import { ROLE_SLAM } from '../../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import UploadDocumentButton from './UploadDocumentButton';
import SecondaryButton from '../../../../../pitch4_layout/components/buttons/SecondaryButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { handleDownload } from '../../../../../pitch4_documents/src/components/DownloadButton';
import PlaceholderImageLayout from '../../../../../pitch4_layout/components/content/PlaceholderImageLayout';
import DocumentListItem from './DocumentListItem';

class DocumentList extends React.Component {
    render() {
        const {
            lenderLoanEnquiry = null,
            disableUploadButton = false,
            disabled = false,
            componentContainerStyles = {},
        } = this.props;

        return (
            <Query
                fetchPolicy={'no-cache'}
                query={gql`
                    query getLenderLoanEnquiryDocuments($count: Int!, $filters: [FilterInput], $sorts: [SortInput]) {
                        documents(first: $count, filters: $filters, sorts: $sorts) {
                            edges {
                                node {
                                    id
                                    fileName
                                    type
                                    downloadUrl
                                    createdAt
                                    uploaderRole
                                }
                                cursor
                            }
                            pageInfo {
                                hasNextPage
                                endCursor
                                startCursor
                                hasPreviousPage
                            }
                            totalCount
                        }
                    }
                `}
                context={{
                    uri: SLAM_URL,
                }}
                variables={{
                    count: 9999,
                    filters: [
                        {
                            column: 'contextEntity',
                            operation: OPERATION_EQ,
                            operand: lenderLoanEnquiry.id,
                            not: false,
                        },
                    ],
                    sorts: [
                        {
                            column: 'type',
                            direction: SORT_ASC,
                        },
                        {
                            column: 'updatedAt',
                            direction: SORT_DESC,
                        },
                        {
                            column: 'createdAt',
                            direction: SORT_DESC,
                        },
                    ],
                }}
            >
                {({ data, refetch }) => {
                    const docs = data.documents ? data.documents.edges : [];
                    const disabledClassName = disabled ? 'document-list-placeholder-disabled' : '';

                    return (
                        <P4StyleComponentContainer
                            styles={componentContainerStyles}
                            header={
                                <Container
                                    justifyContent={`flex-end`}
                                    alignItems={`center`}
                                    style={{ marginBottom: 20 }}
                                >
                                    <UploadDocumentButton
                                        outerRefetch={refetch}
                                        lenderLoanEnquiryId={lenderLoanEnquiry.id}
                                        buttonRender={(openAddDocumentModal) => (
                                            <SecondaryButton
                                                disabled={disableUploadButton}
                                                onClick={() => openAddDocumentModal()}
                                                style={{ color: '#ffffff', padding: '10px 35px', margin: 0 }}
                                            >
                                                Add a Document <AddCircleIcon />
                                            </SecondaryButton>
                                        )}
                                    />
                                </Container>
                            }
                            content={
                                docs.length > 0 ? (
                                    docs.map(({ node: document }) => (
                                        <DocumentListItem refetch={refetch} document={document} />
                                    ))
                                ) : (
                                    <PlaceholderImageLayout
                                        classNames={`document-list-placeholder ${disabledClassName}`}
                                    >
                                        <div>
                                            <h3 className={'typography-card-title'}>No documents uploaded yet</h3>
                                            <p className={'typography-default'}>
                                                Upload here to share documents with this lender.
                                            </p>
                                        </div>
                                    </PlaceholderImageLayout>
                                )
                            }
                        />
                    );
                }}
            </Query>
        );
    }

    downloadDocument = (document) => () => {
        let { setModalClose, setModalComponent } = this.props;

        handleDownload(document, {
            onPending: () => setModalComponent(<Loading heading="Please wait. Downloading file." />, false),
            onSuccess: () => setModalClose(),
            onFailure: () => setModalClose(),
        });
    };
}

DocumentList.propTypes = {
    lenderLoanEnquiry: PropTypes.object.isRequired,
    setModalComponent: PropTypes.func.isRequired,
    setModalClose: PropTypes.func.isRequired,
    disableUploadButton: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_SLAM])
)(DocumentList);
