import { BaseEnum } from '../../pitch4_enum';

export const STATUS_DRAFT = 'draft';
export const STATUS_PENDING = 'pending';
export const STATUS_MATCHING = 'matching';
export const STATUS_IN_PROGRESS = 'in_progress';
export const STATUS_WITHDRAWN = 'withdrawn';
export const STATUS_WITHDRAWN_FULLY = 'withdrawn_fully';
export const STATUS_CLOSED = 'closed';
export const STATUS_COMPLETION_REQUESTED = 'completion_requested';
export const STATUS_COMPLETION_REJECTED = 'completion_rejected';
export const STATUS_COMPLETE = 'completed';

class LoanEnquiryStatusEnum extends BaseEnum {
    getTypes = () => {
        return {
            [STATUS_DRAFT]: 'Draft',
            [STATUS_PENDING]: 'Pending',
            [STATUS_MATCHING]: 'Matching in progress',
            [STATUS_IN_PROGRESS]: 'In progress',
            [STATUS_WITHDRAWN]: 'Withdrawn',
            [STATUS_WITHDRAWN_FULLY]: 'Fully Withdrawn',
            [STATUS_CLOSED]: 'Closed',
            [STATUS_COMPLETION_REQUESTED]: 'Completion Requested',
            [STATUS_COMPLETION_REJECTED]: 'Completion Rejected',
            [STATUS_COMPLETE]: 'Completed',
        };
    };
}

export default LoanEnquiryStatusEnum;
