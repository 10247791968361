import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const AlertDotIndicatorIcon = (props) => {
    const { active, elkey } = props;
    const className = active ? 'alert dot-indicator-active' : 'alert';
    return <FiberManualRecordIcon className={className} style={{ width: 8, height: 8 }} key={elkey} />;
};

export default AlertDotIndicatorIcon;
