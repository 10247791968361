import {
    STATUS_APPLICATION,
    STATUS_COMPLETION_REJECTED,
    STATUS_COMPLETION_REQUESTED,
    STATUS_DRAFT,
    STATUS_IN_PROGRESS,
    STATUS_LEGALS,
    STATUS_MATCHED,
    STATUS_PENDING,
    STATUS_VALUATION,
} from '../apps/admin/pitch4admin/enum/LoanEnquiries/StatusEnum';

export const DEFAULT_SEARCH_FILTER_DELAY = 4000;

export const defaultRequestFilters = [
    {
        column: 'status',
        operation: 'eq',
        not: false,
        operand: STATUS_PENDING,
    },
    {
        column: 'status',
        operation: 'eq',
        not: false,
        operand: STATUS_MATCHED,
    },
    {
        column: 'status',
        operation: 'eq',
        not: false,
        operand: STATUS_IN_PROGRESS,
    },
    {
        column: 'status',
        operation: 'eq',
        not: false,
        operand: STATUS_APPLICATION,
    },
    {
        column: 'status',
        operation: 'eq',
        not: false,
        operand: STATUS_VALUATION,
    },
    {
        column: 'status',
        operation: 'eq',
        not: false,
        operand: STATUS_LEGALS,
    },
    {
        column: 'status',
        operation: 'eq',
        not: false,
        operand: STATUS_COMPLETION_REQUESTED,
    },
    {
        column: 'status',
        operation: 'eq',
        not: false,
        operand: STATUS_COMPLETION_REJECTED,
    },
    {
        column: 'status',
        operation: 'eq',
        not: true,
        operand: STATUS_DRAFT,
    },
];
