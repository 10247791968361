import base_reducer, { defaultInitialState } from '../../../../form_wizard/redux';

export const NAMESPACE = 'slam.business_streams.manage';

export const GET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.get_matching_criteria_config`;
export const SET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.set_matching_criteria_config`;
export const RESET = `${NAMESPACE}.reset`;
export const SUBMIT_FORM = `${NAMESPACE}.submit_form`;
export const SUBMIT_FORM_ERROR = `${NAMESPACE}.submit_form_error`;
export const SET_STORED_DATA_META = `${NAMESPACE}.set_stored_data_meta`;
export const GET_STORED_DATA_META = `${NAMESPACE}.get_stored_data_meta`;
export const GET_BUSINESS_STREAM = `${NAMESPACE}.get_business_stream`;
export const SUBMIT_CREATE_FORM = `${NAMESPACE}.submit_create_form`;
export const SUBMIT_EDIT_FORM = `${NAMESPACE}.submit_edit_form`;
export const RESET_FIELDS = `${NAMESPACE}.reset_fields`;
export const SET_BUSINESS_STREAM = `${NAMESPACE}.set_business_stream`;

export const actionResetFields = (form, section, fieldNames) => ({
    type: RESET_FIELDS,
    form,
    section,
    fieldNames,
});

export const actionGetMatchingCriteriaConfig = () => ({
    type: GET_MATCHING_CRITERIA_CONFIG,
});

export const actionGetStoreDataMeta = () => ({
    type: GET_STORED_DATA_META,
});

export const actionSetStoreDataMeta = (storedDataMeta) => ({
    type: SET_STORED_DATA_META,
    storedDataMeta,
});

export const actionGetBusinessStream = (businessStreamId, isBusinessFinance) => ({
    type: GET_BUSINESS_STREAM,
    businessStreamId,
    isBusinessFinance,
});

export const actionSubmitCreateForm = (name, formSections, isBusinessFinanceStream, version) => ({
    type: SUBMIT_CREATE_FORM,
    name,
    formSections,
    isBusinessFinanceStream,
    version,
});

export const actionSubmitEditForm = (businessStreamId, name, formSections, isBusinessFinanceStream, version) => ({
    type: SUBMIT_EDIT_FORM,
    businessStreamId,
    name,
    formSections,
    isBusinessFinanceStream,
    version,
});

export const actionSetBusinessStream = (businessStream) => ({
    type: SET_BUSINESS_STREAM,
    businessStream,
});

const initialState = {
    ...defaultInitialState,
    businessStream: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATCHING_CRITERIA_CONFIG:
            const config = action.config;

            return {
                ...state,
                config,
            };

        case RESET:
            return {
                ...state,
                formStack: [],
                submitting: false,
            };

        case SUBMIT_FORM:
            return {
                ...state,
                submitting: true,
            };

        case SUBMIT_FORM_ERROR:
            return {
                ...state,
                submitting: false,
            };

        case SET_STORED_DATA_META:
            const storedDataMeta = action.storedDataMeta;

            return {
                ...state,
                storedDataMeta,
            };

        case SET_BUSINESS_STREAM:
            const businessStream = action.businessStream;

            return {
                ...state,
                businessStream,
            };
        default:
            break;
    }

    return base_reducer(state, action, NAMESPACE);
};

export const selectorGetStoredDataMetaValues = (state) => {
    return state[`${NAMESPACE}`].storedDataMeta;
};

export const selectorGetBusinessStream = (state) => {
    return state[`${NAMESPACE}`].businessStream;
};

//Default export reducer
export default reducer;
