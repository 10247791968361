import React from 'react';
import { Query } from 'react-apollo/index';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import { Container } from '../../../../../pitch4_layout/components/div';
import { P4StyleComponentContainer } from '../../../../../pitch4_layout/components/div';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import { withRouter } from 'react-router-dom';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import UploadDocumentButton from './UploadDocumentButton';
import PlaceholderImageLayout from '../../../../../pitch4_layout/components/content/PlaceholderImageLayout';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SecondaryButton from '../../../../../pitch4_layout/components/buttons/SecondaryButton';
import { handleDownload } from '../../../../../pitch4_documents/src/components/DownloadButton';
import { Loading } from '../../../../../pitchblack_react_utils/form/renderIfSubmitting';
import AdminCaseMgmtUploadDocumentButton from './AdminCaseMgmtUploadDocumentButton';
import DocumentListItem from './DocumentListItem';
import _ from 'lodash';

class DocumentList extends React.Component {
    componentDidMount() {
        const { handleACMDocumentsAlert, loanEnquiry } = this.props;

        if (loanEnquiry && handleACMDocumentsAlert) {
            handleACMDocumentsAlert(loanEnquiry.id);
        }
    }

    render() {
        const {
            loanEnquiry = null,
            lenderLoanEnquiry = null,
            disableUploadButton = false,
            containerStyles = {},
        } = this.props;

        let query;

        if (lenderLoanEnquiry) {
            query = gql`
                query lenderLoanEnquiryDocument($id: ID!) {
                    lenderLoanEnquiryDocument(id: $id) {
                        id
                        fileName
                        type
                        downloadUrl
                        createdAt
                        uploaderRole
                        createdAt
                    }
                }
            `;
        } else if (loanEnquiry) {
            query = gql`
                query loanEnquiryDocument($id: ID!) {
                    loanEnquiryDocument(id: $id) {
                        id
                        fileName
                        type
                        uploaderRole
                        downloadUrl
                        createdAt
                        visibleByBorrower
                    }
                }
            `;
        }

        const buildDocList = (docs, refetch) => {
            return docs.map((document, index) => {
                return (
                    <DocumentListItem
                        key={index}
                        refetch={refetch}
                        document={document}
                        loanEnquiry={loanEnquiry}
                        lenderLoanEnquiry={lenderLoanEnquiry}
                    />
                );
            });
        };

        return (
            <Query
                fetchPolicy={'no-cache'}
                query={query}
                context={{
                    uri: API_URL,
                }}
                variables={{
                    id: lenderLoanEnquiry ? lenderLoanEnquiry.id : loanEnquiry ? loanEnquiry.id : null,
                }}
            >
                {({ data, refetch }) => {
                    const docs =
                        loanEnquiry && !_.isEmpty(data)
                            ? data.loanEnquiryDocument
                            : lenderLoanEnquiry && !_.isEmpty(data)
                            ? data.lenderLoanEnquiryDocument
                            : [];

                    let content = (
                        <PlaceholderImageLayout classNames={`document-list-placeholder`}>
                            <div>
                                <h3 className={'typography-card-title'}>No documents uploaded yet</h3>
                                <p className={'typography-default'}>Upload here to share documents with this lender.</p>
                            </div>
                        </PlaceholderImageLayout>
                    );

                    if (docs.length !== 0) {
                        content = (
                            <table className={'documents-table'}>
                                {!lenderLoanEnquiry && loanEnquiry && (
                                    <thead>
                                        <tr className={'doc-table-header'}>
                                            <th />
                                            <th>Document</th>
                                            <th>Uploaded by</th>
                                            <th>Visible to Int / Borrower</th>
                                            <th>Date Uploaded</th>
                                        </tr>
                                    </thead>
                                )}
                                <tbody>{buildDocList(docs, refetch)}</tbody>
                            </table>
                        );
                    }

                    return (
                        <P4StyleComponentContainer
                            styles={containerStyles}
                            headerStyles={{ marginBottom: 20 }}
                            header={
                                <Container justifyContent={`flex-end`} spacing={0} alignItems={`center`} style={{}}>
                                    {lenderLoanEnquiry ? (
                                        <div
                                            style={{
                                                width: '100%',
                                                padding: '0px 20px',
                                                textAlign: 'right',
                                            }}
                                        >
                                            <UploadDocumentButton
                                                outerRefetch={refetch}
                                                lenderLoanEnquiryId={lenderLoanEnquiry.id}
                                                buttonRender={(openAddDocumentModal) => (
                                                    <SecondaryButton
                                                        disabled={disableUploadButton}
                                                        onClick={() => openAddDocumentModal()}
                                                        style={{ color: '#ffffff' }}
                                                    >
                                                        Add a Document&nbsp;
                                                        <AddCircleIcon />
                                                    </SecondaryButton>
                                                )}
                                            />
                                        </div>
                                    ) : loanEnquiry ? (
                                        <AdminCaseMgmtUploadDocumentButton
                                            outerRefetch={refetch}
                                            loanEnquiryId={loanEnquiry.id}
                                            buttonRender={(openAddDocumentModal) => (
                                                <SecondaryButton
                                                    disabled={disableUploadButton}
                                                    onClick={() => openAddDocumentModal()}
                                                    style={{ color: '#ffffff' }}
                                                >
                                                    Add a Document <AddCircleIcon />
                                                </SecondaryButton>
                                            )}
                                        />
                                    ) : null}
                                </Container>
                            }
                            content={content}
                        />
                    );
                }}
            </Query>
        );
    }

    downloadDocument = (document) => () => {
        let { setModalClose, setModalComponent } = this.props;

        handleDownload(document, {
            onPending: () => setModalComponent(<Loading heading="Please wait. Downloading file." />, false),
            onSuccess: () => setModalClose(),
            onFailure: () => setModalClose(),
        });
    };
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(DocumentList);
