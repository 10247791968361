import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SLAM } from '../../../../authentication/enum/Roles/NameEnum';
import { actionSetInitialValues, actionSetValidationErrors, selectorGetInitialValues } from '../redux/edit';
import { reduxForm } from 'redux-form';
import gql from 'graphql-tag';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';
import { NoQueryMutation } from 'pitch4_forms/components';
import Container from 'pitchblack_react_utils/layout/Container';
import { CenteringContainer } from 'pitchblack_react_utils/layout';
import Item from 'pitchblack_react_utils/layout/Item';
import RowItem from 'pitchblack_react_utils/layout/RowItem';
import PropTypes from 'prop-types';
import { PrimaryButton, SecondaryButton } from 'pitch4_layout/components/buttons';
import { RightAligned } from 'pitch4_layout/components/div';
import { TextField } from 'pitch4_forms/components/inputs';

class EditDocumentForm extends React.Component {
    componentDidMount() {
        let { document, setInitialValues } = this.props;
        setInitialValues(document);
    }

    onSubmit = (mutate) => ({ fileName }) => {
        const {
            document: { id },
            onPending,
            onError,
            onSuccess,
        } = this.props;

        onPending();

        //map the variables if necessary
        return mutate({
            variables: {
                id,
                input: {
                    fileName,
                },
            },
            context: {
                uri: SLAM_URL,
            },
        })
            .then(({ data }) => {
                onSuccess(data);
            })
            .catch((error) => {
                onError(error);
            });
    };

    render() {
        return (
            <NoQueryMutation
                mutationQuery={gql`
                    mutation editDocument($id: ID!, $input: slam_editDocumentInput!) {
                        editDocument(id: $id, input: $input) {
                            document {
                                id
                            }
                        }
                    }
                `}
                renderFormFunction={(mutate, props) => {
                    const { submitting, reset, handleSubmit } = this.props;

                    return (
                        <CenteringContainer>
                            <Item xs={12}>
                                <form onSubmit={handleSubmit(this.onSubmit(mutate, props))}>
                                    <Container>
                                        <RowItem>
                                            <Container>
                                                <Item xs={12}>
                                                    <TextField name={'fileName'} label={'File name'} />
                                                </Item>
                                            </Container>
                                        </RowItem>
                                        <RowItem>
                                            <RightAligned className={'button-panel'}>
                                                <SecondaryButton disabled={submitting} onClick={reset}>
                                                    Reset
                                                </SecondaryButton>
                                                <PrimaryButton type="submit" disabled={submitting}>
                                                    Submit
                                                </PrimaryButton>
                                            </RightAligned>
                                        </RowItem>
                                    </Container>
                                </form>
                            </Item>
                        </CenteringContainer>
                    );
                }}
            />
        );
    }
}

EditDocumentForm.propTypes = {
    document: PropTypes.object.isRequired,
    initialValues: PropTypes.object.isRequired,
    setValidationErrors: PropTypes.func.isRequired,
    setInitialValues: PropTypes.func.isRequired,
    onPending: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        initialValues: selectorGetInitialValues(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setValidationErrors: (errors) => {
            dispatch(actionSetValidationErrors(errors));
        },
        setInitialValues: (values, types) => {
            dispatch(actionSetInitialValues(values, types));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'slam_document_edit_form',
        enableReinitialize: true,
        destroyOnUnmount: false,
        pure: true,
        updateUnregisteredFields: false,
    }),
    renderErrorIfAuthorisationFail([ROLE_SLAM])
)(EditDocumentForm);
