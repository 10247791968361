import React, { useEffect, useState } from 'react';
import { Grid, LinearProgress, Paper } from '@material-ui/core';
import moment from 'moment';
import EditTermItemText from './EditTermItemText';
import EditTermItemNumber from './EditTermItemNumber';
import EditTermItemChoice from './EditTermItemChoice';

export default function DrawerTerms(props) {
    const { termsUpdatedAt, allTerms } = props;
    const [terms, setTerms] = useState(allTerms);
    const updateTermValue = (item, itemIndex, newValue) => {
        item.value = newValue || null;
        terms.splice(itemIndex, 1, item);
        setTerms(terms);
        setTermsProgress(calculateProgress(terms));
    };
    const updateTermChoice = (item, itemIndex, newChoice) => {
        item.choiceIndex = item.choice.indexOf(newChoice);
        terms.splice(itemIndex, 1, item);
        setTerms(terms);
        setTermsProgress(calculateProgress(terms));
    };
    const calculateProgress = (terms) => {
        const totalItemsCount = terms.length;
        const doneItemsCount = terms.filter((item) => item.value !== null).length;

        return {
            totalItemsCount: terms.length,
            doneItemsCount: terms.filter((item) => item.value !== null).length,
            percentDone:
                totalItemsCount !== 0 && doneItemsCount !== 0
                    ? Math.round((doneItemsCount / totalItemsCount) * 100)
                    : 0,
        };
    };
    const [termsProgress, setTermsProgress] = useState(calculateProgress(terms));

    useEffect(() => {
        if (allTerms !== terms) {
            setTerms(allTerms);
            setTermsProgress(calculateProgress(allTerms));
        }
    });

    return (
        <>
            <Paper elevation={1} className={'progress-container'}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <div className={'title'}>Your live terms</div>
                            <div className={'last-updated-title'}>Last Updated</div>
                            <div className={'last-updated-date'}>
                                {termsUpdatedAt
                                    ? moment(termsUpdatedAt).format('H:mm Do MMMM YYYY')
                                    : 'Awaiting Your Terms'}
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={'progress'}>
                            <div
                                className={'title'}
                            >{`${termsProgress.doneItemsCount} / ${termsProgress.totalItemsCount} Terms offered`}</div>
                            <LinearProgress
                                className={'progress-bar'}
                                value={termsProgress.percentDone}
                                variant="determinate"
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <div className={'edit-terms-container'}>
                {terms.map((item, index) => {
                    switch (item.valueType) {
                        case 'text':
                            return (
                                <EditTermItemText
                                    updateTermValue={updateTermValue}
                                    key={index}
                                    item={item}
                                    itemIndex={index}
                                    isLast={index === terms.length - 1}
                                />
                            );
                        case 'number':
                            return (
                                <EditTermItemNumber
                                    updateTermValue={updateTermValue}
                                    updateTermChoice={updateTermChoice}
                                    key={index}
                                    item={item}
                                    itemIndex={index}
                                    isLast={index === terms.length - 1}
                                />
                            );
                        case 'choice':
                            return (
                                <EditTermItemChoice
                                    updateTermValue={updateTermValue}
                                    key={index}
                                    item={item}
                                    itemIndex={index}
                                    isLast={index === terms.length - 1}
                                />
                            );
                        default:
                            return (
                                <EditTermItemText
                                    updateTermValue={updateTermValue}
                                    key={index}
                                    item={item}
                                    itemIndex={index}
                                    isLast={index === terms.length - 1}
                                />
                            );
                    }
                })}
            </div>
        </>
    );
}
