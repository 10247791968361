import React from 'react';
import { ILIKE } from '../enum/OperationEnum';
import { Container } from '../../pitchblack_react_utils/layout';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

let finterval;

class Filter extends React.Component {
    handleFiltersOnChange = (onFilterChange) => (event) => {
        event.stopPropagation();
        clearTimeout(finterval);

        let { filterValues } = this.props;
        let { operand, operation, column, not } = filterValues;

        let newFilterOperand = event.target.value;

        if (operand === newFilterOperand) {
            return;
        }

        onFilterChange({ operand: newFilterOperand, operation, column, not });
    };

    render() {
        let { onFilterChange, filterValues, id, label, icon = null } = this.props;

        if (
            filterValues.operation === ILIKE &&
            filterValues.operand.match(/^[%]/) &&
            filterValues.operand.match(/[%]$/)
        ) {
            filterValues.operand = filterValues.operand.replace(/[%]/g, '');
        }

        if (icon === null) {
            icon = <span className={'search-icon'} />;
        }

        return (
            <Container>
                <TextField
                    className={'filter text-field text-field-search'}
                    id={id}
                    placeholder={label}
                    value={filterValues.operand}
                    variant="outlined"
                    onChange={this.handleFiltersOnChange(onFilterChange)}
                    InputProps={{
                        startAdornment: icon,
                    }}
                />
            </Container>
        );
    }
}

Filter.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    filterValues: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export default Filter;
