import React from 'react';

import TagsInput from 'react-tagsinput';

import 'react-tagsinput/react-tagsinput.css'; // If using WebPack and style-loader.
import Chip from '@material-ui/core/Chip';

class ChipInput extends React.Component {
    constructor() {
        super();
        this.state = { tags: [] };
    }

    handleChange(tags) {
        this.setState({ tags });
    }

    defaultRenderTag = (props) => {
        let { tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other } = props;

        let onRequestDelete = null;
        if (!disabled) {
            onRequestDelete = (e) => onRemove(key);
        }

        return (
            <span key={key} {...other}>
                <Chip key={key} label={getTagDisplayValue(tag)} onRequestDelete={onRequestDelete} />
            </span>
        );
    };

    render() {
        return <TagsInput value={this.state.tags} renderTag={this.defaultRenderTag} onChange={this.handleChange} />;
    }
}

export default ChipInput;
