//Admin redux
import borrowerViewLenderLoanEnquiryReducer, {
    NAMESPACE as BORROWER_LENDER_LOAN_ENQUIRY_VIEW_REDUCER_NAMESPACE,
} from '../lender_loan_enquiries_view/redux/main';
import borrowerLenderLoanEnquiriesReducer, {
    NAMESPACE as BORROWER_LENDER_LOAN_ENQUIRIES_REDUCER_NAMESPACE,
} from '../loan_enquiries/redux';
import borrowerDocumentsReducer, { NAMESPACE as BORROWER_DOCUMENTS_REDUCER_NAMESPACE } from '../documents/redux';
import borrowerDocumentsAddReducer, {
    REDUCER_NAMESPACE as BORROWER_DOCUMENTS_ADD_REDUCER_NAMESPACE,
} from '../documents_widget/redux/add';
import borrowerDocumentsEditReducer, {
    NAMESPACE as BORROWER_DOCUMENTS_EDIT_REDUCER_NAMESPACE,
} from '../documents/redux/edit';
import borrowerLenderLoanEnquiryDocumentsReducer, {
    NAMESPACE as BORROWER_LENDER_LOAN_ENQUIRY_DOCUMENTS_REDUCER_NAMESPACE,
} from '../lender_loan_enquires_documents/redux';
import borrowerLenderLoanEnquiryDocumentsEditReducer, {
    NAMESPACE as BORROWER_LENDER_LOAN_ENQUIRY_DOCUMENTS_FILTERS_REDUCER_NAMESPACE,
} from '../lender_loan_enquires_documents/redux/edit';
import userSettings, { REDUCER_NAMESPACE as USER_SETTINGS } from '../user_settings/redux/usersettingscontainer';

import createLoanEnquiryContainer, { NAMESPACE as CREATE_LOAN_ENQUIRY_REDUCER_NAMESPACE } from '../mle/redux';
import viewLoanEnquiryMain, { NAMESPACE as VIEW_LOAN_ENQUIRY_REDUCER_NAMESPACE } from '../view_loan_enquiry/redux';
import deleteLoanEnquiryMain, {
    NAMESPACE as DELETE_LOAN_ENQUIRY_REDUCER_NAMESPACE,
} from '../delete_loan_enquiry/redux';

import borrowerViewApplicantsMainReducer, { NAMESPACE as VIEW_APPLICANTS_NAMESPACE } from '../applicants/redux/main';
import borrowerMleApplicantReducer, {
    NAMESPACE as MLE_APPLICANT_NAMESPACE,
} from '../mle/form_sections/Applicant/redux';

export default {
    [BORROWER_LENDER_LOAN_ENQUIRIES_REDUCER_NAMESPACE]: borrowerLenderLoanEnquiriesReducer,
    [BORROWER_LENDER_LOAN_ENQUIRY_VIEW_REDUCER_NAMESPACE]: borrowerViewLenderLoanEnquiryReducer,

    [BORROWER_DOCUMENTS_REDUCER_NAMESPACE]: borrowerDocumentsReducer,
    [BORROWER_DOCUMENTS_ADD_REDUCER_NAMESPACE]: borrowerDocumentsAddReducer,
    [BORROWER_DOCUMENTS_EDIT_REDUCER_NAMESPACE]: borrowerDocumentsEditReducer,

    [BORROWER_LENDER_LOAN_ENQUIRY_DOCUMENTS_REDUCER_NAMESPACE]: borrowerLenderLoanEnquiryDocumentsReducer,
    [BORROWER_LENDER_LOAN_ENQUIRY_DOCUMENTS_FILTERS_REDUCER_NAMESPACE]: borrowerLenderLoanEnquiryDocumentsEditReducer,

    [USER_SETTINGS]: userSettings,
    [CREATE_LOAN_ENQUIRY_REDUCER_NAMESPACE]: createLoanEnquiryContainer,
    [VIEW_LOAN_ENQUIRY_REDUCER_NAMESPACE]: viewLoanEnquiryMain,
    [DELETE_LOAN_ENQUIRY_REDUCER_NAMESPACE]: deleteLoanEnquiryMain,

    [VIEW_APPLICANTS_NAMESPACE]: borrowerViewApplicantsMainReducer,
    [MLE_APPLICANT_NAMESPACE]: borrowerMleApplicantReducer,
};
