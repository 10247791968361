import { BaseEnum } from 'pitch4_enum';

export const INVOICE_FACTORING = 'invoice_factoring';
export const INVOICE_DISCOUNTING = 'invoice_discounting';
export const SELECTIVE_INVOICE_FINANCE = 'selective_invoice_finance';
export const SPOT_FACTORING = 'spot_factoring';

class BusinessInvoiceFactoringEnum extends BaseEnum {
    getTypes = () => {
        return {
            [INVOICE_FACTORING]: 'Invoice Factoring',
            [INVOICE_DISCOUNTING]: 'Invoice Discounting',
            [SELECTIVE_INVOICE_FINANCE]: 'Selective Invoice Finance',
            [SPOT_FACTORING]: 'Spot Factoring',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default BusinessInvoiceFactoringEnum;
