import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import { SubmissionError } from 'redux-form';
import styled from 'styled-components';
import renderErrorIfAuthorisationFail from '../../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { API_URL } from '../../../../../../bootstrap/constants/constants';
import { BRAND_MAIN_CONTACT } from '../../../../../../bootstrap/constants/constants';
import { ROLE_SUPER_ADMIN } from '../../../../../authentication/enum/Roles/NameEnum';
import { P4StyleComponentContainer, Container, RowItem } from '../../../../../../pitch4_layout/components/div';
import { NoQueryMutation } from '../../../../../../pitch4_forms/components';
import Mapper from '../../shared/form/Mapper';
import UserForm from '../../shared/form/UserForm';
import { ROUTE_P4A_ADMINISTER_USERS_LIST } from '../../../../../../pitch4_routing';

const StyledRowItem = styled(RowItem)`
    padding-bottom: 20px;
`;

class CreateContainer extends React.Component {
    render() {
        return (
            <Container className={`account-settings`}>
                <StyledRowItem className={`account-settings-item`}>
                    <P4StyleComponentContainer
                        header={`Create User`}
                        content={
                            <NoQueryMutation
                                mutationQuery={gql`
                                    mutation CreateUser($input: pitch4admin_createUserInput!) {
                                        createUser(input: $input) {
                                            user {
                                                id
                                                displayNameTemplates
                                                username
                                                email
                                                createdAt
                                                updatedAt
                                                status
                                                userProfile {
                                                    id
                                                    title
                                                    given
                                                    family
                                                    createdAt
                                                    updatedAt
                                                    dateOfBirth
                                                    primaryAddress {
                                                        address1
                                                        address2
                                                        address3
                                                        address4
                                                        country
                                                        postcode
                                                    }
                                                    primaryPhone {
                                                        number
                                                    }
                                                }
                                                roles {
                                                    name
                                                }
                                                primaryLender {
                                                    lender {
                                                        id
                                                    }
                                                }
                                            }
                                        }
                                    }
                                `}
                                renderFormFunction={(mutate, props) => {
                                    return <UserForm onSubmit={this.onSubmit(mutate, props)} />;
                                }}
                            />
                        }
                    />
                </StyledRowItem>
            </Container>
        );
    }

    onSubmit = (mutate, props) => (values, dispatch, formProps) => {
        let { notificationSuccess, notificationError, redirect } = props;

        const mapper = new Mapper();
        const mapped = mapper.mapFromCreateForm(values);
        const exclude = ['jobTitle'];

        let mappedValues = Object.keys(mapped)
            .filter((key) => !exclude.includes(key))
            .reduce((obj, key) => {
                obj[key] = mapped[key];
                return obj;
            }, {});

        //map the variables if necessary
        return mutate({
            variables: {
                id: values.id,
                input: mappedValues,
            },
            context: {
                uri: API_URL,
            },
        })
            .then(({ data }) => {
                redirect(ROUTE_P4A_ADMINISTER_USERS_LIST)();
                notificationSuccess('User created successfully');
            })
            .catch((error) => {
                notificationError('Unable to save user data. Contact support on ' + BRAND_MAIN_CONTACT);
                throw new SubmissionError({ _error: error.graphQLErrors });
            });
    };
}

export default compose(withRouter, renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN]))(CreateContainer);
