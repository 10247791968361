import React from 'react';
import styled from 'styled-components';
import { CenteringContainer, Container as LayoutContainer } from 'pitchblack_react_utils/layout/index';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button/Button';

const DeleteContainer = styled(CenteringContainer)`
    padding: 0 20px 20px;
`;

const ConfirmContainer = styled(CenteringContainer)`
    margin-top: 20px;
`;

class ConfirmDeletion extends React.Component {
    handleOnClick = (document) => {
        let { mutate, onSuccess, onError } = this.props;

        //map the variables if necessary
        return mutate({
            variables: {
                id: document.id,
            },
        })
            .then(({ data }) => {
                onSuccess(data);
            })
            .catch((error) => {
                onError(error.graphQLErrors);
            });
    };

    render() {
        let { document, content } = this.props;

        return (
            <DeleteContainer>
                <LayoutContainer>{content()}</LayoutContainer>
                <ConfirmContainer>
                    <Button type="submit" onClick={() => this.handleOnClick(document)}>
                        Yes I am sure
                    </Button>
                </ConfirmContainer>
            </DeleteContainer>
        );
    }
}

ConfirmDeletion.propTypes = {
    document: PropTypes.object.isRequired,
    content: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    mutate: PropTypes.func.isRequired,
};

export default ConfirmDeletion;
