import React from 'react';
import { CenteringContainer } from '../../../../pitchblack_react_utils/layout/index';
import Item from '../../../../pitchblack_react_utils/layout/Item';
import RowItem from '../../../../pitchblack_react_utils/layout/RowItem';
import Container from '../../../../pitchblack_react_utils/layout/Container';
import CreateDocumentManagerFactory from '../../CreateDocumentManagerFactory';
import PropTypes from 'prop-types';
import AddMapper from '../../form/AddMapper';
import DocumentTypeSelect from './DocumentTypeSelect';
import UserStoreSelect from './UserStoreSelect';
import UploadButton from './UploadButton';
import { Checkbox } from '../../../../pitchblack_react_utils/form';
import PrimaryButton from '../../../../pitch4_layout/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../pitch4_layout/components/buttons/SecondaryButton';
import { RightAligned } from '../../../../pitch4_layout/components/div';

class AddForm extends React.Component {
    onSubmit = (values, setValidationErrors, props) => {
        const createDocumentManagerFactory = new CreateDocumentManagerFactory();

        let { id, onPending, onSuccess, onError, reset } = props;
        let { createMetaMutate, createPermissionMutate } = this.props;

        const { allowAddToUserStore, adminCaseManagement = false } = this.props;

        let mapper = new AddMapper();
        let mappedValues = mapper.mapFromForm(values);
        let { file, type, document, addToUserStore } = mappedValues;

        onPending(false);

        if (document) {
            let input = {
                contextEntity: id,
            };

            return createPermissionMutate({
                variables: {
                    id: document,
                    input: input,
                },
            })
                .then(() => {
                    onSuccess('Document shared', reset);
                })
                .catch((error) => {
                    onError('Error occurred in your submission. Please contact support.');
                });
        }

        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();

            fileReader.onloadend = (r) => {
                //map the variables if necessary
                const createDocumentManager = createDocumentManagerFactory.create(createMetaMutate);
                createDocumentManager
                    .setContextId(id)
                    .setFileReader(r)
                    .setFile(file)
                    .setType(type)
                    .setAddToUserStore(addToUserStore);

                return createDocumentManager
                    .createMeta(allowAddToUserStore)
                    .then(({ data }) => {
                        const op = Object.keys(data)[0];
                        let { uploadUrl } = data[op].document;

                        if (adminCaseManagement) {
                            uploadUrl = uploadUrl + '/acm';
                        }

                        try {
                            return createDocumentManager.uploadFile(uploadUrl).then(() => {
                                onSuccess('Document uploaded', reset, resolve);
                            });
                        } catch (error) {
                            resolve();
                            onError('Error occurred in your submission. Please contact support.');
                        }
                    })
                    .catch((error) => {
                        resolve();
                        onError('Error occurred in your submission. Please contact support.');
                    });
            };

            fileReader.readAsArrayBuffer(file);
        });
    };

    renderTypeSelect = () => {
        let { types, userStoreDocument } = this.props;

        return <DocumentTypeSelect types={types} disabled={!!userStoreDocument} />;
    };

    renderFileInput = () => {
        let { userStoreDocument } = this.props;

        return <UploadButton disabled={!!userStoreDocument} />;
    };

    renderAddToUserStoreCheckbox = () => {
        let { userStoreDocument } = this.props;

        return <Checkbox name="addToUserStore" label="Also add to user store" disabled={!!userStoreDocument} />;
    };

    renderOwnStoreSelect = () => {
        let { types, userDocuments, submittedDocuments, loading = false } = this.props;

        return (
            <UserStoreSelect
                userDocuments={userDocuments}
                submittedDocuments={submittedDocuments}
                loading={loading}
                types={types}
            />
        );
    };

    render() {
        let {
            handleSubmit,
            reset,
            submitting,
            initialValues,
            createPermissionMutate = null,
            allowAddToUserStore,
        } = this.props;

        return (
            <CenteringContainer>
                <Item xs={12}>
                    <form onSubmit={handleSubmit(this.onSubmit)}>
                        <Container>
                            <RowItem>
                                <Container>
                                    <Item xs={12}>{this.renderFileInput()}</Item>
                                </Container>
                            </RowItem>
                            <RowItem>
                                <Container>
                                    <Item xs={12}>{this.renderTypeSelect(initialValues.type)}</Item>
                                </Container>
                            </RowItem>
                            {allowAddToUserStore && (
                                <RowItem>
                                    <Container>
                                        <Item xs={12}>
                                            <div className={'checkbox padding-left-10 margin-top-25'}>
                                                {this.renderAddToUserStoreCheckbox(initialValues.addToUserStore)}
                                            </div>
                                        </Item>
                                    </Container>
                                </RowItem>
                            )}
                            {createPermissionMutate && (
                                <RowItem>
                                    <Container>
                                        <Item xs={12}>{this.renderOwnStoreSelect()}</Item>
                                    </Container>
                                </RowItem>
                            )}
                            <RowItem>
                                <RightAligned className={'button-panel create-reset-buttons'}>
                                    <SecondaryButton type="reset" disabled={submitting} onClick={reset}>
                                        Reset
                                    </SecondaryButton>
                                    <PrimaryButton type="submit" disabled={submitting}>
                                        Submit
                                    </PrimaryButton>
                                </RightAligned>
                            </RowItem>
                        </Container>
                    </form>
                </Item>
            </CenteringContainer>
        );
    }
}

AddForm.propTypes = {
    initialValues: PropTypes.object.isRequired,
    setValidationErrors: PropTypes.func.isRequired,
    changeFile: PropTypes.func.isRequired,
    mapper: PropTypes.object.isRequired,
    types: PropTypes.object.isRequired,
    userDocuments: PropTypes.array,
    userStoreDocument: PropTypes.string,
    createMetaMutate: PropTypes.func.isRequired,
    createPermissionMutate: PropTypes.func,
    loading: PropTypes.bool,
    id: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    onPending: PropTypes.func,
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    allowAddToUserStore: PropTypes.bool,
};

export default AddForm;
