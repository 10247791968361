import createP4ApolloFetch from 'bootstrap/middlewares';
import { BORROWER_URL } from '../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

const mutation = `
    mutation resendVerificationEmail {
      resendVerificationEmail {
        success
      }
    }
`;

export const resendVerificationEmail = () => {
    return apolloFetch({
        query: mutation,
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};
