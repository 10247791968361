import React from 'react';
import SingleColumnLayout from './layouts/SingleColumnLayout';
import NotificationsContainer from '../apps/notifications/containers/Container';
import ModalContainer from '../apps/modal/containers/Container';
import NavigationContainer from '../apps/navigation/containers/NavigationContainer';
import { Route, Switch } from 'react-router';
import { ROUTE_2FA_RESET, ROUTE_2FA_RESET_SUCCESS } from '../pitch4_routing';
import ResetTwoFactorAuthContainer from '../apps/two_factor_auth_reset/containers/ResetTwoFactorAuthContainer';
import ResetTwoFactorAuthSuccessContainer from '../apps/two_factor_auth_reset/containers/ResetTwoFactorAuthSuccessContainer';

class TwoFactorAuthResetPage extends React.Component {
    render() {
        return (
            <SingleColumnLayout header={<NavigationContainer />} notifications={<NotificationsContainer />}>
                <Switch>
                    <Route path={ROUTE_2FA_RESET_SUCCESS} component={ResetTwoFactorAuthSuccessContainer} />
                    <Route path={ROUTE_2FA_RESET} component={ResetTwoFactorAuthContainer} />
                </Switch>
                <ModalContainer />
            </SingleColumnLayout>
        );
    }
}

export default TwoFactorAuthResetPage;
