import { BaseEnum } from 'pitch4_enum';

export const TYPE_1TO14 = '1_14';
export const TYPE_14TO28 = '14_28';
export const TYPE_28TO60 = '28_60';
export const TYPE_GT60 = '60+';

class LoanNeededByEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_1TO14]: '1-14 days',
            [TYPE_14TO28]: '14-28 days',
            [TYPE_28TO60]: '28-60 days',
            [TYPE_GT60]: '60+ days',
        };
    };
}

export default LoanNeededByEnum;
