import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';

import PasswordResetRequestForm from '../components/PasswordResetRequestForm';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { CenteringContainer } from '../../../pitch4_layout/components/div';
import { Container, Item } from '../../../pitchblack_react_utils/layout';
import {
    actionFlagPasswordResetRequested,
    actionReset,
    selectorHasForgottenPasswordBeenRequested,
    selectorHasForgottenPasswordBeenRequestedSuccessfully,
} from '../redux/flagPasswordReset';
import PasswordResetRequestSuccess from '../components/PasswordResetRequestSuccess';
import PropTypes from 'prop-types';
import { BRAND_NAME, PLATFORM_REGISTER_URL, TOS_URL, WEB_HOST } from '../../../bootstrap/constants/constants';

const sessionManager = new SessionManager();

class PasswordResetRequestContainer extends React.Component {
    componentWillUnmount() {
        const { reset } = this.props;
        reset();
    }

    render() {
        const {
            dashboardRedirect,
            hasForgottenPasswordBeenRequestedSuccessfully,
            hasForgottenPasswordBeenRequested,
        } = this.props;

        if (sessionManager.hasAssumedToken()) {
            dashboardRedirect();
        }

        return (
            <React.Fragment>
                <Item className={'content-container'}>
                    {hasForgottenPasswordBeenRequestedSuccessfully ? (
                        <PasswordResetRequestSuccess />
                    ) : (
                        <PasswordResetRequestForm
                            onSubmit={this.onSubmit}
                            hasForgottenPasswordBeenRequested={hasForgottenPasswordBeenRequested}
                        />
                    )}
                </Item>
                <Container className={'footer-container'}>
                    <React.Fragment>
                        <CenteringContainer>
                            <a href={PLATFORM_REGISTER_URL}>Need an account? Click here to register</a>
                        </CenteringContainer>
                        <CenteringContainer className={'links'}>
                            <a href={WEB_HOST}>About {BRAND_NAME}</a>
                            <a href={`${WEB_HOST}/contact-us`}>Contact Us</a>
                            <a href={TOS_URL}>Terms & Conditions</a>
                        </CenteringContainer>
                    </React.Fragment>
                </Container>
            </React.Fragment>
        );
    }

    onSubmit = (values) => {
        let { flagPasswordResetRequested } = this.props;
        let { email } = values;

        return flagPasswordResetRequested(email);
    };
}

PasswordResetRequestContainer.propTypes = {
    hasForgottenPasswordBeenRequested: PropTypes.bool.isRequired,
    hasForgottenPasswordBeenRequestedSuccessfully: PropTypes.bool.isRequired,
    flagPasswordResetRequested: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        hasForgottenPasswordBeenRequested: selectorHasForgottenPasswordBeenRequested(state),
        hasForgottenPasswordBeenRequestedSuccessfully: selectorHasForgottenPasswordBeenRequestedSuccessfully(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (uri) => () => dispatch(push(uri)),
        flagPasswordResetRequested: (email) => dispatch(actionFlagPasswordResetRequested(email)),
        reset: () => dispatch(actionReset()),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(PasswordResetRequestContainer);
