import React from 'react';
import PropTypes from 'prop-types';

export default function Status(props) {
    const { status, style = {} } = props;

    return <div style={style} className={`le-table-status data-grid-table-status ${status}`} />;
}

Status.propTypes = {
    status: PropTypes.string.isRequired,
};
