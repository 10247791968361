import React from 'react';

export default function HeaderWithCount(props) {
    const { title, count = null, countText = null } = props;
    return (
        <div className={'header-with-count text'}>
            <h1>{title}</h1>
            {null !== count && null !== countText && (
                <div style={{ position: 'relative', top: -5, marginBottom: 5, textAlign: 'left' }}>
                    {new Intl.NumberFormat('en-UK').format(count)} {countText}
                </div>
            )}
        </div>
    );
}
