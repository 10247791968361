import {
    QUESTION_ADDITIONAL_INFO,
    QUESTION_APPLICANT_AGE_AT_SUBMISSION,
    QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
    QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
    QUESTION_APPLICANT_MAIN_NATIONALITY,
    QUESTION_APPLICANT_MAIN_OCCUPATION,
    QUESTION_BUILD_COSTS_BORROWING,
    QUESTION_BUSINESS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION,
    QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT,
    QUESTION_BUSINESS_FACILITY_TERM,
    QUESTION_BUSINESS_INVOICE_FINANCE_TYPE,
    QUESTION_BUSINESS_IS_UK_REGISTERED,
    QUESTION_BUSINESS_OUTSTANDING_DEBT,
    QUESTION_BUSINESS_OUTSTANDING_INVOICES,
    QUESTION_BUSINESS_PDQ_INCOME,
    QUESTION_BUSINESS_PROFITABLE,
    QUESTION_BUSINESS_REFINANCE,
    QUESTION_BUSINESS_SECTOR,
    QUESTION_BUSINESS_TRADING_LENGTH,
    QUESTION_BUSINESS_TYPE,
    QUESTION_BUY_TO_LET_EXPERIENCE,
    QUESTION_COMMERCIAL_OWNER_OCCUPIED,
    QUESTION_COMMERCIAL_TYPES,
    QUESTION_COMMERCIAL_UNIT_COUNT,
    QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
    QUESTION_CURRENT_VALUE,
    QUESTION_DAY_ONE_LOAN_AMOUNT,
    QUESTION_DEVELOPMENT_LENGTH,
    QUESTION_EXPECTED_END_VALUE,
    QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE,
    QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
    QUESTION_HMO_BEDROOMS,
    QUESTION_INTEND_TO_DEVELOP_OR_REFURBISH,
    QUESTION_IS_UK_CITIZEN,
    QUESTION_LOAN_AMOUNT,
    QUESTION_LOAN_NEEDED_BY,
    QUESTION_LOAN_PURPOSE,
    QUESTION_LOAN_TERM,
    QUESTION_LOAN_TO_COST,
    QUESTION_LOAN_TO_VALUE,
    QUESTION_LOAN_TYPE,
    QUESTION_MERCHANT_PRODUCT,
    QUESTION_OCCUPY_PROPERTY,
    QUESTION_OUTSTANDING_LOAN,
    QUESTION_OWN_ANY_OTHER_PROPERTY,
    QUESTION_PERCENTAGE_RESIDENTIAL,
    QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
    QUESTION_PLANNING_IN_PLACE,
    QUESTION_PORTFOLIO_ALREADY_OWNED,
    QUESTION_PORTFOLIO_PROPERTIES,
    QUESTION_PORTFOLIO_VALUE,
    QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE,
    QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY,
    QUESTION_PROPERTY_LOCATION,
    QUESTION_PROPERTY_OWNERSHIP,
    QUESTION_PROPERTY_TYPE,
    QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
    QUESTION_REFURBISHMENT_LENGTH,
    QUESTION_RENTAL_INCOME,
    QUESTION_RENTAL_INCOME_RESIDENTIAL,
    QUESTION_RESIDENTIAL_REFINANCE,
    QUESTION_RESIDENTIAL_UNIT_COUNT,
    QUESTION_RESIDENTIAL_UNIT_VALUE,
    QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS,
    QUESTION_SITE_INTENTIONS,
    QUESTION_TOTAL_DEVELOPMENT_COSTS,
    QUESTION_TURN_OVER,
    QUESTION_UNITS_PRE_SOLD,
} from '../MakeLoanEnquiry/enums/Broker/BrokerQuestions';

export const toCamelCase = (str) => str.trim().replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));

export const getMainApplicantName = (loanEnquiryApplicants) => {
    let applicantName = '';
    loanEnquiryApplicants.forEach((loanEnquiryApplicant) => {
        if (loanEnquiryApplicant.isMainApplicant) {
            applicantName = loanEnquiryApplicant.applicant.given + ' ' + loanEnquiryApplicant.applicant.family;
        }
    });
    return applicantName;
};

const convertStoredDataKeyToQuestion = (key, questions) => {
    switch (key) {
        case 'ADVERSE_CREDIT':
        case 'BORROWER_HAS_ADVERSE_CREDIT':
        case 'BORROWER_HAS_CCJS':
        case 'BORROWER_HAS_DEFAULTED':
            return questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES];
        case 'BEDROOMS':
            return questions[QUESTION_HMO_BEDROOMS];
        case 'BORROWER_IS_UK_CITIZEN':
        case 'BORROWER_RIGHT_TO_RESIDE':
        case 'UK_CITIZEN':
            return questions[QUESTION_IS_UK_CITIZEN];
        case 'BORROWER_OWN_OTHER_PROPERTIES':
        case 'OWN_OTHER_PROPERTIES':
            return questions[QUESTION_OWN_ANY_OTHER_PROPERTY];
        case 'BUILD_COST':
        case 'BUILD_COSTS':
            return questions[QUESTION_BUILD_COSTS_BORROWING];
        case 'BUSINESS_ADDITIONAL_SECURITY':
            return questions[QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY];
        case 'BUSINESS_HAD_ADVERSE_CREDIT':
            return questions[QUESTION_BUSINESS_ADVERSE_CREDIT];
        case 'BUSINESS_ADVERSE_CREDIT_DETAILS':
            return questions[QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION];
        case 'BUSINESS_LOAN_TERM':
        case 'LOAN_DURATION':
        case 'LOAN_TERM':
            return questions[QUESTION_LOAN_TERM];
        case 'BUSINESS_NON_REFINANCE_ASSET':
            return questions[QUESTION_BUSINESS_REFINANCE];
        case 'BUSINESS_OUTSTANDING_INVOICE':
        case 'BUSINESS_OUTSTANDING_INVOICE_AMOUNT':
            return questions[QUESTION_BUSINESS_OUTSTANDING_INVOICES];
        case 'BUSINESS_PROFITABLE':
            return questions[QUESTION_BUSINESS_PROFITABLE];
        case 'BUSINESS_SECTOR':
            return questions[QUESTION_BUSINESS_SECTOR];
        case 'BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT':
            return questions[QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT];
        case 'BUSINESS_STRUCTURE':
            return questions[QUESTION_BUSINESS_TYPE];
        case 'BUSINESS_TOTAL_DEBTS':
            return questions[QUESTION_BUSINESS_OUTSTANDING_DEBT];
        case 'BUSINESS_TRADING_MONTHS':
            return questions[QUESTION_BUSINESS_TRADING_LENGTH];
        case 'BUY_TO_LET_EXPERIENCE':
            return questions[QUESTION_BUY_TO_LET_EXPERIENCE];
        case 'COMMERCIAL_RENTED':
        case 'PROPERTY_TO_BE_RENTED_OUT':
            return questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT];
        case 'COMMERCIAL_TYPE':
        case 'TYPE_OF_COMMERCIAL':
            return questions[QUESTION_COMMERCIAL_TYPES];
        case 'CURRENT_VALUE':
        case 'PROPERTY_PURCHASE_PRICE':
            return questions[QUESTION_CURRENT_VALUE];
        case 'DAY_ONE_LOAN_AMOUNT':
        case 'INITIAL_LOAN_AMOUNT_REQUIRED':
            return questions[QUESTION_DAY_ONE_LOAN_AMOUNT];
        case 'DEVELOPMENT_COSTS':
            return questions[QUESTION_TOTAL_DEVELOPMENT_COSTS];
        case 'DEVELOPMENT_DURATION':
        case questions[QUESTION_REFURBISHMENT_LENGTH]:
        case 'EXPECTED_BUILD_TIME_MONTHS':
            return questions[QUESTION_DEVELOPMENT_LENGTH];
        case 'DEVELOPMENT_EXPERIENCE':
        case 'HAS_EXPERIENCE_DEVELOPING':
            return questions[QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE];
        case 'END_VALUE':
        case 'EXPECTED_END_VALUE':
            return questions[QUESTION_EXPECTED_END_VALUE];
        case 'GDV':
            return questions[QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE];
        case 'INVOICE_FINANCE_PRODUCTS':
            return questions[QUESTION_BUSINESS_INVOICE_FINANCE_TYPE];
        case 'PLANNING_DESCRIPTION':
            return questions[QUESTION_SITE_INTENTIONS];
        case 'BORROWER_DEFAULTED_WHEN':
        case 'BORROWER_DEFAULTED_HOW_MUCH':
            return questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION];
        case 'LAND_HAS_PLANNING_PERMISSION':
        case 'PLANNING_IN_PLACE':
        case 'REFURBISHMENT_NOT_REQUIRE_PLANNING':
        case 'REQUIRES_PLANNING_PERMISSION':
            return questions[QUESTION_PLANNING_IN_PLACE];
        case 'LOAN_AMOUNT':
            return questions[QUESTION_LOAN_AMOUNT];
        case 'LOAN_NEEDED_BY':
            return questions[QUESTION_LOAN_NEEDED_BY];
        case 'LOAN_PURPOSE':
        case 'LOAN_REASON':
            return questions[QUESTION_LOAN_PURPOSE];
        case 'LOAN_TYPE':
            return questions[QUESTION_LOAN_TYPE];
        case 'LOCATION':
        case 'PROPERTY_COUNTRY':
            return questions[QUESTION_PROPERTY_LOCATION];
        case 'NUMBER_COMMERCIAL_UNITS':
            return questions[QUESTION_COMMERCIAL_UNIT_COUNT];
        case 'NUMBER_RESIDENTIAL_UNITS':
        case 'RESIDENTIAL_UNITS':
            return questions[QUESTION_RESIDENTIAL_UNIT_COUNT];
        case 'OUTSTANDING_LOAN':
        case 'CURRENT_LOAN_AMOUNT':
            return questions[QUESTION_OUTSTANDING_LOAN];
        case 'OWNER_OCCUPIED':
        case 'BUSINESS_INTEND_TO_OCCUPY':
            return questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED];
        case 'PDQ_TURNOVER_PER_MONTH':
            return questions[QUESTION_BUSINESS_PDQ_INCOME];
        case 'PERCENTAGE_FLOOR_SPACE_RESIDENTIAL':
            return questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT];
        case 'PERCENTAGE_RESIDENTIAL':
            return questions[QUESTION_PERCENTAGE_RESIDENTIAL];
        case 'PORTFOLIO_ALREADY_OWNED':
        case 'INTEND_TO_FINANCE_PORTFOLIO':
            return questions[QUESTION_PORTFOLIO_ALREADY_OWNED];
        case 'PORTFOLIO_PROPERTIES':
            return questions[QUESTION_PORTFOLIO_PROPERTIES];
        case 'PORTFOLIO_VALUE':
            return questions[QUESTION_PORTFOLIO_VALUE];
        case 'PREVIOUS_YEAR_TURNOVER':
            return questions[QUESTION_TURN_OVER];
        case 'PROPERTY_EXPECTED_RENTAL_INCOME':
        case 'EXPECTED_RENTAL_INCOME':
            return questions[QUESTION_RENTAL_INCOME_RESIDENTIAL];
        case 'PROPERTY_HOW_OWNED':
        case 'PROPERTY_OWNERSHIP':
            return questions[QUESTION_PROPERTY_OWNERSHIP];
        case 'PROPERTY_IS_ALREADY_OWNED':
        case 'REFINANCE':
            return questions[QUESTION_RESIDENTIAL_REFINANCE];
        case 'PROPERTY_TYPE':
            return questions[QUESTION_PROPERTY_TYPE];
        case 'REFURBISHMENT_FUNDING':
        case 'TOTAL_REFURBISHMENT_COST':
            return questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY];
        case 'REGISTER_USER_AGE_POINTINTIME':
        case 'REGISTER_USER_AGE_POINT_IN_TIME':
            return questions[QUESTION_APPLICANT_AGE_AT_SUBMISSION];
        case 'RESIDENTIAL_UNITS_MONTHLY_INCOME':
            return questions[QUESTION_RENTAL_INCOME];
        case 'RESIDENTIAL_UNITS_VALUE':
            return questions[QUESTION_RESIDENTIAL_UNIT_VALUE];
        case 'SHAREHOLDER_IS_HOMEOWNER':
            return questions[QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS];
        case 'UNIT_PRE_SOLD':
            return questions[QUESTION_UNITS_PRE_SOLD];
        case 'LOAN_LTC':
            return questions[QUESTION_LOAN_TO_COST];
        case 'LTV':
        case 'LOAN_LTV':
            return questions[QUESTION_LOAN_TO_VALUE];
        case 'BUSINESS_UK_REGISTERED':
            return questions[QUESTION_BUSINESS_IS_UK_REGISTERED];
        case 'INTEND_TO_DEVELOP_OR_REFURBISH':
            return questions[QUESTION_INTEND_TO_DEVELOP_OR_REFURBISH];
        case 'BUSINESS_PRODUCT':
            return questions[QUESTION_MERCHANT_PRODUCT];
        case 'PROPERTY_INTEND_TO_OCCUPY':
            return questions[QUESTION_OCCUPY_PROPERTY];
        case 'PROPERTY_WAS_OCCUPIED':
            return questions[QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY];
        case 'BORROWER_OCCUPATION':
            return questions[QUESTION_APPLICANT_MAIN_OCCUPATION];
        case 'BORROWER_GROWTH_AND_INCOME':
            return questions[QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME];
        case 'BORROWER_COUNTRY_OF_RESIDENCE':
            return questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE];
        case 'BORROWER_NATIONALITY':
            return questions[QUESTION_APPLICANT_MAIN_NATIONALITY];
        case 'TENANCY_TERM':
            return questions[QUESTION_BUSINESS_FACILITY_TERM];
        case 'ADDITIONAL_INFO_NOTES':
            return questions[QUESTION_ADDITIONAL_INFO];
        default:
            return key;
    }
};

export const convertStoredDataKeyToLabel = (key, questions) => {
    return convertStoredDataKeyToQuestion(key, questions)['label'];
};

export const convertStoredDataKeyToFieldName = (key, questions) => {
    return convertStoredDataKeyToQuestion(key, questions)['fieldName'];
};

export const getStoredDataValueFromArray = (
    storedData,
    key,
    loanEnquiryData = null,
    questionField = null,
    defaultValue = 'N/A'
) => {
    let value = defaultValue;
    if (storedData !== undefined && storedData !== null && storedData.length > 0) {
        const storedDatum = storedData.find((item) => item.meta.key === key);
        if (storedData) {
            value = storedDatum.value;
        }
    } else if (loanEnquiryData !== undefined && loanEnquiryData !== null) {
        if (loanEnquiryData[questionField] !== null) {
            if (questionField in loanEnquiryData) {
                value = loanEnquiryData[questionField];
            } else {
                value = loanEnquiryData[toCamelCase(questionField)];
            }
        }
    }
    return value;
};

export const getStoredDataValueFromEdges = (storedData, key) => {
    let value = '';
    storedData.forEach((storedDatum) => {
        if (storedDatum.node.meta.key === key) {
            value = storedDatum.node.value;
        }
    });
    return value;
};
