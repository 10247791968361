import React from 'react';
import { Button } from '@material-ui/core';
import StartApplicationModal from '../../apps/borrower/view_loan_enquiry/components/ApplicationSection/StartApplicationModal';

export default function StartApplication(props) {
    const {
        chatTabIndex,
        chatChannel,
        updateLLEStatus,
        matchSelected,
        setModalComponent,
        setModalClose,
        toggleLenderDrawer,
    } = props;
    const handleClick = () => {
        setModalComponent(
            <StartApplicationModal
                toggleLenderDrawer={toggleLenderDrawer}
                chatTabIndex={chatTabIndex}
                updateLLEStatus={updateLLEStatus}
                matchSelected={matchSelected}
                setModalClose={setModalClose}
                setModalComponent={setModalComponent}
            />,
            true
        );
    };

    return (
        <div className={'start-application-container'}>
            <div className={'header'}>Are you happy with these terms?</div>
            <Button className={'btn'} onClick={handleClick} disabled={null === chatChannel}>
                START APPLICATION
            </Button>
        </div>
    );
}
