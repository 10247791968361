import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const Item = (props) => {
    return (
        <Grid key={props.itemKey} item {...props}>
            {props.children}
        </Grid>
    );
};
Item.propTypes = {
    xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    className: PropTypes.any,
    itemKey: PropTypes.any,
};

export default Item;
