// see https://github.com/erikras/ducks-modular-redux
export const NAMESPACE = 'anon.two_factor_auth_reset';

// Actions
export const RESET_2FA = `${NAMESPACE}.reset_two_factor_auth`;
export const GET_RESET_2FA_TOKEN = `${NAMESPACE}.get_reset_token`;
export const SET_RESET_2FA_TOKEN = `${NAMESPACE}.set_reset_token`;

export const actionGetResetToken = (tokenId) => {
    return {
        type: GET_RESET_2FA_TOKEN,
        tokenId,
    };
};

export const actionSetResetToken = (token) => {
    return {
        type: SET_RESET_2FA_TOKEN,
        token,
    };
};

export const actionResetTwoFactorAuth = () => {
    return {
        type: RESET_2FA,
    };
};

const initialState = {
    token: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_RESET_2FA_TOKEN:
            return {
                ...state,
                token: action.token,
            };
        default:
            return { ...state };
    }
};

export const getResetToken = (state) => {
    return state[NAMESPACE].token;
};

export default reducer;
