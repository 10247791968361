import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import { SLAM_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: SLAM_URL });

const BusinessFinanceBusinessStreamMutation = `
    mutation createBusinessFinanceBusinessStream($input: slam_createBusinessFinanceBusinessStreamInput!){
      createBusinessFinanceBusinessStream(input: $input){
        businessStream {
            id
        }
      }
    }
`;

const PropertyFinanceBusinessStreamMutation = `
    mutation createPropertyFinanceBusinessStream($input: slam_createPropertyFinanceBusinessStreamInput!){
      createPropertyFinanceBusinessStream(input: $input){
        businessStream {
            id
        }
      }
    }
`;

//response classes
const errorFactory = new ValidationResultFactory();

class CreateBusinessStream {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class CreateBusinessStreamErrors extends CreateBusinessStream {
    constructor(errors) {
        super('CreateBusinessStreamErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class CreateBusinessStreamSuccess extends CreateBusinessStream {
    constructor(data) {
        super('CreateBusinessStreamSuccess');
        this.data = data;
    }

    getData = () => {
        return this.data;
    };
}

export const createBusinessStream = (name, formSections, isBusinessFinanceStream, formVersion) => {
    let out = apolloFetch({
        query:
            isBusinessFinanceStream === true
                ? BusinessFinanceBusinessStreamMutation
                : PropertyFinanceBusinessStreamMutation,
        variables: {
            input: {
                formVersion,
                name,
                formSections,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new CreateBusinessStreamErrors(vR);
            }

            return new CreateBusinessStreamSuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new CreateBusinessStreamErrors(vR);
        });

    return out;
};
