import { spawn } from 'redux-saga/effects';

import pitch4adminRoot from '../pitch4admin/sagas/index';
import slamRoot from '../slam/sagas/index';
import intermediaryRoot from '../intermediary/sagas/index';

function* main() {
    yield spawn(pitch4adminRoot);
    yield spawn(slamRoot);
    yield spawn(intermediaryRoot);
}

export default main;
