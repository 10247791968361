import { call, put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { change, untouch } from 'redux-form';
import {
    actionSetBusinessStream,
    actionSetStoreDataMeta,
    GET_BUSINESS_STREAM,
    GET_MATCHING_CRITERIA_CONFIG,
    GET_STORED_DATA_META,
    NAMESPACE,
    RESET,
    RESET_FIELDS,
    SUBMIT_CREATE_FORM,
    SUBMIT_EDIT_FORM,
} from '../redux';
import { actionSetConfig, actionSetInitialValues } from '../../../../form_wizard/redux';
import { createBusinessStream } from '../api/create_business_stream';
import { getBusinessStream } from '../api/get_business_stream';
import { editBusinessStream } from '../api/edit_business_stream';
import { getStoredDataMeta } from '../api/get_stored_data_meta';
import { getConfig } from '../api/get_config';
import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../../../notifications/sagas/container';
import { path, ROUTE_SLAM_BUSINESS_STREAMS } from 'pitch4_routing';
import { baseYields } from '../../../../form_wizard/sagas';
import { isEmpty } from 'pitch4_validation';

function* submitCreateBusinessStreamForm(action) {
    const { name, formSections, isBusinessFinanceStream, version } = action;
    const response = yield call(createBusinessStream, name, formSections, isBusinessFinanceStream, version);

    switch (response.getClassName()) {
        case 'CreateBusinessStreamSuccess':
            yield put({ type: SAGA_ACTION_SUCCESS, message: 'Successfully created business stream' });
            yield put({ type: RESET });
            yield put(push(path(ROUTE_SLAM_BUSINESS_STREAMS, {})));
            break;
        case 'CreateBusinessStreamError':
            yield put({
                type: SAGA_ACTION_ERROR,
                message: 'There was a problem with the submission. Unable to create business stream at this time.',
            });
            break;
        default:
            break;
    }
}

function* submitEditBusinessStreamForm(action) {
    const { businessStreamId, name, formSections, isBusinessFinanceStream, version } = action;
    const response = yield call(
        editBusinessStream,
        businessStreamId,
        name,
        formSections,
        isBusinessFinanceStream,
        version
    );

    switch (response.getClassName()) {
        case 'EditBusinessStreamSuccess':
            yield put({ type: SAGA_ACTION_SUCCESS, message: 'Successfully edit business stream' });
            yield put({ type: RESET });
            yield put(push(path(ROUTE_SLAM_BUSINESS_STREAMS, {})));
            break;
        case 'EditBusinessStreamError':
            yield put({
                type: SAGA_ACTION_ERROR,
                message: 'There was a problem with the submission. Unable to edit business stream at this time.',
            });
            break;
        default:
            break;
    }
}

function* getBusinessStreamValues(action) {
    const { businessStreamId } = action;
    const response = yield call(getBusinessStream, businessStreamId);

    switch (response.getClassName()) {
        case 'GetBusinessStreamSuccess':
            const businessStream = response.getBusinessStream();
            const initialValues = response.getFormSections();

            // Set the form initial values
            yield put(actionSetInitialValues(NAMESPACE)(initialValues));
            yield put(actionSetBusinessStream(businessStream));
            break;
        case 'GetBusinessStreamError':
        default:
            break;
    }
}

function* getMatchingCriteriaConfig() {
    const response = yield call(getConfig);

    switch (response.getClassName()) {
        case 'GetConfigSuccess':
            const config = response.getData();

            // Set the wizard config
            yield put(actionSetConfig(NAMESPACE)(config));
            break;
        case 'GetConfigError':
        default:
            break;
    }
}

function* getStoredDataMetaValues(action) {
    const response = yield call(getStoredDataMeta);
    yield put(actionSetStoreDataMeta(response.getData()));
}

function* resetFields(action) {
    const form = action.form;
    const section = action.section;
    const fieldNames = !isEmpty(action.fieldNames) ? action.fieldNames : [];

    for (let i = 0; i < fieldNames.length; i++) {
        yield put(untouch(form, [section + '.' + fieldNames[i]]));
        yield put(untouch(form, [section + '.' + fieldNames[i] + '.operation']));
        yield put(untouch(form, [section + '.' + fieldNames[i] + '.operand']));
        yield put(untouch(form, [section + '.' + fieldNames[i] + '.operand-start']));
        yield put(untouch(form, [section + '.' + fieldNames[i] + '.operand-end']));
        yield put(change(form, section + '.' + fieldNames[i] + '.operation', ''));
        yield put(change(form, section + '.' + fieldNames[i] + '.operand', ''));
        yield put(change(form, section + '.' + fieldNames[i] + '.operand-start', ''));
        yield put(change(form, section + '.' + fieldNames[i] + '.operand-end', ''));
    }
}

//Main Saga
function* main() {
    //response handling
    yield takeLatest(SUBMIT_CREATE_FORM, submitCreateBusinessStreamForm);
    yield takeLatest(SUBMIT_EDIT_FORM, submitEditBusinessStreamForm);
    yield takeLatest(GET_BUSINESS_STREAM, getBusinessStreamValues);
    yield takeLatest(GET_STORED_DATA_META, getStoredDataMetaValues);
    yield takeLatest(GET_MATCHING_CRITERIA_CONFIG, getMatchingCriteriaConfig);
    yield takeLatest(RESET_FIELDS, resetFields);

    // Core sagas - see form_wizard/sagas/index.js
    yield* baseYields(NAMESPACE);
}

export default main;
