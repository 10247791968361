import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import LenderLoanEnquiryStatusEnum, {
    STATUS_APPLICATION,
    STATUS_COMPLETE,
    STATUS_DECLINED,
    STATUS_IN_PROGRESS,
    STATUS_LEGALS,
    STATUS_MATCHED,
    STATUS_VALUATION,
    STATUS_ENDED,
    STATUS_MATCH_PENDING,
    STATUS_ON_HOLD,
    STATUS_COMPLETION_REQUESTED,
    STATUS_COMPLETION_REJECTED,
} from '../../../../../../pitch4_enum/enum/LenderLoanEnquiryStatusEnum';
import ChangeLLEStatusPopup from './ChangeLLEStatusPopup';
import { setLenderLoanEnquiryStatus } from '../../api/set_lle_status';
import _ from 'lodash';

export default function ChangeLLEStatus(props) {
    const {
        setModalComponent,
        setModalClose,
        currentStatus,
        matchId,
        handleMatchSelectedStatusChange,
        notificationError,
        leType,
    } = props;
    const [status, setStatus] = useState(currentStatus);
    const LLEStatusEnum = new LenderLoanEnquiryStatusEnum();
    const allowedStatuses = [
        STATUS_MATCHED,
        STATUS_IN_PROGRESS,
        STATUS_APPLICATION,
        STATUS_VALUATION,
        STATUS_LEGALS,
        STATUS_COMPLETE,
        STATUS_DECLINED,
        STATUS_ENDED,
        STATUS_MATCH_PENDING,
    ];
    const mapping = {
        // [TARGET_STATUS]: [FROM_STATUS_1, FROM_STATUS_2, FROM_STATUS_3,....]
        [STATUS_MATCHED]: [STATUS_MATCH_PENDING, STATUS_ON_HOLD],
        [STATUS_IN_PROGRESS]: [
            STATUS_MATCHED,
            STATUS_APPLICATION,
            STATUS_VALUATION,
            STATUS_LEGALS,
            STATUS_COMPLETION_REQUESTED,
            STATUS_COMPLETE,
            STATUS_COMPLETION_REJECTED,
            STATUS_DECLINED,
            STATUS_ON_HOLD,
        ],
        [STATUS_APPLICATION]: [
            STATUS_MATCHED,
            STATUS_IN_PROGRESS,
            STATUS_VALUATION,
            STATUS_LEGALS,
            STATUS_COMPLETION_REQUESTED,
            STATUS_COMPLETE,
            STATUS_COMPLETION_REJECTED,
            STATUS_DECLINED,
            STATUS_ON_HOLD,
        ],
        [STATUS_VALUATION]: [
            STATUS_MATCHED,
            STATUS_IN_PROGRESS,
            STATUS_APPLICATION,
            STATUS_LEGALS,
            STATUS_COMPLETION_REQUESTED,
            STATUS_COMPLETE,
            STATUS_COMPLETION_REJECTED,
            STATUS_DECLINED,
            STATUS_ON_HOLD,
        ],
        [STATUS_LEGALS]: [
            STATUS_MATCHED,
            STATUS_IN_PROGRESS,
            STATUS_APPLICATION,
            STATUS_VALUATION,
            STATUS_COMPLETION_REQUESTED,
            STATUS_COMPLETE,
            STATUS_COMPLETION_REJECTED,
            STATUS_DECLINED,
            STATUS_ON_HOLD,
        ],
        [STATUS_COMPLETE]: [
            STATUS_MATCHED,
            STATUS_IN_PROGRESS,
            STATUS_APPLICATION,
            STATUS_VALUATION,
            STATUS_LEGALS,
            STATUS_COMPLETION_REQUESTED,
            STATUS_COMPLETION_REJECTED,
            STATUS_DECLINED,
        ],
        [STATUS_DECLINED]: [
            STATUS_MATCHED,
            STATUS_IN_PROGRESS,
            STATUS_APPLICATION,
            STATUS_VALUATION,
            STATUS_LEGALS,
            STATUS_COMPLETION_REQUESTED,
            STATUS_COMPLETION_REJECTED,
            STATUS_ON_HOLD,
        ],
    };

    const handleMenuItemDisabled = (currentStatus, newStatus) => {
        // Remove this condition once setting statuses are fixed in the BE
        if (currentStatus === STATUS_MATCHED && newStatus !== STATUS_IN_PROGRESS) {
            return true;
        }

        return !_.includes(mapping[newStatus], currentStatus);
    };

    const handleSelectChange = (event) => {
        const status = event.target.value;
        setStatus(status);
        setModalComponent(
            <ChangeLLEStatusPopup
                currentStatus={currentStatus}
                setModalClose={setModalClose}
                newStatus={status}
                handleCancelStatusChange={handleCancelStatusChange}
                handleStatusChange={handleStatusChange}
                leType={leType}
            />,
            false
        );
    };

    const handleStatusChange = (newStatus) => {
        setLenderLoanEnquiryStatus(
            matchId,
            newStatus,
            `Admin has changed the status from ${currentStatus} to ${newStatus}`
        ).then((resp) => {
            if (resp.errors) {
                setStatus(currentStatus);
                notificationError('Updating match status failed.');
                setModalClose();
            } else {
                handleMatchSelectedStatusChange(newStatus);
            }
        });
    };

    const handleCancelStatusChange = () => {
        setStatus(currentStatus);
        setModalClose();
    };

    return (
        <div className={'change-lle-status-container'}>
            <FormControl variant="outlined">
                <InputLabel id="change-lle-status-outlined-label">Status</InputLabel>
                <Select
                    className={'change-lle-status-select'}
                    labelId="change-lle-status-outlined-label"
                    id="change-lle-status-outlined"
                    value={status}
                    onChange={handleSelectChange}
                    label="Status"
                >
                    {allowedStatuses.map((newStatus, index) => (
                        <MenuItem
                            disabled={handleMenuItemDisabled(currentStatus, newStatus)}
                            key={`clles_${index}`}
                            value={newStatus}
                            className={'change-lle-status-item'}
                        >
                            {LLEStatusEnum.getType(newStatus, leType)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}
