import { BaseEnum } from 'pitch4_enum';

export const NATIONALITY_AFGHAN = 'NATIONALITY_AFGHAN';
export const NATIONALITY_ALBANIAN = 'NATIONALITY_ALBANIAN';
export const NATIONALITY_ALGERIAN = 'NATIONALITY_ALGERIAN';
export const NATIONALITY_AMERICAN = 'NATIONALITY_AMERICAN';
export const NATIONALITY_ANDORRAN = 'NATIONALITY_ANDORRAN';
export const NATIONALITY_ANGOLAN = 'NATIONALITY_ANGOLAN';
export const NATIONALITY_ANGUILLAN = 'NATIONALITY_ANGUILLAN';
export const NATIONALITY_CITIZEN_OF_ANTIGUA_AND_BARBUDA = 'NATIONALITY_CITIZEN_OF_ANTIGUA_AND_BARBUDA';
export const NATIONALITY_ARGENTINE = 'NATIONALITY_ARGENTINE';
export const NATIONALITY_ARMENIAN = 'NATIONALITY_ARMENIAN';
export const NATIONALITY_AUSTRALIAN = 'NATIONALITY_AUSTRALIAN';
export const NATIONALITY_AUSTRIAN = 'NATIONALITY_AUSTRIAN';
export const NATIONALITY_AZERBAIJANI = 'NATIONALITY_AZERBAIJANI';
export const NATIONALITY_BAHAMIAN = 'NATIONALITY_BAHAMIAN';
export const NATIONALITY_BAHRAINI = 'NATIONALITY_BAHRAINI';
export const NATIONALITY_BANGLADESHI = 'NATIONALITY_BANGLADESHI';
export const NATIONALITY_BARBADIAN = 'NATIONALITY_BARBADIAN';
export const NATIONALITY_BELARUSIAN = 'NATIONALITY_BELARUSIAN';
export const NATIONALITY_BELGIAN = 'NATIONALITY_BELGIAN';
export const NATIONALITY_BELIZEAN = 'NATIONALITY_BELIZEAN';
export const NATIONALITY_BENINESE = 'NATIONALITY_BENINESE';
export const NATIONALITY_BERMUDIAN = 'NATIONALITY_BERMUDIAN';
export const NATIONALITY_BHUTANESE = 'NATIONALITY_BHUTANESE';
export const NATIONALITY_BOLIVIAN = 'NATIONALITY_BOLIVIAN';
export const NATIONALITY_CITIZEN_OF_BOSNIA_AND_HERZEGOVINA = 'NATIONALITY_CITIZEN_OF_BOSNIA_AND_HERZEGOVINA';
export const NATIONALITY_BOTSWANAN = 'NATIONALITY_BOTSWANAN';
export const NATIONALITY_BRAZILIAN = 'NATIONALITY_BRAZILIAN';
export const NATIONALITY_BRITISH = 'NATIONALITY_BRITISH';
export const NATIONALITY_BRITISH_VIRGIN_ISLANDER = 'NATIONALITY_BRITISH_VIRGIN_ISLANDER';
export const NATIONALITY_BRUNEIAN = 'NATIONALITY_BRUNEIAN';
export const NATIONALITY_BULGARIAN = 'NATIONALITY_BULGARIAN';
export const NATIONALITY_BURKINAN = 'NATIONALITY_BURKINAN';
export const NATIONALITY_BURMESE = 'NATIONALITY_BURMESE';
export const NATIONALITY_BURUNDIAN = 'NATIONALITY_BURUNDIAN';
export const NATIONALITY_CAMBODIAN = 'NATIONALITY_CAMBODIAN';
export const NATIONALITY_CAMEROONIAN = 'NATIONALITY_CAMEROONIAN';
export const NATIONALITY_CANADIAN = 'NATIONALITY_CANADIAN';
export const NATIONALITY_CAPE_VERDEAN = 'NATIONALITY_CAPE_VERDEAN';
export const NATIONALITY_CAYMAN_ISLANDER = 'NATIONALITY_CAYMAN_ISLANDER';
export const NATIONALITY_CENTRAL_AFRICAN = 'NATIONALITY_CENTRAL_AFRICAN';
export const NATIONALITY_CHADIAN = 'NATIONALITY_CHADIAN';
export const NATIONALITY_CHILEAN = 'NATIONALITY_CHILEAN';
export const NATIONALITY_CHINESE = 'NATIONALITY_CHINESE';
export const NATIONALITY_COLOMBIAN = 'NATIONALITY_COLOMBIAN';
export const NATIONALITY_COMORAN = 'NATIONALITY_COMORAN';
export const NATIONALITY_CONGOLESE_CONGO = 'NATIONALITY_CONGOLESE_CONGO';
export const NATIONALITY_CONGOLESE_DRC = 'NATIONALITY_CONGOLESE_DRC';
export const NATIONALITY_COOK_ISLANDER = 'NATIONALITY_COOK_ISLANDER';
export const NATIONALITY_COSTA_RICAN = 'NATIONALITY_COSTA_RICAN';
export const NATIONALITY_CROATIAN = 'NATIONALITY_CROATIAN';
export const NATIONALITY_CUBAN = 'NATIONALITY_CUBAN';
export const NATIONALITY_CYMRAES = 'NATIONALITY_CYMRAES';
export const NATIONALITY_CYMRO = 'NATIONALITY_CYMRO';
export const NATIONALITY_CYPRIOT = 'NATIONALITY_CYPRIOT';
export const NATIONALITY_CZECH = 'NATIONALITY_CZECH';
export const NATIONALITY_DANISH = 'NATIONALITY_DANISH';
export const NATIONALITY_DJIBOUTIAN = 'NATIONALITY_DJIBOUTIAN';
export const NATIONALITY_DOMINICAN = 'NATIONALITY_DOMINICAN';
export const NATIONALITY_CITIZEN_OF_THE_DOMINICAN_REPUBLIC = 'NATIONALITY_CITIZEN_OF_THE_DOMINICAN_REPUBLIC';
export const NATIONALITY_DUTCH = 'NATIONALITY_DUTCH';
export const NATIONALITY_EAST_TIMORESE = 'NATIONALITY_EAST_TIMORESE';
export const NATIONALITY_ECUADOREAN = 'NATIONALITY_ECUADOREAN';
export const NATIONALITY_EGYPTIAN = 'NATIONALITY_EGYPTIAN';
export const NATIONALITY_EMIRATI = 'NATIONALITY_EMIRATI';
export const NATIONALITY_ENGLISH = 'NATIONALITY_ENGLISH';
export const NATIONALITY_EQUATORIAL_GUINEAN = 'NATIONALITY_EQUATORIAL_GUINEAN';
export const NATIONALITY_ERITREAN = 'NATIONALITY_ERITREAN';
export const NATIONALITY_ESTONIAN = 'NATIONALITY_ESTONIAN';
export const NATIONALITY_ETHIOPIAN = 'NATIONALITY_ETHIOPIAN';
export const NATIONALITY_FAROESE = 'NATIONALITY_FAROESE';
export const NATIONALITY_FIJIAN = 'NATIONALITY_FIJIAN';
export const NATIONALITY_FILIPINO = 'NATIONALITY_FILIPINO';
export const NATIONALITY_FINNISH = 'NATIONALITY_FINNISH';
export const NATIONALITY_FRENCH = 'NATIONALITY_FRENCH';
export const NATIONALITY_GABONESE = 'NATIONALITY_GABONESE';
export const NATIONALITY_GAMBIAN = 'NATIONALITY_GAMBIAN';
export const NATIONALITY_GEORGIAN = 'NATIONALITY_GEORGIAN';
export const NATIONALITY_GERMAN = 'NATIONALITY_GERMAN';
export const NATIONALITY_GHANAIAN = 'NATIONALITY_GHANAIAN';
export const NATIONALITY_GIBRALTARIAN = 'NATIONALITY_GIBRALTARIAN';
export const NATIONALITY_GREEK = 'NATIONALITY_GREEK';
export const NATIONALITY_GREENLANDIC = 'NATIONALITY_GREENLANDIC';
export const NATIONALITY_GRENADIAN = 'NATIONALITY_GRENADIAN';
export const NATIONALITY_GUAMANIAN = 'NATIONALITY_GUAMANIAN';
export const NATIONALITY_GUATEMALAN = 'NATIONALITY_GUATEMALAN';
export const NATIONALITY_CITIZEN_OF_GUINEA_BISSAU = 'NATIONALITY_CITIZEN_OF_GUINEA_BISSAU';
export const NATIONALITY_GUINEAN = 'NATIONALITY_GUINEAN';
export const NATIONALITY_GUYANESE = 'NATIONALITY_GUYANESE';
export const NATIONALITY_HAITIAN = 'NATIONALITY_HAITIAN';
export const NATIONALITY_HONDURAN = 'NATIONALITY_HONDURAN';
export const NATIONALITY_HONG_KONGER = 'NATIONALITY_HONG_KONGER';
export const NATIONALITY_HUNGARIAN = 'NATIONALITY_HUNGARIAN';
export const NATIONALITY_ICELANDIC = 'NATIONALITY_ICELANDIC';
export const NATIONALITY_INDIAN = 'NATIONALITY_INDIAN';
export const NATIONALITY_INDONESIAN = 'NATIONALITY_INDONESIAN';
export const NATIONALITY_IRANIAN = 'NATIONALITY_IRANIAN';
export const NATIONALITY_IRAQI = 'NATIONALITY_IRAQI';
export const NATIONALITY_IRISH = 'NATIONALITY_IRISH';
export const NATIONALITY_ISRAELI = 'NATIONALITY_ISRAELI';
export const NATIONALITY_ITALIAN = 'NATIONALITY_ITALIAN';
export const NATIONALITY_IVORIAN = 'NATIONALITY_IVORIAN';
export const NATIONALITY_JAMAICAN = 'NATIONALITY_JAMAICAN';
export const NATIONALITY_JAPANESE = 'NATIONALITY_JAPANESE';
export const NATIONALITY_JORDANIAN = 'NATIONALITY_JORDANIAN';
export const NATIONALITY_KAZAKH = 'NATIONALITY_KAZAKH';
export const NATIONALITY_KENYAN = 'NATIONALITY_KENYAN';
export const NATIONALITY_KITTITIAN = 'NATIONALITY_KITTITIAN';
export const NATIONALITY_CITIZEN_OF_KIRIBATI = 'NATIONALITY_CITIZEN_OF_KIRIBATI';
export const NATIONALITY_KOSOVAN = 'NATIONALITY_KOSOVAN';
export const NATIONALITY_KUWAITI = 'NATIONALITY_KUWAITI';
export const NATIONALITY_KYRGYZ = 'NATIONALITY_KYRGYZ';
export const NATIONALITY_LAO = 'NATIONALITY_LAO';
export const NATIONALITY_LATVIAN = 'NATIONALITY_LATVIAN';
export const NATIONALITY_LEBANESE = 'NATIONALITY_LEBANESE';
export const NATIONALITY_LIBERIAN = 'NATIONALITY_LIBERIAN';
export const NATIONALITY_LIBYAN = 'NATIONALITY_LIBYAN';
export const NATIONALITY_LIECHTENSTEIN_CITIZEN = 'NATIONALITY_LIECHTENSTEIN_CITIZEN';
export const NATIONALITY_LITHUANIAN = 'NATIONALITY_LITHUANIAN';
export const NATIONALITY_LUXEMBOURGER = 'NATIONALITY_LUXEMBOURGER';
export const NATIONALITY_MACANESE = 'NATIONALITY_MACANESE';
export const NATIONALITY_MACEDONIAN = 'NATIONALITY_MACEDONIAN';
export const NATIONALITY_MALAGASY = 'NATIONALITY_MALAGASY';
export const NATIONALITY_MALAWIAN = 'NATIONALITY_MALAWIAN';
export const NATIONALITY_MALAYSIAN = 'NATIONALITY_MALAYSIAN';
export const NATIONALITY_MALDIVIAN = 'NATIONALITY_MALDIVIAN';
export const NATIONALITY_MALIAN = 'NATIONALITY_MALIAN';
export const NATIONALITY_MALTESE = 'NATIONALITY_MALTESE';
export const NATIONALITY_MARSHALLESE = 'NATIONALITY_MARSHALLESE';
export const NATIONALITY_MARTINIQUAIS = 'NATIONALITY_MARTINIQUAIS';
export const NATIONALITY_MAURITANIAN = 'NATIONALITY_MAURITANIAN';
export const NATIONALITY_MAURITIAN = 'NATIONALITY_MAURITIAN';
export const NATIONALITY_MEXICAN = 'NATIONALITY_MEXICAN';
export const NATIONALITY_MICRONESIAN = 'NATIONALITY_MICRONESIAN';
export const NATIONALITY_MOLDOVAN = 'NATIONALITY_MOLDOVAN';
export const NATIONALITY_MONEGASQUE = 'NATIONALITY_MONEGASQUE';
export const NATIONALITY_MONGOLIAN = 'NATIONALITY_MONGOLIAN';
export const NATIONALITY_MONTENEGRIN = 'NATIONALITY_MONTENEGRIN';
export const NATIONALITY_MONTSERRATIAN = 'NATIONALITY_MONTSERRATIAN';
export const NATIONALITY_MOROCCAN = 'NATIONALITY_MOROCCAN';
export const NATIONALITY_MOSOTHO = 'NATIONALITY_MOSOTHO';
export const NATIONALITY_MOZAMBICAN = 'NATIONALITY_MOZAMBICAN';
export const NATIONALITY_NAMIBIAN = 'NATIONALITY_NAMIBIAN';
export const NATIONALITY_NAURUAN = 'NATIONALITY_NAURUAN';
export const NATIONALITY_NEPALESE = 'NATIONALITY_NEPALESE';
export const NATIONALITY_NEW_ZEALANDER = 'NATIONALITY_NEW_ZEALANDER';
export const NATIONALITY_NICARAGUAN = 'NATIONALITY_NICARAGUAN';
export const NATIONALITY_NIGERIAN = 'NATIONALITY_NIGERIAN';
export const NATIONALITY_NIGERIEN = 'NATIONALITY_NIGERIEN';
export const NATIONALITY_NIUEAN = 'NATIONALITY_NIUEAN';
export const NATIONALITY_NORTH_KOREAN = 'NATIONALITY_NORTH_KOREAN';
export const NATIONALITY_NORTHERN_IRISH = 'NATIONALITY_NORTHERN_IRISH';
export const NATIONALITY_NORWEGIAN = 'NATIONALITY_NORWEGIAN';
export const NATIONALITY_OMANI = 'NATIONALITY_OMANI';
export const NATIONALITY_PAKISTANI = 'NATIONALITY_PAKISTANI';
export const NATIONALITY_PALAUAN = 'NATIONALITY_PALAUAN';
export const NATIONALITY_PALESTINIAN = 'NATIONALITY_PALESTINIAN';
export const NATIONALITY_PANAMANIAN = 'NATIONALITY_PANAMANIAN';
export const NATIONALITY_PAPUA_NEW_GUINEAN = 'NATIONALITY_PAPUA_NEW_GUINEAN';
export const NATIONALITY_PARAGUAYAN = 'NATIONALITY_PARAGUAYAN';
export const NATIONALITY_PERUVIAN = 'NATIONALITY_PERUVIAN';
export const NATIONALITY_PITCAIRN_ISLANDER = 'NATIONALITY_PITCAIRN_ISLANDER';
export const NATIONALITY_POLISH = 'NATIONALITY_POLISH';
export const NATIONALITY_PORTUGUESE = 'NATIONALITY_PORTUGUESE';
export const NATIONALITY_PRYDEINIG = 'NATIONALITY_PRYDEINIG';
export const NATIONALITY_PUERTO_RICAN = 'NATIONALITY_PUERTO_RICAN';
export const NATIONALITY_QATARI = 'NATIONALITY_QATARI';
export const NATIONALITY_SALVADOREAN = 'NATIONALITY_SALVADOREAN';
export const NATIONALITY_SAMMARINESE = 'NATIONALITY_SAMMARINESE';
export const NATIONALITY_SAMOAN = 'NATIONALITY_SAMOAN';
export const NATIONALITY_SAO_TOMEAN = 'NATIONALITY_SAO_TOMEAN';
export const NATIONALITY_SAUDI_ARABIAN = 'NATIONALITY_SAUDI_ARABIAN';
export const NATIONALITY_SCOTTISH = 'NATIONALITY_SCOTTISH';
export const NATIONALITY_SENEGALESE = 'NATIONALITY_SENEGALESE';
export const NATIONALITY_SERBIAN = 'NATIONALITY_SERBIAN';
export const NATIONALITY_CITIZEN_OF_SEYCHELLES = 'NATIONALITY_CITIZEN_OF_SEYCHELLES';
export const NATIONALITY_SIERRA_LEONEAN = 'NATIONALITY_SIERRA_LEONEAN';
export const NATIONALITY_SINGAPOREAN = 'NATIONALITY_SINGAPOREAN';
export const NATIONALITY_SLOVAK = 'NATIONALITY_SLOVAK';
export const NATIONALITY_SLOVENIAN = 'NATIONALITY_SLOVENIAN';
export const NATIONALITY_SOLOMON_ISLANDER = 'NATIONALITY_SOLOMON_ISLANDER';
export const NATIONALITY_SOMALI = 'NATIONALITY_SOMALI';
export const NATIONALITY_SOUTH_AFRICAN = 'NATIONALITY_SOUTH_AFRICAN';
export const NATIONALITY_SOUTH_KOREAN = 'NATIONALITY_SOUTH_KOREAN';
export const NATIONALITY_SOUTH_SUDANESE = 'NATIONALITY_SOUTH_SUDANESE';
export const NATIONALITY_SPANISH = 'NATIONALITY_SPANISH';
export const NATIONALITY_SRI_LANKAN = 'NATIONALITY_SRI_LANKAN';
export const NATIONALITY_ST_HELENIAN = 'NATIONALITY_ST_HELENIAN';
export const NATIONALITY_ST_LUCIAN = 'NATIONALITY_ST_LUCIAN';
export const NATIONALITY_STATELESS = 'NATIONALITY_STATELESS';
export const NATIONALITY_SUDANESE = 'NATIONALITY_SUDANESE';
export const NATIONALITY_SURINAMESE = 'NATIONALITY_SURINAMESE';
export const NATIONALITY_SWAZI = 'NATIONALITY_SWAZI';
export const NATIONALITY_SWEDISH = 'NATIONALITY_SWEDISH';
export const NATIONALITY_SWISS = 'NATIONALITY_SWISS';
export const NATIONALITY_SYRIAN = 'NATIONALITY_SYRIAN';
export const NATIONALITY_TAIWANESE = 'NATIONALITY_TAIWANESE';
export const NATIONALITY_TAJIK = 'NATIONALITY_TAJIK';
export const NATIONALITY_TANZANIAN = 'NATIONALITY_TANZANIAN';
export const NATIONALITY_THAI = 'NATIONALITY_THAI';
export const NATIONALITY_TOGOLESE = 'NATIONALITY_TOGOLESE';
export const NATIONALITY_TONGAN = 'NATIONALITY_TONGAN';
export const NATIONALITY_TRINIDADIAN = 'NATIONALITY_TRINIDADIAN';
export const NATIONALITY_TRISTANIAN = 'NATIONALITY_TRISTANIAN';
export const NATIONALITY_TUNISIAN = 'NATIONALITY_TUNISIAN';
export const NATIONALITY_TURKISH = 'NATIONALITY_TURKISH';
export const NATIONALITY_TURKMEN = 'NATIONALITY_TURKMEN';
export const NATIONALITY_TURKS_AND_CAICOS_ISLANDER = 'NATIONALITY_TURKS_AND_CAICOS_ISLANDER';
export const NATIONALITY_TUVALUAN = 'NATIONALITY_TUVALUAN';
export const NATIONALITY_UGANDAN = 'NATIONALITY_UGANDAN';
export const NATIONALITY_UKRAINIAN = 'NATIONALITY_UKRAINIAN';
export const NATIONALITY_URUGUAYAN = 'NATIONALITY_URUGUAYAN';
export const NATIONALITY_UZBEK = 'NATIONALITY_UZBEK';
export const NATIONALITY_VATICAN_CITIZEN = 'NATIONALITY_VATICAN_CITIZEN';
export const NATIONALITY_CITIZEN_OF_VANUATU = 'NATIONALITY_CITIZEN_OF_VANUATU';
export const NATIONALITY_VENEZUELAN = 'NATIONALITY_VENEZUELAN';
export const NATIONALITY_VIETNAMESE = 'NATIONALITY_VIETNAMESE';
export const NATIONALITY_VINCENTIAN = 'NATIONALITY_VINCENTIAN';
export const NATIONALITY_WALLISIAN = 'NATIONALITY_WALLISIAN';
export const NATIONALITY_WELSH = 'NATIONALITY_WELSH';
export const NATIONALITY_YEMENI = 'NATIONALITY_YEMENI';
export const NATIONALITY_ZAMBIAN = 'NATIONALITY_ZAMBIAN';
export const NATIONALITY_ZIMBABWEAN = 'NATIONALITY_ZIMBABWEAN';
export const NATIONALITY_OTHER = 'NATIONALITY_OTHER';

class NationalityEnum extends BaseEnum {
    getTypes = () => {
        return {
            [NATIONALITY_AFGHAN]: 'Afghan',
            [NATIONALITY_ALBANIAN]: 'Albanian',
            [NATIONALITY_ALGERIAN]: 'Algerian',
            [NATIONALITY_AMERICAN]: 'American',
            [NATIONALITY_ANDORRAN]: 'Andorran',
            [NATIONALITY_ANGOLAN]: 'Angolan',
            [NATIONALITY_ANGUILLAN]: 'Anguillan',
            [NATIONALITY_CITIZEN_OF_ANTIGUA_AND_BARBUDA]: 'Citizen of Antigua and Barbuda',
            [NATIONALITY_ARGENTINE]: 'Argentine',
            [NATIONALITY_ARMENIAN]: 'Armenian',
            [NATIONALITY_AUSTRALIAN]: 'Australian',
            [NATIONALITY_AUSTRIAN]: 'Austrian',
            [NATIONALITY_AZERBAIJANI]: 'Azerbaijani',
            [NATIONALITY_BAHAMIAN]: 'Bahamian',
            [NATIONALITY_BAHRAINI]: 'Bahraini',
            [NATIONALITY_BANGLADESHI]: 'Bangladeshi',
            [NATIONALITY_BARBADIAN]: 'Barbadian',
            [NATIONALITY_BELARUSIAN]: 'Belarusian',
            [NATIONALITY_BELGIAN]: 'Belgian',
            [NATIONALITY_BELIZEAN]: 'Belizean',
            [NATIONALITY_BENINESE]: 'Beninese',
            [NATIONALITY_BERMUDIAN]: 'Bermudian',
            [NATIONALITY_BHUTANESE]: 'Bhutanese',
            [NATIONALITY_BOLIVIAN]: 'Bolivian',
            [NATIONALITY_CITIZEN_OF_BOSNIA_AND_HERZEGOVINA]: 'Citizen of Bosnia and Herzegovina',
            [NATIONALITY_BOTSWANAN]: 'Botswanan',
            [NATIONALITY_BRAZILIAN]: 'Brazilian',
            [NATIONALITY_BRITISH]: 'British',
            [NATIONALITY_BRITISH_VIRGIN_ISLANDER]: 'British Virgin Islander',
            [NATIONALITY_BRUNEIAN]: 'Bruneian',
            [NATIONALITY_BULGARIAN]: 'Bulgarian',
            [NATIONALITY_BURKINAN]: 'Burkinan',
            [NATIONALITY_BURMESE]: 'Burmese',
            [NATIONALITY_BURUNDIAN]: 'Burundian',
            [NATIONALITY_CAMBODIAN]: 'Cambodian',
            [NATIONALITY_CAMEROONIAN]: 'Cameroonian',
            [NATIONALITY_CANADIAN]: 'Canadian',
            [NATIONALITY_CAPE_VERDEAN]: 'Cape verdean',
            [NATIONALITY_CAYMAN_ISLANDER]: 'Cayman Islander',
            [NATIONALITY_CENTRAL_AFRICAN]: 'Central African',
            [NATIONALITY_CHADIAN]: 'Chadian',
            [NATIONALITY_CHILEAN]: 'Chilean',
            [NATIONALITY_CHINESE]: 'Chinese',
            [NATIONALITY_COLOMBIAN]: 'Colombian',
            [NATIONALITY_COMORAN]: 'Comoran',
            [NATIONALITY_CONGOLESE_CONGO]: 'Congolese (Congo)',
            [NATIONALITY_CONGOLESE_DRC]: 'Congolese (DRC)',
            [NATIONALITY_COOK_ISLANDER]: 'Cook Islander',
            [NATIONALITY_COSTA_RICAN]: 'Costa Rican',
            [NATIONALITY_CROATIAN]: 'Croatian',
            [NATIONALITY_CUBAN]: 'Cuban',
            [NATIONALITY_CYMRAES]: 'Cymraes',
            [NATIONALITY_CYMRO]: 'Cymro',
            [NATIONALITY_CYPRIOT]: 'Cypriot',
            [NATIONALITY_CZECH]: 'Czech',
            [NATIONALITY_DANISH]: 'Danish',
            [NATIONALITY_DJIBOUTIAN]: 'Djiboutian',
            [NATIONALITY_DOMINICAN]: 'Dominican',
            [NATIONALITY_CITIZEN_OF_THE_DOMINICAN_REPUBLIC]: 'Citizen of the Dominican Republic',
            [NATIONALITY_DUTCH]: 'Dutch',
            [NATIONALITY_EAST_TIMORESE]: 'East Timorese',
            [NATIONALITY_ECUADOREAN]: 'Ecuadorean',
            [NATIONALITY_EGYPTIAN]: 'Egyptian',
            [NATIONALITY_EMIRATI]: 'Emirati',
            [NATIONALITY_ENGLISH]: 'English',
            [NATIONALITY_EQUATORIAL_GUINEAN]: 'Equatorial Guinean',
            [NATIONALITY_ERITREAN]: 'Eritrean',
            [NATIONALITY_ESTONIAN]: 'Estonian',
            [NATIONALITY_ETHIOPIAN]: 'Ethiopian',
            [NATIONALITY_FAROESE]: 'Faroese',
            [NATIONALITY_FIJIAN]: 'Fijian',
            [NATIONALITY_FILIPINO]: 'Filipino',
            [NATIONALITY_FINNISH]: 'Finnish',
            [NATIONALITY_FRENCH]: 'French',
            [NATIONALITY_GABONESE]: 'Gabonese',
            [NATIONALITY_GAMBIAN]: 'Gambian',
            [NATIONALITY_GEORGIAN]: 'Georgian',
            [NATIONALITY_GERMAN]: 'German',
            [NATIONALITY_GHANAIAN]: 'Ghanaian',
            [NATIONALITY_GIBRALTARIAN]: 'Gibraltarian',
            [NATIONALITY_GREEK]: 'Greek',
            [NATIONALITY_GREENLANDIC]: 'Greenlandic',
            [NATIONALITY_GRENADIAN]: 'Grenadian',
            [NATIONALITY_GUAMANIAN]: 'Guamanian',
            [NATIONALITY_GUATEMALAN]: 'Guatemalan',
            [NATIONALITY_CITIZEN_OF_GUINEA_BISSAU]: 'Citizen of Guinea-Bissau',
            [NATIONALITY_GUINEAN]: 'Guinean',
            [NATIONALITY_GUYANESE]: 'Guyanese',
            [NATIONALITY_HAITIAN]: 'Haitian',
            [NATIONALITY_HONDURAN]: 'Honduran',
            [NATIONALITY_HONG_KONGER]: 'Hong Konger',
            [NATIONALITY_HUNGARIAN]: 'Hungarian',
            [NATIONALITY_ICELANDIC]: 'Icelandic',
            [NATIONALITY_INDIAN]: 'Indian',
            [NATIONALITY_INDONESIAN]: 'Indonesian',
            [NATIONALITY_IRANIAN]: 'Iranian',
            [NATIONALITY_IRAQI]: 'Iraqi',
            [NATIONALITY_IRISH]: 'Irish',
            [NATIONALITY_ISRAELI]: 'Israeli',
            [NATIONALITY_ITALIAN]: 'Italian',
            [NATIONALITY_IVORIAN]: 'Ivorian',
            [NATIONALITY_JAMAICAN]: 'Jamaican',
            [NATIONALITY_JAPANESE]: 'Japanese',
            [NATIONALITY_JORDANIAN]: 'Jordanian',
            [NATIONALITY_KAZAKH]: 'Kazakh',
            [NATIONALITY_KENYAN]: 'Kenyan',
            [NATIONALITY_KITTITIAN]: 'Kittitian',
            [NATIONALITY_CITIZEN_OF_KIRIBATI]: 'Citizen of Kiribati',
            [NATIONALITY_KOSOVAN]: 'Kosovan',
            [NATIONALITY_KUWAITI]: 'Kuwaiti',
            [NATIONALITY_KYRGYZ]: 'Kyrgyz',
            [NATIONALITY_LAO]: 'Lao',
            [NATIONALITY_LATVIAN]: 'Latvian',
            [NATIONALITY_LEBANESE]: 'Lebanese',
            [NATIONALITY_LIBERIAN]: 'Liberian',
            [NATIONALITY_LIBYAN]: 'Libyan',
            [NATIONALITY_LIECHTENSTEIN_CITIZEN]: 'Liechtenstein citizen',
            [NATIONALITY_LITHUANIAN]: 'Lithuanian',
            [NATIONALITY_LUXEMBOURGER]: 'Luxembourger',
            [NATIONALITY_MACANESE]: 'Macanese',
            [NATIONALITY_MACEDONIAN]: 'Macedonian',
            [NATIONALITY_MALAGASY]: 'Malagasy',
            [NATIONALITY_MALAWIAN]: 'Malawian',
            [NATIONALITY_MALAYSIAN]: 'Malaysian',
            [NATIONALITY_MALDIVIAN]: 'Maldivian',
            [NATIONALITY_MALIAN]: 'Malian',
            [NATIONALITY_MALTESE]: 'Maltese',
            [NATIONALITY_MARSHALLESE]: 'Marshallese',
            [NATIONALITY_MARTINIQUAIS]: 'Martiniquais',
            [NATIONALITY_MAURITANIAN]: 'Mauritanian',
            [NATIONALITY_MAURITIAN]: 'Mauritian',
            [NATIONALITY_MEXICAN]: 'Mexican',
            [NATIONALITY_MICRONESIAN]: 'Micronesian',
            [NATIONALITY_MOLDOVAN]: 'Moldovan',
            [NATIONALITY_MONEGASQUE]: 'Monegasque',
            [NATIONALITY_MONGOLIAN]: 'Mongolian',
            [NATIONALITY_MONTENEGRIN]: 'Montenegrin',
            [NATIONALITY_MONTSERRATIAN]: 'Montserratian',
            [NATIONALITY_MOROCCAN]: 'Moroccan',
            [NATIONALITY_MOSOTHO]: 'Mosotho',
            [NATIONALITY_MOZAMBICAN]: 'Mozambican',
            [NATIONALITY_NAMIBIAN]: 'Namibian',
            [NATIONALITY_NAURUAN]: 'Nauruan',
            [NATIONALITY_NEPALESE]: 'Nepalese',
            [NATIONALITY_NEW_ZEALANDER]: 'New Zealander',
            [NATIONALITY_NICARAGUAN]: 'Nicaraguan',
            [NATIONALITY_NIGERIAN]: 'Nigerian',
            [NATIONALITY_NIGERIEN]: 'Nigerien',
            [NATIONALITY_NIUEAN]: 'Niuean',
            [NATIONALITY_NORTH_KOREAN]: 'North Korean',
            [NATIONALITY_NORTHERN_IRISH]: 'Northern Irish',
            [NATIONALITY_NORWEGIAN]: 'Norwegian',
            [NATIONALITY_OMANI]: 'Omani',
            [NATIONALITY_PAKISTANI]: 'Pakistani',
            [NATIONALITY_PALAUAN]: 'Palauan',
            [NATIONALITY_PALESTINIAN]: 'Palestinian',
            [NATIONALITY_PANAMANIAN]: 'Panamanian',
            [NATIONALITY_PAPUA_NEW_GUINEAN]: 'Papua New Guinean',
            [NATIONALITY_PARAGUAYAN]: 'Paraguayan',
            [NATIONALITY_PERUVIAN]: 'Peruvian',
            [NATIONALITY_PITCAIRN_ISLANDER]: 'Pitcairn Islander',
            [NATIONALITY_POLISH]: 'Polish',
            [NATIONALITY_PORTUGUESE]: 'Portuguese',
            [NATIONALITY_PRYDEINIG]: 'Prydeinig',
            [NATIONALITY_PUERTO_RICAN]: 'Puerto Rican',
            [NATIONALITY_QATARI]: 'Qatari',
            [NATIONALITY_SALVADOREAN]: 'Salvadorean',
            [NATIONALITY_SAMMARINESE]: 'Sammarinese',
            [NATIONALITY_SAMOAN]: 'Samoan',
            [NATIONALITY_SAO_TOMEAN]: 'Sao Tomean',
            [NATIONALITY_SAUDI_ARABIAN]: 'Saudi Arabian',
            [NATIONALITY_SCOTTISH]: 'Scottish',
            [NATIONALITY_SENEGALESE]: 'Senegalese',
            [NATIONALITY_SERBIAN]: 'Serbian',
            [NATIONALITY_CITIZEN_OF_SEYCHELLES]: 'Citizen of Seychelles',
            [NATIONALITY_SIERRA_LEONEAN]: 'Sierra Leonean',
            [NATIONALITY_SINGAPOREAN]: 'Singaporean',
            [NATIONALITY_SLOVAK]: 'Slovak',
            [NATIONALITY_SLOVENIAN]: 'Slovenian',
            [NATIONALITY_SOLOMON_ISLANDER]: 'Solomon Islander',
            [NATIONALITY_SOMALI]: 'Somali',
            [NATIONALITY_SOUTH_AFRICAN]: 'South African',
            [NATIONALITY_SOUTH_KOREAN]: 'South Korean',
            [NATIONALITY_SOUTH_SUDANESE]: 'South Sudanese',
            [NATIONALITY_SPANISH]: 'Spanish',
            [NATIONALITY_SRI_LANKAN]: 'Sri Lankan',
            [NATIONALITY_ST_HELENIAN]: 'St Helenian',
            [NATIONALITY_ST_LUCIAN]: 'St Lucian',
            [NATIONALITY_STATELESS]: 'Stateless',
            [NATIONALITY_SUDANESE]: 'Sudanese',
            [NATIONALITY_SURINAMESE]: 'Surinamese',
            [NATIONALITY_SWAZI]: 'Swazi',
            [NATIONALITY_SWEDISH]: 'Swedish',
            [NATIONALITY_SWISS]: 'Swiss',
            [NATIONALITY_SYRIAN]: 'Syrian',
            [NATIONALITY_TAIWANESE]: 'Taiwanese',
            [NATIONALITY_TAJIK]: 'Tajik',
            [NATIONALITY_TANZANIAN]: 'Tanzanian',
            [NATIONALITY_THAI]: 'Thai',
            [NATIONALITY_TOGOLESE]: 'Togolese',
            [NATIONALITY_TONGAN]: 'Tongan',
            [NATIONALITY_TRINIDADIAN]: 'Trinidadian',
            [NATIONALITY_TRISTANIAN]: 'Tristanian',
            [NATIONALITY_TUNISIAN]: 'Tunisian',
            [NATIONALITY_TURKISH]: 'Turkish',
            [NATIONALITY_TURKMEN]: 'Turkmen',
            [NATIONALITY_TURKS_AND_CAICOS_ISLANDER]: 'Turks and Caicos Islander',
            [NATIONALITY_TUVALUAN]: 'Tuvaluan',
            [NATIONALITY_UGANDAN]: 'Ugandan',
            [NATIONALITY_UKRAINIAN]: 'Ukrainian',
            [NATIONALITY_URUGUAYAN]: 'Uruguayan',
            [NATIONALITY_UZBEK]: 'Uzbek',
            [NATIONALITY_VATICAN_CITIZEN]: 'Vatican citizen',
            [NATIONALITY_CITIZEN_OF_VANUATU]: 'Citizen of Vanuatu',
            [NATIONALITY_VENEZUELAN]: 'Venezuelan',
            [NATIONALITY_VIETNAMESE]: 'Vietnamese',
            [NATIONALITY_VINCENTIAN]: 'Vincentian',
            [NATIONALITY_WALLISIAN]: 'Wallisian',
            [NATIONALITY_WELSH]: 'Welsh',
            [NATIONALITY_YEMENI]: 'Yemeni',
            [NATIONALITY_ZAMBIAN]: 'Zambian',
            [NATIONALITY_ZIMBABWEAN]: 'Zimbabwean',
            [NATIONALITY_OTHER]: 'Other',
        };
    };

    getOptions = () => {
        return [
            { value: NATIONALITY_AFGHAN, label: 'Afghan' },
            { value: NATIONALITY_ALBANIAN, label: 'Albanian' },
            { value: NATIONALITY_ALGERIAN, label: 'Algerian' },
            { value: NATIONALITY_AMERICAN, label: 'American' },
            { value: NATIONALITY_ANDORRAN, label: 'Andorran' },
            { value: NATIONALITY_ANGOLAN, label: 'Angolan' },
            { value: NATIONALITY_ANGUILLAN, label: 'Anguillan' },
            { value: NATIONALITY_CITIZEN_OF_ANTIGUA_AND_BARBUDA, label: 'Citizen of Antigua and Barbuda' },
            { value: NATIONALITY_ARGENTINE, label: 'Argentine' },
            { value: NATIONALITY_ARMENIAN, label: 'Armenian' },
            { value: NATIONALITY_AUSTRALIAN, label: 'Australian' },
            { value: NATIONALITY_AUSTRIAN, label: 'Austrian' },
            { value: NATIONALITY_AZERBAIJANI, label: 'Azerbaijani' },
            { value: NATIONALITY_BAHAMIAN, label: 'Bahamian' },
            { value: NATIONALITY_BAHRAINI, label: 'Bahraini' },
            { value: NATIONALITY_BANGLADESHI, label: 'Bangladeshi' },
            { value: NATIONALITY_BARBADIAN, label: 'Barbadian' },
            { value: NATIONALITY_BELARUSIAN, label: 'Belarusian' },
            { value: NATIONALITY_BELGIAN, label: 'Belgian' },
            { value: NATIONALITY_BELIZEAN, label: 'Belizean' },
            { value: NATIONALITY_BENINESE, label: 'Beninese' },
            { value: NATIONALITY_BERMUDIAN, label: 'Bermudian' },
            { value: NATIONALITY_BHUTANESE, label: 'Bhutanese' },
            { value: NATIONALITY_BOLIVIAN, label: 'Bolivian' },
            { value: NATIONALITY_CITIZEN_OF_BOSNIA_AND_HERZEGOVINA, label: 'Citizen of Bosnia and Herzegovina' },
            { value: NATIONALITY_BOTSWANAN, label: 'Botswanan' },
            { value: NATIONALITY_BRAZILIAN, label: 'Brazilian' },
            { value: NATIONALITY_BRITISH, label: 'British' },
            { value: NATIONALITY_BRITISH_VIRGIN_ISLANDER, label: 'British Virgin Islander' },
            { value: NATIONALITY_BRUNEIAN, label: 'Bruneian' },
            { value: NATIONALITY_BULGARIAN, label: 'Bulgarian' },
            { value: NATIONALITY_BURKINAN, label: 'Burkinan' },
            { value: NATIONALITY_BURMESE, label: 'Burmese' },
            { value: NATIONALITY_BURUNDIAN, label: 'Burundian' },
            { value: NATIONALITY_CAMBODIAN, label: 'Cambodian' },
            { value: NATIONALITY_CAMEROONIAN, label: 'Cameroonian' },
            { value: NATIONALITY_CANADIAN, label: 'Canadian' },
            { value: NATIONALITY_CAPE_VERDEAN, label: 'Cape verdean' },
            { value: NATIONALITY_CAYMAN_ISLANDER, label: 'Cayman Islander' },
            { value: NATIONALITY_CENTRAL_AFRICAN, label: 'Central African' },
            { value: NATIONALITY_CHADIAN, label: 'Chadian' },
            { value: NATIONALITY_CHILEAN, label: 'Chilean' },
            { value: NATIONALITY_CHINESE, label: 'Chinese' },
            { value: NATIONALITY_COLOMBIAN, label: 'Colombian' },
            { value: NATIONALITY_COMORAN, label: 'Comoran' },
            { value: NATIONALITY_CONGOLESE_CONGO, label: 'Congolese (Congo)' },
            { value: NATIONALITY_CONGOLESE_DRC, label: 'Congolese (DRC)' },
            { value: NATIONALITY_COOK_ISLANDER, label: 'Cook_Islander' },
            { value: NATIONALITY_COSTA_RICAN, label: 'Costa_Rican' },
            { value: NATIONALITY_CROATIAN, label: 'Croatian' },
            { value: NATIONALITY_CUBAN, label: 'Cuban' },
            { value: NATIONALITY_CYMRAES, label: 'Cymraes' },
            { value: NATIONALITY_CYMRO, label: 'Cymro' },
            { value: NATIONALITY_CYPRIOT, label: 'Cypriot' },
            { value: NATIONALITY_CZECH, label: 'Czech' },
            { value: NATIONALITY_DANISH, label: 'Danish' },
            { value: NATIONALITY_DJIBOUTIAN, label: 'Djiboutian' },
            { value: NATIONALITY_DOMINICAN, label: 'Dominican' },
            { value: NATIONALITY_CITIZEN_OF_THE_DOMINICAN_REPUBLIC, label: 'Citizen of the Dominican Republic' },
            { value: NATIONALITY_DUTCH, label: 'Dutch' },
            { value: NATIONALITY_EAST_TIMORESE, label: 'East_Timorese' },
            { value: NATIONALITY_ECUADOREAN, label: 'Ecuadorean' },
            { value: NATIONALITY_EGYPTIAN, label: 'Egyptian' },
            { value: NATIONALITY_EMIRATI, label: 'Emirati' },
            { value: NATIONALITY_ENGLISH, label: 'English' },
            { value: NATIONALITY_EQUATORIAL_GUINEAN, label: 'Equatorial Guinean' },
            { value: NATIONALITY_ERITREAN, label: 'Eritrean' },
            { value: NATIONALITY_ESTONIAN, label: 'Estonian' },
            { value: NATIONALITY_ETHIOPIAN, label: 'Ethiopian' },
            { value: NATIONALITY_FAROESE, label: 'Faroese' },
            { value: NATIONALITY_FIJIAN, label: 'Fijian' },
            { value: NATIONALITY_FILIPINO, label: 'Filipino' },
            { value: NATIONALITY_FINNISH, label: 'Finnish' },
            { value: NATIONALITY_FRENCH, label: 'French' },
            { value: NATIONALITY_GABONESE, label: 'Gabonese' },
            { value: NATIONALITY_GAMBIAN, label: 'Gambian' },
            { value: NATIONALITY_GEORGIAN, label: 'Georgian' },
            { value: NATIONALITY_GERMAN, label: 'German' },
            { value: NATIONALITY_GHANAIAN, label: 'Ghanaian' },
            { value: NATIONALITY_GIBRALTARIAN, label: 'Gibraltarian' },
            { value: NATIONALITY_GREEK, label: 'Greek' },
            { value: NATIONALITY_GREENLANDIC, label: 'Greenlandic' },
            { value: NATIONALITY_GRENADIAN, label: 'Grenadian' },
            { value: NATIONALITY_GUAMANIAN, label: 'Guamanian' },
            { value: NATIONALITY_GUATEMALAN, label: 'Guatemalan' },
            { value: NATIONALITY_CITIZEN_OF_GUINEA_BISSAU, label: 'Citizen of Guinea-Bissau' },
            { value: NATIONALITY_GUINEAN, label: 'Guinean' },
            { value: NATIONALITY_GUYANESE, label: 'Guyanese' },
            { value: NATIONALITY_HAITIAN, label: 'Haitian' },
            { value: NATIONALITY_HONDURAN, label: 'Honduran' },
            { value: NATIONALITY_HONG_KONGER, label: 'Hong_Konger' },
            { value: NATIONALITY_HUNGARIAN, label: 'Hungarian' },
            { value: NATIONALITY_ICELANDIC, label: 'Icelandic' },
            { value: NATIONALITY_INDIAN, label: 'Indian' },
            { value: NATIONALITY_INDONESIAN, label: 'Indonesian' },
            { value: NATIONALITY_IRANIAN, label: 'Iranian' },
            { value: NATIONALITY_IRAQI, label: 'Iraqi' },
            { value: NATIONALITY_IRISH, label: 'Irish' },
            { value: NATIONALITY_ISRAELI, label: 'Israeli' },
            { value: NATIONALITY_ITALIAN, label: 'Italian' },
            { value: NATIONALITY_IVORIAN, label: 'Ivorian' },
            { value: NATIONALITY_JAMAICAN, label: 'Jamaican' },
            { value: NATIONALITY_JAPANESE, label: 'Japanese' },
            { value: NATIONALITY_JORDANIAN, label: 'Jordanian' },
            { value: NATIONALITY_KAZAKH, label: 'Kazakh' },
            { value: NATIONALITY_KENYAN, label: 'Kenyan' },
            { value: NATIONALITY_KITTITIAN, label: 'Kittitian' },
            { value: NATIONALITY_CITIZEN_OF_KIRIBATI, label: 'Citizen of Kiribati' },
            { value: NATIONALITY_KOSOVAN, label: 'Kosovan' },
            { value: NATIONALITY_KUWAITI, label: 'Kuwaiti' },
            { value: NATIONALITY_KYRGYZ, label: 'Kyrgyz' },
            { value: NATIONALITY_LAO, label: 'Lao' },
            { value: NATIONALITY_LATVIAN, label: 'Latvian' },
            { value: NATIONALITY_LEBANESE, label: 'Lebanese' },
            { value: NATIONALITY_LIBERIAN, label: 'Liberian' },
            { value: NATIONALITY_LIBYAN, label: 'Libyan' },
            { value: NATIONALITY_LIECHTENSTEIN_CITIZEN, label: 'Liechtenstein citizen' },
            { value: NATIONALITY_LITHUANIAN, label: 'Lithuanian' },
            { value: NATIONALITY_LUXEMBOURGER, label: 'Luxembourger' },
            { value: NATIONALITY_MACANESE, label: 'Macanese' },
            { value: NATIONALITY_MACEDONIAN, label: 'Macedonian' },
            { value: NATIONALITY_MALAGASY, label: 'Malagasy' },
            { value: NATIONALITY_MALAWIAN, label: 'Malawian' },
            { value: NATIONALITY_MALAYSIAN, label: 'Malaysian' },
            { value: NATIONALITY_MALDIVIAN, label: 'Maldivian' },
            { value: NATIONALITY_MALIAN, label: 'Malian' },
            { value: NATIONALITY_MALTESE, label: 'Maltese' },
            { value: NATIONALITY_MARSHALLESE, label: 'Marshallese' },
            { value: NATIONALITY_MARTINIQUAIS, label: 'Martiniquais' },
            { value: NATIONALITY_MAURITANIAN, label: 'Mauritanian' },
            { value: NATIONALITY_MAURITIAN, label: 'Mauritian' },
            { value: NATIONALITY_MEXICAN, label: 'Mexican' },
            { value: NATIONALITY_MICRONESIAN, label: 'Micronesian' },
            { value: NATIONALITY_MOLDOVAN, label: 'Moldovan' },
            { value: NATIONALITY_MONEGASQUE, label: 'Monegasque' },
            { value: NATIONALITY_MONGOLIAN, label: 'Mongolian' },
            { value: NATIONALITY_MONTENEGRIN, label: 'Montenegrin' },
            { value: NATIONALITY_MONTSERRATIAN, label: 'Montserratian' },
            { value: NATIONALITY_MOROCCAN, label: 'Moroccan' },
            { value: NATIONALITY_MOSOTHO, label: 'Mosotho' },
            { value: NATIONALITY_MOZAMBICAN, label: 'Mozambican' },
            { value: NATIONALITY_NAMIBIAN, label: 'Namibian' },
            { value: NATIONALITY_NAURUAN, label: 'Nauruan' },
            { value: NATIONALITY_NEPALESE, label: 'Nepalese' },
            { value: NATIONALITY_NEW_ZEALANDER, label: 'New Zealander' },
            { value: NATIONALITY_NICARAGUAN, label: 'Nicaraguan' },
            { value: NATIONALITY_NIGERIAN, label: 'Nigerian' },
            { value: NATIONALITY_NIGERIEN, label: 'Nigerien' },
            { value: NATIONALITY_NIUEAN, label: 'Niuean' },
            { value: NATIONALITY_NORTH_KOREAN, label: 'North Korean' },
            { value: NATIONALITY_NORTHERN_IRISH, label: 'Northern Irish' },
            { value: NATIONALITY_NORWEGIAN, label: 'Norwegian' },
            { value: NATIONALITY_OMANI, label: 'Omani' },
            { value: NATIONALITY_PAKISTANI, label: 'Pakistani' },
            { value: NATIONALITY_PALAUAN, label: 'Palauan' },
            { value: NATIONALITY_PALESTINIAN, label: 'Palestinian' },
            { value: NATIONALITY_PANAMANIAN, label: 'Panamanian' },
            { value: NATIONALITY_PAPUA_NEW_GUINEAN, label: 'Papua New Guinean' },
            { value: NATIONALITY_PARAGUAYAN, label: 'Paraguayan' },
            { value: NATIONALITY_PERUVIAN, label: 'Peruvian' },
            { value: NATIONALITY_PITCAIRN_ISLANDER, label: 'Pitcairn Islander' },
            { value: NATIONALITY_POLISH, label: 'Polish' },
            { value: NATIONALITY_PORTUGUESE, label: 'Portuguese' },
            { value: NATIONALITY_PRYDEINIG, label: 'Prydeinig' },
            { value: NATIONALITY_PUERTO_RICAN, label: 'Puerto Rican' },
            { value: NATIONALITY_QATARI, label: 'Qatari' },
            { value: NATIONALITY_SALVADOREAN, label: 'Salvadorean' },
            { value: NATIONALITY_SAMMARINESE, label: 'Sammarinese' },
            { value: NATIONALITY_SAMOAN, label: 'Samoan' },
            { value: NATIONALITY_SAO_TOMEAN, label: 'Sao Tomean' },
            { value: NATIONALITY_SAUDI_ARABIAN, label: 'Saudi Arabian' },
            { value: NATIONALITY_SCOTTISH, label: 'Scottish' },
            { value: NATIONALITY_SENEGALESE, label: 'Senegalese' },
            { value: NATIONALITY_SERBIAN, label: 'Serbian' },
            { value: NATIONALITY_CITIZEN_OF_SEYCHELLES, label: 'Citizen of Seychelles' },
            { value: NATIONALITY_SIERRA_LEONEAN, label: 'Sierra Leonean' },
            { value: NATIONALITY_SINGAPOREAN, label: 'Singaporean' },
            { value: NATIONALITY_SLOVAK, label: 'Slovak' },
            { value: NATIONALITY_SLOVENIAN, label: 'Slovenian' },
            { value: NATIONALITY_SOLOMON_ISLANDER, label: 'Solomon Islander' },
            { value: NATIONALITY_SOMALI, label: 'Somali' },
            { value: NATIONALITY_SOUTH_AFRICAN, label: 'South African' },
            { value: NATIONALITY_SOUTH_KOREAN, label: 'South Korean' },
            { value: NATIONALITY_SOUTH_SUDANESE, label: 'South Sudanese' },
            { value: NATIONALITY_SPANISH, label: 'Spanish' },
            { value: NATIONALITY_SRI_LANKAN, label: 'Sri_Lankan' },
            { value: NATIONALITY_ST_HELENIAN, label: 'St Helenian' },
            { value: NATIONALITY_ST_LUCIAN, label: 'St Lucian' },
            { value: NATIONALITY_STATELESS, label: 'Stateless' },
            { value: NATIONALITY_SUDANESE, label: 'Sudanese' },
            { value: NATIONALITY_SURINAMESE, label: 'Surinamese' },
            { value: NATIONALITY_SWAZI, label: 'Swazi' },
            { value: NATIONALITY_SWEDISH, label: 'Swedish' },
            { value: NATIONALITY_SWISS, label: 'Swiss' },
            { value: NATIONALITY_SYRIAN, label: 'Syrian' },
            { value: NATIONALITY_TAIWANESE, label: 'Taiwanese' },
            { value: NATIONALITY_TAJIK, label: 'Tajik' },
            { value: NATIONALITY_TANZANIAN, label: 'Tanzanian' },
            { value: NATIONALITY_THAI, label: 'Thai' },
            { value: NATIONALITY_TOGOLESE, label: 'Togolese' },
            { value: NATIONALITY_TONGAN, label: 'Tongan' },
            { value: NATIONALITY_TRINIDADIAN, label: 'Trinidadian' },
            { value: NATIONALITY_TRISTANIAN, label: 'Tristanian' },
            { value: NATIONALITY_TUNISIAN, label: 'Tunisian' },
            { value: NATIONALITY_TURKISH, label: 'Turkish' },
            { value: NATIONALITY_TURKMEN, label: 'Turkmen' },
            { value: NATIONALITY_TURKS_AND_CAICOS_ISLANDER, label: 'Turks and Caicos Islander' },
            { value: NATIONALITY_TUVALUAN, label: 'Tuvaluan' },
            { value: NATIONALITY_UGANDAN, label: 'Ugandan' },
            { value: NATIONALITY_UKRAINIAN, label: 'Ukrainian' },
            { value: NATIONALITY_URUGUAYAN, label: 'Uruguayan' },
            { value: NATIONALITY_UZBEK, label: 'Uzbek' },
            { value: NATIONALITY_VATICAN_CITIZEN, label: 'Vatican citizen' },
            { value: NATIONALITY_CITIZEN_OF_VANUATU, label: 'Citizen of Vanuatu' },
            { value: NATIONALITY_VENEZUELAN, label: 'Venezuelan' },
            { value: NATIONALITY_VIETNAMESE, label: 'Vietnamese' },
            { value: NATIONALITY_VINCENTIAN, label: 'Vincentian' },
            { value: NATIONALITY_WALLISIAN, label: 'Wallisian' },
            { value: NATIONALITY_WELSH, label: 'Welsh' },
            { value: NATIONALITY_YEMENI, label: 'Yemeni' },
            { value: NATIONALITY_ZAMBIAN, label: 'Zambian' },
            { value: NATIONALITY_ZIMBABWEAN, label: 'Zimbabwean' },
            { value: NATIONALITY_OTHER, label: 'Other' },
        ];
    };
}

export default NationalityEnum;
