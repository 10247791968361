import React from 'react';
import { Grid } from '@material-ui/core';

export default function AdminNotesErrorModal(props) {
    const { message } = props;
    return (
        <Grid container style={{}}>
            <Grid item>
                <div style={{ marginTop: 50, marginBottom: 20, textAlign: 'center' }}>
                    {message}
                    <br />
                    Please contact your beloved technical support.
                </div>
            </Grid>
        </Grid>
    );
}
