import { API_URL, INTERMEDIARY_URL, SLAM_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const removeLEAssignee = (uri, lenderLoanEnquiryId, loanEnquiryId, userIds) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    let query;

    switch (uri) {
        case API_URL:
            query = `
                mutation removeAdmin($loanEnquiryId: ID, $lenderLoanEnquiryId: ID,  $userIds: [ID]) {
                    removeAdmin(loanEnquiryId: $loanEnquiryId, lenderLoanEnquiryId: $lenderLoanEnquiryId, userIds: $userIds) {
                      success
                    }
                }
            `;
            break;
        case SLAM_URL:
            query = `
                mutation removeLender($lenderLoanEnquiryId: ID, $userIds: [ID]) {
                    removeLender(lenderLoanEnquiryId: $lenderLoanEnquiryId, userIds: $userIds) {
                      success
                    }
                }
            `;
            break;
        case INTERMEDIARY_URL:
            query = `
                mutation removeBroker($loanEnquiryId: ID, $lenderLoanEnquiryId: ID,  $userIds: [ID]) {
                    removeBroker(loanEnquiryId: $loanEnquiryId, lenderLoanEnquiryId: $lenderLoanEnquiryId, userIds: $userIds) {
                      success
                    }
                }
            `;
            break;
        default:
            query = '';
    }

    return apolloFetch({
        query: query,
        variables: {
            lenderLoanEnquiryId,
            loanEnquiryId,
            userIds,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
