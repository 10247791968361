import createP4ApolloFetch from '../bootstrap/middlewares';
import { BORROWER_URL } from '../bootstrap/constants/constants';

export const sendChatMessage = (uri, message, channelId) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation SendTextMessage($input: ${
            uri === BORROWER_URL ? 'borrower_liveChatSendMessageInput!' : 'intermediary_liveChatSendMessageInput!'
        }) {
        liveChatSendMessage(input: $input) {
            message {
                id
                senderId
                type
                category
                userProfile {
                    given
                }
                userRole
                content
                sentAt
            }
        }
    }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            input: {
                channelId: channelId,
                message: message,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return null;
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
