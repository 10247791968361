import React, { useState } from 'react';
import { Button, Drawer, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ZoomIcon from '../../../../../../assets/icons/zoom.svg';
import { setLLETerms } from '../../../../../../pitch4_api/set_lle_terms';
import DrawerTerms from '../../../../../../pitch4_elements/terms/DrawerTerms';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import { SLAM_URL } from '../../../../../../bootstrap/constants/constants';

export default function TermsEditDrawer(props) {
    const {
        termsEditDrawerOpen,
        toggleTermsEditDrawer,
        allTerms,
        termsUpdatedAt,
        lleId,
        product,
        updateLLETerms,
        disableActions,
    } = props;
    const [submitting, setSubmitting] = useState(false);
    const handleTermsSubmit = () => {
        setSubmitting(true);
        const newTerms = {
            product: product,
            terms: allTerms,
            updatedAt: moment().format(),
        };
        setLLETerms(SLAM_URL, lleId, newTerms).then((resp) => {
            setSubmitting(false);
            toggleTermsEditDrawer(); //Close the drawer
            updateLLETerms(newTerms);
        });
    };

    return (
        <Drawer anchor={'right'} open={termsEditDrawerOpen} className={'slam-terms-edit-drawer'}>
            <>
                <div className={'header'}>
                    <div className={'sub-header'} style={{ alignItems: 'center' }}>
                        <div className={'icon'}>
                            <img src={ZoomIcon} alt={''} style={{ height: 40 }} />
                        </div>
                        <span className={'title'}>Offer Terms</span>
                    </div>
                    <IconButton onClick={() => toggleTermsEditDrawer(true)}>
                        <CloseIcon fontSize={'large'} />
                    </IconButton>
                </div>
                <DrawerTerms termsUpdatedAt={termsUpdatedAt} allTerms={allTerms} />
                <div className={'actions'}>
                    <Button
                        className={'btn-submit'}
                        variant={'contained'}
                        size={'large'}
                        color={'primary'}
                        onClick={handleTermsSubmit}
                        disabled={submitting || disableActions}
                    >
                        {true === submitting ? <CircularProgress className={'progress'} /> : 'Submit'}
                    </Button>
                </div>
            </>
        </Drawer>
    );
}
