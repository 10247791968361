import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });

const GetLEsQuery = `
    query getLoanEnquiries(
        $cursor: String
        $first: Int!
        $sorts: [SortInput]
        $filters: [FilterInput]
        $storedDataFilters: [String]
        $page: Int
    ) {
        loanEnquiries(
            first: $first
            after: $cursor
            sorts: $sorts
            filters: $filters
            storedDataFilters: $storedDataFilters
            page: $page
        ) {
            edges {
                node {
                    id
                    borrowerReference
                    alertActive
                    lenderTermsUpdatedAlertActive
                    submittedBy {
                        userProfile {
                            title
                            given
                            family
                        }
                    }
                    statistic {
                        finalised
                        approved
                        pending
                        considered
                        rejected
                        createdAt
                        updatedAt
                    }
                    loanEnquiryApplicants {
                        isMainApplicant
                        applicant {
                            given
                            family
                            dateOfBirth
                        }
                    }
                    isNewForm
                    loanEnquiryData {
                        loanToValue
                        loanToCost
                        loanToGrossDevelopmentValue
                        dayOneLoanAmountPercentage
                        additionalInformation
                        rentalIncomeResidential
                        hmoBedroom
                        hmoRoomsSelfContained
                        hmoHasLicense
                        hmoNeedsLicense
                        buyToLetExperience
                        rentalIncomeCommercial
                        rentalIncomePortfolio
                        rentalIncome
                        commercialUnitsRentedOut
                        commercialUnitOwnerOccupied
                        commercialUnitAmount
                        commercialType
                        commercialTypeOther
                        siteDescription
                        siteIntention
                        refinance
                        businessSeasonal
                        businessType
                        businessName
                        businessWebsite
                        businessTradingLength
                        businessSector
                        businessFacilityNeededBy
                        businessFacilityTerm
                        businessCurrentPaymentTerms
                        businessOutstandingInvoices
                        businessPdqIncome
                        propertyType
                        loanAmount
                        loanPurpose
                        turnOver
                        businessProfitable
                        businessOverdraftFacility
                        businessOutstandingDebt
                        businessAdverseCredit
                        businessAdverseCreditExplanation
                        businessDirectorsAdverseCredit
                        businessDirectorsAdverseCreditExplanation
                        shareholdersAreHomeOwners
                        propertyAvailableForAdditionalSecurity
                        planningFor
                        developmentLength
                        refurbishmentLength
                        outstandingLoan
                        dayOneLoanAmount
                        unitsPreSold
                        worksExplanation
                        totalDevelopmentCosts
                        buildCostsBorrowing
                        expectedGrossDevelopmentValue
                        previousDevelopmentExperience
                        previousRefurbishmentExperience
                        teamDevelopmentExperience
                        epcRating
                        loanType
                        fundingType
                        businessSecuredFinance
                        loanUsedInUkEu
                        planningInPlace
                        endValue
                        percentageResidential
                        percentageCommercial
                        lender
                        firstChargeAmount
                        currentValue
                        properties
                        portfolioValue
                        portfolioAlreadyOwned
                        loanTerm
                        loanNeededBy
                        preApproveMatches
                        loanAmountRefurbishment
                        percentageResidentialSquareFeet
                        percentageResidentialValue
                        percentageCommercialValue
                        residentialUnitCount
                        residentialUnitValue
                        businessOccupyEntireProperty
                        commercialUnitRentBreakdown
                        commercialTenantAmount
                        ownsOtherCommercialProperty
                        ownOtherProperties
                        occupyProperty
                        ownsOtherInvestmentProperties
                        familyHasOccupied
                        secondCharge
                        occupyingGreaterThan40Percent
                        location
                        locationOther
                        address
                        postcode
                        propertyOwnership
                        refurbishmentCost
                        invoiceFinanceType
                        leaseHirePurchase
                        mainApplicantCreditDifficulties
                        mainApplicantCreditDifficultiesExplanation
                        mainApplicantCitizenshipStatus
                        mainApplicantCountryOfResidence
                        mainApplicantCountryOfResidenceOther
                        mainApplicantNationality
                        mainApplicantNationalityOther
                        mainApplicantOccupation
                        mainApplicantGrossIncome
                        mainApplicantExitStrategy
                        agreeToTerms
                    }
                    storedData {
                        meta {
                            key
                        }
                        value
                    }
                    status
                    createdAt
                    updatedAt
                    favourite
                    assignees {
                      id
                      userProfile {
                        given
                        family
                      }
                    }
                    broker {
                       id
                       name
                       parentBroker {
                         id
                         name
                       }
                       isNetwork
                    }
                }
                cursor
            }
            pageInfo {
                hasNextPage
                endCursor
                startCursor
                hasPreviousPage
            }
            totalCount
        }
    }
`;

export const getLEs = (cursor, filters, first, sorts, storedDataFilters, page) => {
    return apolloFetch({
        query: GetLEsQuery,
        variables: {
            cursor: cursor,
            filters: filters,
            first: first,
            sorts: sorts,
            storedDataFilters: storedDataFilters,
            page: page,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
