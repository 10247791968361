import React from 'react';
import { put, select, takeLatest } from 'redux-saga/effects';
import { STATUS_MATCHING } from '../../enum/LoanEnquiries/StatusEnum';
import ReviewMatchedLendersContainer from '../containers/ReviewMatchedLendersContainer';
import matchingInProgress from '../../../../assets/images/matching-inprogress.svg';
import ModalLayout from 'pitch4_modal/src/component/ModalLayout';
import { CenteringContainer } from 'pitch4_layout/components/div';
import { CLEANUP, SET_API_GET_LOAN_ENQUIRY_MATCHES_RESPONSE } from '../redux';
import { actionModalHide, actionModalSetComponent, selectorVisible } from '../../../modal/redux/container';

function* showMatchModal(action) {
    const { loanEnquiryMatch } = action;
    const {
        loanEnquiry: { status },
        match_pending,
    } = loanEnquiryMatch;

    if (status === STATUS_MATCHING && window._ENV_.APP_ENV === 'prod') {
        yield put(
            actionModalSetComponent(
                <ModalLayout>
                    <CenteringContainer>
                        <img src={matchingInProgress} alt="Matching in progress. Please wait." />
                    </CenteringContainer>
                </ModalLayout>,
                false
            )
        );
        return;
    }

    if (match_pending.length > 0) {
        yield put(
            actionModalSetComponent(
                <ModalLayout title={`Approve Matched Lenders`}>
                    <ReviewMatchedLendersContainer matches={match_pending} />
                </ModalLayout>,
                false
            )
        );
        return;
    }

    const modalVisible = yield select(selectorVisible);
    if (modalVisible) {
        yield put(actionModalHide());
    }
}

function* closeModal() {
    const modalVisible = yield select(selectorVisible);
    if (modalVisible) {
        yield put(actionModalHide());
    }
}

function* main() {
    yield takeLatest(SET_API_GET_LOAN_ENQUIRY_MATCHES_RESPONSE, showMatchModal);
    yield takeLatest(CLEANUP, closeModal);
}

export default main;
