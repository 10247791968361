import React from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import SwitchButton from '../../pitch4_layout/components/buttons/SwitchButton';

class RollupCommandContainer extends React.Component {
    render() {
        let { children, label = null } = this.props;
        let options = [];
        let name = null;
        let itemChildren = null;

        React.Children.map(children, (item, index) => {
            if (item === null || !item.type) {
                return;
            }

            itemChildren = item.props.children;

            options.push({
                name: name,
                item: item,
                itemChildren: itemChildren,
            });
        });

        return <SwitchButton options={options} label={label} />;
    }
}

RollupCommandContainer.propTypes = {
    children: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RollupCommandContainer);
