import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import CurrencyFormatter from './formatter/CurrencyFormatter';
import PercentFormatter from './formatter/PercentFormatter';
import DateFormatter from './formatter/DateFormatter';
import {
    ANNUAL_RENT,
    BORROWER_CCJ_HOW_MUCH,
    BORROWER_CCJ_SATISFIED,
    BORROWER_COUNTRY_OF_RESIDENCE,
    BORROWER_DEFAULTED_HOW_MUCH,
    BORROWER_DEFAULTED_UP_TO_DATE,
    BORROWER_GROWTH_AND_INCOME,
    BORROWER_HAS_ADVERSE_CREDIT,
    BORROWER_HAS_CCJS,
    BORROWER_HAS_DEFAULTED,
    BORROWER_IS_UK_CITIZEN,
    BORROWER_OWN_OTHER_PROPERTIES,
    BORROWER_RIGHT_TO_RESIDE,
    BUILD_COSTS,
    BUSINESS_INTEND_TO_OCCUPY,
    COMMERCIAL_PROPERTY_TO_BE_DEVELOPED,
    DEVELOP_PURCHASE_PRICE,
    EXPECTED_END_VALUE,
    HAS_EXPERIENCE_DEVELOPING,
    IS_SPECIFIC_TENANT,
    LAND_HAS_PLANNING_PERMISSION,
    LAND_PURCHASE_PRICE,
    INITIAL_LOAN_AMOUNT_REQUIRED,
    LOAN_AMOUNT_TO_COVER_BUILD_COSTS,
    LOAN_AMOUNT_TO_COVER_REFURBISHMENT_COSTS,
    LOAN_AMOUNT,
    LOAN_LTV,
    LOAN_TYPE,
    LOAN_NEEDED_BY,
    LOAN_NEEDED_TO_COVER_BUILD_COSTS,
    PLANNING_IN_PLACE,
    PROPERTY_EXPECTED_RENTAL_INCOME,
    PROPERTY_HOW_OWNED,
    PROPERTY_INTEND_TO_OCCUPY,
    PROPERTY_IS_ALREADY_OWNED,
    PROPERTY_LOAN_TYPE,
    PROPERTY_PURCHASE_PRICE,
    PROPERTY_TO_BE_RENTED_OUT,
    PROPERTY_TYPE,
    PROPERTY_WAS_OCCUPIED,
    REFURBISHMENT_COSTS,
    REFURBISHMENT_NOT_REQUIRE_PLANNING,
    TENANCY_TERM,
    TYPE_OF_COMMERCIAL,
    CURRENT_LOAN_AMOUNT,
    BORROWER_NATIONALITY,
    DEVELOPMENT_OR_REFURBISHMENT_COSTS,
    EXPECTED_BUILD_TIME_MONTHS,
    EXPECTED_RENTAL_INCOME,
    INTEND_TO_DEVELOP_OR_REFURBISH,
    INTEND_TO_FINANCE_PORTFOLIO,
    LOAN_DURATION,
    NUMBER_COMMERCIAL_UNITS,
    NUMBER_RESIDENTIAL_UNITS,
    PROPERTY_COUNTRY,
    REQUIRES_PLANNING_PERMISSION,
    RESIDENTIAL_UNITS_MONTHLY_INCOME,
    PLANNING_FOR,
    LOAN_PURPOSE,
    BUSINESS_SECTOR,
    BUSINESS_HAD_ADVERSE_CREDIT,
    BUSINESS_TOTAL_DEBTS,
    PREVIOUS_YEAR_TURNOVER,
    PDQ_TURNOVER_PER_MONTH,
    SHAREHOLDER_IS_HOMEOWNER,
    LOAN_ASSET,
    BUSINESS_OUTSTANDING_INVOICE,
    BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT,
    BUSINESS_PROFITABLE,
    BUSINESS_ADDITIONAL_SECURITY,
    BUSINESS_OUTSTANDING_INVOICE_AMOUNT,
    BUSINESS_TRADING_MONTHS,
    BUSINESS_LOAN_TERM,
    REGISTER_USER_AGE_POINT_IN_TIME,
    ADDITIONAL_INFO_NOTES,
    ADDRESS,
    ADVERSE_CREDIT,
    BEDROOMS,
    BORROWER_CCJ_WHEN,
    BORROWER_CCJ_WHY,
    BORROWER_DEFAULTED_WHEN,
    BORROWER_DEFAULTED_WHY,
    BORROWER_EMPLOYMENT_STATUS,
    BORROWER_OCCUPATION,
    BUILD_COST,
    BUSINESS_ADVERSE_CREDIT_DETAILS,
    BUSINESS_NAME,
    BUSINESS_NON_REFINANCE_ASSET,
    BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT_EXPLANATION,
    BUY_TO_LET_EXPERIENCE,
    COMMERCIAL_RENTED,
    COMMERCIAL_TYPE,
    COMMERCIAL_UNITS,
    CURRENT_VALUE,
    DAY_ONE_LOAN_AMOUNT,
    DEVELOPMENT_COSTS,
    DEVELOPMENT_DURATION,
    DEVELOPMENT_EXPERIENCE,
    END_VALUE,
    FIRST_CHARGE_AMOUNT,
    GDV,
    LAND_HOW_OWNED,
    LOAN_NEEDED_TO_COVER_REFURBISHMENT_COSTS,
    LOAN_REASON,
    LOAN_TERM,
    LOCATION,
    LTV,
    OUTSTANDING_LOAN,
    OWNER_OCCUPIED,
    PERCENTAGE_COMMERCIAL,
    PERCENTAGE_RESIDENTIAL,
    PLANNING_DESCRIPTION,
    PORTFOLIO_ALREADY_OWNED,
    PORTFOLIO_PROPERTIES,
    PORTFOLIO_VALUE,
    POSTCODE,
    PROPERTY_OWNERSHIP,
    REFINANCE,
    RENTAL_INCOME_COMMERCIAL,
    RENTAL_INCOME_RESIDENTIAL,
    RESIDENTIAL_UNITS,
    RESIDENTIAL_UNITS_VALUE,
    TOTAL_FUNDING_REQUIRED,
    TYPE_OF_TENANT,
    UK_CITIZEN,
    UNIT_PRE_SOLD,
    INVOICE_FINANCE_PRODUCTS,
    PERCENTAGE_FLOOR_SPACE_COMMERCIAL,
} from '../../../apps/admin/slam/enum/MatchingRules/MetaKeyEnum';
import { BooleanFormatter } from './formatter';
import CountryNameEnum from 'pitch4_enum/enum/CountryNameEnum';
import EnumLabel from './EnumLabel';
import NationalityEnum from 'pitch4_enum/enum/NationalityEnum';
import {
    CommercialTypeEnum,
    CountryEnum,
    LoanTermEnum,
    PlanningForEnum,
    PropertyHowOwnedEnum,
    PropertyTypeEnum,
    YesNoEnum,
    PropertyLoanTypeEnum,
    LoanNeededByEnum,
    BusinessFinanceSectorEnum,
    BusinessFinanceLoanPurposeEnum,
    LoanTypeEnum,
    LoanAssetTypeEnum,
    BusinessLoanTermEnum,
    OwnAnyOtherPropertyTypeEnum,
} from '../../../apps/admin/enum/matching/enum';
import {
    BUSINESS_PRODUCT,
    BUSINESS_STRUCTURE,
    BUSINESS_UK_REGISTERED,
    OWN_OTHER_PROPERTIES,
    PERCENTAGE_FLOOR_SPACE_RESIDENTIAL,
    REFURBISHMENT_FUNDING,
    TOTAL_REFURBISHMENT_COST,
} from '../../../apps/admin/enum/LoanEnquiries/MetaKeyEnum';
import ProductTypeEnum from '../../../apps/admin/slam/enum/BusinessStreams/ProductTypeEnum';
import BusinessStructureEnum from '../../../apps/admin/slam/enum/BusinessStreams/BusinessStructureEnum';
import BusinessNonRefinanceAssetEnum from '../../../apps/admin/slam/enum/BusinessStreams/BusinessNonRefinanceAssetEnum';
import PropertyOwnershipEnum from '../../../apps/admin/slam/enum/BusinessStreams/PropertyOwnershipEnum';
import MezzanineLoanReasonEnum from '../../../apps/admin/slam/enum/BusinessStreams/MezzanineLoanReasonEnum';
import BusinessInvoiceFactoringEnum from '../../../apps/admin/enum/matching/enum/BusinessInvoiceFactoringEnum';
import UKCitizenshipEnum from '../../../apps/admin/enum/matching/enum/UKCitizenshipEnum';
import RefinancePurchaseEnum from '../../../apps/admin/enum/matching/enum/RefinancePurchaseEnum';

// Constants denoting value type
export const TYPE_FLOAT = 'float';
export const TYPE_INTEGER = 'int';
export const TYPE_INTEGER_LONG = 'integer';
export const TYPE_PERCENT = 'percent';
export const TYPE_CURRENCY = 'currency';
export const TYPE_DATE = 'date';
export const TYPE_TEXT = 'text';
export const TYPE_ENUM = 'enum';
export const TYPE_YESNO_ENUM = 'yesno_enum';
export const TYPE_LOAN_TYPE_ENUM = 'loan_type_enum';
export const TYPE_GLOBAL_COUNTRY_ENUM = 'global_country_enum';
export const TYPE_UK_CITIZEN_ENUM = 'borrower_citizenship';
export const TYPE_PROPERTY_COUNTRY_ENUM = 'property_country_enum';
export const TYPE_NATIONALITY_ENUM = 'nationality_enum';
export const TYPE_LOAN_DURATION_ENUM = 'loan_duration_enum';
export const TYPE_PLANNING_FOR_ENUM = 'planning_for_enum';
export const TYPE_HOW_OWNED_ENUM = 'how_owned_enum';
export const TYPE_PROPERTY_TYPE_ENUM = 'property_type_enum';
export const TYPE_COMMERCIAL_TYPE_ENUM = 'commercial_type_enum';
export const TYPE_PROPERTY_LOAN_TYPE_ENUM = 'property_loan_type_enum';
export const TYPE_LOAN_NEEDED_BY_ENUM = 'loan_needed_by_enum';
export const TYPE_BUSINESS_SECTOR_ENUM = 'business_sector_enum';
export const TYPE_BUSINESS_LOAN_PURPOSE_ENUM = 'business_loan_purpose_enum';
export const TYPE_BUSINESS_LOAN_ASSET_PURPOSE_ENUM = 'business_loan_asset_purpose';
export const TYPE_BUSINESS_LOAN_TERM = 'business_loan_term';
export const TYPE_HIRE_LEASE_ENUM = 'hire_lease';
export const TYPE_BOOLEAN = 'boolean';
export const TYPE_PRODUCT_TYPE = 'product_type_enum';
export const TYPE_BUSINESS_STRUCTURE = 'business_structure_enum';
export const TYPE_LOCATION = 'property_country';
export const TYPE_PROPERTY_OWNERSHIP_ENUM = 'type_property_ownership_enum';
export const TYPE_LOAN_REASON_ENUM = 'type_loan_reason_enum';
export const TYPE_INVOICE_FINANCE_PRODUCTS_ENUM = 'type_invoice_finance_products_enum';
export const REFINANCE_NEW_PURCHASE_ENUM = 'refinance_new_purchase_enum';
export const TYPE_OWN_OTHER_PROPERTIES = 'own_other_properties_enum';

/**
 * Get the type for specific facts.
 *
 * @param name
 * @returns {string}
 */
const getType = (name) => {
    switch (name) {
        case PROPERTY_LOAN_TYPE:
            return TYPE_PROPERTY_LOAN_TYPE_ENUM;

        case LOAN_TYPE:
            return TYPE_LOAN_TYPE_ENUM;

        case LOAN_PURPOSE:
            return TYPE_BUSINESS_LOAN_PURPOSE_ENUM;

        case BUSINESS_SECTOR:
            return TYPE_BUSINESS_SECTOR_ENUM;

        case LOAN_NEEDED_BY:
            return TYPE_LOAN_NEEDED_BY_ENUM;

        case LOAN_ASSET:
            return TYPE_BUSINESS_LOAN_ASSET_PURPOSE_ENUM;

        case BUSINESS_LOAN_TERM:
            return TYPE_BUSINESS_LOAN_TERM;

        case INVOICE_FINANCE_PRODUCTS:
            return TYPE_INVOICE_FINANCE_PRODUCTS_ENUM;

        case PROPERTY_INTEND_TO_OCCUPY:
        case PROPERTY_WAS_OCCUPIED:
        case BORROWER_OWN_OTHER_PROPERTIES:
        case BORROWER_HAS_ADVERSE_CREDIT:
        case BORROWER_HAS_CCJS:
        case BORROWER_CCJ_SATISFIED:
        case BORROWER_HAS_DEFAULTED:
        case BORROWER_DEFAULTED_UP_TO_DATE:
        case BORROWER_IS_UK_CITIZEN:
        case BORROWER_RIGHT_TO_RESIDE:
        case LAND_HAS_PLANNING_PERMISSION:
        case PROPERTY_IS_ALREADY_OWNED:
        case PLANNING_IN_PLACE:
        case LOAN_NEEDED_TO_COVER_BUILD_COSTS:
        case REFURBISHMENT_NOT_REQUIRE_PLANNING:
        case HAS_EXPERIENCE_DEVELOPING:
        case PROPERTY_TO_BE_RENTED_OUT:
        case COMMERCIAL_PROPERTY_TO_BE_DEVELOPED:
        case IS_SPECIFIC_TENANT:
        case BUSINESS_INTEND_TO_OCCUPY:
        case INTEND_TO_DEVELOP_OR_REFURBISH:
        case INTEND_TO_FINANCE_PORTFOLIO:
        case REQUIRES_PLANNING_PERMISSION:
        case BUSINESS_HAD_ADVERSE_CREDIT:
        case SHAREHOLDER_IS_HOMEOWNER:
        case BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT:
        case BUSINESS_ADDITIONAL_SECURITY:
        case BUSINESS_OUTSTANDING_INVOICE:
        case BUSINESS_PROFITABLE:
        case ADVERSE_CREDIT:
        case BUY_TO_LET_EXPERIENCE:
        case COMMERCIAL_RENTED:
        case DEVELOPMENT_EXPERIENCE:
        case LOAN_NEEDED_TO_COVER_REFURBISHMENT_COSTS:
        case PLANNING_DESCRIPTION:
        case OWNER_OCCUPIED:
        case UNIT_PRE_SOLD:
        case REFINANCE:
        case BUSINESS_UK_REGISTERED:
            return TYPE_YESNO_ENUM;

        case OWN_OTHER_PROPERTIES:
            return TYPE_OWN_OTHER_PROPERTIES;

        case PORTFOLIO_ALREADY_OWNED:
            return REFINANCE_NEW_PURCHASE_ENUM;

        case UK_CITIZEN:
            return TYPE_UK_CITIZEN_ENUM;

        case BORROWER_COUNTRY_OF_RESIDENCE:
            return TYPE_GLOBAL_COUNTRY_ENUM;

        case BORROWER_NATIONALITY:
            return TYPE_NATIONALITY_ENUM;

        case PROPERTY_COUNTRY:
            return TYPE_PROPERTY_COUNTRY_ENUM;

        case PLANNING_FOR:
            return TYPE_PLANNING_FOR_ENUM;

        case PROPERTY_HOW_OWNED:
            return TYPE_HOW_OWNED_ENUM;

        case PROPERTY_TYPE:
            return TYPE_PROPERTY_TYPE_ENUM;

        case COMMERCIAL_TYPE:
        case TYPE_OF_COMMERCIAL:
            return TYPE_COMMERCIAL_TYPE_ENUM;

        case BUSINESS_NON_REFINANCE_ASSET:
            return TYPE_HIRE_LEASE_ENUM;

        case BUSINESS_PRODUCT:
            return TYPE_PRODUCT_TYPE;

        case BUSINESS_STRUCTURE:
            return TYPE_BUSINESS_STRUCTURE;

        case LOCATION:
        case TYPE_PROPERTY_COUNTRY_ENUM:
            return TYPE_LOCATION;

        case PROPERTY_OWNERSHIP:
            return TYPE_PROPERTY_OWNERSHIP_ENUM;

        case LOAN_REASON:
            return TYPE_LOAN_REASON_ENUM;

        case EXPECTED_END_VALUE:
        case PROPERTY_PURCHASE_PRICE:
        case DEVELOP_PURCHASE_PRICE:
        case PROPERTY_EXPECTED_RENTAL_INCOME:
        case RESIDENTIAL_UNITS_MONTHLY_INCOME:
        case BORROWER_GROWTH_AND_INCOME:
        case LAND_PURCHASE_PRICE:
        case INITIAL_LOAN_AMOUNT_REQUIRED:
        case BUILD_COSTS:
        case LOAN_AMOUNT_TO_COVER_BUILD_COSTS:
        case REFURBISHMENT_COSTS:
        case LOAN_AMOUNT_TO_COVER_REFURBISHMENT_COSTS:
        case ANNUAL_RENT:
        case CURRENT_LOAN_AMOUNT:
        case DEVELOPMENT_OR_REFURBISHMENT_COSTS:
        case EXPECTED_RENTAL_INCOME:
        case LOAN_AMOUNT:
        case BUSINESS_TOTAL_DEBTS:
        case PREVIOUS_YEAR_TURNOVER:
        case BUSINESS_OUTSTANDING_INVOICE_AMOUNT:
        case PDQ_TURNOVER_PER_MONTH:
        case CURRENT_VALUE:
        case FIRST_CHARGE_AMOUNT:
        case PORTFOLIO_VALUE:
        case RENTAL_INCOME_COMMERCIAL:
        case RENTAL_INCOME_RESIDENTIAL:
        case TOTAL_FUNDING_REQUIRED:
            return TYPE_CURRENCY;

        case RESIDENTIAL_UNITS_VALUE:
        case DAY_ONE_LOAN_AMOUNT:
        case END_VALUE:
        case BUILD_COST:
        case DEVELOPMENT_COSTS:
        case LOAN_LTV:
        case LTV:
        case PERCENTAGE_RESIDENTIAL:
        case PERCENTAGE_COMMERCIAL:
        case PERCENTAGE_FLOOR_SPACE_COMMERCIAL:
        case REFURBISHMENT_FUNDING:
        case PERCENTAGE_FLOOR_SPACE_RESIDENTIAL:
        case GDV:
        case OUTSTANDING_LOAN:
        case TOTAL_REFURBISHMENT_COST:
            return TYPE_PERCENT;

        case NUMBER_COMMERCIAL_UNITS:
        case REGISTER_USER_AGE_POINT_IN_TIME:
        case TENANCY_TERM:
        case EXPECTED_BUILD_TIME_MONTHS:
        case NUMBER_RESIDENTIAL_UNITS:
        case BORROWER_CCJ_HOW_MUCH:
        case BUSINESS_TRADING_MONTHS:
        case BORROWER_DEFAULTED_HOW_MUCH:
        case LOAN_DURATION:
        case COMMERCIAL_UNITS:
        case BEDROOMS:
        case DEVELOPMENT_DURATION:
        case LOAN_TERM:
        case PORTFOLIO_PROPERTIES:
        case RESIDENTIAL_UNITS:
            return TYPE_INTEGER;

        case ADDRESS:
        case ADDITIONAL_INFO_NOTES:
        case BUSINESS_ADVERSE_CREDIT_DETAILS:
        case BORROWER_CCJ_WHEN:
        case BORROWER_CCJ_WHY:
        case BORROWER_DEFAULTED_WHEN:
        case BORROWER_DEFAULTED_WHY:
        case BUSINESS_NAME:
        case BORROWER_OCCUPATION:
        case BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT_EXPLANATION:
        case LAND_HOW_OWNED:
        case BORROWER_EMPLOYMENT_STATUS:
        case POSTCODE:
        case TYPE_OF_TENANT:
        default:
            return TYPE_TEXT;
    }
};

const isNumericType = (type) => {
    return (
        _.indexOf(
            [TYPE_CURRENCY, TYPE_DATE, TYPE_FLOAT, TYPE_INTEGER, TYPE_INTEGER_LONG, TYPE_INTEGER_LONG, TYPE_PERCENT],
            type
        ) >= 0
    );
};

const renderString = (name, value) => {
    const parts = String(value).split('\n');

    if (parts.length > 1) {
        return parts.map((item, i) => <p key={i}>{item}</p>);
    }

    return <span>{value}</span>;
};

/**
 * Format and render Fact values depending on their type (e.g. dates, currency, percent etc).
 */
class FactValue extends React.Component {
    render() {
        let { name, value, inPennies = false } = this.props;
        switch (getType(name)) {
            case TYPE_PERCENT:
                return <PercentFormatter value={value} />;

            case TYPE_CURRENCY:
                return <CurrencyFormatter value={value} inPennies={inPennies} />;

            case TYPE_DATE:
                return <DateFormatter value={value} />;

            case TYPE_BOOLEAN:
                return <BooleanFormatter value={value} />;

            case TYPE_YESNO_ENUM:
                return <EnumLabel enumIn={new YesNoEnum()} value={value} />;

            case TYPE_GLOBAL_COUNTRY_ENUM:
                return <EnumLabel enumIn={new CountryNameEnum()} value={value} />;

            case TYPE_LOCATION:
            case TYPE_PROPERTY_COUNTRY_ENUM:
                return <EnumLabel enumIn={new CountryEnum()} value={value} />;

            case TYPE_NATIONALITY_ENUM:
                return <EnumLabel enumIn={new NationalityEnum()} value={value} />;

            case TYPE_LOAN_DURATION_ENUM:
                return <EnumLabel enumIn={new LoanTermEnum()} value={value} />;

            case TYPE_PLANNING_FOR_ENUM:
                return <EnumLabel enumIn={new PlanningForEnum()} value={value} />;

            case TYPE_HOW_OWNED_ENUM:
                return <EnumLabel enumIn={new PropertyHowOwnedEnum()} value={value} />;

            case TYPE_PROPERTY_OWNERSHIP_ENUM:
                return <EnumLabel enumIn={new PropertyOwnershipEnum()} value={value} />;

            case TYPE_PROPERTY_TYPE_ENUM:
                return <EnumLabel enumIn={new PropertyTypeEnum()} value={value} />;

            case TYPE_LOAN_TYPE_ENUM:
                return <EnumLabel enumIn={new LoanTypeEnum()} value={value} />;

            case TYPE_COMMERCIAL_TYPE_ENUM:
                return <EnumLabel enumIn={new CommercialTypeEnum()} value={value} />;

            case TYPE_PROPERTY_LOAN_TYPE_ENUM:
                return <EnumLabel enumIn={new PropertyLoanTypeEnum()} value={value} />;

            case TYPE_LOAN_NEEDED_BY_ENUM:
                return <EnumLabel enumIn={new LoanNeededByEnum()} value={value} />;

            case TYPE_BUSINESS_SECTOR_ENUM:
                return <EnumLabel enumIn={new BusinessFinanceSectorEnum()} value={value} />;

            case TYPE_BUSINESS_LOAN_PURPOSE_ENUM:
                return <EnumLabel enumIn={new BusinessFinanceLoanPurposeEnum()} value={value} />;

            case TYPE_BUSINESS_LOAN_ASSET_PURPOSE_ENUM:
                return <EnumLabel enumIn={new LoanAssetTypeEnum()} value={value} />;

            case TYPE_BUSINESS_LOAN_TERM:
                return <EnumLabel enumIn={new BusinessLoanTermEnum()} value={value} />;

            case TYPE_PRODUCT_TYPE:
                return <EnumLabel enumIn={new ProductTypeEnum()} value={value} />;

            case TYPE_BUSINESS_STRUCTURE:
                return <EnumLabel enumIn={new BusinessStructureEnum()} value={value} />;

            case TYPE_HIRE_LEASE_ENUM:
                return <EnumLabel enumIn={new BusinessNonRefinanceAssetEnum()} value={value} />;

            case TYPE_LOAN_REASON_ENUM:
                return <EnumLabel enumIn={new MezzanineLoanReasonEnum()} value={value} />;

            case TYPE_INVOICE_FINANCE_PRODUCTS_ENUM:
                return <EnumLabel enumIn={new BusinessInvoiceFactoringEnum()} value={value} />;

            case TYPE_UK_CITIZEN_ENUM:
                return <EnumLabel enumIn={new UKCitizenshipEnum()} value={value} />;

            case REFINANCE_NEW_PURCHASE_ENUM:
                return <EnumLabel enumIn={new RefinancePurchaseEnum()} value={value} />;

            case TYPE_OWN_OTHER_PROPERTIES:
                return <EnumLabel enumIn={new OwnAnyOtherPropertyTypeEnum()} value={value} />;

            default:
                return renderString(name, value);
        }
    }
}

/**
 * Set requirements for props.
 *
 * @type {{column: *, value: *}}
 */
FactValue.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    inPennies: PropTypes.bool,
};

export { getType, isNumericType };

export default FactValue;
