import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Divider,
    FormControl,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core';
import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TuneIcon from '@material-ui/icons/Tune';
import UpdatesFilter from '../../../../../pitch4_elements/toolbar/UpdatesFilter';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';
import AssigneeFilter from '../../../../../pitch4_elements/toolbar/AssigneeFilter';

export default function Toolbar(props) {
    const {
        updatesFilter,
        searchFilter,
        dataLoading,
        handleUpdatesFilterChange,
        handleClearAllFilters,
        handleBusinessStreamFilterChange,
        handleSearchFilterChange,
        businessStreamsFilter,
        businessStreams,
        assigneeFilter,
        handleAssigneeFilterChange,
    } = props;
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <>
            <div className={'header-toolbar'}>
                <TextField
                    name={'search'}
                    className={'search'}
                    label="Search"
                    variant="outlined"
                    value={searchFilter}
                    disabled={dataLoading}
                    onChange={handleSearchFilterChange}
                />
                <AssigneeFilter
                    assigneeFilter={assigneeFilter}
                    handleAssigneeFilterChange={handleAssigneeFilterChange}
                    uri={SLAM_URL}
                />
                <UpdatesFilter updatesFilter={updatesFilter} handleUpdatesFilterChange={handleUpdatesFilterChange} />
                <FormControl variant="outlined" className={'select-bs'} style={{ minWidth: 150 }}>
                    <InputLabel id="select-bs-label">Business Streams</InputLabel>
                    <Select
                        labelId="select-bs-label"
                        id="select-bs"
                        name={'bs'}
                        label={'Business Streams'}
                        value={businessStreamsFilter}
                        onChange={handleBusinessStreamFilterChange}
                        renderValue={(selected) => selected}
                        multiple={false}
                        MenuProps={MenuProps}
                    >
                        {businessStreams.map((bs) => {
                            return (
                                <MenuItem key={bs.id} value={bs.name}>
                                    <ListItemText primary={bs.name} />
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Button
                    variant="outlined"
                    size={'large'}
                    style={{ height: 45, paddingTop: 11 }}
                    onClick={handleClearAllFilters}
                >
                    Clear all
                </Button>
            </div>

            <div className={'header-toolbar-mobile'}>
                <Divider className={'divider'} />
                <Accordion className={'container'}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        style={{ padding: '0 5px' }}
                    >
                        <TuneIcon style={{ marginRight: 10 }} /> Filters
                    </AccordionSummary>
                    <AccordionDetails className={'details'}>
                        <TextField
                            name={'search'}
                            className={'search'}
                            label="Search"
                            variant="outlined"
                            value={searchFilter}
                            disabled={dataLoading}
                            onChange={handleSearchFilterChange}
                        />
                        <AssigneeFilter
                            assigneeFilter={assigneeFilter}
                            handleAssigneeFilterChange={handleAssigneeFilterChange}
                            uri={SLAM_URL}
                        />
                        <UpdatesFilter
                            updatesFilter={updatesFilter}
                            handleUpdatesFilterChange={handleUpdatesFilterChange}
                        />
                        <FormControl variant="outlined" className={'select-bs'}>
                            <InputLabel id="select-bs-label">Business Streams</InputLabel>
                            <Select
                                labelId="select-bs-label"
                                id="select-bs"
                                name={'bs'}
                                label={'Business Streams'}
                                value={businessStreamsFilter}
                                onChange={handleBusinessStreamFilterChange}
                                renderValue={(selected) => selected}
                                multiple={false}
                                MenuProps={MenuProps}
                            >
                                {businessStreams.map((bs) => {
                                    return (
                                        <MenuItem key={bs.id} value={bs.name}>
                                            <ListItemText primary={bs.name} />
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Button
                            variant="outlined"
                            size={'large'}
                            className={'btn-clear-all'}
                            onClick={handleClearAllFilters}
                        >
                            Clear all
                        </Button>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}
