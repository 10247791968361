import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import { DownloadButton as DownloadBtn } from 'pitch4_documents';

class DownloadDocumentButton extends React.Component {
    render() {
        const { document, onPending, onSuccess, onFailure, inputRef = () => null, children } = this.props;

        return (
            <DownloadBtn
                buttonRender={(handleDownload) => (
                    <PrimaryButton inputRef={inputRef} onClick={() => handleDownload()}>
                        {children}
                    </PrimaryButton>
                )}
                document={document}
                onPending={onPending}
                onSuccess={onSuccess}
                onFailure={onFailure}
            />
        );
    }
}

DownloadDocumentButton.propTypes = {
    document: PropTypes.object.isRequired,
    onPending: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
    children: PropTypes.any,
    inputRef: PropTypes.any,
};

export default DownloadDocumentButton;
