import { BaseEnum } from 'pitch4_enum';

import { OPERATION_EQ } from './OperationEnum';

class EnumOperationEnum extends BaseEnum {
    getTypes = () => {
        return {
            [OPERATION_EQ]: 'Is',
        };
    };
}

export default EnumOperationEnum;
