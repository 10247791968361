import React from 'react';
import { ASSETS_LOGO_URL } from '../../../../bootstrap/constants/constants';
import StarIcon from '../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../assets/images/star-gray.svg';
import { Badge } from '../../../../pitch4_layout/components/badge/Badge';
import CardViewLLEStatusSteps from './CardViewLLEStatusSteps';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { Paper } from '@material-ui/core';
import StatusEnum from '../../enum/LenderLoanEnquiries/StatusEnum';

export default function CardViewItem(props) {
    const {
        index,
        lender,
        selectedLenders,
        toggleLenderDrawer,
        handleFavouriteMatchChange,
        handleRemoveAlertMatchAlert,
        interestRateTerm,
        lendersMessageAllowed,
        handleLenderSelection,
        dataLength,
        handleSelectAll,
        updateLLEStatus,
        loanType,
    } = props;
    const statusEnum = new StatusEnum();
    return (
        <Paper
            key={index}
            className={`lender-card ${true === lender.favourite && 'favourite'} ${
                true === lender.alertActive && 'alert-active'
            }`}
            elevation={
                selectedLenders && selectedLenders.selectionModel.includes(lender.id)
                    ? 4
                    : true === lender.alertActive
                    ? 1
                    : 0
            }
            onClick={() => {
                toggleLenderDrawer()();
                toggleLenderDrawer(
                    true,
                    lender,
                    handleFavouriteMatchChange,
                    // handleRemoveAlertMatchAlert method is executed in src/apps/borrower/view_loan_enquiry/containers/LeadsContainer.js
                    handleRemoveAlertMatchAlert,
                    updateLLEStatus
                )();
            }}
        >
            <div>
                <div className={'header'}>
                    <span>
                        {lender.lenderProfile.profileLogo ? (
                            <img
                                style={{ maxHeight: 44 }}
                                title={lender.lenderName}
                                alt={lender.lenderName}
                                src={ASSETS_LOGO_URL.replace('{{lenderGuid}}', lender.lenderId)}
                                className={'lender-profile-logo'}
                            />
                        ) : (
                            lender.lenderName
                        )}
                    </span>
                    <img
                        src={true === lender.favourite ? StarIcon : StarGrayIcon}
                        alt="Favourite"
                        onClick={(event) => {
                            event.stopPropagation();
                            handleFavouriteMatchChange(lender.id, null, lender.favourite)();
                        }}
                        className={'favourite-icon'}
                    />
                </div>
                {lender.terms && interestRateTerm.value && (
                    <div className={'interest-rate-container'}>
                        Interest Rate:{' '}
                        <span className={'rate'}>
                            {interestRateTerm.value}
                            {interestRateTerm.choice[interestRateTerm.choiceIndex]}
                        </span>
                    </div>
                )}
                {lender.alertActive && (
                    <div>
                        <Badge label={'Update'} />
                    </div>
                )}
            </div>
            <div className={'footer'}>
                <div className={'status'}>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-end',
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: 5 }} className={`status-${lender.status}`}>
                                {statusEnum.getType(lender.status, loanType)}
                            </div>
                            <div style={{ display: 'flex' }}>
                                <CardViewLLEStatusSteps status={lender.status} />
                            </div>
                        </div>
                        {lendersMessageAllowed &&
                        selectedLenders &&
                        selectedLenders.selectionModel.includes(lender.id) ? (
                            <CheckBoxIcon
                                className={'select'}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    const slsm = selectedLenders.selectionModel;
                                    const newSelectedLenders = slsm.filter((lenderId) => lenderId !== lender.id);
                                    handleLenderSelection({
                                        selectionModel: newSelectedLenders,
                                    });

                                    if (newSelectedLenders === dataLength) {
                                        handleSelectAll(true);
                                    } else {
                                        handleSelectAll(false);
                                    }
                                }}
                            />
                        ) : (
                            <CheckBoxOutlineBlankIcon
                                className={'select'}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (null === selectedLenders) {
                                        handleLenderSelection({ selectionModel: [lender.id] });
                                    } else {
                                        const slsm = selectedLenders.selectionModel;
                                        slsm.push(lender.id);
                                        handleLenderSelection({ selectionModel: slsm });

                                        if (slsm.length === dataLength) {
                                            handleSelectAll(true);
                                        } else {
                                            handleSelectAll(false);
                                        }
                                    }
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </Paper>
    );
}
