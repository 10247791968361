import { spawn } from 'redux-saga/effects';

import intermediaryMle from '../mle/sagas';
import intermediaryMleApplicant from '../mle/form_sections/Applicant/sagas';
import intermediaryViewLeMain from '../view_loan_enquiry/sagas';
import intermediaryDeleteLeMain from '../delete_loan_enquiry/sagas';
import intermediaryViewLePollingMatches from '../view_loan_enquiry/sagas/pollMatches';
import intermediaryViewLeModals from '../view_loan_enquiry/sagas/modals';
import intermediaryViewLeadMain from '../view_lender_loan_enquiry/sagas';
import intermediaryDynamicEnum from '../enum/sagas/dynamic_enum';
import matchFavourites from '../view_loan_enquiry/sagas/favourites';
import alerts from '../view_loan_enquiry/sagas/alerts';

function* main() {
    yield spawn(intermediaryMle);
    yield spawn(intermediaryViewLeMain);
    yield spawn(intermediaryDeleteLeMain);
    yield spawn(intermediaryViewLeadMain);
    yield spawn(intermediaryMleApplicant);
    yield spawn(intermediaryDynamicEnum);
    yield spawn(intermediaryViewLePollingMatches);
    yield spawn(intermediaryViewLeModals);
    yield spawn(matchFavourites);
    yield spawn(alerts);
}

export default main;
