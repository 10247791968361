export const REFINANCE_AND_BUILD_COSTS = 'REFINANCE_BUILD_COSTS';
export const BUILD_COSTS = 'BUILD_COST';
export const EQUITY_RAISE_AND_BUILD_COSTS = 'EQUITY_RAISE_BUILD_COSTS';
export const PURCHASE_AND_BUILD_COSTS = 'PURCHASE_BUILD_COSTS';
export const CAPITAL_RAISE_INVESTMENT = 'CAPITAL_RAISE_INVESTMENT';
export const CAPITAL_RAISE_BUSINESS = 'CAPITAL_RAISE_BUSINESS';
export const CAPITAL_RAISE_PERSONAL = 'CAPITAL_RAISE_PERSONAL';
export const PROPERTY_IMPROVEMENTS = 'PROPERTY_IMPROVEMENTS';
export const MIXED_REASONS = 'MIXED_REASONS';
export const REPAY_OUTSTANDING_FINANCES = 'REPAY_OUTSTANDING_FINANCES';
export const REFURB_AND_DEVELOPMENT = 'REFURB_AND_DEVELOPMENT';
export const ACQUISITION = 'ACQUISITION';
export const ASSET_PURCHASE = 'ASSET_PURCHASE';
export const ASSET_REFINANCE = 'ASSET_REFINANCE';
export const CASH_FLOW = 'CASH_FLOW';
export const EXPANSION_GROWTH = 'EXPANSION_GROWTH';
export const MANAGEMENT_BUY_IN_OUT = 'MANAGEMENT_BUY_IN_OUT';
export const STOCK = 'STOCK';
export const REFINANCING = 'REFINANCING';
export const START_UP = 'START_UP';
export const SUPPLY_CHAIN = 'SUPPLY_CHAIN';
export const TAX = 'TAX';
export const WORKING_CAPITAL = 'WORKING_CAPITAL';

class LoanPurposes {
    getTypes = () => {
        return [
            { value: REFINANCE_AND_BUILD_COSTS, label: 'Refinance and build costs' },
            { value: BUILD_COSTS, label: 'Build costs' },
            { value: EQUITY_RAISE_AND_BUILD_COSTS, label: 'Raise equity and build costs' },
            { value: PURCHASE_AND_BUILD_COSTS, label: 'Purchase and build costs' },
            { value: CAPITAL_RAISE_INVESTMENT, label: 'Capital Raise for further investment' },
            { value: CAPITAL_RAISE_BUSINESS, label: 'Capital Raise for business purposes' },
            { value: CAPITAL_RAISE_PERSONAL, label: 'Capital Raise for personal purposes' },
            { value: PROPERTY_IMPROVEMENTS, label: '[bold|Minor] property improvements' },
            { value: MIXED_REASONS, label: 'A mixture of reasons' },
            { value: REPAY_OUTSTANDING_FINANCES, label: 'Repay outstanding finances [bold|only]' },
            { value: REFURB_AND_DEVELOPMENT, label: 'Refurbishment and development' },
        ];
    };

    getMezzanineTypes = () => {
        return [
            { value: REFINANCE_AND_BUILD_COSTS, label: 'Refinance and build costs' },
            { value: BUILD_COSTS, label: 'Build costs' },
            { value: EQUITY_RAISE_AND_BUILD_COSTS, label: 'Raise equity and build costs' },
            { value: PURCHASE_AND_BUILD_COSTS, label: 'Purchase and build costs' },
        ];
    };

    getBridgingTypes = () => {
        return [
            { value: CAPITAL_RAISE_INVESTMENT, label: 'Capital Raise for further investment' },
            { value: CAPITAL_RAISE_BUSINESS, label: 'Capital Raise for business purposes' },
            { value: CAPITAL_RAISE_PERSONAL, label: 'Capital Raise for personal purposes' },
            { value: PROPERTY_IMPROVEMENTS, label: '[bold|Minor] property improvements' },
            { value: MIXED_REASONS, label: 'A mixture of reasons' },
            { value: REPAY_OUTSTANDING_FINANCES, label: 'Repay outstanding finances [bold|only]' },
        ];
    };

    getTermTypes = () => {
        return [
            { value: CAPITAL_RAISE_INVESTMENT, label: 'Capital Raise for further investment' },
            { value: CAPITAL_RAISE_BUSINESS, label: 'Capital Raise for business purposes' },
            { value: CAPITAL_RAISE_PERSONAL, label: 'Capital Raise for personal purposes' },
            { value: PROPERTY_IMPROVEMENTS, label: '[bold|Minor] property improvements' },
            { value: MIXED_REASONS, label: 'A mixture of reasons' },
            { value: REPAY_OUTSTANDING_FINANCES, label: 'Repay outstanding finances [bold|only]' },
        ];
    };

    getSecondChargeTypes = () => {
        return [
            { value: CAPITAL_RAISE_INVESTMENT, label: 'Capital Raise for further investment' },
            { value: CAPITAL_RAISE_BUSINESS, label: 'Capital Raise for business purposes' },
            { value: CAPITAL_RAISE_PERSONAL, label: 'Capital Raise for personal purposes' },
            { value: PROPERTY_IMPROVEMENTS, label: '[bold|Minor] property improvements' },
            { value: MIXED_REASONS, label: 'A mixture of reasons' },
            { value: REPAY_OUTSTANDING_FINANCES, label: 'Repay outstanding finances [bold|only]' },
        ];
    };

    getRefurbishmentTypes = () => {
        return [{ value: REFURB_AND_DEVELOPMENT, label: 'Refurbishment and development' }];
    };

    getDevelopmentTypes = () => {
        return [{ value: REFURB_AND_DEVELOPMENT, label: 'Refurbishment and development' }];
    };

    getOptions = () => {
        return {
            [REFINANCE_AND_BUILD_COSTS]: { value: REFINANCE_AND_BUILD_COSTS, label: 'Refinance and build costs' },
            [BUILD_COSTS]: { value: BUILD_COSTS, label: 'Build costs' },
            [EQUITY_RAISE_AND_BUILD_COSTS]: {
                value: EQUITY_RAISE_AND_BUILD_COSTS,
                label: 'Raise equity and build costs',
            },
            [PURCHASE_AND_BUILD_COSTS]: { value: PURCHASE_AND_BUILD_COSTS, label: 'Purchase and build costs' },
            [CAPITAL_RAISE_INVESTMENT]: {
                value: CAPITAL_RAISE_INVESTMENT,
                label: 'Capital Raise for further investment',
            },
            [CAPITAL_RAISE_BUSINESS]: { value: CAPITAL_RAISE_BUSINESS, label: 'Capital Raise for business purposes' },
            [CAPITAL_RAISE_PERSONAL]: { value: CAPITAL_RAISE_PERSONAL, label: 'Capital Raise for personal purposes' },
            [PROPERTY_IMPROVEMENTS]: { value: PROPERTY_IMPROVEMENTS, label: '[bold|Minor] property improvements' },
            [MIXED_REASONS]: { value: MIXED_REASONS, label: 'A mixture of reasons' },
            [REPAY_OUTSTANDING_FINANCES]: {
                value: REPAY_OUTSTANDING_FINANCES,
                label: 'Repay outstanding finances [bold|only]',
            },
            [REFURB_AND_DEVELOPMENT]: { value: REFURB_AND_DEVELOPMENT, label: 'Refurbishment and development' },
            [REFINANCE_AND_BUILD_COSTS]: { value: REFINANCE_AND_BUILD_COSTS, label: 'Refinance and build costs' },
            [BUILD_COSTS]: { value: BUILD_COSTS, label: 'Build costs' },
            [EQUITY_RAISE_AND_BUILD_COSTS]: {
                value: EQUITY_RAISE_AND_BUILD_COSTS,
                label: 'Raise equity and build costs',
            },
            [PURCHASE_AND_BUILD_COSTS]: { value: PURCHASE_AND_BUILD_COSTS, label: 'Purchase and build costs' },
            [CAPITAL_RAISE_INVESTMENT]: {
                value: CAPITAL_RAISE_INVESTMENT,
                label: 'Capital Raise for further investment',
            },
            [CAPITAL_RAISE_BUSINESS]: { value: CAPITAL_RAISE_BUSINESS, label: 'Capital Raise for business purposes' },
            [CAPITAL_RAISE_PERSONAL]: { value: CAPITAL_RAISE_PERSONAL, label: 'Capital Raise for personal purposes' },
            [PROPERTY_IMPROVEMENTS]: { value: PROPERTY_IMPROVEMENTS, label: '[bold|Minor] property improvements' },
            [MIXED_REASONS]: { value: MIXED_REASONS, label: 'A mixture of reasons' },
            [REPAY_OUTSTANDING_FINANCES]: {
                value: REPAY_OUTSTANDING_FINANCES,
                label: 'Repay outstanding finances [bold|only]',
            },
            [CAPITAL_RAISE_INVESTMENT]: {
                value: CAPITAL_RAISE_INVESTMENT,
                label: 'Capital Raise for further investment',
            },
            [CAPITAL_RAISE_BUSINESS]: { value: CAPITAL_RAISE_BUSINESS, label: 'Capital Raise for business purposes' },
            [CAPITAL_RAISE_PERSONAL]: { value: CAPITAL_RAISE_PERSONAL, label: 'Capital Raise for personal purposes' },
            [PROPERTY_IMPROVEMENTS]: { value: PROPERTY_IMPROVEMENTS, label: '[bold|Minor] property improvements' },
            [MIXED_REASONS]: { value: MIXED_REASONS, label: 'A mixture of reasons' },
            [REPAY_OUTSTANDING_FINANCES]: {
                value: REPAY_OUTSTANDING_FINANCES,
                label: 'Repay outstanding finances [bold|only]',
            },
            [CAPITAL_RAISE_INVESTMENT]: {
                value: CAPITAL_RAISE_INVESTMENT,
                label: 'Capital Raise for further investment',
            },
            [CAPITAL_RAISE_BUSINESS]: { value: CAPITAL_RAISE_BUSINESS, label: 'Capital Raise for business purposes' },
            [CAPITAL_RAISE_PERSONAL]: { value: CAPITAL_RAISE_PERSONAL, label: 'Capital Raise for personal purposes' },
            [PROPERTY_IMPROVEMENTS]: { value: PROPERTY_IMPROVEMENTS, label: '[bold|Minor] property improvements' },
            [MIXED_REASONS]: { value: MIXED_REASONS, label: 'A mixture of reasons' },
            [REPAY_OUTSTANDING_FINANCES]: {
                value: REPAY_OUTSTANDING_FINANCES,
                label: 'Repay outstanding finances [bold|only]',
            },
            [CAPITAL_RAISE_INVESTMENT]: {
                value: CAPITAL_RAISE_INVESTMENT,
                label: 'Capital Raise for further investment',
            },
            [CAPITAL_RAISE_BUSINESS]: { value: CAPITAL_RAISE_BUSINESS, label: 'Capital Raise for business purposes' },
            [CAPITAL_RAISE_PERSONAL]: { value: CAPITAL_RAISE_PERSONAL, label: 'Capital Raise for personal purposes' },
            [PROPERTY_IMPROVEMENTS]: { value: PROPERTY_IMPROVEMENTS, label: '[bold|Minor] property improvements' },
            [MIXED_REASONS]: { value: MIXED_REASONS, label: 'A mixture of reasons' },
            [REPAY_OUTSTANDING_FINANCES]: {
                value: REPAY_OUTSTANDING_FINANCES,
                label: 'Repay outstanding finances [bold|only]',
            },
            [REFURB_AND_DEVELOPMENT]: { value: REFURB_AND_DEVELOPMENT, label: 'Refurbishment and development' },
            [ACQUISITION]: { label: 'Acquisition', value: ACQUISITION },
            [ASSET_PURCHASE]: { label: 'Asset purchase', value: ASSET_PURCHASE },
            [ASSET_REFINANCE]: { label: 'Asset refinance', value: ASSET_REFINANCE },
            [CASH_FLOW]: { label: 'Cash flow', value: CASH_FLOW },
            [EXPANSION_GROWTH]: { label: 'Expansion/Growth', value: EXPANSION_GROWTH },
            [MANAGEMENT_BUY_IN_OUT]: { label: 'Management buy-in / management buy-out', value: MANAGEMENT_BUY_IN_OUT },
            [STOCK]: { label: 'Purchasing stock', value: STOCK },
            [REFINANCING]: { label: 'Refinancing', value: REFINANCING },
            [START_UP]: { label: 'Start up funding', value: START_UP },
            [SUPPLY_CHAIN]: { label: 'Supply Chain', value: SUPPLY_CHAIN },
            [TAX]: { label: 'Tax Payments', value: TAX },
            [WORKING_CAPITAL]: { label: 'Working Capital', value: WORKING_CAPITAL },
        };
    };
}

export default LoanPurposes;
