import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const getLenderLoanEnquiryQuery = `
    query getLenderLoanEnquiry($lenderLoanEnquiry: ID!) {
        lenderLoanEnquiry(id: $lenderLoanEnquiry) {
            id
            status
            lenderReference
            lenderTerms
            channel {
                id
            }
            lender {
                id
                name
                profileDescription
                profileUrl
                profileLogo
                primaryEmail
                companyNumber
                offPanel
            }
            alertActive
            lenderTermsUpdatedAlertActive
            loanEnquiry {
                id
                status
            }
            createdAt
            updatedAt
            matchedAt
        }
    }
`;

export const getLenderLoanEnquiry = (lenderLoanEnquiryId) => {
    return apolloFetch({
        query: getLenderLoanEnquiryQuery,
        variables: {
            lenderLoanEnquiry: lenderLoanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
