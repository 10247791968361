import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { P4StyleErrorContainer } from 'pitch4_layout/components/div';
import { goBack, push } from 'react-router-redux';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../apps/notifications/sagas/container';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Loading } from 'pitch4_layout/components/waiting';

class NoMutationQuery extends React.Component {
    render() {
        let {
            //query props
            query,
            queryContext = {},
            queryVariables = {},
            mapQueryFunction,
            renderFunction,
            pollingInterval = 0,
        } = this.props;

        return (
            <Query
                fetchPolicy={'no-cache'}
                context={queryContext}
                query={query}
                variables={queryVariables}
                pollInterval={pollingInterval}
            >
                {({ errors, error, data, loading, refetch, ...stuff }) => {
                    if (loading) return <Loading />;
                    if (error) return <P4StyleErrorContainer />;

                    let mappedValues = mapQueryFunction(data);

                    return renderFunction({ mappedValues, errors, error, data, loading, refetch, ...stuff });
                }}
            </Query>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

NoMutationQuery.propTypes = {
    query: PropTypes.any.isRequired,
    queryContext: PropTypes.object,
    queryVariables: PropTypes.object,
    mapQueryFunction: PropTypes.func.isRequired,
    renderFunction: PropTypes.func.isRequired,
    pollingInterval: PropTypes.number,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NoMutationQuery);
