import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from '../../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { API_URL } from '../../../../../../bootstrap/constants/constants';
import { BRAND_MAIN_CONTACT } from '../../../../../../bootstrap/constants/constants';
import { ROLE_SUPER_ADMIN } from '../../../../../authentication/enum/Roles/NameEnum';
import { SubmissionError } from 'redux-form';
import { P4StyleComponentContainer, Container, RowItem } from '../../../../../../pitch4_layout/components/div';
import { QueryMutation } from '../../../../../../pitch4_forms/components';
import Mapper from '../../shared/form/Mapper';
import UserForm from '../../shared/form/UserForm';
import RoleUtil from '../../../../../../pitchblack_react_utils/session/RoleUtil';
import ChangeUserPasswordContainer from '../../change_password/containers/ChangeUserPasswordContainer';

class EditContainer extends React.Component {
    mapValues = (data) => {
        let mapper = new Mapper();

        let mappedValues = {};

        if (data && data.user) {
            let user = { ...data.user };
            let roleUtil = new RoleUtil();

            user.primaryRole = { primaryRole: { name: '' }, ...roleUtil.getMostSeniorRole(data.user.roles) };
            user.lender = data.user.primaryLender ? data.user.primaryLender.lender.id : '';
            user.broker = data.user.broker ? data.user.broker.id : '';

            mappedValues = mapper.mapToForm(user);
        }

        return mappedValues;
    };

    render() {
        let { match } = this.props;

        return (
            <Container className={`account-settings`}>
                <RowItem className={`account-settings-item`}>
                    <P4StyleComponentContainer
                        header={`Edit User`}
                        content={
                            <QueryMutation
                                query={gql`
                                    query getUser($id: ID!) {
                                        user(id: $id) {
                                            id
                                            displayNameTemplates
                                            username
                                            email
                                            createdAt
                                            updatedAt
                                            status
                                            userProfile {
                                                id
                                                title
                                                given
                                                family
                                                createdAt
                                                updatedAt
                                                dateOfBirth
                                                primaryAddress {
                                                    address1
                                                    address2
                                                    address3
                                                    address4
                                                    country
                                                    postcode
                                                }
                                                primaryPhone {
                                                    number
                                                }
                                                jobTitle
                                            }
                                            roles {
                                                name
                                            }
                                            primaryLender {
                                                lender {
                                                    id
                                                }
                                            }
                                            broker {
                                                id
                                            }
                                        }
                                    }
                                `}
                                queryContext={{
                                    uri: API_URL,
                                }}
                                queryVariables={{
                                    id: match.params.id,
                                }}
                                mutationQuery={gql`
                                    mutation EditUser($id: ID!, $input: pitch4admin_editUserInput!) {
                                        editUser(id: $id, input: $input) {
                                            user {
                                                id
                                                displayNameTemplates
                                                username
                                                email
                                                createdAt
                                                updatedAt
                                                status
                                                userProfile {
                                                    id
                                                    title
                                                    given
                                                    family
                                                    createdAt
                                                    updatedAt
                                                    dateOfBirth
                                                    primaryAddress {
                                                        address1
                                                        address2
                                                        address3
                                                        address4
                                                        country
                                                        postcode
                                                    }
                                                    primaryPhone {
                                                        number
                                                    }
                                                    jobTitle
                                                }
                                                roles {
                                                    name
                                                }
                                                primaryLender {
                                                    lender {
                                                        id
                                                    }
                                                }
                                                broker {
                                                    id
                                                }
                                            }
                                        }
                                    }
                                `}
                                renderFormFunction={(mappedValues, mutate, refetch, props) => {
                                    return (
                                        <UserForm
                                            initialValues={mappedValues}
                                            onSubmit={this.onSubmit(mutate, refetch, props)}
                                            disableRole={true}
                                        />
                                    );
                                }}
                                mapQueryFunction={this.mapValues}
                            />
                        }
                    />
                </RowItem>
                <RowItem className={`account-settings-item`}>
                    <P4StyleComponentContainer header={`Reset Password`} content={<ChangeUserPasswordContainer />} />
                </RowItem>
            </Container>
        );
    }

    onSubmit = (mutate, refetch, props) => (values, dispatch, formProps) => {
        let { notificationSuccess, notificationError } = props;
        let mapper = new Mapper();

        let mappedValues = mapper.mapFromEditForm(values);

        //map the variables if necessary
        return mutate({
            variables: {
                id: values.id,
                input: mappedValues,
            },
            context: {
                uri: API_URL,
            },
        })
            .then(({ data }) => {
                refetch();
                notificationSuccess('User updated successfully');
            })
            .catch((error) => {
                notificationError('Unable to save user data. Contact support on ' + BRAND_MAIN_CONTACT);
                throw new SubmissionError({ _error: error.graphQLErrors });
            });
    };
}

export default compose(withRouter, renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN]))(EditContainer);
