import React from 'react';
import { Container, Item, RowItem } from 'pitch4_layout/components/div';
import PropTypes from 'prop-types';

class FieldButtonPanelRow extends React.Component {
    render() {
        let { children, justify = `flex-end` } = this.props;

        return (
            <RowItem className={`field-button-panel-row`}>
                <Container justifyContent={justify}>
                    {Array.isArray(children) ? (
                        children.map((item, index) => (
                            <Item key={index}>
                                <span className={`field-button-panel-row-item`}>{item}</span>
                            </Item>
                        ))
                    ) : (
                        <Item key={0}>{children}</Item>
                    )}
                </Container>
            </RowItem>
        );
    }
}

FieldButtonPanelRow.propTypes = {
    children: PropTypes.any,
};

export default FieldButtonPanelRow;
