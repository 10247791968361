import React from 'react';
import { Route, Switch } from 'react-router';

import AuthenticationLayout from './layouts/AuthenticationLayout';
import VerifyEmailContainer from '../apps/verify_email/containers/VerifyEmailContainer';
import NotificationsContainer from '../apps/notifications/containers/Container';
import { ROUTE_VERIFY_EMAIL } from '../pitch4_routing';

class VerifyEmailPage extends React.Component {
    render() {
        return (
            <AuthenticationLayout notifications={<NotificationsContainer />}>
                <Switch>
                    <Route path={ROUTE_VERIFY_EMAIL} component={VerifyEmailContainer} />
                </Switch>
            </AuthenticationLayout>
        );
    }
}

export default VerifyEmailPage;
