import { USER_URL } from 'bootstrap/constants/constants';
import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: USER_URL });

const logoutGql = `
mutation logout ($token: String!){
  logout(token: $token) {
    message
  }
}
`;
//response classes
const errorFactory = new ValidationResultFactory();

class Logout {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class LogoutErrors extends Logout {
    constructor(errors) {
        super('LogoutErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

export const logout = async (token) => {
    return apolloFetch({
        query: logoutGql,
        variables: {
            token,
        },
    }).catch((error) => {
        let vR = errorFactory.createValidationResults();

        if (error && error.graphQLErrors) {
            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });
        }

        throw new LogoutErrors(vR);
    });
};
