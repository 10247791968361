// see https://github.com/erikras/ducks-modular-redux
import { EditMapper } from 'pitch4_documents';

export const NAMESPACE = 'borrower.documents_edit';

// Actions
export const BORROWER_DOCUMENTS_ADD_HIDE_VALIDATION_ERRORS = `${NAMESPACE}.hide_server_side_errors`;
export const BORROWER_DOCUMENTS_ADD_SHOW_VALIDATION_ERRORS = `${NAMESPACE}.show_server_side_errors`;
export const BORROWER_DOCUMENTS_ADD_SET_VALIDATION_ERRORS = `${NAMESPACE}.set_validation_errors`;
export const BORROWER_DOCUMENTS_ADD_SET_INITIAL_VALUES = `${NAMESPACE}.set_initial_values`;

const mapper = new EditMapper();

//Reducer
const initialState = {
    validationErrors: [],
    visible: true,
    initialValues: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case BORROWER_DOCUMENTS_ADD_HIDE_VALIDATION_ERRORS:
            return {
                ...state,
                visible: false,
            };

        case BORROWER_DOCUMENTS_ADD_SHOW_VALIDATION_ERRORS:
            return {
                ...state,
                visible: true,
            };

        case BORROWER_DOCUMENTS_ADD_SET_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: action.validationErrors,
                visible: true,
            };

        case BORROWER_DOCUMENTS_ADD_SET_INITIAL_VALUES:
            return {
                ...state,
                initialValues: action.initialValues,
            };

        default:
            return { ...state };
    }
};

//Default export reducer
export default reducer;

//Action Creators
export const actionHideValidationErrors = () => {
    return {
        type: BORROWER_DOCUMENTS_ADD_HIDE_VALIDATION_ERRORS,
    };
};

export const actionShowValidationErrors = () => {
    return {
        type: BORROWER_DOCUMENTS_ADD_SHOW_VALIDATION_ERRORS,
    };
};

export const actionSetValidationErrors = (errors) => {
    return {
        type: BORROWER_DOCUMENTS_ADD_SET_VALIDATION_ERRORS,
        validationErrors: errors,
    };
};

export const actionSetInitialValues = (document) => {
    let initialValues = mapper.mapToForm(document);

    return {
        type: BORROWER_DOCUMENTS_ADD_SET_INITIAL_VALUES,
        initialValues,
    };
};

//Selectors
export const selectorGetValidationErrors = (state) => {
    return state[NAMESPACE].validationErrors;
};

export const selectorAreErrorsVisible = (state) => {
    return state[NAMESPACE].visible;
};

export const selectorGetId = (state) => {
    return state[NAMESPACE].id;
};

export const selectorGetInitialValues = (state) => {
    return state[NAMESPACE].initialValues;
};

export const selectorGetTypes = (state) => {
    return state[NAMESPACE].types;
};
