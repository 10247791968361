import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const LoanEnquirySetStatusesMutation = `
  mutation setLoanEnquiryStatus($id: ID!, $input: pitch4admin_setLoanEnquiryStatusInput!){
    setLoanEnquiryStatus(id: $id, input: $input){
      loanEnquiry {
        id
        status
      }
    }
  } 
`;

export const setLoanEnquiryStatus = (loanEnquiryId, newStatus, reason) => {
    return apolloFetch({
        query: LoanEnquirySetStatusesMutation,
        variables: {
            id: loanEnquiryId,
            input: {
                newStatus: newStatus,
                reason: reason,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
