import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const TickIcon = (props) => {
    return (
        <SvgIcon {...props} className={`tick-icon ${props.className || ''}`}>
            <path d="M10.5 19.7761L2.625 11.9403L0 14.5522L10.5 25L33 2.61194L30.375 0L10.5 19.7761Z" fill="white" />
        </SvgIcon>
    );
};

export default TickIcon;
