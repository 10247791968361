import React from 'react';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

export default function ContractSigned(props) {
    const { contractSigned = false, contractInProgress = true, handleLegalContractDownload = null } = props;

    return (
        <div className={'legal-contract'}>
            {false === contractSigned ? (
                <div className={'unsigned'}>Please sign your contract</div>
            ) : true === contractInProgress ? (
                <div className={'unsigned'}>We are generating your contract</div>
            ) : (
                <>
                    <CheckCircleRoundedIcon className={'icon'} />
                    <div>
                        <div className={'title'}>Signed</div>
                        <div
                            className={'link'}
                            onClick={() =>
                                null !== handleLegalContractDownload ? handleLegalContractDownload() : null
                            }
                        >
                            Click here to download
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
