import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const mutation = `
    mutation massDeclineLender($input: MassDeclineLender) {
      massDeclineLender(input: $input) {
        success
      }
    }
`;

export const massDeclineLender = (lenders) => {
    return apolloFetch({
        query: mutation,
        variables: {
            input: {
                lenderLoanEnquiries: lenders,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
