import React, { useState } from 'react';
import finalLoanAmountImage from '../../assets/images/dashboard-docs.svg';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Button } from '@material-ui/core';
import { setLECompleted } from '../../pitch4_api/set_le_completed';
import moment from 'moment';
import StatusEnum from '../../pitch4_enum/enum/LenderLoanEnquiryStatusEnum.js';

export default function CompleteModal(props) {
    const { setModalClose, lenders, amount, url, loanEnquiryId } = props;
    const [selectedLender, setSelectedLender] = useState('select-lender');
    const [finalAmount, setFinalAmount] = useState(amount);
    const handleChange = (event) => {
        setSelectedLender(event.target.value);
    };
    const handleSubmit = () => {
        setLECompleted(url, loanEnquiryId, selectedLender, finalAmount, moment().format('YYYY-MM-DD')).then(() => {
            setModalClose();
            window.location.reload();
        });
    };
    const statusEnum = new StatusEnum();

    return (
        <div className={'complete-modal-container'}>
            <div className={'panel-left'}>
                <div>
                    <img className="info-pane-image" src={finalLoanAmountImage} alt="Final Loan Amount" />
                </div>
                <div className={'header'}>Are you sure?</div>
                <div className={'text-1'}>
                    Marking a Lead as Completed cannot be undone. Please confirm this loan is completed.
                </div>
                <div className={'text-2'}>
                    Need to understand what counts as
                    <br />
                    complete?{' '}
                    <a
                        className="typography-default-link"
                        href="https://help.providefinance.com/knowledge/what-is-the-completion-process"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Click here.
                    </a>
                </div>
            </div>
            <div className={'panel-right'}>
                <div>
                    <div className={'header'}>
                        Please enter and confirm final
                        <br />
                        Net Loan amount & Lender
                    </div>
                    <div className={'loan-amount-input'}>
                        <TextField
                            id="loan-amount"
                            variant="outlined"
                            type={'number'}
                            inputProps={{ min: 0, step: '0.01' }}
                            placeholder={'£'}
                            value={finalAmount}
                            onChange={(event) => setFinalAmount(event.target.value)}
                        />
                    </div>
                    <div className={'lender-select'}>
                        <FormControl variant="outlined">
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={selectedLender}
                                onChange={handleChange}
                            >
                                <MenuItem value="select-lender">
                                    <em>Select Lender</em>
                                </MenuItem>
                                {lenders
                                    .filter((lender) => statusEnum.getCompletionAllowedTypes().includes(lender.status))
                                    .map((lender, index) => (
                                        <MenuItem key={index} value={lender.id}>
                                            {lender.lender.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className={'actions'}>
                    <Button onClick={() => setModalClose()}>CANCEL</Button>
                    <Button
                        onClick={() => handleSubmit()}
                        variant={'contained'}
                        color={'primary'}
                        disabled={'select-lender' === selectedLender || finalAmount === ''}
                    >
                        CONFIRM
                    </Button>
                </div>
            </div>
        </div>
    );
}
