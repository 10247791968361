import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { CommercialTypeEnum } from '../../../../admin/enum/matching/enum';
import {
    QUESTION_COMMERCIAL_OWNER_OCCUPIED,
    QUESTION_COMMERCIAL_TYPE_OTHER,
    QUESTION_COMMERCIAL_TYPES,
    QUESTION_COMMERCIAL_TYPES_REFURBISHMENT,
    QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT,
    QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
    QUESTION_DAY_ONE_LOAN_AMOUNT,
    QUESTION_EXPECTED_END_VALUE,
    QUESTION_HMO_BEDROOMS,
    QUESTION_HMO_HAS_LICENSE,
    QUESTION_HMO_LICENSE_IS_REQUIRED,
    QUESTION_LOAN_AMOUNT_REFURBISHMENT,
    QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT,
    QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT,
    QUESTION_PLANNING_IN_PLACE,
    QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE,
    QUESTION_PROPERTY_TYPE,
    QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
    QUESTION_REFURBISHMENT_LENGTH,
    QUESTION_SITE_DESCRIPTION,
    QUESTION_SITE_INTENTIONS_REFURBISHMENT,
} from '../../../enums/Borrower/BorrowerQuestions';
import YesNo from '../../../enums/Shared/YesNo';
import Question from '../../Question/containers/Question';
import {
    COMMERCIAL_FUNDING_TYPE,
    HMO_FUNDING_TYPE,
    SEMI_COMMERCIAL_FUNDING_TYPE,
} from '../../../enums/Shared/CommercialFundingType';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import BorrowerQuestions from '../../../enums/Borrower/BorrowerQuestions';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';
import { OTHER } from '../../../enums/Shared/PropertyLocation';

class RefurbishmentSelectionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        let commercialTypeOptions = new CommercialTypeEnum().getOptions();
        const yesNoOptions = new YesNo().getTypes();
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER
                            ? 'About your Refurbishment Finance'
                            : 'About your clients Refurbishment Finance'}
                    </Typography>
                </Grid>
                <Question
                    type={questions[QUESTION_PLANNING_IN_PLACE].type}
                    label={questions[QUESTION_PLANNING_IN_PLACE].label}
                    questionEnum={'QUESTION_PLANNING_IN_PLACE'}
                    fieldName={questions[QUESTION_PLANNING_IN_PLACE].fieldName}
                    placeholder={questions[QUESTION_PLANNING_IN_PLACE].placeholder}
                    validation={questions[QUESTION_PLANNING_IN_PLACE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_DAY_ONE_LOAN_AMOUNT].type}
                    label={questions[QUESTION_DAY_ONE_LOAN_AMOUNT].label}
                    questionEnum={'QUESTION_DAY_ONE_LOAN_AMOUNT'}
                    fieldName={questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName}
                    placeholder={questions[QUESTION_DAY_ONE_LOAN_AMOUNT].placeholder}
                    validation={questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].type}
                    label={questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].label}
                    questionEnum={'QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY'}
                    fieldName={questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName}
                    placeholder={questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].placeholder}
                    validation={questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].type}
                    label={questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].label}
                    questionEnum={'QUESTION_LOAN_AMOUNT_REFURBISHMENT'}
                    fieldName={questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].fieldName}
                    placeholder={questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].placeholder}
                    validation={questions[QUESTION_LOAN_AMOUNT_REFURBISHMENT].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_REFURBISHMENT_LENGTH].type}
                    label={questions[QUESTION_REFURBISHMENT_LENGTH].label}
                    questionEnum={'QUESTION_REFURBISHMENT_LENGTH'}
                    fieldName={questions[QUESTION_REFURBISHMENT_LENGTH].fieldName}
                    placeholder={questions[QUESTION_REFURBISHMENT_LENGTH].placeholder}
                    validation={questions[QUESTION_REFURBISHMENT_LENGTH].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_SITE_DESCRIPTION].type}
                    label={questions[QUESTION_SITE_DESCRIPTION].label}
                    questionEnum={'QUESTION_SITE_DESCRIPTION'}
                    fieldName={questions[QUESTION_SITE_DESCRIPTION].fieldName}
                    placeholder={questions[QUESTION_SITE_DESCRIPTION].placeholder}
                    validation={questions[QUESTION_SITE_DESCRIPTION].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={questions[QUESTION_SITE_DESCRIPTION].rows}
                />
                <Question
                    type={questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].type}
                    label={questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].label}
                    questionEnum={'QUESTION_SITE_INTENTIONS_REFURBISHMENT'}
                    fieldName={questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].fieldName}
                    placeholder={questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].placeholder}
                    validation={questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={questions[QUESTION_SITE_INTENTIONS_REFURBISHMENT].rows}
                />
                <Question
                    type={questions[QUESTION_EXPECTED_END_VALUE].type}
                    label={questions[QUESTION_EXPECTED_END_VALUE].label}
                    questionEnum={'QUESTION_EXPECTED_END_VALUE'}
                    fieldName={questions[QUESTION_EXPECTED_END_VALUE].fieldName}
                    placeholder={questions[QUESTION_EXPECTED_END_VALUE].placeholder}
                    validation={questions[QUESTION_EXPECTED_END_VALUE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].type}
                    label={questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].label}
                    questionEnum={'QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE'}
                    fieldName={questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].fieldName}
                    placeholder={questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].placeholder}
                    validation={questions[QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />

                {this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === HMO_FUNDING_TYPE && (
                    <>
                        <Question
                            type={questions[QUESTION_HMO_BEDROOMS].type}
                            label={questions[QUESTION_HMO_BEDROOMS].label}
                            questionEnum={'QUESTION_HMO_BEDROOMS'}
                            fieldName={questions[QUESTION_HMO_BEDROOMS].fieldName}
                            placeholder={questions[QUESTION_HMO_BEDROOMS].placeholder}
                            validation={questions[QUESTION_HMO_BEDROOMS].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_HMO_HAS_LICENSE].type}
                            label={questions[QUESTION_HMO_HAS_LICENSE].label}
                            questionEnum={'QUESTION_HMO_HAS_LICENSE'}
                            fieldName={questions[QUESTION_HMO_HAS_LICENSE].fieldName}
                            placeholder={questions[QUESTION_HMO_HAS_LICENSE].placeholder}
                            validation={questions[QUESTION_HMO_HAS_LICENSE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_HMO_LICENSE_IS_REQUIRED].type}
                            label={questions[QUESTION_HMO_LICENSE_IS_REQUIRED].label}
                            questionEnum={'QUESTION_HMO_LICENSE_IS_REQUIRED'}
                            fieldName={questions[QUESTION_HMO_LICENSE_IS_REQUIRED].fieldName}
                            placeholder={questions[QUESTION_HMO_LICENSE_IS_REQUIRED].placeholder}
                            validation={questions[QUESTION_HMO_LICENSE_IS_REQUIRED].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    </>
                )}

                {this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE && (
                    <>
                        <Question
                            type={questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT].type}
                            label={questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT].label}
                            questionEnum={'QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT'}
                            fieldName={questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT].fieldName}
                            placeholder={questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT].placeholder}
                            validation={
                                questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT].fieldName + '_error'
                            }
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT].type}
                            label={questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT].label}
                            questionEnum={'QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT'}
                            fieldName={questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT].fieldName}
                            placeholder={questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT].placeholder}
                            validation={
                                questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT].fieldName + '_error'
                            }
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    </>
                )}

                {this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE ||
                this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE ? (
                    <>
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_COMMERCIAL_TYPES_REFURBISHMENT].type}
                            label={questions[QUESTION_COMMERCIAL_TYPES_REFURBISHMENT].label}
                            questionEnum={'QUESTION_COMMERCIAL_TYPES'}
                            fieldName={questions[QUESTION_COMMERCIAL_TYPES_REFURBISHMENT].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_TYPES_REFURBISHMENT].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_TYPES_REFURBISHMENT].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={commercialTypeOptions}
                            rows={0}
                        />
                        {this.state.formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) && (
                            <Question
                                formData={this.state.formData}
                                formValidation={this.state.validation}
                                type={questions[QUESTION_COMMERCIAL_TYPE_OTHER].type}
                                label={questions[QUESTION_COMMERCIAL_TYPE_OTHER].label}
                                questionEnum={'QUESTION_COMMERCIAL_TYPE_OTHER'}
                                fieldName={questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName}
                                placeholder={questions[QUESTION_COMMERCIAL_TYPE_OTHER].placeholder}
                                validation={questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'}
                                onChangeFunction={this.props.onChangeHandler}
                                fieldOptions={[]}
                                rows={0}
                            />
                        )}
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].type}
                            label={questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].label}
                            questionEnum={'QUESTION_COMMERCIAL_UNITS_RENTED_OUT'}
                            fieldName={questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            rows={0}
                        />
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].type}
                            label={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].label}
                            questionEnum={'QUESTION_COMMERCIAL_OWNER_OCCUPIED'}
                            fieldName={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            rows={0}
                        />
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT].type}
                            label={questions[QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT].label}
                            questionEnum={'QUESTION_COMMERCIAL_UNIT_COUNT'}
                            fieldName={questions[QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            rows={0}
                        />
                    </>
                ) : (
                    ''
                )}
            </Grid>
        );
    }
}

export default RefurbishmentSelectionContainer;
