import React from 'react';

class PrimaryColour extends React.Component {
    render() {
        let { children, ...other } = this.props;

        return (
            <span className={`primary-colour`} {...other}>
                {children}
            </span>
        );
    }
}

export default PrimaryColour;
