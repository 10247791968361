import React, { useState } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import { CenteringContainer, Container, Item, RowItem } from '../../../pitchblack_react_utils/layout/index';
import {
    isEmail,
    isRequired,
    isDateTime,
    hasMaxLength,
    hasMinLength,
    isValidPassword,
    isInDateRange,
    hasMinLength8,
    hasMaxLength255,
    isMobilePhoneNumber,
} from '../../../pitchblack_react_utils/form/validation';
import { normalizeEmail } from '../../../pitchblack_react_utils/form/Normalize';
import { TextField, DateField } from '../../../pitch4_forms/components/inputs';
import { RightAligned } from '../../../pitch4_layout/components/div';
import FormMessage, { MESSAGE_ERROR } from '../../../pitch4_forms/components/FormMessage';
import { Checkbox } from '../../../pitch4_forms/components/inputs';
import { connect } from 'react-redux';

export const FormFields = ({
    disabled,
    stepHeading,
    firstFieldAutoFocus,
    smsRequested,
    setSmsRequested,
    setTCAndPPAgreement,
    setCommsAgreement,
    tcAndPPAgreement,
    commsAgreement,
}) => {
    const emailValidate = [isRequired, isEmail];
    const nameValidate = [isRequired, hasMinLength(2), hasMaxLength(255)];
    const passwordValidate = [isRequired, isValidPassword, hasMaxLength(255)];
    const dobValidate = [
        isRequired,
        isDateTime,
        isInDateRange(
            -100,
            'years',
            'The maximum age allowed for an applicant is 99',
            -18,
            'years',
            'The minimum age allowed for an applicant is 18'
        ),
    ];
    const phoneValidate =
        true === smsRequested
            ? [isRequired, isMobilePhoneNumber, hasMinLength8, hasMaxLength255]
            : [isMobilePhoneNumber, hasMinLength8, hasMaxLength255];

    return (
        <>
            <h3 className={'registration-form__step-heading'}>
                <span className={'registration-form__step-heading--highlight'}>Step 1 of 2.</span> {stepHeading}
            </h3>
            <RowItem>
                <CenteringContainer alignItems={'flex-start'}>
                    <Item lg={6} md={6} sm={12} xs={12} className={'item-left'}>
                        <TextField
                            name="given"
                            autoComplete={`given-name`}
                            placeholder="First Name"
                            variant={`outlined`}
                            disabled={disabled}
                            InputProps={{}}
                            validate={nameValidate}
                            autoFocus={firstFieldAutoFocus}
                        />
                    </Item>
                    <Item lg={6} md={6} sm={12} xs={12}>
                        <TextField
                            name="family"
                            autoComplete={`family-name`}
                            placeholder="Surname"
                            variant={`outlined`}
                            disabled={disabled}
                            InputProps={{}}
                            validate={nameValidate}
                        />
                    </Item>
                </CenteringContainer>
            </RowItem>
            <RowItem>
                <CenteringContainer>
                    <Item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            name="email"
                            autoComplete={`email`}
                            placeholder="Email address"
                            variant={`outlined`}
                            disabled={disabled}
                            InputProps={{}}
                            validate={emailValidate}
                            normalize={normalizeEmail}
                        />
                    </Item>
                </CenteringContainer>
            </RowItem>
            <RowItem>
                <CenteringContainer alignItems={'flex-start'}>
                    <Item lg={6} md={6} sm={12} xs={12} className={'item-left'}>
                        <TextField
                            name="phoneNumber"
                            placeholder="Mobile Phone Number"
                            variant={`outlined`}
                            disabled={disabled}
                            InputProps={{}}
                            validate={phoneValidate}
                        />
                    </Item>
                    <Item lg={6} md={6} sm={12} xs={12}>
                        <DateField
                            name="dateOfBirth"
                            placeholder="DOB [dd/mm/yyyy]"
                            disabled={disabled}
                            validate={dobValidate}
                            disableFuture={true}
                            autoOk={true}
                        />
                    </Item>
                </CenteringContainer>
            </RowItem>
            <RowItem>
                <CenteringContainer>
                    <Item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            variant={`outlined`}
                            InputProps={{}}
                            name="password"
                            type="password"
                            placeholder="Password"
                            disabled={disabled}
                            validate={passwordValidate}
                        />
                    </Item>
                </CenteringContainer>
            </RowItem>
            <RowItem className={'sms-verification-container'}>
                <Item lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                        name="sms_verification"
                        label={<span>Would you like to verify by SMS?</span>}
                        onChangeFunc={(event) => {
                            setSmsRequested(event.target.value !== '1');
                        }}
                        defaultChecked={true}
                    />
                </Item>
            </RowItem>
            <RowItem className={'sms-verification-container'}>
                <Item lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                        name="tcPpAgreement"
                        label={
                            <span>
                                I agree to the{' '}
                                <a
                                    href={'https://providefinance.com/borrowers/terms-of-business/'}
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    Terms and Conditions
                                </a>{' '}
                                and acknowledge the{' '}
                                <a
                                    href={'https://providefinance.com/privacy-policy'}
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    Provide Finance Privacy Policy
                                </a>
                            </span>
                        }
                        onChangeFunc={(event) => {
                            setTCAndPPAgreement(event.target.value !== '1');
                        }}
                        defaultChecked={tcAndPPAgreement}
                    />
                </Item>
            </RowItem>
            <RowItem className={'sms-verification-container'}>
                <Item lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                        name="commsAgreement"
                        label={
                            <span>
                                I’m happy to receive communications regarding the latest news, insights, products and
                                services from Provide Finance.
                            </span>
                        }
                        onChangeFunc={(event) => {
                            setCommsAgreement(event.target.value !== '1');
                        }}
                        defaultChecked={commsAgreement}
                    />
                </Item>
            </RowItem>
        </>
    );
};

let BorrowerDefaultRegistrationForm = ({
    errors = [],
    errorsVisible,
    handleSubmit,
    submitted,
    stepHeading,
    firstFieldAutoFocus,
}) => {
    const [smsRequested, setSmsRequested] = useState(true);
    const [tcAndPPAgreement, setTCAndPPAgreement] = useState(false);
    const [commsAgreement, setCommsAgreement] = useState(false);

    return (
        <Item className={'content-container'}>
            <Container className={'registration-form'}>
                {errorsVisible &&
                    errors.map((error) => <FormMessage type={MESSAGE_ERROR} messages={error} key={error} />)}
                <CenteringContainer>
                    <form onSubmit={handleSubmit} className={'registration-form__form'}>
                        <FormFields
                            disabled={submitted}
                            stepHeading={stepHeading}
                            firstFieldAutoFocus={firstFieldAutoFocus}
                            smsRequested={smsRequested}
                            setSmsRequested={setSmsRequested}
                            setTCAndPPAgreement={setTCAndPPAgreement}
                            tcAndPPAgreement={tcAndPPAgreement}
                            setCommsAgreement={setCommsAgreement}
                            commsAgreement={commsAgreement}
                        />
                        <RowItem>
                            <h4 style={{ marginBottom: 0 }}>
                                Financing Is Available For UK-Based Projects & Businesses Only
                            </h4>
                        </RowItem>
                        <RowItem>
                            <RightAligned className={'button-panel'}>
                                <PrimaryButton
                                    type="submit"
                                    name={'next-btn'}
                                    disabled={submitted || !tcAndPPAgreement}
                                >
                                    Next
                                </PrimaryButton>
                            </RightAligned>
                        </RowItem>
                    </form>
                </CenteringContainer>
            </Container>
        </Item>
    );
};

BorrowerDefaultRegistrationForm.defaultProps = {
    firstFieldAutoFocus: true,
    errors: [],
};

const mapStateToProps = (state) => {
    return {
        initialValues: {
            phoneNumber: '+44',
        },
    };
};

BorrowerDefaultRegistrationForm = reduxForm({
    form: 'registration',
    asyncBlurFields: [],
})(BorrowerDefaultRegistrationForm);

BorrowerDefaultRegistrationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.array,
    errorsVisible: PropTypes.bool.isRequired,
    submitted: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    stepHeading: PropTypes.string.isRequired,
};

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'registration',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
)(BorrowerDefaultRegistrationForm);
