import React from 'react';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { getNetworkLoginCookie } from '../../../pitch4_utils/LoginCookie';

class NavLogo extends React.Component {
    render() {
        const { redirect } = this.props;
        const networkBroker = getNetworkLoginCookie();

        return (
            <div className={`logo ${null !== networkBroker ? 'top-nav-network-' + networkBroker : ''}`}>
                <span
                    className="hyperlink"
                    onClick={(e) => {
                        e.preventDefault();
                        redirect('/');
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (page) => {
            return dispatch(push(page));
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NavLogo);
