import { BaseEnum } from 'pitch4_enum';
import moment from 'moment/moment';
import { NO, YES } from '../Shared/YesNo';
export const QUESTION_ADDITIONAL_INFO = 'QUESTION_ADDITIONAL_INFO';
export const QUESTION_RENTAL_INCOME_RESIDENTIAL = 'QUESTION_RENTAL_INCOME_RESIDENTIAL';
export const QUESTION_RENTAL_INCOME_COMMERCIAL = 'QUESTION_RENTAL_INCOME_COMMERCIAL';
export const QUESTION_RENTAL_INCOME_PORTFOLIO = 'QUESTION_RENTAL_INCOME_PORTFOLIO';
export const QUESTION_RENTAL_INCOME = 'QUESTION_RENTAL_INCOME';
export const QUESTION_HMO_BEDROOMS = 'QUESTION_HMO_BEDROOMS';
export const QUESTION_HMO_ROOMS_SELF_CONTAINED = 'QUESTION_HMO_ROOMS_SELF_CONTAINED';
export const QUESTION_HMO_HAS_LICENSE = 'QUESTION_HMO_HAS_LICENSE';
export const QUESTION_HMO_LICENSE_IS_REQUIRED = 'QUESTION_HMO_LICENSE_IS_REQUIRED';
export const QUESTION_BUY_TO_LET_EXPERIENCE = 'QUESTION_BUY_TO_LET_EXPERIENCE';
export const QUESTION_COMMERCIAL_UNITS_RENTED_OUT = 'QUESTION_COMMERCIAL_UNITS_RENTED_OUT';
export const QUESTION_COMMERCIAL_OWNER_OCCUPIED = 'QUESTION_COMMERCIAL_OWNER_OCCUPIED';
export const QUESTION_COMMERCIAL_TYPES = 'QUESTION_COMMERCIAL_TYPES';
export const QUESTION_COMMERCIAL_TYPES_REFURBISHMENT = 'QUESTION_COMMERCIAL_TYPES_REFURBISHMENT';
export const QUESTION_COMMERCIAL_TYPE_OTHER = 'QUESTION_COMMERCIAL_TYPE_OTHER';
export const QUESTION_SITE_DESCRIPTION = 'QUESTION_SITE_DESCRIPTION';
export const QUESTION_SITE_INTENTIONS = 'QUESTION_SITE_INTENTIONS';
export const QUESTION_SITE_INTENTIONS_MEZZANINE = 'QUESTION_SITE_INTENTIONS_MEZZANINE';
export const QUESTION_BUSINESS_REFINANCE = 'QUESTION_BUSINESS_REFINANCE';
export const QUESTION_BUSINESS_SEASONAL = 'QUESTION_BUSINESS_SEASONAL';
export const QUESTION_BUSINESS_TYPE = 'QUESTION_BUSINESS_TYPE';
export const QUESTION_BUSINESS_NAME = 'QUESTION_BUSINESS_NAME';
export const QUESTION_BUSINESS_WEBSITE = 'QUESTION_BUSINESS_WEBSITE';
export const QUESTION_BUSINESS_TRADING_LENGTH = 'QUESTION_BUSINESS_TRADING_LENGTH';
export const QUESTION_BUSINESS_SECTOR = 'QUESTION_BUSINESS_SECTOR';
export const QUESTION_BUSINESS_FACILITY_NEEDED_BY = 'QUESTION_BUSINESS_FACILITY_NEEDED_BY';
export const QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES = 'QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES';
export const QUESTION_BUSINESS_OUTSTANDING_INVOICES = 'QUESTION_BUSINESS_OUTSTANDING_INVOICES';
export const QUESTION_BUSINESS_PDQ_INCOME = 'QUESTION_BUSINESS_PDQ_INCOME';
export const QUESTION_PROPERTY_TYPE = 'QUESTION_PROPERTY_TYPE';
export const QUESTION_PROPERTY_TYPE_REFURBISHMENT = 'QUESTION_PROPERTY_TYPE_REFURBISHMENT';
export const QUESTION_PROPERTY_TYPE_PORTFOLIO = 'QUESTION_PROPERTY_TYPE_PORTFOLIO';
export const QUESTION_RESIDENTIAL_REFINANCE = 'QUESTION_RESIDENTIAL_REFINANCE';
export const QUESTION_PORTFOLIO_REFINANCE = 'QUESTION_PORTFOLIO_REFINANCE';
export const QUESTION_LOAN_AMOUNT = 'QUESTION_LOAN_AMOUNT';
export const QUESTION_LOAN_PURPOSE = 'QUESTION_LOAN_PURPOSE';
export const QUESTION_LOAN_PURPOSE_MEZZANINE = 'QUESTION_LOAN_PURPOSE_MEZZANINE';
export const QUESTION_TURN_OVER = 'QUESTION_TURN_OVER';
export const QUESTION_BUSINESS_PROFITABLE = 'QUESTION_BUSINESS_PROFITABLE';
export const QUESTION_BUSINESS_OVER_DRAFT_FACILITY = 'QUESTION_BUSINESS_OVER_DRAFT_FACILITY';
export const QUESTION_BUSINESS_OUTSTANDING_DEBT = 'QUESTION_BUSINESS_OUTSTANDING_DEBT';
export const QUESTION_BUSINESS_ADVERSE_CREDIT = 'QUESTION_BUSINESS_ADVERSE_CREDIT';
export const QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION = 'QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION';
export const QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT = 'QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT';
export const QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION =
    'QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION';
export const QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS = 'QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS';
export const QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY =
    'QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY';
export const QUESTION_PLANNING_FOR = 'QUESTION_PLANNING_FOR';
export const QUESTION_DEVELOPMENT_LENGTH = 'QUESTION_DEVELOPMENT_LENGTH';
export const QUESTION_OUTSTANDING_LOAN = 'QUESTION_OUTSTANDING_LOAN';
export const QUESTION_UNITS_PRE_SOLD = 'QUESTION_UNITS_PRE_SOLD';
export const QUESTION_DAY_ONE_LOAN_AMOUNT = 'QUESTION_DAY_ONE_LOAN_AMOUNT';
export const QUESTION_WORKS_EXPLANATION = 'QUESTION_WORKS_EXPLANATION';
export const QUESTION_TOTAL_DEVELOPMENT_COSTS = 'QUESTION_TOTAL_DEVELOPMENT_COSTS';
export const QUESTION_BUILD_COSTS_BORROWING = 'QUESTION_BUILD_COSTS_BORROWING';
export const QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE = 'QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE';
export const QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE = 'QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE';
export const QUESTION_TEAM_DEVELOPMENT_EXPERIENCE = 'QUESTION_TEAM_DEVELOPMENT_EXPERIENCE';
export const QUESTION_EPC_RATING = 'QUESTION_EPC_RATING';
export const QUESTION_LOAN_TYPE = 'QUESTION_LOAN_TYPE';
export const QUESTION_FUNDING_TYPE = 'QUESTION_FUNDING_TYPE';
export const QUESTION_BUSINESS_IS_SECURE_FINANCE = 'QUESTION_BUSINESS_IS_SECURE_FINANCE';
export const QUESTION_LOAN_USAGE_LOCATION = 'QUESTION_LOAN_USAGE_LOCATION';
export const QUESTION_PLANNING_IN_PLACE = 'QUESTION_PLANNING_IN_PLACE';
export const QUESTION_EXPECTED_END_VALUE = 'QUESTION_EXPECTED_END_VALUE';
export const QUESTION_PERCENTAGE_RESIDENTIAL = 'QUESTION_PERCENTAGE_RESIDENTIAL';
export const QUESTION_PERCENTAGE_COMMERCIAL = 'QUESTION_PERCENTAGE_COMMERCIAL';
export const QUESTION_LENDER = 'QUESTION_LENDER';
export const QUESTION_FIRST_CHARGE_AMOUNT = 'QUESTION_FIRST_CHARGE_AMOUNT';
export const QUESTION_CURRENT_VALUE = 'QUESTION_CURRENT_VALUE';
export const QUESTION_PORTFOLIO_PROPERTIES = 'QUESTION_PORTFOLIO_PROPERTIES';
export const QUESTION_PORTFOLIO_VALUE = 'QUESTION_PORTFOLIO_VALUE';
export const QUESTION_PORTFOLIO_ALREADY_OWNED = 'QUESTION_PORTFOLIO_ALREADY_OWNED';
export const QUESTION_OUTSTANDING_LOAN_AMOUNT = 'QUESTION_OUTSTANDING_LOAN_AMOUNT';
export const QUESTION_LOAN_TERM = 'QUESTION_LOAN_TERM';
export const QUESTION_LOAN_TERM_BUSINESS_TERM = 'QUESTION_LOAN_TERM_BUSINESS_TERM';
export const QUESTION_LOAN_NEEDED_BY = 'QUESTION_LOAN_NEEDED_BY';
export const QUESTION_PRE_APPROVE = 'QUESTION_PRE_APPROVE';
export const QUESTION_DAY_ONE_VALUE = 'QUESTION_DAY_ONE_VALUE';
export const QUESTION_LOAN_AMOUNT_REFURBISHMENT = 'QUESTION_LOAN_AMOUNT_REFURBISHMENT';
export const QUESTION_REFURBISHMENT_LENGTH = 'QUESTION_REFURBISHMENT_LENGTH';
export const QUESTION_SITE_INTENTIONS_REFURBISHMENT = 'QUESTION_SITE_INTENTIONS_REFURBISHMENT';
export const QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE = 'QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE';
export const QUESTION_PERCENTAGE_RESIDENTIAL_SQFT = 'QUESTION_PERCENTAGE_RESIDENTIAL_SQFT';
export const QUESTION_PERCENTAGE_RESIDENTIAL_VALUE = 'QUESTION_PERCENTAGE_RESIDENTIAL_VALUE';
export const QUESTION_COMMERCIAL_UNIT_COUNT = 'QUESTION_COMMERCIAL_UNIT_COUNT';
export const QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT = 'QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT';
export const QUESTION_RESIDENTIAL_UNIT_COUNT = 'QUESTION_RESIDENTIAL_UNIT_COUNT';
export const QUESTION_RESIDENTIAL_UNIT_VALUE = 'QUESTION_RESIDENTIAL_UNIT_VALUE';
export const QUESTION_PERCENTAGE_COMMERCIAL_VALUE = 'QUESTION_PERCENTAGE_COMMERCIAL_VALUE';
export const QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY = 'QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY';
export const QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN = 'QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN';
export const QUESTION_COMMERCIAL_TENANT_AMOUNT = 'QUESTION_COMMERCIAL_TENANT_AMOUNT';
export const QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY = 'QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY';
export const QUESTION_OCCUPY_PROPERTY = 'QUESTION_OCCUPY_PROPERTY';
export const QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES = 'QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES';
export const QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY = 'QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY';
export const QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES = 'QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES';
export const QUESTION_OCCUPY_GREATER_THAN_40_PERCENT = 'QUESTION_OCCUPY_GREATER_THAN_40_PERCENT';
export const QUESTION_PROPERTY_LOCATION = 'QUESTION_PROPERTY_LOCATION';
export const QUESTION_PROPERTY_LOCATION_BUSINESS = 'QUESTION_PROPERTY_LOCATION_BUSINESS';
export const QUESTION_PROPERTY_LOCATION_OTHER = 'QUESTION_PROPERTY_LOCATION_OTHER';
export const QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER = 'QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER';
export const QUESTION_PROPERTY_ADDRESS = 'QUESTION_PROPERTY_ADDRESS';
export const QUESTION_PROPERTY_POSTCODE = 'QUESTION_PROPERTY_POSTCODE';
export const QUESTION_OWN_ANY_OTHER_PROPERTY = 'QUESTION_OWN_ANY_OTHER_PROPERTY';
export const QUESTION_PROPERTY_OWNERSHIP = 'QUESTION_PROPERTY_OWNERSHIP';
export const QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY = 'QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY';
export const QUESTION_BUSINESS_FACILITY_TERM = 'QUESTION_BUSINESS_FACILITY_TERM';
export const QUESTION_BUSINESS_INVOICE_FINANCE_TYPE = 'QUESTION_BUSINESS_INVOICE_FINANCE_TYPE';
export const QUESTION_BUSINESS_ASSET_HIRE_PURCHASE_OR_LEASE = 'QUESTION_BUSINESS_ASSET_HIRE_PURCHASE_OR_LEASE';
export const QUESTION_PROPERTY_TYPE_MEZZANINE = 'QUESTION_PROPERTY_TYPE_MEZZANINE';
export const QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE = 'QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE';
export const QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES = 'QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES';
export const QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION =
    'QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION';
export const QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS = 'QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS';
export const QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE = 'QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE';
export const QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER = 'QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER';
export const QUESTION_APPLICANT_MAIN_NATIONALITY = 'QUESTION_APPLICANT_MAIN_NATIONALITY';
export const QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER = 'QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER';
export const QUESTION_APPLICANT_MAIN_OCCUPATION = 'QUESTION_APPLICANT_MAIN_OCCUPATION';
export const QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME = 'QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME';
export const QUESTION_EXIT_STRATEGY = 'QUESTION_EXIT_STRATEGY';
export const QUESTION_AGREE_TO_TERMS = 'QUESTION_AGREE_TO_TERMS';
export const QUESTION_CURRENT_VALUE_REFURBISHMENT = 'QUESTION_CURRENT_VALUE_REFURBISHMENT';
export const QUESTION_IS_UK_CITIZEN = 'QUESTION_IS_UK_CITIZEN';
export const QUESTION_APPLICANT_AGE_AT_SUBMISSION = 'QUESTION_APPLICANT_AGE_AT_SUBMISSION';
export const QUESTION_LOAN_TO_COST = 'QUESTION_LOAN_TO_COST';
export const QUESTION_LOAN_TO_VALUE = 'QUESTION_LOAN_TO_VALUE';
export const QUESTION_BUSINESS_IS_UK_REGISTERED = 'QUESTION_BUSINESS_IS_UK_REGISTERED';
export const QUESTION_INTEND_TO_DEVELOP_OR_REFURBISH = 'QUESTION_INTEND_TO_DEVELOP_OR_REFURBISH';
export const QUESTION_MERCHANT_PRODUCT = 'QUESTION_MERCHANT_PRODUCT';
export const QUESTION_SECOND_CHARGE_REFINANCE = 'QUESTION_SECOND_CHARGE_REFINANCE';
export const QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE = 'QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE';
export const QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS =
    'QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS';
export const QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD = 'QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD';
export const QUESTION_PERCENTAGE_COMMERCIAL_END_OF_BUILD = 'QUESTION_PERCENTAGE_COMMERCIAL_END_OF_BUILD';
export const QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT = 'QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT';
export const QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT = 'QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT';
export const QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE = 'QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE';
export const QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE = 'QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE';
export const QUESTION_IS_CASH_ADVANCE = 'QUESTION_IS_CASH_ADVANCE';
export const QUESTION_IS_INVOICE_FINANCE = 'QUESTION_IS_INVOICE_FINANCE';
export const QUESTION_BUSINESS_REQUIRE_FACILITY = 'QUESTION_BUSINESS_REQUIRE_FACILITY';
export const QUESTION_LOAN_PURPOSE_DESCRIPTION = 'QUESTION_LOAN_PURPOSE_DESCRIPTION';

class BrokerQuestions extends BaseEnum {
    toCamelCase = (str) => str.trim().replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ''));

    getInitialFormData = () => {
        const fields = this.getTypes();
        let formData = {
            applicants: {
                main: null,
                sub: [],
            },
        };
        Object.entries(fields).forEach((field) => {
            if (field[0] === QUESTION_AGREE_TO_TERMS) {
                formData[field[1].fieldName] = false;
            } else {
                formData[field[1].fieldName] = '';
            }
        });

        return formData;
    };

    getFormDataFromLoanEnquiryData = (loanEnquiryData, applicants) => {
        const fields = this.getTypes();
        let formData = {
            applicants: {
                main: null,
                sub: [],
            },
        };
        for (let i = 0; i < applicants.length; i++) {
            const node = applicants[i];
            const labelText =
                node.applicant.given +
                ' ' +
                node.applicant.family +
                ' (' +
                moment(node.applicant.dateOfBirth).format('MMM D, YYYY') +
                ')';
            const applicant = {
                index: i,
                id: node.applicant.id,
                family: node.applicant.family,
                given: node.applicant.given,
                date_of_birth: node.applicant.dateOfBirth,
                label: labelText,
            };
            if (node.isMainApplicant) {
                formData.applicants.main = applicant;
                continue;
            }
            formData.applicants.sub.push(applicant);
        }
        Object.entries(fields).forEach((field) => {
            if (field[0] === QUESTION_AGREE_TO_TERMS) {
                formData[field[1].fieldName] = false;
                return;
            }
            if (null === loanEnquiryData[this.toCamelCase(field[1].fieldName)]) {
                formData[field[1].fieldName] = '';
            } else {
                formData[field[1].fieldName] = String(loanEnquiryData[this.toCamelCase(field[1].fieldName)]);
            }
            if (field[1].type === 'radio') {
                switch (loanEnquiryData[this.toCamelCase(field[1].fieldName)]) {
                    case 'true':
                    case true:
                        formData[field[1].fieldName] = YES;
                        break;
                    case 'false':
                    case false:
                        formData[field[1].fieldName] = NO;
                        break;
                    default:
                        break;
                }
            }
            if (field[0] === QUESTION_COMMERCIAL_TYPES) {
                formData[field[1].fieldName] = String(loanEnquiryData[this.toCamelCase(field[1].fieldName)]).split(',');
            }
        });

        return formData;
    };

    getInitialValidationData = () => {
        const fields = this.getTypes();
        let validationData = {
            applicants_error: {
                main: '',
                sub: '',
            },
        };
        Object.entries(fields).forEach((field) => {
            validationData[field[1].fieldName + '_error'] = '';
        });

        return validationData;
    };

    getTypes = () => {
        return {
            [QUESTION_ADDITIONAL_INFO]: {
                type: 'textarea',
                rows: 10,
                label: 'Is there any additional information you or your clients would like to provide to lenders?',
                fieldName: 'additional_information',
                placeholder:
                    'Please describe clients backgrounds, experience, A&L & current and onward plans.\nInclude any terms you or your clients are trying to beat. Anything odd about the transaction/structure.\n\nRemember to upload relevant documents post-submission.',
            },
            [QUESTION_RENTAL_INCOME_RESIDENTIAL]: {
                type: 'currency',
                label: 'What is the actual or expected monthly rental income?',
                fieldName: 'rental_income_residential',
                placeholder: '£20,000',
            },
            [QUESTION_HMO_BEDROOMS]: {
                type: 'number',
                label: 'How many [bold|bedrooms] does the property have?',
                fieldName: 'hmo_bedroom',
                placeholder: '3',
            },
            [QUESTION_HMO_ROOMS_SELF_CONTAINED]: {
                type: 'radio',
                label: 'Are the rooms self-contained?',
                fieldName: 'hmo_rooms_self_contained',
                placeholder: 'Please Select',
            },
            [QUESTION_HMO_HAS_LICENSE]: {
                type: 'radio',
                label: 'Do your clients have a HMO license?',
                fieldName: 'hmo_has_license',
                placeholder: 'Please Select',
            },
            [QUESTION_HMO_LICENSE_IS_REQUIRED]: {
                type: 'radio',
                label: 'Do your clients need a HMO license?',
                fieldName: 'hmo_needs_license',
                placeholder: 'Please Select',
            },
            [QUESTION_BUY_TO_LET_EXPERIENCE]: {
                type: 'radio',
                label: 'Do your clients have experience with buy to let properties?',
                fieldName: 'buy_to_let_experience',
                placeholder: 'Please Select',
            },
            [QUESTION_RENTAL_INCOME_COMMERCIAL]: {
                type: 'currency',
                label: 'What is the actual or expected monthly income?',
                fieldName: 'rental_income_commercial',
                placeholder: '£300,000',
            },
            [QUESTION_RENTAL_INCOME_PORTFOLIO]: {
                type: 'currency',
                label: 'What is the annual rental income from the whole portfolio?',
                fieldName: 'rental_income_portfolio',
                placeholder: '£300,000',
            },
            [QUESTION_RENTAL_INCOME]: {
                type: 'currency',
                label: 'What is the expected or current rental income?',
                fieldName: 'rental_income',
                placeholder: '£300,000',
            },
            [QUESTION_COMMERCIAL_UNITS_RENTED_OUT]: {
                type: 'radio',
                label: 'Will the commercial units be rented out?',
                fieldName: 'commercial_units_rented_out',
                placeholder: 'Please Select',
            },
            [QUESTION_COMMERCIAL_OWNER_OCCUPIED]: {
                type: 'radio',
                label: 'Will any of the commercial units be owner occupied?',
                fieldName: 'commercial_unit_owner_occupied',
                placeholder: 'Please Select',
            },
            [QUESTION_COMMERCIAL_UNIT_COUNT]: {
                type: 'number',
                label: 'How many commercial units are in the property?',
                fieldName: 'commercial_unit_amount',
                placeholder: '10',
            },
            [QUESTION_COMMERCIAL_UNIT_COUNT_REFURBISHMENT]: {
                type: 'number',
                label: 'How many commercial units will be on the property?',
                fieldName: 'commercial_unit_amount',
                placeholder: '10',
            },
            [QUESTION_COMMERCIAL_TYPES]: {
                type: 'multiselect',
                label: 'What commercial units are on the property?',
                fieldName: 'commercial_type',
                placeholder: 'Please Select',
            },
            [QUESTION_COMMERCIAL_TYPES_REFURBISHMENT]: {
                type: 'multiselect',
                label: 'What commercial units will be on the property?',
                fieldName: 'commercial_type',
                placeholder: 'Please Select',
            },
            [QUESTION_COMMERCIAL_TYPE_OTHER]: {
                type: 'text',
                label: 'What type of commercial units will be on the property?',
                fieldName: 'commercial_type_other',
                placeholder: 'A shopping centre',
            },
            [QUESTION_SITE_DESCRIPTION]: {
                type: 'textarea',
                label: 'Please describe the site',
                fieldName: 'site_description',
                placeholder: 'A large field in the...',
                rows: 3,
            },
            [QUESTION_SITE_INTENTIONS]: {
                type: 'textarea',
                label: "Please describe your client's intentions on the site",
                fieldName: 'site_intention',
                placeholder: 'My client would like to...',
                rows: 3,
            },
            [QUESTION_SITE_INTENTIONS_REFURBISHMENT]: {
                type: 'textarea',
                label: 'Please describe the refurbishment being carried out',
                fieldName: 'site_intention',
                placeholder: 'My client would like to...',
                rows: 3,
            },
            [QUESTION_SITE_INTENTIONS_MEZZANINE]: {
                type: 'textarea',
                label: 'Please describe the works or build to be carried out on the property',
                fieldName: 'site_intention',
                placeholder: 'My client would like to...',
                rows: 3,
            },
            [QUESTION_BUSINESS_REFINANCE]: {
                type: 'radio',
                label: 'What is the purpose of this loan?',
                fieldName: 'refinance',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_SEASONAL]: {
                type: 'radio',
                label: "Is the client's business [bold|seasonal]?",
                fieldName: 'business_seasonal',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_TYPE]: {
                type: 'select',
                label: 'What is the [bold|structure] of the business?',
                fieldName: 'business_type',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_NAME]: {
                type: 'text',
                label: "What is the [bold|name] of your client's business",
                fieldName: 'business_name',
                placeholder: 'Provide Finance Limited',
            },
            [QUESTION_BUSINESS_WEBSITE]: {
                type: 'text',
                label: "What is the URL of the client's business [bold|website]?",
                fieldName: 'business_website',
                placeholder: 'https://providefinance.com/',
            },
            [QUESTION_BUSINESS_TRADING_LENGTH]: {
                type: 'number',
                label: "How long has your client's business been [bold|trading]? (in months)",
                fieldName: 'business_trading_length',
                placeholder: '72',
            },
            [QUESTION_BUSINESS_SECTOR]: {
                type: 'select',
                label: 'What [bold|sector] is the business in?',
                fieldName: 'business_sector',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_FACILITY_NEEDED_BY]: {
                type: 'select',
                label: 'When does the business need the [bold|facility]?',
                fieldName: 'business_facility_needed_by',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_FACILITY_TERM]: {
                type: 'number',
                label: '[bold|How long] does the business need the [bold|facility]? (in months)',
                fieldName: 'business_facility_term',
                placeholder: '3',
            },
            [QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES]: {
                type: 'textarea',
                label: 'What are your clients [bold|payment terms] with their customers?',
                fieldName: 'business_current_payment_terms',
                placeholder: 'Payment terms are...',
                rows: 4,
            },
            [QUESTION_BUSINESS_OUTSTANDING_INVOICES]: {
                type: 'currency',
                label: "What is the total value of the business' [bold|outstanding invoices]?",
                fieldName: 'business_outstanding_invoices',
                placeholder: '£18,000',
            },
            [QUESTION_BUSINESS_PDQ_INCOME]: {
                type: 'currency',
                label:
                    'How much [bold|revenue] does the business take via [bold| card terminals/payment processors per month]?',
                fieldName: 'business_pdq_income',
                placeholder: '£720,000',
            },
            [QUESTION_PROPERTY_TYPE]: {
                type: 'radio',
                label: 'What kind of property is this for?',
                fieldName: 'property_type',
                placeholder: 'Please Select',
            },
            [QUESTION_PROPERTY_TYPE_REFURBISHMENT]: {
                type: 'radio',
                label: 'What kind of property will this be at the end of the project?',
                fieldName: 'property_type',
                placeholder: 'Please Select',
            },
            [QUESTION_PROPERTY_TYPE_MEZZANINE]: {
                type: 'radio',
                label: 'What kind of development is the mezzanine required for?',
                fieldName: 'property_type',
                placeholder: 'Please Select',
            },
            [QUESTION_PROPERTY_TYPE_PORTFOLIO]: {
                type: 'radio',
                label: 'What is the make-up of the portfolio?',
                fieldName: 'property_type',
                placeholder: 'Please Select',
            },
            [QUESTION_RESIDENTIAL_REFINANCE]: {
                type: 'radio',
                label: 'Are your clients looking to refinance or purchase this property?',
                fieldName: 'refinance',
                placeholder: 'Please Select',
            },
            [QUESTION_PORTFOLIO_REFINANCE]: {
                type: 'radio',
                label: 'Is your client looking to refinance their portfolio?',
                fieldName: 'refinance',
                placeholder: 'Please Select',
            },
            [QUESTION_SECOND_CHARGE_REFINANCE]: {
                type: 'radio',
                label: 'Are your clients refinancing an existing second charge?',
                fieldName: 'refinance',
                placeholder: 'Please Select',
            },
            [QUESTION_LOAN_AMOUNT]: {
                type: 'currency',
                label: 'How much do your clients wish to borrow?',
                fieldName: 'loan_amount',
                placeholder: '£100,000',
            },
            [QUESTION_LOAN_PURPOSE]: {
                type: 'select',
                label: 'What do your clients intend to use this loan for?',
                fieldName: 'loan_purpose',
                placeholder: 'Please Select',
            },
            [QUESTION_LOAN_PURPOSE_MEZZANINE]: {
                type: 'select',
                label: 'What is the main purpose for this loan?',
                fieldName: 'loan_purpose',
                placeholder: 'Please Select',
            },
            [QUESTION_TURN_OVER]: {
                type: 'currency',
                label: 'What was their [bold|turnover] for the last financial year?',
                fieldName: 'turn_over',
                placeholder: '£1,400,000',
            },
            [QUESTION_BUSINESS_PROFITABLE]: {
                type: 'radio',
                label: "Is your client's business currently making a [bold|profit]?",
                fieldName: 'business_profitable',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_OVER_DRAFT_FACILITY]: {
                type: 'textarea',
                label: 'Does the business have an [bold|overdraft] facility?',
                fieldName: 'business_overdraft_facility',
                placeholder: 'Yes, we currently...',
                rows: 3,
            },
            [QUESTION_BUSINESS_OUTSTANDING_DEBT]: {
                type: 'radio',
                label:
                    'Does the business have an outstanding [bold|debt] facility, which isn’t being refinanced in this transaction?',
                fieldName: 'business_outstanding_debt',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_ADVERSE_CREDIT]: {
                type: 'radio',
                label: "Does your client's business have [bold|adverse credit]?",
                fieldName: 'business_adverse_credit',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_ADVERSE_CREDIT_EXPLANATION]: {
                type: 'textarea',
                label: 'Please explain the [bold|adverse credit]',
                fieldName: 'business_adverse_credit_explanation',
                placeholder: 'Due to...',
                rows: 3,
            },
            [QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT]: {
                type: 'radio',
                label: 'Have any directors or shareholders had any [bold|adverse credit] registered against them?',
                fieldName: 'business_directors_adverse_credit',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_DIRECTORS_ADVERSE_CREDIT_EXPLANATION]: {
                type: 'textarea',
                label: 'Please explain the [bold|adverse credit] registered against shareholders',
                fieldName: 'business_directors_adverse_credit_explanation',
                placeholder: 'Due to...',
                rows: 3,
            },
            [QUESTION_SHAREHOLDERS_ARE_HOME_OWNERS]: {
                type: 'radio',
                label: 'Are any [bold|shareholders] over [bold|20% UK homeowners]?',
                fieldName: 'shareholders_are_home_owners',
                placeholder: 'Please Select',
            },
            [QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY]: {
                type: 'text',
                label: 'Is there a [bold|property available] for [bold|additional security]?',
                fieldName: 'property_available_for_additional_security',
                placeholder: 'Yes',
            },
            [QUESTION_PROPERTY_AVAILABLE_FOR_ADDITIONAL_SECURITY_BUSINESS]: {
                type: 'textarea',
                label: 'Please provide details of the property available as security',
                fieldName: 'property_available_for_additional_security',
                placeholder: 'Please include the address, value, property type and ownership structure.',
                rows: 6,
            },
            [QUESTION_PLANNING_FOR]: {
                type: 'radio',
                label: 'What is the planning for?',
                fieldName: 'planning_for',
                placeholder: 'Please Select',
            },
            [QUESTION_DEVELOPMENT_LENGTH]: {
                type: 'number',
                label: 'How long will development take? (in months)',
                fieldName: 'development_length',
                placeholder: '16',
            },
            [QUESTION_REFURBISHMENT_LENGTH]: {
                type: 'number',
                label: 'How long will refurbishment take? (in months)',
                fieldName: 'development_length',
                placeholder: '16',
            },
            [QUESTION_OUTSTANDING_LOAN]: {
                type: 'currency',
                label: 'How much is currently outstanding?',
                fieldName: 'outstanding_loan',
                placeholder: '£300,000',
            },
            [QUESTION_OUTSTANDING_LOAN_AMOUNT]: {
                type: 'currency',
                label: 'What is the current outstanding loan amount?',
                fieldName: 'outstanding_loan',
                placeholder: '£300,000',
            },
            [QUESTION_DAY_ONE_LOAN_AMOUNT]: {
                type: 'currency',
                label: 'What is the required day one loan amount?',
                fieldName: 'day_one_loan_amount',
                placeholder: '£300,000',
            },
            [QUESTION_DAY_ONE_LOAN_AMOUNT_PERCENTAGE]: {
                type: 'percent',
                label: 'What is the day one loan amount (Day One LTV) %?',
                fieldName: 'day_one_loan_amount_percentage',
                placeholder: '%',
            },
            [QUESTION_DAY_ONE_LOAN_AMOUNT_MEZZANINE]: {
                type: 'currency',
                label: 'What is the required day one mezzanine loan amount?',
                fieldName: 'day_one_loan_amount',
                placeholder: '£300,000',
            },
            [QUESTION_UNITS_PRE_SOLD]: {
                type: 'radio',
                label: 'Do your clients have any of the units pre-sold or pre-let?',
                fieldName: 'units_pre_sold',
                placeholder: 'Please Select',
            },
            [QUESTION_WORKS_EXPLANATION]: {
                type: 'textarea',
                label: 'Please explain the works to be carried out',
                fieldName: 'works_explanation',
                placeholder: 'Roofing...',
                rows: 3,
            },
            [QUESTION_TOTAL_DEVELOPMENT_COSTS]: {
                type: 'currency',
                label: 'What are the total development costs?',
                fieldName: 'total_development_costs',
                placeholder: '£120,000',
            },
            [QUESTION_BUILD_COSTS_BORROWING]: {
                type: 'currency',
                label: 'How much does the client want to borrow for the build costs?',
                fieldName: 'build_costs_borrowing',
                placeholder: '£80,000',
            },
            [QUESTION_EXPECTED_GROSS_DEVELOPMENT_VALUE]: {
                type: 'currency',
                label: 'What is the expected Gross Development Value?',
                fieldName: 'expected_gross_development_value',
                placeholder: '£100,000',
            },
            [QUESTION_PREVIOUS_DEVELOPMENT_EXPERIENCE]: {
                type: 'radio',
                label: 'Have your clients got previous development experience?',
                fieldName: 'previous_development_experience',
                placeholder: 'Please Select',
            },
            [QUESTION_PREVIOUS_REFURBISHMENT_EXPERIENCE]: {
                type: 'radio',
                label: 'Do your clients have any experience refurbishing properties?',
                fieldName: 'previous_refurbishment_experience',
                placeholder: 'Please Select',
            },
            [QUESTION_TEAM_DEVELOPMENT_EXPERIENCE]: {
                type: 'radio',
                label: 'Does the clients build team have similar building experience in regards to this project?',
                fieldName: 'team_development_experience',
                placeholder: 'Please Select',
            },
            [QUESTION_EPC_RATING]: {
                type: 'radio',
                label: 'What will the EPC rating be on the property(s)?',
                fieldName: 'epc_rating',
                placeholder: 'Please Select',
            },
            [QUESTION_LOAN_TYPE]: {
                type: 'radio',
                label: 'Are your clients looking for [bold|business] or [bold|property] finance?',
                fieldName: 'loan_type',
                placeholder: 'Please Select',
            },
            [QUESTION_FUNDING_TYPE]: {
                type: 'radio',
                label: 'THIS IS GENERATED IN LoanTypeSelectorContainer.js',
                fieldName: 'funding_type',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_IS_SECURE_FINANCE]: {
                type: 'radio',
                label: 'Are your clients looking for [bold|secured] or [bold|unsecured] finance?',
                fieldName: 'business_secured_finance',
                placeholder: 'Please Select',
            },
            [QUESTION_LOAN_USAGE_LOCATION]: {
                type: 'radio',
                label: 'Is this loan going to be used in the [bold|UK] or [bold|EU]?',
                fieldName: 'loan_used_in_uk_eu',
                placeholder: 'Please Select',
            },
            [QUESTION_PLANNING_IN_PLACE]: {
                type: 'radio',
                label: 'Is planning in place, or about to be?',
                fieldName: 'planning_in_place',
                placeholder: 'Please Select',
            },
            [QUESTION_EXPECTED_END_VALUE]: {
                type: 'currency',
                label: 'What is the expected end value?',
                fieldName: 'end_value',
                placeholder: '£500,000',
            },
            [QUESTION_PERCENTAGE_RESIDENTIAL]: {
                type: 'percent',
                label: 'What percentage of the property is residential?',
                fieldName: 'percentage_residential',
                placeholder: '50',
            },
            [QUESTION_PERCENTAGE_COMMERCIAL]: {
                type: 'percent',
                label: 'What percentage of the property is commercial?',
                fieldName: 'percentage_commercial',
                placeholder: '50',
            },
            [QUESTION_PERCENTAGE_RESIDENTIAL_END_OF_BUILD]: {
                type: 'percent',
                label: 'What percentage of the property will be residential?',
                fieldName: 'percentage_residential',
                placeholder: '50',
            },
            [QUESTION_PERCENTAGE_COMMERCIAL_END_OF_BUILD]: {
                type: 'percent',
                label: 'What percentage of the property will be commercial?',
                fieldName: 'percentage_commercial',
                placeholder: '50',
            },
            [QUESTION_LENDER]: {
                type: 'text',
                label: 'Who is the first charge lender?',
                fieldName: 'lender',
                placeholder: 'Provide Finance',
            },
            [QUESTION_FIRST_CHARGE_AMOUNT]: {
                type: 'currency',
                label: 'What is or will be the outstanding first charge amount?',
                fieldName: 'first_charge_amount',
                placeholder: '£150,000',
            },
            [QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE]: {
                type: 'currency',
                label: 'What is or will be the outstanding first charge amount?',
                fieldName: 'first_charge_amount',
                placeholder: '£150,000',
            },
            [QUESTION_CURRENT_VALUE]: {
                type: 'currency',
                label: 'What is the current value of the property?',
                fieldName: 'current_value',
                placeholder: '£450,000',
            },
            [QUESTION_CURRENT_VALUE_REFURBISHMENT]: {
                type: 'currency',
                label: 'What is the value of the property on day one?',
                fieldName: 'current_value',
                placeholder: '£450,000',
            },
            [QUESTION_PORTFOLIO_PROPERTIES]: {
                type: 'number',
                label: 'How many properties are in the portfolio?',
                fieldName: 'properties',
                placeholder: '10',
            },
            [QUESTION_PORTFOLIO_VALUE]: {
                type: 'currency',
                label: 'What is the value of the portfolio?',
                fieldName: 'portfolio_value',
                placeholder: '£5,340,499',
            },
            [QUESTION_PORTFOLIO_ALREADY_OWNED]: {
                type: 'radio',
                label: 'Do your clients own these already?',
                fieldName: 'portfolio_already_owned',
                placeholder: 'Please Select',
            },
            [QUESTION_LOAN_TERM]: {
                type: 'number',
                label: 'What is the loan length your clients are looking for? (in months)',
                fieldName: 'loan_term',
                placeholder: '18',
            },
            [QUESTION_LOAN_TERM_BUSINESS_TERM]: {
                type: 'number',
                label: '[bold|How long] does the business need the [bold|facility]? (in months)',
                fieldName: 'loan_term',
                placeholder: '18',
            },
            [QUESTION_LOAN_NEEDED_BY]: {
                type: 'radio',
                label: 'When does this loan need to complete by?',
                fieldName: 'loan_needed_by',
                placeholder: 'Please Select',
            },
            [QUESTION_PRE_APPROVE]: {
                type: 'radio',
                label: "Do your clients want to pre-approve matched lenders before they're notified?",
                fieldName: 'pre_approve_matches',
                placeholder: 'Please Select',
            },
            [QUESTION_DAY_ONE_VALUE]: {
                type: 'currency',
                label: 'What is the value of the property on day one?',
                fieldName: 'current_value',
                placeholder: '£750,000',
            },
            [QUESTION_LOAN_AMOUNT_REFURBISHMENT]: {
                type: 'currency',
                label: 'What is the required loan amount for refurbishment?',
                fieldName: 'loan_amount_refurbishment',
                placeholder: '£500,000',
            },
            [QUESTION_PERCENTAGE_RESIDENTIAL_SQFT]: {
                type: 'percent',
                label: "What percentage of the property's [bold|floor space] is residential?",
                fieldName: 'percentage_residential_square_feet',
                placeholder: '50',
            },
            [QUESTION_PERCENTAGE_RESIDENTIAL_SQFT_DEVELOPMENT]: {
                type: 'percent',
                label: "What percentage of the property's [bold|floor space] will be residential?",
                fieldName: 'percentage_residential_square_feet',
                placeholder: '50',
            },
            [QUESTION_PERCENTAGE_RESIDENTIAL_VALUE]: {
                type: 'percent',
                label: 'What percentage of the property is residential in value?',
                fieldName: 'percentage_residential_value',
                placeholder: '50',
            },
            [QUESTION_PERCENTAGE_RESIDENTIAL_VALUE_DEVELOPMENT]: {
                type: 'percent',
                label: 'What percentage of the property will be residential in value?',
                fieldName: 'percentage_residential_value',
                placeholder: '50',
            },
            [QUESTION_PERCENTAGE_COMMERCIAL_VALUE]: {
                type: 'percent',
                label: 'What percentage of the property is commercial in value?',
                fieldName: 'percentage_commercial_value',
                placeholder: '50',
            },
            [QUESTION_RESIDENTIAL_UNIT_COUNT]: {
                type: 'number',
                label: 'How many residential units are there?',
                fieldName: 'residential_unit_count',
                placeholder: '10',
            },
            [QUESTION_RESIDENTIAL_UNIT_VALUE]: {
                type: 'currency',
                label: 'What is the value of the residential units?',
                fieldName: 'residential_unit_value',
                placeholder: '£500,000',
            },
            [QUESTION_BUSINESS_OCCUPY_ENTIRE_PROPERTY]: {
                type: 'radio',
                label: 'Will the business occupy the entire property?',
                fieldName: 'business_occupy_entire_property',
                placeholder: 'Please Select',
            },
            [QUESTION_COMMERCIAL_UNIT_RENT_BREAKDOWN]: {
                type: 'text',
                label:
                    'What is the expected or actual rental income for the commercial unit? (please breakdown per unit, and whether it will be rented out)',
                fieldName: 'commercial_unit_rent_breakdown',
                placeholder: 'Unit 1: £10,000...',
            },
            [QUESTION_COMMERCIAL_TENANT_AMOUNT]: {
                type: 'text',
                label: 'How many commercial tenants does the client expect there to be?',
                fieldName: 'commercial_tenant_amount',
                placeholder: '30',
            },
            [QUESTION_OWN_ANY_OTHER_COMMERCIAL_PROPERTY]: {
                type: 'radio',
                label: 'Do your clients own any other commercial properties?',
                fieldName: 'owns_other_commercial_property',
                placeholder: 'Please Select',
            },
            [QUESTION_OWN_ANY_OTHER_PROPERTY]: {
                type: 'radio',
                label: 'Do your clients own any other properties?',
                fieldName: 'own_other_properties',
                placeholder: 'Please Select',
            },
            [QUESTION_OCCUPY_PROPERTY]: {
                type: 'radio',
                label: 'LABEL GENERATED IN PropertySelectionContainer.js',
                fieldName: 'occupy_property',
                placeholder: 'Please Select',
            },
            [QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES]: {
                type: 'radio',
                label: 'Do your clients own any other properties?',
                fieldName: 'owns_other_investment_properties',
                placeholder: 'Please Select',
            },
            [QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY]: {
                type: 'radio',
                label: 'Have your clients or a member of their family ever lived in the property?',
                fieldName: 'family_has_occupied',
                placeholder: 'Please Select',
            },
            [QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES]: {
                type: 'radio',
                label: 'Are your clients looking at getting a second charge for business purposes?',
                fieldName: 'second_charge',
                placeholder: 'Please Select',
            },
            [QUESTION_OCCUPY_GREATER_THAN_40_PERCENT]: {
                type: 'radio',
                label: 'Will the client be occupying more than 40% of the property as your main residence?',
                fieldName: 'occupying_greater_than_40_percent',
                placeholder: 'Please Select',
            },
            [QUESTION_PROPERTY_LOCATION]: {
                type: 'select',
                label: 'Where is the property located?',
                fieldName: 'location',
                placeholder: 'Please Select',
            },
            [QUESTION_PROPERTY_LOCATION_OTHER]: {
                type: 'text',
                label: 'Please tell us where the property is located',
                fieldName: 'location_other',
                placeholder: 'Mars',
            },
            [QUESTION_PROPERTY_ADDRESS]: {
                type: 'text',
                label: 'What is the address of the property?',
                fieldName: 'address',
                placeholder: '42 Wallaby Way',
            },
            [QUESTION_PROPERTY_POSTCODE]: {
                type: 'text',
                label: 'What is the postcode of the property?',
                fieldName: 'postcode',
                placeholder: 'SW1A 2AA',
            },
            [QUESTION_PROPERTY_OWNERSHIP]: {
                type: 'radio',
                label: 'How will the properties be owned?',
                fieldName: 'property_ownership',
                placeholder: 'Please Select',
            },
            [QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY]: {
                type: 'currency',
                label: 'How much will the refurbishment works cost, including contingency?',
                fieldName: 'refurbishment_cost',
                placeholder: '£300,000',
            },
            [QUESTION_PROPERTY_LOCATION_BUSINESS]: {
                type: 'select',
                label: 'Where is the business located?',
                fieldName: 'location',
                placeholder: 'Please Select',
            },
            [QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER]: {
                type: 'text',
                label: 'Please tell us where the facility is located',
                fieldName: 'location_other',
                placeholder: 'Mars',
            },
            [QUESTION_BUSINESS_INVOICE_FINANCE_TYPE]: {
                type: 'select',
                label: 'What kind of invoice financing is required?',
                fieldName: 'invoice_finance_type',
                placeholder: 'Please Select',
            },
            [QUESTION_BUSINESS_ASSET_HIRE_PURCHASE_OR_LEASE]: {
                type: 'radio',
                label: 'Are your clients looking for a lease or a hire purchase?',
                fieldName: 'lease_hire_purchase',
                placeholder: 'Please Select',
            },
            [QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES]: {
                type: 'radio',
                label: 'GENERATED IN CreditAndCitizenshipContainer.js',
                fieldName: 'main_applicant_credit_difficulties',
                placeholder: 'Please Select',
                rows: 0,
            },
            [QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION]: {
                type: 'textarea',
                label: "Please explain your client's credit difficulties",
                fieldName: 'main_applicant_credit_difficulties_explanation',
                placeholder: "Please describe your client's credit difficulties",
                rows: 3,
            },
            [QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS]: {
                type: 'select',
                label: 'GENERATED IN CreditAndCitizenshipContainer.js',
                fieldName: 'main_applicant_citizenship_status',
                placeholder: 'Please Select',
                rows: 0,
            },
            [QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE]: {
                type: 'select',
                label: 'GENERATED IN CreditAndCitizenshipContainer.js',
                fieldName: 'main_applicant_country_of_residence',
                placeholder: 'Please Select',
                rows: 0,
            },
            [QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER]: {
                type: 'text',
                label: 'Please enter the country of residence',
                fieldName: 'main_applicant_country_of_residence_other',
                placeholder: 'Gallifrey',
                rows: 0,
            },
            [QUESTION_APPLICANT_MAIN_NATIONALITY]: {
                type: 'select',
                label: 'GENERATED IN CreditAndCitizenshipContainer.js',
                fieldName: 'main_applicant_nationality',
                placeholder: 'Please Select',
                rows: 0,
            },
            [QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER]: {
                type: 'text',
                label: 'Please enter a nationality',
                fieldName: 'main_applicant_nationality_other',
                placeholder: 'Gallifreyan.',
                rows: 3,
            },
            [QUESTION_APPLICANT_MAIN_OCCUPATION]: {
                type: 'textarea',
                label: 'GENERATED IN CreditAndCitizenshipContainer.js',
                fieldName: 'main_applicant_occupation',
                placeholder: 'Job Title',
                rows: 3,
            },
            [QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME]: {
                type: 'currency',
                label: 'GENERATED IN CreditAndCitizenshipContainer.js',
                fieldName: 'main_applicant_gross_income',
                placeholder: '£80,000',
                rows: 0,
            },
            [QUESTION_EXIT_STRATEGY]: {
                type: 'textarea',
                label: 'What is your exit strategy',
                fieldName: 'main_applicant_exit_strategy',
                placeholder: 'I plan to...',
                rows: 4,
            },
            [QUESTION_AGREE_TO_TERMS]: {
                type: 'checkbox',
                label:
                    'I have read and understood these statements and the general Provide Finance Terms and Conditions',
                fieldName: 'agree_to_terms',
                placeholder: 'Check the box.',
            },
            [QUESTION_IS_UK_CITIZEN]: {
                type: 'radio',
                label: 'Is the main applicant a UK citizen?',
                fieldName: 'is_uk_citizen',
                placeholder: 'No one is ever going to see these you know.',
            },
            [QUESTION_APPLICANT_AGE_AT_SUBMISSION]: {
                type: 'radio',
                label: "What is the applicant's age?",
                fieldName: 'applicant_age_at_submission',
                placeholder: 'They are a bit pointless. I should probably hardcode them in the component',
            },
            [QUESTION_LOAN_TO_COST]: {
                type: 'percent',
                label: 'What is the Loan to Cost (LTC) %?',
                fieldName: 'loan_to_cost',
                placeholder: 'But meh. I will do it "properly"',
            },
            [QUESTION_LOAN_TO_VALUE]: {
                type: 'percent',
                label: 'What is the Loan to Value (LTV) %?',
                fieldName: 'loan_to_value',
                placeholder: 'Maybe.',
            },
            [QUESTION_BUSINESS_IS_UK_REGISTERED]: {
                type: 'radio',
                label: 'Is the business UK registered?',
                fieldName: 'business_is_uk_registered',
                placeholder: 'It is still undecided.',
            },
            [QUESTION_INTEND_TO_DEVELOP_OR_REFURBISH]: {
                type: 'radio',
                label: 'Intending to develop or refurbish?',
                fieldName: 'intend_to_develop_or_refurbish',
                placeholder: 'I guess not',
            },
            [QUESTION_MERCHANT_PRODUCT]: {
                type: 'radio',
                label: 'What are the required merchant products?',
                fieldName: 'merchant_product_type',
                placeholder: 'I already added all the fields here.',
            },
            [QUESTION_LOAN_TO_GROSS_DEVELOPMENT_VALUE]: {
                type: 'percent',
                label: 'What is the Loan To Gross Development Value (LTGDV) %?',
                fieldName: 'loan_to_gross_development_value',
                placeholder: '45%',
            },
            [QUESTION_IS_CASH_ADVANCE]: {
                type: 'radio',
                label: 'Does your client need an advance on their future credit card sales?',
                fieldName: 'is_cash_advance',
                placeholder: 'Another thing people will never seeeeeeeeeeee!',
            },
            [QUESTION_IS_INVOICE_FINANCE]: {
                type: 'radio',
                label:
                    'Is your client seeking solutions to improve their cash flow by leveraging their outstanding invoices?',
                fieldName: 'is_invoice_finance',
                placeholder: 'Another thing people will never seeeeeeeeeeee!',
            },
            [QUESTION_BUSINESS_REQUIRE_FACILITY]: {
                type: 'radio',
                label: 'Does your client require a facility?',
                fieldName: 'requires_facility',
                placeholder: 'Another thing people will never seeeeeeeeeeee!',
            },
            [QUESTION_LOAN_PURPOSE_DESCRIPTION]: {
                type: 'textarea',
                label: 'What does your client intend to use the funds for?',
                fieldName: 'loan_purpose_description',
                placeholder: 'I plan to...',
                rows: 4,
            },
        };
    };
}

export default BrokerQuestions;
