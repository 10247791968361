import React, { useEffect, useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import TextField from '../../redux-form-material-ui/TextField';
import { verifyUserWithSMSCode } from '../../pitch4_api/set_user_verified_with_sms_code';
import { BORROWER_URL } from '../../bootstrap/constants/constants';
import { getSMSVerificationCode } from '../../pitch4_api/get_sms_verification_code';
import SessionManager from '../../pitchblack_react_utils/session/SessionManager';
import Bowser from 'bowser';

export default function SMSVerificationModal(props) {
    const {
        setModalClose,
        resendCode = false,
        hideBannerNotice,
        welcomeModal = null,
        setModalComponent = null,
    } = props;
    const sm = new SessionManager();
    const cs = sm.getRelevantSession();
    const userId = cs.getId();
    const [code, setCode] = useState('');
    const [codeInvalid, setCodeInvalid] = useState(false);
    const [rc, setResendCode] = useState(resendCode);
    const [newUserPhoneValid, setNewUserPhoneValid] = useState(false);
    const [updateUserPhone, setUpdateUserPhone] = useState(false);
    const [codeInvalidMessage, setCodeInvalidMessage] = useState('');
    const [requestingSMSCode, setRequestingSMSCode] = useState(false);
    const [verifyingSMSCode, setVerifyingSMSCode] = useState(false);
    const [newUserPhone, setNewUserPhone] = useState(cs.getPrimaryPhone());
    const userEnvironment = Bowser.parse(window.navigator.userAgent);

    if ('OTPCredential' in window) {
        const ac = new AbortController();

        navigator.credentials
            .get({
                otp: { transport: ['sms'] },
                signal: ac.signal,
            })
            .then((otp) => {
                setCode(otp.code);
                execVerifyUser(otp.code);
                ac.abort();
            })
            .catch((err) => {
                ac.abort();
            });
    }

    useEffect(() => {
        if (true === rc) {
            execResendCode();
        }
    }, []);

    const validateNewUserPhone = (phone) => () => {
        const regex = new RegExp(/((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/g);
        setNewUserPhoneValid(regex.test(phone));
        setNewUserPhone(phone);

        return newUserPhoneValid;
    };

    const updatePhone = () => {
        setUpdateUserPhone(true);
        setCode('');
        setCodeInvalidMessage('');
        setCodeInvalid(false);
    };

    const handleNoCodeReceived = () => {
        setResendCode(true);
        setCodeInvalidMessage('');
        setCodeInvalid(false);
        setCode('');
        execResendCode();
    };

    const execResendCode = () => {
        setRequestingSMSCode(true);
        setCodeInvalidMessage('');
        setCodeInvalid(false);
        sm.setPrimaryPhone(newUserPhone);
        setUpdateUserPhone(false);
        getSMSVerificationCode(
            BORROWER_URL,
            userId,
            newUserPhone,
            userEnvironment.os.name || null,
            userEnvironment.browser.name || null
        ).then((resp) => {
            setRequestingSMSCode(false);
        });
    };

    const execVerifyUser = (smsCode) => {
        setVerifyingSMSCode(true);
        setCodeInvalidMessage('');
        setCodeInvalid(false);
        verifyUserWithSMSCode(BORROWER_URL, userId, smsCode).then((resp) => {
            if (resp.verifyUserWithSMSCode && true === resp.verifyUserWithSMSCode.success) {
                sm.setIsVerified(true);
                hideBannerNotice();
                setModalClose();

                if (welcomeModal && setModalComponent) {
                    setModalComponent(welcomeModal);
                }
            } else {
                setCodeInvalid(true);
                setCodeInvalidMessage('Provided code is invalid!');
            }

            setVerifyingSMSCode(false);
        });
    };

    const execVerifyLater = () => {
        setModalClose();
        if (welcomeModal && setModalComponent) {
            setModalComponent(welcomeModal);
        }
    };

    return (
        <div className={'sms-verification-modal-container'}>
            <div className={'header'}>
                {true === rc && (
                    <div className={'resend-code'}>
                        {true === requestingSMSCode ? (
                            <>
                                Sending a new SMS code to
                                <br /> {newUserPhone}
                            </>
                        ) : true === updateUserPhone ? (
                            'Update the phone number'
                        ) : (
                            <>
                                We have re-sent your code to
                                <br /> {newUserPhone}
                            </>
                        )}
                        {false === updateUserPhone && (
                            <div className={'update-phone'} onClick={() => updatePhone()}>
                                Update my phone number
                            </div>
                        )}
                        {true === updateUserPhone && (
                            <div className={'resend-code-container'}>
                                <TextField
                                    id="new-phone"
                                    variant="outlined"
                                    onChange={(event) => validateNewUserPhone(event.target.value)()}
                                    value={newUserPhone}
                                    className={'new-phone'}
                                    placeholder={'Mobile phone number'}
                                />
                                <Button
                                    variant={'contained'}
                                    color="primary"
                                    onClick={(event) => execResendCode()}
                                    disabled={!newUserPhoneValid}
                                    className={'btn-resend'}
                                >
                                    {false === requestingSMSCode ? (
                                        'Resend'
                                    ) : (
                                        <CircularProgress color={'white'} size={30} />
                                    )}
                                </Button>
                            </div>
                        )}
                    </div>
                )}
                <div className={'enter-code'}>
                    <span>Last Step:</span> Enter your SMS verify code
                </div>
            </div>
            <div className={'content'}>
                <TextField
                    id="sms-code"
                    variant="outlined"
                    onChange={(event) => setCode(event.target.value)}
                    value={code}
                    inputProps={{ maxLength: 6, autoComplete: 'one-time-code' }}
                    placeholder={'6-digit SMS code'}
                    className={'code-input'}
                    helperText={codeInvalidMessage}
                    error={codeInvalid}
                />
                {false === rc && (
                    <div className={'no-code-received'} onClick={() => handleNoCodeReceived()}>
                        I haven’t received my code
                    </div>
                )}
            </div>
            <div className={'actions'}>
                <Button size={'large'} color={'default'} onClick={() => execVerifyLater()}>
                    I'll verify later
                </Button>
                <Button
                    size={'large'}
                    variant={'contained'}
                    color="primary"
                    onClick={() => execVerifyUser(code)}
                    disabled={code.length === 0}
                    className={'btn-next'}
                    style={{ width: 50 }}
                >
                    {false === verifyingSMSCode ? 'Next' : <CircularProgress color={'white'} size={20} />}
                </Button>
            </div>
        </div>
    );
}
