export const RESIDENTIAL = 'RESIDENTIAL';
export const COMMERCIAL = 'COMMERCIAL';
export const SEMI_COMMERCIAL = 'SEMI_COMMERCIAL';
export const NOT_GRANTED = 'NOT_GRANTED';

class CommercialTypes {
    getSummaryTypes = () => {
        return {
            [RESIDENTIAL]: { value: RESIDENTIAL, label: 'Residential' },
            [COMMERCIAL]: { value: COMMERCIAL, label: 'Commercial' },
            [SEMI_COMMERCIAL]: { value: SEMI_COMMERCIAL, label: 'Semi-commercial' },
        };
    };

    getTypes = () => {
        return [
            { value: RESIDENTIAL, label: 'Residential' },
            { value: COMMERCIAL, label: 'Commercial' },
            { value: SEMI_COMMERCIAL, label: 'Semi-commercial' },
        ];
    };

    getPlanningOptions = () => {
        return [
            { value: RESIDENTIAL, label: 'Residential' },
            { value: COMMERCIAL, label: 'Commercial' },
            { value: SEMI_COMMERCIAL, label: 'Semi-commercial' },
            { value: NOT_GRANTED, label: 'Planning not yet granted' },
        ];
    };

    getSummaryPlanningOptions = () => {
        return {
            [RESIDENTIAL]: { value: RESIDENTIAL, label: 'Residential' },
            [COMMERCIAL]: { value: COMMERCIAL, label: 'Commercial' },
            [SEMI_COMMERCIAL]: { value: SEMI_COMMERCIAL, label: 'Semi-commercial' },
            [NOT_GRANTED]: { value: NOT_GRANTED, label: 'Planning not yet granted' },
        };
    };

    getOptions = () => {
        return {
            [RESIDENTIAL]: { value: RESIDENTIAL, label: 'Residential' },
            [COMMERCIAL]: { value: COMMERCIAL, label: 'Commercial' },
            [SEMI_COMMERCIAL]: { value: SEMI_COMMERCIAL, label: 'Semi-commercial' },
            [RESIDENTIAL]: { value: RESIDENTIAL, label: 'Residential' },
            [COMMERCIAL]: { value: COMMERCIAL, label: 'Commercial' },
            [SEMI_COMMERCIAL]: { value: SEMI_COMMERCIAL, label: 'Semi-commercial' },
            [NOT_GRANTED]: { value: NOT_GRANTED, label: 'Planning not yet granted' },
        };
    };
}

export default CommercialTypes;
