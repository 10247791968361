import React from 'react';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import { RollupCommandContainer } from '../../pitch4_tabular/components';
import { PrimaryButton } from '../../pitch4_layout/components/buttons';
import BorrowerDeleteApplicantButton from '../../apps/borrower/applicants/components/DeleteApplicantButton';
import IntermediaryDeleteApplicantButton from '../../apps/admin/intermediary/applicants/components/DeleteApplicantButton';

export default function List(props) {
    const {
        sortingModel,
        handlePageChange,
        handlePageSizeChange,
        handleSortModelChange,
        totalRowCount,
        pageSize,
        rowsPerPage,
        rows,
        dataLoading,
        openEditApplicantModal,
        refreshData,
        type,
    } = props;

    return (
        <DataGrid
            sortingOrder={['desc', 'asc']}
            sortModel={sortingModel}
            paginationMode="server"
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            sortingMode="server"
            onSortModelChange={handleSortModelChange}
            rowCount={totalRowCount}
            pageSize={pageSize}
            rowsPerPageOptions={rowsPerPage}
            rows={rows}
            loading={true}
            disableColumnFilter={true}
            disableMultipleColumnsSorting={true}
            disableColumnReorder={true}
            disableMultipleColumnsFiltering={true}
            disableSelectionOnClick={true}
            disableColumnMenu={true}
            components={{
                LoadingOverlay: () => {
                    return (
                        <GridOverlay>
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0,
                                    width: '100%',
                                }}
                            >
                                {dataLoading ? (
                                    <LinearProgress />
                                ) : rows.length === 0 ? (
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            width: '100%',
                                            paddingTop: 100,
                                            textAlign: 'center',
                                        }}
                                    >
                                        No data
                                    </div>
                                ) : null}
                            </div>
                        </GridOverlay>
                    );
                },
            }}
            columns={[
                {
                    field: 'given',
                    headerName: 'FIRST NAME',
                    width: 150,
                    cellClassName: 'le-table-cell',
                    headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                },
                {
                    field: 'family',
                    headerName: 'LAST NAME',
                    width: 150,
                    cellClassName: 'le-table-cell',
                    headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                },
                {
                    field: 'dateOfBirth',
                    headerName: 'DATE OF BIRTH',
                    width: 150,
                    valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY'),
                    cellClassName: 'le-table-cell',
                    headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                    sortable: false,
                },
                {
                    field: 'email',
                    headerName: 'EMAIL',
                    width: 300,
                    cellClassName: 'le-table-cell',
                    headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                    sortable: false,
                },
                {
                    field: 'address1',
                    headerName: 'ADDRESS LINE 1',
                    width: 150,
                    valueFormatter: ({ row }) => row.address.address1,
                    cellClassName: 'le-table-cell',
                    headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                    sortable: false,
                },
                {
                    field: 'address2',
                    headerName: 'ADDRESS LINE 2',
                    width: 150,
                    valueFormatter: ({ row }) => row.address.address2,
                    cellClassName: 'le-table-cell',
                    headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                    sortable: false,
                },
                {
                    field: 'address3',
                    headerName: 'CITY',
                    width: 150,
                    valueFormatter: ({ row }) => row.address.address3,
                    cellClassName: 'le-table-cell',
                    headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                    sortable: false,
                },
                {
                    field: 'postcode',
                    headerName: 'POSTCODE',
                    width: 150,
                    valueFormatter: ({ row }) => row.address.postcode,
                    cellClassName: 'le-table-cell',
                    headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                    sortable: false,
                },
                {
                    field: 'createdAt',
                    headerName: 'DATE ADDED',
                    width: 150,
                    valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY'),
                    cellClassName: 'le-table-cell',
                    headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                    sortable: false,
                },
                {
                    field: 'actions',
                    renderHeader: () => <div />,
                    cellClassName: 'le-table-cell le-table-cell-content-right',
                    headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                    disableColumnMenu: true,
                    sortable: false,
                    filterable: false,
                    resizable: false,
                    renderCell: (params) => {
                        return (
                            <div className={'le-table-cell-context-menu'} style={{ marginTop: 10 }}>
                                <RollupCommandContainer>
                                    <PrimaryButton
                                        onClick={() => openEditApplicantModal(refreshData, params.row)}
                                        disabled={
                                            params.row.applicantLoanEnquiries !== null &&
                                            params.row.applicantLoanEnquiries.length > 0
                                        }
                                    >
                                        Edit
                                    </PrimaryButton>
                                    {type === 'borrower' ? (
                                        <BorrowerDeleteApplicantButton
                                            id={params.row.id}
                                            outerRefetch={refreshData}
                                            disabled={
                                                params.row.applicantLoanEnquiries !== null &&
                                                params.row.applicantLoanEnquiries.length > 0
                                            }
                                        >
                                            Delete
                                        </BorrowerDeleteApplicantButton>
                                    ) : (
                                        <IntermediaryDeleteApplicantButton
                                            id={params.row.id}
                                            outerRefetch={refreshData}
                                            disabled={
                                                params.row.applicantLoanEnquiries !== null &&
                                                params.row.applicantLoanEnquiries.length > 0
                                            }
                                        >
                                            Delete
                                        </IntermediaryDeleteApplicantButton>
                                    )}
                                </RollupCommandContainer>
                            </div>
                        );
                    },
                },
            ]}
        />
    );
}
