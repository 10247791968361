import React from 'react';
import { RollupCommandContainer } from '../../../../../pitch4_tabular/components';
import { PrimaryButton } from '../../../../../pitch4_layout/components/buttons';
import MassDeclineLenderModal from './MassDeclineLenderModal';

export default function LoanEnquiryContextMenuWidget(props) {
    const {
        toggleMessageDrawer,
        handleTabSelect,
        toggleLenderTermsMatrixModal,
        selectedLenders,
        setModalComponent,
        setModalClose,
    } = props;

    return (
        <>
            <div className="loan-enquiry-context-menu">
                <RollupCommandContainer>
                    <PrimaryButton
                        onClick={() => {
                            toggleMessageDrawer();
                            handleTabSelect();
                        }}
                        disabled={null === selectedLenders || selectedLenders.length === 0}
                    >
                        <strong>Send to</strong>&nbsp; selected Lenders
                    </PrimaryButton>
                    <PrimaryButton onClick={() => toggleLenderTermsMatrixModal(true)}>
                        <strong>Export</strong>&nbsp; Terms Matrix
                    </PrimaryButton>
                    <PrimaryButton
                        onClick={() => {
                            setModalComponent(
                                <MassDeclineLenderModal
                                    setModalClose={setModalClose}
                                    selectedLenders={selectedLenders}
                                />
                            );
                        }}
                        disabled={null === selectedLenders || selectedLenders.length === 0}
                    >
                        <strong>Decline</strong>&nbsp; all selected Lenders
                    </PrimaryButton>
                </RollupCommandContainer>
            </div>
        </>
    );
}
