import React from 'react';
import styled from 'styled-components';
import { Container as LayoutContainer } from 'pitchblack_react_utils/layout/index';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography/Typography';
import PropTypes from 'prop-types';

const Heading = styled(Typography)`
    margin-bottom: 15px !important;
`;

const Panel = styled(ExpansionPanel)`
    width: 100%;
`;

class Explorer extends React.Component {
    componentDidMount() {
        let { expanded = null } = this.props;
        this.setState({
            expanded,
        });
    }

    handleChange = (event, expanded) => {
        this.setState({
            expanded: expanded,
        });
    };

    render() {
        let { documents, header } = this.props;

        return (
            <Panel expanded={this.state.expanded} onChange={this.handleChange}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div>
                        <Heading variant="subtitle1">{header()}</Heading>
                    </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <LayoutContainer>{documents()}</LayoutContainer>
                </ExpansionPanelDetails>
            </Panel>
        );
    }
}

Explorer.propTypes = {
    documents: PropTypes.func.isRequired,
    header: PropTypes.func.isRequired,
    expanded: PropTypes.bool,
};

export default Explorer;
