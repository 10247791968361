import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { handleDownload } from '../../../../../../pitch4_documents/src/components/DownloadButton';
import { Loading } from '../../../../../../pitch4_layout/components/waiting';

export default function DownloadCreditConsentButton(props) {
    const { setModalComponent, setModalClose, document, index } = props;
    const [downloading, setDownloading] = useState(false);

    return (
        <>
            <Button
                variant={'contained'}
                color={'primary'}
                size={'small'}
                style={{ marginBottom: 5, width: 140, height: 25 }}
                onClick={() => {
                    setDownloading(true);
                    handleDownload(document, {
                        onPending: () => setModalComponent(<Loading heading="Please wait. Downloading file." />, false),
                        onSuccess: () => {
                            setDownloading(false);
                            setModalClose();
                        },
                        onFailure: () => {
                            setDownloading(false);
                            setModalClose();
                        },
                    });
                }}
            >
                {true === downloading ? (
                    <CircularProgress style={{ color: '#f1f3f4' }} size={18} />
                ) : (
                    `Download Form #${index + 1}` // index is zero based array index rom map function
                )}
            </Button>
            <div style={{ marginBottom: 10 }}>
                <small>{document.fileName}</small>
            </div>
        </>
    );
}
