import React from 'react';
import { reset, change, reduxForm } from 'redux-form';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { TextField, TextLabel, FieldRow } from 'pitch4_forms/components/inputs';
import { Container, RowItem } from 'pitch4_layout/components/div';
import { isRequired, hasMaxLength255, hasMinLength8 } from 'pitchblack_react_utils/form/validation';
import { PrimaryButton, SecondaryButton } from 'pitch4_layout/components/buttons';
import { ServerErrors } from 'pitch4_forms/components';
import { PasswordGenerator } from '../../../../../authentication/utils/PasswordGenerator';
import { sagaActionNotificationSetSuccess } from '../../../../../notifications/sagas/container';
import { RightAligned } from '../../../../../../pitch4_layout/components/div';

const ChangeUserPasswordForm = 'administer_users_change_password_form';

const passwordGenerator = new PasswordGenerator();

class ChangePasswordForm extends React.Component {
    render() {
        let { onSubmit, submitting, handleSubmit, error = [], reset } = this.props;

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container>
                    <RowItem>
                        <ServerErrors errors={error} visible={true} />
                    </RowItem>
                    <FieldRow
                        label={<TextLabel label="New password" />}
                        input={
                            <TextField
                                name="newPassword"
                                disabled={true}
                                validate={[isRequired, hasMinLength8, hasMaxLength255]}
                                type={`text`}
                            />
                        }
                    />
                    <RightAligned className={'button-panel create-reset-buttons'}>
                        <PrimaryButton disabled={submitting} onClick={this.handleGenerateClick}>
                            Generate Password
                        </PrimaryButton>
                        <SecondaryButton disabled={submitting} onClick={reset}>
                            Reset
                        </SecondaryButton>
                        <PrimaryButton type="submit" disabled={submitting}>
                            Submit
                        </PrimaryButton>
                    </RightAligned>
                </Container>
            </form>
        );
    }

    handleGenerateClick = () => {
        let { changePassword, notificationSuccess } = this.props;
        const newPassword = passwordGenerator.generatePassword(8, 10);
        changePassword(newPassword);
        navigator.clipboard.writeText(newPassword);
        notificationSuccess('Password copied to clipboard. Click Save to use this new password.');
    };
}

const afterSubmit = (result, dispatch) => dispatch(reset(ChangeUserPasswordForm));

const mapDispatchToProps = (dispatch) => {
    return {
        changePassword: (password) => {
            dispatch(change(ChangeUserPasswordForm, 'newPassword', password));
        },
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
    };
};

ChangePasswordForm.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    reset: PropTypes.func,
    initialValues: PropTypes.any,
};

export default compose(
    connect(null, mapDispatchToProps),
    reduxForm({
        form: ChangeUserPasswordForm,
        onSubmitSuccess: afterSubmit,
        enableReinitialize: true,
    })
)(ChangePasswordForm);
