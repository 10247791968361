import { BaseEnum } from 'pitch4_enum';

export const TYPE_EMPLOYED = 'employed';
export const TYPE_SELF_EMPLOYED = 'self_employed';

class BorrowerEmploymentStatusEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_EMPLOYED]: 'Employed',
            [TYPE_SELF_EMPLOYED]: 'Self employed',
        };
    };
}

export default BorrowerEmploymentStatusEnum;
