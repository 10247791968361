import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import FormHelperText from '@material-ui/core/FormHelperText';

/**
 * Auto-complete component which provides a filterable drop-down input.
 *
 * @see https://material-ui.com/demos/autocomplete/#downshift
 * @see https://github.com/downshift-js/downshift
 */
class Autocomplete extends React.Component {
    constructor(props) {
        super(props);

        // Reference to the Popper component. This is currently the only way to get access to the internal Popper.js
        // instance, which is necessary to ensure that the position of the popper is recalculated when the suggestions
        // are filtered.
        this.popperRef = React.createRef();

        // The input element that Popper will anchor to.
        this.popperAnchorEl = null;

        // Number of items to display before overflow scroll kicks in
        this.defaultDisplayNumItems = 6;

        // Used as the default selectedItem
        this.defaultItem = { value: '', label: '' };

        // When displaying option groups this array tracks which groups have already been rendered.
        this.displayedGroups = [];

        // Store the selected item in local state
        this.state = {
            selectedItem: this.defaultItem,
        };
    }

    componentDidMount() {
        const { input } = this.props;

        if (input.value && !this.state.selectedItem.value) {
            this.setState({ selectedItem: this.getSelectedItem(input) });
        }
    }

    render() {
        const {
            input,
            meta: { error, touched },
            renderer = null,
        } = this.props;

        return (
            <Downshift
                {...input}
                selectedItem={this.getSelectedItem(input)}
                itemToString={(item) => `${item.label}` || ''}
                onChange={(selectedItem) => {
                    if (selectedItem) {
                        input.onChange(selectedItem.value);
                        this.setState({ selectedItem: selectedItem });
                    }
                }}
                onInputValueChange={(newValue) => {
                    const item = this.getItemByLabel(newValue);

                    // TODO: When the fix here is merged https://github.com/mui-org/material-ui/issues/13179, update the Popper library and call the updatePosition() function here.

                    // Update the selected item
                    if (item && item.value !== this.state.selectedItem.value) {
                        this.setState({ selectedItem: item });
                    } else if (newValue === '') {
                        this.setState({ selectedItem: this.defaultItem });
                    }
                }}
            >
                {(props) => {
                    const { fullWidth = false } = this.props;

                    if (renderer !== null) {
                        return renderer({ ...props }, () =>
                            this.defaultRender({
                                ...props,
                                error,
                                touched,
                                fullWidth,
                            })
                        );
                    }

                    return this.defaultRender({
                        ...props,
                        error,
                        touched,
                        fullWidth,
                        input,
                    });
                }}
            </Downshift>
        );
    }

    defaultRender({
        getInputProps,
        getItemProps,
        getMenuProps,
        highlightedIndex,
        inputValue,
        isOpen,
        selectedItem,
        closeMenu,
        openMenu,
        clearSelection,
        reset,
        selectItem,
        error,
        touched,
        fullWidth,
    }) {
        const classNames = 'input-autocomplete' + (fullWidth ? ' input-autocomplete-fullwidth' : '');

        return (
            <div className={classNames}>
                {this.renderInput(getInputProps, isOpen, clearSelection, openMenu, closeMenu, reset, selectItem)}
                {this.renderDropdown(
                    inputValue,
                    isOpen,
                    getMenuProps,
                    getItemProps,
                    highlightedIndex,
                    selectedItem,
                    reset,
                    closeMenu
                )}
                {error && touched && <FormHelperText>{error}</FormHelperText>}
            </div>
        );
    }

    /**
     * Get the selected item.
     *
     * @param input
     * @returns {*}
     */
    getSelectedItem = (input) => {
        const { defaultValue } = this.props;
        let inputVal = _.isArray(input.value) ? input.value[0] : input.value;

        if (this.state.selectedItem.value !== '' && inputVal === this.state.selectedItem.value) {
            return this.state.selectedItem;
        } else if (!!inputVal === false && !!defaultValue) {
            inputVal = defaultValue;
        }

        let matches = this.props.suggestions.filter((suggestion) => {
            return suggestion.value === inputVal;
        });

        return matches.length > 0 ? matches[0] : this.defaultItem;
    };

    /**
     * Get an item by label.
     *
     * @param label
     * @returns {null}
     */
    getItemByLabel = (label) => {
        let matches = this.props.suggestions.filter((suggestion) => {
            return suggestion.label === label;
        });

        return matches.length > 0 ? matches[0] : null;
    };

    /**
     * Render the input element and dropdown icon.
     *
     * @param getInputProps
     * @param isOpen
     * @param clearSelection
     * @param openMenu
     * @param closeMenu
     * @param reset
     * @param selectItem
     * @returns {*}
     */
    renderInput = (getInputProps, isOpen, clearSelection, openMenu, closeMenu, reset, selectItem) => {
        const {
            input,
            disabled = false,
            style = {},
            fullWidth = false,
            inputProps = {},
            meta: { error, touched },
        } = this.props;

        let inputClassNames = 'input-text-field';

        if (disabled === true) {
            inputClassNames += ' input-text-field-disabled';
        }

        if (error && touched) {
            inputClassNames += ' input-text-field-error';
        }

        if (input.value === '' || null === input.value) {
            inputClassNames += ' input-text-field-empty-value';
        }

        return (
            <div className={`input-text-field-wrapper`} id={'input-text-field-wrapper'}>
                <TextField
                    InputProps={{
                        inputRef: (node) => {
                            this.popperAnchorEl = node;
                        },
                        ...getInputProps({
                            placeholder: this.props.placeholder,
                            onClick: () => {
                                disabled === false &&
                                    this.onClick(isOpen, clearSelection, openMenu, closeMenu, selectItem);
                            },
                            disabled,
                        }),
                        className: inputClassNames,
                    }}
                    style={style}
                    fullWidth={fullWidth}
                    {...inputProps}
                    variant={`outlined`}
                />
                {this.state.selectedItem.value && disabled === false && (
                    <ClearIcon
                        title={`Clear selection`}
                        className={`clear-icon`}
                        onClick={() => {
                            selectItem(this.defaultItem);
                            input.onChange(this.defaultItem.value);
                        }}
                    />
                )}
                <ArrowDropDownIcon
                    className={`arrow-dropdown`}
                    onClick={() => {
                        disabled === false && this.onClick(isOpen, clearSelection, openMenu, closeMenu, selectItem);
                    }}
                />
            </div>
        );
    };

    /**
     * Called when either the input element or dropdown arrow are clicked.
     *
     * @param isOpen
     * @param clearSelection
     * @param openMenu
     * @param closeMenu
     * @param selectItem
     */
    onClick = (isOpen, clearSelection, openMenu, closeMenu, selectItem) => {
        if (isOpen === false) {
            this.popperAnchorEl.focus();
            selectItem(this.defaultItem);
            openMenu();
        } else {
            selectItem(this.defaultItem);
            closeMenu();
        }
    };

    /**
     * Render the dropdown.
     *
     * @see https://material-ui.com/demos/autocomplete/#downshift (With Popper)
     * @see https://popper.js.org/popper-documentation.html
     *
     * @param inputValue
     * @param isOpen
     * @param getMenuProps
     * @param getItemProps
     * @param highlightedIndex
     * @param selectedItem
     * @param reset
     * @param closeMenu
     * @returns {*}
     */
    renderDropdown = (
        inputValue,
        isOpen,
        getMenuProps,
        getItemProps,
        highlightedIndex,
        selectedItem,
        reset,
        closeMenu
    ) => {
        const { callToActionItem = {} } = this.props;

        const matchedSuggestions = this.getSuggestions(inputValue);

        // Reset state for displayed groups every render() call.
        this.displayedGroups = [];

        // Render the matched items to allow us to calculate the drop down height (with groups)
        let items = [];
        matchedSuggestions.forEach((suggestion, index) => {
            items.push(
                this.renderItem(
                    inputValue,
                    suggestion,
                    index,
                    getItemProps({ item: suggestion }),
                    highlightedIndex,
                    selectedItem
                )
            );
        });

        // Add a call-to-action item if configured
        const callToActionMenuItem = this.getCallToActionItem(items, callToActionItem, getItemProps, reset, closeMenu);

        // Making the 'key' equal to inputValue is a temporary workaround to force a remount of the Popper when the input changes.
        // This is until the fix here has been merged and we can then call the updatePosition() method to do this:
        //     https://github.com/mui-org/material-ui/issues/13179
        // Once that fix is in remove the key={inputValue} below.
        return (
            <Popper
                key={inputValue}
                ref={this.popperRef}
                open={isOpen}
                anchorEl={this.popperAnchorEl}
                className={`input-dropdown-wrapper`}
            >
                <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
                    <Paper
                        square
                        style={{ minWidth: this.popperAnchorEl ? this.popperAnchorEl.clientWidth : null }}
                        elevation={4}
                        className={`input-dropdown`}
                    >
                        <div
                            className={`input-dropdown-scrollable-items`}
                            style={{ height: this.calculateDropdownHeight(matchedSuggestions) }}
                        >
                            {items}
                        </div>
                        <div>{callToActionMenuItem}</div>
                    </Paper>
                </div>
            </Popper>
        );
    };

    /**
     * Render a single suggestion.
     *
     * @param inputValue
     * @param suggestion
     * @param index
     * @param itemProps
     * @param highlightedIndex
     * @param selectedItem
     * @returns {*}
     */
    renderItem = (inputValue, suggestion, index, itemProps, highlightedIndex, selectedItem) => {
        const { groups = false } = this.props;

        if (groups === true && !!suggestion.group) {
            if (this.displayedGroups.indexOf(suggestion.group, 0) === -1) {
                // Store the group so it isn't rendered more than once
                this.displayedGroups.push(suggestion.group);

                return (
                    <React.Fragment key={index}>
                        <div key={suggestion.group} className={`input-dropdown-item-group`}>
                            {suggestion.group}
                        </div>
                        {this.getMenuItem(inputValue, suggestion, index, itemProps, highlightedIndex, selectedItem)}
                    </React.Fragment>
                );
            }
        }

        return this.getMenuItem(inputValue, suggestion, index, itemProps, highlightedIndex, selectedItem);
    };

    /**
     * Build a MenuItem component.
     *
     * @param inputValue
     * @param suggestion
     * @param index
     * @param itemProps
     * @param highlightedIndex
     * @param selectedItem
     * @returns {*}
     */
    getMenuItem = (inputValue, suggestion, index, itemProps, highlightedIndex, selectedItem) => {
        const isHighlighted = index === highlightedIndex;
        const isSelected = !!selectedItem && selectedItem.selectedIndex === suggestion.selectedIndex;
        const classNames = 'input-dropdown-item' + (isSelected === true ? ' input-dropdown-item-selected' : '');

        // Split the label into the matched part and unmatched part.
        const labelHighlight = suggestion.label.substr(0, inputValue.length);
        const labelNoHighlight = suggestion.label.substr(inputValue.length);
        const key = suggestion.label + index;

        return (
            <MenuItem key={key} selected={isHighlighted} component="div" className={classNames} {...itemProps}>
                <span className={`input-dropdown-item-label`}>
                    <span className={`input-dropdown-item-label-highlight`}>{labelHighlight}</span>
                    {labelNoHighlight}
                </span>
            </MenuItem>
        );
    };

    /**
     * Get all suggestions that match the current input value.
     *
     * If 'this.props.partials' is false this always returns all suggestions (for select interface).
     *
     * @param value
     * @returns {*}
     */
    getSuggestions = (value) => {
        const { partials = true, suggestions } = this.props;

        // Map a unique index to each suggestion in case there are multiple suggestions with the same value.
        suggestions.forEach((suggestion, index) => {
            suggestion.selectedIndex = index;
        });

        // Sort suggestions by group name (if applicable) then label
        this.sortSuggestions(suggestions);

        if (partials !== true) {
            return suggestions;
        }

        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0
            ? suggestions
            : suggestions.filter((suggestion) => {
                  const suggestionPartial = suggestion.label.slice(0, inputLength).toLowerCase();

                  return suggestionPartial === inputValue;
              });
    };

    /**
     * Add a call to action item to the dropdown.
     *
     * @param items
     * @param callToActionItem
     * @param getItemProps
     * @param reset
     * @param closeMenu
     */
    getCallToActionItem = (items, callToActionItem, getItemProps, reset, closeMenu) => {
        if (_.isEmpty(callToActionItem)) {
            return;
        }

        const { content = null, onClick = null } = callToActionItem;

        if (content === null || onClick === null) {
            return;
        }

        const key = items.length + 1;
        const itemProps = getItemProps({
            item: null,
            onClick: () => {
                closeMenu();
                reset();
                onClick();
            },
        });

        return (
            <MenuItem
                key={'cta-' + key}
                selected={false}
                component="div"
                className={`input-dropdown-item`}
                {...itemProps}
            >
                <span className={`input-dropdown-item-label`}>{content}</span>
            </MenuItem>
        );
    };

    /**
     * Sort suggestions by group name and then label.
     *
     * @param suggestions
     */
    sortSuggestions = (suggestions) => {
        const { groups = false, sortLabels = true } = this.props;

        suggestions.sort((a, b) => {
            if (groups === true && !!a['group'] && !!b['group']) {
                if (sortLabels === true) {
                    // Sort by group and then label
                    return (
                        (a['group'] > b['group'] ? 1 : a['group'] < b['group'] ? -1 : 0) ||
                        (a['label'] > b['label'] ? 1 : a['label'] < b['label'] ? -1 : 0)
                    );
                } else {
                    // Sort by group only
                    return a['group'] > b['group'] ? 1 : a['group'] < b['group'] ? -1 : 0;
                }
            } else if (groups === true) {
                // Push items without a 'group' to the bottom of the list
                return !!b['group'] === false ? -1 : !!a['group'] === false ? 1 : 0;
            } else if (sortLabels === true) {
                // Sort by label only
                return a['label'] > b['label'] ? 1 : a['label'] < b['label'] ? -1 : 0;
            }

            return 0;
        });
    };

    /**
     * Based on the value of this.props.displayNumItems, calculate the desired height for the dropdown.
     *
     * When we have more suggestions that displayNumItems the dropdown becomes scrollable.
     *
     * @param matchedSuggestions
     * @returns {string|null}
     */
    calculateDropdownHeight = (matchedSuggestions) => {
        const { displayNumItems = this.defaultDisplayNumItems } = this.props;

        if (matchedSuggestions.length <= displayNumItems) {
            return null;
        }

        // Determine how many unique group names we have in the set of items up to displayNumItems
        const slice = matchedSuggestions.slice(0, displayNumItems);
        const pluckedGroups = slice.map((item) => item['group']);
        const uniqueGroups = pluckedGroups.filter((item, index) => {
            return !!item && pluckedGroups.indexOf(item) === index;
        });

        return `${(displayNumItems + uniqueGroups.length) * 48}px`;
    };
}

Autocomplete.propTypes = {
    /**
     * An array of objects in the form:
     *
     * [
     *   { value: 'val1', label: 'Label 1' },
     *   { value: 'val2', label: 'Label 2' },
     *   ...,
     * ]
     */
    suggestions: PropTypes.array.isRequired,

    /**
     * (optional)
     *
     * Overrides the default item (no selection).
     */
    defaultItem: PropTypes.object,

    /**
     * (optional)
     *
     * Additional props to pass through to the text input element.
     */
    inputProps: PropTypes.object,

    /**
     * (optional)
     *
     * The number of menu items to display (excluding the callToActionItem if set)
     */
    displayNumItems: PropTypes.number,

    /**
     * (optional)
     *
     * If false the autocomplete will act more like a native select input and not match suggestions against the input value.
     */
    partials: PropTypes.bool,

    /**
     * (optional)
     *
     * Flag indicating whether or not to group items.
     *
     * Each of the given suggestions must also have a 'group' label attribute like so:
     *
     * [
     *   { value: 1, label: 'First Label', group: 'First Group' },
     *   { value: 2, label: 'Second Label', group: 'Second Group' },
     *   { value: 3, label: 'Third Label', group: 'Second Group' },
     *   ...
     * ]
     */
    groups: PropTypes.bool,

    /**
     * (optional)
     *
     * Flag denoting whether the input should expand to fill the full width of its parent container.
     */
    fullWidth: PropTypes.bool,

    /**
     * (optional)
     *
     * If false the autocomplete will display items as they are passed in.
     */
    sortLabels: PropTypes.bool,

    /**
     * (optional)
     *
     * Flag to disable the input.
     */
    disabled: PropTypes.bool,

    /**
     * (optional)
     *
     * Default value for the input
     */
    defaultValue: PropTypes.string,

    /**
     * (optional)
     *
     * A menu item always visible at the bottom of the list which when clicked will run the given handler.
     *
     * This must be an object with the following properties:
     *
     * - content (string|element)
     * - onClick (function)
     */
    callToActionItem: PropTypes.object,
};

export default Autocomplete;
