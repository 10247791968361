import React from 'react';

import { TableHead as MTableHead } from '@material-ui/core';

class TableHead extends React.Component {
    render() {
        let { children, ...other } = this.props;

        return <MTableHead {...other}>{children}</MTableHead>;
    }
}

export default TableHead;
