import { BaseEnum } from '../../pitch4_enum';

export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN';
export const ROLE_INTERMEDIARY = 'ROLE_INTERMEDIARY';
export const ROLE_ADMIN = 'ROLE_ADMIN';
export const ROLE_SLAM = 'ROLE_SLAM';
export const ROLE_BORROWER = 'ROLE_BORROWER';

class RoleNameEnum extends BaseEnum {
    getTypes = () => {
        return {
            [ROLE_SUPER_ADMIN]: 'Super Admin',
            [ROLE_ADMIN]: 'Admin',
            [ROLE_INTERMEDIARY]: 'Intermediary',
            [ROLE_SLAM]: 'Super Lender Account Manager',
            [ROLE_BORROWER]: 'Borrower',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default RoleNameEnum;
