import React from 'react';
import TermsAndFeesEnum from '../../pitch4_enum/enum/TermsAndFeesEnum';
import { LOAN_TYPE_BUSINESS_FINANCE } from '../../pitch4_enum/enum/LoanTypeEnum';
import { getStoredDataValueFromArray } from '../../apps/view_loan_enquiry/HelperFunctions';
import { LOAN_TYPE } from '../../pitch4_enum/enum/FactEnum';
import { Grid } from '@material-ui/core';
import { getValue, getTermName } from './Utils';

export default function TopTerms(props) {
    const { matchSelected, loanEnquiry } = props;
    const termsEnum = new TermsAndFeesEnum();
    const product = getStoredDataValueFromArray(loanEnquiry.storedData, LOAN_TYPE);
    const getDefaultProduct = () => (product === LOAN_TYPE_BUSINESS_FINANCE ? 'business-finance' : 'term-loan');
    const getDefaultTerms = () => termsEnum.getType(getDefaultProduct(product)).terms;
    const terms = !matchSelected.lenderTerms ? getDefaultTerms() : JSON.parse(matchSelected.lenderTerms).terms;
    const topTerms = terms.filter((item) => item.termType === 'top');

    return (
        <div className={'top-terms-container'}>
            <Grid container>
                {topTerms.map((term, index) => {
                    return (
                        <Grid item lg={4} md={4} sm={4} xs={4} className={'term-item'} key={index}>
                            <div className={'name'}>{getTermName(term)}</div>
                            <div className={`value ${!term.value ? 'empty' : ''}`}>{getValue(term)}</div>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
}
