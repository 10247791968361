import React from 'react';
import { compose } from 'recompose';
import _ from 'lodash';
import { connect } from 'react-redux';
import { path, ROUTE_SLAM_BUSINESS_STREAM_EDIT } from '../../../../../pitch4_routing';
import { withRouter } from 'react-router';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SLAM } from '../../../../authentication/enum/Roles/NameEnum';
import { Loading } from '../../../../../pitch4_layout/components/waiting';
import {
    actionGetBusinessStream,
    actionGetMatchingCriteriaConfig,
    actionGetStoreDataMeta,
    NAMESPACE,
    selectorGetBusinessStream,
    selectorGetStoredDataMetaValues,
} from '../redux';
import {
    actionSetInitialValues,
    selectorGetConfig,
    selectorGetFormHeadings,
    selectorGetInitialValues,
} from '../../../../form_wizard/redux';
import { Container, Item } from '../../../../../pitchblack_react_utils/layout';
import { ScreenTitle } from '../../../../../pitch4_layout';
import { PrimaryRedirectButton } from '../../../../../pitch4_layout/components/buttons';
import { P4StyleComponentContainer } from '../../../../../pitch4_layout/components/div';
import { KeyValueTable, KeyValueTableRow } from '../../../../../pitch4_tabular/components/kvtable';
import Chip from '../../../../../pitch4_forms/components/inputs/Chip';
import { EnumLabel } from '../../../../../pitch4_layout/components/data';
import StatusEnum, { STATUS_ACTIVE, STATUS_INACTIVE } from '../../enum/BusinessStreams/StatusEnum';
import { DateTimeFormatter } from '../../../../../pitch4_layout/components/data/formatter';
import BusinessStreamSummaryTable from '../components/BusinessStreamSummaryTable';
import ToggleStatusButton from '../components/ToggleStatusButton';
import { SLAM_BUSINESS_STREAM_CREATION } from '../../../../joyride/config';
import { actionSetJoyrideType } from '../../../../joyride/redux';

class ViewContainer extends React.Component {
    componentDidMount() {
        const {
            clearBusinessStream,
            getBusinessStream,
            getStoredDataMeta,
            getMatchingCriteriaConfig,
            match,
            setJoyrideType,
        } = this.props;

        clearBusinessStream();
        getBusinessStream(match.params.businessStreamId);
        getStoredDataMeta();
        getMatchingCriteriaConfig();
        setJoyrideType(SLAM_BUSINESS_STREAM_CREATION);
    }

    render() {
        const {
            formSections = {},
            businessStream = {},
            storedDataMetas = {},
            matchingCriteriaConfig = {},
            getBusinessStream,
            match,
            formHeadings,
        } = this.props;

        if (
            _.isEmpty(formSections) ||
            _.isEmpty(businessStream) ||
            _.isEmpty(storedDataMetas) ||
            _.isEmpty(matchingCriteriaConfig)
        ) {
            return <Loading />;
        }

        let statusColor = businessStream.status === STATUS_ACTIVE ? 'primary' : 'secondary';
        let toggleButtonLabel = businessStream.status === STATUS_ACTIVE ? 'Turn Stream Off' : 'Turn Stream On';

        return (
            <div className={`business-stream-view`}>
                <Container
                    className={`business-stream-view-header`}
                    justifyContent={`space-between`}
                    alignItems={`center`}
                    spacing={4}
                >
                    <Item>
                        <ScreenTitle title={businessStream.name} />
                    </Item>
                    <Item>
                        <ToggleStatusButton
                            id={match.params.businessStreamId}
                            useStatus={businessStream.status === STATUS_ACTIVE ? STATUS_INACTIVE : STATUS_ACTIVE}
                            outerRefetch={() => getBusinessStream(match.params.businessStreamId)}
                            color={statusColor}
                            className={`button-toggle-status`}
                        >
                            {toggleButtonLabel}
                        </ToggleStatusButton>

                        <PrimaryRedirectButton
                            url={path(ROUTE_SLAM_BUSINESS_STREAM_EDIT, {
                                businessStreamId: match.params.businessStreamId,
                            })}
                        >
                            Edit Stream
                        </PrimaryRedirectButton>
                    </Item>
                </Container>
                <Container className={`business-stream-view-content`} justifyContent={`space-between`} spacing={4}>
                    <Item xs={12} md={5}>
                        <P4StyleComponentContainer
                            styles={{ backgroundColor: '#fafafa', border: 'none' }}
                            header={`Information`}
                            content={
                                <KeyValueTable style={{ backgroundColor: '#fafafa', border: 'none' }}>
                                    <KeyValueTableRow term={`Name:`} def={businessStream.name} />
                                    <KeyValueTableRow term={`Number of Parameters:`} def={this.getParameterCount()} />
                                    <KeyValueTableRow
                                        term={`Date & Time Created:`}
                                        def={<DateTimeFormatter value={businessStream.createdAt} />}
                                    />
                                    <KeyValueTableRow
                                        term={`Current Status:`}
                                        def={
                                            <Chip
                                                size="small"
                                                color={statusColor}
                                                label={
                                                    <EnumLabel
                                                        enumIn={new StatusEnum()}
                                                        value={businessStream.status}
                                                    />
                                                }
                                            />
                                        }
                                    />
                                </KeyValueTable>
                            }
                        />
                    </Item>
                    <Item xs={12} md={7}>
                        <P4StyleComponentContainer
                            styles={{ backgroundColor: '#fafafa', border: 'none' }}
                            content={<BusinessStreamSummaryTable formSections={formSections} headings={formHeadings} />}
                        />
                    </Item>
                </Container>
            </div>
        );
    }

    getParameterCount() {
        const { formSections, storedDataMetas } = this.props;

        let count = 0;

        for (let sectionName in formSections) {
            for (let fieldName in formSections[sectionName]) {
                if (!_.isUndefined(storedDataMetas[fieldName])) {
                    count++;
                }
            }
        }

        return count;
    }
}

const mapStateToProps = (state) => {
    return {
        storedDataMetas: selectorGetStoredDataMetaValues(state),
        formSections: selectorGetInitialValues(NAMESPACE)(state),
        businessStream: selectorGetBusinessStream(state),
        matchingCriteriaConfig: selectorGetConfig(NAMESPACE)(state),
        formHeadings: selectorGetFormHeadings(NAMESPACE)(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        clearBusinessStream: () => dispatch(actionSetInitialValues(NAMESPACE)({})),
        getBusinessStream: (businessStreamId) => dispatch(actionGetBusinessStream(businessStreamId)),
        getStoredDataMeta: () => dispatch(actionGetStoreDataMeta()),
        getMatchingCriteriaConfig: () => dispatch(actionGetMatchingCriteriaConfig()),
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SLAM])
)(ViewContainer);
