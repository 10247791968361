import React from 'react';
import propTypes from 'prop-types';
import RouteManager from 'pitch4_routing/src/RouteManager';

import {
    ROUTE_P4A_ADMINISTER_LENDERS_EDIT,
    ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW,
    ROUTE_P4A_ADMINISTER_USERS_EDIT,
    ROUTE_P4A_LLE,
} from 'pitch4_routing/index';
import { PrimaryRedirectButton } from '../../../../../pitch4_layout/components/buttons';

class EventTagLink extends React.Component {
    render() {
        let { data = {}, children } = this.props;
        let routeManager = new RouteManager();
        let href = '#';
        if (data.version === 1 || !data.version) {
            switch (data.type) {
                case 'Document':
                    return <div className={`taglink_not_supported`}>{children}</div>;

                case 'User':
                    href = routeManager.route(ROUTE_P4A_ADMINISTER_USERS_EDIT, { id: data.id });
                    break;

                case 'UserProfile':
                    href = routeManager.route(ROUTE_P4A_ADMINISTER_USERS_EDIT, { id: data.user_profile_id });
                    break;

                case 'Lender':
                    href = routeManager.route(ROUTE_P4A_ADMINISTER_LENDERS_EDIT, { id: data.id });
                    break;

                case 'LenderLoanEnquiry':
                    href = routeManager.route(ROUTE_P4A_LLE, {
                        lenderLoanEnquiryId: data.id,
                        loanEnquiryId: data.loan_enquiry_id,
                    });
                    break;

                case 'LoanEnquiry':
                    href = routeManager.route(ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW, { loanEnquiryId: data.id });
                    break;

                case 'MakeLoanEnquirySubmission':
                    href = routeManager.route(ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW, {
                        loanEnquiryId: data.loan_enquiry_id,
                    });
                    break;

                case 'LenderLoanEnquiryAgreementInPrinciple':
                    href = routeManager.route(ROUTE_P4A_LLE, {
                        lenderLoanEnquiryId: data.lle_id,
                        loanEnquiryId: data.lle_loan_enquiry_id,
                    });
                    break;

                case 'LenderLoanEnquiryAgreementInPrincipleOffer':
                    href = routeManager.route(ROUTE_P4A_LLE, {
                        lenderLoanEnquiryId: data.lle_id,
                        loanEnquiryId: data.lle_loan_enquiry_id,
                    });
                    break;

                default:
                    break;
            }
        }

        //always opens in new tab
        return <PrimaryRedirectButton url={href}>{children}</PrimaryRedirectButton>;
    }
}

EventTagLink.propTypes = {
    children: propTypes.any.isRequired,
    tag: propTypes.object.isRequired,
    data: propTypes.object,
};

export default EventTagLink;
