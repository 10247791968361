import React from 'react';
import { reduxForm, change } from 'redux-form';
import styled from 'styled-components';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { TextField, TextLabel, Select, FieldRow } from 'pitch4_forms/components/inputs';
import { Container, RowItem } from 'pitch4_layout/components/div';
import {
    isRequired,
    hasMinLength2,
    hasMaxLength255,
    isValidPostcode,
    hasMinLength6,
    hasMaxLength30,
} from 'pitchblack_react_utils/form/validation';
import { PrimaryButton, SecondaryButton } from 'pitch4_layout/components/buttons';
import { ServerErrors } from 'pitch4_forms/components';
import { createOptionsFromEnum } from 'pitch4_forms/components/inputs/select/index';
import TitleEnum from '../../../../enum/UserProfile/TitleEnum';
import RoleNameEnum, { ROLE_SLAM, ROLE_INTERMEDIARY } from 'pitch4_enum/enum/RoleNameEnum';
import { DateField } from 'pitch4_forms/components/inputs';
import LenderSelect from '../../../administer_lenders/components/LenderSelect';
import BrokerSelect from '../../../administer_brokers/components/BrokerSelect';
import { validateSuperAdmins } from '../validation/validateSuperAdmins';
import { actionGetLenders, selectorGetLenders, actionGetBrokers, selectorGetBrokers } from '../redux/form';
import { RightAligned } from '../../../../../../pitch4_layout/components/div';

const SpacerItem = styled(RowItem)``;

const FORM_NAME = 'administer_users_create_edit_form';

class UserForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLenders: false,
            showBrokers: false,
        };
    }

    componentDidMount() {
        const { getLenders, getBrokers } = this.props;
        getLenders();
        getBrokers();
    }

    render() {
        let {
            dispatch,
            onSubmit,
            submitting,
            handleSubmit,
            error = [],
            reset,
            lenders = {},
            brokers = {},
            getLenders,
            getBrokers,
            initialValues = {},
            disableRole = false,
        } = this.props;

        const { showLenders = false, showBrokers = false } = this.state;

        if (showLenders === false && initialValues['primaryRole'] === ROLE_SLAM) {
            this.setState({ showLenders: true });
        } else if (showBrokers === false && initialValues['primaryRole'] === ROLE_INTERMEDIARY) {
            this.setState({ showBrokers: true });
        }

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container>
                    <RowItem>
                        <ServerErrors errors={error} visible={true} />
                    </RowItem>
                    {initialValues['id'] && (
                        <FieldRow
                            label={<TextLabel label="ID" />}
                            input={<TextField hidden={true} name="id" placeholder="ID" disabled={true} />}
                        />
                    )}
                    <FieldRow
                        label={<TextLabel label="Email/Username" />}
                        input={<TextField hidden={true} name="email" placeholder="Email" />}
                    />
                    {initialValues['id'] && (
                        <FieldRow
                            label={<TextLabel label="Created at" />}
                            input={
                                <TextField hidden={true} name="createdAt" placeholder="Created at" disabled={true} />
                            }
                        />
                    )}
                    {initialValues['id'] && (
                        <FieldRow
                            label={<TextLabel label="Updated at" />}
                            input={
                                <TextField hidden={true} name="updatedAt" placeholder="Updated at" disabled={true} />
                            }
                        />
                    )}
                    <FieldRow
                        label={<TextLabel label="Role" />}
                        input={
                            <Select
                                name="primaryRole"
                                sortLabels={false}
                                placeholder="Role"
                                style={{ padding: 0 }}
                                validate={[isRequired]}
                                suggestions={createOptionsFromEnum(new RoleNameEnum())}
                                disabled={disableRole}
                                onChange={(e, value) => {
                                    if (value === ROLE_SLAM) {
                                        this.setState({ showLenders: true, showBrokers: false });
                                    } else if (value === ROLE_INTERMEDIARY) {
                                        this.setState({ showBrokers: true, showLenders: false });
                                    } else {
                                        this.setState({ showLenders: false, showBrokers: false });
                                    }
                                }}
                            />
                        }
                    />
                    {showLenders === true && (
                        <LenderSelect
                            lenders={lenders}
                            onSuccessFunc={(data) => {
                                getLenders();

                                const lenderId = _.get(data, ['createLender', 'lender', 'id']);

                                if (lenderId) {
                                    dispatch(change(FORM_NAME, 'lender', lenderId));
                                }
                            }}
                            submitting={submitting}
                        />
                    )}
                    {showBrokers === true && (
                        <BrokerSelect
                            brokers={brokers}
                            onSuccessFunc={(data) => {
                                getBrokers();

                                const brokerId = _.get(data, ['createBroker', 'broker', 'id']);

                                if (brokerId) {
                                    dispatch(change(FORM_NAME, 'broker', brokerId));
                                }
                            }}
                            submitting={submitting}
                        />
                    )}
                    <FieldRow
                        label={<TextLabel label="Title" />}
                        input={
                            <Select
                                name="title"
                                placeholder="Title"
                                style={{ padding: 0 }}
                                disabled={submitting}
                                validate={[isRequired]}
                                suggestions={createOptionsFromEnum(new TitleEnum())}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Given Name" />}
                        input={
                            <TextField
                                name="given"
                                placeholder="Given name"
                                disabled={submitting}
                                validate={[isRequired, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Family Name" />}
                        input={
                            <TextField
                                name="family"
                                placeholder="Family name"
                                disabled={submitting}
                                validate={[isRequired, hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 1" />}
                        input={
                            <TextField
                                name="primaryAddressAddress1"
                                placeholder="Address 1"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 2" />}
                        input={
                            <TextField
                                name="primaryAddressAddress2"
                                placeholder="Address 2"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 3" />}
                        input={
                            <TextField
                                name="primaryAddressAddress3"
                                placeholder="Address 3"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 4" />}
                        input={
                            <TextField
                                name="primaryAddressAddress4"
                                placeholder="Address 4"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Country" />}
                        input={
                            <TextField
                                name="primaryAddressCountry"
                                placeholder="Country"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Postcode" />}
                        input={
                            <TextField
                                name="primaryAddressPostcode"
                                placeholder="Postcode"
                                disabled={submitting}
                                validate={[isValidPostcode]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Phone" />}
                        input={
                            <TextField
                                name="primaryPhoneNumber"
                                placeholder="Number"
                                disabled={submitting}
                                validate={[hasMinLength6, hasMaxLength30]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Date Of Birth" />}
                        input={
                            <DateField
                                name="dateOfBirth"
                                placeholder="DOB [dd/mm/yyyy]"
                                disabled={submitting}
                                validate={[isRequired]}
                                disableFuture={true}
                                autoOk={true}
                            />
                        }
                    />
                    {initialValues['id'] && (
                        <FieldRow
                            label={<TextLabel label="Job Title" />}
                            input={
                                <TextField
                                    name="jobTitle"
                                    placeholder="Job title"
                                    disabled={submitting}
                                    validate={[hasMinLength2, hasMaxLength255]}
                                />
                            }
                        />
                    )}
                    <SpacerItem />
                    <RightAligned className={'button-panel create-reset-buttons'}>
                        <SecondaryButton disabled={submitting} onClick={reset}>
                            Reset
                        </SecondaryButton>
                        <PrimaryButton type="submit" disabled={submitting}>
                            Submit
                        </PrimaryButton>
                    </RightAligned>
                </Container>
            </form>
        );
    }
}

UserForm.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    reset: PropTypes.func,
    initialValues: PropTypes.any,
    disableRole: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {
        lenders: selectorGetLenders(state),
        brokers: selectorGetBrokers(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getLenders: () => dispatch(actionGetLenders()),
        getBrokers: () => dispatch(actionGetBrokers()),
    };
};

export const globalValidator = (data) => {
    let errors = {};

    validateSuperAdmins(data, errors);

    return errors;
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate: globalValidator,
    })
)(UserForm);
