import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';
//Network Brokers routes
import {
    ROUTE_ANON_LOGIN,
    ROUTE_ANON_LOGIN_ACCESS_FINANCIAL_SERVICES,
    ROUTE_ANON_LOGIN_CONNECT,
    ROUTE_ANON_LOGIN_WARREN_FINANCE_LTD,
    ROUTE_ANON_LOGIN_WEALTHMAX,
    ROUTE_ANON_LOGIN_GIFTWARE,
    ROUTE_BORROWER_REGISTRATION_DEFAULT,
} from '../../pitch4_routing';
import { Container } from '../../pitchblack_react_utils/layout';
import provideLogo from '../../assets/images/logo.svg';
//Network Broker logo
import connectLogo from '../../assets/images/networks/connect.png';
import wealthmaxLogo from '../../assets/images/networks/wealthmax.png';
import warrenFinanceLtdLogo from '../../assets/images/networks/warren-finance-ltd.png';
import accessFinancialServicesLogo from '../../assets/images/networks/access-financial-services.png';
import giftwareLogo from '../../assets/images/networks/giftware.png';
import { CenteringContainer } from '../../pitch4_layout/components/div';
import { selectorBorrowerRegisterStep } from '../../apps/registration/redux/step';
import { BRAND_NAME } from '../../bootstrap/constants/constants';
import { deleteNetworkLoginCookie, getNetworkLoginCookie, setNetworkLoginCookie } from '../../pitch4_utils/LoginCookie';
import {
    NETWORK_BROKER_CONNECT,
    NETWORK_BROKER_WEALTHMAX,
    NETWORK_BROKER_WARREN_FINANCE_LTD,
    NETWORK_BROKER_ACCESS_FINANCIAL_SERVICES,
    NETWORK_BROKER_GIFTWARE,
} from '../../pitch4_enum/enum/NetworkBrokerEnum';

const AuthenticationLayout = ({ children, notifications, match, step }) => {
    const routeClassName =
        match.path === ROUTE_BORROWER_REGISTRATION_DEFAULT && step ? `${match.path.replace('/', '')}-step-${step}` : '';
    const path = match.path.replace('/', '');
    let logo;
    let loginUrl;
    //Network Brokers array
    const networkBrokers = [
        NETWORK_BROKER_CONNECT,
        NETWORK_BROKER_WEALTHMAX,
        NETWORK_BROKER_WARREN_FINANCE_LTD,
        NETWORK_BROKER_ACCESS_FINANCIAL_SERVICES,
        NETWORK_BROKER_GIFTWARE,
    ];
    const href = window.location.href;
    const arr = new URL(href);
    const networkBroker = arr.searchParams.get('nb');

    if (networkBroker) {
        setNetworkLoginCookie(networkBroker);
    } else if (networkBrokers.includes(path)) {
        setNetworkLoginCookie(path);
    } else if (path === 'login') {
        deleteNetworkLoginCookie();
    }
    //Network Brokers switch
    switch (getNetworkLoginCookie()) {
        case NETWORK_BROKER_CONNECT:
            logo = connectLogo;
            loginUrl = ROUTE_ANON_LOGIN_CONNECT;
            break;
        case NETWORK_BROKER_WEALTHMAX:
            logo = wealthmaxLogo;
            loginUrl = ROUTE_ANON_LOGIN_WEALTHMAX;
            break;
        case NETWORK_BROKER_WARREN_FINANCE_LTD:
            logo = warrenFinanceLtdLogo;
            loginUrl = ROUTE_ANON_LOGIN_WARREN_FINANCE_LTD;
            break;
        case NETWORK_BROKER_ACCESS_FINANCIAL_SERVICES:
            logo = accessFinancialServicesLogo;
            loginUrl = ROUTE_ANON_LOGIN_ACCESS_FINANCIAL_SERVICES;
            break;
        case NETWORK_BROKER_GIFTWARE:
            logo = giftwareLogo;
            loginUrl = ROUTE_ANON_LOGIN_GIFTWARE;
            break;
        default:
            logo = provideLogo;
            loginUrl = ROUTE_ANON_LOGIN;
    }

    return (
        <Container spacing={0} className={`authentication ${routeClassName}`}>
            <Helmet>
                <title>{BRAND_NAME}</title>
            </Helmet>
            <Container className={'container'}>
                <CenteringContainer className={'branding-container'}>
                    <a href={loginUrl}>
                        <img className={'logo'} src={logo} alt={`${BRAND_NAME} &copy; Borrowing Made Simple`} />
                    </a>
                </CenteringContainer>
                {children}
            </Container>
            {notifications}
        </Container>
    );
};

const mapStateToProps = (state) => {
    return {
        step: selectorBorrowerRegisterStep(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

AuthenticationLayout.propTypes = {
    notifications: PropTypes.element.isRequired,
    children: PropTypes.any.isRequired,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(AuthenticationLayout);
