import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import renderErrorIfAuthorisationFail from '../../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { path } from '../../../../../../pitch4_routing';
import { push } from 'react-router-redux';
import { RollupCommandContainer } from '../../../../../../pitch4_tabular/components';
import { PrimaryRedirectButton } from '../../../../../../pitch4_layout/components/buttons';
import { API_URL } from '../../../../../../bootstrap/constants/constants';
import {
    ROUTE_P4A_ADMINISTER_USERS_CREATE,
    ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST,
    ROUTE_P4A_ADMINISTER_USERS_EDIT,
    ROUTE_P4A_BORROWERS_DOCUMENTS_VIEW,
    ROUTE_P4A_LENDERS_DOCUMENTS_VIEW,
} from '../../../../../../pitch4_routing';
import StatusEnum, { STATUS_DISABLED, STATUS_ENABLED } from '../../../enum/Users/StatusEnum';
import withSession from '../../../../../../pitchblack_react_utils/session/withSession';
import SetStatusButton from '../components/SetStatusButton';
import _ from 'lodash';
import PromptResetPassword from '../components/PromptResetPassword';
import AssumeIdentityButton from '../components/AssumeIdentityButton';
import RoleUtil from '../../../../../../pitchblack_react_utils/session/RoleUtil';
import RoleNameEnum, {
    ROLE_SUPER_ADMIN,
    ROLE_ADMIN,
    ROLE_BORROWER,
    ROLE_INTERMEDIARY,
    ROLE_SLAM,
} from '../../../../../../pitch4_enum/enum/RoleNameEnum';
import PromptReset2FA from '../components/PromptReset2FA';
import { Button, FormControlLabel, Switch } from '@material-ui/core';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import createP4ApolloFetch from '../../../../../../bootstrap/middlewares';
import { ROWS_PER_PAGE, PAGE_SIZE } from '../../../../../../pitch4_enum/enum/DataGridEnum';
import moment from 'moment';
import HeaderWithCount from '../../../../../../pitch4_elements/screen-headers/HeaderWithCount';
import Toolbar from '../components/Toolbar';
import { setUserVerified } from '../../../../../../pitch4_api/set_user_verified';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../../modal/sagas/container';
import SetUserVerifiedModal from '../components/SetUserVerifiedModal';
import SetUserRealTimeNotifications from '../components/SetUserRealTimeNotifications';
import { setUserRealTimeNotifications } from '../../../../../../pitch4_api/set_user_real_time_notifications';
import Enable2FA from '../components/Enable2FA';
import Disable2FA from '../components/Disable2FA';
import { DEFAULT_SEARCH_FILTER_DELAY } from '../../../../../../pitch4_utils/FiltersDefaults';
import { setDigestNotifications } from '../../../../../../pitch4_api/set_user_digest_notifications';
import SetUserDigestNotifications from '../components/SetUserDigestNotifications';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });
const roleEnum = new RoleNameEnum();
const roleUtil = new RoleUtil();
const statusEnum = new StatusEnum();

class ListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataFetchError: null,
            searchFilter: '',
            roles: [ROLE_SUPER_ADMIN, ROLE_INTERMEDIARY, ROLE_ADMIN, ROLE_SLAM, ROLE_BORROWER],
            dataGrid: {
                rows: [],
                totalRowCount: 0,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: [{ column: 'createdAt', direction: 'desc' }],
            },
            dataRequest: {
                filters: [],
                sorts: [
                    {
                        column: 'createdAt',
                        direction: 'desc',
                    },
                ],
                pages: [],
                cursor: null,
                storedDataFilters: [],
            },
        };
    }

    componentDidMount() {
        const data = this.getData();
        this.processData(data);
    }

    refreshData = () => {
        this.setState(
            {
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages: [], cursor: null },
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    processData(data) {
        const { currentPage } = this.state.dataGrid;
        const { cursor } = this.state.dataRequest;

        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            const users = data.users.edges.map((user) => user.node);
            const rows = users.map((user, index) => {
                return {
                    id: user.id,
                    email: user.email,
                    given: user.userProfile.given,
                    family: user.userProfile.family,
                    roles: user.roles,
                    status: user.status,
                    createdAt: user.createdAt,
                    verified: user.verified,
                    twoFactorAuthStatus: user.twoFactorAuthStatus,
                    realTimeNotifications: user.realTimeNotifications ?? false,
                    digestNotifications: user.digestNotifications ?? false,
                    broker:
                        user.roles[0].name === ROLE_INTERMEDIARY && null !== user.broker
                            ? { id: user.broker.id, status: user.broker.status }
                            : null,
                    lender:
                        user.roles[0].name === ROLE_SLAM && null !== user.primaryLender
                            ? {
                                  id: user.primaryLender.lender.id,
                                  status: user.primaryLender.lender.status,
                              }
                            : null,
                };
            });

            let pages = this.state.dataRequest.pages;
            pages[currentPage] = {
                cursor: cursor,
                hasNextPage: data.users.pageInfo.hasNextPage,
                endCursor: data.users.pageInfo.endCursor,
                startCursor: data.users.pageInfo.startCursor,
                hasPreviousPage: data.users.pageInfo.hasPreviousPage,
            };

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: rows,
                    totalRowCount: data.users.totalCount,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages, cursor: data.users.pageInfo.endCursor },
                },
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    getData() {
        const query = `
            query getUsers($cursor: String, $first: Int!, $sorts: [SortInput], $filters: [FilterInput]) {
                users(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            displayNameTemplates
                            username
                            email
                            createdAt
                            updatedAt
                            status
                            verified
                            twoFactorAuthStatus
                            realTimeNotifications
                            digestNotifications
                            userProfile {
                                id
                                title
                                given
                                family
                                createdAt
                                updatedAt
                                primaryAddress {
                                    address1
                                    address2
                                    address3
                                    address4
                                    country
                                    postcode
                                }
                                primaryPhone {
                                    number
                                }
                            }
                            primaryLender {
                              lender {
                                id
                                status
                              }
                            }
                            broker{
                                id
                                status
                            }
                            roles {
                                name
                            }
                            primaryLender {
                                lender {
                                    id
                                }
                            }
                        }
                    }
                    totalCount
                }
            }
        `;

        return apolloFetch({
            query: query,
            variables: {
                cursor: this.state.dataRequest.cursor,
                filters: this.state.dataRequest.filters,
                first: this.state.dataGrid.pageSize,
                sorts: this.state.dataRequest.sorts,
                storedDataFilters: this.state.dataRequest.storedDataFilters,
            },
        });
    }

    handlePageChange = (pageConfig) => {
        const previousPage = this.state.dataGrid.currentPage;
        const newPage = pageConfig;
        const { pages } = this.state.dataRequest;

        if (newPage > previousPage) {
            // Next page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[previousPage].endCursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        } else {
            // Previous page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[newPage].cursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handlePageSizeChange = (pageConfig) => {
        this.setState(
            {
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: true,
                    pageSize: pageConfig,
                    rows: [],
                    currentPage: 0,
                    totalRowCount: 0,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    pages: [],
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    buildSearchRequestFilters = () => {
        const { searchFilter } = this.state;
        let searchRequestFilter = [];
        const searchableFields = ['email', 'given', 'family'];

        if (searchFilter.length > 0) {
            searchRequestFilter = searchableFields.map((field) => {
                return {
                    operation: 'ilike',
                    operand: '%' + searchFilter + '%',
                    column: field,
                    not: false,
                    logicOperator: 'or',
                };
            });
        }

        return searchRequestFilter;
    };

    buildRoleRequestFilters = () => {
        const { roleFilter } = this.state;
        let roleRequestFilter = [];

        roleRequestFilter = roleFilter.map((role) => {
            return {
                column: 'roles',
                operation: 'eq',
                not: false,
                operand: role,
            };
        });

        return roleRequestFilter;
    };

    handleSearchFilterChange = (event) => {
        this.setState(
            {
                searchFilter: event.target.value,
            },
            () => {
                this.setState({
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: [...this.buildSearchRequestFilters()],
                    },
                });
            }
        );

        _.delay(
            (value) => {
                if (this.state.searchFilter === value) {
                    const data = this.getData();
                    this.processData(data);
                }
            },
            DEFAULT_SEARCH_FILTER_DELAY,
            event.target.value
        );
    };

    handleClearAllFilters = () => {
        this.setState(
            {
                roleFilter: [],
                searchFilter: '',
                dataGrid: { ...this.state.dataGrid, rows: [] },
                dataRequest: { ...this.state.dataRequest, filters: [], cursor: null },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    handleSortModelChange = (params) => {
        const { sortModel } = this.state.dataGrid;
        const sortableFieldsMapping = { email: 'email', given: 'given', family: 'family', createdAt: 'createdAt' };

        if (params.sortModel !== null && typeof params.sortModel !== 'undefined' && params.sortModel !== sortModel) {
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        sortModel: params.sortModel,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        sorts: [
                            {
                                column: sortableFieldsMapping[params.sortModel[0].field],
                                direction: params.sortModel[0].sort,
                            },
                        ],
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    setUserVerified = (row) =>
        setUserVerified(API_URL, row.id, !row.verified).then((resp) => {
            const { setModalClose } = this.props;
            const rows = this.state.dataGrid.rows;
            let user = rows.find((le) => le.id === row.id);
            user.verified = !row.verified;
            user.verifiedUpdated = moment();

            const newRows = rows.map((item) => (item.id !== user.id ? item : user));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } }, () => setModalClose());
        });

    setUserRealTimeNotifications = (row) =>
        setUserRealTimeNotifications(API_URL, row.id, !row.realTimeNotifications).then((resp) => {
            const { setModalClose } = this.props;
            const rows = this.state.dataGrid.rows;
            let user = rows.find((le) => le.id === row.id);
            user.realTimeNotifications = !row.realTimeNotifications;

            const newRows = rows.map((item) => (item.id !== user.id ? item : user));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } }, () => setModalClose());
        });

    setUserDigestNotifications = (row) =>
        setDigestNotifications(API_URL, row.id, !row.digestNotifications).then((resp) => {
            const { setModalClose } = this.props;
            const rows = this.state.dataGrid.rows;
            let user = rows.find((le) => le.id === row.id);
            user.digestNotifications = !row.digestNotifications;

            const newRows = rows.map((item) => (item.id !== user.id ? item : user));
            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } }, () => setModalClose());
        });

    handleVerifyUser = (row) => {
        const { setModalComponent, setModalClose } = this.props;
        setModalComponent(
            <SetUserVerifiedModal user={row} setUserVerified={this.setUserVerified} setModalClose={setModalClose} />,
            true
        );
    };

    handleRealTimeNotifications = (row) => {
        const { setModalComponent, setModalClose } = this.props;
        setModalComponent(
            <SetUserRealTimeNotifications
                user={row}
                setUserRealTimeNotifications={this.setUserRealTimeNotifications}
                setModalClose={setModalClose}
            />,
            true
        );
    };

    handleDigestNotifications = (row) => {
        const { setModalComponent, setModalClose } = this.props;
        setModalComponent(
            <SetUserDigestNotifications
                user={row}
                setUserDigestNotifications={this.setUserDigestNotifications}
                setModalClose={setModalClose}
            />,
            true
        );
    };

    render() {
        const { redirect } = this.props;
        const { roles, searchFilter } = this.state;
        const { dataLoading } = this.state.dataGrid;
        const { sortingModel, totalRowCount, pageSize, rowsPerPage } = this.state.dataGrid;

        return (
            <div>
                <div className={'le-header-container'}>
                    <div className={'header-text'}>
                        <HeaderWithCount title={'Users'} count={totalRowCount} countText={'loan users'} />
                        <div className={'btn-mobile'}>
                            <Button
                                className={'btn-create'}
                                variant="contained"
                                color="primary"
                                size={'large'}
                                style={{
                                    marginLeft: 20,
                                    height: 45,
                                    paddingTop: 11,
                                    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
                                }}
                                onClick={redirect(ROUTE_P4A_ADMINISTER_USERS_CREATE)}
                            >
                                Create
                            </Button>
                        </div>
                    </div>
                    <Toolbar
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                        handleClearAllFilters={this.handleClearAllFilters}
                        redirect={redirect}
                    />
                </div>

                <div className={`le-table-container`}>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortingModel}
                        paginationMode="server"
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        sortingMode="server"
                        onSortModelChange={this.handleSortModelChange}
                        rowCount={totalRowCount}
                        pageSize={pageSize}
                        rowsPerPageOptions={rowsPerPage}
                        rows={this.state.dataGrid.rows}
                        loading={true}
                        disableColumnFilter={true}
                        disableMultipleColumnsSorting={true}
                        disableColumnReorder={true}
                        disableMultipleColumnsFiltering={true}
                        disableSelectionOnClick={true}
                        disableColumnMenu={true}
                        components={{
                            LoadingOverlay: () => {
                                return (
                                    <GridOverlay>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {this.state.dataGrid.dataLoading ? (
                                                <LinearProgress />
                                            ) : this.state.dataGrid.rows.length === 0 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        width: '100%',
                                                        paddingTop: 100,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    No data
                                                </div>
                                            ) : null}
                                        </div>
                                    </GridOverlay>
                                );
                            },
                        }}
                        columns={[
                            {
                                field: 'email',
                                headerName: 'EMAIL',
                                width: 300,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'given',
                                headerName: 'GIVEN',
                                width: 200,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'family',
                                headerName: 'FAMILY',
                                width: 200,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'roles',
                                headerName: 'ROLE',
                                width: 300,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                valueFormatter: ({ value }) => {
                                    const role = roleUtil.getMostSeniorRole(value);
                                    return roleEnum.getType(role.name);
                                },
                                sortable: false,
                            },
                            {
                                field: 'status',
                                headerName: 'STATUS',
                                width: 100,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                                valueFormatter: ({ value }) => statusEnum.getType(value),
                            },
                            {
                                field: 'parentStatus',
                                headerName: ' ',
                                width: 150,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                                renderCell: (params) => {
                                    const util = new RoleUtil();
                                    const role = util.getMostSeniorRole(params.row.roles).name;
                                    const broker = params.row.broker;
                                    const lender = params.row.lender;

                                    if (null !== lender && role === ROLE_SLAM) {
                                        return (
                                            <>
                                                <span>Lender</span>
                                                <strong>
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                            color:
                                                                lender.status === STATUS_ENABLED
                                                                    ? '#2ec4b6'
                                                                    : '#c7c7c7',
                                                        }}
                                                    >
                                                        {statusEnum.getType(lender.status)}
                                                    </span>
                                                </strong>
                                            </>
                                        );
                                    }

                                    if (null !== broker && role === ROLE_INTERMEDIARY) {
                                        return (
                                            <>
                                                <span>Broker</span>
                                                <strong>
                                                    <span
                                                        style={{
                                                            marginLeft: 5,
                                                            color:
                                                                broker.status === STATUS_ENABLED
                                                                    ? '#008000'
                                                                    : '#ff0000',
                                                        }}
                                                    >
                                                        {statusEnum.getType(broker.status)}
                                                    </span>
                                                </strong>
                                            </>
                                        );
                                    }

                                    return '-';
                                },
                            },
                            {
                                field: 'verified',
                                headerName: 'VERIFIED',
                                width: 100,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator',
                                sortable: false,
                                renderCell: (params) => {
                                    const util = new RoleUtil();
                                    const role = util.getMostSeniorRole(params.row.roles).name;

                                    if (role === ROLE_BORROWER) {
                                        return (
                                            <>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={params.row.verified}
                                                            onChange={(event) => this.handleVerifyUser(params.row)}
                                                            color="primary"
                                                            name="verified"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                            disabled={false}
                                                            id={params.row.id}
                                                        />
                                                    }
                                                    label={''}
                                                />
                                            </>
                                        );
                                    }

                                    return '-';
                                },
                            },
                            {
                                field: 'realTimeNotifications',
                                headerName: 'REAL-TIME NOTIFICATION',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-terms-sent',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={params.row.realTimeNotifications}
                                                        onChange={(event) =>
                                                            this.handleRealTimeNotifications(params.row)
                                                        }
                                                        color="primary"
                                                        name="verified"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={false}
                                                        id={params.row.id}
                                                    />
                                                }
                                                label={''}
                                            />
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'digestNotifications',
                                headerName: 'DIGEST NOTIFICATION',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-terms-sent',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={params.row.digestNotifications}
                                                        onChange={(event) => this.handleDigestNotifications(params.row)}
                                                        color="primary"
                                                        name="digest"
                                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        disabled={false}
                                                        id={params.row.id}
                                                    />
                                                }
                                                label={''}
                                            />
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'createdAt',
                                headerName: 'CREATED',
                                width: 170,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                            },
                            {
                                field: 'actions',
                                renderHeader: () => <div />,
                                cellClassName: 'le-table-cell le-table-cell-content-right',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                renderCell: (params) => {
                                    const role = roleUtil.getMostSeniorRole(params.row.roles);
                                    let docUrl = null;

                                    if (params.row.primaryLender) {
                                        docUrl = path(ROUTE_P4A_LENDERS_DOCUMENTS_VIEW, {
                                            id: params.row.primaryLender.lender.id,
                                        });
                                    } else if (_.indexOf(roles, ROLE_BORROWER) >= 0) {
                                        docUrl = path(ROUTE_P4A_BORROWERS_DOCUMENTS_VIEW, { id: params.row.id });
                                    }

                                    return (
                                        <div className={'le-table-cell-context-menu'} style={{ marginTop: 10 }}>
                                            <RollupCommandContainer>
                                                {role.name !== ROLE_SUPER_ADMIN && role.name !== ROLE_ADMIN && (
                                                    <AssumeIdentityButton id={params.row.id} redirect={redirect}>
                                                        Admin Assist
                                                    </AssumeIdentityButton>
                                                )}
                                                {params.row.status === STATUS_ENABLED ? (
                                                    <SetStatusButton
                                                        outerRefetch={this.refreshData}
                                                        userId={params.row.id}
                                                        useStatus={STATUS_DISABLED}
                                                    >
                                                        Disable
                                                    </SetStatusButton>
                                                ) : (
                                                    <SetStatusButton
                                                        outerRefetch={this.refreshData}
                                                        userId={params.row.id}
                                                        useStatus={STATUS_ENABLED}
                                                    >
                                                        Enable
                                                    </SetStatusButton>
                                                )}
                                                <PrimaryRedirectButton
                                                    url={path(ROUTE_P4A_ADMINISTER_USERS_EDIT, { id: params.row.id })}
                                                >
                                                    Edit
                                                </PrimaryRedirectButton>
                                                <PrimaryRedirectButton
                                                    url={path(ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST, {
                                                        id: params.row.id,
                                                    })}
                                                >
                                                    Lenders
                                                </PrimaryRedirectButton>
                                                <PrimaryRedirectButton
                                                    disabled={docUrl === null}
                                                    url={docUrl ? docUrl : '#'}
                                                >
                                                    Docs
                                                </PrimaryRedirectButton>
                                                <PromptResetPassword
                                                    uuid={params.row.id}
                                                    outerRefetch={this.refreshData}
                                                >
                                                    Reset Password
                                                </PromptResetPassword>
                                                <Enable2FA
                                                    disabled={
                                                        params.row.twoFactorAuthStatus === STATUS_ENABLED ||
                                                        params.row.twoFactorAuth === 'PENDING'
                                                    }
                                                    uuid={params.row.id}
                                                    outerRefetch={this.refreshData}
                                                >
                                                    Enable 2FA
                                                </Enable2FA>
                                                <Disable2FA
                                                    disabled={params.row.twoFactorAuthStatus === STATUS_DISABLED}
                                                    uuid={params.row.id}
                                                    outerRefetch={this.refreshData}
                                                >
                                                    Disable 2FA
                                                </Disable2FA>
                                                <PromptReset2FA
                                                    disabled={params.row.twoFactorAuthStatus !== STATUS_ENABLED}
                                                    uuid={params.row.id}
                                                    outerRefetch={this.refreshData}
                                                >
                                                    Reset 2FA
                                                </PromptReset2FA>
                                            </RollupCommandContainer>
                                        </div>
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        redirect: (url) => () => dispatch(push(url)),
    };
};

export default compose(
    withRouter,
    withSession,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(ListContainer);
