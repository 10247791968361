import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { path, ROUTE_SLAM_LEAD } from '../../../../../pitch4_routing';
import { push } from 'react-router-redux';
import { WithQueryStringVariables } from '../../../../../pitch4_layout/components/uri';
import { ROLE_SLAM } from '../../../../authentication/enum/Roles/NameEnum';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';
import LeadStatusEnum from '../../enum/LenderLoanEnquiries/StatusEnum';
import { getMainApplicantName, getStoredDataValueFromArray } from '../../../../view_loan_enquiry/HelperFunctions';
import { LOAN_AMOUNT } from '../../../enum/MatchingRules/MetaKeyEnum';
import { FactValue } from '../../../../../pitch4_layout/components/data';
import { STATUS_DRAFT } from '../../enum/LoanEnquiries/StatusEnum';
import _ from 'lodash';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import { ROWS_PER_PAGE, PAGE_SIZE } from '../../../../../pitch4_enum/enum/DataGridEnum';
import { actionSetJoyrideType } from '../../../../joyride/redux';
import Toolbar from '../components/Toolbar';
import HeaderWithCount from '../../../../../pitch4_elements/screen-headers/HeaderWithCount';
import StarIcon from '../../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../../assets/images/star-gray.svg';
import { updateLEFavourite } from '../api/le_favourite';
import { getLeads } from '../api/get_leads';
import { setLEAssignee } from '../../../../../pitch4_api/set_le_assignee';
import { getAssignableUsers } from '../../../../../pitch4_api/get_assignable_users';
import AssignToModal from '../../../../../pitch4_elements/loan-enquiry-owner/AssignToModal';
import PersonIcon from '@material-ui/icons/Person';
import { sagaActionModalHide, sagaActionModalShow } from '../../../../modal/sagas/container';
import { removeLEAssignee } from '../../../../../pitch4_api/remove_le_assignee';
import { defaultFilters, defaultSorts } from '../api/config';
import Status from '../../../../borrower/loan_enquiries/components/Status';
import { DEFAULT_SEARCH_FILTER_DELAY } from '../../../../../pitch4_utils/FiltersDefaults';
import AnonDots from '../../../../../pitch4_elements/anon-dots/AnonDots';
import BorrowerQuestions, { QUESTION_LOAN_AMOUNT } from '../../../../MakeLoanEnquiry/enums/Borrower/BorrowerQuestions';
import { calculateLoanType } from '../../../../MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';
import { defaultSortModel } from '../../../../../pitch4_utils/SortsDefaults';

const apolloFetch = createP4ApolloFetch({ uri: SLAM_URL });

class ActiveTableStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previousSortModel: [],
            dataFetchError: null,
            searchFilter: '',
            updatesFilter: '',
            assigneeFilter: '',
            businessStreamsFilter: [],
            businessStreams: [],
            dataGrid: {
                rows: [],
                totalRowCount: 0,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: defaultSortModel,
            },
            dataRequest: {
                filters: defaultFilters(),
                sorts: defaultSorts(),
                pages: [],
                cursor: null,
                storedDataFilters: ['LOAN_AMOUNT'],
            },
        };
    }

    componentDidMount() {
        const { setJoyrideType } = this.props;
        const data = this.getData();
        this.processData(data);

        const businessStreamData = this.getBusinessStreamData();
        this.processBusinessStreamData(businessStreamData);
        setJoyrideType(null);
    }

    refreshData = () => {
        this.setState(
            {
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages: [], cursor: null },
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    getAssignees = (assignees) => {
        if (assignees.length === 0) {
            return [
                {
                    id: 'no_owner',
                    name: 'No owner',
                },
            ];
        }

        return [
            {
                id: assignees[0].id,
                name: assignees[0].userProfile.given + ' ' + assignees[0].userProfile.family,
            },
        ];
    };

    processData(data) {
        const { currentPage } = this.state.dataGrid;
        const { cursor } = this.state.dataRequest;
        const questions = new BorrowerQuestions().getTypes();

        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then((data) => {
            const leads = data.lenderLoanEnquiries.edges.map((lead) => lead.node);
            const rows = leads.map((lead, index) => {
                const loanType = calculateLoanType(lead.loanEnquiry.loanEnquiryData).value;
                return {
                    id: lead.id,
                    lenderReference: lead.lenderReference,
                    status: lead.status,
                    loanEnquiryStatus: lead.loanEnquiry.status,
                    createdAt: lead.createdAt,
                    matchedAt: lead.matchedAt,
                    favourite: lead.favourite,
                    loanEnquiryData: lead.loanEnquiry.loanEnquiryData,
                    loanAmount: getStoredDataValueFromArray(
                        lead.storedData,
                        LOAN_AMOUNT,
                        lead.loanEnquiry.loanEnquiryData,
                        questions[QUESTION_LOAN_AMOUNT].fieldName
                    ),
                    applicant: getMainApplicantName(lead.loanEnquiryApplicants),
                    storedData: lead.storedData,
                    alertActive: lead.alertActive,
                    businessStream: lead.businessStreams.length > 0 ? lead.businessStreams[0].name : null,
                    assignedTo: this.getAssignees(lead.assignees),
                    loanType: loanType,
                    isNewForm: lead.loanEnquiry.isNewForm,
                };
            });

            let pages = this.state.dataRequest.pages;
            pages[currentPage] = {
                cursor: cursor,
                hasNextPage: data.lenderLoanEnquiries.pageInfo.hasNextPage,
                endCursor: data.lenderLoanEnquiries.pageInfo.endCursor,
                startCursor: data.lenderLoanEnquiries.pageInfo.startCursor,
                hasPreviousPage: data.lenderLoanEnquiries.pageInfo.hasPreviousPage,
            };

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: rows,
                    totalRowCount: rows.length,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages, cursor: data.lenderLoanEnquiries.pageInfo.endCursor },
                },
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    processBusinessStreamData(data) {
        data.then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            const businessStreams = data.businessStreams.edges.map((bs) => bs.node);

            this.setState({
                businessStreams: businessStreams.map((bs, index) => {
                    return {
                        id: bs.id,
                        name: bs.name,
                    };
                }),
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    getData = () =>
        getLeads(
            this.state.dataRequest.cursor,
            this.state.dataRequest.filters,
            this.state.dataGrid.pageSize,
            this.state.dataRequest.sorts,
            this.state.dataRequest.storedDataFilters,
            this.state.dataRequest.currentPage
        );

    getBusinessStreamData() {
        const query = `
            query getLoanEnquiries(
                $cursor: String
                $first: Int!
                $sorts: [SortInput]
                $filters: [FilterInput]
            ) {
                businessStreams(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
        `;

        return apolloFetch({
            query: query,
            variables: {
                first: 9999,
            },
        });
    }

    handlePageChange = (pageConfig) => {
        const previousPage = this.state.dataGrid.currentPage;
        const newPage = pageConfig;
        const { pages } = this.state.dataRequest;

        if (newPage > previousPage) {
            // Next page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[previousPage].endCursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        } else {
            // Previous page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[newPage].cursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handlePageSizeChange = (pageConfig) => {
        this.setState(
            {
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: true,
                    pageSize: pageConfig,
                    rows: [],
                    currentPage: 0,
                    totalRowCount: 0,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    pages: [],
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    buildSearchRequestFilters = () => {
        const { searchFilter } = this.state;
        let searchRequestFilter = [];
        const searchableFields = ['lenderReference'];

        if (searchFilter.length > 0) {
            searchRequestFilter = searchableFields.map((field) => {
                return {
                    operation: 'ilike',
                    operand: '%' + searchFilter + '%',
                    column: field,
                    not: false,
                };
            });
        }

        return searchRequestFilter;
    };

    buildAssigneeRequestFilters = () => {
        const { assigneeFilter } = this.state;
        let filters = [];

        if ('' !== assigneeFilter) {
            filters.push({
                operation: assigneeFilter,
                operand: 'true',
                column: 'leAssignedActive',
                not: false,
            });
        }

        return filters;
    };

    handleSearchFilterChange = (event) => {
        this.setState(
            {
                searchFilter: event.target.value,
            },
            () => {
                this.setState({
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: this.buildRequestFilters(),
                    },
                });
            }
        );

        _.delay(
            (value) => {
                if (this.state.searchFilter === value) {
                    const data = this.getData();
                    this.processData(data);
                }
            },
            DEFAULT_SEARCH_FILTER_DELAY,
            event.target.value
        );
    };

    buildUpdatesRequestFilters = () => {
        const { updatesFilter } = this.state;
        let filters = [];

        if (updatesFilter.length > 0) {
            filters.push({
                operation: 'eq',
                operand: 'updatesOnly' === updatesFilter ? 'true' : 'false',
                column: 'leAlertActive',
                not: false,
            });
        }

        return filters;
    };

    handleUpdatesFilterChange = (event) => {
        const updatesFilter = event.target.value;

        this.setState(
            {
                updatesFilter: updatesFilter,
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            filters: this.buildRequestFilters(),
                        },
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    buildRequestFilters = () =>
        _.concat(defaultFilters(), [
            ...this.buildSearchRequestFilters(),
            ...this.buildUpdatesRequestFilters(),
            ...this.buildAssigneeRequestFilters(),
        ]);

    handleBusinessStreamFilterChange = (event) => {
        const { businessStreams } = this.state;
        const selectedBusinessStream = event.target.value;
        this.setState(
            {
                businessStreamsFilter: selectedBusinessStream,
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            filters: [
                                ...this.buildSearchRequestFilters(),
                                {
                                    column: 'businessStreamId',
                                    not: false,
                                    operand: businessStreams.find((bs) => bs.name === selectedBusinessStream).id,
                                    operation: 'eq',
                                },
                                ...this.buildUpdatesRequestFilters(),
                            ],
                        },
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    handleClearAllFilters = () => {
        window.location.reload();
    };

    handleSortModelChange = (params) => {
        const { sortModel } = this.state.dataGrid;
        const sortableFieldsMapping = {
            favourite: 'favourite',
            lenderReference: 'lenderReference',
            createdAt: 'createdAt',
            matchedAt: 'matchedAt',
            assignedTo: 'assignedTo',
        };
        if (
            params === null ||
            typeof params === 'undefined' ||
            JSON.stringify(params) === JSON.stringify(defaultSortModel)
        ) {
            return;
        }
        if (JSON.stringify(params) === JSON.stringify(this.state.previousSortModel)) {
            return;
        }
        let sorts = params.map((sort) => ({
            column: sortableFieldsMapping[sort.field],
            direction: sort.sort,
        }));

        if (JSON.stringify(params) !== JSON.stringify(sortModel)) {
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        sortModel: params,
                    },
                    previousSortModel: sortModel,
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: defaultFilters(),
                        sorts: sorts,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handleLEFavouriteChange = (leId, isFavourite) => {
        const data = updateLEFavourite(leId, isFavourite);

        data.then((resp) => {
            const rows = this.state.dataGrid.rows;
            const le = (rows.find((le) => le.id === leId).favourite = isFavourite);
            const newRows = rows.map((item) => (le.id !== leId ? item : le));

            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } });
        });
    };

    handleUpdateLEAssignee = (lleId) => (newAssignedTo) => {
        const { setModalClose } = this.props;
        let data;

        if (newAssignedTo.id === 'no_owner') {
            data = removeLEAssignee(SLAM_URL, lleId, null, [newAssignedTo.id]);
        } else {
            data = setLEAssignee(SLAM_URL, lleId, null, [newAssignedTo.id]);
        }

        data.then((resp) => {
            const rows = this.state.dataGrid.rows;
            const le = (rows.find((le) => le.id === lleId).assignedTo = [
                {
                    id: newAssignedTo.id,
                    name: newAssignedTo.name,
                },
            ]);
            const newRows = rows.map((item) => (le.id !== lleId ? item : le));

            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } }, () => setModalClose());
        });
    };

    handleAssignLETo = (le) => {
        const { setModalComponent, setModalClose } = this.props;
        getAssignableUsers(SLAM_URL).then((resp) => {
            setModalComponent(
                <AssignToModal
                    assignees={resp}
                    setModalClose={setModalClose}
                    userRole={ROLE_SLAM}
                    assignedTo={le.assignedTo[0]}
                    handleUpdateLEAssignee={this.handleUpdateLEAssignee(le.id)}
                />,
                true
            );
        });
    };

    handleAssigneeFilterChange = (event) => {
        const assigneeFilter = event.target.value;

        this.setState(
            {
                assigneeFilter: assigneeFilter,
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            filters: this.buildRequestFilters(),
                        },
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    render() {
        const { dispatch } = this.props;
        const { rows, pageSize, rowsPerPage, totalRowCount, dataLoading, sortModel } = this.state.dataGrid;
        const { businessStreams, businessStreamsFilter, updatesFilter, searchFilter, assigneeFilter } = this.state;
        const leadStatusEnum = new LeadStatusEnum();

        return (
            <div>
                <div className={'le-header-container'}>
                    <div className={'header-text'}>
                        <HeaderWithCount title={'Active Leads'} count={totalRowCount} countText={'active leads'} />
                    </div>
                    <Toolbar
                        assigneeFilter={assigneeFilter}
                        updatesFilter={updatesFilter}
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        handleUpdatesFilterChange={this.handleUpdatesFilterChange}
                        handleClearAllFilters={this.handleClearAllFilters}
                        handleBusinessStreamFilterChange={this.handleBusinessStreamFilterChange}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                        businessStreamsFilter={businessStreamsFilter}
                        businessStreams={businessStreams}
                        handleAssigneeFilterChange={this.handleAssigneeFilterChange}
                    />
                </div>

                <div className={`le-table-container`}>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        paginationMode="server"
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        sortingMode="server"
                        onSortModelChange={this.handleSortModelChange}
                        rowCount={totalRowCount}
                        pageSize={pageSize}
                        rowsPerPageOptions={rowsPerPage}
                        rows={rows}
                        loading={true}
                        disableColumnFilter={true}
                        disableMultipleColumnsSorting={true}
                        disableColumnReorder={true}
                        disableMultipleColumnsFiltering={true}
                        disableSelectionOnClick={true}
                        disableColumnMenu={true}
                        onCellClick={(params) => {
                            switch (params.field) {
                                case 'favourite':
                                    this.handleLEFavouriteChange(params.row.id, !params.row.favourite);
                                    break;
                                case 'assignedTo':
                                    this.handleAssignLETo(params.row);
                                    break;
                                default:
                                    dispatch(push(path(ROUTE_SLAM_LEAD, { lenderLoanEnquiryId: params.row.id })));
                                    break;
                            }
                        }}
                        components={{
                            LoadingOverlay: () => {
                                return (
                                    <GridOverlay>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {this.state.dataGrid.dataLoading ? (
                                                <LinearProgress />
                                            ) : this.state.dataGrid.rows.length === 0 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        width: '100%',
                                                        paddingTop: 100,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    No data
                                                </div>
                                            ) : null}
                                        </div>
                                    </GridOverlay>
                                );
                            },
                        }}
                        columns={[
                            {
                                field: 'favourite',
                                headerName: ' ',
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                width: 50,
                                renderCell: (params) => {
                                    return (
                                        <img
                                            src={params.row.favourite ? StarIcon : StarGrayIcon}
                                            alt="Favourite"
                                            className={'favourite'}
                                        />
                                    );
                                },
                                disableColumnMenu: true,
                            },
                            {
                                field: 'alert',
                                renderHeader: () => <div />,
                                cellClassName: 'le-table-cell le-table-cell-content-center badge-container',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                width: 80,
                                renderCell: (params) => {
                                    if (true === params.row.alertActive) {
                                        return <Badge label={'update'} />;
                                    }
                                    return <></>;
                                },
                            },
                            {
                                field: 'amount',
                                headerName: 'AMOUNT',
                                width: 200,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                renderCell: (params) => {
                                    return (
                                        <FactValue name={LOAN_AMOUNT} value={params.row.loanEnquiryData.loanAmount} />
                                    );
                                },
                                sortable: false,
                            },
                            {
                                field: 'applicant',
                                headerName: 'APPLICANT',
                                width: 200,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                                renderCell: (params) => {
                                    if (true === leadStatusEnum.isAnonStatus(params.row.status)) {
                                        return <AnonDots count={5} />;
                                    }
                                    return params.applicant;
                                },
                            },
                            {
                                field: 'assignedTo',
                                headerName: 'ENQUIRY OWNER',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-assigned-to',
                                headerClassName: 'le-table-header-no-separator',
                                disableColumnMenu: true,
                                filterable: false,
                                resizable: false,
                                renderCell: (params) => {
                                    return (
                                        <>
                                            <div className={'icon'}>
                                                <PersonIcon />
                                            </div>
                                            <div
                                                className={`name-${
                                                    params.value[0].id !== 'no_owner' ? 'active' : null
                                                }`}
                                            >
                                                {params.value[0].name}
                                            </div>
                                        </>
                                    );
                                },
                            },
                            {
                                field: 'createdAt',
                                headerName: 'CREATED',
                                width: 170,
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'matchedAt',
                                headerName: 'MATCHED',
                                width: 170,
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'lenderReference',
                                headerName: 'REF',
                                width: 130,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'businessStream',
                                headerName: 'BUSINESS STREAM',
                                width: 360,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'status',
                                headerName: 'STATUS',
                                width: 200,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                                renderCell: (params) => {
                                    return (
                                        <>
                                            {params.row.status === STATUS_DRAFT ? (
                                                <div className={'label-draft'}>
                                                    {leadStatusEnum.getType(params.row.status, params.row.loanType)}
                                                </div>
                                            ) : (
                                                <div>
                                                    {leadStatusEnum.getType(params.row.status, params.row.loanType)}
                                                </div>
                                            )}
                                            <Status style={{ marginLeft: 10 }} status={params.row.status} />
                                        </>
                                    );
                                },
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => dispatch(push(url)),
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    WithQueryStringVariables,
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_SLAM])
)(ActiveTableStyleContainer);
