import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import { BORROWER_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

const BusinessFinanceEnquiryMutation = `
    mutation createBusinessFinanceLoanEnquirySubmission($draftId: ID, $input: borrower_BusinessFinanceEnquirySubmissionInput!){
      createBusinessFinanceLoanEnquirySubmission(draftId: $draftId, input: $input){
        createLoanEnquirySubmission {
            id
            loanEnquiry {
                id
            }
        }
      }
    }
`;

const PropertyFinanceEnquiryMutation = `
    mutation createLoanEnquirySubmission($draftId: ID, $input: borrower_PropertyFinanceEnquirySubmissionInput!){
      createLoanEnquirySubmission(draftId: $draftId, input: $input){
        createLoanEnquirySubmission {
            id
            loanEnquiry {
                id
            }
        }
      }
    }
`;

//response classes
const errorFactory = new ValidationResultFactory();

class MakeLoanEnquiry {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class MakeLoanEnquiryErrors extends MakeLoanEnquiry {
    constructor(errors) {
        super('MakeLoanEnquiryErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class MakeLoanEnquirySuccess extends MakeLoanEnquiry {
    constructor(data) {
        super('MakeLoanEnquirySuccess');
        this.data = data;
    }

    getData = () => {
        return this.data;
    };
}

export const makeLoanEnquiry = (
    acceptedTermsVersion,
    reviewMatches,
    formSections,
    isBusinessFinanceEnquiry,
    formVersion,
    draftId = null
) => {
    let out = apolloFetch({
        query: isBusinessFinanceEnquiry === true ? BusinessFinanceEnquiryMutation : PropertyFinanceEnquiryMutation,
        variables: {
            draftId: draftId,
            input: {
                acceptedTermsVersion,
                reviewMatches,
                formSections,
                formVersion,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new MakeLoanEnquiryErrors(vR);
            }

            return new MakeLoanEnquirySuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new MakeLoanEnquiryErrors(vR);
        });

    return out;
};
