export const REDUCER_NAMESPACE = 'terms';

// Actions
export const TERMS_GET_LATEST = `${REDUCER_NAMESPACE}.get_latest`;
export const actionGetLatestTerms = () => {
    return {
        type: TERMS_GET_LATEST,
    };
};

export const TERMS_SET_LATEST = `${REDUCER_NAMESPACE}.set_latest`;
export const actionSetLatestTerms = (terms) => {
    return {
        type: TERMS_SET_LATEST,
        terms,
    };
};

export const TERMS_SET_ACCEPTED_VERSION = `${REDUCER_NAMESPACE}.set_accepted_version`;
export const actionSetAcceptedVersion = (acceptedVersion) => {
    return {
        type: TERMS_SET_ACCEPTED_VERSION,
        acceptedVersion,
    };
};

const initialState = {
    latest_terms: {},
    acceptedVersion: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case TERMS_SET_LATEST:
            return {
                ...state,
                latest_terms: action.terms,
            };

        case TERMS_SET_ACCEPTED_VERSION:
            return {
                ...state,
                acceptedVersion: action.acceptedVersion,
            };

        default:
            return { ...state };
    }
};

export const selectorGetLatestTerms = (state) => {
    return state[REDUCER_NAMESPACE].latest_terms;
};

export const selectorGetAcceptedTermsVersion = (state) => {
    return state[REDUCER_NAMESPACE].acceptedVersion;
};

export default { [REDUCER_NAMESPACE]: reducer };
