import React from 'react';
import { Drawer } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { TextField } from '@material-ui/core';
import { PrimaryButton } from '../buttons';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ChooseADocumentIcon from '../../../assets/images/choose-a-document-icon.svg';
import Button from '@material-ui/core/Button';
import SecondaryButton from '../buttons/SecondaryButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import BorrowerUploadDocumentButton from '../../../apps/borrower/lender_loan_enquiries_view/components/UploadDocumentButton';
import IntermediaryUploadDocumentButton from '../../../apps/admin/intermediary/view_lender_loan_enquiry/components/UploadDocumentButton';
import AdminUploadDocumentButton from '../../../apps/admin/pitch4admin/administer_lenders_loanenquiries_view/components/UploadDocumentButton';
import {
    ROLE_BORROWER as BORROWER,
    ROLE_INTERMEDIARY as INTERMEDIARY,
    ROLE_SUPER_ADMIN as ADMIN,
} from '../../../pitch4_enum/enum/RoleNameEnum';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <>{children}</>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

class LenderMessageDrawer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: 0,
        };
    }

    getUploadButton = () => {
        const { userRole, selectedLenders, toggleDocumentSentModal } = this.props;
        switch (userRole) {
            case BORROWER:
                return (
                    <BorrowerUploadDocumentButton
                        lenderLoanEnquiryId={selectedLenders ? selectedLenders.selectionModel : []}
                        buttonRender={(openAddDocumentModal) => (
                            <SecondaryButton onClick={() => openAddDocumentModal()} style={{ color: '#ffffff' }}>
                                Add a Document&nbsp;
                                <AddCircleIcon />
                            </SecondaryButton>
                        )}
                        toggleDocumentSentModal={toggleDocumentSentModal}
                    />
                );
            case INTERMEDIARY:
                return (
                    <IntermediaryUploadDocumentButton
                        lenderLoanEnquiryId={selectedLenders ? selectedLenders.selectionModel : []}
                        buttonRender={(openAddDocumentModal) => (
                            <SecondaryButton onClick={() => openAddDocumentModal()} style={{ color: '#ffffff' }}>
                                Add a Document <AddCircleIcon />
                            </SecondaryButton>
                        )}
                        toggleDocumentSentModal={toggleDocumentSentModal}
                    />
                );
            case ADMIN:
                return (
                    <AdminUploadDocumentButton
                        lenderLoanEnquiryId={selectedLenders ? selectedLenders.selectionModel : []}
                        buttonRender={(openAddDocumentModal) => (
                            <SecondaryButton onClick={() => openAddDocumentModal()} style={{ color: '#ffffff' }}>
                                Add a Document <AddCircleIcon />
                            </SecondaryButton>
                        )}
                        toggleDocumentSentModal={toggleDocumentSentModal}
                    />
                );
            default:
                return;
        }
    };

    render() {
        const {
            selectedLenders,
            setLendersMessage,
            getLendersMessage,
            toggleMessageDrawer,
            sendMessageToLenders,
            getMessageSent,
            setMessageSent,
            anchor,
            open,
        } = this.props;

        let lenders = [];
        if (selectedLenders && selectedLenders.hasOwnProperty('selectionModel')) {
            lenders = selectedLenders.selectionModel.length > 0 ? selectedLenders.selectionModel : [];
        } else {
            lenders = selectedLenders && selectedLenders.length > 0 ? selectedLenders : [];
        }

        const handleTabChange = (event, newValue) => {
            this.setState({ tabValue: newValue });
        };

        return (
            <Drawer anchor={anchor} variant={'persistent'} open={open} className={'lender-message-modal'}>
                <div className={'header'}>
                    <div>
                        <h2 className={'text'}>Send to Selected Lenders ({lenders.length})</h2>
                    </div>
                    <div>
                        <IconButton onClick={toggleMessageDrawer}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div>
                    <hr className={'tabs-indicator-top-line'} />
                    <Tabs
                        value={this.state.tabValue}
                        onChange={handleTabChange}
                        aria-label="Send to Selected Tabs"
                        TabIndicatorProps={{
                            children: (
                                <div className={'tab-indicator-container'}>
                                    <div className={'tab-active-indicator'} />
                                </div>
                            ),
                        }}
                    >
                        <Tab
                            label="Send Message"
                            {...a11yProps(0)}
                            style={{
                                fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif',
                                textTransform: 'none',
                                fontSize: 14,
                            }}
                        />
                        <Tab
                            label="Send Documents"
                            {...a11yProps(1)}
                            style={{
                                fontFamily: 'Roboto, Helvetica, Arial, sans-serif, serif',
                                textTransform: 'none',
                                fontSize: 14,
                            }}
                        />
                    </Tabs>
                    <TabPanel value={this.state.tabValue} index={0}>
                        <div style={{ marginTop: 20 }}>
                            <TextField
                                value={getLendersMessage() || ''}
                                className={'message-input'}
                                multiline
                                minRows={10}
                                id="outlined-basic"
                                label="Message"
                                variant="outlined"
                                onChange={(event) => setLendersMessage(event.target.value)}
                            />
                            <div>
                                <PrimaryButton
                                    disabled={
                                        getMessageSent() ||
                                        lenders.length === 0 ||
                                        null === getLendersMessage() ||
                                        (null !== getLendersMessage() && getLendersMessage().trim().length === 0)
                                    }
                                    onClick={sendMessageToLenders(
                                        lenders,
                                        getLendersMessage(),
                                        toggleMessageDrawer,
                                        setMessageSent
                                    )}
                                >
                                    Send
                                </PrimaryButton>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.tabValue} index={1}>
                        <div style={{ marginTop: 20, textAlign: 'center' }}>
                            <div>
                                <img
                                    src={ChooseADocumentIcon}
                                    alt={'Choose a document'}
                                    style={{ width: 100, marginBottom: 20 }}
                                />
                            </div>
                            <div>
                                <Button>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {this.getUploadButton()}
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </Drawer>
        );
    }
}

export default LenderMessageDrawer;
