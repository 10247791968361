class Mapper {
    mapToForm = (props) => {
        let {
            name = '',
            primaryEmail = '',
            fcaIdentifier = '',
            companyNumber = '',
            primaryAddress: { address1 = '', address2 = '', address3 = '', address4 = '', postcode = '', country = '' },
            primaryPhone: { number = '' },
        } = props;

        return {
            name,
            fcaIdentifier,
            companyNumber,
            primaryEmail,
            primaryAddressAddress1: address1,
            primaryAddressAddress2: address2,
            primaryAddressAddress3: address3,
            primaryAddressAddress4: address4,
            primaryAddressCountry: country,
            primaryAddressPostcode: postcode,
            primaryPhoneNumber: number,
        };
    };

    mapFromForm = ({
        name = '',
        primaryEmail = '',
        fcaIdentifier = '',
        companyNumber = '',
        primaryAddressAddress1 = '',
        primaryAddressAddress2 = '',
        primaryAddressAddress3 = '',
        primaryAddressAddress4 = '',
        primaryAddressCountry = '',
        primaryAddressPostcode = '',
        primaryPhoneNumber = '',
    }) => {
        return {
            name,
            primaryEmail,
            fcaIdentifier,
            companyNumber,
            primaryAddress: {
                address1: primaryAddressAddress1,
                address2: primaryAddressAddress2,
                address3: primaryAddressAddress3,
                address4: primaryAddressAddress4,
                postcode: primaryAddressPostcode,
                country: primaryAddressCountry,
            },
            primaryPhone: {
                number: primaryPhoneNumber,
            },
        };
    };
}

export default Mapper;
