import React, { useState } from 'react';
import { Drawer, TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { PrimaryButton } from '../../../../../../pitch4_layout/components/buttons';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function AdminNoteDrawer(props) {
    const { adminNoteDrawerOpen, toggleAdminNoteDrawer, handleAddNote, adminNote, handleUpdateAdminNote } = props;
    const [title, setTitle] = useState('');
    const [note, setNote] = useState('');
    const [updated, setUpdated] = useState(false);
    const [addingNote, setAddingNote] = useState(false);

    const clearForm = () => {
        setTitle('');
        setNote('');
        setUpdated(false);
    };

    if (null !== adminNote && false === updated) {
        setTitle(adminNote.adminNoteTitle);
        setNote(adminNote.adminNote);
        setUpdated(true);
    }

    return (
        <Drawer
            anchor={'right'}
            open={adminNoteDrawerOpen}
            onClose={() => {
                toggleAdminNoteDrawer();
            }}
        >
            <div style={{ minWidth: 380 }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 10 }}>
                    <div>
                        <IconButton onClick={toggleAdminNoteDrawer()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <div style={{ padding: '10px 20px 20px 20px' }}>
                    <div style={{ marginBottom: 20 }}>
                        <TextField
                            style={{ width: '100%', backgroundColor: '#e7e7e7' }}
                            value={title}
                            className={'note-title-input'}
                            multiline
                            rows={1}
                            id="outlined-basic"
                            label="Note title"
                            variant="outlined"
                            onChange={(event) => setTitle(event.target.value)}
                        />
                    </div>
                    <div style={{ marginBottom: 20 }}>
                        <TextField
                            style={{ width: '100%', backgroundColor: '#e7e7e7', whiteSpace: 'pre' }}
                            value={note}
                            className={'note-input'}
                            multiline
                            rows={10}
                            id="outlined-basic"
                            label="Type your note"
                            variant="outlined"
                            onChange={(event) => setNote(event.target.value)}
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <PrimaryButton
                            style={{ marginRight: 0 }}
                            onClick={() => {
                                setAddingNote(true);
                                if (null === adminNote) {
                                    handleAddNote(title, note, setAddingNote, clearForm);
                                } else {
                                    handleUpdateAdminNote(adminNote.id, title, note, setAddingNote, clearForm);
                                }
                            }}
                            disabled={!title || !note || addingNote}
                        >
                            {addingNote ? (
                                <CircularProgress size={25} style={{ color: '#ffffff', marginLeft: 5 }} />
                            ) : (
                                'Save'
                            )}
                        </PrimaryButton>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}
