import React from 'react';
import { Button } from '@material-ui/core';
import { STATUS_APPLICATION } from '../../../../../pitch4_enum/enum/LenderLoanEnquiryStatusEnum';
import ApplicationStartedModal from './ApplicationStartedModal';

export default function StartApplicationModal(props) {
    const { updateLLEStatus, matchSelected, setModalComponent, setModalClose, toggleLenderDrawer } = props;
    const handleOnProceedClick = () => {
        toggleLenderDrawer()();
        updateLLEStatus(matchSelected, STATUS_APPLICATION);
        setModalClose();
        setModalComponent(<ApplicationStartedModal setModalClose={setModalClose} />, true);
    };
    const handleOnCancelClick = () => setModalClose();

    return (
        <div className={'start-application-modal'}>
            <div className={'header'}>Add this lender to your applications and alert the lender?</div>
            <div className={'text'}>
                <p>
                    This will show to this lender that you are interested in progressing with them and exchanging
                    documents.
                </p>
                <p className={'bold'}>You can choose to apply to as many of your lenders as you want.</p>
            </div>
            <div className={'actions'}>
                <p>
                    <Button color={'primary'} variant={'contained'} size={'large'} onClick={handleOnProceedClick}>
                        Proceed
                    </Button>
                </p>
                <p style={{ marginTop: 5 }}>
                    <Button color={'default'} variant={'outlined'} size={'large'} onClick={handleOnCancelClick}>
                        Cancel
                    </Button>
                </p>
            </div>
        </div>
    );
}
