import React from 'react';
import { reduxForm } from 'redux-form';
import styled from 'styled-components';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import { FieldButtonPanelRow } from 'pitch4_forms/components/inputs';
import { ServerErrors } from 'pitch4_forms/components';
import { TextField, TextLabel, FieldRow } from 'pitch4_forms/components/inputs';
import { Container, RowItem } from 'pitch4_layout/components/div';
import { sagaActionModalHide, sagaActionModalShow } from '../../../../modal/sagas/container';
import ModalLayout from '../../../../../pitch4_modal/src/component/ModalLayout';
import { connect } from 'react-redux';
import { SecondaryButton } from '../../../../../pitch4_layout/components/buttons';
import { RightAligned } from '../../../../../pitch4_layout/components/div';
const SpacerItem = styled(RowItem)``;
export const FORM_NAME = 'administer_lenders_delete_lender_logo_form';

class FormLogoDelete extends React.Component {
    openDeleteLogoModal = () => {
        let { onSubmit, handleSubmit, submitting, setModalComponent, setModalClose } = this.props;

        setModalComponent(
            <ModalLayout title={'Are you sure you?'}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Container className={'logoDeleteModal'}>
                        <span className={'logoDeleteText'}>You're about to delete a lender logo. Are you sure?</span>
                        <FieldButtonPanelRow>
                            <SecondaryButton type="button" disabled={submitting} onClick={setModalClose}>
                                Close
                            </SecondaryButton>
                            <PrimaryButton
                                type="submit"
                                disabled={submitting}
                                onClick={() => {
                                    setModalClose();
                                }}
                            >
                                Delete
                            </PrimaryButton>
                        </FieldButtonPanelRow>
                    </Container>
                </form>
            </ModalLayout>
        );
    };

    render() {
        let { submitting, error = [] } = this.props;

        return (
            <Container>
                <RowItem>
                    <ServerErrors errors={error} visible={true} />
                </RowItem>
                <FieldRow
                    label={<TextLabel label="ID" />}
                    input={<TextField name="id" placeholder="ID" disabled={true} />}
                />
                <SpacerItem />
                <RightAligned className={'button-panel create-reset-buttons'}>
                    <PrimaryButton type="button" disabled={submitting} onClick={this.openDeleteLogoModal}>
                        Delete
                    </PrimaryButton>
                </RightAligned>
            </Container>
        );
    }
}

FormLogoDelete.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    reset: PropTypes.func,
    initialValues: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
    };
};

export default compose(
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
    }),
    connect(mapStateToProps, mapDispatchToProps)
)(FormLogoDelete);
