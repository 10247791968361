import loanEnquiriesContainer, { NAMESPACE as LOAN_ENQUIRIES_REDUCER_NAMESPACE } from '../loan_enquiries/redux';
import documentsReducer, { NAMESPACE as DOCS_NAMESPACE } from '../documents/redux';
import documentsEditReducer, { REDUCER_NAMESPACE as DOCS_EDIT_NAMESPACE } from '../documents/redux/edit';
import lenderLoanEnquiryDocumentsAdd, {
    REDUCER_NAMESPACE as LLE_DOCS_ADD_NAMESPACE,
} from '../documents_widget/redux/add';
import lenderLoanEnquiryDocumentsEdit, {
    NAMESPACE as LLE_DOCS_EDIT_NAMESPACE,
} from '../lender_loan_enquires_documents/redux/edit';
import lenderLoanEnquiryDocuments, { NAMESPACE as LLE_DOCS_NAMESPACE } from '../lender_loan_enquires_documents/redux';
import intermediaryMleReducer, { NAMESPACE as MLE_NAMESPACE } from '../mle/redux';
import intermediaryMleApplicantReducer, {
    NAMESPACE as MLE_APPLICANT_NAMESPACE,
} from '../mle/form_sections/Applicant/redux';
import intermediaryViewLeMainReducer, { NAMESPACE as VIEW_LE_MAIN_NAMESPACE } from '../view_loan_enquiry/redux/main';
import intermediaryDeleteLeMainReducer, {
    NAMESPACE as DELETE_LE_MAIN_NAMESPACE,
} from '../delete_loan_enquiry/redux/main';
import intermediaryViewLeadMainReducer, {
    NAMESPACE as VIEW_LEAD_MAIN_NAMESPACE,
} from '../view_lender_loan_enquiry/redux/main';
import intermediaryViewApplicantsMainReducer, {
    NAMESPACE as VIEW_APPLICANTS_NAMESPACE,
} from '../applicants/redux/main';
import dynamicEnumReducer, { NAMESPACE as DYNAMIC_ENUM_NAMESPACE } from '../enum/redux/dynamic_enum';

export default {
    //Administer loan enquiries
    [LLE_DOCS_ADD_NAMESPACE]: lenderLoanEnquiryDocumentsAdd,
    [LLE_DOCS_EDIT_NAMESPACE]: lenderLoanEnquiryDocumentsEdit,
    [LLE_DOCS_NAMESPACE]: lenderLoanEnquiryDocuments,
    [LOAN_ENQUIRIES_REDUCER_NAMESPACE]: loanEnquiriesContainer,
    [MLE_NAMESPACE]: intermediaryMleReducer,
    [MLE_APPLICANT_NAMESPACE]: intermediaryMleApplicantReducer,
    [VIEW_LE_MAIN_NAMESPACE]: intermediaryViewLeMainReducer,
    [DELETE_LE_MAIN_NAMESPACE]: intermediaryDeleteLeMainReducer,
    [VIEW_LEAD_MAIN_NAMESPACE]: intermediaryViewLeadMainReducer,
    [VIEW_APPLICANTS_NAMESPACE]: intermediaryViewApplicantsMainReducer,
    [DYNAMIC_ENUM_NAMESPACE]: dynamicEnumReducer,
    [DOCS_NAMESPACE]: documentsReducer,
    [DOCS_EDIT_NAMESPACE]: documentsEditReducer,
};
