import React from 'react';
import { Field } from 'redux-form';
import { TextareaAutosize as TextareaAutosizeMuiCore } from '@material-ui/core';
import _ from 'lodash';

const TextAreaInput = ({ input: reduxFormProps, ...inputProps }) => {
    const {
        name,
        className = 'MuiInputBase-inputMultiline',
        rowsMin = 1,
        rowsMax = 4,
        placeholder = '',
        value = '',
    } = inputProps;

    return (
        <TextareaAutosizeMuiCore
            placeholder={placeholder}
            name={name}
            value={value}
            maxRows={rowsMax}
            minRows={rowsMin}
            className={className}
            {...reduxFormProps}
            {...inputProps}
        />
    );
};

class TextAreaAutosize extends React.Component {
    render() {
        const { name, fullWidth = true, InputProps = {} } = this.props;

        if (_.includes(['development', 'qa', 'test'], process.env.NODE_ENV)) {
            InputProps['data-cy'] = `${name}-input`;
        }

        return (
            <div className={`text-area ${fullWidth ? 'block' : 'inline-block'}`}>
                <Field name={name} {...this.props} component={TextAreaInput} />
            </div>
        );
    }
}

export default TextAreaAutosize;
