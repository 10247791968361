import { BORROWER_URL } from '../bootstrap/constants/constants';
import createP4ApolloFetch from '../bootstrap/middlewares';

export const getSession = (uri = BORROWER_URL) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const query = `
        query session {
            session {
                title
                given
                gender
                family
                user {
                    isVerified
                    smsVerificationRequested
                    userProfile {
                        dateOfBirth
                    }
                }
                primaryPhone{
                    number
                }
            }
        }
    `;

    return apolloFetch({
        query: query,
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
