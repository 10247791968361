import React, { useState } from 'react';
import { Button, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select } from '@material-ui/core';
import moment from 'moment';
import TermItemText from './TermItemText';
import TermItemNumber from './TermItemNumber';
import TermItemChoice from './TermItemChoice';
import {
    PRODUCT_BRIDGING_FINANCE,
    PRODUCT_BRIDGING_TO_LET,
    PRODUCT_BUSINESS_FINANCE,
    PRODUCT_CARE_HOME,
    PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS,
    PRODUCT_TERM_LOAN,
} from '../../../../../../pitch4_enum/enum/ProductEnum';
import { LOAN_TYPE_BUSINESS_FINANCE } from '../../../../../../pitch4_enum/enum/LoanTypeEnum';
import TermsAndFeesEnum from '../../../../../../pitch4_enum/enum/TermsAndFeesEnum';
import CircularProgress from '@material-ui/core/CircularProgress';
import { setLLETerms } from '../../../../../../pitch4_api/set_lle_terms';
import { API_URL } from '../../../../../../bootstrap/constants/constants';

export default function DrawerTerms(props) {
    const { lle, updateLenderTermsInGrid, updateMatchSelectedTerms } = props;
    const termsEnum = new TermsAndFeesEnum();
    const [submitting, setSubmitting] = useState(false);
    const getDefaultProduct = () => (lle.leType === LOAN_TYPE_BUSINESS_FINANCE ? 'business-finance' : 'term-loan');
    const getDefaultTerms = () => termsEnum.getType(getDefaultProduct());
    const [product, setProduct] = React.useState(lle.lenderTerms ? lle.lenderTerms.product : getDefaultProduct());
    const [terms, setTerms] = useState(lle.lenderTerms ? lle.lenderTerms.terms : getDefaultTerms().terms);
    const handleProductChange = (event) => {
        const newProduct = event.target.value;
        setProduct(newProduct);
        if (lle.lenderTerms !== null && newProduct === lle.lenderTerms.product) {
            setTerms(lle.lenderTerms.terms);
            setTermsProgress(calculateProgress(lle.lenderTerms.terms, 'handleProductChange-1'));
        } else {
            setTerms(termsEnum.getType(newProduct).terms);
            setTermsProgress(calculateProgress(termsEnum.getType(newProduct).terms, 'handleProductChange-2'));
        }
    };
    const updateTermValue = (item, itemIndex, newValue) => {
        item.value = newValue || null;
        terms.splice(itemIndex, 1, item);
        setTerms(terms);
        setTermsProgress(calculateProgress(terms, 'updateTermValue'));
    };
    const updateTermChoice = (item, itemIndex, newChoice) => {
        item.choiceIndex = item.choice.indexOf(newChoice);
        terms.splice(itemIndex, 1, item);
        setTerms(terms);
        setTermsProgress(calculateProgress(terms, 'updateTermChoice'));
    };
    const calculateProgress = (terms, from = null) => {
        const totalItemsCount = terms.length;
        const doneItemsCount = terms.filter((item) => item.value !== null).length;

        return {
            totalItemsCount: terms.length,
            doneItemsCount: terms.filter((item) => item.value !== null).length,
            percentDone:
                totalItemsCount !== 0 && doneItemsCount !== 0
                    ? Math.round((doneItemsCount / totalItemsCount) * 100)
                    : 0,
        };
    };
    const [termsProgress, setTermsProgress] = useState(calculateProgress(terms, 'state termsProgress'));
    const handleTermsSubmit = () => {
        setSubmitting(true);

        const newTerms = {
            product: product,
            terms: terms,
            updatedAt: moment().format(),
        };

        setLLETerms(API_URL, lle.id, newTerms).then((resp) => {
            setSubmitting(false);
            updateLenderTermsInGrid(lle.id, newTerms);
            updateMatchSelectedTerms(newTerms); // Updated matchSelected in LenderDrawer component
        });
    };

    return (
        <div className={'admin-terms-edit'}>
            <Paper elevation={1} className={'progress-container'}>
                <Grid container>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <div>
                            <div className={'title'}>Live lender terms</div>
                            <div className={'last-updated-title'}>Last Updated</div>
                            <div className={'last-updated-date'}>
                                {lle.lenderTerms
                                    ? moment(lle.lenderTerms.updatedAt).format('H:mm Do MMMM YYYY')
                                    : 'Awaiting Your Terms'}
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <div className={'progress'}>
                            <div
                                className={'title'}
                            >{`${termsProgress.doneItemsCount} / ${termsProgress.totalItemsCount} Terms offered`}</div>
                            <LinearProgress
                                className={'progress-bar'}
                                value={termsProgress.percentDone}
                                variant="determinate"
                            />
                        </div>
                    </Grid>
                </Grid>
            </Paper>
            <div style={{ marginTop: 20 }}>
                <div className={'product-select-container'}>
                    <div className={'header'}>Please select product:</div>
                    <FormControl variant="outlined" className={'product-select'}>
                        <InputLabel id="terms-product-select-outlined-label">Select product</InputLabel>
                        <Select
                            labelId="terms-product-select-outlined-label"
                            id="terms-product-select-outlined"
                            value={product}
                            onChange={handleProductChange}
                            label="Select product"
                        >
                            <MenuItem
                                value={PRODUCT_BUSINESS_FINANCE}
                                className={
                                    lle.lenderTerms &&
                                    lle.lenderTerms.product === PRODUCT_BUSINESS_FINANCE &&
                                    'product-select-product-active'
                                }
                            >
                                <span
                                    className={
                                        lle.lenderTerms &&
                                        lle.lenderTerms.product === PRODUCT_BUSINESS_FINANCE &&
                                        'active'
                                    }
                                >
                                    Business Finance
                                </span>
                            </MenuItem>
                            <MenuItem
                                value={PRODUCT_BRIDGING_FINANCE}
                                className={
                                    lle.lenderTerms &&
                                    lle.lenderTerms.product === PRODUCT_BRIDGING_FINANCE &&
                                    'product-select-product-active'
                                }
                            >
                                <span
                                    className={
                                        lle.lenderTerms &&
                                        lle.lenderTerms.product === PRODUCT_BRIDGING_FINANCE &&
                                        'active'
                                    }
                                >
                                    Bridging
                                </span>
                            </MenuItem>
                            <MenuItem
                                value={PRODUCT_TERM_LOAN}
                                className={
                                    lle.lenderTerms &&
                                    lle.lenderTerms.product === PRODUCT_TERM_LOAN &&
                                    'product-select-product-active'
                                }
                            >
                                <span
                                    className={
                                        lle.lenderTerms && lle.lenderTerms.product === PRODUCT_TERM_LOAN && 'active'
                                    }
                                >
                                    Term Loan
                                </span>
                            </MenuItem>
                            <MenuItem
                                value={PRODUCT_BRIDGING_TO_LET}
                                className={
                                    lle.lenderTerms &&
                                    lle.lenderTerms.product === PRODUCT_BRIDGING_TO_LET &&
                                    'product-select-product-active'
                                }
                            >
                                <span
                                    className={
                                        lle.lenderTerms &&
                                        lle.lenderTerms.product === PRODUCT_BRIDGING_TO_LET &&
                                        'active'
                                    }
                                >
                                    Bridge To Let
                                </span>
                            </MenuItem>
                            <MenuItem
                                value={PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS}
                                className={
                                    lle.lenderTerms &&
                                    lle.lenderTerms.product === PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS &&
                                    'product-select-product-active'
                                }
                            >
                                <span
                                    className={
                                        lle.lenderTerms &&
                                        lle.lenderTerms.product === PRODUCT_PROPERTY_DEVELOPMENT_AND_REFURBISHMENTS &&
                                        'active'
                                    }
                                >
                                    Refurbs and Developments
                                </span>
                            </MenuItem>
                            <MenuItem
                                value={PRODUCT_CARE_HOME}
                                className={
                                    lle.lenderTerms &&
                                    lle.lenderTerms.product === PRODUCT_CARE_HOME &&
                                    'product-select-product-active'
                                }
                            >
                                <span
                                    className={
                                        lle.lenderTerms && lle.lenderTerms.product === PRODUCT_CARE_HOME && 'active'
                                    }
                                >
                                    Care Home
                                </span>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            <div className={'edit-terms-container'}>
                {terms.map((item, index) => {
                    switch (item.valueType) {
                        case 'text':
                            return (
                                <TermItemText
                                    updateTermValue={updateTermValue}
                                    key={index}
                                    item={item}
                                    itemIndex={index}
                                    isLast={index === terms.length - 1}
                                />
                            );
                        case 'number':
                            return (
                                <TermItemNumber
                                    updateTermValue={updateTermValue}
                                    updateTermChoice={updateTermChoice}
                                    key={index}
                                    item={item}
                                    itemIndex={index}
                                    isLast={index === terms.length - 1}
                                />
                            );
                        case 'choice':
                            return (
                                <TermItemChoice
                                    updateTermValue={updateTermValue}
                                    key={index}
                                    item={item}
                                    itemIndex={index}
                                    isLast={index === terms.length - 1}
                                />
                            );
                        default:
                            return (
                                <TermItemText
                                    updateTermValue={updateTermValue}
                                    key={index}
                                    item={item}
                                    itemIndex={index}
                                    isLast={index === terms.length - 1}
                                />
                            );
                    }
                })}
            </div>
            <div className={'actions'}>
                <Button
                    className={'btn-submit'}
                    variant={'contained'}
                    size={'large'}
                    color={'primary'}
                    onClick={handleTermsSubmit}
                    disabled={submitting}
                >
                    {true === submitting ? <CircularProgress className={'progress'} /> : 'UPDATE TERMS'}
                </Button>
            </div>
        </div>
    );
}
