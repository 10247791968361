import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { actionCloseLeftSideMenu, selectorIsLeftSideMenuOpen } from '../redux/leftsidemenu';

import Menu from './Menu';

//visual components
import Drawer from '@material-ui/core/Drawer';

const StyledMenu = styled(Menu)`
    width: 280px;
`;

class LeftSideMenu extends React.Component {
    render() {
        const { open, actionCloseLeftSideMenu, menuItems } = this.props;

        if (!menuItems) {
            return <div className={`no_menu_items`} />;
        }

        return (
            <Drawer type="temporary" open={open} onClose={actionCloseLeftSideMenu}>
                <StyledMenu id="leftsidemenu">{menuItems}</StyledMenu>
            </Drawer>
        );
    }
}

LeftSideMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    actionCloseLeftSideMenu: PropTypes.func.isRequired,
    menuItems: PropTypes.arrayOf(PropTypes.element),
};

const mapStateToProps = (state) => {
    return {
        open: selectorIsLeftSideMenuOpen(state),
    };
};

export default connect(mapStateToProps, {
    actionCloseLeftSideMenu: actionCloseLeftSideMenu,
})(LeftSideMenu);
