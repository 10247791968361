import BaseEnum from 'pitch4_enum/enum/BaseEnum';

export const SORT_ASC = 'asc';
export const SORT_DESC = 'desc';

class SortDirectionEnum extends BaseEnum {
    getTypes = () => {
        return {
            [SORT_DESC]: 'Descending',
            [SORT_ASC]: 'Ascending',
        };
    };
}

export default SortDirectionEnum;
