import React from 'react';
import { Button } from '@material-ui/core';

export default function SetUserDigestNotifications(props) {
    const { user, setUserDigestNotifications, setModalClose } = props;

    return (
        <div className={'set-user-digest-notifications-container-modal'}>
            <div className={'header'}>Set user digest notifications</div>
            <div className={'content'}>
                Are you sure you want to change the user ({user.email}) <br />
                digest notifications to <strong>{!user.digestNotifications ? 'ON' : 'OFF'}</strong>
            </div>
            <div className={'actions'}>
                <Button className={'btn-back'} onClick={() => setModalClose()}>
                    BACK
                </Button>
                <Button
                    className={'btn-submit'}
                    variant={'contained'}
                    color="primary"
                    onClick={() => setUserDigestNotifications(user)}
                >
                    SUBMIT
                </Button>
            </div>
        </div>
    );
}
