import React from 'react';
import rgbToHex from '../utils/colourInterpolate/rgbToHex';
import interpolateColors from '../utils/colourInterpolate/Interpolate';

const defaultGray = '#C7C7C7';
const darkPurple = 'rgb(187, 186, 232)';
const hotPink = 'rgb(46, 196, 182)';

const StatusSteps = ({ totalSteps, currentStep, colours }) => {
    let items = [];

    for (let i = 0; i < totalSteps; i++) {
        const hexColour = i > currentStep ? defaultGray : rgbToHex(colours[i][0], colours[i][1], colours[i][2]);
        items.push(<span className={`status-step`} style={{ backgroundColor: `${hexColour}` }} />);
    }

    return items;
};

export default class StepsWidget extends React.Component {
    render() {
        const { totalSteps, currentStep } = this.props;
        const colours = interpolateColors(darkPurple, hotPink, totalSteps);

        return (
            <div className="status-widget-steps">
                <StatusSteps totalSteps={totalSteps} currentStep={currentStep} colours={colours} />
            </div>
        );
    }
}
