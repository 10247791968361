import {
    ROUTE_BORROWER_APPLICANTS_VIEW,
    ROUTE_BORROWER_CONFIGURE_PROFILE,
    ROUTE_BORROWER_CREATE_LOAN_ENQUIRY,
    ROUTE_BORROWER_DOCUMENTS_VIEW,
    ROUTE_BORROWER_EDIT_LOAN_ENQUIRY,
    ROUTE_BORROWER_LOAN_ENQUIRIES,
    ROUTE_BORROWER_LENDER_LOAN_ENQUIRY_VIEW,
    ROUTE_BORROWER_LOAN_ENQUIRY_VIEW,
    ROUTE_BORROWER_ROOT,
} from '../../../pitch4_routing';

export default {
    [ROUTE_BORROWER_ROOT]: [{ path: ROUTE_BORROWER_ROOT, label: 'Dashboard', link: false }],
    //Loan Enquiries
    [ROUTE_BORROWER_LOAN_ENQUIRIES]: [{ path: ROUTE_BORROWER_LOAN_ENQUIRIES, label: 'Loan Enquiries', link: false }],
    [ROUTE_BORROWER_LOAN_ENQUIRY_VIEW]: [
        { path: ROUTE_BORROWER_LOAN_ENQUIRIES, label: 'Loan Enquiries', link: true },
        { path: ROUTE_BORROWER_LOAN_ENQUIRY_VIEW, label: 'Loan Enquiry', link: false },
    ],
    [ROUTE_BORROWER_CREATE_LOAN_ENQUIRY]: [
        { path: ROUTE_BORROWER_LOAN_ENQUIRIES, label: 'Loan Enquiries', link: true },
        { path: ROUTE_BORROWER_CREATE_LOAN_ENQUIRY, label: 'Create Loan Enquiry', link: false },
    ],
    [ROUTE_BORROWER_EDIT_LOAN_ENQUIRY]: [
        { path: ROUTE_BORROWER_LOAN_ENQUIRIES, label: 'Loan Enquiries', link: true },
        { path: ROUTE_BORROWER_EDIT_LOAN_ENQUIRY, label: 'Create Loan Enquiry', link: false },
    ],
    [ROUTE_BORROWER_LENDER_LOAN_ENQUIRY_VIEW]: [
        { path: ROUTE_BORROWER_LOAN_ENQUIRIES, label: 'Loan Enquiries', link: true },
        { path: ROUTE_BORROWER_LOAN_ENQUIRY_VIEW, label: 'Single Loan Enquiry', link: true },
        { path: ROUTE_BORROWER_LENDER_LOAN_ENQUIRY_VIEW, label: 'Loan Match', link: false },
    ],
    //Applicants
    [ROUTE_BORROWER_APPLICANTS_VIEW]: [{ path: ROUTE_BORROWER_APPLICANTS_VIEW, label: 'Applicants', link: false }],
    //Documents
    [ROUTE_BORROWER_DOCUMENTS_VIEW]: [{ path: ROUTE_BORROWER_DOCUMENTS_VIEW, label: 'Your Documents', link: false }],
    //Configure Profile
    [ROUTE_BORROWER_CONFIGURE_PROFILE]: [
        { path: ROUTE_BORROWER_CONFIGURE_PROFILE, label: 'Account Settings', link: false },
    ],
};
