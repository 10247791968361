import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import { CenteringContainer, Container, Item, RowItem } from '../../../pitchblack_react_utils/layout/index';
import { DateField, TextField } from '../../../pitch4_forms/components/inputs';
import FormMessage, { MESSAGE_ERROR } from '../../../pitch4_forms/components/FormMessage';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../../bootstrap/constants/constants';
import { connect } from 'react-redux';
import HiddenField from '../../../pitch4_forms/components/inputs/HiddenField';
import { isDateTime, isDOBValid, isInDateRange, isRequired } from '../../../pitchblack_react_utils/form/validation';
import { hasMaxLength, isEmailValid, isValidPassword } from '../../../pitchblack_react_utils/form/validation';
import { registerHubspot } from '../api/register_hubspot';

const isValidApplicantAge = isInDateRange(
    -100,
    'years',
    'The maximum age allowed for an applicant is 99',
    -18,
    'years',
    'The minimum age allowed for an applicant is 18'
);

export const FormFields = ({ disabled, introducerConfig, initialValues }) => {
    const passwordValidate = [isRequired, isValidPassword, hasMaxLength(255)];

    return (
        <div className={`introducer-registration-form ${introducerConfig.name}`}>
            <RowItem>
                <CenteringContainer className={`form-heading-container`}>
                    <h1
                        className={`form-heading ${introducerConfig.name}`}
                        dangerouslySetInnerHTML={{
                            __html: introducerConfig.formHeaderText,
                        }}
                    />
                    <h2
                        className={`form-sub-heading ${introducerConfig.name}`}
                        dangerouslySetInnerHTML={{
                            __html: introducerConfig.formSubHeaderText,
                        }}
                    />
                </CenteringContainer>
            </RowItem>
            <HiddenField name="given" />
            <HiddenField name="family" />
            <HiddenField name="email" />
            {true === initialValues.dateOfBirthExists && <HiddenField name="dob" />}
            <HiddenField name="phoneNumber" />
            <HiddenField name="agreedToMarketing" />
            <HiddenField name="page" />
            <HiddenField name="sms_verification" />
            <RowItem>
                <CenteringContainer>
                    {false === initialValues.dateOfBirthExists && (
                        <Item lg={12} md={12} sm={12} xs={12}>
                            <DateField
                                name="dateOfBirth"
                                placeholder="Date of birth [dd/mm/yyyy]"
                                // disabled={submitting}
                                validate={[isRequired, isDateTime, isValidApplicantAge]}
                                disableFuture={true}
                            />
                        </Item>
                    )}
                    <Item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            variant={`outlined`}
                            InputProps={{}}
                            name="password"
                            type="password"
                            placeholder="Password"
                            disabled={disabled}
                            validate={passwordValidate}
                        />
                    </Item>
                </CenteringContainer>
            </RowItem>
        </div>
    );
};

const PrePopulatedIntroducerBorrowerRegistrationForm = ({
    errors = [],
    errorsVisible,
    handleSubmit,
    submitted,
    firstFieldAutoFocus,
    introducerConfig,
    initialValues,
}) => {
    const checkIfDisabled = () => {
        return (
            submitted ||
            null === initialValues.given ||
            '' === initialValues.given ||
            null === initialValues.family ||
            '' === initialValues.family ||
            null === initialValues.email ||
            '' === initialValues.email ||
            null === initialValues.password
        );
    };

    useEffect(() => {
        if (
            (initialValues.given !== '' || initialValues.given !== null) &&
            (initialValues.family !== '' || initialValues.family !== null) &&
            (initialValues.email !== '' || initialValues.email !== null)
        ) {
            registerHubspot(
                initialValues.given,
                initialValues.family,
                initialValues.email,
                initialValues.phoneNumber,
                initialValues.loanAmount,
                initialValues.loanLength,
                initialValues.loanType,
                initialValues.customerSource,
                initialValues.agreedToMarketing,
                initialValues.page
            ).then((r) => {});
        }
    }, []);

    return (
        <Item className={'content-container borrower-registration-form-item'}>
            <Container className={'registration-form borrower-registration-form-container'}>
                {errorsVisible &&
                    errors.map((error) => <FormMessage type={MESSAGE_ERROR} messages={error} key={error} />)}
                <CenteringContainer>
                    <form onSubmit={handleSubmit} className={'registration-form__form'}>
                        <FormFields
                            disabled={submitted}
                            firstFieldAutoFocus={firstFieldAutoFocus}
                            introducerConfig={introducerConfig}
                            initialValues={initialValues}
                        />
                        <RowItem>
                            <CenteringContainer className={'button-panel'}>
                                <PrimaryButton
                                    type="submit"
                                    className={`button-submit ${checkIfDisabled() ? 'disabled' : ''}`}
                                    disabled={checkIfDisabled()}
                                    style={{ marginRight: 0 }}
                                >
                                    Create Account
                                </PrimaryButton>
                            </CenteringContainer>
                        </RowItem>
                        <RowItem>
                            <CenteringContainer className={'privacy-panel'}>
                                <p>
                                    By clicking this button, you agree to receive our marketing
                                    <br />
                                    offers in accordance with our{' '}
                                    <a
                                        href={PRIVACY_POLICY_URL}
                                        className="typography-default-link"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        privacy policy
                                    </a>{' '}
                                    and you agree to
                                    <br />
                                    our{' '}
                                    <a href={TOS_URL} target={'_blank'} rel={'noreferrer'}>
                                        terms & conditions
                                    </a>
                                    .
                                </p>
                            </CenteringContainer>
                        </RowItem>
                    </form>
                </CenteringContainer>
            </Container>
        </Item>
    );
};

const mapStateToProps = (state) => {
    const href = window.location.href;
    const arr = new URL(href);

    const dateOfBirthValue = false === isDOBValid(arr.searchParams.get('dob')) ? null : arr.searchParams.get('dob');

    return {
        initialValues: {
            given:
                !arr.searchParams.get('fn') || arr.searchParams.get('fn').trim().length < 2
                    ? null
                    : arr.searchParams.get('fn'),
            family:
                !arr.searchParams.get('ln') || arr.searchParams.get('ln').trim().length < 2
                    ? null
                    : arr.searchParams.get('ln'),
            email: false === isEmailValid(arr.searchParams.get('e')) ? null : arr.searchParams.get('e'),
            phoneNumber:
                !arr.searchParams.get('p') || arr.searchParams.get('p').trim().length < 8
                    ? null
                    : arr.searchParams.get('p'),
            dateOfBirth: dateOfBirthValue,
            dateOfBirthExists: null !== dateOfBirthValue,
            loanAmount: !arr.searchParams.get('la') ? null : arr.searchParams.get('la'),
            loanLength: !arr.searchParams.get('ll') ? null : arr.searchParams.get('ll'),
            loanType: !arr.searchParams.get('lt') ? null : arr.searchParams.get('lt'),
            customerSource: !arr.searchParams.get('cs') ? null : arr.searchParams.get('cs'),
            agreedToMarketing: !(!arr.searchParams.get('am') || arr.searchParams.get('am').length === 0),
            page: !arr.searchParams.get('pg') ? document.referrer : arr.searchParams.get('pg'),
            sms_verification: false,
        },
    };
};

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'registration',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
)(PrePopulatedIntroducerBorrowerRegistrationForm);
