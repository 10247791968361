import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { CommercialTypeEnum } from '../../../../admin/enum/matching/enum';
import {
    COMMERCIAL_FUNDING_TYPE,
    HMO_FUNDING_TYPE,
    LAND_WITHOUT_PLANNING_FUNDING_TYPE,
    SEMI_COMMERCIAL_FUNDING_TYPE,
} from '../../../enums/Shared/CommercialFundingType';
import BorrowerQuestions, {
    QUESTION_BUY_TO_LET_EXPERIENCE,
    QUESTION_COMMERCIAL_OWNER_OCCUPIED,
    QUESTION_COMMERCIAL_TYPE_OTHER,
    QUESTION_COMMERCIAL_TYPES,
    QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
    QUESTION_HMO_BEDROOMS,
    QUESTION_HMO_HAS_LICENSE,
    QUESTION_HMO_LICENSE_IS_REQUIRED,
    QUESTION_HMO_ROOMS_SELF_CONTAINED,
    QUESTION_PROPERTY_TYPE,
    QUESTION_RESIDENTIAL_REFINANCE,
    QUESTION_RESIDENTIAL_UNIT_COUNT,
    QUESTION_SITE_DESCRIPTION,
    QUESTION_SITE_INTENTIONS,
} from '../../../enums/Borrower/BorrowerQuestions';
import YesNo, { YES } from '../../../enums/Shared/YesNo';
import Question from '../../Question/containers/Question';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';
import { OTHER } from '../../../enums/Shared/PropertyLocation';

class BridgingSelectionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        const commercialTypeOptions = new CommercialTypeEnum().getOptions();
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const yesNoOptions = new YesNo().getTypes();

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER
                            ? 'About your Bridging Finance'
                            : 'About your clients Bridging Finance'}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={12}></Grid>
                {this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === HMO_FUNDING_TYPE &&
                    this.state.formData[questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName] === YES && (
                        <>
                            <Question
                                formData={this.state.formData}
                                formValidation={this.state.validation}
                                type={questions[QUESTION_HMO_HAS_LICENSE].type}
                                label={questions[QUESTION_HMO_HAS_LICENSE].label}
                                questionEnum={'QUESTION_HMO_HAS_LICENSE'}
                                fieldName={questions[QUESTION_HMO_HAS_LICENSE].fieldName}
                                placeholder={questions[QUESTION_HMO_HAS_LICENSE].placeholder}
                                validation={questions[QUESTION_HMO_HAS_LICENSE].fieldName + '_error'}
                                onChangeFunction={this.props.onChangeHandler}
                                fieldOptions={yesNoOptions}
                                rows={0}
                            />
                            <Question
                                formData={this.state.formData}
                                formValidation={this.state.validation}
                                type={questions[QUESTION_HMO_LICENSE_IS_REQUIRED].type}
                                label={questions[QUESTION_HMO_LICENSE_IS_REQUIRED].label}
                                questionEnum={'QUESTION_HMO_LICENSE_IS_REQUIRED'}
                                fieldName={questions[QUESTION_HMO_LICENSE_IS_REQUIRED].fieldName}
                                placeholder={questions[QUESTION_HMO_LICENSE_IS_REQUIRED].placeholder}
                                validation={questions[QUESTION_HMO_LICENSE_IS_REQUIRED].fieldName + '_error'}
                                onChangeFunction={this.props.onChangeHandler}
                                fieldOptions={yesNoOptions}
                                rows={0}
                            />
                        </>
                    )}

                {this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === HMO_FUNDING_TYPE && (
                    <>
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_HMO_BEDROOMS].type}
                            label={questions[QUESTION_HMO_BEDROOMS].label}
                            questionEnum={'QUESTION_HMO_BEDROOMS'}
                            fieldName={questions[QUESTION_HMO_BEDROOMS].fieldName}
                            placeholder={questions[QUESTION_HMO_BEDROOMS].placeholder}
                            validation={questions[QUESTION_HMO_BEDROOMS].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            rows={0}
                        />
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_HMO_ROOMS_SELF_CONTAINED].type}
                            label={questions[QUESTION_HMO_ROOMS_SELF_CONTAINED].label}
                            questionEnum={'QUESTION_HMO_BEDROOMS'}
                            fieldName={questions[QUESTION_HMO_ROOMS_SELF_CONTAINED].fieldName}
                            placeholder={questions[QUESTION_HMO_ROOMS_SELF_CONTAINED].placeholder}
                            validation={questions[QUESTION_HMO_ROOMS_SELF_CONTAINED].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            rows={0}
                        />
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_BUY_TO_LET_EXPERIENCE].type}
                            label={questions[QUESTION_BUY_TO_LET_EXPERIENCE].label}
                            questionEnum={'QUESTION_BUY_TO_LET_EXPERIENCE'}
                            fieldName={questions[QUESTION_BUY_TO_LET_EXPERIENCE].fieldName}
                            placeholder={questions[QUESTION_BUY_TO_LET_EXPERIENCE].placeholder}
                            validation={questions[QUESTION_BUY_TO_LET_EXPERIENCE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            rows={0}
                        />
                    </>
                )}

                {this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE ||
                this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE ? (
                    <>
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].type}
                            label={questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].label}
                            questionEnum={'QUESTION_COMMERCIAL_UNITS_RENTED_OUT'}
                            fieldName={questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            rows={0}
                        />
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].type}
                            label={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].label}
                            questionEnum={'QUESTION_COMMERCIAL_OWNER_OCCUPIED'}
                            fieldName={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            rows={0}
                        />
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_BUY_TO_LET_EXPERIENCE].type}
                            label={questions[QUESTION_BUY_TO_LET_EXPERIENCE].label}
                            questionEnum={'QUESTION_BUY_TO_LET_EXPERIENCE'}
                            fieldName={questions[QUESTION_BUY_TO_LET_EXPERIENCE].fieldName}
                            placeholder={questions[QUESTION_BUY_TO_LET_EXPERIENCE].placeholder}
                            validation={questions[QUESTION_BUY_TO_LET_EXPERIENCE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            rows={0}
                        />
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_COMMERCIAL_TYPES].type}
                            label={questions[QUESTION_COMMERCIAL_TYPES].label}
                            questionEnum={'QUESTION_COMMERCIAL_TYPES'}
                            fieldName={questions[QUESTION_COMMERCIAL_TYPES].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_TYPES].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={commercialTypeOptions}
                            rows={0}
                        />
                        {this.state.formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) && (
                            <Question
                                formData={this.state.formData}
                                formValidation={this.state.validation}
                                type={questions[QUESTION_COMMERCIAL_TYPE_OTHER].type}
                                label={questions[QUESTION_COMMERCIAL_TYPE_OTHER].label}
                                questionEnum={'QUESTION_COMMERCIAL_TYPE_OTHER'}
                                fieldName={questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName}
                                placeholder={questions[QUESTION_COMMERCIAL_TYPE_OTHER].placeholder}
                                validation={questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'}
                                onChangeFunction={this.props.onChangeHandler}
                                fieldOptions={[]}
                                rows={0}
                            />
                        )}
                    </>
                ) : (
                    ''
                )}

                {this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] ===
                    LAND_WITHOUT_PLANNING_FUNDING_TYPE && (
                    <>
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_SITE_DESCRIPTION].type}
                            label={questions[QUESTION_SITE_DESCRIPTION].label}
                            questionEnum={'QUESTION_SITE_DESCRIPTION'}
                            fieldName={questions[QUESTION_SITE_DESCRIPTION].fieldName}
                            placeholder={questions[QUESTION_SITE_DESCRIPTION].placeholder}
                            validation={questions[QUESTION_SITE_DESCRIPTION].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            rows={questions[QUESTION_SITE_DESCRIPTION].rows}
                        />
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_SITE_INTENTIONS].type}
                            label={questions[QUESTION_SITE_INTENTIONS].label}
                            questionEnum={'QUESTION_SITE_INTENTIONS'}
                            fieldName={questions[QUESTION_SITE_INTENTIONS].fieldName}
                            placeholder={questions[QUESTION_SITE_INTENTIONS].placeholder}
                            validation={questions[QUESTION_SITE_INTENTIONS].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            rows={questions[QUESTION_SITE_INTENTIONS].rows}
                        />
                    </>
                )}

                {this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE && (
                    <Question
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        type={questions[QUESTION_RESIDENTIAL_UNIT_COUNT].type}
                        label={questions[QUESTION_RESIDENTIAL_UNIT_COUNT].label}
                        questionEnum={'QUESTION_RESIDENTIAL_UNIT_COUNT'}
                        fieldName={questions[QUESTION_RESIDENTIAL_UNIT_COUNT].fieldName}
                        placeholder={questions[QUESTION_RESIDENTIAL_UNIT_COUNT].placeholder}
                        validation={questions[QUESTION_RESIDENTIAL_UNIT_COUNT].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={[]}
                        rows={0}
                    />
                )}
            </Grid>
        );
    }
}

export default BridgingSelectionContainer;
