import { INTERMEDIARY_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';
import { SORT_ASC } from 'pitch4_tabular/enum/SortDirectionEnum';

const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });

const GetApplicantsQuery = `
    query getApplicants($first: Int!, $sorts: [SortInput], $filters: [FilterInput]) {
        applicants(first: $first, sorts: $sorts, filters: $filters) {
            edges {
                node {
                    id
                    given
                    family
                    dateOfBirth
                    createdAt
                    updatedAt
                }
                cursor
            }
            pageInfo{
              hasNextPage,
              endCursor,
              startCursor,
              hasPreviousPage
            }
            totalCount
        }
    }
`;

export const getApplicants = (filters = [], size = 99999) => {
    let out = apolloFetch({
        query: GetApplicantsQuery,
        variables: {
            first: size,
            sorts: [
                {
                    column: 'given',
                    direction: SORT_ASC,
                },
            ],
            filters: filters,
        },
    })
        .then(({ data, errors }) => {
            return data;
        })
        .catch(() => {
            return [];
        });

    return out;
};
