import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { selectorLeftMenuItems } from '../redux/leftsidemenu';

import LeftSideMenu from '../components/LeftSideMenu';
import MenuItem from '../components/MenuItem';

const StyledMenuItem = styled(MenuItem)`
    width: 100%;
`;

class LeftSideMenuContainer extends React.Component {
    createMenuItems = (menuItems) => {
        return menuItems.map((item, index) => {
            return <StyledMenuItem key={index} {...item} />;
        });
    };

    render() {
        const { menuItemsConfig } = this.props;

        let menuItems = this.createMenuItems(menuItemsConfig);

        if (menuItems.length === 0) {
            return <div className="no_menu_items" />;
        }

        return <LeftSideMenu menuItems={menuItems} />;
    }
}

LeftSideMenuContainer.propTypes = {
    menuItemsConfig: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = (state) => {
    return {
        menuItemsConfig: selectorLeftMenuItems(state),
    };
};

export default connect(mapStateToProps)(LeftSideMenuContainer);
