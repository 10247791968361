import { BaseEnum } from 'pitch4_enum';

export const STATUS_PENDING = 'STATUS_PENDING';
export const STATUS_ACCEPTED = 'STATUS_ACCEPTED';

class StatusEnum extends BaseEnum {
    getTypes = () => {
        return {
            [STATUS_PENDING]: 'Pending',
            [STATUS_ACCEPTED]: 'Accepted',
        };
    };
}

export default StatusEnum;
