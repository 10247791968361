import createP4ApolloFetch from 'bootstrap/middlewares';

const GetMatchesQuery = `
    query getMatchDebug(
        $loanEnquiryId: ID!
        $lenderLoanEnquiryId: ID
        $businessStreamId: ID
    ) {
        getMatchDebug(
            loanEnquiryId: $loanEnquiryId
            lenderLoanEnquiryId: $lenderLoanEnquiryId
            businessStreamId: $businessStreamId
        ) {
            data
       }
    }
`;

export const getMatchesDebug = (uri, loanEnquiryId, lenderLoanEnquiryId) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    return apolloFetch({
        query: GetMatchesQuery,
        variables: {
            loanEnquiryId: loanEnquiryId,
            lenderLoanEnquiryId: lenderLoanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            if (error) {
                throw error;
            }

            return error;
        });
};

export const getMatchesStreamDebug = (uri, loanEnquiryId, businessStreamId) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    return apolloFetch({
        query: GetMatchesQuery,
        variables: {
            loanEnquiryId: loanEnquiryId,
            businessStreamId: businessStreamId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            if (error) {
                throw error;
            }

            return error;
        });
};
