import { put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import { LOCATION_CHANGE, push } from 'react-router-redux';
import { actionHubspotConversationsRefresh } from '../../apps/hubspot/redux/conversations';
import { actionHubspotTrackingPageView } from '../../apps/hubspot/redux/tracking';
import SessionManager from '../../pitchblack_react_utils/session/SessionManager';
import { determineAuthenticatedBaseRoute } from '../src/findRoute';

export const SAGA_NAMESPACE = 'sagas.routing.events';

function* setHubspotPageView() {
    yield put(actionHubspotTrackingPageView(window.location.pathname));
}

function* refreshHubspotConversationsWidget() {
    yield put(actionHubspotConversationsRefresh());
}

function* forceTwoFactorAuthentication() {
    const sessionManager = new SessionManager();

    if (sessionManager.isAuthenticated() === false) {
        return;
    }

    if (sessionManager.isTwoFactorAuthForced()) {
        const route = determineAuthenticatedBaseRoute();
        const enableTwoFactorAuthRoute = route + '/two-factor-auth';
        const currentRoute = window.location.pathname;

        // Redirect to the base 2FA route if we are on it or any of its child routes.
        if (!_.isUndefined(route) && !_.isNull(route) && currentRoute.indexOf(enableTwoFactorAuthRoute) !== 0) {
            yield put(push(enableTwoFactorAuthRoute));
        }
    }
}

// Main Saga
function* main() {
    // Track the page view in Hubspot
    yield takeLatest(LOCATION_CHANGE, setHubspotPageView);

    // This makes sure that the conversations widget only displays on the correct pages.
    yield takeLatest(LOCATION_CHANGE, refreshHubspotConversationsWidget);

    // Force the user through the 2FA process if required
    yield takeLatest(LOCATION_CHANGE, forceTwoFactorAuthentication);
}

export default main;
