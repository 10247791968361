import React from 'react';
import { BRAND_MAIN_CONTACT } from '../../../bootstrap/constants/constants';
import { Container } from '../../../pitchblack_react_utils/layout';
import { Paper } from '@material-ui/core';

class PasswordResetRequestSuccess extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Paper style={{ padding: 20 }}>
                    <Container>
                        <h1 className={'heading'}>Password Reset Sent</h1>
                    </Container>
                    <Container className={'margin-top-25'}>
                        <p className={'body margin-bottom-15'}>
                            Thank you we have received your forgotten password request. Please check your inbox for an
                            activation link.
                        </p>
                        <p className={'body'}>
                            If you don't receive the mail and it's not in your inbox, don't hesitate to contact us on{' '}
                            <strong className={'text-highlight'}>{BRAND_MAIN_CONTACT}</strong>
                        </p>
                    </Container>
                </Paper>
            </React.Fragment>
        );
    }
}

export default PasswordResetRequestSuccess;
