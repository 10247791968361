import { spawn } from 'redux-saga/effects';

import passwordResetRootSaga from '../sagas/password_reset';
import flagPasswordResetRootSaga from '../sagas/flag_password_reset';

function* main() {
    yield spawn(passwordResetRootSaga);
    yield spawn(flagPasswordResetRootSaga);
}

export default main;
