import questionIcon from '../../assets/images/question-mark.svg';
import StepsWidget from './StepsWidget';
import React from 'react';

const renderStepCount = (index, size, showProgress) => {
    if (false === showProgress) {
        return '';
    }

    return (
        <div className={'joyride-row'}>
            <div className={'joyride-status-widget-count'}>
                {index + 1} of {size}
            </div>
        </div>
    );
};

const renderStepWidget = (index, size, showProgress) => {
    if (false === showProgress) {
        return '';
    }

    return <StepsWidget totalSteps={size} currentStep={index} />;
};

const renderDirectionButton = (isLastStep, primaryProps) => {
    return (
        <span className={'button'} {...primaryProps}>
            {true === isLastStep ? 'Close' : 'Next'}
        </span>
    );
};

const JoyrideTooltip = ({ index, size, step, closeProps, primaryProps, tooltipProps, isLastStep }) => {
    return (
        <div className={'joyride-tooltip-body joyride-tooltip-width'} {...tooltipProps}>
            {step.title && (
                <div className={'joyride-tooltip-title'}>
                    <img className={'joyride-tooltip-img'} alt="Questions" src={questionIcon} />
                    <div className={'joyride-tooltip-title-text'}>{step.title}</div>
                </div>
            )}
            <div className={'joyride-tooltip-content'}>{step.content}</div>

            <div className={'joyride-tooltip-footer'}>
                {renderStepCount(index, size, step.showProgress)}

                <div className={'joyride-row'}>
                    {renderStepWidget(index, size, step.showProgress)}
                    {renderDirectionButton(isLastStep, primaryProps)}
                </div>
            </div>
        </div>
    );
};

export default JoyrideTooltip;
