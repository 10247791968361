import { BaseEnum } from '../../pitch4_enum';
import { ROLE_ADMIN, ROLE_INTERMEDIARY, ROLE_SLAM, ROLE_SUPER_ADMIN } from './RoleNameEnum';
import { WEB_HELP_HOST } from '../../bootstrap/constants/constants';

export const ADMIN_ADD_TEAM_MEMBERS_URL = `${WEB_HELP_HOST}/en/knowledge/how-to-add-a-team-member-1`;
export const SLAM_ADD_TEAM_MEMBERS_URL = `${WEB_HELP_HOST}/en/knowledge/how-to-add-a-team-member-1`;
export const INTERMEDIARY_ADD_TEAM_MEMBERS_URL = `${WEB_HELP_HOST}/en/knowledge/how-to-add-a-team-member`;

class URLEnum extends BaseEnum {
    getURLs = () => {
        return {
            [ROLE_SUPER_ADMIN]: ADMIN_ADD_TEAM_MEMBERS_URL,
            [ROLE_ADMIN]: ADMIN_ADD_TEAM_MEMBERS_URL,
            [ROLE_INTERMEDIARY]: INTERMEDIARY_ADD_TEAM_MEMBERS_URL,
            [ROLE_SLAM]: SLAM_ADD_TEAM_MEMBERS_URL,
        };
    };

    getURL = (role) => this.getURLs()[role];
}

export default URLEnum;
