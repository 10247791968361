import _ from 'lodash';
import React from 'react';

export const TOKEN_TYPE_SIMPLE_LINK = 'link_no_text';
export const TOKEN_TYPE_LINK = 'link_with_text';
export const TOKEN_TYPE_SIMPLE_LINK_EXTERNAL = 'link_external_no_text';
export const TOKEN_TYPE_LINK_EXTERNAL = 'link_external_with_text';
export const TOKEN_TYPE_ROUTE_CHANGE = 'route_change';
export const TOKEN_TYPE_HIGHLIGHT = 'highlight';
export const TOKEN_TYPE_BOLD = 'bold';
export const TOKEN_TYPE_ALL = [
    TOKEN_TYPE_SIMPLE_LINK,
    TOKEN_TYPE_LINK,
    TOKEN_TYPE_SIMPLE_LINK_EXTERNAL,
    TOKEN_TYPE_LINK_EXTERNAL,
    TOKEN_TYPE_ROUTE_CHANGE,
    TOKEN_TYPE_HIGHLIGHT,
    TOKEN_TYPE_BOLD,
];

export const TOKEN_REGEXES = {
    [TOKEN_TYPE_SIMPLE_LINK]: /\[link\|([^\]|]+)\]/g,
    [TOKEN_TYPE_LINK]: /\[link\|([^\]]+)\|([^\]|]+)\]/g,
    [TOKEN_TYPE_SIMPLE_LINK_EXTERNAL]: /\[link_external\|([^\]|]+)\]/g,
    [TOKEN_TYPE_LINK_EXTERNAL]: /\[link_external\|([^\]]+)\|([^\]|]+)\]/g,
    [TOKEN_TYPE_ROUTE_CHANGE]: /\[route_change\]/g,
    [TOKEN_TYPE_HIGHLIGHT]: /\[highlight\|([^\]|]+)\]/g,
    [TOKEN_TYPE_BOLD]: /\[bold\|([^\]|]+)\]/g,
};

export const filterTokens = (content, tokenTypes = []) => {
    if (typeof content === 'number') {
        content = content.toString();
    }

    const tokens = parseTokens(content, tokenTypes);

    return convertContentToJSXArray(content, tokens);
};

const parseTokens = (content, tokenTypes = []) => {
    let match = false;
    let tokens = [];

    if (tokenTypes.length === 0) {
        tokenTypes = TOKEN_TYPE_ALL;
    }

    if (typeof content === 'number') {
        content = content.toString();
    }

    tokenTypes.forEach((type) => {
        const regex = TOKEN_REGEXES[type];

        while ((match = regex.exec(content)) !== null) {
            tokens.push({
                type: type,
                start: match.index,
                end: match.index + match[0].length,
                match: _.cloneDeep(match),
            });
        }
    });

    tokens.sort((a, b) => (a.start > b.start ? 1 : -1));

    return tokens;
};

const convertContentToJSXArray = (content, tokens) => {
    let filteredContent = [];
    let prevTokenEnd = 0;
    for (let i = 0; i < tokens.length; i++) {
        const token = tokens[i];
        const { type, start, end, match } = token;

        if (prevTokenEnd < start) {
            filteredContent.push(content.substr(prevTokenEnd, start - prevTokenEnd));
        }

        switch (type) {
            case TOKEN_TYPE_ROUTE_CHANGE:
                filteredContent.push(
                    <span key={i} className="token-route-change">
                        Route change
                    </span>
                );
                break;

            case TOKEN_TYPE_SIMPLE_LINK:
                filteredContent.push(
                    <a key={i} className="token-link" href={match[1]}>
                        {match[1]}
                    </a>
                );
                break;

            case TOKEN_TYPE_LINK:
                filteredContent.push(
                    <a key={i} className="token-link" href={match[1]}>
                        {match[2]}
                    </a>
                );
                break;

            case TOKEN_TYPE_SIMPLE_LINK_EXTERNAL:
                filteredContent.push(
                    <a key={i} className="token-link" href={match[1]} target="_blank" rel="noreferrer">
                        {match[1]}
                    </a>
                );
                break;

            case TOKEN_TYPE_LINK_EXTERNAL:
                filteredContent.push(
                    <a key={i} className="token-link" href={match[1]} target="_blank" rel="noreferrer">
                        {match[2]}
                    </a>
                );
                break;

            case TOKEN_TYPE_HIGHLIGHT:
                filteredContent.push(
                    <span key={i} className="token-highlight">
                        {match[1]}
                    </span>
                );
                break;

            case TOKEN_TYPE_BOLD:
                filteredContent.push(
                    <span key={i} className="bold typography-default-link">
                        {match[1]}
                    </span>
                );
                break;

            default:
                continue;
        }

        prevTokenEnd = end;
    }

    if (content && prevTokenEnd < content.length) {
        filteredContent.push(content.slice(prevTokenEnd, content.length));
    }

    return filteredContent;
};
