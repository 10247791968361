import { BaseEnum } from 'pitch4_enum';

export const REFRESH_MAX = 10000;
export const REFRESH_VFAST = 20000;
export const REFRESH_FAST = 30000;
export const REFRESH_BRISK = 60000;
export const REFRESH_NORM = 120000;
export const REFRESH_SLOW = 120000;

class OperationEnum extends BaseEnum {
    getTypes = () => {
        return {
            [REFRESH_MAX]: 'Max',
            [REFRESH_VFAST]: 'Very Fast',
            [REFRESH_FAST]: 'Fast',
            [REFRESH_BRISK]: 'Brisk',
            [REFRESH_NORM]: 'Normal',
            [REFRESH_SLOW]: 'Slow',
        };
    };
}

export default OperationEnum;
