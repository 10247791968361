import createP4ApolloFetch from 'bootstrap/middlewares';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const SetLLEFavMutation = `
  mutation setLenderLoanEnquiryFavourite($lenderLoanEnquiry: ID, $loanEnquiry: ID, $input: setLenderLoanEnquiryFavouriteInput!) {
      setLenderLoanEnquiryFavourite(lenderLoanEnquiry: $lenderLoanEnquiry, loanEnquiry: $loanEnquiry, input: $input) {
        lenderLoanEnquiry
        loanEnquiry
        favourite
      }
    }
`;

export const setLLEFav = (lenderLoanEnquiry, loanEnquiry, favourite) => {
    return apolloFetch({
        query: SetLLEFavMutation,
        variables: {
            lenderLoanEnquiry,
            loanEnquiry,
            input: { favourite },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
