import { BaseEnum } from 'pitch4_enum';

export const PROPERTY_LOAN_TYPE = 'PROPERTY_LOAN_TYPE';
export const PROPERTY_TYPE = 'PROPERTY_TYPE';
export const PROPERTY_PURCHASE_PRICE = 'PROPERTY_PURCHASE_PRICE';
export const DEVELOP_PURCHASE_PRICE = 'DEVELOP_PURCHASE_PRICE';
export const POSTCODE = 'POSTCODE';
export const ADDRESS = 'ADDRESS';
export const LOAN_AMOUNT = 'LOAN_AMOUNT';
export const LOAN_TERM = 'LOAN_TERM';
export const LOAN_NEEDED_BY = 'LOAN_NEEDED_BY';
export const LOAN_LTV = 'LOAN_LTV';
export const LOAN_TYPE = 'LOAN_TYPE';
export const PROPERTY_INTEND_TO_OCCUPY = 'PROPERTY_INTEND_TO_OCCUPY';
export const PROPERTY_WAS_OCCUPIED = 'PROPERTY_WAS_OCCUPIED';
export const PROPERTY_EXPECTED_RENTAL_INCOME = 'PROPERTY_EXPECTED_RENTAL_INCOME';
export const PROPERTY_HOW_OWNED = 'PROPERTY_HOW_OWNED';
export const BORROWER_GROWTH_AND_INCOME = 'BORROWER_GROWTH_AND_INCOME';
export const BORROWER_EMPLOYMENT_STATUS = 'BORROWER_EMPLOYMENT_STATUS';
export const BORROWER_OCCUPATION = 'BORROWER_OCCUPATION';
export const BORROWER_OWN_OTHER_PROPERTIES = 'BORROWER_OWN_OTHER_PROPERTIES';
export const BORROWER_HAS_ADVERSE_CREDIT = 'BORROWER_HAS_ADVERSE_CREDIT';
export const BORROWER_HAS_CCJS = 'BORROWER_HAS_CCJS';
export const BORROWER_CCJ_WHEN = 'BORROWER_CCJ_WHEN';
export const BORROWER_CCJ_WHY = 'BORROWER_CCJ_WHY';
export const BORROWER_CCJ_HOW_MUCH = 'BORROWER_CCJ_HOW_MUCH';
export const BORROWER_CCJ_SATISFIED = 'BORROWER_CCJ_SATISFIED';
export const BORROWER_HAS_DEFAULTED = 'BORROWER_HAS_DEFAULTED';
export const BORROWER_DEFAULTED_WHEN = 'BORROWER_DEFAULTED_WHEN';
export const BORROWER_DEFAULTED_WHY = 'BORROWER_DEFAULTED_WHY';
export const BORROWER_DEFAULTED_HOW_MUCH = 'BORROWER_DEFAULTED_HOW_MUCH';
export const BORROWER_DEFAULTED_UP_TO_DATE = 'BORROWER_DEFAULTED_UP_TO_DATE';
export const BORROWER_IS_UK_CITIZEN = 'BORROWER_IS_UK_CITIZEN';
export const BORROWER_NATIONALITY = 'BORROWER_NATIONALITY';
export const BORROWER_COUNTRY_OF_RESIDENCE = 'BORROWER_COUNTRY_OF_RESIDENCE';
export const BORROWER_RIGHT_TO_RESIDE = 'BORROWER_RIGHT_TO_RESIDE';
export const LAND_HAS_PLANNING_PERMISSION = 'LAND_HAS_PLANNING_PERMISSION';
export const LAND_PURCHASE_PRICE = 'LAND_PURCHASE_PRICE';
export const LAND_HOW_OWNED = 'LAND_HOW_OWNED';
export const PROPERTY_IS_ALREADY_OWNED = 'PROPERTY_IS_ALREADY_OWNED';
export const INITIAL_LOAN_AMOUNT_REQUIRED = 'INITIAL_LOAN_AMOUNT_REQUIRED';
export const PLANNING_IN_PLACE = 'PLANNING_IN_PLACE';
export const PLANNING_FOR = 'PLANNING_FOR';
export const PLANNING_DESCRIPTION = 'PLANNING_DESCRIPTION';
export const BUILD_COSTS = 'BUILD_COSTS';
export const LOAN_NEEDED_TO_COVER_BUILD_COSTS = 'LOAN_NEEDED_TO_COVER_BUILD_COSTS';
export const LOAN_AMOUNT_TO_COVER_BUILD_COSTS = 'LOAN_AMOUNT_TO_COVER_BUILD_COSTS';
export const REFURBISHMENT_NOT_REQUIRE_PLANNING = 'REFURBISHMENT_NOT_REQUIRE_PLANNING';
export const REFURBISHMENT_COSTS = 'REFURBISHMENT_COSTS';
export const LOAN_NEEDED_TO_COVER_REFURBISHMENT_COSTS = 'LOAN_NEEDED_TO_COVER_REFURBISHMENT_COSTS';
export const LOAN_AMOUNT_TO_COVER_REFURBISHMENT_COSTS = 'LOAN_AMOUNT_TO_COVER_REFURBISHMENT_COSTS';
export const EXPECTED_END_VALUE = 'EXPECTED_END_VALUE';
export const HAS_EXPERIENCE_DEVELOPING = 'HAS_EXPERIENCE_DEVELOPING';
export const REGISTER_USER_AGE_POINTINTIME = 'REGISTER_USER_AGE_POINTINTIME';
export const TYPE_OF_COMMERCIAL = 'TYPE_OF_COMMERCIAL';
export const PROPERTY_TO_BE_RENTED_OUT = 'PROPERTY_TO_BE_RENTED_OUT';
export const IS_SPECIFIC_TENANT = 'IS_SPECIFIC_TENANT';
export const TYPE_OF_TENANT = 'TYPE_OF_TENANT';
export const TENANCY_TERM = 'TENANCY_TERM';
export const ANNUAL_RENT = 'ANNUAL_RENT';
export const COMMERCIAL_PROPERTY_TO_BE_DEVELOPED = 'COMMERCIAL_PROPERTY_TO_BE_DEVELOPED';
export const BUSINESS_INTEND_TO_OCCUPY = 'BUSINESS_INTEND_TO_OCCUPY';
export const ADDITIONAL_INFO_NOTES = 'Additional information';

export const LOAN_PURPOSE = 'LOAN_PURPOSE';
export const LOAN_REASON = 'LOAN_REASON';
export const BUSINESS_NAME = 'BUSINESS_NAME';
export const BUSINESS_SECTOR = 'BUSINESS_SECTOR';
export const BUSINESS_HAD_ADVERSE_CREDIT = 'BUSINESS_HAD_ADVERSE_CREDIT';
export const BUSINESS_ADVERSE_CREDIT_DETAILS = 'BUSINESS_ADVERSE_CREDIT_DETAILS';
export const BUSINESS_TOTAL_DEBTS = 'BUSINESS_TOTAL_DEBTS';
export const PREVIOUS_YEAR_TURNOVER = 'PREVIOUS_YEAR_TURNOVER';
export const PDQ_TURNOVER_PER_MONTH = 'PDQ_TURNOVER_PER_MONTH';
export const SHAREHOLDER_IS_HOMEOWNER = 'SHAREHOLDER_IS_HOMEOWNER';
export const LOAN_ASSET = 'LOAN_ASSET';
export const BUSINESS_OUTSTANDING_INVOICE = 'BUSINESS_OUTSTANDING_INVOICE';
export const BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT = 'BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT';
export const BUSINESS_PROFITABLE = 'BUSINESS_PROFITABLE';
export const BUSINESS_ADDITIONAL_SECURITY = 'BUSINESS_ADDITIONAL_SECURITY';
export const BUSINESS_OUTSTANDING_INVOICE_AMOUNT = 'BUSINESS_OUTSTANDING_INVOICE_AMOUNT';
export const BUSINESS_TRADING_MONTHS = 'BUSINESS_TRADING_MONTHS';
export const BUSINESS_LOAN_TERM = 'BUSINESS_LOAN_TERM';
export const BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT_EXPLANATION =
    'BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT_EXPLANATION';
export const INVOICE_FINANCE_PRODUCTS = 'INVOICE_FINANCE_PRODUCTS';

class MetaKeyEnum extends BaseEnum {
    getTypes = () => {
        return {
            [PROPERTY_LOAN_TYPE]: 'Property loan type',
            [PROPERTY_TYPE]: 'Type of property',
            [PROPERTY_PURCHASE_PRICE]: 'Property value',
            [DEVELOP_PURCHASE_PRICE]: 'Development purchase price or valuation',
            [POSTCODE]: 'Postcode',
            [ADDRESS]: 'Address',
            [LOAN_AMOUNT]: 'Loan amount',
            [LOAN_TERM]: 'Term length',
            [LOAN_NEEDED_BY]: 'Loan needed by',
            [LOAN_LTV]: 'Loan to value',
            [LOAN_TYPE]: 'Loan type',
            [PROPERTY_INTEND_TO_OCCUPY]: 'Intend to live in property?',
            [PROPERTY_WAS_OCCUPIED]: 'Ever been lived in?',
            [PROPERTY_EXPECTED_RENTAL_INCOME]: 'Expected monthly rental income',
            [PROPERTY_HOW_OWNED]: 'How will the property be owned?',
            [BORROWER_EMPLOYMENT_STATUS]: 'Employment status',
            [BORROWER_GROWTH_AND_INCOME]: 'Gross income',
            [BORROWER_OCCUPATION]: 'Occupation',
            [BORROWER_OWN_OTHER_PROPERTIES]: 'Owns other properties?',
            [BORROWER_HAS_ADVERSE_CREDIT]: 'Has had credit difficulties',
            [BORROWER_HAS_CCJS]: 'Had CCJs in the past 5 years',
            [BORROWER_CCJ_WHEN]: 'When was the CCJ',
            [BORROWER_CCJ_WHY]: 'Reason for the CCJ',
            [BORROWER_CCJ_HOW_MUCH]: 'CCJ amount',
            [BORROWER_CCJ_SATISFIED]: 'CCJ satisfied?',
            [BORROWER_HAS_DEFAULTED]: 'Has ever defaulted?',
            [BORROWER_DEFAULTED_WHEN]: 'When was the default?',
            [BORROWER_DEFAULTED_WHY]: 'Reason for the default',
            [BORROWER_DEFAULTED_HOW_MUCH]: 'Defaulted amount',
            [BORROWER_DEFAULTED_UP_TO_DATE]: 'Default satisfied?',
            [BORROWER_IS_UK_CITIZEN]: 'UK citizen?',
            [BORROWER_NATIONALITY]: 'Nationality',
            [BORROWER_COUNTRY_OF_RESIDENCE]: 'Country of residence',
            [BORROWER_RIGHT_TO_RESIDE]: 'Has right to reside in the UK?',
            [LAND_HAS_PLANNING_PERMISSION]: 'Has planning permission?',
            [LAND_PURCHASE_PRICE]: 'Purchase price of the land',
            [LAND_HOW_OWNED]: 'How will the land be owned?',
            [PROPERTY_IS_ALREADY_OWNED]: 'Is the property already owned?',
            [INITIAL_LOAN_AMOUNT_REQUIRED]: 'Day 1 loan amount required',
            [PLANNING_IN_PLACE]: 'Planning in place?',
            [PLANNING_FOR]: 'What is the planning for?',
            [PLANNING_DESCRIPTION]: 'Planning description',
            [BUILD_COSTS]: 'Build costs',
            [LOAN_NEEDED_TO_COVER_BUILD_COSTS]: 'Loan needed to cover build costs?',
            [LOAN_AMOUNT_TO_COVER_BUILD_COSTS]: 'Build loan amount',
            [REFURBISHMENT_NOT_REQUIRE_PLANNING]: 'Refurbishment not requiring planning?',
            [REFURBISHMENT_COSTS]: 'Refurbishment costs',
            [LOAN_NEEDED_TO_COVER_REFURBISHMENT_COSTS]: 'Loan required to cover refurbishment costs?',
            [LOAN_AMOUNT_TO_COVER_REFURBISHMENT_COSTS]: 'Refurbishment loan amount',
            [EXPECTED_END_VALUE]: 'Expected end value of the project',
            [HAS_EXPERIENCE_DEVELOPING]: 'Has experience developing other properties?',
            [REGISTER_USER_AGE_POINTINTIME]: 'Borrower age at application',
            [TYPE_OF_COMMERCIAL]: 'What type of commercial?',
            [PROPERTY_TO_BE_RENTED_OUT]: 'Is the property to be rented out?',
            [IS_SPECIFIC_TENANT]: 'Is there a specific tenant?',
            [TYPE_OF_TENANT]: 'Type of tenant',
            [TENANCY_TERM]: 'Tenancy term',
            [ANNUAL_RENT]: 'Annual rent',
            [COMMERCIAL_PROPERTY_TO_BE_DEVELOPED]: 'Commercial property to be developed?',
            [BUSINESS_INTEND_TO_OCCUPY]: 'Business intend to occupy?',
            [LOAN_ASSET]: 'Is this a Refinance or a Purchase?',
            [BUSINESS_OUTSTANDING_INVOICE]: 'Does the business have any outstanding invoices?',
            [BUSINESS_OUTSTANDING_INVOICE_AMOUNT]:
                'What is the total value of the business’ current outstanding invoices?',
            [BUSINESS_TRADING_MONTHS]: 'How long has the business been trading for? (in months)',
            [BUSINESS_LOAN_TERM]: 'How long do you want to borrow the money for?',
            [BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT]:
                'Have any directors or shareholders had any adverse credit registered against them?',
            [BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT_EXPLANATION]: 'If yes, please explain?',
            [BUSINESS_PROFITABLE]: 'Is the business currently making a profit?',
            [BUSINESS_ADDITIONAL_SECURITY]: 'Is there a property available for additional security?',

            [LOAN_AMOUNT]: 'Loan amount',
            [LOAN_PURPOSE]: 'Loan purpose',
            [LOAN_PURPOSE]: 'Loan reason',
            [BUSINESS_NAME]: 'Business name',
            [BUSINESS_SECTOR]: 'Business sector',
            [BUSINESS_HAD_ADVERSE_CREDIT]: 'Business had adverse credit',
            [BUSINESS_ADVERSE_CREDIT_DETAILS]: 'Adverse credit details',
            [BUSINESS_TOTAL_DEBTS]: 'Total business debts',
            [PREVIOUS_YEAR_TURNOVER]: 'Previous year turnover',
            [PDQ_TURNOVER_PER_MONTH]: 'PDQ turnover per month',
            [SHAREHOLDER_IS_HOMEOWNER]: 'Any >20% shareholders homeowners?',
            [ADDITIONAL_INFO_NOTES]: 'Additional information',
            [INVOICE_FINANCE_PRODUCTS]: 'Which invoice finance products do you offer?',
        };
    };
}

export default MetaKeyEnum;
