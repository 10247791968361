import { spawn } from 'redux-saga/effects';

import submitSaga from './submit';
import registerInterestSaga from './step';

function* main() {
    yield spawn(submitSaga);
    yield spawn(registerInterestSaga);
}

export default main;
