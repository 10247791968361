import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { push, goBack } from 'react-router-redux';
import {
    RollupCommandContainer,
    SimpleCommandContainer,
    FiltersContainer,
    SortsContainer,
} from 'pitch4_tabular/components';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import { GenericTableContainer } from 'pitch4_tabular/containers';
import { TableRow, TableCell, TableRowControls } from 'pitch4_layout/components/tables';
import {
    selectorGetCurrentCursor,
    selectorGetFilters,
    selectorGetPages,
    selectorGetPerPage,
    selectorGetSorts,
} from 'pitch4_tabular/redux/index_ns';
import { sagaActionSetFilter } from 'pitch4_tabular/sagas';
import { onSortChange, onSortClear, onFilterChange } from 'pitch4_tabular/utils';
import { ILIKE } from 'pitch4_tabular/enum/OperationEnum';
import { SORT_ASC } from 'pitch4_tabular/enum/SortDirectionEnum';
import { Sort, Filter } from 'pitch4_tabular/components';
import { EnumLabel } from 'pitch4_layout/components/data';
import { DateFormatter } from 'pitch4_layout/components/data';
import { NAMESPACE } from '../redux';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { API_URL } from 'bootstrap/constants/constants';
import DocumentTypeEnum from 'pitch4_enum/enum/DocumentTypeEnum';
import DeleteDocumentButton from '../components/DeleteDocumentButton';
import EditForm from '../components/EditDocumentForm';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import DownloadDocumentButton from '../components/DownloadDocumentButton';
import { graphql } from 'react-apollo/index';
import filesize from 'filesize';
import { Loading } from 'pitchblack_react_utils/form/renderIfSubmitting';
import { EQ } from 'pitch4_tabular/enum/OperationEnum';
import { sagaActionSetSort } from 'pitch4_tabular/sagas';
import AddToBorrowerStoreForm from '../../administer_documents_widget/components/AddToBorrowerStoreForm';
import ModalLayout from 'pitch4_modal/src/component/ModalLayout';

class TableStyleContainer extends React.Component {
    componentDidMount() {
        let { match, dispatch } = { ...this.props };

        dispatch(
            sagaActionSetFilter(
                NAMESPACE,
                match.location
            )({
                column: 'contextEntity',
                operation: EQ,
                operand: match.params.id,
                not: false,
            })
        );

        dispatch(
            sagaActionSetSort(
                NAMESPACE,
                match.location
            )({
                column: 'type',
                direction: SORT_ASC,
            })
        );
    }

    render() {
        //put code to map filters to props here. If you need multiple props - escalate at stand up.
        let { match, sorts, pages, filters = [], first, cursor, dispatch } = this.props;

        let fileNameFilter = filters.filter(({ column }) => {
            return column === 'fileName';
        });
        fileNameFilter =
            fileNameFilter.length > 0
                ? fileNameFilter[0]
                : {
                      column: 'fileName',
                      operation: ILIKE,
                      operand: '',
                      not: false,
                  };

        let typeSort = sorts.filter(({ column }) => {
            return column === 'type';
        });
        typeSort =
            typeSort.length > 0
                ? typeSort[0]
                : {
                      column: 'type',
                      direction: SORT_ASC,
                  };

        //render
        return (
            <div>
                <GenericTableContainer
                    title={`Borrower Documents`}
                    namespace={NAMESPACE}
                    description={`Borrower document store. Each document can be shared to given offer(s).`}
                    commands={(outerRefetch) => (
                        <SimpleCommandContainer>
                            <PrimaryButton onClick={() => this.openAddDocumentModal(outerRefetch)}>Add</PrimaryButton>
                        </SimpleCommandContainer>
                    )}
                    query={gql`
                        query getDocuments(
                            $cursor: String
                            $first: Int!
                            $sorts: [SortInput]
                            $filters: [FilterInput]
                        ) {
                            documents(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
                                edges {
                                    node {
                                        id
                                        fileName
                                        fileSize
                                        mimeType
                                        downloadUrl
                                        status
                                        type
                                        createdAt
                                        updatedAt
                                    }
                                    cursor
                                }
                                pageInfo {
                                    hasNextPage
                                    endCursor
                                    startCursor
                                    hasPreviousPage
                                }
                                totalCount
                            }
                        }
                    `}
                    context={{
                        uri: API_URL,
                    }}
                    extractResultDataFunction={({ documents = {} }) => {
                        if (!documents || !documents.edges) {
                            return [];
                        }
                        return [...documents.edges];
                    }}
                    extractPaginationDataFunction={({ documents = {} }) => {
                        if (!documents || !documents.edges) {
                            return {};
                        }

                        return {
                            pageInfo: { ...documents.pageInfo },
                            totalCount: documents.totalCount,
                        };
                    }}
                    renderHeaderFunction={this.renderHeader}
                    renderRowFunction={this.renderRow}
                    filters={
                        <FiltersContainer>
                            <Filter
                                id={`documents-fileName-filter`}
                                label={`Search filename`}
                                filterValues={fileNameFilter}
                                onFilterChange={onFilterChange(NAMESPACE, dispatch, filters, match)}
                            />
                        </FiltersContainer>
                    }
                    sorts={
                        <SortsContainer>
                            <Sort
                                column={typeSort.column}
                                direction={SORT_ASC}
                                onChange={onSortChange(NAMESPACE, dispatch, match)}
                                onClear={onSortClear(NAMESPACE, dispatch, match)}
                                options={[
                                    { value: 'type', label: 'Type' },
                                    { value: 'fileName', label: 'Filename' },
                                    { value: 'createdAt', label: 'Created' },
                                    { value: 'updatedAt', label: 'Updated' },
                                ]}
                            />
                        </SortsContainer>
                    }
                    variables={{
                        sorts,
                        filters,
                        first,
                        pages,
                        cursor,
                    }}
                />
            </div>
        );
    }

    renderRow = ({ node, cursor }, refetch) => {
        let { setModalClose, setModalComponent, errorNotification, successNotification } = this.props;

        return (
            <TableRow key={node['id']}>
                <TableCell>
                    <EnumLabel enumIn={new DocumentTypeEnum()} value={node['type']} />
                </TableCell>
                <TableCell>{node['fileName']}</TableCell>
                <TableCell>{filesize(node['fileSize'])}</TableCell>
                <TableCell>{node['mimeType']}</TableCell>
                <TableCell>
                    <DateFormatter format={'DD/MM/YYYY HH:mm:ss'} value={node['createdAt']} />
                </TableCell>
                <TableCell>
                    <DateFormatter format={'DD/MM/YYYY HH:mm:ss'} value={node['updatedAt']} />
                </TableCell>
                <TableRowControls xs={4}>
                    <RollupCommandContainer>
                        <DownloadDocumentButton
                            document={node}
                            onPending={() =>
                                setModalComponent(<Loading heading="Please wait. Downloading file." />, false)
                            }
                            onSuccess={() => setModalClose()}
                            onFailure={() => setModalClose()}
                        >
                            Download
                        </DownloadDocumentButton>
                        <PrimaryButton onClick={() => this.openEditDocumentModal(refetch, node)}>Edit</PrimaryButton>
                        <DeleteDocumentButton
                            outerRefetch={refetch}
                            id={node['id']}
                            setModalComponent={setModalComponent}
                            setModalClose={setModalClose}
                            successNotification={successNotification}
                            errorNotification={errorNotification}
                            document={node}
                            onSuccess={(data) => {
                                successNotification('Document deleted successfully');
                                setModalClose();
                                refetch();
                            }}
                            onError={() => {
                                errorNotification('Error has occurred. Please contact support.');
                                setModalClose();
                            }}
                            onPending={() =>
                                setModalComponent(<Loading heading="Please wait. Removing file." />, false)
                            }
                        >
                            Delete
                        </DeleteDocumentButton>
                    </RollupCommandContainer>
                </TableRowControls>
            </TableRow>
        );
    };

    renderHeader = () => {
        return (
            <TableRow>
                <TableCell>Type</TableCell>
                <TableCell>Filename</TableCell>
                <TableCell>File size</TableCell>
                <TableCell>File type</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                <TableCell />
            </TableRow>
        );
    };

    openAddDocumentModal = (outerRefetch) => {
        let {
            setModalClose,
            setModalComponent,
            setModalInteractive,
            createMetaMutate,
            successNotification,
            errorNotification,
            match,
        } = this.props;

        setModalComponent(
            <ModalLayout title="Add Document">
                <AddToBorrowerStoreForm
                    id={match.params.id}
                    onPending={() => setModalInteractive(false)}
                    onSuccess={(message) => {
                        successNotification(message);
                        outerRefetch();
                        setModalClose();
                    }}
                    onError={(error) => {
                        errorNotification(error);
                        setModalInteractive(true);
                    }}
                    createMetaMutate={createMetaMutate}
                />
            </ModalLayout>
        );
    };

    openEditDocumentModal = (outerRefetch, document) => {
        let {
            setModalClose,
            setModalComponent,
            setModalInteractive,
            successNotification,
            errorNotification,
        } = this.props;

        setModalComponent(
            <ModalLayout title="Edit Document">
                <EditForm
                    document={document}
                    onPending={() => setModalInteractive(false)}
                    onError={(error) => {
                        errorNotification('Unable to modify document. Please contact support.');
                        setModalInteractive(true);
                    }}
                    onSuccess={(data) => {
                        successNotification('Document successfully modified');
                        outerRefetch();
                        setModalClose();
                    }}
                />
            </ModalLayout>
        );
    };
}

TableStyleContainer.propTypes = {
    sorts: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    pages: PropTypes.array.isRequired,
    cursor: PropTypes.any,
    first: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    return {
        sorts: selectorGetSorts(NAMESPACE)(state),
        pages: selectorGetPages(NAMESPACE)(state),
        cursor: selectorGetCurrentCursor(NAMESPACE)(state),
        filters: selectorGetFilters(NAMESPACE)(state),
        first: selectorGetPerPage(NAMESPACE)(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        successNotification: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        errorNotification: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    graphql(
        gql`
            mutation CreateDocument($input: pitch4admin_createDocumentInput!) {
                createUserStoreDocument(input: $input) {
                    document {
                        id
                        uploadUrl
                    }
                }
            }
        `,
        {
            name: 'createMetaMutate',
        }
    ),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(TableStyleContainer);
