import { put, select, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';
import {
    actionSetCurrentForm,
    NEXT_PAGE,
    RESET_FIELDS,
    selectorGetConfig,
    selectorGetCurrentForm,
    selectorGetFieldValueSelector,
    selectorGetInitialSection,
} from '../redux';
import { SECTION_SUBMIT } from '../constants';
import { change, untouch } from 'redux-form';
import { isEmpty } from 'pitch4_validation';

export function* nextPage(action) {
    const currentForm = yield select(selectorGetCurrentForm(action.namespace));
    const config = yield select(selectorGetConfig(action.namespace));
    const startSection = yield select(selectorGetInitialSection(action.namespace));
    const startSectionName = startSection['name'];

    const fieldValueSelector = yield select(selectorGetFieldValueSelector(action.namespace));
    const values = action.values;

    if (isEmpty(values)) {
        yield put(actionSetCurrentForm(action.namespace)(startSectionName));
        return;
    }

    // Get the next section based on the conditions specified in the config.
    const possibleNextSections = config['sections'][currentForm]['next_section'] || {};

    if (_.isEmpty(possibleNextSections)) {
        yield put(actionSetCurrentForm(action.namespace)(SECTION_SUBMIT));
    } else {
        const nextSectionKeys = Object.keys(possibleNextSections);
        let matchedSection = null;

        for (let i = 0; i < nextSectionKeys.length; i++) {
            const sectionName = nextSectionKeys[i];

            const conditions = possibleNextSections[sectionName]['conditions'];

            // Empty conditions mean always match so we default to true
            let match = true;

            // All conditions have to pass to be considered a match
            for (let j = 0; j < conditions.length; j++) {
                let fieldValue = fieldValueSelector(_.get(values, [currentForm, conditions[j]['field']]));

                if (!isEmpty(fieldValue) && conditions[j]['value'].indexOf(fieldValue) !== -1) {
                    match = true;
                } else {
                    match = false;
                    break;
                }
            }

            if (match === true) {
                matchedSection = sectionName;
                break;
            }
        }

        if (matchedSection) {
            yield put(actionSetCurrentForm(action.namespace)(matchedSection));
        }
    }
}

export function* resetFields(action) {
    const form = action.form;
    const section = action.section;
    const fieldNames = !isEmpty(action.fieldNames) ? action.fieldNames : [];

    for (let i = 0; i < fieldNames.length; i++) {
        yield put(untouch(form, [section + '.' + fieldNames[i]]));
        yield put(change(form, section + '.' + fieldNames[i], ''));
    }
}

export function* baseYields(namespace) {
    yield takeLatest(`${namespace}.${NEXT_PAGE}`, nextPage);
    yield takeLatest(`${namespace}.${RESET_FIELDS}`, resetFields);
}
