import React from 'react';
import { Container, Item } from '../../pitch4_layout/components/div';
import ErrorIcon from '@material-ui/icons/Error';

export default function CompleteAlert(props) {
    const { setModalComponent, modalComponent, header, text } = props;
    return (
        <Container>
            <div className="banner-notice banner-notice-error">
                <Container spacing={1} alignItems={`center`}>
                    <Item xs={12} sm={12} className={`notice-message`}>
                        <ErrorIcon />
                        <strong>
                            {header}
                            <span
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={() => setModalComponent(modalComponent, false)}
                            >
                                {text}
                            </span>
                        </strong>
                    </Item>
                </Container>
            </div>
        </Container>
    );
}
