import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import ModalLayout from '../../../../../pitch4_modal/src/component/ModalLayout';
import { Body1 } from '../../../../../pitch4_layout/components/text';
import { Container, RowItem } from '../../../../../pitch4_layout/components/div';
import { CenteringContainer, Item } from '../../../../../pitchblack_react_utils/layout/index';
import { PrimaryButton } from '../../../../../pitch4_layout/components/buttons';
import { connect } from 'react-redux';
import { selectorGetSubmitted } from '../redux/main';

const WithdrawEnquiryConfirm = ({ handleSubmit, submitted }) => {
    return (
        <ModalLayout>
            <CenteringContainer>
                <Item xs={12}>
                    <Container>
                        <RowItem>
                            <CenteringContainer className={'text-align-center'}>
                                <Body1>
                                    <h2
                                        style={{
                                            color: '#2ec4b6',
                                            fontWeight: 700,
                                            fontSize: '15px',
                                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                        }}
                                    >
                                        Withdraw This Enquiry?
                                    </h2>
                                </Body1>
                            </CenteringContainer>
                        </RowItem>
                        <RowItem>
                            <CenteringContainer className={'margin-top-20 text-align-center'}>
                                <Body1>
                                    <p>
                                        Please confirm you wish to withdraw and close this enquiry. Doing so will cease
                                        all lender communication and is irreversible.
                                    </p>
                                </Body1>
                            </CenteringContainer>
                        </RowItem>
                        <RowItem>
                            <CenteringContainer className={'button-panel'}>
                                <PrimaryButton type="submit" disabled={submitted} onClick={handleSubmit}>
                                    Confirm
                                </PrimaryButton>
                            </CenteringContainer>
                        </RowItem>
                    </Container>
                </Item>
            </CenteringContainer>
        </ModalLayout>
    );
};

WithdrawEnquiryConfirm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitted: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        submitted: selectorGetSubmitted(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(WithdrawEnquiryConfirm);
