import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
    INITIATE,
    RELOAD_LOAN_ENQUIRY,
    actionSetMatchingCriteriaConfig,
    actionSetApiGetLoanEnquiryResponse,
    selectorGetLoanEnquiry,
    actionSetStatus,
    SEND_MESSAGE_TO_LENDERS,
    API_SET_STATUS,
    REMOVE_LOAN_ENQUIRY_ALERT,
} from '../redux/main';
import { getConfig, getEnquiry } from '../api';
import { sendMessageToLenders } from '../api/send_message_to_lenders';
import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../../../notifications/sagas/container';
import { sagaActionModalHide } from '../../../../modal/sagas/container';
import { setLoanEnquiryStatus } from '../api';
import { removeLoanEnquiryAlert } from '../api/remove_loan_enquiry_alert';

function* getMatchingCriteriaConfig() {
    const response = yield call(getConfig);

    switch (response.getClassName()) {
        case 'GetConfigSuccess':
            const config = response.getData();

            // Set the form initial values
            yield put(actionSetMatchingCriteriaConfig(config));
            break;
        case 'GetConfigError':
        default:
            break;
    }
}

function* getLoanEnquiry(action) {
    const { loanEnquiryId } = action;
    const response = yield call(getEnquiry, loanEnquiryId);
    yield put(actionSetApiGetLoanEnquiryResponse(response));
}

function* refreshLoanEnquiry(action) {
    const loanEnquiry = yield select(selectorGetLoanEnquiry);
    const response = yield call(getEnquiry, loanEnquiry.id);
    yield put(actionSetApiGetLoanEnquiryResponse(response));
}

function* apiSendMessageToLenders(action) {
    const { lenders, message, toggleMessageDrawer, setMessageSent } = action;
    setMessageSent(true);

    const response = yield call(sendMessageToLenders, lenders, message);
    const { errors } = response;

    if (errors) {
        yield put({ type: SAGA_ACTION_ERROR, message: 'Could not send message' });
    } else {
        yield put({ type: SAGA_ACTION_SUCCESS, message: 'Message sent' });
    }

    toggleMessageDrawer();
}

function* apiSetLoanEnquiryAlertStatus(action) {
    yield call(removeLoanEnquiryAlert, action.loanEnquiryId);
}

function* apiSetStatus(action) {
    const response = yield call(setLoanEnquiryStatus, action.loanEnquiryId, action.status, action.reason);
    const { errors } = response;
    if (errors) {
        yield put({ type: SAGA_ACTION_ERROR, message: 'Could not update enquiry status' });
    } else {
        yield put(actionSetStatus(action.status));
        yield put(sagaActionModalHide());
        yield put({ type: SAGA_ACTION_SUCCESS, message: 'Enquiry status saved!' });
    }
}

//Main Saga
function* main() {
    yield takeLatest(REMOVE_LOAN_ENQUIRY_ALERT, apiSetLoanEnquiryAlertStatus);
    yield takeLatest(INITIATE, getMatchingCriteriaConfig);
    yield takeLatest(INITIATE, getLoanEnquiry);
    yield takeLatest(RELOAD_LOAN_ENQUIRY, refreshLoanEnquiry);
    yield takeLatest(SEND_MESSAGE_TO_LENDERS, apiSendMessageToLenders);
    yield takeLatest(API_SET_STATUS, apiSetStatus);
}

export default main;
