import BaseEnum from 'pitchblack_react_utils/enums/BaseEnum';

export const EVENT_CATEGORY_USER = 'EVENT_CATEGORY_USER';
export const EVENT_CATEGORY_SYSTEM = 'EVENT_CATEGORY_SYSTEM';

class CategoryEnum extends BaseEnum {
    getTypes = () => {
        return {
            [EVENT_CATEGORY_USER]: 'User',
            [EVENT_CATEGORY_SYSTEM]: 'System',
        };
    };
}

export default CategoryEnum;
