import React from 'react';
import { branch, renderComponent } from 'recompose';
import Spinner from 'react-spinkit';
import PropTypes from 'prop-types';

import { CenteringContainer } from 'pitchblack_react_utils/layout/index';
import { Body1 } from 'pitch4_layout/components/text';

export class Loading extends React.Component {
    render() {
        let { heading = null } = this.props;

        return (
            <CenteringContainer className={'margin-top-15 margin-bottom-15'}>
                {heading && <Body1>{heading}</Body1>}
                <CenteringContainer className={'margin-top-25'}>
                    <Spinner name="cube-grid" key={'loading-spinner'} />
                </CenteringContainer>
            </CenteringContainer>
        );
    }
}

Loading.propTypes = {
    heading: PropTypes.string,
};

//function
export default (component) => {
    return branch((props) => props.submitting === true, renderComponent(component));
};
