import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import BusinessTypes from '../../../enums/Shared/BusinessTypes';
import BorrowerQuestions, {
    QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES,
    QUESTION_BUSINESS_FACILITY_NEEDED_BY,
    QUESTION_BUSINESS_NAME,
    QUESTION_BUSINESS_OUTSTANDING_INVOICES,
    QUESTION_BUSINESS_PDQ_INCOME,
    QUESTION_BUSINESS_SEASONAL,
    QUESTION_BUSINESS_SECTOR,
    QUESTION_BUSINESS_TRADING_LENGTH,
    QUESTION_BUSINESS_TYPE,
    QUESTION_BUSINESS_WEBSITE,
    QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER,
    QUESTION_PROPERTY_LOCATION_BUSINESS,
    QUESTION_BUSINESS_INVOICE_FINANCE_TYPE,
    QUESTION_BUSINESS_IS_UK_REGISTERED,
    QUESTION_IS_CASH_ADVANCE,
    QUESTION_IS_INVOICE_FINANCE,
    QUESTION_BUSINESS_IS_SECURE_FINANCE,
    QUESTION_LOAN_PURPOSE,
    QUESTION_BUSINESS_REFINANCE,
    QUESTION_LOAN_PURPOSE_DESCRIPTION,
} from '../../../enums/Borrower/BorrowerQuestions';
import YesNo, { NO, YES } from '../../../enums/Shared/YesNo';
import Question from '../../Question/containers/Question';
import FacilityNeededBy from '../../../enums/Shared/FacilityNeededBy';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';
import PropertyLocation, { OTHER } from '../../../enums/Shared/PropertyLocation';
import CommercialStructure from '../../../enums/Shared/CommercialStructure';
import InvoiceFinancingTypes from '../../../enums/Shared/InvoiceFinancingTypes';
import SecuredUnsecuredFinance from '../../../enums/Shared/SecuredUnsecuredFinance';
import BusinessLoanPurposes, { ASSET_PURCHASE, ASSET_REFINANCE } from '../../../enums/Shared/BusinessLoanPurposes';
import AssetRefinance from '../../../enums/Shared/AssetRefinance';

class BusinessDetailsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const yesNoOptions = new YesNo().getTypes();
        const facilityNeededByOptions = new FacilityNeededBy().getTypes();
        const propertyLocations = new PropertyLocation().getTypes();
        const invoiceFinanceOptions = new InvoiceFinancingTypes().getTypes();
        const businessTypes = new BusinessTypes().getTypes();
        const commercialStructure = new CommercialStructure().getTypes();
        const securedUnsecuredOptions = new SecuredUnsecuredFinance().getTypes();
        const businessFinanceLoanPurpose = new BusinessLoanPurposes().getTypes();
        const assetRefinanceOptions = new AssetRefinance().getBusinessOptions();

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER
                            ? 'Can you provide some details on your business?'
                            : 'Can you provide some details on your clients business?'}
                    </Typography>
                </Grid>
                <Question
                    type={questions[QUESTION_LOAN_PURPOSE].type}
                    label={questions[QUESTION_LOAN_PURPOSE].label}
                    questionEnum={'QUESTION_LOAN_PURPOSE'}
                    fieldName={questions[QUESTION_LOAN_PURPOSE].fieldName}
                    placeholder={questions[QUESTION_LOAN_PURPOSE].placeholder}
                    validation={questions[QUESTION_LOAN_PURPOSE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={businessFinanceLoanPurpose}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_LOAN_PURPOSE_DESCRIPTION].type}
                    label={questions[QUESTION_LOAN_PURPOSE_DESCRIPTION].label}
                    questionEnum={'QUESTION_LOAN_PURPOSE_DESCRIPTION'}
                    fieldName={questions[QUESTION_LOAN_PURPOSE_DESCRIPTION].fieldName}
                    placeholder={questions[QUESTION_LOAN_PURPOSE_DESCRIPTION].placeholder}
                    validation={questions[QUESTION_LOAN_PURPOSE_DESCRIPTION].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={questions[QUESTION_LOAN_PURPOSE_DESCRIPTION].rows}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_SEASONAL].type}
                    label={questions[QUESTION_BUSINESS_SEASONAL].label}
                    questionEnum={'QUESTION_BUSINESS_SEASONAL'}
                    fieldName={questions[QUESTION_BUSINESS_SEASONAL].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_SEASONAL].placeholder}
                    validation={questions[QUESTION_BUSINESS_SEASONAL].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_TYPE].type}
                    label={questions[QUESTION_BUSINESS_TYPE].label}
                    questionEnum={'QUESTION_BUSINESS_TYPE'}
                    fieldName={questions[QUESTION_BUSINESS_TYPE].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_TYPE].placeholder}
                    validation={questions[QUESTION_BUSINESS_TYPE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={commercialStructure}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_NAME].type}
                    label={questions[QUESTION_BUSINESS_NAME].label}
                    questionEnum={'QUESTION_BUSINESS_NAME'}
                    fieldName={questions[QUESTION_BUSINESS_NAME].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_NAME].placeholder}
                    validation={questions[QUESTION_BUSINESS_NAME].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_WEBSITE].type}
                    label={questions[QUESTION_BUSINESS_WEBSITE].label}
                    questionEnum={'QUESTION_BUSINESS_WEBSITE'}
                    fieldName={questions[QUESTION_BUSINESS_WEBSITE].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_WEBSITE].placeholder}
                    validation={questions[QUESTION_BUSINESS_WEBSITE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_TRADING_LENGTH].type}
                    label={questions[QUESTION_BUSINESS_TRADING_LENGTH].label}
                    questionEnum={'QUESTION_BUSINESS_TRADING_LENGTH'}
                    fieldName={questions[QUESTION_BUSINESS_TRADING_LENGTH].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_TRADING_LENGTH].placeholder}
                    validation={questions[QUESTION_BUSINESS_TRADING_LENGTH].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_SECTOR].type}
                    label={questions[QUESTION_BUSINESS_SECTOR].label}
                    questionEnum={'QUESTION_BUSINESS_SECTOR'}
                    fieldName={questions[QUESTION_BUSINESS_SECTOR].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_SECTOR].placeholder}
                    validation={questions[QUESTION_BUSINESS_SECTOR].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={businessTypes}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_IS_UK_REGISTERED].type}
                    label={questions[QUESTION_BUSINESS_IS_UK_REGISTERED].label}
                    questionEnum={'QUESTION_BUSINESS_IS_UK_REGISTERED'}
                    fieldName={questions[QUESTION_BUSINESS_IS_UK_REGISTERED].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_IS_UK_REGISTERED].placeholder}
                    validation={questions[QUESTION_BUSINESS_IS_UK_REGISTERED].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_PROPERTY_LOCATION_BUSINESS].type}
                    label={questions[QUESTION_PROPERTY_LOCATION_BUSINESS].label}
                    questionEnum={'QUESTION_PROPERTY_LOCATION_BUSINESS'}
                    fieldName={questions[QUESTION_PROPERTY_LOCATION_BUSINESS].fieldName}
                    placeholder={questions[QUESTION_PROPERTY_LOCATION_BUSINESS].placeholder}
                    validation={questions[QUESTION_PROPERTY_LOCATION_BUSINESS].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={propertyLocations}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_BUSINESS_FACILITY_NEEDED_BY].type}
                    label={questions[QUESTION_BUSINESS_FACILITY_NEEDED_BY].label}
                    questionEnum={'QUESTION_BUSINESS_FACILITY_NEEDED_BY'}
                    fieldName={questions[QUESTION_BUSINESS_FACILITY_NEEDED_BY].fieldName}
                    placeholder={questions[QUESTION_BUSINESS_FACILITY_NEEDED_BY].placeholder}
                    validation={questions[QUESTION_BUSINESS_FACILITY_NEEDED_BY].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={facilityNeededByOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                {this.state.formData[questions[QUESTION_PROPERTY_LOCATION_BUSINESS].fieldName] === OTHER && (
                    <Question
                        type={questions[QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER].type}
                        label={questions[QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER].label}
                        questionEnum={'QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER'}
                        fieldName={questions[QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER].fieldName}
                        placeholder={questions[QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER].placeholder}
                        validation={questions[QUESTION_PROPERTY_LOCATION_BUSINESS_OTHER].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={[]}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
                <Question
                    type={questions[QUESTION_IS_CASH_ADVANCE].type}
                    label={questions[QUESTION_IS_CASH_ADVANCE].label}
                    questionEnum={'QUESTION_IS_CASH_ADVANCE'}
                    fieldName={questions[QUESTION_IS_CASH_ADVANCE].fieldName}
                    placeholder={questions[QUESTION_IS_CASH_ADVANCE].placeholder}
                    validation={questions[QUESTION_IS_CASH_ADVANCE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                {this.state.formData[questions[QUESTION_IS_CASH_ADVANCE].fieldName] === NO && (
                    <Question
                        type={questions[QUESTION_IS_INVOICE_FINANCE].type}
                        label={questions[QUESTION_IS_INVOICE_FINANCE].label}
                        questionEnum={'QUESTION_IS_INVOICE_FINANCE'}
                        fieldName={questions[QUESTION_IS_INVOICE_FINANCE].fieldName}
                        placeholder={questions[QUESTION_IS_INVOICE_FINANCE].placeholder}
                        validation={questions[QUESTION_IS_INVOICE_FINANCE].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={yesNoOptions}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
                {(this.state.formData[questions[QUESTION_LOAN_PURPOSE].fieldName] === ASSET_PURCHASE ||
                    this.state.formData[questions[QUESTION_LOAN_PURPOSE].fieldName] === ASSET_REFINANCE) && (
                    <Question
                        type={questions[QUESTION_BUSINESS_REFINANCE].type}
                        label={questions[QUESTION_BUSINESS_REFINANCE].label}
                        questionEnum={'QUESTION_BUSINESS_REFINANCE'}
                        fieldName={questions[QUESTION_BUSINESS_REFINANCE].fieldName}
                        placeholder={questions[QUESTION_BUSINESS_REFINANCE].placeholder}
                        validation={questions[QUESTION_BUSINESS_REFINANCE].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={assetRefinanceOptions}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
                {this.state.formData[questions[QUESTION_IS_INVOICE_FINANCE].fieldName] === NO && (
                    <Question
                        type={questions[QUESTION_BUSINESS_IS_SECURE_FINANCE].type}
                        label={questions[QUESTION_BUSINESS_IS_SECURE_FINANCE].label}
                        questionEnum={'QUESTION_BUSINESS_IS_SECURE_FINANCE'}
                        fieldName={questions[QUESTION_BUSINESS_IS_SECURE_FINANCE].fieldName}
                        placeholder={questions[QUESTION_BUSINESS_IS_SECURE_FINANCE].placeholder}
                        validation={questions[QUESTION_BUSINESS_IS_SECURE_FINANCE].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={securedUnsecuredOptions}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
                {this.state.formData[questions[QUESTION_IS_INVOICE_FINANCE].fieldName] === YES && (
                    <>
                        <Question
                            type={questions[QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES].type}
                            label={questions[QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES].label}
                            questionEnum={'QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES'}
                            fieldName={questions[QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES].fieldName}
                            placeholder={questions[QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES].placeholder}
                            validation={questions[QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={questions[QUESTION_BUSINESS_CURRENT_PAYMENT_TIMES].rows}
                        />
                        <Question
                            type={questions[QUESTION_BUSINESS_OUTSTANDING_INVOICES].type}
                            label={questions[QUESTION_BUSINESS_OUTSTANDING_INVOICES].label}
                            questionEnum={'QUESTION_BUSINESS_OUTSTANDING_INVOICES'}
                            fieldName={questions[QUESTION_BUSINESS_OUTSTANDING_INVOICES].fieldName}
                            placeholder={questions[QUESTION_BUSINESS_OUTSTANDING_INVOICES].placeholder}
                            validation={questions[QUESTION_BUSINESS_OUTSTANDING_INVOICES].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_BUSINESS_INVOICE_FINANCE_TYPE].type}
                            label={questions[QUESTION_BUSINESS_INVOICE_FINANCE_TYPE].label}
                            questionEnum={'QUESTION_BUSINESS_INVOICE_FINANCE_TYPE'}
                            fieldName={questions[QUESTION_BUSINESS_INVOICE_FINANCE_TYPE].fieldName}
                            placeholder={questions[QUESTION_BUSINESS_INVOICE_FINANCE_TYPE].placeholder}
                            validation={questions[QUESTION_BUSINESS_INVOICE_FINANCE_TYPE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={invoiceFinanceOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    </>
                )}
                {this.state.formData[questions[QUESTION_IS_CASH_ADVANCE].fieldName] === YES && (
                    <>
                        <Question
                            type={questions[QUESTION_BUSINESS_PDQ_INCOME].type}
                            label={questions[QUESTION_BUSINESS_PDQ_INCOME].label}
                            questionEnum={'QUESTION_BUSINESS_PDQ_INCOME'}
                            fieldName={questions[QUESTION_BUSINESS_PDQ_INCOME].fieldName}
                            placeholder={questions[QUESTION_BUSINESS_PDQ_INCOME].placeholder}
                            validation={questions[QUESTION_BUSINESS_PDQ_INCOME].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    </>
                )}
            </Grid>
        );
    }
}

export default BusinessDetailsContainer;
