import moment from 'moment/moment';

export const isRequired = (value) => (value || typeof value === 'number' ? undefined : 'Required');

export const isValidPercentage = (max) => (value) => {
    const val = Number(value);
    return isNaN(val) === false && val > max ? `Cannot be greater than 100%.` : undefined;
};

export const isRequiredCheckbox = (value) => (!value ? 'Required' : undefined);

export const hasMaxLength = (max) => (value) =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const hasMinLength = (min) => (value) =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const isMinLength = (length, value) => value && value.length >= length;
export const isMaxLength = (length, value) => value && value.length <= length;

export const hasMaxLength3 = hasMaxLength(3);
export const hasMaxLength6 = hasMaxLength(6);
export const hasMaxLength7 = hasMaxLength(7);
export const hasMaxLength10 = hasMaxLength(10);
export const hasMaxLength30 = hasMaxLength(30);
export const hasMaxLength255 = hasMaxLength(255);
export const hasMaxLength1000 = hasMaxLength(1000);
export const hasMaxLength1500 = hasMaxLength(1500);

export const isBoolean = (value) => {
    let bool = Boolean(value);
    return value && bool === value ? `Invalid boolean!` : undefined;
};

export const isDateTime = (value) => {
    const parsed = Date.parse(value);
    return value && isNaN(parsed) ? `Invalid datetime!` : undefined;
};

export const hasMinLength2 = hasMinLength(2);
export const hasMinLength5 = hasMinLength(5);
export const hasMinLength6 = hasMinLength(6);
export const hasMinLength7 = hasMinLength(7);
export const hasMinLength8 = hasMinLength(8);
export const isPercentage = isValidPercentage(100);

export const isNumeric = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

export const hasMinValue = (min) => (value) => {
    // value/100, makes validation possible because we set currency validation to 0.01 in
    // src/frontend/src/apps/admin/slam/administer_loanenquiries_viewlenderloanenquiry/components/FinalLoanAmountForm.js:17
    const val = Number(value) / 100;
    return isNaN(val) === false && val < min ? `Must be at least £${min.toLocaleString()}` : undefined;
};

export const hasMinIntegerValue = (min) => (value) => {
    const val = Number(value);
    return isNaN(val) === false && val < min ? `Must be at least ${min.toLocaleString()}` : undefined;
};

export const hasMinValue18 = hasMinValue(18);
export const hasMinValue1 = hasMinValue(1);
export const hasMinIntegerValue1 = hasMinIntegerValue(1);
export const hasMinValue10000 = hasMinValue(10000);

export const isEmail = (value) =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value) ? 'Invalid email address' : undefined;

export const isEmailValid = (value) => !(value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value));

export const isAlphaNumeric = (value) =>
    value && /[^a-zA-Z0-9 ]/i.test(value) ? 'Only alphanumeric characters' : undefined;

export const isValidPostcode = (value) =>
    value &&
    !/^(GIR 0AA)|((([A-Z][0-9]{1,2})|(([A-Z][A-HJ-Y][0-9]{1,2})|(([A-Z][0-9][A-Z])|([A-Z][A-HJ-Y][0-9]?[A-Z]))))\s*[0-9][A-Z]{2})$/i.test(
        value
    )
        ? 'Must be a valid UK postcode'
        : undefined;

export const isDOBValid = (value, min = -100, max = -18) => {
    return (
        moment(value, 'YYYY-MM-DD', true).isValid() &&
        !moment(value).isBefore(moment().add(min, 'years')) &&
        !moment(value).isAfter(moment().add(max, 'years')) &&
        null !== value &&
        0 !== value.length
    );
};

export const isInDateRange = (
    minModifierValue,
    minModifierType,
    minMessage,
    maxModifierValue,
    maxModifierType,
    maxMessage
) => (value) => {
    let out = undefined;
    if (moment(value).isBefore(moment().add(minModifierValue, minModifierType))) {
        out = minMessage;
    }
    if (moment(value).isAfter(moment().add(maxModifierValue, maxModifierType))) {
        out = maxMessage;
    }
    return out;
};

export const passwordsMustMatch = (passwordPropertyName) => (value, allValues) => {
    return allValues.hasOwnProperty(passwordPropertyName) && value === allValues[passwordPropertyName]
        ? undefined
        : 'Passwords do not match';
};

export const isValidPassword = (value) => {
    const containsLCLetter = (value) => value && /[a-z]/.test(value);

    const containsUCLetter = (value) => value && /[A-Z]/.test(value);

    const containsNumber = (value) => value && /\d/.test(value);

    return value && value.length >= 8 && containsLCLetter(value) && containsUCLetter(value) && containsNumber(value)
        ? undefined
        : 'The password must be at least 8 characters long and contain at least 1 lowercase letter, 1 uppercase letter and 1 number';
};

export const isUrlValid = (str) => {
    if (str === '' || str === undefined || str === null) {
        return undefined;
    }

    str = str.trimStart().trimEnd();
    const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
        'i'
    ); // fragment locator
    return true === !!pattern.test(str) ? undefined : 'The URL is invalid.';
};

export const isMobilePhoneNumber = (number) =>
    number && !/^((\+44|\+440)7\d{3}\s?\d{6})$/g.test(number)
        ? 'Invalid mobile phone number. It must start with +44, e.g. +447415123456'
        : undefined;

export const isMobilePhoneNumberValid = (number) => /^((\+44|\+440)7\d{3}\s?\d{6})$/g.test(number);

export const isPhoneNumber = (value) =>
    value && !/^[+]?[0-9]+$/i.test(value) ? 'Invalid phone number, must be digits and + character only' : undefined;
