import base_reducer from 'pitch4_tabular/redux/index_ns';

export const NAMESPACE = 'borrower.loan_enquiries';

//Reducer
const initialState = {
    totalCount: null,
    pages: [],
    first: 10,
    filters: [],
    sorts: [],
    selected: {},
    ignoreSelected: [],
    disableUrlFunctions: false,
};

const reducer = (state = initialState, action) => {
    return base_reducer(state, action, NAMESPACE);
};

//Default export reducer
export default reducer;
