import { BaseEnum } from '../../pitch4_enum';
import { BRAND_NAME } from '../../bootstrap/constants/constants';

// Borrower types
export const TYPE_PASSPORT_BORROWERS = 'TYPE_PASSPORT_BORROWERS';
export const TYPE_SIGNED_CONTRACT_BORROWERS = 'TYPE_SIGNED_CONTRACT_BORROWERS';
export const TYPE_UK_DRIVING_LICENCE_BORROWERS = 'TYPE_UK_DRIVING_LICENCE_BORROWERS';
export const TYPE_EUEEA_NATIONAL_IDENTITY_CARD_BORROWERS = 'TYPE_EUEEA_NATIONAL_IDENTITY_CARD_BORROWERS';
export const TYPE_VISA_BORROWERS = 'TYPE_VISA_BORROWERS';
export const TYPE_PERMANENT_RESIDENCE_CARD_BORROWERS = 'TYPE_PERMANENT_RESIDENCE_CARD_BORROWERS';
export const TYPE_BANK_STATEMENT_BORROWERS = 'TYPE_BANK_STATEMENT_BORROWERS';
export const TYPE_CREDIT_CARD_STATEMENT_BORROWERS = 'TYPE_CREDIT_CARD_STATEMENT_BORROWERS';
export const TYPE_UTILITY_BILL_BORROWERS = 'TYPE_UTILITY_BILL_BORROWERS';
export const TYPE_COUNCIL_TAX_BILL_BORROWERS = 'TYPE_COUNCIL_TAX_BILL_BORROWERS';
export const TYPE_PROOF_OF_ADDRESS_OTHER_BORROWERS = 'TYPE_PROOF_OF_ADDRESS_OTHER_BORROWERS';
export const TYPE_CREDIT_REPORT_BORROWERS = 'TYPE_CREDIT_REPORT_BORROWERS';
export const TYPE_STATEMENT_OF_ASSETS_AND_LIABILITIES_BORROWERS = 'TYPE_STATEMENT_OF_ASSETS_AND_LIABILITIES_BORROWERS';
export const TYPE_INCOME_AND_EXPENDITURE_BORROWERS = 'TYPE_INCOME_AND_EXPENDITURE_BORROWERS';
export const TYPE_CREDIT_HISTORY_OTHER_BORROWERS = 'TYPE_CREDIT_HISTORY_OTHER_BORROWERS';
export const TYPE_PAYSLIP_BORROWERS = 'TYPE_PAYSLIP_BORROWERS';
export const TYPE_P60_BORROWERS = 'TYPE_P60_BORROWERS';
export const TYPE_P45_BORROWERS = 'TYPE_P45_BORROWERS';
export const TYPE_SA302_BORROWERS = 'TYPE_SA302_BORROWERS';
export const TYPE_ACCOUNTS_BORROWERS = 'TYPE_ACCOUNTS_BORROWERS';
export const TYPE_ACCOUNTANTS_LETTER_BORROWERS = 'TYPE_ACCOUNTANTS_LETTER_BORROWERS';
export const TYPE_EMPLOYMENT_CONTRACT_BORROWERS = 'TYPE_EMPLOYMENT_CONTRACT_BORROWERS';
export const TYPE_PROOF_OF_INCOME_OTHER_BORROWERS = 'TYPE_PROOF_OF_INCOME_OTHER_BORROWERS';
export const TYPE_SALES_PARTICULARS_BORROWERS = 'TYPE_SALES_PARTICULARS_BORROWERS';
export const TYPE_TENANCY_AGREEMENT_BORROWERS = 'TYPE_TENANCY_AGREEMENT_BORROWERS';
export const TYPE_COMMERCIAL_LEASE_BORROWERS = 'TYPE_COMMERCIAL_LEASE_BORROWERS';
export const TYPE_VALUATION_REPORT_BORROWERS = 'TYPE_VALUATION_REPORT_BORROWERS';
export const TYPE_CURRENT_MORTGAGE_AGREEMENT_BORROWERS = 'TYPE_CURRENT_MORTGAGE_AGREEMENT_BORROWERS';
export const TYPE_PLANNING_DOCUMENT_BORROWERS = 'TYPE_PLANNING_DOCUMENT_BORROWERS';
export const TYPE_OTHER_BORROWERS = 'TYPE_OTHER_BORROWERS';
export const TYPE_AGREEMENT_IN_PRINCIPLE_BORROWERS = 'TYPE_AGREEMENT_IN_PRINCIPLE_BORROWERS';
export const TYPE_APPLICATION_BORROWERS = 'TYPE_APPLICATION_BORROWERS';
export const TYPE_AST_BORROWERS = 'TYPE_AST_BORROWERS';
export const TYPE_BUSINESS_PURPOSES_EXEMPTION_DECLARATION_BORROWERS =
    'TYPE_BUSINESS_PURPOSES_EXEMPTION_DECLARATION_BORROWERS';
export const TYPE_CREDIT_SEARCH_AUTHORITY_BORROWERS = 'TYPE_CREDIT_SEARCH_AUTHORITY_BORROWERS';
export const TYPE_CUSTOMER_PRIVACY_NOTICE_BORROWERS = 'TYPE_CUSTOMER_PRIVACY_NOTICE_BORROWERS';
export const TYPE_DEVELOPMENT_APPRAISAL_BORROWERS = 'TYPE_DEVELOPMENT_APPRAISAL_BORROWERS';
export const TYPE_DEVELOPMENT_CASH_FLOW_BORROWERS = 'TYPE_DEVELOPMENT_CASH_FLOW_BORROWERS';
export const TYPE_DIRECT_DEBIT_MANDATE_BORROWERS = 'TYPE_DIRECT_DEBIT_MANDATE_BORROWERS';
export const TYPE_EU_EEA_NATIONAL_IDENTITY_CARD_BORROWERS = 'TYPE_EU_EEA_NATIONAL_IDENTITY_CARD_BORROWERS';
export const TYPE_FACILITY_LETTER_BORROWERS = 'TYPE_FACILITY_LETTER_BORROWERS';
export const TYPE_HEADS_OF_TERMS_BORROWERS = 'TYPE_HEADS_OF_TERMS_BORROWERS';
export const TYPE_HEIGHT_NET_WORTH_EXEMPTION_DECLARATION_BORROWERS =
    'TYPE_HEIGHT_NET_WORTH_EXEMPTION_DECLARATION_BORROWERS';
export const TYPE_HMO_LICENSE_BORROWERS = 'TYPE_HMO_LICENSE_BORROWERS';
export const TYPE_INCOME_EXPENDITURE_STATEMENT_BORROWERS = 'TYPE_INCOME_EXPENDITURE_STATEMENT_BORROWERS';
export const TYPE_LOAN_MORTGAGE_ILLUSTRATION_BORROWERS = 'TYPE_LOAN_MORTGAGE_ILLUSTRATION_BORROWERS';
export const TYPE_MORTGAGE_OFFER_BORROWERS = 'TYPE_MORTGAGE_OFFER_BORROWERS';
export const TYPE_PERSONAL_RESIDENCE_CARD_BORROWERS = 'TYPE_PERSONAL_RESIDENCE_CARD_BORROWERS';
export const TYPE_PLANNING_DOC_BORROWERS = 'TYPE_PLANNING_DOC_BORROWERS';
export const TYPE_PROPERTY_PORTFOLIO_BORROWERS = 'TYPE_PROPERTY_PORTFOLIO_BORROWERS';
export const TYPE_RESUME_BORROWERS = 'TYPE_RESUME_BORROWERS';
export const TYPE_SCHEDULE_OF_WORKS_DEVELOPMENTS_BORROWERS = 'TYPE_SCHEDULE_OF_WORKS_DEVELOPMENTS_BORROWERS';
export const TYPE_SITE_PLANS_BORROWERS = 'TYPE_SITE_PLANS_BORROWERS';
export const TYPE_SPECIALIST_REPORT_BORROWERS = 'TYPE_SPECIALIST_REPORT_BORROWERS';
export const TYPE_TENANCY_SCHEDULE_BORROWERS = 'TYPE_TENANCY_SCHEDULE_BORROWERS';
export const TYPE_TERMS_OF_BUSINESS_BORROWERS = 'TYPE_TERMS_OF_BUSINESS_BORROWERS';
export const TYPE_UK_DRIVING_LICENSE_BORROWERS = 'TYPE_UK_DRIVING_LICENSE_BORROWERS';

// Intermediary types
export const TYPE_SIGNED_CONTRACT_INTERMEDIARY = 'TYPE_SIGNED_CONTRACT_INTERMEDIARY';
export const TYPE_PASSPORT_INTERMEDIARY = 'TYPE_PASSPORT_INTERMEDIARY';
export const TYPE_UK_DRIVING_LICENCE_INTERMEDIARY = 'TYPE_UK_DRIVING_LICENCE_INTERMEDIARY';
export const TYPE_EUEEA_NATIONAL_IDENTITY_CARD_INTERMEDIARY = 'TYPE_EUEEA_NATIONAL_IDENTITY_CARD_INTERMEDIARY';
export const TYPE_VISA_INTERMEDIARY = 'TYPE_VISA_INTERMEDIARY';
export const TYPE_PERMANENT_RESIDENCE_CARD_INTERMEDIARY = 'TYPE_PERMANENT_RESIDENCE_CARD_INTERMEDIARY';
export const TYPE_BANK_STATEMENT_INTERMEDIARY = 'TYPE_BANK_STATEMENT_INTERMEDIARY';
export const TYPE_CREDIT_CARD_STATEMENT_INTERMEDIARY = 'TYPE_CREDIT_CARD_STATEMENT_INTERMEDIARY';
export const TYPE_UTILITY_BILL_INTERMEDIARY = 'TYPE_UTILITY_BILL_INTERMEDIARY';
export const TYPE_COUNCIL_TAX_BILL_INTERMEDIARY = 'TYPE_COUNCIL_TAX_BILL_INTERMEDIARY';
export const TYPE_PROOF_OF_ADDRESS_OTHER_INTERMEDIARY = 'TYPE_PROOF_OF_ADDRESS_OTHER_INTERMEDIARY';
export const TYPE_CREDIT_REPORT_INTERMEDIARY = 'TYPE_CREDIT_REPORT_INTERMEDIARY';
export const TYPE_STATEMENT_OF_ASSETS_AND_LIABILITIES_INTERMEDIARY =
    'TYPE_STATEMENT_OF_ASSETS_AND_LIABILITIES_INTERMEDIARY';
export const TYPE_INCOME_AND_EXPENDITURE_INTERMEDIARY = 'TYPE_INCOME_AND_EXPENDITURE_INTERMEDIARY';
export const TYPE_CREDIT_HISTORY_OTHER_INTERMEDIARY = 'TYPE_CREDIT_HISTORY_OTHER_INTERMEDIARY';
export const TYPE_PAYSLIP_INTERMEDIARY = 'TYPE_PAYSLIP_INTERMEDIARY';
export const TYPE_P60_INTERMEDIARY = 'TYPE_P60_INTERMEDIARY';
export const TYPE_P45_INTERMEDIARY = 'TYPE_P45_INTERMEDIARY';
export const TYPE_SA302_INTERMEDIARY = 'TYPE_SA302_INTERMEDIARY';
export const TYPE_ACCOUNTS_INTERMEDIARY = 'TYPE_ACCOUNTS_INTERMEDIARY';
export const TYPE_ACCOUNTANTS_LETTER_INTERMEDIARY = 'TYPE_ACCOUNTANTS_LETTER_INTERMEDIARY';
export const TYPE_EMPLOYMENT_CONTRACT_INTERMEDIARY = 'TYPE_EMPLOYMENT_CONTRACT_INTERMEDIARY';
export const TYPE_PROOF_OF_INCOME_OTHER_INTERMEDIARY = 'TYPE_PROOF_OF_INCOME_OTHER_INTERMEDIARY';
export const TYPE_SALES_PARTICULARS_INTERMEDIARY = 'TYPE_SALES_PARTICULARS_INTERMEDIARY';
export const TYPE_TENANCY_AGREEMENT_INTERMEDIARY = 'TYPE_TENANCY_AGREEMENT_INTERMEDIARY';
export const TYPE_COMMERCIAL_LEASE_INTERMEDIARY = 'TYPE_COMMERCIAL_LEASE_INTERMEDIARY';
export const TYPE_VALUATION_REPORT_INTERMEDIARY = 'TYPE_VALUATION_REPORT_INTERMEDIARY';
export const TYPE_CURRENT_MORTGAGE_AGREEMENT_INTERMEDIARY = 'TYPE_CURRENT_MORTGAGE_AGREEMENT_INTERMEDIARY';
export const TYPE_PLANNING_DOCUMENT_INTERMEDIARY = 'TYPE_PLANNING_DOCUMENT_INTERMEDIARY';
export const TYPE_TERMS_OF_BUSINESS_INTERMEDIARY = 'TYPE_TERMS_OF_BUSINESS_INTERMEDIARY';
export const TYPE_OTHER_INTERMEDIARY = 'TYPE_OTHER_INTERMEDIARY';
export const TYPE_AGREEMENT_IN_PRINCIPLE_INTERMEDIARY = 'TYPE_AGREEMENT_IN_PRINCIPLE_INTERMEDIARY';
export const TYPE_APPLICATION_INTERMEDIARY = 'TYPE_APPLICATION_INTERMEDIARY';
export const TYPE_AST_INTERMEDIARY = 'TYPE_AST_INTERMEDIARY';
export const TYPE_BUSINESS_PURPOSES_EXEMPTION_DECLARATION_INTERMEDIARY =
    'TYPE_BUSINESS_PURPOSES_EXEMPTION_DECLARATION_INTERMEDIARY';
export const TYPE_CREDIT_SEARCH_AUTHORITY_INTERMEDIARY = 'TYPE_CREDIT_SEARCH_AUTHORITY_INTERMEDIARY';
export const TYPE_CUSTOMER_PRIVACY_NOTICE_INTERMEDIARY = 'TYPE_CUSTOMER_PRIVACY_NOTICE_INTERMEDIARY';
export const TYPE_DEVELOPMENT_APPRAISAL_INTERMEDIARY = 'TYPE_DEVELOPMENT_APPRAISAL_INTERMEDIARY';
export const TYPE_DEVELOPMENT_CASH_FLOW_INTERMEDIARY = 'TYPE_DEVELOPMENT_CASH_FLOW_INTERMEDIARY';
export const TYPE_DIRECT_DEBIT_MANDATE_INTERMEDIARY = 'TYPE_DIRECT_DEBIT_MANDATE_INTERMEDIARY';
export const TYPE_EU_EEA_NATIONAL_IDENTITY_CARD_INTERMEDIARY = 'TYPE_EU_EEA_NATIONAL_IDENTITY_CARD_INTERMEDIARY';
export const TYPE_FACILITY_LETTER_INTERMEDIARY = 'TYPE_FACILITY_LETTER_INTERMEDIARY';
export const TYPE_HEADS_OF_TERMS_INTERMEDIARY = 'TYPE_HEADS_OF_TERMS_INTERMEDIARY';
export const TYPE_HEIGHT_NET_WORTH_EXEMPTION_DECLARATION_INTERMEDIARY =
    'TYPE_HEIGHT_NET_WORTH_EXEMPTION_DECLARATION_INTERMEDIARY';
export const TYPE_HMO_LICENSE_INTERMEDIARY = 'TYPE_HMO_LICENSE_INTERMEDIARY';
export const TYPE_INCOME_EXPENDITURE_INTERMEDIARY = 'TYPE_INCOME_EXPENDITURE_INTERMEDIARY';
export const TYPE_INCOME_EXPENDITURE_STATEMENT_INTERMEDIARY = 'TYPE_INCOME_EXPENDITURE_STATEMENT_INTERMEDIARY';
export const TYPE_LOAN_MORTGAGE_ILLUSTRATION_INTERMEDIARY = 'TYPE_LOAN_MORTGAGE_ILLUSTRATION_INTERMEDIARY';
export const TYPE_MORTGAGE_OFFER_INTERMEDIARY = 'TYPE_MORTGAGE_OFFER_INTERMEDIARY';
export const TYPE_PERSONAL_RESIDENCE_CARD_INTERMEDIARY = 'TYPE_PERSONAL_RESIDENCE_CARD_INTERMEDIARY';
export const TYPE_PLANNING_DOC_INTERMEDIARY = 'TYPE_PLANNING_DOC_INTERMEDIARY';
export const TYPE_PROPERTY_PORTFOLIO_INTERMEDIARY = 'TYPE_PROPERTY_PORTFOLIO_INTERMEDIARY';
export const TYPE_RESUME_INTERMEDIARY = 'TYPE_RESUME_INTERMEDIARY';
export const TYPE_SCHEDULE_OF_WORKS_DEVELOPMENTS_INTERMEDIARY = 'TYPE_SCHEDULE_OF_WORKS_DEVELOPMENTS_INTERMEDIARY';
export const TYPE_SITE_PLANS_INTERMEDIARY = 'TYPE_SITE_PLANS_INTERMEDIARY';
export const TYPE_SPECIALIST_REPORT_INTERMEDIARY = 'TYPE_SPECIALIST_REPORT_INTERMEDIARY';
export const TYPE_TENANCY_SCHEDULE_INTERMEDIARY = 'TYPE_TENANCY_SCHEDULE_INTERMEDIARY';
export const TYPE_UK_DRIVING_LICENSE_INTERMEDIARY = 'TYPE_UK_DRIVING_LICENSE_INTERMEDIARY';

// Lender types
export const TYPE_ACCOUNTANTS_REFERENCE_LENDERS = 'TYPE_ACCOUNTANTS_REFERENCE_LENDERS';
export const TYPE_AGREEMENT_IN_PRINCIPLE_LENDERS = 'TYPE_AGREEMENT_IN_PRINCIPLE_LENDERS';
export const TYPE_APPLICATION_LENDERS = 'TYPE_APPLICATION_LENDERS';
export const TYPE_ASSET_AND_LIABILITY_STATEMENT_LENDERS = 'TYPE_ASSET_AND_LIABILITY_STATEMENT_LENDERS';
export const TYPE_BUSINESS_PURPOSES_EXEMPTION_DECLARATION_LENDERS =
    'TYPE_BUSINESS_PURPOSES_EXEMPTION_DECLARATION_LENDERS';
export const TYPE_CREDIT_SEARCH_AUTHORITY_LENDERS = 'TYPE_CREDIT_SEARCH_AUTHORITY_LENDERS';
export const TYPE_DIRECT_DEBIT_MANDATE_LENDERS = 'TYPE_DIRECT_DEBIT_MANDATE_LENDERS';
export const TYPE_FACILITY_LETTER_LENDERS = 'TYPE_FACILITY_LETTER_LENDERS';
export const TYPE_HEADS_OF_TERMS_LENDERS = 'TYPE_HEADS_OF_TERMS_LENDERS';
export const TYPE_HEIGHT_NET_WORTH_EXEMPTION_DECLARATION_LENDERS =
    'TYPE_HEIGHT_NET_WORTH_EXEMPTION_DECLARATION_LENDERS';
export const TYPE_INCOME_EXPENDITURE_STATEMENT_LENDERS = 'TYPE_INCOME_EXPENDITURE_STATEMENT_LENDERS';
export const TYPE_MORTGAGE_OFFER_LENDERS = 'TYPE_MORTGAGE_OFFER_LENDERS';
export const TYPE_LOAN_MORTGAGE_ILLUSTRATION_LENDERS = 'TYPE_LOAN_MORTGAGE_ILLUSTRATION_LENDERS';
export const TYPE_CUSTOMER_PRIVACY_NOTICE_LENDERS = 'TYPE_CUSTOMER_PRIVACY_NOTICE_LENDERS';
export const TYPE_VALUATION_REPORT_LENDERS = 'TYPE_VALUATION_REPORT_LENDERS';
export const TYPE_SPECIALIST_REPORT_LENDERS = 'TYPE_SPECIALIST_REPORT_LENDERS';
export const TYPE_TERMS_OF_BUSINESS_LENDERS = 'TYPE_TERMS_OF_BUSINESS_LENDERS';
export const TYPE_OTHER_LENDERS = 'TYPE_OTHER_LENDERS';
export const TYPE_ACCOUNTANTS_LETTER_LENDERS = 'TYPE_ACCOUNTANTS_LETTER_LENDERS';
export const TYPE_ACCOUNTS_LENDERS = 'TYPE_ACCOUNTS_LENDERS';
export const TYPE_AST_LENDERS = 'TYPE_AST_LENDERS';
export const TYPE_BANK_STATEMENT_LENDERS = 'TYPE_BANK_STATEMENT_LENDERS';
export const TYPE_COMMERCIAL_LEASE_LENDERS = 'TYPE_COMMERCIAL_LEASE_LENDERS';
export const TYPE_COUNCIL_TAX_BILL_LENDERS = 'TYPE_COUNCIL_TAX_BILL_LENDERS';
export const TYPE_CREDIT_CARD_STATEMENT_LENDERS = 'TYPE_CREDIT_CARD_STATEMENT_LENDERS';
export const TYPE_CREDIT_REPORT_LENDERS = 'TYPE_CREDIT_REPORT_LENDERS';
export const TYPE_CURRENT_MORTGAGE_AGREEMENT_LENDERS = 'TYPE_CURRENT_MORTGAGE_AGREEMENT_LENDERS';
export const TYPE_DEVELOPMENT_APPRAISAL_LENDERS = 'TYPE_DEVELOPMENT_APPRAISAL_LENDERS';
export const TYPE_DEVELOPMENT_CASH_FLOW_LENDERS = 'TYPE_DEVELOPMENT_CASH_FLOW_LENDERS';
export const TYPE_EU_EEA_NATIONAL_IDENTITY_CARD_LENDERS = 'TYPE_EU_EEA_NATIONAL_IDENTITY_CARD_LENDERS';
export const TYPE_EMPLOYMENT_CONTRACT_LENDERS = 'TYPE_EMPLOYMENT_CONTRACT_LENDERS';
export const TYPE_HMO_LICENSE_LENDERS = 'TYPE_HMO_LICENSE_LENDERS';
export const TYPE_INCOME_AND_EXPENDITURE_LENDERS = 'TYPE_INCOME_AND_EXPENDITURE_LENDERS';
export const TYPE_P45_LENDERS = 'TYPE_P45_LENDERS';
export const TYPE_P60_LENDERS = 'TYPE_P60_LENDERS';
export const TYPE_PASSPORT_LENDERS = 'TYPE_PASSPORT_LENDERS';
export const TYPE_PAYSLIP_LENDERS = 'TYPE_PAYSLIP_LENDERS';
export const TYPE_PERSONAL_RESIDENCE_CARD_LENDERS = 'TYPE_PERSONAL_RESIDENCE_CARD_LENDERS';
export const TYPE_PLANNING_DOC_LENDERS = 'TYPE_PLANNING_DOC_LENDERS';
export const TYPE_PROOF_OF_ADDRESS_OTHER_LENDERS = 'TYPE_PROOF_OF_ADDRESS_OTHER_LENDERS';
export const TYPE_PROOF_OF_INCOME_OTHER_LENDERS = 'TYPE_PROOF_OF_INCOME_OTHER_LENDERS';
export const TYPE_PROPERTY_PORTFOLIO_LENDERS = 'TYPE_PROPERTY_PORTFOLIO_LENDERS';
export const TYPE_RESUME_LENDERS = 'TYPE_RESUME_LENDERS';
export const TYPE_SA302_LENDERS = 'TYPE_SA302_LENDERS';
export const TYPE_SALES_PARTICULARS_LENDERS = 'TYPE_SALES_PARTICULARS_LENDERS';
export const TYPE_SCHEDULE_OF_WORKS_DEVELOPMENTS_LENDERS = 'TYPE_SCHEDULE_OF_WORKS_DEVELOPMENTS_LENDERS';
export const TYPE_SITE_PLANS_LENDERS = 'TYPE_SITE_PLANS_LENDERS';
export const TYPE_STATEMENT_OF_ASSETS_AND_LIABILITIES_LENDERS = 'TYPE_STATEMENT_OF_ASSETS_AND_LIABILITIES_LENDERS';
export const TYPE_TENANCY_AGREEMENT_LENDERS = 'TYPE_TENANCY_AGREEMENT_LENDERS';
export const TYPE_TENANCY_SCHEDULE_LENDERS = 'TYPE_TENANCY_SCHEDULE_LENDERS';
export const TYPE_UK_DRIVING_LICENSE_LENDERS = 'TYPE_UK_DRIVING_LICENSE_LENDERS';
export const TYPE_UTILITY_BILL_LENDERS = 'TYPE_UTILITY_BILL_LENDERS';
export const TYPE_VISA_LENDERS = 'TYPE_VISA_LENDERS';
export const TYPE_CONSENT_FORM = 'TYPE_CONSENT_FORM';

const borrowerTypes = {
    [TYPE_SIGNED_CONTRACT_BORROWERS]: `${BRAND_NAME} Contract`,
    [TYPE_PASSPORT_BORROWERS]: 'Passport',
    [TYPE_UK_DRIVING_LICENCE_BORROWERS]: 'UK Driving Licence',
    [TYPE_EUEEA_NATIONAL_IDENTITY_CARD_BORROWERS]: 'EU/EEA National Identity Card',
    [TYPE_VISA_BORROWERS]: 'Visa',
    [TYPE_PERMANENT_RESIDENCE_CARD_BORROWERS]: 'Permanent Residence Card',
    [TYPE_BANK_STATEMENT_BORROWERS]: 'Bank Statement',
    [TYPE_CREDIT_CARD_STATEMENT_BORROWERS]: 'Credit Card Statement',
    [TYPE_UTILITY_BILL_BORROWERS]: 'Utility Bill',
    [TYPE_COUNCIL_TAX_BILL_BORROWERS]: 'Council Tax Bill',
    [TYPE_UK_DRIVING_LICENCE_BORROWERS]: 'UK Driving Licence',
    [TYPE_PROOF_OF_ADDRESS_OTHER_BORROWERS]: 'Proof of Address – Other',
    [TYPE_CREDIT_REPORT_BORROWERS]: 'Credit Report',
    [TYPE_STATEMENT_OF_ASSETS_AND_LIABILITIES_BORROWERS]: 'Statement of Assets & Liabilities',
    [TYPE_INCOME_AND_EXPENDITURE_BORROWERS]: 'Income & Expenditure',
    [TYPE_CREDIT_HISTORY_OTHER_BORROWERS]: 'Credit History – Other',
    [TYPE_PAYSLIP_BORROWERS]: 'Payslip',
    [TYPE_P60_BORROWERS]: 'P60',
    [TYPE_P45_BORROWERS]: 'P45',
    [TYPE_SA302_BORROWERS]: 'SA302',
    [TYPE_ACCOUNTS_BORROWERS]: 'Accounts',
    [TYPE_ACCOUNTANTS_LETTER_BORROWERS]: 'Accountant’s Letter',
    [TYPE_EMPLOYMENT_CONTRACT_BORROWERS]: 'Employment Contract',
    [TYPE_PROOF_OF_INCOME_OTHER_BORROWERS]: 'Proof of Income – Other',
    [TYPE_SALES_PARTICULARS_BORROWERS]: 'Sales Particulars',
    [TYPE_TENANCY_AGREEMENT_BORROWERS]: 'Tenancy Agreement',
    [TYPE_COMMERCIAL_LEASE_BORROWERS]: 'Commercial Lease',
    [TYPE_VALUATION_REPORT_BORROWERS]: 'Valuation Report',
    [TYPE_CURRENT_MORTGAGE_AGREEMENT_BORROWERS]: 'Current Mortgage Agreement',
    [TYPE_PLANNING_DOCUMENT_BORROWERS]: 'Planning Document',
    [TYPE_OTHER_BORROWERS]: 'Other',
    [TYPE_AGREEMENT_IN_PRINCIPLE_BORROWERS]: 'Agreement in Principle',
    [TYPE_APPLICATION_BORROWERS]: 'Application',
    [TYPE_AST_BORROWERS]: 'AST',
    [TYPE_BUSINESS_PURPOSES_EXEMPTION_DECLARATION_BORROWERS]: 'Business Purposes Exemption Declaration',
    [TYPE_CREDIT_SEARCH_AUTHORITY_BORROWERS]: 'Credit Search Authority',
    [TYPE_CUSTOMER_PRIVACY_NOTICE_BORROWERS]: 'Customer Privacy Notice',
    [TYPE_DEVELOPMENT_APPRAISAL_BORROWERS]: 'Development Appraisal',
    [TYPE_DEVELOPMENT_CASH_FLOW_BORROWERS]: 'Development Cash Flow',
    [TYPE_DIRECT_DEBIT_MANDATE_BORROWERS]: 'Direct Debit Mandate',
    [TYPE_FACILITY_LETTER_BORROWERS]: 'Facility Letter',
    [TYPE_HEADS_OF_TERMS_BORROWERS]: 'Heads of Terms',
    [TYPE_HEIGHT_NET_WORTH_EXEMPTION_DECLARATION_BORROWERS]: 'Height Net Worth Exemption Declaration',
    [TYPE_HMO_LICENSE_BORROWERS]: 'HMO License',
    [TYPE_INCOME_EXPENDITURE_STATEMENT_BORROWERS]: 'Income Expenditure Statement',
    [TYPE_LOAN_MORTGAGE_ILLUSTRATION_BORROWERS]: 'Loan / Mortgage Illustration',
    [TYPE_MORTGAGE_OFFER_BORROWERS]: 'Mortgage Offer',
    [TYPE_PERSONAL_RESIDENCE_CARD_BORROWERS]: 'Personal Residence Card',
    [TYPE_PLANNING_DOC_BORROWERS]: 'Planning Doc',
    [TYPE_PROPERTY_PORTFOLIO_BORROWERS]: 'Property Portfolio',
    [TYPE_RESUME_BORROWERS]: 'Resume',
    [TYPE_SCHEDULE_OF_WORKS_DEVELOPMENTS_BORROWERS]: 'Schedule of Works – Developments',
    [TYPE_SITE_PLANS_BORROWERS]: 'Site Plans',
    [TYPE_SPECIALIST_REPORT_BORROWERS]: 'Specialist Report',
    [TYPE_TENANCY_SCHEDULE_BORROWERS]: 'Tenancy Schedule',
    [TYPE_TERMS_OF_BUSINESS_BORROWERS]: 'Terms of Business',
    [TYPE_CONSENT_FORM]: 'Credit Consent Form',
};

const intermediaryTypes = {
    [TYPE_SIGNED_CONTRACT_INTERMEDIARY]: `${BRAND_NAME} Contract`,
    [TYPE_PASSPORT_INTERMEDIARY]: 'Passport',
    [TYPE_UK_DRIVING_LICENCE_INTERMEDIARY]: 'UK Driving Licence',
    [TYPE_EUEEA_NATIONAL_IDENTITY_CARD_INTERMEDIARY]: 'EU/EEA National Identity Card',
    [TYPE_VISA_INTERMEDIARY]: 'Visa',
    [TYPE_PERMANENT_RESIDENCE_CARD_INTERMEDIARY]: 'Permanent Residence Card',
    [TYPE_BANK_STATEMENT_INTERMEDIARY]: 'Bank Statement',
    [TYPE_CREDIT_CARD_STATEMENT_INTERMEDIARY]: 'Credit Card Statement',
    [TYPE_UTILITY_BILL_INTERMEDIARY]: 'Utility Bill',
    [TYPE_COUNCIL_TAX_BILL_INTERMEDIARY]: 'Council Tax Bill',
    [TYPE_UK_DRIVING_LICENCE_INTERMEDIARY]: 'UK Driving Licence',
    [TYPE_PROOF_OF_ADDRESS_OTHER_INTERMEDIARY]: 'Proof of Address – Other',
    [TYPE_CREDIT_REPORT_INTERMEDIARY]: 'Credit Report',
    [TYPE_STATEMENT_OF_ASSETS_AND_LIABILITIES_INTERMEDIARY]: 'Statement of Assets & Liabilities',
    [TYPE_INCOME_AND_EXPENDITURE_INTERMEDIARY]: 'Income & Expenditure',
    [TYPE_CREDIT_HISTORY_OTHER_INTERMEDIARY]: 'Credit History – Other',
    [TYPE_PAYSLIP_INTERMEDIARY]: 'Payslip',
    [TYPE_P60_INTERMEDIARY]: 'P60',
    [TYPE_P45_INTERMEDIARY]: 'P45',
    [TYPE_SA302_INTERMEDIARY]: 'SA302',
    [TYPE_ACCOUNTS_INTERMEDIARY]: 'Accounts',
    [TYPE_ACCOUNTANTS_LETTER_INTERMEDIARY]: 'Accountant’s Letter',
    [TYPE_EMPLOYMENT_CONTRACT_INTERMEDIARY]: 'Employment Contract',
    [TYPE_PROOF_OF_INCOME_OTHER_INTERMEDIARY]: 'Proof of Income – Other',
    [TYPE_SALES_PARTICULARS_INTERMEDIARY]: 'Sales Particulars',
    [TYPE_TENANCY_AGREEMENT_INTERMEDIARY]: 'Tenancy Agreement',
    [TYPE_COMMERCIAL_LEASE_INTERMEDIARY]: 'Commercial Lease',
    [TYPE_VALUATION_REPORT_INTERMEDIARY]: 'Valuation Report',
    [TYPE_CURRENT_MORTGAGE_AGREEMENT_INTERMEDIARY]: 'Current Mortgage Agreement',
    [TYPE_PLANNING_DOCUMENT_INTERMEDIARY]: 'Planning Document',
    [TYPE_TERMS_OF_BUSINESS_INTERMEDIARY]: 'Terms of Business',
    [TYPE_OTHER_INTERMEDIARY]: 'Other',
    [TYPE_AGREEMENT_IN_PRINCIPLE_INTERMEDIARY]: 'Agreement in Principle',
    [TYPE_APPLICATION_INTERMEDIARY]: 'Application',
    [TYPE_AST_INTERMEDIARY]: 'AST',
    [TYPE_BUSINESS_PURPOSES_EXEMPTION_DECLARATION_INTERMEDIARY]: 'Business Purposes Exemption Declaration',
    [TYPE_CREDIT_SEARCH_AUTHORITY_INTERMEDIARY]: 'Credit Search Authority',
    [TYPE_CUSTOMER_PRIVACY_NOTICE_INTERMEDIARY]: 'Customer Privacy Notice',
    [TYPE_DEVELOPMENT_APPRAISAL_INTERMEDIARY]: 'Development Appraisal',
    [TYPE_DEVELOPMENT_CASH_FLOW_INTERMEDIARY]: 'Development Cash Flow',
    [TYPE_DIRECT_DEBIT_MANDATE_INTERMEDIARY]: 'Direct Debit Mandate',
    [TYPE_FACILITY_LETTER_INTERMEDIARY]: 'Facility Letter',
    [TYPE_HEADS_OF_TERMS_INTERMEDIARY]: 'Heads of Terms',
    [TYPE_HEIGHT_NET_WORTH_EXEMPTION_DECLARATION_INTERMEDIARY]: 'Height Net Worth Exemption Declaration',
    [TYPE_HMO_LICENSE_INTERMEDIARY]: 'HMO License',
    [TYPE_INCOME_EXPENDITURE_STATEMENT_INTERMEDIARY]: 'Income Expenditure Statement',
    [TYPE_LOAN_MORTGAGE_ILLUSTRATION_INTERMEDIARY]: 'Loan / Mortgage Illustration',
    [TYPE_MORTGAGE_OFFER_INTERMEDIARY]: 'Mortgage Offer',
    [TYPE_PERSONAL_RESIDENCE_CARD_INTERMEDIARY]: 'Personal Residence Card',
    [TYPE_PLANNING_DOC_INTERMEDIARY]: 'Planning Doc',
    [TYPE_PROPERTY_PORTFOLIO_INTERMEDIARY]: 'Property Portfolio',
    [TYPE_RESUME_INTERMEDIARY]: 'Resume',
    [TYPE_SCHEDULE_OF_WORKS_DEVELOPMENTS_INTERMEDIARY]: 'Schedule of Works – Developments',
    [TYPE_SITE_PLANS_INTERMEDIARY]: 'Site Plans',
    [TYPE_SPECIALIST_REPORT_INTERMEDIARY]: 'Specialist Report',
    [TYPE_TENANCY_SCHEDULE_INTERMEDIARY]: 'Tenancy Schedule',
    [TYPE_CONSENT_FORM]: 'Credit Consent Form',
};

const lenderTypes = {
    [TYPE_ACCOUNTANTS_REFERENCE_LENDERS]: "Accountant's reference",
    [TYPE_AGREEMENT_IN_PRINCIPLE_LENDERS]: 'Agreement in principle',
    [TYPE_APPLICATION_LENDERS]: 'Application',
    [TYPE_ASSET_AND_LIABILITY_STATEMENT_LENDERS]: 'Asset and Liability statement',
    [TYPE_BUSINESS_PURPOSES_EXEMPTION_DECLARATION_LENDERS]: 'Business Purposes Exemption declaration',
    [TYPE_CREDIT_SEARCH_AUTHORITY_LENDERS]: 'Credit search Authority',
    [TYPE_DIRECT_DEBIT_MANDATE_LENDERS]: 'Direct Debit Mandate',
    [TYPE_FACILITY_LETTER_LENDERS]: 'Facility Letter',
    [TYPE_HEADS_OF_TERMS_LENDERS]: 'Heads of terms',
    [TYPE_HEIGHT_NET_WORTH_EXEMPTION_DECLARATION_LENDERS]: 'Height Net worth exemption Declaration',
    [TYPE_INCOME_EXPENDITURE_STATEMENT_LENDERS]: 'Income Expenditure Statement',
    [TYPE_MORTGAGE_OFFER_LENDERS]: 'Mortgage offer',
    [TYPE_LOAN_MORTGAGE_ILLUSTRATION_LENDERS]: 'Loan / Mortgage illustration',
    [TYPE_CUSTOMER_PRIVACY_NOTICE_LENDERS]: 'Customer Privacy Notice',
    [TYPE_VALUATION_REPORT_LENDERS]: 'Valuation Report',
    [TYPE_SPECIALIST_REPORT_LENDERS]: 'Specialist report',
    [TYPE_TERMS_OF_BUSINESS_LENDERS]: 'Terms of Business',
    [TYPE_OTHER_LENDERS]: 'Other',
    [TYPE_ACCOUNTANTS_LETTER_LENDERS]: 'Accountants Letter',
    [TYPE_ACCOUNTS_LENDERS]: 'Accounts',
    [TYPE_AST_LENDERS]: 'AST',
    [TYPE_BANK_STATEMENT_LENDERS]: 'Bank Statement',
    [TYPE_COMMERCIAL_LEASE_LENDERS]: 'Commercial Lease',
    [TYPE_COUNCIL_TAX_BILL_LENDERS]: 'Council Tax Bill',
    [TYPE_CREDIT_CARD_STATEMENT_LENDERS]: 'Credit Card Statement',
    [TYPE_CREDIT_REPORT_LENDERS]: 'Credit Report',
    [TYPE_CURRENT_MORTGAGE_AGREEMENT_LENDERS]: 'Current Mortgage Agreement',
    [TYPE_DEVELOPMENT_APPRAISAL_LENDERS]: 'Development Appraisal',
    [TYPE_DEVELOPMENT_CASH_FLOW_LENDERS]: 'Development Cash Flow',
    [TYPE_EU_EEA_NATIONAL_IDENTITY_CARD_LENDERS]: 'EU/EEA National Identity Card',
    [TYPE_EMPLOYMENT_CONTRACT_LENDERS]: 'Employment Contract',
    [TYPE_HMO_LICENSE_LENDERS]: 'HMO License',
    [TYPE_INCOME_AND_EXPENDITURE_LENDERS]: 'Income & Expenditure',
    [TYPE_P45_LENDERS]: 'P45',
    [TYPE_P60_LENDERS]: 'P60',
    [TYPE_PASSPORT_LENDERS]: 'Passport',
    [TYPE_PAYSLIP_LENDERS]: 'Payslip',
    [TYPE_PERSONAL_RESIDENCE_CARD_LENDERS]: 'Personal Residence Card',
    [TYPE_PLANNING_DOC_LENDERS]: 'Planning Doc',
    [TYPE_PROOF_OF_ADDRESS_OTHER_LENDERS]: 'Proof of Address – Other',
    [TYPE_PROOF_OF_INCOME_OTHER_LENDERS]: 'Proof of Income – Other',
    [TYPE_PROPERTY_PORTFOLIO_LENDERS]: 'Property Portfolio',
    [TYPE_RESUME_LENDERS]: 'Resume',
    [TYPE_SA302_LENDERS]: 'SA302',
    [TYPE_SALES_PARTICULARS_LENDERS]: 'Sales Particulars',
    [TYPE_SCHEDULE_OF_WORKS_DEVELOPMENTS_LENDERS]: 'Schedule of Works – Developments',
    [TYPE_SITE_PLANS_LENDERS]: 'Site Plans',
    [TYPE_STATEMENT_OF_ASSETS_AND_LIABILITIES_LENDERS]: 'Statement of Assets and Liabilities',
    [TYPE_TENANCY_AGREEMENT_LENDERS]: 'Tenancy Agreement',
    [TYPE_TENANCY_SCHEDULE_LENDERS]: 'Tenancy Schedule',
    [TYPE_UK_DRIVING_LICENSE_LENDERS]: 'UK Driving License',
    [TYPE_UTILITY_BILL_LENDERS]: 'Utility Bill',
    [TYPE_VISA_LENDERS]: 'Visa',
    [TYPE_CONSENT_FORM]: 'Credit Consent Form',
};

class DocumentTypeEnum extends BaseEnum {
    getTypes = () => {
        return { ...this.getLenderTypes(), ...this.getBorrowerTypes(), ...this.getIntermediaryTypes() };
    };

    getLenderTypes = () => {
        return lenderTypes;
    };

    getBorrowerTypes = () => {
        return borrowerTypes;
    };

    getIntermediaryTypes = () => {
        return intermediaryTypes;
    };

    getIntermediaryType = (type) => this.getIntermediaryTypes()[type];
}

export default DocumentTypeEnum;
