import React from 'react';
import { Container, Item, RowItem } from 'pitch4_layout/components/div';
import PropTypes from 'prop-types';

class FieldRowInputOnly extends React.Component {
    render() {
        let { input = <div />, ...rest } = this.props;

        // Initialise xs to full width
        if (typeof rest['xs'] === 'undefined') {
            rest['xs'] = 12;
        }

        return (
            <RowItem className={`field-row`}>
                <Container className={`container`}>
                    <Item {...rest}>{input}</Item>
                </Container>
            </RowItem>
        );
    }
}

FieldRowInputOnly.propTypes = {
    input: PropTypes.object,
};

export default FieldRowInputOnly;
