import { API_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { ROLE_BORROWER, ROLE_INTERMEDIARY } from '../../../../../pitch4_enum/enum/RoleNameEnum';
import moment from 'moment';
import { initialLELoadCount, newAccountsDays } from '../components/lists/config';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const GetDashboardListQuery = `
    query getUsers($cursor: String, $first: Int!, $sorts: [SortInput], $filters: [FilterInput]) {
      users(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            displayNameTemplates
            username
            email
            createdAt
            updatedAt
            status
            twoFactorAuthStatus
            userProfile {
              id
              title
              given
              family
              createdAt
              updatedAt
              primaryAddress {
                address1
                address2
                address3
                address4
                country
                postcode
              }
              primaryPhone {
                number
              }
            }
            roles {
              name
              label
            }
            primaryLender {
              lender {
                id
              }
            }
          }
        }
        totalCount
        totalIntermediariesCount
        totalBorrowersCount
      }
    }
`;

export const getDashboardListNewAccounts = (first = initialLELoadCount, cursor) => {
    return apolloFetch({
        query: GetDashboardListQuery,
        variables: {
            sorts: [],
            filters: [
                {
                    column: 'createdAt',
                    operation: 'gte',
                    not: false,
                    operand: moment().subtract(newAccountsDays, 'days').format('YYYY-MM-DD'), // Last 28 days
                },
                {
                    column: 'userRoles',
                    operation: 'nothing',
                    not: false,
                    operand: `${ROLE_BORROWER},${ROLE_INTERMEDIARY}`,
                },
            ],
            first: first,
            pages: [],
            cursor: cursor,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
