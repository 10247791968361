import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { Container, P4StyleComponentContainer, RowItem } from 'pitch4_layout/components/div';
import MapperLogo from '../form/MapperLogo';
import styled from 'styled-components';
import LogoApi from '../api/LogoApi';
import FormLogoDelete, { FORM_NAME as DELETE_LOGO_FORM_NAME } from '../components/FormLogoDelete';
import FormLogoUpload, { FORM_NAME as UPLOAD_LOGO_FORM_NAME } from '../components/FormLogoUpload';
import SessionManager from 'pitchblack_react_utils/session/SessionManager';
import withSession from 'pitchblack_react_utils/session/withSession';
import { reset } from 'redux-form';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import { connect } from 'react-redux';

const StyledRowItem = styled(RowItem)`
    padding-bottom: 20px;
`;

class LogoContainer extends React.Component {
    mapValues = (data) => {
        const mapper = new MapperLogo();
        let mappedValues = {};
        if (data && data.params) {
            mappedValues = mapper.mapToForm(data.params);
        }
        return mappedValues;
    };

    onSubmitLogo = () => (values) => {
        const { notificationSuccess, notificationError, resetForm } = this.props;
        const mapper = new MapperLogo();
        const sessionManager = new SessionManager();
        const logoApi = new LogoApi();

        let mappedValues = mapper.mapFromForm(values);
        logoApi
            .uploadLogo(mappedValues, sessionManager.getToken().token)
            .then((response) => {
                if (response.data.success === true) {
                    notificationSuccess('Logo successfully uploaded.');
                    resetForm(UPLOAD_LOGO_FORM_NAME);
                    return;
                }

                return response;
            })
            .catch((errors) => {
                notificationError('Unable to update logo, please try again and/or contact support.');
                return [];
            });
    };

    onSubmitLogoDelete = () => (values) => {
        const { notificationSuccess, notificationError, resetForm } = this.props;
        const logoApi = new LogoApi();
        const sessionManager = new SessionManager();
        logoApi
            .deleteLogo(values.id, sessionManager.getToken().token)
            .then((response) => {
                if (response.data.success === true) {
                    notificationSuccess('Logo successfully deleted.');
                    resetForm(DELETE_LOGO_FORM_NAME);
                }

                return response;
            })
            .catch((errors) => {
                notificationError('Unable to delete logo, please try again and/or contact support.');
                return [];
            });
    };

    render() {
        let { match } = this.props;

        return (
            <Container>
                <StyledRowItem>
                    <P4StyleComponentContainer
                        header={`Replace Logo`}
                        content={
                            <FormLogoUpload initialValues={this.mapValues(match)} onSubmit={this.onSubmitLogo()} />
                        }
                    />
                </StyledRowItem>

                <StyledRowItem>
                    <P4StyleComponentContainer
                        header={`Delete Logo`}
                        content={
                            <FormLogoDelete
                                initialValues={this.mapValues(match)}
                                onSubmit={this.onSubmitLogoDelete()}
                            />
                        }
                    />
                </StyledRowItem>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
        resetForm: (formName) => {
            dispatch(reset(formName));
        },
    };
};

export default compose(
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN]),
    connect(mapStateToProps, mapDispatchToProps),
    withSession
)(LogoContainer);
