import React from 'react';
import { Query } from 'react-apollo/index';
import gql from 'graphql-tag';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { OPERATION_EQ } from '../../../../../pitch4_enum/enum/OperationEnum';
import { SORT_ASC, SORT_DESC } from '../../../../../pitch4_tabular/enum/SortDirectionEnum';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import { Container } from '../../../../../pitch4_layout/components/div';
import { P4StyleComponentContainer } from '../../../../../pitch4_layout/components/div';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import { withRouter } from 'react-router-dom';
import { ROLE_INTERMEDIARY } from '../../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import UploadDocumentButton from './UploadDocumentButton';
import AdminCaseMgmtUploadDocumentButton from './AdminCaseMgmtUploadDocumentButton';
import PlaceholderImageLayout from '../../../../../pitch4_layout/components/content/PlaceholderImageLayout';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SecondaryButton from '../../../../../pitch4_layout/components/buttons/SecondaryButton';
import { handleDownload } from '../../../../../pitch4_documents/src/components/DownloadButton';
import { Loading } from '../../../../../pitchblack_react_utils/form/renderIfSubmitting';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import DocumentListItem from './DocumentListItem';

class DocumentList extends React.Component {
    componentDidMount() {
        const { handleACMDocumentsAlert, loanEnquiry } = this.props;

        if (loanEnquiry && handleACMDocumentsAlert) {
            handleACMDocumentsAlert(loanEnquiry.id);
        }
    }

    render() {
        const {
            loanEnquiry = null,
            lenderLoanEnquiry = null,
            disableUploadButton = false,
            disabled = false,
            componentContainerStyles = {},
            adminCaseManagement = false,
        } = this.props;
        let query;

        if (lenderLoanEnquiry) {
            query = gql`
                query getDocuments($count: Int!, $filters: [FilterInput], $acm: Boolean!) {
                    documents(first: $count, filters: $filters, acm: $acm) {
                        edges {
                            node {
                                id
                                fileName
                                type
                                uploaderRole
                                downloadUrl
                                uploaderRole
                                createdAt
                            }
                            cursor
                        }
                        pageInfo {
                            hasNextPage
                            endCursor
                            startCursor
                            hasPreviousPage
                        }
                        totalCount
                    }
                }
            `;
        } else if (loanEnquiry) {
            query = gql`
                query getDocuments($count: Int!, $filters: [FilterInput], $acm: Boolean!) {
                    documents(first: $count, filters: $filters, acm: $acm) {
                        edges {
                            node {
                                id
                                fileName
                                type
                                uploaderRole
                                downloadUrl
                                uploaderRole
                                createdAt
                            }
                            cursor
                        }
                        pageInfo {
                            hasNextPage
                            endCursor
                            startCursor
                            hasPreviousPage
                        }
                        totalCount
                    }
                }
            `;
        }

        return (
            <Query
                fetchPolicy={'no-cache'}
                query={query}
                context={{
                    uri: INTERMEDIARY_URL,
                }}
                variables={{
                    count: 9999,
                    acm: adminCaseManagement,
                    filters: [
                        {
                            column: 'contextEntity',
                            operation: OPERATION_EQ,
                            operand: lenderLoanEnquiry ? lenderLoanEnquiry.id : loanEnquiry ? loanEnquiry.id : null,
                            not: false,
                        },
                    ],
                    sorts: [
                        {
                            column: 'type',
                            direction: SORT_ASC,
                        },
                        {
                            column: 'createdAt',
                            direction: SORT_DESC,
                        },
                    ],
                }}
            >
                {({ data, refetch }) => {
                    const docs = data.documents ? data.documents.edges : [];
                    const disabledClassName = disabled ? 'document-list-placeholder-disabled' : '';

                    return (
                        <P4StyleComponentContainer
                            styles={componentContainerStyles}
                            header={
                                <Container
                                    justifyContent={`flex-end`}
                                    alignItems={`center`}
                                    style={{ marginTop: 20, paddingRight: 20 }}
                                >
                                    {lenderLoanEnquiry ? (
                                        <UploadDocumentButton
                                            outerRefetch={refetch}
                                            lenderLoanEnquiryId={lenderLoanEnquiry.id}
                                            buttonRender={(openAddDocumentModal) => (
                                                <SecondaryButton
                                                    disabled={disableUploadButton}
                                                    onClick={() => openAddDocumentModal()}
                                                    style={{ color: '#ffffff' }}
                                                >
                                                    Add a Document <AddCircleIcon />
                                                </SecondaryButton>
                                            )}
                                        />
                                    ) : loanEnquiry ? (
                                        <AdminCaseMgmtUploadDocumentButton
                                            outerRefetch={refetch}
                                            loanEnquiryId={loanEnquiry.id}
                                            buttonRender={(openAddDocumentModal) => (
                                                <SecondaryButton
                                                    disabled={disableUploadButton}
                                                    onClick={() => openAddDocumentModal()}
                                                    style={{ color: '#ffffff' }}
                                                >
                                                    Add a Document <AddCircleIcon />
                                                </SecondaryButton>
                                            )}
                                        />
                                    ) : null}
                                </Container>
                            }
                            content={
                                docs.length > 0 ? (
                                    docs.map(({ node: document }) => (
                                        <DocumentListItem
                                            refetch={refetch}
                                            document={document}
                                            adminCaseManagement={adminCaseManagement}
                                        />
                                    ))
                                ) : (
                                    <PlaceholderImageLayout
                                        classNames={`document-list-placeholder ${disabledClassName}`}
                                    >
                                        <div>
                                            <h3 className={'typography-card-title'}>No documents uploaded yet</h3>
                                            <p className={'typography-default'}>
                                                Upload here to share documents with this lender.
                                            </p>
                                        </div>
                                    </PlaceholderImageLayout>
                                )
                            }
                        />
                    );
                }}
            </Query>
        );
    }

    downloadDocument = (document) => () => {
        let { setModalClose, setModalComponent } = this.props;

        handleDownload(document, {
            onPending: () => setModalComponent(<Loading heading="Please wait. Downloading file." />, false),
            onSuccess: () => setModalClose(),
            onFailure: () => setModalClose(),
        });
    };
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        successNotification: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        errorNotification: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_INTERMEDIARY])
)(DocumentList);
