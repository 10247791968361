import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
    actionGetMatches,
    actionSetApiGetLoanEnquiryMatchesResponse,
    actionSetApiGetLoanEnquiryResponse,
    actionSetMatchingCriteriaConfig,
    actionSetStatus,
    API_GET_MATCHES,
    API_SET_LEAD_STATUS,
    API_SET_STATUS,
    INITIATE,
    RELOAD_LOAN_ENQUIRY,
    REVERT_LOAN_ENQUIRY_TO_DRAFT,
    selectorGetLoanEnquiry,
    selectorGetLoanEnquiryId,
    SET_MATCH_STATUSES,
    SET_STATUS_SUBMISSION_ERRORS,
    SEND_MESSAGE_TO_LENDERS,
} from '../redux';

import { getConfig, getEnquiry, getMatches, setLenderLoanEnquiryStatuses } from '../api';
import { setLeadStatus } from '../../lender_loan_enquiries_view/api/set_status';
import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../../notifications/sagas/container';
import { setLoanEnquiryStatus } from '../api/set_loan_enquiry_status';
import { revertLoanEnquiryToDraft } from '../api/revert_loan_enquiry_to_draft';
import { sendMessageToLenders } from '../api/send_message_to_lenders';
import { sagaActionModalHide } from '../../../modal/sagas/container';
import { push } from 'react-router-redux';
import { path, ROUTE_BORROWER_EDIT_LOAN_ENQUIRY } from '../../../../pitch4_routing';

function* getMatchingCriteriaConfig() {
    const response = yield call(getConfig);

    switch (response.getClassName()) {
        case 'GetConfigSuccess':
            const config = response.getData();

            // Set the form initial values
            yield put(actionSetMatchingCriteriaConfig(config));
            break;
        case 'GetConfigError':
        default:
            break;
    }
}

function* getLoanEnquiry(action) {
    const { loanEnquiryId } = action;
    const response = yield call(getEnquiry, loanEnquiryId);
    yield put(actionSetApiGetLoanEnquiryResponse(response));
}

function* refreshLoanEnquiry(action) {
    const loanEnquiry = yield select(selectorGetLoanEnquiry);
    const response = yield call(getEnquiry, loanEnquiry.id);
    yield put(actionSetApiGetLoanEnquiryResponse(response));
}

function* setMatchStatuses(action) {
    const { statuses } = action;
    yield call(setLenderLoanEnquiryStatuses, statuses);
    const loanEnquiry = yield select(selectorGetLoanEnquiry);
    const response = yield call(getMatches, loanEnquiry.id);
    yield put(actionSetApiGetLoanEnquiryMatchesResponse(response));
}

function* apiSetLeadStatus(action) {
    const response = yield call(setLeadStatus, action.leadId, action.newStatus, action.metadata);
    const { errors } = response;
    if (errors) {
        const errors = response.errors.map((err) => err.message);
        yield put({ type: SAGA_ACTION_ERROR, message: 'Could not update lead status' });
        yield put({ type: SET_STATUS_SUBMISSION_ERRORS, errors });
    } else {
        yield put(actionSetStatus(action.newStatus));
        if (action.refetchMatches) {
            yield put(actionGetMatches());
        }
        yield put(sagaActionModalHide());
        yield put({ type: SAGA_ACTION_SUCCESS, message: 'Lead status saved!' });
    }
}

function* apiGetMatches(action) {
    const loanEnquiryId = yield select(selectorGetLoanEnquiryId);
    const response = yield call(getMatches, loanEnquiryId);
    yield put(actionSetApiGetLoanEnquiryMatchesResponse(response));
}

function* apiSetStatus(action) {
    const response = yield call(setLoanEnquiryStatus, action.loanEnquiryId, action.status);
    const { errors } = response;
    if (errors) {
        yield put({ type: SAGA_ACTION_ERROR, message: 'Could not update enquiry status' });
    } else {
        yield put(actionSetStatus(action.status));
        yield put(sagaActionModalHide());
        yield put({ type: SAGA_ACTION_SUCCESS, message: 'Enquiry status saved!' });
    }
}

function* apiRevertToDraft(action) {
    const response = yield call(revertLoanEnquiryToDraft, action.loanEnquiryId);
    const { errors } = response;
    if (errors) {
        yield put({ type: SAGA_ACTION_ERROR, message: 'Could not revert enquiry to draft' });
    } else {
        const data = response.getData();
        yield put(sagaActionModalHide());
        yield put({ type: SAGA_ACTION_SUCCESS, message: 'Enquiry reverted to draft!' });
        yield put(
            push(path(ROUTE_BORROWER_EDIT_LOAN_ENQUIRY, { loanEnquiryId: data.revertToDraftLoanEnquiry.loanEnquiryId }))
        );
    }
}

function* apiSendMessageToLenders(action) {
    const { lenders, message, toggleMessageDrawer, setMessageSent } = action;
    setMessageSent(true);

    const response = yield call(sendMessageToLenders, lenders, message);
    const { errors } = response;
    if (errors) {
        yield put({ type: SAGA_ACTION_ERROR, message: 'Could not send message' });
    } else {
        yield put({ type: SAGA_ACTION_SUCCESS, message: 'Message sent' });
    }

    toggleMessageDrawer();
}

//Main Saga
function* main() {
    yield takeLatest(INITIATE, getMatchingCriteriaConfig);
    yield takeLatest(INITIATE, getLoanEnquiry);
    yield takeLatest(RELOAD_LOAN_ENQUIRY, refreshLoanEnquiry);
    yield takeLatest(SET_MATCH_STATUSES, setMatchStatuses);
    yield takeLatest(API_SET_STATUS, apiSetStatus);
    yield takeLatest(API_GET_MATCHES, apiGetMatches);
    yield takeLatest(API_SET_LEAD_STATUS, apiSetLeadStatus);
    yield takeLatest(REVERT_LOAN_ENQUIRY_TO_DRAFT, apiRevertToDraft);
    yield takeLatest(SEND_MESSAGE_TO_LENDERS, apiSendMessageToLenders);
}

export default main;
