import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import { sagaActionModalHide } from '../../modal/sagas/container';
import { CenteringContainer, Container, RowItem } from '../../../pitchblack_react_utils/layout/index';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import WelcomeIcon from '../../../assets/images/welcome.svg';

const Presentation = (props) => {
    const { onClick } = props;
    return (
        <Container>
            <RowItem>
                <CenteringContainer className={'margin-top-20'}>
                    <img src={WelcomeIcon} alt={'Welcome to your Enquiry Screen'} />
                </CenteringContainer>
            </RowItem>
            <RowItem>
                <h2
                    style={{
                        color: '#2ec4b6',
                        fontWeight: 700,
                        fontSize: '18px',
                        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                        textAlign: 'center',
                        marginTop: 50,
                    }}
                >
                    Welcome to your Enquiry Screen
                </h2>
            </RowItem>
            <RowItem>
                <p className={'body1 text-align-center margin-top-10'}>
                    From here you can <strong>create a new enquiry</strong>, get matched with our many lenders and view
                    your ongoing enquiries.
                </p>
            </RowItem>
            <Container className={'button-panel'} justifyContent={`center`} alignItems={`center`}>
                <PrimaryButton type="submit" onClick={onClick}>
                    Create an enquiry
                </PrimaryButton>
            </Container>
        </Container>
    );
};

Presentation.propTypes = {
    onClick: PropTypes.func.isRequired,
};

const Functional = ({ url, redirect, modalHide, inModal = false }) => {
    const onClick = () => {
        redirect(url);

        if (inModal) {
            modalHide();
        }
    };

    return <Presentation onClick={onClick} />;
};

Functional.propTypes = {
    url: PropTypes.string.isRequired,
    redirect: PropTypes.func.isRequired,
    modalHide: PropTypes.func.isRequired,
    inModal: PropTypes.bool,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
        modalHide: () => dispatch(sagaActionModalHide()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Functional));
