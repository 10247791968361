import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import SessionManager from '../../pitchblack_react_utils/session/SessionManager';

import { actionLogout } from '../../apps/authentication/redux/login';
import { ROUTE_ANON_LOGIN } from '../index';
import { determineAuthenticatedBaseRoute } from '../src/findRoute';

export const SAGA_NAMESPACE = 'sagas.routing';

const sessionManager = new SessionManager();

//Saga Action Creators
export const SAGA_ACTION_RESOLVE_BASE_ROUTE = `${SAGA_NAMESPACE}.resolve_base_admin_route`;
export const actionResolveBaseRouteFromSession = () => ({
    type: SAGA_ACTION_RESOLVE_BASE_ROUTE,
});

//Worker Sagas
function* resolveBaseRoute() {
    if (sessionManager.isAuthenticated() === false) {
        yield put(actionLogout());
    }

    let route = determineAuthenticatedBaseRoute();

    if (route === null) {
        route = ROUTE_ANON_LOGIN;
    }

    yield put(push(`${route}`));
}

//Main Saga
function* main() {
    yield takeLatest(SAGA_ACTION_RESOLVE_BASE_ROUTE, resolveBaseRoute);
}

export default main;
