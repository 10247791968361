import React from 'react';
import gql from 'graphql-tag';

class TokenFragment extends React.Component {
    static fragments = {
        token: gql`
            fragment TokenFragmentToken on Token {
                token
                expires
            }
        `,
    };
}

export default TokenFragment;
