import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import StatusEnum from '../../../../../pitch4_enum/enum/BrokerStatusEnum';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import { ROWS_PER_PAGE, PAGE_SIZE } from '../../../../../pitch4_enum/enum/DataGridEnum';
import HeaderWithCount from '../../../../../pitch4_elements/screen-headers/HeaderWithCount';
import { compose } from 'recompose';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import { getBrokers } from '../../../../../pitch4_api/get_brokers';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const statusEnum = new StatusEnum();

function NetworkBrokersTableStyleContainer(props) {
    const [dataGrid, setDataGrid] = useState({
        rows: [],
        totalRowCount: 0,
        dataLoading: false,
        pageSize: PAGE_SIZE,
        rowsPerPage: ROWS_PER_PAGE,
        currentPage: 0,
        sortModel: [{ column: 'createdAt', direction: 'desc' }],
    });
    const [network, setNetwork] = useState(null);

    useEffect(() => {
        const brokers = getBrokers(API_URL)(null, [
            {
                operation: 'eq',
                operand: props.match.params.networkId,
                column: 'parentBroker',
                not: false,
            },
        ]);
        processBrokers(brokers);

        getBrokers(API_URL)(null, [
            {
                operation: 'eq',
                operand: props.match.params.networkId,
                column: 'id',
                not: false,
            },
        ]).then((resp) =>
            setNetwork({
                id: props.match.params.networkId,
                name: resp.data.brokers.edges[0].node.name,
            })
        );
    }, []);

    const processBrokers = (data) => {
        setDataGrid({
            rows: [],
            totalRowCount: 0,
            dataLoading: true,
            pageSize: PAGE_SIZE,
            rowsPerPage: ROWS_PER_PAGE,
            currentPage: 0,
            sortModel: [{ column: 'createdAt', direction: 'desc' }],
        });

        data.then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            const brokers = data.brokers.edges.map((user) => user.node);
            const rows = brokers.map((broker, index) => {
                return {
                    id: broker.id,
                    name: broker.name,
                    hasDataProtectionLicence: broker.hasDataProtectionLicence,
                    isNetwork: broker.isNetwork ?? false,
                    primaryEmail: broker.primaryEmail,
                    fcaIdentifier: broker.fcaIdentifier,
                    companyNumber: broker.companyNumber,
                    primaryAddressAddress1: broker.primaryAddress.address1,
                    primaryAddressAddress2: broker.primaryAddress.address2,
                    primaryAddressAddress3: broker.primaryAddress.address3,
                    primaryAddressAddress4: broker.primaryAddress.address4,
                    primaryAddressCountry: broker.primaryAddress.country,
                    primaryAddressPostcode: broker.primaryAddress.postcode,
                    primaryPhoneNumber: broker.primaryPhone.number,
                    status: broker.status,
                    createdAt: broker.createdAt,
                    joinedNetworkAt: broker.joinedNetworkAt,
                    realTimeNotifications: broker.realTimeNotifications,
                };
            });

            setDataGrid({
                rows: rows,
                totalRowCount: data.brokers.totalCount,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: [{ column: 'createdAt', direction: 'desc' }],
            });
        });
    };

    return (
        <div>
            <div className={'le-header-container'}>
                <div className={'header-text'}>
                    <HeaderWithCount title={`${network ? network.name + ' - ' : ''}Network Brokers`} />
                </div>
            </div>

            <div className={`le-table-container`}>
                <DataGrid
                    rowCount={dataGrid.totalRowCount}
                    pageSize={dataGrid.pageSize}
                    rowsPerPageOptions={dataGrid.rowsPerPage}
                    rows={dataGrid.rows}
                    loading={true}
                    disableColumnFilter={true}
                    disableMultipleColumnsSorting={true}
                    disableColumnReorder={true}
                    disableMultipleColumnsFiltering={true}
                    disableSelectionOnClick={true}
                    disableColumnMenu={true}
                    components={{
                        LoadingOverlay: () => {
                            return (
                                <GridOverlay>
                                    <div
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            width: '100%',
                                        }}
                                    >
                                        {dataGrid.dataLoading ? (
                                            <LinearProgress />
                                        ) : dataGrid.rows.length === 0 ? (
                                            <div
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    width: '100%',
                                                    paddingTop: 100,
                                                    textAlign: 'center',
                                                }}
                                            >
                                                No data
                                            </div>
                                        ) : null}
                                    </div>
                                </GridOverlay>
                            );
                        },
                    }}
                    columns={[
                        {
                            field: 'name',
                            headerName: 'NAME',
                            width: 300,
                            cellClassName: 'le-table-cell',
                            headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                        },
                        {
                            field: 'hasDataProtectionLicence',
                            headerName: 'DATA PROTECTION LICENCE',
                            width: 220,
                            cellClassName: 'le-table-cell',
                            headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            valueFormatter: ({ value }) => ('true' === value ? 'Yes' : 'No'),
                            sortable: false,
                        },
                        {
                            field: 'realTimeNotifications',
                            headerName: 'EXTRA NOTIFICATIONS',
                            width: 200,
                            cellClassName: 'le-table-cell',
                            headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            renderCell: (params) => {
                                return <>{params.row.realTimeNotifications ? 'ON' : 'OFF'}</>;
                            },
                        },
                        {
                            field: 'isNetwork',
                            headerName: 'IS NETWORK BROKER',
                            width: 220,
                            cellClassName: 'le-table-cell',
                            headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            valueFormatter: ({ value }) => ('true' === value ? 'Yes' : 'No'),
                            sortable: false,
                        },
                        {
                            field: 'joinedNetworkAt',
                            headerName: 'JOINED NETWORK',
                            width: 170,
                            valueFormatter: ({ value }) =>
                                null !== value ? moment(value).format('MMM DD,YYYY H:mm') : 'Date not available',
                            cellClassName: 'le-table-cell',
                            headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                        },
                        {
                            field: 'createdAt',
                            headerName: 'CREATED',
                            width: 170,
                            valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                            cellClassName: 'le-table-cell',
                            headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                        },
                        {
                            field: 'status',
                            headerName: 'STATUS',
                            width: 100,
                            cellClassName: 'le-table-cell',
                            headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            valueFormatter: ({ value }) => statusEnum.getType(value),
                        },
                    ]}
                />
            </div>
        </div>
    );
}

export default compose(
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(NetworkBrokersTableStyleContainer);
