import { API_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const UpdateLEFavouriteMutation = `
    mutation setLenderLoanEnquiryFavourite($lenderLoanEnquiry: ID, $loanEnquiry: ID, $input: setLenderLoanEnquiryFavouriteInput!) {
      setLenderLoanEnquiryFavourite(lenderLoanEnquiry: $lenderLoanEnquiry, loanEnquiry: $loanEnquiry, input: $input) {
        lenderLoanEnquiry
        loanEnquiry
        favourite
      }
    }
`;

export const updateLEFavourite = (leId, isFavourite) => {
    return apolloFetch({
        query: UpdateLEFavouriteMutation,
        variables: {
            lenderLoanEnquiry: null,
            loanEnquiry: leId,
            input: {
                favourite: isFavourite,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
