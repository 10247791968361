import SessionManager from '../../pitchblack_react_utils/session/SessionManager';
import {
    ROLE_ADMIN,
    ROLE_BORROWER,
    ROLE_INTERMEDIARY,
    ROLE_SLAM,
    ROLE_SUPER_ADMIN,
} from '../../pitch4_enum/enum/RoleNameEnum';
import { API_URL, BORROWER_URL, INTERMEDIARY_URL, SLAM_URL } from '../../bootstrap/constants/constants';

export const getUserUrl = () => {
    const session = new SessionManager();
    const userRole = session.getMostSeniorRole();

    switch (userRole.name) {
        case ROLE_BORROWER:
            return BORROWER_URL;
        case ROLE_INTERMEDIARY:
            return INTERMEDIARY_URL;
        case ROLE_ADMIN:
        case ROLE_SUPER_ADMIN:
            return API_URL;
        case ROLE_SLAM:
            return SLAM_URL;
        default:
            return null;
    }
};
