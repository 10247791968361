export const HMO_FUNDING_TYPE = 'HMO_FUNDING_TYPE';
export const RESIDENTIAL_FUNDING_TYPE = 'RESIDENTIAL_FUNDING_TYPE';
export const SEMI_COMMERCIAL_FUNDING_TYPE = 'SEMI_COMMERCIAL_FUNDING_TYPE';
export const COMMERCIAL_FUNDING_TYPE = 'COMMERCIAL_FUNDING_TYPE';
export const RESIDENTIAL_PORTFOLIO_FUNDING_TYPE = 'RESIDENTIAL_PORTFOLIO_FUNDING_TYPE';
export const COMMERCIAL_PORTFOLIO_FUNDING_TYPE = 'COMMERCIAL_PORTFOLIO_FUNDING_TYPE';
export const MIXED_PORTFOLIO_FUNDING_TYPE = 'MIXED_PORTFOLIO_FUNDING_TYPE';
export const LAND_WITHOUT_PLANNING_FUNDING_TYPE = 'LAND_WITHOUT_PLANNING_FUNDING_TYPE';
export const MIXED_FUNDING_TYPE = 'MIXED_FUNDING_TYPE';
export const RESIDENTIAL_FUNDING_TYPE_NON_RENTED = 'RESIDENTIAL_FUNDING_TYPE_NON_RENTED';
export const RESIDENTIAL_FUNDING_TYPE_RENTED = 'RESIDENTIAL_FUNDING_TYPE_RENTED';
export const RESIDENTIAL_FUNDING_TYPE_SECOND_CHARGE = 'RESIDENTIAL_FUNDING_TYPE_SECOND_CHARGE';
class CommercialFundingType {
    getTypes = () => {
        return {
            [HMO_FUNDING_TYPE]: {
                value: HMO_FUNDING_TYPE,
                label: 'A residential property to multiple tenants ([bold|HMO])',
            },
            [RESIDENTIAL_FUNDING_TYPE]: {
                value: RESIDENTIAL_FUNDING_TYPE,
                label: 'A residential property that will be rented out or sold ([bold|residential])',
            },
            [RESIDENTIAL_FUNDING_TYPE_NON_RENTED]: {
                value: RESIDENTIAL_FUNDING_TYPE,
                label: 'A residential property that will be sold ([bold|residential])',
            },
            [RESIDENTIAL_FUNDING_TYPE_RENTED]: {
                value: RESIDENTIAL_FUNDING_TYPE,
                label: 'A residential property that will be rented out ([bold|residential])',
            },
            [RESIDENTIAL_FUNDING_TYPE_SECOND_CHARGE]: {
                value: RESIDENTIAL_FUNDING_TYPE,
                label: 'A residential property ([bold|residential])',
            },
            [SEMI_COMMERCIAL_FUNDING_TYPE]: {
                value: SEMI_COMMERCIAL_FUNDING_TYPE,
                label:
                    'A business or commercial property that also has a residential element that will either be rented out or will be occupied by the business ([bold|semi-commercial])',
            },
            [COMMERCIAL_FUNDING_TYPE]: {
                value: COMMERCIAL_FUNDING_TYPE,
                label:
                    'A business or commercial property that will either be rented out or will be occupied by the business ([bold|commercial])',
            },
            [RESIDENTIAL_PORTFOLIO_FUNDING_TYPE]: {
                value: RESIDENTIAL_PORTFOLIO_FUNDING_TYPE,
                label: 'A fully residential portfolio ',
            },
            [COMMERCIAL_PORTFOLIO_FUNDING_TYPE]: {
                value: COMMERCIAL_PORTFOLIO_FUNDING_TYPE,
                label: 'A fully commercial portfolio',
            },
            [MIXED_PORTFOLIO_FUNDING_TYPE]: {
                value: MIXED_PORTFOLIO_FUNDING_TYPE,
                label: 'A mixture of residential and commercial properties',
            },
            [LAND_WITHOUT_PLANNING_FUNDING_TYPE]: {
                value: LAND_WITHOUT_PLANNING_FUNDING_TYPE,
                label: 'A plot of land or building site',
            },
        };
    };

    getOptions = () => [
        { value: HMO_FUNDING_TYPE, label: 'A residential property to multiple tenants ([bold|HMO])' },
        {
            value: RESIDENTIAL_FUNDING_TYPE,
            label: 'A residential property that will be rented out ([bold|residential)',
        },
        {
            value: SEMI_COMMERCIAL_FUNDING_TYPE,
            label:
                'A business or commercial property that also has a residential element that will either be rented out or will be occupied by the business ([bold|semi-commercial])',
        },
        {
            value: COMMERCIAL_FUNDING_TYPE,
            label:
                'A business or commercial property that will either be rented out or will be occupied by the business ([bold|commercial])',
        },
        { value: RESIDENTIAL_FUNDING_TYPE, label: 'A fully residential portfolio ' },
        { value: COMMERCIAL_FUNDING_TYPE, label: 'A fully commercial portfolio' },
        { value: MIXED_FUNDING_TYPE, label: 'A mixture of residential and commercial properties' },
        { value: LAND_WITHOUT_PLANNING_FUNDING_TYPE, label: 'A plot of land or building site' },
    ];
}

export default CommercialFundingType;
