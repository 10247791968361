import BaseLevel from './BaseLevel';
import { SUCCESS_LEVEL } from '../enum/LevelEnum';

class Success extends BaseLevel {
    constructor(message) {
        super(SUCCESS_LEVEL, message);
    }
}

export default Success;
