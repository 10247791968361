import createP4ApolloFetch from '../bootstrap/middlewares';

const ClearLenderTermsUpdatedAlertMutation = `
    mutation clearLenderTermsUpdatedAlerts($loanEnquiryId: ID, $lenderLoanEnquiryId: ID) {
      clearLenderTermsUpdatedAlerts(loanEnquiryId: $loanEnquiryId, lenderLoanEnquiryId: $lenderLoanEnquiryId) {
        success
      }
    }
`;

export const clearLenderTermsUpdatedAlerts = (loanEnquiryId = null, lenderLoanEnquiryId = null, uri) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    return apolloFetch({
        query: ClearLenderTermsUpdatedAlertMutation,
        variables: {
            loanEnquiryId,
            lenderLoanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
