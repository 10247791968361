import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { createScrollMiddleware } from 'react-redux-scroll';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'react-router-redux';
import reducers from '../redux/index';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// create the sagas middleware
const scrollMiddleware = createScrollMiddleware();
const sagaMiddleware = createSagaMiddleware();
const routerMiddleware = createRouterMiddleware(history);
const reduxLogger = createLogger({
    predicate: () => {
        const logging = false;
        return logging;
    },
});

const configureStore = () => {
    return createStore(
        reducers,
        compose(applyMiddleware(reduxLogger, scrollMiddleware, sagaMiddleware, routerMiddleware))
    );
};

export { configureStore, sagaMiddleware, history };
