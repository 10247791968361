import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Button, Grid } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { push } from 'react-router-redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import RouteManagerFactory from '../../pitch4_routing/src/RouteManagerFactory';

const routeManagerFactory = new RouteManagerFactory();
const routeManager = routeManagerFactory.create();

class LEEmptyCard extends React.Component {
    render() {
        const { url, label, redirect } = this.props;

        return (
            <Grid item lg={4} md={4} xs={12} sm={12}>
                <Paper elevation={0} className={'empty-le'}>
                    <Button onClick={() => redirect(routeManager.route(url))}>
                        {label} <AddCircleIcon style={{ marginLeft: 10, color: '#2ec4b6' }} />
                    </Button>
                </Paper>
            </Grid>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LEEmptyCard);
