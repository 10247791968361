import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import DashboardContainer from '../../dashboard/containers/DashboardContainer';
import AdministerLendersContainer from '../../administer_lenders/containers/TableStyleContainer';
import CreateLenderContainer from '../../administer_lenders_createlender/containers/CreateContainer';
import EditLenderContainer from '../../administer_lenders_editlender/containers/EditContainer';
import AdministerUsersContainer from '../../administer_users/list/containers/ListContainer';
import CreateUserContainer from '../../administer_users/create/containers/CreateContainer';
import EditUserContainer from '../../administer_users/edit/containers/EditContainer';
import ConfigureProfileContainer from '../../../../user/configure_profile/containers/ConfigureProfileContainer';
import AdministerUserLendersContainer from '../../administer_user_lenders/containers/TableStyleContainer';
import AdministerUserLendersCreateContainer from '../../administer_user_lenders_create/containers/CreateUserLenderFormContainer';
import AdministerLoanEnquiriesContainer from '../../administer_loanenquiries/containers/TableStyleContainer';
import AdministerLoanEnquiryContainer from '../../administer_loanenquiries_viewloanenquiry/containers/MasonryStyleContainer';
import AdministerLendersDocumentsContainer from '../../administer_lenders_documents/containers/TableStyleContainer';
import AdministerBorrowersDocumentsContainer from '../../administer_borrowers_documents/containers/TableStyleContainer';
import AdministerLenderLoanEnquiryContainer from '../../administer_lenders_loanenquiries_view/containers/MasonryStyleContainer';
import ViewEventsContainer from '../../view_events/containers/TableStyleContainer';
import TwoFactorAuthIndexContainer from '../../../../user/two_factor_auth/containers/IndexContainer';
import BrokersListContainer from '../../administer_brokers/containers/TableStyleContainer';
import NetworkBrokersListContainer from '../../administer_brokers/containers/NetworkBrokersTableStyleContainer';
import TagsListContainer from '../../tags/TableStyleContainer';

import {
    ROUTE_P4A_2FA_INDEX,
    ROUTE_P4A_ADMINISTER_LENDERS_CREATE,
    ROUTE_P4A_ADMINISTER_LENDERS_EDIT,
    ROUTE_P4A_ADMINISTER_LENDERS_LIST,
    ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW,
    ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW,
    ROUTE_P4A_ADMINISTER_USER_LENDERS_CREATE,
    ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST,
    ROUTE_P4A_ADMINISTER_USERS_CREATE,
    ROUTE_P4A_ADMINISTER_USERS_EDIT,
    ROUTE_P4A_ADMINISTER_USERS_LIST,
    ROUTE_P4A_BORROWERS_DOCUMENTS_VIEW,
    ROUTE_P4A_BROKERS_LIST,
    ROUTE_P4A_CONFIGURE_PROFILE,
    ROUTE_P4A_DASHBOARD,
    ROUTE_P4A_LENDERS_DOCUMENTS_VIEW,
    ROUTE_P4A_LLE,
    ROUTE_P4A_NETWORK_BROKERS_LIST,
    ROUTE_P4A_TAGS_LIST,
    ROUTE_P4A_VIEW_EVENTS,
} from '../../../../../pitch4_routing';

class ContentContainer extends React.Component {
    render() {
        return (
            <div id="content">
                <Switch location={this.props.router.location}>
                    <Route path={ROUTE_P4A_LLE} render={() => <AdministerLenderLoanEnquiryContainer />} />
                    <Route
                        path={ROUTE_P4A_LENDERS_DOCUMENTS_VIEW}
                        render={() => <AdministerLendersDocumentsContainer />}
                    />
                    <Route
                        path={ROUTE_P4A_BORROWERS_DOCUMENTS_VIEW}
                        render={() => <AdministerBorrowersDocumentsContainer />}
                    />
                    <Route path={ROUTE_P4A_ADMINISTER_LENDERS_CREATE} render={() => <CreateLenderContainer />} />
                    <Route
                        path={ROUTE_P4A_ADMINISTER_USER_LENDERS_CREATE}
                        render={() => <AdministerUserLendersCreateContainer />}
                    />
                    <Route
                        path={ROUTE_P4A_ADMINISTER_USER_LENDERS_LIST}
                        render={() => <AdministerUserLendersContainer />}
                    />
                    <Route path={ROUTE_P4A_ADMINISTER_USERS_EDIT} render={() => <EditUserContainer />} />
                    <Route path={ROUTE_P4A_ADMINISTER_USERS_CREATE} render={() => <CreateUserContainer />} />
                    <Route path={ROUTE_P4A_ADMINISTER_USERS_LIST} render={() => <AdministerUsersContainer />} />
                    <Route
                        path={ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW}
                        render={() => <AdministerLoanEnquiryContainer />}
                    />
                    <Route
                        path={ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW}
                        render={() => <AdministerLoanEnquiriesContainer />}
                    />
                    {/*<Route path={ROUTE_BC_ROOT} render={() => <DashboardContainer />} />*/}

                    <Route
                        path={ROUTE_P4A_CONFIGURE_PROFILE}
                        render={() => <ConfigureProfileContainer showJobTitle={true} />}
                    />
                    <Route path={ROUTE_P4A_2FA_INDEX} render={() => <TwoFactorAuthIndexContainer />} />
                    <Route path={ROUTE_P4A_ADMINISTER_LENDERS_EDIT} render={() => <EditLenderContainer />} />
                    <Route path={ROUTE_P4A_ADMINISTER_LENDERS_CREATE} render={() => <CreateLenderContainer />} />

                    <Route path={ROUTE_P4A_ADMINISTER_LENDERS_LIST} render={() => <AdministerLendersContainer />} />

                    <Route path={ROUTE_P4A_BROKERS_LIST} render={() => <BrokersListContainer />} />
                    <Route path={ROUTE_P4A_NETWORK_BROKERS_LIST} render={() => <NetworkBrokersListContainer />} />

                    <Route path={ROUTE_P4A_ADMINISTER_USERS_EDIT} render={() => <EditUserContainer />} />
                    <Route path={ROUTE_P4A_ADMINISTER_USERS_CREATE} render={() => <CreateUserContainer />} />
                    <Route path={ROUTE_P4A_ADMINISTER_USERS_LIST} render={() => <AdministerUsersContainer />} />

                    <Route
                        path={ROUTE_P4A_ADMINISTER_LOANENQUIRIES_VIEW}
                        render={() => <AdministerLoanEnquiriesContainer />}
                    />
                    <Route
                        path={ROUTE_P4A_ADMINISTER_LOANENQUIRY_VIEW}
                        render={() => <AdministerLoanEnquiryContainer />}
                    />

                    <Route path={ROUTE_P4A_VIEW_EVENTS} render={() => <ViewEventsContainer />} />

                    <Route path={ROUTE_P4A_TAGS_LIST} render={() => <TagsListContainer />} />

                    {/* Keep it as last route */}
                    <Route path={ROUTE_P4A_DASHBOARD} render={() => <DashboardContainer />} />
                </Switch>
            </div>
        );
    }
}

ContentContainer.propTypes = {};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ContentContainer);
