import React from 'react';
import { Link } from 'pitch4_layout/components/href';
import PropTypes from 'prop-types';
import { goBack } from 'react-router-redux';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import Button from './PrimaryButton';
import { withStyles } from '@material-ui/core';

const styles = (theme) => ({
    button: {
        margin: theme.spacing(1),
        backgroundColor: 'rgb(185,21,231)',
    },
    input: {
        display: 'none',
    },
});

class GoBackButton extends React.Component {
    render() {
        let { classes, className, goBack, children, inputRef = () => null, ...other } = this.props;

        return (
            <Link onClickIn={goBack}>
                <Button
                    ref={inputRef}
                    variant="contained"
                    color="primary"
                    className={className + ` ` + classes.button}
                    {...other}
                >
                    {children}
                </Button>
            </Link>
        );
    }
}

GoBackButton.propTypes = {
    redirect: PropTypes.func,
    classes: PropTypes.any,
    className: PropTypes.any,
    children: PropTypes.any,
    inputRef: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        goBack: () => dispatch(goBack()),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(GoBackButton);
