import React from 'react';
import gql from 'graphql-tag';
import { withRouter } from 'react-router-dom';
import AddToContextStoreForm from '../../documents_widget/components/AddToContextStoreForm';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import { ADMIN_URL } from '../../../../../bootstrap/constants/constants';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import {
    sagaActionModalHide,
    sagaActionModalInteractive,
    sagaActionModalShow,
} from '../../../../modal/sagas/container';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import { connect } from 'react-redux';
import ModalLayout from '../../../../../pitch4_modal/src/component/ModalLayout';

class AdminCaseMgmtUploadDocumentButton extends React.Component {
    openAddDocumentModal = () => {
        let {
            setModalClose,
            setModalComponent,
            setModalInteractive,
            successNotification,
            errorNotification,
            loanEnquiryId = null,
            createMetaMutate,
            outerRefetch,
            createPermissionMutate,
        } = this.props;

        setModalComponent(
            <ModalLayout title="Add Document">
                <AddToContextStoreForm
                    id={loanEnquiryId}
                    onPending={() => setModalInteractive(false)}
                    onSuccess={(message) => {
                        successNotification(message);
                        outerRefetch();
                        setModalClose();
                    }}
                    onError={(error) => {
                        errorNotification(error);
                        setModalInteractive(true);
                    }}
                    createMetaMutate={createMetaMutate}
                    createPermissionMutate={createPermissionMutate}
                    contextDocsQuery={gql`
                        query getDocuments($count: Int!, $filters: [FilterInput]) {
                            documents(first: $count, filters: $filters) {
                                edges {
                                    node {
                                        id
                                        fileName
                                        type
                                    }
                                    cursor
                                }
                                pageInfo {
                                    hasNextPage
                                    endCursor
                                    startCursor
                                    hasPreviousPage
                                }
                                totalCount
                            }
                        }
                    `}
                />
            </ModalLayout>
        );
    };

    render() {
        let { buttonRender } = this.props;
        return buttonRender(() => this.openAddDocumentModal());
    }
}

const CreateLoanEnquiryDocumentMutation = gql`
    mutation CreateLoanEnquiryDocument($input: pitch4admin_createDocumentInput!) {
        createLoanEnquiryDocument(input: $input) {
            document {
                id
                uploadUrl
            }
        }
    }
`;

const CreateLoanEnquiryDocumentPermissionMutation = gql`
    mutation createDocumentPermission($id: ID!, $input: pitch4admin_createDocumentPermissionInput!) {
        createLoanEnquiryDocumentPermission(id: $id, input: $input) {
            documentPermission {
                id
            }
        }
    }
`;

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        successNotification: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        errorNotification: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    graphql(CreateLoanEnquiryDocumentMutation, {
        options() {
            return {
                context: {
                    uri: ADMIN_URL,
                },
            };
        },
        name: 'createMetaMutate',
    }),
    graphql(CreateLoanEnquiryDocumentPermissionMutation, {
        options() {
            return {
                context: {
                    uri: ADMIN_URL,
                },
            };
        },
        name: 'createPermissionMutate',
    }),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(AdminCaseMgmtUploadDocumentButton);
