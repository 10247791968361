import React, { useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import moment from 'moment';
import { setLEChaseData } from '../../../../../pitch4_api/set_le_chase_data';
import { API_URL } from '../../../../../bootstrap/constants/constants';

export default function ChaseDataModal(props) {
    const { setModalClose, loanEnquiryId, chaseDate, chaseNote, handleLEChaseDateChange } = props;
    const [cd, setCD] = useState(chaseDate);
    const [cn, setCN] = useState(chaseNote || '');
    const [submitting, setSubmitting] = useState(false);

    const handleDateChange = (date) => {
        setCD(moment(date).toISOString());
    };

    const handleSubmit = () => {
        setSubmitting(true);
        setLEChaseData(API_URL, loanEnquiryId, cd, cn).then(() => {
            handleLEChaseDateChange(loanEnquiryId, cd, cn);
            setSubmitting(false);
            setModalClose();
        });
    };

    return (
        <div className={'admin-chase-details-modal-container'}>
            <div className={'header'}>Update Chase Details</div>
            <div>
                <KeyboardDatePicker
                    margin="normal"
                    id="chase-date"
                    format="D MMMM yyyy"
                    value={cd}
                    onChange={handleDateChange}
                    inputVariant="outlined"
                    className={'chase-date-picker'}
                    label="Chase date"
                    minDate={moment()}
                />
            </div>
            <div>
                <TextField
                    id="chase-note"
                    variant="outlined"
                    className={'chase-note-input'}
                    onChange={(event) => setCN(event.target.value || '')}
                    value={cn}
                    rows={4}
                    multiline
                    label="Chase note"
                />
            </div>
            <div className={'actions'}>
                <Button variant={'contained'} onClick={() => setModalClose()} className={'btn-back'}>
                    BACK
                </Button>
                <Button variant={'contained'} color={'primary'} onClick={() => handleSubmit()} disabled={submitting}>
                    SAVE
                </Button>
            </div>
        </div>
    );
}
