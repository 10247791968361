// Value in pence
export const formatter = (value = 0) =>
    new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
    }).format(value / 100);

export const formatterInPounds = (value = 0) =>
    new Intl.NumberFormat('en-UK', {
        style: 'currency',
        currency: 'GBP',
        minimumFractionDigits: 0,
    }).format(value);
