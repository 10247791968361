export const NAMESPACE = 'admin.view_le.main';
export const INITIATE = `${NAMESPACE}.initiate`;
export const SET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.set_matching_criteria_config`;
export const SET_API_GET_LOAN_ENQUIRY_RESPONSE = `${NAMESPACE}.set_api_get_loan_enquiry_response`;
export const SET_API_GET_LOAN_ENQUIRY_MATCHES_RESPONSE = `${NAMESPACE}.set_api_get_loan_enquiry_matches_response`;
export const STOP_POLLING_MATCHES = `${NAMESPACE}.stop_polling_matches`;
export const RELOAD_LOAN_ENQUIRY = `${NAMESPACE}.reload_loan_enquiry`;
export const CLEANUP = `${NAMESPACE}.cleanup`;
export const SET_MATCH_STATUSES = `${NAMESPACE}.set_match_statuses`;
export const SEND_MESSAGE_TO_LENDERS = `${NAMESPACE}.send_message_to_lenders`;
export const API_SET_STATUS = `${NAMESPACE}.api_set_status`;
export const SET_STATUS = `${NAMESPACE}.set_status`;
export const REMOVE_LOAN_ENQUIRY_ALERT = `${NAMESPACE}.remove_loan_enquiry_alert`;

const initialState = {
    config: {},
    loanEnquiryId: null,
    loanEnquiry: null,
    loanEnquiryMatches: null,
    pollingMatches: true,
    status: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATCHING_CRITERIA_CONFIG:
            const config = action.config;

            return {
                ...state,
                config,
            };

        case INITIATE:
            const loanEnquiryId = action.loanEnquiryId;

            return {
                ...initialState,
                loanEnquiryId,
            };

        case SET_API_GET_LOAN_ENQUIRY_RESPONSE:
            const { loanEnquiry } = action;

            return {
                ...state,
                loanEnquiry,
            };

        case API_SET_STATUS:
            return {
                ...state,
                submitted: true,
            };

        case SET_STATUS:
            const { status } = action;
            return {
                ...state,
                status,
                submitted: false,
            };

        case SET_API_GET_LOAN_ENQUIRY_MATCHES_RESPONSE:
            const { loanEnquiryMatch } = action;

            return {
                ...state,
                loanEnquiryMatch,
            };
        case REMOVE_LOAN_ENQUIRY_ALERT:
            const { leaId } = action.loanEnquiryId;
            return {
                ...state,
                leaId,
            };
        case STOP_POLLING_MATCHES:
        case CLEANUP:
            return {
                ...state,
                pollingMatches: false,
            };
        case SEND_MESSAGE_TO_LENDERS:
            const { lenders, message } = action;
            return {
                ...state,
                lenders,
                message,
            };

        default:
            return {
                ...state,
            };
    }
};

export const actionApiSetStatus = (loanEnquiryId, status, reason) => {
    return {
        type: API_SET_STATUS,
        loanEnquiryId,
        status,
        reason,
    };
};

export const actionInitiate = (loanEnquiryId) => ({
    type: INITIATE,
    loanEnquiryId,
});

export const actionSetMatchingCriteriaConfig = (config) => ({
    type: SET_MATCHING_CRITERIA_CONFIG,
    config,
});

export const actionSetApiGetLoanEnquiryResponse = (response) => ({
    type: SET_API_GET_LOAN_ENQUIRY_RESPONSE,
    ...response,
});

export const actionSetApiGetLoanEnquiryMatchesResponse = (response) => ({
    type: SET_API_GET_LOAN_ENQUIRY_MATCHES_RESPONSE,
    ...response,
});

export const actionStopPollingMatches = (polling) => ({
    type: STOP_POLLING_MATCHES,
});

export const actionReloadLoanEnquiry = () => ({
    type: RELOAD_LOAN_ENQUIRY,
});

export const actionSetMatchStatuses = (statuses) => ({
    type: SET_MATCH_STATUSES,
    statuses,
});

export const actionCleanup = () => ({
    type: CLEANUP,
});

export const actionSetStatus = (status) => ({
    type: SET_STATUS,
    status,
});

export const actionSendMessageToLenders = (lenders, message, toggleMessageDrawer, setMessageSent) => {
    return {
        type: SEND_MESSAGE_TO_LENDERS,
        lenders,
        message,
        toggleMessageDrawer,
        setMessageSent,
    };
};

export const selectorGetStatus = (state) => {
    return state[NAMESPACE].status;
};

export const selectorGetMatchingCriteriaConfig = (state) => {
    return state[NAMESPACE].config;
};

export const selectorGetLoanEnquiry = (state) => {
    return state[NAMESPACE].loanEnquiry;
};

export const selectorGetLoanEnquiryMatch = (state) => {
    return state[NAMESPACE].loanEnquiryMatch;
};

export const actionRemoveLoanEnquiryAlert = (loanEnquiryId) => {
    return {
        type: REMOVE_LOAN_ENQUIRY_ALERT,
        loanEnquiryId,
    };
};

//Default export reducer
export default reducer;
