import React from 'react';
import { Button, Grid } from '@material-ui/core';

export default function AdminNoteDeleteModal(props) {
    const { deleteAdminNote, setModalClose, note } = props;

    return (
        <Grid container style={{}}>
            <Grid item>
                <div style={{ marginTop: 40, wordBreak: 'break-word' }}>
                    Are you sure you want to delete Admin Note?
                    <br />
                    <strong>"{note.adminNoteTitle}"</strong>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                    <Button
                        style={{ color: '#2ec4b6' }}
                        color={'red'}
                        onClick={() => {
                            setModalClose();
                            deleteAdminNote(note)();
                        }}
                    >
                        Delete
                    </Button>
                    <Button
                        onClick={() => {
                            setModalClose();
                        }}
                    >
                        Close
                    </Button>
                </div>
            </Grid>
        </Grid>
    );
}
