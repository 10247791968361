import React from 'react';
import { sagaActionRedirectToDashboard } from '../../authentication/sagas/dashboard';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { verifyEmail } from '../api/verify_email';
import _ from 'lodash';
import { sagaActionNotificationSetError, sagaActionNotificationSetSuccess } from '../../notifications/sagas/container';
import { Item } from 'pitchblack_react_utils/layout';
import Centered from 'pitch4_layout/components/div/Centered';
import IconFactory, { SPINNER_ICON } from 'pitchblack_react_utils/icons/IconFactory';
import SessionManager from 'pitchblack_react_utils/session/SessionManager';

let sm = new SessionManager();

class VerifyEmailContainer extends React.Component {
    async componentDidMount() {
        const { dashboardRedirect, match } = this.props;
        const { notificationSuccess, notificationError } = this.props;
        const response = await verifyEmail(match.params.token);
        if (_.get(response, ['data', 'verifyEmail']) !== undefined && response.data.verifyEmail.success === true) {
            if (sm.isAuthenticated()) {
                sm.setIsVerified(true);
            }
            notificationSuccess('Email verified');
            dashboardRedirect();
            return;
        }
        notificationError('Failed to verify email');
        dashboardRedirect();
    }

    render() {
        const ic = new IconFactory();
        return (
            <React.Fragment>
                <Item className={'content-container'}>
                    <Centered>
                        <p>Please wait while we verify your email address...</p>
                    </Centered>

                    <Centered className={'spaced-spinner'}>{ic.createIcon(SPINNER_ICON)}</Centered>
                </Item>
            </React.Fragment>
        );
    }
}

VerifyEmailContainer.propTypes = {};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dashboardRedirect: () => dispatch(sagaActionRedirectToDashboard()),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(VerifyEmailContainer);
