import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { ROUTE_ANON_LOGIN } from '../../../pitch4_routing';
import NetworkBrokerEnum from '../../../pitch4_enum/enum/NetworkBrokerEnum';

export default function SecureRouteContainer(props) {
    const sessionManager = new SessionManager();
    const isAuthenticated = sessionManager.isAuthenticated();

    if (isAuthenticated) {
        const { children } = props;
        return children;
    }

    const href = window.location.href;
    const arr = new URL(href);
    const networkBroker = arr.searchParams.get('nb');
    let loginPath = ROUTE_ANON_LOGIN;

    if (networkBroker) {
        const nbe = new NetworkBrokerEnum();
        loginPath = nbe.getLoginPath(networkBroker);
    }

    window.location.replace(loginPath);

    return null;
}
