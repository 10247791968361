import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { destroy, formValueSelector, getFormValues, reduxForm } from 'redux-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
    actionGetConfig,
    actionNextPage,
    actionRemoveLastForm,
    actionRemoveTo,
    actionReset,
    actionResetFields,
    actionSetConfig,
    actionSetCurrentForm,
    actionSetFieldValueSelector,
    actionSetInitialValues,
    actionSkipToSection,
    selectorGetConfigEnumValues,
    selectorGetConfigVersion,
    selectorGetCurrentForm,
    selectorGetDeterminedSections,
    selectorGetFieldValueSelector,
    selectorGetFormHeadings,
    selectorGetFormStack,
    selectorGetFormSubmitting,
    selectorGetStepsMap,
} from '../redux';
import { Loading } from '../../../pitch4_layout/components/waiting';
import WizardController from '../components/WizardController';
import { normalizeInitialValues } from '../utils/initialValues';
import { scrollTo } from '../../utils/scrollTo';
import { selectorGetLoanUsageInUk } from '../../loan_usage_in_uk/redux';

class FormWizardContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            PreparedWizardController: null,
        };
    }

    componentDidMount() {
        this.prepareControllerComponent();
    }

    render() {
        const { config = {}, initialValues = {}, isLoanUsageInUk } = this.props;
        const { PreparedWizardController = null } = this.state;

        if (_.isEmpty(config) || PreparedWizardController === null) {
            return <Loading />;
        }

        return (
            <PreparedWizardController
                {...this.props}
                initialValues={normalizeInitialValues(config, initialValues)}
                isLoanUsageInUk={isLoanUsageInUk}
            />
        );
    }

    /**
     * Create a configured WizardController, connected to a redux store and redux form.
     */
    prepareControllerComponent = () => {
        const { dispatch, formName, reduxNamespace, reduxFormProps = {} } = this.props;

        const selector = formValueSelector(formName);

        const mapStateToProps = (state) => {
            return {
                formStack: selectorGetFormStack(reduxNamespace)(state),
                formValues: getFormValues(formName)(state),
                currentForm: selectorGetCurrentForm(reduxNamespace)(state),
                formSubmitting: selectorGetFormSubmitting(reduxNamespace)(state),
                fieldValueSelector: selectorGetFieldValueSelector(reduxNamespace)(state),
                configFormVersion: selectorGetConfigVersion(reduxNamespace)(state),
                getFormValue: (section, field) => selector(state, [section, field].join('.')),
                getConfigEnumValues: (enumName) => selectorGetConfigEnumValues(reduxNamespace)(state, enumName),
                formHeadings: selectorGetFormHeadings(reduxNamespace)(state),
                determinedSections: selectorGetDeterminedSections(reduxNamespace)(state),
                stepsMap: selectorGetStepsMap(reduxNamespace)(state),
                isLoanUsageInUk: selectorGetLoanUsageInUk(state),
            };
        };

        const mapDispatchToProps = (dispatch) => {
            return {
                goBack: () => dispatch(actionRemoveLastForm(reduxNamespace)()),
                goBackTo: (form) => dispatch(actionRemoveTo(reduxNamespace)(form)),
                addForm: (form) => dispatch(actionSetCurrentForm(reduxNamespace)(form)),
                skipTo: (form) => dispatch(actionSkipToSection(reduxNamespace)(form)),
                nextPage: (values) => dispatch(actionNextPage(reduxNamespace)(values)),
                resetFields: (section, fieldNames) => {
                    dispatch(actionResetFields(reduxNamespace)(formName, section, fieldNames));
                },
                getConfig: () => dispatch(actionGetConfig(reduxNamespace)()),
                setConfig: (config) => dispatch(actionSetConfig(reduxNamespace)(config)),
                resetForm: () => {
                    dispatch(actionReset(reduxNamespace)());
                    dispatch(destroy(formName));
                },
                setFieldValueSelector: (fieldValueSelector) =>
                    dispatch(actionSetFieldValueSelector(reduxNamespace)(fieldValueSelector)),
                setInitialValues: (initialValues) => dispatch(actionSetInitialValues(reduxNamespace)(initialValues)),
            };
        };

        // Set the given state overrides
        this.setInitialStateOverrides(mapDispatchToProps(dispatch));

        // Connect the WizardController to the redux store
        const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

        // Add the redux form
        const PreparedWizardController = compose(
            reduxConnector,
            reduxForm({
                form: formName,
                destroyOnUnmount: false,
                forceUnregisterOnUnmount: true,
                onSubmitFail: this.scrollToFirstError,
                ...reduxFormProps,
            })
        )(WizardController);

        this.setState({ PreparedWizardController: PreparedWizardController });
    };

    scrollToFirstError = (errors) => {
        const formName = Object.keys(errors)[0];
        const fieldName = Object.keys(errors[formName])[0];

        let el = document.querySelector(`[name="${fieldName}"]`);
        if (!el) {
            el = document.querySelector(`[name="${formName}.${fieldName}"]`);
        }
        if (el) {
            const position = el.getBoundingClientRect().top + document.documentElement.scrollTop;
            scrollTo(position, null, 500);
        }
    };

    setInitialStateOverrides = (dispatchers) => {
        const { config, fieldValueSelector = null } = this.props;

        dispatchers['setConfig'](config);

        if (fieldValueSelector !== null) {
            dispatchers['setFieldValueSelector'](fieldValueSelector);
        }
    };
}

FormWizardContainer.propTypes = {
    /**
     * The config object - see api/config/matching_criteria/xxxxxxxx/borrower.json for an example.
     */
    config: PropTypes.object.isRequired,

    /**
     * Form name as passed to redux form.
     */
    formName: PropTypes.string.isRequired,

    /**
     * Namespace for redux to avoid clashes.
     */
    reduxNamespace: PropTypes.string.isRequired,

    /**
     * The name of the field in the very first section that determines which steps to display in the Stepper.
     *
     * All possible values for this field should be equal to one of the section categories in the config file.
     */
    stepsTriggerField: PropTypes.string.isRequired,

    /**
     * Custom function handling the submit to API. Receives the processed form values.
     *
     * @example
     *
     * submitFormHandler = function(prunedValues) {
     *     const { dispatch } = this.props;
     *
     *     const anyOtherArgs = '...';
     *
     *     dispatch(submitToApiAction(prunedValues, anyOtherArgs));
     * };
     */
    submitFormHandler: PropTypes.func.isRequired,

    /**
     * The component used to display the summary of data in the submit section.
     *
     * This component will receive the following props:
     *
     * - 'submissionData': Object. The pruned form submission.
     *
     * - 'headings': Object. The section headings key by section name.
     *
     * - All expanded 'submitSummaryProps' props.
     *
     * - All props given to the WizardContainer component (see prepareControllerComponent() method above).
     */
    submitSummaryComponent: PropTypes.func.isRequired,

    /**
     * Provide a pre submit handler to process the submission values prior to sending to the API.
     *
     * Receives:
     *
     * - 'values': All form values as produced by redux-form getFormValues()
     */
    preSubmitHandler: PropTypes.func.isRequired,

    /**
     * (optional)
     *
     * Additional props to send to the submit summary component.
     */
    submitSummaryProps: PropTypes.object,

    /**
     * (optional)
     *
     * Additional props to pass to the reduxForm() call.
     */
    reduxFormProps: PropTypes.object,

    /**
     * Dictate to the form wizard what form component to be rendered for a given section
     *
     * @see src/frontend/src/apps/form_wizard/components/GenericFormSection.js for a starting point
     */
    formSectionRender: PropTypes.func.isRequired,

    /**
     * (optional)
     *
     * A function that accepts the value of the field and returns the desired value.
     *
     * Usually you shouldn't need to set this. But for example with SLAM business streams the actual value is under an 'operand' property so
     * we use this option to pull the operand out of the field object.
     */
    fieldValueSelector: PropTypes.func,
    draftSubmitHandler: PropTypes.func,
    deleteHandler: PropTypes.func,
};

export default connect()(FormWizardContainer);
