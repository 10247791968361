import React from 'react';
import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import InputFile from '../../../pitch4_documents/src/components/InputFile';

class Upload extends React.Component {
    render() {
        let { name = '', label = '', fullWidth = false, wrapperStyle = {}, style = {}, ...rest } = this.props;

        return (
            <div style={wrapperStyle}>
                <Field
                    type={`file`}
                    name={name}
                    component={InputFile}
                    label={label}
                    fullWidth={fullWidth}
                    style={style}
                    {...rest}
                />
            </div>
        );
    }
}

Upload.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    fullWidth: PropTypes.bool,
    style: PropTypes.object,
    wrapperStyle: PropTypes.object,
};

export default Upload;
