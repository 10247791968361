import React from 'react';
import { Button } from '@material-ui/core';
import LenderLoanEnquiryStatusEnum from '../../../../../../pitch4_enum/enum/LenderLoanEnquiryStatusEnum';

export default function ChangeLLEStatusPopup(props) {
    const { newStatus, handleStatusChange, handleCancelStatusChange, currentStatus, leType } = props;
    const LLEStatusEnum = new LenderLoanEnquiryStatusEnum();

    return (
        <div className={'change-lle-status-popup-container'}>
            <div className={'header'}>
                Are you sure you want to move this from
                <br /> {LLEStatusEnum.getType(currentStatus, leType)} to {LLEStatusEnum.getType(newStatus, leType)}?
            </div>
            <div className={'actions'}>
                <Button className={'btn-cancel'} onClick={() => handleCancelStatusChange()}>
                    No, I'm not sure
                </Button>
                <Button
                    className={'btn-yes'}
                    color={'primary'}
                    variant={'contained'}
                    onClick={() => handleStatusChange(newStatus)}
                >
                    Yes, move to {LLEStatusEnum.getType(newStatus, leType)}
                </Button>
            </div>
        </div>
    );
}
