import { API_URL } from '../../../../../bootstrap/constants/constants';
import createP4ApolloFetch from '../../../../../bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const query = `
    mutation createBroker($input: pitch4admin_createBrokerInput!) {
        createBroker(input: $input) {
            broker {
                id
                name
            }
        }
    }
`;

export const createBroker = (values) => {
    return apolloFetch({
        query: query,
        variables: {
            input: values,
        },
    })
        .then(({ data, errors }) => {
            return data;
        })
        .catch(() => {
            return [];
        });
};
