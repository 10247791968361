export const SPV = 'SPV';
export const TRADING_COMPANY = 'TRADING_COMPANY';
export const LLP = 'LLP';
export const TRUST = 'TRUST';
export const OFFSHORE = 'OFFSHORE';
export const OVERSEAS = 'OVERSEAS';
export const PERSONAL_NAME = 'PERSONAL_NAME';
export const UK_LIMITED = 'UK_LIMITED';

class Ownership {
    getTypes = () => {
        return [
            { value: SPV, label: 'Limited Company (SPV)' },
            { value: TRADING_COMPANY, label: 'Limited company (Trading Company)' },
            { value: LLP, label: 'LLP' },
            { value: TRUST, label: 'Trust' },
            { value: OFFSHORE, label: 'Offshore Company' },
            { value: OVERSEAS, label: 'Overseas Company' },
            { value: PERSONAL_NAME, label: 'Personal Name' },
        ];
    };

    getOptions = () => {
        return {
            [SPV]: { value: SPV, label: 'Limited Company (SPV)' },
            [TRADING_COMPANY]: { value: TRADING_COMPANY, label: 'Limited company (Trading Company)' },
            [LLP]: { value: LLP, label: 'LLP' },
            [TRUST]: { value: TRUST, label: 'Trust' },
            [OFFSHORE]: { value: OFFSHORE, label: 'Offshore Company' },
            [OVERSEAS]: { value: OVERSEAS, label: 'Overseas Company' },
            [PERSONAL_NAME]: { value: PERSONAL_NAME, label: 'Personal Name' },
        };
    };
}

export default Ownership;
