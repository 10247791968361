import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import { PrimaryButton } from '../../../../../pitch4_layout/components/buttons';
import { CenteringContainer } from '../../../../../pitchblack_react_utils/layout/index';
import { NoQueryMutation } from '../../../../../pitch4_forms/components';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import ModalLayout from '../../../../../pitch4_modal/src/component/ModalLayout';
import Item from '../../../../../pitchblack_react_utils/layout/Item';

class DeleteDocumentButton extends React.Component {
    render() {
        let { children, inputRef = () => null, type = 'primary' } = this.props;

        if (type === 'icon') {
            return <div onClick={this.openConfirmationModal}>{children}</div>;
        }

        return (
            <PrimaryButton inputRef={inputRef} onClick={this.openConfirmationModal}>
                {children}
            </PrimaryButton>
        );
    }

    handleSubmit = (document, mutate, adminCaseManagement) => {
        let { onSuccess, onError, onPending } = this.props;

        onPending();

        //map the variables if necessary
        return mutate({
            variables: {
                id: document.id,
                acm: adminCaseManagement === true,
            },
            context: {
                uri: INTERMEDIARY_URL,
            },
        })
            .then(({ data }) => {
                onSuccess(data);
            })
            .catch((error) => {
                onError(error);
            });
    };

    openConfirmationModal = () => {
        let { setModalComponent, document, adminCaseManagement } = this.props;

        setModalComponent(
            <ModalLayout title="Delete Document">
                <NoQueryMutation
                    mutationQuery={gql`
                        mutation deleteDocument($id: ID!, $acm: Boolean!) {
                            deleteDocument(id: $id, acm: $acm) {
                                deleted
                            }
                        }
                    `}
                    renderFormFunction={(mutate, props) => {
                        return (
                            <CenteringContainer>
                                <Item>
                                    <p className={'body1 spaced-text'}>
                                        This document will not available to all other users that you have shared this
                                        document with. Are you sure that you want to permanently remove this file?
                                    </p>
                                </Item>
                                <Item className={'button-panel'}>
                                    <PrimaryButton
                                        type="submit"
                                        onClick={() => this.handleSubmit(document, mutate, adminCaseManagement)}
                                    >
                                        Yes I am sure
                                    </PrimaryButton>
                                </Item>
                            </CenteringContainer>
                        );
                    }}
                />
            </ModalLayout>
        );
    };
}

DeleteDocumentButton.propTypes = {
    children: PropTypes.any,
    setModalComponent: PropTypes.func.isRequired,
    document: PropTypes.object.isRequired,
    onPending: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    inputRef: PropTypes.any,
};

export default DeleteDocumentButton;
