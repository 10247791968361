import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actionLeftSideMenuItemSelected } from '../redux/leftsidemenu';
import { Avatar, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import IconFactory from 'pitchblack_react_utils/icons/IconFactory';
import styled from 'styled-components';

const iconFactory = new IconFactory();
const StyledAvatar = styled(Avatar)`
    padding-top: 0px;
    color: rgb(176, 21, 211);
`;

class MenuItem extends React.Component {
    handleMenuItemClick = () => {
        const { dispatch, url } = this.props;

        dispatch(actionLeftSideMenuItemSelected(url));
    };

    render() {
        const { id, label, icon, className } = this.props;
        let text = '';
        if (label !== '' && label !== undefined && label !== null) {
            text = <ListItemText disableTypography primary={label} />;
        }

        let listIcon = '';
        if (icon !== '' && icon !== undefined && icon !== null) {
            listIcon = (
                <ListItemIcon>
                    <StyledAvatar>{iconFactory.createIcon(icon)}</StyledAvatar>
                </ListItemIcon>
            );
        }

        return (
            <ListItem button id={id} className={className} onClick={this.handleMenuItemClick}>
                {listIcon}
                {text}
            </ListItem>
        );
    }
}

MenuItem.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    icon: PropTypes.string,
};

export default connect()(MenuItem);
