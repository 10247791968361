import React from 'react';
import AppEnableForm from './AppEnableForm';
import { P4StyleComponentContainer } from 'pitch4_layout/components/div';
import { Body1 } from 'pitch4_layout/components/text';
import styled from 'styled-components';
import _ from 'lodash';
import { Loading } from 'pitch4_layout/components/waiting';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import PropTypes from 'prop-types';

const SummaryText = styled(Body1)`
    display: block;
    width: 100%;
    padding: 0 20px 20px;
    text-align: center;
`;

const StyledContainer = styled(P4StyleComponentContainer)`
    padding-bottom: 30px;
`;

const styles = {
    largeIcon: {
        color: 'rgb(206,0,234)',
        fontSize: 'xx-large',
        position: 'relative',
        top: '10px',
    },
};

class SettingsIndex extends React.Component {
    render() {
        const { twoFactorAuthDetails = {}, enableTwoFactorAuth, errors } = this.props;

        if (_.isEmpty(twoFactorAuthDetails)) {
            return <Loading />;
        }

        if (twoFactorAuthDetails['status'] === 'PENDING') {
            return (
                <AppEnableForm
                    enableTwoFactorAuth={enableTwoFactorAuth}
                    twoFactorAuthDetails={twoFactorAuthDetails}
                    errors={errors}
                />
            );
        }

        return (
            <StyledContainer
                header={`Two Factor Authentication Settings`}
                content={
                    <SummaryText>
                        <CheckCircleIcon style={styles.largeIcon} /> Two Factor Authentication is successfully enabled
                        for your account.
                    </SummaryText>
                }
            />
        );
    }
}

SettingsIndex.propTypes = {
    twoFactorAuthDetails: PropTypes.object.isRequired,
    errors: PropTypes.object,
    enableTwoFactorAuth: PropTypes.func.isRequired,
};

export default SettingsIndex;
