import { BaseEnum } from '../../../../../pitch4_enum';
export const TYPE_UK_CITIZEN = 'uk_citizen';
export const TYPE_FOREIGN_NAT_IN_UK_PERMANENT = 'foreign_nat_in_uk_permanent';
export const TYPE_FOREIGN_NAT_IN_UK_VISA = 'foreign_nat_in_uk_visa';
export const TYPE_FOREIGN_NAT_NOT_IN_UK = 'foreign_nat_not_in_uk';
export const TYPE_BRITISH_EXPAT = 'british_expat';

class UKCitizenshipEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_UK_CITIZEN]: 'UK Citizen',
            [TYPE_FOREIGN_NAT_IN_UK_PERMANENT]: 'Foreign National Living In The UK (Right to Reside)',
            [TYPE_FOREIGN_NAT_IN_UK_VISA]: 'Foreign National Living In The UK (VISA)',
            [TYPE_FOREIGN_NAT_NOT_IN_UK]: 'Foreign National',
            [TYPE_BRITISH_EXPAT]: 'British Expat',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default UKCitizenshipEnum;
