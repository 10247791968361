import React from 'react';
import { Container, Item } from '../../pitchblack_react_utils/layout/index';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import connect from 'react-redux/src/connect/connect';
import { reduxForm, reset } from 'redux-form';
import { sagaHandleNewChannelMessage } from '../sagas/chat';
import PrimaryButton from '../../pitch4_layout/components/buttons/PrimaryButton';
import { sagaActionNotificationSetError } from '../../apps/notifications/sagas/container';
import { isRequired } from '../../pitchblack_react_utils/form/validation';
import TextAreaAutosize from '../../pitch4_forms/components/inputs/TextAreaAutosize';
import { TAG_MANAGER_EVENT_PLATFORM_LENDER_SENT_CHAT_MESSAGE } from '../../bootstrap/constants/constants';
import SessionManager from '../../pitchblack_react_utils/session/SessionManager';

class ChatForm extends React.Component {
    onSubmit = (values, dispatch, props) => {
        let { sendMessageMutate, channelId, loanEnquiryId = null, session = null } = props;

        session = new SessionManager();

        let mappedValues = {
            message: values['chat_message'],
            channelId: channelId,
        };

        if (loanEnquiryId) {
            mappedValues['loanEnquiryId'] = loanEnquiryId;
        }

        return sendMessageMutate({
            variables: {
                input: mappedValues,
            },
        })
            .then(({ data }) => {
                dispatch(reset('submit_chat_message'));
                dispatch(sagaHandleNewChannelMessage(data.liveChatSendMessage.message));

                // Trigger GTM event
                window.dataLayer.push({
                    event: TAG_MANAGER_EVENT_PLATFORM_LENDER_SENT_CHAT_MESSAGE,
                    userId: session.getRelevantSession().getId(),
                    userRole: session.getMostSeniorRole(),
                    userEmail: session.getRelevantSession().getEmail(),
                });
            })
            .catch((e) => {
                dispatch(sagaActionNotificationSetError('Failed to send chat message'));
            });
    };

    render() {
        let { handleSubmit, submitting, disabled, invalid, buttonLabel = 'Send' } = this.props;

        return (
            <form onSubmit={handleSubmit(this.onSubmit)}>
                <Container className={'chat-form'}>
                    <Item xs={12} sm={8} lg={9}>
                        <TextAreaAutosize
                            name={`chat_message`}
                            placeholder={`Type here to chat`}
                            validate={[isRequired]}
                            disabled={disabled || submitting}
                        />
                    </Item>
                    <Item xs={false} sm={1} lg={1} />
                    <Item xs={12} sm={3} lg={2}>
                        <Container>
                            <PrimaryButton
                                className={'chat-submit-message'}
                                name={`submit_question_response`}
                                type="submit"
                                disabled={disabled || submitting || invalid}
                            >
                                {buttonLabel}
                            </PrimaryButton>
                        </Container>
                    </Item>
                </Container>
            </form>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

ChatForm.propTypes = {
    channelId: PropTypes.string.isRequired,
    sendMessageMutate: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string,
    disabled: PropTypes.bool,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'submit_chat_message',
    })
)(ChatForm);
