import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { ROUTE_BORROWER_ROOT, ROUTE_INTERMEDIARY_ROOT, ROUTE_P4A_ROOT, ROUTE_SLAM_ROOT } from 'pitch4_routing';
import {
    NETWORK_BROKER_ACCESS_FINANCIAL_SERVICES,
    NETWORK_BROKER_CONNECT,
    NETWORK_BROKER_GIFTWARE,
    NETWORK_BROKER_WARREN_FINANCE_LTD,
    NETWORK_BROKER_WEALTHMAX,
} from '../../../pitch4_enum/enum/NetworkBrokerEnum';

let sessionManager = new SessionManager();

export const SAGA_NAMESPACE = 'sagas.authenticated.dashboard';

//Saga Action Creators

export const SAGA_ACTION_REDIRECT_TO_DASHBOARD = `${SAGA_NAMESPACE}.SAGA_ACTION_REDIRECT_TO_DASHBOARD`;
export const sagaActionRedirectToDashboard = () => ({
    type: SAGA_ACTION_REDIRECT_TO_DASHBOARD,
});

//Worker Sagas

function* redirectToDashboard() {
    let session = sessionManager.getRelevantSession();
    if (session && session.getMostSeniorRole() && session.getMostSeniorRole().name) {
        switch (session.getMostSeniorRole().name) {
            case 'ROLE_SUPER_ADMIN':
                yield put(push(ROUTE_P4A_ROOT));
                break;
            case 'ROLE_INTERMEDIARY':
                yield put(push(ROUTE_INTERMEDIARY_ROOT));
                break;
            case 'ROLE_SLAM':
                yield put(push(ROUTE_SLAM_ROOT));
                break;
            case 'ROLE_BORROWER':
                yield put(push(ROUTE_BORROWER_ROOT));
                break;
            default:
                throw Error('Login failed - unsupported user role');
        }
    } else {
        const href = window.location.href;
        const arr = new URL(href);
        const networkBroker = arr.searchParams.get('nb');
        //Network Brokers array
        const networkBrokers = [
            NETWORK_BROKER_CONNECT,
            NETWORK_BROKER_WEALTHMAX,
            NETWORK_BROKER_WARREN_FINANCE_LTD,
            NETWORK_BROKER_ACCESS_FINANCIAL_SERVICES,
            NETWORK_BROKER_GIFTWARE,
        ];

        yield put(push(networkBrokers.includes(networkBroker) ? networkBroker : '/login'));
    }
}

//Main Saga
function* main() {
    yield takeLatest(SAGA_ACTION_REDIRECT_TO_DASHBOARD, redirectToDashboard);
}

export default main;
