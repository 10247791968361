import React from 'react';
import { Button, Checkbox, FormControlLabel, Paper } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import moment from 'moment';

function RebrandRedirect(props) {
    const [open, setOpen] = React.useState(getCookie());
    const [modalStyle] = React.useState(getModalStyle);
    const [checked, setChecked] = React.useState(false);
    const handleModalClose = () => {
        if (true === checked) {
            document.cookie = 'hideRedirectModal=true';
        } else {
            document.cookie = 'hideRedirectModal=false';
        }

        setOpen(false);
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: 650,
            height: 525,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            padding: 50,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            alignItems: 'center',
        },
        header: {
            fontFamily: 'futura-pt, Helvetica, Arial, sans-serif',
            fontWeight: 500,
            fontSize: 18,
            color: '#0C0A3E',
            marginBottom: 30,
        },
        text: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 400,
            fontSize: 14,
            color: '#606060',
            padding: '0 60px',
        },
        label: {
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            fontWeight: 700,
            fontSize: 14,
            color: '#606060',
        },
        logo: {
            maxWidth: 276,
        },
        logoContainer: {
            marginBottom: 30,
        },
    }));

    const classes = useStyles();

    function getCookie() {
        const cookie = document.cookie.split('; ').find((row) => row.startsWith('hideRedirectModal='));
        return cookie === undefined ? true : cookie.split('=')[1] === 'false';
    }

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const today = moment();
    const preLaunchDate = moment('2022-08-30');
    const launchDate = moment('2022-09-14');

    if (today > preLaunchDate && today < launchDate) {
        return (
            <Modal open={open} onClose={handleModalClose}>
                <Paper elevation={1}>
                    <div style={modalStyle} className={classes.paper}>
                        <div className={classes.header}>Big Changes on September 14th!</div>
                        <div className={classes.text}>
                            A lot has been happening behind the scenes at Pitch 4 Finance. We’ll
                            <br />
                            be able to provide you with more details soon. But for now, you’ll have
                            <br />
                            to keep wondering what’s next...
                        </div>
                        <div className={classes.text}>
                            <strong>
                                Don’t worry, these changes won’t affect your login or any existing features.
                            </strong>
                        </div>
                        <div>
                            <FormControlLabel
                                control={<Checkbox checked={checked} onChange={handleChange} name="checkedA" />}
                                label="Do not display this anymore"
                                className={classes.label}
                            />
                        </div>
                        <div style={{ textAlign: 'center', marginTop: 20 }}>
                            <Button variant={'contained'} color={'primary'} size={'large'} onClick={handleModalClose}>
                                OK
                            </Button>
                        </div>
                    </div>
                </Paper>
            </Modal>
        );
    }

    return null;
}

export default compose(withRouter)(RebrandRedirect);
