import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import { ANON_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: ANON_URL });

const GetTokenQuery = `
    query getTwoFactorAuthResetToken($id: ID!) {
        twoFactorAuthResetToken(id: $id) {
            id
            hasExpired
        }
    }
`;

const errorFactory = new ValidationResultFactory();

class GetToken {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class GetTokenErrors extends GetToken {
    constructor(errors) {
        super('GetTokenErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class GetTokenSuccess extends GetToken {
    constructor(data) {
        super('GetTokenSuccess');
        this.data = data;
    }

    getData = () => {
        return this.data['twoFactorAuthResetToken'];
    };
}

export const apiGetResetToken = (tokenId) => {
    let out = apolloFetch({
        query: GetTokenQuery,
        variables: { id: tokenId },
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new GetTokenErrors(vR);
            }

            return new GetTokenSuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new GetTokenErrors(vR);
        });

    return out;
};
