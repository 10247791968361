import React from 'react';
import PropTypes from 'prop-types';
import { CenteringContainer, Item, RowItem } from '../../pitch4_layout/components/div';
import styled from 'styled-components';

const WidgetContainer = styled(CenteringContainer)``;

class GenericTableBody extends React.Component {
    render() {
        let { content } = this.props;

        return (
            <WidgetContainer>
                <RowItem>
                    <CenteringContainer>
                        <Item xs={12}>{content}</Item>
                    </CenteringContainer>
                </RowItem>
            </WidgetContainer>
        );
    }
}

GenericTableBody.propTypes = {
    content: PropTypes.any.isRequired,
};

export default GenericTableBody;
