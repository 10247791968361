export const LOAN_TYPE_PORTFOLIO = 'portfolio';
export const LOAN_TYPE_RESIDENTIAL_BRIDGE = 'residential_bridge';
export const LOAN_TYPE_RESIDENTIAL_DEVELOPMENT = 'residential_development';
export const LOAN_TYPE_RESIDENTIAL_REFURBISHMENT = 'residential_refurbishment';
export const LOAN_TYPE_RESIDENTIAL_SECOND_CHARGE = 'residential_second_charge';
export const LOAN_TYPE_RESIDENTIAL_MEZZANINE = 'residential_mezzanine';
export const LOAN_TYPE_RESIDENTIAL_TERM = 'residential_term';
export const LOAN_TYPE_COMMERCIAL_BRIDGE = 'commercial_bridge';
export const LOAN_TYPE_COMMERCIAL_DEVELOPMENT = 'commercial_development';
export const LOAN_TYPE_COMMERCIAL_REFURBISHMENT = 'commercial_refurbishment';
export const LOAN_TYPE_COMMERCIAL_SECOND_CHARGE = 'commercial_second_charge';
export const LOAN_TYPE_COMMERCIAL_MEZZANINE = 'commercial_mezzanine';
export const LOAN_TYPE_COMMERCIAL_TERM = 'commercial_term';
export const LOAN_TYPE_SEMI_COMMERCIAL_BRIDGE = 'semi_commercial_bridge';
export const LOAN_TYPE_SEMI_COMMERCIAL_DEVELOPMENT = 'semi_commercial_development';
export const LOAN_TYPE_SEMI_COMMERCIAL_REFURBISHMENT = 'semi_commercial_refurbishment';
export const LOAN_TYPE_SEMI_COMMERCIAL_SECOND_CHARGE = 'semi_commercial_second_charge';
export const LOAN_TYPE_SEMI_COMMERCIAL_MEZZANINE = 'semi_commercial_mezzanine';
export const LOAN_TYPE_SEMI_COMMERCIAL_TERM = 'semi_commercial_term';
export const LOAN_TYPE_HMO_BRIDGE = 'hmo_bridge';
export const LOAN_TYPE_HMO_DEVELOPMENT = 'hmo_development';
export const LOAN_TYPE_HMO_REFURBISHMENT = 'hmo_refurbishment';
export const LOAN_TYPE_HMO_SECOND_CHARGE = 'hmo_second_charge';
export const LOAN_TYPE_HMO_MEZZANINE = 'hmo_mezzanine';
export const LOAN_TYPE_HMO_TERM = 'hmo_term';
class LoanTypeEnum {
    getTypes = () => {
        return {
            [LOAN_TYPE_PORTFOLIO]: 'Portfolio Finance',
            [LOAN_TYPE_RESIDENTIAL_BRIDGE]: 'Residential Bridging Finance',
            [LOAN_TYPE_RESIDENTIAL_DEVELOPMENT]: 'Residential Development Finance',
            [LOAN_TYPE_RESIDENTIAL_REFURBISHMENT]: 'Residential Refurbishment Finance',
            [LOAN_TYPE_RESIDENTIAL_SECOND_CHARGE]: 'Residential Second Charge Finance',
            [LOAN_TYPE_RESIDENTIAL_MEZZANINE]: 'Residential Mezzanine Finance',
            [LOAN_TYPE_RESIDENTIAL_TERM]: 'Residential Term Finance',
            [LOAN_TYPE_COMMERCIAL_BRIDGE]: 'Commercial Bridge Finance',
            [LOAN_TYPE_COMMERCIAL_DEVELOPMENT]: 'Commercial Development Finance',
            [LOAN_TYPE_COMMERCIAL_REFURBISHMENT]: 'Commercial Refurbishment Finance',
            [LOAN_TYPE_COMMERCIAL_SECOND_CHARGE]: 'Commercial Second Charge Finance',
            [LOAN_TYPE_COMMERCIAL_MEZZANINE]: 'Commercial Mezzanine Finance',
            [LOAN_TYPE_COMMERCIAL_TERM]: 'Commercial Term Finance',
            [LOAN_TYPE_SEMI_COMMERCIAL_BRIDGE]: 'Semi Commercial Bridge Finance',
            [LOAN_TYPE_SEMI_COMMERCIAL_DEVELOPMENT]: 'Semi Commercial Development Finance',
            [LOAN_TYPE_SEMI_COMMERCIAL_REFURBISHMENT]: 'Semi Commercial Refurbishment Finance',
            [LOAN_TYPE_SEMI_COMMERCIAL_SECOND_CHARGE]: 'Semi Commercial Second Charge Finance',
            [LOAN_TYPE_SEMI_COMMERCIAL_MEZZANINE]: 'Semi Commercial Mezzanine Finance',
            [LOAN_TYPE_SEMI_COMMERCIAL_TERM]: 'Semi Commercial Term Finance',
            [LOAN_TYPE_HMO_BRIDGE]: 'HMO Bridge Finance',
            [LOAN_TYPE_HMO_DEVELOPMENT]: 'HMO Development Finance',
            [LOAN_TYPE_HMO_REFURBISHMENT]: 'HMO Refurbishment Finance',
            [LOAN_TYPE_HMO_SECOND_CHARGE]: 'HMO Second Charge Finance',
            [LOAN_TYPE_HMO_MEZZANINE]: 'HMO Mezzanine Finance',
            [LOAN_TYPE_HMO_TERM]: 'HMO Term Finance',
        };
    };

    getType = (type) => this.getTypes()[type];
}

export default LoanTypeEnum;
