import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Container as LayoutContainer } from '../../../pitch4_layout/components/div';
import { actionSplashHide, selectorSplashComponent, selectorSplashShow } from '../redux/control';

class Container extends React.Component {
    render() {
        const { show, hide, component } = this.props;

        if (show === false) {
            return null;
        }

        return (
            <div className={'splash'}>
                <LayoutContainer className={'splash-outer'}>
                    <LayoutContainer className={'splash-inner'}>
                        {React.createElement(component, {
                            onClick: () => hide(),
                        })}
                    </LayoutContainer>
                </LayoutContainer>
            </div>
        );
    }
}

Container.propTypes = {
    component: PropTypes.element,
    hide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        show: selectorSplashShow(state),
        component: selectorSplashComponent(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        hide: () => dispatch(actionSplashHide()),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Container);
