import { BaseEnum } from 'pitch4_enum';
import { LOAN_TYPE_BUSINESS_FINANCE, LOAN_TYPE_PROPERTY } from '../../../pitch4admin/enum/LoanEnquiries/LoanTypeEnum';

// new
export const STATUS_MATCH_PENDING = 'match_pending';
export const STATUS_MATCH_REJECTED = 'match_rejected';
export const STATUS_MATCHED = 'matched';
export const STATUS_IN_PROGRESS = 'in_progress';
export const STATUS_APPLICATION = 'application';
export const STATUS_VALUATION = 'valuation';
export const STATUS_LEGALS = 'legals';
export const STATUS_COMPLETION_REQUESTED = 'completion_requested';
export const STATUS_COMPLETION_REJECTED = 'completion_rejected';
export const STATUS_COMPLETE = 'completed';
export const STATUS_DECLINED = 'lender_declined';
export const STATUS_ENDED = 'ended';

class StatusEnum extends BaseEnum {
    getTypes = (loanType = LOAN_TYPE_PROPERTY) => {
        return {
            [STATUS_MATCH_PENDING]: 'Match Pending',
            [STATUS_MATCH_REJECTED]: 'Match Rejected',
            [STATUS_MATCHED]: 'Matched',
            [STATUS_IN_PROGRESS]: 'In progress',
            [STATUS_APPLICATION]: 'Application',
            [STATUS_VALUATION]: loanType === LOAN_TYPE_BUSINESS_FINANCE ? 'Approval' : 'Valuation',
            [STATUS_LEGALS]: loanType === LOAN_TYPE_BUSINESS_FINANCE ? 'Payout' : 'Legals',
            [STATUS_COMPLETION_REQUESTED]: 'Completion Requested',
            [STATUS_COMPLETION_REJECTED]: 'Completion Rejected',
            [STATUS_COMPLETE]: 'Completed',
            [STATUS_DECLINED]: 'No Longer Available',
            [STATUS_ENDED]: 'Ended',
        };
    };

    getStatusOrder = () => [
        STATUS_MATCHED,
        STATUS_IN_PROGRESS,
        STATUS_APPLICATION,
        STATUS_VALUATION,
        STATUS_LEGALS,
        STATUS_COMPLETE,
        STATUS_ENDED,
    ];

    getType = (type, loanType) => this.getTypes(loanType)[type];
}

export default StatusEnum;
