import { BaseEnum } from 'pitch4_enum';

export const DR = 'dr';
export const MISS = 'miss';
export const MR = 'mr';
export const MRS = 'mrs';
export const MS = 'ms';
export const REV = 'rev';
export const HON = 'hon';
export const SIR = 'sir';
export const MX = 'mx';

class TitleEnum extends BaseEnum {
    getTypes = () => {
        return {
            [MR]: 'Mr',
            [MRS]: 'Mrs',
            [MS]: 'Ms',
            [MISS]: 'Miss',
            [DR]: 'Dr',
            [REV]: 'Rev',
            [HON]: 'Hon',
            [SIR]: 'Sir',
            [MX]: 'Mx',
        };
    };
}

export default TitleEnum;
