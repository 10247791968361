import { BORROWER_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';
import { SORT_ASC } from 'pitch4_tabular/enum/SortDirectionEnum';

const apolloFetch = createP4ApolloFetch({ uri: BORROWER_URL });

const GetApplicantsQuery = `
    query getApplicants($first: Int!, $sorts: [SortInput]) {
        applicants(first: $first, sorts: $sorts) {
            edges {
                node {
                    id
                    given
                    family
                    dateOfBirth
                    createdAt
                    updatedAt
                }
                cursor
            }
            pageInfo{
              hasNextPage,
              endCursor,
              startCursor,
              hasPreviousPage
            }
            totalCount
        }
    }
`;

export const getBorrowerApplicants = (size = 99999) => {
    return apolloFetch({
        query: GetApplicantsQuery,
        variables: {
            first: size,
            sorts: [
                {
                    column: 'given',
                    direction: SORT_ASC,
                },
            ],
        },
    })
        .then(({ data, errors }) => {
            return data;
        })
        .catch((error) => {
            return [];
        });
};
