import React from 'react';
import Popper from '@material-ui/core/Popper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { push } from 'react-router-redux';
import { actionLogout } from '../../authentication/redux/login';
import { sagaActionEndAssumedIdentitySession } from '../../authentication/sagas/assumedIdentity';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import {
    ROUTE_BORROWER_CONFIGURE_PROFILE,
    ROUTE_INTERMEDIARY_CONFIGURE_PROFILE,
    ROUTE_LANDING,
    ROUTE_P4A_CONFIGURE_PROFILE,
    ROUTE_SLAM_CONFIGURE_PROFILE,
} from '../../../pitch4_routing';
import { actionAccountMenuClose, actionAccountMenuOpen, selectorIsOpen } from '../redux/account-menu';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import AccountIcon from '@material-ui/icons/AccountCircle';
import SupervisedUserCircleRoundedIcon from '@material-ui/icons/SupervisedUserCircleRounded';
import withSession from '../../../pitchblack_react_utils/session/withSession';
import { ROLE_BORROWER, ROLE_INTERMEDIARY, ROLE_SLAM, ROLE_SUPER_ADMIN } from '../../../pitch4_enum/enum/RoleNameEnum';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { API_URL, BORROWER_URL, INTERMEDIARY_URL, SLAM_URL } from '../../../bootstrap/constants/constants';
import { setUserNotifications } from '../../../pitch4_api/toggle_user_notifications';
import ls from 'local-storage';
import { NotificationsActiveOutlined, NotificationsNoneOutlined, SettingsOutlined } from '@material-ui/icons';
import { ListItemButton } from '@mui/material';

const sessionManager = new SessionManager();

class AccountMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            realTimeNotifications: sessionManager.getRelevantSession().getRealTimeNotifications(),
        };
        this.wrapperEl = React.createRef();
        this.anchorEl = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('click', this.closeOnOuterClick.bind(this), false);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.closeOnOuterClick.bind(this), false);
    }

    render() {
        const { isOpen, goto, actionLogout, endAssumedIdentitySession, session } = this.props;
        const { realTimeNotifications } = this.state;

        const userFullName = this.getUserFullName();
        const company = this.getCompanyName();
        const accountType = this.getAccountType();
        const accountTypeClassname = accountType.toLowerCase().replace(/\s/, '-');

        return (
            <div className={`account-menu`} id={`account-menu`}>
                {true === sessionManager.hasMissingRequiredFields() && <div id={'field-beacon'}>!</div>}

                <div className={`anchor-wrapper`} ref={this.anchorEl} onClick={this.toggleMenu.bind(this)}>
                    <IconButton>
                        <Avatar>
                            {session.hasAssumedToken() ? (
                                <SupervisedUserCircleRoundedIcon className={`icon-assumed`} />
                            ) : (
                                <AccountIcon />
                            )}
                        </Avatar>
                    </IconButton>
                </div>
                <Popper
                    id={`account-menu-popper`}
                    open={isOpen}
                    placement={`bottom-end`}
                    anchorEl={this.anchorEl ? this.anchorEl.current : null}
                    ref={this.wrapperEl}
                    className={`${accountTypeClassname}-menu`}
                >
                    <span className="arrow" />
                    <div className={`account-details`}>
                        <div className={`account-user`}>{userFullName}</div>
                        {company && <div className={`account-company`}>{company}</div>}
                    </div>
                    <List component="nav" className={`account-menu-links`}>
                        <ListItem key={'am_1'} className={`account-type`}>
                            <ListItemText primary={accountType} />
                        </ListItem>
                        <Divider />
                        <ListItem key={'am_2'} button onClick={goto(this.getAccountSettingsRoute())}>
                            <ListItemIcon>
                                <SettingsOutlined />
                            </ListItemIcon>
                            <ListItemText primary="Account Settings" />
                        </ListItem>
                        <ListItemButton
                            key={'am_3'}
                            onClick={() => {
                                this.toggleUserNotifications();
                            }}
                        >
                            <ListItemIcon>
                                {realTimeNotifications === true && <NotificationsActiveOutlined />}
                                {realTimeNotifications === false && <NotificationsNoneOutlined />}
                            </ListItemIcon>
                            {realTimeNotifications === true && (
                                <ListItemText
                                    primary="Notifications"
                                    secondary={'Immediate emails'}
                                    className={`digest-toggle`}
                                />
                            )}
                            {realTimeNotifications === false && (
                                <ListItemText
                                    primary="Notifications"
                                    secondary={'Daily digest emails'}
                                    className={`digest-toggle`}
                                />
                            )}
                        </ListItemButton>
                        {session.hasAssumedToken() && (
                            <ListItem key={'am_4'} button onClick={endAssumedIdentitySession}>
                                <ListItemIcon>
                                    <ExitToAppIcon />
                                </ListItemIcon>
                                <ListItemText primary="End Assumed Session" />
                            </ListItem>
                        )}
                        {false === session.hasAssumedToken() && (
                            <ListItem
                                key={'am_5'}
                                button
                                onClick={() => {
                                    return actionLogout();
                                }}
                            >
                                <ListItemIcon>
                                    <PowerSettingsNewIcon />
                                </ListItemIcon>
                                <ListItemText primary="Logout" />
                            </ListItem>
                        )}
                    </List>
                </Popper>
            </div>
        );
    }

    getUserFullName() {
        const { session } = this.props;

        const currentSession = session.getRelevantSession();

        return currentSession.getFullname();
    }

    getAccountType() {
        const { session } = this.props;

        const role = session.getRelevantSession().getMostSeniorRole().name;

        switch (role) {
            case ROLE_SLAM:
                return 'Lender Account';

            case ROLE_INTERMEDIARY:
                return 'Intermediary Account';

            case ROLE_BORROWER:
                return 'Borrower Account';

            case ROLE_SUPER_ADMIN:
                return 'Admin Account';

            default:
                return 'Your Account';
        }
    }

    toggleUserNotifications = () => {
        const { session } = this.props;
        const currentSession = session.getRelevantSession();
        const roleUri = this.getRoleUri(currentSession.getMostSeniorRole().name);
        setUserNotifications(roleUri, currentSession.getId(), !currentSession.getRealTimeNotifications()).then(
            (resp) => {
                let sessionData = ls.get('session');
                sessionData.realTimeNotifications = !currentSession.getRealTimeNotifications();
                ls.set('session', sessionData);
                this.setState({ realTimeNotifications: !currentSession.getRealTimeNotifications() });
            }
        );
    };

    getRoleUri = (role) => {
        switch (role) {
            case ROLE_INTERMEDIARY:
                return INTERMEDIARY_URL;
            case ROLE_SLAM:
                return SLAM_URL;
            case ROLE_SUPER_ADMIN:
                return API_URL;
            default:
            case ROLE_BORROWER:
                return BORROWER_URL;
        }
    };

    getCompanyName() {
        const { session } = this.props;

        const currentSession = session.getRelevantSession();

        const supplementary = currentSession.getSupplementaryData();

        const lenderName = supplementary['lender_name'] ? supplementary['lender_name'] : null;
        const brokerName = supplementary['broker_name'] ? supplementary['broker_name'] : null;

        return lenderName ? lenderName : brokerName ? brokerName : null;
    }

    getAccountSettingsRoute() {
        const { session } = this.props;

        const role = session.getRelevantSession().getMostSeniorRole().name;

        switch (role) {
            case ROLE_SLAM:
                return ROUTE_SLAM_CONFIGURE_PROFILE;

            case ROLE_INTERMEDIARY:
                return ROUTE_INTERMEDIARY_CONFIGURE_PROFILE;

            case ROLE_BORROWER:
                return ROUTE_BORROWER_CONFIGURE_PROFILE;

            case ROLE_SUPER_ADMIN:
                return ROUTE_P4A_CONFIGURE_PROFILE;

            default:
                return ROUTE_LANDING;
        }
    }

    toggleMenu() {
        const { isOpen, openMenu, closeMenu } = this.props;

        if (isOpen === true) {
            closeMenu();
        } else {
            openMenu();
        }
    }

    closeOnOuterClick(event) {
        const { closeMenu } = this.props;

        if (!this.wrapperEl || !this.wrapperEl.current || !this.anchorEl || !this.anchorEl.current) {
            return;
        }

        if (this.wrapperEl.current.contains(event.target) || this.anchorEl.current.contains(event.target)) {
            // Click is inside the menu or anchor so do nothing
            return;
        }

        // Click is outside the menu so close it
        closeMenu();
    }
}

const mapStateToProps = (state) => {
    return {
        isOpen: selectorIsOpen(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        goto: (page) => () => {
            dispatch(actionAccountMenuClose());
            dispatch(push(page));
        },
        actionLogout: () => {
            dispatch(actionAccountMenuClose());
            dispatch(actionLogout());
        },
        openMenu: () => {
            dispatch(actionAccountMenuOpen());
        },
        closeMenu: () => {
            dispatch(actionAccountMenuClose());
        },
        endAssumedIdentitySession: () => {
            dispatch(actionAccountMenuClose());
            dispatch(sagaActionEndAssumedIdentitySession());
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(AccountMenu);
