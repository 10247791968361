import { spawn } from 'redux-saga/effects';

import borrowerMleForm from '../mle/sagas';
import borrowerMleApplicant from '../mle/form_sections/Applicant/sagas';
import viewLoanEnquiry from '../view_loan_enquiry/sagas';
import deleteLoanEnquiry from '../delete_loan_enquiry/sagas';
import ViewLePollingMatches from '../view_loan_enquiry/sagas/pollMatches';
import ViewLeModals from '../view_loan_enquiry/sagas/modals';
import matchFavourites from '../view_loan_enquiry/sagas/favourites';
import viewLenderLoanEnquiry from '../lender_loan_enquiries_view/sagas';
import alerts from '../view_loan_enquiry/sagas/alerts';

function* main() {
    yield spawn(borrowerMleForm);
    yield spawn(borrowerMleApplicant);
    yield spawn(viewLoanEnquiry);
    yield spawn(deleteLoanEnquiry);
    yield spawn(ViewLePollingMatches);
    yield spawn(ViewLeModals);
    yield spawn(viewLenderLoanEnquiry);
    yield spawn(matchFavourites);
    yield spawn(alerts);
}

export default main;
