import BaseEnum from '../../pitch4_enum/enum/BaseEnum';

export const NULLABLE_EQ = 'null_compat_eq';
export const EQ = 'eq';
export const LIKE = 'like';
export const ILIKE = 'ilike';
export const LT = 'lt';
export const LTE = 'lte';
export const GT = 'gt';
export const GTE = 'gte';
export const IN = 'in';
export const REGEXP = 'regexp';
export const BETWEEN = 'between';

class OperationEnum extends BaseEnum {
    getTypes = () => {
        return {
            [EQ]: 'Is',
            [NULLABLE_EQ]: 'Is (or empty)',
            [GT]: 'Greater than',
            [GTE]: 'Greater than or equal to',
            [LTE]: 'Less than or equal to',
            [LT]: 'Less than',
            [LIKE]: 'Matches text',
            [ILIKE]: 'Matches text (case-insensitive)',
            [REGEXP]: 'Pattern matches',
            [BETWEEN]: 'Between',
        };
    };

    getStandardIDOperations = () => {
        return [ILIKE, LIKE, EQ, NULLABLE_EQ, IN];
    };

    getStandardTextOperations = () => {
        return [ILIKE, LIKE, EQ, NULLABLE_EQ, IN];
    };
    getStandardDateTimeOperations = () => {
        return [GT, GTE, LT, LTE, EQ];
    };

    getStandardNumericOperations = () => {
        return [GT, GTE, LT, LTE, EQ];
    };
}

export default OperationEnum;
