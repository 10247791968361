import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import { CenteringContainer, Container, RowItem } from '../../../pitchblack_react_utils/layout/index';
import {
    hasMaxLength,
    hasMinLength,
    isRequired,
    passwordsMustMatch,
} from '../../../pitchblack_react_utils/form/validation';
import { TextField } from '../../../pitch4_forms/components/inputs';
import { RightAligned } from '../../../pitch4_layout/components/div';
import FormMessage, { MESSAGE_ERROR } from '../../../pitch4_forms/components/FormMessage';
import { Paper } from '@material-ui/core';

const passwordValidate = [isRequired, hasMinLength(7), hasMaxLength(255)];
const confirmPasswordValidate = [...passwordValidate, passwordsMustMatch('newPassword')];

class PasswordResetForm extends React.Component {
    render() {
        let { errors = [], handleSubmit, submitting, onSubmit, resetSubmitted } = this.props;
        let submitFunc = handleSubmit(onSubmit);
        const errorsVisible = errors.length > 0;

        return (
            <Paper style={{ padding: 20 }}>
                <Container>
                    <Container>
                        <h1 className={'heading'}>Choose a New Password</h1>
                    </Container>

                    {errorsVisible && <FormMessage type={MESSAGE_ERROR} messages={errors} />}

                    <CenteringContainer className={'reset-password'}>
                        <form onSubmit={submitFunc}>
                            <CenteringContainer>
                                <RowItem>
                                    <CenteringContainer>
                                        <TextField
                                            variant={`outlined`}
                                            InputProps={{}}
                                            name="newPassword"
                                            type="password"
                                            label="Password"
                                            disabled={submitting}
                                            validate={passwordValidate}
                                        />
                                    </CenteringContainer>
                                </RowItem>
                                <RowItem>
                                    <CenteringContainer>
                                        <TextField
                                            variant={`outlined`}
                                            InputProps={{}}
                                            name="confirmPassword"
                                            type="password"
                                            label="Password Confirmation"
                                            disabled={submitting}
                                            validate={confirmPasswordValidate}
                                        />
                                    </CenteringContainer>
                                </RowItem>
                                <RowItem>
                                    <RightAligned className={'button-panel'}>
                                        <PrimaryButton type="submit" disabled={submitting || resetSubmitted}>
                                            Reset now
                                        </PrimaryButton>
                                    </RightAligned>
                                </RowItem>
                            </CenteringContainer>
                        </form>
                    </CenteringContainer>
                </Container>
            </Paper>
        );
    }
}

PasswordResetForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.array,
    submitting: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    resetSubmitted: PropTypes.bool.isRequired,
};

export default compose(
    reduxForm({
        form: 'reset-password',
    })
)(PasswordResetForm);
