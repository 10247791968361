import React from 'react';
import { ViewDocumentManagerFactory } from 'pitch4_documents/index';
import PropTypes from 'prop-types';

export const handleDownload = (document, props) => {
    let { onPending, onSuccess, onFailure } = props;
    onPending();
    const viewDocumentManagerFactory = new ViewDocumentManagerFactory();
    let viewDocumentManager = viewDocumentManagerFactory.create();

    viewDocumentManager
        .downloadFile(document.downloadUrl)
        .then((response) => {
            viewDocumentManager.forceDownload(response, document);
            onSuccess();
        })
        .catch((error) => {
            onFailure(error);
        });
};

class DownloadButton extends React.Component {
    render() {
        let { buttonRender, document } = this.props;
        return buttonRender(() => handleDownload(document, this.props));
    }
}

DownloadButton.propTypes = {
    document: PropTypes.object.isRequired,
    buttonRender: PropTypes.func.isRequired,
    onPending: PropTypes.func.isRequired,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
};

export default DownloadButton;
