import BaseEnum from 'pitchblack_react_utils/enums/BaseEnum';

export const REFINANCE_AND_BUILD_COSTS = 'refinance_build_cost';
export const BUILD_COSTS = 'build_cost';
export const RAISE_EQUITY_AND_BUILD_COSTS = 'equity_raise_build_costs';
export const PURCHASE_AND_BUILD_COSTS = 'purchase_build_costs';

class MezzanineLoanReasonEnum extends BaseEnum {
    getTypes = () => {
        return {
            [REFINANCE_AND_BUILD_COSTS]: 'Refinance and build costs',
            [BUILD_COSTS]: 'Build costs',
            [RAISE_EQUITY_AND_BUILD_COSTS]: 'Raise equity and build costs',
            [PURCHASE_AND_BUILD_COSTS]: 'Purchase and build costs',
        };
    };
}

export default MezzanineLoanReasonEnum;
