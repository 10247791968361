import { BaseEnum } from 'pitch4_enum';

export const TYPE_OUTLINE = 'outline';
export const TYPE_FULL = 'full';
export const TYPE_NO = 'no';

class LandHasPlanningPermissionEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_OUTLINE]: 'Yes - Outline planning permission',
            [TYPE_FULL]: 'Yes - Full planning permission',
            [TYPE_NO]: 'No',
        };
    };
}

export default LandHasPlanningPermissionEnum;
