import React from 'react';

import gql from 'graphql-tag';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../../notifications/sagas/container';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import styled from 'styled-components';
import { NoQueryMutation } from 'pitch4_forms/components';

const StyledPrimaryButton = styled(PrimaryButton)`
    min-width: 80px !important;
`;

class Enable2FA extends React.Component {
    render() {
        let { disabled = false, inputRef = () => null, children } = this.props;

        return (
            <NoQueryMutation
                mutationQuery={gql`
                    mutation enableTwoFactorAuth($input: pitch4admin_promptResetTwoFactorAuthInput!) {
                        enableTwoFactorAuth(input: $input) {
                            result
                        }
                    }
                `}
                renderFormFunction={(mutate, props) => {
                    return (
                        <StyledPrimaryButton
                            inputRef={inputRef}
                            disabled={disabled}
                            onClick={this.onClick(mutate, props)}
                        >
                            {children}
                        </StyledPrimaryButton>
                    );
                }}
            />
        );
    }

    onClick = (mutate) => () => {
        let { outerRefetch, uuid, notificationSuccess, notificationError } = this.props;

        return mutate({
            variables: {
                input: {
                    uuid,
                },
            },
        })
            .then(({ data }) => {
                notificationSuccess('2FA has been enabled and a reset link has been sent.');
                outerRefetch();
            })
            .catch((error) => {
                notificationError('An error occurred. Please contact support.');
                outerRefetch();
            });
    };
}

Enable2FA.propTypes = {
    uuid: PropTypes.string.isRequired,
    outerRefetch: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.any,
    inputRef: PropTypes.any,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(Enable2FA);
