import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import ModalLayout from '../../../pitch4_modal/src/component/ModalLayout';
import { Body1 } from '../../../pitch4_layout/components/text';
import { Container, RowItem } from '../../../pitch4_layout/components/div';
import { CenteringContainer, Item } from '../../../pitchblack_react_utils/layout/index';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import { connect } from 'react-redux';
import AlertIcon from '../../../assets/images/alert-icon.svg';

const RevertLoanEnquiryToDraftModal = ({ handleRevertToDraft }) => {
    return (
        <ModalLayout>
            <CenteringContainer className={'modal-delete-modal-container'}>
                <Item xs={12}>
                    <Container>
                        <RowItem>
                            <CenteringContainer className={'margin-top-30 text-align-center'}>
                                <Body1>
                                    <div>
                                        <img src={AlertIcon} alt={''} />
                                    </div>
                                </Body1>
                            </CenteringContainer>
                        </RowItem>
                        <RowItem>
                            <CenteringContainer>
                                <Body1>
                                    <p
                                        style={{
                                            color: '#2ec4b6',
                                            fontWeight: 700,
                                            fontSize: '15px',
                                            marginTop: '20px',
                                            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                                        }}
                                    >
                                        Reverting back to a draft will remove matches and messages.
                                    </p>
                                </Body1>
                            </CenteringContainer>
                        </RowItem>
                        <RowItem>
                            <CenteringContainer className={'text-align-center'}>
                                <Body1>
                                    <p className={'modal-delete-content'}>
                                        Please confirm you are sure you wish to proceed. You will then need to edit and
                                        then re-submit this enquiry. The current live enquiry will be closed and you
                                        will need to start any lender communications again.
                                    </p>
                                </Body1>
                            </CenteringContainer>
                        </RowItem>
                        <RowItem>
                            <CenteringContainer className={'button-panel'}>
                                <PrimaryButton type="submit" onClick={handleRevertToDraft}>
                                    Revert to draft
                                </PrimaryButton>
                            </CenteringContainer>
                        </RowItem>
                    </Container>
                </Item>
            </CenteringContainer>
        </ModalLayout>
    );
};

RevertLoanEnquiryToDraftModal.propTypes = {
    handleRevertToDraft: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(RevertLoanEnquiryToDraftModal);
