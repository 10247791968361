import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import { Container, Item } from 'pitch4_layout/components/div';

export const BANNER_NOTICE_TYPE_ERROR = 'BANNER_NOTICE_TYPE_ERROR';

const BannerNoticeError = ({ message }) => {
    return (
        <Container>
            <div className="banner-notice banner-notice-error">
                <Container spacing={1} alignItems={`center`}>
                    <Item xs={12} sm={12} className={`notice-message`}>
                        <ErrorIcon />
                        {message}
                    </Item>
                </Container>
            </div>
        </Container>
    );
};

export default BannerNoticeError;

BannerNoticeError.PropType = {
    message: PropTypes.string.isRequired,
};
