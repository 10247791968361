import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CrossIcon = (props) => {
    return (
        <SvgIcon {...props} className={`cross-icon ${props.className || ''}`}>
            <path
                d="M30.0312 5.12033L25.8797 0.96875L15.5 11.3484L5.12033 0.96875L0.96875 5.12033L11.3484 15.5L0.96875 25.8797L5.12033 30.0312L15.5 19.6516L25.8797 30.0312L30.0312 25.8797L19.6516 15.5L30.0312 5.12033Z"
                fill="white"
            />
        </SvgIcon>
    );
};

export default CrossIcon;
