import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { change, destroy } from 'redux-form';

import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SLAM } from '../../../../authentication/enum/Roles/NameEnum';
import FormWizard from '../../../../form_wizard/containers/FormWizardContainer';
import { Loading } from 'pitch4_layout/components/waiting';
import { SubmitSummary } from '../components/SubmitSummary';
import {
    actionGetBusinessStream,
    actionGetMatchingCriteriaConfig,
    actionGetStoreDataMeta,
    actionResetFields,
    actionSubmitEditForm,
    NAMESPACE,
    selectorGetStoredDataMetaValues,
} from '../redux';
import { SECTION_BUSINESS_FINANCE } from '../constants';
import { FieldRow } from '../components/FieldRow';
import { globalValidator } from '../validation/global_validator';
import { SECTION_SUBMIT } from '../../../../form_wizard/constants';
import { pruneSections } from '../../../../form_wizard/utils/pruneSections';
import { mapFactsForSection } from '../utils/mapFactsForSection';
import { isEmpty } from 'pitch4_validation';
import GenericFormSection from '../../../../form_wizard/components/GenericFormSection';
import { Container, Item } from 'pitch4_layout/components/div';
import { ScreenTitle } from 'pitch4_layout';
import {
    actionSetInitialValues,
    selectorGetConfig,
    selectorGetFormStack,
    selectorGetInitialValues,
} from '../../../../form_wizard/redux';
import { actionSetJoyrideType } from '../../../../joyride/redux';

const FORM_NAME = 'slam-edit-business-stream';

class EditContainer extends React.Component {
    componentDidMount() {
        const {
            getMatchingCriteriaConfig,
            getStoredDataMeta,
            getBusinessStream,
            destroy,
            match,
            setJoyrideType,
        } = this.props;

        // Make sure the form has been destroyed on page load
        destroy();

        // Retrieve the config from the API
        getMatchingCriteriaConfig();

        // Retrieve the stored data meta from the API
        getStoredDataMeta();

        // Retrieve the business stream values from the API
        getBusinessStream(match.params.businessStreamId);

        setJoyrideType(null);
    }

    render() {
        const {
            initialValues = {},
            storedDataMetas,
            matchingCriteriaConfig,
            submitFormHandler,
            changeValue,
            resetFields,
        } = this.props;

        if (_.isEmpty(initialValues) || _.isEmpty(matchingCriteriaConfig) || _.isEmpty(storedDataMetas)) {
            return <Loading />;
        }

        const name = _.get(initialValues, [SECTION_SUBMIT, 'businessStream']);

        return (
            <React.Fragment>
                <Container justifyContent={`space-between`} spacing={`4`}>
                    <Item>
                        <ScreenTitle title={name} />
                    </Item>
                </Container>
                <FormWizard
                    config={matchingCriteriaConfig}
                    storedDataMetas={storedDataMetas}
                    formName={FORM_NAME}
                    reduxNamespace={NAMESPACE}
                    stepsTriggerField={'LOAN_TYPE'}
                    submitSummaryComponent={SubmitSummary}
                    submitSummaryProps={{
                        textMainHeading: 'Please review and confirm',
                        textSubtitle:
                            "If you need to amend anything then you can either hit 'Back' or just click on the section headings in the menu above.",
                        textRoundup:
                            "You can change the name of this business stream below. Once you're happy with your criteria then simply hit 'Submit'.",
                    }}
                    submitFormHandler={submitFormHandler}
                    formSectionRender={(props) => <GenericFormSection {...props} fieldRowComponent={FieldRow} />}
                    preSubmitHandler={pruneSections(this.props.formStack, mapFactsForSection)}
                    fieldValueSelector={(value) => {
                        const operand = value ? value.operand : null;

                        if (!isEmpty(operand)) {
                            return _.isArray(operand) ? operand[0] : operand;
                        }

                        return null;
                    }}
                    reduxFormProps={{
                        enableReinitialize: true,
                        validate: globalValidator,
                    }}
                    changeValue={changeValue}
                    resetFields={resetFields}
                    initialValues={initialValues}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        formStack: selectorGetFormStack(NAMESPACE)(state),
        matchingCriteriaConfig: selectorGetConfig(NAMESPACE)(state),
        storedDataMetas: selectorGetStoredDataMetaValues(state),
        initialValues: selectorGetInitialValues(NAMESPACE)(state),
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getMatchingCriteriaConfig: () => dispatch(actionGetMatchingCriteriaConfig()),
        submitFormHandler: (prunedValues, version) => {
            const { match } = ownProps;
            const name = _.get(prunedValues, [SECTION_SUBMIT, 'businessStream']);

            // Remove forms that aren't specific to a business stream submission
            delete prunedValues[SECTION_SUBMIT];

            dispatch(
                actionSubmitEditForm(
                    match.params.businessStreamId,
                    name,
                    prunedValues,
                    _.get(prunedValues, [SECTION_BUSINESS_FINANCE]) !== undefined,
                    version
                )
            );
        },
        getBusinessStream: (businessStreamId) => dispatch(actionGetBusinessStream(businessStreamId)),
        getStoredDataMeta: () => dispatch(actionGetStoreDataMeta()),
        changeValue: (field, value) => {
            dispatch(change(FORM_NAME, field, value));
        },
        destroy: () => {
            dispatch(destroy(FORM_NAME));
            dispatch(actionSetInitialValues(NAMESPACE)({}));
        },
        resetFields: (section, fieldNames) => {
            dispatch(actionResetFields(FORM_NAME, section, fieldNames));
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SLAM])
)(EditContainer);
