import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';

export default function NewApplicantDialog(props) {
    const { newApplicantModalOpen, setNewApplicantModalOpen, applicantsData, addSubApplicant } = props;
    const [selectedApplicant, setSelectedApplicant] = useState(null);

    const [newSubApplicantFirstName, setNewSubApplicantFirstName] = useState('');
    const [newSubApplicantLastName, setNewSubApplicantLastName] = useState('');
    const [newSubApplicantDOB, setNewSubApplicantDOB] = useState('');

    const [newSubApplicant, setNewSubApplicant] = useState(null);
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const handleSubApplicantSelectChange = (selectedOption) => {
        setSelectedApplicant(selectedOption ? selectedOption.option : null);
        setNewSubApplicant(selectedOption ? selectedOption.option : null);
        setSaveButtonDisabled(!selectedOption);
    };
    const handleNewApplicantCancel = () => {
        setSelectedApplicant(null);
        setNewSubApplicantFirstName('');
        setNewSubApplicantLastName('');
        setNewSubApplicantDOB('');
        setNewApplicantModalOpen(false);
    };
    const handleNewApplicantSave = () => {
        setSelectedApplicant(null);
        setNewSubApplicantFirstName('');
        setNewSubApplicantLastName('');
        setNewSubApplicantDOB('');
        setNewApplicantModalOpen(false);

        addSubApplicant(newSubApplicant);
    };
    const newSubApplicantDataChange = () => {
        setNewSubApplicant({
            given: newSubApplicantFirstName,
            family: newSubApplicantLastName,
            date_of_birth: newSubApplicantDOB,
            label: ''.concat(
                newSubApplicantFirstName,
                ' ',
                newSubApplicantLastName,
                ' (',
                moment(newSubApplicantDOB).format('MMM D, YYYY'),
                ')'
            ),
        });
    };

    useEffect(() => {
        if (
            (newSubApplicantFirstName === '' || newSubApplicantLastName === '' || newSubApplicantDOB === '') &&
            selectedApplicant === null
        ) {
            setSaveButtonDisabled(true);
        } else {
            setSaveButtonDisabled(false);
        }
    });
    useEffect(() => newSubApplicantDataChange(), [newSubApplicantFirstName]);
    useEffect(() => newSubApplicantDataChange(), [newSubApplicantLastName]);
    useEffect(() => newSubApplicantDataChange(), [newSubApplicantDOB]);

    const date = new Date();
    date.setFullYear(date.getFullYear() - 18);

    return (
        <MuiPickersUtilsProvider locale="gb" utils={MomentUtils}>
            <Dialog open={newApplicantModalOpen} onClose={() => setNewApplicantModalOpen(false)}>
                <DialogContent>
                    <h2 style={{ marginTop: 0 }}>Select a sub applicant</h2>
                    <Autocomplete
                        id="main-applicant"
                        options={applicantsData}
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={!applicantsData.length ? 'Loading applicants...' : 'Select sub applicant'}
                                variant="outlined"
                            />
                        )}
                        disabled={!applicantsData.length}
                        onChange={(event, data, reason, selectedOption) =>
                            handleSubApplicantSelectChange(selectedOption)
                        }
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 10 }}>
                        <span>or</span>
                    </div>
                    <h2 style={{ marginTop: 0 }}>Add a new sub applicant</h2>
                    <TextField
                        autoFocus
                        id="first_name"
                        label="First name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        style={{ marginTop: 10 }}
                        disabled={selectedApplicant !== null}
                        value={selectedApplicant !== null ? '' : newSubApplicantFirstName}
                        onChange={(event) => {
                            setNewSubApplicantFirstName(event.target.value);
                            if (
                                event.target.value === '' ||
                                newSubApplicantLastName === '' ||
                                newSubApplicantDOB === ''
                            ) {
                                setSaveButtonDisabled(true);
                            } else {
                                setSaveButtonDisabled(false);
                            }
                        }}
                    />
                    <TextField
                        id="last_name"
                        label="Last name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        style={{ marginTop: 10, marginBottom: 10 }}
                        disabled={selectedApplicant !== null}
                        value={selectedApplicant !== null ? '' : newSubApplicantLastName}
                        onChange={(event) => {
                            setNewSubApplicantLastName(event.target.value);
                            if (
                                newSubApplicantFirstName === '' ||
                                event.target.value === '' ||
                                newSubApplicantDOB === ''
                            ) {
                                setSaveButtonDisabled(true);
                            } else {
                                setSaveButtonDisabled(false);
                            }
                        }}
                    />
                    <DatePicker
                        id="dob"
                        label="Date of Birth"
                        format="DD/MM/yyyy"
                        disableFuture
                        fullWidth
                        variant="outlined"
                        inputProps={{
                            style: { textAlign: 'right' },
                        }}
                        disabled={selectedApplicant !== null}
                        value={newSubApplicantDOB ? newSubApplicantDOB : new Date(1990, 1, 1)}
                        maxDate={new Date(date.getFullYear(), date.getMonth(), date.getDay())}
                        onChange={(event) => {
                            setNewSubApplicantDOB(event);
                            if (newSubApplicantFirstName === '' || newSubApplicantLastName === '' || date === '') {
                                setSaveButtonDisabled(true);
                            } else {
                                setSaveButtonDisabled(false);
                            }
                        }}
                    />
                </DialogContent>
                <DialogActions style={{ padding: 24 }}>
                    <Button onClick={handleNewApplicantCancel}>Cancel</Button>
                    <Button
                        variant="contained"
                        color={'primary'}
                        onClick={handleNewApplicantSave}
                        disabled={saveButtonDisabled}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
}
