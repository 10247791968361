import React from 'react';
import { Container, Centered, RowItem } from 'pitchblack_react_utils/layout';
import PropTypes from 'prop-types';
import { P4StyleComponentContainer } from '../div';

class BasicFormContentLayout extends React.Component {
    render() {
        let { content } = this.props;
        return (
            <Container>
                <RowItem className={`content-row`}>
                    <Centered xs={12}>
                        <P4StyleComponentContainer content={content} />
                    </Centered>
                </RowItem>
            </Container>
        );
    }
}

BasicFormContentLayout.propTypes = {
    label: PropTypes.string,
    content: PropTypes.object.isRequired,
};

export default BasicFormContentLayout;
