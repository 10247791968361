import BaseLevel from './BaseLevel';
import { INFO_LEVEL } from '../enum/LevelEnum';

class Info extends BaseLevel {
    constructor(message) {
        super(INFO_LEVEL, message);
    }
}

export default Info;
