import React from 'react';
import MoreVert from '@material-ui/icons/MoreVert';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withStyles } from '@material-ui/core';
import { PrimaryButton } from './index';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { BRAND_MAIN_CONTACT } from '../../../bootstrap/constants/constants';

const styles = (theme) => ({
    button: {
        margin: theme.spacing(1),
        backgroundColor: 'rgb(185,21,231)',
    },
    input: {
        display: 'none',
    },
});

class SwitchButton extends React.Component {
    constructor(props) {
        super(props);

        let options = props.options.map((option, index) => {
            option['inputRef'] = React.createRef();
            option['item'] = React.cloneElement(
                option.item,
                { ...option.item.props, inputRef: option['inputRef'] },
                option.itemChildren
            );
            return option;
        });

        this.state = {
            options: options,
            childRefs: {},
            selectedIndex: null,
            setOpen: false,
            anchorRef: React.createRef(),
        };
    }

    handleMenuItemClick = (event, index) => {
        this.state.options[index].inputRef.current.click();

        this.setState({
            ...this.state,
            selectedIndex: index,
            setOpen: false,
        });
    };

    handleToggle = () => {
        this.setState({
            ...this.state,
            setOpen: !this.state.setOpen,
        });
    };

    handleClose = (event) => {
        if (this.state.anchorRef.current && this.state.anchorRef.current.contains(event.target)) {
            return;
        }

        this.setState({
            ...this.state,
            setOpen: false,
        });
    };

    static getDerivedStateFromProps(props, state) {
        // Any time the current user changes,
        // Reset any parts of state that are tied to that user.
        // In this simple example, that's just the email.
        if (props.options !== state.options) {
            let options = props.options.map((option, index) => {
                option['inputRef'] = React.createRef();
                option['item'] = React.cloneElement(
                    option.item,
                    { ...option.item.props, inputRef: option['inputRef'] },
                    option.itemChildren
                );
                return option;
            });

            return {
                ...state,
                options,
            };
        }
        return null;
    }

    render() {
        let { options, selectedIndex } = this.state;
        const { label = null } = this.props;

        options = options.map((option, index) => {
            option['inputRef'] = React.createRef();
            option['item'] = React.cloneElement(
                option.item,
                { ...option.item.props, inputRef: option['inputRef'] },
                option.itemChildren
            );
            return option;
        });

        if (options.length === 0) {
            return (
                <div>
                    <PrimaryButton
                        onClick={(event) =>
                            alert(`Technical error has occurred please contact support on ${BRAND_MAIN_CONTACT}`)
                        }
                    >
                        Error
                    </PrimaryButton>
                </div>
            );
        }

        return (
            <div className={`switch-button`}>
                {label ? (
                    <span
                        style={{ padding: 10, display: 'flex', alignItems: 'center', fontWeight: 600 }}
                        className={`switch-button-toggle`}
                        onClick={this.handleToggle}
                        ref={this.state.anchorRef}
                    >
                        {label} <ArrowDropDownIcon />
                    </span>
                ) : (
                    <MoreVert
                        className={`switch-button-toggle`}
                        onClick={this.handleToggle}
                        ref={this.state.anchorRef}
                    />
                )}

                <Popper
                    className={`switch-button-dropdown`}
                    open={this.state.setOpen}
                    anchorEl={this.state.anchorRef.current}
                    transition
                    placement={`bottom-end`}
                >
                    <Paper elevation={4}>
                        <ClickAwayListener onClickAway={this.handleClose}>
                            <MenuList>
                                {options.map((option, index) => (
                                    <MenuItem
                                        disabled={option.item.props.disabled ? option.item.props.disabled : false}
                                        key={index}
                                        selected={index === selectedIndex}
                                        onClick={(event) => {
                                            this.handleMenuItemClick(event, index);
                                        }}
                                    >
                                        <div style={{ display: 'none' }}>{option.item}</div>
                                        {option.itemChildren}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </div>
        );
    }
}

SwitchButton.propTypes = {
    useOneLabel: PropTypes.string,
    options: PropTypes.array.isRequired,
};

export default compose(withStyles(styles))(SwitchButton);
