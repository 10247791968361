import { BaseEnum } from 'pitch4_enum';
import { OTHER } from '../../../../MakeLoanEnquiry/enums/Shared/PropertyLocation';

export const TYPE_CARE_HOME = 'care_home';
export const TYPE_RETAIL = 'retail';
export const TYPE_OFFICE = 'office';
export const TYPE_HOTEL = 'hotel';
export const TYPE_RETIREMENT_HOME = 'retirement_home';
export const TYPE_STUDENT_ACCOMMODATION = 'student_accommodation';
export const TYPE_PUB = 'pub';
export const TYPE_INDUSTRIAL = 'industrial';
export const TYPE_CAFE = 'cafe';
export const TYPE_RESTAURANT = 'restaurant';
export const TYPE_NURSERY = 'nursery';
export const TYPE_NIGHTCLUB = 'nightclub';
export const TYPE_MEDICAL_OR_VETERINARY_PRACTICE = 'medical_or_veterinary';
export const TYPE_OTHER = 'other';

export const TYPE_MEDICAL_OR_VETERINARY = 'medical_or_veterinary';
export const TYPE_AUTOMOTIVE = 'automotive';
export const TYPE_TAKEAWAY = 'takeaway';
export const TYPE_DRY_CLEANER = 'dry_cleaner';

class CommercialTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_CARE_HOME]: 'Care home',
            [TYPE_RETAIL]: 'Retail',
            [TYPE_OFFICE]: 'Office',
            [TYPE_HOTEL]: 'Hotel or B&B',
            [TYPE_RETIREMENT_HOME]: 'Retirement home',
            [TYPE_STUDENT_ACCOMMODATION]: 'Student Accommodation',
            [TYPE_PUB]: 'Pub',
            [TYPE_INDUSTRIAL]: 'Industrial',
            [TYPE_CAFE]: 'Café',
            [TYPE_RESTAURANT]: 'Restaurant',
            [TYPE_NURSERY]: 'Nursery',
            [TYPE_NIGHTCLUB]: 'Nightclub',
            [TYPE_MEDICAL_OR_VETERINARY_PRACTICE]: 'Medical or Veterinary Practice',
            [TYPE_OTHER]: 'Other',
            [TYPE_MEDICAL_OR_VETERINARY]: 'Medical or Veterinary Practice',
            [TYPE_AUTOMOTIVE]: 'Automotive',
            [TYPE_TAKEAWAY]: 'Takeaway',
            [TYPE_DRY_CLEANER]: 'Dry Cleaner',
        };
    };

    getOptions = () => {
        return [
            { value: TYPE_CARE_HOME, label: 'Care home' },
            { value: TYPE_RETAIL, label: 'Retail' },
            { value: TYPE_OFFICE, label: 'Office' },
            { value: TYPE_HOTEL, label: 'Hotel or B&B' },
            { value: TYPE_RETIREMENT_HOME, label: 'Retirement home' },
            { value: TYPE_STUDENT_ACCOMMODATION, label: 'Student Accommodation' },
            { value: TYPE_PUB, label: 'Pub' },
            { value: TYPE_INDUSTRIAL, label: 'Industrial' },
            { value: TYPE_CAFE, label: 'Café' },
            { value: TYPE_RESTAURANT, label: 'Restaurant' },
            { value: TYPE_NURSERY, label: 'Nursery' },
            { value: TYPE_NIGHTCLUB, label: 'Nightclub' },
            { value: TYPE_MEDICAL_OR_VETERINARY_PRACTICE, label: 'Medical or Veterinary Practice' },
            { value: TYPE_AUTOMOTIVE, label: 'Automotive' },
            { value: TYPE_TAKEAWAY, label: 'Takeaway' },
            { value: TYPE_DRY_CLEANER, label: 'Dry Cleaner' },
            { value: OTHER, label: 'Other' },
        ];
    };
}

export default CommercialTypeEnum;
