import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { ROLE_INTERMEDIARY } from '../../../../authentication/enum/Roles/NameEnum';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';
import { push } from 'react-router-redux';
import {
    path,
    ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY,
    ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY,
    ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW,
} from '../../../../../pitch4_routing';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { PrimaryRedirectButton } from '../../../../../pitch4_layout/components/buttons';
import { withRouter } from 'react-router-dom';
import RouteManagerFactory from '../../../../../pitch4_routing/src/RouteManagerFactory';
import DeleteLoanEnquiryConfirm from '../../../../delete_loan_enquiry/components/DeleteLoanEnquiryConfirm';
import { actionDelete } from '../../delete_loan_enquiry/redux/main';
import { sagaActionModalHide, sagaActionModalShow } from '../../../../modal/sagas/container';
import LoanTypeEnum from '../../enum/LoanEnquiries/LoanTypeEnum';
import StatusEnum, {
    STATUS_APPLICATION,
    STATUS_DRAFT,
    STATUS_IN_PROGRESS,
    STATUS_LEGALS,
    STATUS_MATCHED,
    STATUS_ON_HOLD,
    STATUS_VALUATION,
    STATUS_COMPLETION_REJECTED,
    STATUS_COMPLETION_REQUESTED,
    STATUS_PENDING,
    STATUS_MATCHING,
} from '../../enum/LoanEnquiries/StatusEnum';
import moment from 'moment';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Status from '../components/Status';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import _ from 'lodash';
import { PAGE_SIZE, ROWS_PER_PAGE } from '../../../../../pitch4_enum/enum/DataGridEnum';
import { actionSetJoyrideType } from '../../../../joyride/redux';
import { INTERMEDIARY_LOAN_ENQUIRY_LIST } from '../../../../joyride/config';
import StarIcon from '../../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../../assets/images/star-gray.svg';
import { actionUpdateMatchFavourite } from '../../view_loan_enquiry/redux/main';
import Toolbar from '../components/Toolbar';
import HeaderWithCount from '../../../../../pitch4_elements/screen-headers/HeaderWithCount';
import PersonIcon from '@material-ui/icons/Person';
import { getAssignableUsers } from '../../../../../pitch4_api/get_assignable_users';
import AssignToModal from '../../../../../pitch4_elements/loan-enquiry-owner/AssignToModal';
import { setLEAssignee } from '../../../../../pitch4_api/set_le_assignee';
import { removeLEAssignee } from '../../../../../pitch4_api/remove_le_assignee';
import { getLEs } from '../api/get_les';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import { DEFAULT_SEARCH_FILTER_DELAY } from '../../../../../pitch4_utils/FiltersDefaults';
import { LOAN_AMOUNT } from '../../../enum/MatchingRules/MetaKeyEnum';
import { calculateLoanType } from '../../../../MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';
import { defaultSortModel } from '../../../../../pitch4_utils/SortsDefaults';

const routeManagerFactory = new RouteManagerFactory();
const routeManager = routeManagerFactory.create();
const statusEnum = new StatusEnum();
const financeTypeEnum = new LoanTypeEnum();

class TableStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previousSortModel: [],
            dataLoading: true,
            dataFetchError: null,
            statusFilter: [],
            updatesFilter: '',
            assigneeFilter: '',
            statuses: [
                STATUS_APPLICATION,
                STATUS_DRAFT,
                STATUS_IN_PROGRESS,
                STATUS_LEGALS,
                STATUS_MATCHED,
                STATUS_VALUATION,
                STATUS_ON_HOLD,
            ],
            expiredStatuses: statusEnum.getExpiredTypes(),
            searchFilter: '',
            showExpired: false,
            dataGrid: {
                rows: [],
                totalRowCount: 0,
                dataLoading: false,
                pageSize: PAGE_SIZE,
                rowsPerPage: ROWS_PER_PAGE,
                currentPage: 0,
                sortModel: defaultSortModel,
            },
            dataRequest: {
                filters: this.getDefaultFilters(),
                sorts: [
                    {
                        column: 'favourite',
                        direction: 'desc',
                    },
                    {
                        column: 'createdAt',
                        direction: 'desc',
                    },
                ],
                pages: [],
                cursor: null,
                storedDataFilters: [],
            },
        };
    }

    componentDidMount() {
        const { setJoyrideType } = this.props;
        setJoyrideType(INTERMEDIARY_LOAN_ENQUIRY_LIST);
        const data = this.getData();
        this.processData(data);
    }

    refreshData() {
        const data = this.getData();
        this.processData(data);
    }

    processData(data) {
        const { currentPage } = this.state.dataGrid;
        const { cursor } = this.state.dataRequest;

        this.setState({
            dataGrid: { ...this.state.dataGrid, dataLoading: true },
        });

        data.then((resp) => {
            if (!resp) {
                return {};
            }

            const loanEnquiries = resp.loanEnquiries.edges.map((le) => le.node);
            const les = loanEnquiries.map((le, index) => {
                if (le.loanEnquiryData === null) {
                    le.loanEnquiryData = {
                        loanAmount: 0,
                    };
                }
                const mainApplicant = le.loanEnquiryApplicants.find((a) => a.isMainApplicant === true);
                const loanEnquiryDataAmount = le.loanEnquiryData.loanAmount > 0 ? le.loanEnquiryData.loanAmount : 0;
                let loanType = calculateLoanType(le.loanEnquiryData);
                loanType = loanType != null ? loanType.label : 'Not Set';
                return {
                    id: le.id,
                    submittedBy: le.submittedBy.userProfile.given + ' ' + le.submittedBy.userProfile.family,
                    mainApplicant:
                        mainApplicant && mainApplicant.applicant
                            ? mainApplicant.applicant.given + ' ' + mainApplicant.applicant.family
                            : 'Not set',
                    createdAt: le.createdAt,
                    type: loanType,
                    amount: le.storedData.find((item) => item.meta.key === LOAN_AMOUNT)
                        ? parseInt(le.storedData.find((item) => item.meta.key === LOAN_AMOUNT).value)
                        : loanEnquiryDataAmount,
                    status: le.status,
                    alertActive: le.alertActive,
                    adminDocumentsAlertActive: le.adminDocumentsAlertActive,
                    favourite: le.favourite,
                    assignedTo: this.getAssignees(le.assignees),
                    broker: le.broker,
                    isNewForm: le.isNewForm,
                };
            });

            let pages = this.state.dataRequest.pages;
            pages[currentPage] = {
                cursor: cursor,
                hasNextPage: resp.loanEnquiries.pageInfo.hasNextPage,
                endCursor: resp.loanEnquiries.pageInfo.endCursor,
                startCursor: resp.loanEnquiries.pageInfo.startCursor,
                hasPreviousPage: resp.loanEnquiries.pageInfo.hasPreviousPage,
            };

            this.setState({
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: false,
                    rows: les,
                    totalRowCount: resp.loanEnquiries.totalCount,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    ...{ pages, cursor: resp.loanEnquiries.pageInfo.endCursor },
                },
            });
        }).catch((error) => {
            this.setState({ dataFetchError: error });
        });
    }

    getAssignees = (assignees) => {
        if (assignees.length === 0) {
            return [
                {
                    id: 'no_owner',
                    name: 'No owner',
                },
            ];
        }

        return [
            {
                id: assignees[0].id,
                name: assignees[0].userProfile.given + ' ' + assignees[0].userProfile.family,
            },
        ];
    };

    getData = () =>
        getLEs(
            this.state.dataRequest.cursor,
            this.state.dataRequest.filters,
            this.state.dataGrid.pageSize,
            this.state.dataRequest.sorts,
            this.state.dataRequest.storedDataFilters,
            this.state.dataGrid.currentPage
        );

    getDefaultFilters = () => [
        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_DRAFT,
        },
        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_PENDING,
        },
        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_MATCHED,
        },
        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_IN_PROGRESS,
        },
        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_APPLICATION,
        },
        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_VALUATION,
        },
        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_LEGALS,
        },
        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_COMPLETION_REQUESTED,
        },
        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_COMPLETION_REJECTED,
        },
        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_ON_HOLD,
        },

        {
            column: 'status',
            operation: 'eq',
            not: false,
            operand: STATUS_MATCHING,
        },
    ];

    handlePageChange = (pageConfig) => {
        const previousPage = this.state.dataGrid.currentPage;
        const newPage = pageConfig;
        const { pages } = this.state.dataRequest;

        if (newPage > previousPage) {
            // Next page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[previousPage].endCursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        } else {
            // Previous page
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        dataLoading: true,
                        currentPage: newPage,
                    },
                    dataRequest: {
                        ...this.state.dataRequest,
                        cursor: pages[newPage].cursor,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    handlePageSizeChange = (pageConfig) => {
        this.setState(
            {
                dataGrid: {
                    ...this.state.dataGrid,
                    dataLoading: true,
                    pageSize: pageConfig,
                    rows: [],
                    currentPage: 0,
                    totalRowCount: 0,
                },
                dataRequest: {
                    ...this.state.dataRequest,
                    pages: [],
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    buildSearchRequestFilters = () => {
        const { searchFilter } = this.state;
        let searchRequestFilter = [];
        const searchableFields = ['email', 'title', 'given', 'family', 'borrowerRef'];

        if (searchFilter.length > 0) {
            searchRequestFilter = searchableFields.map((field) => {
                return {
                    operation: 'ilike',
                    operand: '%' + searchFilter + '%',
                    column: field,
                    not: false,
                    logicOperator: 'or',
                };
            });
        }

        return searchRequestFilter;
    };

    buildStatusRequestFilters = () => {
        const { statusFilter } = this.state;

        if (statusFilter.length === 0) {
            return this.getDefaultFilters();
        }

        return statusFilter.map((status) => {
            return {
                column: 'status',
                operation: 'eq',
                not: false,
                operand: status,
            };
        });
    };

    handleSearchFilterChange = (event) => {
        this.setState(
            {
                searchFilter: event.target.value,
            },
            () => {
                this.setState({
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        filters: this.buildRequestFilters(),
                    },
                });
            }
        );

        _.delay(
            (value) => {
                if (this.state.searchFilter === value) {
                    const data = this.getData();
                    this.processData(data);
                }
            },
            DEFAULT_SEARCH_FILTER_DELAY,
            event.target.value
        );
    };

    handleClearAllFilters = () => {
        window.location.reload();
    };

    handleStatusFilterChange = (event) => {
        const selectedStatuses = event.target.value;

        this.setState(
            {
                statusFilter: selectedStatuses,
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            filters: this.buildRequestFilters(),
                        },
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    handleSortModelChange = (params) => {
        const { sortModel } = this.state.dataGrid;
        if (
            params === null ||
            typeof params === 'undefined' ||
            JSON.stringify(params) === JSON.stringify(defaultSortModel)
        ) {
            return;
        }

        if (JSON.stringify(params) === JSON.stringify(this.state.previousSortModel)) {
            return;
        }
        const sortableFieldsMapping = {
            favourite: 'favourite',
            createdAt: 'createdAt',
            chaseDate: 'chaseDate',
            submittedBy: 'submitterEmail',
            applicant: 'mainApplicant',
            applicants: 'mainApplicant',
            status: 'status',
            assignedTo: 'assignedToBroker',
        };
        let sorts = params.map((sort) => ({
            column: sortableFieldsMapping[sort.field],
            direction: sort.sort,
        }));

        if (JSON.stringify(params) !== JSON.stringify(sortModel)) {
            this.setState(
                {
                    dataGrid: {
                        ...this.state.dataGrid,
                        sortModel: params,
                    },
                    previousSortModel: sortModel,
                    dataRequest: {
                        ...this.state.dataRequest,
                        pages: [],
                        cursor: null,
                        sorts: sorts,
                    },
                },
                () => {
                    const data = this.getData();
                    this.processData(data);
                }
            );
        }
    };

    buildExpiredFilters = () =>
        statusEnum.getExpiredTypes().map((status) => ({
            column: 'status',
            operation: 'eq',
            not: false,
            operand: status,
        }));

    handleShowExpired = (showExpired) => {
        let filters = [];

        if (true === showExpired) {
            filters = _.concat(this.state.dataRequest.filters, this.buildExpiredFilters());
        } else {
            const expiredFilters = this.buildExpiredFilters();
            const currentFilters = this.state.dataRequest.filters;
            filters = currentFilters.filter(
                (cr) => !expiredFilters.find((ef) => ef.operand === cr.operand && cr.column === ef.column)
            );
        }

        this.setState(
            {
                statusFilter: [],
                showExpired: showExpired,
                dataGrid: { ...this.state.dataGrid, rows: [], totalRowCount: 0 },
                dataRequest: {
                    ...this.state.dataRequest,
                    filters: filters,
                    cursor: null,
                },
            },
            () => {
                const data = this.getData();
                this.processData(data);
            }
        );
    };

    handleDeleteConfirm = (loanEnquiryId) => () => {
        const { deleteOnConfirm, setModalComponent, setModalClose } = this.props;
        const handleDelete = () => {
            deleteOnConfirm(loanEnquiryId);
            setModalClose();

            // Delay data refresh to let system delete the LE
            _.delay(() => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                        },
                    },
                    () => {
                        this.refreshData();
                    }
                );
            }, 1000);
        };
        setModalComponent(<DeleteLoanEnquiryConfirm handleDelete={handleDelete} />, true);
    };

    handleFavouriteChange = (leId, isFavourite) => {
        const { handleFavouriteMatchChange } = this.props;
        const { rows } = this.state.dataGrid;

        // eslint-disable-next-line array-callback-return
        rows.map((row) => {
            if (row.id === leId) {
                row.favourite = isFavourite;
            }
        });

        this.setState({ dataGrid: { ...this.state.dataGrid, rows: rows, totalRowCount: rows.length } }, () =>
            handleFavouriteMatchChange(null, leId, isFavourite)
        );
    };

    buildUpdatesRequestFilters = () => {
        const { updatesFilter } = this.state;
        let filters = [];

        if (updatesFilter.length > 0) {
            filters.push({
                operation: 'eq',
                operand: 'updatesOnly' === updatesFilter ? 'true' : 'false',
                column: 'leAlertActive',
                not: false,
            });
        }

        return filters;
    };

    handleUpdatesFilterChange = (event) => {
        const updatesFilter = event.target.value;

        this.setState(
            {
                updatesFilter: updatesFilter,
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            filters: this.buildRequestFilters(),
                        },
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    buildAssigneeRequestFilters = () => {
        const { assigneeFilter } = this.state;
        let filters = [];

        if ('' !== assigneeFilter) {
            filters.push({
                operation: assigneeFilter,
                operand: 'true',
                column: 'leAssignedActive',
                not: false,
            });
        }

        return filters;
    };

    buildRequestFilters = () => [
        ...this.buildSearchRequestFilters(),
        ...this.buildStatusRequestFilters(),
        ...this.buildUpdatesRequestFilters(),
        ...this.buildAssigneeRequestFilters(),
    ];

    handleUpdateLEAssignee = (leId) => (newAssignedTo) => {
        const { setModalClose } = this.props;
        let data;

        if (newAssignedTo.id === 'no_owner') {
            data = removeLEAssignee(INTERMEDIARY_URL, null, leId, [newAssignedTo.id]);
        } else {
            data = setLEAssignee(INTERMEDIARY_URL, null, leId, [newAssignedTo.id]);
        }

        data.then((resp) => {
            const rows = this.state.dataGrid.rows;
            const le = (rows.find((le) => le.id === leId).assignedTo = [
                {
                    id: newAssignedTo.id,
                    name: newAssignedTo.name,
                },
            ]);
            const newRows = rows.map((item) => (le.id !== leId ? item : le));

            this.setState({ dataGrid: { ...this.state.dataGrid, rows: newRows } }, () => setModalClose());
        });
    };

    handleAssignLETo = (le) => {
        const { setModalComponent, setModalClose } = this.props;
        getAssignableUsers(INTERMEDIARY_URL).then((resp) => {
            setModalComponent(
                <AssignToModal
                    assignees={resp}
                    setModalClose={setModalClose}
                    userRole={ROLE_INTERMEDIARY}
                    assignedTo={le.assignedTo[0]}
                    handleUpdateLEAssignee={this.handleUpdateLEAssignee(le.id)}
                />,
                true
            );
        });
    };

    handleAssigneeFilterChange = (event) => {
        const assigneeFilter = event.target.value;

        this.setState(
            {
                assigneeFilter: assigneeFilter,
            },
            () => {
                this.setState(
                    {
                        dataRequest: {
                            ...this.state.dataRequest,
                            pages: [],
                            cursor: null,
                            filters: this.buildRequestFilters(),
                        },
                    },
                    () => {
                        const data = this.getData();
                        this.processData(data);
                    }
                );
            }
        );
    };

    isNetworkBroker = () => {
        const { session } = this.props;
        return session.getRelevantSession().isNetwork();
    };

    render() {
        const { dispatch } = this.props;
        let { statuses, statusFilter, searchFilter, showExpired, updatesFilter, assigneeFilter } = this.state;
        const { sortModel, rows, pageSize, dataLoading, rowsPerPage, totalRowCount } = this.state.dataGrid;

        return (
            <div>
                <div className={'le-header-container'}>
                    <div className={'header-text'}>
                        <HeaderWithCount title={'Enquiries'} count={totalRowCount} countText={'loan enquiries'} />
                        <div className={'btn-mobile'}>
                            <PrimaryRedirectButton
                                style={{ margin: 0 }}
                                url={routeManager.route(ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY)}
                            >
                                Create
                            </PrimaryRedirectButton>
                        </div>
                    </div>
                    <Toolbar
                        assigneeFilter={assigneeFilter}
                        updatesFilter={updatesFilter}
                        statusFilter={statusFilter}
                        searchFilter={searchFilter}
                        dataLoading={dataLoading}
                        showExpired={showExpired}
                        statuses={statuses}
                        handleUpdatesFilterChange={this.handleUpdatesFilterChange}
                        handleShowExpired={this.handleShowExpired}
                        handleClearAllFilters={this.handleClearAllFilters}
                        handleStatusFilterChange={this.handleStatusFilterChange}
                        handleSearchFilterChange={this.handleSearchFilterChange}
                        handleAssigneeFilterChange={this.handleAssigneeFilterChange}
                    />
                </div>
                <div className={`le-table-container`} id={'le-list-pagination'}>
                    <DataGrid
                        sortingOrder={['desc', 'asc']}
                        sortModel={sortModel}
                        onCellClick={(params) => {
                            switch (params.field) {
                                case 'favourite':
                                    this.handleFavouriteChange(params.row.id, !params.row.favourite);
                                    break;
                                case 'assignedTo':
                                    if (
                                        // Network Broker
                                        (true === this.isNetworkBroker() && null === params.row.broker.parentBroker) ||
                                        // Child Broker
                                        (false === this.isNetworkBroker() &&
                                            false === params.row.broker.isNetwork &&
                                            null !== params.row.broker.parentBroker) ||
                                        // Regular Broker
                                        (false === this.isNetworkBroker() &&
                                            false === params.row.broker.isNetwork &&
                                            null === params.row.broker.parentBroker)
                                    ) {
                                        this.handleAssignLETo(params.row);
                                    }
                                    break;
                                case 'actions':
                                    break;
                                default:
                                    if (params.row.status === STATUS_DRAFT) {
                                        dispatch(
                                            push(
                                                path(ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY, {
                                                    loanEnquiryId: params.row.id,
                                                })
                                            )
                                        );
                                    } else {
                                        dispatch(
                                            push(
                                                path(ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW, {
                                                    loanEnquiryId: params.row.id,
                                                })
                                            )
                                        );
                                    }
                                    break;
                            }
                        }}
                        paginationMode="server"
                        onPageChange={this.handlePageChange}
                        onPageSizeChange={this.handlePageSizeChange}
                        sortingMode="server"
                        onSortModelChange={this.handleSortModelChange}
                        rowCount={totalRowCount}
                        pageSize={pageSize}
                        rowsPerPageOptions={rowsPerPage}
                        rows={rows}
                        loading={true}
                        disableColumnFilter={true}
                        disableMultipleColumnsSorting={true}
                        disableColumnReorder={true}
                        disableMultipleColumnsFiltering={true}
                        disableSelectionOnClick={true}
                        disableColumnMenu={true}
                        components={{
                            LoadingOverlay: () => {
                                return (
                                    <GridOverlay>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                width: '100%',
                                            }}
                                        >
                                            {dataLoading ? (
                                                <LinearProgress />
                                            ) : rows.length === 0 ? (
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        width: '100%',
                                                        paddingTop: 100,
                                                        textAlign: 'center',
                                                    }}
                                                >
                                                    No data
                                                </div>
                                            ) : null}
                                        </div>
                                    </GridOverlay>
                                );
                            },
                        }}
                        columns={[
                            {
                                field: 'favourite',
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: true,
                                width: 50,
                                headerName: ' ',
                                renderCell: (params) => {
                                    return (
                                        <img
                                            src={params.row.favourite ? StarIcon : StarGrayIcon}
                                            alt="Favourite"
                                            className={'favourite'}
                                        />
                                    );
                                },
                                disableColumnMenu: true,
                            },
                            {
                                field: 'alert',
                                renderHeader: () => <div />,
                                cellClassName: 'le-table-cell le-table-cell-content-center badge-container',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                disableColumnMenu: true,
                                sortable: false,
                                filterable: false,
                                resizable: false,
                                width: 80,
                                renderCell: (params) => {
                                    if (params.row.alertActive || params.row.adminDocumentsAlertActive) {
                                        return <Badge label={'update'} />;
                                    } else {
                                        return <></>;
                                    }
                                },
                            },
                            {
                                field: 'amount',
                                headerName: 'LOAN AMOUNT',
                                width: 150,
                                valueFormatter: ({ value, row }) => {
                                    const formatter = new Intl.NumberFormat('en-UK', {
                                        style: 'currency',
                                        currency: 'GBP',
                                        minimumFractionDigits: 0,
                                    });
                                    if (true === row.isNewForm) {
                                        return formatter.format(value);
                                    } else {
                                        return formatter.format(value / 100); // Amount in pennies
                                    }
                                },
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'submittedBy',
                                headerName: 'SUBMITTED BY',
                                width: 200,
                                cellClassName: 'le-table-cell',
                            },
                            {
                                field: 'childBroker',
                                headerName: 'BROKER NAME',
                                width: 200,
                                hide: !this.isNetworkBroker(),
                                cellClassName: 'le-table-cell',
                                renderCell: (params) =>
                                    null !== params.row.broker.parentBroker ? params.row.broker.name : '-',
                            },
                            {
                                field: 'mainApplicant',
                                headerName: 'APPLICANT',
                                width: 200,
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'assignedTo',
                                headerName: 'ENQUIRY OWNER',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-assigned-to',
                                headerClassName: 'le-table-header-no-separator',
                                disableColumnMenu: true,
                                filterable: false,
                                resizable: false,
                                renderCell: (params) => {
                                    if (
                                        // Network Broker
                                        (true === this.isNetworkBroker() && null === params.row.broker.parentBroker) ||
                                        // Child Broker
                                        (false === this.isNetworkBroker() &&
                                            false === params.row.broker.isNetwork &&
                                            null !== params.row.broker.parentBroker) ||
                                        // Regular Broker
                                        (false === this.isNetworkBroker() &&
                                            false === params.row.broker.isNetwork &&
                                            null === params.row.broker.parentBroker)
                                    ) {
                                        return (
                                            <>
                                                <div className={'icon'}>
                                                    <PersonIcon />
                                                </div>
                                                <div
                                                    className={`name-${
                                                        params.value[0].id !== 'no_owner' ? 'active' : null
                                                    }`}
                                                >
                                                    {params.value[0].name}
                                                </div>
                                            </>
                                        );
                                    }

                                    return <></>;
                                },
                            },
                            {
                                field: 'createdAt',
                                headerName: 'CREATED',
                                width: 170,
                                valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                            },
                            {
                                field: 'type',
                                headerName: 'LOAN TYPE',
                                width: 360,
                                valueFormatter: ({ value }) => financeTypeEnum.getType(value),
                                cellClassName: 'le-table-cell',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                sortable: false,
                            },
                            {
                                field: 'status',
                                headerName: 'STATUS',
                                width: 200,
                                cellClassName: 'le-table-cell le-table-cell-status',
                                headerClassName: 'le-table-header-no-separator le-table-header-fixed-height',
                                valueFormatter: ({ value }) => statusEnum.getType(value),
                                renderCell: (params) => (
                                    <>
                                        {params.value === STATUS_DRAFT ? (
                                            <div className={'label-draft'}>
                                                {statusEnum.getType(params.value, params.row.type)}
                                            </div>
                                        ) : params.value === STATUS_COMPLETION_REQUESTED ||
                                          params.value === STATUS_COMPLETION_REJECTED ? (
                                            <div>
                                                {statusEnum.getType(params.value, params.row.type)}

                                                <Status status={params.value} />
                                            </div>
                                        ) : (
                                            <>
                                                <div>{statusEnum.getType(params.value, params.row.type)}</div>
                                                <div>
                                                    <Status status={params.value} />
                                                </div>
                                            </>
                                        )}
                                    </>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        deleteOnConfirm: (loanEnquiryId) => {
            dispatch(actionDelete(loanEnquiryId));
        },
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
        handleFavouriteMatchChange: (lenderLoanEnquiryId, loanEnquiryId, isFavourite) =>
            dispatch(actionUpdateMatchFavourite(lenderLoanEnquiryId, loanEnquiryId, isFavourite)),
    };
};

export default compose(
    withRouter,
    withSession,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_INTERMEDIARY])
)(TableStyleContainer);
