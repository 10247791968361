export const REDUCER_NAMESPACE = 'joyride';

// Actions
export const SET_JOYRIDE_TYPE = `${REDUCER_NAMESPACE}.type`;
export const SET_JOYRIDE_ACTIVE = `${REDUCER_NAMESPACE}.active`;

// Reducer
const initialState = {
    joyrideType: null,
    joyrideActive: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_JOYRIDE_TYPE:
            return {
                ...state,
                joyrideType: action.joyrideType,
            };

        case SET_JOYRIDE_ACTIVE:
            return {
                ...state,
                joyrideActive: action.joyrideActive,
            };

        default:
            return { ...state };
    }
};

// Default export reducer
export default reducer;

// Action Creators
export const actionSetJoyrideType = (joyrideType) => {
    return {
        type: SET_JOYRIDE_TYPE,
        joyrideType,
    };
};
export const actionSetJoyrideActive = (joyrideActive) => {
    return {
        type: SET_JOYRIDE_ACTIVE,
        joyrideActive,
    };
};

// Selectors
export const selectorGetJoyrideType = (state, name) => {
    return state[REDUCER_NAMESPACE].joyrideType;
};
export const selectorGetJoyrideActive = (state, name) => {
    return state[REDUCER_NAMESPACE].joyrideActive;
};
