import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { goBack, push } from 'react-router-redux';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../apps/notifications/sagas/container';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { sagaActionLogout } from '../../apps/authentication/sagas/login';
import { ROUTE_ANON_LOGIN } from '../../pitch4_routing';

class NoQueryMutation extends React.Component {
    render() {
        let { renderFormFunction, mutationQuery } = this.props;

        return (
            <Mutation mutation={mutationQuery}>
                {(mutate) => {
                    return renderFormFunction(mutate, this.props);
                }}
            </Mutation>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(sagaActionLogout(ROUTE_ANON_LOGIN)),
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

NoQueryMutation.propTypes = {
    renderFormFunction: PropTypes.func.isRequired,
    mutationQuery: PropTypes.any.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(NoQueryMutation);
