import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { change } from 'redux-form';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SLAM } from '../../../../authentication/enum/Roles/NameEnum';
import FormWizard from '../../../../form_wizard/containers/FormWizardContainer';
import { Loading } from 'pitch4_layout/components/waiting';
import { SubmitSummary } from '../components/SubmitSummary';
import {
    actionGetMatchingCriteriaConfig,
    actionGetStoreDataMeta,
    actionResetFields,
    actionSubmitCreateForm,
    NAMESPACE,
    selectorGetStoredDataMetaValues,
} from '../redux';
import { SECTION_BUSINESS_FINANCE } from '../constants';
import { FieldRow } from '../components/FieldRow';
import { globalValidator } from '../validation/global_validator';
import { SECTION_SUBMIT } from '../../../../form_wizard/constants';
import GenericFormSection from '../../../../form_wizard/components/GenericFormSection';
import { Container, Item } from '../../../../../pitch4_layout/components/div';
import { ScreenTitle } from '../../../../../pitch4_layout';
import { mapFactsForSection } from '../utils/mapFactsForSection';
import { pruneSections } from '../../../../form_wizard/utils/pruneSections';
import { selectorGetConfig, selectorGetFormStack } from '../../../../form_wizard/redux';
import { actionSetJoyrideType } from '../../../../joyride/redux';
import { SLAM_BUSINESS_STREAM_CREATION } from '../../../../joyride/config';

const FORM_NAME = 'slam-create-business-stream';

class CreateContainer extends React.Component {
    componentDidMount() {
        const { getMatchingCriteriaConfig, getStoredDataMeta, setJoyrideType } = this.props;

        // Retrieve the config from the API
        getMatchingCriteriaConfig();

        // Retrieve the stored data meta from the API
        getStoredDataMeta();
        setJoyrideType(SLAM_BUSINESS_STREAM_CREATION);
    }

    render() {
        const { storedDataMetas, matchingCriteriaConfig, submitFormHandler, changeValue, resetFields } = this.props;

        if (_.isEmpty(matchingCriteriaConfig) || _.isEmpty(storedDataMetas)) {
            return <Loading />;
        }

        return (
            <React.Fragment>
                <Container justifyContent={`space-between`} spacing={4}>
                    <Item>
                        <ScreenTitle title={`Create New Stream`} />
                    </Item>
                </Container>
                <FormWizard
                    config={matchingCriteriaConfig}
                    storedDataMetas={storedDataMetas}
                    formName={FORM_NAME}
                    reduxNamespace={NAMESPACE}
                    stepsTriggerField={'LOAN_TYPE'}
                    submitSummaryComponent={SubmitSummary}
                    submitSummaryProps={{
                        textMainHeading: 'Please review and confirm',
                        textSubtitle:
                            "If you need to amend anything then you can either hit 'Back' or just click on the section headings in the menu above.",
                        textRoundup:
                            "If you're happy with your criteria then simply give the business stream a name and hit 'Submit'.",
                    }}
                    submitFormHandler={submitFormHandler}
                    formSectionRender={(props) => <GenericFormSection {...props} fieldRowComponent={FieldRow} />}
                    fieldValueSelector={(value) => {
                        const operand = value ? value.operand : null;

                        if (operand !== undefined && operand !== null) {
                            return _.isArray(operand) ? operand[0] : operand;
                        }

                        return null;
                    }}
                    reduxFormProps={{
                        validate: globalValidator,
                    }}
                    preSubmitHandler={pruneSections(this.props.formStack, mapFactsForSection)}
                    changeValue={changeValue}
                    resetFields={resetFields}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        formStack: selectorGetFormStack(NAMESPACE)(state),
        matchingCriteriaConfig: selectorGetConfig(NAMESPACE)(state),
        storedDataMetas: selectorGetStoredDataMetaValues(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMatchingCriteriaConfig: () => dispatch(actionGetMatchingCriteriaConfig()),
        submitFormHandler: (prunedValues, version) => {
            const name = _.get(prunedValues, [SECTION_SUBMIT, 'businessStream']);

            // Remove forms that aren't specific to a business stream submission
            delete prunedValues[SECTION_SUBMIT];

            dispatch(
                actionSubmitCreateForm(
                    name,
                    prunedValues,
                    _.get(prunedValues, [SECTION_BUSINESS_FINANCE]) !== undefined,
                    version
                )
            );
        },
        getStoredDataMeta: () => dispatch(actionGetStoreDataMeta()),
        changeValue: (field, value) => {
            dispatch(change(FORM_NAME, field, value));
        },
        resetFields: (section, fieldNames) => {
            dispatch(actionResetFields(FORM_NAME, section, fieldNames));
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SLAM])
)(CreateContainer);
