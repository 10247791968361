import React, { useEffect, useState } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, CircularProgress, Grid, Hidden } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import { getDashboardListNewAccounts } from '../../api/get_dashboard_list_new_accounts';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { initialLELoadCount, loadMoreLECount } from './config';
import NewAccountCard from '../NewAccountCard';

export default function NewAccounts(props) {
    const { expanded, handleChange, setNewAccountsCount } = props;
    const [requestCursor, setRequestCursor] = useState(null);
    const [nextPage, setNextPage] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [data, setData] = useState({
        intermediaryCount: 0,
        borrowerCount: 0,
        totalCount: 0,
        leads: [],
    });

    // Component did mount
    useEffect(() => {
        getData();
    }, []);

    const getData = () =>
        getDashboardListNewAccounts(initialLELoadCount, requestCursor).then((resp) => {
            setNewAccountsCount(resp.users.totalCount);
            setData({
                ...data,
                ...{
                    intermediaryCount: resp.users.totalIntermediariesCount || 0,
                    borrowerCount: resp.users.totalBorrowersCount || 0,
                    totalCount: resp.users.totalCount,
                    leads: resp.users.edges,
                },
            });
            setRequestCursor(resp.users.pageInfo.endCursor);
            setNextPage(resp.users.pageInfo.hasNextPage);
        });

    const loadMore = () => {
        setLoadingMore(true);
        getDashboardListNewAccounts(loadMoreLECount, requestCursor).then((resp) => {
            setRequestCursor(resp.users.pageInfo.endCursor);
            setNextPage(resp.users.pageInfo.hasNextPage);
            setData({
                ...data,
                ...{ leads: [...data.leads, ...resp.users.edges] },
            });
            setLoadingMore(false);
        });
    };

    const loadLess = () => {
        setRequestCursor(null);
        setNextPage(true);
        setLoadingMore(true);
        getDashboardListNewAccounts(initialLELoadCount, null).then((resp) => {
            setNewAccountsCount(resp.users.totalCount);
            setData({
                ...data,
                ...{
                    intermediaryCount: resp.users.totalIntermediariesCount || 0,
                    borrowerCount: resp.users.totalBorrowersCount || 0,
                    totalCount: resp.users.totalCount,
                    leads: resp.users.edges,
                },
            });
            setRequestCursor(resp.users.pageInfo.endCursor);
            setNextPage(resp.users.pageInfo.hasNextPage);
            setLoadingMore(false);
        });
    };

    return (
        <Accordion
            id={'new-accounts'}
            className={'new-accounts'}
            elevation={1}
            expanded={expanded === 'new-accounts'}
            onChange={handleChange('new-accounts')}
            style={{ marginBottom: 20, borderTopLeftRadius: 20, borderTopRightRadius: 20 }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-new-accounts-content"
                id="panel-new-accounts-header"
                className={'summary'}
                style={{ borderTopLeftRadius: 20 }}
            >
                <Grid
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className={'header-container'}
                    item={true}
                    style={{ display: 'flex' }}
                >
                    <Grid lg={2} md={2} sm={6} xs={12} className={'new-accounts'} item={true}>
                        <div>New Accounts</div>
                        <div className={'value'}>{data.totalCount}</div>
                    </Grid>
                    <Hidden xsDown>
                        <Grid lg={2} md={2} sm={3} xs={3} className={'intermediary-leads'} item={true}>
                            <div>Intermediaries</div>
                            <div className={'value'}>{data.intermediaryCount}</div>
                        </Grid>
                        <Grid lg={2} md={2} sm={3} xs={3} className={'borrower-leads'} item={true}>
                            <div>Borrowers</div>
                            <div className={'value'}>{data.borrowerCount}</div>
                        </Grid>
                    </Hidden>
                    <Hidden smDown>
                        <Grid lg={6} md={6} sm={6} xs={6} className={'total-value'} item={true} />
                    </Hidden>
                </Grid>
            </AccordionSummary>
            <AccordionDetails className={'content'}>
                <Grid lg={12} md={12} sm={12} xs={12} className={'content-container'} item={true}>
                    <Hidden smDown>
                        <Grid lg={2} md={2} sm={6} xs={6} className={'new-accounts'} item={true} />
                    </Hidden>
                    <Grid lg={10} md={10} sm={10} xs={10} className={'content-leads'} item={true}>
                        <Grid container lg={12} md={12} item={true}>
                            <Grid container>
                                {data.leads.map(({ node }, index) => (
                                    <NewAccountCard key={index} user={node} />
                                ))}
                                {data.totalCount > initialLELoadCount ? (
                                    true === nextPage ? (
                                        <Grid container style={{ margin: 10 }}>
                                            <Button
                                                className={'btn-load-more-less'}
                                                variant={'contained'}
                                                color={'primary'}
                                                onClick={loadMore}
                                            >
                                                {true === loadingMore ? (
                                                    <CircularProgress
                                                        size={18}
                                                        style={{ color: '#ffffff', marginLeft: 5 }}
                                                    />
                                                ) : (
                                                    'Load more'
                                                )}
                                            </Button>
                                        </Grid>
                                    ) : (
                                        <Grid container style={{ margin: 10 }}>
                                            <Button
                                                className={'btn-load-more-less'}
                                                variant={'contained'}
                                                color={'primary'}
                                                onClick={loadLess}
                                            >
                                                Load less
                                            </Button>
                                        </Grid>
                                    )
                                ) : null}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
}
