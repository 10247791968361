import { call, put, takeLatest } from 'redux-saga/effects';

import {
    GET_MATCHING_CRITERIA_CONFIG,
    NAMESPACE,
    RESET,
    SUBMIT_DRAFT_FORM,
    SUBMIT_FORM,
    SUBMIT_PUBLISH_FORM,
    SUBMIT_FORM_ERROR,
} from '../redux';
import { actionSetConfig } from '../../../form_wizard/redux';
import { baseYields } from '../../../form_wizard/sagas';
import { getConfig } from '../api/get_config';
import { makeLoanEnquiry } from '../api/make_loan_enquiry';
import { publishLoanEnquiry } from '../api/publish_loan_enquiry';
import { SAGA_ACTION_ERROR, SAGA_ACTION_SUCCESS } from '../../../notifications/sagas/container';
import _ from 'lodash';
import { push } from 'react-router-redux';
import { path, ROUTE_BORROWER_LOAN_ENQUIRY_VIEW, ROUTE_BORROWER_LOAN_ENQUIRIES } from 'pitch4_routing';
import { getEnquiry } from '../api/get_enquiry';
import { actionSetApiGetLoanEnquiryResponse, INITIATE } from '../redux';
import { editLoanEnquiryDraft } from '../api/edit_loan_enquiry_draft';
import { makeDraftLoanEnquiry } from '../api/make_loan_enquiry_draft';
import { HUBSPOT_CHAT_OPEN_KEY, HUBSPOT_CHAT_OPEN_VALUE_NEW_LE } from '../../../../pitch4_utils/HubSpotSettings';
import SessionManager from '../../../../pitchblack_react_utils/session/SessionManager';

function* getMatchingCriteriaConfig() {
    const response = yield call(getConfig);

    switch (response.getClassName()) {
        case 'GetConfigSuccess':
            const config = response.getData();
            // Set the form initial values
            yield put(actionSetConfig(NAMESPACE)(config));
            break;
        case 'GetConfigError':
        default:
            break;
    }
}

function* submitForm(action) {
    const { acceptedTermsVersion, reviewMatches, formSections, isBusinessFinance, version, draftId } = action;
    const sessionManager = new SessionManager();
    const userRole = sessionManager.getMostSeniorRole().label;
    const userEmail = sessionManager.getSession().getEmail();

    const response = yield call(
        makeLoanEnquiry,
        acceptedTermsVersion,
        reviewMatches,
        formSections,
        isBusinessFinance,
        version,
        draftId
    );

    switch (response.getClassName()) {
        case 'MakeLoanEnquirySuccess':
            yield put({ type: RESET });
            yield put({ type: SAGA_ACTION_SUCCESS, message: 'Successfully created loan enquiry' });
            const keys = _.keys(response.getData());
            const loanEnquiryId = _.get(response.getData(), [
                keys[0],
                'createLoanEnquirySubmission',
                'loanEnquiry',
                'id',
            ]);
            if ('prod' === window._env_.APP_ENV) {
                window.dataLayer.push({
                    event: 'enquiry_submitted',
                    userDetails: {
                        email: userEmail,
                        userType: userRole,
                        userID: sessionManager.getSession().getId(),
                        timestamp: new Date().toISOString(),
                    },
                    contractSigned: false,
                    isSubmittingDraftEnquiry: false,
                    loanEnquiryId: loanEnquiryId,
                });
            }
            yield put(
                push(
                    path(`${ROUTE_BORROWER_LOAN_ENQUIRY_VIEW}`, {
                        loanEnquiryId: loanEnquiryId,
                    }) + `?${HUBSPOT_CHAT_OPEN_KEY}=${HUBSPOT_CHAT_OPEN_VALUE_NEW_LE}`
                )
            );
            break;
        case 'MakeLoanEnquiryErrors':
            yield put({
                type: SAGA_ACTION_ERROR,
                message: 'There was a problem with the submission. Unable to create loan enquiry at this time.',
            });
            yield put({ type: SUBMIT_FORM_ERROR });
            break;

        default:
            break;
    }
}

function* submitPublishForm(action) {
    const {
        acceptedTermsVersion,
        reviewMatches,
        formSections,
        isBusinessFinance,
        version,
        loanEnquiryId = '',
    } = action;

    const response = yield call(
        publishLoanEnquiry,
        acceptedTermsVersion,
        reviewMatches,
        formSections,
        isBusinessFinance,
        version,
        loanEnquiryId
    );

    switch (response.getClassName()) {
        case 'PublishLoanEnquirySuccess':
            yield put({ type: RESET });
            yield put({ type: SAGA_ACTION_SUCCESS, message: 'Successfully published loan enquiry' });
            const sessionManager = new SessionManager();
            const userRole = sessionManager.getMostSeniorRole().label;
            const userEmail = sessionManager.getSession().getEmail();

            const keys = _.keys(response.getData());
            const loanEnquiryId = _.get(response.getData(), [
                keys[0],
                'createLoanEnquirySubmission',
                'loanEnquiry',
                'id',
            ]);
            if ('prod' === window._env_.APP_ENV) {
                window.dataLayer.push({
                    event: 'enquiry_submitted',
                    userDetails: {
                        email: userEmail,
                        userType: userRole,
                        userID: sessionManager.getSession().getId(),
                        timestamp: new Date().toISOString(),
                    },
                    contractSigned: false,
                    isSubmittingDraftEnquiry: false,
                    loanEnquiryId: loanEnquiryId,
                });
            }
            yield put(
                push(
                    path(ROUTE_BORROWER_LOAN_ENQUIRY_VIEW, {
                        loanEnquiryId: _.get(response.getData(), [
                            keys[0],
                            'createLoanEnquirySubmission',
                            'loanEnquiry',
                            'id',
                        ]),
                    })
                )
            );
            break;
        case 'PublishLoanEnquiryErrors':
            yield put({
                type: SAGA_ACTION_ERROR,
                message: 'There was a problem with the submission. Unable to publish loan enquiry at this time.',
            });
            yield put({ type: SUBMIT_FORM_ERROR });
            break;

        default:
            break;
    }
}

function* submitDraftForm(action) {
    const {
        acceptedTermsVersion,
        reviewMatches,
        formSections,
        isBusinessFinance,
        version,
        loanEnquiryId = '',
    } = action;

    let response;
    if (loanEnquiryId !== '') {
        response = yield call(
            editLoanEnquiryDraft,
            acceptedTermsVersion,
            reviewMatches,
            formSections,
            isBusinessFinance,
            version,
            loanEnquiryId
        );
    } else {
        response = yield call(
            makeDraftLoanEnquiry,
            acceptedTermsVersion,
            reviewMatches,
            formSections,
            isBusinessFinance,
            version
        );
    }

    switch (response.getClassName()) {
        case 'MakeLoanEnquirySuccess':
            yield put({ type: RESET });
            yield put({ type: SAGA_ACTION_SUCCESS, message: 'Successfully created the draft loan enquiry' });
            yield put(push(path(ROUTE_BORROWER_LOAN_ENQUIRIES)));
            break;
        case 'EditLoanEnquiryDraftSuccess':
            yield put({ type: RESET });
            yield put({ type: SAGA_ACTION_SUCCESS, message: 'Successfully saved the draft loan enquiry' });
            yield put(push(path(ROUTE_BORROWER_LOAN_ENQUIRIES)));
            break;
        case 'MakeLoanEnquiryErrors':
        case 'EditLoanEnquiryDraftErrors':
            yield put({
                type: SAGA_ACTION_ERROR,
                message: 'There was a problem with the submission. Unable to create draft loan enquiry at this time.',
            });
            yield put({ type: SUBMIT_FORM_ERROR });
            break;

        default:
            break;
    }
}

function* getLoanEnquiry(action) {
    const { loanEnquiryId } = action;
    const response = yield call(getEnquiry, loanEnquiryId);
    yield put(actionSetApiGetLoanEnquiryResponse(response));
}

//Main Saga
function* main() {
    yield takeLatest(GET_MATCHING_CRITERIA_CONFIG, getMatchingCriteriaConfig);
    yield takeLatest(SUBMIT_FORM, submitForm);
    yield takeLatest(SUBMIT_PUBLISH_FORM, submitPublishForm);
    yield takeLatest(SUBMIT_DRAFT_FORM, submitDraftForm);
    yield takeLatest(INITIATE, getLoanEnquiry);
    // Core sagas - see form_wizard/sagas/index.js
    yield* baseYields(NAMESPACE);
}

export default main;
