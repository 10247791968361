import { Grid } from '@material-ui/core';
import Card from './QuickStatsCard';
import React from 'react';

export default function (props) {
    const { data } = props;

    const stats = [
        {
            label: <span>Moved To Application Last Month</span>,
            count: data.movedToApplicationLastMonth,
        },
        {
            label: <span>Moved To Application Last 30 Days</span>,
            count: data.movedToApplicationLast30Days,
        },
        {
            label: <span>Moved To Completed Last Month</span>,
            count: data.movedToCompletedLastMonth,
        },
        {
            label: <span>Moved To Completed Last 30 Days</span>,
            count: data.movedToCompletedLast30Days,
        },
    ];
    return (
        <Grid container className={'basic-stats-container'} spacing={3}>
            {stats.map((stat) => (
                <Grid item xs={12} sm md lg xl>
                    <Card count={stat.count} label={stat.label} />
                </Grid>
            ))}
        </Grid>
    );
}
