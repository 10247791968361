import { STATUS_LEGALS, STATUS_VALUATION } from '../admin/intermediary/enum/LoanEnquiries/StatusEnum';
import StatusEnum from '../admin/pitch4admin/enum/LoanEnquiries/StatusEnum';

const statusEnum = new StatusEnum();

export const getStatusFilterName = (status) => {
    let additionalStatus = '';
    if (status === STATUS_LEGALS) {
        additionalStatus = ' / Approval';
    }
    if (status === STATUS_VALUATION) {
        additionalStatus = ' / Payout';
    }

    return statusEnum.getType(status) + additionalStatus;
};
