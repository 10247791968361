import React from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import ModalLayout from '../../../../pitch4_modal/src/component/ModalLayout';
import { CenteringContainer, Item, RowItem } from '../../../../pitch4_layout/components/div';
import FormMessage, { MESSAGE_ERROR } from '../../../../pitch4_forms/components/FormMessage';
import { PrimaryButton, SecondaryButton } from '../../../../pitch4_layout/components/buttons';
import completionImage from '../../../../assets/images/dashboard-completion.svg';
import { selectorAreErrorsVisible, selectorGetSubmitted, selectorSetStatusSubmissionErrors } from '../redux';
import { PITCH4_COMPLETION_PROCESS } from '../../../../bootstrap/constants/constants';

const CompletionRequestedRespondForm = ({
    errors = [],
    errorsVisible,
    handleSubmit,
    handleConfirm,
    handleReject,
    submitting,
    submitted,
    lenderName,
    lenderLink,
}) => {
    return (
        <ModalLayout contentClassName="modal-content-one-pane">
            <CenteringContainer>
                <Item xs={12} sm={8} className={'content-container'}>
                    <img className="info-pane-image" src={completionImage} alt="Completion Status" />
                    <h3 className="typography-card-title">Has this Enquiry been completed?</h3>
                    <p className="typography-default">
                        <a href={lenderLink} className="typography-default-link">
                            {lenderName}
                        </a>{' '}
                        has marked this Enquiry as being completed. To close this enquiry and enter it into a completed
                        state, we need you to confirm this below.
                    </p>
                    <p className="typography-default">
                        Need to understand what counts as complete?{' '}
                        <a
                            className="typography-default-link"
                            href={PITCH4_COMPLETION_PROCESS}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Click here
                        </a>
                    </p>

                    {errorsVisible &&
                        errors.map((error) => <FormMessage type={MESSAGE_ERROR} messages={error} key={error} />)}
                    <form onSubmit={handleSubmit}>
                        <RowItem>
                            <CenteringContainer className={'button-panel'}>
                                <PrimaryButton
                                    onClick={handleConfirm}
                                    type="submit"
                                    name={'next-btn'}
                                    disabled={submitting || submitted}
                                >
                                    Confirm
                                </PrimaryButton>
                                <SecondaryButton
                                    onClick={handleReject}
                                    name={'cancel-btn'}
                                    disabled={submitting || submitted}
                                >
                                    Reject
                                </SecondaryButton>
                            </CenteringContainer>
                        </RowItem>
                    </form>
                </Item>
            </CenteringContainer>
        </ModalLayout>
    );
};

CompletionRequestedRespondForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    handleReject: PropTypes.func.isRequired,
    errors: PropTypes.array,
    errorsVisible: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired,
    submitted: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    lenderName: PropTypes.string.isRequired,
    lenderLink: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    return {
        submitted: selectorGetSubmitted(state),
        errors: selectorSetStatusSubmissionErrors(state),
        errorsVisible: selectorAreErrorsVisible(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: 'completion_requested_respond',
    })
)(CompletionRequestedRespondForm);
