import createP4ApolloFetch from 'bootstrap/middlewares';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });

export const mutation = `
    mutation setLenderLoanEnquiryStatus(
        $id: ID!
        $input: setLenderLoanEnquiryStatusInput!
    ) {
        setLenderLoanEnquiryStatus(id: $id, input: $input) {
            lead {
                id
            }
        }
    }
`;

export const setLeadStatus = (leadId, newStatus, metadata) => {
    return apolloFetch({
        query: mutation,
        variables: {
            id: leadId,
            input: {
                newStatus,
                metadata,
            },
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};
