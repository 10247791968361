import React, { useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Accordion, AccordionDetails, Button, CircularProgress } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { createTermsMatrix } from './api/export_matrix';
import TermsAndFeesEnum from '../../../pitch4_enum/enum/TermsAndFeesEnum';
import ConfirmationModal from './ConfirmationModal';
import { sagaActionNotificationSetError } from '../../../apps/notifications/sagas/container';
import { BRAND_MAIN_CONTACT } from '../../../bootstrap/constants/constants';

export default function StepTwo(props) {
    const {
        setStep,
        blankLenderSpaces,
        matrixLenders,
        setModalClose,
        setModalComponent,
        handleLenderSelection,
        loanEnquiryId,
        uri,
        dispatch,
    } = props;
    const [expanded, setExpanded] = useState('');
    const [exporting, setExporting] = useState(false);
    const termsEnum = new TermsAndFeesEnum();
    const [terms] = useState(termsEnum.getMatrixTerms());

    const handleSectionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : '');
    };

    const handleOnChange = (event) => {
        const arr = event.target.value.split('#');
        const term = terms[arr[0]].find((item) => item.code === arr[1]);
        term['selected'] = event.target.checked;
    };

    const handleExport = () => {
        setExporting(true);

        createTermsMatrix(uri, loanEnquiryId, blankLenderSpaces, matrixLenders, terms).then((resp) => {
            handleLenderSelection(null);
            setModalClose();

            if (resp.errors) {
                dispatch(
                    sagaActionNotificationSetError(
                        'Export failed. Please try again or contact our customer support on ' + BRAND_MAIN_CONTACT
                    )
                );
            } else {
                setModalComponent(
                    <ConfirmationModal
                        setModalClose={setModalClose}
                        setModalComponent={setModalComponent}
                        document={resp.data.createTermsMatrix}
                    />
                );
            }
        });
    };

    const handleBack = () => setStep(1);

    return (
        <>
            <div className={'sub-header'}>
                Please confirm the <strong>terms</strong> to be included in this terms matrix <br />
                and the number of blank <strong>terms</strong> rows to include.
            </div>
            <div className={'step-two'}>
                {Object.entries(terms).map(([termType, terms], index) => {
                    return (
                        <Accordion
                            expanded={expanded === termType}
                            onChange={handleSectionChange(termType)}
                            key={`0-${index}`}
                        >
                            <AccordionSummary key={`1-${index}`} expandIcon={<ExpandMoreIcon key={`3-${index}`} />}>
                                <Typography key={`2-${index}`} className={'term-type'}>
                                    {termsEnum.getMatrixTermsSectionName(termType)}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className={'th-terms'}>Terms</th>
                                            <th className={'th-include'}>Include?</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {terms.map((term, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td width={'70%'}>{term.name}</td>
                                                    <td width={'30%'} className={'term-include'}>
                                                        <Checkbox
                                                            color={'primary'}
                                                            defaultChecked
                                                            onChange={handleOnChange}
                                                            value={`${termType}#${term.code}`}
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
            <div className={'actions-container'}>
                <Button
                    disabled={exporting}
                    className={'btn-back'}
                    color={'primary'}
                    variant={'contained'}
                    onClick={handleBack}
                >
                    Back
                </Button>
                <Button
                    disabled={exporting}
                    className={'btn-export'}
                    color={'primary'}
                    variant={'contained'}
                    onClick={handleExport}
                >
                    Export
                    {exporting && <CircularProgress size={20} style={{ marginLeft: 5 }} />}
                </Button>
            </div>
        </>
    );
}
