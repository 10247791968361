import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from './Checkbox';

class CheckboxMultiValue extends React.Component {
    render() {
        let {
            name,
            value,
            label = '',
            wrapperStyle = { paddingRight: '10px' },
            style = { padding: 0 },
            onChangeFunc,
            ...rest
        } = this.props;

        return (
            <Checkbox
                {...rest}
                name={[name, value].join('.')}
                label={label}
                style={style}
                wrapperStyle={wrapperStyle}
                onChangeFunc={onChangeFunc}
            />
        );
    }
}

CheckboxMultiValue.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    style: PropTypes.object,
    wrapperStyle: PropTypes.object,
    onChangeFunc: PropTypes.func,
};

export default CheckboxMultiValue;
