class Option {
    constructor() {
        this.label = null;
        this.value = null;
    }

    getLabel = () => this.label;

    setLabel = (label) => {
        this.label = label;
        return this;
    };

    getValue = () => this.value;

    setValue = (value) => {
        this.value = value;
        return this;
    };

    toConfig = () => {
        return {
            label: this.getLabel(),
            value: this.getValue(),
        };
    };
}

export default Option;
