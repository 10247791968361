import React from 'react';

export default function AnonDots(props) {
    const { count } = props;

    return (
        <div className={'anon-dot-container'}>
            {Array(count)
                .fill(null)
                .map((value, index) => (
                    <div className={'anon-dot'} key={index} />
                ))}
        </div>
    );
}
