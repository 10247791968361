import BaseEnum from 'pitchblack_react_utils/enums/BaseEnum';

export const HIRE_PURCHASE = 'hire_purchase';
export const LEASE = 'lease';

class BusinessNonRefinanceAssetEnum extends BaseEnum {
    getTypes = () => {
        return {
            [HIRE_PURCHASE]: 'Hire Purchase',
            [LEASE]: 'Lease',
        };
    };
}

export default BusinessNonRefinanceAssetEnum;
