import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Helmet from 'react-helmet';
import logo from '../../assets/images/logo.svg';
import { selectorBorrowerRegisterStep } from '../../apps/registration/redux/step';
import { ROUTE_ANON_LOGIN } from '../../pitch4_routing';
import { CenteringContainer, Container, Item } from '../../pitchblack_react_utils/layout';
import { BRAND_NAME, TOS_URL, WEB_HOST } from '../../bootstrap/constants/constants';

const DefaultRegistrationLayout = ({ children, notifications }) => {
    return (
        <>
            <Helmet>
                <title>{BRAND_NAME} | property & business finance | free comparison</title>
            </Helmet>
            <Container className={'registration borrower-default-registration'}>
                <Container xs={12} spacing={0}>
                    <Item xs={12} className={'page-header'}>
                        <img className={'logo'} src={logo} alt={`${BRAND_NAME} &copy; Borrowing Made Simple`} />
                    </Item>
                </Container>
                <Container xs={12} spacing={0} alignItems={'center'} justifyContent={'center'}>
                    <Item md={4}>
                        <CenteringContainer>
                            <h1 className={'form-heading'}>Let's Start...</h1>
                        </CenteringContainer>
                        <CenteringContainer>
                            <h2>Sign Up as a Borrower to Secure Funding Today.</h2>
                        </CenteringContainer>
                    </Item>
                    <Item md={4}>
                        <Container className={'container'}>{children}</Container>
                        {notifications}
                    </Item>
                    <Item md={4} />
                </Container>
                <Container xs={12} spacing={0} className={'footer-container'}>
                    <CenteringContainer className={'links'}>
                        <p>
                            Already have an account?{' '}
                            <a id="login" href={ROUTE_ANON_LOGIN}>
                                Click here to login instead
                            </a>
                        </p>
                    </CenteringContainer>
                    <CenteringContainer className={'links'}>
                        <a href={WEB_HOST}>About {BRAND_NAME}</a>
                        <a href={`${WEB_HOST}/contact-us`}>Contact Us</a>
                        <a href={TOS_URL} target={'_blank'} rel={'noreferrer'}>
                            Terms & Conditions
                        </a>
                    </CenteringContainer>
                </Container>
            </Container>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        step: selectorBorrowerRegisterStep(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

DefaultRegistrationLayout.propTypes = {
    notifications: PropTypes.element.isRequired,
    children: PropTypes.any.isRequired,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(DefaultRegistrationLayout);
