import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import CommercialFundingType, {
    COMMERCIAL_FUNDING_TYPE,
    COMMERCIAL_PORTFOLIO_FUNDING_TYPE,
    HMO_FUNDING_TYPE,
    LAND_WITHOUT_PLANNING_FUNDING_TYPE,
    MIXED_PORTFOLIO_FUNDING_TYPE,
    RESIDENTIAL_FUNDING_TYPE,
    RESIDENTIAL_FUNDING_TYPE_RENTED,
    RESIDENTIAL_FUNDING_TYPE_SECOND_CHARGE,
    RESIDENTIAL_PORTFOLIO_FUNDING_TYPE,
    SEMI_COMMERCIAL_FUNDING_TYPE,
} from '../../../enums/Shared/CommercialFundingType';
import BorrowerQuestions, {
    QUESTION_FUNDING_TYPE,
    QUESTION_PORTFOLIO_REFINANCE,
    QUESTION_PROPERTY_TYPE,
    QUESTION_PROPERTY_TYPE_MEZZANINE,
    QUESTION_PROPERTY_TYPE_PORTFOLIO,
    QUESTION_PROPERTY_TYPE_REFURBISHMENT,
    QUESTION_RESIDENTIAL_REFINANCE,
    QUESTION_SECOND_CHARGE_REFINANCE,
} from '../../../enums/Borrower/BorrowerQuestions';
import {
    BRIDGE_TYPE,
    DEVELOPMENT_TYPE,
    MEZZANINE_TYPE,
    PORTFOLIO_TYPE,
    REFURBISHMENT_TYPE,
    SECOND_CHARGE_TYPE,
    TERM_TYPE,
} from '../../../enums/Borrower/BorrowerLoanType';
import Question from '../../Question/containers/Question';
import PortfolioRefinance from '../../../enums/Shared/PortfolioRefinance';
import YesNo from '../../../enums/Shared/YesNo';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';

class CommercialTypeSelectorContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const commercialFundingTypes = new CommercialFundingType().getTypes();
        const hmoOptions = [commercialFundingTypes[HMO_FUNDING_TYPE]];
        let propertyOptions = [
            commercialFundingTypes[RESIDENTIAL_FUNDING_TYPE],
            commercialFundingTypes[SEMI_COMMERCIAL_FUNDING_TYPE],
            commercialFundingTypes[COMMERCIAL_FUNDING_TYPE],
        ];
        if (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === SECOND_CHARGE_TYPE) {
            propertyOptions = [
                commercialFundingTypes[RESIDENTIAL_FUNDING_TYPE_SECOND_CHARGE],
                commercialFundingTypes[SEMI_COMMERCIAL_FUNDING_TYPE],
                commercialFundingTypes[COMMERCIAL_FUNDING_TYPE],
            ];
        }
        if (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === TERM_TYPE) {
            propertyOptions = [
                commercialFundingTypes[RESIDENTIAL_FUNDING_TYPE_RENTED],
                commercialFundingTypes[SEMI_COMMERCIAL_FUNDING_TYPE],
                commercialFundingTypes[COMMERCIAL_FUNDING_TYPE],
            ];
        }
        const portfolioOptions = [
            commercialFundingTypes[RESIDENTIAL_PORTFOLIO_FUNDING_TYPE],
            commercialFundingTypes[COMMERCIAL_PORTFOLIO_FUNDING_TYPE],
            commercialFundingTypes[MIXED_PORTFOLIO_FUNDING_TYPE],
        ];
        const developmentBridgingOptions = [commercialFundingTypes[LAND_WITHOUT_PLANNING_FUNDING_TYPE]];
        let renderOptions = [{}];
        if (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE) {
            renderOptions = propertyOptions;
        }
        if (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === PORTFOLIO_TYPE) {
            renderOptions = portfolioOptions;
        }
        if (
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === TERM_TYPE ||
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === BRIDGE_TYPE
        ) {
            renderOptions = _.union(renderOptions, hmoOptions);
        }
        if (
            // this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === DEVELOPMENT_TYPE ||
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === BRIDGE_TYPE
        ) {
            renderOptions = _.union(renderOptions, developmentBridgingOptions);
        }

        const portfolioRefinanceOptions = new PortfolioRefinance().getOptions();
        const yesNoOptions = new YesNo().getTypes();

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        Tell us about the property
                    </Typography>
                </Grid>
                {this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== REFURBISHMENT_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== DEVELOPMENT_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== MEZZANINE_TYPE && (
                        <Question
                            type={questions[QUESTION_PROPERTY_TYPE].type}
                            label={questions[QUESTION_PROPERTY_TYPE].label}
                            questionEnum={'QUESTION_PROPERTY_TYPE'}
                            fieldName={questions[QUESTION_PROPERTY_TYPE].fieldName}
                            placeholder={questions[QUESTION_PROPERTY_TYPE].placeholder}
                            validation={questions[QUESTION_PROPERTY_TYPE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={renderOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    )}
                {this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === REFURBISHMENT_TYPE && (
                    <Question
                        type={questions[QUESTION_PROPERTY_TYPE_REFURBISHMENT].type}
                        label={questions[QUESTION_PROPERTY_TYPE_REFURBISHMENT].label}
                        questionEnum={'QUESTION_PROPERTY_TYPE'}
                        fieldName={questions[QUESTION_PROPERTY_TYPE_REFURBISHMENT].fieldName}
                        placeholder={questions[QUESTION_PROPERTY_TYPE_REFURBISHMENT].placeholder}
                        validation={questions[QUESTION_PROPERTY_TYPE_REFURBISHMENT].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={renderOptions}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
                {this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === DEVELOPMENT_TYPE && (
                    <Question
                        type={questions[QUESTION_PROPERTY_TYPE_REFURBISHMENT].type}
                        label={questions[QUESTION_PROPERTY_TYPE_REFURBISHMENT].label}
                        questionEnum={'QUESTION_PROPERTY_TYPE'}
                        fieldName={questions[QUESTION_PROPERTY_TYPE_REFURBISHMENT].fieldName}
                        placeholder={questions[QUESTION_PROPERTY_TYPE_REFURBISHMENT].placeholder}
                        validation={questions[QUESTION_PROPERTY_TYPE_REFURBISHMENT].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={renderOptions}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
                {this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === MEZZANINE_TYPE && (
                    <Question
                        type={questions[QUESTION_PROPERTY_TYPE_MEZZANINE].type}
                        label={questions[QUESTION_PROPERTY_TYPE_MEZZANINE].label}
                        questionEnum={'QUESTION_PROPERTY_TYPE'}
                        fieldName={questions[QUESTION_PROPERTY_TYPE_MEZZANINE].fieldName}
                        placeholder={questions[QUESTION_PROPERTY_TYPE_MEZZANINE].placeholder}
                        validation={questions[QUESTION_PROPERTY_TYPE_MEZZANINE].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={renderOptions}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
                {this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === PORTFOLIO_TYPE && (
                    <>
                        <Question
                            type={questions[QUESTION_PROPERTY_TYPE_PORTFOLIO].type}
                            label={questions[QUESTION_PROPERTY_TYPE_PORTFOLIO].label}
                            questionEnum={'QUESTION_PROPERTY_TYPE_PORTFOLIO'}
                            fieldName={questions[QUESTION_PROPERTY_TYPE_PORTFOLIO].fieldName}
                            placeholder={questions[QUESTION_PROPERTY_TYPE_PORTFOLIO].placeholder}
                            validation={questions[QUESTION_PROPERTY_TYPE_PORTFOLIO].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={renderOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_PORTFOLIO_REFINANCE].type}
                            label={questions[QUESTION_PORTFOLIO_REFINANCE].label}
                            questionEnum={'QUESTION_PORTFOLIO_REFINANCE'}
                            fieldName={questions[QUESTION_PORTFOLIO_REFINANCE].fieldName}
                            placeholder={questions[QUESTION_PORTFOLIO_REFINANCE].placeholder}
                            validation={questions[QUESTION_PORTFOLIO_REFINANCE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    </>
                )}
                {this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== SECOND_CHARGE_TYPE && (
                        <Question
                            type={questions[QUESTION_RESIDENTIAL_REFINANCE].type}
                            label={questions[QUESTION_RESIDENTIAL_REFINANCE].label}
                            questionEnum={'QUESTION_RESIDENTIAL_REFINANCE'}
                            fieldName={questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName}
                            placeholder={questions[QUESTION_RESIDENTIAL_REFINANCE].placeholder}
                            validation={questions[QUESTION_RESIDENTIAL_REFINANCE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={portfolioRefinanceOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    )}
                {this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === SECOND_CHARGE_TYPE && (
                    <Question
                        type={questions[QUESTION_SECOND_CHARGE_REFINANCE].type}
                        label={questions[QUESTION_SECOND_CHARGE_REFINANCE].label}
                        questionEnum={'QUESTION_SECOND_CHARGE_REFINANCE'}
                        fieldName={questions[QUESTION_SECOND_CHARGE_REFINANCE].fieldName}
                        placeholder={questions[QUESTION_SECOND_CHARGE_REFINANCE].placeholder}
                        validation={questions[QUESTION_SECOND_CHARGE_REFINANCE].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={yesNoOptions}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
            </Grid>
        );
    }
}

export default CommercialTypeSelectorContainer;
