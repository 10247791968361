import React from 'react';
import gql from 'graphql-tag';

class SessionFragment extends React.Component {
    static fragments = {
        session: gql`
            fragment SessionFragmentSession on Session {
                roles {
                    label
                    name
                }
                id
                title
                gender
                given
                family
                authExpiry
                supplementaryData
                realTimeNotifications
                user {
                    userProfile {
                        dateOfBirth
                    }
                    realTimeNotifications
                }
            }
        `,
    };
}

export default SessionFragment;
