import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import { reduxForm } from 'redux-form';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import withSession from 'pitchblack_react_utils/session/withSession';
import { TextField, Checkbox, FieldRowInputOnly } from 'pitch4_forms/components/inputs';
import { isRequiredCheckbox, hasMaxLength255 } from 'pitchblack_react_utils/form/validation';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import { NoQueryMutation } from 'pitch4_forms/components';
import { API_URL } from 'bootstrap/constants/constants';
import { RightAligned } from 'pitch4_layout/components/div';
import { SecondaryButton } from 'pitch4_layout/components/buttons';

class CreateGlobalIgnoreContainer extends React.Component {
    render() {
        let { handleSubmit, submitting, reset } = this.props;
        return (
            <NoQueryMutation
                mutationQuery={gql`
                    mutation CreateLoanEnquiryGlobalIgnores($input: pitch4admin_createLoanEnquiryGlobalIgnoresInput!) {
                        createLoanEnquiryGlobalIgnores(input: $input) {
                            loanEnquiryGlobalIgnore {
                                id
                            }
                        }
                    }
                `}
                renderFormFunction={(mutate, props) => (
                    <form onSubmit={handleSubmit(this.onSubmit(mutate, props))} style={{ padding: 30 }}>
                        <FieldRowInputOnly
                            input={
                                <TextField
                                    name="reason"
                                    placeholder="What is the reason for this ignore?"
                                    disabled={submitting}
                                    validate={[hasMaxLength255]}
                                />
                            }
                        />
                        <FieldRowInputOnly
                            input={
                                <Checkbox
                                    name="confirm"
                                    label="Are you sure you want to ignore this loan enquiry?"
                                    disabled={submitting}
                                    validate={[isRequiredCheckbox]}
                                />
                            }
                        />
                        <RightAligned>
                            <SecondaryButton disabled={submitting} onClick={reset}>
                                Reset
                            </SecondaryButton>
                            <PrimaryButton type="submit" disabled={submitting} style={{ marginRight: 0 }}>
                                Submit
                            </PrimaryButton>
                        </RightAligned>
                    </form>
                )}
            />
        );
    }

    onSubmit = (mutate, props) => (values) => {
        const { inProgress, selectedLoanEnquiries, onSuccess, onFailure } = this.props;
        const { notificationSuccess, notificationError } = props;

        let { confirm, ...formValues } = values;
        inProgress();

        return mutate({
            variables: {
                input: {
                    uuids: selectedLoanEnquiries,
                    ...formValues,
                },
            },
            context: {
                uri: API_URL,
            },
        })
            .then(({ data }) => {
                notificationSuccess('The loan enquiry is now ignored.');
                onSuccess(data);
            })
            .catch((error) => {
                notificationError('Unable to create global ignore.');
                onFailure(error);
            });
    };
}

CreateGlobalIgnoreContainer.propTypes = {
    submitting: PropTypes.bool,
    selectedLoanEnquiries: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    reset: PropTypes.func,
    onSuccess: PropTypes.func.isRequired,
    onFailure: PropTypes.func.isRequired,
};

export default compose(
    reduxForm({
        form: 'administer_loan_enquiries_global_ignore_form',
        enableReinitialize: true,
    }),
    withSession,
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(CreateGlobalIgnoreContainer);
