import React from 'react';
import TermsAndFeesEnum from '../../pitch4_enum/enum/TermsAndFeesEnum';
import { LOAN_TYPE_BUSINESS_FINANCE } from '../../pitch4_enum/enum/LoanTypeEnum';
import { getStoredDataValueFromArray } from '../../apps/view_loan_enquiry/HelperFunctions';
import { LOAN_TYPE } from '../../pitch4_enum/enum/FactEnum';
import { Grid } from '@material-ui/core';
import AddCircleOutlinedIcon from '@material-ui/icons/AddCircleOutlined';
import moment from 'moment';
import { getValue, getTermName } from './Utils';

export default function DetailTerms(props) {
    const { matchSelected, loanEnquiry } = props;
    const termsEnum = new TermsAndFeesEnum();
    const product = getStoredDataValueFromArray(loanEnquiry.storedData, LOAN_TYPE);
    const getDefaultProduct = () => (product === LOAN_TYPE_BUSINESS_FINANCE ? 'business-finance' : 'term-loan');
    const getDefaultTerms = () => termsEnum.getType(getDefaultProduct(product)).terms;
    const termsUpdatedAt = matchSelected.lenderTerms ? JSON.parse(matchSelected.lenderTerms).updatedAt : null;
    const terms = !matchSelected.lenderTerms ? getDefaultTerms() : JSON.parse(matchSelected.lenderTerms).terms;
    const topTerms = terms.filter((item) => item.termType === 'details');

    return (
        <div>
            <Grid container>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                    {topTerms.map((term, index) => (
                        <Grid item className={'term-container'} key={index}>
                            <div className={'header'}>
                                <AddCircleOutlinedIcon className={'icon'} /> {getTermName(term)}
                            </div>
                            <div className={`value ${!term.value ? 'empty' : ''}`}>{getValue(term)}</div>
                        </Grid>
                    ))}
                </Grid>
                {termsUpdatedAt && (
                    <Grid item lg={6} md={6} sm={12} xs={12} className={'detail-terms-updated-at-container'}>
                        <div className={'box'}>
                            <div>Last Updated</div>
                            <div className={'date'}>{moment(termsUpdatedAt).format('HH:mm Do MMMM YYYY')}</div>
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
}
