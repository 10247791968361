import { BaseEnum } from 'pitch4_enum';

export const TYPE_PURCHASE = 'purchase';
export const TYPE_REFINANCE = 'refinance';
export const TYPE_NONE = 'none';

class LoanAssetTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_PURCHASE]: 'Purchase',
            [TYPE_REFINANCE]: 'Refinance',
            [TYPE_NONE]: 'Neither',
        };
    };
}

export default LoanAssetTypeEnum;
