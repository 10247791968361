import React from 'react';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import SecureRouteContainer from '../../apps/authentication/containers/SecureRouteContainer';
import DefaultAdminLayout from '../layouts/DefaultAdminLayout';
import NavigationContainer from '../../apps/navigation/containers/NavigationContainer';
import ContentContainer from '../../apps/borrower/routing/containers/ContentContainer';
import NotificationsContainer from '../../apps/notifications/containers/Container';
import ModalContainer from '../../apps/modal/containers/Container';
import SplashContainer from '../../apps/splash/container/Container';
import withSession from '../../pitchblack_react_utils/session/withSession';
import { Link } from '../../pitch4_layout/components/href';
import { ROUTE_BORROWER_CONFIGURE_PROFILE } from '../../pitch4_routing';
import BannerNotice from '../../apps/bannernotice/components/BannerNotice';
import { BANNER_NOTICE_TYPE_ERROR } from '../../apps/bannernotice/components/BannerNoticeError';
import { actionBannerNoticeShow, actionBannerNoticeHide } from '../../apps/bannernotice/redux/container';
import '../../assets/sass/borrower.scss';
import SessionManager from '../../pitchblack_react_utils/session/SessionManager';
import { getSession } from '../../pitch4_api/get_session';
import { sagaActionModalHide, sagaActionModalShow } from '../../apps/modal/sagas/container';
import SMSVerificationModal from '../../pitch4_elements/sms-verification/SMSVerificationModal';

class DefaultBorrowerPage extends React.Component {
    componentDidMount() {
        this.renderUnverifiedUserContent();
    }

    renderUnverifiedUserContent = () => {
        const { session, showBannerNotice, hideBannerNotice, redirect, setModalClose, setModalComponent } = this.props;
        const currentSession = session.getRelevantSession();

        if (false === currentSession.isVerified()) {
            /*
             * Display Account unverified banner
             */
            let message = '';
            if (true === currentSession.smsVerificationRequested()) {
                /*
                 * Display SMS verification modal - moved to DashboardContainer.js
                 */

                message = (
                    <>
                        <strong>This Account is currently unverified</strong> -{' '}
                        <Link
                            onClickIn={() =>
                                setModalComponent(
                                    <SMSVerificationModal
                                        setModalClose={setModalClose}
                                        resendCode={true}
                                        hideBannerNotice={hideBannerNotice}
                                    />,
                                    false
                                )
                            }
                            style={{ color: 'white', textDecoration: 'underline', fontSize: 'inherit' }}
                        >
                            Enter SMS code
                        </Link>
                    </>
                );
            } else {
                message = (
                    <>
                        <strong>This Account is currently unverified</strong> - Please check your inbox and confirm, to
                        be able to complete loan enquiries.{' '}
                        <Link
                            onClickIn={() => redirect(ROUTE_BORROWER_CONFIGURE_PROFILE)}
                            style={{ color: 'white', textDecoration: 'underline', fontSize: 'inherit' }}
                        >
                            Resend email.
                        </Link>
                    </>
                );
            }

            showBannerNotice(BANNER_NOTICE_TYPE_ERROR, { message });

            const userVerificationCheck = setInterval(() => {
                // Declare new SessionManager for each interval cycle as the one from props is filled with data after logging out ;-(
                const sm = new SessionManager();

                if (false === sm.isAuthenticated() || sm.hasAssumedToken()) {
                    clearInterval(userVerificationCheck);
                } else {
                    getSession().then((resp) => {
                        const { isVerified, smsVerificationRequested } = resp.session.user;
                        if (true === smsVerificationRequested) {
                            session.setSmsVerificationRequested(true);
                        }
                        if (true === isVerified) {
                            // Set in the current session - from props
                            session.setIsVerified(true);
                            hideBannerNotice();
                            clearInterval(userVerificationCheck);
                        }
                    });
                }
            }, 10000);
        }
    };

    render() {
        return (
            <>
                <SecureRouteContainer>
                    <DefaultAdminLayout
                        header={<NavigationContainer key={11} />}
                        content={<ContentContainer key={12} />}
                        notice={<BannerNotice key={13} />}
                        notifications={<NotificationsContainer key={15} />}
                        modal={<ModalContainer key={16} />}
                        splash={<SplashContainer key={17} />}
                        className={`role-borrower`}
                    />
                </SecureRouteContainer>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
        showBannerNotice: (type, props) => dispatch(actionBannerNoticeShow(type, props)),
        hideBannerNotice: () => dispatch(actionBannerNoticeHide()),
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withSession)(DefaultBorrowerPage);
