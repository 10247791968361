export const LEASE = 'LEASE';
export const HIRE_PURCHASE = 'HIRE_PURCHASE';

class LeaseHirePurchaseTypes {
    getTypes = () => {
        return [
            { value: LEASE, label: 'Lease' },
            { value: HIRE_PURCHASE, label: 'Hire Purchase' },
        ];
    };

    getOptions = () => {
        return {
            [LEASE]: { value: LEASE, label: 'Lease' },
            [HIRE_PURCHASE]: { value: HIRE_PURCHASE, label: 'Hire Purchase' },
        };
    };
}

export default LeaseHirePurchaseTypes;
