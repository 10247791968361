import React from 'react';
import {
    STATUS_COMPLETE,
    STATUS_COMPLETION_REJECTED,
    STATUS_COMPLETION_REQUESTED,
} from '../../../admin/enum/LenderLoanEnquiries/StatusEnum';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import TickIcon from 'pitch4_layout/components/icons/TickIcon';
import PropTypes from 'prop-types';

class SlamIconFactory extends React.Component {
    render() {
        const { leadStatus } = this.props;

        switch (leadStatus) {
            case STATUS_COMPLETE:
            case STATUS_COMPLETION_REQUESTED:
            case STATUS_COMPLETION_REJECTED:
                return <TickIcon viewBox="0 0 32 22" />;
            default:
                return <ArrowDropDownIcon className={`arrow-dropdown`} />;
        }
    }
}

SlamIconFactory.propTypes = {
    leadStatus: PropTypes.string.isRequired,
};

export default SlamIconFactory;
