import {
    sagaActionClearFilter,
    sagaActionClearSort,
    sagaActionSetFilter,
    sagaActionSetSorts,
} from 'pitch4_tabular/sagas';

const onFilterChange = (NAMESPACE, dispatch, filters, location) => (filter) => {
    //check current state
    let currentFilter = filters.filter((item) => item.column === filter.column);
    if (currentFilter.length > 0) {
        currentFilter = currentFilter[0];

        if (currentFilter.operand === filter.operand) {
            //no change in state needed so ignore
            return;
        }
    }

    //new state is empty
    if (filter.operand === '') {
        dispatch(sagaActionClearFilter(NAMESPACE, location.pathname)(filter.column));
    } else {
        //new state is not empty
        dispatch(sagaActionSetFilter(NAMESPACE, location.pathname)(filter));
    }
};

const onSortClear = (NAMESPACE, dispatch, location) => (column) => {
    dispatch(sagaActionClearSort(NAMESPACE, location.pathname)(column));
};

const onSortChange = (NAMESPACE, dispatch, location) => (sort) => {
    dispatch(sagaActionSetSorts(NAMESPACE, location.pathname)([sort]));
};

export { onSortChange, onSortClear, onFilterChange };
