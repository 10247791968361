import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import { SLAM_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';

const apolloFetch = createP4ApolloFetch({ uri: SLAM_URL });

const BusinessFinanceBusinessStreamMutation = `
    mutation editBusinessFinanceBusinessStream($id: ID!, $input: slam_editBusinessFinanceBusinessStreamInput!){
      editBusinessFinanceBusinessStream(id: $id, input: $input){
        businessStream {
            id
        }
      }
    }
`;

const PropertyFinanceBusinessStreamMutation = `
    mutation editPropertyFinanceBusinessStream($id: ID!, $input: slam_editPropertyFinanceBusinessStreamInput!){
      editPropertyFinanceBusinessStream(id: $id, input: $input){
        businessStream {
            id
        }
      }
    }
`;

//response classes
const errorFactory = new ValidationResultFactory();

class EditBusinessStream {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class EditBusinessStreamErrors extends EditBusinessStream {
    constructor(errors) {
        super('EditBusinessStreamErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class EditBusinessStreamSuccess extends EditBusinessStream {
    constructor(data) {
        super('EditBusinessStreamSuccess');
        this.data = data;
    }

    getData = () => {
        return this.data;
    };
}

export const editBusinessStream = (businessStreamId, name, formSections, isBusinessFinanceStream, formVersion) => {
    let out = apolloFetch({
        query:
            isBusinessFinanceStream === true
                ? BusinessFinanceBusinessStreamMutation
                : PropertyFinanceBusinessStreamMutation,
        variables: {
            id: businessStreamId,
            input: {
                name,
                formVersion,
                formSections,
            },
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new EditBusinessStreamErrors(vR);
            }

            return new EditBusinessStreamSuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new EditBusinessStreamErrors(vR);
        });

    return out;
};
