import React from 'react';
import { DateTimeFormatter } from '../data/formatter';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledSpan = styled.span`
    margin-right: 12px;
`;

class ExpandedDateTimeLayout extends React.Component {
    render() {
        let { value } = this.props;
        return (
            <React.Fragment>
                <StyledSpan>
                    <DateTimeFormatter value={value} format={'MMMM D, YYYY'} />
                </StyledSpan>
                <DateTimeFormatter value={value} format={'h:mm A'} />
            </React.Fragment>
        );
    }
}

ExpandedDateTimeLayout.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ExpandedDateTimeLayout;
