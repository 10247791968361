export const PROFESSIONAL_SERVICES_TYPE = 'PROFESSIONAL_SERVICES';
export const PROPERTY_MANAGEMENT_TYPE = 'PROPERTY_MANAGEMENT';
export const PROPERTY_DEVELOPMENT_TYPE = 'PROPERTY_DEVELOPMENT';
export const AUTOMOBILES_TYPE = 'AUTOMOBILES';
export const MARINE_TYPE = 'MARINE';
export const AVIATION_TYPE = 'AVIATION';
export const CONSTRUCTION_TYPE = 'CONSTRUCTION';
export const INTELLECTUAL_PROPERTY_TYPE = 'INTELLECTUAL_PROPERTY';
export const RETAIL_TYPE = 'RETAIL';
export const FOOD_TYPE = 'FOOD';
export const RECRUITMENT_AND_HR_TYPE = 'RECRUITMENT';
export const MANUFACTURING_TYPE = 'MANUFACTURING';
export const MARKETING_ADVERTISING_PR_TYPE = 'MARKETING';
export const HOSPITALITY_AND_EVENTS_MANAGEMENT_TYPE = 'HOSPITALITY';
export const TECHNOLOGY_TYPE = 'TECHNOLOGY';
export const HEALTHCARE_TYPE = 'HEALTHCARE';
export const CREATIVE_ARTS_AND_DESIGN_TYPE = 'CREATIVE';
export const ENERGY_AND_UTILITIES_TYPE = 'UTILITIES';
export const ENVIRONMENT_AND_AGRICULTURE_TYPE = 'ENVIRONMENT';
export const LAW_TYPE = 'LAW';
export const LEISURE_SPORT_TOURISM_TYPE = 'LEISURE';
export const MEDIA_AND_INTERNET_TYPE = 'MEDIA';
export const SALES_TYPE = 'SALES';
export const SCIENCE_AND_PHARMACEUTICALS_TYPE = 'SCIENCE';
export const TRANSPORT_AND_LOGISTICS_TYPE = 'TRANSPORT';
export const EDUCATION_TYPE = 'EDUCATION';
export const SECURITY_TYPE = 'SECURITY';
export const ACCOUNTING_TYPE = 'ACCOUNTING';
export const CONSULTANCY_AND_MANAGEMENT_TYPE = 'CONSULTANCY';
export const SOCIETIES = 'SOCIETIES';
export const CHARITY = 'CHARITY';
export const NON_PROFIT = 'NON_PROFIT';
export const SOCIAL_HOUSING = 'SOCIAL_HOUSING';
export const RELIGIOUS_GROUP = 'RELIGIOUS_GROUP';
export const CHURCH_TEMPLES = 'CHURCH_TEMPLES';
export const ECOMMERCE = 'ECOMMERCE';
export const PRINTING = 'PRINTING';
export const WHOLESALE = 'WHOLESALE';

class BusinessTypes {
    getTypes = () => {
        return [
            { label: 'Professional Services', value: PROFESSIONAL_SERVICES_TYPE },
            { label: 'Property Management', value: PROPERTY_MANAGEMENT_TYPE },
            { label: 'Property Development', value: PROPERTY_DEVELOPMENT_TYPE },
            { label: 'Automobiles', value: AUTOMOBILES_TYPE },
            { label: 'Marine', value: MARINE_TYPE },
            { label: 'Aviation', value: AVIATION_TYPE },
            { label: 'Construction', value: CONSTRUCTION_TYPE },
            { label: 'Intellectual Property', value: INTELLECTUAL_PROPERTY_TYPE },
            { label: 'Retail', value: RETAIL_TYPE },
            { label: 'Food', value: FOOD_TYPE },
            { label: 'Recruitment and HR', value: RECRUITMENT_AND_HR_TYPE },
            { label: 'Manufacturing', value: MANUFACTURING_TYPE },
            { label: 'Marketing, advertising and PR', value: MARKETING_ADVERTISING_PR_TYPE },
            { label: 'Hospitality and events management', value: HOSPITALITY_AND_EVENTS_MANAGEMENT_TYPE },
            { label: 'Technology', value: TECHNOLOGY_TYPE },
            { label: 'Healthcare', value: HEALTHCARE_TYPE },
            { label: 'Creative arts and design', value: CREATIVE_ARTS_AND_DESIGN_TYPE },
            { label: 'Energy and Utilities', value: ENERGY_AND_UTILITIES_TYPE },
            { label: 'Environment and Agriculture', value: ENVIRONMENT_AND_AGRICULTURE_TYPE },
            { label: 'Law', value: LAW_TYPE },
            { label: 'Leisure, sport and tourism', value: LEISURE_SPORT_TOURISM_TYPE },
            { label: 'Media and internet', value: MEDIA_AND_INTERNET_TYPE },
            { label: 'Sales', value: SALES_TYPE },
            { label: 'Science and Pharmaceuticals', value: SCIENCE_AND_PHARMACEUTICALS_TYPE },
            { label: 'Transport and logistics', value: TRANSPORT_AND_LOGISTICS_TYPE },
            { label: 'Education', value: EDUCATION_TYPE },
            { label: 'Security', value: SECURITY_TYPE },
            { label: 'Accounting', value: ACCOUNTING_TYPE },
            { label: 'Consultancy and management', value: CONSULTANCY_AND_MANAGEMENT_TYPE },
            { label: 'Societies', value: SOCIETIES },
            { label: 'Charity', value: CHARITY },
            { label: 'Not for Profit', value: NON_PROFIT },
            { label: 'Social Housing', value: SOCIAL_HOUSING },
            { label: 'Faith and Religious Groups', value: RELIGIOUS_GROUP },
            { label: 'Churches and Temples', value: CHURCH_TEMPLES },
            { label: 'Ecommerce', value: ECOMMERCE },
            { label: 'Printing', value: PRINTING },
            { label: 'Wholesale', value: WHOLESALE },
        ];
    };

    getOptions = () => {
        return {
            [PROFESSIONAL_SERVICES_TYPE]: { label: 'Professional Services', value: PROFESSIONAL_SERVICES_TYPE },
            [PROPERTY_MANAGEMENT_TYPE]: { label: 'Property Management', value: PROPERTY_MANAGEMENT_TYPE },
            [PROPERTY_DEVELOPMENT_TYPE]: { label: 'Property Development', value: PROPERTY_DEVELOPMENT_TYPE },
            [AUTOMOBILES_TYPE]: { label: 'Automobiles', value: AUTOMOBILES_TYPE },
            [MARINE_TYPE]: { label: 'Marine', value: MARINE_TYPE },
            [AVIATION_TYPE]: { label: 'Aviation', value: AVIATION_TYPE },
            [CONSTRUCTION_TYPE]: { label: 'Construction', value: CONSTRUCTION_TYPE },
            [INTELLECTUAL_PROPERTY_TYPE]: { label: 'Intellectual Property', value: INTELLECTUAL_PROPERTY_TYPE },
            [RETAIL_TYPE]: { label: 'Retail', value: RETAIL_TYPE },
            [FOOD_TYPE]: { label: 'Food', value: FOOD_TYPE },
            [RECRUITMENT_AND_HR_TYPE]: { label: 'Recruitment and HR', value: RECRUITMENT_AND_HR_TYPE },
            [MANUFACTURING_TYPE]: { label: 'Manufacturing', value: MANUFACTURING_TYPE },
            [MARKETING_ADVERTISING_PR_TYPE]: {
                label: 'Marketing, advertising and PR',
                value: MARKETING_ADVERTISING_PR_TYPE,
            },
            [HOSPITALITY_AND_EVENTS_MANAGEMENT_TYPE]: {
                label: 'Hospitality and events management',
                value: HOSPITALITY_AND_EVENTS_MANAGEMENT_TYPE,
            },
            [TECHNOLOGY_TYPE]: { label: 'Technology', value: TECHNOLOGY_TYPE },
            [HEALTHCARE_TYPE]: { label: 'Healthcare', value: HEALTHCARE_TYPE },
            [CREATIVE_ARTS_AND_DESIGN_TYPE]: {
                label: 'Creative arts and design',
                value: CREATIVE_ARTS_AND_DESIGN_TYPE,
            },
            [ENERGY_AND_UTILITIES_TYPE]: { label: 'Energy and Utilities', value: ENERGY_AND_UTILITIES_TYPE },
            [ENVIRONMENT_AND_AGRICULTURE_TYPE]: {
                label: 'Environment and Agriculture',
                value: ENVIRONMENT_AND_AGRICULTURE_TYPE,
            },
            [LAW_TYPE]: { label: 'Law', value: LAW_TYPE },
            [LEISURE_SPORT_TOURISM_TYPE]: { label: 'Leisure, sport and tourism', value: LEISURE_SPORT_TOURISM_TYPE },
            [MEDIA_AND_INTERNET_TYPE]: { label: 'Media and internet', value: MEDIA_AND_INTERNET_TYPE },
            [SALES_TYPE]: { label: 'Sales', value: SALES_TYPE },
            [SCIENCE_AND_PHARMACEUTICALS_TYPE]: {
                label: 'Science and Pharmaceuticals',
                value: SCIENCE_AND_PHARMACEUTICALS_TYPE,
            },
            [TRANSPORT_AND_LOGISTICS_TYPE]: { label: 'Transport and logistics', value: TRANSPORT_AND_LOGISTICS_TYPE },
            [EDUCATION_TYPE]: { label: 'Education', value: EDUCATION_TYPE },
            [SECURITY_TYPE]: { label: 'Security', value: SECURITY_TYPE },
            [ACCOUNTING_TYPE]: { label: 'Accounting', value: ACCOUNTING_TYPE },
            [CONSULTANCY_AND_MANAGEMENT_TYPE]: {
                label: 'Consultancy and management',
                value: CONSULTANCY_AND_MANAGEMENT_TYPE,
            },
            [SOCIETIES]: { label: 'Societies', value: SOCIETIES },
            [CHARITY]: { label: 'Charity', value: CHARITY },
            [NON_PROFIT]: { label: 'Not for Profit', value: NON_PROFIT },
            [SOCIAL_HOUSING]: { label: 'Social Housing', value: SOCIAL_HOUSING },
            [RELIGIOUS_GROUP]: { label: 'Faith and Religious Groups', value: RELIGIOUS_GROUP },
            [CHURCH_TEMPLES]: { label: 'Churches and Temples', value: CHURCH_TEMPLES },
            [ECOMMERCE]: { label: 'Ecommerce', value: ECOMMERCE },
            [PRINTING]: { label: 'Printing', value: PRINTING },
            [WHOLESALE]: { label: 'Wholesale', value: WHOLESALE },
        };
    };
}

export default BusinessTypes;
