import React from 'react';
import { Container, Item, RowItem } from '../../../pitch4_layout/components/div';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';

class P4StyleComponentContainer extends React.Component {
    render() {
        let { header = null, content, buttons = null, styles = {}, headerStyles = {}, buttonStyles = {} } = {
            ...this.props,
        };

        return (
            <Paper className={`p4style-component-container`} elevation={1} style={styles}>
                {header !== null && (
                    <RowItem className={`p4style-component-header`}>
                        <Container>
                            <Item xs={buttons ? 6 : 12} style={headerStyles}>
                                <h1>{header}</h1>
                            </Item>
                            {buttons && (
                                <Item xs={6} style={buttonStyles}>
                                    {buttons}
                                </Item>
                            )}
                        </Container>
                    </RowItem>
                )}
                <RowItem className={`p4style-component-body`}>{content}</RowItem>
            </Paper>
        );
    }
}

P4StyleComponentContainer.propTypes = {
    content: PropTypes.any,
    buttons: PropTypes.object,
    containerStyle: PropTypes.object,
};

export default P4StyleComponentContainer;
