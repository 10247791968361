const currencyFormatter = new Intl.NumberFormat('en-UK', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
});
const numberFormatter = new Intl.NumberFormat('en-UK');
export const getTermName = (term) => {
    if (term.valueType === 'number' && term.choice !== null) {
        return term.name + ' (' + term.choice[term.choiceIndex] + ')';
    }

    return term.name;
};
export const getValue = (item, isMainInterest = false) => {
    if (item && null !== item.value) {
        if (null !== item.choice) {
            switch (item.choice[item.choiceIndex]) {
                case '£':
                    return currencyFormatter.format(item.value);
                case '%':
                    return `${numberFormatter.format(item.value)}%`;
                case '% PCM':
                    return `${numberFormatter.format(item.value)}% PCM`;
                case '% PA':
                    return `${numberFormatter.format(item.value)}% PA`;
                case 'Months':
                    return `${numberFormatter.format(item.value)} months`;
                case 'Years':
                    return `${numberFormatter.format(item.value)} years`;
                default:
                    return item.value;
            }
        } else {
            if (item.valueType === 'number') {
                switch (item.units) {
                    case '£':
                        return '£' + numberFormatter.format(item.value);
                    case '%':
                        return numberFormatter.format(item.value) + '%';
                    default:
                        return numberFormatter.format(item.value);
                }
            } else {
                return item.value;
            }
        }
    }

    return true === isMainInterest ? 'Terms Pending' : 'Pending';
};

export const getTersByType = (lenderTerms, type) => {
    return lenderTerms.terms.filter((term) => term.termType === type);
};
