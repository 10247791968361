import React, { useEffect, useState } from 'react';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

export default function LenderTermsMatrix(props) {
    const {
        lenders,
        selectedLenders,
        setModalClose,
        setModalComponent,
        toggleLenderTermsMatrixModal,
        handleLenderSelection,
        loanEnquiryId,
        uri,
        dispatch,
    } = props;
    const [matrixLenders, setMatrixLenders] = useState(
        lenders
            .filter((lender) => selectedLenders.includes(lender.id)) // filter preselected all selected lenders
            .filter((lender) => lender.lenderTerms !== null) // filter lenders with terms
            .map((item) => item.id)
    );
    const [step, setStep] = useState(1);
    const [blankLenderSpaces, setBlankLenderSpaces] = useState(0);

    useEffect(() => {
        return function cleanup() {
            toggleLenderTermsMatrixModal();
            handleLenderSelection(null);
        };
    }, []);

    useEffect(() => {
        const { handleLenderSelection } = props;
        handleLenderSelection(matrixLenders);
    }, [matrixLenders]); // Run only after 'matrixLenders' is updated

    const renderStepOne = () => {
        return (
            <StepOne
                matches={lenders}
                // selectedLenders={selectedLenders}
                handleLenderSelection={handleLenderSelection}
                blankLenderSpaces={blankLenderSpaces}
                setBlankLenderSpaces={setBlankLenderSpaces}
                setStep={setStep}
                setMatrixLenders={setMatrixLenders}
                matrixLenders={matrixLenders}
            />
        );
    };

    const renderStepTwo = () => {
        return (
            <StepTwo
                matches={lenders}
                setStep={setStep}
                blankLenderSpaces={blankLenderSpaces}
                matrixLenders={matrixLenders}
                setModalClose={setModalClose}
                setModalComponent={setModalComponent}
                handleLenderSelection={handleLenderSelection}
                loanEnquiryId={loanEnquiryId}
                uri={uri}
                dispatch={dispatch}
            />
        );
    };

    const renderStep = () => (step === 1 ? renderStepOne() : renderStepTwo());

    return (
        <div className={'lender-terms-matrix-container'}>
            <div className={'header'}>Export Terms Matrix</div>
            <div className={'steps'}>Step {step} of 2</div>
            {renderStep()}
        </div>
    );
}
