import _ from 'lodash';

import { FIELD_TYPE_TEXT } from '../constants';
import { isEmpty } from '../../../pitch4_validation';
import {
    hasMaxLength255,
    hasMinValue1,
    hasMinValue10000,
    hasMinIntegerValue1,
    isNumeric,
    isValidPostcode,
    isPercentage,
} from '../../../pitchblack_react_utils/form/validation';

export const getConfigFieldLabel = (sectionConfig, fieldName) => {
    return _.get(sectionConfig, ['fields', fieldName, 'label']);
};

export const getConfigFieldType = (sectionConfig, fieldName) => {
    return _.get(sectionConfig, ['fields', fieldName, 'type'], FIELD_TYPE_TEXT);
};

export const getConfigFieldTooltip = (sectionConfig, fieldName) => {
    return _.get(sectionConfig, ['fields', fieldName, 'tooltip'], '');
};

export const getConfigFieldPlaceholder = (sectionConfig, fieldName) => {
    return _.get(sectionConfig, ['fields', fieldName, 'placeholder'], '');
};

export const getConfigFieldIsMulti = (sectionConfig, fieldName) => {
    return _.get(sectionConfig, ['fields', fieldName, 'multi'], false);
};

export const getConfigFieldIsRequired = (sectionConfig, fieldName) => {
    return _.get(sectionConfig, ['fields', fieldName, 'required'], false);
};

export const getConfigDependantFields = (sectionConfig, fieldName) => {
    const sectionFields = _.get(sectionConfig, ['fields'], []);

    const dependantFields = [];

    Object.keys(sectionFields).forEach(function (fieldKey) {
        const field = sectionFields[fieldKey];
        const dependencies = field['dependencies'];

        if (isEmpty(dependencies)) {
            return;
        }

        for (let i = 0; i < dependencies.length; i++) {
            if (dependencies[i]['field'] === fieldName) {
                dependantFields.push(field['name']);
                return;
            }
        }
    });

    return dependantFields;
};

export const getConfigDependencies = (sectionConfig, fieldName) => {
    return _.get(sectionConfig, ['fields', fieldName, 'dependencies'], []);
};

export const getConfigFieldValidators = (sectionConfig, fieldName) => {
    const validatorKeys = _.get(sectionConfig, ['fields', fieldName, 'validators']);

    if (isEmpty(validatorKeys)) {
        return [];
    }

    let validators = [];

    validatorKeys.forEach(function (validatorKey) {
        const fieldType = _.get(sectionConfig, ['fields', fieldName, 'type']);
        switch (validatorKey) {
            case 'is_numeric':
                validators.push(isNumeric);
                break;

            case 'max_length_255':
                validators.push(hasMaxLength255);
                break;

            case 'postcode':
                validators.push(isValidPostcode);
                break;

            case 'is_percentage':
                validators.push(isPercentage);
                break;

            case 'has_min_value_1':
                if (fieldType === 'currency') {
                    validators.push(hasMinValue1);
                } else {
                    validators.push(hasMinIntegerValue1);
                }
                break;

            case 'has_min_value_10000':
                validators.push(hasMinValue10000);
                break;

            default:
                break;
        }
    });

    return validators;
};

export const getConfigFieldFrontend = (sectionConfig, fieldName) => {
    return _.get(sectionConfig, ['fields', fieldName, 'frontend'], {});
};
