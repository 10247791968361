import {
    ROUTE_INTERMEDIARY_APPLICANTS_VIEW,
    ROUTE_INTERMEDIARY_CONFIGURE_PROFILE,
    ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY,
    ROUTE_INTERMEDIARY_DOCUMENTS_VIEW,
    ROUTE_INTERMEDIARY_LENDERLOANENQUIRY_VIEW,
    ROUTE_INTERMEDIARY_LOAN_ENQUIRIES,
    ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW,
    ROUTE_INTERMEDIARY_NETWORK_BROKERS_VIEW,
    ROUTE_INTERMEDIARY_ROOT,
} from '../../../pitch4_routing';

export default {
    [ROUTE_INTERMEDIARY_ROOT]: [{ path: ROUTE_INTERMEDIARY_ROOT, label: 'Dashboard', link: false }],
    [ROUTE_INTERMEDIARY_CONFIGURE_PROFILE]: [
        { path: ROUTE_INTERMEDIARY_CONFIGURE_PROFILE, label: 'Configure Profile', link: false },
    ],
    [ROUTE_INTERMEDIARY_APPLICANTS_VIEW]: [
        { path: ROUTE_INTERMEDIARY_APPLICANTS_VIEW, label: 'Applicants', link: false },
    ],
    [ROUTE_INTERMEDIARY_LOAN_ENQUIRIES]: [
        { path: ROUTE_INTERMEDIARY_LOAN_ENQUIRIES, label: 'Loan Enquiries', link: false },
    ],
    [ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY]: [
        { path: ROUTE_INTERMEDIARY_LOAN_ENQUIRIES, label: 'Loan Enquiries', link: true },
        { path: ROUTE_INTERMEDIARY_CREATE_LOAN_ENQUIRY, label: 'Create Loan Enquiry', link: false },
    ],
    [ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW]: [
        { path: ROUTE_INTERMEDIARY_LOAN_ENQUIRIES, label: 'Loan Enquiries', link: true },
        { path: ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW, label: 'Single Loan Enquiry', link: false },
    ],
    [ROUTE_INTERMEDIARY_LENDERLOANENQUIRY_VIEW]: [
        { path: ROUTE_INTERMEDIARY_LOAN_ENQUIRIES, label: 'Loan Enquiries', link: true },
        { path: ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW, label: 'Single Loan Enquiry', link: true },
        { path: ROUTE_INTERMEDIARY_LENDERLOANENQUIRY_VIEW, label: 'Loan Match', link: false },
    ],
    [ROUTE_INTERMEDIARY_DOCUMENTS_VIEW]: [{ path: ROUTE_INTERMEDIARY_DOCUMENTS_VIEW, label: 'Documents', link: false }],
    [ROUTE_INTERMEDIARY_NETWORK_BROKERS_VIEW]: [
        { path: ROUTE_INTERMEDIARY_NETWORK_BROKERS_VIEW, label: 'Network Brokers', link: false },
    ],
};
