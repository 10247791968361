import { spawn } from 'redux-saga/effects';

import loginRootSaga from '../sagas/login';
import dashboardRootSaga from '../sagas/dashboard';
import assumedIdentityRootSaga from '../sagas/assumedIdentity';

function* main() {
    yield spawn(loginRootSaga);
    yield spawn(dashboardRootSaga);
    yield spawn(assumedIdentityRootSaga);
}

export default main;
