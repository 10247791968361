import React from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

export default function Item(props) {
    const { elkey = '', ...other } = props;
    return (
        <Grid item key={'i_' + elkey} {...other}>
            {props.children}
        </Grid>
    );
}

Item.propTypes = {
    xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    sm: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    md: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    lg: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    className: PropTypes.any,
    elkey: PropTypes.string,
};
