import createP4ApolloFetch from '../../bootstrap/middlewares';

const ClearChannelAlertMutation = `
    mutation clearChannelAlert($id: ID!) {
      clearChannelAlert(id: $id) {
        id
      }
    }
`;

export const clearChannelAlert = (id, uri) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    return apolloFetch({
        query: ClearChannelAlertMutation,
        variables: {
            id,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
