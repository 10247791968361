import React from 'react';
import ButtonMUI from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
    button: {
        margin: theme.spacing(1),
        backgroundColor: 'rgb(185,21,231)',
    },
    input: {
        display: 'none',
    },
});

class Button extends React.Component {
    render() {
        let { classes, variant = 'contained', className, children, ...other } = this.props;

        return (
            <ButtonMUI variant={variant} className={className + ` ` + classes.button} {...other}>
                {children}
            </ButtonMUI>
        );
    }
}

export default withStyles(styles)(Button);
