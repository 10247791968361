import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import {
    BRIDGE_TYPE,
    BUSINESS_TYPE,
    DEVELOPMENT_TYPE,
    MEZZANINE_TYPE,
    PORTFOLIO_TYPE,
    PROPERTY_TYPE,
    REFURBISHMENT_TYPE,
    SECOND_CHARGE_TYPE,
    TERM_TYPE,
    UNKNOWN_TYPE,
} from '../../../enums/Borrower/BorrowerLoanType';
import { QUESTION_FUNDING_TYPE, QUESTION_LOAN_TYPE } from '../../../enums/Borrower/BorrowerQuestions';
import Question from '../../Question/containers/Question';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BorrowerQuestions from '../../../enums/Borrower/BorrowerQuestions';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';
import BorrowerLoanType from '../../../enums/Borrower/BorrowerLoanType';
import BrokerLoanType from '../../../enums/Broker/BrokerLoanType';

class LoanTypeSelectorContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const loanTypes =
            userRole === ROLE_BORROWER ? new BorrowerLoanType().getTypes() : new BrokerLoanType().getTypes();
        let propertyOptions = [
            loanTypes[TERM_TYPE],
            loanTypes[BRIDGE_TYPE],
            loanTypes[REFURBISHMENT_TYPE],
            loanTypes[SECOND_CHARGE_TYPE],
            loanTypes[DEVELOPMENT_TYPE],
            loanTypes[PORTFOLIO_TYPE],
        ];
        // V1P-837 Mezzanine is a hidden option, only show it for enquiries that are already set as Mezzanine
        if (this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === MEZZANINE_TYPE) {
            propertyOptions.push(loanTypes[MEZZANINE_TYPE]);
        }
        const unknownOptions = [loanTypes[UNKNOWN_TYPE]];
        const businessPropertyOptions = [loanTypes[BUSINESS_TYPE], loanTypes[PROPERTY_TYPE]];
        let renderOptions = _.union(propertyOptions, unknownOptions);
        if (this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE) {
            renderOptions = _.union(propertyOptions, unknownOptions);
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">
                        What's this funding for?
                    </Typography>
                </Grid>
                <Question
                    type={questions[QUESTION_LOAN_TYPE].type}
                    label={questions[QUESTION_LOAN_TYPE].label}
                    questionEnum={'QUESTION_LOAN_TYPE'}
                    fieldName={questions[QUESTION_LOAN_TYPE].fieldName}
                    placeholder={questions[QUESTION_LOAN_TYPE].placeholder}
                    validation={questions[QUESTION_LOAN_TYPE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={businessPropertyOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />

                {this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] !== '' &&
                    this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] !== BUSINESS_TYPE && (
                        <Question
                            type={questions[QUESTION_FUNDING_TYPE].type}
                            label={
                                'What is the main purpose for this [bold|' +
                                loanTypes[
                                    this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName]
                                ].label.toLowerCase() +
                                '] finance?'
                            }
                            questionEnum={'QUESTION_FUNDING_TYPE'}
                            fieldName={questions[QUESTION_FUNDING_TYPE].fieldName}
                            placeholder={questions[QUESTION_FUNDING_TYPE].placeholder}
                            validation={questions[QUESTION_FUNDING_TYPE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={renderOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    )}
            </Grid>
        );
    }
}

export default LoanTypeSelectorContainer;
