import React from 'react';
import { Avatar } from '@material-ui/core';
import { Body1 } from 'pitch4_layout/components/text';
import { Centered } from 'pitch4_layout/components/div';

class CountIcon extends React.Component {
    render() {
        let { count } = this.props;

        return (
            <Avatar style={{ display: 'inline-block', backgroundColor: 'rgb(185, 21,231)', width: 25, height: 25 }}>
                <Centered containerProps={{ style: { width: '100%', height: '100%', paddingTop: 3 } }}>
                    <Body1>{count}</Body1>
                </Centered>
            </Avatar>
        );
    }
}

export default CountIcon;
