import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import StarIcon from '../../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../../assets/images/star-gray.svg';
import { DataGrid } from '@material-ui/data-grid';
import Status from '../../loan_enquiries/components/Status';
import moment from 'moment';
import StatusEnum from '../../enum/LenderLoanEnquiries/StatusEnum';
import { ROWS_PER_PAGE, PAGE_SIZE } from '../../../../../pitch4_enum/enum/DataGridEnum';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';

const statusEnum = new StatusEnum();

class ListView extends React.Component {
    render() {
        let {
            handleLenderSelection,
            selectedLenders,
            lendersMessageAllowed,
            toggleLenderDrawer,
            handleFavouriteMatchChange,
            handleRemoveAlertMatchAlert,
            data,
            sortBy,
            handleSelectAll,
            loanType,
        } = this.props;

        return (
            <div className="leads-container data-grid-table-container" style={{ margin: 0 }}>
                <DataGrid
                    sortingOrder={['desc', 'asc']}
                    sortModel={[
                        {
                            field: sortBy.field,
                            sort: sortBy.sort,
                        },
                    ]}
                    sortingMode="server"
                    checkboxSelection={lendersMessageAllowed}
                    onSelectionModelChange={(selected) => {
                        handleLenderSelection(selected);
                        if (selected.length === data.length) {
                            handleSelectAll(true);
                        } else {
                            handleSelectAll(false);
                        }
                    }}
                    selectionModel={selectedLenders && selectedLenders.length > 0 ? selectedLenders : []}
                    className={'data-grid-no-border'}
                    onCellClick={(params) => {
                        switch (params.field) {
                            case '__check__': // Checkbox - do nothing
                                break;
                            case 'favourite':
                                handleFavouriteMatchChange(params.row.id, null, params.row.favourite)();
                                break;
                            default:
                                toggleLenderDrawer()();
                                toggleLenderDrawer(
                                    true,
                                    params.row,
                                    handleFavouriteMatchChange,
                                    // handleRemoveAlertMatchAlert method is executed in src/apps/borrower/view_loan_enquiry/containers/LeadsContainer.js
                                    handleRemoveAlertMatchAlert
                                )();
                        }
                    }}
                    pageSize={PAGE_SIZE}
                    rowsPerPageOptions={ROWS_PER_PAGE}
                    rows={data}
                    disableSelectionOnClick={true}
                    columns={[
                        {
                            field: 'favourite',
                            renderHeader: () => <div />,
                            headerClassName: 'data-grid-table-header-no-separator',
                            cellClassName: 'data-grid-table-cell',
                            width: 50,
                            renderCell: (params) => {
                                return (
                                    <img
                                        src={params.row.favourite ? StarIcon : StarGrayIcon}
                                        alt="Favourite"
                                        className={'favourite'}
                                    />
                                );
                            },
                            disableColumnMenu: true,
                            sortable: false,
                        },
                        {
                            field: 'lenderName',
                            headerName: 'LENDER',
                            headerClassName: 'data-grid-table-header-no-separator',
                            width: 200,
                            cellClassName: 'data-grid-table-cell',
                            disableColumnMenu: true,
                            renderCell: (params) => {
                                return (
                                    <div className={'lender-name'}>
                                        <div>{params.row.lenderName}</div>
                                        {null !== params.row.lenderAnonName &&
                                            params.row.lenderName !== params.row.lenderAnonName && (
                                                <div className={'anon-name'}>({params.row.lenderAnonName})</div>
                                            )}
                                    </div>
                                );
                            },
                            sortable: false,
                        },
                        {
                            field: 'terms',
                            headerName: 'TERMS',
                            headerClassName: 'data-grid-table-header-no-separator',
                            width: 150,
                            cellClassName: 'data-grid-table-cell',
                            renderCell: (params) => {
                                const terms = params.row.terms ? params.row.terms.terms : null;
                                const totalItemsCount = null !== terms ? terms.length : 0;
                                const doneItemsCount =
                                    null !== terms ? terms.filter((item) => item.value !== null).length : 0;
                                return (
                                    <div className={doneItemsCount !== 0 ? 'terms-added' : 'terms-empty'}>
                                        {null !== terms ? (
                                            <strong>
                                                {doneItemsCount} / {totalItemsCount} Terms
                                            </strong>
                                        ) : (
                                            <strong>Terms not set</strong>
                                        )}
                                    </div>
                                );
                            },
                            sortable: false,
                        },
                        {
                            field: 'updatedAt',
                            headerName: 'UPDATED',
                            headerClassName: 'data-grid-table-header-no-separator',
                            width: 170,
                            valueFormatter: ({ value }) => moment(value).format('MMM DD,YYYY H:mm'),
                            cellClassName: 'data-grid-table-cell',
                            disableColumnMenu: true,
                            sortable: false,
                        },
                        {
                            field: 'status',
                            headerName: 'STATUS',
                            headerClassName: 'data-grid-table-header-no-separator',
                            width: 150,
                            cellClassName: 'data-grid-table-cell le-table-cell-status',
                            disableColumnMenu: true,
                            valueFormatter: ({ value }) => statusEnum.getType(value),
                            renderCell: (params) => (
                                <>
                                    <div>{statusEnum.getType(params.value, loanType)}</div>
                                    <Status style={{ marginLeft: 20 }} status={params.value} />
                                </>
                            ),
                            sortComparator: (v1, v2, param1, param2) => {
                                return (
                                    statusEnum.getStatusOrder().indexOf(param1.value) -
                                    statusEnum.getStatusOrder().indexOf(param2.value)
                                );
                            },
                            sortable: false,
                        },
                        {
                            field: 'alert',
                            renderHeader: () => <div />,
                            cellClassName: 'data-grid-table-cell badge-container',
                            headerClassName: 'data-grid-table-header-no-separator',
                            disableColumnMenu: true,
                            sortable: false,
                            filterable: false,
                            resizable: false,
                            renderCell: (params) => {
                                if (params.row.alertActive) {
                                    return <Badge label={'update'} />;
                                } else {
                                    return <></>;
                                }
                            },
                        },
                    ]}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ListView);
