import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import StarIcon from '../../../../../assets/images/star.svg';
import StarGrayIcon from '../../../../../assets/images/star-gray.svg';
import StatusEnum from '../../enum/LenderLoanEnquiries/StatusEnum';
import { Badge } from '../../../../../pitch4_layout/components/badge/Badge';
import { Paper } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CardViewLLEStatusSteps from './CardViewLLEStatusSteps';
import { ASSETS_LOGO_URL } from '../../../../../bootstrap/constants/constants';
import { getTersByType } from '../../../../../pitch4_elements/terms/Utils';

const statusEnum = new StatusEnum();

class CardView extends React.Component {
    render() {
        let {
            handleLenderSelection,
            selectedLenders,
            lendersMessageAllowed,
            toggleLenderDrawer,
            handleFavouriteMatchChange,
            handleRemoveAlertMatchAlert,
            data,
            handleSelectAll,
            loanType,
        } = this.props;

        return (
            <div className={'card-view-container'}>
                {data.map((lender, index) => {
                    const interestRateTerm = lender.terms ? getTersByType(lender.terms, 'mainInterestRate')[0] : null;
                    return (
                        <Paper
                            key={index}
                            className={`lender-card ${true === lender.favourite && 'favourite'} ${
                                true === lender.alertActive && 'alert-active'
                            }`}
                            elevation={
                                selectedLenders && selectedLenders.includes(lender.id)
                                    ? 4
                                    : true === lender.alertActive
                                    ? 1
                                    : 0
                            }
                            onClick={() => {
                                toggleLenderDrawer()();
                                toggleLenderDrawer(
                                    true,
                                    lender,
                                    handleFavouriteMatchChange,
                                    // handleRemoveAlertMatchAlert method is executed in src/apps/borrower/view_loan_enquiry/containers/LeadsContainer.js
                                    handleRemoveAlertMatchAlert
                                )();
                            }}
                        >
                            <div>
                                <div className={'header'}>
                                    <span>
                                        {lender.lenderProfile.profileLogo &&
                                        null !== lender.lenderAnonName &&
                                        lender.lenderName !== lender.lenderAnonName ? (
                                            <>
                                                <img
                                                    style={{ maxHeight: 30 }}
                                                    title={lender.lenderName}
                                                    alt={lender.lenderName}
                                                    src={ASSETS_LOGO_URL.replace('{{lenderGuid}}', lender.lenderId)}
                                                    className={'lender-profile-logo'}
                                                />
                                                <div className={'lender-name'}>
                                                    <div className={'anon-name'}>({lender.lenderAnonName})</div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className={'lender-name'}>
                                                <div>{lender.lenderName}</div>
                                                {null !== lender.lenderAnonName &&
                                                    lender.lenderName !== lender.lenderAnonName && (
                                                        <div className={'anon-name'}>({lender.lenderAnonName})</div>
                                                    )}
                                            </div>
                                        )}
                                    </span>
                                    <img
                                        src={true === lender.favourite ? StarIcon : StarGrayIcon}
                                        alt="Favourite"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            handleFavouriteMatchChange(lender.id, null, lender.favourite)();
                                        }}
                                        className={'favourite-icon'}
                                    />
                                </div>
                                {lender.terms && interestRateTerm.value && (
                                    <div className={'interest-rate-container'}>
                                        Interest Rate:{' '}
                                        <span className={'rate'}>
                                            {interestRateTerm.value}
                                            {interestRateTerm.choice[interestRateTerm.choiceIndex]}
                                        </span>
                                    </div>
                                )}
                                {lender.alertActive && (
                                    <div>
                                        <Badge label={'update'} />
                                    </div>
                                )}
                            </div>
                            <div className={'footer'}>
                                <div className={'status'}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'flex-end',
                                        }}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ marginBottom: 5 }} className={`status-${lender.status}`}>
                                                {statusEnum.getType(lender.status, loanType)}
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                <CardViewLLEStatusSteps status={lender.status} />
                                            </div>
                                        </div>
                                        {lendersMessageAllowed &&
                                        selectedLenders &&
                                        selectedLenders.includes(lender.id) ? (
                                            <CheckBoxIcon
                                                className={'select'}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    const slsm = selectedLenders;
                                                    const newSelectedLenders = slsm.filter(
                                                        (lenderId) => lenderId !== lender.id
                                                    );
                                                    handleLenderSelection(newSelectedLenders);

                                                    if (newSelectedLenders === data.length) {
                                                        handleSelectAll(true);
                                                    } else {
                                                        handleSelectAll(false);
                                                    }
                                                }}
                                            />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon
                                                className={'select'}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    if (null === selectedLenders) {
                                                        handleLenderSelection(lender.id);
                                                    } else {
                                                        const slsm = selectedLenders;
                                                        slsm.push(lender.id);
                                                        handleLenderSelection(slsm);

                                                        if (slsm.length === data.length) {
                                                            handleSelectAll(true);
                                                        } else {
                                                            handleSelectAll(false);
                                                        }
                                                    }
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    );
                })}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(CardView);
