import React from 'react';
import { compose } from 'recompose';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import Form from '../form/Form';
import { Loading } from '../../../../../pitch4_layout/components/waiting';
import Mapper from '../form/Mapper';
import { updateBroker } from '../api/update_broker';
import { push } from 'react-router-redux';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import { connect } from 'react-redux';

class EditContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            broker: props.broker,
            submitting: false,
        };
    }

    handleSubmit = (broker) => (event) => {
        event.preventDefault();
        this.setState({ submitting: true });
        const { inProgress, onSuccess, onFailure, notificationSuccess, notificationError } = this.props;
        const mapper = new Mapper();
        const mappedValues = mapper.mapFromForm(broker);

        inProgress();

        return updateBroker(this.state.broker.id, mappedValues)
            .then(({ data }) => {
                notificationSuccess('Broker updated');
                onSuccess(data);
            })
            .catch((error) => {
                notificationError('Unable to update broker. Please contact support.');
                onFailure(error);
            });
    };

    render() {
        if (!this.state.broker) {
            return <Loading />;
        }

        return <Form broker={this.state.broker} handleSubmit={this.handleSubmit} submitting={this.state.submitting} />;
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withSession,
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(EditContainer);
