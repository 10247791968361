import React from 'react';
import { getFormValues, reduxForm } from 'redux-form';
import styled from 'styled-components';
import { compose } from 'recompose';
import { CenteringContainer, Container, RowItem } from 'pitchblack_react_utils/layout/index';
import { isRequired } from 'pitchblack_react_utils/form/validation';
import { connect } from 'react-redux';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import { ROUTE_ANON_LOGIN } from 'pitch4_routing';
import { push } from 'react-router-redux';
import { TextField } from 'pitch4_forms/components/inputs';
import { sagaActionLogout } from '../sagas/login';
import { withRouter } from 'react-router';
import logo from '../../landing/assets/images/pitch4-d.svg';
import { withStyles } from '@material-ui/core/styles';
import {
    actionSubmitTwoFactorAuthForm,
    selectorGetTwoFactorAuthUser,
    selectorGetTwoFactorAuthValidationErrors,
} from '../redux/login';
import _ from 'lodash';
import { BRAND_NAME } from '../../../bootstrap/constants/constants';

const FORM_NAME = 'two-factor-auth-login';

const StyledContainer = styled.form`
    padding: 10% 20px 20px 20px;
    width: auto;
    margin: 0 auto;
`;

const Errors = styled.ul`
    margin-top: 10px;
    text-align: center;
`;

const ErrorListItem = styled.li`
    color: #f44336;
    line-height: 1.5em;
    list-style-type: none;
`;

const Logo = styled.img`
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 40px;
    padding-top: 10px;
`;

const styles = {
    root: {
        flexGrow: 1,
        width: '100%',
    },
    input: {
        textAlign: 'center',
        fontSize: '2.0em',
        width: '220px',
        margin: 0,
        letterSpacing: '5px',
    },
};

class TwoFactorAuthLoginForm extends React.Component {
    constructor(props) {
        super(props);

        this.gotoPath = null;
    }

    componentDidMount() {
        const { history, location, redirect, twoFactorAuthUser = {} } = this.props;

        if (!!location.state && !!location.state.goto) {
            this.gotoPath = decodeURIComponent(location.state.goto);
            history.replace(location.pathname, null);
        }

        if (_.isEmpty(twoFactorAuthUser)) {
            redirect(ROUTE_ANON_LOGIN)();
        }
    }

    render() {
        const { handleSubmit, classes, submitting } = this.props;

        const inputClasses = {
            root: classes.root,
            input: classes.input,
        };

        return (
            <StyledContainer>
                <RowItem>
                    <CenteringContainer>
                        <Logo src={logo} alt={`${BRAND_NAME} &copy; Borrowing Made Simple`} />
                    </CenteringContainer>
                </RowItem>
                <RowItem>
                    <CenteringContainer>
                        <form name={FORM_NAME} onSubmit={handleSubmit(this.onSubmit)}>
                            <Container>
                                <RowItem>
                                    {this.getServerErrors(['code'])}
                                    <TextField
                                        fullWidth={true}
                                        name={`code`}
                                        label={`Two Factor Authentication Code`}
                                        margin="normal"
                                        variant="outlined"
                                        validate={[isRequired]}
                                        style={{}}
                                        InputLabelProps={{ shrink: true }}
                                        InputProps={{
                                            classes: inputClasses,
                                        }}
                                        autoFocus={true}
                                    />
                                </RowItem>
                            </Container>
                            <RowItem>
                                <CenteringContainer>
                                    <PrimaryButton style={{ marginTop: '30px' }} type="submit" disabled={submitting}>
                                        Login
                                    </PrimaryButton>
                                </CenteringContainer>
                            </RowItem>
                        </form>
                    </CenteringContainer>
                </RowItem>
            </StyledContainer>
        );
    }

    getServerErrors = (fields) => {
        const { errors } = this.props;

        if (!_.isEmpty(errors)) {
            return (
                <RowItem>
                    <Errors>
                        {Object.keys(errors).map(function (fieldName) {
                            if (fields.indexOf(fieldName) !== -1 || fieldName === 'unknown') {
                                return <ErrorListItem>{errors[fieldName]}</ErrorListItem>;
                            }

                            return <></>;
                        })}
                    </Errors>
                </RowItem>
            );
        }
    };

    onSubmit = () => {
        const { twoFactorAuthUser, submitForm, formValues } = this.props;

        submitForm(twoFactorAuthUser, _.get(formValues, 'code'));
    };
}

const mapStateToProps = (state) => {
    return {
        twoFactorAuthUser: selectorGetTwoFactorAuthUser(state),
        formValues: getFormValues(FORM_NAME)(state),
        errors: selectorGetTwoFactorAuthValidationErrors(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        logout: () => dispatch(sagaActionLogout(ROUTE_ANON_LOGIN)),
        submitForm: (user, code) => dispatch(actionSubmitTwoFactorAuthForm(user, code)),
    };
};

TwoFactorAuthLoginForm.propTypes = {};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: FORM_NAME,
    }),
    withStyles(styles)
)(TwoFactorAuthLoginForm);
