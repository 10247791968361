import React from 'react';
import PropTypes from 'prop-types';

export const formatCurrency = ({ locale = 'en-UK', currency = 'GBP', value, inPennies = false }) => {
    let minimumFractionDigits = 0;

    // If in pennies display as [POUNDS].[PENCE]
    if (inPennies === true) {
        value = value / 100;

        // If its not a whole number then show the pennies
        if (value % 1 !== 0) {
            minimumFractionDigits = 2;
        }
    }

    let formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: minimumFractionDigits,
    });

    return formatter.format(value);
};

/**
 * Formats a currency value.
 *
 * This class uses the Intl.NumberFormat util to actually do the formatting which is supported in IE11 plus all other
 * major browsers.
 */
class CurrencyFormatter extends React.Component {
    render() {
        return formatCurrency({ ...this.props });
    }
}

CurrencyFormatter.propTypes = {
    locale: PropTypes.string,
    currency: PropTypes.string,
    value: PropTypes.any.isRequired,
    inPennies: PropTypes.bool,
};

export default CurrencyFormatter;
