import BaseLevel from './BaseLevel';
import { ERROR_LEVEL } from '../enum/LevelEnum';

class Error extends BaseLevel {
    constructor(message) {
        super(ERROR_LEVEL, message);
    }
}

export default Error;
