import React from 'react';

import { TableFooter as MTableFooter } from '@material-ui/core';

class TableFooter extends React.Component {
    render() {
        let { children, ...other } = this.props;

        return <MTableFooter {...other}>{children}</MTableFooter>;
    }
}

export default TableFooter;
