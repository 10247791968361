import React from 'react';
import { actionLeftSideMenuItemSelected, selectorLeftMenuItems } from '../redux/leftsidemenu';
import { Container, Item } from '../../../pitchblack_react_utils/layout';
import { connect } from 'react-redux';
import AlertDotIndicatorIcon from '../../../pitch4_layout/components/icons/AlertDotIndicatorIcon';
import { compose } from 'recompose';
import { selectorGetUserAlertsActive } from '../../user/configure_profile/redux';
import MenuDotIndicatorIcon from '../../../pitch4_layout/components/icons/MenuDotIndicatorIcon';

class HorizontalMenuItem extends React.Component {
    render() {
        const { handleMenuItemClick, isActive, id, label, url, alertIndicator, alertsActive } = this.props;

        return (
            <Item id={id} className={isActive ? 'active-menu-item menu-item' : 'menu-item'}>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        this.forceUpdate();
                        handleMenuItemClick(url);
                    }}
                >
                    <Container alignItems={`center`}>
                        {id ? <Item key={id}>{label}</Item> : <Item>{label}</Item>}
                        {alertIndicator && alertsActive && (
                            <AlertDotIndicatorIcon
                                key={`${id}-alert-indicator`}
                                active
                                elkey={`${id}-alert-indicator`}
                            />
                        )}
                    </Container>
                    <MenuDotIndicatorIcon active={isActive} />
                </button>
            </Item>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        menuItems: selectorLeftMenuItems(state),
        alertsActive: selectorGetUserAlertsActive(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    handleMenuItemClick: (url) => {
        dispatch(actionLeftSideMenuItemSelected(url));
    },
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(HorizontalMenuItem);
