const host = new URL(window._env_.API_URL);

let logo_url_host;
if ('development' === process.env.NODE_ENV) {
    host.hostname = window.location.hostname;
    logo_url_host = window._env_.API_URL;
} else {
    logo_url_host = window._env_.PUBLIC_ASSETS_URL;
}
let web_host_apex_dom = '';
let plat_web_host;
let app_debug;
if ('prod' === window._env_.APP_ENV) {
    web_host_apex_dom = 'providefinance.com';
}
if ('preprod' === window._env_.APP_ENV) {
    web_host_apex_dom = 'stage.providefinance.dev';
}
if ('dev' === window._env_.APP_ENV) {
    web_host_apex_dom = 'localhost:3000';
}
if ('qa' === window._env_.APP_ENV) {
    web_host_apex_dom = 'qa.providefinance.dev';
}
if ('demo' === window._env_.APP_ENV) {
    web_host_apex_dom = 'demo.providefinance.dev';
}
export const WEB_HOST_APEX_DOMAIN = web_host_apex_dom;

export const HOST = host.origin;
export const ANON_URL = `${HOST}/graphql/api_anon`;
export const USER_URL = `${HOST}/graphql/api_user`;
export const API_URL = `${HOST}/graphql/api_pitch4admin`;
export const INTERMEDIARY_URL = `${HOST}/graphql/api_intermediary`;
export const AUTH_URL = `${HOST}/graphql/api_auth`;
export const SLAM_URL = `${HOST}/graphql/api_slam`;
export const BORROWER_URL = `${HOST}/graphql/api_borrower`;
export const ADMIN_LOGO_UPLOAD_URL = `${HOST}/pitch4admin/lender-profile/{lenderGuid}/logo`;
export const RECAPTCHA_KEY = window._env_.RECAPTCHA_KEY;
export const TAG_MANAGER_AUTH = window._env_.TAG_MANAGER_AUTH;
export const TAG_MANAGER_PREVIEW = window._env_.TAG_MANAGER_PREVIEW;
export const TAG_MANAGER_ID = window._env_.TAG_MANAGER_ID;
export const TAG_MANAGER_DATA_LAYER_NAME = window._env_.TAG_MANAGER_DATA_LAYER_NAME;
export const TAG_MANAGER_EVENT_PLATFORM_LOGIN_SUCCESS = 'platform_login_success';
export const TAG_MANAGER_EVENT_PLATFORM_LENDER_SENT_CHAT_MESSAGE = 'platform_lender_sent_chat_message';
export const HUBSPOT_ACCOUNT_ID = window._env_.HUBSPOT_ACCOUNT_ID;
export const ASSETS_URL = window._env_.PUBLIC_ASSETS_URL;
export const ASSETS_LOGO_URL = logo_url_host + '/logos/{{lenderGuid}}.png';
export const WEB_HOST_HELP_SUBDOMAIN = 'help';
export const WEB_HOST = `https://${WEB_HOST_APEX_DOMAIN}`;
if ('prod' !== window._env_.APP_ENV) {
    plat_web_host = `https://${WEB_HOST_APEX_DOMAIN}`;
} else {
    plat_web_host = `https://app.${WEB_HOST_APEX_DOMAIN}`;
}
app_debug = 'prod' !== window._env_.APP_ENV;
export const APP_DEBUG = app_debug;
export const PLATFORM_WEB_HOST = plat_web_host;
export const PLATFORM_REGISTER_URL = `${PLATFORM_WEB_HOST}/register`;
export const WEB_HELP_HOST = `https://${WEB_HOST_HELP_SUBDOMAIN}.${WEB_HOST_APEX_DOMAIN}`;
export const TOS_URL = `https://providefinance.com/borrowers-hub/terms-of-business/`;
export const PRIVACY_POLICY_URL = `https://providefinance.com/borrowers-hub/privacy-policy/`;
export const PITCH4_COMPLETION_PROCESS = `${WEB_HELP_HOST}/knowledge/what-is-the-completion-process`;
export const BRAND_MAIN_CONTACT = '0800 7723 180';
export const BRAND_NAME = 'Provide Finance';
export const BRAND_NAME_UPPERCASE = 'PROVIDE FINANCE';
export const BRAND_NAME_FULL = `${BRAND_NAME} trading as Yellow Stone Finance Group Ltd`;

export const DEFAULT_SEARCH_PARAMS = {
    cursor: null,
    pages: [],
    first: 25,
    sorts: [],
    filters: [],
};
