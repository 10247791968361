import { call, put, takeLatest } from 'redux-saga/effects';

import {
    GET_MATCHING_CRITERIA_CONFIG,
    actionSetMatchingCriteriaConfig,
    actionUpdateLoanEnquiryMatchesSuccess,
    UPDATE_LENDER_LOAN_MATCH_FAVOURITE,
    REMOVE_LENDER_LOAN_ENQUIRY_ALERT,
} from '../redux/main';
import { getConfig } from '../api/get_config';
import apolloFetchCreator, { setMatchFavourite } from '../../../favourites/api/set_match_favourites';
import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import { removeLenderLoanEnquiryAlert } from '../api/remove_lender_loan_enquiry_alert';

function* apiGetMatchingCriteriaConfig() {
    const response = yield call(getConfig);

    switch (response.getClassName()) {
        case 'GetConfigSuccess':
            const config = response.getData();

            // Set the form initial values
            yield put(actionSetMatchingCriteriaConfig(config));
            break;
        case 'GetConfigError':
        default:
            break;
    }
}

function* updateLenderLoanEnquiryFavourite(action) {
    const response = yield call(
        setMatchFavourite,
        apolloFetchCreator(BORROWER_URL),
        action.lenderLoanEnquiryId,
        action.loanEnquiryId,
        action.favourite
    );
    const favourite = response.setLenderLoanEnquiryFavourite.matched.favourite;

    // Trigger redux update
    yield put(actionUpdateLoanEnquiryMatchesSuccess(favourite));
}

function* apiRemoveLenderLoanEnquiryAlert(action) {
    yield call(removeLenderLoanEnquiryAlert, action.lenderLoanEnquiryId);
}

//Main Saga
function* main() {
    yield takeLatest(GET_MATCHING_CRITERIA_CONFIG, apiGetMatchingCriteriaConfig);
    yield takeLatest(UPDATE_LENDER_LOAN_MATCH_FAVOURITE, updateLenderLoanEnquiryFavourite);
    yield takeLatest(REMOVE_LENDER_LOAN_ENQUIRY_ALERT, apiRemoveLenderLoanEnquiryAlert);
}

export default main;
