import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ROLE_INTERMEDIARY } from '../../../../authentication/enum/Roles/NameEnum';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import ReviewMatchedLendersForm from '../components/ReviewMatchedLendersForm';
import { actionSetMatchStatuses } from '../redux/main';

class ReviewMatchedLendersContainer extends React.Component {
    render() {
        const { matches, setMatchStatuses } = this.props;

        const initialValues = {
            match: {},
        };

        matches.forEach((match) => {
            initialValues.match[match.id] = true;
        });

        return (
            <ReviewMatchedLendersForm
                matches={matches}
                initialValues={initialValues}
                setMatchStatuses={setMatchStatuses}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMatchStatuses: (statuses) => {
            dispatch(actionSetMatchStatuses(statuses));
        },
    };
};

ReviewMatchedLendersContainer.propTypes = {
    matches: PropTypes.array.isRequired,
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_INTERMEDIARY])
)(ReviewMatchedLendersContainer);
