import React, { useState } from 'react';
import { Button, FormControl, CircularProgress, InputAdornment, OutlinedInput } from '@material-ui/core';

export default function RevenueModal(props) {
    const { setModalClose, currentRevenue, handleRevenueUpdate } = props;
    const [submitting, setSubmitting] = useState(false);
    const [revenue, setRevenue] = useState(currentRevenue);

    const handleSubmit = () => {
        setSubmitting(true);
        handleRevenueUpdate(revenue);
    };

    return (
        <div className={'le-revenue-container-modal'}>
            <div className={'header'}>Loan Enquiry Revenue</div>
            <div className={'content'}>
                <FormControl variant="outlined">
                    <OutlinedInput
                        variant="outlined"
                        onChange={(event) => setRevenue(event.target.value)}
                        value={revenue}
                        startAdornment={<InputAdornment position="start">£</InputAdornment>}
                        inputProps={{ step: '.01' }}
                        type="number"
                        placeholder="0"
                    />
                </FormControl>
            </div>
            <div className={'actions'}>
                <Button className={'btn-back'} onClick={() => setModalClose()}>
                    BACK
                </Button>
                <Button
                    className={'btn-submit'}
                    variant={'contained'}
                    disabled={submitting || revenue.length === 0}
                    color="primary"
                    onClick={() => handleSubmit()}
                >
                    {true === submitting ? <CircularProgress size={18} /> : 'SUBMIT'}
                </Button>
            </div>
        </div>
    );
}
