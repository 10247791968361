import React from 'react';

class Body1 extends React.Component {
    render() {
        let { children, ...rest } = this.props;

        return (
            <span className={`body-1`} {...rest}>
                {children}
            </span>
        );
    }
}

export default Body1;
