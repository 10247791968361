import React from 'react';
import Container from './Container';
import Item from './Item';

class LeftAligned extends React.Component {
    render() {
        let { children, className = '', containerProps = {}, style = {}, ...itemProps } = this.props;

        return (
            <Container justifyContent={`flex-start`} {...containerProps}>
                <Item {...itemProps} style={style} className={className}>
                    {children}
                </Item>
            </Container>
        );
    }
}

export default LeftAligned;
