import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import moment from 'moment';

export default function CompletedDateModal(props) {
    const { setModalClose, loanEnquiry, handleUpdateCompletedDate } = props;
    const [completedDate, setCompletedDate] = useState(
        loanEnquiry.completedDate
            ? moment(loanEnquiry.completedDate).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD')
    );
    const handleSubmit = () => {
        handleUpdateCompletedDate(loanEnquiry, completedDate);
        setModalClose();
    };

    return (
        <div className={'complete-modal-container'}>
            <div className={'one-panel'}>
                <div>
                    <div className={'header'}>Please select completed date</div>
                    <div className={'completed-date-input'}>
                        <TextField
                            className={'completed-date-picker'}
                            id="completedDate"
                            type="date"
                            defaultValue={completedDate}
                            value={completedDate}
                            variant={'outlined'}
                            onChange={(event) => {
                                setCompletedDate(event.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className={'actions'}>
                    <Button onClick={() => setModalClose()}>CANCEL</Button>
                    <Button
                        onClick={() => handleSubmit()}
                        variant={'contained'}
                        color={'primary'}
                        disabled={'' === completedDate}
                    >
                        CONFIRM
                    </Button>
                </div>
            </div>
        </div>
    );
}
