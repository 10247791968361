import React from 'react';
import { Container, Dialog, DialogContent } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import ApplicantContainer from '../components/Applicants/containers/ApplicantContainer';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent/CardContent';
import LoanTypeSelectorContainer from '../components/LoanTypeSelector/containers/LoanTypeSelectorContainer';
import { Loading } from '../../../pitch4_layout/components/waiting';
import CommercialTypeSelectorContainer from '../components/CommercialTypeSelector/containers/CommercialTypeSelectorContainer';
import _ from 'lodash';
import BusinessDetailsContainer from '../components/BusinessDetails/containers/BusinessDetailsContainer';
import BusinessFinancialsContainer from '../components/BusinessFinancials/containers/BusinessFinancialsContainer';
import { validateSection } from './MakeLoanEnquiryValidtion';
import PortfolioSelectionContainer from '../components/PortfolioSelection/containers/PortfolioSelectionContainer';
import PropertySelectionContainer from '../components/PropertySelection/containers/PropertySelectionContainer';
import TermSelectionContainer from '../components/TermSelection/containers/TermSelectionContainer';
import BridgingSelectionContainer from '../components/BridgingSelection/containers/BridgingSelectionContainer';
import RefurbishmentSelectionContainer from '../components/RefurbishmentSelection/containers/RefurbishmentSelectionContainer';
import SecondChargeSelectionContainer from '../components/SecondChargeSelection/containers/SecondChargeSelectionContainer';
import DevelopmentSelectionContainer from '../components/DevelopmentSelection/containers/DevelopmentSelectionContainer';
import MezzanineSelectionContainer from '../components/MezzanineSelection/containers/MezzanineSelectionContainer';
import PropertyInformationContainer from '../components/PropertyInformation/containers/PropertyInformationContainer';
import CreditAndCitizenshipContainer from '../components/CreditAndCitizenship/containers/CreditAndCitizenshipContainer';
import SummaryContainer from '../components/Summary/containers/SummaryContainer';
import AdditionalInformation from '../components/AdditionalInformation/containers/AdditionalInformation';
import { createLoanEnquiry } from '../api/create_loan_enquiry';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import {
    path,
    ROUTE_BORROWER_LOAN_ENQUIRIES,
    ROUTE_BORROWER_LOAN_ENQUIRY_VIEW,
    ROUTE_INTERMEDIARY_LOAN_ENQUIRIES,
    ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW,
} from '../../../pitch4_routing';
import { BORROWER_URL, INTERMEDIARY_URL } from '../../../bootstrap/constants/constants';
import { saveDraft } from '../api/save_draft';
import Notice from '../../../pitch4_layout/components/content/Notice';
import { BRAND_MAIN_CONTACT } from '../../../bootstrap/constants/constants';
import PreApprovalContainer from '../components/PreApprovalSelection/containers/PreApprovalContainer';
import LocationConfirmationContainer from '../components/LocationConfirmationSelection/containers/LocationConfirmationContainer';
import { HUBSPOT_CHAT_OPEN_KEY, HUBSPOT_CHAT_OPEN_VALUE_NEW_LE } from '../../../pitch4_utils/HubSpotSettings';
import { NO, YES } from '../enums/Shared/YesNo';
import {
    BRIDGE_TYPE,
    BUSINESS_TYPE,
    DEVELOPMENT_TYPE,
    MEZZANINE_TYPE,
    PORTFOLIO_TYPE,
    PROPERTY_TYPE,
    REFURBISHMENT_TYPE,
    SECOND_CHARGE_TYPE,
    TERM_TYPE,
    UNKNOWN_TYPE,
} from '../enums/Borrower/BorrowerLoanType';
import Questions, {
    QUESTION_AGREE_TO_TERMS,
    QUESTION_BUILD_COSTS_BORROWING,
    QUESTION_COMMERCIAL_OWNER_OCCUPIED,
    QUESTION_COMMERCIAL_UNITS_RENTED_OUT,
    QUESTION_CURRENT_VALUE,
    QUESTION_DAY_ONE_LOAN_AMOUNT,
    QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY,
    QUESTION_FUNDING_TYPE,
    QUESTION_LOAN_AMOUNT,
    QUESTION_LOAN_PURPOSE,
    QUESTION_LOAN_TERM,
    QUESTION_LOAN_TYPE,
    QUESTION_OCCUPY_GREATER_THAN_40_PERCENT,
    QUESTION_OCCUPY_PROPERTY,
    QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES,
    QUESTION_PERCENTAGE_COMMERCIAL,
    QUESTION_PERCENTAGE_RESIDENTIAL,
    QUESTION_PRE_APPROVE,
    QUESTION_PROPERTY_TYPE,
    QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY,
    QUESTION_RENTAL_INCOME,
    QUESTION_RESIDENTIAL_UNIT_VALUE,
    QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES,
} from '../enums/Borrower/BorrowerQuestions';
import BorrowerQuestions from '../enums/Borrower/BorrowerQuestions';
import { RESIDENTIAL_FUNDING_TYPE, SEMI_COMMERCIAL_FUNDING_TYPE } from '../enums/Shared/CommercialFundingType';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_INTERMEDIARY, ROLE_SUPER_ADMIN } from '../../authentication/enum/Roles/NameEnum';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import withSession from '../../../pitchblack_react_utils/session/withSession';
import renderErrorIfAuthorisationFail from '../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { getEnquiry } from '../api/get_enquiry';
import { STATUS_DRAFT } from '../../borrower/enum/LoanEnquiries/StatusEnum';
import { deleteDraft } from '../api/delete_draft';
import {
    QUESTION_PERCENTAGE_COMMERCIAL_VALUE,
    QUESTION_PERCENTAGE_RESIDENTIAL_VALUE,
} from '../enums/Broker/BrokerQuestions';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

class MakeLoanEnquiryContainer extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeHandler = this.onChangeHandler.bind(this);
        this.handleCreditOnChange = this.handleCreditOnChange.bind(this);

        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole();
        const roleName = userRole !== null ? userRole.name : ROLE_BORROWER;
        const loanEnquiryUrl =
            roleName === ROLE_INTERMEDIARY ? ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW : ROUTE_BORROWER_LOAN_ENQUIRY_VIEW;
        const enquiryListView =
            roleName === ROLE_INTERMEDIARY ? ROUTE_INTERMEDIARY_LOAN_ENQUIRIES : ROUTE_BORROWER_LOAN_ENQUIRIES;

        this.state = {
            completedSteps: [],
            formData: new BorrowerQuestions().getInitialFormData(),
            validation: new BorrowerQuestions().getInitialValidationData(),
            activeStep: 0,
            bluefrogModalOpen: false,
            unsureModalOpen: false,
            steps: [],
            submitting: false,
            loanEnquiryUrl: loanEnquiryUrl,
            enquiryListView: enquiryListView,
            loanEnquiryId: null,
            loanEnquiryStatus: null,
            roleName: roleName,
        };
    }

    componentDidMount() {
        const {
            match: {
                params: { loanEnquiryId },
            },
        } = this.props;
        const { roleName } = this.state;
        this.setState({ loanEnquiryId: loanEnquiryId });
        if (loanEnquiryId) {
            getEnquiry(roleName === ROLE_INTERMEDIARY ? INTERMEDIARY_URL : BORROWER_URL, { loanEnquiryId }).then(
                (res) => {
                    const loanEnquiryStatus = res.loanEnquiry.status;
                    const loanEnquiryData = res.loanEnquiry.loanEnquiryData;
                    const loanEnquiryFormData = new Questions().getFormDataFromLoanEnquiryData(
                        loanEnquiryData,
                        res.loanEnquiry.loanEnquiryApplicants
                    );
                    this.setState({ formData: loanEnquiryFormData, loanEnquiryStatus: loanEnquiryStatus });
                    this.handleSteps();
                }
            );
        } else {
            this.handleSteps();
        }
    }

    onChangeHandler = (event) => {
        let dataFromForm = this.state.formData;
        const questions = new Questions().getTypes();
        const field = event.target.name;
        let value = event.target.value;
        let fieldType = null;
        if (typeof event.target.getAttribute === 'function') {
            fieldType = event.target.getAttribute('data-type') ?? event.target.getAttribute('type');
        }

        if (field === questions[QUESTION_AGREE_TO_TERMS].fieldName) {
            value = event.target.checked;
        }

        if (
            field === questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName ||
            field === questions[QUESTION_BUILD_COSTS_BORROWING].fieldName ||
            field === questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName
        ) {
            let loanAmount = '0';
            const dayOneLoanAmount =
                field === questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName
                    ? value
                    : dataFromForm[questions[QUESTION_DAY_ONE_LOAN_AMOUNT].fieldName];
            const buildCosts =
                field === questions[QUESTION_BUILD_COSTS_BORROWING].fieldName
                    ? value
                    : dataFromForm[questions[QUESTION_BUILD_COSTS_BORROWING].fieldName];
            const refurbCosts =
                field === questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName
                    ? value
                    : dataFromForm[questions[QUESTION_REFURBISHMENT_COST_INCLUDING_CONTINGENCY].fieldName];

            if (dayOneLoanAmount !== '') {
                loanAmount = String(Number(loanAmount) + Number(dayOneLoanAmount));
            }

            if (buildCosts !== '') {
                loanAmount = String(Number(loanAmount) + Number(buildCosts));
            }

            if (refurbCosts !== '') {
                loanAmount = String(Number(loanAmount) + Number(refurbCosts));
            }
            dataFromForm[questions[QUESTION_LOAN_AMOUNT].fieldName] = loanAmount;
        }

        if (fieldType === 'percent') {
            if (_.toInteger(value) < 0) {
                value = '0';
            } else if (_.toInteger(value) > 100) {
                value = '100';
            }
        }

        // I realise I'm forcefully setting the state, but it's not listening to me.
        // I don't care to debug this any further.
        // Don't @Me.
        // @DennisL.
        if (field === questions[QUESTION_FUNDING_TYPE].fieldName && value === UNKNOWN_TYPE) {
            // eslint-disable-next-line
            this.state.unsureModalOpen = true;
            this.setState({ unsureModalOpen: true });
        }

        // Hacks to fix data entry
        if (
            field === questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName &&
            '' === dataFromForm[questions[QUESTION_RENTAL_INCOME].fieldName] &&
            dataFromForm[questions[QUESTION_FUNDING_TYPE].fieldName] === SECOND_CHARGE_TYPE &&
            NO === this.state.formData[questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName]
        ) {
            dataFromForm[questions[QUESTION_RENTAL_INCOME].fieldName] = '0';
        }

        if (field === questions[QUESTION_FUNDING_TYPE].fieldName) {
            dataFromForm[questions[QUESTION_LOAN_PURPOSE].fieldName] = '';
            dataFromForm[questions[QUESTION_LOAN_TERM].fieldName] = '';
        }

        if (
            (dataFromForm[questions[QUESTION_OCCUPY_PROPERTY].fieldName] === YES ||
                (field === questions[QUESTION_OCCUPY_PROPERTY].fieldName && value === YES)) &&
            (dataFromForm[questions[QUESTION_OCCUPY_GREATER_THAN_40_PERCENT].fieldName] === YES ||
                (field === questions[QUESTION_OCCUPY_GREATER_THAN_40_PERCENT].fieldName && value === YES)) &&
            (dataFromForm[questions[QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES].fieldName] === NO ||
                (field === questions[QUESTION_SECOND_CHARGE_BUSINESS_PURPOSES].fieldName && value === NO))
        ) {
            // eslint-disable-next-line
            this.state.bluefrogModalOpen = true;
            this.setState({ bluefrogModalOpen: true });
        }

        if (
            (dataFromForm[questions[QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY].fieldName] === YES ||
                (field === questions[QUESTION_FAMILY_HAS_EVER_OCCUPIED_PROPERTY].fieldName && value === YES)) &&
            (dataFromForm[questions[QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES].fieldName] === NO ||
                (field === questions[QUESTION_OWNS_OTHER_INVESTMENT_PROPERTIES].fieldName && value === NO))
        ) {
            // eslint-disable-next-line
            this.state.bluefrogModalOpen = true;
            this.setState({ bluefrogModalOpen: true });
        }

        if (
            field === questions[QUESTION_RESIDENTIAL_UNIT_VALUE].fieldName &&
            dataFromForm[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
            dataFromForm[questions[QUESTION_FUNDING_TYPE].fieldName] === TERM_TYPE &&
            dataFromForm[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE
        ) {
            const propertyValue = dataFromForm[questions[QUESTION_CURRENT_VALUE].fieldName];
            let percentageResidential = Math.round((_.toInteger(value) / _.toInteger(propertyValue)) * 100);
            if (percentageResidential > 100) {
                percentageResidential = 100;
            }
            if (percentageResidential < 0) {
                percentageResidential = 0;
            }
            dataFromForm[questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName] = percentageResidential.toString();
            dataFromForm[questions[QUESTION_PERCENTAGE_COMMERCIAL_VALUE].fieldName] = (
                100 - percentageResidential
            ).toString();
        }

        if (field === questions[QUESTION_PERCENTAGE_RESIDENTIAL].fieldName) {
            dataFromForm[questions[QUESTION_PERCENTAGE_COMMERCIAL].fieldName] = (100 - _.toInteger(value)).toString();
            dataFromForm[questions[QUESTION_PERCENTAGE_RESIDENTIAL].fieldName] = value.toString();
        }

        if (field === questions[QUESTION_PERCENTAGE_COMMERCIAL].fieldName) {
            dataFromForm[questions[QUESTION_PERCENTAGE_RESIDENTIAL].fieldName] = (100 - _.toInteger(value)).toString();
            dataFromForm[questions[QUESTION_PERCENTAGE_COMMERCIAL].fieldName] = value.toString();
        }

        if (field === questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName) {
            dataFromForm[questions[QUESTION_PERCENTAGE_COMMERCIAL_VALUE].fieldName] = (
                100 - _.toInteger(value)
            ).toString();
            dataFromForm[questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName] = value.toString();
        }

        if (field === questions[QUESTION_PERCENTAGE_COMMERCIAL_VALUE].fieldName) {
            dataFromForm[questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName] = (
                100 - _.toInteger(value)
            ).toString();
            dataFromForm[questions[QUESTION_PERCENTAGE_COMMERCIAL_VALUE].fieldName] = value.toString();
        }

        if (
            (field === questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName ||
                field === questions[QUESTION_OCCUPY_PROPERTY].fieldName) &&
            '' === dataFromForm[questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName] &&
            NO === dataFromForm[questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName] &&
            NO === dataFromForm[questions[QUESTION_OCCUPY_PROPERTY].fieldName]
        ) {
            dataFromForm[questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName] = YES;
        } else {
            dataFromForm[questions[QUESTION_COMMERCIAL_UNITS_RENTED_OUT].fieldName] = NO;
        }

        // Reset validation for changed field
        let updatedValidation = this.state.validation;
        updatedValidation[field + '_error'] = '';

        dataFromForm[field] = value;
        this.setState({
            validation: updatedValidation,
            formData: dataFromForm,
        });

        this.handleSteps();
    };

    validateSection = (section) => {
        const validationData = this.state.validation;
        const result = validateSection(validationData, this.state.formData, section);

        this.setState({
            ...this.state,
            validation: result.validationData,
        });

        return result.isValidated;
    };

    handleNext = (section) => {
        const isValidated = this.validateSection(section);
        const { activeStep, loanEnquiryUrl, loanEnquiryId, roleName } = this.state;
        const { dispatch } = this.props;
        let completedSteps = this.state.completedSteps;

        if (activeStep === this.state.steps.length - 1) {
            this.setState({ ...this.state, submitting: true }, () => {
                createLoanEnquiry(roleName === ROLE_INTERMEDIARY ? INTERMEDIARY_URL : BORROWER_URL, {
                    data: this.state.formData,
                    enquiryId: loanEnquiryId,
                }).then((resp) => {
                    // Redirect
                    dispatch(
                        push(
                            path(loanEnquiryUrl, {
                                loanEnquiryId: resp.data.submitLoanEnquiry.loanEnquiry.id,
                            }) + `?${HUBSPOT_CHAT_OPEN_KEY}=${HUBSPOT_CHAT_OPEN_VALUE_NEW_LE}`
                        )
                    );
                });
            });
        } else {
            if (true === isValidated) {
                if (false === completedSteps.includes(section)) {
                    completedSteps = [...completedSteps, section];
                }
                this.setState({ ...this.state, completedSteps: completedSteps, activeStep: this.state.activeStep + 1 });
            }
        }
    };

    handleSaveDraft = () => {
        const { dispatch } = this.props;
        const { loanEnquiryUrl, roleName, loanEnquiryId } = this.state;
        saveDraft(roleName === ROLE_INTERMEDIARY ? INTERMEDIARY_URL : BORROWER_URL, {
            data: this.state.formData,
            enquiryId: loanEnquiryId,
        }).then((resp) => {
            // redirect to LE screen
            dispatch(
                push(
                    path(loanEnquiryUrl, {
                        loanEnquiryId: resp.data.submitLoanEnquiry.loanEnquiry.id,
                    })
                )
            );
        });
    };

    handleDeleteDraft = () => {
        const { dispatch } = this.props;
        const { enquiryListView, roleName, loanEnquiryId } = this.state;
        deleteDraft(roleName === ROLE_INTERMEDIARY ? INTERMEDIARY_URL : BORROWER_URL, {
            enquiryId: loanEnquiryId,
        }).then((resp) => {
            // redirect to LE screen
            dispatch(push(path(enquiryListView)));
        });
    };

    handleBack = () => {
        this.setState({ ...this.state, activeStep: this.state.activeStep - 1 });
    };

    handleSteps = () => {
        let { formData } = this.state;
        let sessionManager = new SessionManager();
        let session;
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            session = sessionManager.getAssumedSession();
        } else {
            session = sessionManager.getSession();
        }
        const isInt = session.getMostSeniorRole().name === ROLE_INTERMEDIARY;
        const isPartner = isInt && session.isPartnerBroker();
        let showPreApprove = false;
        if (true !== isInt) {
            showPreApprove = false;
        }
        if (true === isInt && false === isPartner) {
            showPreApprove = false;
        }
        if (true === isInt && true === isPartner) {
            showPreApprove = true;
        }

        const questions = new BorrowerQuestions().getTypes();
        let steps = [
            { key: 'applicant', label: 'Applicant' },
            { key: 'loan-type', label: 'Funding' },
        ];

        // Everything is done in this specific order to ensure steps are in the correct order.

        if (this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE) {
            steps = _.union(steps, [{ key: 'commercial-type', label: 'Property Type' }]);
            steps = _.union(steps, [{ key: 'property-information', label: 'Property Information' }]);
        }

        if (
            this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === PORTFOLIO_TYPE
        ) {
            steps = _.union(steps, [{ key: 'portfolio-selection', label: 'Portfolio Financing' }]);
        }

        if (
            this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE
        ) {
            steps = _.union(steps, [{ key: 'property-selection', label: 'Property Financing' }]);
        }

        if (
            this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === TERM_TYPE
        ) {
            steps = _.union(steps, [{ key: 'term-selection', label: 'Term Finance' }]);
        }

        if (
            this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === BRIDGE_TYPE
        ) {
            if (this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] !== RESIDENTIAL_FUNDING_TYPE) {
                steps = _.union(steps, [{ key: 'bridge-selection', label: 'Bridging Finance' }]);
            }
        }

        if (
            this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === REFURBISHMENT_TYPE
        ) {
            steps = _.union(steps, [{ key: 'refurbishment-selection', label: 'Refurbishment Finance' }]);
        }

        if (
            this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === SECOND_CHARGE_TYPE
        ) {
            steps = _.union(steps, [{ key: 'second-charge-selection', label: 'Second Charge Finance' }]);
        }

        if (
            this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === DEVELOPMENT_TYPE
        ) {
            steps = _.union(steps, [{ key: 'development-selection', label: 'Development Finance' }]);
        }

        if (
            this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === MEZZANINE_TYPE
        ) {
            steps = _.union(steps, [{ key: 'mezzanine-selection', label: 'Mezzanine Finance' }]);
        }

        if (
            this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === BUSINESS_TYPE &&
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE
        ) {
            steps = _.union(steps, [
                { key: 'business-details', label: 'Business Details' },
                { key: 'business-financials', label: 'Business Financials' },
            ]);
        }

        steps = _.union(steps, [{ key: 'credit', label: 'Credit and Citizenship' }]);
        steps = _.union(steps, [{ key: 'additional', label: 'Additional Information' }]);
        if (showPreApprove === true) {
            steps = _.union(steps, [{ key: 'preapproved', label: 'Pre-Approve Lenders' }]);
        } else {
            formData[questions[QUESTION_PRE_APPROVE].fieldName] = NO;
        }
        steps = _.union(steps, [{ key: 'location_confirmation', label: 'Confirmation' }]);
        steps = _.union(steps, [{ key: 'summary', label: 'Summary' }]);

        this.setState({ ...this.state, steps: steps });
    };

    handleCreditOnChange = (event, applicant) => {
        const dataFromForm = this.state.formData;
        const value = event.target.value;
        const field = event.target.name;
        switch (field) {
            case 'main_credit_difficulties':
                dataFromForm.applicants.main.credit_difficulties = value;
                break;
            case 'main_credit_difficulties_explanation':
                dataFromForm.applicants.main.credit_difficulties_explanation = value;
                break;
            case 'main_uk_citizen':
                dataFromForm.applicants.main.uk_citizen = value;
                break;
            case 'main_country_of_residence':
                dataFromForm.applicants.main.country_of_residence = value;
                break;
            case 'main_country_of_residence_other':
                dataFromForm.applicants.main.country_of_residence_other = value;
                break;
            case 'main_nationality':
                dataFromForm.applicants.main.nationality = value;
                break;
            case 'main_nationality_other':
                dataFromForm.applicants.main.nationality_other = value;
                break;
            case 'main_occupation':
                dataFromForm.applicants.main.occupation = value;
                break;
            case 'main_gross_income':
                dataFromForm.applicants.main.gross_income = value;
                break;
            default:
                break;
        }
        this.setState({ ...this.state, formData: dataFromForm });
    };

    renderStep = (type) => {
        switch (type) {
            case 'applicant':
                return (
                    <ApplicantContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                        setMainApplicant={this.setMainApplicant}
                        deleteFormSubApplicant={this.deleteSubApplicant}
                    />
                );
            case 'loan-type':
                return (
                    <LoanTypeSelectorContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'commercial-type':
                return (
                    <CommercialTypeSelectorContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'property-information':
                return (
                    <PropertyInformationContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'portfolio-selection':
                return (
                    <PortfolioSelectionContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'property-selection':
                return (
                    <PropertySelectionContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'term-selection':
                return (
                    <TermSelectionContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'bridge-selection':
                return (
                    <BridgingSelectionContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'refurbishment-selection':
                return (
                    <RefurbishmentSelectionContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'second-charge-selection':
                return (
                    <SecondChargeSelectionContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'development-selection':
                return (
                    <DevelopmentSelectionContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'mezzanine-selection':
                return (
                    <MezzanineSelectionContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'business-details':
                return (
                    <BusinessDetailsContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'business-financials':
                return (
                    <BusinessFinancialsContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'credit':
                return (
                    <CreditAndCitizenshipContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                        handleCreditOnChange={this.handleCreditOnChange}
                    />
                );
            case 'additional':
                return (
                    <AdditionalInformation
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'preapproved':
                return (
                    <PreApprovalContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'location_confirmation':
                return (
                    <LocationConfirmationContainer
                        formData={this.state.formData}
                        validation={this.state.validation}
                        onChangeHandler={this.onChangeHandler}
                    />
                );
            case 'summary':
                return <SummaryContainer formData={this.state.formData} />;
            default:
                return <Loading />;
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    closeUnsureModal = () => {
        this.setState({ unsureModalOpen: false });
    };

    closeBlueFrogModal = () => {
        this.setState({ bluefrogModalOpen: false });
    };

    deleteSubApplicant = (index) => {
        const sa = this.state.formData.applicants.sub;
        const su = sa.filter((item, i) => i !== index);
        const formData = this.state.formData;
        formData.applicants.sub = su;
        this.setState({ ...this.state, formData: formData });
    };

    setMainApplicant = (data) => {
        const formData = this.state.formData;
        formData.applicants.main = data;

        this.setState({ ...this.state, formData: formData });
    };

    showDraftButton = () => {
        const { loan_type, funding_type } = this.state.formData;
        const { activeStep } = this.state;

        const questions = new BorrowerQuestions().getTypes();
        if (this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] === PROPERTY_TYPE) {
            return loan_type !== '' && funding_type !== '' && activeStep > 0;
        } else {
            return loan_type !== '' && activeStep > 0;
        }
    };

    showDeleteDraftButton = () => {
        const { loanEnquiryId, loanEnquiryStatus } = this.state;

        return loanEnquiryId !== null && loanEnquiryStatus !== null && loanEnquiryStatus === STATUS_DRAFT;
    };

    skipToStep = (step, stepKey) => {
        const completedSteps = this.state.completedSteps;
        if (this.state.activeStep !== step && true === completedSteps.includes(stepKey)) {
            this.setState({ ...this.state, activeStep: step });
        }
    };

    render() {
        const activeStep = this.state.activeStep;
        if (this.state.steps.length === 0) {
            return <Loading />;
        }
        const skipToSection = activeStep === 0;
        const assistanceMessage = 'Do You Need Any Assistance With This Process?';
        const telephoneMessage = `Please call us on ${BRAND_MAIN_CONTACT} to talk to a member of our admin team.`;
        const skipToTelephoneMessage = `For assistance call us on ${BRAND_MAIN_CONTACT} to talk to a member of our admin team.`;
        const message = <span>{skipToSection ? skipToTelephoneMessage : telephoneMessage}</span>;

        return (
            <Container maxWidth="xl">
                <div className={'make-loan-enquiry'}>
                    <Dialog open={this.state.bluefrogModalOpen} onClose={() => this.closeBlueFrogModal()}>
                        <DialogContent>
                            <h2 style={{ marginTop: 0 }}>Unfortunately we're unable to help</h2>
                            <p>
                                Residential Mortgages are regulated by the Financial Conduct Authority, our system
                                cannot currently source this type of mortgage.
                            </p>
                            <p>
                                Please contact our associate Syed Abbas on{' '}
                                <a target="_blank" rel="noreferrer" href="tel:+447727184668">
                                    07727184668
                                </a>
                                , who will be able to assist.
                            </p>
                        </DialogContent>
                    </Dialog>
                    <Dialog open={this.state.unsureModalOpen} onClose={() => this.closeUnsureModal()}>
                        <DialogContent>
                            <h2 style={{ marginTop: 0 }}>Give us a call</h2>
                            <p>Let us walk you through the process, give the team at Provide Finance a call today.</p>
                            <p>
                                <a href="tel:+448007723180">0800 772 3180</a>
                            </p>
                        </DialogContent>
                    </Dialog>

                    <Stepper activeStep={activeStep} orientation="vertical">
                        {this.state.steps.map((step, index) => (
                            <Step key={step.key}>
                                <StepLabel onClick={() => this.skipToStep(index, step.key)}>{step.label}</StepLabel>
                                <StepContent>
                                    <div className={'business-stream-telephone-box-wrapper'}>
                                        <div
                                            className={'business-stream-telephone-box'}
                                            id={'business-stream-telephone-box'}
                                        >
                                            <Notice
                                                style={{
                                                    width: 'unset',
                                                    maxWidth: 600,
                                                    height: 'unset',
                                                    marginBottom: 10,
                                                }}
                                                messageHeader={assistanceMessage}
                                                message={message}
                                            />
                                        </div>
                                    </div>
                                    <Card className={'mle-card'} sx={{ p: 2 }}>
                                        <CardContent>
                                            {this.renderStep(step.key)}
                                            <div className={'make-loan-enquiry-buttons'}>
                                                {activeStep !== 0 ? (
                                                    <Button
                                                        disabled={activeStep === 0}
                                                        onClick={this.handleBack}
                                                        className={'back-button'}
                                                    >
                                                        Previous
                                                    </Button>
                                                ) : (
                                                    ''
                                                )}
                                                <Button
                                                    variant="contained"
                                                    disabled={this.state.submitting}
                                                    onClick={() => {
                                                        this.handleNext(step.key);
                                                    }}
                                                    color="primary"
                                                    className={'next-button'}
                                                >
                                                    {activeStep === this.state.steps.length - 1 ? 'Submit' : 'Next'}
                                                </Button>
                                                {this.showDeleteDraftButton() && (
                                                    <Button
                                                        variant={'text'}
                                                        onClick={() => this.handleDeleteDraft()}
                                                        color={'primary'}
                                                        className={'draft-delete-button'}
                                                        startIcon={<DeleteIcon />}
                                                    >
                                                        Delete Draft
                                                    </Button>
                                                )}
                                                {this.showDraftButton() && (
                                                    <Button
                                                        variant={'text'}
                                                        onClick={() => this.handleSaveDraft()}
                                                        color={'primary'}
                                                        className={'draft-save-button'}
                                                        startIcon={<SaveIcon />}
                                                    >
                                                        Save Draft
                                                    </Button>
                                                )}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(
    connect(null, mapDispatchToProps),
    withRouter,
    withSession,
    renderErrorIfAuthorisationFail([ROLE_BORROWER, ROLE_INTERMEDIARY])
)(MakeLoanEnquiryContainer);
