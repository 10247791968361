import React from 'react';
import LoginForm from '../components/LoginForm';
import { sagaActionRedirectToDashboard } from '../sagas/dashboard';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import SessionManager from '../../../pitchblack_react_utils/session/SessionManager';
import { CenteringContainer } from '../../../pitch4_layout/components/div';
import { Container, Item } from '../../../pitchblack_react_utils/layout/index';
import { BRAND_NAME, PLATFORM_REGISTER_URL, TOS_URL, WEB_HOST } from '../../../bootstrap/constants/constants';
// import {
//     API_URL,
//     TAG_MANAGER_AUTH,
//     TAG_MANAGER_ID,
//     TAG_MANAGER_DATA_LAYER_NAME,
//     TAG_MANAGER_PREVIEW,
//     RECAPTCHA_KEY,
// } from '../../../bootstrap/constants/constants';

class LoginContainer extends React.Component {
    componentDidMount() {
        const { dashboardRedirect } = this.props;
        let sessionManager = new SessionManager();
        if (sessionManager.isAuthenticated()) {
            dashboardRedirect();
        }
    }

    render() {
        return (
            <React.Fragment>
                <Item className={'content-container'}>
                    <LoginForm />
                </Item>
                <Container className={'footer-container'}>
                    <React.Fragment>
                        <CenteringContainer>
                            <a href={PLATFORM_REGISTER_URL}>Need an account? Click here to register</a>
                        </CenteringContainer>
                        <CenteringContainer className={'links'}>
                            <a href={WEB_HOST}>About {BRAND_NAME}</a>
                            <a href={`${WEB_HOST}/contact-us`}>Contact Us</a>
                            <a href={TOS_URL}>Terms & Conditions</a>
                        </CenteringContainer>
                    </React.Fragment>
                </Container>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dashboardRedirect: () => dispatch(sagaActionRedirectToDashboard()),
    };
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(LoginContainer);
