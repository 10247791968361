import { call, select, takeLatest } from 'redux-saga/effects';
import {
    actionHubspotTrackingGetLastPageViewed,
    actionHubspotTrackingGetVisitor,
    HUBSPOT_TRACKING_IDENTIFY_VISITOR,
    HUBSPOT_TRACKING_INITIALISE,
    HUBSPOT_TRACKING_PAGE_VIEW,
    HUBSPOT_TRACKING_REMOVE_COOKIE,
} from '../redux/tracking';
import { HUBSPOT_ACCOUNT_ID } from '../../../bootstrap/constants/constants';

export const SAGA_NAMESPACE = 'hubspot.tracking';

function* initialiseTrackingCode() {
    yield call(() => {
        const script = document.createElement('script');

        script.id = 'hs-script-loader';
        script.src = `//js.hs-scripts.com/${HUBSPOT_ACCOUNT_ID}.js`;
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);
    });
}

function* identifyVisitor() {
    let visitor = yield select(actionHubspotTrackingGetVisitor);

    yield call(() => {
        let _hsq = (window._hsq = window._hsq || []);

        _hsq.push([
            'identify',
            {
                email: visitor.email,
                ...visitor.extraProperties,
            },
        ]);

        _hsq.push(['trackPageView']);
    });
}

function* trackPageView() {
    let lastPage = yield select(actionHubspotTrackingGetLastPageViewed);

    yield call(() => {
        let _hsq = (window._hsq = window._hsq || []);

        _hsq.push(['setPath', lastPage]);

        // Track the page view for the new page
        _hsq.push(['trackPageView']);
    });
}

function* removeCookie() {
    yield call(() => {
        let _hsq = (window._hsq = window._hsq || []);
        _hsq.push(['revokeCookieConsent']);
    });
}

// Main Saga
function* main() {
    yield takeLatest(HUBSPOT_TRACKING_INITIALISE, initialiseTrackingCode);
    yield takeLatest(HUBSPOT_TRACKING_IDENTIFY_VISITOR, identifyVisitor);
    yield takeLatest(HUBSPOT_TRACKING_PAGE_VIEW, trackPageView);
    yield takeLatest(HUBSPOT_TRACKING_REMOVE_COOKIE, removeCookie);
}

export default main;
