import React from 'react';
import MUIAppBar from '@material-ui/core/AppBar/AppBar';

class AppBar extends React.Component {
    render() {
        let { position = `static`, ...props } = this.props;

        return (
            <MUIAppBar className={`app-bar`} position={position} {...props}>
                {this.props.children}
            </MUIAppBar>
        );
    }
}

export default AppBar;
