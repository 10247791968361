import {
    ALL_COMMERCIAL_TYPES,
    ASSET_TYPES,
    COMMERCIAL_PORTFOLIO_TYPES,
    COMMERCIAL_TYPES,
    HMO_TYPES,
    INVOICE_TYPES,
    LAND_WITH_PLANNING_TYPES,
    MERCHANT_CASH_ADVANCE_TYPES,
    MIXED_FUNDING_TYPE,
    MIXED_PORTFOLIO_TYPES,
    R_C_F_TYPES,
    RESIDENTIAL_PORTFOLIO_TYPES,
    RESIDENTIAL_TYPES,
    SEMI_COMMERCIAL_TYPES,
    SUPPLY_CHAIN_TYPES,
    TERM_LONG_TYPES,
    TERM_SHORT_TYPES,
} from '../enums/Shared/Validation';
import { QUESTION_PROPERTY_POSTCODE } from '../enums/Borrower/BorrowerQuestions';
import {
    COMMERCIAL_FUNDING_TYPE,
    COMMERCIAL_PORTFOLIO_FUNDING_TYPE,
    HMO_FUNDING_TYPE,
    LAND_WITHOUT_PLANNING_FUNDING_TYPE,
    MIXED_PORTFOLIO_FUNDING_TYPE,
    RESIDENTIAL_FUNDING_TYPE,
    RESIDENTIAL_PORTFOLIO_FUNDING_TYPE,
    SEMI_COMMERCIAL_FUNDING_TYPE,
} from '../enums/Shared/CommercialFundingType';
import {
    ASSET_TYPE,
    INVOICE_TYPE,
    MERCHANT_CASH_ADVANCE_TYPE,
    R_C_F_TYPE,
    SUPPLY_CHAIN_TYPE,
    TERM_LONG_TYPE,
    TERM_SHORT_TYPE,
} from '../enums/Borrower/BorrowerLoanType';

export function validate(validationData, formData, fields, isValidated, questions) {
    const postcodeRegex = /(GIR 0AA)|((([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][ABCDEFGHJKSTUW])|([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][ABEHMNPRVWXY])))) [0-9][ABDEFGHJLNPQRSTUWXYZ]{2})/i;
    let concatField = '';
    let formField = '';
    fields.forEach((field) => {
        // Oh, god. This is... hacky.
        formField = formData[field.form];
        if (field.form.includes('.')) {
            concatField = field.form.split('.');
            formField = formData[concatField[0]];
            concatField.forEach((fieldPart, index) => {
                if (index !== 0) {
                    formField = formField[concatField[index]];
                }
            });
        }
        validationData[field.validation] = formField !== '' ? '' : field.message;
        if (field.form === 'applicants.main' && formField === null) {
            validationData['applicants_error']['main'] = field.message;
            isValidated = false;
        }
        if (field.form === questions[QUESTION_PROPERTY_POSTCODE].fieldName) {
            validationData[field.validation] = postcodeRegex.test(formData[field.form]) ? '' : field.message;
        }
        if (validationData[field.validation] !== '') {
            isValidated = false;
        }
    });

    return isValidated;
}

export function validateFundingTypes(section, fundingType, fields, questions, formData, validationData, isValidated) {
    if (fields.hasOwnProperty(COMMERCIAL_TYPES) && COMMERCIAL_FUNDING_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[COMMERCIAL_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(SEMI_COMMERCIAL_TYPES) && SEMI_COMMERCIAL_FUNDING_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[SEMI_COMMERCIAL_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(RESIDENTIAL_TYPES) && RESIDENTIAL_FUNDING_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[RESIDENTIAL_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(HMO_TYPES) && HMO_FUNDING_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[HMO_TYPES], isValidated, questions);
    } else if (
        fields.hasOwnProperty(RESIDENTIAL_PORTFOLIO_TYPES) &&
        RESIDENTIAL_PORTFOLIO_FUNDING_TYPE === fundingType
    ) {
        isValidated = validate(validationData, formData, fields[RESIDENTIAL_PORTFOLIO_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(COMMERCIAL_PORTFOLIO_TYPES) && COMMERCIAL_PORTFOLIO_FUNDING_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[COMMERCIAL_PORTFOLIO_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(MIXED_PORTFOLIO_TYPES) && MIXED_PORTFOLIO_FUNDING_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[MIXED_PORTFOLIO_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(TERM_LONG_TYPES) && TERM_LONG_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[TERM_LONG_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(TERM_SHORT_TYPES) && TERM_SHORT_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[TERM_SHORT_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(R_C_F_TYPES) && R_C_F_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[R_C_F_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(INVOICE_TYPES) && INVOICE_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[INVOICE_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(ASSET_TYPES) && ASSET_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[ASSET_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(MERCHANT_CASH_ADVANCE_TYPES) && MERCHANT_CASH_ADVANCE_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[MERCHANT_CASH_ADVANCE_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(SUPPLY_CHAIN_TYPES) && SUPPLY_CHAIN_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[SUPPLY_CHAIN_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(LAND_WITH_PLANNING_TYPES) && LAND_WITHOUT_PLANNING_FUNDING_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[LAND_WITH_PLANNING_TYPES], isValidated, questions);
    } else if (fields.hasOwnProperty(MIXED_FUNDING_TYPE) && MIXED_FUNDING_TYPE === fundingType) {
        isValidated = validate(validationData, formData, fields[MIXED_FUNDING_TYPE], isValidated, questions);
    } else {
        isValidated = validate(validationData, formData, fields[ALL_COMMERCIAL_TYPES], isValidated, questions);
    }

    return isValidated;
}
