import React from 'react';
import PDFIcon from '../../../assets/images/pdf-icon.svg';
import { Button } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { handleDownload } from '../../../pitch4_documents/src/components/DownloadButton';
import { Loading } from '../../../pitchblack_react_utils/form/renderIfSubmitting';

export default function ConfirmationModal(props) {
    const { setModalClose, document } = props;

    const downloadDocument = (document) => () => {
        let { setModalClose, setModalComponent } = props;

        handleDownload(document, {
            onPending: () => setModalComponent(<Loading heading="Please wait. Downloading file." />, false),
            onSuccess: () => setModalClose(),
            onFailure: () => setModalClose(),
        });
    };

    return (
        <div className={'lender-terms-matrix-confirmation-modal-container'}>
            <div className={'icon-container'}>
                <img src={PDFIcon} alt={''} />
            </div>
            <div className={'header-container'}>Terms Matrix Saved</div>
            <div className={'text-container'}>
                Your Terms Matrix is saved as an editable PDF. After downloading you are able to complete the terms and
                details for the off-panel lenders and save, ready to send to the end client.
            </div>
            <div className={'action-container'}>
                <Button className={'btn-back'} color={'primary'} variant={'outlined'} onClick={() => setModalClose()}>
                    close
                </Button>
                <Button
                    className={'btn-export'}
                    color={'primary'}
                    variant={'contained'}
                    onClick={downloadDocument(document)}
                >
                    <CloudDownloadIcon className={`cloud-icon`} />
                    Download
                </Button>
            </div>
        </div>
    );
}
