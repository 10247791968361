import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import BorrowerQuestions, {
    QUESTION_COMMERCIAL_UNIT_COUNT,
    QUESTION_LENDER,
    QUESTION_PROPERTY_TYPE,
    QUESTION_RENTAL_INCOME,
    QUESTION_PERCENTAGE_RESIDENTIAL_SQFT,
    QUESTION_PERCENTAGE_RESIDENTIAL_VALUE,
    QUESTION_COMMERCIAL_TYPES,
    QUESTION_COMMERCIAL_TYPE_OTHER,
    QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE,
    QUESTION_COMMERCIAL_OWNER_OCCUPIED,
} from '../../../enums/Borrower/BorrowerQuestions';
import Question from '../../Question/containers/Question';
import { COMMERCIAL_FUNDING_TYPE, SEMI_COMMERCIAL_FUNDING_TYPE } from '../../../enums/Shared/CommercialFundingType';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';
import { OTHER } from '../../../enums/Shared/PropertyLocation';
import { CommercialTypeEnum } from '../../../../admin/enum/matching/enum';
import YesNo, { NO } from '../../../enums/Shared/YesNo';

class SecondChargeSelectionContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const commercialTypeOptions = new CommercialTypeEnum().getOptions();
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const yesNoOptions = new YesNo().getTypes();
        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER
                            ? 'About your Second Charge Finance'
                            : 'About your clients Second Charge Finance'}
                    </Typography>
                </Grid>
                <Question
                    type={questions[QUESTION_LENDER].type}
                    label={questions[QUESTION_LENDER].label}
                    questionEnum={'QUESTION_LENDER'}
                    fieldName={questions[QUESTION_LENDER].fieldName}
                    placeholder={questions[QUESTION_LENDER].placeholder}
                    validation={questions[QUESTION_LENDER].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                <Question
                    type={questions[QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE].type}
                    label={questions[QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE].label}
                    questionEnum={'QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE'}
                    fieldName={questions[QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE].fieldName}
                    placeholder={questions[QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE].placeholder}
                    validation={questions[QUESTION_FIRST_CHARGE_AMOUNT_SECOND_CHARGE].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                {(this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === COMMERCIAL_FUNDING_TYPE ||
                    this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] ===
                        SEMI_COMMERCIAL_FUNDING_TYPE) && (
                    <>
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].type}
                            label={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].label}
                            questionEnum={'QUESTION_COMMERCIAL_OWNER_OCCUPIED'}
                            fieldName={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={yesNoOptions}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_COMMERCIAL_UNIT_COUNT].type}
                            label={questions[QUESTION_COMMERCIAL_UNIT_COUNT].label}
                            questionEnum={'QUESTION_COMMERCIAL_UNIT_COUNT'}
                            fieldName={questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_UNIT_COUNT].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_UNIT_COUNT].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            type={questions[QUESTION_COMMERCIAL_TYPES].type}
                            label={questions[QUESTION_COMMERCIAL_TYPES].label}
                            questionEnum={'QUESTION_COMMERCIAL_TYPES'}
                            fieldName={questions[QUESTION_COMMERCIAL_TYPES].fieldName}
                            placeholder={questions[QUESTION_COMMERCIAL_TYPES].placeholder}
                            validation={questions[QUESTION_COMMERCIAL_TYPES].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={commercialTypeOptions}
                            rows={0}
                        />
                        {this.state.formData[questions[QUESTION_COMMERCIAL_TYPES].fieldName].includes(OTHER) && (
                            <Question
                                formData={this.state.formData}
                                formValidation={this.state.validation}
                                type={questions[QUESTION_COMMERCIAL_TYPE_OTHER].type}
                                label={questions[QUESTION_COMMERCIAL_TYPE_OTHER].label}
                                questionEnum={'QUESTION_COMMERCIAL_TYPE_OTHER'}
                                fieldName={questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName}
                                placeholder={questions[QUESTION_COMMERCIAL_TYPE_OTHER].placeholder}
                                validation={questions[QUESTION_COMMERCIAL_TYPE_OTHER].fieldName + '_error'}
                                onChangeFunction={this.props.onChangeHandler}
                                fieldOptions={[]}
                                rows={0}
                            />
                        )}
                    </>
                )}
                {NO === this.state.formData[questions[QUESTION_COMMERCIAL_OWNER_OCCUPIED].fieldName] && (
                    <Question
                        type={questions[QUESTION_RENTAL_INCOME].type}
                        label={questions[QUESTION_RENTAL_INCOME].label}
                        questionEnum={'QUESTION_RENTAL_INCOME'}
                        fieldName={questions[QUESTION_RENTAL_INCOME].fieldName}
                        placeholder={questions[QUESTION_RENTAL_INCOME].placeholder}
                        validation={questions[QUESTION_RENTAL_INCOME].fieldName + '_error'}
                        onChangeFunction={this.props.onChangeHandler}
                        fieldOptions={[]}
                        formData={this.state.formData}
                        formValidation={this.state.validation}
                        rows={0}
                    />
                )}
                {this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] === SEMI_COMMERCIAL_FUNDING_TYPE && (
                    <>
                        <Question
                            type={questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].type}
                            label={questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].label}
                            questionEnum={'QUESTION_PERCENTAGE_RESIDENTIAL_VALUE'}
                            fieldName={questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName}
                            placeholder={questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].placeholder}
                            validation={questions[QUESTION_PERCENTAGE_RESIDENTIAL_VALUE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].type}
                            label={questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].label}
                            questionEnum={'QUESTION_PERCENTAGE_RESIDENTIAL_SQFT'}
                            fieldName={questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].fieldName}
                            placeholder={questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].placeholder}
                            validation={questions[QUESTION_PERCENTAGE_RESIDENTIAL_SQFT].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    </>
                )}
            </Grid>
        );
    }
}

export default SecondChargeSelectionContainer;
