import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { API_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: API_URL });

const LoanEnquirySetStatusesMutation = `
  mutation updateBrokerWaiting($lenderLoanEnquiry: ID!, $brokerWaiting: DateTime) {
      setBrokerWaiting(lenderLoanEnquiry: $lenderLoanEnquiry, brokerWaiting: $brokerWaiting) {
        success
      }
    }
`;

export const updateBrokerWaiting = (lenderLoanEnquiry, brokerWaiting) => {
    return apolloFetch({
        query: LoanEnquirySetStatusesMutation,
        variables: {
            lenderLoanEnquiry: lenderLoanEnquiry,
            brokerWaiting: brokerWaiting,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            throw error;
        });
};
