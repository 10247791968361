import { call, put, takeLatest } from 'redux-saga/effects';
import { REMOVE_LOAN_ENQUIRY_ALERT, CLEAR_USER_ALERTS } from '../redux';
import { removeLoanEnquiryAlert } from '../api/remove_loan_enquiry_alert';
import { clearUserAlerts } from '../api';
import SessionManager from '../../../../pitchblack_react_utils/session/SessionManager';
import { SET_USER_ALERTS_ACTIVE } from '../../../user/configure_profile/redux';

function* apiRemoveLoanEnquiryAlert(action) {
    yield call(removeLoanEnquiryAlert, action.loanEnquiryId);
}

function* apiClearUserAlerts(action) {
    const response = yield call(clearUserAlerts, action.loanEnquiryId);

    if (action.loanEnquiryId) {
        // Mark all LLEs as read
        if (response.clearAllLoanEnquiryAlert.success) {
            window.location.reload(false);
        }
    } else {
        //Mark all LEs as read
        if (response.clearUserAlerts.success) {
            const sm = new SessionManager();
            const alertActive = false;

            sm.setAlertsActive(alertActive);
            yield put({ type: SET_USER_ALERTS_ACTIVE, alertActive: alertActive });

            window.location.reload(false);
        }
    }
}

function* main() {
    yield takeLatest(REMOVE_LOAN_ENQUIRY_ALERT, apiRemoveLoanEnquiryAlert);
    yield takeLatest(CLEAR_USER_ALERTS, apiClearUserAlerts);
}

export default main;
