import React from 'react';
import PropTypes from 'prop-types';
import { Mutation, Query } from 'react-apollo';
import { P4StyleErrorContainer } from 'pitch4_layout/components/div';
import { goBack, push } from 'react-router-redux';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../apps/notifications/sagas/container';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { Loading } from 'pitch4_layout/components/waiting';

class QueryMutation extends React.Component {
    render() {
        let {
            //query props
            query,
            queryContext = {},
            queryVariables = {},
            mapQueryFunction,
            renderFormFunction,
            mutationQuery,
        } = this.props;

        //note polling is disabled on purpose. Dont enable it.
        return (
            <Query
                fetchPolicy={'no-cache'}
                context={queryContext}
                query={query}
                variables={queryVariables}
                pollInterval={0}
            >
                {({ errors, error, data, loading, refetch, ...stuff }) => {
                    if (loading) return <Loading />;
                    if (error) return <P4StyleErrorContainer />;

                    let mappedValues = mapQueryFunction(data);

                    return (
                        <Mutation mutation={mutationQuery}>
                            {(mutate) => {
                                return renderFormFunction(mappedValues, mutate, refetch, this.props);
                            }}
                        </Mutation>
                    );
                }}
            </Query>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

QueryMutation.propTypes = {
    query: PropTypes.any.isRequired,
    queryContext: PropTypes.object,
    queryVariables: PropTypes.object,
    mapQueryFunction: PropTypes.func.isRequired,
    renderFormFunction: PropTypes.func.isRequired,
    mutationQuery: PropTypes.any.isRequired,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(QueryMutation);
