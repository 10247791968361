import React from 'react';
import SessionManager from './SessionManager';

//function
export default function logProps(WrappedComponent) {
    function getDisplayName(WrappedComponent) {
        return WrappedComponent.displayName || WrappedComponent.name || 'Component';
    }

    class WithSession extends React.Component {
        render() {
            let session = new SessionManager();

            // Wraps the input component in a container, without mutating it. Good!
            return <WrappedComponent session={session} {...this.props} />;
        }
    }

    WithSession.displayName = `WithSession(${getDisplayName(WrappedComponent)})`;

    return WithSession;
}
