import React from 'react';
import { Checkbox, Grid, InputBase, MenuItem, Radio, Select, TextField } from '@material-ui/core';
import { filterTokens } from '../../../../utils/filter-tokens';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import _ from 'lodash';
const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
            prefix="£"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function Question(props) {
    const {
        formData,
        formValidation,
        label,
        type,
        questionEnum,
        fieldName,
        placeholder,
        validation,
        rows,
        fieldOptions,
    } = props;

    const questionLabel = <p>{filterTokens(label)}</p>;

    return (
        <>
            {type === 'number' && (
                <>
                    <Grid item xs={12} md={6} className={questionEnum}>
                        {questionLabel}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            onChange={props.onChangeFunction}
                            value={formData[fieldName]}
                            type={type}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            step="1"
                            xs={12}
                            md={4}
                            error={formValidation[validation] !== ''}
                            id={fieldName}
                            name={fieldName}
                            placeholder={placeholder}
                            variant="outlined"
                            helperText={formValidation[validation]}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                </>
            )}
            {type === 'percent' && (
                <>
                    <Grid item xs={12} md={6} className={questionEnum}>
                        {questionLabel}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            onChange={props.onChangeFunction}
                            value={formData[fieldName]}
                            type="number"
                            inputProps={{
                                inputMode: 'numeric',
                                pattern: '[0-9]*',
                                min: 0,
                                max: 100,
                                'data-type': type,
                            }}
                            step="1"
                            xs={12}
                            md={4}
                            error={formValidation[validation] !== ''}
                            id={fieldName}
                            name={fieldName}
                            placeholder={placeholder}
                            variant="outlined"
                            helperText={formValidation[validation]}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                </>
            )}
            {type === 'currency' && (
                <>
                    <Grid item xs={12} md={6} className={questionEnum}>
                        {questionLabel}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            onChange={props.onChangeFunction}
                            value={formData[fieldName]}
                            type={type}
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            // eslint-disable-next-line
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            step="1"
                            xs={12}
                            md={4}
                            error={formValidation[validation] !== ''}
                            id={fieldName}
                            name={fieldName}
                            placeholder={placeholder}
                            variant="outlined"
                            helperText={formValidation[validation]}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                </>
            )}
            {type === 'radio' && (
                <>
                    <Grid item xs={12} md={6} className={questionEnum}>
                        {questionLabel}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <RadioGroup
                            aria-label={fieldName}
                            name={fieldName}
                            value={formData[fieldName]}
                            onChange={props.onChangeFunction}
                        >
                            {fieldOptions.map((type) => (
                                <FormControlLabel
                                    value={type.value}
                                    control={<Radio />}
                                    key={type.value}
                                    label={filterTokens(type.label)}
                                />
                            ))}
                        </RadioGroup>
                        {formValidation[validation] !== '' && (
                            <p className={'typography-field-error'}>{formValidation[validation]}</p>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                </>
            )}
            {type === 'text' && (
                <>
                    <Grid item xs={12} md={6} className={questionEnum}>
                        {questionLabel}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            onChange={props.onChangeFunction}
                            value={formData[fieldName]}
                            xs={12}
                            md={4}
                            error={formValidation[validation] !== ''}
                            id={fieldName}
                            name={fieldName}
                            placeholder={placeholder}
                            variant="outlined"
                            helperText={formValidation[validation]}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                </>
            )}
            {type === 'textarea' && (
                <>
                    <Grid item xs={12} md={6} className={questionEnum}>
                        {questionLabel}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            multiline={true}
                            onChange={props.onChangeFunction}
                            value={formData[fieldName]}
                            type={type}
                            minRows={rows}
                            xs={12}
                            md={4}
                            error={formValidation[validation] !== ''}
                            id={fieldName}
                            name={fieldName}
                            placeholder={placeholder}
                            variant="outlined"
                            helperText={formValidation[validation]}
                        />
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                </>
            )}
            {type === 'checkbox' && (
                <>
                    <p>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData[fieldName]}
                                    onChange={props.onChangeFunction}
                                    name={fieldName}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            }
                            label={label}
                            labelPlacement={label}
                        />
                    </p>
                    {formValidation[validation] !== true && (
                        <p className={'typography-field-error'}>{formValidation[validation]}</p>
                    )}
                </>
            )}
            {type === 'select' && (
                <>
                    <Grid item xs={12} md={6} className={questionEnum}>
                        {questionLabel}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            value={formData[fieldName]}
                            onChange={props.onChangeFunction}
                            input={<BootstrapInput />}
                            inputProps={{
                                name: fieldName,
                                id: fieldName,
                            }}
                            displayEmpty={true}
                            renderValue={(value) => {
                                if (!value) {
                                    return 'Please Select';
                                }

                                return filterTokens(fieldOptions.find((item) => item.value === value).label);
                            }}
                        >
                            {fieldOptions.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    <p>{filterTokens(type.label)}</p>
                                </MenuItem>
                            ))}
                        </Select>
                        {formValidation[validation] !== '' && (
                            <p className={'typography-field-error'}>{formValidation[validation]}</p>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                </>
            )}

            {type === 'no_label_select' && (
                <>
                    <Grid item xs={12} md={12}>
                        <Select
                            value={formData[fieldName]}
                            onChange={props.onChangeFunction}
                            input={<BootstrapInput />}
                            inputProps={{
                                name: fieldName,
                                id: fieldName,
                            }}
                            displayEmpty={true}
                            renderValue={(value) => {
                                if (!value) {
                                    return 'Please Select';
                                }

                                return filterTokens(fieldOptions.find((item) => item.value === value).label);
                            }}
                        >
                            {fieldOptions.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    <p>{filterTokens(type.label)}</p>
                                </MenuItem>
                            ))}
                        </Select>
                        {formValidation[validation] !== '' && (
                            <p className={'typography-field-error'}>{formValidation[validation]}</p>
                        )}
                    </Grid>
                </>
            )}

            {type === 'multiselect' && (
                <>
                    <Grid item xs={12} md={6} className={questionEnum}>
                        {questionLabel}
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Select
                            multiple
                            value={_.isArray(formData[fieldName]) === false ? [] : formData[fieldName]}
                            onChange={props.onChangeFunction}
                            input={<BootstrapInput />}
                            inputProps={{
                                name: fieldName,
                                id: fieldName,
                            }}
                            displayEmpty={true}
                            renderValue={(values) => {
                                if (values.length === 0) {
                                    return 'Please Select';
                                }

                                let selectedVals = [];
                                for (const selectedVal of values) {
                                    selectedVals.push(fieldOptions.find((item) => item.value === selectedVal).label);
                                }
                                return selectedVals.join(', ');
                            }}
                        >
                            {fieldOptions.map((type) => (
                                <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>
                            ))}
                        </Select>
                        {formValidation[validation] !== '' && (
                            <p className={'typography-field-error'}>{formValidation[validation]}</p>
                        )}
                    </Grid>
                    <Grid item xs={12} md={12}></Grid>
                </>
            )}
        </>
    );
}
