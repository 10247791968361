export const NAMESPACE = 'intermediary.view_lead.main';
export const GET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.get_matching_criteria_config`;
export const SET_MATCHING_CRITERIA_CONFIG = `${NAMESPACE}.set_matching_criteria_config`;
export const UPDATE_LENDER_LOAN_MATCH_FAVOURITE = `${NAMESPACE}.update_lender_loan_match_favourite`;
export const UPDATE_LENDER_LOAN_MATCH_FAVOURITE_SUCCESS = `${NAMESPACE}.update_lender_loan_match_favourite_success`;
export const REMOVE_LENDER_LOAN_ENQUIRY_ALERT = `${NAMESPACE}.remove_lender_loan_enquiry_alert`;

export const actionGetMatchingCriteriaConfig = () => ({
    type: GET_MATCHING_CRITERIA_CONFIG,
});

export const actionSetMatchingCriteriaConfig = (config) => ({
    type: SET_MATCHING_CRITERIA_CONFIG,
    config,
});

const initialState = {
    config: {},
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MATCHING_CRITERIA_CONFIG:
            const config = action.config;

            return {
                ...state,
                config,
            };

        case UPDATE_LENDER_LOAN_MATCH_FAVOURITE_SUCCESS:
            const { favourite } = action;
            return {
                ...state,
                favourite: favourite,
            };
        case REMOVE_LENDER_LOAN_ENQUIRY_ALERT:
            const { lenderLoanEnquiryId } = action;
            return {
                ...state,
                lenderLoanEnquiryId,
            };

        default:
            return {
                ...state,
            };
    }
};

export const actionUpdateMatchFavourite = (lenderLoanEnquiryId, loanEnquiryId, favourite) => {
    return {
        type: UPDATE_LENDER_LOAN_MATCH_FAVOURITE,
        lenderLoanEnquiryId,
        loanEnquiryId,
        favourite,
    };
};

export const actionUpdateLoanEnquiryMatchesSuccess = (favourite) => {
    return {
        type: UPDATE_LENDER_LOAN_MATCH_FAVOURITE_SUCCESS,
        favourite,
    };
};

export const actionRemoveLenderLoanEnquiryAlert = (lenderLoanEnquiryId) => {
    return {
        type: REMOVE_LENDER_LOAN_ENQUIRY_ALERT,
        lenderLoanEnquiryId,
    };
};

export const selectorGetMatchingCriteriaConfig = (state) => {
    return state[NAMESPACE].config;
};

export const selectorGetFavourite = (state) => {
    return state[NAMESPACE].favourite;
};

//Default export reducer
export default reducer;
