import { put, takeLatest } from 'redux-saga/effects';
import {
    EDIT_PROFILE_SUBMISSION_SUCCESS,
    SET_MISSING_REQUIRED_FIELDS,
    SET_MISSING_REQUIRED_FIELDS_LIST,
} from '../redux';
import SessionManager from '../../../../pitchblack_react_utils/session/SessionManager';
import { SAGA_ACTION_SUCCESS } from '../../../notifications/sagas/container';

function* handleProfileSubmissionSuccess() {
    const sessionManager = new SessionManager();
    sessionManager.setHasMissingRequiredFields(false);
    sessionManager.setMissingRequiredFieldsList([]);

    // yield put({ type: SET_APPLICANTS, applicants: _.map(applicantEdges, ({ node }) => node) });
    yield put({ type: SAGA_ACTION_SUCCESS, message: 'Profile saved successfully' });
    yield put({ type: SET_MISSING_REQUIRED_FIELDS, hasMissingRequiredFields: false });
    yield put({ type: SET_MISSING_REQUIRED_FIELDS_LIST, missingRequiredFieldsList: [] });
}

//Main Saga
function* main() {
    yield takeLatest(EDIT_PROFILE_SUBMISSION_SUCCESS, handleProfileSubmissionSuccess);
}

export default main;
