import createP4ApolloFetch from '../../../bootstrap/middlewares';

const query = `
    mutation submitLoanEnquiry($id: ID, $input: LoanEnquirySubmissionInput!) {
        submitLoanEnquiry(id: $id, input: $input) {
            loanEnquiry {
                id
            }
        }
    }
`;

export const saveDraft = (uri, { data, enquiryId = null }) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });
    return apolloFetch({
        query: query,
        variables: {
            id: enquiryId,
            input: {
                isDraft: true,
                formData: data,
            },
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
};
