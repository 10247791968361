import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import logo from '../../assets/images/logo.svg';
import { ROUTE_ANON_LOGIN } from '../../pitch4_routing';
import { CenteringContainer, Container } from '../../pitchblack_react_utils/layout';
import { BRAND_NAME, TOS_URL, WEB_HOST } from '../../bootstrap/constants/constants';
import { Grid } from '@material-ui/core';

const RegistrationLayout = ({ children }) => {
    return (
        <>
            <Grid container className={'borrower-product-registration'}>
                <Grid item lg={12} sm={12} md={12} xs={12} className={'page-header'} style={{ height: 100 }}>
                    <img className={'logo'} src={logo} alt={`${BRAND_NAME} &copy; Borrowing Made Simple`} />
                </Grid>
                <Grid
                    container
                    lg={12}
                    sm={12}
                    md={12}
                    xs={12}
                    style={{ position: 'absolute', top: 0, paddingTop: 100, height: '100vh' }}
                >
                    <Grid item lg={6} md={6} sm={12} xs={12} className={'container'}>
                        {children}
                        <Container className={'footer-container'}>
                            <CenteringContainer className={'links'}>
                                <p>
                                    Already have an account?{' '}
                                    <a id="login" href={ROUTE_ANON_LOGIN}>
                                        Click here to login instead
                                    </a>
                                </p>
                            </CenteringContainer>
                            <CenteringContainer className={'links'}>
                                <a href={WEB_HOST}>About {BRAND_NAME}</a>
                                <a href={`${WEB_HOST}/contact-us`}>Contact Us</a>
                                <a href={TOS_URL} target={'_blank'} rel={'noreferrer'}>
                                    Terms & Conditions
                                </a>
                            </CenteringContainer>
                        </Container>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} className={'right-container'}>
                        <CenteringContainer>
                            <h1 className={'heading'}>
                                Create Your Free
                                <br />
                                Company Account
                            </h1>
                        </CenteringContainer>
                        <CenteringContainer>
                            <h2 className={'sub-heading'}>
                                The fastest way to source lenders for your
                                <br />
                                customers. <span className={'strong'}>Source, compare</span> and{' '}
                                <span className={'strong'}>communicate</span>
                                <br />
                                all in one place.
                            </h2>
                        </CenteringContainer>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

RegistrationLayout.propTypes = {
    notifications: PropTypes.element.isRequired,
    children: PropTypes.any.isRequired,
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(RegistrationLayout);
