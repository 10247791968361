import React from 'react';
import { BRAND_NAME } from '../../../bootstrap/constants/constants';

class BrandText extends React.Component {
    render() {
        return <span>{BRAND_NAME}</span>;
    }
}

export default BrandText;
