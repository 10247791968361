import { put, takeLatest } from 'redux-saga/effects';

import { actionModalHide, actionModalSetComponent, actionModalSetComponentInteractive } from '../redux/container';

export const SAGA_NAMESPACE = 'sagas.modals';

//Saga Action Creators
export const SAGA_MODAL_SHOW = `${SAGA_NAMESPACE}.SAGA_MODAL_SHOW`;
export const sagaActionModalShow = (component, interactive = true, className = null) => {
    return {
        type: SAGA_MODAL_SHOW,
        component: component,
        interactive,
        className,
    };
};

export const SAGA_MODAL_HIDE = `${SAGA_NAMESPACE}.SAGA_MODAL_HIDE`;
export const sagaActionModalHide = () => ({
    type: SAGA_MODAL_HIDE,
});

export const SAGA_MODAL_INTERACTIVE = `${SAGA_NAMESPACE}.SAGA_MODAL_INTERACTIVE`;
export const sagaActionModalInteractive = (interactive) => ({
    type: SAGA_MODAL_INTERACTIVE,
    interactive,
});

//Worker Sagas
function* modalShow(action) {
    yield put(actionModalSetComponent(action.component, action.interactive, action.className));
}

function* modalHide(action) {
    yield put(actionModalHide());
}

function* modalInteractive(action) {
    yield put(actionModalSetComponentInteractive(action.interactive));
}

//Main Saga
function* main() {
    yield takeLatest(SAGA_MODAL_SHOW, modalShow);
    yield takeLatest(SAGA_MODAL_HIDE, modalHide);
    yield takeLatest(SAGA_MODAL_INTERACTIVE, modalInteractive);
}

export default main;
