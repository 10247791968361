import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { USER_URL } from '../../../../bootstrap/constants/constants';
import { ROLE_USER } from '../../../authentication/enum/Roles/NameEnum';
import { SubmissionError } from 'redux-form';
import { Container, P4StyleComponentContainer, RowItem } from '../../../../pitch4_layout/components/div';
import { QueryMutation } from '../../../../pitch4_forms/components';
import ChangePasswordContainer from '../../change_password/containers/ChangePasswordContainer';
import _ from 'lodash';
import Mapper from '../form/Mapper';
import Form from '../components/Form';
import TwoFactorAuthSummary from '../components/TwoFactorAuthSummary';
import {
    actionSetProfileSubmissionSuccess,
    selectorHasMissingRequiredFields,
    selectorGetMissingRequiredFieldsList,
} from '../redux/';

import {
    actionGetTwoFactorAuthDetails,
    selectorGetTwoFactorAuthDetails,
    selectorGetTwoFactorAuthFormErrors,
} from '../../two_factor_auth/redux';
import withSession from '../../../../pitchblack_react_utils/session/withSession';
import { connect } from 'react-redux';
import { ROLE_BORROWER } from '../../../../pitch4_enum/enum/RoleNameEnum';
import ResendVerificationEmailSection from '../components/ResendVerificationEmailSection';
import { PROFILE } from '../../../joyride/config';
import { actionSetJoyrideType } from '../../../joyride/redux';

class ConfigureProfileContainer extends React.Component {
    mapValues = (data) => {
        let mapper = new Mapper();

        let mappedValues = {};

        if (data && data.profile) {
            mappedValues = mapper.mapToForm(data.profile);
        }

        return mappedValues;
    };

    displayVerificationSection = () => {
        const { session } = this.props;
        const currentSession = session.getRelevantSession();
        const role = session.getRelevantSession().getMostSeniorRole().name;
        return role === ROLE_BORROWER && currentSession.isVerified() === false;
    };

    componentDidMount() {
        const { getTwoFactorAuthDetails, setJoyrideType } = this.props;

        getTwoFactorAuthDetails(false);
        setJoyrideType(PROFILE);
    }

    render() {
        const {
            match,
            profileHasMissingRequiredFields,
            profileMissingRequiredFieldsList,
            showJobTitle = false,
            twoFactorAuthDetails = {},
        } = this.props;

        return (
            <Container className={`account-settings`}>
                {this.displayVerificationSection() && (
                    <RowItem className={`account-settings-item resend-verification-section`}>
                        <P4StyleComponentContainer
                            headerStyles={{ color: '#2ec4b6' }}
                            header={`Resend Verification Email?`}
                            content={<ResendVerificationEmailSection />}
                        />
                    </RowItem>
                )}

                <RowItem className={`account-settings-item`}>
                    <P4StyleComponentContainer
                        header={`My Account`}
                        content={
                            <QueryMutation
                                query={gql`
                                    query viewProfile {
                                        profile {
                                            email
                                            title
                                            gender
                                            given
                                            family
                                            createdAt
                                            updatedAt
                                            primaryAddress {
                                                address1
                                                address2
                                                address3
                                                address4
                                                country
                                                postcode
                                            }
                                            primaryPhone {
                                                number
                                            }
                                            jobTitle
                                        }
                                    }
                                `}
                                queryContext={{
                                    uri: USER_URL,
                                }}
                                queryVariables={{
                                    id: match.params.id,
                                }}
                                mutationQuery={gql`
                                    mutation EditProfile($input: editProfileInput!) {
                                        editProfile(input: $input) {
                                            profile {
                                                title
                                                gender
                                                given
                                                family
                                                createdAt
                                                updatedAt
                                                primaryAddress {
                                                    address1
                                                    address2
                                                    address3
                                                    address4
                                                    country
                                                    postcode
                                                }
                                                primaryPhone {
                                                    number
                                                }
                                                jobTitle
                                            }
                                        }
                                    }
                                `}
                                renderFormFunction={(mappedValues, mutate, refetch, props) => {
                                    return (
                                        <>
                                            <Form
                                                initialValues={mappedValues}
                                                showJobTitle={showJobTitle}
                                                onSubmit={this.onSubmit(mutate, refetch, props)}
                                                profileHasMissingRequiredFields={profileHasMissingRequiredFields}
                                                profileMissingRequiredFieldsList={profileMissingRequiredFieldsList}
                                            />
                                        </>
                                    );
                                }}
                                mapQueryFunction={this.mapValues}
                            />
                        }
                    />
                </RowItem>
                {!_.isEmpty(twoFactorAuthDetails) && (
                    <RowItem className={`account-settings-item`}>
                        <P4StyleComponentContainer
                            header={`Two Factor Authentication (2FA)`}
                            content={<TwoFactorAuthSummary />}
                        />
                    </RowItem>
                )}
                <RowItem className={`account-settings-item`}>
                    <P4StyleComponentContainer header={`Reset Password`} content={<ChangePasswordContainer />} />
                </RowItem>
            </Container>
        );
    }

    onSubmit = (mutate, refetch, props) => (values, dispatch, formProps) => {
        let { notificationError } = props;
        let { setEditProfileSuccess } = this.props;
        let mapper = new Mapper();
        let mappedValues = mapper.mapFromForm(values);

        //map the variables if necessary
        return mutate({
            variables: {
                input: mappedValues,
            },
            context: {
                uri: USER_URL,
            },
        })
            .then(({ data }) => {
                refetch();
                setEditProfileSuccess();
            })
            .catch((error) => {
                notificationError('Unable to save profile data. Contact support.');
                throw new SubmissionError({ _error: error.graphQLErrors });
            });
    };
}

const mapStateToProps = (state) => {
    return {
        twoFactorAuthDetails: selectorGetTwoFactorAuthDetails(state),
        errors: selectorGetTwoFactorAuthFormErrors(state),
        profileHasMissingRequiredFields: selectorHasMissingRequiredFields(state),
        profileMissingRequiredFieldsList: selectorGetMissingRequiredFieldsList(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTwoFactorAuthDetails: (redirectOnFailure) => {
            dispatch(actionGetTwoFactorAuthDetails(redirectOnFailure));
        },
        setEditProfileSuccess: () => {
            dispatch(actionSetProfileSubmissionSuccess());
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    withSession,
    renderErrorIfAuthorisationFail([ROLE_USER])
)(ConfigureProfileContainer);
