import React from 'react';
import propTypes from 'prop-types';

class EventTagData extends React.Component {
    render() {
        let { tag, data = {} } = this.props;
        let tempFunc;

        let label = '';
        if (data.version === 1 || !data.version) {
            switch (data.type) {
                case 'Channel':
                    tempFunc = () => {
                        let { channel_status = '' } = data;
                        return (
                            <div>
                                Channel({data.id},{channel_status})
                            </div>
                        );
                    };

                    label = tempFunc();
                    break;

                case 'Document':
                    tempFunc = () => {
                        let { type = '' } = data;
                        return <div>File({type})</div>;
                    };

                    label = tempFunc();
                    break;

                case 'User':
                    tempFunc = () => {
                        let { name = '' } = data;
                        return <div>{name}</div>;
                    };

                    label = tempFunc();
                    break;

                case 'UserProfile':
                    tempFunc = () => {
                        let { name = '' } = data;
                        return <div>{name}</div>;
                    };

                    label = tempFunc();
                    break;

                case 'Lender':
                    tempFunc = () => {
                        let { name = '' } = data;
                        return <div>Lender({name})</div>;
                    };

                    label = tempFunc();
                    break;

                case 'LoanEnquiryInbox':
                    tempFunc = () => {
                        let { name = '' } = data;

                        return <div>Business stream({name})</div>;
                    };

                    label = tempFunc();
                    break;

                case 'LenderLoanEnquiry':
                    tempFunc = () => {
                        let { lender_name = '' } = data;
                        return <div>Lead({lender_name})</div>;
                    };

                    label = tempFunc();
                    break;

                case 'LoanEnquiry':
                case 'MakeLoanEnquirySubmission':
                    tempFunc = () => {
                        let { loan_enquiry_id = '' } = data;
                        return (
                            <div>
                                Loan Enquiry(...
                                {loan_enquiry_id.substring(loan_enquiry_id.length - 4, loan_enquiry_id.length)}){' '}
                            </div>
                        );
                    };

                    label = tempFunc();
                    break;

                case 'LenderLoanEnquiryAgreementInPrinciple':
                    tempFunc = () => {
                        let { lle_lender_name = '' } = data;
                        return <div>Loan Enquiry:{lle_lender_name}</div>;
                    };

                    label = tempFunc();
                    break;

                case 'LenderLoanEnquiryAgreementInPrincipleOffer':
                    tempFunc = () => {
                        let { lle_loan_enquiry_id = '' } = data;
                        return <div>{lle_loan_enquiry_id}</div>;
                    };

                    label = tempFunc();
                    break;
                default:
                    return <div className={tag.slug}>?</div>;
            }
        }

        if (label === '') {
            return <div className={`unknown_version_etdata`}>{tag.slug}</div>;
        }

        return label;
    }
}

EventTagData.propTypes = {
    tag: propTypes.object.isRequired,
    data: propTypes.object,
};

export default EventTagData;
