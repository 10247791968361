import { ROLE_SUPER_ADMIN } from '../../../../../../pitch4_enum/enum/RoleNameEnum';
import { isEmpty } from '../../../../../../pitch4_validation';

export const validateSuperAdmins = (data, errors) => {
    if (data.primaryRole === ROLE_SUPER_ADMIN) {
        if (isEmpty(data.email)) {
            return;
        }

        if (!/@providefinance\.com$/.test(data.email)) {
            errors['primaryRole'] = 'Only providefinance.com users can be super admins';
        }
    }

    return errors;
};
