import React from 'react';
import { compose } from 'recompose';
import withSession from '../../../../../pitchblack_react_utils/session/withSession';
import { RollupCommandContainer } from '../../../../../pitch4_tabular/components';
import { PrimaryButton } from '../../../../../pitch4_layout/components/buttons';
import { connect } from 'react-redux';
import { actionRevertLoanEnquiryToDraft, actionClearUserAlerts } from '../redux/main';
import { push } from 'react-router-redux';
import { STATUS_DRAFT, STATUS_IN_PROGRESS, STATUS_WITHDRAWN_EDITED } from '../../enum/LoanEnquiries/StatusEnum';
import { path, ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY } from '../../../../../pitch4_routing';
import { sagaActionModalShow } from '../../../../modal/sagas/container';
import RevertLoanEnquiryToDraftModal from '../../../../revert_loan_enquiry_to_draft_modal/components/RevertLoanEnquiryToDraftModal';
import { ROLE_BORROWER, ROLE_INTERMEDIARY, ROLE_SUPER_ADMIN } from '../../../../../pitch4_enum/enum/RoleNameEnum';

class LoanEnquiryContextMenuWidget extends React.Component {
    handleRevertToDraft = (loanEnquiryId) => () => {
        const { revertToDraftOnConfirm, setModalComponent } = this.props;
        const handleRTD = () => {
            revertToDraftOnConfirm(loanEnquiryId);
        };
        setModalComponent(<RevertLoanEnquiryToDraftModal handleRevertToDraft={handleRTD} />, true);
    };

    handleClearAllLoanEnquiryAlert = (loanEnquiryId) => () => {
        const { clearUserAlerts } = this.props;
        clearUserAlerts(loanEnquiryId);
    };

    render() {
        const {
            session,
            loanEnquiry,
            redirect,
            toggleMessageDrawer,
            lendersMessageAllowed,
            handleTabSelect,
            toggleLenderTermsMatrixModal,
            selectedLenders,
        } = this.props;

        if (
            session.getMostSeniorRole().name === ROLE_INTERMEDIARY ||
            session.getMostSeniorRole().name === ROLE_SUPER_ADMIN ||
            (session.getMostSeniorRole().name === ROLE_BORROWER && session.getRelevantSession().isVerified())
        ) {
            return (
                <>
                    {loanEnquiry.status === STATUS_WITHDRAWN_EDITED ? (
                        <></>
                    ) : (
                        <div
                            className="loan-enquiry-context-menu"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: 155,
                                paddingTop: 13,
                                paddingLeft: 8,
                                height: 49,
                            }}
                        >
                            <RollupCommandContainer label={'Enquiry Actions'}>
                                {loanEnquiry.status === STATUS_DRAFT && (
                                    <PrimaryButton
                                        onClick={redirect(
                                            path(ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY, {
                                                loanEnquiryId: loanEnquiry.id,
                                            })
                                        )}
                                    >
                                        <strong>Edit</strong>&nbsp; enquiry
                                    </PrimaryButton>
                                )}
                                {loanEnquiry.status === STATUS_IN_PROGRESS && (
                                    <PrimaryButton onClick={this.handleRevertToDraft(loanEnquiry.id)}>
                                        <strong>Revert enquiry</strong>&nbsp; to draft & edit
                                    </PrimaryButton>
                                )}
                                <PrimaryButton onClick={this.handleClearAllLoanEnquiryAlert(loanEnquiry.id)}>
                                    <strong>Mark all updates</strong>&nbsp; as read
                                </PrimaryButton>
                                {!session.hasAssumedToken() && lendersMessageAllowed && (
                                    <PrimaryButton
                                        onClick={() => {
                                            toggleMessageDrawer();
                                            handleTabSelect(1);
                                        }}
                                        disabled={null === selectedLenders || selectedLenders.length === 0}
                                    >
                                        <strong>Send</strong>&nbsp; to selected lenders
                                    </PrimaryButton>
                                )}
                                <PrimaryButton onClick={() => toggleLenderTermsMatrixModal(true)}>
                                    <strong>Export</strong>&nbsp; Terms Matrix
                                </PrimaryButton>
                            </RollupCommandContainer>
                        </div>
                    )}
                </>
            );
        } else {
            return <></>;
        }
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => () => dispatch(push(url)),
        revertToDraftOnConfirm: (loanEnquiryId) => {
            dispatch(actionRevertLoanEnquiryToDraft(loanEnquiryId));
        },
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        clearUserAlerts: (loanEnquiryId) => {
            dispatch(actionClearUserAlerts(loanEnquiryId));
        },
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withSession)(LoanEnquiryContextMenuWidget);
