import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from '../../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { FiltersContainer, SortsContainer } from '../../../../../pitch4_tabular/components';
import { GenericTableContainer } from '../../../../../pitch4_tabular/containers';
import { TableRow, TableCell, TableRowControls } from '../../../../../pitch4_layout/components/tables';
import {
    selectorGetCurrentCursor,
    selectorGetFilters,
    selectorGetPages,
    selectorGetPerPage,
    selectorGetSorts,
} from '../../../../../pitch4_tabular/redux/index_ns';
import { sagaActionSetSort } from '../../../../../pitch4_tabular/sagas';
import { onSortChange, onSortClear, onFilterChange } from '../../../../../pitch4_tabular/utils';
import { ILIKE } from '../../../../../pitch4_tabular/enum/OperationEnum';
import { SORT_DESC } from '../../../../../pitch4_tabular/enum/SortDirectionEnum';
import { Sort, Filter, EnumFilter } from '../../../../../pitch4_tabular/components';
import { EnumLabel } from '../../../../../pitch4_layout/components/data';
import { NAMESPACE } from '../redux';
import NameEnum, { ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import CategoryEnum from '../../enum/Events/CategoryEnum';
import OutcomeEnum from '../../enum/Events/OutcomeEnum';
import { DateTimeFormatter } from '../../../../../pitch4_layout/components/data/formatter';
import EventTagLink from '../components/EventTagLink';
import EventTagLabel from '../components/EventTagLabel';
import _ from 'lodash';
import TypeEnum from '../../enum/Events/TypeEnum';
import { API_URL } from '../../../../../bootstrap/constants/constants';

class TableStyleContainer extends React.Component {
    componentDidMount() {
        let { match, dispatch } = { ...this.props };

        dispatch(
            sagaActionSetSort(
                NAMESPACE,
                match.location
            )({
                column: 'createdAt',
                direction: SORT_DESC,
            })
        );
    }

    render() {
        //put code to map filters to props here. If you need multiple props - escalate at stand up.
        let { match, sorts, pages, filters = [], first, cursor, dispatch } = this.props;

        let roleFilter = filters.filter(({ column }) => {
            return column === 'causedByRole';
        });
        roleFilter =
            roleFilter.length > 0
                ? roleFilter[0]
                : {
                      column: 'causedByRole',
                      operation: ILIKE,
                      operand: '',
                      not: false,
                  };

        let causedByFilter = filters.filter(({ column }) => {
            return column === 'causedById';
        });
        causedByFilter =
            causedByFilter.length > 0
                ? causedByFilter[0]
                : {
                      column: 'causedById',
                      operation: ILIKE,
                      operand: '',
                      not: false,
                  };

        let typeFilter = filters.filter(({ column }) => {
            return column === 'type';
        });
        typeFilter =
            typeFilter.length > 0
                ? typeFilter[0]
                : {
                      column: 'type',
                      operation: ILIKE,
                      operand: '',
                      not: false,
                  };

        let nameSort = sorts.filter(({ column }) => {
            return column === 'createdAt';
        });
        nameSort =
            nameSort.length > 0
                ? nameSort[0]
                : {
                      column: 'createdAt',
                      direction: SORT_DESC,
                  };

        return (
            <div>
                <GenericTableContainer
                    title={`System events`}
                    namespace={NAMESPACE}
                    description={`Watch as borrowers and lenders do things on the system.`}
                    context={{
                        uri: API_URL,
                    }}
                    query={gql`
                        query viewEvents($cursor: String, $first: Int!, $sorts: [SortInput], $filters: [FilterInput]) {
                            events(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
                                totalCount
                                pageInfo {
                                    hasNextPage
                                    hasPreviousPage
                                    startCursor
                                    endCursor
                                }
                                edges {
                                    cursor
                                    node {
                                        id
                                        type
                                        outcome
                                        category
                                        causedById
                                        causedByRole
                                        createdAt
                                        updatedAt
                                        payload
                                        supplementaryData
                                        tags {
                                            id
                                            slug
                                            data
                                            createdAt
                                            updatedAt
                                        }
                                    }
                                }
                            }
                        }
                    `}
                    extractResultDataFunction={({ events = {} }) => {
                        if (!events || !events.edges) {
                            return [];
                        }
                        return [...events.edges];
                    }}
                    extractPaginationDataFunction={({ events = {} }) => {
                        if (!events || !events.edges) {
                            return {};
                        }

                        return {
                            pageInfo: { ...events.pageInfo },
                            totalCount: events.totalCount,
                        };
                    }}
                    renderHeaderFunction={this.renderHeader}
                    renderRowFunction={this.renderRow}
                    filters={
                        <FiltersContainer>
                            <EnumFilter
                                id={`le-role-filter`}
                                label={`Caused By Role`}
                                filterValues={roleFilter}
                                onFilterChange={onFilterChange(NAMESPACE, dispatch, filters, match)}
                                enumIn={new NameEnum()}
                                name={'role_filter'}
                            />
                            <Filter
                                id={`le-causedById-filter`}
                                label={`By User Id`}
                                filterValues={causedByFilter}
                                onFilterChange={onFilterChange(NAMESPACE, dispatch, filters, match)}
                            />
                            <EnumFilter
                                id={`le-type-filter`}
                                label={`Type`}
                                filterValues={typeFilter}
                                onFilterChange={onFilterChange(NAMESPACE, dispatch, filters, match)}
                                enumIn={new TypeEnum()}
                                name={'type_filter'}
                            />
                        </FiltersContainer>
                    }
                    sorts={
                        <SortsContainer>
                            <Sort
                                column={nameSort.column}
                                direction={SORT_DESC}
                                onChange={onSortChange(NAMESPACE, dispatch, match)}
                                onClear={onSortClear(NAMESPACE, dispatch, match)}
                                options={[
                                    { value: 'type', label: 'Type' },
                                    { value: 'causedById', label: 'Caused By Id' },
                                    { value: 'createdAt', label: 'Created' },
                                ]}
                            />
                        </SortsContainer>
                    }
                    variables={{
                        sorts,
                        filters,
                        first,
                        pages,
                        cursor,
                    }}
                    toolbarMobile={
                        <div>
                            <FiltersContainer justifyContent={'flex-start'} style={{ display: 'block' }}>
                                <EnumFilter
                                    id={`le-role-filter`}
                                    label={`Caused By Role`}
                                    filterValues={roleFilter}
                                    onFilterChange={onFilterChange(NAMESPACE, dispatch, filters, match)}
                                    enumIn={new NameEnum()}
                                />
                                <Filter
                                    id={`le-causedById-filter`}
                                    label={`By User Id`}
                                    filterValues={causedByFilter}
                                    onFilterChange={onFilterChange(NAMESPACE, dispatch, filters, match)}
                                />
                                <EnumFilter
                                    id={`le-type-filter`}
                                    label={`Type`}
                                    filterValues={typeFilter}
                                    onFilterChange={onFilterChange(NAMESPACE, dispatch, filters, match)}
                                    enumIn={new TypeEnum()}
                                />
                            </FiltersContainer>
                            <SortsContainer justifyContent={'flex-start'} style={{ display: 'block' }}>
                                <Sort
                                    isMobile={true}
                                    column={nameSort.column}
                                    direction={SORT_DESC}
                                    onChange={onSortChange(NAMESPACE, dispatch, match)}
                                    onClear={onSortClear(NAMESPACE, dispatch, match)}
                                    options={[
                                        { value: 'type', label: 'Type' },
                                        { value: 'causedById', label: 'Caused By Id' },
                                        { value: 'createdAt', label: 'Created' },
                                    ]}
                                />
                            </SortsContainer>
                        </div>
                    }
                />
            </div>
        );
    }

    renderRow = ({ node, cursor }) => {
        const tags = node.tags;

        return (
            <TableRow key={node['id']}>
                <TableCell>
                    <DateTimeFormatter value={node['createdAt']} />
                </TableCell>
                <TableCell>
                    <EventTagLink
                        tag={{}}
                        data={{
                            type: 'User',
                            id: node['causedById'],
                        }}
                    >
                        <EnumLabel enumIn={new NameEnum()} value={node['causedByRole']} />
                    </EventTagLink>
                </TableCell>
                <TableCell>
                    <EnumLabel enumIn={new CategoryEnum()} value={node['category']} />
                </TableCell>
                <TableCell>
                    <EnumLabel enumIn={new TypeEnum()} value={node['type']} />
                </TableCell>
                <TableCell>
                    <EnumLabel enumIn={new OutcomeEnum()} value={node['outcome']} />
                </TableCell>
                <TableCell>
                    {_.map(tags, (tag) => {
                        const data = tag.data;

                        return (
                            <div key={tag.id}>
                                <EventTagLink tag={tag} data={data}>
                                    <EventTagLabel tag={tag} data={data} />
                                </EventTagLink>
                            </div>
                        );
                    })}
                </TableCell>

                <TableRowControls />
            </TableRow>
        );
    };

    renderHeader = () => (
        <TableRow>
            <TableCell>Created</TableCell>
            <TableCell>Caused By</TableCell>
            <TableCell>Category</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Outcome</TableCell>
            <TableCell>Tags</TableCell>
            <TableCell />
        </TableRow>
    );
}

TableStyleContainer.propTypes = {
    sorts: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    pages: PropTypes.array.isRequired,
    cursor: PropTypes.any,
    first: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    return {
        sorts: selectorGetSorts(NAMESPACE)(state),
        pages: selectorGetPages(NAMESPACE)(state),
        cursor: selectorGetCurrentCursor(NAMESPACE)(state),
        filters: selectorGetFilters(NAMESPACE)(state),
        first: selectorGetPerPage(NAMESPACE)(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps),
    renderErrorIfAuthorisationFail([ROLE_SUPER_ADMIN])
)(TableStyleContainer);
