import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, RowItem } from 'pitch4_layout/components/div';
import styled from 'styled-components';
import PrimaryRedirectButton from 'pitch4_layout/components/buttons/PrimaryRedirectButton';
import { ROUTE_P4A_2FA_INDEX } from 'pitch4_routing';
import { FieldButtonPanelRow } from 'pitch4_forms/components/inputs';

const SummaryText = styled.p`
    padding: 20px 0 0;
`;

const SpacerItem = styled(RowItem)``;

class TwoFactorAuthSummary extends React.Component {
    render() {
        return (
            <Container>
                <RowItem>
                    <SummaryText>
                        Two-Factor Authentication (2FA) enables a second authentication step that requires you to enter
                        a verification code, after successfully confirming your username and password.
                    </SummaryText>
                    <SummaryText>
                        The code required will continually change so enabling 2FA will mean that your account will
                        remain secure even if your login credentials are compromised.
                    </SummaryText>
                    <SummaryText>To manage your 2FA settings click the button below.</SummaryText>
                </RowItem>
                <SpacerItem />
                <FieldButtonPanelRow>
                    <PrimaryRedirectButton url={ROUTE_P4A_2FA_INDEX}>View 2FA Settings</PrimaryRedirectButton>
                </FieldButtonPanelRow>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

TwoFactorAuthSummary.propTypes = {};

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(TwoFactorAuthSummary);
