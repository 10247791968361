import { BaseEnum } from 'pitch4_enum';

export const TYPE_NEW = 'new';
export const TYPE_REFINANCE = 'refinance';
export const TYPE_DEVELOPMENT = 'develop';

class PropertyLoanTypeEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_NEW]: 'Purchase',
            [TYPE_REFINANCE]: 'Existing loan',
            [TYPE_DEVELOPMENT]: 'Development',
        };
    };
}

export default PropertyLoanTypeEnum;
