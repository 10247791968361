import _ from 'lodash';

export const normalizeInitialValues = (config, values) => {
    _.forEach(_.keys(values), (section) => {
        _.forEach(_.keys(values[section]), (fact) => {
            let type = _.get(config, ['sections', section, 'fields', fact, 'type']);

            if (type !== undefined && _.indexOf(['integer', 'percent', 'currency'], type) >= 0) {
                values[section][fact] = normalizeNumericValue(values[section][fact]);
            }
        });
    });

    return values;
};

function normalizeNumericValue(fact) {
    if (fact.operation === 'between') {
        fact['operand-start'] = parseInt(fact['operand-start'], 10);
        fact['operand-end'] = parseInt(fact['operand-end'], 10);
    } else {
        // Since we're reusing this functionality and do not want to break existing BusinessSteam editing
        // We're passing in an int as a fact, mapping it to the correct structure for this function to handle
        // and also supplying a unique operation identifier so we can essentially skip any processing this method does
        // and directly output the value
        if (_.isString(fact)) {
            fact = {
                operand: [fact],
                operation: 'skip_int_normalisation',
            };
        }

        fact.operand = _.map(fact.operand, (operand) => parseInt(operand, 10));
    }

    if (fact.operation === 'skip_int_normalisation') {
        return fact.operand[0];
    }

    return fact;
}
