//Slam redux

import administerUsers, { NAMESPACE as ADMINISTER_USERS_REDUCER_NAMESPACE } from '../administer_users/redux';
import administerLenderLoanEnquiryContainer, {
    NAMESPACE as ADMINISTER_LENDERLOANENQUIRY_REDUCER_NAMESPACE,
} from '../administer_loanenquiries_viewlenderloanenquiry/redux';
import administerLoanEnquiriesContainer, {
    NAMESPACE as ADMINISTER_LOANENQUIRIES_REDUCER_NAMESPACE,
} from '../administer_loanenquiries/redux';
import administerLoanEnquiryInboxesCentricContainer, {
    NAMESPACE as ADMINISTER_LOANENQUIRYINBOXES_CENTRIC_REDUCER_NAMESPACE,
} from '../administer_lenders_business_streams/redux';
import administerLenderLoanEnquiryDocumentsContainer, {
    NAMESPACE as ADMINISTER_LENDERLOANENQUIRY_DOCUMENTS_REDUCER_NAMESPACE,
} from '../administer_lenders_loanenquiries_documents/redux';
import administerLenderLoanEnquiryDocumentEditContainer, {
    NAMESPACE as ADMINISTER_LENDERLOANENQUIRY_DOCUMENTS_EDIT_REDUCER_NAMESPACE,
} from '../administer_lenders_loanenquiries_documents/redux/edit';
import administerCompanyDocumentsContainer, {
    NAMESPACE as ADMINISTER_DOCUMENTS_REDUCER_NAMESPACE,
} from '../administer_documents/redux';
import administerDocumentsAddContainer, {
    REDUCER_NAMESPACE as ADMINISTER_DOCUMENTS_ADD_REDUCER_NAMESPACE,
} from '../administer_documents_widget/redux/add';
import administerDocumentsEditContainer, {
    REDUCER_NAMESPACE as ADMINISTER_DOCUMENTS_EDIT_REDUCER_NAMESPACE,
} from '../administer_documents/redux/edit';

import businessStream, { NAMESPACE as BUSINESS_STREAM_NAMESPACE } from '../business_streams/redux';

export default {
    //Administer loan enquiries
    [ADMINISTER_LENDERLOANENQUIRY_REDUCER_NAMESPACE]: administerLenderLoanEnquiryContainer,
    [ADMINISTER_LOANENQUIRIES_REDUCER_NAMESPACE]: administerLoanEnquiriesContainer,

    //Users
    [ADMINISTER_USERS_REDUCER_NAMESPACE]: administerUsers,

    //Inbox Centric
    [ADMINISTER_LOANENQUIRYINBOXES_CENTRIC_REDUCER_NAMESPACE]: administerLoanEnquiryInboxesCentricContainer,

    //Documents
    [ADMINISTER_DOCUMENTS_REDUCER_NAMESPACE]: administerCompanyDocumentsContainer,
    [ADMINISTER_DOCUMENTS_ADD_REDUCER_NAMESPACE]: administerDocumentsAddContainer,
    [ADMINISTER_DOCUMENTS_EDIT_REDUCER_NAMESPACE]: administerDocumentsEditContainer,

    //Lender Loan Enquiries
    [ADMINISTER_LENDERLOANENQUIRY_DOCUMENTS_REDUCER_NAMESPACE]: administerLenderLoanEnquiryDocumentsContainer,
    [ADMINISTER_LENDERLOANENQUIRY_DOCUMENTS_EDIT_REDUCER_NAMESPACE]: administerLenderLoanEnquiryDocumentEditContainer,

    [BUSINESS_STREAM_NAMESPACE]: businessStream,
};
