import ValidationResultFactory from 'pitch4_validation/validation/ValidationResultFactory';
import { SLAM_URL } from 'bootstrap/constants/constants';
import createP4ApolloFetch from 'bootstrap/middlewares';
import _ from 'lodash';
import { OPERATION_BETWEEN } from 'pitch4_enum/enum/OperationEnum';
import { SECTION_SUBMIT } from '../../../../form_wizard/constants';

const apolloFetch = createP4ApolloFetch({ uri: SLAM_URL });

const GetFinanceBusinessStreamValuesQuery = `
    query getBusinessStreamValues($id: ID!) {
        businessStream(id: $id) {
            id
            name
            createdAt
            status
            formSections {
                LoanType { column, operation, operand, not }
                BusinessFinance { column, operation, operand, not }
                IntentToOccupy { column, operation, operand, not }
                Universal { column, operation, operand, not }
                Residential { column, operation, operand, not }
                SemiCommercial { column, operation, operand, not }
                Commercial { column, operation, operand, not }
                LandAndDevelopment { column, operation, operand, not }
                CreditAndCitizenship { column, operation, operand, not }
                CreditAndCitizenshipNew { column, operation, operand, not }
                CreditAndCitizenshipBusinessFinance { column, operation, operand, not }
                IntentToOccupyResidentialBridge { column, operation, operand, not }
                IntentToOccupyResidentialDevelopment { column, operation, operand, not }
                IntentToOccupyResidentialTerm { column, operation, operand, not }
                IntentToOccupyResidentialSecondCharge { column, operation, operand, not }
                IntentToOccupyResidentialRefurbishment { column, operation, operand, not }
                IntentToOccupyResidentialMezzanine { column, operation, operand, not }
                IntentToOccupyCommercialBridge { column, operation, operand, not }
                IntentToOccupyCommercialDevelopment { column, operation, operand, not }
                IntentToOccupyCommercialTerm { column, operation, operand, not }
                IntentToOccupyCommercialSecondCharge { column, operation, operand, not }
                IntentToOccupyCommercialRefurbishment { column, operation, operand, not }
                IntentToOccupyCommercialMezzanine { column, operation, operand, not }
                IntentToOccupySemiCommercialBridge { column, operation, operand, not }
                IntentToOccupySemiCommercialDevelopment { column, operation, operand, not }
                IntentToOccupySemiCommercialTerm { column, operation, operand, not }
                IntentToOccupySemiCommercialSecondCharge { column, operation, operand, not }
                IntentToOccupySemiCommercialRefurbishment { column, operation, operand, not }
                IntentToOccupySemiCommercialMezzanine { column, operation, operand, not }
                IntentToOccupyHMOBridge { column, operation, operand, not }
                IntentToOccupyHMOTerm { column, operation, operand, not }
                IntentToOccupyLandWithoutPlanning { column, operation, operand, not }
                UniversalResidentialBridge { column, operation, operand, not }
                UniversalResidentialRefurbishment { column, operation, operand, not }
                UniversalResidentialDevelopment { column, operation, operand, not }
                UniversalResidentialTerm { column, operation, operand, not }
                UniversalResidentialSecondCharge { column, operation, operand, not }
                UniversalResidentialMezzanine { column, operation, operand, not }
                UniversalSemiCommercialBridge { column, operation, operand, not }
                UniversalSemiCommercialRefurbishment { column, operation, operand, not }
                UniversalSemiCommercialDevelopment { column, operation, operand, not }
                UniversalSemiCommercialTerm { column, operation, operand, not }
                UniversalSemiCommercialSecondCharge { column, operation, operand, not }
                UniversalSemiCommercialMezzanine { column, operation, operand, not }
                UniversalCommercialBridge { column, operation, operand, not }
                UniversalCommercialRefurbishment { column, operation, operand, not }
                UniversalCommercialDevelopment { column, operation, operand, not }
                UniversalCommercialTerm { column, operation, operand, not }
                UniversalCommercialSecondCharge { column, operation, operand, not }
                UniversalCommercialMezzanine { column, operation, operand, not }
                UniversalHMOBridge { column, operation, operand, not }
                UniversalHMORefurbishment { column, operation, operand, not }
                UniversalHMODevelopment { column, operation, operand, not }
                UniversalHMOTerm { column, operation, operand, not }
                UniversalLandWithoutPlanning { column, operation, operand, not }
                UniversalPortfolio { column, operation, operand, not }
                ResidentialRefurbishment { column, operation, operand, not }
                ResidentialDevelopment { column, operation, operand, not }
                ResidentialTerm { column, operation, operand, not }
                ResidentialSecondCharge { column, operation, operand, not }
                ResidentialMezzanine { column, operation, operand, not }
                SemiCommercialBridge { column, operation, operand, not }
                SemiCommercialDevelopment { column, operation, operand, not }
                SemiCommercialTerm { column, operation, operand, not }
                SemiCommercialSecondCharge { column, operation, operand, not }
                SemiCommercialMezzanine { column, operation, operand, not }
                CommercialBridge { column, operation, operand, not }
                CommercialRefurbishment { column, operation, operand, not }
                CommercialDevelopment { column, operation, operand, not }
                CommercialTerm { column, operation, operand, not }
                CommercialSecondCharge { column, operation, operand, not }
                CommercialMezzanine { column, operation, operand, not }
                HMOBridge { column, operation, operand, not }
                HMOTerm { column, operation, operand, not }
                BusinessFinanceTerm { column, operation, operand, not }
                BusinessFinanceSecured { column, operation, operand, not }
                BusinessFinanceMerchant { column, operation, operand, not }
                BusinessFinanceAsset { column, operation, operand, not }
                BusinessFinanceInvoice { column, operation, operand, not }
                BusinessFinanceSupplyChainTradeFinance { column, operation, operand, not }
            }
        }
    }
`;

//response classes
const errorFactory = new ValidationResultFactory();

class GetBusinessStream {
    constructor(className) {
        this.className = className;
    }

    getClassName = () => {
        return this.className;
    };
}

class GetBusinessStreamErrors extends GetBusinessStream {
    constructor(errors) {
        super('GetBusinessStreamErrors');
        this.errors = errors;
    }

    getErrors = () => {
        return this.errors;
    };
}

class GetBusinessStreamSuccess extends GetBusinessStream {
    constructor(data) {
        super('GetBusinessStreamSuccess');
        this.data = data;
        this.formInitialValues = {};
    }

    getBusinessStream = () => {
        const businessStream = _.get(this.data, ['businessStream']);

        if (!businessStream) {
            return {};
        }

        return businessStream;
    };

    getFormSections = () => {
        const formSections = _.get(this.data, ['businessStream', 'formSections']);
        const businessStream = _.get(this.data, ['businessStream', 'name']);

        if (!formSections || !businessStream) {
            return {};
        }

        // Ensure multiple calls to this method don't cause the mapping logic to run more than once
        if (!_.isEmpty(this.formInitialValues)) {
            return this.formInitialValues;
        }

        // Map the API response data into structure expected by redux form
        Object.keys(formSections).forEach((section) => {
            // Although we retrieve all sections some may be null as they are optional. In which case we skip them.
            if (formSections[section] === null) {
                return;
            }

            let mappedFacts = {};

            Object.keys(formSections[section]).forEach((factIndex) => {
                const fact = formSections[section][factIndex];
                let operandObj = {
                    operand: fact['operand'],
                };

                if (fact['operation'] === OPERATION_BETWEEN) {
                    operandObj = {
                        'operand-start': fact['operand'][0],
                        'operand-end': fact['operand'][1],
                    };
                }

                mappedFacts[fact['column']] = {
                    operation: fact['operation'],
                    not: fact['not'],
                    ...operandObj,
                };
            });

            this.formInitialValues[section] = mappedFacts;
        });

        // Add the businessStream
        this.formInitialValues[SECTION_SUBMIT] = { businessStream };

        return this.formInitialValues;
    };
}

export const getBusinessStream = (businessStreamId) => {
    let out = apolloFetch({
        query: GetFinanceBusinessStreamValuesQuery,
        variables: {
            id: businessStreamId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                let vR = errorFactory.createValidationResults();

                errors.forEach((item) => {
                    vR.addResult(new errorFactory.createError(item));
                });

                return new GetBusinessStreamErrors(vR);
            }

            return new GetBusinessStreamSuccess(data);
        })
        .catch((error) => {
            let vR = errorFactory.createValidationResults();

            error.graphQLErrors.forEach((item) => {
                vR.addResult(new errorFactory.createError(item));
            });

            return new GetBusinessStreamErrors(vR);
        });

    return out;
};
