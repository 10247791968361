import React from 'react';
import { Container, Item, RowItem } from '../../../pitch4_layout/components/div';
import PropTypes from 'prop-types';

class FieldRow extends React.Component {
    render() {
        const { label = <div />, input = <div />, id = null, displayFieldBeacon = false } = this.props;

        return (
            <RowItem className={`field-row`} id={id}>
                <Container className={`container`}>
                    <Item xs={12} md={4} className={`label-container`}>
                        {label}
                        {true === displayFieldBeacon && <div id={'field-beacon'}>!</div>}
                    </Item>
                    <Item xs={12} md={8}>
                        {input}
                    </Item>
                </Container>
            </RowItem>
        );
    }
}

FieldRow.propTypes = {
    label: PropTypes.object,
    input: PropTypes.object,
};

export default FieldRow;
