import createP4ApolloFetch from 'bootstrap/middlewares';
import { ANON_URL } from '../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: ANON_URL });

const mutation = `
    mutation verifyEmail($input: anon_verifyEmailInput!) {
        verifyEmail(input: $input) {
          success
        }
    }
`;

export const verifyEmail = (token) => {
    return apolloFetch({
        query: mutation,
        variables: {
            input: {
                token,
            },
        },
    })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return {};
        });
};
