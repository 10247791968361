import React, { useEffect, useState } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { PrimaryButton } from '../../../pitch4_layout/components/buttons';
import { CenteringContainer, Container, Item, RowItem } from '../../../pitchblack_react_utils/layout/index';
import { Checkbox, DateField, TextField } from '../../../pitch4_forms/components/inputs';
import FormMessage, { MESSAGE_ERROR } from '../../../pitch4_forms/components/FormMessage';
import { connect } from 'react-redux';
import HiddenField from '../../../pitch4_forms/components/inputs/HiddenField';
import {
    hasMaxLength255,
    hasMinLength,
    hasMinLength8,
    isDateTime,
    isEmail,
    isInDateRange,
    isMobilePhoneNumber,
    isMobilePhoneNumberValid,
    isRequired,
} from '../../../pitchblack_react_utils/form/validation';
import { hasMaxLength, isEmailValid, isValidPassword } from '../../../pitchblack_react_utils/form/validation';
import { registerHubspot } from '../api/register_hubspot';
import { Box } from '@mui/material';
import { normalizeEmail } from '../../../pitchblack_react_utils/form/Normalize';

const isValidApplicantAge = isInDateRange(
    -100,
    'years',
    'The maximum age allowed for an applicant is 99',
    -18,
    'years',
    'The minimum age allowed for an applicant is 18'
);

export const FormFields = ({ disabled, setTCAndPPAgreement, tcAndPPAgreement, initialValues }) => {
    const passwordValidate = [isRequired, isValidPassword, hasMaxLength(255)];
    const emailValidate = [isRequired, isEmail];
    const nameValidate = [isRequired, hasMinLength(2), hasMaxLength(255)];

    return (
        <div className={'ppc-registration-form'}>
            <RowItem>
                <CenteringContainer>
                    <h2 className={'form-heading'} style={{ marginBottom: -10 }}>
                        Create your password to get started
                    </h2>
                </CenteringContainer>
            </RowItem>
            {null !== initialValues.given && <HiddenField name="given" />}
            {null !== initialValues.family && <HiddenField name="family" />}
            {null !== initialValues.email && <HiddenField name="email" />}
            {initialValues.isPhoneNumberValid && <HiddenField name="phoneNumber" />}
            {null === initialValues.tcAndPPAgreement && <HiddenField name="agreedToMarketing" />}
            {null === initialValues.tcAndPPAgreement && <HiddenField name="commsAgreement" />}
            <HiddenField name="page" />
            {null === initialValues.given && null === initialValues.family && (
                <RowItem>
                    <CenteringContainer alignItems={'flex-start'} style={{ display: 'block' }}>
                        <Box sx={{ display: { md: 'flex', xs: 'none' } }}>
                            <Item lg={6} md={6} className={'item-left'}>
                                <TextField
                                    style={{ marginRight: 20 }}
                                    name="given"
                                    autoComplete={`given-name`}
                                    placeholder="First Name"
                                    variant={`outlined`}
                                    disabled={disabled}
                                    InputProps={{}}
                                    validate={nameValidate}
                                />
                            </Item>
                            <Item lg={6} md={6}>
                                <TextField
                                    name="family"
                                    autoComplete={`additional-name`}
                                    placeholder="Surname"
                                    variant={`outlined`}
                                    disabled={disabled}
                                    InputProps={{}}
                                    validate={nameValidate}
                                />
                            </Item>
                        </Box>
                        <Box sx={{ display: { md: 'none', xs: 'block' }, width: '100%' }}>
                            <Item>
                                <TextField
                                    name="given"
                                    autoComplete={`given-name`}
                                    placeholder="First Name"
                                    variant={`outlined`}
                                    disabled={disabled}
                                    InputProps={{}}
                                    validate={nameValidate}
                                />
                            </Item>
                            <Item>
                                <TextField
                                    name="family"
                                    autoComplete={`additional-name`}
                                    placeholder="Surname"
                                    variant={`outlined`}
                                    disabled={disabled}
                                    InputProps={{}}
                                    validate={nameValidate}
                                />
                            </Item>
                        </Box>
                    </CenteringContainer>
                </RowItem>
            )}
            {null === initialValues.email && (
                <RowItem>
                    <CenteringContainer>
                        <Item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                name="email"
                                autoComplete={`email`}
                                placeholder="Email address"
                                variant={`outlined`}
                                disabled={disabled}
                                InputProps={{}}
                                validate={emailValidate}
                                normalize={normalizeEmail}
                            />
                        </Item>
                    </CenteringContainer>
                </RowItem>
            )}
            <RowItem>
                <CenteringContainer>
                    <Item lg={12} md={12} sm={12} xs={12}>
                        <DateField
                            name="dateOfBirth"
                            placeholder="Date of birth [dd/mm/yyyy]"
                            // disabled={submitting}
                            validate={[isRequired, isDateTime, isValidApplicantAge]}
                            disableFuture={true}
                        />
                    </Item>
                    <Item lg={12} md={12} sm={12} xs={12}>
                        <TextField
                            variant={`outlined`}
                            InputProps={{}}
                            name="password"
                            type="password"
                            placeholder="Password"
                            disabled={disabled}
                            validate={passwordValidate}
                        />
                    </Item>
                    {!initialValues.isPhoneNumberValid && (
                        <Item lg={12} md={12} sm={12} xs={12}>
                            <TextField
                                variant={`outlined`}
                                InputProps={{}}
                                name="phoneNumber"
                                type="text"
                                placeholder="Phone Number e.g. +447314123456"
                                disabled={disabled}
                                validate={[isRequired, isMobilePhoneNumber, hasMinLength8, hasMaxLength255]}
                            />
                        </Item>
                    )}
                </CenteringContainer>
            </RowItem>
            <RowItem className={'sms-verification-container'}>
                <Item lg={12} md={12} sm={12} xs={12}>
                    <Checkbox name="sms_verification" label="Would you like to verify by SMS?" defaultChecked={true} />
                </Item>
            </RowItem>
            <RowItem className={'sms-verification-container'}>
                <Item lg={12} md={12} sm={12} xs={12}>
                    <Checkbox
                        name="tcPpAgreement"
                        label={
                            <span>
                                I agree to the{' '}
                                <a
                                    href={'https://providefinance.com/borrowers/terms-of-business/'}
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    Terms and Conditions
                                </a>{' '}
                                and acknowledge the{' '}
                                <a
                                    href={'https://providefinance.com/privacy-policy'}
                                    target={'_blank'}
                                    rel="noreferrer"
                                >
                                    Provide Finance Privacy Policy
                                </a>
                            </span>
                        }
                        onChangeFunc={(event) => {
                            setTCAndPPAgreement(event.target.value !== '1');
                        }}
                        defaultChecked={tcAndPPAgreement}
                    />
                </Item>
            </RowItem>
        </div>
    );
};

const PrePopulatedBorrowerProductRegistrationForm = ({
    errors = [],
    errorsVisible,
    handleSubmit,
    submitted,
    firstFieldAutoFocus,
    productConfig,
    initialValues,
}) => {
    const checkIfDisabled = () => {
        console.log(initialValues);
        if (initialValues.hmpg !== '1') {
            return (
                submitted ||
                null === initialValues.given ||
                null === initialValues.family ||
                null === initialValues.email ||
                null === initialValues.phoneNumber ||
                (null === initialValues.tcAndPPAgreement && !tcAndPPAgreement)
            );
        }
        return submitted || !tcAndPPAgreement;
    };

    useEffect(() => {
        if (
            null !== initialValues.given &&
            null !== initialValues.family &&
            null !== initialValues.email &&
            null !== initialValues.phoneNumber &&
            null !== initialValues.tcAndPPAgreement
        ) {
            registerHubspot(
                initialValues.given,
                initialValues.family,
                initialValues.email,
                initialValues.phoneNumber,
                initialValues.loanAmount,
                initialValues.loanLength,
                initialValues.loanType,
                initialValues.customerSource,
                initialValues.agreedToMarketing,
                initialValues.page
            ).then((r) => {});
        }
    }, []);

    const [tcAndPPAgreement, setTCAndPPAgreement] = useState(initialValues.tcAndPPAgreement);

    return (
        <Item className={'content-container borrower-registration-form-item'}>
            <Container className={'registration-form borrower-registration-form-container'}>
                {errorsVisible &&
                    errors.map((error) => <FormMessage type={MESSAGE_ERROR} messages={error} key={error} />)}
                <CenteringContainer>
                    <form onSubmit={handleSubmit} className={'registration-form__form'}>
                        <FormFields
                            disabled={submitted}
                            firstFieldAutoFocus={firstFieldAutoFocus}
                            productConfig={productConfig}
                            initialValues={initialValues}
                            tcAndPPAgreement={tcAndPPAgreement}
                            setTCAndPPAgreement={setTCAndPPAgreement}
                            onChangeFunc={(event) => {
                                setTCAndPPAgreement(event.target.value !== '1');
                            }}
                            defaultChecked={tcAndPPAgreement}
                        />
                        <RowItem>
                            <h4 style={{ marginBottom: 0 }}>
                                Financing Is Available For UK-Based Projects & Businesses Only
                            </h4>
                        </RowItem>
                        <RowItem>
                            <CenteringContainer className={'button-panel'}>
                                <PrimaryButton
                                    type="submit"
                                    className={`button-submit ${checkIfDisabled() ? 'disabled' : ''}`}
                                    disabled={checkIfDisabled()}
                                    style={{ marginRight: 0 }}
                                >
                                    Create Account
                                </PrimaryButton>
                            </CenteringContainer>
                        </RowItem>
                    </form>
                </CenteringContainer>
            </Container>
        </Item>
    );
};

PrePopulatedBorrowerProductRegistrationForm.defaultProps = {
    firstFieldAutoFocus: true,
    errors: [],
};

PrePopulatedBorrowerProductRegistrationForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    errors: PropTypes.array,
    errorsVisible: PropTypes.bool.isRequired,
    submitted: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func.isRequired,
    stepHeading: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
    const href = window.location.href;
    const arr = new URL(href);
    const urlParams = new URLSearchParams(window.location.search).toString().replace('+', '%2B').split('&');
    let pa = {};
    urlParams.forEach((item) => {
        const is = item.split('=');
        pa[is[0]] = decodeURIComponent(is[1]);
    });

    const correctPhoneNumber = (number) => {
        let correctNumber = number;

        if (
            correctNumber.substring(0, 1) === '+' &&
            correctNumber.substring(0, 4) !== '+447' &&
            correctNumber.substring(0, 5) !== '+4407'
        ) {
            return correctNumber.slice(1, correctNumber.length);
        }

        if (correctNumber.substring(0, 4) !== '+447' && correctNumber.substring(0, 5) !== '+4407') {
            return '+44' + correctNumber;
        }

        return correctNumber;
    };

    const phoneNumber = Object.hasOwn(pa, 'p') ? correctPhoneNumber(pa['p']) : null;
    let email = false === isEmailValid(arr.searchParams.get('e')) ? null : pa['e'];

    return {
        initialValues: {
            given: !arr.searchParams.get('fn') || arr.searchParams.get('fn').trim().length < 2 ? null : pa['fn'],
            family: !arr.searchParams.get('ln') || arr.searchParams.get('ln').trim().length < 2 ? null : pa['ln'],
            email: !arr.searchParams.get('e') ? null : email,
            phoneNumber: !phoneNumber || phoneNumber.length < 8 ? null : phoneNumber,
            isPhoneNumberValid: phoneNumber ? isMobilePhoneNumberValid(phoneNumber) : false,
            dateOfBirth: null,
            hmpg: !arr.searchParams.get('hmpg') ? null : arr.searchParams.get('hmpg'),
            loanAmount: !arr.searchParams.get('la') ? null : arr.searchParams.get('la'),
            loanLength: !arr.searchParams.get('ll') ? null : arr.searchParams.get('ll'),
            loanType: !arr.searchParams.get('lt') ? null : arr.searchParams.get('lt'),
            customerSource: !arr.searchParams.get('cs') ? null : arr.searchParams.get('cs'),
            agreedToMarketing: !(!arr.searchParams.get('am') || arr.searchParams.get('am').length === 0),
            commsAgreement: !(!arr.searchParams.get('am') || arr.searchParams.get('am') === '0'),
            page: !arr.searchParams.get('pg') ? null : arr.searchParams.get('pg'),
            smsVerificationRequested: true,
            tcAndPPAgreement: !arr.searchParams.get('hmpg'), //!(!arr.searchParams.get('tc') || arr.searchParams.get('tc') === '0'),
        },
    };
};

export default compose(
    connect(mapStateToProps),
    reduxForm({
        form: 'registration',
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
    })
)(PrePopulatedBorrowerProductRegistrationForm);
