import React from 'react';
import { Provider } from 'react-redux';
import App from './App';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { setContext } from 'apollo-link-context';
import SessionManager from '../pitchblack_react_utils/session/SessionManager';
import { sagaMiddleware, configureStore, history } from '../bootstrap/store/configureStore.dev';
import 'typeface-roboto';
import sagas from '../bootstrap/sagas/index';
import { actionAuthorisationTokenFailed } from '../apps/authentication/redux/login';
import {
    API_URL,
    TAG_MANAGER_AUTH,
    TAG_MANAGER_ID,
    TAG_MANAGER_DATA_LAYER_NAME,
    TAG_MANAGER_PREVIEW,
    RECAPTCHA_KEY,
} from '../bootstrap/constants/constants';
import { sagaActionNotificationSetError } from '../apps/notifications/sagas/container';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { BRAND_MAIN_CONTACT } from '../bootstrap/constants/constants';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: TAG_MANAGER_ID,
    auth: TAG_MANAGER_AUTH,
    dataLayerName: TAG_MANAGER_DATA_LAYER_NAME,
    preview: TAG_MANAGER_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

const store = configureStore();

sagaMiddleware.run(sagas);

const link = ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
        if (networkError) {
            switch (networkError.statusCode) {
                case 500:
                    store.dispatch(
                        sagaActionNotificationSetError(
                            `There is a problem handling your request. Please try again or call us on ${BRAND_MAIN_CONTACT}`
                        )
                    );

                    break;
                case 401:
                case 403:
                    store.dispatch(actionAuthorisationTokenFailed(networkError));
                    break;
                default:
                    break;
            }
        }
    }),
    new HttpLink({
        uri: API_URL,
    }),
]);

const authLink = setContext((_, { headers }) => {
    let sessionManager = new SessionManager();

    const token = sessionManager.getToken();

    if (sessionManager.isAuthenticated() && sessionManager.hasAssumedToken()) {
        const assumedIdentityToken = sessionManager.getAssumedToken();

        headers = {
            ...headers,
            'X-Authorization-Assumed-Identity': `Bearer ${assumedIdentityToken.token}`,
        };
    }

    return {
        headers: {
            ...headers,
            Authorization: sessionManager.isAuthenticated() ? `Bearer ${token.token}` : null,
        },
    };
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
        pollInterval: 60000 * 15,
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

const cache = new InMemoryCache();

const client = new ApolloClient({
    link: authLink.concat(link),
    cache: cache,
    query: {
        notifyOnNetworkStatusChange: true,
    },
    defaultOptions: defaultOptions,
});

const Root = () => (
    <Provider store={store}>
        <ApolloProvider client={client}>
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <App history={history} />
                </MuiPickersUtilsProvider>
            </GoogleReCaptchaProvider>
        </ApolloProvider>
    </Provider>
);

Root.propTypes = {};

export default Root;
