import React from 'react';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { compose } from 'recompose';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { API_URL } from '../../../../../bootstrap/constants/constants';
import {
    sagaActionNotificationSetError,
    sagaActionNotificationSetSuccess,
} from '../../../../notifications/sagas/container';
import { STATUS_ACTIVE } from '../../enum/Lenders/StatusEnum';
import { PrimaryButton } from 'pitch4_layout/components/buttons';
import styled from 'styled-components';

const StyledPrimaryButton = styled(PrimaryButton)`
    min-width: 80px !important;
`;

class SetStatusButton extends React.Component {
    render() {
        let { useLabel, disabled, inputRef = () => null, children } = this.props;

        return (
            <StyledPrimaryButton inputRef={inputRef} disabled={disabled} onClick={this.onClick}>
                {useLabel}
                {children}
            </StyledPrimaryButton>
        );
    }

    onClick = () => {
        let { outerRefetch, mutate, selectedItems, useStatus, notificationSuccess, notificationError } = this.props;

        return mutate({
            variables: {
                input: {
                    uuids: selectedItems,
                    newStatus: useStatus,
                },
            },
        })
            .then(({ data }) => {
                const msg = useStatus === STATUS_ACTIVE ? 'Criteria enabled' : 'Criteria disabled';
                notificationSuccess(msg);
                outerRefetch();
            })
            .catch((error) => {
                notificationError('An error occurred. Please contact support.');
                outerRefetch();
            });
    };
}

SetStatusButton.propTypes = {
    selectedItems: PropTypes.arrayOf(PropTypes.string.isRequired),
    mutate: PropTypes.func.isRequired,
    outerRefetch: PropTypes.func.isRequired,
    useStatus: PropTypes.string.isRequired,
    useLabel: PropTypes.string,
    children: PropTypes.any,
    inputRef: PropTypes.any,
};

const SetStatusesGql = gql`
    mutation setLenderStatuses($input: pitch4admin_setLenderStatusesInput!) {
        setLenderStatuses(input: $input) {
            lenders {
                id
                status
            }
        }
    }
`;

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        redirect: (url) => dispatch(push(url)),
        notificationSuccess: (message) => {
            dispatch(sagaActionNotificationSetSuccess(message));
        },
        notificationError: (message) => {
            dispatch(sagaActionNotificationSetError(message));
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    graphql(SetStatusesGql, {
        options: {
            context: {
                uri: API_URL,
            },
        },
    })
)(SetStatusButton);
