import BaseEnum from 'pitchblack_react_utils/enums/BaseEnum';

export const LIMITED_COMPANY = 'limited_company';
export const TRADING_LIMITED_COMPANY = 'trading_limited_company';
export const OFFSHORE_LIMITED = 'offshore_limited';
export const PERSONAL_NAME = 'personal_name';
export const TRUST = 'trust';
export const LLP = 'llp';
export const OVERSEAS_COMPANY = 'overseas_company';
export const UK_LIMITED = 'uk_limited';

class PropertyOwnershipEnum extends BaseEnum {
    getTypes = () => {
        return {
            [LIMITED_COMPANY]: 'Limited Company (SPV)',
            [TRADING_LIMITED_COMPANY]: 'Trading Limited Company',
            [OFFSHORE_LIMITED]: 'Offshore Limited Company',
            [PERSONAL_NAME]: 'Personal name',
            [TRUST]: 'Trust',
            [LLP]: 'LLP',
            [OVERSEAS_COMPANY]: 'Overseas Company',
            [UK_LIMITED]: 'UK Limited Company',
        };
    };
}

export default PropertyOwnershipEnum;
