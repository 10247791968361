import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { ModalLayoutMuiName } from './ModalLayout';

const defaultPaperProps = {
    elevation: 4,
    square: true,
};

class Modal extends React.Component {
    render() {
        let { modalShow, handleClose, render, modalInteractive, paperProps, className } = this.props;

        return (
            <Dialog
                className={`modal ` + className}
                aria-labelledby="simple-dialog-title"
                aria-describedby="simple-dialog-description"
                PaperProps={{ ...defaultPaperProps, ...paperProps }}
                open={modalShow}
                onClose={(event, reason) => {
                    if (reason === 'backdropClick' && reason === 'escapeKeyDown') {
                        if (modalInteractive !== false) {
                            handleClose();
                        }
                    }
                }}
            >
                {modalInteractive && (
                    <IconButton className={`close-button`} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                )}

                {this.renderComponent(render())}
            </Dialog>
        );
    }

    renderComponent = (component) => {
        if (component.type && component.type.name && component.type.name === ModalLayoutMuiName) {
            return component;
        }
        return <DialogContent className={`modal-content`}>{component}</DialogContent>;
    };
}

Modal.propTypes = {
    modalShow: PropTypes.bool.isRequired,
    handleClose: PropTypes.func,
    render: PropTypes.func.isRequired,
    modalInteractive: PropTypes.bool.isRequired,
};

export default Modal;
