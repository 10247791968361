import React from 'react';
import TermsAndFeesEnum from '../../pitch4_enum/enum/TermsAndFeesEnum';
import { LOAN_TYPE_BUSINESS_FINANCE } from '../../pitch4_enum/enum/LoanTypeEnum';
import { getStoredDataValueFromArray } from '../../apps/view_loan_enquiry/HelperFunctions';
import { LOAN_TYPE } from '../../pitch4_enum/enum/FactEnum';
import { Grid } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import _ from 'lodash';
import { getValue, getTermName } from './Utils';

export default function FeeTerms(props) {
    const { matchSelected, loanEnquiry } = props;
    const termsEnum = new TermsAndFeesEnum();
    const product = getStoredDataValueFromArray(loanEnquiry.storedData, LOAN_TYPE);
    const getDefaultProduct = () => (product === LOAN_TYPE_BUSINESS_FINANCE ? 'business-finance' : 'term-loan');
    const getDefaultTerms = () => termsEnum.getType(getDefaultProduct(product)).terms;
    const terms = !matchSelected.lenderTerms ? getDefaultTerms() : JSON.parse(matchSelected.lenderTerms).terms;
    const feeTerms = terms.filter((item) => item.termType === 'fees');

    const totalTermsCount = feeTerms.length;
    const a = Math.round(totalTermsCount / 2); //Get number items in the first group; 2 represents number of groups.
    const groups = _.chunk(feeTerms, a);

    return (
        <>
            <Grid container className={'fee-term-container'}>
                <Grid className={'header'} item lg={12} md={12} sm={12} xs={12}>
                    Fees
                </Grid>
                {groups.map((group, index) => (
                    <Grid className={'group-container'} item lg={6} md={6} sm={12} xs={12} key={index}>
                        {group.map((term, termIndex) => {
                            return (
                                <div className={'item'} key={termIndex}>
                                    <FiberManualRecordIcon className={'icon'} />
                                    {getTermName(term)}:{' '}
                                    <span className={`value ${term.value === null ? 'pending' : ''}`}>
                                        {getValue(term)}
                                    </span>
                                </div>
                            );
                        })}
                    </Grid>
                ))}
            </Grid>
        </>
    );
}
