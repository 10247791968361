import React from 'react';
import { Switch as MuiSwitch } from '@material-ui/core';

const WrappedSwitch = (props) => (
    <span className={`switch`}>
        <MuiSwitch {...props} />
    </span>
);

WrappedSwitch.muiName = MuiSwitch.muiName;

class Switch extends React.Component {
    render() {
        return <WrappedSwitch {...this.props} />;
    }
}

export default Switch;
