import React from 'react';
import PropTypes from 'prop-types';

class TableRow extends React.Component {
    render() {
        let { children, elkey = '' } = this.props;

        return <React.Fragment key={'tr_' + elkey}>{children}</React.Fragment>;
    }
}

TableRow.propTypes = {
    children: PropTypes.any.isRequired,
};

export default TableRow;
