import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import CountryNameEnum, { COUNTRY_OTHER } from '../../../../../pitch4_enum/enum/CountryNameEnum';
import NationalityEnum, { NATIONALITY_OTHER } from '../../../../../pitch4_enum/enum/NationalityEnum';
import BorrowerQuestions, {
    QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS,
    QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE,
    QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES,
    QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION,
    QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME,
    QUESTION_APPLICANT_MAIN_NATIONALITY,
    QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER,
    QUESTION_APPLICANT_MAIN_OCCUPATION,
    QUESTION_EXIT_STRATEGY,
    QUESTION_FUNDING_TYPE,
    QUESTION_LOAN_TYPE,
    QUESTION_PROPERTY_TYPE,
} from '../../../enums/Borrower/BorrowerQuestions';
import Question from '../../Question/containers/Question';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';
import YesNo, { YES } from '../../../enums/Shared/YesNo';
import CitizenshipTypes, {
    BRITISH_EXPAT,
    FOREIGN_NAT_IN_UK_PERMANENT,
    FOREIGN_NAT_IN_UK_VISA,
    UK_CITIZEN,
} from '../../../enums/Shared/CitizenshipTypes';
import { BUSINESS_TYPE, PORTFOLIO_TYPE, SECOND_CHARGE_TYPE, TERM_TYPE } from '../../../enums/Borrower/BorrowerLoanType';
import { SEMI_COMMERCIAL_FUNDING_TYPE } from '../../../enums/Shared/CommercialFundingType';

class CreditAndCitizenshipContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        // Setup
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        // Enums
        const yesNoOptions = new YesNo().getTypes();
        const countryOptions = new CountryNameEnum().getOptions();
        const nationalityOptions = new NationalityEnum().getOptions();
        const citizenshipTypes = new CitizenshipTypes().getTypes();
        // These next line is really just for readability, 'cause god-damn does Javascript like deep nesting
        const mainApplicant = this.state.formData.applicants.main;
        const mainDifficultiesLabel =
            'Has ' + mainApplicant.given + ' ' + mainApplicant.family + ' had any credit difficulties?';
        const mainCitizenshipStatusLabel =
            'What is ' + mainApplicant.given + ' ' + mainApplicant.family + "'s citizenship status?";
        const mainCountryOfResidenceLabel =
            'What country does ' + mainApplicant.given + ' ' + mainApplicant.family + ' reside in?';
        const nationalityLabel = 'What is ' + mainApplicant.given + ' ' + mainApplicant.family + "'s nationality?";
        const occupationLabel = 'What is ' + mainApplicant.given + ' ' + mainApplicant.family + "'s occupation?";
        const grossIncomeLabel =
            'What is ' + mainApplicant.given + ' ' + mainApplicant.family + "'s annual gross income?";
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER ? 'About you' : 'About your clients'}
                    </Typography>
                </Grid>
                <Question
                    type={questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES].type}
                    label={mainDifficultiesLabel}
                    questionEnum={'QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES'}
                    fieldName={questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES].fieldName}
                    placeholder={questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES].placeholder}
                    validation={questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={yesNoOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
                {this.state.formData[questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES].fieldName] === YES && (
                    <>
                        <Question
                            type={questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION].type}
                            label={questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION].label}
                            questionEnum={'QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION'}
                            fieldName={questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION].fieldName}
                            placeholder={questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION].placeholder}
                            validation={
                                questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION].fieldName + '_error'
                            }
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={questions[QUESTION_APPLICANT_MAIN_CREDIT_DIFFICULTIES_EXPLANATION].rows}
                        />
                    </>
                )}
                <Question
                    type={questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].type}
                    label={mainCitizenshipStatusLabel}
                    questionEnum={'QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS'}
                    fieldName={questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName}
                    placeholder={questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].placeholder}
                    validation={questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={citizenshipTypes}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].rows}
                />
                {this.state.formData[questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName] !==
                    FOREIGN_NAT_IN_UK_PERMANENT &&
                    this.state.formData[questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName] !==
                        FOREIGN_NAT_IN_UK_VISA && (
                        <>
                            <Question
                                type={questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE].type}
                                label={mainCountryOfResidenceLabel}
                                questionEnum={'QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE'}
                                fieldName={questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE].fieldName}
                                placeholder={questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE].placeholder}
                                validation={
                                    questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE].fieldName + '_error'
                                }
                                onChangeFunction={this.props.onChangeHandler}
                                fieldOptions={countryOptions}
                                formData={this.state.formData}
                                formValidation={this.state.validation}
                                rows={questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE].rows}
                            />
                            {this.state.formData[questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE].fieldName] ===
                                COUNTRY_OTHER && (
                                <Question
                                    type={questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER].type}
                                    label={questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER].label}
                                    questionEnum={'QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER'}
                                    fieldName={questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER].fieldName}
                                    placeholder={
                                        questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER].placeholder
                                    }
                                    validation={
                                        questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER].fieldName +
                                        '_error'
                                    }
                                    onChangeFunction={this.props.onChangeHandler}
                                    fieldOptions={[]}
                                    formData={this.state.formData}
                                    formValidation={this.state.validation}
                                    rows={questions[QUESTION_APPLICANT_MAIN_COUNTRY_OF_RESIDENCE_OTHER].rows}
                                />
                            )}
                        </>
                    )}
                {this.state.formData[questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName] !== UK_CITIZEN &&
                    this.state.formData[questions[QUESTION_APPLICANT_MAIN_CITIZENSHIP_STATUS].fieldName] !==
                        BRITISH_EXPAT && (
                        <>
                            <Question
                                type={questions[QUESTION_APPLICANT_MAIN_NATIONALITY].type}
                                label={nationalityLabel}
                                questionEnum={'QUESTION_APPLICANT_MAIN_NATIONALITY'}
                                fieldName={questions[QUESTION_APPLICANT_MAIN_NATIONALITY].fieldName}
                                placeholder={questions[QUESTION_APPLICANT_MAIN_NATIONALITY].placeholder}
                                validation={questions[QUESTION_APPLICANT_MAIN_NATIONALITY].fieldName + '_error'}
                                onChangeFunction={this.props.onChangeHandler}
                                fieldOptions={nationalityOptions}
                                formData={this.state.formData}
                                formValidation={this.state.validation}
                                rows={questions[QUESTION_APPLICANT_MAIN_NATIONALITY].rows}
                            />
                            {this.state.formData[questions[QUESTION_APPLICANT_MAIN_NATIONALITY].fieldName] ===
                                NATIONALITY_OTHER && (
                                <Question
                                    type={questions[QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER].type}
                                    label={questions[QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER].label}
                                    questionEnum={'QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER'}
                                    fieldName={questions[QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER].fieldName}
                                    placeholder={questions[QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER].placeholder}
                                    validation={
                                        questions[QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER].fieldName + '_error'
                                    }
                                    onChangeFunction={this.props.onChangeHandler}
                                    fieldOptions={[]}
                                    formData={this.state.formData}
                                    formValidation={this.state.validation}
                                    rows={questions[QUESTION_APPLICANT_MAIN_NATIONALITY_OTHER].rows}
                                />
                            )}
                        </>
                    )}
                <Question
                    type={questions[QUESTION_APPLICANT_MAIN_OCCUPATION].type}
                    label={occupationLabel}
                    questionEnum={'QUESTION_APPLICANT_MAIN_OCCUPATION'}
                    fieldName={questions[QUESTION_APPLICANT_MAIN_OCCUPATION].fieldName}
                    placeholder={questions[QUESTION_APPLICANT_MAIN_OCCUPATION].placeholder}
                    validation={questions[QUESTION_APPLICANT_MAIN_OCCUPATION].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={questions[QUESTION_APPLICANT_MAIN_OCCUPATION].rows}
                />
                <Question
                    type={questions[QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME].type}
                    label={grossIncomeLabel}
                    questionEnum={'QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME'}
                    fieldName={questions[QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME].fieldName}
                    placeholder={questions[QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME].placeholder}
                    validation={questions[QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={[]}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={questions[QUESTION_APPLICANT_MAIN_GROSS_ANNUAL_INCOME].rows}
                />

                {this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== TERM_TYPE &&
                    this.state.formData[questions[QUESTION_LOAN_TYPE].fieldName] !== BUSINESS_TYPE &&
                    this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== SECOND_CHARGE_TYPE &&
                    this.state.formData[questions[QUESTION_PROPERTY_TYPE].fieldName] !==
                        SEMI_COMMERCIAL_FUNDING_TYPE && (
                        <Question
                            type={questions[QUESTION_EXIT_STRATEGY].type}
                            label={questions[QUESTION_EXIT_STRATEGY].label}
                            questionEnum={'QUESTION_EXIT_STRATEGY'}
                            fieldName={questions[QUESTION_EXIT_STRATEGY].fieldName}
                            placeholder={questions[QUESTION_EXIT_STRATEGY].placeholder}
                            validation={questions[QUESTION_EXIT_STRATEGY].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={questions[QUESTION_EXIT_STRATEGY].rows}
                        />
                    )}
                <Grid item xs={12} md={12}></Grid>
            </Grid>
        );
    }
}

export default CreditAndCitizenshipContainer;
