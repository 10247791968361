import React from 'react';
import { scrollableArea, scrollToWhen } from 'react-redux-scroll';
import Message from './Message';
import PropTypes from 'prop-types';
import { Container, RowItem } from '../../pitchblack_react_utils/layout/index';
import DateTimeFactory from '../../pitchblack_react_utils/datetime/DateTimeFactory';
import Tooltip from '@material-ui/core/Tooltip';
import ChatForm from './ChatForm';
import styled from 'styled-components';
import SessionManager from '../../pitchblack_react_utils/session/SessionManager';
import { TYPE_TEXT } from '../../pitch4_enum/enum/MessageTypeEnum';
import ChatPlaceholder from '../../pitch4_layout/components/placeholders/ChatPlaceholder';

class BottomOfMessages extends React.Component {
    render() {
        return <RowItem id={`bottomOfMessages`} />;
    }
}

const StyledMessageList = styled.div``;
const ScrollableAreaMessageList = scrollableArea(StyledMessageList);

const BottomOfMessagesScrollAnchor = scrollToWhen(() => true, null, { duration: 500, yAlignment: 'BOTTOM' }, ['id'])(
    BottomOfMessages
);

let sManager = new SessionManager();

const getIsSender = (message) => {
    if (!message) {
        return false;
    }

    return message.getSenderId() === sManager.getSession().getId();
};

const getSenderName = (isSender, message) => {
    if (isSender) {
        return 'You';
    }
    return message.getUserProfile()['given'];
};

const getUserRoleClassName = (isSender, message) => {
    if (isSender) {
        return '';
    }

    switch (message.getUserRole()) {
        case 'ROLE_BORROWER':
            return 'role-borrower';

        case 'ROLE_INTERMEDIARY':
            return 'role-intermediary';

        case 'ROLE_SLAM':
            return 'role-slam';

        case 'ROLE_SUPER_ADMIN':
            return 'role-admin';

        default:
            throw new Error('Unknown userRole' + message.getUserRole());
    }
};

const dateTimeFactory = new DateTimeFactory();

const renderMessages = (messages = []) => {
    return messages.map((message, index) => {
        const isSender = getIsSender(message);
        const roleClassName = getUserRoleClassName(isSender, message);
        const toolTipStatus =
            getSenderName(isSender, message) + ' @ ' + dateTimeFactory.create(message.getSentAt()).toLocaleString();

        switch (message.getType()) {
            case TYPE_TEXT:
                message = <Message message={message} isSender={isSender} />;
                break;

            default:
                return null;
        }

        const isSenderClass = isSender ? 'is-sender' : '';

        return (
            <RowItem className={`message-row ${isSenderClass} ${roleClassName}`} key={index} xs={12} sm={10} md={11}>
                <Tooltip title={toolTipStatus} enterDelay={300} placement="top">
                    {message}
                </Tooltip>
            </RowItem>
        );
    });
};

class MessageList extends React.Component {
    render() {
        let {
            messages,
            channelId,
            sendMessageMutate,
            disableForm,
            chatPlaceholderText,
            loanEnquiryId = null,
            session,
        } = this.props;

        return (
            <Container className={`message-list`}>
                <ScrollableAreaMessageList className={'messages'}>
                    {messages.length > 0 && (
                        <div className={'message-row start-chat'}>... This is the start of the communications ...</div>
                    )}

                    {this.renderMessagesContent(messages, chatPlaceholderText, disableForm)}
                    <BottomOfMessagesScrollAnchor />
                </ScrollableAreaMessageList>

                <Container>
                    <RowItem>
                        <ChatForm
                            buttonLabel={messages.length ? 'Send' : 'Start Chat'}
                            channelId={channelId}
                            sendMessageMutate={sendMessageMutate}
                            disabled={disableForm}
                            loanEnquiryId={loanEnquiryId}
                            session={session}
                        />
                    </RowItem>
                </Container>
            </Container>
        );
    }

    renderMessagesContent = (messages, chatPlaceholderText, disableForm) => {
        if (messages.length > 0) {
            return renderMessages(messages);
        }
        return <ChatPlaceholder disabled={disableForm}>{chatPlaceholderText}</ChatPlaceholder>;
    };
}

MessageList.defaultProps = {
    disableForm: undefined,
};

MessageList.propTypes = {
    messages: PropTypes.array.isRequired,
    sendMessageMutate: PropTypes.func.isRequired,
    disableForm: PropTypes.bool,
    chatPlaceholderText: PropTypes.any,
};

export default MessageList;
