import _ from 'lodash';

import { SECTION_SUBMIT } from '../constants';

export function getStepsMap(config, sectionKeys) {
    const stepsMap = {};

    sectionKeys.forEach(function (sectionName) {
        config['sections'][sectionName]['categories'].forEach(function (category) {
            if (_.get(stepsMap, [category]) === undefined) {
                stepsMap[category] = [sectionName];
            } else {
                stepsMap[category].push(sectionName);
            }
        });
    });

    Object.keys(stepsMap).forEach(function (category) {
        stepsMap[category].push(SECTION_SUBMIT);
    });

    return stepsMap;
}

export function getDeterminedSections(config, initialSection) {
    const determinedSections = [];

    let nextSection = config['sections'][initialSection['name']];
    let nextSectionKeys = Object.keys(nextSection['next_section']);
    determinedSections.push(config['sections'][initialSection['name']]);

    do {
        if (nextSectionKeys.length > 1) {
            nextSection = null;
        } else {
            nextSection = config['sections'][nextSectionKeys[0]] || null;

            if (nextSection === null) {
                continue;
            }

            nextSectionKeys = _.keys(nextSection['next_section']);
            determinedSections.push(nextSection);
        }
    } while (nextSection !== null);

    return determinedSections;
}

export function getFormHeadings(config, sectionKeys) {
    const formHeadings = {};

    sectionKeys.forEach(function (sectionName) {
        formHeadings[sectionName] = config['sections'][sectionName]['frontend']['label'];
    });

    formHeadings[SECTION_SUBMIT] = 'Review & Submit';

    return formHeadings;
}

export function getInitialSection(config, sectionKeys) {
    let initialSection = {};

    for (let section of sectionKeys) {
        if (config['sections'][section]['initial'] === true) {
            initialSection = config['sections'][section];
            return initialSection;
        }
    }

    throw Error('No initial section found in form wizard config');
}
