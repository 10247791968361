import createP4ApolloFetch from 'bootstrap/middlewares';

const getDebugStreamsQuery = `
    query getMatchDebugStreams ($lenderId: ID!) {
        getMatchDebugStreams(lenderId: $lenderId) {
            id
            name
            status
            type
        }
    }
`;

export const getDebugStreams = (uri, lenderId) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    return apolloFetch({
        query: getDebugStreamsQuery,
        variables: {
            lenderId: lenderId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                throw errors;
            }

            return data;
        })
        .catch((error) => {
            if (error) {
                throw error;
            }

            return error;
        });
};
