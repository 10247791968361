import React from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { goBack, push } from 'react-router-redux';
import _ from 'lodash';
import { Container, Item, P4StyleComponentContainer } from '../../../../pitch4_layout/components/div';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { Body1, Subtitle1 } from '../../../../pitch4_layout/components/text';
import { ScreenTitle } from '../../../../pitch4_layout';
import { DateFormatter, FactValue } from '../../../../pitch4_layout/components/data';
import { KeyValueTable, KeyValueTableRow } from '../../../../pitch4_tabular/components/kvtable';
import {
    STATUS_COMPLETION_REQUESTED,
    STATUS_WITHDRAWN_EDITED,
    STATUS_ON_HOLD,
    STATUS_LEGALS,
    STATUS_DRAFT,
} from '../../enum/LoanEnquiries/StatusEnum';
import { STATUS_COMPLETE, STATUS_COMPLETION_REJECTED } from '../../enum/LenderLoanEnquiries/StatusEnum';
import { ROLE_BORROWER, ROLE_INTERMEDIARY } from '../../../authentication/enum/Roles/NameEnum';
import { ROLE_BORROWER as BORROWER } from '../../../../pitch4_enum/enum/RoleNameEnum';
import { Loading } from '../../../../pitch4_layout/components/waiting';
import FactSummaryTable from '../../../view_loan_enquiry/components/FactSummaryTable';
import LeadsContainer from './LeadsContainer';
import { getMainApplicantName, getStoredDataValueFromArray } from '../../../view_loan_enquiry/HelperFunctions';
import { LOAN_AMOUNT } from '../../enum/LoanEnquiries/MetaKeyEnum';
import { ExpandedDateTimeLayout } from '../../../../pitch4_layout/components/content';
import Notice, { NOTICE_TYPE_ERROR } from '../../../../pitch4_layout/components/content/Notice';
import LoanEnquiryStatusWidget from '../components/LoanEnquiryStatusWidget';
import BorStatusBannerNotice from '../../../bannernotice/containers/BorStatusBannerNotice';
import {
    actionApiSetLeadStatus,
    actionApiSetStatus,
    actionCleanup,
    actionClearStatus,
    actionInitiate,
    actionReloadLoanEnquiry,
    selectorGetLoanEnquiry,
    selectorGetLoanEnquiryMatch,
    selectorGetMatchingCriteriaConfig,
    selectorGetStatus,
    selectorGetSubmitted,
    actionRemoveLoanEnquiryAlert,
    actionSendMessageToLenders,
} from '../redux';
import { sagaActionModalHide, sagaActionModalInteractive, sagaActionModalShow } from '../../../modal/sagas/container';
import CompletionRequestedRespondForm from '../components/CompletionRequestedForm';
import {
    path,
    ROUTE_BORROWER_EDIT_LOAN_ENQUIRY,
    ROUTE_BORROWER_LENDER_LOAN_ENQUIRY_VIEW,
    ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY,
} from '../../../../pitch4_routing';
import CompletionRejectedForm from '../components/CompletionRejectedForm';
import RevisionHistoryContainer from '../../../../pitch4_layout/components/le_revision_history';
import AdminStatusBannerNotice from '../../../bannernotice/containers/AdminStatusBannerNotice';
import LenderMessageDrawer from '../../../../pitch4_layout/components/lender_message_drawer/LenderMessageDrawer';
import withSession from '../../../../pitchblack_react_utils/session/withSession';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { BORROWER_URL, BRAND_NAME } from '../../../../bootstrap/constants/constants';
import { BORROWER_LOAN_ENQUIRY } from '../../../joyride/config';
import { actionSetJoyrideType } from '../../../joyride/redux';
import LenderDrawer from '../components/LenderDrawer';
import AdminCaseManagementDrawer from '../components/AdminCaseManagementDrawer';
import DocumentSentModal from '../../../../pitch4_layout/components/lender_message_drawer/DocumentSentModal';
import { clearChannelAlert } from '../../../../pitch4_chat/api/channel';
import { clearDocumentsAlert } from '../../../../pitch4_documents/api/alerts';
import { getLenderLoanEnquiry } from '../api/get_ll_enquiry';
import ContractSigned from '../../../legal/ContractSigned';
import ContractTermsPopup from '../../../legal/ContractTermsPopup';
import ContractProgressPopup from '../../../legal/ContractProgressPopup';
import { signLegalContract } from '../../../legal/api/sign_legal_contract';
import { getEnquiry } from '../api';
import { handleDownload } from '../../../../pitch4_documents/src/components/DownloadButton';
import { getDocument } from '../../../../pitch4_documents/api/get_document';
import ContractDownloadPopup from '../../../legal/ContractDownoadFailedPopup';
import CompleteAlert from '../../../../pitch4_elements/le-completed/CompleteAlert';
import CompleteModal from '../../../../pitch4_elements/le-completed/CompleteModal';
import { BRAND_MAIN_CONTACT } from '../../../../bootstrap/constants/constants';
import { NewEnquiryContent } from '../components/NewEnquiryContent';
import Konami from 'react-konami-code';
import SessionManager from '../../../../pitchblack_react_utils/session/SessionManager';

const SendMessageMutation = gql`
    mutation SendTextMessage($input: borrower_liveChatSendMessageInput!) {
        liveChatSendMessage(input: $input) {
            message {
                id
                senderId
                type
                category
                userProfile {
                    given
                }
                userRole
                content
                sentAt
            }
        }
    }
`;

class MasonryStyleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDebug: false,
            lenderProfileDrawer: {
                open: false,
                anchor: 'right',
                lender: null,
                lenderLoanEnquiry: null,
                handleFavouriteMatchChange: null,
                handleRemoveAlertMatchAlert: null,
            },
            adminCaseManagementDrawer: {
                open: false,
                anchor: 'right',
                lender: null,
                loanEnquiry: null,
                chatAlertActive: false,
                acmAlertUpdated: false,
                acmDocumentsAlertActive: false,
            },
            lenderMessageDrawer: {
                open: false,
                anchor: 'right',
                documentSent: false,
            },
            legal: {
                contractUpdated: false,
                contractSigned: true,
                contractInProgress: false,
                contractPreviouslyContactedLenders: '',
                contract: null,
            },
            selectedLenders: null,
            lendersMessage: null,
            messageSent: false,
            tabValue: 0,
            leAlertRemoved: false,
        };
    }

    componentDidMount() {
        const {
            match: {
                params: { loanEnquiryId },
            },
            initiate,
            setJoyrideType,
        } = this.props;

        initiate(loanEnquiryId);
        setJoyrideType(BORROWER_LOAN_ENQUIRY);
        this.setState({ loanEnquiryId: loanEnquiryId });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { acmAlertUpdated } = this.state.adminCaseManagementDrawer;
        const { contractUpdated } = this.state.legal;
        const { leAlertRemoved } = this.state;
        const { loanEnquiry, removeLoanEnquiryAlert } = this.props;

        if (loanEnquiry) {
            if (!acmAlertUpdated && loanEnquiry.channel) {
                this.setState({
                    adminCaseManagementDrawer: {
                        ...this.state.adminCaseManagementDrawer,
                        chatAlertActive: loanEnquiry.channel.alertActive,
                        acmAlertUpdated: true,
                    },
                });
            }

            if (!contractUpdated) {
                this.setState({
                    legal: {
                        ...this.state.legal,
                        contractUpdated: true,
                        contractSigned: loanEnquiry.contractSigned || false,
                        contractInProgress: loanEnquiry.contractCreationProgress,
                        contract: loanEnquiry.contract || null,
                    },
                });
            }

            if (false === leAlertRemoved && loanEnquiry.alertActive) {
                this.setState({ leAlertRemoved: true }, () => removeLoanEnquiryAlert(loanEnquiry.id));
            }
        }
    }

    componentWillUnmount() {
        const { cleanup, clearStatus } = this.props;
        cleanup();
        clearStatus();
    }

    toggleMessageDrawer = () => {
        if (!this.state.lenderMessageDrawer.open) {
            return this.setState({
                lenderMessageDrawer: {
                    ...this.state.lenderMessageDrawer,
                    open: true,
                },
                adminCaseManagementDrawer: {
                    ...this.state.adminCaseManagementDrawer,
                    open: false,
                },
                lenderProfileDrawer: {
                    ...this.state.lenderProfileDrawer,
                    open: false,
                    lender: null,
                },
            });
        }

        return this.setState({
            lenderMessageDrawer: {
                ...this.state.lenderMessageDrawer,
                open: false,
            },
            adminCaseManagementDrawer: {
                ...this.state.adminCaseManagementDrawer,
                open: false,
            },
            lendersMessage: null,
            messageSent: false,
        });
    };

    toggleLenderDrawer = (
        open = false,
        lenderLoanEnquiry = null,
        handleFavouriteMatchChange = null,
        // handleRemoveAlertMatchAlert method is executed in src/apps/borrower/view_loan_enquiry/containers/LeadsContainer.js
        handleRemoveAlertMatchAlert = null,
        updateLLEStatus = null
    ) => (event) => {
        this.setState({
            lenderMessageDrawer: {
                ...this.state.lenderMessageDrawer,
                open: false,
            },
            adminCaseManagementDrawer: {
                ...this.state.adminCaseManagementDrawer,
                open: false,
            },
            lenderProfileDrawer: {
                ...this.state.lenderProfileDrawer,
                open: open,
                lenderLoanEnquiry: lenderLoanEnquiry,
                handleFavouriteMatchChange: handleFavouriteMatchChange,
                handleRemoveAlertMatchAlert: handleRemoveAlertMatchAlert,
                updateLLEStatus: updateLLEStatus,
            },
        });

        if (null !== lenderLoanEnquiry) {
            getLenderLoanEnquiry(lenderLoanEnquiry.id).then((response) => {
                const data = response.lenderLoanEnquiry;
                this.setState({
                    lenderProfileDrawer: {
                        ...this.state.lenderProfileDrawer,
                        handleFavouriteMatchChange: handleFavouriteMatchChange,
                        handleRemoveAlertMatchAlert: handleRemoveAlertMatchAlert,
                        updateLLEStatus: updateLLEStatus,
                        lenderLoanEnquiry: { ...this.state.lenderProfileDrawer.lenderLoanEnquiry, ...data },
                    },
                });
            });
        }
    };

    toggleAdminCaseManagementDrawer = (open = false, loanEnquiry = null) => (event) => {
        this.setState({
            lenderMessageDrawer: {
                ...this.state.lenderMessageDrawer,
                open: false,
            },
            lenderProfileDrawer: {
                ...this.state.lenderProfileDrawer,
                open: false,
            },
            adminCaseManagementDrawer: {
                ...this.state.adminCaseManagementDrawer,
                open: open,
                loanEnquiry: loanEnquiry,
            },
        });
    };

    setLendersMessage = (lendersMessage) => this.setState({ ...lendersMessage, lendersMessage });

    getLendersMessage = () => this.state.lendersMessage;

    setMessageSent = (isMessageSent) => this.setState({ messageSent: isMessageSent });

    getMessageSent = () => this.state.messageSent;

    handleLenderSelection = (selectedLenders) => {
        this.setState({
            selectedLenders: selectedLenders,
        });
    };

    handleSubmitCompletionConfirm = (leadId) => () => {
        const { updateLeadStatusOnChange } = this.props;
        updateLeadStatusOnChange(leadId, STATUS_COMPLETE, null, true);
    };

    handleSubmitCompletionReject = (leadId) => ({ rejectReason }) => {
        const { updateLeadStatusOnChange } = this.props;
        updateLeadStatusOnChange(leadId, STATUS_COMPLETION_REJECTED, rejectReason, true);
    };

    handleCompletionRequestedRespond = ({ lenderLoanEnquiryId, lenderName }, loanEnquiryId) => {
        const { setModalComponent, setModalClose, submitted } = this.props;
        if (null !== lenderName && null !== lenderLoanEnquiryId && !submitted) {
            setModalComponent(
                <CompletionRequestedRespondForm
                    lenderName={lenderName}
                    lenderLink={path(ROUTE_BORROWER_LENDER_LOAN_ENQUIRY_VIEW, {
                        loanEnquiryId: loanEnquiryId,
                        lenderLoanEnquiryId: lenderLoanEnquiryId,
                    })}
                    onSubmit={() => {}}
                    handleClose={setModalClose}
                    handleConfirm={this.handleSubmitCompletionConfirm(lenderLoanEnquiryId)}
                    handleReject={() => this.handleCompletionRejectedForm(lenderLoanEnquiryId)}
                />,
                true
            );
        }
    };

    handleCompletionRejectedForm = (lenderLoanEnquiryId) => {
        const { setModalComponent, setModalClose } = this.props;
        if (null !== lenderLoanEnquiryId) {
            setModalComponent(
                <CompletionRejectedForm
                    handleClose={setModalClose}
                    onSubmit={this.handleSubmitCompletionReject(lenderLoanEnquiryId)}
                />,
                true
            );
        }
    };

    handleTabSelect = (selectedTabIndex = 0) => this.setState({ tabValue: selectedTabIndex });

    toggleDocumentSentModal = (open = false) => {
        this.setState({
            lenderMessageDrawer: {
                ...this.state.lenderMessageDrawer,
                open: false,
                documentSent: open,
            },
            lenderProfileDrawer: {
                ...this.state.lenderProfileDrawer,
                open: false,
            },
            adminCaseManagementDrawer: {
                ...this.state.adminCaseManagementDrawer,
                open: false,
            },
            selectedLenders: { selectionModel: [] },
        });
    };

    handleACMChatAlert = (acmChatAlertActive = false, channelId = null) => {
        if (channelId) {
            clearChannelAlert(channelId, BORROWER_URL).then((resp) => {
                this.setState({
                    adminCaseManagementDrawer: {
                        ...this.state.adminCaseManagementDrawer,
                        chatAlertActive: false,
                    },
                });
            });
        } else {
            this.setState({
                adminCaseManagementDrawer: {
                    ...this.state.adminCaseManagementDrawer,
                    chatAlertActive: false,
                },
            });
        }
    };

    handleACMDocumentsAlert = (leId = null) => {
        if (leId) {
            clearDocumentsAlert(leId, null, BORROWER_URL).then((resp) => {
                this.setState({
                    adminCaseManagementDrawer: {
                        ...this.state.adminCaseManagementDrawer,
                        acmDocumentsAlertActive: false,
                    },
                });
            });
        }
    };

    handleLegalContractTerms = () =>
        this.setState({
            legal: {
                ...this.state.legal,
                contractUpdated: true,
                contractSigned: true,
            },
        });

    handleLegalContractSigned = () => {
        const { loanEnquiry, session } = this.props;
        const { contractPreviouslyContactedLenders } = this.state.legal;
        const sessionManager = new SessionManager();
        const userRole = sessionManager.getMostSeniorRole().label;
        const userEmail = sessionManager.getSession().getEmail();
        if ('prod' === window._env_.APP_ENV) {
            window.dataLayer.push({
                event: 'contract_signed',
                userDetails: {
                    email: userEmail,
                    userType: userRole,
                    userID: sessionManager.getSession().getId(),
                    timestamp: new Date().toISOString(),
                },
                contractSigned: true,
                loanEnquiryId: loanEnquiry.id,
            });
        }

        signLegalContract(
            loanEnquiry.id,
            contractPreviouslyContactedLenders.length === 0 ? null : contractPreviouslyContactedLenders
        ).then((resp) => {
            let interval = null;
            interval = setInterval(() => {
                if (true === session.isAuthenticated()) {
                    getEnquiry(loanEnquiry.id).then((resp) => {
                        if (false === resp.loanEnquiry.contractCreationProgress) {
                            clearInterval(interval);
                            window.location.reload(false);
                        }
                    });
                } else {
                    clearInterval(interval);
                }
            }, 5000);
        });
    };

    setDebug = () => {
        this.setState({
            isDebug: true,
        });
    };

    handleContractPreviouslyContactedLenders = (lenders = null) =>
        this.setState({
            legal: {
                ...this.state.legal,
                contractPreviouslyContactedLenders: lenders || '',
            },
        });

    handleLegalContractDownload = () => {
        const { setModalClose, setModalComponent } = this.props;
        const { contract } = this.state.legal;

        if (null !== contract) {
            setModalComponent(
                <ContractDownloadPopup
                    type={'progress'}
                    content={
                        <div className={'progress'}>
                            <Loading />
                            <br />
                            Downloading your contract.
                        </div>
                    }
                />,
                false
            );
            getDocument(contract).then((resp) => {
                handleDownload(resp.document, {
                    onPending: () => () => {},
                    onSuccess: () => setModalClose(),
                    onFailure: () => setModalClose(),
                });
            });
        } else {
            setModalComponent(
                <ContractDownloadPopup
                    type={'failed'}
                    content={
                        <div className={'failed'}>
                            Unfortunately, downloading your contract failed.
                            <br />
                            Please contact our support {BRAND_MAIN_CONTACT}
                        </div>
                    }
                />,
                true
            );
        }
    };

    render() {
        let {
            config,
            loanEnquiry,
            loanEnquiryMatch,
            currentStatus = null,
            sendMessageToLenders,
            updateStatusOnChange,
            setModalComponent,
            session,
            setModalClose,
        } = this.props;
        const { contractSigned, contractInProgress, contractPreviouslyContactedLenders } = this.state.legal;
        const { dispatch } = this.props;

        if (_.isEmpty(config) || _.isEmpty(loanEnquiry) || _.isEmpty(loanEnquiryMatch)) {
            return (
                <Loading
                    onTimeoutResponse={
                        <Notice message={`Sorry, we cannot display this loan enquiry`} type={NOTICE_TYPE_ERROR} />
                    }
                />
            );
        }

        currentStatus = currentStatus || loanEnquiry.status;

        const userRole = new SessionManager().getMostSeniorRole();
        const roleName = userRole !== null ? userRole.name : ROLE_BORROWER;
        const enquiryWithdrawnEdited = currentStatus === STATUS_WITHDRAWN_EDITED;
        const enquiryOnHold = currentStatus === STATUS_ON_HOLD;
        const completionRequested = currentStatus === STATUS_COMPLETION_REQUESTED;
        const enquiryIsDraft = currentStatus === STATUS_DRAFT;
        const loanEnquiryUrl =
            roleName === ROLE_INTERMEDIARY ? ROUTE_INTERMEDIARY_EDIT_LOAN_ENQUIRY : ROUTE_BORROWER_EDIT_LOAN_ENQUIRY;

        let completionRequestData = null;
        let statusBanner = null;
        if (true === completionRequested) {
            completionRequestData = loanEnquiry.completionRequest;
            this.handleCompletionRequestedRespond(completionRequestData, loanEnquiry.id);
            statusBanner = (
                <BorStatusBannerNotice
                    status={loanEnquiry.status}
                    onClick={() => this.handleCompletionRequestedRespond(completionRequestData)}
                />
            );
        } else if (true === enquiryWithdrawnEdited) {
            statusBanner = <AdminStatusBannerNotice status={loanEnquiry.status} />;
        } else if (true === enquiryOnHold) {
            statusBanner = <AdminStatusBannerNotice status={loanEnquiry.status} />;
        } else if (true === enquiryIsDraft) {
            statusBanner = (
                <AdminStatusBannerNotice
                    status={loanEnquiry.status}
                    onClick={() =>
                        dispatch(
                            push(
                                path(loanEnquiryUrl, {
                                    loanEnquiryId: loanEnquiry.id,
                                })
                            )
                        )
                    }
                />
            );
        }

        const lendersMessageAllowed = !session.hasAssumedToken() && loanEnquiry.status !== STATUS_ON_HOLD;

        return (
            <div className={'view-enquiry'}>
                {loanEnquiry.status === STATUS_LEGALS && (
                    <CompleteAlert
                        setModalComponent={setModalComponent}
                        modalComponent={
                            <CompleteModal
                                setModalClose={setModalClose}
                                lenders={loanEnquiryMatch.matched || []}
                                amount={getStoredDataValueFromArray(loanEnquiry.storedData, LOAN_AMOUNT)}
                                url={BORROWER_URL}
                                loanEnquiryId={loanEnquiry.id}
                            />
                        }
                        header={'Has this funding been received? - '}
                        text={'Click here to move to completed status as soon funds are received'}
                    />
                )}
                {currentStatus !== STATUS_DRAFT &&
                    (false === contractSigned
                        ? setModalComponent(
                              <ContractTermsPopup
                                  handleLegalContractTerms={this.handleLegalContractTerms}
                                  handleContractPreviouslyContactedLenders={
                                      this.handleContractPreviouslyContactedLenders
                                  }
                                  contractPreviouslyContactedLenders={contractPreviouslyContactedLenders}
                                  applicants={loanEnquiry.loanEnquiryApplicants}
                                  submittedBy={loanEnquiry.submittedBy.userProfile}
                              />,
                              false
                          )
                        : true === contractInProgress
                        ? setModalComponent(
                              <ContractProgressPopup handleLegalContractSigned={this.handleLegalContractSigned} />,
                              false
                          )
                        : null)}
                {this.state.lenderMessageDrawer.documentSent && (
                    <DocumentSentModal
                        modalShow={this.state.lenderMessageDrawer.documentSent}
                        toggleDocumentSentModal={this.toggleDocumentSentModal}
                    />
                )}
                <LenderMessageDrawer
                    anchor={this.state.lenderMessageDrawer.anchor}
                    open={this.state.lenderMessageDrawer.open}
                    selectedLenders={this.state.selectedLenders}
                    toggleMessageDrawer={this.toggleMessageDrawer}
                    setMessageSent={this.setMessageSent}
                    sendMessageToLenders={sendMessageToLenders}
                    getLendersMessage={this.getLendersMessage}
                    setLendersMessage={this.setLendersMessage}
                    getMessageSent={this.getMessageSent}
                    userRole={BORROWER}
                    toggleDocumentSentModal={this.toggleDocumentSentModal}
                />
                {this.state.lenderProfileDrawer.open && (
                    <LenderDrawer
                        anchor={this.state.lenderProfileDrawer.anchor}
                        open={this.state.lenderProfileDrawer.open}
                        toggleLenderDrawer={this.toggleLenderDrawer}
                        lenderLoanEnquiry={this.state.lenderProfileDrawer.lenderLoanEnquiry}
                        channelId={
                            this.state.lenderProfileDrawer.lenderLoanEnquiry &&
                            this.state.lenderProfileDrawer.lenderLoanEnquiry.channel
                                ? this.state.lenderProfileDrawer.lenderLoanEnquiry.channel.id
                                : null
                        }
                        handleFavouriteMatchChange={this.state.lenderProfileDrawer.handleFavouriteMatchChange}
                        handleRemoveAlertMatchAlert={this.state.lenderProfileDrawer.handleRemoveAlertMatchAlert}
                        updateLLEStatus={this.state.lenderProfileDrawer.updateLLEStatus}
                        matches={loanEnquiryMatch.matched || []}
                        loanEnquiry={loanEnquiry}
                        setDebug={this.setDebug}
                        isDebug={this.state.isDebug}
                    />
                )}
                {this.state.adminCaseManagementDrawer.open && (
                    <AdminCaseManagementDrawer
                        anchor={this.state.adminCaseManagementDrawer.anchor}
                        open={this.state.adminCaseManagementDrawer.open}
                        toggleAdminCaseManagementDrawer={this.toggleAdminCaseManagementDrawer}
                        loanEnquiry={this.state.adminCaseManagementDrawer.loanEnquiry}
                        acmChatAlertActive={this.state.adminCaseManagementDrawer.chatAlertActive}
                        acmDocumentsAlertActive={this.state.adminCaseManagementDrawer.acmDocumentsAlertActive}
                        handleACMChatAlert={this.handleACMChatAlert}
                        handleACMDocumentsAlert={this.handleACMDocumentsAlert}
                    />
                )}
                <Container
                    className={`view-enquiry-header`}
                    justifyContent={`space-between`}
                    spacing={4}
                    alignItems={`center`}
                >
                    <Item xs={12} md={12} key={1}>
                        <ScreenTitle
                            title={`Loan enquiry: ${getMainApplicantName(loanEnquiry.loanEnquiryApplicants)}`}
                        />
                    </Item>
                </Container>
                {statusBanner}
                <Konami action={this.setDebug} code={[17, 16, 83]}>
                    <></>
                </Konami>
                <Container spacing={4}>
                    <Item md={12} key={1}>
                        <LoanEnquiryStatusWidget
                            setModalComponent={setModalComponent}
                            setModalClose={setModalClose}
                            updateStatusOnChange={updateStatusOnChange}
                            toggleMessageDrawer={this.toggleMessageDrawer}
                            loanEnquiry={loanEnquiry}
                            lendersMessageAllowed={lendersMessageAllowed}
                            handleTabSelect={this.handleTabSelect}
                            selectedLenders={this.state.selectedLenders}
                            acmChatAlertActive={this.state.adminCaseManagementDrawer.chatAlertActive}
                            acmDocumentsAlertActive={this.state.adminCaseManagementDrawer.acmDocumentsAlertActive}
                            toggleAdminCaseManagementDrawer={this.toggleAdminCaseManagementDrawer(true, loanEnquiry)}
                            setDebug={this.setDebug}
                            isDebug={this.state.isDebug}
                        />
                    </Item>
                </Container>
                <Container className={`view-enquiry-content`} justifyContent={`space-between`} spacing={4}>
                    <Item xs={12} md={5} key={1}>
                        {loanEnquiry.isNewForm === false ? (
                            <Container justifyContent={`space-between`} spacing={4}>
                                <Item xs={12} className={`view-enquiry-summary`} key={11}>
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={'Enquiry summary'}
                                        buttons={<div />}
                                        content={
                                            <KeyValueTable style={{ backgroundColor: '#fafafa', border: 'none' }}>
                                                <KeyValueTableRow
                                                    key={'kvtr_1'}
                                                    term={
                                                        <Subtitle1>
                                                            <strong>Loan amount:</strong>
                                                        </Subtitle1>
                                                    }
                                                    def={
                                                        <Body1>
                                                            <FactValue
                                                                name={LOAN_AMOUNT}
                                                                value={getStoredDataValueFromArray(
                                                                    loanEnquiry.storedData,
                                                                    LOAN_AMOUNT
                                                                )}
                                                                inPennies={true}
                                                            />
                                                        </Body1>
                                                    }
                                                />
                                                <KeyValueTableRow
                                                    key={'kvtr_2'}
                                                    term={<Subtitle1>Reference number:</Subtitle1>}
                                                    def={<Body1>{loanEnquiry.borrowerReference}</Body1>}
                                                />
                                                <KeyValueTableRow
                                                    key={'kvtr_3'}
                                                    term={<Subtitle1>Created:</Subtitle1>}
                                                    def={
                                                        <Body1>
                                                            <ExpandedDateTimeLayout value={loanEnquiry.createdAt} />
                                                        </Body1>
                                                    }
                                                />
                                                <KeyValueTableRow
                                                    key={'kvtr_4'}
                                                    term={<Subtitle1>{BRAND_NAME} Contract:</Subtitle1>}
                                                    def={
                                                        <ContractSigned
                                                            contractSigned={contractSigned}
                                                            contractInProgress={contractInProgress}
                                                            handleLegalContractDownload={
                                                                this.handleLegalContractDownload
                                                            }
                                                        />
                                                    }
                                                />
                                            </KeyValueTable>
                                        }
                                    />
                                </Item>
                                <Item xs={12} className={`view-enquiry-applicants`} key={12}>
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={'Applicant details'}
                                        buttons={<div />}
                                        content={
                                            <React.Fragment>
                                                {loanEnquiry.loanEnquiryApplicants.map(
                                                    (loanEnquiryApplicant, index) => (
                                                        <KeyValueTable
                                                            key={`applicant${index}`}
                                                            style={{ backgroundColor: '#fafafa', border: 'none' }}
                                                        >
                                                            <KeyValueTableRow
                                                                key={'kvtr_5'}
                                                                term={
                                                                    <Subtitle1>
                                                                        {loanEnquiryApplicant.isMainApplicant
                                                                            ? 'Main Applicant Name'
                                                                            : 'Applicant ' + (index + 1) + ' Name'}
                                                                    </Subtitle1>
                                                                }
                                                                def={
                                                                    <span>
                                                                        {loanEnquiryApplicant.applicant.given}{' '}
                                                                        {loanEnquiryApplicant.applicant.family}
                                                                    </span>
                                                                }
                                                            />
                                                            <KeyValueTableRow
                                                                key={'kvtr_6'}
                                                                term={<Subtitle1>Date of birth</Subtitle1>}
                                                                def={
                                                                    <DateFormatter
                                                                        value={
                                                                            loanEnquiryApplicant.applicant.dateOfBirth
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </KeyValueTable>
                                                    )
                                                )}
                                            </React.Fragment>
                                        }
                                    />
                                </Item>
                                <Item xs={12} className={`view-enquiry-info`} key={13}>
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={'Enquiry information'}
                                        buttons={<div />}
                                        content={
                                            <FactSummaryTable
                                                config={config}
                                                loanEnquiry={loanEnquiry}
                                                loanEnquiryStoredData={loanEnquiry.storedData}
                                                loanEnquirySubmissionData={JSON.parse(
                                                    loanEnquiry.submissionData['rawSubmission'] || '{}'
                                                )}
                                            />
                                        }
                                    />
                                </Item>
                                <Item xs={12} className={`view-enquiry-summary`} key={14}>
                                    <P4StyleComponentContainer
                                        styles={{ backgroundColor: '#fafafa', border: 'none' }}
                                        header={'Enquiry Revision History'}
                                        buttons={<div />}
                                        content={
                                            <RevisionHistoryContainer
                                                elkey={'rhc' + loanEnquiry.id}
                                                loanEnquiry={loanEnquiry}
                                                userRole={BORROWER}
                                            />
                                        }
                                    />
                                </Item>
                            </Container>
                        ) : (
                            <>
                                <NewEnquiryContent
                                    loanEnquiry={loanEnquiry}
                                    contractSigned={contractSigned}
                                    contractInProgress={contractInProgress}
                                    handleLegalContractDownload={this.handleLegalContractDownload}
                                    currentStatus={currentStatus}
                                />
                            </>
                        )}
                    </Item>
                    <Item className={`view-enquiry-matches`} xs={12} md={7} key={2}>
                        <LeadsContainer
                            contractSigned={contractSigned}
                            contractInProgress={contractInProgress}
                            loanEnquiryId={loanEnquiry.id}
                            loanEnquiry={loanEnquiry}
                            matches={loanEnquiryMatch.matched || []}
                            loanEnquiryStatus={loanEnquiry.status}
                            matchCount={loanEnquiryMatch.match_count || 0}
                            userIsVerified={session.getSession().isVerified()}
                            handleLenderSelection={this.handleLenderSelection}
                            selectedLenders={this.state.selectedLenders}
                            lendersMessageAllowed={lendersMessageAllowed}
                            toggleLenderDrawer={this.toggleLenderDrawer}
                            selectAllLenders={this.state.selectAllLenders}
                        />
                    </Item>
                </Container>
            </div>
        );
    }
}

MasonryStyleContainer.propTypes = {};

const mapStateToProps = (state) => {
    return {
        config: selectorGetMatchingCriteriaConfig(state),
        loanEnquiry: selectorGetLoanEnquiry(state),
        loanEnquiryMatch: selectorGetLoanEnquiryMatch(state),
        currentStatus: selectorGetStatus(state),
        submitted: selectorGetSubmitted(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        initiate: (loanEnquiryId) => dispatch(actionInitiate(loanEnquiryId)),
        reloadLoanEnquiry: () => dispatch(actionReloadLoanEnquiry()),
        cleanup: () => dispatch(actionCleanup()),
        updateStatusOnChange: (leadId, newStatus, metadata) => {
            dispatch(actionApiSetStatus(leadId, newStatus, metadata));
        },
        updateLeadStatusOnChange: (leadId, newStatus, metadata, fetchMatches) => {
            dispatch(actionApiSetLeadStatus(leadId, newStatus, metadata, fetchMatches));
        },
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
        setModalInteractive: (interactive) => {
            dispatch(sagaActionModalInteractive(interactive));
        },
        clearStatus: () => dispatch(actionClearStatus()),
        removeLoanEnquiryAlert: (status) => {
            dispatch(actionRemoveLoanEnquiryAlert(status));
        },
        sendMessageToLenders: (lenders, message, toggleMessageDrawer, setMessageSent) => () => {
            dispatch(actionSendMessageToLenders(lenders, message, toggleMessageDrawer, setMessageSent));
        },
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withSession,
    graphql(SendMessageMutation, {
        options() {
            return {
                context: {
                    uri: BORROWER_URL,
                },
            };
        },
        name: 'sendMessageMutate',
    }),
    renderErrorIfAuthorisationFail([ROLE_BORROWER])
)(MasonryStyleContainer);
