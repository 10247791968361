import React, { useState } from 'react';
import finalLoanAmountImage from '../../assets/images/dashboard-docs.svg';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import { setLECompleted } from '../../pitch4_api/set_le_completed';
import moment from 'moment';

export default function CompleteFinalAmountModal(props) {
    const { setModalClose, lenderId, amount, url, loanEnquiryId } = props;
    const [finalAmount, setFinalAmount] = useState(amount);
    const handleSubmit = () => {
        setLECompleted(url, loanEnquiryId, lenderId, finalAmount, moment().format('YYYY-MM-DD')).then(() => {
            setModalClose();
            window.location.reload();
        });
    };

    return (
        <div className={'complete-modal-container'}>
            <div className={'panel-left'}>
                <div>
                    <img className="info-pane-image" src={finalLoanAmountImage} alt="Final Loan Amount" />
                </div>
                <div className={'header'}>Are you sure?</div>
                <div className={'text-1'}>
                    Marking a Lead as Completed cannot be undone. Please confirm this loan is completed.
                </div>
                <div className={'text-2'}>
                    Need to understand what counts as
                    <br />
                    complete?{' '}
                    <a
                        className="typography-default-link"
                        href="https://help.providefinance.com/knowledge/what-is-the-completion-process"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Click here.
                    </a>
                </div>
            </div>
            <div className={'panel-right'}>
                <div>
                    <div className={'header'}>
                        Please enter and confirm final
                        <br />
                        Net Loan amount
                    </div>
                    <div className={'loan-amount-input'}>
                        <TextField
                            id="loan-amount"
                            variant="outlined"
                            type={'number'}
                            inputProps={{ min: 0, step: '0.01' }}
                            placeholder={'£'}
                            value={finalAmount}
                            onChange={(event) => setFinalAmount(event.target.value)}
                        />
                    </div>
                </div>
                <div className={'actions'}>
                    <Button onClick={() => setModalClose()}>CANCEL</Button>
                    <Button
                        onClick={() => handleSubmit()}
                        variant={'contained'}
                        color={'primary'}
                        disabled={'' === finalAmount}
                    >
                        CONFIRM
                    </Button>
                </div>
            </div>
        </div>
    );
}
