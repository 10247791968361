import createP4ApolloFetch from '../../../../bootstrap/middlewares';
import { API_URL } from '../../../../bootstrap/constants/constants';

export const createTermsMatrix = (uri = API_URL, loanEnquiryId, blankLenderSpaces, matrixLenders, terms) => {
    const apolloFetch = createP4ApolloFetch({ uri: uri });

    const mutation = `
        mutation createTermsMatrix(
            $loanEnquiryId: ID!,
            $blankLenderSpaces: Int,
            $matrixLenders: String,
            $terms: String
        ) {
            createTermsMatrix(loanEnquiryId: $loanEnquiryId, blankLenderSpaces: $blankLenderSpaces, matrixLenders: $matrixLenders, terms: $terms) {
                id
                fileName
                fileSize
                mimeType
                type
                status
                createdAt
                updatedAt
                downloadUrl
            }
        }
    `;

    return apolloFetch({
        query: mutation,
        variables: {
            loanEnquiryId: loanEnquiryId,
            blankLenderSpaces: blankLenderSpaces,
            matrixLenders: JSON.stringify(matrixLenders),
            terms: JSON.stringify(terms),
        },
    })
        .then((resp) => resp)
        .catch((error) => {
            return {};
        });
};
