import React from 'react';
import { BRAND_NAME_FULL } from '../../../bootstrap/constants/constants';

class BrandTextFull extends React.Component {
    render() {
        return <span {...this.props}>{BRAND_NAME_FULL}</span>;
    }
}

export default BrandTextFull;
