import { call, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import { getApplicants as apiCall } from '../api/get_applicants';
import { GET_INIT_API_GET_APPLICANTS, SET_APPLICANTS } from '../redux';

function* getApplicants() {
    const result = yield call(apiCall);
    const applicantEdges = _.get(result, ['applicants', 'edges']);

    if (applicantEdges === undefined) {
        yield put({ type: SET_APPLICANTS, applicants: [] });
    } else {
        yield put({ type: SET_APPLICANTS, applicants: _.map(applicantEdges, ({ node }) => node) });
    }
}

//Main Saga
function* main() {
    yield takeLatest(GET_INIT_API_GET_APPLICANTS, getApplicants);
}

export default main;
