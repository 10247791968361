import { BaseEnum } from 'pitch4_enum';

export const TYPE_UK_LTD = 'uk_limited';
export const TYPE_OFFSHORE_LTD = 'offshore_limited';
export const TYPE_PERSONAL_NAME = 'personal_name';
export const TYPE_OVERSEAS_COMPANY = 'overseas_company';
export const TYPE_LLP = 'llp';

class PropertyHowOwnedEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_UK_LTD]: 'UK Limited Company',
            [TYPE_OFFSHORE_LTD]: 'Offshore Limited Company',
            [TYPE_PERSONAL_NAME]: 'Personal name',
            [TYPE_OVERSEAS_COMPANY]: 'Overseas Company',
            [TYPE_LLP]: 'LLP',
        };
    };
}

export default PropertyHowOwnedEnum;
