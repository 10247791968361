import React from 'react';
import PropTypes from 'prop-types';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '../../../pitch4_forms/components/inputs';
import { InputAdornment } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import IconButton from '@material-ui/core/IconButton';
import { ACCEPTED_FILE_MIME_TYPES, MAX_FILE_UPLOAD_SIZE_MB } from '../../constants';

class InputFile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            label: null,
            colour: null,
        };

        this.inputFile = React.createRef();
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.input !== prevState.input) {
            let {
                label = 'Upload',
                colour = 'default',
                input: { value },
            } = nextProps;
            return {
                label: value ? value.name : label,
                colour: value ? 'primary' : colour,
            };
        } else return null;
    }

    handleInputFileClick = (ev) => {
        ev.stopPropagation();
        this.inputFile.current.click();
    };

    handleChange = (event) => {
        let {
            currentTarget: { files },
        } = event;
        let {
            input: { onChange },
        } = this.props;

        const file = files[0];
        if (file) {
            this.setState({
                label: file.name,
                colour: 'primary',
            });
        }

        onChange(file);
    };

    render() {
        let {
            meta: { touched, error },
            disabled = false,
            mimeTypes = ACCEPTED_FILE_MIME_TYPES,
            maxFileSize = MAX_FILE_UPLOAD_SIZE_MB,
            fullWidth = true,
        } = this.props;

        return (
            <>
                <FormControl className={'input-file'} error={touched && error !== ''} fullWidth={fullWidth}>
                    <input
                        type="file"
                        accept={mimeTypes.join(',')}
                        name="file"
                        ref={this.inputFile}
                        onChange={this.handleChange}
                        style={{ display: 'none' }}
                    />
                    <TextField
                        name="file-select"
                        onClick={this.handleInputFileClick}
                        disabled={disabled}
                        placeholder={touched && error ? '' : this.state.label}
                        fullWidth={fullWidth}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton aria-label="upload file" onClick={this.handleInputFileClick} edge="end">
                                        <CloudUploadIcon className={'upload-icon'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {<div className="field-info">{`Max file upload size: ` + maxFileSize + `mb`}</div>}
                    {touched && error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </>
        );
    }
}

InputFile.propTypes = {
    label: PropTypes.string,
    colour: PropTypes.string,
};

export default InputFile;
