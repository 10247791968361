import React from 'react';

import { Container } from './index';

const RightAligned = (props) => (
    <Container justifyContent={`flex-end`} alignItems={`center`} {...props}>
        {props.children}
    </Container>
);

export default RightAligned;
