import { put, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { actionCloseLeftSideMenu, NAV_LEFT_SIDEMENU_ITEM_SELECTED } from '../redux/leftsidemenu';

//Worker Sagas
function* itemSelectedInLeftSideMenu(action) {
    yield put(actionCloseLeftSideMenu());
    yield put(push(action.url));
}

//Main Saga
function* main() {
    yield takeLatest(NAV_LEFT_SIDEMENU_ITEM_SELECTED, itemSelectedInLeftSideMenu);
}

export default main;
