import ChannelMessage from '../ChannelMessage';

class ChannelMessageFactory {
    create = () => {
        return new ChannelMessage();
    };

    createFromApiMessage = (apiMessage) => {
        let { id, senderId, type, content, channel, sentAt, userProfile, userRole } = apiMessage;
        let message = new ChannelMessage();
        message
            .setId(id)
            .setSenderId(senderId)
            .setType(type)
            .setUserRole(userRole)
            .setContent(content)
            .setChannel(channel)
            .setSentAt(sentAt)
            .setUserProfile(userProfile);
        return message;
    };
}

export default ChannelMessageFactory;
