import moment from 'moment';

class Mapper {
    mapToForm = (props) => {
        let {
            id = '',
            username = '',
            email = '',
            createdAt = '',
            updatedAt = '',
            userProfile: {
                title = '',
                given = '',
                family = '',
                dateOfBirth = null,
                primaryAddress: {
                    address1 = '',
                    address2 = '',
                    address3 = '',
                    address4 = '',
                    postcode = '',
                    country = '',
                },
                primaryPhone: { number = '' },
                jobTitle = '',
            },
            primaryRole: { name = '' },
            lender = '',
            broker = '',
        } = props;

        return {
            id,
            email,
            username,
            title,
            given,
            family,
            createdAt,
            updatedAt,
            dateOfBirth: null === dateOfBirth ? null : moment(dateOfBirth).format('YYYY-MM-DD'),
            primaryAddressAddress1: address1,
            primaryAddressAddress2: address2,
            primaryAddressAddress3: address3,
            primaryAddressAddress4: address4,
            primaryAddressCountry: country,
            primaryAddressPostcode: postcode,
            primaryPhoneNumber: number,
            primaryRole: name,
            jobTitle,
            lender,
            broker,
        };
    };

    mapFromCreateForm = ({
        email = '',
        title = '',
        given = '',
        family = '',
        createdAt = '',
        updatedAt = '',
        dateOfBirth = '',
        primaryAddressAddress1 = '',
        primaryAddressAddress2 = '',
        primaryAddressAddress3 = '',
        primaryAddressAddress4 = '',
        primaryAddressCountry = '',
        primaryAddressPostcode = '',
        primaryPhoneNumber = '',
        primaryRole = '',
        jobTitle = '',
        lender = '',
        broker = '',
    }) => {
        return {
            email,
            title,
            given,
            family,
            dateOfBirth,
            primaryAddress: {
                address1: primaryAddressAddress1,
                address2: primaryAddressAddress2,
                address3: primaryAddressAddress3,
                address4: primaryAddressAddress4,
                postcode: primaryAddressPostcode,
                country: primaryAddressCountry,
            },
            primaryPhone: {
                number: primaryPhoneNumber,
            },
            primaryRole: {
                name: primaryRole,
            },
            jobTitle,
            lender: lender,
            broker: broker,
        };
    };

    mapFromEditForm = ({
        email = '',
        title = '',
        given = '',
        family = '',
        createdAt = '',
        updatedAt = '',
        dateOfBirth = '',
        primaryAddressAddress1 = '',
        primaryAddressAddress2 = '',
        primaryAddressAddress3 = '',
        primaryAddressAddress4 = '',
        primaryAddressCountry = '',
        primaryAddressPostcode = '',
        primaryPhoneNumber = '',
        jobTitle = '',
        lender = '',
        broker = '',
    }) => {
        return {
            email,
            title,
            given,
            family,
            dateOfBirth,
            primaryAddress: {
                address1: primaryAddressAddress1,
                address2: primaryAddressAddress2,
                address3: primaryAddressAddress3,
                address4: primaryAddressAddress4,
                postcode: primaryAddressPostcode,
                country: primaryAddressCountry,
            },
            primaryPhone: {
                number: primaryPhoneNumber,
            },
            jobTitle,
            lender: lender,
            broker: broker,
        };
    };
}

export default Mapper;
