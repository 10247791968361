import React from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_BORROWER } from '../../../authentication/enum/Roles/NameEnum';
import { Container } from '../../../../pitch4_layout/components/div';
import { push } from 'react-router-redux';
import { connect } from 'react-redux';
import PlaceholderImageLayout from '../../../../pitch4_layout/components/content/PlaceholderImageLayout';
import { actionUpdateMatchFavourite } from '../redux';
import withSession from '../../../../pitchblack_react_utils/session/withSession';
import CardView from '../components/CardView';
import { Paper } from '@material-ui/core';
import _ from 'lodash';
import { actionRemoveLenderLoanEnquiryAlert } from '../../lender_loan_enquiries_view/redux/main';
import { sagaActionModalHide, sagaActionModalShow } from '../../../modal/sagas/container';
import ContractSigned from '../../../legal/ContractSigned';
import ApplicationPlaceholder from '../components/ApplicationSection/ApplicationPlaceholder';
import ActionToolbar from '../components/ActionToolbar';
import StatusEnum, { STATUS_IN_PROGRESS } from '../../enum/LenderLoanEnquiries/StatusEnum';
import CardViewItem from '../components/CardViewItem';
import { getTersByType } from '../../../../pitch4_elements/terms/Utils';
import { setLenderLoanEnquiryStatus } from '../api/set_lender_loan_enquiry_status';
import { SECTION_LOAN_TYPE } from '../../mle/constants';
import { LOAN_TYPE_BUSINESS_FINANCE, LOAN_TYPE_PROPERTY } from '../../enum/LoanEnquiries/LoanTypeEnum';
import { BUSINESS_TYPE } from '../../../MakeLoanEnquiry/enums/Borrower/BorrowerLoanType';
import { calculateLoanType } from '../../../MakeLoanEnquiry/enums/Shared/CalculateLoanTypeEnum';

const statusEnum = new StatusEnum();

class LeadsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lenderProfileDrawOpen: false,
            lenderProfile: null,
            loanEnquiryMatches: [],
            matchSelected: null,
            refreshedData: false,
            refreshedFavData: false,
            showOnlyFavEnabled: false,
            data: this.getData(),
            matches: props.matches,
            selectAll: false,
            selectFav: false,
            sortBy: {
                field: 'status',
                sort: 'asc',
            },
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            (false === this.state.refreshedFavData || this.state.matches.length !== this.props.matches.length) &&
            (!this.state.refreshedData || this.props.matchCount !== this.state.data.length)
        ) {
            const { field, sort } = this.state.sortBy;
            // Refresh data after a user accepts matches in a popup form
            const data = this.props.matches.map((match) => {
                return {
                    id: match.id,
                    lenderId: match.lender.id,
                    lenderName: match.lender.name,
                    lenderProfileEnabled: match.lender.profileEnabled,
                    lenderProfile: match.lender,
                    updatedAt: match.updatedAt,
                    status: match.status,
                    alertActive: match.alertActive,
                    favourite: match.favourite,
                    terms: JSON.parse(match.lenderTerms) || null,
                };
            });

            this.setState({ data: _.orderBy(data, [field], [sort]), refreshedData: true, matches: data });
        }
    }

    getData = () => {
        const { matches } = this.props;
        const data = matches.map((match) => {
            return {
                id: match.id,
                lenderId: match.lender.id,
                lenderName: match.lender.name,
                lenderProfileEnabled: match.lender.profileEnabled,
                lenderProfile: match.lender,
                updatedAt: match.updatedAt,
                status: match.status,
                alertActive: match.alertActive,
                favourite: match.favourite,
                terms: JSON.parse(match.lenderTerms) || null,
            };
        });

        return _.orderBy(data, ['status'], ['asc']);
    };

    handleShowOnlyFav = (selectFav) => {
        const { matches } = this.state;
        const data = matches
            .filter((match) => (true === selectFav ? selectFav === match.favourite : match))
            .map((match) => {
                return {
                    id: match.id,
                    lenderId: match.lender.id,
                    lenderName: match.lender.name,
                    lenderProfileEnabled: match.lender.profileEnabled,
                    lenderProfile: match.lender,
                    updatedAt: match.updatedAt,
                    status: match.status,
                    alertActive: match.alertActive,
                    favourite: match.favourite,
                    terms: JSON.parse(match.lenderTerms) || null,
                };
            });

        this.setState({
            data: data,
            refreshedFavData: true,
            showOnlyFavEnabled: selectFav,
        });
    };

    handleFavouriteMatchChange = (lenderLoanEnquiryId = null, loanEnquiryId = null, isFavourite = null) => () => {
        if (null !== lenderLoanEnquiryId && null !== isFavourite) {
            const { handleFavouriteMatchChange } = this.props;
            const data = this.state.data;
            const dMatchIndex = this.state.data.findIndex((m) => m.id === lenderLoanEnquiryId);
            data[dMatchIndex].favourite = !isFavourite;

            // We need to do the same as above to update props matches objects in state.
            // Props matches kept in state  - we need them to filter out Fav matches and then show all matches again
            const matches = this.state.matches;
            const mMatchIndex = this.state.matches.findIndex((m) => m.id === lenderLoanEnquiryId);
            matches[mMatchIndex].favourite = !isFavourite;

            this.setState({ data: [], matches: [] }, () =>
                this.setState({
                    // Filter out not Fav LLEs
                    data:
                        true === this.state.showOnlyFavEnabled
                            ? data.filter((match) => match.id !== lenderLoanEnquiryId)
                            : data,
                    matches: matches,
                })
            );

            handleFavouriteMatchChange(lenderLoanEnquiryId, loanEnquiryId, !isFavourite);
        }
    };

    handleRemoveAlertMatchAlert = (lenderLoanEnquiry) => {
        const { removeLenderLoanEnquiryAlert } = this.props;
        const { data, matches } = this.state;
        let dLle = data.find((lle) => lle.id === lenderLoanEnquiry.id);
        dLle.alertActive = false;

        // Same as the above comment
        let mLle = matches.find((lle) => lle.id === lenderLoanEnquiry.id);
        mLle.alertActive = false;

        this.setState({ data: [] }, () => {
            this.setState({ data, matches }, () => removeLenderLoanEnquiryAlert(lenderLoanEnquiry.id));
        });
    };

    updateLLEStatus = (lenderLoanEnquiry, status) => {
        setLenderLoanEnquiryStatus(lenderLoanEnquiry.id, status, 'Borrower Application').then((r) => {
            const { data, matches } = this.state;
            let dLle = data.find((lle) => lle.id === lenderLoanEnquiry.id);
            dLle.status = status;

            // Same as the above comment
            let mLle = matches.find((lle) => lle.id === lenderLoanEnquiry.id);
            mLle.status = status;

            this.setState({ data: [] }, () => {
                this.setState({ data, matches });
            });
        });
    };

    handleSelectAll = (selectAll = false) => this.setState({ selectAll: selectAll });

    handleSortBy = (
        sortBy = {
            field: 'status',
            sort: 'asc',
        }
    ) => {
        this.setState({ sortBy: sortBy }, () => {
            const { data } = this.state;
            this.setState({ data: _.orderBy(data, [sortBy.field], [sortBy.sort]) });
        });
    };

    setStatusName = () => {
        const { sortBy } = this.state;
        switch (sortBy.field) {
            case 'lenderName':
                return sortBy.sort === 'asc' ? 'lenderNameAsc' : 'lenderNameDesc';
            case 'updatedAt':
                return sortBy.sort === 'asc' ? 'updatedAtAsc' : 'updatedAtDesc';
            case 'status':
                return sortBy.sort === 'asc' ? 'statusAsc' : 'statusDesc';
            default:
                return 'statusAsc';
        }
    };

    getApplications = () => {
        return this.state.data.filter((item) => {
            return statusEnum.getApplicationStatuses().includes(item.status);
        });
    };

    getLenders = () => {
        return this.state.data.filter((item) => {
            return !statusEnum.getApplicationStatuses().includes(item.status);
        });
    };

    getEligibleLenders = () => {
        const lenders = this.getLenders();
        return lenders.filter((lender) => lender.status === STATUS_IN_PROGRESS);
    };

    render() {
        let {
            matchCount,
            userIsVerified,
            handleLenderSelection,
            selectedLenders,
            lendersMessageAllowed,
            toggleLenderDrawer,
            contractSigned = false,
            contractInProgress = true,
            loanEnquiry,
        } = this.props;
        const applications = this.getApplications();

        const submissionData = JSON.parse(loanEnquiry.submissionData['rawSubmission'] || '{}');
        let loanType = LOAN_TYPE_PROPERTY;
        if (typeof submissionData === 'object' && SECTION_LOAN_TYPE in submissionData) {
            loanType = calculateLoanType(loanEnquiry.loanEnquiryData).value;
        } else {
            loanType =
                loanEnquiry.loanEnquiryData.loanType === BUSINESS_TYPE
                    ? LOAN_TYPE_BUSINESS_FINANCE
                    : LOAN_TYPE_PROPERTY;
        }

        return (
            <>
                <Paper
                    elevation={0}
                    className={'leads-container-main'}
                    // style={{ backgroundColor: '#fafafa', border: 'none' }}
                >
                    <div className={'lenders-badge'}>
                        <div className={'count'}>{matchCount}</div>
                        <div>LENDER{matchCount !== 1 && 'S'}</div>
                    </div>
                    <div className={`leads-container-header`}>
                        <div className={'label'}>
                            Your Lender&nbsp;<strong>Applications</strong>:{' '}
                            <span className={'count'}>({applications.length})</span>
                        </div>
                        <div className={'actions'}>
                            <ActionToolbar
                                data={this.state.data}
                                handleShowOnlyFav={this.handleShowOnlyFav}
                                handleLenderSelection={handleLenderSelection}
                                setStatusName={this.setStatusName}
                                handleSortBy={this.handleSortBy}
                            />
                        </div>
                    </div>
                    {(this.getLenders().length > 0 || applications.length > 0) && (
                        <>
                            <div className={'application-view-container'}>
                                {(() => {
                                    if (true === contractSigned && false === contractInProgress) {
                                        if (applications.length === 0) {
                                            return (
                                                <>
                                                    <ApplicationPlaceholder
                                                        lender={
                                                            this.getEligibleLenders().length > 0
                                                                ? this.getEligibleLenders()[0]
                                                                : this.getLenders()[0]
                                                        }
                                                        toggleLenderDrawer={toggleLenderDrawer}
                                                        interactive={true}
                                                        handleFavouriteMatchChange={this.handleFavouriteMatchChange}
                                                        handleRemoveAlertMatchAlert={this.handleRemoveAlertMatchAlert}
                                                        updateLLEStatus={this.updateLLEStatus}
                                                    />
                                                </>
                                            );
                                        } else {
                                            return applications.map((lender, index) => {
                                                const interestRateTerm = lender.terms
                                                    ? getTersByType(lender.terms, 'mainInterestRate')[0]
                                                    : null;
                                                return (
                                                    <CardViewItem
                                                        index={index}
                                                        lender={lender}
                                                        selectedLenders={selectedLenders}
                                                        toggleLenderDrawer={toggleLenderDrawer}
                                                        handleFavouriteMatchChange={this.handleFavouriteMatchChange}
                                                        handleRemoveAlertMatchAlert={this.handleRemoveAlertMatchAlert}
                                                        interestRateTerm={interestRateTerm}
                                                        lendersMessageAllowed={lendersMessageAllowed}
                                                        handleLenderSelection={handleLenderSelection}
                                                        dataLength={this.state.data.length}
                                                        handleSelectAll={this.handleSelectAll}
                                                        updateLLEStatus={this.updateLLEStatus}
                                                        loanType={loanType}
                                                    />
                                                );
                                            });
                                        }
                                    }
                                })()}
                                <ApplicationPlaceholder interactive={false} />
                            </div>
                        </>
                    )}
                    <div className={`leads-container-header`}>
                        <div className={'label'}>
                            Lender Matches: <span className={'count'}>({this.getLenders().length})</span>
                        </div>
                    </div>
                    {true === contractSigned && false === contractInProgress ? (
                        <CardView
                            handleLenderSelection={handleLenderSelection}
                            selectedLenders={selectedLenders}
                            lendersMessageAllowed={lendersMessageAllowed}
                            toggleLenderDrawer={toggleLenderDrawer}
                            handleFavouriteMatchChange={this.handleFavouriteMatchChange}
                            handleRemoveAlertMatchAlert={this.handleRemoveAlertMatchAlert}
                            data={this.getLenders()}
                            handleSelectAll={this.handleSelectAll}
                            updateLLEStatus={this.updateLLEStatus}
                        />
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 20, paddingBottom: 20 }}>
                            <ContractSigned contractSigned={contractSigned} contractInProgress={contractInProgress} />
                        </div>
                    )}

                    {this.renderMessage(matchCount, userIsVerified)}
                </Paper>
            </>
        );
    }

    renderMessage(matchCount, userIsVerified) {
        if (userIsVerified === false) {
            return (
                <Container justifyContent={'center'}>
                    <PlaceholderImageLayout classNames={'unverified-user-empty-table-placeholder'}>
                        <div>
                            <h3 className={'typography-card-title'}>You are still unverified.</h3>
                            <p className={'typography-default'}>
                                You may need to complete the email verification process before some lenders will contact
                                you.
                            </p>
                        </div>
                    </PlaceholderImageLayout>
                </Container>
            );
        }

        if (matchCount < 1) {
            return (
                <Container justifyContent={'center'}>
                    <PlaceholderImageLayout classNames={'empty-table-placeholder'}>
                        <div>
                            <h3 className={'typography-card-title'}>This enquiry has not matched with any lenders</h3>
                            <p className={'typography-default'}>
                                This may be due to the criteria being too specific. Please contact an Admin with any
                                questions.
                            </p>
                        </div>
                    </PlaceholderImageLayout>
                </Container>
            );
        }

        return null;
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        redirect: (url) => dispatch(push(url)),
        handleFavouriteMatchChange: (lenderLoanEnquiryId, loanEnquiryId, isFavourite) =>
            dispatch(actionUpdateMatchFavourite(lenderLoanEnquiryId, loanEnquiryId, isFavourite)),
        removeLenderLoanEnquiryAlert: (status) => {
            dispatch(actionRemoveLenderLoanEnquiryAlert(status));
        },
        setModalComponent: (component, interactive = true) => {
            dispatch(sagaActionModalShow(component, interactive));
        },
        setModalClose: () => {
            dispatch(sagaActionModalHide());
        },
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withSession,
    withRouter,
    renderErrorIfAuthorisationFail([ROLE_BORROWER])
)(LeadsContainer);
