import React from 'react';
import { reduxForm } from 'redux-form';
import {
    FieldButtonPanelRow,
    FieldRow,
    Select,
    TextField,
    TextLabel,
} from '../../../../pitch4_forms/components/inputs';
import { Container, RowItem } from '../../../../pitch4_layout/components/div';
import styled from 'styled-components';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import {
    hasMaxLength255,
    hasMaxLength30,
    hasMinLength2,
    hasMinLength6,
    isRequired,
    isValidPostcode,
} from '../../../../pitchblack_react_utils/form/validation';
import { PrimaryButton, SecondaryButton } from '../../../../pitch4_layout/components/buttons';
import { ServerErrors } from '../../../../pitch4_forms/components';
import { createOptionsFromEnum } from '../../../../pitch4_forms/components/inputs/select/index';
import TitleEnum from '../../../admin/enum/UserProfile/TitleEnum';
import GenderEnum from '../../../admin/enum/UserProfile/GenderEnum';

const SpacerItem = styled(RowItem)``;

class Form extends React.Component {
    displayBeacon = (fieldId) => {
        const { profileMissingRequiredFieldsList } = this.props;
        return profileMissingRequiredFieldsList.includes(fieldId);
    };

    render() {
        let { onSubmit, submitting, handleSubmit, error = [], reset, showJobTitle = false } = this.props;

        return (
            <form onSubmit={handleSubmit(onSubmit)}>
                <Container>
                    <RowItem>
                        <ServerErrors errors={error} visible={true} />
                    </RowItem>
                    <FieldRow
                        label={<TextLabel label="Email" />}
                        input={<TextField name="email" placeholder="Email" disabled={true} />}
                    />
                    <FieldRow
                        label={<TextLabel label="Title" />}
                        input={
                            <Select
                                name="title"
                                placeholder="Title"
                                style={{ padding: 0 }}
                                disabled={submitting}
                                options={createOptionsFromEnum(new TitleEnum())}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Gender" />}
                        displayFieldBeacon={this.displayBeacon('gender')}
                        input={
                            <Select
                                name="gender"
                                placeholder="Gender"
                                style={{ padding: 0 }}
                                disabled={submitting}
                                options={createOptionsFromEnum(new GenderEnum())}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Given Name" />}
                        input={
                            <TextField
                                name="given"
                                placeholder="Given name"
                                disabled={submitting}
                                validate={[isRequired, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Family Name" />}
                        input={
                            <TextField
                                name="family"
                                placeholder="Family name"
                                disabled={submitting}
                                validate={[isRequired, hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Phone" />}
                        displayFieldBeacon={this.displayBeacon('phone_number')}
                        input={
                            <TextField
                                name="primaryPhoneNumber"
                                placeholder="Number"
                                disabled={submitting}
                                validate={[isRequired, hasMinLength6, hasMaxLength30]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 1" />}
                        input={
                            <TextField
                                value="1234"
                                name="primaryAddressAddress1"
                                placeholder="Address 1"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 2" />}
                        input={
                            <TextField
                                name="primaryAddressAddress2"
                                placeholder="Address 2"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 3" />}
                        input={
                            <TextField
                                name="primaryAddressAddress3"
                                placeholder="Address 3"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Address 4" />}
                        input={
                            <TextField
                                name="primaryAddressAddress4"
                                placeholder="Address 4"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Country" />}
                        input={
                            <TextField
                                name="primaryAddressCountry"
                                placeholder="Country"
                                disabled={submitting}
                                validate={[hasMinLength2, hasMaxLength255]}
                            />
                        }
                    />
                    <FieldRow
                        label={<TextLabel label="Postcode" />}
                        input={
                            <TextField
                                name="primaryAddressPostcode"
                                placeholder="Postcode"
                                disabled={submitting}
                                validate={[isValidPostcode]}
                            />
                        }
                    />
                    {showJobTitle && (
                        <FieldRow
                            label={<TextLabel label="Job Title" />}
                            input={
                                <TextField
                                    name="jobTitle"
                                    placeholder="Job title"
                                    disabled={submitting}
                                    validate={[hasMinLength2, hasMaxLength255]}
                                />
                            }
                        />
                    )}
                    <SpacerItem />
                    <FieldButtonPanelRow>
                        <SecondaryButton disabled={submitting} onClick={reset}>
                            Reset
                        </SecondaryButton>
                        <PrimaryButton type="submit" disabled={submitting}>
                            Submit
                        </PrimaryButton>
                    </FieldButtonPanelRow>
                </Container>
            </form>
        );
    }
}

Form.propTypes = {
    onSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    handleSubmit: PropTypes.func,
    error: PropTypes.any,
    reset: PropTypes.func,
    showJobTitle: PropTypes.bool,
    initialValues: PropTypes.any,
};

export default compose(
    reduxForm({
        form: 'administer_users_configureprofile_form',
        enableReinitialize: true,
    })
)(Form);
