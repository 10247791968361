import { BaseEnum } from 'pitch4_enum';

export const ADDITIONAL_INFO_NOTES = 'Additional information';
export const ADDRESS = 'ADDRESS';
export const ADVERSE_CREDIT = 'ADVERSE_CREDIT';
export const ANNUAL_RENT = 'ANNUAL_RENT';
export const BEDROOMS = 'BEDROOMS';
export const BORROWER_CCJ_HOW_MUCH = 'BORROWER_CCJ_HOW_MUCH';
export const BORROWER_CCJ_SATISFIED = 'BORROWER_CCJ_SATISFIED';
export const BORROWER_CCJ_WHEN = 'BORROWER_CCJ_WHEN';
export const BORROWER_CCJ_WHY = 'BORROWER_CCJ_WHY';
export const BORROWER_COUNTRY_OF_RESIDENCE = 'BORROWER_COUNTRY_OF_RESIDENCE';
export const BORROWER_DEFAULTED_HOW_MUCH = 'BORROWER_DEFAULTED_HOW_MUCH';
export const BORROWER_DEFAULTED_UP_TO_DATE = 'BORROWER_DEFAULTED_UP_TO_DATE';
export const BORROWER_DEFAULTED_WHEN = 'BORROWER_DEFAULTED_WHEN';
export const BORROWER_DEFAULTED_WHY = 'BORROWER_DEFAULTED_WHY';
export const BORROWER_EMPLOYMENT_STATUS = 'BORROWER_EMPLOYMENT_STATUS';
export const BORROWER_GROWTH_AND_INCOME = 'BORROWER_GROWTH_AND_INCOME';
export const BORROWER_HAS_ADVERSE_CREDIT = 'BORROWER_HAS_ADVERSE_CREDIT';
export const BORROWER_HAS_CCJS = 'BORROWER_HAS_CCJS';
export const BORROWER_HAS_DEFAULTED = 'BORROWER_HAS_DEFAULTED';
export const BORROWER_IS_UK_CITIZEN = 'BORROWER_IS_UK_CITIZEN';
export const BORROWER_NATIONALITY = 'BORROWER_NATIONALITY';
export const BORROWER_OCCUPATION = 'BORROWER_OCCUPATION';
export const BORROWER_OWN_OTHER_PROPERTIES = 'BORROWER_OWN_OTHER_PROPERTIES';
export const BORROWER_RIGHT_TO_RESIDE = 'BORROWER_RIGHT_TO_RESIDE';
export const BUILD_COST = 'BUILD_COST';
export const BUILD_COSTS = 'BUILD_COSTS';
export const BUSINESS_ADDITIONAL_SECURITY = 'BUSINESS_ADDITIONAL_SECURITY';
export const BUSINESS_ADVERSE_CREDIT_DETAILS = 'BUSINESS_ADVERSE_CREDIT_DETAILS';
export const BUSINESS_HAD_ADVERSE_CREDIT = 'BUSINESS_HAD_ADVERSE_CREDIT';
export const BUSINESS_INTEND_TO_OCCUPY = 'BUSINESS_INTEND_TO_OCCUPY';
export const BUSINESS_LOAN_TERM = 'BUSINESS_LOAN_TERM';
export const BUSINESS_NAME = 'BUSINESS_NAME';
export const BUSINESS_NON_REFINANCE_ASSET = 'BUSINESS_NON_REFINANCE_ASSET';
export const BUSINESS_OUTSTANDING_INVOICE = 'BUSINESS_OUTSTANDING_INVOICE';
export const BUSINESS_OUTSTANDING_INVOICE_AMOUNT = 'BUSINESS_OUTSTANDING_INVOICE_AMOUNT';
export const BUSINESS_PROFITABLE = 'BUSINESS_PROFITABLE';
export const BUSINESS_SECTOR = 'BUSINESS_SECTOR';
export const BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT = 'BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT';
export const BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT_EXPLANATION =
    'BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT_EXPLANATION';
export const BUSINESS_TOTAL_DEBTS = 'BUSINESS_TOTAL_DEBTS';
export const BUSINESS_TRADING_MONTHS = 'BUSINESS_TRADING_MONTHS';
export const BUY_TO_LET_EXPERIENCE = 'BUY_TO_LET_EXPERIENCE';
export const COMMERCIAL_PROPERTY_TO_BE_DEVELOPED = 'COMMERCIAL_PROPERTY_TO_BE_DEVELOPED';
export const COMMERCIAL_RENTED = 'COMMERCIAL_RENTED';
export const COMMERCIAL_TYPE = 'COMMERCIAL_TYPE';
export const COMMERCIAL_UNITS = 'COMMERCIAL_UNITS';
export const CURRENT_LOAN_AMOUNT = 'CURRENT_LOAN_AMOUNT';
export const CURRENT_VALUE = 'CURRENT_VALUE';
export const DAY_ONE_LOAN_AMOUNT = 'DAY_ONE_LOAN_AMOUNT';
export const DEVELOPMENT_COSTS = 'DEVELOPMENT_COSTS';
export const DEVELOPMENT_DURATION = 'DEVELOPMENT_DURATION';
export const DEVELOPMENT_EXPERIENCE = 'DEVELOPMENT_EXPERIENCE';
export const DEVELOPMENT_OR_REFURBISHMENT_COSTS = 'DEVELOPMENT_OR_REFURBISHMENT_COSTS';
export const DEVELOP_PURCHASE_PRICE = 'DEVELOP_PURCHASE_PRICE';
export const END_VALUE = 'END_VALUE';
export const EXPECTED_BUILD_TIME_MONTHS = 'EXPECTED_BUILD_TIME_MONTHS';
export const EXPECTED_END_VALUE = 'EXPECTED_END_VALUE';
export const EXPECTED_RENTAL_INCOME = 'EXPECTED_RENTAL_INCOME';
export const FIRST_CHARGE_AMOUNT = 'FIRST_CHARGE_AMOUNT';
export const GDV = 'GDV';
export const HAS_EXPERIENCE_DEVELOPING = 'HAS_EXPERIENCE_DEVELOPING';
export const INITIAL_LOAN_AMOUNT_REQUIRED = 'INITIAL_LOAN_AMOUNT_REQUIRED';
export const INTEND_TO_DEVELOP_OR_REFURBISH = 'INTEND_TO_DEVELOP_OR_REFURBISH';
export const INTEND_TO_FINANCE_PORTFOLIO = 'INTEND_TO_FINANCE_PORTFOLIO';
export const IS_SPECIFIC_TENANT = 'IS_SPECIFIC_TENANT';
export const LAND_HAS_PLANNING_PERMISSION = 'LAND_HAS_PLANNING_PERMISSION';
export const LAND_HOW_OWNED = 'LAND_HOW_OWNED';
export const LAND_PURCHASE_PRICE = 'LAND_PURCHASE_PRICE';
export const LOAN_AMOUNT = 'LOAN_AMOUNT';
export const LOAN_AMOUNT_TO_COVER_BUILD_COSTS = 'LOAN_AMOUNT_TO_COVER_BUILD_COSTS';
export const LOAN_AMOUNT_TO_COVER_REFURBISHMENT_COSTS = 'LOAN_AMOUNT_TO_COVER_REFURBISHMENT_COSTS';
export const LOAN_ASSET = 'LOAN_ASSET';
export const LOAN_DURATION = 'LOAN_DURATION';
export const LOAN_LTV = 'LOAN_LTV';
export const LOAN_NEEDED_BY = 'LOAN_NEEDED_BY';
export const LOAN_NEEDED_TO_COVER_BUILD_COSTS = 'LOAN_NEEDED_TO_COVER_BUILD_COSTS';
export const LOAN_NEEDED_TO_COVER_REFURBISHMENT_COSTS = 'LOAN_NEEDED_TO_COVER_REFURBISHMENT_COSTS';
export const LOAN_PURPOSE = 'LOAN_PURPOSE';
export const LOAN_REASON = 'LOAN_REASON';
export const LOAN_TERM = 'LOAN_TERM';
export const LOAN_TYPE = 'LOAN_TYPE';
export const LOCATION = 'LOCATION';
export const LTV = 'LTV';
export const NUMBER_COMMERCIAL_UNITS = 'NUMBER_COMMERCIAL_UNITS';
export const NUMBER_RESIDENTIAL_UNITS = 'NUMBER_RESIDENTIAL_UNITS';
export const OUTSTANDING_LOAN = 'OUTSTANDING_LOAN';
export const OWNER_OCCUPIED = 'OWNER_OCCUPIED';
export const PDQ_TURNOVER_PER_MONTH = 'PDQ_TURNOVER_PER_MONTH';
export const PERCENTAGE_COMMERCIAL = 'PERCENTAGE_COMMERCIAL';
export const PERCENTAGE_RESIDENTIAL = 'PERCENTAGE_RESIDENTIAL';
export const PLANNING_DESCRIPTION = 'PLANNING_DESCRIPTION';
export const PLANNING_FOR = 'PLANNING_FOR';
export const PLANNING_IN_PLACE = 'PLANNING_IN_PLACE';
export const PORTFOLIO_ALREADY_OWNED = 'PORTFOLIO_ALREADY_OWNED';
export const PORTFOLIO_PROPERTIES = 'PORTFOLIO_PROPERTIES';
export const PORTFOLIO_VALUE = 'PORTFOLIO_VALUE';
export const POSTCODE = 'POSTCODE';
export const PREVIOUS_YEAR_TURNOVER = 'PREVIOUS_YEAR_TURNOVER';
export const PROPERTY_COUNTRY = 'PROPERTY_COUNTRY';
export const PROPERTY_EXPECTED_RENTAL_INCOME = 'PROPERTY_EXPECTED_RENTAL_INCOME';
export const PROPERTY_HOW_OWNED = 'PROPERTY_HOW_OWNED';
export const PROPERTY_INTEND_TO_OCCUPY = 'PROPERTY_INTEND_TO_OCCUPY';
export const PROPERTY_IS_ALREADY_OWNED = 'PROPERTY_IS_ALREADY_OWNED';
export const PROPERTY_LOAN_TYPE = 'PROPERTY_LOAN_TYPE';
export const PROPERTY_OWNERSHIP = 'PROPERTY_OWNERSHIP';
export const PROPERTY_PURCHASE_PRICE = 'PROPERTY_PURCHASE_PRICE';
export const PROPERTY_TO_BE_RENTED_OUT = 'PROPERTY_TO_BE_RENTED_OUT';
export const PROPERTY_TYPE = 'PROPERTY_TYPE';
export const PROPERTY_WAS_OCCUPIED = 'PROPERTY_WAS_OCCUPIED';
export const REFINANCE = 'REFINANCE';
export const REFURBISHMENT_COSTS = 'REFURBISHMENT_COSTS';
export const REFURBISHMENT_NOT_REQUIRE_PLANNING = 'REFURBISHMENT_NOT_REQUIRE_PLANNING';
export const REGISTER_USER_AGE_POINT_IN_TIME = 'REGISTER_USER_AGE_POINT_IN_TIME';
export const RENTAL_INCOME_COMMERCIAL = 'RENTAL_INCOME_COMMERCIAL';
export const RENTAL_INCOME_RESIDENTIAL = 'RENTAL_INCOME_RESIDENTIAL';
export const REQUIRES_PLANNING_PERMISSION = 'REQUIRES_PLANNING_PERMISSION';
export const RESIDENTIAL_UNITS = 'RESIDENTIAL_UNITS';
export const RESIDENTIAL_UNITS_MONTHLY_INCOME = 'RESIDENTIAL_UNITS_MONTHLY_INCOME';
export const RESIDENTIAL_UNITS_VALUE = 'RESIDENTIAL_UNITS_VALUE';
export const TOTAL_REFURBISHMENT_COST = 'TOTAL_REFURBISHMENT_COST';
export const SHAREHOLDER_IS_HOMEOWNER = 'SHAREHOLDER_IS_HOMEOWNER';
export const TENANCY_TERM = 'TENANCY_TERM';
export const TOTAL_FUNDING_REQUIRED = 'TOTAL_FUNDING_REQUIRED';
export const TYPE_OF_COMMERCIAL = 'TYPE_OF_COMMERCIAL';
export const TYPE_OF_TENANT = 'TYPE_OF_TENANT';
export const UK_CITIZEN = 'UK_CITIZEN';
export const UNIT_PRE_SOLD = 'UNIT_PRE_SOLD';
export const INVOICE_FINANCE_PRODUCTS = 'INVOICE_FINANCE_PRODUCTS';
export const PERCENTAGE_FLOOR_SPACE_COMMERCIAL = 'PERCENTAGE_FLOOR_SPACE_COMMERCIAL';

class MetaKeyEnum extends BaseEnum {
    getTypes = () => {
        return {
            [ADDITIONAL_INFO_NOTES]: 'Additional information',
            [ADDRESS]: 'The address of the property',
            [ADVERSE_CREDIT]: 'Adverse Credit',
            [ANNUAL_RENT]: 'Annual rent amount',
            [BEDROOMS]: 'Amount of bedrooms',
            [BORROWER_CCJ_HOW_MUCH]: 'How many CCJs?',
            [BORROWER_CCJ_SATISFIED]: 'Are the CCJs satisfied?',
            [BORROWER_CCJ_WHEN]: 'When the CCJs occurred?',
            [BORROWER_CCJ_WHY]: 'Why the CCJs occurred?',
            [BORROWER_COUNTRY_OF_RESIDENCE]: 'Country of residence',
            [BORROWER_DEFAULTED_HOW_MUCH]: 'Default amount',
            [BORROWER_DEFAULTED_UP_TO_DATE]: 'Are the defaults up to date?',
            [BORROWER_DEFAULTED_WHEN]: 'When did the default occur?',
            [BORROWER_DEFAULTED_WHY]: 'Why did the default occur?',
            [BORROWER_EMPLOYMENT_STATUS]: 'Employment status',
            [BORROWER_GROWTH_AND_INCOME]: 'Growth and income',
            [BORROWER_HAS_ADVERSE_CREDIT]: 'Adverse Credit',
            [BORROWER_HAS_CCJS]: 'Does the borrower have a CCJ?',
            [BORROWER_HAS_DEFAULTED]: 'Has the borrower defaulted?',
            [BORROWER_IS_UK_CITIZEN]: 'Is the borrower a UK citizen?',
            [BORROWER_NATIONALITY]: 'Borrower Nationalities?',
            [BORROWER_OCCUPATION]: 'Borrower Occupations?',
            [BORROWER_OWN_OTHER_PROPERTIES]: 'Does the borrower own any other properties?',
            [BORROWER_RIGHT_TO_RESIDE]: 'Does the borrower have right to reside in the UK?',
            [BUILD_COST]: 'Build Costs',
            [BUILD_COSTS]: 'Build Costs',
            [BUSINESS_ADDITIONAL_SECURITY]: 'Does the business have additional security?',
            [BUSINESS_ADVERSE_CREDIT_DETAILS]: 'Details about the businesses adverse credit',
            [BUSINESS_HAD_ADVERSE_CREDIT]:
                'Has the business had adverse credit or does it currently have adverse credit?',
            [BUSINESS_INTEND_TO_OCCUPY]: 'Does the business intend to occupy?',
            [BUSINESS_LOAN_TERM]: 'What loan lengths will you lend on?',
            [BUSINESS_NAME]: 'What is the name of the business?',
            [BUSINESS_NON_REFINANCE_ASSET]: 'Which asset finance products do you offer?',
            [BUSINESS_OUTSTANDING_INVOICE]:
                'Is this business stream intended for businesses with outstanding invoices?',
            [BUSINESS_OUTSTANDING_INVOICE_AMOUNT]: 'What is the minimum debtor book value you would lend on? (£)',
            [BUSINESS_PROFITABLE]: 'Do you require the business to be profitable?',
            [BUSINESS_SECTOR]: 'Which business sectors will you lend on?',
            [BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT]:
                'Will you lend if the directors or shareholders have had Credit Difficulties?',
            [BUSINESS_SHAREHOLDER_OWNER_ADVERSE_CREDIT_EXPLANATION]: 'Explanation of shareholders adverse credit',
            [BUSINESS_TOTAL_DEBTS]: 'What are your business debt lending parameters?',
            [BUSINESS_TRADING_MONTHS]: 'What minimum trading history is required? (months)',
            [BUY_TO_LET_EXPERIENCE]: 'Will you lend to borrowers without buy to let experience?',
            [COMMERCIAL_RENTED]: 'Will you lend on commercial units that are not currently being rented out?',
            [COMMERCIAL_TYPE]: 'What types of commercial units will you lend on?',
            [COMMERCIAL_UNITS]: 'What are the maximum amount of commercial units allowed per property?',
            [CURRENT_LOAN_AMOUNT]: 'Existing loan amount',
            [CURRENT_VALUE]: 'What are the Property Values (£) you can lend on?',
            [DAY_ONE_LOAN_AMOUNT]: 'What is the day one loan amount you will lend on? (%)',
            [DEVELOPMENT_COSTS]: 'What are the Development Costs (%) you are willing to lend on?',
            [DEVELOPMENT_DURATION]: 'What is the refurbishment duration you will lend on?',
            [DEVELOPMENT_EXPERIENCE]: 'Do you require that the borrower have any previous development experience?',
            [DEVELOPMENT_OR_REFURBISHMENT_COSTS]: 'Development/refurbishment costs',
            [DEVELOP_PURCHASE_PRICE]: 'Development purchase price or valuation',
            [END_VALUE]: 'Expected end value of the property',
            [EXPECTED_BUILD_TIME_MONTHS]: 'Expected build time (months)',
            [EXPECTED_END_VALUE]: 'What are your Gross Development Cost (£) parameters?',
            [EXPECTED_RENTAL_INCOME]:
                'What [highlight|Rental Income (£)] should the residential properties generate per month?',
            [FIRST_CHARGE_AMOUNT]: 'First Charge Amount',
            [GDV]: 'Gross Development Value',
            [HAS_EXPERIENCE_DEVELOPING]: 'What Levels of Experience do the Developers need to have?',
            [INITIAL_LOAN_AMOUNT_REQUIRED]: 'What are your Day 1 Loan Amount (£) parameters?',
            [INTEND_TO_DEVELOP_OR_REFURBISH]: 'Will you lend on Property Development?',
            [INTEND_TO_FINANCE_PORTFOLIO]: 'Intend to finance a portfolio?',
            [IS_SPECIFIC_TENANT]: 'Is there a specific tenant?',
            [LAND_HAS_PLANNING_PERMISSION]: 'Do you require Planning Permission to be in place?',
            [LAND_HOW_OWNED]: 'How will the land be owned?',
            [LAND_PURCHASE_PRICE]: 'Land Purchase Price (£)',
            [LOAN_AMOUNT]: 'What are your Loan Amount (£) lending parameters?',
            [LOAN_ASSET]: '?',
            [LOAN_DURATION]: 'What is the Duration of your loan term(s)?',
            [LOAN_LTV]: 'What are your total LTV (%) lending parameters?',
            [LOAN_NEEDED_BY]: 'What is your turnaround time from application to completion?',
            [LOAN_NEEDED_TO_COVER_BUILD_COSTS]: 'Loan needed to cover build costs?',
            [LOAN_NEEDED_TO_COVER_REFURBISHMENT_COSTS]: 'Loan needed to cover refurbishment costs?',
            [LOAN_PURPOSE]: 'Which Loan Purposes will you lend on?',
            [LOAN_REASON]: 'What reasons will you lend on?',
            [LOAN_TERM]: 'What are your minimum and maximum loan terms (months)?',
            [LOAN_TYPE]: 'What Type of Loan is this Business Stream for?',
            [LOCATION]: 'Which Countries are you happy to lend in?',
            [LTV]: 'What are your total LTV (%) lending parameters?',
            [NUMBER_COMMERCIAL_UNITS]: 'What are the maximum amount of commercial units allowed per property?',
            [NUMBER_RESIDENTIAL_UNITS]: 'What are the maximum amount of residential units allowed per property?',
            [OUTSTANDING_LOAN]: 'What is your maximum LTV (%) for Portfolio mortgage products?',
            [OWNER_OCCUPIED]: 'Will you lend on properties where the commercial element is owner occupied?',
            [PDQ_TURNOVER_PER_MONTH]: 'What is your monthly payment processor revenue (£)?',
            [PERCENTAGE_COMMERCIAL]: 'How much of the property is commercial?',
            [PERCENTAGE_RESIDENTIAL]: 'How much of the property is residental?',
            [PLANNING_DESCRIPTION]: 'Planning Description',
            [PLANNING_FOR]: 'What is the planning for?',
            [PLANNING_IN_PLACE]: 'Do you require planning to be in place?',
            [PORTFOLIO_ALREADY_OWNED]: 'Will you lend on a New Purchase or a Refinance or Both?',
            [PORTFOLIO_PROPERTIES]: 'What is the number of Properties you can lend on?',
            [PORTFOLIO_VALUE]: 'What is the total portfolio value (£) you can lend on?',
            [POSTCODE]: 'Postcode',
            [PREVIOUS_YEAR_TURNOVER]: 'What is the minimum annual turnover required? (£)',
            [PROPERTY_COUNTRY]: 'Which Countries are you happy to lend in?',
            [PROPERTY_EXPECTED_RENTAL_INCOME]:
                'What [highlight|Rental Income (£)] should the residential properties generate per month?',
            [PROPERTY_HOW_OWNED]: 'How can the property be Owned?',
            [PROPERTY_INTEND_TO_OCCUPY]: 'Intend to occupy?',
            [PROPERTY_IS_ALREADY_OWNED]: 'Will you lend on a New Purchase or a Refinance or Both?',
            [PROPERTY_LOAN_TYPE]: 'Loan Type',
            [PROPERTY_OWNERSHIP]: 'Which ownership structures will you allow?',
            [PROPERTY_PURCHASE_PRICE]: 'What are the Property Values (£) you can lend on?',
            [PROPERTY_TO_BE_RENTED_OUT]: 'Will you lend on Rented or Owned Properties or Both?',
            [PROPERTY_TYPE]: 'What type of portfolio mix is acceptable?',
            [PROPERTY_WAS_OCCUPIED]: 'Was the property occupied?',
            [REFINANCE]: 'Will you lend on a New Purchase or a Refinance or Both?',
            [REFURBISHMENT_COSTS]: 'Refurbishment Costs',
            [REFURBISHMENT_NOT_REQUIRE_PLANNING]: 'Will you lend on Refurbishments?',
            [REGISTER_USER_AGE_POINT_IN_TIME]: 'What are your Borrower Age Ranges?',
            [RENTAL_INCOME_COMMERCIAL]: 'Commercial Rental Income',
            [RENTAL_INCOME_RESIDENTIAL]: 'Residential Rental Income',
            [REQUIRES_PLANNING_PERMISSION]: 'Will you lend if Additional Planning is required, but not yet obtained?',
            [RESIDENTIAL_UNITS]:
                "What is the maximum number of residential units (MUFB) that you will lend on? (If you don't lend on MUFB then input 1)?",
            [RESIDENTIAL_UNITS_MONTHLY_INCOME]:
                'What is the required Monthly Income (£)] from these residential units?',
            [RESIDENTIAL_UNITS_VALUE]: 'The maximum value of the residential units',
            [SHAREHOLDER_IS_HOMEOWNER]: 'Do you require shareholders/directors to be UK homeowners?',
            [TENANCY_TERM]: 'Tenancy Term',
            [TOTAL_FUNDING_REQUIRED]: 'Total amount of funding you',
            [TYPE_OF_COMMERCIAL]: 'Which Types of Commercial Units will you lend on?',
            [TYPE_OF_TENANT]: 'Type of tenant',
            [UK_CITIZEN]: 'Will you lend to UK and Non-UK Citizens?',
            [UNIT_PRE_SOLD]: 'Do you require that the borrower have any of the end units pre-sold or pre-let?',
            [INVOICE_FINANCE_PRODUCTS]: 'Which invoice finance products do you offer?',
            [PERCENTAGE_FLOOR_SPACE_COMMERCIAL]: 'How much of the floor space (%) is commercial?',
            [TOTAL_REFURBISHMENT_COST]: 'How much of the Total Refurbishment Costs (%) can you fund?',
        };
    };
}

export default MetaKeyEnum;
