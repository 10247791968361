import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import StatusEnum, {
    STATUS_MATCHED,
    STATUS_IN_PROGRESS,
    STATUS_APPLICATION,
    STATUS_LEGALS,
    STATUS_VALUATION,
    STATUS_COMPLETION_REQUESTED,
    STATUS_COMPLETE,
} from '../../enum/LenderLoanEnquiries/StatusEnum';
import LenderFavourite from './LenderFavourite';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const statusEnum = new StatusEnum();
const statusStepsOrder = [
    STATUS_MATCHED,
    STATUS_IN_PROGRESS,
    STATUS_APPLICATION,
    STATUS_LEGALS,
    STATUS_VALUATION,
    STATUS_COMPLETION_REQUESTED,
    STATUS_COMPLETE,
];

class LenderLoanEnquiryStatusWidget extends React.Component {
    render() {
        const { lenderLoanEnquiryStatus, lenderLoanEnquiry } = this.props;
        let labelClass = 'inactive';
        const activeStatusIndex = Object.keys(statusStepsOrder).find(
            (key) => statusStepsOrder[key] === lenderLoanEnquiryStatus
        );

        return (
            <div className={'progress-bar-container'}>
                <div className={'progress-container'}>
                    <span style={{ whiteSpace: 'nowrap', marginRight: 10 }}>Status:</span>
                    {statusStepsOrder.map((status, index) => {
                        if (status === lenderLoanEnquiryStatus) {
                            labelClass = 'active';
                        }

                        if (index > activeStatusIndex) {
                            labelClass = 'inactive';
                        }

                        return (
                            <div className={'status-container'}>
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    <div className={`status-label ${labelClass}`}>
                                        <FiberManualRecordIcon style={{ marginRight: 5 }} />
                                        {index + 1}. {statusEnum.getType(status)}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className={'favourite-container'}>
                    <LenderFavourite
                        lenderLoanEnquiryId={lenderLoanEnquiry.id}
                        favourite={lenderLoanEnquiry.favourite}
                    />
                </div>
            </div>
        );
    }
}

LenderLoanEnquiryStatusWidget.propTypes = {
    lenderLoanEnquiryStatus: PropTypes.object.isRequired,
};

export default compose()(LenderLoanEnquiryStatusWidget);
