import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from '../../../../pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { ROLE_BORROWER } from '../../../authentication/enum/Roles/NameEnum';
import withSession from '../../../../pitchblack_react_utils/session/withSession';
import { BORROWER_URL } from '../../../../bootstrap/constants/constants';
import { QueryMutation } from '../../../../pitch4_forms/components';
import Mapper from '../../../../pitch4_elements/applicant/FormMapper';
import EditForm from '../form/EditForm';

class EditContainer extends React.Component {
    mapValues = (data) => {
        const mapper = new Mapper();

        let mappedValues = {};

        if (data && data.applicant) {
            mappedValues = mapper.mapToForm(data.applicant);
        }

        return mappedValues;
    };

    render() {
        let { applicantId } = this.props;

        return (
            <QueryMutation
                query={gql`
                    query getApplicant($id: ID!) {
                        applicant(id: $id) {
                            id
                            given
                            family
                            dateOfBirth
                            email
                            address {
                                address1
                                address2
                                address3
                                address4
                                postcode
                                country
                            }
                            createdAt
                            updatedAt
                        }
                    }
                `}
                queryContext={{
                    uri: BORROWER_URL,
                }}
                queryVariables={{
                    id: applicantId,
                }}
                mapQueryFunction={this.mapValues}
                mutationQuery={gql`
                    mutation EditApplicant($id: ID!, $input: borrower_editApplicantInput!) {
                        editApplicant(id: $id, input: $input) {
                            applicant {
                                id
                                given
                                family
                                dateOfBirth
                                email
                                address {
                                    address1
                                    address2
                                    address3
                                    address4
                                    postcode
                                    country
                                }
                                createdAt
                                updatedAt
                            }
                        }
                    }
                `}
                renderFormFunction={(mappedValues, mutate, refetch, props) => (
                    <EditForm initialValues={mappedValues} onSubmit={this.onSubmit(mutate, props)} />
                )}
            />
        );
    }

    onSubmit = (mutate, props) => (values) => {
        const { inProgress, onSuccess, onError, applicantId } = this.props;
        const { notificationSuccess, notificationError } = props;

        const mapper = new Mapper();
        let mappedValues = mapper.mapFromForm(values);

        inProgress();

        return mutate({
            variables: {
                id: applicantId,
                input: {
                    ...mappedValues,
                },
            },
            context: {
                uri: BORROWER_URL,
            },
        })
            .then(({ data }) => {
                notificationSuccess('Applicant edited');
                onSuccess(data);
            })
            .catch((error) => {
                notificationError('Unable to edit applicant. Please contact support.');
                onError(error);
            });
    };
}

EditContainer.propTypes = {
    applicantId: PropTypes.string.isRequired,
};

export default compose(withSession, renderErrorIfAuthorisationFail([ROLE_BORROWER]))(EditContainer);
