import Warning from './Warning';
import Error from './Error';
import ValidationResults from './ValidationResults';

/**
 * This class is the factory class for errors and warnings when validating field_validation
 */
class ValidationResultFactory {
    createValidationResults = () => {
        let out = new ValidationResults();

        return out;
    };

    createError = (message, data = []) => {
        let out = new Error();

        out.setData(data);
        out.setMessage(message);
        out.setType('ERROR');

        return out;
    };

    createConstraintsError = ({ message, data = [], constraints = [] }) => {
        let out = new Error();

        out.setData(data);
        out.setMessage(message);
        out.setConstraints(constraints);
        out.setType('ERROR');

        return out;
    };

    createWarning = (message) => {
        let out = new Warning();

        out.setMessage(message);
        out.setType('WARNING');

        return out;
    };
}

export default ValidationResultFactory;
