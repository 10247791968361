import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import gql from 'graphql-tag';
import renderErrorIfAuthorisationFail from 'pitchblack_react_utils/session/renderErrorIfAuthorisationFail';
import { goBack, push } from 'react-router-redux';

import { Filter, FiltersContainer, RollupCommandContainer, Sort, SortsContainer } from 'pitch4_tabular/components';

import { GenericTableContainer } from 'pitch4_tabular/containers';
import { TableCell, TableRow, TableRowControls } from 'pitch4_layout/components/tables';
import {
    selectorGetCurrentCursor,
    selectorGetFilters,
    selectorGetPages,
    selectorGetPerPage,
    selectorGetSorts,
} from 'pitch4_tabular/redux/index_ns';

import { onFilterChange, onSortChange, onSortClear } from 'pitch4_tabular/utils';

import { ILIKE } from 'pitch4_tabular/enum/OperationEnum';
import { SORT_DESC } from 'pitch4_tabular/enum/SortDirectionEnum';

import { EnumLabel } from 'pitch4_layout/components/data';

import { NAMESPACE } from '../redux';

import { ROLE_SLAM } from '../../../../authentication/enum/Roles/NameEnum';
import { SLAM_URL } from '../../../../../bootstrap/constants/constants';

import StatusEnum from '../../enum/Users/StatusEnum';
import DisassociateUserButton from '../components/DisassociateUserButton';
import withSession from 'pitchblack_react_utils/session/withSession';
import { actionSetJoyrideType } from '../../../../joyride/redux';

class TableStyleContainer extends React.Component {
    componentDidMount() {
        const { setJoyrideType } = this.props;
        setJoyrideType(null);
    }

    renderRow = ({ node, cursor }, refetch) => {
        let { session } = this.props;

        return (
            <TableRow key={node['id']}>
                <TableCell>{node.email}</TableCell>
                <TableCell>{node.userProfile.given}</TableCell>
                <TableCell>{node.userProfile.family}</TableCell>
                <TableCell>
                    <EnumLabel enumIn={new StatusEnum()} value={node['status']} />
                </TableCell>
                <TableRowControls xs={2}>
                    <RollupCommandContainer>
                        <DisassociateUserButton
                            outerRefetch={refetch}
                            id={node.id}
                            disabled={node.id === session.getRelevantSession().getId()}
                        >
                            Delete
                        </DisassociateUserButton>
                    </RollupCommandContainer>
                </TableRowControls>
            </TableRow>
        );
    };

    renderHeader = () => (
        <TableRow>
            <TableCell>Email</TableCell>
            <TableCell>Given</TableCell>
            <TableCell>Family</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
        </TableRow>
    );

    render() {
        //put code to map filters to props here. If you need multiple props - escalate at stand up.
        let { match, sorts, pages, filters = [], first, cursor, dispatch } = this.props;

        let emailFilter = filters.filter(({ column }) => {
            return column === 'email';
        });
        emailFilter =
            emailFilter.length > 0
                ? emailFilter[0]
                : {
                      column: 'email',
                      operation: ILIKE,
                      operand: '',
                      not: false,
                  };

        let currentSort =
            sorts.length > 0
                ? sorts[0]
                : {
                      column: 'none',
                      direction: SORT_DESC,
                  };

        const QUERY = gql`
            query getUsers($cursor: String, $first: Int!, $sorts: [SortInput], $filters: [FilterInput]) {
                users(first: $first, after: $cursor, sorts: $sorts, filters: $filters) {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        cursor
                        node {
                            id
                            displayNameTemplates
                            username
                            email
                            createdAt
                            updatedAt
                            status
                            userProfile {
                                id
                                title
                                given
                                family
                                createdAt
                                updatedAt
                            }
                        }
                    }
                    totalCount
                }
            }
        `;

        //render
        return (
            <div>
                <GenericTableContainer
                    title={`Users`}
                    description={`Manage users attached to your lender.`}
                    context={{
                        uri: SLAM_URL,
                    }}
                    query={QUERY}
                    extractResultDataFunction={({ users = {} }) => {
                        if (!users || !users.edges) {
                            return [];
                        }
                        return [...users.edges];
                    }}
                    extractPaginationDataFunction={({ users = {} }) => {
                        if (!users || !users.edges) {
                            return {};
                        }

                        return {
                            pageInfo: { ...users.pageInfo },
                            totalCount: users.totalCount,
                        };
                    }}
                    renderHeaderFunction={this.renderHeader}
                    renderRowFunction={this.renderRow}
                    filters={
                        <FiltersContainer>
                            <Filter
                                id={`email-filter`}
                                label={`Email filter`}
                                filterValues={emailFilter}
                                onFilterChange={onFilterChange(NAMESPACE, dispatch, filters, match)}
                            />
                        </FiltersContainer>
                    }
                    sorts={
                        <SortsContainer>
                            <Sort
                                column={currentSort.column}
                                direction={currentSort.direction}
                                onChange={onSortChange(NAMESPACE, dispatch, match)}
                                onClear={onSortClear(NAMESPACE, dispatch, match)}
                                options={[
                                    { value: 'email', label: 'Email' },
                                    { value: 'status', label: 'Status' },
                                    { value: 'family', label: 'Family' },
                                ]}
                            />
                        </SortsContainer>
                    }
                    onPaginationChange={this.handleChangePage}
                    onRowChange={this.handleChangeRowsPerPage}
                    variables={{
                        sorts,
                        filters,
                        first,
                        pages,
                        cursor,
                    }}
                />
            </div>
        );
    }
}

TableStyleContainer.propTypes = {
    sorts: PropTypes.array.isRequired,
    filters: PropTypes.array.isRequired,
    pages: PropTypes.array.isRequired,
    cursor: PropTypes.any,
    first: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
    return {
        sorts: selectorGetSorts(NAMESPACE)(state),
        pages: selectorGetPages(NAMESPACE)(state),
        cursor: selectorGetCurrentCursor(NAMESPACE)(state),
        filters: selectorGetFilters(NAMESPACE)(state),
        first: selectorGetPerPage(NAMESPACE)(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
        redirect: (url) => () => dispatch(push(url)),
        goBack: () => dispatch(goBack()),
        setJoyrideType: (type) => dispatch(actionSetJoyrideType(type)),
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withSession,
    renderErrorIfAuthorisationFail([ROLE_SLAM])
)(TableStyleContainer);
