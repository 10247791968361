import ls from 'local-storage';
import Session from './Session';
import DateTimeFactory from '../../pitchblack_react_utils/datetime/DateTimeFactory';
import _ from 'lodash';

const dateTimeFactory = new DateTimeFactory();

class SessionManager {
    sdPropertyForceTwoFactorAuth = 'force_two_factor_auth';
    sdPropertyVerified = 'verified';
    sdSmsVerificationRequested = 'sms_verification_requested';
    sdAlertsActive = 'alertActive';
    sdPropertyMissingRequiredFields = 'has_missing_required_fields';
    sdPropertyMissingRequiredFieldsList = 'missing_required_fields_list';
    sdPropertyHasSeenMissingRequiredFieldsAlert = 'has_seen_missing_required_fields_alert';

    login = (token, session) => {
        ls.set('token', token);
        ls.set('session', session);
    };

    assumedIdentity = (token, session) => {
        ls.set('assumedToken', token);
        ls.set('assumedSession', session);
    };

    deleteAssumedIdentity = () => {
        ls.remove('assumedToken');
        ls.remove('assumedSession');
    };

    isTwoFactorAuthForced = () => {
        const supplementaryData = this.getSession().getSupplementaryData();

        if (false === _.isEmpty(supplementaryData) && supplementaryData[this.sdPropertyForceTwoFactorAuth] === true) {
            return true;
        }

        return false;
    };

    getMostSeniorRole = () => this.getSession().getMostSeniorRole();

    removeForceTwoFactorAuthFlag = () => {
        const sessionData = ls('session');
        const supplementaryData = sessionData['supplementaryData'];

        if (_.isEmpty(supplementaryData) || _.isUndefined(supplementaryData[this.sdPropertyForceTwoFactorAuth])) {
            return;
        }

        delete supplementaryData[this.sdPropertyForceTwoFactorAuth];

        ls('session', sessionData);
    };

    setIsVerified = (isVerified) => {
        const sessionData = ls('session');
        const supplementaryData = sessionData['supplementaryData'];

        if (_.isEmpty(supplementaryData) || _.isUndefined(supplementaryData[this.sdPropertyVerified])) {
            return;
        }

        supplementaryData[this.sdPropertyVerified] = isVerified;

        ls('session', sessionData);
    };

    setSmsVerificationRequested = (isVerified) => {
        const sessionData = ls('session');
        const supplementaryData = sessionData['supplementaryData'];

        if (_.isEmpty(supplementaryData) || _.isUndefined(supplementaryData[this.sdSmsVerificationRequested])) {
            return;
        }

        supplementaryData[this.sdSmsVerificationRequested] = isVerified;

        ls('session', sessionData);
    };

    setPrimaryPhone = (phone) => {
        const sessionData = ls('session');
        sessionData.primaryPhone = { number: phone };
        ls('session', sessionData);
    };

    setAlertsActive = (value) => {
        const sessionData = ls('session');
        const supplementaryData = sessionData['supplementaryData'];

        if (_.isEmpty(supplementaryData) || _.isUndefined(supplementaryData[this.sdAlertsActive])) {
            return;
        }

        supplementaryData[this.sdAlertsActive] = value;
        ls('session', sessionData);
    };

    getAlertsActive = () => {
        const sessionData = ls('session');

        if (sessionData === null || sessionData === undefined || !('supplementaryData' in sessionData)) {
            return false;
        }

        const supplementaryData = sessionData['supplementaryData'];

        if (_.isEmpty(supplementaryData) || _.isUndefined(supplementaryData[this.sdAlertsActive])) {
            return false;
        }

        return supplementaryData[this.sdAlertsActive];
    };

    getCompletedJoyrides = () => {
        const sessionData = ls('session');
        const supplementaryData = sessionData['supplementaryData'];

        return typeof supplementaryData['joyrideCompleted'] === 'undefined'
            ? {}
            : supplementaryData['joyrideCompleted'];
    };

    setJoyrideAsComplete = (joyrideName) => {
        const sessionData = ls('session');
        const supplementaryData = sessionData['supplementaryData'];
        supplementaryData['joyrideCompleted'][joyrideName] = true;
        ls('session', sessionData);
    };

    logout = () => {
        ls.clear();
    };

    isAuthorised = (requiredRoles = []) => {
        let out = false;

        if (this.isAuthenticated()) {
            let session = this.getSession();

            out = requiredRoles.some((item) => {
                return session.getRoleNames().some((role) => {
                    return item === role;
                });
            });

            if (out === false && this.hasAssumedToken()) {
                const assumedSession = this.getAssumedSession();
                out = requiredRoles.some((item) => {
                    return assumedSession.getRoleNames().some((role) => {
                        return item === role;
                    });
                });
            }
        }

        return out;
    };

    getRelevantSession = () => {
        if (this.hasAssumedToken()) {
            return this.getAssumedSession();
        }

        return this.getSession();
    };

    isAuthenticated = () => {
        let token = this.getToken();
        let assumedToken = this.getAssumedToken();
        let out = false;

        if (token) {
            out = this.isExpired(token);

            if (out === false && this.hasAssumedToken()) {
                out = this.isExpired(assumedToken);
            }
        }

        return out;
    };

    hasAssumedToken = () => {
        return this.getAssumedToken() !== undefined && this.getAssumedToken() !== null;
    };

    isExpired = (token) => {
        let expiry = dateTimeFactory.create(token.expires);
        return expiry.hasElapsed();
    };

    getToken = () => ls('token');

    getSession = () => {
        return new Session(ls('session'));
    };

    getAssumedToken = () => ls('assumedToken');

    getAssumedSession = () => {
        return new Session(ls('assumedSession'));
    };

    setPrivacyPolicyAccepted = (value) => {
        ls('p4_privacy_accepted', value);
    };

    getPrivacyPolicyAccepted = () => {
        return ls('p4_privacy_accepted');
    };

    hasMissingRequiredFields = () => {
        const supplementaryData = this.getSession().getSupplementaryData();

        if (typeof supplementaryData[this.sdPropertyMissingRequiredFields] === 'undefined') {
            return false;
        }

        return supplementaryData[this.sdPropertyMissingRequiredFields];
    };

    setHasMissingRequiredFields = (hasMissingRequiredFields) => {
        const sessionData = ls('session');
        const supplementaryData = sessionData['supplementaryData'];

        if (typeof supplementaryData[this.sdPropertyMissingRequiredFields] === 'undefined') {
            return;
        }

        supplementaryData[this.sdPropertyMissingRequiredFields] = hasMissingRequiredFields;
        ls('session', sessionData);
    };

    getMissingRequiredFieldsList = () => {
        const supplementaryData = this.getSession().getSupplementaryData();

        if (typeof supplementaryData[this.sdPropertyMissingRequiredFieldsList] === 'undefined') {
            return [];
        }

        return supplementaryData[this.sdPropertyMissingRequiredFieldsList];
    };

    setMissingRequiredFieldsList = (missingRequiredFieldsList) => {
        const sessionData = ls('session');
        const supplementaryData = sessionData['supplementaryData'];

        if (typeof supplementaryData[this.sdPropertyMissingRequiredFieldsList] === 'undefined') {
            return;
        }

        supplementaryData[this.sdPropertyMissingRequiredFieldsList] = missingRequiredFieldsList;
        ls('session', sessionData);
    };

    setHasSeenMissingRequiredFieldsJoyride = (hasSeen) => {
        const sessionData = ls('session');
        const supplementaryData = sessionData['supplementaryData'];
        supplementaryData[this.sdPropertyHasSeenMissingRequiredFieldsAlert] = hasSeen;
        ls('session', sessionData);
    };

    displayMissingRequiredFieldsJoyride = () => {
        const sessionData = ls('session');
        const supplementaryData = sessionData['supplementaryData'];
        let hasSeenMissingRequiredFieldsAlert = supplementaryData[this.sdPropertyHasSeenMissingRequiredFieldsAlert];

        if (typeof hasSeenMissingRequiredFieldsAlert === 'undefined') {
            hasSeenMissingRequiredFieldsAlert = false;
        }

        return hasSeenMissingRequiredFieldsAlert === false && this.hasMissingRequiredFields() === true;
    };
}

export default SessionManager;
