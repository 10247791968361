import React from 'react';
import { branch, renderComponent } from 'recompose';

import styled from 'styled-components';

import SnackbarContent from '@material-ui/core/SnackbarContent';
import Paper from '@material-ui/core/Paper';

import IconButton from '@material-ui/core/IconButton';
import ErrorIcon from '@material-ui/icons/Error';

import { CenteringContainer } from 'pitchblack_react_utils/layout/index';

const StyledErrorComponent = styled(CenteringContainer)`
    padding: 20px;
`;

export class ErrorComponent extends React.Component {
    handleError = (error, index) => {
        return (
            <div>
                <IconButton>
                    <ErrorIcon />
                </IconButton>
                <SnackbarContent key={index} message={`Error: ${error.message}`} />
            </div>
        );
    };

    render() {
        let { initialFetchErrors } = this.props;

        return (
            <Paper>
                <StyledErrorComponent>
                    {initialFetchErrors.map((error, index) => this.handleError(error, index))}
                </StyledErrorComponent>
            </Paper>
        );
    }
}

//function
export default (component) =>
    branch(
        (props) => props['initialFetchErrors'] && props['initialFetchErrors'].length > 0,
        renderComponent(ErrorComponent)
    );
