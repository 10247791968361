import React from 'react';
import PropTypes from 'prop-types';
import Input from '@material-ui/core/Input';

class EditFileNameInput extends React.Component {
    componentDidMount() {
        let { document, disabled = false, readOnly = false } = this.props;
        this.setState({
            value: document.fileName,
            initialValue: document.fileName,
            disabled,
            readOnly,
        });
    }

    onChange(value) {
        this.setState({ value });
    }

    onKeyPress(key, value) {
        if (key !== 'Enter') {
            return;
        }

        this.submit(value);
    }

    submit = (value) => {
        let { mutate, document, successNotification, errorNotification } = this.props;

        if (!value || this.state.initialValue === value) {
            return;
        }

        this.setState({ disabled: true });

        //map the variables if necessary
        return mutate({
            variables: {
                id: document.id,
                input: {
                    fileName: value,
                },
            },
        })
            .then(() => {
                successNotification('File name modified');
                this.setState({ initialValue: value, disabled: false });
            })
            .catch((error) => {
                errorNotification('Update file name failed');
                this.setState({ disabled: false });
            });
    };

    render() {
        const { value, disabled, readOnly } = this.state;

        return (
            <Input
                component="input"
                type="text"
                name="fileName"
                placeholder="File name"
                required={true}
                onBlur={(e) => this.submit(e.target.value)}
                value={value}
                onChange={(e) => this.onChange(e.target.value)}
                onKeyPress={(e) => this.onKeyPress(e.key, e.target.value)}
                disabled={disabled}
                readOnly={readOnly}
            />
        );
    }
}

EditFileNameInput.propTypes = {
    document: PropTypes.object.isRequired,
    successNotification: PropTypes.func,
    errorNotification: PropTypes.func,
    mutate: PropTypes.func,
    readOnly: PropTypes.bool,
    disabled: PropTypes.bool,
};

export default EditFileNameInput;
