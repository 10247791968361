import React from 'react';

import { default as MuiRadioGroup } from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { default as MuiRadio } from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import { Field } from 'redux-form';
import FormLabel from '@material-ui/core/FormLabel';

export function RadioLabel({ children, className = 'radio-group-label', ...props }) {
    return (
        <FormLabel component="legend" className={className} {...props}>
            {children}
        </FormLabel>
    );
}

const Radios = ({ input, label, options, ...rest }) => {
    return (
        <div className="radio">
            <FormControl>
                {label}
                <MuiRadioGroup className={'input-right'} {...input}>
                    {options.map(({ value, label }) => {
                        return <FormControlLabel value={value} control={<MuiRadio />} label={label} />;
                    })}
                </MuiRadioGroup>
            </FormControl>
        </div>
    );
};

const RadioGroup = ({ name, label, value, options, onChangeFunc, className }) => {
    return (
        <Field onChange={onChangeFunc} name={name} component={Radios} label={label} options={options}>
            {options.map(({ value, label }) => {
                return <MuiRadio className={className} value={value} label={label} />;
            })}
        </Field>
    );
};

export default RadioGroup;
