import React from 'react';
import { Container, Item, RowItem } from 'pitch4_layout/components/div';
import PropTypes from 'prop-types';

import OperationSelect from './OperationSelect';
import { isRequired } from 'pitchblack_react_utils/form/validation';
import { OPERATION_BETWEEN } from 'pitch4_enum/enum/OperationEnum';
import { isNumericType } from 'pitch4_layout/components/data/FactValue';
import _ from 'lodash';
import InputField from '../../../../form_wizard/components/InputField';

import { FIELD_TYPE_CURRENCY } from '../../../../form_wizard/constants';
import { isEmpty } from 'pitch4_validation';
import {
    getConfigDependantFields,
    getConfigDependencies,
    getConfigFieldIsMulti,
    getConfigFieldIsRequired,
    getConfigFieldType,
} from '../../../../form_wizard/utils/configFieldLookup';
import LoanAssetPurposeEnum, { TYPE_NONE } from '../../enum/BusinessStreams/LoanAssetPurposeEnum';

export class FieldRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            between: false,
        };
    }

    componentDidMount() {
        const { formValues, formStack, columnName } = this.props;
        const currentSection = formStack[formStack.length - 1];

        if (_.get(formValues, [currentSection, columnName, 'operation']) === OPERATION_BETWEEN) {
            this.setState({ between: true });
        }
    }

    render() {
        const {
            label = <div />,
            sectionConfig,
            columnName,
            columnType,
            columnOptions,
            storedDataMetas,
            inputProps = {},
        } = this.props;
        const { between } = this.state;
        const operationProps = {};

        if (getConfigFieldIsRequired(sectionConfig, columnName) === true) {
            this.setRequiredValidators([operationProps, inputProps]);
        }

        const isMulti = getConfigFieldIsMulti(sectionConfig, columnName);

        inputProps.multi = isMulti;

        if ('LOAN_ASSET' === columnName) {
            let loanAssetPurposes = new LoanAssetPurposeEnum();
            columnOptions[3].label = loanAssetPurposes.getTypes()[TYPE_NONE];
        }

        const multiClassName = isMulti ? 'scrollable' : 'not-scrollable';

        return (
            this.allDependenciesSatisfied() && (
                <RowItem className={`field-row`}>
                    <Container className={`container`}>
                        <Item className={`label-container label-container-business-stream`} xs={12} md={3}>
                            {label}
                        </Item>
                        <Item xs={12} md={9}>
                            <Container
                                className={`operand-container`}
                                justify="space-between"
                                direction="row"
                                spacing={2}
                            >
                                {isNumericType(storedDataMetas[columnName]['type'].toLowerCase()) === true ? (
                                    <React.Fragment>
                                        <Item className={`operation-container`} xs={12} md={4}>
                                            <OperationSelect
                                                name={columnName + `.operation`}
                                                columnName={columnName}
                                                placeholder={`Please select`}
                                                storedDataMetas={storedDataMetas}
                                                isNumericFact={isNumericType(
                                                    storedDataMetas[columnName]['type'].toLowerCase()
                                                )}
                                                {...operationProps}
                                                onChange={this.onOperationChange}
                                            />
                                        </Item>

                                        {between === false && (
                                            <Item className={`operand-single-container`} xs={12} md={8}>
                                                <InputField
                                                    name={columnName + `.operand`}
                                                    columnName={columnName}
                                                    columnType={columnType}
                                                    columnOptions={columnOptions}
                                                    placeholder={`Value`}
                                                    {...inputProps}
                                                />
                                            </Item>
                                        )}

                                        {between === true && (
                                            <React.Fragment>
                                                <Item className={`operand-start-container`} xs={12} sm={6} md={4}>
                                                    <InputField
                                                        name={columnName + `.operand-start`}
                                                        columnName={columnName}
                                                        columnType={columnType}
                                                        columnOptions={columnOptions}
                                                        {...inputProps}
                                                        fullWidth={false}
                                                        placeholder={
                                                            inputProps.placeholder
                                                                ? inputProps.placeholder + ' - START'
                                                                : 'START'
                                                        }
                                                    />
                                                </Item>
                                                <Item className={`operand-end-container`} xs={12} sm={6} md={4}>
                                                    <InputField
                                                        name={columnName + `.operand-end`}
                                                        columnName={columnName}
                                                        columnType={columnType}
                                                        columnOptions={columnOptions}
                                                        {...inputProps}
                                                        fullWidth={false}
                                                        placeholder={
                                                            inputProps.placeholder
                                                                ? inputProps.placeholder + ' - END'
                                                                : 'END'
                                                        }
                                                    />
                                                </Item>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                ) : (
                                    <Item
                                        className={`operand-multiple-container ${multiClassName}`}
                                        xs={12}
                                        md={12}
                                        lg={10}
                                    >
                                        <InputField
                                            name={columnName + `.operand`}
                                            columnName={columnName}
                                            columnType={columnType}
                                            columnOptions={columnOptions}
                                            placeholder={`Value`}
                                            {...inputProps}
                                        />
                                    </Item>
                                )}
                            </Container>
                        </Item>
                    </Container>
                </RowItem>
            )
        );
    }

    onOperationChange = (event, value, formerValue, inputName) => {
        if (value === OPERATION_BETWEEN) {
            this.setState({ between: true });
        } else {
            this.setState({ between: false });
        }

        this.resetOperandField(inputName, value);
    };

    resetOperandField = (inputName, operatorValue) => {
        const { getFormValue, changeValue } = this.props;

        if (operatorValue !== '') {
            return;
        }

        const [section, fact] = inputName.split('.');
        const factInput = getFormValue(section, fact);

        if (factInput !== undefined) {
            changeValue(`${section}.${fact}.operand`, '');
            changeValue(`${section}.${fact}.operand-start`, '');
            changeValue(`${section}.${fact}.operand-end`, '');
        }
    };

    setRequiredValidators = (inputPropsCollection) => {
        inputPropsCollection.forEach((inputProps) => {
            if (inputProps.validate) {
                inputProps.validate.unshift(isRequired);
            } else {
                inputProps.validate = [isRequired];
            }
        });
    };

    resetDependantFields = (inputProps) => {
        const { currentForm, columnName, resetFields, sectionConfig } = this.props;

        const dependants = getConfigDependantFields(sectionConfig, columnName) || [];

        if (dependants.length > 0) {
            const prevOnChange = _.isFunction(inputProps.onChange) ? inputProps.onChange : () => null;

            inputProps.onChange = (event, value, formerValue, inputName) => {
                resetFields(currentForm, dependants);
                prevOnChange(event, value, formerValue, inputName);
            };
        }
    };

    allDependenciesSatisfied = () => {
        const { formValues, sectionName, columnName, sectionConfig } = this.props;

        const dependencies = getConfigDependencies(sectionConfig, columnName) || [];

        for (let i = 0; i < dependencies.length; i++) {
            const dependencyValues = dependencies[i]['value'] || [];
            const dependencyField = dependencies[i]['field'];
            const dependencyType = getConfigFieldType(sectionConfig, dependencyField);
            let fieldValue = _.get(formValues, [sectionName, dependencyField, 'operand']);

            // Map currency types to pounds as they are stored and sent to the API in pennies.
            if (dependencyType === FIELD_TYPE_CURRENCY) {
                fieldValue = !isEmpty(fieldValue) ? fieldValue / 100 : fieldValue;
            }

            // Check to ensure the field value equals ANY of the values defined in config
            let stringValues = String(fieldValue);
            let stringValueArray = stringValues.split(',');
            let containsSomeValue = false;
            dependencyValues.forEach((element) => {
                if (stringValueArray.includes(element)) {
                    containsSomeValue = true;
                }
            });
            return containsSomeValue;
        }

        return true;
    };
}

FieldRow.propTypes = {
    label: PropTypes.object,
    columnOptions: PropTypes.array,
    columnName: PropTypes.string,
    inputProps: PropTypes.object,
    formValues: PropTypes.object,
    formStack: PropTypes.array.isRequired,
    getFormValue: PropTypes.func.isRequired,
};
