import createP4ApolloFetch from '../../../../../bootstrap/middlewares';
import { INTERMEDIARY_URL } from '../../../../../bootstrap/constants/constants';

const apolloFetch = createP4ApolloFetch({ uri: INTERMEDIARY_URL });

const getLenderLoanEnquiryQuery = `
    query getLenderLoanEnquiry($lenderLoanEnquiry: ID!) {
        lenderLoanEnquiry(id: $lenderLoanEnquiry) {
            id
            status
            lenderReference
            lenderTerms
            alertActive
            lenderTermsUpdatedAlertActive
            channel {
                id
            }
            lender {
                name
            }
            loanEnquiry {
                id
                status
                isNewForm
                loanEnquiryData {
                    loanToValue
                    loanToCost
                    loanToGrossDevelopmentValue
                    dayOneLoanAmountPercentage
                    additionalInformation
                    rentalIncomeResidential
                    hmoBedroom
                    hmoRoomsSelfContained
                    hmoHasLicense
                    hmoNeedsLicense
                    buyToLetExperience
                    rentalIncomeCommercial
                    rentalIncomePortfolio
                    rentalIncome
                    commercialUnitsRentedOut
                    commercialUnitOwnerOccupied
                    commercialUnitAmount
                    commercialType
                    commercialTypeOther
                    siteDescription
                    siteIntention
                    refinance
                    businessSeasonal
                    businessType
                    businessName
                    businessWebsite
                    businessTradingLength
                    businessSector
                    businessFacilityNeededBy
                    businessFacilityTerm
                    businessCurrentPaymentTerms
                    businessOutstandingInvoices
                    businessPdqIncome
                    propertyType
                    loanAmount
                    loanPurpose
                    turnOver
                    businessProfitable
                    businessOverdraftFacility
                    businessOutstandingDebt
                    businessAdverseCredit
                    businessAdverseCreditExplanation
                    businessDirectorsAdverseCredit
                    businessDirectorsAdverseCreditExplanation
                    shareholdersAreHomeOwners
                    propertyAvailableForAdditionalSecurity
                    planningFor
                    developmentLength
                    refurbishmentLength
                    outstandingLoan
                    dayOneLoanAmount
                    unitsPreSold
                    worksExplanation
                    totalDevelopmentCosts
                    buildCostsBorrowing
                    expectedGrossDevelopmentValue
                    previousDevelopmentExperience
                    previousRefurbishmentExperience
                    teamDevelopmentExperience
                    epcRating
                    loanType
                    fundingType
                    businessSecuredFinance
                    loanUsedInUkEu
                    planningInPlace
                    endValue
                    percentageResidential
                    percentageCommercial
                    lender
                    firstChargeAmount
                    currentValue
                    properties
                    portfolioValue
                    portfolioAlreadyOwned
                    loanTerm
                    loanNeededBy
                    preApproveMatches
                    loanAmountRefurbishment
                    percentageResidentialSquareFeet
                    percentageResidentialValue
                    percentageCommercialValue
                    residentialUnitCount
                    residentialUnitValue
                    businessOccupyEntireProperty
                    commercialUnitRentBreakdown
                    commercialTenantAmount
                    ownsOtherCommercialProperty
                    ownOtherProperties
                    occupyProperty
                    ownsOtherInvestmentProperties
                    familyHasOccupied
                    secondCharge
                    occupyingGreaterThan40Percent
                    location
                    locationOther
                    address
                    postcode
                    propertyOwnership
                    refurbishmentCost
                    invoiceFinanceType
                    leaseHirePurchase
                    mainApplicantCreditDifficulties
                    mainApplicantCreditDifficultiesExplanation
                    mainApplicantCitizenshipStatus
                    mainApplicantCountryOfResidence
                    mainApplicantCountryOfResidenceOther
                    mainApplicantNationality
                    mainApplicantNationalityOther
                    mainApplicantOccupation
                    mainApplicantGrossIncome
                    mainApplicantExitStrategy
                    agreeToTerms
                }
                storedData {
                    id
                    meta {
                        key
                        type
                    }
                    value
                }
                loanEnquiryApplicants {
                    isMainApplicant
                    applicant {
                        given
                        family
                        dateOfBirth
                    }
                }
                submissionData {
                    rawSubmission
                }
            }
            createdAt
            updatedAt
            matchedAt
            favourite
        }
    }
`;

export const getLenderLoanEnquiry = (lenderLoanEnquiryId) => {
    return apolloFetch({
        query: getLenderLoanEnquiryQuery,
        variables: {
            lenderLoanEnquiry: lenderLoanEnquiryId,
        },
    })
        .then(({ data, errors }) => {
            if (errors) {
                return {};
            }

            return data;
        })
        .catch((error) => {
            return {};
        });
};
