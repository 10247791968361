import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import BannerNoticeError, { BANNER_NOTICE_TYPE_ERROR } from './BannerNoticeError';
import { selectorBannerNotice } from '../redux/container';

export const NOTICE_ALL_TYPES = [BANNER_NOTICE_TYPE_ERROR];

const noticeBannerComponents = {
    BANNER_NOTICE_TYPE_ERROR: BannerNoticeError,
};

const BannerNotice = ({ currentNoticeBanner }) => {
    if (!currentNoticeBanner) {
        return null;
    }
    const { noticeType, noticeProps = {} } = currentNoticeBanner;
    const NoticeBannerComponent = noticeBannerComponents[noticeType];
    const renderComponent = <NoticeBannerComponent {...noticeProps} />;
    return <span>{renderComponent}</span>;
};

BannerNotice.propTypes = {
    currentNoticeBanner: PropTypes.shape({
        noticeType: PropTypes.string,
        noticeProps: PropTypes.object,
    }),
};

const mapStateToProps = (state) => {
    return {
        currentNoticeBanner: selectorBannerNotice(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(BannerNotice);
