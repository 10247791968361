import React from 'react';
import { Badge } from '../../pitch4_layout/components/badge/Badge';
import BorrowerCardViewLEStatusSteps from '../../apps/borrower/view_loan_enquiry/components/CardViewLEStatusSteps';
import IntermediaryCardViewLEStatusSteps from '../../apps/admin/intermediary/view_loan_enquiry/components/CardViewLEStatusSteps';
import moment from 'moment';
import BorrowerLoanTypeEnum from '../../apps/borrower/enum/LoanEnquiries/LoanTypeEnum';
import IntermediaryLoanTypeEnum from '../../apps/admin/intermediary/enum/LoanEnquiries/LoanTypeEnum';
import BorrowerStatusEnum, { STATUS_DRAFT } from '../../apps/borrower/enum/LoanEnquiries/StatusEnum';
import IntermediaryStatusEnum from '../../apps/admin/intermediary/enum/LoanEnquiries/StatusEnum';
import { Button, Hidden } from '@material-ui/core';
import { push } from 'react-router-redux';
import {
    path,
    ROUTE_BORROWER_EDIT_LOAN_ENQUIRY,
    ROUTE_BORROWER_LOAN_ENQUIRY_VIEW,
    ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW,
} from '../../pitch4_routing';
import { compose } from 'recompose';
import { connect } from 'react-redux';

class LECard extends React.Component {
    render() {
        const {
            le,
            cardStyle = {},
            mobileLEViewButton = false,
            dispatch,
            borrower = false,
            intermediary = false,
        } = this.props;
        let loanTypeEnum = null;
        let statusEnum = null;
        let leViewUrl = null;
        let CardViewLEStatusSteps = null;

        switch (true) {
            case borrower:
                loanTypeEnum = new BorrowerLoanTypeEnum();
                statusEnum = new BorrowerStatusEnum();
                if (le.status === STATUS_DRAFT) {
                    leViewUrl = ROUTE_BORROWER_EDIT_LOAN_ENQUIRY;
                } else {
                    leViewUrl = ROUTE_BORROWER_LOAN_ENQUIRY_VIEW;
                }
                CardViewLEStatusSteps = BorrowerCardViewLEStatusSteps;
                break;
            case intermediary:
                loanTypeEnum = new IntermediaryLoanTypeEnum();
                statusEnum = new IntermediaryStatusEnum();
                leViewUrl = ROUTE_INTERMEDIARY_LOANENQUIRY_VIEW;
                CardViewLEStatusSteps = IntermediaryCardViewLEStatusSteps;
                break;
            default:
                return;
        }

        const getLELoanType = (le) => {
            if (le.isNewForm) {
                const loanType = le.loanEnquiryData.loanType;
                return loanType ? loanType : '-';
            } else {
                const loanType = le.storedData.find((item) => item.meta.key === 'LOAN_TYPE');
                return loanType ? loanType.value : undefined;
            }
        };

        const getLELoanAmount = (le) => {
            let loanAmount = null;
            const formatter = new Intl.NumberFormat('en-UK', {
                style: 'currency',
                currency: 'GBP',
                minimumFractionDigits: 0,
            });
            if (true === le.isNewForm) {
                loanAmount = le.loanEnquiryData.loanAmount;
                return loanAmount ? formatter.format(loanAmount) : '-';
            } else {
                loanAmount = le.storedData.find((item) => item.meta.key === 'LOAN_AMOUNT');
                return loanAmount ? formatter.format(loanAmount.value / 100) : '-'; // Amount in pennies
            }
        };

        return (
            <div style={cardStyle} className={'le-card'}>
                {intermediary && le.loanEnquiryApplicants && (
                    <div className={'applicant'}>
                        <div className={'name'}>
                            {le.loanEnquiryApplicants[0] && le.loanEnquiryApplicants[0].applicant
                                ? le.loanEnquiryApplicants[0].applicant.given +
                                  ' ' +
                                  le.loanEnquiryApplicants[0].applicant.family
                                : 'No applicant selected'}
                        </div>
                    </div>
                )}
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div className={'amount'}>{getLELoanAmount(le)}</div>
                        <div className={'update'}>
                            {le.alertActive && (
                                <Badge
                                    style={{
                                        fontSize: 12,
                                        fontWeight: 500,
                                        padding: '5px 15px 5px 15px',
                                    }}
                                    label={'update'}
                                />
                            )}
                        </div>
                    </div>
                    <div className={'type'}>{loanTypeEnum.getType(getLELoanType(le))}</div>
                    {mobileLEViewButton && (
                        <Hidden mdUp>
                            <div className={'mobile-le-view-btn'}>
                                <Button
                                    variant={'contained'}
                                    color="primary"
                                    onClick={() => {
                                        dispatch(
                                            push(
                                                path(leViewUrl, {
                                                    loanEnquiryId: le.id,
                                                })
                                            )
                                        );
                                    }}
                                >
                                    VIEW LOAN ENQUIRY
                                </Button>
                            </div>
                        </Hidden>
                    )}
                </div>
                {intermediary && le.submittedBy && (
                    <div className={'submitted-by'}>
                        <div>
                            <strong>Submitted by</strong>
                        </div>
                        <div className={'name'}>
                            {le.submittedBy.userProfile.given + ' ' + le.submittedBy.userProfile.family}
                        </div>
                    </div>
                )}
                <div>
                    <div className={'status'}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: 5 }} className={`status-${le.status}`}>
                                {statusEnum.getType(le.status, getLELoanType(le))}
                            </div>
                            <div style={{ display: 'flex' }}>
                                <CardViewLEStatusSteps status={le.status} />
                            </div>
                        </div>
                    </div>
                    <div className={'matches-count'}>
                        {null !== le.lenderLoanEnquiries ? le.lenderLoanEnquiries.length : 0} Lender Matches
                    </div>
                    <div className={'dates'}>
                        <div className={'created'}>
                            <div className={'header'}>Created</div>
                            <div className={'date'}>{moment(le.createdAt).format('MMM DD, YYYY')}</div>
                        </div>
                        <div className={'divider'} />
                        <div className={'updated'}>
                            <div className={'header'}>Last Update</div>
                            <div className={'date'}>{moment(le.updatedAt).format('MMM DD, YYYY')}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(LECard);
