export const NAMESPACE = 'admin.user.create_edit';

export const GET_LENDERS = `${NAMESPACE}.get_lenders`;
export const SET_LENDERS = `${NAMESPACE}.set_lenders`;
export const GET_BROKERS = `${NAMESPACE}.get_brokers`;
export const SET_BROKERS = `${NAMESPACE}.set_brokers`;

export const actionGetLenders = () => ({
    type: GET_LENDERS,
});

export const actionSetLenders = (lenders) => ({
    type: SET_LENDERS,
    lenders,
});

export const actionGetBrokers = () => ({
    type: GET_BROKERS,
});

export const actionSetBrokers = (brokers) => ({
    type: SET_BROKERS,
    brokers,
});

const initialState = {
    lenders: [],
    brokers: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_LENDERS:
            const lenders = action.lenders;
            return {
                ...state,
                lenders,
            };

        case SET_BROKERS:
            const brokers = action.brokers;
            return {
                ...state,
                brokers,
            };

        default:
            return {
                ...state,
            };
    }
};

export const selectorGetLenders = (state) => {
    return state[`${NAMESPACE}`].lenders;
};

export const selectorGetBrokers = (state) => {
    return state[`${NAMESPACE}`].brokers;
};

//Default export reducer
export default reducer;
