import React from 'react';
import PropTypes from 'prop-types';
import { ClickAwayListener, Paper, Popper } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { filterTokens } from '../filter-tokens';

class Tooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isOpen: false };
        this.anchorRef = React.createRef();
    }

    handleOpen = () => {
        this.setState({ isOpen: true });
    };

    handleClose = () => {
        this.setState({ isOpen: false });
    };

    render() {
        const { confirmText = 'Ok', placement = 'top', arrow = true } = this.props;
        let { content = '' } = this.props;
        let tooltipArrowClass = true === arrow ? ' tooltip-with-arrow' : '';

        return (
            <div className="tooltip-anchor" ref={this.anchorRef}>
                <HelpOutlineIcon
                    aria-controls="tooltip"
                    aria-haspopup="true"
                    onClick={this.handleOpen}
                    className="tooltip-icon"
                />
                <Popper
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    anchorEl={this.anchorRef.current}
                    placement={placement}
                >
                    <ClickAwayListener onClickAway={this.handleClose}>
                        <Paper square elevation={6} className={`tooltip${tooltipArrowClass}`}>
                            <div className="tooltip-content">{filterTokens(content)}</div>
                            {confirmText && (
                                <div onClick={this.handleClose} className="tooltip-confirm">
                                    {confirmText}
                                </div>
                            )}
                        </Paper>
                    </ClickAwayListener>
                </Popper>
            </div>
        );
    }
}

Tooltip.propTypes = {
    content: PropTypes.string.isRequired,
    confirmText: PropTypes.string,
    placement: PropTypes.string,
    arrow: PropTypes.bool,
};

export default Tooltip;
