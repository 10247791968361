import React from 'react';
import SingleColumnLayout from './layouts/SingleColumnLayout';
import NotificationsContainer from '../apps/notifications/containers/Container';
import ModalContainer from '../apps/modal/containers/Container';
import NavigationContainer from '../apps/navigation/containers/NavigationContainer';
import TwoFactorAuthLoginContainer from '../apps/authentication/containers/TwoFactorAuthLoginContainer';

const TwoFactorAuthLoginPage = () => (
    <SingleColumnLayout header={<NavigationContainer />} notifications={<NotificationsContainer />}>
        <TwoFactorAuthLoginContainer />
        <ModalContainer />
    </SingleColumnLayout>
);

export default TwoFactorAuthLoginPage;
