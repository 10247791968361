export const BUSINESS_TYPE = 'BUSINESS_TYPE';
export const PROPERTY_TYPE = 'PROPERTY_TYPE';
export const TERM_TYPE = 'TERM_TYPE';
export const BRIDGE_TYPE = 'BRIDGE_TYPE';
export const REFURBISHMENT_TYPE = 'REFURBISHMENT_TYPE';
export const SECOND_CHARGE_TYPE = 'SECOND_CHARGE_TYPE';
export const DEVELOPMENT_TYPE = 'DEVELOPMENT_TYPE';
export const MEZZANINE_TYPE = 'MEZZANINE_TYPE';
export const PORTFOLIO_TYPE = 'PORTFOLIO_TYPE';
export const UNKNOWN_TYPE = 'UNKNOWN_TYPE';
export const TERM_LONG_TYPE = 'TERM_LONG_TYPE';
export const TERM_SHORT_TYPE = 'TERM_SHORT_TYPE';
export const R_C_F_TYPE = 'R_C_F_TYPE';
export const INVOICE_TYPE = 'INVOICE_TYPE';
export const ASSET_TYPE = 'ASSET_TYPE';
export const MERCHANT_CASH_ADVANCE_TYPE = 'MERCHANT_CASH_ADVANCE_TYPE';
export const SUPPLY_CHAIN_TYPE = 'SUPPLY_CHAIN_TYPE';

class BorrowerLoanType {
    getTypes = () => {
        return {
            [TERM_TYPE]: {
                value: TERM_TYPE,
                label: "I'm looking for a long term mortgage on a property ([bold|Term])",
            },
            [BRIDGE_TYPE]: {
                value: BRIDGE_TYPE,
                label: "I'm looking for a fast and short term lending solution on a property or site ([bold|Bridging])",
            },
            [REFURBISHMENT_TYPE]: {
                value: REFURBISHMENT_TYPE,
                label: "I'm looking to purchase and renovate a property ([bold|Refurbishment])",
            },
            [SECOND_CHARGE_TYPE]: {
                value: SECOND_CHARGE_TYPE,
                label: "I'm looking for a second charge mortgage ([bold|Second Charge])",
            },
            [DEVELOPMENT_TYPE]: {
                value: DEVELOPMENT_TYPE,
                label: "I'm looking for development finance ([bold|Development])",
            },
            [MEZZANINE_TYPE]: {
                value: MEZZANINE_TYPE,
                label: "I'm looking for mezzanine finance ([bold|Mezzanine])",
            },
            [PORTFOLIO_TYPE]: {
                value: PORTFOLIO_TYPE,
                label: "I'm looking to finance or refinance my portfolio ([bold|Portfolio])",
            },
            [UNKNOWN_TYPE]: { value: UNKNOWN_TYPE, label: "I'm unsure, or I don't know" },
            [TERM_LONG_TYPE]: { value: TERM_LONG_TYPE, label: "I'm looking for a long term business loan" },
            [TERM_SHORT_TYPE]: { value: TERM_SHORT_TYPE, label: "I'm looking for a short term business loan" },
            [R_C_F_TYPE]: {
                value: R_C_F_TYPE,
                label: "I'm looking for a flexible Line Of Credit/Revolving Credit Facility",
            },
            [INVOICE_TYPE]: { value: INVOICE_TYPE, label: "I'm looking for invoice finance" },
            [ASSET_TYPE]: { value: ASSET_TYPE, label: "I'm looking to raise finance on an asset" },
            [MERCHANT_CASH_ADVANCE_TYPE]: {
                value: MERCHANT_CASH_ADVANCE_TYPE,
                label: "I'm looking for a merchant cash advance",
            },
            [SUPPLY_CHAIN_TYPE]: { value: SUPPLY_CHAIN_TYPE, label: "I'm looking for Supply Chain or Trade finance" },
            [BUSINESS_TYPE]: { value: BUSINESS_TYPE, label: 'Business' },
            [PROPERTY_TYPE]: { value: PROPERTY_TYPE, label: 'Property' },
        };
    };
}

export default BorrowerLoanType;
