import base_reducer, { defaultInitialState } from '../../../../../pitch4_tabular/redux/index_ns';

const initialState = {
    ...defaultInitialState,
};

export const NAMESPACE = 'slam.loanenquiries';
export const CLEAR_USER_ALERTS = `${NAMESPACE}.clear_user_alerts`;

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_USER_ALERTS:
            return {
                ...state,
            };

        default:
            break;
    }

    return base_reducer(state, action, NAMESPACE);
};

export const actionClearUserAlerts = () => {
    return {
        type: CLEAR_USER_ALERTS,
    };
};

//Default export reducer
export default reducer;
