import React from 'react';
import { Container } from '../../../pitch4_layout/components/div';
import PropTypes from 'prop-types';

class Table extends React.Component {
    render() {
        let { children, style = {} } = this.props;

        return (
            <Container spacing={1} className={`table-container`} style={style}>
                {children}
            </Container>
        );
    }
}

Table.propTypes = {
    children: PropTypes.any.isRequired,
    style: PropTypes.object,
};

export default Table;
