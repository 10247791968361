import { BaseEnum } from 'pitch4_enum';

export const TYPE_RESIDENTIAL = 'residential';
export const TYPE_COMMERCIAL = 'commercial';
export const TYPE_MIXED_USE = 'mixed_use';
export const TYPE_OTHER = 'other';

class PlanningForEnum extends BaseEnum {
    getTypes = () => {
        return {
            [TYPE_RESIDENTIAL]: 'Residential',
            [TYPE_COMMERCIAL]: 'Commercial',
            [TYPE_MIXED_USE]: 'Mixed use',
            [TYPE_OTHER]: 'Other',
        };
    };
}

export default PlanningForEnum;
