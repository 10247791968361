import _ from 'lodash';
import * as routes from '../../../pitch4_routing';
import admin from './admin';
import intermediary from './intermediary';
import slam from './slam';
import borrower from './borrower';
import matchPath from 'react-router/es/matchPath';

const configPathMap = {
    [routes.ROUTE_BC_ROOT]: admin,
    [routes.ROUTE_P4A_ROOT]: admin,
    [routes.ROUTE_INTERMEDIARY_ROOT]: intermediary,
    [routes.ROUTE_SLAM_ROOT]: slam,
    [routes.ROUTE_BORROWER_ROOT]: borrower,
};

const match = (path, pathname) => {
    for (let key of Object.keys(routes)) {
        if (typeof routes[key] === 'string') {
            const res = matchPath(pathname, { path: routes[key] }, routes[key]);

            if (res !== null && typeof res === 'object' && res.isExact === true) {
                return res;
            }
        }
    }

    return '';
};

export default function (path, pathname) {
    // support for slug based urls
    const matched = match(path, pathname);

    if (matched !== null && _.get(configPathMap, [path, matched.path]) !== undefined) {
        return {
            trail: {
                ...configPathMap[path][matched.path],
            },
            match: matched,
        };
    }

    return null;
}
