class BaseLevel {
    constructor(type, message) {
        this.type = type;
        this.message = message;
    }

    getMessage() {
        return this.message;
    }

    getType() {
        return this.type;
    }
}

export default BaseLevel;
