import React from 'react';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import { Grid } from '@material-ui/core';
import { BRAND_MAIN_CONTACT } from '../../bootstrap/constants/constants';

export default class Header extends React.Component {
    render() {
        const { user, showHelp = true } = this.props;
        return (
            <Grid container className={'header-container'}>
                <Grid item className={'user'}>
                    <Grid item className={'welcome'}>
                        Welcome {user.name},
                    </Grid>
                    <Grid item className={'logged-in-as'}>
                        Logged in as a <span className={'user-type'}>{user.type}</span> with the Email address:{' '}
                        <strong>{user.email}</strong>
                    </Grid>
                </Grid>
                {true === showHelp && (
                    <Grid item className={'help'}>
                        <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                            <PhoneInTalkIcon className={'icon'} />
                            <span>Need to talk? {BRAND_MAIN_CONTACT}</span>
                        </Grid>
                        <Grid item className={'text'}>
                            We are here to help!
                        </Grid>
                    </Grid>
                )}
            </Grid>
        );
    }
}
