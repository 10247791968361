export const YES = 'YES';
export const NO = 'NO';

class YesNo {
    getTypes = () => {
        return [
            { value: YES, label: 'Yes' },
            { value: NO, label: 'No' },
        ];
    };

    getOptions = () => {
        return {
            [YES]: { value: YES, label: 'Yes' },
            [NO]: { value: NO, label: 'No' },
        };
    };
}

export default YesNo;
