export const SECTION_LOAN_TYPE = 'LoanType';
export const SECTION_BUSINESS_FINANCE = 'BusinessFinance';
export const SECTION_INTENT_TO_OCCUPY = 'IntentToOccupy';
export const SECTION_REFINANCE = 'Refinance';
export const SECTION_UNIVERSAL = 'Universal';
export const SECTION_RESIDENTIAL = 'Residential';
export const SECTION_SEMI_COMMERCIAL = 'SemiCommercial';
export const SECTION_COMMERCIAL = 'Commercial';
export const SECTION_LAND_AND_DEVELOPMENT = 'LandAndDevelopment';
export const SECTION_CREDIT_AND_CITIZENSHIP = 'CreditAndCitizenship';
