export class PasswordGenerator {
    generatePassword(min, max) {
        let passwordLength = this.randomIntFromInterval(min, max);
        const numberChars = '0123456789';
        const upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerChars = 'abcdefghijklmnopqrstuvwxyz';
        const specialChars = '?*-_$';
        const allChars = numberChars + upperChars + lowerChars + specialChars;
        let randPasswordArray = Array(passwordLength);
        randPasswordArray[0] = numberChars;
        randPasswordArray[1] = upperChars;
        randPasswordArray[2] = lowerChars;
        randPasswordArray[3] = specialChars;
        randPasswordArray = randPasswordArray.fill(allChars, 3);
        return this.shuffleArray(
            randPasswordArray.map(function (x) {
                return x[Math.floor(Math.random() * x.length)];
            })
        ).join('');
    }

    randomIntFromInterval = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    };

    shuffleArray = (array) => {
        for (var i = array.length - 1; i > 0; i--) {
            var j = Math.floor(Math.random() * (i + 1));
            var temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
        return array;
    };
}
