import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import PropertyLocation, { OTHER } from '../../../enums/Shared/PropertyLocation';
import BorrowerQuestions, {
    QUESTION_CURRENT_VALUE,
    QUESTION_CURRENT_VALUE_REFURBISHMENT,
    QUESTION_FUNDING_TYPE,
    QUESTION_OWN_ANY_OTHER_PROPERTY,
    QUESTION_PROPERTY_ADDRESS,
    QUESTION_PROPERTY_LOCATION,
    QUESTION_PROPERTY_LOCATION_OTHER,
    QUESTION_PROPERTY_OWNERSHIP,
    QUESTION_PROPERTY_POSTCODE,
} from '../../../enums/Borrower/BorrowerQuestions';
import Question from '../../Question/containers/Question';
import OwnAnyOtherProperty from '../../../enums/Shared/OwnAnyOtherProperty';
import Ownership from '../../../enums/Shared/Ownership';
import SessionManager from '../../../../../pitchblack_react_utils/session/SessionManager';
import { ROLE_BORROWER, ROLE_SUPER_ADMIN } from '../../../../authentication/enum/Roles/NameEnum';
import BrokerQuestions from '../../../enums/Broker/BrokerQuestions';
import { DEVELOPMENT_TYPE, PORTFOLIO_TYPE, REFURBISHMENT_TYPE } from '../../../enums/Borrower/BorrowerLoanType';

class PropertyInformationContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: props.formData,
            validation: props.validation,
        };
    }

    render() {
        let sessionManager = new SessionManager();
        if (sessionManager.getMostSeniorRole().name === ROLE_SUPER_ADMIN && sessionManager.hasAssumedToken() === true) {
            sessionManager = sessionManager.getAssumedSession();
        }
        const userRole = sessionManager.getMostSeniorRole().name;
        const questions =
            userRole === ROLE_BORROWER ? new BorrowerQuestions().getTypes() : new BrokerQuestions().getTypes();
        const propertyLocations = new PropertyLocation().getTypes();
        const ownAnyOtherPropertyOptions = new OwnAnyOtherProperty().getTypes();
        const ownershipOptions = new Ownership().getTypes();
        let currentValueLabel = questions[QUESTION_CURRENT_VALUE].label;
        if (
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === REFURBISHMENT_TYPE ||
            this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] === DEVELOPMENT_TYPE
        ) {
            currentValueLabel = questions[QUESTION_CURRENT_VALUE_REFURBISHMENT].label;
        }

        return (
            <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                    <Typography variant="h3" component="h3">
                        {userRole === ROLE_BORROWER
                            ? 'About your Property Finance'
                            : 'About your clients Property Finance'}
                    </Typography>
                </Grid>
                {this.state.formData[questions[QUESTION_FUNDING_TYPE].fieldName] !== PORTFOLIO_TYPE && (
                    <>
                        <Question
                            type={questions[QUESTION_CURRENT_VALUE].type}
                            label={currentValueLabel}
                            questionEnum={'QUESTION_CURRENT_VALUE'}
                            fieldName={questions[QUESTION_CURRENT_VALUE].fieldName}
                            placeholder={questions[QUESTION_CURRENT_VALUE].placeholder}
                            validation={questions[QUESTION_CURRENT_VALUE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_PROPERTY_LOCATION].type}
                            label={questions[QUESTION_PROPERTY_LOCATION].label}
                            questionEnum={'QUESTION_PROPERTY_LOCATION'}
                            fieldName={questions[QUESTION_PROPERTY_LOCATION].fieldName}
                            placeholder={questions[QUESTION_PROPERTY_LOCATION].placeholder}
                            validation={questions[QUESTION_PROPERTY_LOCATION].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={propertyLocations}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        {this.state.formData[questions[QUESTION_PROPERTY_LOCATION].fieldName] === OTHER && (
                            <Question
                                type={questions[QUESTION_PROPERTY_LOCATION_OTHER].type}
                                label={questions[QUESTION_PROPERTY_LOCATION_OTHER].label}
                                questionEnum={'QUESTION_PROPERTY_LOCATION_OTHER'}
                                fieldName={questions[QUESTION_PROPERTY_LOCATION_OTHER].fieldName}
                                placeholder={questions[QUESTION_PROPERTY_LOCATION_OTHER].placeholder}
                                validation={questions[QUESTION_PROPERTY_LOCATION_OTHER].fieldName + '_error'}
                                onChangeFunction={this.props.onChangeHandler}
                                fieldOptions={[]}
                                formData={this.state.formData}
                                formValidation={this.state.validation}
                                rows={0}
                            />
                        )}
                        <Question
                            type={questions[QUESTION_PROPERTY_ADDRESS].type}
                            label={questions[QUESTION_PROPERTY_ADDRESS].label}
                            questionEnum={'QUESTION_PROPERTY_ADDRESS'}
                            fieldName={questions[QUESTION_PROPERTY_ADDRESS].fieldName}
                            placeholder={questions[QUESTION_PROPERTY_ADDRESS].placeholder}
                            validation={questions[QUESTION_PROPERTY_ADDRESS].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_PROPERTY_POSTCODE].type}
                            label={questions[QUESTION_PROPERTY_POSTCODE].label}
                            questionEnum={'QUESTION_PROPERTY_POSTCODE'}
                            fieldName={questions[QUESTION_PROPERTY_POSTCODE].fieldName}
                            placeholder={questions[QUESTION_PROPERTY_POSTCODE].placeholder}
                            validation={questions[QUESTION_PROPERTY_POSTCODE].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={[]}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                        <Question
                            type={questions[QUESTION_OWN_ANY_OTHER_PROPERTY].type}
                            label={questions[QUESTION_OWN_ANY_OTHER_PROPERTY].label}
                            questionEnum={'QUESTION_OWN_ANY_OTHER_PROPERTY'}
                            fieldName={questions[QUESTION_OWN_ANY_OTHER_PROPERTY].fieldName}
                            placeholder={questions[QUESTION_OWN_ANY_OTHER_PROPERTY].placeholder}
                            validation={questions[QUESTION_OWN_ANY_OTHER_PROPERTY].fieldName + '_error'}
                            onChangeFunction={this.props.onChangeHandler}
                            fieldOptions={ownAnyOtherPropertyOptions}
                            formData={this.state.formData}
                            formValidation={this.state.validation}
                            rows={0}
                        />
                    </>
                )}
                <Question
                    type={questions[QUESTION_PROPERTY_OWNERSHIP].type}
                    label={questions[QUESTION_PROPERTY_OWNERSHIP].label}
                    questionEnum={'QUESTION_PROPERTY_OWNERSHIP'}
                    fieldName={questions[QUESTION_PROPERTY_OWNERSHIP].fieldName}
                    placeholder={questions[QUESTION_PROPERTY_OWNERSHIP].placeholder}
                    validation={questions[QUESTION_PROPERTY_OWNERSHIP].fieldName + '_error'}
                    onChangeFunction={this.props.onChangeHandler}
                    fieldOptions={ownershipOptions}
                    formData={this.state.formData}
                    formValidation={this.state.validation}
                    rows={0}
                />
            </Grid>
        );
    }
}

export default PropertyInformationContainer;
